import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import LoadingButton from "@mui/lab/LoadingButton";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Avatar from "@mui/material/Avatar";

import { BASE_URL } from "../../../constants";
import Scrollbar from "../../../components/Scrollbar";
import DashboardLayout from "../../../layouts/dashboard";
import { decryption, encryption } from "../../../utils/encodeString";
import Loader from "../../../components/Loader";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import internEvaluationSchema from "../../../validations/internEvaluationSchema";

import { getStudentById } from "../../../redux/student/studentThunk";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import {
  insertEvaluation,
  getEvaluationQuestion,
} from "../../../redux/internEvaluation/internEvaluationThunk";
import { FormHelperText } from "@mui/material";

function InternEvaluation() {
  let { id } = useParams();
  id = decryption(id);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = location.state;
  const [isLoading, setIsLoading] = useState(true);
  const [internData, setInternData] = useState(null);
  //const employeeData = useSelector((state) => state.employee);
  const employeeData = useSelector((state) => state.employee.allEmployeeData);
  const evaluationQuestion = useSelector(
    (state) => state.internEvaluation.evaluationQuestion
  );
  const [answerArray, setAnswerArray] = useState([]);

  useEffect(() => {
    if (id === -1) {
      navigate("/interns");
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getStudentById(id))
      .unwrap()
      .then((res) => {
        setInternData(res);

        setIsLoading(false);
      })
      .catch((err) => {
        navigate("/interns");
      });
  }, [dispatch, id, navigate]);

  // useEffect(() => {
  // 	dispatch(getEmployeeForDropDown({...params}));
  // }, []);

  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

  const { data } = useSelector((state) => state.internEvaluation);
  const formik = useFormik({
    initialValues: {
      evalDate: new Date(),
      evalFromDate: "",
      evalToDate: "",
      employeeId: "",
      studentId: id,
      queText: [],
    },
    validate: async (values) => {
      let errors = {};

      if (values.evalFromDate) {
        if (
          internData?.joiningDate &&
          new Date(internData.joiningDate) > values.evalFromDate
        ) {
          errors = {
            ...errors,
            evalFromDate: "Date must be greater than joining date",
          };
        }
      }

      if (values.evalToDate) {
        if (
          internData?.joiningDate &&
          new Date(internData.joiningDate) > values.evalToDate
        ) {
          errors = {
            ...errors,
            evalToDate: "Date must be greater than joining date",
          };
        }
        if (
          new Date(values?.evalToDate)?.setHours(0, 0, 0, 0) <
          new Date(values?.evalFromDate)?.setHours(0, 0, 0, 0)
        ) {
          errors = {
            ...errors,
            evalToDate: "Date must be greater than Evaluation To date",
          };
        }
      }

      if (values?.evalDate) {
        var defaultDate = new Date();
        defaultDate.setFullYear(1970, 0, 1);
        if (
          values?.evalDate?.setHours(0, 0, 0, 0) ==
          defaultDate.setHours(0, 0, 0, 0)
        ) {
          errors = { ...errors, evalDate: "This field is required" };
        } else if (
          internData?.joiningDate &&
          new Date(internData.joiningDate).setHours(0, 0, 0, 0) >
            values?.evalDate?.setHours(0, 0, 0, 0)
        ) {
          errors = {
            ...errors,
            evalDate: "Date must be greater than joining date",
          };
        } else {
          if (
            values?.evalDate &&
            new Date(values?.evalToDate)?.setHours(0, 0, 0, 0) >
              new Date(values?.evalDate)?.setHours(0, 0, 0, 0)
          ) {
            errors = {
              ...errors,
              evalToDate: "Date must be smaller than Evaluation Date",
            };
          }
          if (
            values?.evalDate &&
            new Date(values?.evalFromDate)?.setHours(0, 0, 0, 0) >
              new Date(values?.evalDate)?.setHours(0, 0, 0, 0)
          ) {
            errors = {
              ...errors,
              evalFromDate: "Date must be smaller than Evaluation Date",
            };
          }
        }
      }

      return errors;
    },
    validationSchema: internEvaluationSchema,
    onSubmit: async (values, { resetForm }) => {
      values.questions = [
        ...values?.workquestions,
        ...values?.perquestions,
        ...values?.queText,
        ...values?.techquestions,
      ];
      // console.log("V__________01",values)
      try {
        await dispatch(insertEvaluation(values)).unwrap();
        navigate(`/interns/view/${encryption(id)}`);
        resetForm();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    handleBlur,
    errors,
    touched,
    isSubmitting,
    setErrors,
  } = formik;
  useEffect(() => {
    const fetchEvaluationQuestions = async () => {
      try {
        const res = await dispatch(getEvaluationQuestion()).unwrap();
        
        let techquestions = [];
        let queText = [];
        let perquestions = [];
        let workquestions = [];
        
        res.forEach((que) => {
          if (que?.questionType === "Text") {
            queText.push({ ...que, questionValue: null, questionId: que?.id });
          } else {
            if (que?.questionGroup === "technical") {
              techquestions.push({
                ...que,
                questionValue: null,
                questionId: que?.id,
              });
            } else if (que?.questionGroup === "performance") {
              perquestions.push({
                ...que,
                questionValue: null,
                questionId: que?.id,
              });
            } else if (que?.questionGroup === "work_habit") {
              workquestions.push({
                ...que,
                questionValue: null,
                questionId: que?.id,
              });
            }
          }
        });

        setFieldValue("queText", queText);
        setFieldValue("techquestions", techquestions);
        setFieldValue("workquestions", workquestions);
        setFieldValue("perquestions", perquestions);

        // Optional: setAnswerArray(temp);
        // Optional: setFieldValue("questions", temp);
        
      } catch (error) {
        // Handle error (optional)
        console.error(error.message);
      }
    };

    fetchEvaluationQuestions();
  }, []);

  // const handleAnswerChange = (event, newValue, question) => {
  //   const tempAnswerArray = answerArray.map((q) => {
  //     if (q.questionId === question.id) {
  //       if (question.questionType == "Text") {
  //         return { ...q, questionValue: event.target.value };
  //       } else {
  //         return { ...q, questionValue: newValue };
  //       }
  //     } else {
  //       return q;
  //     }
  //   });
  //   setAnswerArray(tempAnswerArray);
  // };

  const handleEvaluationDate = (eDate, joiningDate) => {
    // console.log(errors)
    // console.log( "Eval date",eDate)
    // console.log("joining date", joiningDate)
    // if(joiningDate)
    // {
    // 	if(joiningDate > eDate)
    // 	{
    // 		// setErrors({...errors, evalDate: "Date must be after joining date"});
    // 		// setErrors({...errors, evalFromDate:"olp"})
    // 		console.log(errors)
    // 	}
    // }
  };
  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <div className="view_employee candidate-details">
      <Container maxWidth="xl" className="container">
        <form onSubmit={handleSubmit}>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
           <div className="title-button-wrapper">
            <Typography variant="h4" gutterBottom>
              Intern Evaluation :{" "}
              {internData?.firstName ? internData?.firstName : ""}{" "}
              {internData?.lastName ? internData?.lastName : ""}
            </Typography>
            
            <Stack direction="row" alignItems="center">
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
              <Button
                variant="contained"
                onClick={() => navigate("/interns")}
                startIcon={<ArrowBackIcon />}
                style={{ marginLeft: "10px" }}
              >
                Back To List
              </Button>
            </Stack>
            </div>
          </Stack>
          <Card className="holiday-table-grid Intern-Evaluation-card gray-box Employee_Detail_Content" mb={1}>
            <Container maxWidth="xl">
              <div className="profile-info-part">
                <div className="profile-image-part">
                  <Avatar
                    src={
                      internData.image
                        ? `${BASE_URL}/${internData?.image}`
                        : internData?.gender == "F"
                        ? "/assets/images/female.png"
                        : "/assets/images/male.png"
                    }
                    // alt={internData?.firstName}
                    sx={{
                      width: "100px",
                      height: "100px",
                      fontSize: "2.5rem",
                      // marginTop: "5px",
                    }}
                  ></Avatar>
                </div>
              </div>
              <Paper className="Employee_Detail_Content-Paper">
                <Grid container spacing={1}>
                  <Grid item xs={3} mt={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        //defaultValue={new Date()}
                        label="Evaluation Date"
                        maxDate={new Date()}
                        inputFormat="dd/MM/yyyy"
                        value={values?.evalDate}
                        defaultValue=""
                        onChange={(newValue) => {
                          // setFieldValue(`evalDate`, newValue);
                          formik.setFieldValue(`evalDate`, new Date(newValue));
                          handleEvaluationDate(
                            newValue,
                            internData?.joiningDate
                              ? new Date(internData.joiningDate)
                              : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            autoComplete="false"
                            {...params}
                            error={Boolean(
                              touched?.evalDate && errors?.evalDate
                            )}
                            helperText={touched?.evalDate && errors?.evalDate}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={3} mt={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Evaluation From Date"
                        maxDate={new Date()}
                        inputFormat="dd/MM/yyyy"
                        value={values?.evalFromDate ? values?.evalFromDate : ""}
                        onChange={(newValue) => {
                          // setFieldValue(`evalFromDate`, newValue);
                          formik.setFieldValue(
                            `evalFromDate`,
                            new Date(newValue)
                          );
                          handleEvaluationDate(
                            newValue,
                            internData?.joiningDate
                              ? new Date(internData.joiningDate)
                              : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.evalFromDate && errors?.evalFromDate
                            )}
                            helperText={
                              touched?.evalFromDate && errors?.evalFromDate
                            }
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item xs={3} mt={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Evaluation To Date"
                        maxDate={new Date()}
                        inputFormat="dd/MM/yyyy"
                        value={values?.evalToDate ? values?.evalToDate : ""}
                        onChange={(newValue) => {
                          // setFieldValue(`evalToDate`, newValue);
                          formik.setFieldValue(
                            `evalToDate`,
                            new Date(newValue)
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.evalToDate && errors?.evalToDate
                            )}
                            helperText={
                              touched?.evalToDate && errors?.evalToDate
                            }
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3} mt={3}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={employeeData || []}
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName || ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} value={option.id} key={option.id}>
                          {option.firstName + " " + option.lastName || ""}
                        </li>
                      )}
                      onChange={(event, newValue) => {
                        setFieldValue("employeeId", newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Evaluation By"
                          error={Boolean(
                            touched?.employeeId && errors?.employeeId
                          )}
                          helperText={touched?.employeeId && errors?.employeeId}
                        />
                      )}
                      value={employeeData?.find(
                        (t) => t.id === values.employeeId
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      label="Intern Name"
                      name="internName"
                      autoComplete="off"
                      disabled
                      defaultValue={`${internData?.firstName} ${internData?.lastName}`}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      label="College"
                      name="college"
                      autoComplete="off"
                      disabled
                      defaultValue={
                        internData?.college?.collegeName
                          ? internData?.college.collegeName
                          : ""
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      label="Technology"
                      name="technology"
                      autoComplete="off"
                      disabled
                      defaultValue={
                        internData?.technology?.title
                          ? internData?.technology.title
                          : "-"
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} mt={2}>
                    <TextField
                      label="SSC Percentage"
                      name="sscPercentage"
                      autoComplete="off"
                      disabled
                      defaultValue={
                        internData?.sscPercentage
                          ? internData?.sscPercentage
                          : "-"
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} mt={2} mb={3}>
                    <TextField
                      label="HSC Percentage"
                      name="hscPercentage"
                      autoComplete="off"
                      disabled
                      defaultValue={
                        internData?.hscPercentage
                          ? internData?.hscPercentage
                          : "-"
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={6} mt={2} mb={3}>
                    <TextField
                      label={
                        internData?.education?.title
                          ? internData?.education?.title
                          : ""
                      }
                      name="hscPercentage"
                      autoComplete="off"
                      disabled
                      defaultValue={
                        internData?.graduationPercentage
                          ? internData?.graduationPercentage
                          : "-"
                      }
                      variant="outlined"
                      size="small"
                      fullWidth
                    />
                  </Grid>
                  {/* <Grid item xs={6} mt={2}>
									<TextField
										label="Project Type"
										name="projectType"
										autoComplete="off"
										disabled
										value={values.projectType}
										onChange={handleChange}
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 256 }}
										error={Boolean(touched.projectType && errors.projectType)}
										helperText={touched.projectType && errors.projectType}
										fullWidth
									/>
								</Grid>
								<Grid item xs={6} mt={2}>
									<TextField
										label="Project Name"
										name="projectName"
										autoComplete="off"
										disabled
										value={values.projectName}
										onChange={handleChange}
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 256 }}
										error={Boolean(touched.projectName && errors.projectName)}
										helperText={touched.projectName && errors.projectName}
										fullWidth
									/>
								</Grid>
								<Grid item xs={6} mt={2} mb={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Project Submission Date"
                      inputFormat="dd/MM/yyyy"
                      value={values?.submissionDate ? values?.submissionDate : ""}
                      onChange={(newValue) => {
                        setFieldValue(`submissionDate`, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            touched?.submissionDate &&
                              errors?.submissionDate
                          )}
                          helperText={
                            touched?.submissionDate &&
                            errors?.submissionDate
                          }
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
								</Grid>
								<Grid item xs={6} mt={2} mb={3}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={employeeData.data || []}
                    getOptionLabel={(option) => `${option.firstName} ${option.lastName}` || ""}
                    name="employee"
                    onChange={(event, newValue) => {
                      setFieldValue("superVisor", newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} autoComplete="off" label="Project Supervisor"
											error={Boolean(
												touched?.superVisor &&
													errors?.superVisor
											)}
											helperText={
												touched?.superVisor &&
												errors?.superVisor
											} />
                    )}
                    value={employeeData?.data?.find(
                      (t) => t.id === values.superVisor,
                    )}
                  />
                </Grid> */}
                </Grid>
              </Paper>
            </Container>
          </Card>

          <Stack
            style={{ paddingBottom: "0" }}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            pt={3}
            pb={3}
          >
            <Typography variant="h6" gutterBottom>
              1: Below Average / 2: Average / 3: Good
            </Typography>
          </Stack>
          <Card className="custom-grid">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={3}
            >
              <Scrollbar>
                <TableContainer
                  component={Paper}
                  sx={{ overflow: "hidden" }}
                  // className="radius-remove"
                  // style={{ lineHeight:"5px" }}
                >
                  <TableHead>
                    <TableRow sx={{ bgcolor: "#f4f6f8" }}>
                      <TableCell align="left" width="100%">
                        Rating
                      </TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right"></TableCell>
                    </TableRow>
                  </TableHead>
                  {/* <TableBody> */}
                  <TableRow>
                    <TableCell width="100%" align="left">
                      Technology:{" "}
                      {internData?.technology?.title
                        ? internData?.technology?.title
                        : ""}
                    </TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>

                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      bgcolor: "#f4f6f8",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      width="100%"
                      align="left"
                    >
                      <b>Technical Aspect Indicators</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", padding: "0px" }}
                    >
                      Below Average
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      Average
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", paddingRight: "30px" }}
                    >
                      Good
                    </TableCell>
                  </TableRow>
                  {!Array.isArray(values?.techquestions) ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan={3} align="center">
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : values?.techquestions?.length === 0 ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell className="No-Record-text" colSpan={3}>No Record(s) Found</TableCell>
                    </TableRow>
                  ) : (
                    values?.techquestions?.map((question, idx) =>
                      question.questionType == "Rating" &&
                      question.questionGroup == "technical" ? (
                        <>
                          <TableRow
                            sx={{
                              " td,th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              width="100%"
                              align="left"
                            >
                              {question?.questionTitle
                                ? question?.questionTitle
                                : ""}
                            </TableCell>
                            <FormControl
                              component="fieldset"
                              size="small"
                              error={true}
                            >
                              <RadioGroup
                                row
                                // name="questions.questionValue"
                                name={`techquestions[${idx}].questionValue`}
                                onBlur={handleBlur}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `techquestions[${idx}].questionValue`,
                                    newValue
                                  );
                                }}
                                style={{ width: "230%", paddingLeft: "30px" }}
                              >
                                <TableCell style={{ alignContent: "center" }}>
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio size="small" />}
                                    label=""
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    paddingLeft: "40px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="2"
                                    control={<Radio size="small" />}
                                    label=""
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{
                                    fontWeight: "bold",
                                    paddingLeft: "30px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="3"
                                    control={<Radio size="small" />}
                                    label=""
                                  />
                                </TableCell>
                              </RadioGroup>
                            </FormControl>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell></TableCell>

                            <TableCell colSpan={3}>
                              <FormHelperText
                                style={{
                                  margin: "5px 0 0 0",
                                  color: "red",
                                  textAlign: "center",
                                }}
                              >
                                {touched?.techquestions &&
                                  errors?.techquestions &&
                                  touched?.techquestions?.[idx]
                                    ?.questionValue &&
                                  errors?.techquestions?.[idx]?.questionValue}
                              </FormHelperText>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        ""
                      )
                    )
                  )}

                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      bgcolor: "#f4f6f8",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      width="100%"
                      align="left"
                    >
                      <b>Performance Indicators</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", padding: "0px" }}
                    >
                      Below Average
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      Average
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", paddingRight: "30px" }}
                    >
                      Good
                    </TableCell>
                  </TableRow>
                  {!Array.isArray(values?.perquestions) ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan={3} align="center">
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : values?.perquestions?.length === 0 ? (
                    <TableRow
                      sx={{
                        "&:last-child  td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell className="No-Record-text" colSpan={3}>No Record(s) Found</TableCell>
                    </TableRow>
                  ) : (
                    values?.perquestions?.map((question, idx) =>
                      question.questionType == "Rating" &&
                      question.questionGroup == "performance" ? (
                        <>
                          <TableRow
                            sx={{
                              "td,th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              width="100%"
                              align="left"
                            >
                              {question?.questionTitle
                                ? question?.questionTitle
                                : ""}
                            </TableCell>
                            <RadioGroup
                              row
                              name={`perquestions[${idx}].questionValue`}
                              onChange={(event, newValue) => {
                                // handleAnswerChange(event, newValue, question);
                                setFieldValue(
                                  `perquestions[${idx}].questionValue`,
                                  newValue
                                );
                              }}
                              style={{ width: "250%", paddingLeft: "30px" }}
                            >
                              <TableCell style={{ alignContent: "center" }}>
                                <FormControlLabel
                                  value="1"
                                  control={<Radio size="small" />}
                                  label=""
                                />
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", paddingLeft: "40px" }}
                              >
                                <FormControlLabel
                                  value="2"
                                  control={<Radio size="small" />}
                                  label=""
                                />
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", paddingLeft: "30px" }}
                              >
                                <FormControlLabel
                                  value="3"
                                  control={<Radio size="small" />}
                                  label=""
                                />
                              </TableCell>
                            </RadioGroup>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell></TableCell>

                            <TableCell colSpan={3}>
                              <FormHelperText
                                style={{
                                  margin: "5px 0 0 0",
                                  color: "red",
                                  textAlign: "center",
                                }}
                              >
                                {touched?.perquestions &&
                                  errors?.perquestions &&
                                  touched?.perquestions?.[idx]?.questionValue &&
                                  errors?.perquestions?.[idx]?.questionValue}
                              </FormHelperText>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        ""
                      )
                    )
                  )}
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                      bgcolor: "#f4f6f8",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      width="100%"
                      align="left"
                    >
                      <b>Work Habit Indicators</b>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", padding: "0px" }}
                    >
                      Below Average
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{
                        fontWeight: "bold",
                        paddingRight: "10px",
                        paddingLeft: "10px",
                      }}
                    >
                      Average
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={{ fontWeight: "bold", paddingRight: "30px" }}
                    >
                      Good
                    </TableCell>
                  </TableRow>
                  {!Array.isArray(values?.workquestions) ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell colSpan={3} align="center">
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : values?.workquestions?.length === 0 ? (
                    <TableRow>
                      <TableCell align="center" colSpan={3}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    values?.workquestions?.map((question, idx) =>
                      question.questionType == "Rating" &&
                      question.questionGroup == "work_habit" ? (
                        <>
                          <TableRow
                            sx={{
                              "td,th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              width="100%"
                              align="left"
                            >
                              {question?.questionTitle
                                ? question?.questionTitle
                                : ""}
                            </TableCell>
                            <RadioGroup
                              row
                              name={`workquestions[${idx}].questionValue`}
                              onChange={(event, newValue) => {
                                // handleAnswerChange(event, newValue, question);
                                setFieldValue(
                                  `workquestions[${idx}].questionValue`,
                                  newValue
                                );
                              }}
                              style={{ width: "250%", paddingLeft: "30px" }}
                            >
                              <TableCell style={{ alignContent: "center" }}>
                                <FormControlLabel
                                  value="1"
                                  control={<Radio size="small" />}
                                  label=""
                                />
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", paddingLeft: "40px" }}
                              >
                                <FormControlLabel
                                  value="2"
                                  control={<Radio size="small" />}
                                  label=""
                                />
                              </TableCell>
                              <TableCell
                                sx={{ fontWeight: "bold", paddingLeft: "30px" }}
                              >
                                <FormControlLabel
                                  value="3"
                                  control={<Radio size="small" />}
                                  label=""
                                />
                              </TableCell>
                            </RadioGroup>
                          </TableRow>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell></TableCell>

                            <TableCell colSpan={3}>
                              <FormHelperText
                                style={{
                                  margin: "5px 0 0 0",
                                  color: "red",
                                  textAlign: "center",
                                }}
                              >
                                {touched?.workquestions &&
                                  errors?.workquestions &&
                                  touched?.workquestions?.[idx]
                                    ?.questionValue &&
                                  errors?.workquestions?.[idx]?.questionValue}
                              </FormHelperText>
                            </TableCell>
                          </TableRow>
                        </>
                      ) : (
                        ""
                      )
                    )
                  )}
                  {/* </TableBody> */}
                  {/* </Table> */}
                </TableContainer>
              </Scrollbar>
            </Stack>
          </Card>
          <Card className="custom-grid gray-box Employee_Detail_Content">
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="center"
              spacing={2}
              mb={2}
              mt={2}
              ml={1}
            >
              {!Array.isArray(values?.queText) ?
              <Loader/> :   
              values?.queText?.length !== 0
                ? values?.queText?.map((question, idx) =>
                    question.questionType == "Text" ? (
                      <>
                        <Grid
                          container
                          xs={12}
                          mb={0}
                          style={{ paddingRight: "10px", paddingLeft: "10px" }}
                        >
                          <Typography variant="body2" gutterBottom>
                            <b>{question.questionTitle} </b>
                          </Typography>
                        </Grid>
                        <Grid
                          container
                          xs={12}
                          style={{
                            paddingRight: "10px",
                            paddingLeft: "10px",
                            marginTop: "0px",
                          }}
                        >
                          <TextField
                            multiline
                            name={`queText[${idx}].questionValue`}
                            autoComplete="off"
                            value={values?.queText[idx].questionValue}
                            variant="outlined"
                            size="small"
                            fullWidth
                            minRows={2}
                            // onBlur={(event, newValue) => {
                            //   handleAnswerChange(event, newValue, question);
                            // }}
                            onChange={(event, newValue) => {
                              //   handleAnswerChange(event, newValue, question);
                              setFieldValue(
                                `queText[${idx}].questionValue`,
                                event.target.value
                              );
                            }}
                          />
                        </Grid>
                      </>
                    ) : (
                      ""
                    )
                  )
                : ""}
            </Stack>
          </Card>
          <Stack
            direction="row"
            alignItems="right"
            mt={2}
            justifyContent="flex-end"
          >
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </div>
  );
}

const componentConfig = {
  component: InternEvaluation,
  path: "/interns/evaluate/:id",
  public: false,
  layout: DashboardLayout,
  group: "interns",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
