import DashboardLayout from "../../../layouts/dashboard";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import "./mixStyles.css";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { Divider, Paper } from "@mui/material";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import invertDirection from "../../../utils/invertDirection";
import Scrollbar from "../../../components/Scrollbar";
import CustomPagination from "../../../components/Pagination";
import {
  getExperienceDetails,
  getExperienceDetailsForExcel,
  totalCountExperienceDetails,
} from "../../../redux/reportsEmployeeExperience/reportsEmployeeExperienceThunk";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as XLSX from "xlsx";
import { getEmployeeStatusWithDate } from "../../../redux/reportsJoiningRelieving/reportsJoiningRelievingThunk";
import moment from "moment";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Drawer from "@mui/material/Drawer";
import CachedIcon from "@mui/icons-material/Cached";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));

function JoiningRelievingReports() {
  const dispatch = useDispatch();
  const classes = useStyles();

  const [filterChips, setFilterChips] = useState([]);
  const [searchIdArray, setSearchIdArray] = useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const inputRef = React.useRef(null);
  const navigate = useNavigate();
  const [groupedData, setGroupedData] = useState([]);
  const [statusCount, setStatusCount] = useState({});
  const [state, setState] = React.useState({
    right: false,
  });
  const [sortOrder, setSortOrder] = useState("asc");
  const [activeColumn, setActiveColumn] = useState(null);
  const [dataForExport, setDataForExport] = useState([]);
  const firstRecordWithBorderRef = useRef(null);

  useEffect(() => {
    if (!getViewVisible("reports")) {
      navigate("/dashboard");
    }
    setFieldValue("startDate", moment().startOf("month").format("YYYY-MM-DD"));
    setFieldValue("endDate", moment().endOf("month").format("YYYY-MM-DD"));
  }, []);

  const handleSortByDate = () => {
    const sortedGroupedData = { ...groupedData };
    for (const key in sortedGroupedData) {
      sortOrder === "desc"
        ? sortedGroupedData[key].sort(
            (a, b) => new Date(a.date) - new Date(b.date)
          )
        : sortedGroupedData[key].sort(
            (a, b) => new Date(b.date) - new Date(a.date)
          );
    }
    setGroupedData(sortedGroupedData);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    setActiveColumn("date");
  };

  // function for toggle filter drawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const { data, loading, status } = useSelector(
    (state) => state.reportsJoiningRelieving
  );
  const {
    handleSubmit,
    values,
    resetForm,
    isSubmitting,
    setFieldValue,
    setValues,
    getFieldProps,
  } = useFormik({
    initialValues: {
      startDate: "",
      endDate: "",
      search: "",
    },
    onSubmit: async (values) => {
      let { search } = values;
      try {
        const data = await dispatch(getEmployeeStatusWithDate({ ...values }));
        const temp =
          data?.data?.map(({ id, ...rest }) => rest) ||
          data?.payload?.data?.map(({ id, ...rest }) => rest);
        setDataForExport(temp);
        if (data?.data?.length > 0 || data?.payload?.data?.length > 0) {
          getMonthsFromData(data?.data || data?.payload?.data);
        }
        prepareChips(values);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const getMonthsFromData = async (data) => {
    // const tempData = data?.map((res) => ({
    //   ...res,
    //   fullName: res?.firstName + " " + res?.lastName,
    // }));
    const grouped = data.reduce((result, item) => {
      const monthKey = item.month;
      if (!result[monthKey]) {
        result[monthKey] = [];
      }
      result[monthKey].push(item);
      return result;
    }, {});

    await setGroupedData(grouped);
    const statusCount = {
      joined: data.filter((item) => item.status === "joined").length,
      left: data.filter((item) => item.status === "left").length,
      goingToProduction: data.filter(
        (item) => item.status === "going to production"
      ).length,
      rejoined: data.filter((item) => item.status === "re-joined").length,
      totalCount: data.length,
    };
    await setStatusCount(statusCount);
  };

  useEffect(() => {
    const fetchEmployeeStatus = async () => {
      try {
        const data = await dispatch(getEmployeeStatusWithDate({ ...values })).unwrap();
        const temp = data?.data?.map(({ id, ...rest }) => rest) || data?.payload?.data?.map(({ id, ...rest }) => rest);
        setDataForExport(temp);
        prepareChips(values);
        
        if (data?.data?.length > 0) {
          getMonthsFromData(data?.data);
        } else {
          setStatusCount({
            joined: 0,
            left: 0,
            goingToProduction: 0,
            rejoined: 0,
            totalCount: 0,
          });
          setDataForExport([]);
        }
      } catch (error) {
        toast.error(error?.message);
      }
    };

    fetchEmployeeStatus();
  }, [status]);

  const handleSearch = async (e) => {
    await setFieldValue("search", e?.target?.value || "");
    if(values.search ===""){
      firstRecordWithBorderRef.current = "";
    }
    // await setSearchValue(e?.target?.value);
    if (e?.target?.value) {
      const searchData = { ...groupedData };
      prepareChips({ ...values, search: e?.target?.value });
      // console.log("SEARCHDATA",searchData)
      // console.log("MONTHDATA",monthData)
      const filteredData = Object.values(searchData).flatMap((monthData) =>
      monthData.filter((item) => {
        const fullName = item?.fullName?.toLowerCase().replace(/\s/g, '');
        const searchValue = e?.target?.value?.toLowerCase().replace(/\s/g, '');
        return fullName && searchValue && fullName.includes(searchValue);
      })
    );
    
      await checkForBorderDATA(e?.target?.value, filteredData);
      console.log('filtereddata',filteredData)
    } else {
      await setSearchIdArray([]);
      prepareChips({ ...values, search: "" });
    }
  };
  const checkForBorderDATA = async (search, searchData) => {
    if (search != "") {
      let temp = [];
      searchData?.map((res) => {
        temp.push(res?.id);
      });
      await setSearchIdArray(temp);
      if (temp.length > 0 && firstRecordWithBorderRef.current) {
        setTimeout(()=>{
          firstRecordWithBorderRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
        },500)
      }
    } else {
      await setSearchIdArray([]);
    }
  };
  // console.log("SearchIdArray",searchIdArray)
  const debounceWithSearch = debounce(handleSearch, 500);

  const prepareChips = (values) => {
    let filters = { ...values };
    // console.log('values: ', values);
    let tempChips = [];
    console.log("FILETERs", filters);
    for (const key in filters) {
      if (filters[key] && key === "startDate") {
        filters[key] = "From : " + moment(filters[key]).format("MM/YYYY");
      }
      if (filters[key] && key === "endDate") {
        filters[key] = "To : " + moment(filters[key]).format("MM/YYYY");
      }
      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
    // console.log('tempChips: ', tempChips);
  };

  const onDeleteChip = async (value, length) => {
   await setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    
    let filters = { ...values, [value["type"]]: "" };
    await setFieldValue(value.type, "");
    // console.log("FILETERECHIPS",filterChips)
    if (value.type === "search") {
      // await setSearchValue("");
     await setFieldValue("search","")
      inputRef.current.value = "";
    }
    if(value.type === "startDate"){
      await setFieldValue("startDate", null);
    }
    if(value.type === "endDate"){
      await setFieldValue("endDate", null);
    }
    const data = await dispatch(
      getEmployeeStatusWithDate({ ...filters })
    ).unwrap();
    if (data?.data?.length > 0 || data?.payload?.data?.length > 0) {
      getMonthsFromData(data?.data || data?.payload?.data);
    } else {
      setStatusCount({
        joined: 0,
        left: 0,
        goingToProduction: 0,
        rejoined: 0,
        totalCount: 0,
      });
      setDataForExport([]);
    }
  };
  // console.log("values", values);
  const handleReset = async () => {
    await setFieldValue("startDate", null);
    await setFieldValue("endDate", null);
    inputRef.current.value = "";
    // resetForm();

    const data = await dispatch(
      getEmployeeStatusWithDate({ startDate: "", endDate: "" })
    );
    const temp =
      data?.data?.map(({ id, ...rest }) => rest) ||
      data?.payload?.data?.map(({ id, ...rest }) => rest);
    setDataForExport(temp);

    if (data?.data?.length > 0 || data?.payload?.data?.length > 0) {
      getMonthsFromData(data?.data || data?.payload?.data);
    } else {
      setStatusCount({
        joined: 0,
        left: 0,
        goingToProduction: 0,
        rejoined: 0,
        totalCount: 0,
      });
      setDataForExport([]);
    }
    // setState({ ...state, right: false });
    setFilterChips([]);
  };

  const exportFile = async () => {
    try {
      const tempData = dataForExport.map((res)=>({
        ...res,
        date:moment(res.date).format("DD-MM-YYYY")
      }))
      // console.log("TEMPDATEFOREXCEL",tempData)
      const worksheet = XLSX.utils.json_to_sheet(tempData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "JoiningRelievingReport.xlsx");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Joining/Relieving Reports
          <Typography variant="body2">
            Total Records : {statusCount?.totalCount || 0}
          </Typography>
          <Stack direction="row" mt={3} spacing={3}>
            <Typography variant="body2">
              Joined : {statusCount?.joined || 0}
            </Typography>
            <Typography variant="body2">
              Left : {statusCount?.left || 0}
            </Typography>
            <Typography variant="body2">
              Going to production : {statusCount?.goingToProduction || 0}
            </Typography>
            <Typography variant="body2">
              Rejoined : {statusCount?.rejoined || 0}
            </Typography>

          </Stack>
        </Typography>
        </div>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          {/* 
            {AuthRole === "Super Admin" ? ( */}
          <>
            {/* {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <span
                  className="filter-icon-part"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <IconButton>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={filterChips.length !== 0 ? false : true}
                    >
                      <FilterAltIcon />
                    </Badge>
                  </IconButton>
                </span>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  style={{ zIndex: "1300" }}
                >
                  <Box
                    pt={4}
                    px={3}
                    sx={{
                      width:
                        anchor === "top" || anchor === "bottom" ? "auto" : 400,
                    }}
                    role="presentation"
                  >
                    <div className="content-filter-part">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <Typography variant="h6">Reports Filter</Typography>
                        <span>
                          <IconButton onClick={toggleDrawer(anchor, false)}>
                            <CloseIcon />
                          </IconButton>
                        </span>
                      </Stack>
                    </div>
                  </Box>
                </Drawer>
              </React.Fragment>
            ))} */}
            <Button
              variant="contained"
              onClick={() => exportFile()}
              startIcon={<FileUploadSharpIcon />}
              className="filter-icon-part"
            >
              Export
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/reports"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back
            </Button>
          </>
        </Stack>
      </Stack>
      <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={3} alignItems="center">
              <Grid
                item
                xs={6}
                my={3}
                style={{ marginBottom: "17px", paddingTop: "17px" }}
              >
                <TextField
                  fullWidth
                  label="Search by Name"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <LocalizationProvider dateAdapter={AdapterDateFns} item>
                  <Grid item>
                    <DesktopDatePicker
                      xs={4}
                      label="From"
                      style={{ width: "100px" }}
                      value={values?.startDate || null}
                      inputFormat="MM/yyyy"
                      name="startDate"
                      {...getFieldProps("startDate")}
                      // minDate={new Date("2017-01-01")}
                      minDate={moment(values.endDate)
                        .subtract(12, "months")
                        .format("YYYY-MM-DD")}
                      maxDate={moment(values.endDate)}
                      views={["year", "month"]}
                      onChange={(newValue) => {
                        setFieldValue(
                          "startDate",
                          moment(newValue).startOf("month").format("YYYY-MM-DD")
                        );
                        setFieldValue("search","")
                        inputRef.current.value = "";
                        prepareChips({ ...values, search: "" });

                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={false}
                          size="small"
                          style={{ width: "45%", marginRight: "10px" }}
                          inputProps={{
                            ...params.inputProps,
                            disabled: true, // Add the disabled attribute to the input element
                          }}
                          classes={{
                            root: classes.root,
                          }}
                        />
                      )}
                    />
                    <DesktopDatePicker
                      xs={4}
                      label="To"
                      value={values.endDate || null}
                      inputFormat="MM/yyyy"
                      minDate={moment(values.startDate)}
                      name="endDate"
                      views={["year", "month"]}
                      {...getFieldProps("endDate")}
                      maxDate={moment(values.startDate)
                        .add(12, "months")
                        .format("YYYY-MM-DD")}
                      onChange={(newValue)=>{
                        setFieldValue(
                          "endDate",
                          moment(newValue).endOf("month").format("YYYY-MM-DD")
                        );
                        setFieldValue("search","")     
                       inputRef.current.value = "";
                       prepareChips({ ...values, search: "" });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={false}
                          size="small"
                          style={{ width: "50%" }}
                          inputProps={{
                            ...params.inputProps,
                            disabled: true, // Add the disabled attribute to the input element
                          }}
                          classes={{
                            root: classes.root,
                          }}
                        />
                      )}
                    />
                  </Grid>
                </LocalizationProvider>
                <Button
                  variant="contained"
                  startIcon={<SearchIcon />}
                  type="submit"
                  onClick={handleSubmit}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  type="reset"
                  onClick={() => handleReset()}
                  startIcon={<CachedIcon />}
                  sx={{ marginLeft: "10px" }}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "10px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {filterChips.length !== 0 ? (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {(inputRef.current.value!="" ) ? (searchIdArray.length===0? 0 :searchIdArray.length ):(statusCount?.totalCount || "No")} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Card>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Month-Year</TableCell>
                    <TableCell>Full name</TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={activeColumn === "date"}
                        direction={sortOrder}
                        onClick={handleSortByDate}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    {/* <TableCell>Month</TableCell> */}
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : (Array.isArray(data) && data?.length === 0) || (searchIdArray?.length===0 && values?.search!="") ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={3}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    Object.entries(groupedData).map(
                      ([month, rows], monthIndex) => (
                        <React.Fragment key={monthIndex}>
                          <TableRow
                            style={
                              monthIndex > 0
                                ? { borderTop: "2px solid grey" }
                                : {}
                            }
                          >
                            <TableCell
                              rowSpan={rows.length}
                              component="th"
                              scope="row"
                            >
                              {month}
                            </TableCell>

                            <TableCell
                              className={
                                searchIdArray?.includes(rows[0]?.id)
                                  ? "row-border-left"
                                  : ""
                              }
                            >
                              {/* {rows[0]?.firstName + " " + rows[0]?.lastName ||
                                ""} */}
                              {rows[0]?.fullName || ""}
                            </TableCell>
                            <TableCell
                              className={
                                searchIdArray?.includes(rows[0]?.id)
                                  ? "row-border-middle"
                                  : ""
                              }
                            >
                              {moment(rows[0]?.date).format("DD-MM-YYYY") || ""}
                            </TableCell>
                            {/* <TableCell
                              className={
                                searchIdArray?.includes(rows[0]?.id)
                                  ? "row-border-middle"
                                  : ""
                              }
                            >
                              {rows[0]?.month || ""}
                            </TableCell> */}
                            <TableCell
                              className={
                                searchIdArray?.includes(rows[0]?.id)
                                  ? "row-border-right"
                                  : ""
                              }
                            >
                              {rows[0]?.status || ""}
                            </TableCell>
                          </TableRow>
                          {rows.slice(1).map((record, idx) => (
                            <TableRow key={idx}>
                              <TableCell
                                className={
                                  searchIdArray?.includes(record?.id)
                                    ? "row-border-left"
                                    : ""
                                }
                                ref={(ref) => {
                                  // Assign the ref for the first record with a border
                                  if (searchIdArray.includes(record?.id) && !firstRecordWithBorderRef.current && searchIdArray[0]) {
                                    firstRecordWithBorderRef.current = ref;
                                  }
                                }}
                              >
                                {/* {record?.firstName + " " + record?.lastName ||
                                  ""} */}
                                {record?.fullName || ""}
                              </TableCell>
                              <TableCell
                                className={
                                  searchIdArray?.includes(record?.id)
                                    ? "row-border-middle"
                                    : ""
                                }
                                ref={(ref) => {
                                  // Assign the ref for the first record with a border
                                  if (searchIdArray.includes(record?.id) && !firstRecordWithBorderRef.current && searchIdArray[0]) {
                                    firstRecordWithBorderRef.current = ref;
                                  }
                                }}
                              >
                                {moment(record?.date).format("DD-MM-YYYY") ||
                                  ""}
                              </TableCell>
                              {/* <TableCell
                                className={
                                  searchIdArray?.includes(record?.id)
                                    ? "row-border-middle"
                                    : ""
                                }
                              >
                                {record?.month || ""}
                              </TableCell> */}
                              <TableCell
                                className={
                                  searchIdArray?.includes(record?.id)
                                    ? "row-border-right"
                                    : ""
                                }
                                ref={(ref) => {
                                  // Assign the ref for the first record with a border
                                  if (searchIdArray.includes(record?.id) && !firstRecordWithBorderRef.current && searchIdArray[0]) {
                                    firstRecordWithBorderRef.current = ref;
                                  }
                                }}
                              >
                                {record?.status || ""}
                              </TableCell>
                            </TableRow>
                          ))}
                        </React.Fragment>
                      )
                    )
                  )}
                </TableBody>
              </Table>
            </Card>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: JoiningRelievingReports,
  path: "reports/joining-relieving-report",
  public: false,
  layout: DashboardLayout,
  group: "reports",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
