import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Autocomplete from "@mui/material/Autocomplete";
import {
	getCandidateByStatus,
	getCandidateStatus,
} from "../../../../redux/candidateState/candidateStatethunk";
import { Box } from "@mui/system";
import moment from "moment";
import { getJobDes } from "../../../../redux/jobDescription/jobDesThunk";
import { getViewVisible } from "../../../../utils/userPermission";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// import { Step, StepLabel, Stepper } from "@mui/material";
export default function Activity(props) {
	const activityData = useSelector((state) => state.activity);
	const dispatch = useDispatch();
	const {
		values,
		getFieldProps,
		setFieldValue,
		errors,
		touched,
		// setErrors,
		handleTabChange,
	} = props;
	const statusData = useSelector((state) => state.candidateState.statusData);
	const stateData = useSelector((state) => state.candidateState.stateData);
	const [selectedId, setSelectedId] = useState("");
	const [candidateState, setCandidateState] = useState({});
	const [jobList, setJobList] = useState([]);
	const [JD, setJd] = useState('');
	const [error, setError] = useState({});
	const {
		data,
		totalRecords,
		currentPage,
		limit,
		status,
		loading,
		sortBy,
		orderBy,
	  } = useSelector((state) => state.jobDes);
	  useEffect(() => {
		let finalList = data.filter((element) => element.jobStatus !== 'CLOSED')
	
		const updatedList = finalList.map((val) => {
			const skillsString = val.skills.map((skil) => skil.title).join(', ');
			return {
				...val,
				title: `${val.title} (${skillsString})`,
			};
		});
		setJobList(updatedList);
	}, [data]);
	
	useEffect(() => {
		if (Array.isArray(statusData) && statusData.length <= 0)
			dispatch(getCandidateStatus({sortBy: '', orderBy: ''}));
	}, []);
	useEffect(() => {
		getViewVisible('jobDescription')&&dispatch(
		  getJobDes({
			sortBy,
			orderBy,
			jobStatus: '',
			seniority: '',
			employment: '',
			departmentId: '',
		  })
		);
	  }, []);

		const [defaultValueSet, setDefaultValueSet] = useState(false);
		const [defaultState, setDefaultState] = useState(false)
		useEffect(() => {
			// Set the default value only once when statusData is available and defaultValueSet is false
			if (statusData && statusData?.length > 0 && !defaultValueSet) {
				// Check if the current selected status exists in statusData
				const selectedStatus = statusData?.find(status => status?.id === values?.activity?.candidateStatusId);
				// If the selected status doesn't exist or is null, set it to 'Lead'
				if (!selectedStatus) {
					setFieldValue('activity.candidateStatusId', statusData?.find(status => status?.name === 'Lead')?.id || '');
				}
				// Set defaultValueSet to true to ensure this block runs only once
				setDefaultValueSet(true);
			}
			if (stateData && stateData?.length > 0 && !defaultState) {
				// Check if the current selected status exists in statusData
				const selectedState = stateData?.find(state => state?.id === values?.activity?.candidateStateId);
				// If the selected status doesn't exist or is null, set it to 'Lead'
				if (!selectedState) {
					setFieldValue('activity.candidateStateId', stateData?.find(state => state?.name == 'Applied')?.id || '');
				}
				// Set defaultValueSet to true to ensure this block runs only once
				setDefaultValueSet(true);
			}
		}, [statusData, stateData, values.activity?.candidateStatusId, setFieldValue, defaultValueSet, defaultState]);

	useEffect(() => {
		// setIsLoading(true);
		if (values.activity?.candidateStatusId ?? false) {
			dispatch(getCandidateByStatus(values.activity?.candidateStatusId))
				.unwrap()
				.then((res) => {
					// setCandidateState({});
				})
				.catch((err) => {
					// setCandidateState({});
				});
		}
	}, [dispatch, values.activity?.candidateStatusId]);
	useEffect(() => {
		// console.log(stateData,"000000",values.activity?.candidateStateId);
		if (values.activity?.candidateStateId ?? false) {
			  
			let state = stateData.find(
				(state) => state.id === values.activity?.candidateStateId,
			);
			setCandidateState(state);
		}else{
			setCandidateState({});
		}
	}, [stateData,values.activity?.candidateStateId]);

	useEffect(()=>{
		let finaldata=jobList.find((status) =>status.id == values.candidateJobs[0]?.jobId)
		setJd(finaldata);
	},[jobList])

	// useEffect(()=>{
	// 	const keys = Object.keys(errors);
	// 	if(keys.length===1&&keys[0]==='experience'&&errors.experience.length<1){
	// 	  setErrors({})
	// 	}
	//   },[errors])
	const handleJoiningDate = (newValue) => {
		
		if(new Date(newValue).setHours(0,0,0,0) < new Date().setHours(0,0,0,0))
		{
			setError({joiningDate:"Joining date can't be past date"})
		}
		else{
			setError({joiningDate:""})
		}
		setFieldValue(`joiningDate`, newValue);
	}
	return (
		<Box>
			<Card className="right-side-content-part">
				<Container maxWidth="xl">
					<div className="education">
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							my={0}
						>
							<Typography className="Employee_Detail-h6 " variant="h6" gutterBottom style={{marginTop:"0"}}>
								Activity
							</Typography>
						</Stack>

						<Grid className="gray-box Contact-Detail-content" container spacing={3}>
							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="Approach date"
										inputFormat="dd/MM/yyyy"
										value={values.activity?.approachDate}
										onChange={(newValue) => {
											setFieldValue("activity.approachDate", newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.activity?.approachDate &&
														errors?.activity?.approachDate,
												)}
												helperText={
													touched?.activity?.approachDate &&
													errors?.activity?.approachDate
												}
												fullWidth
												size="small"
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="Applied Date"
										inputFormat="dd/MM/yyyy"
										value={values.activity?.appliedDate}
										onChange={(newValue) => {
											setFieldValue("activity.appliedDate", newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.activity?.appliedDate &&
														errors?.activity?.appliedDate,
												)}
												helperText={
													touched?.activity?.appliedDate &&
													errors?.activity?.appliedDate
												}
												fullWidth
												size="small"
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={6}>
								<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
									<Autocomplete
										size="small"
										fullWidth
										popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										options={statusData || []}
										getOptionLabel={(option) => option?.name || ""}
										{...getFieldProps("activity.candidateStatusId")}
										onChange={(event, newValue) => {
											setFieldValue(
												"activity.candidateStatusId",
												newValue?.id || "",
											);
											// console.log(values.activity?.candidateStateId,"pddddd");
											setFieldValue("activity.candidateStateId", null);
											setCandidateState({});
											setSelectedId(newValue?.id ?? "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.activity?.candidateStatusId &&
														errors?.activity?.candidateStatusId,
												)}
												helperText={
													touched?.activity?.candidateStatusId &&
													errors?.activity?.candidateStatusId
												}
												label="Status"
											/>
										)}
										value={statusData?.find(
											(status) =>
												status.id === values.activity?.candidateStatusId,
										) || ""}
										defaultValue={statusData?.find(status => status?.id === values?.activity?.candidateStatusId) || null}

										error={true}
									/>
									{/* <InputLabel id="demo-simple-select-helper-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Status"
                  size="small"
                  {...getFieldProps("activity.candidateStatusId")}
                  value={values.activity?.candidateStatusId}
                >
                  {activityData.data &&
                    activityData.data.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select> */}
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
									<Autocomplete
										size="small"
										fullWidth
										popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										options={stateData || []}
										getOptionLabel={(option) => option?.name || ""}
										{...getFieldProps("activity.candidateStateId")}
										onChange={(event, newValue) => {
											// console.log(newValue,"newValue");
											if(newValue===null){
											setFieldValue(
												"activity.candidateStateId",
												null,
											);
											}else{
												setFieldValue(
													"activity.candidateStateId",
													newValue?.id || null,
												);
											}
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.activity?.candidateStateId &&
														errors?.activity?.candidateStateId,
												)}
												helperText={
													touched?.activity?.candidateStateId &&
													errors?.activity?.candidateStateId
												}
												label="State"
											/>
										)}
										// value={stateData.find(
										// 	(status) =>
										// 		status.id === values.activity?.candidateStateId,
										// )}
										value={candidateState}
										defaultValue={stateData?.find(state => state?.id === values?.activity?.candidateStateId) || null}

										error={true}
									/>

									{/* <InputLabel id="demo-simple-select-helper-label">
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Status"
                  size="small"
                  {...getFieldProps("activity.candidateStatusId")}
                  value={values.activity?.candidateStatusId}
                >
                  {activityData.data &&
                    activityData.data.map((item) => {
                      return (
                        <MenuItem key={item.id} value={item.id}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                </Select> */}
								</FormControl>
							</Grid>
							{/* {activityData.data
              .find(
                (activity) =>
                  parseInt(activity.id) ===
                  parseInt(values.activity?.candidateStatusId)
              )
              ?.name?.toLowerCase() === "future" && ( */}
							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="Next followup date"
										inputFormat="dd/MM/yyyy"
										mask="__/__/____"
										minDate={new Date()}
										value={values.activity?.futureDate}
										name={`activity.futureDate`}
										onChange={(newValue) => {
											setFieldValue(`activity.futureDate`, newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												size="small"
												error={Boolean(
													touched?.activity?.futureDate &&
														errors?.activity?.futureDate,
												)}
												helperText={
													touched?.activity?.futureDate &&
													errors?.activity?.futureDate
												}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							{/* )} */}

							
						{/* {jobList[0]?	 */}
						<Grid item xs={6}>
							{/* {console.log(jobList.find((status) =>status.id == 25),"pppp",jobList)} */}
								<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
									<Autocomplete
										size="small"
										fullWidth
										popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										disabled={!getViewVisible('jobDescription')}
										options={jobList || []}
										{...getFieldProps("jobId")}
										getOptionLabel={(option) => option.title || ""}
										// defaultValue={jobList.find((status) =>status.id == 28)}
										// value={jobList.find((status) =>status.id == values.candidateJobs[0]?.jobId)}
										value={JD}
										// value={{ title: 'Hr', id: 28 }}
										onChange={(event, newValue) => {
											setFieldValue(
												"jobId",
												newValue?.id || null,
											);
											setJd(newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.jobId &&
														errors?.jobId,
												)}
												helperText={
													touched?.jobId &&
													errors?.jobId
												}
												label="Available JD"
											/>
										)}
										
										// error={true}
									/>
									
								</FormControl>
							</Grid>
							
							{values.activity?.candidateStateId && candidateState?.name == "Offer Accepted" ||
							values.activity?.candidateStateId && candidateState?.name == "Employee" ? 
								<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="Joining Date"
										inputFormat="dd/MM/yyyy"
										mask="__/__/____"
										minDate={new Date()}
										value={values.joiningDate ? values.joiningDate : null}
										name={`joiningDate`}
										onChange={(newValue) => {
											// handleJoiningDate(newValue)
											setFieldValue(`joiningDate`, newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												size="small"
												error={Boolean(
													touched?.joiningDate &&
														error?.joiningDate,
												)}
												helperText={
													touched?.joiningDate &&
													error?.joiningDate
												}
												// error={Boolean(
												// 	touched?.joiningDate && errors?.joiningDate,
												// )}
												// helperText={touched?.joiningDate && errors?.joiningDate}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>

							: "" }
							
							{/* :''} */}
							<Grid item xs={6}>
								{/* <FormControl
									fullWidth
									 error={Boolean(
										touched?.activity?.note && errors?.activity?.note,
									)} 
								> */}
								<TextField
									fullWidth
									multiline
									aria-label="Note..."
									minRows={4}
									// placeholder="Minimum 3 rows"
									/* className="custom-textarea" */
									inputProps={{
										inputComponent: TextareaAutosize,
										maxLength: 512,
										style: {
											resize: "auto",
										},
									}}
									{...getFieldProps("activity.note")}
									error={Boolean(
										touched?.activity?.note && errors?.activity?.note,
									)}
									helperText={touched?.activity?.note && errors?.activity?.note}
								/>
								{/* <FormHelperText style={{ margin: "5px 0 0 0" }}>
										{touched?.activity?.note && errors?.activity?.note}
									</FormHelperText> */}
								{/* </FormControl> */}
							</Grid>
						</Grid>

						<Stack
							direction="row"
							alignItems="center"
							justifyContent="end"
							mb={3}
							mt={4}
						>
							<Button onClick={() => handleTabChange("3")} variant="contained" className="back-button" startIcon={<ArrowBackIcon />}>
								Back
							</Button>
						</Stack>
					</div>
				</Container>
			</Card>
			{/* <Stack className="white-area" sx={{ mt: 3 }}>
        <Stack
          className="section-title"
          direction="row"
          alignItems="center"
          color="#000"
          sx={{
            background: "#F0F0F0",
            fontSize: 14,
            fontWeight: 500,
            gridGap: 10,
          }}
          py={1.5}
          px={2.5}
        >
          <img
            src="/assets/images/history-icon.svg"
            alt="history-icon"
            width="16px"
          />
          History
        </Stack>
        <Stack className="area-content" py={3} px={2.5}>
          {values?.candidateactivitylogs?.map((log, index) => (
            <Stack key={index} className="history-row active" direction="row">
              <Stack className="left" pb={3.75}>
                {log.approachDate
                  ? moment(log.approachDate).format("DD MMM YYYY")
                  : "-"}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                className="right"
                spacing={2}
                pl={3}
                pb={3.75}
              >
                {log.candidateState && (
                  <p>
                    <strong>{log.candidateState.name}</strong>
                  </p>
                )}
              </Stack>
            </Stack>
          ))}
        </Stack>
      </Stack> */}
		</Box>
	);
}
