import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const createEvalution = createAsyncThunk(
	//action type string
	"evaluation/createEvalution",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/createEvalution", params, {
			headers:{
				type:'create',module:'evaluations'
			}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const getAllEvalution = createAsyncThunk(
	//action type string
	"evaluation/getAllEvalution",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/getAllEvalution", params, {
			headers:{
				type:'view',module:'evaluations'
			}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
export const evaluationById = createAsyncThunk(
	//action type string
	"evaluation/getEvalutionByIdForHrms",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.get("/getEvalutionByIdForHrms",{
			headers:{
				evalution_id:id,type:'view',module:'evaluations'
			}
			});
			// console.log("EDITED_RES__",res.data)
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const updateEvaluation = createAsyncThunk(
	//action type string
	"evaluation/updateEvalution",
	// callback function
	async (body,{ rejectWithValue }) => {
		try {
			// console.log("_____body____",body)
			const res = await Axios.put("/updateEvalution", body?.editEvaluationData, {
			headers:{
				evalution_id:body?.evaluation_id,type:'update',module:'evaluations'
			}
			});
			// console.log("EDITED_RES__",res.data)
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
export const deleteEvalutionById = createAsyncThunk(
	//action type string
	"evaluation/deleteEvalutionById",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.delete("/deleteEvalutionById", {
			headers:{
				evalution_id:id,type:'delete',module:'evaluations'
			}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
export const getEvalutionCount = createAsyncThunk(
	//action type string
	"evaluation/getEvalutionCount",
	// callback function
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/getEvalutionCount", {
			headers:{
				type:'view',module:'evaluations'
			}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);


// export const addQuestion = createAsyncThunk(
// 	//action type string
// 	"question/addQuestion",
// 	// callback function
// 	async (body, { rejectWithValue }) => {
// 		try {
// 			const res = await Axios.post("/question", body,{
// 				headers:{
// 					type:'create',module:'questions'
// 				}
// 			});
// 			toast.success(res.data.message);
// 			return res.data;
// 		} catch (error) {
// 			return rejectWithValue(error?.response?.data || error.message);
// 		}
// 	},
// );
// export const deleteQuestionById = createAsyncThunk(
// 	//action type string
// 	"question/deleteQuestionById",
// 	// callback function
// 	async (id, { rejectWithValue }) => {
// 		try {
// 			const res = await Axios.delete("/question", { headers: { question_id:id,type:'delete',module:'questions' } });
// 			toast.success(res.data.message);
// 			return res.data;
// 		} catch (error) {
// 			return rejectWithValue(error?.response?.data || error.message);
// 		}
// 	},
// );

// export const updateQuestionById = createAsyncThunk(
// 	//action type string
// 	"question/updateQuestionById",
// 	// callback function
// 	async (body, { rejectWithValue }) => {
// 		try {
// 			const res = await Axios.put("/question", body, {
// 				headers: { question_id: body.question_id, type:'update',module:'questions' },
// 			});
// 			toast.success(res.data.message);
// 			return res.data;
// 		} catch (error) {
// 			return rejectWithValue(error?.response?.data || error.message);
// 		}
// 	},
// );

// export const getAllSections = createAsyncThunk(
// 	//action type string
// 	"question/getAllSections",
// 	// callback function
// 	async (id, { rejectWithValue }) => {
// 		try {
// 			const res = await Axios.get(`getAllSections/`, { headers: {type:'view',module:'questions' } });
// 			return res.data.data;
// 		} catch (error) {
// 			return rejectWithValue(error.response.data);
// 		}
// 	},
// );


// export const getAllQuestionSectionWise = createAsyncThunk(
// 	//action type string
// 	"question/getAllQuestionSectionWise",
// 	// callback function
// 	async ( { rejectWithValue }) => {
// 		try {
// 			const res = await Axios.get('/getAllQuestionSectionWise',{ headers: {type:'view',module:'questions' } });
// 			console.log("RES",res)
// 			return res.data.data;
// 		} catch (error) {
// 			return rejectWithValue(error.response.data);
// 		}
// 	},
// );

// export const getTotalQuestion = createAsyncThunk(
// 	"question/getTotalQuestion",
// 	async ({ rejectWithValue }) => {
// 		try {
// 			const res = await Axios.get("/getQuestionCount",{
//         headers: { type: 'view',module:'questions' },
//       });

// 			return res.data;
// 		} catch (error) {
// 			return rejectWithValue(error.response.data.message || error.message);
// 		}
// 	},
// );