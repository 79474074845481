import React,{useEffect, useState} from "react";
import DashboardLayout from "../../../layouts/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import { useFormik } from "formik";
// import { isMobile } from 'react-device-detect';
import LoadingButton from "@mui/lab/LoadingButton";
import { useConfirm } from "material-ui-confirm";

import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import Autocomplete from "@mui/material/Autocomplete";
import { 
  getEvalutionByDesignation, 
  getEvaluationById, 
  submitEvalutionForm, 
  getTeamForEvaluation
} from "../../../redux/employeeEvaluation/employeeEvaluationThunk";
import { toast } from "react-toastify";
import { Box, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, Rating } from '@mui/material';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { styled } from '@mui/system';
import Loader from "../../../components/Loader";
import EmployeeEvaluationSchema from "../../../validations/employeeEvaluationSchema";

const StyledCheckbox = styled(Checkbox)`
  .MuiSvgIcon-root {
    fill: ${(props) => (props.error ? 'red' : '#fda92d')};
  }
  .Mui-checked {
    color: ${(props) => (props.error ? 'red' : '#fda92d')} !important;
  }
  .MuiFormControlLabel-label {
    color: ${(props) => (props.error ? 'red' : '#fda92d')};
  }
  .MuiFormControl-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) =>
      props?.error ? 'red' : '#fda92d'} !important;
  }
`;

function AddEmployeeEvaluation() {
  const[loading, setLoading] = useState(false);
  const confirm = useConfirm();
	const dispatch = useDispatch();
  const navigate = useNavigate();
	const [questionData, setQuestionData] = useState({})
	const [formData, setFormData] = useState(null);
	const [totalAverage, setTotalAverage] = useState(0);
  const [submitId, setSubmitId] = useState("");
  const [ submittedForData, setSubmittedForData] = useState([])
	const [sectionAverage, setSectionAverage] = useState(
    []
  );
	const allEmployeeData = useSelector(
    (state) => state.employee.allEmployeeData
  );

	const evaluationDataByDesignation = useSelector(
    (state) => state.employeeEvaluation.evaluationDataByDesignation
  );

	const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

const formik = useFormik({
  initialValues: {
      startDate: null,
			endDate: null,
			startYear: "",
			endYear: "",
			startMonth: "",
			endMonth: "",
			submittedBy: "",
			submittedFor: "",
			question: [],
      comments: '',
      trainingDevelopement: '',
      actionsAgreed: '',
      agreed: false,
  },
  // validationSchema: employeeEvalutionSchema(
  //   formData?.submitEvaluation?.isProbationEvaluation ? formData?.submitEvaluation?.isProbationEvaluation : false
  // ),
  validationSchema: EmployeeEvaluationSchema,
  onSubmit: async (values, { resetForm }) => {
    try {

    const submitObj = {
      submitEvalutionId: submitId,
      question: values?.question,
      trainingDevelopement: values?.trainingDevelopement,
      comments: values?.comments,
      actionsAgreedTo: values?.actionsAgreed,
      totalAverageRating: totalAverage || 0,
    };
    await confirm({
      description: `You want to Submit Evaluation?`,
    });
    await dispatch(submitEvalutionForm(submitObj)).unwrap();
    navigate('/employeeEvaluation');
    resetForm()
    } catch (error) {
      toast.error(error.message);
    }
  },
});
const {
  values,
		errors, 
		touched, 
		setFieldValue, 
		handleBlur,
		handleChange,
		getFieldProps,
    isSubmitting,
    handleSubmit,
} = formik;


    const handleSubmittedBy = async (id) => {
      if(id)
      {
        let res = await dispatch(getTeamForEvaluation({id}))
        .unwrap()
        .catch((err) => {
          // navigate("/candidate");
        });
        // console.log("response===>", res)
        setSubmittedForData(res?.data)
      }
     
    }

		useEffect(() => {
			dispatch(getEmployeeForDropDown({ status: "employee" }));
		}, [dispatch]);

		useEffect(() => {
      formData?.evalutionquestion?.map((section, index) =>
        setFieldValue(`question[${index}]`, {
          sectionName: section?.sectionName,
          // avarageRating: 0.0,
          // textField: 0,
          answers: [],
        })
      );
      formData?.evalutionquestion?.map((section, index) =>
        section?.questions?.map((question, index1) => {
          if (question?.questionmaster?.answerType == 'Text') {
            setFieldValue(`question[${index}].answers[${index1}]`, {
              questionId: question?.questionId,
              answer: '',
              answerType: question?.questionmaster?.answerType,
            });
          } else {
            setFieldValue(`question[${index}].answers[${index1}]`, {
              questionId: question?.questionId,
              answer: 0,
              answerType: question?.questionmaster?.answerType,
            });
          }
        })
      );
    }, [formData]);

		const getQuestion = () => {
			let data = {}
			data = {
				submittedBy: values.submittedBy,
				submittedFor: values.submittedFor,
        startDate: values.startDate,
        endDate: values.endDate,
				startYear: new Date(values.startDate).getFullYear(),
				endYear: new Date(values.endDate).getFullYear(),
				startMonth: monthNames[new Date(values.startDate).getMonth()],
				endMonth: monthNames[new Date(values.endDate).getMonth()],
			}
			const fetchQuestion = async (id) => {
				try{
					let data = await dispatch(getEvaluationById(id)).unwrap();
					setFormData(data?.data);
					setQuestionData(data?.data)
				}catch (error) {
					if (error.status === 404 && !error.success) {
						// toast.error(error.message);
						// navigation("/employee");
					}
				}
			}

			const fetchData = async () => {
				try {
					let record = await dispatch(getEvalutionByDesignation(data)).unwrap();
          setSubmitId(record?.id)
					if(record?.evaluationId){
						fetchQuestion(record?.evaluationId);
					}
				} catch (error) {
					if (error.status === 404 && !error.success) {
						// toast.error(error.message);
						// navigation("/employee");
					}
				} finally {
					// setLoading(false);
				}
			};
	
			fetchData();
		}

		const handleAgreed = (event) => {
			// console.log("Values", values);
			// console.log("event.target.value", event.target.checked);
			setFieldValue(`agreed`, event.target.checked);
		};
		const handleratingChange = (
			event,
			question,
			index,
			index1
		) => {
			// console.log("EVENT",event.target.value)
			setFieldValue(
				`question[${index}].answers[${index1}].answer`,
				event.target.value
			);
			// let rating=0
			// sectionAverage[index]?.answers[index1]?.answer = event.target.value;
			setSectionAverage(sectionAverage);
		};

		const handleaverageRatingShow = (section) => {
			// debugger;
			// console.log("SECTION________",section,values?.question)
			for (let i = 0; i < values?.question?.length; i++) {
				if (values?.question[i]?.sectionName == section?.sectionName) {
					if (values?.question[i]?.textField == section?.questions?.length) {
						// console.log("SECTION________",section,values?.question,true)
						return false;
					} else {
						return true;
					}
				}
			}
		};

    useEffect(() => {
      let rating = 0;
      let totalRating = 0;
      let minusAverage = 0;
      let TextField = 0;
      values?.question?.map((item) => {
        item?.answers?.map((ans) => {
          if (ans?.answerType == 'Rating') {
            rating += parseInt(ans?.answer);
            // console.log("RATING",rating)
          } else {
            TextField += 1;
          }
        });
        item.avarageRating = rating / (item?.answers?.length - TextField);
        item.textField = TextField;
        // console.log(item.avarageRating,rating/item?.answers?.length)
        rating = 0;
        TextField = 0;
      });
      setSectionAverage(values?.question);
      values?.question?.map((item) => {
        if (item?.answers?.length == item?.textField) {
          item.avarageRating = 0;
          minusAverage += 1;
        } else {
          if (item?.answers?.length == 0) {
            minusAverage += 1;
            // totalRating+=0
          } else {
            totalRating += item?.avarageRating;
          }
        }
      });
      totalRating = totalRating / (values?.question?.length - minusAverage);
      setTotalAverage(totalRating);
    }, [values?.question]);

	return (
		<Container maxWidth="xl">
			<Card>
				<Container className="Add-Evaluation-container" maxWidth="xl">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
            className="Add-Evaluation-mainheading"
					>
            <div className="Add-Evaluation-title title-button-wrapper">
						<Typography variant="h4" gutterBottom>
							Add Evaluation
						</Typography>
            <Button
              className="back-button"
              component={RouterLink}
              to="/employeeEvaluation"
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            </div>
					</Stack>
					<Paper>
          <form
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
							className="custom-space-layout"
						>
							<Grid className="employeeEvaluation-grid-layout gray-box Employee_Detail_Content" container spacing={3}>
								<Grid className="grid-item" item xs={6}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											label="Start Date"
											value={values.startDate}
											inputFormat="dd/MM/yyyy"
											// minDate={new Date('2017-01-01')}
											onChange={(newValue) => {
											  setFieldValue("startDate", newValue);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.startDate &&
															errors?.startDate
													)}
													helperText={
														touched?.startDate &&
														errors?.startDate
													}
													fullWidth
													size="small"
												/>
											)}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											label="End Date"
											value={values.endDate}
											inputFormat="dd/MM/yyyy"
											// minDate={new Date('2017-01-01')}
											onChange={(newValue) => {
											  setFieldValue("endDate", newValue);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.endDate &&
															errors?.endDate
													)}
													helperText={
														touched?.endDate &&
														errors?.endDate
													}
													fullWidth
													size="small"
												/>
											)}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										defaultValue=""
										fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										options={allEmployeeData || []}
										getOptionLabel={(option) =>
											option ? `${option?.firstName} ${option?.lastName}` : ""
										}

										renderOption={(props, option) => (
											<li {...props} value={option.id} key={option.id}>
												{option.firstName + " " + option.lastName || ""}
											</li>
										)}
										{...getFieldProps(`submittedBy`)}
										onChange={(event, newValue) => {
											setFieldValue(`submittedBy`, newValue?.id || "");
                      handleSubmittedBy(newValue?.id)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Submitted By"
												error={
													touched?.submittedBy &&
													errors?.submittedBy 
												}
												helperText={
													touched?.submittedBy &&
													errors?.submittedBy 
												}
											/>
										)}
										value={
											allEmployeeData.find(
												(e) => e.id == values.submittedBy
											) || ""
										}
									/>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										defaultValue=""
										fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										options={submittedForData || []}
										getOptionLabel={(option) =>
											option ? `${option?.employeeId}-${option?.firstName} ${option?.lastName}` : ""
										}

										renderOption={(props, option) => (
											<li {...props} value={option.id} key={option.id}>
												{option.employeeId + "-" + option.firstName + " " + option.lastName || ""}
											</li>
										)}
										{...getFieldProps(`submittedFor`)}
										onChange={(event, newValue) => {
											setFieldValue(`submittedFor`, newValue?.id || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Submitted For"
                        error={
													touched?.submittedFor &&
													errors?.submittedFor 
												}
												helperText={
													touched?.submittedFor &&
													errors?.submittedFor 
												}
											/>
										)}
										value={
											submittedForData?.find(
												(e) => e.id == values.submittedFor
											) || ""
										}
									/>
								</Grid>
								<Grid item xs={4}>
									<Button 
									variant="contained" 
									onClick={()=> getQuestion()}
									>
										Get Questions
									</Button>
								</Grid>
							</Grid>
               {loading ? (
                <Loader />
              ) : (
              <>
							{formData?.evalutionquestion?.map((section, index) =>
                section?.questions?.length > 0 ? (
                  <React.Fragment key={index}>
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      style={{
                        marginTop: '10px',
                        marginBottom: '10px',
                        marginLeft: '-14px',
                      }}
                    >
                      <Grid
                        item
                        xs={4}
                        sm={8}
                        md={12}
                      >
                        <Typography
                          variant="h6"
                        >
                          Section:- {section?.sectionName}
                        </Typography>
                        <Typography
                          variant="body1"
                        >
                          {
                            handleaverageRatingShow(section)
                              ? sectionAverage[index]?.avarageRating > 0
                                ? 'Average Rating:- ' +
                                  sectionAverage[index]?.avarageRating?.toFixed(
                                    2
                                  )
                                : 'Average Rating:- 0.00 '
                              : ''
                          }
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={8}
                        md={12}
                        sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                      >
                        {section?.questions?.map(
                          (question, index1) => (
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                              key={index1}
                              sx={{ marginTop: '10px', marginBottom: '10px' }}
                            >
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={6}
                                sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                              >
                                <Typography
                                  variant="body1"
                                  sx={{ marginLeft: '10px' }}
                                >
                                  {index1 + 1}.{' '}
                                  {question?.questionmaster?.title}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={6}
                              >
                                {question?.questionmaster?.answerType ===
                                  'Text' && (
                                  <TextField
                                    fullWidth
                                    label="Answer"
                                    size="small"
                                    name={`question[${index}].answers[${index1}].answer`}
                                    value={
                                      values.question[index]?.answers[index1]
                                        ?.answer
                                    }
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    error={
                                      touched?.question?.[index]?.answers?.[
                                        index1
                                      ]?.answer &&
                                      Boolean(
                                        errors?.question?.[index]?.answers?.[
                                          index1
                                        ]?.answer
                                      )
                                    }
                                    helperText={
                                      touched?.question?.[index]?.answers?.[
                                        index1
                                      ]?.answer &&
                                      errors?.question?.[index]?.answers?.[
                                        index1
                                      ]?.answer
                                    }
                                    InputProps={{
                                      classes: {
                                        input: 'custom-input',
                                      },
                                    }}
                                  />
                                )}
                                {question?.questionmaster?.answerType ===
                                  'Rating' && (
                                  <div
                                  >
                                    <Rating
                                      name={`question[${index}].answers[${index1}].answer`}
                                      value={
                                        values.question[index]?.answers?.[
                                          index1
                                        ]?.answer
                                      }
                                      onChange={(e) =>
                                        handleratingChange(
                                          e,
                                          question,
                                          index,
                                          index1
                                        )
                                      }
                                      onBlur={handleBlur}
                                    />
                                    {touched?.question?.[index]?.answers?.[
                                      index1
                                    ]?.answer &&
                                      errors?.question?.[index]?.answers?.[
                                        index1
                                      ]?.answer && (
                                        <FormHelperText
                                          error
                                        >
                                          {
                                            errors?.question?.[index]
                                              ?.answers?.[index1]?.answer
                                          }
                                        </FormHelperText>
                                      )}
                                  </div>
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                    
                  </React.Fragment>
                ) : (
                  ''
                )
              )}
              {formData?.submitEvaluation?.isProbationEvaluation ? (
                <Grid
                  container
                  spacing={3}
                  style={{ paddingLeft: '20px', paddingRight: '20px' }}
                >
                  <Grid item xs={12} sx={{ marginBottom: '10px' }}>
                    <Divider sx={{ marginBottom: '5px', marginTop: '5px' }} />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      multiline
                      label="Comments"
                      minRows={4}
                      variant="outlined"
                      size="small"
                      name="comments"
                      inputProps={{
                        inputComponent: TextareaAutosize,
                        maxLength: 512,
                        style: {
                          //   resize: 'auto',
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.comments}
                      error={touched?.comments && Boolean(errors?.comments)}
                      helperText={touched?.comments && errors?.comments}
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      multiline
                      label="Training and/or Development Needs:"
                      minRows={4}
                      variant="outlined"
                      size="small"
                      name="trainingDevelopement"
                      inputProps={{
                        inputComponent: TextareaAutosize,
                        maxLength: 512,
                        style: {
                          //   resize: 'auto',
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.trainingDevelopement}
                      error={
                        touched?.trainingDevelopement &&
                        Boolean(errors?.trainingDevelopement)
                      }
                      helperText={
                        touched?.trainingDevelopement &&
                        errors?.trainingDevelopement
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                    <TextField
                      fullWidth
                      multiline
                      label="Actions Agreed"
                      minRows={4}
                      variant="outlined"
                      size="small"
                      name="actionsAgreed"
                      inputProps={{
                        inputComponent: TextareaAutosize,
                        maxLength: 512,
                        style: {
                          //   resize: 'auto',
                        },
                      }}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.actionsAgreed}
                      error={
                        touched?.actionsAgreed && Boolean(errors?.actionsAgreed)
                      }
                      helperText={
                        touched?.actionsAgreed && errors?.actionsAgreed
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sx={{ marginBottom: '20px' }}>
                    <FormControl error={errors?.agreed}>
                      <FormControlLabel
                        control={
                          <StyledCheckbox
                            name="agreed"
                            onChange={handleAgreed}
                            // error={touched?.agreed && errors?.agreed}
                          />
                        }
                        style={
                          touched?.agreed && errors?.agreed
                            ? { color: 'red' }
                            : {}
                        }
                        label="I agree with the comments detailed on this form and any timescales or targets have been mutually agreed. *"
                      />
                                         
                      {(touched?.agreed && errors?.agreed) && (
                        <FormHelperText>{errors?.agreed}</FormHelperText>
                      )} 
                     </FormControl>
                  </Grid>
                </Grid>
            
              ) : (
                ''
              )} 
              {Array.isArray(questionData?.evalutionquestion) && questionData?.evalutionquestion?.length > 0 ? 
              <div>
                <Button
                  variant="contained"
                  className="upload-btn"
                  style={{marginTop:"10px"}}
                  type="submit"
                >
                  Submit
                </Button>
              </div>: ""}
               </>
               )} 
						</form>
					</Paper>
					{/* <Stack direction="row" justifyContent="flex-end" mt={4} mb={5}>
						<Button variant="contained">Save</Button>
					</Stack> */}
          
          
				</Container>
			</Card>
		</Container>
	);
}

const componentConfig = [
  {
    component: AddEmployeeEvaluation,
    path: "/employeeEvaluation/evaluation/add",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddEmployeeEvaluation,
    path: "/employeeEvaluation/evaluation/:id",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
