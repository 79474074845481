// VehicleTable.js
import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  table: {
    width: "100%",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  cell: {
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderColor: "#000",
    padding: 3,
    flex: 1,
    fontSize: 8,
    textAlign: "center",
  },
  lastCell: {
    borderTopWidth: 1,
    borderColor: "#000",
    padding: 3,
    flex: 1,
    fontSize: 8,
    textAlign: "center",
  },
  headerCell: {
    borderRightWidth: 1,
    borderColor: "#000",
    padding: 3,
    flex: 1,
    fontSize: 8,
    textAlign: "center",
  },
  headerLastCell: {
    borderColor: "#000",
    padding: 3,
    flex: 1,
    fontSize: 8,
    textAlign: "center",
  },
  header: {
    backgroundColor: "#eee",
    fontWeight: "bold",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    marginBottom: 10,
  },
  srNoCell: {
    width: 30,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderColor: "#000",
    padding: 3,
    fontSize: 8,
    textAlign: "center",
  },
  nameCell: {
    width: 100,
    borderTopWidth: 1,
    borderRightWidth: 1,
    borderColor: "#000",
    padding: 3,
    fontSize: 8,
    textAlign: "center",
  },
  headerSrNoCell: {
    width: 30,
    borderRightWidth: 1,
    borderColor: "#000",
    padding: 3,
    fontSize: 8,
    textAlign: "center",
  },
  headerNameCell: {
    width: 100,
    borderRightWidth: 1,
    borderColor: "#000",
    padding: 3,
    fontSize: 8,
    textAlign: "center",
  },
});

const VehicleTable = ({ data }) => (
  <>
    {data && data?.length > 0 && (
      <>
        <Text style={styles.title}>Employee Vehicle Details</Text>
        <View style={styles.table}>
          <View style={[styles.row, styles.header]}>
            <Text style={styles.headerSrNoCell}>Sr No.</Text>
            <Text style={styles.headerNameCell}>Employee Name</Text>
            <Text style={styles.headerCell}>Vehicle Name</Text>
            <Text style={styles.headerCell}>Vehicle Number</Text>
            <Text style={styles.headerCell}>Vehicle Type</Text>
            <Text style={styles.headerCell}>Parking Number</Text>
            <Text style={styles.headerLastCell}>Parking Block</Text>
          </View>
          {data?.map((row, index) => (
            <View key={index} style={styles.row}>
              <Text style={styles.srNoCell}>{row?.srNo}</Text>
              <Text style={styles.nameCell}>{row?.name}</Text>
              <Text style={styles.cell}>{row?.vehicleName}</Text>
              <Text style={styles.cell}>{row?.vehicleNumber}</Text>
              <Text style={styles.cell}>{row?.vehicleType}</Text>
              <Text style={styles.cell}>{row?.parkingNumber}</Text>
              <Text style={styles.lastCell}>{row?.parkingBlock}</Text>
            </View>
          ))}
        </View>
      </>
    )}
  </>
);

export default VehicleTable;
