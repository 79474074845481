import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import { debounce, update } from "lodash";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "../../components/Scrollbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { encryption } from "../../utils/encodeString";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import CustomPagination from "../../components/Pagination";
import invertDirection from "../../utils/invertDirection";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";
import {
  setLimit,
  setCurrentPage,
  setOrderBy,
  setSortBy,
} from "../../redux/question/questionSlice";
import {
  getAllPoll,
  getoTotalPoll,
  updateStatus,
  deletePollById,
} from "../../redux/poll/pollThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

function Poll() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  // useEffect(() => {
  //   if (!getViewVisible("evaluation")) {
  //     dispatch(
  //       getAllQuestions({
  //         page: currentPage + 1,
  //         limit: limit,
  //         sortBy: sortBy,
  //         orderBy: orderBy,
  //         search: "",
  //       })
  //     );
  //     navigate("/dashboard");
  //   }
  // }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const {
    data,
    currentPage,
    status,
    limit,
    count,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
  } = useSelector((state) => state.poll);
  
  const pollCount = useSelector(
    (state) => state.pollCount.data
  );

  // useEffect(() => {
  //   dispatch(
  //     getAllQuestions({
  //       page: currentPage + 1,
  //       limit: limit,
  //       sortBy: sortBy,
  //       orderBy: orderBy,
  //       search: "",
  //     })
  //   );
  // }, [dispatch]);
  useEffect(() => {
    if (data?.length === 0 && currentPage !== 0) {
      dispatch(setCurrentPage({ page: currentPage - 1 }));
    }
  }, [data?.length]);

  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      getAllPoll({
        page: currentPage + 1,
        limit,
        sortBy,
        orderBy,
        search: "",
        status: "",
      })
    );
    // }
    // return () => {
    // 	dispatch(setStatus({ status: "idle" }));
    // };
  }, [dispatch, limit, currentPage, sortBy, orderBy]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ currentPage: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleDelete = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to delete?`,
      });
      await dispatch(deletePollById(id)).unwrap();
      dispatch(
        getAllPoll({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (!error) {
      dispatch(getoTotalPoll({}));
    }
  }, [error, data]);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    // setFieldValue("search", e?.target?.value || "");
    // handleSubmit();
    setSearchValue(e?.target?.value);
    if (e?.target?.value != "") {
      setIsSearching(true);
      await dispatch(
        getAllPoll({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: e?.target?.value,
          status: "",
        })
      );
    } else {
      setIsSearching(false);
      await dispatch(
        getAllPoll({
          limit,
          page: currentPage + 1,
          orderBy,
          sortBy,
          search: "",
        })
      );
    }
    setSearchValue(e?.target?.value);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const onDeleteChip = async () => {
    setSearchValue("");
    setIsSearching(false);
    inputRef.current.value = "";
    dispatch(
      getAllPoll({
        page: currentPage + 1,
        limit: limit,
        sortBy,
        orderBy,
        search: "",
      })
    );
  };

  const handleToggle = async (question) => {
    try {
      await dispatch(
        updateStatus({
          id: question.id,
          status: !question.status,
        })
      ).unwrap();
      dispatch(getAllPoll());
    } catch (error) {
      toast.error(error.message);
    }
  };

  console.log(data)

  return (
    <Container maxWidth="xl" className="item-component">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Polls
          <Typography variant="body2">
            Total Records : {pollCount?.count ? pollCount?.count : "0"}
          </Typography>
        </Typography>

        {getAddVisible("poll") && (
          <Button
            variant="contained"
            component={RouterLink}
            to="/poll/add"
            startIcon={<AddIcon />}
          >
            Add Poll
          </Button>
        )}
        </div>
      </Stack>

      <Card className="custom-grid">
        <Container style={{ maxWidth: "100%" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container xs={12} pt={2} pb={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search by Title"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearching ? (
          <Chip
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={() => onDeleteChip()}
          />
        ) : (
          ""
        )}
      </Stack>
      {isSearching ? (
        <Typography variant="body2" mb={1}>
          {count} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomPagination
              totalPage={totalPages}
              totalCount={count}
              limit={limit}
              handleChangePage={handleChangePage}
              page={currentPage}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              // filter={isSearchQuery ? true : false}
            />
            <Scrollbar>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 800 }}
                className="radius-remove"
                style={{ borderRadius: "0" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "questionText"}
                          direction={
                            sortBy === "questionText" ? orderBy : "asc"
                          }
                          onClick={() => handleSorting("questionText")}
                        >
                          Question Text
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        Answer Count
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "status"}
                          direction={sortBy === "status" ? orderBy : "asc"}
                          onClick={() => handleSorting("status")}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="center" style={{ width: "100px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {loading ? (
                    <TableCell align="center" width="100%" colSpan={7}>
                      <Loader />
                    </TableCell>
                  ) : (
                    <TableBody>
                      {data?.data?.length > 0 ? (
                        data?.data?.map((question, idx) => {
                          return (
                            <TableRow key={question.id}>
                              <TableCell align="center">
                                {question?.questionText || "-"}
                              </TableCell>
                              <TableCell align="center">
                                {question?.attempts || "-"}
                              </TableCell>
                              <TableCell align="left">
                                <Switch
                                  checked={question?.status}
                                  onChange={() => handleToggle(question)}
                                  inputProps={{ "aria-label": "controlled" }}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell align="left">
                                <Stack direction="row">
                                  {/* {getEditVisible("poll") && (
                                    <IconButton
                                      style={{ padding: "5px " }}
                                      component={RouterLink}
                                      to={`/poll/edit/${encryption(
                                        question.id
                                      )}`}
                                    >
                                      <EditIcon color="primary" />
                                    </IconButton>
                                  )} */}
                                  {getViewVisible("poll") && (
                                    <IconButton
                                      style={{ padding: "5px" }}
                                      component={RouterLink}
                                      to={`/poll/view/${encryption(question.id)}`}
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  )}
                                  {getDeleteVisible("poll") && (
                                    <span>
                                      <IconButton
                                        onClick={() =>
                                          handleDelete(question.id)
                                        }
                                      >
                                        <DeleteIcon color="error" />
                                      </IconButton>
                                    </span>
                                  )}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell className="No-Record-text" colSpan={12} align="left">
                            No Record(s) Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: Poll,
  path: "/poll",
  public: false,
  layout: DashboardLayout,
  group: "poll",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
