import * as Yup from 'yup';

const internEvaluationSchema = Yup.object().shape({
	evalDate: Yup.date()
		.required('Evaluation date is required')
		.typeError("Invalid date")
		.nullable(),
	evalFromDate: Yup.date()
		.required('From date is required')
		.typeError("Invalid date")
		.nullable(),
	// evalToDate: Yup.date()
	// 	.required('To date is required')
	// 	.typeError("Invalid date")
	// 	.when('evalToDate', )
	// 	.nullable(),
	evalToDate: Yup.date()
	.required('To date is required')
	.typeError("Invalid date")
	.nullable(),
	employeeId: Yup.string()
		.required('Evaluator is required')
		.nullable(),
		workquestions: Yup.array().of(
		Yup.object().shape({
			questionValue: Yup.string().required("This Field  is required").nullable()
		}
		  ),
		),
		techquestions: Yup.array().of(
			Yup.object().shape({
				questionValue: Yup.string().required("This Field  is required").nullable()
			}
			  ),
			),
			perquestions: Yup.array().of(
				Yup.object().shape({
					questionValue: Yup.string().required("This Field  is required").nullable()
				}
				  ),
				)
});

export default internEvaluationSchema;
