import DashboardLayout from "../../../layouts/dashboard";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import ClearIcon from "@material-ui/icons/Clear";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, FormControlLabel, FormHelperText, IconButton, Switch } from "@mui/material";
import { decryption, encryption } from "../../../utils/encodeString";
import {
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../../service/localStorage";
import { makeStyles } from "@material-ui/core/styles";
import faqsSchema from "../../../validations/faqsSchema";
import Loader from "../../../components/Loader";
import {
  addFAQ,
  getFAQById,
  updateFAQ,
} from "../../../redux/faqs/faqsThunk";
import { debounce } from "lodash";
import { CKEditor } from "ckeditor4-react";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fda92d",
        borderWidth: 2,
      },
      "&:hover fieldset": {
        borderColor: "#fda92d",
        borderWidth: 2,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#fda92d",
      },
    },
  },
  customOutline: {
    outline: "2px solid #fda92d", // Replace 'red' with your desired outline color
  },
}));


function AddFAQs() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  if (id) id = decryption(id);
  // console.log(id)
  let searchIndexArray = [];
  const [loading, setLoading] = useState(true);
  const inputRef = React.useRef(null);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  // const classes = useStyles();
  // const { selectedTags, placeholder, tags, ...other } = props;
  const [searchQuery, setSearchQuery] = useState("");
  const [editFAQsData, setEditFAQsData] = React.useState({});

  useEffect(() => {
    if (id === -1) {
      navigate("/faqs");
    }
  }, [dispatch, id]);

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
      isActive: false,
      faqs: [
        {
          question: "",
          answer: "",
          isActive: false,
        },
      ],
      search: "",
    },
    validationSchema: faqsSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // console.log("SUBMIT_VALUES_____",values)
        if (!id) {
          await dispatch(addFAQ(values)).unwrap();
        }

        if (id) {
          await dispatch(
            updateFAQ({ editFAQsData: values, faqCategoryId: id })
          ).unwrap();
        }
        navigate("/faqs");
        resetForm();
      } catch (error) {
        return toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    handleChange,
    setValues,
    touched,
    errors,
    handleBlur,
  } = formik;
  const [filteredFAQs, setFilteredFAQs] = useState([]);

  useEffect(() => {
    if (values?.isActive == false) {
      values?.faqs?.map((item, index) => {
        setFieldValue(`faqs[${index}].isActive`, false);
      });
    }
  }, [values?.isActive]);

  useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let record = await dispatch(getFAQById(id)).unwrap();
          // console.log("RECORD___",record)
          let body1 = {
            title: record?.data?.title,
            description: record?.data?.description,
            isActive: record?.data?.isActive,
            faqs: record?.data?.faqs,
          };
          setFilteredFAQs(body1?.faqs);
          setValues(body1);
          setLoading(false);
          setEditFAQsData(record?.data);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
          navigate(`/faqs`);
        }
      }
      setLoading(false);
    };
    fetchById();
  }, [dispatch, setValues, id, navigate]);

  const removeField = (e, index) => {
    if (id) {
      inputRef.current.value = "";
      searchIndexArray = [];
      setSearchQuery("");
    }
    e.preventDefault();
    const temp = [...values.faqs];
    temp.splice(index, 1);
    setFieldValue("faqs", temp);
  };

  // add fields dynamically on click of " + " button
  const addMore = (index, index1) => {
    if (id) {
      inputRef.current.value = "";
      searchIndexArray = [];
      setSearchQuery("");
    }
    setFieldValue("faqs", [
      ...values.faqs,
      {
        question: "",
        answer: "",
        isActive: false,
      },
    ]);
  };
  if (loading) {
    return <Loader />;
  }

  const handleSearchChange = async (event) => {
    const query = event?.target?.value || "";
    // const query = inputRef.current.value || "";
    // console.log("QUERY",query)
    // debugger;
    if (query == "") {
      setFilteredFAQs(values?.faqs);
      setSearchQuery(query);
    } else {
      setSearchQuery(query);
      const filtered = values?.faqs.filter((faq) => {
        const question = faq.question.toLowerCase();
        const answer = faq.answer.toLowerCase();
        return (
          question.includes(query.toLowerCase()) ||
          answer.includes(query.toLowerCase())
        );
      });
      // console.log("filtered_______====>",filtered)
      setFilteredFAQs(filtered);
      filtered?.map((faq) => {
        let index = values?.faqs?.findIndex((item) => item?.id == faq?.id);
        searchIndexArray.push(index);
        // console.log("searchIndexArray",searchIndexArray)
        // console.log("searchIndexArray?.includes(index)",searchIndexArray?.includes(index))
      });
    }
  };
  const debounceWithSearch = debounce(handleSearchChange, 500);
  const handleCheckIndex = (i) => {
    // debugger;
    // console.log("filteredFAQs",filteredFAQs)
    filteredFAQs?.map((faq) => {
      let index = values?.faqs?.findIndex((item) => item?.id == faq?.id);
      if (!searchIndexArray?.includes(index)) {
        searchIndexArray.push(index);
      }
      // console.log("searchIndexArray",searchIndexArray)
      // console.log("searchIndexArray?.includes(index)",searchIndexArray?.includes(index))
    });

    // console.log("searchIndexArray",searchIndexArray)
    // console.log("searchIndexArray?.includes(i)=====>",searchIndexArray?.includes(i))
    if (searchIndexArray?.includes(i)) {
      return true;
    } else return false;
  };
  // console.log("VALUES_______",values)

  const handleClearInput = () => {
    if (id) {
      inputRef.current.value = "";
      searchIndexArray = [];
      setSearchQuery("");
    }
    setSearchQuery("");
    setFilteredFAQs(values?.faqs);
  };
  // console.log(designationData.data)
  return (
    <Container className="Employee-wrapper Add-Faq-Main-Container" maxWidth="xl">
      <form
        autoComplete="off"
        noValidate
        className="custom-space-layout"
        onSubmit={handleSubmit}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="title-button-wrapper Add-Faq-Title">
            <Typography variant="h4" gutterBottom mb={3}>
              {id ? "Edit FAQs" : "Add FAQs"}
            </Typography>
            <Stack className="Add-faq-header-right" direction="row" alignItems="center">
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
              <Button
                className="back-button"
                variant="contained"
                component={RouterLink}
                to="/faqs"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Stack>
          </div>
        </Stack>
        <Card className="Add-faq-card-wrapper">
          <Container className="gray-box Employee_Detail_Content Addfaq_main_container" maxWidth="xl">
            <Grid
              container
              spacing={3}
              py={3}
              style={{ paddingBottom: "13px", paddingTop: "17px" }}
            >
              <Grid item xs={12}>
                <TextField
                  label="FAQs Title"
                  variant="outlined"
                  size="small"
                  multiline
                  inputProps={{
                    // inputComponent: TextareaAutosize,
                    maxLength: 512,
                    // style: {
                    // 	resize: "auto",
                    // },
                  }}
                  // value={values.title}
                  onChange={handleChange}
                  autoComplete="off"
                  {...getFieldProps("title")}
                  fullWidth
                  error={Boolean(touched?.title && errors?.title)}
                  helperText={touched?.title && errors?.title}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="FAQs Description"
                  variant="outlined"
                  size="small"
                  multiline
                  minRows={2}
                  maxRows={5}
                  inputProps={{
                    // inputComponent: TextareaAutosize,
                    maxLength: 512,
                    // style: {
                    // 	resize: "auto",
                    // },
                  }}
                  // value={values.title}
                  onChange={handleChange}
                  autoComplete="off"
                  {...getFieldProps("description")}
                  fullWidth
                  error={Boolean(touched?.description && errors?.description)}
                  helperText={touched?.description && errors?.description}
                />
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack className="checkbox-part">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          name="isActive"
                          onChange={handleChange}
                          inputProps={{
                            "aria-label": "controlled",
                            "data-cy": "chk-isActive-department",
                          }}
                        />
                      }
                      label="Status"
                    />
                  </Stack>
                </Stack>
              </Grid>

              {id ? (
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              ) : (
                ""
              )}
              {id ? (
                <Grid item xs={8}>
                  <form onSubmit={(e) => e.preventDefault()}>
                    <TextField
                      fullWidth
                      label="Search by Question/Answer"
                      name="search"
                      inputRef={inputRef}
                      // value={searchQuery}
                      variant="outlined"
                      size="small"
                      onChange={debounceWithSearch}
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            aria-label="Clear input"
                            onClick={handleClearInput}
                            edge="end"
                            size="small"
                            style={
                              !inputRef.current || !inputRef.current.value
                                ? { display: "none" }
                                : {}
                            }
                            disabled={
                              !inputRef.current || !inputRef.current.value
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </form>
                </Grid>
              ) : (
                ""
              )}
              {values?.faqs != 0 && (
                <Grid item xs={12}>
                  <Divider sx={{ my: 1 }} />
                </Grid>
              )}
            </Grid>
            {!id
              ? values?.faqs?.map((faq, index) => (
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    key={index}
                  >
                    {index != 0 && (
                      <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                      </Grid>
                    )}
                    <Grid item xs={8}>
                      <TextField
                        label="Question"
                        variant="outlined"
                        size="small"
                        name={`faqs[${index}].question`}
                        multiline
                        inputProps={{
                          // inputComponent: TextareaAutosize,
                          maxLength: 512,
                          // style: {
                          // 	resize: "auto",
                          // },
                        }}
                        // value={values.title}
                        onChange={handleChange}
                        autoComplete="off"
                        {...getFieldProps(`faqs[${index}].question`)}
                        fullWidth
                        error={Boolean(
                          touched?.faqs?.[index]?.question &&
                            errors?.faqs?.[index]?.question
                        )}
                        helperText={
                          touched?.faqs?.[index]?.question &&
                          errors?.faqs?.[index]?.question
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack className="checkbox-part">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.faqs[index]?.isActive}
                                name={`faqs[${index}].isActive`}
                                onChange={handleChange}
                                disabled={!values?.isActive}
                                inputProps={{
                                  "aria-label": "controlled",
                                  "data-cy": "chk-isActive-department",
                                }}
                              />
                            }
                            label="Status"
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    {index + 1 === values.faqs.length ? (
                      <Grid item xs={2}>
                        {values.faqs.length !== 1 && (
                          <IconButton onClick={(e) => removeField(e, index)}>
                            <RemoveIcon></RemoveIcon>
                          </IconButton>
                        )}
                        <IconButton onClick={() => addMore(index)}>
                          <AddIcon></AddIcon>
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <IconButton onClick={(e) => removeField(e, index)}>
                          <RemoveIcon></RemoveIcon>
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CKEditor
                        name={`faqs[${index}].answer`}
                        initData={
                          values?.faqs?.[index]?.answer
                            ? values?.faqs?.[index]?.answer
                            : ""
                        }
                        onChange={(event) => {
                          setFieldValue(
                            `faqs[${index}].answer`,
                            event.editor.getData()
                          );
                        }}
                      />
                      {Boolean(
                        touched?.faqs?.[index]?.answer &&
                          errors?.faqs?.[index]?.answer
                      ) && (
                        <FormHelperText error style={{ margin: "5px 0 0 0" }}>
                          {touched?.faqs?.[index]?.answer &&
                            errors?.faqs?.[index]?.answer}
                        </FormHelperText>
                      )}
                      {/* <TextField
                        label="Answer"
                        variant="outlined"
                        size="small"
                        name={`faqs[${index}].answer`}
                        multiline
                        minRows={2}
                        maxRows={3}
                        inputProps={{
                          // inputComponent: TextareaAutosize,
                          maxLength: 512,
                          // style: {
                          // 	resize: "auto",
                          // },
                        }}
                        // value={values.title}
                        onChange={handleChange}
                        autoComplete="off"
                        {...getFieldProps(`faqs[${index}].answer`)}
                        fullWidth
                        error={Boolean(
                          touched?.faqs?.[index]?.answer &&
                            errors?.faqs?.[index]?.answer
                        )}
                        helperText={
                          touched?.faqs?.[index]?.answer &&
                          errors?.faqs?.[index]?.answer
                        }
                      /> */}
                    </Grid>
                  </Grid>
                ))
              : values?.faqs?.map((faq, index) => (
                  <Grid
                    container
                    spacing={3}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                    key={index}
                  >
                    {index != 0 && (
                      <Grid item xs={12}>
                        <Divider sx={{ my: 1 }} />
                      </Grid>
                    )}
                    <Grid item xs={8}>
                      <TextField
                        label="Question"
                        variant="outlined"
                        size="small"
                        classes={
                          searchQuery && handleCheckIndex(index)
                            ? {
                                root: classes.root,
                              }
                            : {}
                        }
                        name={`faqs[${index}].question`}
                        multiline
                        inputProps={{
                          maxLength: 512,
                          // style: {
                          // 	resize: "auto",
                          // },
                        }}
                        // value={values.title}
                        onChange={handleChange}
                        autoComplete="off"
                        {...getFieldProps(`faqs[${index}].question`)}
                        fullWidth
                        error={Boolean(
                          touched?.faqs?.[index]?.question &&
                            errors?.faqs?.[index]?.question
                        )}
                        helperText={
                          touched?.faqs?.[index]?.question &&
                          errors?.faqs?.[index]?.question
                        }
                      />
                    </Grid>
                    <Grid item xs={2}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Stack className="checkbox-part">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.faqs[index]?.isActive}
                                name={`faqs[${index}].isActive`}
                                onChange={handleChange}
                                disabled={!values?.isActive}
                                inputProps={{
                                  "aria-label": "controlled",
                                  "data-cy": "chk-isActive-department",
                                }}
                              />
                            }
                            label="Status"
                          />
                        </Stack>
                      </Stack>
                    </Grid>
                    {index + 1 === values.faqs.length ? (
                      <Grid item xs={2}>
                        {values.faqs.length !== 1 && (
                          <IconButton onClick={(e) => removeField(e, index)}>
                            <RemoveIcon></RemoveIcon>
                          </IconButton>
                        )}
                        <IconButton onClick={() => addMore(index)}>
                          <AddIcon></AddIcon>
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <IconButton onClick={(e) => removeField(e, index)}>
                          <RemoveIcon></RemoveIcon>
                        </IconButton>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <CKEditor
                        name={`faqs[${index}].answer`}
                        initData={
                          values?.faqs?.[index]?.answer
                            ? values?.faqs?.[index]?.answer
                            : ""
                        }
                        onChange={(event) => {
                          setFieldValue(
                            `faqs[${index}].answer`,
                            event.editor.getData()
                          );
                        }}
                        style={
                          searchQuery && handleCheckIndex(index)
                            ? {
                                border: "2px solid #fda92d",
                              }
                            : {}
                        }
                      />
                      {Boolean(
                        touched?.faqs?.[index]?.answer &&
                          errors?.faqs?.[index]?.answer
                      ) && (
                        <FormHelperText error style={{ margin: "5px 0 0 0" }}>
                          {touched?.faqs?.[index]?.answer &&
                            errors?.faqs?.[index]?.answer}
                        </FormHelperText>
                      )}
                      {/* <TextField
                        label="Answer"
                        variant="outlined"
                        size="small"
                        name={`faqs[${index}].answer`}
                        multiline
                        classes={
                          searchQuery && handleCheckIndex(index)
                            ? {
                                root: classes.root,
                              }
                            : {}
                        }
                        minRows={2}
                        maxRows={3}
                        inputProps={{
                          // inputComponent: TextareaAutosize,
                          maxLength: 512,
                          // style: {
                          // 	resize: "auto",
                          // },
                        }}
                        // value={values.title}
                        onChange={handleChange}
                        autoComplete="off"
                        {...getFieldProps(`faqs[${index}].answer`)}
                        fullWidth
                        error={Boolean(
                          touched?.faqs?.[index]?.answer &&
                            errors?.faqs?.[index]?.answer
                        )}
                        helperText={
                          touched?.faqs?.[index]?.answer &&
                          errors?.faqs?.[index]?.answer
                        }
                      /> */}
                    </Grid>
                  </Grid>
                ))}
          </Container>
        </Card>
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddFAQs,
    path: "/faqs/add",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddFAQs,
    path: "/faqs/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
