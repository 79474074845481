import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getEvalutionByDesignation,
  ViewSubmittedEvaluationById,
  ViewSubmittedEvaluationHistory,
  getEvaluation,
  getEvaluationById,
  getSubmittedEvalutionHistory,
  getEmployeeSubmittedEvalution,
  getTeamForEvaluation,
  getAllEmployeeEvaluation
} from './employeeEvaluationThunk';


const initialState = {
  loading: false,
  error: null,
  evaluationData: [],
  evaluationFormData: {},
  viewSubmittedEvaluationData: {},
  evaluationHistory: {},
  viewSubmittedEvaluationHistory: {},
  allEmployeeEvaluation: [],
  complete: false,
  limit: 40,
  page: 0,
  totalPage: 0,
  totalCount: 0,
  sortBy: 'id',
  orderBy: 'desc',
};

export const employeeEvaluationSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.complete = false;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload.orderBy;
      state.complete = false;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.complete = false;
      state.error = null;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
      state.complete = false;
      state.error = null;
    },
  },
  extraReducers:{
    [getEvalutionByDesignation.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getEvalutionByDesignation.fulfilled]:(state,{payload})=>{
        state.evaluationDataByDesignation= payload;
        state.loading = false;
        state.status = "fulfilled";
    },
    [getEvalutionByDesignation.rejected]:(state,{payload})=>{
        state.loading = false;
        state.status = "rejected"
    },
    [getEvaluation.pending]:(state)=>{
        state.loading=true;
        state.status="pending"
    },
    [getEvaluation.fulfilled]:(state,{payload})=>{
        state.evaluationData= payload;
        state.loading = false;
        state.status = "fulfilled";
    },
    [getEvaluation.rejected]:(state,{payload})=>{
        state.loading = false;
        state.status = "rejected"
    },
    [getEvaluationById.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getEvaluationById.fulfilled]:(state,{payload})=>{
        state.evaluationFormData= payload?.data;
        state.loading = false;
        state.status = "fulfilled";
    },
    [getEvaluationById.rejected]:(state,{payload})=>{
        state.loading = false;
        state.status = "rejected"
        state.evaluationFormData = [];
    },
    [ViewSubmittedEvaluationById.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [ViewSubmittedEvaluationById.fulfilled]:(state,{payload})=>{
        state.viewSubmittedEvaluationData= payload?.data;
        state.loading = false;
        state.status = "fulfilled";
    },
    [ViewSubmittedEvaluationById.rejected]:(state,{payload})=>{
        state.loading = false;
        state.status = "rejected"
        state.viewSubmittedEvaluationData = [];
    },
    [getSubmittedEvalutionHistory.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getSubmittedEvalutionHistory.fulfilled]:(state,{payload})=>{
        state.loading = false;
        state.evaluationHistory = payload?.data || payload;
        state.complete = true;
        state.page = payload?.data?.currentPage - 1 || 0;
        state.totalCount = payload?.data?.totalRecords || 0;
        state.totalPage = payload?.data?.totalPages || 0;
        state.limit = payload?.data?.limit
        state.status = "fulfilled";
    },
    [getSubmittedEvalutionHistory.rejected]:(state,{payload})=>{
      state.loading = false;
      state.evaluationHistory = {};
      state.error = payload;
      state.complete = true;
      state.totalCount = 0;
      state.totalPage = 0;
      state.page = 0;
      state.status = "rejected"
    },
    [getEmployeeSubmittedEvalution.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getEmployeeSubmittedEvalution.fulfilled]:(state,{payload})=>{
        state.loading = false;
        console.log("payload", payload)
        state.employeeSubmittedEvalution = payload?.data?.getSubmittedEvalutionHistory || payload;
        state.complete = true;
        state.page = payload?.data?.currentPage - 1 || 0;
        state.totalRecords = payload?.data?.totalRecords || 0;
        state.totalPage = payload?.data?.totalPages || 0;
        state.limit = payload?.data?.limit;
        state.status = "fulfilled";
    },
    [getEmployeeSubmittedEvalution.rejected]:(state,{payload})=>{
      state.loading = false;
      state.employeeSubmittedEvalution = {};
      state.error = payload;
      state.complete = true;
      state.totalCount = 0;
      state.totalPage = 0;
      state.page = 0;
      state.status = "rejected"
    },
    [ViewSubmittedEvaluationHistory.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [ViewSubmittedEvaluationHistory.fulfilled]:(state,{payload})=>{
      state.loading = false;
      state.viewSubmittedEvaluationHistory = payload?.data;
      state.complete = true;
      state.status = "fulfilled";
    },
    [ViewSubmittedEvaluationHistory.rejected]:(state,{payload})=>{
      state.loading = false;
      state.viewSubmittedEvaluationHistory = {};
      state.error = payload;
      state.complete = true;
      state.status = "rejected"
    },
    [getTeamForEvaluation.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getTeamForEvaluation.fulfilled]:(state,{payload})=>{
        state.evalutionTeam= payload?.data;
        state.loading = false;
        state.status = "fulfilled";
    },
    [getTeamForEvaluation.rejected]:(state,{payload})=>{
        state.loading = false;
        state.status = "rejected"
        state.evalutionTeam = [];
    },
    [getAllEmployeeEvaluation.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getAllEmployeeEvaluation.fulfilled]:(state,{payload})=>{
      console.log("Payload", payload)
        state.allEmployeeEvaluation= payload;
        state.loading = false;
        state.status = "fulfilled";
        state.complete = true;
        state.page = payload?.data?.currentPage - 1 || 0;
        state.totalRecords = payload?.data?.totalRecords || 0;
        state.totalPages = payload?.data?.totalPages || 0;
        state.limit = payload?.data?.limit;
    },
    [getAllEmployeeEvaluation.rejected]:(state,{payload})=>{
        state.loading = false;
        state.status = "rejected"
        state.allEmployeeEvaluation = [];
    },
  }
});


export const { setLimit, setPage, setOrderBy, setSortBy } =
employeeEvaluationSlice.actions;

export const employeeEvaluationReducer = employeeEvaluationSlice.reducer;
