import React, { useRef, useState, useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Button from "@mui/material/Button";
import NiceModal from "@ebay/nice-modal-react";
import AddCertificateModel from "./AddCertificateModel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export default function CheckList(props) {
	const employeeResumeFileInput = useRef(null);
	const presentAddressFileInput = useRef(null);
	const permanentAddressFileInput = useRef(null);
	const bachelorCertificatesFileInput = useRef(null);
	const masterCertificatesFileInput = useRef(null);

	const {
		values,
		handleChange,
		checkIfImage,
		setFieldValue,
		errors,
		touched,
		getFileName,
		getCertificateName,
		getImageUrl,
		handleTabChange,
	} = props;

	const [residentialAddressCheck, setresidentialAddressCheck] = useState(
		values?.checklists?.isResidentialAddress ? 1 : 0,
	);

	const [bachelorCertificateCheck, setBachelorCertificateCheck] = useState(
		!values.checklists?.isBachelorCertificates || values.checklists?.isBachelorCertificates == null  ? 0 : 1,
	);

	const [masterCertificateCheck, setMasterCertificateCheck] = useState(
		!values.checklists?.isMasterCertificates || values.checklists?.isMasterCertificates == null  ? 0 : 1,
	);

	const [photoIdProofCheck, setPhotoIdProofCheck] = useState(
		!values?.checklists?.isPhotoProof || values.checklists?.isPhotoProof == null  ? 0 : 1,
	);

	const [passportSizePhotoseCheck, setPassportSizePhotoseCheck] = useState(
		!values.checklists?.isPassportSizePhotos || values.checklists?.isPassportSizePhotos == null  ? 0 : 1,
	);

	const [serviceAgreementCheck, setServiceAgreementCheck] = useState(
		!values.checklists?.isServiceAggrement || values.checklists?.isServiceAggrement == null  ? 0 : 1,
	);

	const [last3MonthSalarySlipCheck, setLast3MonthSalarySlipCheck] = useState(
		!values.checklists?.isLast3MonthsSalarySlip || values.checklists?.isLast3MonthsSalarySlip == null  ? 0 : 1,
	);

	const [expAndRelLetterCheck, setExpAndRelLetterCheck] = useState(
		!values.checklists?.isExpAndRelLetter || values.checklists?.isExpAndRelLetter == null  ? 0 : 1,
	);

	useEffect(() => {
		if (
			(values.bachelorCertificates.length > 0 &&
				values.bachelorCertificates.length === 1) ||
			values.checklists?.isBachelorCertificates
		) {
			setBachelorCertificateCheck(1);
		} else {
			setBachelorCertificateCheck(0);
			setMasterCertificateCheck(0);
		}

		if (
			values.bachelorCertificates.length > 1 ||
			values.checklists?.isMasterCertificates
		) {
			setBachelorCertificateCheck(1);
			setMasterCertificateCheck(1);
		} else {
			setMasterCertificateCheck(0);
		}
	}, [values.bachelorCertificates]);

	useEffect(() => {
		if (
			values.presentAddressImage ||
			values.permanentAddressImage ||
			values?.checklists?.isResidentialAddress
		) {
			setresidentialAddressCheck(1);
		} else {
			setresidentialAddressCheck(0);
		}
	}, [values.presentAddressImage, values.permanentAddressImage]);

	useEffect(() => {
		if (
			values?.checklists?.isPhotoProof
		) {
			setPhotoIdProofCheck(1);
		} else {
			setPhotoIdProofCheck(0);
		}
	}, [values?.checklists?.isPhotoProof]);

	useEffect(() => {
		if (
			values?.checklists?.isServiceAggrement
		) {
			setServiceAgreementCheck(1);
		} else {
			setServiceAgreementCheck(0);
		}
	}, [values?.checklists?.isServiceAggrement]);

	useEffect(() => {
		if (
			values?.checklists?.isPassportSizePhotos
		) {
			setPassportSizePhotoseCheck(1);
		} else {
			setPassportSizePhotoseCheck(0);
		}
	}, [values?.checklists?.isPassportSizePhotos]);

	useEffect(() => {
		if (
			values?.checklists?.isLast3MonthsSalarySlip
		) {
			setLast3MonthSalarySlipCheck(1);
		} else {
			setLast3MonthSalarySlipCheck(0);
		}
	}, [values?.checklists?.isLast3MonthsSalarySlip]);

	useEffect(() => {
		if (
			values?.checklists?.isExpAndRelLetter
		) {
			setExpAndRelLetterCheck(1);
		} else {
			setExpAndRelLetterCheck(0);
		}
	}, [values?.checklists?.isExpAndRelLetter]);

	const handlePresentAddressImageChange = (e) => {
		setFieldValue("presentAddressImage", e.target.files[0]);
	};

	const handleEmployeeResumeImageChange = (e) => {
		setFieldValue("employeeResume", e.target.files[0]);
	};

	const handleDeleteEmployeeResume = (image) => {
		setFieldValue("employeeResume", null);
		setFieldValue("toBeDeletedEmployeeResume", image?.id);
	};

	const handleDeletePesentAddress = (image) => {
		setFieldValue("presentAddressImage", null);
		setFieldValue("toBeDeletedPresentAddress", image?.id);
	};

	const handleDeletePermanentAddress = (image) => {
		setFieldValue("permanentAddressImage", null);
		setFieldValue("toBeDeletedPermanentAddress", image?.id);
	};

	const handleAddCertificate = (name, file) => {
		var blob = file.slice(0, file.size, file.type);
		const newFile = new File([blob], name, { type: file.type });
		// console.log(newFile);
		setFieldValue("bachelorCertificates", [
			...values.bachelorCertificates,
			newFile,
		]);
	};

	const handleDeleteBachelorCertificate = (image, index) => {
		let temp = [...values.bachelorCertificates];
		let tobeDeleteImage = temp.splice(index, 1);
		setFieldValue("toBeDeletedBachelorCertificates", [
			...values.toBeDeletedBachelorCertificates,
			tobeDeleteImage[0] && tobeDeleteImage[0].id,
		]);
		setFieldValue("bachelorCertificates", temp);
	};

	const handleMasterCertificate = (image, index) => {
		let temp = [...values.masterCertificates];
		let tobeDeleteImage = temp.splice(index, 1);
		setFieldValue("toBeDeletedMasterCertificates", [
			...values.toBeDeletedMasterCertificates,
			tobeDeleteImage[0] && tobeDeleteImage[0].id,
		]);
		setFieldValue("masterCertificates", temp);
	};

	return (
		<Card className="right-side-content-part">
			<Container maxWidth="xl">
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mt={3}
					mb={2}
				>
					<Typography variant="h6">Check List</Typography>
				</Stack>
				<FormGroup>
					<Stack className="gray-box Employee_Detail_Content Check-List-Detail" direction="row" flexWrap="wrap">
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isPhotoProof"
									// onChange={handleChange}
									onChange={(e) => {
										setFieldValue(
											"checklists.isPhotoProof",
											e.target.checked ? 1 : 0,
										);
										setPhotoIdProofCheck(e.target.checked ? 1 : 0);
									}}
									// checked={values.checklists?.isPhotoProof || false}
									checked={photoIdProofCheck}
								/>
							}
							label="Photo Id proof"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isResidentialAddress"
									onChange={(e) => {
										setFieldValue(
											"checklists.isResidentialAddress",
											e.target.checked ? 1 : 0,
										);
										// console.log(e.target.checked ? 1 :0)
										setresidentialAddressCheck(e.target.checked ? 1 : 0);
									}}
									checked={residentialAddressCheck}
								/>
							}
							label="Residential Address"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isBachelorCertificates"
									onChange={(e) => {
										setFieldValue(
											"checklists.isBachelorCertificates",
											e.target.checked ? 1 : 0,
										);
										setBachelorCertificateCheck(e.target.checked ? 1 : 0);
									}}
									checked={bachelorCertificateCheck}
								/>
							}
							label="Bachelor Certificates"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isPassportSizePhotos"
									// onChange={handleChange}
									onChange={(e) => {
										setFieldValue(
											"checklists.isPassportSizePhotos",
											e.target.checked ? 1 : 0,
										);
										setPassportSizePhotoseCheck(e.target.checked ? 1 : 0);
									}}
									// checked={values.checklists?.isPassportSizePhotos || false}
									checked={passportSizePhotoseCheck}
								/>
							}
							label="Passport Size Photos"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isServiceAggrement"
									// onChange={handleChange}
									// checked={values.checklists?.isServiceAggrement || false}
									onChange={(e) => {
										setFieldValue(
											"checklists.isServiceAggrement",
											e.target.checked ? 1 : 0,
										);
										setServiceAgreementCheck(e.target.checked ? 1 : 0);
									}}
									checked={serviceAgreementCheck}
								/>
							}
							label="Service Aggrement"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isMasterCertificates"
									onChange={(e) => {
										setFieldValue(
											"checklists.isMasterCertificates",
											e.target.checked ? 1 : 0,
										);
										setMasterCertificateCheck(e.target.checked ? 1 : 0);
									}}
									checked={masterCertificateCheck}
								/>
							}
							label="Master Certificates"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isLast3MonthsSalarySlip"
									// onChange={handleChange}
									// checked={values.checklists?.isLast3MonthsSalarySlip || false}
									onChange={(e) => {
										setFieldValue(
											"checklists.isLast3MonthsSalarySlip",
											e.target.checked ? 1 : 0,
										);
										setLast3MonthSalarySlipCheck(e.target.checked ? 1 : 0);
									}}
									checked={last3MonthSalarySlipCheck}
								/>
							}
							label="Last 3 Months Salary Slip"
						/>
						<FormControlLabel
							color="primary"
							control={
								<Checkbox
									name="checklists.isExpAndRelLetter"
									// onChange={handleChange}
									// checked={values.checklists?.isExpAndRelLetter || false}
									onChange={(e) => {
										setFieldValue(
											"checklists.isExpAndRelLetter",
											e.target.checked ? 1 : 0,
										);
										setExpAndRelLetterCheck(e.target.checked ? 1 : 0);
									}}
									checked={expAndRelLetterCheck}
								/>
							}
							label="Exp And Rel Letter"
						/>
					</Stack>
				</FormGroup>
				<Grid container spacing={3} pt={3} pb={3} className="checkList-grid">
					<Grid item xs={12}>
						<Grid className="gray-box Employee_Detail_Content checkList-grid_address" container pt={3}>
							<Grid className="checkList-grid-address-grid" item xs={6}>
								<Stack mb={1}>
									<Typography>Present Address</Typography>
								</Stack>
								<Grid className="checkList-grid-address-detail" container>
									<Grid item style={{ width: "100%" }}>
										<div
											className="custome-uploadBox small profile-image-part"
											onClick={(e) => {
												values.presentAddressImage
													? window.open(
															values.presentAddressImage.webViewLink,
															"_blank",
													  )
													: presentAddressFileInput.current.click();
											}}
										>
											{values.presentAddressImage && (
												<>
													{checkIfImage(values.presentAddressImage) ? (
														<>
															<InsertDriveFileIcon />
															{/* <Box
																component="img"
																src={getImageUrl(
																	values.presentAddressImage
																)}
															/> */}
															<Typography variant="caption">
																{getFileName(values.presentAddressImage)}
															</Typography>
														</>
													) : (
														<>
															<InsertDriveFileIcon />
															<Typography variant="caption">
																{getFileName(values.presentAddressImage)}
															</Typography>
														</>
													)}
												</>
											)}

											{values.presentAddressImage && (
												<CancelRoundedIcon
													onClick={(e) => {
														e.stopPropagation();
														handleDeletePesentAddress(
															values.presentAddressImage,
														);
													}}
													className="closeIcon"
												/>
											)}

											<div className="p-image">
												{!values.presentAddressImage && (
													<i className="upload-button">
														<AddCircleIcon />
													</i>
												)}

												{!values.presentAddressImage && (
													<input
														ref={presentAddressFileInput}
														className="file-upload"
														type="file"
														accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
														onChange={handlePresentAddressImageChange}
													/>
												)}
											</div>
										</div>
									</Grid>
								</Grid>
								<FormHelperText
									error={Boolean(errors?.presentAddressImage)}
									style={{ margin: "5px 0 0 0" }}
								>
									{touched?.presentAddressImage && errors?.presentAddressImage}
								</FormHelperText>
							</Grid>
							<Grid className="checkList-grid-address-grid" item xs={6}>
								<Stack mb={1}>
									<Typography>Permanent Address</Typography>
								</Stack>
								<Grid className="checkList-grid-address-detail" container xs={12}>
									<Grid item style={{ width: "100%" }}>
										<div
											className="custome-uploadBox small profile-image-part"
											onClick={(e) => {
												values.permanentAddressImage
													? window.open(
															values.permanentAddressImage.webViewLink,
															"_blank",
													  )
													: permanentAddressFileInput.current.click();
											}}
										>
											{values.permanentAddressImage && (
												<CancelRoundedIcon
													onClick={(e) => {
														e.stopPropagation();
														handleDeletePermanentAddress(
															values.permanentAddressImage,
														);
													}}
													className="closeIcon"
												/>
											)}

											{values.permanentAddressImage && (
												<>
													{checkIfImage(values.permanentAddressImage) ? (
														<>
															<InsertDriveFileIcon />
															{/* <Box
																component="img"
																src={getImageUrl(
																	values.permanentAddressImage
																)}
															/> */}
															<Typography variant="caption">
																{getFileName(values.permanentAddressImage)}
															</Typography>
														</>
													) : (
														<>
															<InsertDriveFileIcon />
															<Typography variant="caption">
																{getFileName(values.permanentAddressImage)}
															</Typography>
														</>
													)}
												</>
											)}

											<div className="p-image">
												{!values.permanentAddressImage && (
													<i className="upload-button">
														<AddCircleIcon />
													</i>
												)}
												{!values.permanentAddressImage && (
													<input
														ref={permanentAddressFileInput}
														className="file-upload"
														type="file"
														accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
														onChange={(e) => {
															setFieldValue(
																"permanentAddressImage",
																e.target.files[0],
															);
															setresidentialAddressCheck(true);
														}}
													/>
												)}
											</div>
										</div>
									</Grid>
								</Grid>
								<FormHelperText
									error={Boolean(errors?.permanentAddressImage)}
									style={{ margin: "5px 0 0 0" }}
								>
									{touched?.permanentAddressImage &&
										errors?.permanentAddressImage}
								</FormHelperText>
							</Grid>
						</Grid>
					</Grid>
					<div style={{marginTop: "24px", marginBottom: "0", borderTop: "1px solid #dfdfdf", width: "100%"}}></div>
					<Grid className="checkList-grid-cols" item xs={12}>
						<Grid className="gray-box Employee_Detail_Content Certificates_detail" container pt={3}>
							<Grid className="checkList-grid-address-grid" item xs={12}>
								<Stack mb={1}>
									<Typography>Certificates</Typography>
								</Stack>
								<Grid className="checkList-grid-address-detail" container>
									{values.bachelorCertificates &&
										values.bachelorCertificates.map((img, idx) => {
											return (
												<Grid
													key={idx}
													item
													xs={12}
												>
													<div
														className="custome-uploadBox small profile-image-part"
														onClick={(e) => {
															window.open(img.webViewLink, "_blank");
														}}
													>
														<CancelRoundedIcon
															onClick={(e) => {
																e.stopPropagation();
																handleDeleteBachelorCertificate(img, idx);
															}}
															className="closeIcon"
														/>
														{img && (
															<>
																{checkIfImage(img) ? (
																	<>
																		<InsertDriveFileIcon
																			onClick={(e) => {
																				window.open(img.webViewLink, "_blank");
																			}}
																		/>
																		{/* <Box
																			component="img"
																			src={getImageUrl(img)}
																		/> */}
																		<Typography variant="caption">
																			{getCertificateName(img)}
																		</Typography>
																	</>
																) : (
																	<>
																		<InsertDriveFileIcon
																			onClick={(e) => {
																				window.open(img.webViewLink, "_blank");
																			}}
																		/>
																		<Typography variant="caption">
																			{getCertificateName(img)}
																		</Typography>
																	</>
																)}
															</>
														)}
													</div>
												</Grid>
											);
										})}
									<Grid
										item
										xs={12}
									>
										<div
											className="custome-uploadBox small profile-image-part"
											onClick={() => {
												NiceModal.show(AddCertificateModel, {
													handleAddCertificate: handleAddCertificate,
												});
												// bachelorCertificatesFileInput.current.click();
											}}
										>
											<div className="p-image">
												<i className="upload-button">
													<AddCircleIcon />
												</i>
												<input
													ref={bachelorCertificatesFileInput}
													className="file-upload"
													type="file"
													accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
													onChange={(e) => {
														setFieldValue("bachelorCertificates", [
															...values.bachelorCertificates,
															e.target.files[0],
														]);
													}}
												/>
											</div>
										</div>
									</Grid>
								</Grid>
								<FormHelperText
									style={{ margin: "5px 0 0 0" }}
									error={Boolean(errors?.bachelorCertificates)}
								>
									{touched?.bachelorCertificates &&
										errors?.bachelorCertificates}
								</FormHelperText>
							</Grid>
						</Grid>
					</Grid>
					<div style={{marginTop: "24px", marginBottom: "0", borderTop: "1px solid #dfdfdf", width: "100%"}}></div>
					<Grid className="checkList-grid-cols" item xs={12}>
						<Grid className="gray-box Employee_Detail_Content Employee-Resume_detail" container>
							<Grid item xs={12}>
								<Stack mb={1}>
									<Typography>Employee Resume</Typography>
								</Stack>
								<Grid container>
									<Grid item xs={12}>
										<div
											className="custome-uploadBox small profile-image-part"
											onClick={(e) => {
												values.employeeResume
													? window.open(
															values.employeeResume.webViewLink,
															"_blank",
													  )
													: employeeResumeFileInput.current.click();
											}}
										>
											{values.employeeResume && (
												<>
													{checkIfImage(values.employeeResume) ? (
														<>
															<InsertDriveFileIcon />
															{/* <Box
																component="img"
																src={getImageUrl(
																	values.presentAddressImage
																)}
															/> */}
															<Typography variant="caption">
																{getFileName(values.employeeResume)}
															</Typography>
														</>
													) : (
														<>
															<InsertDriveFileIcon />
															<Typography variant="caption">
																{getFileName(values.employeeResume)}
															</Typography>
														</>
													)}
												</>
											)}
											{values.employeeResume && (
												<CancelRoundedIcon
													onClick={(e) => {
														e.stopPropagation();
														handleDeleteEmployeeResume(values.employeeResume);
													}}
													className="closeIcon"
												/>
											)}

											<div className="p-image">
												{!values.employeeResume && (
													<i className="upload-button">
														<AddCircleIcon />
													</i>
												)}

												{!values.employeeResume && (
													<input
														ref={employeeResumeFileInput}
														className="file-upload"
														type="file"
														accept=".doc, .docx, .pdf"
														onChange={handleEmployeeResumeImageChange}
													/>
												)}
											</div>
										</div>
									</Grid>
								</Grid>
								<FormHelperText
									error={Boolean(errors?.employeeResume)}
									style={{ margin: "5px 0 0 0" }}
								>
									{touched?.employeeResume && errors?.employeeResume}
								</FormHelperText>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					mb={3}
					mt={4}
				>
					<Button className="back-button" startIcon={<ArrowBackIcon />} onClick={() => handleTabChange("7")} variant="contained">
						Back
					</Button>
				</Stack>
			</Container>
		</Card>
	);
}
