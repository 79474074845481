import { Autocomplete, Button, Card, Container, FormControlLabel, Grid, IconButton, Paper, Stack, Switch, TextareaAutosize, TextField, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import DashboardLayout from "../../../layouts/dashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
// import { DesktopDatePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import { LoadingButton } from '@mui/lab';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ispSchema from "../../../validations/ispSchema";
import { decryption } from '../../../utils/encodeString';
import { addIsp, getAllIspById, updateIsp } from '../../../redux/assestManagment/thunk';
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import {
  setISPInventoryStatus
} from "../../../redux/assestManagment/assestManagmentSlice";
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
};

const AddIsp = () => {

  let ipTypeList = [
    {title:'Primary', id:'Primary'},
    {title:'Secondary', id:'Secondary'},
  ]
  const navigate = useNavigate();
	const dispatch = useDispatch();
  let { id } = useParams();
	if (id) id = decryption(id);
  const [showRouterPassword, setShowRouterPassword] = useState(false);
  const [showWifiPassword, setShowWifiPassword] = useState(false);
  const [showPortalPassword, setShowPortalPassword] = useState(false);

  const [routerPasswordKey, setRouterPasswordKey] = useState('');
  const [wifiPasswordKey, setWifiPasswordKey] = useState('');
  const [portalPasswordKey, setPortalPasswordKey] = useState('');
  const [fildname, setFildname] = useState('');
  const [showStaticIP, setShowStaticIP] = useState('');
  useEffect(() => {
    if(id===-1){
      navigate("/isp");
    }
  }, [dispatch, id]);
  const formik = useFormik({
    initialValues: {
      ispName: "",
      tariffPlanName: "",
      billingFrom: null,
      billingTo: null,
      rentalCharges: null,
      staticIpCharges: null,
      staticIp:false,
      dataLimitPerBillCycle:null,
      staticIpDetail: [
        {
          staticIpAddress: "",
          assignedToIp: "",
          assignedToDomain: "",
          ipType: "",
        }
      ],
      fixedLine: false,
      ispFixedLine: [
        {
            phoneNumber: ""
        }
    ],
    routerGatewayIp: "",
    routerUsername: "",
    routerPassword: "",
    wifiSsid: '',
    wifiPassword: '',
    portalAccountRelationshipNumber: "",
    portalUrl: "",
    portalUsername: "",
    portalPassword: "",
    notes: "",
    supportPerson: [
      {
          name: "",
          phoneNumber: ""
      }
  ]
    },
    validationSchema: ispSchema,
    onSubmit: async (values,{ resetForm }) => {
      try {
        let finalValues = {...values};
        if(values.staticIp===false){
          finalValues.staticIpDetail = [];
        }
        if(values.fixedLine===false){
          finalValues.ispFixedLine = [];
        }
      if (!id) {
        
        let response = await dispatch(addIsp(finalValues)).unwrap();
        if (response.status === 200) {
          resetForm();
          dispatch(setISPInventoryStatus({ status: "idle" }));
          navigate("/isp");
        }
      }
  
      if (id) {
       let response = await dispatch(updateIsp({ id, finalValues })).unwrap();
       if (response.status === 200) {
        resetForm();
        dispatch(setISPInventoryStatus({ status: "idle" }));
        navigate("/isp");
      }
  
      }

      
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  

  

  const IpDetail = () => {
    setFieldValue("staticIpDetail", [...values.staticIpDetail, {
      staticIpAddress: "",
      assignedToIp: "",
      assignedToDomain: "",
      ipType: "",
    }]);
  };

  const AddSupportPerson = () => {
    setFieldValue("supportPerson", [...values.supportPerson, {
      name: "",
      phoneNumber: ""
    }]);
  };
  const AddFixedLine = () => {
    setFieldValue("ispFixedLine", [...values.ispFixedLine, {
      phoneNumber: ""
    }]);
  };

  const removeIpDetails = (e, index) => {
    e.preventDefault();
    const temp = [...values.staticIpDetail];
    temp.splice(index, 1);
    setFieldValue("staticIpDetail", temp);
  };
  const removeSupportPerson = (e, index) => {
    e.preventDefault();
    const temp = [...values.supportPerson];
    temp.splice(index, 1);
    setFieldValue("supportPerson", temp);
  };
  const removeFixedLine = (e, index) => {
    e.preventDefault();
    const temp = [...values.ispFixedLine];
    temp.splice(index, 1);
    setFieldValue("ispFixedLine", temp);
  };
  const {
		handleSubmit,
		getFieldProps,
		setValues,
		values,
		setFieldValue,
		handleChange,
		errors,
		isSubmitting,
		touched,
		setErrors,
		setFieldTouched,
		setFieldError,
		validateField,
		handleBlur,
		setStatus,
		status,
	} = formik;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  useEffect(() => {
		if (id) {
			// setIsLoading(true);
			dispatch(getAllIspById(id))
				.unwrap()
				.then((res) => {
          let temp = {...res.data};
          setShowStaticIP(res.data.staticIp);
          if(res.data.routerPassword===""){
            setShowRouterPassword(true);
          }
          if(res.data.wifiPassword===""){
            setShowWifiPassword(true);
          }
          if(res.data.portalPassword===""){
            setShowPortalPassword(true);
          }
          if(res.data.staticIp===false){
            temp.staticIpDetail = [ {
              staticIpAddress: "",
              assignedToIp: "",
              assignedToDomain: "",
              ipType: "",
            }];
          } 
          if(res.data.fixedLine===false){
            temp.ispFixedLine = [{
              phoneNumber: ""
          }];
          }
					setValues(temp)
				})
				.catch((err) => {
					toast.error(err.message);
					return navigate("/isp");
				});
		}
	}, [id, dispatch, setValues]);


  let handleShowRouterPassword = () => {
    if(!id){
    setShowRouterPassword(!showRouterPassword);
    }else{
if(showRouterPassword===false){
  setFildname('routerPassword');
  handleOpen();
}else{
  setShowRouterPassword(!showRouterPassword);
}
    }
  };

  let handleShowWifiPassword = () => {
    if(!id){
    setShowWifiPassword(!showWifiPassword);
    }else{
if(showWifiPassword===false){
  setFildname('wifiPassword');
  handleOpen();
}else{
  setShowWifiPassword(!showWifiPassword);
}
    }
  };

  let handleShowPortalPassword = () => {
    if(!id){
    setShowPortalPassword(!showPortalPassword);
    }else{
if(showPortalPassword===false){
  setFildname('portalPassword');
  handleOpen();
}else{
  setShowPortalPassword(!showPortalPassword);
}
    }
  };
  let handleVerify=()=>{
    if(fildname==='routerPassword'){
      if(routerPasswordKey==='1234'){
        setShowRouterPassword(!showRouterPassword);
        handleClose();
      }else{
        toast.error('Wrong Password');
        handleClose();
      }
    }else if(fildname==='wifiPassword'){
      if(wifiPasswordKey==='1234'){
        setShowWifiPassword(!showWifiPassword);
        handleClose();
      }else{
        toast.error('Wrong Password');
        handleClose();
      }
    }else if(fildname==='portalPassword'){
      if(portalPasswordKey==='1234'){
        setShowPortalPassword(!showPortalPassword);
        handleClose();
      }else{
        toast.error('Wrong Password');
        handleClose();
      }
    }
    
  }

  // console.log(values,"pd");
  return (
    <Container maxWidth="xl">
      <form onSubmit={(...args) => {
					// setSubmitted(true);
					handleSubmit(...args);
				}}>
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="end"
      mb={2}
    >
      {/* <Typography variant="h4" gutterBottom>
        {id ? "Edit Job" : "Add Job"}
      </Typography> */}
      <Stack className='title-button_main' direction="row" alignItems="center" justifyContent="end" style={{ width: "100%" }}>
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
                    
          </Typography>
          <div className=''>
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              type="submit"
              sx={{ marginRight: "15px" }}
            >
              Save
            </LoadingButton>
            <Button
              variant="contained"
              component={RouterLink}
              to="/isp"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          </div>
        </div>
      </Stack>
    </Stack>
    <Card className="holiday-table-grid" mb={5}>
      <Container maxWidth="xl">
        <Paper>
          {/* <form onSubmit={(...args) => {
					// setSubmitted(true);
					handleSubmit(...args);
				}}> */}
            <Grid container spacing={3}>
            <Grid item xs={12} mt={3}>
              <Typography variant="h5" gutterBottom>
              Basic Details 
              </Typography>
            </Grid >
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="ISP Name"
                    name="ispName"
                    value={values.ispName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.ispName && errors.ispName)}
                    helperText={touched.ispName && errors.ispName}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="Tariff Plan Name"
                    name="tariffPlanName"
                    value={values.tariffPlanName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.tariffPlanName && errors.tariffPlanName)}
                    helperText={touched.tariffPlanName && errors.tariffPlanName}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="Billing Cycle Start Date"
                        name="billingFrom"
                        value={values.billingFrom}
                        inputFormat="dd/MM/yyyy"
                        {...getFieldProps("billingFrom")}
                        onChange={(newValue) => {
                          setFieldValue(`billingFrom`, newValue);
                        }}
                        // onBlur={(e) => {
                        //   interviewerList(index);
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={Boolean(touched.billingFrom && errors.billingFrom)}
                            helperText={touched.billingFrom && errors.billingFrom}
                            size="small"
                            // error={false}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
              </Grid>
              <Grid item xs={6} mt={0}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="Billing Cycle End Date"
                        name="billingTo"
                        value={values.billingTo}
                        inputFormat="dd/MM/yyyy"
                        {...getFieldProps("billingTo")}
                        onChange={(newValue) => {
                          setFieldValue(`billingTo`, newValue);
                        }}
                        // onBlur={(e) => {
                        //   interviewerList(index);
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(touched.billingTo && errors.billingTo)}
                            helperText={touched.billingTo && errors.billingTo}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
              </Grid>
              <Grid item xs={6} mt={0}>
              {values.dataLimitPerBillCycle?<TextField
                    fullWidth
                    autoComplete="off"
                    label="Data Limit Per Bill Cycle"
                    name="dataLimitPerBillCycle"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.dataLimitPerBillCycle}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.dataLimitPerBillCycle && errors.dataLimitPerBillCycle)}
                    helperText={touched.dataLimitPerBillCycle && errors.dataLimitPerBillCycle}
                  />:
                <TextField
                    fullWidth
                    autoComplete="off"
                    label="Data Limit Per Bill Cycle"
                    name="dataLimitPerBillCycle"
                    value={values.dataLimitPerBillCycle}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.dataLimitPerBillCycle && errors.dataLimitPerBillCycle)}
                    helperText={touched.dataLimitPerBillCycle && errors.dataLimitPerBillCycle}
                  />}
                </Grid>
              <Grid item xs={6} mt={0}>
                {values.rentalCharges?<TextField
                    fullWidth
                    autoComplete="off"
                    label="Rental Charges"
                    name="rentalCharges"
                    // type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.rentalCharges}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.rentalCharges && errors.rentalCharges)}
                    helperText={touched.rentalCharges && errors.rentalCharges}
                  />
                :<TextField
                    fullWidth
                    autoComplete="off"
                    label="Rental Charges"
                    name="rentalCharges"
                    // type="number"
                    value={values.rentalCharges}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.rentalCharges && errors.rentalCharges)}
                    helperText={touched.rentalCharges && errors.rentalCharges}
                  />}
                </Grid>
                <Grid item xs={values.staticIp?6:4} mt={0}>
                  {values.staticIpCharges?<TextField
                    fullWidth
                    autoComplete="off"
                    label="Static IP Charges"
                    name="staticIpCharges"
                    // type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={values.staticIpCharges}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.staticIpCharges && errors.staticIpCharges)}
                    helperText={touched.staticIpCharges && errors.staticIpCharges}
                  />:<TextField
                  fullWidth
                  autoComplete="off"
                  label="Static IP Charges"
                  name="staticIpCharges"
                  // type="number"
                  value={values.staticIpCharges}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  onBlur={handleBlur}
                  error={Boolean(touched.staticIpCharges && errors.staticIpCharges)}
                  helperText={touched.staticIpCharges && errors.staticIpCharges}
                />}
                </Grid>
                <Grid item xs={values.staticIp?6:4} mt={0} >
                  <Stack justifyContent="center" sx={{display:'flex'}} alignItems='center'>
                <FormControlLabel control={<Switch checked={values.staticIp} />} label="Static IP"  name='staticIp' onChange={handleChange} />
                </Stack>
                 </Grid>
                {values.staticIp &&values &&
            values.staticIpDetail &&
            values.staticIpDetail.map((edu, index) => (
            <>{index === 0 && ( <Grid item xs={12} mt={0} sx={{display:'flex'}} justifyContent="flex-end">
              <Stack direction="row" justifyContent="space-between" sx={{width:'100%'}}>
              <Typography variant="h5" gutterBottom>
              IP Details 
              </Typography>
              <Button
              variant="contained"
              sx={{height: 'fit-content'}}
              onClick={IpDetail}
              startIcon={<AddIcon />}
            >
              Add IP Detail
            </Button>
            </Stack>
            </Grid>)}
            
                  <Grid item xs={5.5} mt={0}>
                  <TextField
                        fullWidth
                        autoComplete="off"
                        label="IP Address"
                        name={`staticIpDetail[${index}].staticIpAddress`}
                        value={values.staticIpDetail[index]?.staticIpAddress}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.staticIpDetail &&
                          touched?.staticIpDetail[index] &&
                          touched?.staticIpDetail[index]?.staticIpAddress &&
                          errors?.staticIpDetail &&
                          errors?.staticIpDetail[index] &&
                          errors?.staticIpDetail[index]?.staticIpAddress)}
                        helperText={touched?.staticIpDetail &&
                          touched?.staticIpDetail[index] &&
                          touched?.staticIpDetail[index]?.staticIpAddress &&
                          errors?.staticIpDetail &&
                          errors?.staticIpDetail[index] &&
                          errors?.staticIpDetail[index]?.staticIpAddress}
                      />
                  </Grid>
                  <Grid item xs={5.5} mt={0}>
                  <TextField
                        fullWidth
                        autoComplete="off"
                        label="Assigned To IP"
                        name={`staticIpDetail[${index}].assignedToIp`}
                        value={values.staticIpDetail[index]?.assignedToIp}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.staticIpDetail &&
                          touched?.staticIpDetail[index] &&
                          touched?.staticIpDetail[index]?.assignedToIp &&
                          errors?.staticIpDetail &&
                          errors?.staticIpDetail[index] &&
                          errors?.staticIpDetail[index]?.assignedToIp)}
                        helperText={touched?.staticIpDetail &&
                          touched?.staticIpDetail[index] &&
                          touched?.staticIpDetail[index]?.assignedToIp &&
                          errors?.staticIpDetail &&
                          errors?.staticIpDetail[index] &&
                          errors?.staticIpDetail[index]?.assignedToIp}
                          />
                  </Grid>
                 {index!==0&&(<IconButton  onClick={(e) => removeIpDetails(e, index)}>
                          <DeleteIcon color="error" />
                        </IconButton>)}
                  <Grid item xs={5.5} mt={0}>
                  <TextField
                        fullWidth
                        autoComplete="off"
                        label="Assigned To Domain"
                        name={`staticIpDetail[${index}].assignedToDomain`}
                        value={values.staticIpDetail[index]?.assignedToDomain}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.staticIpDetail &&
                          touched?.staticIpDetail[index] &&
                          touched?.staticIpDetail[index]?.assignedToDomain &&
                          errors?.staticIpDetail &&
                          errors?.staticIpDetail[index] &&
                          errors?.staticIpDetail[index]?.assignedToDomain)}
                        helperText={touched?.staticIpDetail &&
                          touched?.staticIpDetail[index] &&
                          touched?.staticIpDetail[index]?.assignedToDomain &&
                          errors?.staticIpDetail &&
                          errors?.staticIpDetail[index] &&
                          errors?.staticIpDetail[index]?.assignedToDomain}
                          />
                  </Grid>
                  <Grid item xs={5.5} mt={0}>
                  <Autocomplete
                      size="small"
                      
                      // value={{id: "Full Time",title: "Full Time"}}
                      fullWidth
                      onBlur={handleBlur}
                      options={ipTypeList || []}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`ipType`)}
                      onChange={(event, newValue) => {
                        
                        setFieldValue(`staticIpDetail[${index}].ipType`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="IP Type"
                          error={Boolean(touched?.staticIpDetail &&
                            touched?.staticIpDetail[index] &&
                            touched?.staticIpDetail[index]?.ipType &&
                            errors?.staticIpDetail &&
                            errors?.staticIpDetail[index] &&
                            errors?.staticIpDetail[index]?.ipType)}
                          helperText={touched?.staticIpDetail &&
                            touched?.staticIpDetail[index] &&
                            touched?.staticIpDetail[index]?.ipType &&
                            errors?.staticIpDetail &&
                            errors?.staticIpDetail[index] &&
                            errors?.staticIpDetail[index]?.ipType}
                           
                        />
                      )}
                      value={ipTypeList.find(
                        (dept) => dept.id === values.staticIpDetail[index].ipType
                      )}
                      error={true}
                    />
                  </Grid>
                  </>))
                }
                <Grid item xs={values.staticIp?6:4} mt={0}>
                <Stack justifyContent="center" sx={{display:'flex'}} alignItems={values.staticIp?'start':'center'}>
                <FormControlLabel  control={<Switch checked={values.fixedLine}/>} label="Fixed Line"   name='fixedLine' onChange={handleChange} />
                </Stack>
                </Grid>
                
                {values.fixedLine &&values &&
            values.ispFixedLine &&
            values.ispFixedLine.map((edu, index) => (
            <>{index === 0 && ( <Grid item xs={12} mt={0} sx={{display:'flex'}} justifyContent="flex-end">
              <Stack direction="row" justifyContent="space-between" sx={{width:'100%'}}>
              <Typography variant="h5" gutterBottom>
              Fixed Line Details 
              </Typography>
              <Button
              variant="contained"
              sx={{height: 'fit-content'}}
              onClick={AddFixedLine}
              startIcon={<AddIcon />}
            >
              Add Fixed Line
            </Button>
            </Stack>
            </Grid>)}
                  <Grid item xs={11} mt={0}>
                    
                  <TextField
                        fullWidth
                        autoComplete="off"
                        label="Phone Number"
                        name={`ispFixedLine[${index}].phoneNumber`}
                        value={values.ispFixedLine[index]?.phoneNumber}
                        inputProps={{ maxLength: 10 }}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.ispFixedLine &&
                          touched?.ispFixedLine[index] &&
                          touched?.ispFixedLine[index]?.phoneNumber &&
                          errors?.ispFixedLine &&
                          errors?.ispFixedLine[index] &&
                          errors?.ispFixedLine[index]?.phoneNumber)}
                        helperText={touched?.ispFixedLine &&
                          touched?.ispFixedLine[index] &&
                          touched?.ispFixedLine[index]?.phoneNumber &&
                          errors?.ispFixedLine &&
                          errors?.ispFixedLine[index] &&
                          errors?.ispFixedLine[index]?.phoneNumber}
                       />
                  </Grid>
                 {index!==0&&(<IconButton  onClick={(e) => removeFixedLine(e, index)}>
                          <DeleteIcon color="error" />
                        </IconButton>)}
                  </>))
                }
                <Grid item xs={12} mt={0}>
              <Typography variant="h5" gutterBottom>
              Router Information 
              </Typography>
            </Grid>
                <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="Gateway IP"
                    name="routerGatewayIp"
                    value={values.routerGatewayIp}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.routerGatewayIp && errors.routerGatewayIp)}
                    helperText={touched.routerGatewayIp && errors.routerGatewayIp}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="Username"
                    name="routerUsername"
                    value={values.routerUsername}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.routerUsername && errors.routerUsername)}
                    helperText={touched.routerUsername && errors.routerUsername}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    type={showRouterPassword ? "text" : "password"}
                    label="Password"
                    name="routerPassword"
                    value={values.routerPassword}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={(id&&showRouterPassword===false) ? true : false}
                    size="small"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowRouterPassword} edge="end">
                            {showRouterPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.routerPassword && errors.routerPassword)}
                    helperText={touched.routerPassword && errors.routerPassword}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>

              </Grid>
              <Grid item xs={12} mt={0}>
              <Typography variant="h5" gutterBottom>
              Wi-fi Details 
              </Typography>
            </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="SSID"
                    name="wifiSsid"
                    value={values.wifiSsid}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.wifiSsid && errors.wifiSsid)}
                    helperText={touched.wifiSsid && errors.wifiSsid}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    type={showWifiPassword ? "text" : "password"}
                    label="Password"
                    name="wifiPassword"
                    disabled={(id&&showWifiPassword===false) ? true : false}
                    value={values.wifiPassword}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowWifiPassword} edge="end">
                            {showWifiPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.wifiPassword && errors.wifiPassword)}
                    helperText={touched.wifiPassword && errors.wifiPassword}
                  />
              </Grid>
              <Grid item xs={12} mt={0}>
              <Typography variant="h5" gutterBottom>
              Portal Information 
              </Typography>
            </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="Account Relationship Number"
                    name="portalAccountRelationshipNumber"
                    value={values.portalAccountRelationshipNumber}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.portalAccountRelationshipNumber && errors.portalAccountRelationshipNumber)}
                    helperText={touched.portalAccountRelationshipNumber && errors.portalAccountRelationshipNumber}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="URL"
                    name="portalUrl"
                    value={values.portalUrl}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.portalUrl && errors.portalUrl)}
                    helperText={touched.portalUrl && errors.portalUrl}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    label="Username"
                    name="portalUsername"
                    value={values.portalUsername}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.portalUsername && errors.portalUsername)}
                    helperText={touched.portalUsername && errors.portalUsername}
                  />
              </Grid>
              <Grid item xs={6} mt={0}>
              <TextField
                    fullWidth
                    autoComplete="off"
                    type={showPortalPassword ? "text" : "password"}
                    label="Password"
                    name="portalPassword"
                    disabled={(id&&showPortalPassword===false) ? true : false}
                    value={values.portalPassword}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPortalPassword} edge="end">
                            {showPortalPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched.portalPassword && errors.portalPassword)}
                    helperText={touched.portalPassword && errors.portalPassword}
                  />
              </Grid>
              <Grid item xs={12} mt={0}>
                <Stack direction="row" justifyContent="space-between">
              <Typography variant="h5" gutterBottom>
              Support Person Details 
              </Typography>
              {values &&
            values.supportPerson &&
            values.supportPerson[0]? <Button
            variant="contained"
            sx={{height: 'fit-content'}}
            onClick={AddSupportPerson}
            startIcon={<AddIcon />}
          > 
            Add support person
          </Button>:''}
          </Stack>
            </Grid>
              {values &&
            values.supportPerson &&
            values.supportPerson.map((edu, index) => (
             
            <>
            {/* {index === 0 && (
              //  <Grid item xs={12} mt={0} sx={{display:'flex'}} justifyContent="flex-end">
              <Button
              variant="contained"
              sx={{height: 'fit-content'}}
              onClick={AddSupportPerson}
              startIcon={<AddIcon />}
            > 
              Add support person
            </Button>
            // </Grid>
            )} */}
            
                  <Grid item xs={5.5} mt={0}>
                    {/* {console.log(touched,errors)} */}
                  <TextField
                        fullWidth
                        autoComplete="off"
                        label="Name"
                        name={`supportPerson[${index}].name`}
                        value={values.supportPerson[index]?.name}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.supportPerson &&
                          touched?.supportPerson[index] &&
                          touched?.supportPerson[index]?.name &&
                          errors?.supportPerson &&
                          errors?.supportPerson[index] &&
                          errors?.supportPerson[index]?.name)}
                        helperText={touched?.supportPerson &&
                          touched?.supportPerson[index] &&
                          touched?.supportPerson[index]?.name &&
                          errors?.supportPerson &&
                          errors?.supportPerson[index] &&
                          errors?.supportPerson[index]?.name}
                      />
                  </Grid>
                  <Grid item xs={5.5} mt={0}>
                  <TextField
                        fullWidth
                        autoComplete="off"
                        label="Phone Number"
                        name={`supportPerson[${index}].phoneNumber`}
                        value={values.supportPerson[index]?.phoneNumber}
                        inputProps={{ maxLength: 10 }}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.supportPerson &&
                          touched?.supportPerson[index] &&
                          touched?.supportPerson[index]?.phoneNumber &&
                          errors?.supportPerson &&
                          errors?.supportPerson[index] &&
                          errors?.supportPerson[index]?.phoneNumber)}
                        helperText={touched?.supportPerson &&
                          touched?.supportPerson[index] &&
                          touched?.supportPerson[index]?.phoneNumber &&
                          errors?.supportPerson &&
                          errors?.supportPerson[index] &&
                          errors?.supportPerson[index]?.phoneNumber}
                        // error={touched?.supportPerson&&Boolean(touched?.supportPerson[index]?.phoneNumber && errors?.supportPerson[index]?.phoneNumber)}
                        // helperText={touched?.supportPerson&&touched?.supportPerson[index]?.phoneNumber && errors?.supportPerson[index]?.phoneNumber}
                      />
                  </Grid>
                 {index!==0&&(<IconButton  onClick={(e) => removeSupportPerson(e, index)}>
                          <DeleteIcon color="error" />
                        </IconButton>)}
                  </>))
                }
                <Grid item xs={12} mt={0}>
              <Typography variant="h5" gutterBottom>
              Custom Notes 
              </Typography>
            </Grid>
            <Grid item xs={6} mt={0}>
            <TextField
									fullWidth
									multiline
									aria-label="Note..."
                  name='notes'
                  onChange={handleChange}
									minRows={4}
									// placeholder="Minimum 3 rows"
									/* className="custom-textarea" */
									inputProps={{
										inputComponent: TextareaAutosize,
										maxLength: 512,
										style: {
											resize: "auto",
										},
									}}
									{...getFieldProps("notes")}
									error={Boolean(
										touched?.notes && errors?.notes,
									)}
									helperText={touched?.notes && errors?.notes}
								/>
            </Grid>
            </Grid>

            <Stack direction="row" justifyContent="flex-end" my={3}>
            <LoadingButton
							loading={isSubmitting}
							variant="contained"
							type="submit"
							sx={{ marginRight: "15px" }}
						>
							Save
						</LoadingButton>
            </Stack>
          {/* </form> */}
        </Paper>
      </Container>
    </Card>

    <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Stack sx={style}>
  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Varification Code"
                    name="ispName"
                    // value={values.ispName}
                    onChange={(e)=>{fildname=='routerPassword'?setRouterPasswordKey(e.target.value):fildname=='wifiPassword'?setWifiPasswordKey(e.target.value):setPortalPasswordKey(e.target.value)}}
                    variant="outlined"
                    size="small"
                  />
                  <Button variant="contained" onClick={handleVerify}>Submit</Button>
  </Stack>
</Modal>
</form>
  </Container>
  )
}


const componentConfig = [{
    component: AddIsp,
    path: "/isp/add",
    public: false,
    layout: DashboardLayout,
    group: "assMangment",
    sidebar: true,
    role: ["admin"],
  },{
    component: AddIsp,
    path: "/isp/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "assMangment",
    sidebar: true,
    role: ["admin"],
  }];

export default componentConfig