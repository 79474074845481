import React, { useState } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import DownloadIcon from '@mui/icons-material/Download';
import DialogContentText from '@mui/material/DialogContentText';

import LoadingButton from '@mui/lab/LoadingButton';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AddCompanyModal = NiceModal.create(({ name }) => {
	const modal = useModal();
	const [fileName, setFileName] = useState(name);

	const handleSubmit = () => {
		modal.resolve({ fileName });
		return modal.hide();
	};

	return (
		<Dialog
			TransitionComponent={Transition}
			open={modal.visible}
			onClose={() => modal.hide()}
			TransitionProps={{
				onExited: () => modal.remove(),
			}}
			fullWidth
		>
			<DialogTitle>Download PDF?</DialogTitle>
			<DialogContent>
				<DialogContentText>
					The following file will be exported with below name. Do you want to edit the
					file name?
				</DialogContentText>
				<TextField
					label="File name"
					fullWidth
					value={fileName}
					name="fileName"
					required
					variant="standard"
					onChange={(e) => setFileName(e.target.value)}
				/>
			</DialogContent>
			<DialogActions>
				<Button color="error" variant="contained" onClick={() => modal.hide()} >
					Close
				</Button>
				<LoadingButton
					endIcon={<DownloadIcon />}
					onClick={handleSubmit}
					variant="contained"
				>
					Download
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
});

export default AddCompanyModal;
