import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import NiceModal from "@ebay/nice-modal-react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddCompanyModal from "../../../../components/Model/AddCompanyModal";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { getCurrentMonthHolidayList } from "../../../../redux/dashboard/dashboardThunk";
import {
  addMonthsExactPosition,
  addMonths,
  getDay,
  getArrayOfDates,
} from "../../../../utils/formatTime";
import {
  getTLandPMByDepartmentIds,
  getEmployeeForDropDown,
  getRelievingDateValidation,
} from "../../../../redux/employee/employeeThunk";
import { getViewVisible } from "../../../../utils/userPermission";
import { getTeamDropdown } from "../../../../redux/team/teamThunk";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";

const filter = createFilterOptions();

const experienceObj = {
  companyId: "",
  designation: "",
  technologies: [],
  from: "",
  to: "",
  reasonForJobChange: "",
  totalExperience: null,
  isDateVisible: "",
  isPresentVisible: "",
};

const commitmentDuration = ["0", "1", "1.6"];
const noticePeriodData = ["1", "2", "3"];
const appraisalCycleData = ["July", "By Production Date"];

export default function Experience(props) {
  const dispatch = useDispatch();

  const designationData = useSelector((state) => state.designation);
  const technologyData = useSelector((state) => state.technology);
  const jobChangeData = useSelector((state) => state.jobChange);
  const departmentData = useSelector((state) => state.department);
  const companyData = useSelector((state) => state.company);
  const teamData = useSelector((state) => state.team.teamDropdown);

  const [showTLPM, setShowTLPM] = useState({ TL: true, PM: true });

  const allEmployeeData = useSelector(
    (state) => state.employee.allEmployeeData
  );

  const {
    values,
    setFieldValue,
    touched,
    errors,
    handleTabChange,
    getFieldProps,
    setTLandPM,
    TLandPM,
    id,
    relievingDateValidations
  } = props;

  useEffect(() => {
    if (id && values.workDetails?.designation?.title) {
      const title = values.workDetails?.designation?.title?.toLowerCase();
      if (title === "team lead") {
        setShowTLPM({ TL: false, PM: true });
      } else if (title === "project manager") {
        setShowTLPM({ TL: false, PM: false });
      }
    }
    if (id && values.departments?.length > 0) {
      handleOnDepartmentChange(values.departments);
    }
  }, []);

  const addExperience = () => {
    setFieldValue("experience", [...values.experience, experienceObj]);
  };

  const removeExperience = (e, index) => {
    e.preventDefault();
    const temp = [...values.experience];
    temp.splice(index, 1);
    setFieldValue("experience", temp);
  };

  const handleJoinAndProductionDate = (date) => {
    setFieldValue("workDetails.joiningDate", date);
    let flag = false;
    let months = 15 > getDay(date) ? 3 : 4;

    let getDate = addMonths(date, months);
    dispatch(
      getCurrentMonthHolidayList({
        date: moment(getDate).format("YYYY-MM"),
      })
    )
      .unwrap()
      .then((data) => {
        if (data?.holidayData.length === 0) {
          setFieldValue("workDetails.commitmentStartDate", getDate);
          return setFieldValue("workDetails.productionDate", getDate);
        }
        data?.holidayData.sort(function (a, b) {
          return new Date(a.start) - new Date(b.start);
        });

        for (let index = 0; index < data?.holidayData.length; index++) {
          const element = data?.holidayData[index];
          if (
            moment(getDate).format("DD-MM-YYYY") !=
            moment(element.start).format("DD-MM-YYYY")
          ) {
            flag = true;
            setFieldValue("workDetails.productionDate", getDate);
            setFieldValue("workDetails.commitmentStartDate", getDate);
            break;
          } else {
            getDate = moment(getDate).add(1, "d");
          }
        }
        if (!flag) {
          setFieldValue("workDetails.productionDate", getDate);
          setFieldValue("workDetails.commitmentStartDate", getDate);
        }
      })
      .catch((err) => {});
  };

  const handleOnDepartmentChange = async (value) => {
    setFieldValue("departments", value);
    try {
      const response = await dispatch(
        getTLandPMByDepartmentIds(value)
      ).unwrap();
      setTLandPM({ tl: response.teamLead, pm: response.projectManager });
    } catch (error) {
      setTLandPM({ tl: [], pm: [] });
    }
  };

  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeamDropdown({}));
  }, [dispatch]);

  console.log("team data", teamData);

  const handleTLPM = (newValue) => {
    const id = newValue.id;
    const result = designationData.data.find((ele) => ele.id === id);

    // console.log(id);
    // console.log(result);

    if (
      result &&
      (result.title.toLowerCase() === "team lead" ||
        result.title.toLowerCase() === "team leader" ||
        result.title.toLowerCase() === "tl")
    ) {
      setShowTLPM({ TL: false, PM: true });
      setFieldValue(`workDetails.teamLead`, null);
    } else if (
      result &&
      (result.title.toLowerCase() === "project manager" ||
        result.title.toLowerCase() === "pm")
    ) {
      setShowTLPM({ TL: false, PM: false });
      setFieldValue(`workDetails.teamLead`, null);
      setFieldValue(`workDetails.projectManager`, null);
    } else {
      setShowTLPM({ TL: true, PM: true });
    }

    setFieldValue("workDetails.designationId", id);
  };

  const handleRelievingDate = (date) => {
    setFieldValue("workDetails.resignationAcceptanceDate", date);
    let getDate = addMonthsExactPosition(date, values.workDetails.noticePeriod);
    if (moment(getDate).format("YYYY-MM").length === 7) {
      calculateRelievingDate(date, values.workDetails.noticePeriod);
    }
  };

  const calculateRelievingDate = async (date, noticePeriod) => {
    let getDate = addMonthsExactPosition(date, noticePeriod);
    let flag = false;
    const data = await dispatch(
      getCurrentMonthHolidayList({
        date: moment(getDate).format("YYYY-MM"),
      })
    ).unwrap();
    if (data?.holidayData.length === 0) {
      return setFieldValue("workDetails.relievingDate", getDate);
    }
    for (let index = 0; index < data?.holidayData.length; index++) {
      const element = data?.holidayData[index];
      if (
        moment(getDate).format("DD-MM-YYYY") !=
        moment(element.start).format("DD-MM-YYYY")
      ) {
        flag = true;
        setFieldValue("workDetails.relievingDate", getDate);
        break;
      } else {
        getDate = moment(getDate).add(1, "d");
      }
    }
    if (!flag) {
      setFieldValue("workDetails.relievingDate", getDate);
    }
  };

  const handleNoticePeriod = async (e) => {
    const valueNotice = e.target.value;
    setFieldValue("workDetails.noticePeriod", valueNotice);
    if (values.workDetails.resignationAcceptanceDate) {
      calculateRelievingDate(
        values.workDetails.resignationAcceptanceDate,
        valueNotice
      );
    }
  };
  return (
    <Card className="right-side-content-part">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={3}
          className="Employee_Detail-heading"
        >
          <Typography
            className="Employee_Detail-h6"
            variant="h6"
            gutterBottom
            style={{ marginTop: "0" }}
          >
            Work
          </Typography>
        </Stack>

        <Grid
          className="gray-box Employee_Detail_Content work_wrapper_grid"
          container
          spacing={3}
        >
          <Grid className="grid-item" item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Joining/Probation Date"
                value={values.workDetails.joiningDate}
                inputFormat="dd/MM/yyyy"
                // minDate={new Date('2017-01-01')}
                // onChange={(newValue) => {
                //   setFieldValue("workDetails.joiningDate", newValue);
                // }}
                onChange={(newValue) => handleJoinAndProductionDate(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.joiningDate &&
                        errors?.workDetails &&
                        errors.workDetails?.joiningDate
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.joiningDate &&
                      errors?.workDetails &&
                      errors.workDetails.joiningDate
                    }
                    fullWidth
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid className="grid-item" item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Re Joining Date"
                value={values.workDetails.reJoiningDate}
                inputFormat="dd/MM/yyyy"
                // minDate={new Date('2017-01-01')}
                onChange={(newValue) => {
                  setFieldValue("workDetails.reJoiningDate", newValue);
                  setFieldValue("workDetails.resignationAcceptanceDate", null);
                  setFieldValue("workDetails.relievingDate", null);
                  setFieldValue("workDetails.resignationDate", null);
                  setFieldValue("workDetails.bondDuration", "");
                  handleJoinAndProductionDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.reJoiningDate &&
                        errors?.workDetails &&
                        errors.workDetails?.reJoiningDate
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.reJoiningDate &&
                      errors?.workDetails &&
                      errors.workDetails.reJoiningDate
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid className="grid-item" item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Production Date"
                value={values.workDetails.productionDate}
                inputFormat="dd/MM/yyyy"
                // minDate={new Date('2017-01-01')}
                onChange={(newValue) => {
                  setFieldValue("workDetails.productionDate", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.productionDate &&
                        errors?.workDetails &&
                        errors.workDetails?.productionDate
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.productionDate &&
                      errors?.workDetails &&
                      errors.workDetails.productionDate
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          {values.workDetails.bondDuration !== "0" ? (
            <Grid className="grid-item" item xs={4}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  label="Commitment Start Date"
                  value={values.workDetails.commitmentStartDate}
                  inputFormat="dd/MM/yyyy"
                  // minDate={new Date('2017-01-01')}
                  onChange={(newValue) => {
                    setFieldValue("workDetails.commitmentStartDate", newValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      size="small"
                      error={Boolean(
                        touched?.workDetails &&
                          touched.workDetails?.commitmentStartDate &&
                          errors?.workDetails &&
                          errors.workDetails?.commitmentStartDate
                      )}
                      helperText={
                        touched?.workDetails &&
                        touched.workDetails?.commitmentStartDate &&
                        errors?.workDetails &&
                        errors.workDetails.commitmentStartDate
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          ) : (
            ""
          )}
          <Grid className="grid-item" item xs={4}>
            {/*<InputLabel id="demo-simple-select-helper-label">
                Technology
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Technology"
                defaultValue=""
                multiple
                size="small"
                {...getFieldProps(`workDetails.technologies`)}
              >
                {technologyData.data &&
                  technologyData.data.map((tech) => {
                    return (
                      <MenuItem key={tech.id} value={tech.id}>
                        {tech.title}
                      </MenuItem>
                    );
                  })}
                </Select> */}
            <Autocomplete
              multiple
              size="small"
              fullWidth
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              defaultValue=""
              options={technologyData.data || []}
              key={technologyData.data || []}
              getOptionLabel={(option) => option.title || ""}
              {...getFieldProps(`workDetails.technologies`)}
              onChange={(event, newValue) => {
                let tempArray = newValue.map((x) => x.id);
                setFieldValue(`workDetails.technologies`, tempArray || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Technology"
                  error={Boolean(
                    touched?.workDetails &&
                      touched?.workDetails?.technologyId &&
                      errors?.workDetails &&
                      errors?.workDetails?.technologyId
                  )}
                  helperText={
                    touched?.workDetails &&
                    touched?.workDetails.technologyId &&
                    errors?.workDetails &&
                    errors.workDetails?.technologyId
                  }
                />
              )}
              value={technologyData.data.filter((x) =>
                values.workDetails.technologies.includes(x.id)
              )}
            />
          </Grid>
          <Grid className="grid-item" item xs={4}>
            {/*<InputLabel id="demo-simple-select-helper-label">
                Department
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Designation"
                size="small"
                multiple
                name="departments"
                onChange={(e) => handleOnDepartmentChange(e.target.value)}
                value={values.departments}
              >
                {departmentData.data &&
                  departmentData.data.map((dept) => {
                    return (
                      <MenuItem key={dept.id} value={dept.id}>
                        {dept.title}
                      </MenuItem>
                    );
                  })}
                </Select> */}
            <Autocomplete
              multiple
              disabled={getViewVisible("department") === false}
              size="small"
              fullWidth
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              defaultValue=""
              options={departmentData.data || []}
              key={departmentData.data || []}
              getOptionLabel={(option) => option.title || ""}
              {...getFieldProps(`departments`)}
              onChange={(event, newValue) => {
                let tempArray = newValue.map((x) => x.id);

                handleOnDepartmentChange(tempArray);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Department"
                  error={Boolean(touched?.departments && errors?.departments)}
                  helperText={touched?.departments && errors?.departments}
                />
              )}
              value={departmentData.data.filter((x) =>
                values.departments.includes(x.id)
              )}
            />
          </Grid>
          <Grid className="grid-item" item xs={4}>
            <Autocomplete
              size="small"
              fullWidth
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              options={designationData.data || []}
              getOptionLabel={(option) => option.title || ""}
              {...getFieldProps(`workDetails.designationId`)}
              onChange={
                (event, newValue) => {
                  handleTLPM(newValue);
                }
                //

                // 	if (newValue?.id === 1) {
                // 		setFieldValue(`workDetails.teamLead`, null);
                // 	}

                // 	if (newValue?.id === 2) {
                //
                // 	}
                // }
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Designation"
                  error={Boolean(
                    touched?.workDetails &&
                      touched?.workDetails.designationId &&
                      errors?.workDetails &&
                      errors?.workDetails.designationId
                  )}
                  helperText={
                    touched?.workDetails &&
                    touched?.workDetails.designationId &&
                    errors?.workDetails &&
                    errors?.workDetails.designationId
                  }
                />
              )}
              value={designationData.data.find(
                (designation) =>
                  designation.id === values.workDetails.designationId
              )}
            />
          </Grid>
          {/* {showTLPM.TL && ( */}
          <Grid className="grid-item" item xs={4}>
            <Autocomplete
              labelid="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              defaultValue=""
              fullWidth
              options={allEmployeeData || []}
              getOptionLabel={(option) =>
                option ? `${option?.firstName} ${option?.lastName}` : ""
              }
              renderOption={(props, option) => (
                <li {...props} value={option.id} key={option.id}>
                  {option.firstName + " " + option.lastName || ""}
                </li>
              )}
              {...getFieldProps(`workDetails.teamLead`)}
              onChange={(event, newValue) => {
                setFieldValue(`workDetails.teamLead`, newValue?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Lead"
                  error={
                    touched?.workDetails &&
                    touched?.workDetails.teamLead &&
                    errors?.workDetails &&
                    errors?.workDetails.teamLead
                  }
                  helperText={
                    touched?.workDetails &&
                    touched.workDetails?.teamLead &&
                    errors?.workDetails &&
                    errors.workDetails?.teamLead
                  }
                />
              )}
              value={
                allEmployeeData?.find(
                  (lead) => lead?.id == values?.workDetails?.teamLead
                ) || ""
              }
            />
          </Grid>
          {/* )} */}
          {showTLPM.PM && (
            <Grid className="grid-item" item xs={4}>
              {/*<InputLabel id="demo-simple-select-helper-label">
                  Project Manager
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Project Manager"
                  size="small"
                  defaultValue=""
                  {...getFieldProps(`workDetails.projectManager`)}
                >
                  {TLandPM.pm.map((name) => {
                    return (
                      <MenuItem
                        key={name.id}
                        value={name.id}
                      >{`${name.firstName} ${name.lastName}`}</MenuItem>
                    );
                  })}
                </Select>*/}
              <Autocomplete
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                size="small"
                popupIcon={
                  <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                }
                defaultValue=""
                fullWidth
                options={TLandPM.pm || []}
                getOptionLabel={(option) =>
                  option ? `${option?.firstName} ${option?.lastName}` : ""
                }
                {...getFieldProps(`workDetails.projectManager`)}
                onChange={(event, newValue) => {
                  setFieldValue(
                    `workDetails.projectManager`,
                    newValue?.id || ""
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Project Manager"
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.projectManager &&
                        errors?.workDetails &&
                        errors.workDetails?.projectManager
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.projectManager &&
                      errors?.workDetails &&
                      errors.workDetails?.projectManager
                    }
                  />
                )}
                value={
                  TLandPM.pm.find(
                    (lead) => lead.id == values.workDetails.projectManager
                  ) || ""
                }
                error={true}
              />
            </Grid>
          )}

          <Grid className="grid-item" item xs={4}>
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              // defaultValue=""
              fullWidth
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              options={teamData || []}
              getOptionLabel={(option) => option?.title}
              {...getFieldProps(`workDetails.teamId`)}
              onChange={(event, newValue) => {
                setFieldValue(`workDetails.teamId`, newValue?.id || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Team"
                  error={Boolean(
                    touched?.workDetails &&
                      touched.workDetails?.teamId &&
                      errors?.workDetails &&
                      errors.workDetails?.teamId
                  )}
                  helperText={
                    touched?.workDetails &&
                    touched.workDetails?.teamId &&
                    errors?.workDetails &&
                    errors.workDetails?.teamId
                  }
                />
              )}
              value={
                teamData?.find((t) => t.id == values.workDetails.teamId) || null
              }
              error={true}
            />
          </Grid>

          <Grid className="grid-item" item xs={4}>
            {/*<InputLabel id="demo-simple-select-helper-label">
                Commitment Duration
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Commitment Duration"
                size="small"
                defaultValue=""
                {...getFieldProps(`workDetails.bondDuration`)}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="1.6">1.6</MenuItem>
              </Select>*/}
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              name="bondDuration"
              //value={commitmentDuration.find(
              //  (ac) => ac.text == values.workDetails.bondDuration) }
              onChange={(event, newValue) => {
                if (newValue === "0") {
                  setFieldValue("workDetails.commitmentStartDate", null);
                } else {
                  setFieldValue(
                    "workDetails.commitmentStartDate",
                    values.workDetails.productionDate
                  );
                }

                setFieldValue("workDetails.bondDuration", newValue || "");
              }}
              value={values.workDetails.bondDuration}
              options={commitmentDuration || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Commitment Duration"
                  error={Boolean(
                    touched?.workDetails &&
                      touched.workDetails?.bondDuration &&
                      errors?.workDetails &&
                      errors.workDetails?.bondDuration
                  )}
                  helperText={
                    touched?.workDetails &&
                    touched.workDetails?.bondDuration &&
                    errors?.workDetails &&
                    errors.workDetails?.bondDuration
                  }
                />
              )}
            />
          </Grid>
          <Grid className="grid-item" item xs={4}>
            {/*<InputLabel id="demo-simple-select-helper-label">
                Notice Periods (In Months)
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Notice Periods (In Months)"
                size="small"
                defaultValue=""
                name="workDetails.noticePeriod"
                // {...getFieldProps(`workDetails.noticePeriod`)}
                onChange={handleNoticePeriod}
                value={values.workDetails.noticePeriod}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
              </Select>*/}
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              name="noticePeriod"
              {...getFieldProps("workDetails.noticePeriod")}
              //value={noticePeriodData.find(
              //  (ac) => ac.text == values.workDetails.noticePeriod) }

              onChange={(event, newValue) => {
                setFieldValue("workDetails.noticePeriod", newValue || "");
              }}
              value={values.workDetails.noticePeriod}
              options={noticePeriodData || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Notice Periods (In Months)"
                  error={Boolean(
                    touched?.workDetails &&
                      touched.workDetails?.noticePeriod &&
                      errors?.workDetails &&
                      errors.workDetails?.noticePeriod
                  )}
                  helperText={
                    touched?.workDetails &&
                    touched.workDetails?.noticePeriod &&
                    errors?.workDetails &&
                    errors.workDetails?.noticePeriod
                  }
                />
              )}
            />
          </Grid>
          <Grid className="grid-item" item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Resignation Date"
                value={values.workDetails.resignationDate}
                onChange={(newValue) => {
                  setFieldValue("workDetails.resignationDate", newValue);
                }}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.resignationDate &&
                        errors?.workDetails &&
                        errors.workDetails?.resignationDate
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.resignationDate &&
                      errors?.workDetails &&
                      errors.workDetails.resignationDate
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid className="grid-item" item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Resignation Acceptance Date"
                value={values.workDetails.resignationAcceptanceDate}
                // minDate={new Date('2017-01-01')}
                inputFormat="dd/MM/yyyy"
                onChange={(newValue) => {
                  handleRelievingDate(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.resignationAcceptanceDate &&
                        errors?.workDetails &&
                        errors.workDetails?.resignationAcceptanceDate
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.resignationAcceptanceDate &&
                      errors?.workDetails &&
                      errors.workDetails.resignationAcceptanceDate
                    }
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid className="grid-item" item xs={4}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Relieving date"
                value={values.workDetails.relievingDate}
                inputFormat="dd/MM/yyyy"
                // minDate={new Date('2017-01-01')}
                onChange={(newValue) => {
                  setFieldValue("workDetails.relievingDate", newValue);
                  setFieldValue("workDetails.reJoiningDate", null);
                  dispatch(getRelievingDateValidation(moment(newValue).format("DD/MM/YYYY")))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={Boolean(
                      touched?.workDetails &&
                        touched.workDetails?.relievingDate &&
                        errors?.workDetails &&
                        errors.workDetails?.relievingDate
                    )}
                    helperText={
                      touched?.workDetails &&
                      touched.workDetails?.relievingDate &&
                      errors?.workDetails &&
                      errors.workDetails.relievingDate
                    }
                  />
                )}
              />
              <div style={{ color: 'red', fontSize: '13px', marginLeft: '10px' }}>
                {relievingDateValidations?.isWeekend && !relievingDateValidations?.isHoliday && 'Relieving is not applicable on weekend'}
                {relievingDateValidations?.isHoliday && !relievingDateValidations?.isWeekend && 'Relieving is not applicable on public holiday'}
                {relievingDateValidations?.isHoliday && relievingDateValidations?.isWeekend && 'Relieving is not applicable on weekend'}
              </div>
            </LocalizationProvider>
          </Grid>
          <Grid className="grid-item" item xs={4}>
            {/*<InputLabel id="demo-simple-select-helper-label">
                Appraisal Cycle
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                label="Appraisal Cycle"
                size="small"
                defaultValue=""
                {...getFieldProps("workDetails.appraisalCycle")}
              >
                <MenuItem value="april">April</MenuItem>
                <MenuItem value="july">July</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select> */}

            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              name="appraisalCycle"
              options={appraisalCycleData || []}
              {...getFieldProps("workDetails.appraisalCycle")}
              //value={appraisalCycleData.find(
              //  (ac) => ac.text == values.workDetails.appraisalCycle) }
              value={values.workDetails.appraisalCycle}
              onChange={(event, newValue) => {
                setFieldValue(`workDetails.appraisalCycle`, newValue || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Appraisal Cycle"
                  error={Boolean(
                    touched?.workDetails &&
                      touched.workDetails?.appraisalCycle &&
                      errors?.workDetails &&
                      errors.workDetails?.appraisalCycle
                  )}
                  helperText={
                    touched?.workDetails &&
                    touched.workDetails?.appraisalCycle &&
                    errors?.workDetails &&
                    errors.workDetails?.appraisalCycle
                  }
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={4}>
							<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
								<InputLabel id="demo-simple-select-helper-label">
									Employee Type
								</InputLabel>
								<Select
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									label="Employee Type"
									size="small"
                  {...getFieldProps('workDetails.employeeType')}

								>
									<MenuItem value={10}>Full Time</MenuItem>
									<MenuItem value={20}>Half Time</MenuItem>
									<MenuItem value={30}>Part Time</MenuItem>
								</Select>
							</FormControl>
						</Grid> */}
          {/* <Grid item xs={4}>
							<TextField
								fullWidth
								label="Current CTC"
								variant="outlined"
								size="small"
								{...getFieldProps(`workDetails.currentCTC`)}
								error={Boolean(
									touched?.workDetails &&
										touched?.workDetails?.currentCTC &&
										errors?.workDetails &&
										errors.workDetails.currentCTC
								)}
								helperText={
									touched?.workDetails &&
									touched.workDetails.currentCTC &&
									errors?.workDetails &&
									errors.workDetails.currentCTC
								}
							/>
						</Grid>*/}
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={3}
          className="Employee_Detail-heading"
        >
          <Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
            Experience Details
          </Typography>
          <Button
            className="header-add-button"
            onClick={addExperience}
            variant="contained"
            // startIcon={<AddIcon />}
          >
            <img src="/assets/images/add-circle.svg" />
            Add Experience
          </Button>
        </Stack>
        {values &&
          values.experience &&
          values.experience.map((ex, index) => (
            <Stack key={index}>
              <Grid
                className="gray-box Employee_Detail_Content work-experience-detail-wrapper"
                container
                spacing={3}
              >
                <Grid className="grid-item" item xs={4}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/*<InputLabel id="demo-simple-select-helper-label">
                      Company
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Company"
                      size="small"
                      {...getFieldProps(`experience[${index}].companyId`)}
                    >
                      {companyData.companyForDropdown &&
                        companyData.companyForDropdown.map((company) => {
                          return (
                            <MenuItem key={company.id} value={company.id}>
                              {company.name}
                            </MenuItem>
                          );
                        })}
                      </Select>*/}

                    <Autocomplete
                      disabled={getViewVisible("company") === false}
                      value={
                        companyData.companyForDropdown.find(
                          (cmpy) =>
                            cmpy.id === values.experience[index].companyId
                        ) ?? ""
                      }
                      onChange={async (event, newValue) => {
                        event.preventDefault();
                        if (typeof newValue === "string") {
                          // timeout to avoid instant validation of the dialog's form.
                          setTimeout(async () => {
                            const res = await NiceModal.show(AddCompanyModal, {
                              name: newValue,
                            });
                            setFieldValue(
                              `experience[${index}].companyId`,
                              res.id
                            );
                          });
                        } else if (newValue && newValue.inputValue) {
                          const res = await NiceModal.show(AddCompanyModal, {
                            name: newValue.inputValue,
                          });
                          setFieldValue(
                            `experience[${index}].companyId`,
                            res.id
                          );
                        } else {
                          setFieldValue(
                            `experience[${index}].companyId`,
                            newValue?.id
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        if (params.inputValue !== "") {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      options={companyData.companyForDropdown || []}
                      getOptionLabel={(option) => {
                        // e.g value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }

                        if (option.inputValue) {
                          return option.name;
                        }
                        return option.name;
                      }}
                      selectOnFocus
                      handleHomeEndKeys
                      renderOption={(props, option) => (
                        <li {...props} value={option.id} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      fullWidth
                      size="small"
                      freeSolo
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Company"
                          error={Boolean(
                            touched?.experience &&
                              touched?.experience[index] &&
                              touched?.experience[index]?.companyId &&
                              errors?.experience &&
                              errors?.experience[index] &&
                              errors?.experience[index]?.companyId
                          )}
                          helperText={
                            touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.companyId &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.companyId
                          }
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* changes for designation */}
                <Grid className="grid-item" item xs={4}>
                  {/* <TextField
										fullWidth
										label="Designation"
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 128 }}
										{...getFieldProps(`experience[${index}].designation`)}
										error={Boolean(
											touched?.experience &&
												touched?.experience[index] &&
												touched?.experience[index]?.designation &&
												errors?.experience &&
												errors?.experience[index] &&
												errors?.experience[index]?.designation,
										)}
										helperText={
											touched?.experience &&
											touched?.experience[index] &&
											touched?.experience[index]?.designation &&
											errors?.experience &&
											errors?.experience[index] &&
											errors?.experience[index]?.designation
										}
									/> */}
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={designationData.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    {...getFieldProps(`experience[${index}].designation`)}
                    onChange={(event, newValue) => {
                      if (newValue.inputValue) {
                        setFieldValue(
                          `experience[${index}].designation`,
                          newValue?.inputValue || ""
                        );
                      } else {
                        setFieldValue(
                          `experience[${index}].designation`,
                          newValue?.title || ""
                        );
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.designation &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.designation
                        )}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.designation &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.designation
                        }
                        label="Designation"
                      />
                    )}
                    value={{ title: values.experience[index].designation }}
                    error={Boolean(true)}
                  />
                </Grid>
                {/* <Grid item xs={4}>
									<FormControl
										error={Boolean(
											touched?.experience &&
												touched?.experience[index] &&
												touched?.experience[index]?.designation &&
												errors?.experience &&
												errors?.experience[index] &&
												errors?.experience[index]?.designation
										)}
										size="small"
										sx={{ minWidth: 120 }}
										fullWidth
									>
										<InputLabel id="demo-simple-select-helper-label">
											Designation
										</InputLabel>
										<Select
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											label="Designation"
											size="small"
											{...getFieldProps(`experience[${index}].designation`)}
										>
											{designationData.data &&
												designationData.data.map((desig) => {
													return (
														<MenuItem
															key={desig.id}
															value={desig.title}
														>
															{desig.title}
														</MenuItem>
													);
												})}
										</Select>
										<FormHelperText>
											{touched?.experience &&
												touched?.experience[index] &&
												touched?.experience[index]?.designation &&
												errors?.experience &&
												errors?.experience[index] &&
												errors?.experience[index]?.designation}
										</FormHelperText>
									</FormControl>
								</Grid> */}
                <Grid className="grid-item" item xs={3}>
                  {/*<InputLabel id="demo-simple-select-helper-label">
                      Technology
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Technology"
                      size="small"
                      multiple
                      {...getFieldProps(`experience[${index}].technologies`)}
                    >
                      {technologyData.data &&
                        technologyData.data.map((tech) => {
                          return (
                            <MenuItem key={tech.id} value={tech.id}>
                              {tech.title}
                            </MenuItem>
                          );
                        })}
                      </Select> */}
                  <Autocomplete
                    multiple
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    defaultValue=""
                    options={technologyData.data || []}
                    key={technologyData.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    {...getFieldProps(`experience[${index}].technologies`)}
                    onChange={(event, newValue) => {
                      let tempArray = newValue.map((x) => x.id);
                      setFieldValue(
                        `experience[${index}].technologies`,
                        tempArray || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Technology"
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.technologies &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.technologies
                        )}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.technologies &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.technologies
                        }
                      />
                    )}
                    value={technologyData.data.filter((x) =>
                      values.experience[index].technologies.includes(x.id)
                    )}
                  />
                </Grid>
                <Grid className="grid-item" item xs={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                  >
                    {values &&
                      values.experience &&
                      Array.isArray(values.experience) && (
                        <IconButton onClick={(e) => removeExperience(e, index)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      )}
                  </Stack>
                </Grid>
                <Grid className="grid-item" item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      views={["month", "year"]}
                      label="From"
                      name={`values.experience[${index}].from`}
                      value={values.experience[index].from}
                      inputFormat="MM/yyyy"
                      // minDate={new Date('2017-01-01')}
                      onChange={(newValue) => {
                        setFieldValue(`experience[${index}].from`, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.from &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.from
                          }
                          error={Boolean(
                            touched?.experience &&
                              touched?.experience[index] &&
                              touched?.experience[index]?.from &&
                              errors?.experience &&
                              errors?.experience[index] &&
                              errors?.experience[index]?.from
                          )}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid className="grid-item" item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      views={["month", "year"]}
                      label="To"
                      name={`values.experience[${index}].to`}
                      value={values.experience[index].to}
                      inputFormat="MM/yyyy"
                      // minDate={new Date('2017-01-01')}
                      onChange={(newValue) => {
                        setFieldValue(`experience[${index}].to`, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={
                            touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.to &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.to
                          }
                          error={Boolean(
                            touched?.experience &&
                              touched?.experience[index] &&
                              touched?.experience[index]?.to &&
                              errors?.experience &&
                              errors?.experience[index] &&
                              errors?.experience[index]?.to
                          )}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid className="grid-item" item xs={4}>
                  {/*<InputLabel id="demo-simple-select-helper-label">
                      Reason for Job Change
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Reason for Job Change"
                      size="small"
                      {...getFieldProps(
                        `experience[${index}].reasonForJobChange`
                      )}
                    >
                      {jobChangeData.data &&
                        jobChangeData.data.map((jobch) => {
                          return (
                            <MenuItem key={jobch.id} value={jobch.id}>
                              {jobch.title}
                            </MenuItem>
                          );
                        })}
                      </Select>*/}

                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={jobChangeData.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    {...getFieldProps(
                      `experience[${index}].reasonForJobChange`
                    )}
                    onChange={(event, newValue) => {
                      //   console.log(newValue);
                      setFieldValue(
                        `experience[${index}].reasonForJobChange`,
                        newValue?.id || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reason For Job Change"
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.reasonForJobChange &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.reasonForJobChange
                        )}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.reasonForJobChange &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.reasonForJobChange
                        }
                      />
                    )}
                    value={jobChangeData.data.find(
                      (x) => x.id == values.experience[index].reasonForJobChange
                    )}
                  />
                </Grid>
                {/* <Grid item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Total Experience"
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 4 }}
                                        helperText={
                                            touched?.experience &&
                                            touched?.experience[index] &&
                                            touched?.experience[index]
                                                ?.totalExperience &&
                                            errors?.experience &&
                                            errors?.experience[index] &&
                                            errors?.experience[index]
                                                ?.totalExperience
                                        }
                                        error={Boolean(
                                            touched?.experience &&
                                                touched?.experience[index] &&
                                                touched?.experience[index]
                                                    ?.totalExperience &&
                                                errors?.experience &&
                                                errors?.experience[index] &&
                                                errors?.experience[index]
                                                    ?.totalExperience
                                        )}
                                        {...getFieldProps(
                                            `experience[${index}].totalExperience`
                                        )}
                                    />
                                </Grid> */}
              </Grid>
              <Stack my={3}>
                <Divider />
              </Stack>
            </Stack>
          ))}

        {/* <form>
                    <Typography variant="h6" gutterBottom mb={3} mt={4}>
                        Salary
                    </Typography>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    label="Last Increment Date"
                                    value={values.salary.lastIncrementDate}
                                    onChange={(newValue) => {
                                        setFieldValue(`salary.lastIncrementDate`, newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            error={Boolean(
                                                touched?.salary?.lastIncrementDate &&
                                                errors?.salary?.lastIncrementDate
                                            )}
                                            helperText={
                                                touched?.salary?.lastIncrementDate &&
                                                errors?.salary?.lastIncrementDate
                                            }
                                            fullWidth
                                            size="small"
                                        />
                                    )}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Salary Before Increment (Per Month)"
                                variant="outlined"
                                size="small"
                                {...getFieldProps(`salary.salaryBeforeIncrement`)}
                                error={Boolean(
                                    touched?.salary?.salaryBeforeIncrement &&
                                    errors?.salary?.salaryBeforeIncrement
                                )}
                                helperText={
                                    touched?.salary?.salaryBeforeIncrement &&
                                    errors?.salary?.salaryBeforeIncrement
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Current CTC (per month)"
                                variant="outlined"
                                size="small"
                                error={Boolean(
                                    touched?.salary?.currentCtc && errors?.salary?.currentCtc
                                )}
                                helperText={touched?.salary?.currentCtc && errors?.salary?.currentCtc}
                                {...getFieldProps(`salary.currentCtc`)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Expected CTC (per month)"
                                variant="outlined"
                                size="small"
                                {...getFieldProps(`salary.expectedCtc`)}
                                error={Boolean(
                                    touched?.salary?.expectedCtc && errors?.salary?.expectedCtc
                                )}
                                helperText={touched?.salary?.expectedCtc && errors?.salary?.expectedCtc}

                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Status"
                                variant="outlined"
                                size="small"
                                error={Boolean(touched?.salary?.status && errors?.salary?.status)}
                                helperText={touched?.salary?.status && errors?.salary?.status}
                                {...getFieldProps(`salary.status`)}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Add Notice Period (in days)"
                                variant="outlined"
                                size="small"
                                {...getFieldProps(`salary.addNoticePeriod`)}
                                error={Boolean(
                                    touched?.salary?.addNoticePeriod && errors?.salary?.addNoticePeriod
                                )}
                                helperText={
                                    touched?.salary?.addNoticePeriod && errors?.salary?.addNoticePeriod
                                }
                            />
                        </Grid>
                    </Grid>
                </form> */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mb={3}
          mt={4}
        >
          <Button
            className="back-button"
            onClick={() => handleTabChange("4")}
            variant="contained"
            style={{ marginRight: "15px" }}
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button
            onClick={() => handleTabChange("6")}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </Stack>
      </Container>
    </Card>
  );
}
