import * as Yup from "yup";

const licenceSchema = Yup.object().shape({
    productName : Yup.string()
        .required("Product Name is required")
        .trim("Product Name cannot contain only whitespace")
        .strict(true)
        .max(256, "Maximum 256 characters required")
        .nullable(),
        purchasedDate :Yup.date()
    .required("Purchased Date is required")
    .typeError("Invalid date")
    .nullable(),  
    licencePeriod : Yup.string().required("Licence Period is required")
    .matches(/^[0-9]+$/, 'Please enter numeric value')
    .nullable(),
    notes : Yup.string()
    .trim("Notes cannot contain only whitespace")
    .strict(true)
    .nullable()
});

export default licenceSchema;