import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";

function AddForm() {
	const formik = useFormik({
		initialValues: {
			title: "",
			startDate: null,
			endDate: null,
		},
	
		onSubmit: (values) => {
			// console.log(values);
		},
	});

	return (
		<Container maxWidth="xl">
			<Card>
				<Container maxWidth="xl">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mb={3}
						mt={3}
					>
						<Typography variant="h4" gutterBottom>
							Add Holiday
						</Typography>
						<Button variant="contained" startIcon={<AddIcon />}>
							Holiday List
						</Button>
					</Stack>
					<Paper>
						<form>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="Title"
										name="title"
										inputProps={{ maxLength: 256 }}
										value={formik.values.title}
										onChange={formik.handleChange}
										variant="outlined"
										size="small"
									/>
								</Grid>
								<Grid item xs={12}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<Stack spacing={3} direction="row">
											<DesktopDatePicker
												label="Start Date"
												value={formik.values.startDate}
												// minDate={new Date('2017-01-01')}
												onChange={(newValue) => {
													formik.setFieldValue("startDate", new Date(newValue));
												}}
												renderInput={(params) => (
													<TextField {...params} fullWidth size="small" />
												)}
											/>

											<DesktopDatePicker
												label="End Date"
												value={formik.values.endDate}
												// minDate={new Date('2017-01-01')}
												onChange={(newValue) => {
													formik.setFieldValue("endDate", new Date(newValue));
												}}
												renderInput={(params) => (
													<TextField {...params} fullWidth size="small" />
												)}
											/>
										</Stack>
									</LocalizationProvider>
								</Grid>
							</Grid>
						</form>
					</Paper>
					<Stack direction="row" justifyContent="flex-end" mt={4} mb={5}>
						<Button variant="contained">Save</Button>
					</Stack>
				</Container>
			</Card>
		</Container>
	);
}

export default AddForm;
