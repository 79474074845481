import { createSlice } from "@reduxjs/toolkit";
import { addFAQ, getFAQs } from "./faqsThunk";

const initialState = {
    data: [],
    loading: false,
    error: null,
    totalPages:0,
    limit: 40,
    totalRecords: -1,
    currentPage: 0,
    sortBy: 'id',
    orderBy: 'DESC',
    status: 'idle',
};

// A slice for getBloodGroup with our 3 reducers
export const faqsSlice = createSlice({
    name: "faqs",
    initialState,
    reducers: {
        setLimit: (state, action) => {
            state.limit = action.payload.limit;
            state.status = "idle";
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload.currentPage;
            state.status = "idle";
        },
        setSortBy: (state, action) => {
			state.sortBy = action.payload.sortBy;
			state.status = 'idle';
		},
		setOrderBy: (state, action) => {
			state.orderBy = action.payload.orderBy;
			state.status = 'idle';
		},
        setStatus: (state, action) => {
            state.status = action.payload.status;
        },
    },
    extraReducers: {
        [getFAQs.pending]: (state) => {
            state.loading = true;
        },
        [getFAQs.fulfilled]: (state, { payload }) => {
            state.loading = false;
            // console.log(payload)
            state.faqsData = payload?.data?.list || payload?.data || payload;
            state.complete = true;
            state.status = "fulfilled";
        },
        [getFAQs.rejected]: (state, payload) => {
            state.loading = false;
            state.data = [];
            state.error = payload;
            state.complete = true;
            state.status = "rejected";
        },        
    },
});

// The reducer
export const faqsReducer = faqsSlice.reducer;
export const { setSortBy, setOrderBy, setStatus, setLimit, setCurrentPage } =
faqsSlice.actions;
