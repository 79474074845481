import DashboardLayout from "../../../layouts/dashboard";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import Loader from "../../../components/Loader";
// import UserMoreActions from "./EmployeeComponents/UserMoreActions";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import * as XLSX from "xlsx";
import { encryption } from "../../../utils/encodeString";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { Paper } from "@mui/material";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
// import { indianDateFormat } from "../../utils/dateFormat";
import invertDirection from "../../../utils/invertDirection";
import Scrollbar from "../../../components/Scrollbar";
import CustomPagination from "../../../components/Pagination";
import {
  setSortBy,
  setOrderBy,
  setCurrentPage,
  setLimit,
} from "../../../redux/reportsAssets/reportsAssetsSlice";
import {
  getAssetDetails,
  getAssetDetailsForExcel,
  totalAssetDetailCount,
} from "../../../redux/reportsAssets/reportsAssetsThunk";
function AssetsReports() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const isSearchQuery = useSelector((state) => state.employee.isSearchQuery);
  //designation & department
  const [filterData, setFilterData] = useState({});
  const [filterChips, setFilterChips] = useState([]);
  const inputRef = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState("");

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
    // User Permission Use Effect : If user not has access to this module then redirect to dashboard
    useEffect(() => {
      if (!getViewVisible("reports")) {
        navigate("/dashboard");
      }
    }, []);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };



  const {
    data,
    loading,
    sortBy,
    orderBy,
    totalRecords,
    limit,
    currentPage,
    totalPages,
    status,
    error,
    
  } = useSelector((state) => state.reportsAssets);

  const totalCount = useSelector((state) => state.reportsAssets.totalCount);
  useEffect(() => {
    try {
      dispatch(totalAssetDetailCount({})).unwrap();
      dispatch(
        getAssetDetails({
          limit,
          page: currentPage + 1,
          sortBy,
          orderBy,
          search: searchValue,
        })
      ).unwrap();
    } catch (error) {
      toast.error(error?.message);
    }
  }, [limit, currentPage, error, sortBy, orderBy]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };
 
  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: async (values) => {
      let { search } = values;
      try {
        await dispatch(getAssetDetails({ search, limit, page: 1 }));
        dispatch(setCurrentPage({ page: 0 }));
        prepareChips(values);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  const handleSearch = async (e) => {
    setFieldValue("search", e?.target?.value || "");
    setSearchValue(e?.target?.value);
    handleSubmit();
  };
  const debounceWithSearch = debounce(handleSearch, 500);
  const prepareChips = (values) => {
    let filters = { ...values };
    let tempChips = [];
    console.log("FILETERs", filters);
    for (const key in filters) {
      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    console.log("TEMPCHIPS", tempChips);
    setFilterChips(tempChips);
  };

  const onDeleteChip = (value, length) => {
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    let filters = { ...filterData, [value["type"]]: "" };
    setFilterData(filters);
    dispatch(getAssetDetails({ limit, page: 1, sortBy, orderBy }));
    if (value.type === "search") {
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

  const exportFile = async () => {
    try {
      const data = await dispatch(getAssetDetailsForExcel({})).unwrap();
      const worksheet = XLSX.utils.json_to_sheet(data?.data?.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "AssetsReport.xlsx");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Assets Reports ({totalCount || 0})
          {/* <Typography variant="body2">
            Total Records : 
          </Typography> */}
        </Typography>
        
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          {/* {AuthRole === "Super Admin" ? ( */}
          <>
            <Button
              variant="contained"
              onClick={() => exportFile()}
              startIcon={<FileUploadSharpIcon />}
              className="filter-icon-part"
            >
              Export
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/reports"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back
            </Button>
          </>
        </Stack>
        </div>
      </Stack>
      <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                my={3}
                style={{ marginBottom: "17px", paddingTop: "17px" }}
              >
                <TextField
                  fullWidth
                  label="Search by System Name/Assigned To"
                  name="title"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "10px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {filterChips.length !== 0 || isSearchQuery ? (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer
            component={Paper}
            style={{ minWidth: 800, overflowX: "auto" }}
          >
            <Card>
              <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                page={currentPage}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                // filter={isSearchQuery ? true : false}
              />
              <div style={{ overflowX: "auto" }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "systemName"}
                          direction={sortBy === "systemName" ? orderBy : "asc"}
                          onClick={() => handleSorting("systemName")}
                        >
                          System Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "assignedTo"}
                          direction={sortBy === "assignedTo" ? orderBy : "asc"}
                          onClick={() => handleSorting("assignedTo")}
                        >
                          Assigned To
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "location"}
                          direction={sortBy === "location" ? orderBy : "asc"}
                          onClick={() => handleSorting("location")}
                        >
                          Location
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "processor"}
                          direction={sortBy === "processor" ? orderBy : "asc"}
                          onClick={() => handleSorting("processor")}
                        >
                          Processor
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell>
                        <TableSortLabel
                          active={sortBy === "baseClock"}
                          direction={sortBy === "baseClock" ? orderBy : "asc"}
                          onClick={() => handleSorting("baseClock")}
                        >
                          Base Clock
                        </TableSortLabel>
                      </TableCell> */}
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "core"}
                          direction={sortBy === "core" ? orderBy : "asc"}
                          onClick={() => handleSorting("core")}
                        >
                          Core
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "motherBoard"}
                          direction={sortBy === "motherBoard" ? orderBy : "asc"}
                          onClick={() => handleSorting("motherBoard")}
                        >
                          Motherboard
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "ramSlots"}
                          direction={sortBy === "ramSlots" ? orderBy : "asc"}
                          onClick={() => handleSorting("ramSlots")}
                        >
                          RAM slot
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "ramType"}
                          direction={sortBy === "ramType" ? orderBy : "asc"}
                          onClick={() => handleSorting("ramType")}
                        >
                          RAM Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "ramSize"}
                          direction={sortBy === "ramSize" ? orderBy : "asc"}
                          onClick={() => handleSorting("ramSize")}
                        >
                          RAM Size
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "frequency"}
                          direction={sortBy === "frequency" ? orderBy : "asc"}
                          onClick={() => handleSorting("frequency")}
                        >
                          Frequency
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "storageType"}
                          direction={sortBy === "storageType" ? orderBy : "asc"}
                          onClick={() => handleSorting("storageType")}
                        >
                          Storage Type
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "storageSize"}
                          direction={sortBy === "storageSize" ? orderBy : "asc"}
                          onClick={() => handleSorting("storageSize")}
                        >
                          Storage Size
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "os"}
                          direction={sortBy === "os" ? orderBy : "asc"}
                          onClick={() => handleSorting("os")}
                        >
                          OS
                        </TableSortLabel>
                      </TableCell>
                     
                     
                      
                      {/* <TableCell>
                        <TableSortLabel
                          active={sortBy === "memory"}
                          direction={sortBy === "memory" ? orderBy : "asc"}
                          onClick={() => handleSorting("memory")}
                        >
                          Memory
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "monitorSize"}
                          direction={sortBy === "monitorSize" ? orderBy : "asc"}
                          onClick={() => handleSorting("monitorSize")}
                        >
                          Monitor Size
                        </TableSortLabel>
                      </TableCell> */}
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "status"}
                          direction={sortBy === "status" ? orderBy : "asc"}
                          onClick={() => handleSorting("status")}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "tags"}
                          direction={sortBy === "tags" ? orderBy : "asc"}
                          onClick={() => handleSorting("tags")}
                        >
                          Tags
                        </TableSortLabel>
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "notes"}
                          direction={sortBy === "notes" ? orderBy : "asc"}
                          onClick={() => handleSorting("notes")}
                        >
                          Notes
                        </TableSortLabel>
                      </TableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {loading ? (
                      
                        <TableCell align="center" width="100%"colSpan={11}>
                          <Loader />
                        </TableCell>
                     
                    ) : Array.isArray(data) && data ? (
                      data?.length === 0 ? (
                        <TableRow>
                          <TableCell className="No-Record-text" align="center" colSpan={3}>
                            No Record(s) Found
                          </TableCell>
                        </TableRow>
                      ) : (
                        Array.isArray(data) &&
                        data?.length > 0 &&
                        data?.map((record, idx) => (
                          <TableRow
                            key={idx}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {record?.systemName || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.assignedTo || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.location || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.processor || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.core || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.motherBoard || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.ramSlots || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.ramType || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.ramSize || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.frequency || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.storageType || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.storageSize || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.os || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.status || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.tags || ""}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {record?.notes || ""}
                            </TableCell>
                          </TableRow>
                        ))
                      )
                    ) : (
                      ""
                    )}
                  </TableBody>
                </Table>
              </div>
            </Card>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: AssetsReports,
  path: "reports/assets-report",
  public: false,
  layout: DashboardLayout,
  group: "reports",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
