import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getExitQuestions } from './exitQuestionThunk';

const initialState = {
  loading: false,
  error: null,
  data: [],
  complete: false,
  limit: 40,
  page: 0,
  totalPage: 0,
  totalCount: 0,
  sortBy: 'id',
  orderBy: 'desc',
};

export const exitQuestionSlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.complete = false;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload.orderBy;
      state.complete = false;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.complete = false;
      state.error = null;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
      state.complete = false;
      state.error = null;
    },
  },
  extraReducers: {
    [getExitQuestions.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getExitQuestions.fulfilled]:(state,{payload})=>{
        state.loading = false;
        state.data = payload?.data || payload;
        state.complete = true;
        state.page = payload?.data?.currentPage - 1 || 0;
        state.totalCount = payload?.data?.totalRecords || 0;
        state.totalPage = payload?.data?.totalPages || 0;
        state.limit = payload?.data?.limit
        state.status = "fulfilled";
    },
    [getExitQuestions.rejected]:(state,{payload})=>{
      state.loading = false;
      state.data = [];
      state.error = payload;
      state.complete = true;
      state.totalCount = 0;
      state.totalPage = 0;
      state.page = 0;
      state.status = "rejected"
    },
  }
});


export const { setLimit, setPage, setOrderBy, setSortBy } =
exitQuestionSlice.actions;

export const exitQuestionReducer = exitQuestionSlice.reducer;
