import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../layouts/dashboard";
import Board, { moveCard } from "@lourenci/react-kanban";
import "@lourenci/react-kanban/dist/styles.css";
import { Box } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";
import { getCandidateByJob } from "../../../redux/jobCandidates/jobCanThunk";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { encryption } from "../../../utils/encodeString";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  // Accordion,
  // AccordionDetails,
  // AccordionSummary,
  Autocomplete,
  Card,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Popover,
  Stack,
  TextareaAutosize,
  TextField,
} from "@mui/material";
import { a, useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from "@mui/icons-material/Add";
import {
  getCandidateByStatus,
  getCandidateStatus,
} from "../../../redux/candidateState/candidateStatethunk";
import jobCandidateSchema, {
  checkActivityDetails,
  checkPersonalDetails,
} from "../../../validations/jobCandidateSchema";
import {
  insertCandidate,
  getCandidateById,
  addCandidateJd,
  getCandidate,
  uploadCandidateImage,
  convertToEmployee,
} from "../../../redux/candidate/candidateThunk";
import {
  convertTo12HrsFormat,
  convertTo24HrsFormat,
  fDateCustom,
  fDateTime12,
  fDateTime,
} from "../../../utils/formatTime";
import cardboardSchema from "../../../validations/cardboardSchema";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useFormik } from "formik";
import { styled } from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  // DesktopDatePicker,
  // DesktopTimePicker,
  LoadingButton,
  // LocalizationProvider,
} from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker, DesktopTimePicker } from "@mui/x-date-pickers";
import AddCandidate from "./AddCandidate";
import AddActivity from "./AddActivity";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { candidateStateUpdate } from "../../../redux/jobDescription/jobDesThunk";
import { Link as RouterLink } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import "./manageJobStatus.css";
import jdScheduleInterviewSchema from "../../../validations/jdScheduleInterview";
import moment from "moment";
import { insertInterviewSchedule } from "../../../redux/scheduleInterview/scheduleInterviewThunk";
import { getAvailableEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import { getInterviewType } from "../../../redux/interviewType/interviewTypeThunk";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { element } from "prop-types";
import { getAddVisible } from "../../../utils/userPermission";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const style5 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const styleSecond = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  maxHeight: "80vh",
  maxWidth: "80%",
  width: "70%",
  overflow: "scroll",
  scrollbarWidth: "none",
  p: 4,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ManageJobStatus = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { state } = useLocation();
  const [applied, setApplied] = useState([]);
  const [contacted, setContacted] = useState([]);
  const [interview, setInterview] = useState([]);
  const [hired, setHired] = useState([]);
  const [rejected, setRejected] = useState([]);
  const [statusId, setStatusId] = useState("");
  const [drop, setDrop] = useState([]);
  const [leadEmployee, setLeadEmployee] = useState([]);
  const statusData = useSelector((state) => state.candidateState.statusData);
  const stateData = useSelector((state) => state.candidateState.stateData);
  const [open, setOpen] = React.useState(false);
  const [candidateId, setCandidateId] = React.useState("");
  const [count, setCount] = React.useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const [submitted, setSubmitted] = React.useState(false);
  const [jobId, setJobId] = useState("");
  const [candidateResume, setCandidateResume] = useState(null);
  const [candidateUpdateId, setCandidateUpdateId] = useState(null);
  const [interviewListData, setInterviewListData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [designationDisable, setDesignationDisable] = useState(true);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [tabValue, setTabValue] = React.useState(4);
  const [interviewIndex, setInterviewIndex] = useState(0);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const [personal, setPersonal] = useState({});
  const designationData = useSelector((state) => state.designation);
  const [open5, setOpen5] = React.useState(false);
  const handleOpen5 = () => setOpen5(true);
  const handleClose5 = () => setOpen5(false);
  const [openFour, setOpenFour] = React.useState(false);
  const [flag, setFlag] = useState(false);
  const handleOpenFour = () => {
    // formikInterview.setFieldValue(`interviewDetails[${interviewIndex}]`, {
    //   interviewType: "",
    //   interviewDate: "",
    //   interviewTime: "",
    //   duration: null,
    //   technologies: "",
    //   comments: "",
    //   interviewMode: "",
    //   interviewrefrences: [],
    // });
    setOpenFour(true);
  };
  const handleCloseFour = () => {
    formikInterview.setFieldValue(
      `interviewDetails[${interviewIndex}].note`,
      ""
    );
    formikInterview.resetForm();
    setCount(count + 1);
    setOpenFour(false);
  };
  const [toBeDeletedCandidateResume, setToBeDeletedCandidateResume] =
    useState(null);
  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const interviewTypeData = useSelector((state) => state.interviewType);
  const technologyData = useSelector((state) => state.technology);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    resetForm();
    setCount(count + 1);
    setOpen(false);
  };
  const formik = useFormik({
    initialValues: {
      approachDate: new Date(),
      candidateStatusId: null,
      candidateStateId: null,
      candidateStatename: null,
      note: "",
      cardCollem: false,
      futureDate: null,
      joiningDate: null,
    },
    validationSchema: cardboardSchema,
    onSubmit: async (values) => {
      try {
        dispatch(candidateStateUpdate({ ...values, candidateId: candidateId }))
          .unwrap()
          .then((res) => {
            // setCandidateState(res.data);
            handleClose();
          })
          .catch((err) => {
            handleClose();
            // setCandidateState([]);
          });

        // handleClose();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const interviewerList = async (index) => {
    if (
      (formikInterview?.values?.interviewDetails[index]?.interviewDate ??
        false) &&
      (formikInterview?.values?.interviewDetails[index]?.interviewTime ??
        false) &&
      (formikInterview?.values?.interviewDetails[index]?.duration ?? false) &&
      formikInterview?.values?.interviewDetails[index]?.interviewTime !=
        "Invalid Date"
    ) {
      let response = await dispatch(
        getAvailableEmployeeForDropDown({
          interviewDate:
            formikInterview.values.interviewDetails[index].interviewDate,
          interviewTime: moment(
            formikInterview.values.interviewDetails[index].interviewTime
          ).format("HH:mm:ss"),
          duration: formikInterview.values.interviewDetails[index].duration,
        })
      ).unwrap();
      formikInterview.setFieldValue(
        `interviewDetails[${index}].interviewrefrences`,
        []
      );
      setInterviewListData(response.data ?? []);
    } else {
      setInterviewListData([]);
    }
  };

  const interviewerListTab = async (index, duration) => {
    if (
      (formikInterview.values.interviewDetails[index]?.interviewDate ??
        false) &&
      (formikInterview.values.interviewDetails[index]?.interviewTime ??
        false) &&
      (duration ?? false) &&
      formikInterview?.values?.interviewDetails[index]?.interviewTime !=
        "Invalid Date"
    ) {
      let response = await dispatch(
        getAvailableEmployeeForDropDown({
          interviewDate:
            formikInterview.values.interviewDetails[index].interviewDate,
          interviewTime: moment(
            formikInterview.values.interviewDetails[index].interviewTime
          ).format("HH:mm:ss"),
          duration,
        })
      ).unwrap();
      formikInterview.setFieldValue(
        `interviewDetails[${index}].interviewrefrences`,
        []
      );
      setInterviewListData(response.data ?? []);
    } else {
      setInterviewListData([]);
    }
  };
  // useEffect(() => {
  //   if (
  //     !interviewTypeData.complete &&
  //     !interviewTypeData.loading &&
  //     !interviewTypeData.error &&
  //     (!interviewTypeData.data ||
  //       (Array.isArray(interviewTypeData.data) &&
  //         interviewTypeData.data.length <= 0))
  //   )
  //     dispatch(getInterviewType());
  // });

  useEffect(() => {
    dispatch(getInterviewType({ sortBy: "", orderBy: "" }));
  }, []);

  useEffect(() => {
    if (
      !technologyData.complete &&
      !technologyData.loading &&
      !technologyData.error &&
      (!technologyData.data ||
        (Array.isArray(technologyData.data) && technologyData.data.length <= 0))
    )
      // dispatch(getTechnology());
      dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  });
  const interviewMode = [
    { id: "online", title: "Online" },
    { id: "offline", title: "Offline" },
  ];
  const formikInterview = useFormik({
    initialValues: {
      candidateName: "",
      email: "",
      is_from_JD: 1,
      interviewDetails: [
        {
          interviewType: "",
          interviewDate: "",
          interviewTime: "",
          duration: null,
          technologies: "",
          comments: "",
          interviewMode: "",
          interviewrefrences: [],
        },
      ],
    },
    validationSchema: jdScheduleInterviewSchema,
    onSubmit: async (interviewValues) => {
      // alert("test");
      try {
        // values.designationId = values.designationId.id;

        //         for (let details of interviewValues.interviewDetails) {
        //           console.log(details,"details check");
        //           if (moment(details.interviewTime).isValid()) {
        //             details.interviewTime = moment(details.interviewTime).format(
        //               "HH:mm:ss"
        //             );
        //           }
        //           let newdata = details.interviewrefrences.map(
        //             (i) => {
        //               if(i.interviewer){
        //                 return i.interviewerId
        //               }else{
        //                 return i.id
        //               }
        //             }
        //           );
        //           details.interviewrefrences=newdata;
        //         }
        let interview = { ...interviewValues };
        let interviewDetails = [...interviewValues.interviewDetails];
        let newdetails = interviewDetails.map((details, index) => {
          let obj = { ...details };

          if (moment(obj.interviewTime).isValid()) {
            obj.interviewTime = moment(obj.interviewTime).format("HH:mm:ss");
          }
          let newdata = obj.interviewrefrences.map((i) => {
            if (i.interviewer) {
              return i.interviewerId;
            } else {
              return i.id;
            }
          });

          obj.interviewrefrences = [...newdata];

          return obj;
        });
        interview.interviewDetails = newdetails;

        if (interviewIndex == 0) {
          dispatch(
            candidateStateUpdate({
              ...values,
              candidateId: candidateId,
              note: "Scheduled Interview from JD",
            })
          )
            .unwrap()
            .then((res) => {
              // setCandidateState(res.data);
              dispatch(
                insertInterviewSchedule({
                  ...interview,
                  candidateId: candidateId,
                })
              )
                .unwrap()
                .then((res) => {
                  handleCloseFour();
                  formikInterview.resetForm();
                });
            })
            .catch((err) => {
              handleCloseFour();
              formikInterview.resetForm();
              // setCandidateState([]);
            });
        } else {
          dispatch(
            insertInterviewSchedule({
              ...interview,
              candidateId: candidateId,
            })
          )
            .unwrap()
            .then((res) => {
              handleCloseFour();
              formikInterview.resetForm();
            })
            .catch((err) => {
              handleCloseFour();
              formikInterview.resetForm();
              // setCandidateState([]);
            });
        }
        // await dispatch(insertInterviewSchedule(interviewValues)).unwrap();
        /* navigate('/candidate') */
        // return navigate(-1);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  // coxnsole.log(formikInterview,"ddd123")
  const confirmConvertToEmployee = async () => {
    let response = await dispatch(convertToEmployee({ id: candidateId }));
    if (response.payload.status === 200) {
      handleClose5();
      setCount(count + 1);
    }
  };

  const formikCandidate = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: "",
      contactNumber: null,
      alternateContactNumber: "",
      email: "",
      skype: "",
      linkedIn: "",
      referedBy: "",

      totalExperience: 0,
      technologies: [],
      candidateJobs: [],
      // activity: {
      //   approachDate: null,
      //   candidateStatusId: "",
      //   candidateStateId: null,
      //   note: "",
      //   futureDate: null,
      // },
    },
    validationSchema: jobCandidateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // let response;
        let imageId;
        if (!values.contactNumber) {
          values.contactNumber = null;
        }
        if (!values.alternateContactNumber) {
          values.alternateContactNumber = "";
        }
        // console.log(candidateResume, "type resume");
        // if (candidateResume.id) {
        //   setCandidateResume(JSON.stringify(candidateResume));
        // }
        // if (!id) {
        let response = await dispatch(
          addCandidateJd({
            candidateResume: candidateResume?.id
              ? JSON.stringify(candidateResume)
              : candidateResume,
            values,
            id: candidateUpdateId,
          })
        );
        // handleCloseSecondModal();
        // if (id) {
        //   response = await dispatch(addCandidateJd({ values })).unwrap();
        //   // imageId = id;
        // }
        // if (selectedFile || candidateResume || toBeDeletedCandidateResume)
        //   dispatch(
        //     uploadCandidateImage({
        //       id: imageId,
        //       file: selectedFile,
        //       candidateResume: candidateResume,
        //       toBeDeletedCandidateResume: toBeDeletedCandidateResume,
        //     })
        //   );

        if (response.payload.status === 200) {
          resetForm();
          setCandidateResume(null);
          setCandidateUpdateId(null);
          handleCloseSecondModal();
          setCount(count + 1);
        }
        // }
      } catch (error) {
        alert("1");
        toast.error(error.message);
      }
    },
  });

  // const {
  //   handleSubmit,
  //   getFieldProps,
  //   setValues,
  //   values,
  //   setFieldValue,
  //   handleChange,
  //   errors,
  //   isSubmitting,
  //   touched,
  //   setErrors,
  //   setFieldTouched,
  //   setFieldError,
  //   resetForm,
  // } = formikCandidate;
  const [openSecondModal, setOpenSecondModel] = useState(false);
  const handleOpenSecondModal = () => setOpenSecondModel(true);
  const handleCloseSecondModal = () => setOpenSecondModel(false);

  const {
    getFieldProps,
    handleSubmit,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    initialValues,
    resetForm,
  } = formik;

  useEffect(() => {
    if (Array.isArray(statusData) && statusData.length <= 0)
      dispatch(getCandidateStatus());
  }, []);

  // useEffect(() =>{
  //   if (openFour) {
  //     setOpenFour(true);
  //   } else {
  //     setOpenFour(false);
  //   }
  // },[openFour])

  useEffect(() => {
    if (statusId ?? false) {
      dispatch(getCandidateByStatus(statusId))
        .unwrap()
        .then((res) => {
          // setCandidateState(res.data);
        })
        .catch((err) => {
          // setCandidateState([]);
        });
    }
  }, [dispatch, statusId]);

  let board = {
    columns: [
      {
        id: 1,
        title: `Applied (${applied.length ?? 0})`,
        cards: applied,
      },
      {
        id: 2,
        title: `Contacted (${contacted.length ?? 0})`,
        cards: contacted,
      },
      {
        id: 3,
        title: `Interview (${interview.length ?? 0})`,
        cards: interview,
      },
      {
        id: 4,
        title: `Hired (${hired.length ?? 0})`,
        cards: hired,
      },
      {
        id: 5,
        title: `Employee (${leadEmployee.length ?? 0})`,
        cards: leadEmployee,
      },
      {
        id: 6,
        title: `Rejected (${rejected.length ?? 0})`,
        cards: rejected,
      },
      {
        id: 7,
        title: `Drop (${drop.length ?? 0})`,
        cards: drop,
      },
    ],
  };
  const [interviewDataShow, setInterviewDataShow] = useState();
  const [controlledBoard, setBoard] = useState(board);
  const Candidate = useSelector((state) => state.jobCan);
  const convertToEmployeeModel = (id) => {
    handleOpen5();
  };
  useEffect(() => {
    dispatch(getCandidateByJob(state?.id));
  }, [dispatch, count]);

  useEffect(() => {
    let newApplied = [];
    let newContacted = [];
    let newInterview = [];
    let newHired = [];
    let newRejected = [];
    let newDrop = [];
    let newLead = [];

    console.log("C________01", Candidate?.data);

    Candidate?.data?.map((element, index) => {
      if (element.activity ?? false) {
        if (element.activity.candidateStatus ?? false) {
          if (element.activity.candidateStatus.name.toLowerCase() === "lead") {
            if (element.activity.candidateState ?? false) {
              if (
                element.activity?.candidateState?.name.toLowerCase() ===
                "employee"
              ) {
                newLead.push({
                  id: element.id,
                  title: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "230px",
                      }}
                    >
                      <p
                        style={{ fontSize: "13px" }}
                      >{`${element.firstName} ${element.lastName}`}</p>
                      <IconButton
                        style={{ padding: "0px" }}
                        component={RouterLink}
                        to={`/candidate/view/${encryption(element.id)}`}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </div>
                  ),
                  description: (
                    <Stack direction="column" spacing={1}>
                      <p style={{ fontSize: "13px" }}>
                        {element.contactNumber}
                      </p>
                      <p style={{ fontSize: "13px" }}>{element.email}</p>
                      <Stack justifyContent="center">
                        <a
                          onClick={() => {
                            setCandidateId(element.id);
                            setFieldValue("candidateStatusId", 1);
                            selectStateHandler(
                              1,
                              element.activity?.candidateState?.name.toLowerCase()
                            );
                          }}
                          variant="contained"
                          style={{
                            fontSize: "13px",
                            color: "#ec810d",
                            textDecoration: "underline",
                          }}
                        >
                          Update Status
                        </a>
                      </Stack>
                    </Stack>
                  ),
                });
              } else {
                if (
                  element.activity?.candidateState?.name.toLowerCase() ===
                  "rejected"
                ) {
                  newRejected.push({
                    id: element.id,
                    title: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "230px",
                        }}
                      >
                        <p
                          style={{ fontSize: "13px" }}
                        >{`${element.firstName} ${element.lastName}`}</p>
                        <IconButton
                          style={{ padding: "0px" }}
                          component={RouterLink}
                          to={`/candidate/view/${encryption(element.id)}`}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </div>
                    ),
                    description: (
                      <Stack direction="column" spacing={1}>
                        <p style={{ fontSize: "13px" }}>
                          {element.contactNumber}
                        </p>
                        <p style={{ fontSize: "13px" }}>{element.email}</p>
                        <Stack justifyContent="center">
                          <a
                            onClick={() => {
                              setCandidateId(element.id);
                              formikInterview.setFieldValue(
                                "candidateName",
                                `${element.firstName} ${element.lastName}`
                              );
                              formikInterview.setFieldValue(
                                "email",
                                element.email
                              );
                              setFieldValue("candidateStatusId", 1);
                              selectStateHandler(1, "rejected");
                            }}
                            style={{
                              fontSize: "13px",
                              color: "#ec810d",
                              textDecoration: "underline",
                            }}
                            variant="contained"
                          >
                            Update Status
                          </a>
                        </Stack>
                      </Stack>
                    ),
                  });
                } else {
                  newContacted.push({
                    id: element.id,
                    title: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "230px",
                        }}
                      >
                        <p
                          style={{ fontSize: "13px" }}
                        >{`${element.firstName} ${element.lastName}`}</p>
                        <IconButton
                          style={{ padding: "0px" }}
                          component={RouterLink}
                          to={`/candidate/view/${encryption(element.id)}`}
                        >
                          <VisibilityIcon />
                        </IconButton>
                      </div>
                    ),
                    description: (
                      <Stack direction="column" spacing={1}>
                        <p style={{ fontSize: "13px" }}>
                          {element.contactNumber}
                        </p>
                        <p style={{ fontSize: "13px" }}>{element.email}</p>
                        <Stack justifyContent="center">
                          <a
                            onClick={() => {
                              setCandidateId(element.id);
                              formikInterview.setFieldValue(
                                "candidateName",
                                `${element.firstName} ${element.lastName}`
                              );
                              formikInterview.setFieldValue(
                                "email",
                                element.email
                              );
                              setFieldValue("candidateStatusId", 1);
                              selectStateHandler(
                                1,
                                element.activity?.candidateState?.name.toLowerCase()
                              );
                            }}
                            variant="contained"
                            style={{
                              fontSize: "13px",
                              color: "#ec810d",
                              textDecoration: "underline",
                            }}
                          >
                            Update Status
                          </a>
                        </Stack>
                      </Stack>
                    ),
                  });
                }
              }
            } else {
              newApplied.push({
                id: element.id,
                title: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "230px",
                    }}
                  >
                    <p
                      style={{ fontSize: "13px" }}
                    >{`${element.firstName} ${element.lastName}`}</p>
                    <IconButton
                      style={{ padding: "0px" }}
                      component={RouterLink}
                      to={`/candidate/view/${encryption(element.id)}`}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                ),
                description: (
                  <Stack direction="column" spacing={1}>
                    <p style={{ fontSize: "13px" }}>{element.contactNumber}</p>
                    <p style={{ fontSize: "13px" }}>{element.email}</p>
                  </Stack>
                ),
              });
            }
          } else if (
            element.activity.candidateStatus.name.toLowerCase() === "interview"
          ) {
            // console.log(element.activity?.candidateState,"darshit test")
            if (
              (element.activity.candidateState ?? false) &&
              (element.activity?.candidateState?.name.toLowerCase() ===
                "offer accepted" ??
                false)
            ) {
              newHired.push({
                id: element.id,
                title: (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "230px",
                    }}
                  >
                    {" "}
                    <p
                      style={{ fontSize: "13px" }}
                    >{`${element.firstName} ${element.lastName}`}</p>
                    <IconButton
                      style={{ padding: "0px" }}
                      component={RouterLink}
                      to={`/candidate/view/${encryption(element.id)}`}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                ),
                description: (
                  <Stack direction="column" spacing={1}>
                    <p style={{ fontSize: "13px" }}>{element.contactNumber}</p>
                    <p style={{ fontSize: "13px" }}>{element.email}</p>
                    <Stack justifyContent="center">
                      <a
                        onClick={() => {
                          setCandidateId(element.id);
                          formikInterview.setFieldValue(
                            "candidateName",
                            `${element.firstName} ${element.lastName}`
                          );
                          formikInterview.setFieldValue("email", element.email);
                          setFieldValue("candidateStatusId", 4);
                          selectStateHandler(
                            4,
                            element.activity?.candidateState?.name.toLowerCase()
                          );
                        }}
                        variant="contained"
                        style={{
                          fontSize: "13px",
                          color: "#ec810d",
                          textDecoration: "underline",
                        }}
                      >
                        Update Status
                      </a>
                      {/* <a
                        onClick={() => {
                          setCandidateId(element.id);
                          formikInterview.setFieldValue(
                            "candidateName",
                            `${element.firstName} ${element.lastName}`
                          );
                          formikInterview.setFieldValue("email", element.email);
                          convertToEmployeeModel(element.id);
                        }}
                        variant="contained"
                        style={{
                          fontSize: "13px",
                          color: "#ec810d",
                          textDecoration: "underline",
                        }}
                      >
                        Convert To Employee
                      </a> */}
                    </Stack>
                  </Stack>
                ),
              });
            } else {
              if (
                element.activity?.candidateState?.name.toLowerCase() ===
                "rejected"
              ) {
                newRejected.push({
                  id: element.id,
                  title: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "230px",
                      }}
                    >
                      <p
                        style={{ fontSize: "13px" }}
                      >{`${element.firstName} ${element.lastName}`}</p>
                      <IconButton
                        style={{ padding: "0px" }}
                        component={RouterLink}
                        to={`/candidate/view/${encryption(element.id)}`}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    </div>
                  ),
                  description: (
                    <Stack direction="column" spacing={1}>
                      <p style={{ fontSize: "13px" }}>
                        {element.contactNumber}
                      </p>
                      <p style={{ fontSize: "13px" }}>{element.email}</p>
                      <Stack justifyContent="center">
                        <a
                          onClick={() => {
                            setCandidateId(element.id);
                            formikInterview.setFieldValue(
                              "candidateName",
                              `${element.firstName} ${element.lastName}`
                            );
                            formikInterview.setFieldValue(
                              "email",
                              element.email
                            );
                            setFieldValue("candidateStatusId", 4);
                            selectStateHandler(4, "rejected");
                          }}
                          style={{
                            fontSize: "13px",
                            color: "#ec810d",
                            textDecoration: "underline",
                          }}
                          variant="contained"
                        >
                          Update Status
                        </a>
                      </Stack>
                    </Stack>
                  ),
                });
              } else {
                let color =
                  element.activity?.candidateState?.name.toLowerCase() ===
                    "scheduled" ||
                  element.activity?.candidateState?.name.toLowerCase() ===
                    "re-scheduled"
                    ? "#f2b701"
                    : element.activity?.candidateState?.name.toLowerCase() ===
                      "offered"
                    ? "green"
                    : element.activity?.candidateState?.name.toLowerCase() ===
                        "on hold" ||
                      element.activity?.candidateState?.name.toLowerCase() ===
                        "on-hold" ||
                      element.activity?.candidateState?.name.toLowerCase() ===
                        "offer rejected" ||
                      element.activity?.candidateState?.name.toLowerCase() ===
                        "offer-rejected"
                    ? "red"
                    : element.activity?.candidateState?.name.toLowerCase() ===
                      "not appeared"
                    ? "#ec810d"
                    : element.activity?.candidateState?.name.toLowerCase() ===
                        "next round" ||
                      element.activity?.candidateState?.name.toLowerCase() ===
                        "interviewed"
                    ? "#1a73e8"
                    : "";
                newInterview.push({
                  id: element.id,
                  title: (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "230px",
                      }}
                    >
                      {" "}
                      <p
                        style={{
                          fontSize: "13px",
                          // backgroundColor: color,
                          // width: "230px",
                        }}
                      >{`${element.firstName} ${element.lastName}`}</p>
                      <div style={{ display: "flex" }}>
                        <IconButton
                          style={{ padding: "0px" }}
                          component={RouterLink}
                          to={`/candidate/view/${encryption(element.id)}`}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <div
                          style={{
                            color: "white",
                            padding: " 4px 10px",
                            fontSize: "11px",
                            borderRadius: "5px",
                            backgroundColor: color,
                          }}
                        >
                          {element.activity?.candidateState?.name}
                        </div>
                      </div>
                    </div>
                  ),
                  description: (
                    <Stack direction="column" spacing={1}>
                      <p style={{ fontSize: "13px" }}>
                        {element.contactNumber}
                      </p>
                      <p style={{ fontSize: "13px" }}>{element.email}</p>
                      <Stack
                        justifyContent="center"
                        sx={{ position: "relative" }}
                      >
                        <a
                          onClick={() => {
                            setCandidateId(element.id);
                            formikInterview.setFieldValue(
                              "candidateName",
                              `${element.firstName} ${element.lastName}`
                            );
                            formikInterview.setFieldValue(
                              "email",
                              element.email
                            );
                            setFieldValue("candidateStatusId", 4);
                            selectStateHandler(
                              4,
                              element.activity?.candidateState?.name.toLowerCase()
                            );
                          }}
                          variant="contained"
                          style={{
                            fontSize: "13px",
                            color: "#ec810d",
                            textDecoration: "underline",
                          }}
                        >
                          Update Status
                        </a>
                        <a
                          onClick={() => {
                            setCandidateId(element.id);
                            formikInterview.setFieldValue(
                              "candidateName",
                              `${element.firstName} ${element.lastName}`
                            );
                            formikInterview.setFieldValue(
                              "email",
                              element.email
                            );
                            formikInterview.setFieldValue(`interviewDetails`, [
                              ...element.interviewSchedule,
                              {
                                interviewType: "",
                                interviewDate: "",
                                interviewTime: "",
                                duration: null,
                                technologies: "",
                                comments: "",
                                interviewMode: "",
                                interviewrefrences: [],
                              },
                            ]);
                            setInterviewIndex(element.interviewSchedule.length);
                            handleOpenFour();
                          }}
                          variant="contained"
                          style={{
                            fontSize: "13px",
                            color: "#ec810d",
                            textDecoration: "underline",
                          }}
                        >
                          Add New Round
                        </a>
                        <InfoOutlinedIcon
                          fontSize="large"
                          aria-owns={
                            openMouse ? "mouse-over-popover" : undefined
                          }
                          aria-haspopup="true"
                          onMouseEnter={(e) => {
                            setInterviewDataShow({
                              ...element.interviewSchedule[
                                element.interviewSchedule.length - 1
                              ],
                              Round: element.interviewSchedule.length,
                            });
                            handlePopoverMouseOpen(e);
                          }}
                          onMouseLeave={handlePopoverMouseClose}
                          sx={{
                            textAlign: "end",
                            position: "absolute",
                            bottom: "0%",
                            right: "0%",
                          }}
                        />
                        {/* <Typography
                    sx={{textAlign:'end',position:'absolute',bottom:'0%',right:'0%'}}
                      aria-owns={openMouse ? "mouse-over-popover" : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(e)=>{setInterviewDataShow({...element.interviewSchedule[element.interviewSchedule.length-1],Round:element.interviewSchedule.length});handlePopoverMouseOpen(e)}}
                      onMouseLeave={handlePopoverMouseClose}>
                    <InfoOutlinedIcon fontSize="large"/>
                    </Typography> */}
                      </Stack>
                    </Stack>
                  ),
                });
              }
            }
          } else if (
            element.activity.candidateStatus.name.toLowerCase() === "employee"
          ) {
            newHired.push({
              id: element.id,
              title: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "230px",
                  }}
                >
                  <p
                    style={{ fontSize: "13px" }}
                  >{`${element.firstName} ${element.lastName}`}</p>
                  <IconButton
                    style={{ padding: "0px" }}
                    component={RouterLink}
                    to={`/candidate/view/${encryption(element.id)}`}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </div>
              ),
              description: (
                <Stack direction="column" spacing={1}>
                  <p style={{ fontSize: "13px" }}>{element.contactNumber}</p>
                  <p style={{ fontSize: "13px" }}>{element.email}</p>
                  <Stack justifyContent="center">
                    <a
                      onClick={() => {
                        setCandidateId(element.id);
                        formikInterview.setFieldValue(
                          "candidateName",
                          `${element.firstName} ${element.lastName}`
                        );
                        formikInterview.setFieldValue("email", element.email);
                        setFieldValue("candidateStatusId", 5);
                        handleOpen();
                      }}
                      variant="contained"
                      style={{
                        fontSize: "13px",
                        color: "#ec810d",
                        textDecoration: "underline",
                      }}
                    >
                      Update Status
                    </a>
                  </Stack>
                </Stack>
              ),
            });
          }
          // for (status==Drop,contract,Blacklist)
          else {
            newDrop.push({
              id: element.id,
              title: (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "230px",
                  }}
                >
                  <p
                    style={{ fontSize: "13px" }}
                  >{`${element.firstName} ${element.lastName}`}</p>
                  <IconButton
                    style={{ padding: "0px" }}
                    component={RouterLink}
                    to={`/candidate/view/${encryption(element.id)}`}
                  >
                    <VisibilityIcon />
                  </IconButton>
                </div>
              ),
              description: (
                <Stack direction="column" spacing={1}>
                  <p style={{ fontSize: "13px" }}>{element.contactNumber}</p>
                  <p style={{ fontSize: "13px" }}>{element.email}</p>
                  <Stack sx = {{display : 'flex' , flexDirection : 'row' , alignItems : 'center' , justifyContent : 'space-between'}} >
                    <a
                      onClick={() => {
                        setCandidateId(element.id);
                        formikInterview.setFieldValue(
                          "candidateName",
                          `${element.firstName} ${element.lastName}`
                        );
                        formikInterview.setFieldValue("email", element.email);
                        setFieldValue("candidateStatusId", 2);
                        handleOpen();
                        // selectStateHandler(
                        //   2,
                        //   element.activity?.candidateState?.name.toLowerCase()
                        // );
                      }}
                      variant="contained"
                      style={{
                        fontSize: "13px",
                        color: "#ec810d",
                        textDecoration: "underline",
                      }}
                    >
                      Update Status
                    </a>

                    <i
                      style={{
                        fontSize: "13px",
                      }}
                    >
                      {element?.activity?.candidateState?.name}
                    </i>
                  </Stack>
                </Stack>
              ),
            });
          }
        } else {
          newApplied.push({
            id: element.id,
            title: (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "230px",
                }}
              >
                <p
                  style={{ fontSize: "13px" }}
                >{`${element.firstName} ${element.lastName}`}</p>
                <IconButton
                  style={{ padding: "0px" }}
                  component={RouterLink}
                  to={`/candidate/view/${encryption(element.id)}`}
                >
                  <VisibilityIcon />
                </IconButton>
              </div>
            ),
            description: (
              <Stack direction="column" spacing={1}>
                <p style={{ fontSize: "13px" }}>{element.contactNumber}</p>
                <p style={{ fontSize: "13px" }}>{element.email}</p>
              </Stack>
            ),
          });
        }
      } else {
        newApplied.push({
          id: element.id,
          title: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "230px",
              }}
            >
              <p
                style={{ fontSize: "13px" }}
              >{`${element.firstName} ${element.lastName}`}</p>
              <IconButton
                style={{ padding: "0px" }}
                component={RouterLink}
                to={`/candidate/view/${encryption(element.id)}`}
              >
                <VisibilityIcon />
              </IconButton>
            </div>
          ),
          description: (
            <Stack direction="column" spacing={1}>
              <p style={{ fontSize: "13px" }}>{element.contactNumber}</p>
              <p style={{ fontSize: "13px" }}>{element.email}</p>
            </Stack>
          ),
        });
      }
    });
    // let obj = {
    //   id: 1,
    //   title: (
    //     <Box sx={{ backgroundColor: "red", width: "230px" }}>
    //       test title data
    //     </Box>
    //   ),
    //   description: "Card content",
    // };
    setApplied(newApplied);
    setContacted(newContacted);
    setInterview(newInterview);
    setHired(newHired);
    setRejected(newRejected);
    setDrop(newDrop);
    setLeadEmployee(newLead);
  }, [Candidate, count]);

  useEffect(() => {
    setBoard(board);
  }, [applied, contacted, interview, hired, rejected, drop]);

  console.log(formikInterview?.errors);
  useEffect(() => {
    formikInterview?.errors?.interviewDetails?.map((e, key) => {
      if (e?.interviewTime) {
        setFlag(true);
      }
    });
  });

  const selectStateHandler = (id, state) => {
    dispatch(getCandidateByStatus(id))
      .unwrap()
      .then((res) => {
        // setCandidateState(res.data);

        let filterState = res.filter(
          (element) => element.name.toLowerCase() === state.toLowerCase()
        );

        setFieldValue(
          "candidateStateId",
          filterState[0] ? filterState[0].id : null
        );
        setFieldValue("candidateStatename", state || null);
        handleOpen();
      })
      .catch((err) => {
        handleOpen();
        // setCandidateState([]);
      });
  };

  const selectInterviewStateHandler = (id, state) => {
    dispatch(getCandidateByStatus(id))
      .unwrap()
      .then((res) => {
        // setCandidateState(res.data);

        let filterState = res.filter(
          (element) => element.name.toLowerCase() === state
        );

        setFieldValue(
          "candidateStateId",
          filterState[0] ? filterState[0].id : null
        );
        setInterviewIndex(0);
        handleOpenFour();
        // handleOpen();
      })
      .catch((err) => {
        setInterviewIndex(0);
        handleOpenFour();
        // handleOpen();
        // setCandidateState([]);
      });
  };

  const handleCardMove = (_card, source, destination) => {
    console.log(_card, "1", source, "2", destination);
    setFieldValue("cardCollem", false);
    if (source.fromColumnId < destination.toColumnId) {
      setCandidateId(_card?.id);
      formikInterview.setFieldValue(
        "candidateName",
        _card?.title?.props?.children[0]?.props?.children ?? ""
      );
      formikInterview.setFieldValue(
        "email",
        _card?.description?.props?.children[1]?.props?.children ?? ""
      );
      if (destination.toColumnId == 2) {
        setFieldValue("candidateStatusId", 1);
        // setFieldValue("candidateStateId", null);
        selectStateHandler(1, "currently in bond");
        setFieldValue("cardCollem", true);
        // handleOpen();
      } else if (destination.toColumnId == 3) {
        setFieldValue("candidateStatusId", 4);
        selectInterviewStateHandler(4, "scheduled");
        // setFieldValue("candidateStateId", );
      } else if (destination.toColumnId == 4) {
        setFieldValue("candidateStatusId", 4);
        setFieldValue("cardCollem", true);
        // setFieldValue("candidateStateId", null);
        selectStateHandler(4, "Offer Accepted");
        // handleOpen();
        // selectStateHandler(5, "offer selected");
      } else if (destination.toColumnId == 5) {
        setFieldValue("candidateStatusId", 1);
        selectStateHandler(1, "employee");
        setFieldValue("cardCollem", true);
      } else if (destination.toColumnId == 6) {
        setFieldValue("candidateStatusId", 4);
        selectStateHandler(4, "rejected");
        setFieldValue("cardCollem", true);
      } else if (destination.toColumnId == 7) {
        setFieldValue("candidateStatusId", 2);
        setFieldValue("candidateStateId", null);
        selectStateHandler(2, "");
        // handleOpen();
        // selectStateHandler(2,'rejected');
      }

      const updatedBoard = moveCard(controlledBoard, source, destination);
      setBoard(updatedBoard);
    } else {
      toast.error("You can not update to this status");
      setBoard(controlledBoard);
    }
  };
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const handlePopoverMouseOpen = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handlePopoverMouseClose = () => {
    setAnchorEl2(null);
  };

  const openMouse = Boolean(anchorEl2);

  return (
    <Box sx={{ width: "100%", maxWidth: "100%", overflow: "auto" }}>
      <Stack
        className="job-description-data"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <Stack direction={"column"}>
          {/* <Typography variant="h4" gutterBottom>
            Hiring Process
          </Typography> */}
          <Typography variant="h4" gutterBottom>
            {state.name} {state.name ? " - " : ""} {params.id || ""}
          </Typography>
          <Stack direction={"row"} spacing={1}>
            <Typography variant="h5" gutterBottom>
              Team:
            </Typography>
            <Typography variant="h5" gutterBottom>
              {state.hrList}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          className="employee-header-right"
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          {getAddVisible("candidates") && (
            <Button
              variant="contained"
              // component={RouterLink}
              // to="/job-description"
              // startIcon={<ArrowBackIcon />}
              // startIcon={<AddIcon />}
              onClick={() => {
                handleOpenSecondModal();
              }}
              className="header-add-button"
              disabled={state.disableData}
            >
              <img src="/assets/images/add-circle.svg" />
              Add Candidate
            </Button>
          )}
          <Button
            className="back-button"
            variant="contained"
            component={RouterLink}
            to="/job-description"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </Stack>
      </Stack>
      <Board onCardDragEnd={handleCardMove} disableColumnDrag>
        {controlledBoard}
      </Board>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={handleSubmit}>
            <div className="education">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                my={3}
              >
                <Typography variant="h6" gutterBottom>
                  Activity
                </Typography>
              </Stack>

              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={statusData || []}
                      getOptionLabel={(option) => option.name || ""}
                      {...getFieldProps("candidateStatusId")}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          "candidateStatusId",
                          newValue?.id || null
                        );
                        selectStateHandler(newValue?.id, "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            touched?.candidateStatusId &&
                              errors?.candidateStatusId
                          )}
                          helperText={
                            touched?.candidateStatusId &&
                            errors?.candidateStatusId
                          }
                          label="Status"
                        />
                      )}
                      value={statusData.find(
                        (status) => status.id === values.candidateStatusId
                      )}
                      error={true}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={6}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={stateData || []}
                      getOptionLabel={(option) => option.name || ""}
                      {...getFieldProps("candidateStateId")}
                      onChange={(event, newValue) => {
                        setFieldValue("candidateStateId", newValue?.id || null);
                        setFieldValue(
                          "candidateStatename",
                          newValue?.name || null
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(
                            touched?.candidateStateId &&
                              errors?.candidateStateId
                          )}
                          helperText={
                            touched?.candidateStateId &&
                            errors?.candidateStateId
                          }
                          label="State"
                        />
                      )}
                      value={stateData.find(
                        (status) => status.id === values.candidateStateId
                      )}
                      error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Follow Up Date"
                      inputFormat="MM/yyyy"
                      views={["year", "month"]}
                      minDate={new Date()}
                      value={values.futureDate}
                      name={`futureDate`}
                      onChange={(newValue) => {
                        setFieldValue(`futureDate`, newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth size="small" />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* )} */}
                {/* {console.log(values,'darshit54')} */}
                {(values.candidateStatusId == 5 ||
                  values.candidateStatename?.toLowerCase() ===
                    "offer accepted") && (
                  <Grid item xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Joining Date"
                        inputFormat="dd/MM/yyyy"
                        // views={["year", "month"]}
                        minDate={new Date()}
                        value={values.joiningDate}
                        name={`joiningDate`}
                        onChange={(newValue) => {
                          setFieldValue(`joiningDate`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.joiningDate && errors?.joiningDate
                            )}
                            helperText={
                              touched?.joiningDate && errors?.joiningDate
                            }
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                )}
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched?.note && errors?.note)}
                  >
                    <TextareaAutosize
                      aria-label="Note..."
                      minRows={4}
                      // placeholder="Minimum 3 rows"
                      className="custom-textarea"
                      inputProps={{ maxLength: 512 }}
                      {...getFieldProps("note")}
                    />
                    <FormHelperText style={{ margin: "5px 0 0 0" }}>
                      {touched?.note && errors?.note}
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                spacing={2}
                mb={3}
                mt={4}
              >
                <Button onClick={() => handleClose()} variant="contained">
                  Close
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  data-cy="btn-submit-job-description"
                >
                  save
                </LoadingButton>
              </Stack>
            </div>
          </form>
        </Box>
      </Modal>

      <Modal
        open={openSecondModal}
        // onClose={handleCloseSecondModal}
        onClose={() => {
          handleCloseSecondModal();
          setCandidateResume(null);
          setCandidateUpdateId(null);
          formikCandidate.resetForm();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={styleSecond}
          style={{
            overflowY: "scroll",
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0",
          }}
        >
          <Typography variant="h5" className="personal-title">
            {/* Add Candidate To Quailty Analyst */}
            Add Candidate To {state.name}
          </Typography>
          <form
            className="Add-Candidate-form"
            style={{ padding: "20px 30px" }}
            onSubmit={(...args) => {
              setSubmitted(true);
              formikCandidate.handleSubmit(...args);
            }}
          >
            {/* <Accordion
              style={{
                marginBottom: "10px",
                border: "1px solid #919eab3d",
                borderRadius: "5px",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChangeAccordion("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6" className="personal-h6">
                    Personal Details
                  </Typography>
                  {submitted &&
                    checkPersonalDetails(formikCandidate.errors) && (
                      <ErrorOutlineIcon color="error" />
                    )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails> */}
            <AddCandidate
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              candidateResume={candidateResume}
              setCandidateResume={setCandidateResume}
              personal={personal}
              setPersonal={setPersonal}
              setFieldValue={formikCandidate.setFieldValue}
              values={formikCandidate.values}
              handleChange={formikCandidate.handleChange}
              touched={formikCandidate.touched}
              errors={formikCandidate.errors}
              jobId={state?.id}
              setCandidateUpdateId={setCandidateUpdateId}
              // handleTabChange={handleTabChange}
              selectedFileUrl={selectedFileUrl}
              setSelectedFileUrl={setSelectedFileUrl}
              helperText={formikCandidate.errors}
              setToBeDeletedCandidateResume={setToBeDeletedCandidateResume}
              designationData={designationData}
              designationDisable={designationDisable}
              candidateId=""
            />
            {/* </AccordionDetails>
            </Accordion>
            <Accordion
              style={{
                marginBottom: "10px",
                border: "1px solid #919eab3d",
                borderRadius: "5px",
              }}
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6" className="personal-h6">
                    Activity
                  </Typography>{" "}
                  {submitted &&
                    checkActivityDetails(formikCandidate.errors) && (
                      <ErrorOutlineIcon color="error" />
                    )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <AddActivity
                  setFieldValue={formikCandidate.setFieldValue}
                  values={formikCandidate.values}
                  handleChange={formikCandidate.handleChange}
                  touched={formikCandidate.touched}
                  errors={formikCandidate.errors}
                  getFieldProps={formikCandidate.getFieldProps}
                />
              </AccordionDetails>
            </Accordion> */}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  formikCandidate.resetForm();
                  setCandidateResume(null);
                  setCandidateUpdateId(null);
                  handleCloseSecondModal();
                }}
              >
                Close
              </Button>
              <LoadingButton
                loading={formikCandidate.isSubmitting}
                variant="contained"
                // onClick={() => handleSubmit()}
                type="submit"
                sx={{ marginRight: "15px", width: "35px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      </Modal>

      <Modal
        open={open5}
        onClose={handleClose5}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style5}>
          <Stack>
            <p>Are you sure you want to convert to employee</p>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button variant="outlined" onClick={() => handleClose5()}>
                Close
              </Button>
              <LoadingButton
                // loading={formikCandidate.isSubmitting}
                variant="contained"
                onClick={() => confirmConvertToEmployee()}
                type="submit"
                sx={{ marginRight: "15px", width: "35px" }}
              >
                Confirm
              </LoadingButton>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openFour}
        onClose={handleCloseFour}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleSecond}>
          <form onSubmit={formikInterview.handleSubmit}>
            <Stack>
              <Card sx={{ padding: "1rem", marginBottom: "2rem" }}>
                <Typography marginBottom={2} variant="h6">{`Round ${
                  interviewIndex + 1
                }`}</Typography>
                <Grid container spacing={3} pb={3}>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack>
                        <DesktopDatePicker
                          label="Date"
                          value={
                            formikInterview.values.interviewDetails[
                              interviewIndex
                            ]?.interviewDate ?? ""
                          }
                          inputFormat="dd/MM/yyyy"
                          onChange={(newValue) => {
                            formikInterview.setFieldValue(
                              `interviewDetails[${interviewIndex}].interviewDate`,
                              newValue
                            );
                            interviewerList(interviewIndex);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              helperText={
                                formikInterview.touched?.interviewDetails &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ]?.interviewDate &&
                                formikInterview.errors?.interviewDetails &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ]?.interviewDate
                              }
                              error={Boolean(
                                formikInterview.touched?.interviewDetails &&
                                  formikInterview.touched?.interviewDetails[
                                    interviewIndex
                                  ] &&
                                  formikInterview.touched?.interviewDetails[
                                    interviewIndex
                                  ]?.interviewDate &&
                                  formikInterview.errors?.interviewDetails &&
                                  formikInterview.errors?.interviewDetails[
                                    interviewIndex
                                  ] &&
                                  formikInterview.errors?.interviewDetails[
                                    interviewIndex
                                  ]?.interviewDate
                              )}
                              size="small"
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack>
                        <DesktopTimePicker
                          label="Time"
                          ampm={false}
                          value={
                            formikInterview.values.interviewDetails[
                              interviewIndex
                            ]?.interviewTime ?? ""
                          }
                          fullWidth
                          onChange={(newValue) => {
                            formikInterview.setFieldValue(
                              `interviewDetails[${interviewIndex}].interviewTime`,
                              newValue
                            );
                            interviewerList(interviewIndex);
                          }}
                          onBlur={(e) => {
                            interviewerList(interviewIndex);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={
                                formikInterview.touched?.interviewDetails &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ]?.interviewTime &&
                                formikInterview.errors?.interviewDetails &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ]?.interviewTime
                              }
                              error={Boolean(
                                formikInterview.touched?.interviewDetails &&
                                  formikInterview.touched?.interviewDetails[
                                    interviewIndex
                                  ] &&
                                  formikInterview.touched?.interviewDetails[
                                    interviewIndex
                                  ]?.interviewTime &&
                                  formikInterview.errors?.interviewDetails &&
                                  formikInterview.errors?.interviewDetails[
                                    interviewIndex
                                  ] &&
                                  formikInterview.errors?.interviewDetails[
                                    interviewIndex
                                  ]?.interviewTime
                              )}
                              size="small"
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={4}>
                    {
                      <FormControl
                        size="small"
                        sx={{ minWidth: 120 }}
                        fullWidth
                      >
                        {
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={technologyData.data || []}
                            key={technologyData.data || []}
                            getOptionLabel={(option) => option.title || ""}
                            {...getFieldProps(
                              `interviewDetails[${interviewIndex}].technologies`
                            )}
                            onChange={(event, newValue) => {
                              formikInterview.setFieldValue(
                                `interviewDetails[${interviewIndex}].technologies`,
                                newValue?.id || ""
                              );
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  formikInterview.touched?.interviewDetails &&
                                    formikInterview.touched?.interviewDetails[
                                      interviewIndex
                                    ] &&
                                    formikInterview.touched?.interviewDetails[
                                      interviewIndex
                                    ]?.technologies &&
                                    formikInterview.errors?.interviewDetails &&
                                    formikInterview.errors?.interviewDetails[
                                      interviewIndex
                                    ] &&
                                    formikInterview.errors?.interviewDetails[
                                      interviewIndex
                                    ]?.technologies
                                )}
                                helperText={
                                  formikInterview.touched?.interviewDetails &&
                                  formikInterview.touched?.interviewDetails[
                                    interviewIndex
                                  ] &&
                                  formikInterview.touched?.interviewDetails[
                                    interviewIndex
                                  ]?.technologies &&
                                  formikInterview.errors?.interviewDetails &&
                                  formikInterview.errors?.interviewDetails[
                                    interviewIndex
                                  ] &&
                                  formikInterview.errors?.interviewDetails[
                                    interviewIndex
                                  ]?.technologies
                                }
                                label="Technology"
                              />
                            )}
                            //value={values.interviewDetails[index].technologies}
                            value={technologyData.data.find(
                              (interview) =>
                                interview.id ===
                                formikInterview.values.interviewDetails[
                                  interviewIndex
                                ]?.technologies
                            )}
                          />
                        }

                        {/*<InputLabel id="demo-simple-select-helper-label">
                        Technology
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Technology"
                        size="small"
                        defaultValue=""
                        multiple
                        name="technologies"
                        {...getFieldProps(
                          `interviewDetails[${index}].technologies`
                        )}
                      >
                        {technologyData.data &&
                          technologyData.data.map((tech) => {
                            return (
                              <MenuItem key={tech.id} value={tech.title}>
                                {tech.title}
                              </MenuItem>
                            );
                          })}
                        </Select> */}
                      </FormControl>
                    }
                  </Grid>
                  <Grid item xs={4}>
                    <Tabs
                      value={
                        formikInterview.values.interviewDetails[interviewIndex]
                          ?.duration == 15
                          ? 0
                          : formikInterview.values.interviewDetails[
                              interviewIndex
                            ]?.duration == 30
                          ? 1
                          : formikInterview.values.interviewDetails[
                              interviewIndex
                            ]?.duration == 45
                          ? 2
                          : formikInterview.values.interviewDetails[
                              interviewIndex
                            ]?.duration == 60
                          ? 3
                          : 4
                      }
                      onChange={handleChangeTab}
                      variant="scrollable"
                      scrollButtons={false}
                      sx={{
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                      aria-label="scrollable prevent tabs example"
                    >
                      <Tab
                        sx={{
                          border: "1px solid",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        label="15 MIN"
                        onClick={() => {
                          formikInterview.setFieldValue(
                            `interviewDetails[${interviewIndex}].duration`,
                            15
                          );
                          interviewerListTab(interviewIndex, 15);
                        }}
                      />
                      <Tab
                        sx={{ border: "1px solid" }}
                        label="30 MIN"
                        onClick={() => {
                          formikInterview.setFieldValue(
                            `interviewDetails[${interviewIndex}].duration`,
                            30
                          );
                          interviewerListTab(interviewIndex, 30);
                        }}
                      />
                      <Tab
                        sx={{
                          border: "1px solid",
                        }}
                        label="45 MIN"
                        onClick={() => {
                          formikInterview.setFieldValue(
                            `interviewDetails[${interviewIndex}].duration`,
                            45
                          );
                          interviewerListTab(interviewIndex, 45);
                        }}
                      />
                      <Tab
                        sx={{
                          border: "1px solid",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                        label="60 MIN"
                        onClick={() => {
                          formikInterview.setFieldValue(
                            `interviewDetails[${interviewIndex}].duration`,
                            60
                          );
                          interviewerListTab(interviewIndex, 60);
                        }}
                      />
                    </Tabs>
                  </Grid>
                  <Grid item xs={4}>
                    {/* <Stack direction="row"> */}{" "}
                    <TextField
                      fullWidth
                      label="Interview Duration In Minutes"
                      name="duration"
                      variant="outlined"
                      size="small"
                      type="number"
                      onChange={(e) => {
                        formikInterview.setFieldValue(
                          `interviewDetails[${interviewIndex}].duration`,
                          e.target.value
                        );
                      }}
                      onBlur={(e) => {
                        interviewerList(interviewIndex);
                      }}
                      InputLabelProps={{
                        shrink:
                          formikInterview.values.interviewDetails[
                            interviewIndex
                          ]?.duration ?? false,
                      }}
                      inputProps={{ maxLength: 64 }}
                      value={
                        formikInterview.values.interviewDetails[interviewIndex]
                          ?.duration
                      }
                      error={Boolean(
                        formikInterview.touched?.interviewDetails &&
                          formikInterview.touched?.interviewDetails[
                            interviewIndex
                          ] &&
                          formikInterview.touched?.interviewDetails[
                            interviewIndex
                          ]?.duration &&
                          formikInterview.errors?.interviewDetails &&
                          formikInterview.errors?.interviewDetails[
                            interviewIndex
                          ] &&
                          (formikInterview.errors?.interviewDetails[
                            interviewIndex
                          ]?.duration ??
                            false)
                      )}
                      helperText={
                        formikInterview.touched?.interviewDetails &&
                        formikInterview.touched?.interviewDetails[
                          interviewIndex
                        ] &&
                        formikInterview.touched?.interviewDetails[
                          interviewIndex
                        ]?.duration &&
                        formikInterview.errors?.interviewDetails &&
                        formikInterview.errors?.interviewDetails[
                          interviewIndex
                        ] &&
                        (formikInterview.errors?.interviewDetails[
                          interviewIndex
                        ]?.duration ??
                          "")
                      }
                    />
                    {/* </Stack> */}
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      <Autocomplete
                        multiple
                        size="small"
                        fullWidth
                        options={interviewListData || []}
                        getOptionLabel={(option) =>
                          option.firstName + " " + option.lastName || ""
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option.firstName + " " + option.lastName || ""}
                            {option.isBusy == true ? " - " : ""}
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {option.isBusy == true ? "busy" : ""}
                            </div>
                          </Box>
                          // <li {...props} value={option.id} key={option.id}>
                          //   {option.firstName + " " + option.lastName || ""}
                          // </li>
                        )}
                        {...getFieldProps(
                          `interviewDetails[${interviewIndex}].interviewrefrences`
                        )}
                        onChange={(event, newValue) => {
                          formikInterview.setFieldValue(
                            `interviewDetails[${interviewIndex}].interviewrefrences`,
                            newValue
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              formikInterview.touched?.interviewDetails &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ]?.interviewrefrences &&
                                formikInterview.errors?.interviewDetails &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ]?.interviewrefrences
                            )}
                            helperText={
                              formikInterview.touched?.interviewDetails &&
                              formikInterview.touched?.interviewDetails[
                                interviewIndex
                              ] &&
                              formikInterview.touched?.interviewDetails[
                                interviewIndex
                              ]?.interviewrefrences &&
                              formikInterview.errors?.interviewDetails &&
                              formikInterview.errors?.interviewDetails[
                                interviewIndex
                              ] &&
                              formikInterview.errors?.interviewDetails[
                                interviewIndex
                              ]?.interviewrefrences
                            }
                            label="Interviewer Name"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      <Autocomplete
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        fullWidth
                        options={interviewTypeData.data || []}
                        getOptionLabel={(option) => option.title || ""}
                        {...getFieldProps(
                          `interviewDetails[${interviewIndex}].interviewType`
                        )}
                        onChange={(event, newValue) => {
                          formikInterview.setFieldValue(
                            `interviewDetails[${interviewIndex}].interviewType`,
                            newValue?.title || ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              formikInterview.touched?.interviewDetails &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.touched?.interviewDetails[
                                  interviewIndex
                                ].interviewType &&
                                formikInterview.errors?.interviewDetails &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ] &&
                                formikInterview.errors?.interviewDetails[
                                  interviewIndex
                                ].interviewType
                            )}
                            helperText={
                              formikInterview.touched?.interviewDetails &&
                              formikInterview.touched?.interviewDetails[
                                interviewIndex
                              ] &&
                              formikInterview.touched?.interviewDetails[
                                interviewIndex
                              ].interviewType &&
                              formikInterview.errors?.interviewDetails &&
                              formikInterview.errors?.interviewDetails[
                                interviewIndex
                              ] &&
                              formikInterview.errors?.interviewDetails[
                                interviewIndex
                              ].interviewType
                            }
                            label="Interview Type"
                          />
                        )}
                        //value={values.interviewDetails[index].interviewType}
                        value={
                          interviewTypeData.data.find(
                            (i) =>
                              i.title ===
                              formikInterview.values.interviewDetails[
                                interviewIndex
                              ]?.interviewType
                          ) || ""
                        }
                        error={true}
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl
                      error={Boolean(
                        formikInterview.touched?.interviewDetails &&
                          formikInterview.touched?.interviewDetails[
                            interviewIndex
                          ] &&
                          formikInterview.touched?.interviewDetails[
                            interviewIndex
                          ]?.comments &&
                          formikInterview.errors?.interviewDetails &&
                          formikInterview.errors?.interviewDetails[
                            interviewIndex
                          ] &&
                          formikInterview.errors?.interviewDetails[
                            interviewIndex
                          ]?.comments
                      )}
                      fullWidth
                    >
                      <TextareaAutosize
                        aria-label="Comments"
                        minRows={4}
                        placeholder="E.g. Good Communication Skill"
                        className="custom-textarea"
                        {...getFieldProps(
                          `interviewDetails[${interviewIndex}].comments`
                        )}
                      />
                      <FormHelperText style={{ margin: "5px 0 0 0" }}>
                        {formikInterview.touched?.interviewDetails &&
                          formikInterview.touched?.interviewDetails[
                            interviewIndex
                          ] &&
                          formikInterview.touched?.interviewDetails[
                            interviewIndex
                          ]?.comments &&
                          formikInterview.errors?.interviewDetails &&
                          formikInterview.errors?.interviewDetails[
                            interviewIndex
                          ] &&
                          formikInterview.errors?.interviewDetails[
                            interviewIndex
                          ]?.comments}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
              </Card>
              <Stack
                direction="row"
                spacing={2}
                justifyContent="end"
                sx={{ mt: 2 }}
              >
                <Button variant="outlined" onClick={() => handleCloseFour()}>
                  Close
                </Button>
                <LoadingButton
                  // loading={formikCandidate.isSubmitting}
                  variant="contained"
                  // onClick={() => confirmConvertToEmployee()}
                  type="submit"
                  sx={{ marginRight: "15px", width: "35px" }}
                >
                  Save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Box>
      </Modal>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openMouse}
        anchorEl={anchorEl2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverMouseClose}
        disableRestoreFocus
      >
        <Stack direction="column" spacing={1} margin={2}>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Typography component="h5" sx={{ fontWeight: "Bold" }}>
              Round -{" "}
              {`${interviewDataShow?.Round ? interviewDataShow?.Round : "-"}`}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography component="span">Interview Date:</Typography>
            <Typography>{`${
              interviewDataShow?.interviewDate
                ? fDateCustom(interviewDataShow?.interviewDate)
                : "-"
            }`}</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography component="span">Interview Time:</Typography>
            <Typography>{`${
              interviewDataShow?.interviewTime
                ? convertTo12HrsFormat(interviewDataShow?.interviewTime)
                : ""
            }`}</Typography>
            {/* convertTo12HrsFormat,
  convertTo24HrsFormat, */}
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography component="span">Duration:</Typography>
            <Typography>
              {interviewDataShow?.duration
                ? `${interviewDataShow?.duration} min`
                : "-"}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography component="span">Interviewer:</Typography>
            <Typography>
              {interviewDataShow?.interviewrefrences &&
                interviewDataShow.interviewrefrences[0] &&
                interviewDataShow.interviewrefrences
                  .map(
                    (employee) =>
                      `${employee.interviewer.firstName} ${employee.interviewer.lastName}`
                  )
                  .join(", ")}{" "}
            </Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography component="span">Type:</Typography>
            <Typography>{`${interviewDataShow?.interviewType}`}</Typography>
          </Stack>
          <Stack direction="row" spacing={2}>
            <Typography component="span">Technology:</Typography>
            <Typography>{`${interviewDataShow?.technology?.title}`}</Typography>
          </Stack>
        </Stack>
      </Popover>
    </Box>
  );
};

const componentConfig = [
  {
    component: ManageJobStatus,
    path: "/job-description-details/:id",
    public: false,
    layout: DashboardLayout,
    group: "candidate",
    sidebar: true,
    role: ["admin"],
  },
];
// convertTo12HrsFormat,
//   convertTo24HrsFormat,
//   fDateCustom,
//   fDateTime12,
export default componentConfig;
