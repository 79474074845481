import React, { useRef, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useConfirm } from "material-ui-confirm";
import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";

import CloseIcon from "@mui/icons-material/Close";
import invertDirection from "../../../utils/invertDirection";

import {
  getAsset,
  updateAsset,
  deleteById,
  insert,
} from "../../../redux/itAsset/thunk";
import { setSortBy, setOrderBy } from "../../../redux/itAsset/itAssetSlice";
import { assetMaster } from "../../../validations/mastersSchema";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../../utils/encodeString";
import LocalStorage from "../../../service/localStorage";
import { Autocomplete } from "@mui/material";

function Index() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [iconPathData, setIconPathData] = useState([]);
  const confirm = useConfirm();
  const { data, loading, sortBy, orderBy } = useSelector(
    (state) => state.getAsset
  );

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("assetMapping")) {
      dispatch(getAsset({ sortBy, orderBy }));
      navigate("/dashboard");
    }
  }, []);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
    setIconPathData([
      "DesktopWindows",
      "Storage",
      "TabletAndroid",
      "Print",
      "Smartphone",
      "LaptopMac",
      "LaptopWindows",
    ]);
  }, [dispatch]);
  useEffect(() => {
    dispatch(getAsset({ sortBy, orderBy }));
  }, [sortBy, orderBy]);

  //console.log(data)
  const [id, setId] = React.useState(null);
  const searchFormRef = useRef();
  const formik = useFormik({
    initialValues: {
      name: "",
      iconPath: "",
      assetPrefix: "",
    },
    validationSchema: assetMaster,
    onSubmit: async (values, { resetForm }) => {
      
      try {
        if (id) {
          await dispatch(updateAsset({ ...values, id })).unwrap();
          resetForm();
        }
        if (!id) {
          let insetP = await dispatch(insert(values)).unwrap();
         if(insetP?.success){
          resetForm();
         }
        }
        dispatch(getAsset({ sortBy, orderBy }));
        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    isSubmitting,
    setFieldValue,
  } = formik;

  const handleUpdate = (data) => {
    setValues({
      name: data.name,
      iconPath: data.iconPath,
      assetPrefix: data.assetPrefix,
    });
    setFieldValue("iconPath", data?.iconPath);
    setId(data.id);
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  const handleSearch = async (e) => {
    await dispatch(getAsset({ name: e.target.value, sortBy, orderBy }));
  };
  const debounceWithSearch = debounce(handleSearch, 500);

  const handleReset = async () => {
    searchFormRef.current.reset();
    await dispatch(getAsset({ sortBy, orderBy }));
  };

  const handleDelete = async (id, name) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${name}?`,
      });
      await dispatch(deleteById(id));
      await dispatch(getAsset({ sortBy, orderBy }));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  
  return (
    <Container className="IT-Asset-wrapper" maxWidth="xl">
       <div className="title-button-wrapper">
      <Typography variant="h4" gutterBottom mb={3}>
        IT Assets ({data ? data.length : 0})
        {/* <Typography variant="body2">
          Total Records : {data ? data.length : 0}
        </Typography> */}
       
      </Typography>
       </div>
      {getAddVisible("assetMapping") && (
        <Card>
          <Container maxWidth="xl">
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className="custom-space-layout"
            >
              <Typography style={{paddingBottom: "12px"}} variant="h5" mt={2} mb={-1}>
                Add Assets
              </Typography>
              <Grid container spacing={3} py={3}>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Asset Name"
                    variant="outlined"
                    size="small"
                    name="name"
                    autoComplete="off"
                    inputProps={{
                      maxLength: 256,
                      "data-cy": "txt-title-manufacturer",
                    }}
                    FormHelperTextProps={{
                      "data-cy": "txt-title-err-manufacturer",
                    }}
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3}>
                  {/* <TextField
                    id="outlined-basic"
                    label="Enter Asset Icon Path"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    name="iconPath"
                    inputProps={{
                      maxLength: 256,
                      "data-cy": "txt-title-manufacturer",
                    }}
                    FormHelperTextProps={{
                      "data-cy": "txt-title-err-manufacturer",
                    }}
                    value={values.iconPath}
                    onChange={handleChange}
                    error={Boolean(touched.iconPath && errors.iconPath)}
                    helperText={touched.iconPath && errors.iconPath}
                    fullWidth
                  /> */}

                  <Autocomplete
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    name="iconPath"
                    options={iconPathData || []}
                    getOptionLabel={(option) => option || ""}
                    // {...getFieldProps("iconPath")}
                    onChange={(event, newValue) =>
                      setFieldValue("iconPath", newValue || "")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Enter Asset Icon Path"
                        error={touched?.iconPath && errors?.iconPath}
                        helperText={touched?.iconPath && errors?.iconPath}
                      />
                    )}
                    value={values?.iconPath || ""}
                    error={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Asset Prefix"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    name="assetPrefix"
                    inputProps={{
                      maxLength: 256,
                      "data-cy": "txt-title-manufacturer",
                    }}
                    FormHelperTextProps={{
                      "data-cy": "txt-title-err-manufacturer",
                    }}
                    value={values.assetPrefix}
                    onChange={handleChange}
                    error={Boolean(touched.assetPrefix && errors.assetPrefix)}
                    helperText={touched.assetPrefix && errors.assetPrefix}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <Stack direction="row" alignItems="center">
                    <Stack className="button-part">
                      <LoadingButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        data-cy="btn-submit-manufacturer"
                      >
                        {!id ? "save" : "Update"}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Card>
      )}
      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            {loading ? (
              <Loader />
            ) : (
              <Table className="grid-table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="20%">
                      <TableSortLabel
                        active={sortBy === "name"}
                        direction={sortBy === "name" ? orderBy : "asc"}
                        onClick={() => handleSorting("name")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>

                    <TableCell width="25%">
                      <TableSortLabel hideSortIcon="true">
                        Icon Path
                      </TableSortLabel>
                    </TableCell>

                    <TableCell width="25%">
                      <TableSortLabel hideSortIcon="true">
                        Asset Prefix
                      </TableSortLabel>
                    </TableCell>
                    {(getEditVisible("assetMapping") ||
                      getDeleteVisible("assetMapping")) && (
                      <TableCell
                        width="10%"
                        align="right"
                        className="action-field"
                      >
                        <TableSortLabel hideSortIcon="true">
                          Actions
                        </TableSortLabel>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={3}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((asset, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          {asset.name}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {asset.iconPath}
                        </TableCell>
                        <TableCell component="td" scope="row">
                          {asset.assetPrefix}
                        </TableCell>
                        {(getEditVisible("assetMapping") ||
                          getDeleteVisible("assetMapping")) && (
                          <TableCell className="td-action-button" align="right">
                            <div className="action-button">
                              {getEditVisible("assetMapping") && (
                                <IconButton
                                  data-cy="btn-edit-holiday"
                                  onClick={() => handleUpdate(asset)}
                                >
                                  {/* <EditIcon color="primary" /> */}
                                  <img src="assets/images/edit.svg" title="Edit" />
                                </IconButton>
                              )}
                              {getDeleteVisible("assetMapping") && (
                                <IconButton
                                  data-cy="btn-delete-holiday"
                                  onClick={() =>
                                    handleDelete(asset.id, asset.name)
                                  }
                                >
                                  {/* <DeleteIcon color="error" /> */}
                                  <img src="assets/images/trash-gray.svg" title="trash" />
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

export default Index;
