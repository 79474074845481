import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NiceModal from "@ebay/nice-modal-react";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddCompanyModal from "../../../../components/Model/AddCompanyModal";
import moment from "moment";
import { getViewVisible } from "../../../../utils/userPermission";
import { makeStyles } from "@material-ui/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));
const filter = createFilterOptions();

export default function Experience(props) {
  const today = moment();
  const classes = useStyles();
  const designationData = useSelector((state) => state.designation);
  const technologyData = useSelector((state) => state.technology);
  const companyData = useSelector((state) => state.company);
  const jobChangeData = useSelector((state) => state.jobChange);
  // let [dateError,setDateError]=useState([])
const [preserveExperienceData, setPreserveExperienceData] = useState([]);
  // const [customFromToDateError, setCustomFromToDateError] = useState([]);
  // const [count,setCount]=useState(0)

  const {
    getFieldProps,
    setFieldValue,
    values,
    touched,
    errors,
    handleChange,
    handleTabChange,
    // setExperieneceError,
    // experieneceErrorShow,
    // experieneceErrorCheck,
    validateField,
    // setErrors,
    setFieldTouched,
    setFieldError,
    handleBlur,
    setStatus,
    status,
    isSubmitting,
  } = props;

  

  useEffect(() => {
    // console.log(2,"2");
    setPreserveExperienceData(values.experience);
  }, []);
  

useEffect(() => {
  // console.log(1,"1");
    if (Number(values.totalExperience) <= 0) {
      // console.log(2,"2");
      setFieldValue("experience", []);
    }else{
      setFieldValue("experience",preserveExperienceData)
    }
}, [values.totalExperience, preserveExperienceData]);

  const addExp = () => {
    setFieldValue("experience", [
      ...values.experience,
      {
        companyId: null,
        designationId: null,
        technologies: [],
        from: null,
        to: "",
        reasonForJobChange: null,
        totalExperience: "",
      },
    ]);
  };

  const [checkd,setcheckd]=useState()
  const removeExperience = (event, index) => {
    const newExperience = [...values.experience];
    newExperience.splice(index, 1);
    setFieldValue("experience", newExperience);
    // checkStatus();
  };
 const handlePresentCheckChanged = (e, index) => {
  let presentCheckFlag = false;
  
  if(e.target.checked){
  setcheckd({index:index,status:true})
  setFieldValue(`experience[${index}].to`, new Date());   
  }else{
    setcheckd({index:index,status:false})
    setFieldValue(`experience[${index}].to`, "");   
  }
  // setFieldValue(`experience[${index}].to`, e.target.checked ? new Date() : "");   
};
 


  return (
    <Card className="right-side-content-part">
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography className="Employee_Detail-h6" variant="h6" gutterBottom style={{ marginTop:"0", marginBottom:'8px'}}>
            Experience Details
          </Typography>

          {values.totalExperience != 0 && !Boolean(
                  errors &&
                  errors?.totalExperience
              ) && (
            <Button
            className="header-add-button"
                style={{marginBottom:"12px"}}
              onClick={addExp}
              variant="contained"
              // startIcon={<AddIcon />}
            >
              Add Experience
            </Button>
          )}
        </Stack>

        <Grid className="gray-box Employee_Detail_Content Contact-Detail-content" container spacing={3}>
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Total Experience"
              variant="outlined"
              size="small"
              name = "totalExperience"
              inputProps={{ maxLength: 4 }}
              {...getFieldProps(`totalExperience`)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.totalExperience}
              helperText={
                touched &&
                touched?.totalExperience &&
                errors &&
                errors?.totalExperience
              }
              error={Boolean(
                touched &&
                  touched?.totalExperience &&
                  errors &&
                  errors?.totalExperience
              )}
            />
          </Grid>

          {values.totalExperience == 0 && (
            <Grid item xs={4}>
              <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                  defaultValue={[]}
                  options={technologyData.data || []}
                  key={technologyData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`technologies`)}
                  onChange={(event, newValue) => {
                    let tempArray = newValue.map((x) => {
                      return { id: x.id, title: x.title, icon: x?.icon };
                    });

                    setFieldValue(`technologies`, tempArray || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={
                        touched?.experience &&
                        touched?.experience.technologies &&
                        errors?.experience &&
                        errors?.experience.technologies
                      }
                      error={Boolean(
                        touched?.experience &&
                          touched?.experience.technologies &&
                          errors?.experience &&
                          errors?.experience.technologies
                      )}
                      label="Technology"
                    />
                  )}
                  value={
                    values?.technologies&&Array.isArray(values?.technologies)
                      ? values?.technologies?.filter((x) => {
                          return x.id || "";
                        })
                      : []
                  }
                />

                {/* {console.log(values.technologies)} */}
              </FormControl>
            </Grid>
          )}
        </Grid>
        <Stack sx={{color:'red',justifyContent:'end',alignItems:'end'}}>{typeof(errors?.experience)==='string'?errors?.experience:''}</Stack>
        {values &&
          values.totalExperience != 0 &&
          values.experience.map((ex, index) => (
            <Grid
              container
              spacing={3}
              key={index}
              className="form-bottom-border gray-box Employee_Detail_Content Experience-company-detail"
            >
              <Grid item xs={3}>
                <Autocomplete
                disabled={getViewVisible("company") === false}
                  value={
                    companyData.companyForDropdown.find(
                      (cmpy) => cmpy.id === values.experience[index].companyId
                    ) ?? ""
                  }
                  onChange={async (event, newValue) => {
                    event.preventDefault();
                    if (typeof newValue === "string") {
                      // timeout to avoid instant validation of the dialog's form.
                      setTimeout(async () => {
                        const res = await NiceModal.show(AddCompanyModal, {
                          name: newValue,
                        });
                        setFieldValue(`experience[${index}].companyId`, res?.id||'');
                      });
                    } else if (newValue && newValue.inputValue) {
                      const res = await NiceModal.show(AddCompanyModal, {
                        name: newValue.inputValue,
                      });
                      setFieldValue(`experience[${index}].companyId`, res?.id||'');
                    } else {
                      setFieldValue(
                        `experience[${index}].companyId`,
                        newValue?.id||''
                      );
                    }
                  }}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== "") {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`,
                      });
                    }

                    return filtered;
                  }}
                  options={companyData.companyForDropdown || []}
                  getOptionLabel={(option) => {
                    // e.g value selected with enter, right from the input
                    if (typeof option === "string") {
                      return option;
                    }

                    if (option.inputValue) {
                      return option.name;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  handleHomeEndKeys
                  renderOption={(props, option) => (
                    <li {...props} value={option.id} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  fullWidth
                  size="small"
                  freeSolo
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(
                        touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.companyId &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.companyId
                      )}
                      helperText={
                        touched?.experience &&
                        touched?.experience[index] &&
                        touched?.experience[index]?.companyId &&
                        errors?.experience &&
                        errors?.experience[index] &&
                        errors?.experience[index]?.companyId
                      }
                      {...params}
                      label="Company"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  size="small"
                  fullWidth
                  popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                  options={designationData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`experience[${index}].designationName`)}
                  onChange={(event, newValue) => {
                    if(newValue?.inputValue){
                      setFieldValue(
                        `experience[${index}].designationId`,
                        null 
                      );
                      setFieldValue(
                        `experience[${index}].designationName`,
                        newValue?.inputValue|| ""
                      );
                    }else{
                      setFieldValue(
                        `experience[${index}].designationId`,
                        newValue?.id || ""
                      );
                      setFieldValue(
                        `experience[${index}].designationName`,
                        newValue?.title|| ""
                      );
                    }
                  }}
                  filterOptions={(options, params) => {
                    // console.log();
                    const filtered = filter(options, params);
                
                    const { inputValue } = params;
                    // Suggest the creation of a new value
                    const isExisting = options.some((option) => inputValue === option.title);
                    if (inputValue !== '' && !isExisting) {
                      filtered.push({
                      inputValue,
                      title: `Add "${inputValue}"`,
                      });
                    }
                
                    return filtered;
                    }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={Boolean(
                        touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.designationId &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.designationName
                      )}
                      helperText={
                        touched?.experience &&
                        touched?.experience[index] &&
                        touched?.experience[index]?.designationId &&
                        errors?.experience &&
                        errors?.experience[index] &&
                        errors?.experience[index]?.designationName
                      }
                      label="Designation"
                    />
                  )}
                  // value={designationData.data.find(
                  //   (designation) =>
                  //     designation.id === values.experience[index].designationId
                  // )}
                  value={{id:values.experience[index].designationId,title:values.experience[index].designationName}}
                  error={Boolean(true)}
                />
              </Grid>

              <Grid item xs={4}>
                

                <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                  <Autocomplete
                    multiple
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    defaultValue={[]}
                    options={technologyData.data || []}
                    key={technologyData.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    {...getFieldProps(`experience[${index}].technologies`)}
                    onChange={(event, newValue) => {
                      let tempArray = newValue.map((x) => x.id);
                      setFieldValue(
                        `experience[${index}].technologies`,
                        tempArray || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.technologies &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.technologies
                        }
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.technologies &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.technologies
                        )}
                        label="Technology"
                      />
                    )}
                    value={
                      technologyData.data.filter((x) =>
                        values.experience[index].technologies.includes(x.id)
                      ) || ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={1}>
                <Stack direction="row" alignItems="center" justifyContent="end">
                  {values &&
                    values.experience &&
                    Array.isArray(values.experience) &&
                    values.experience.filter((e) => !e.deleted).length > 0 && (
                      <IconButton onClick={(e) => removeExperience(e, index)}>
                        <DeleteIcon color="error" />
                      </IconButton>
                    )}
                </Stack>
              </Grid>
              <Grid item xs={3}>
                <FormControl size="small" sx={{ minWidth: 130 }} fullWidth>
                  {/* <InputLabel id="demo-simple-select-helper-label">
                    Reason for Job Change
                  </InputLabel> */}

                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={jobChangeData.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    {...getFieldProps(
                      `experience[${index}].reasonForJobChange`
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        `experience[${index}].reasonForJobChange`,
                        newValue?.id || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.reasonForJobChange &&
                            errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.reasonForJobChange
                        )}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.reasonForJobChange &&
                          errors?.experience &&
                          errors?.experience[index] &&
                          errors?.experience[index]?.reasonForJobChange
                        }
                        label="Reason For Job Change"
                      />
                    )}
                    value={
                      jobChangeData.data.find(
                        (x) =>
                          x.id == values.experience[index].reasonForJobChange
                      ) || ""
                    }
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="From"
                    // inputFormat="dd/MM/yyyy"
                    inputFormat="MM/yyyy"
                    mask="__/____"
                    disableMaskedInput={false}
                    views={["year", "month"]}
                    maxDate={new Date()}
                    value={values.experience[index].from || ""}
                    name={`experience[${index}].from`}
                    /* onBlur={(e) => checkFromDates(e, index)} */
                    onChange={async(newValue) => {
                      const firstDayOfMonth =moment(newValue).format('D')==moment().format('D')?new Date(newValue.getFullYear(), newValue.getMonth(), 1):new Date(newValue);
                      // setFieldValue(
                      //   `experience[${index}].from`,
                      //   newValue ? firstDayOfMonth : ""
                      // );
                      setFieldValue(
                        `experience[${index}].from`,
                        newValue ? new Date(newValue.getFullYear(), newValue.getMonth(), 2) : ""
                      );
                      // setFieldValue(
                      //   `experience[${index}].from`,
                      //   newValue ? new Date(newValue) : ""
                      // );
                      // setCount((a)=>a+1)
                      }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.from &&
                          ((errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.from) )
                              // ||dateError[index]?.from
                        }
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.from &&
                            ((errors?.experience &&
                              errors?.experience[index] &&
                              errors?.experience[index]?.from) )
                                // ||dateError[index]?.from
                        )}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="To"
                    inputFormat="MM/yyyy"
                    // inputFormat="dd/MM/yyyy"
                    mask="__/____"
                    disableMaskedInput={false}
                    views={["year", "month"]}
                    maxDate={new Date()}
                    disabled={values.experience[index].to === null}
                    value={moment(values.experience[index].to).isSame(today, 'day')?null:values.experience[index].to}
                    // value={values.experience[index].to}
                    name={`experience[${index}].to`}
                    // onChange={
                    // 	/* (e) => checkToDates(e, index) */ (newValue) => {
                    // 		setFieldValue(
                    // 			`experience[${index}].to`,
                    // 			newValue ? new Date(newValue) : "",
                    // 		);
                    // 	}
                    // }
                    
                    onChange={async(newValue) => {
                      // setFieldValue(
                      //   `experience[${index}].to`,
                      //   newValue ? new Date(newValue) : ""
                      // );
                      const firstDayOfMonth =moment(newValue).format('D')==moment().format('D')?new Date(newValue.getFullYear(), newValue.getMonth(), 1):new Date(newValue);
                      // setFieldValue(
                      //   `experience[${index}].to`,
                      //   newValue ? firstDayOfMonth : ""
                      // );
                      setFieldValue(
                        `experience[${index}].to`,
                        newValue ? new Date(newValue.getFullYear(), newValue.getMonth(), 1) : ""
                      );
                      // setCount((a)=>a+1)
                       }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        helperText={
                          touched?.experience &&
                          touched?.experience[index] &&
                          touched?.experience[index]?.to &&
                          ((errors?.experience &&
                            errors?.experience[index] &&
                            errors?.experience[index]?.to) )
                              // ||dateError[index]?.to
                        }
                        error={Boolean(
                          touched?.experience &&
                            touched?.experience[index] &&
                            touched?.experience[index]?.to &&
                            ((errors?.experience &&
                              errors?.experience[index] &&
                              errors?.experience[index]?.to))
                                // ||dateError[index]?.to
                        )}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={
                        values.experience[index].to !== null&&(!moment(values.experience[index].to).isSame(today, 'day')) &&
                        values.experience.some((e) => e.to === null||(moment(e.to).isSame(today, 'day')))
                      }
                      checked={values.experience[index].to === null||moment(values.experience[index].to).isSame(today, 'day')}
                      onChange={(e) => {handlePresentCheckChanged(e, index)}}
                    />
                  }
                  label="Present"
                />
              </Grid>
            </Grid>
          ))}

        <Typography variant="h6" gutterBottom my={3}>
          Salary
        </Typography>
        <Grid className="gray-box Employee_Detail_Content Contact-Detail-content" container spacing={3}>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Current CTC (per month)"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 8 }}
              error={Boolean(
                touched?.salary?.currentCtc && errors?.salary?.currentCtc
              )}
              helperText={
                touched?.salary?.currentCtc && errors?.salary?.currentCtc
              }
              {...getFieldProps(`salary.currentCtc`)}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Expected CTC (per month)"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 8 }}
              {...getFieldProps(`salary.expectedCtc`)}
              error={Boolean(
                touched?.salary?.expectedCtc && errors?.salary?.expectedCtc
              )}
              helperText={
                touched?.salary?.expectedCtc && errors?.salary?.expectedCtc
              }
            />
          </Grid>
          <Grid item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                views={["month", "year"]}
                label="Last Increment Date"
                maxDate={new Date()}

                value={values?.salary ? values?.salary.lastIncrementDate : ""}
                onChange={(newValue) => {
                  setFieldValue(`salary.lastIncrementDate`, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      disabled: true,
                    }}
                    classes={{
                      root: classes.root,
                    }}
                    error={Boolean(
                      touched?.salary?.lastIncrementDate &&
                        errors?.salary?.lastIncrementDate
                    )}
                    helperText={
                      touched?.salary?.lastIncrementDate &&
                      errors?.salary?.lastIncrementDate
                    }
                    fullWidth
                    size="small"
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Salary Before Increment (Per Month)"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 8 }}
              {...getFieldProps(`salary.salaryBeforeIncrement`)}
              error={Boolean(
                touched?.salary?.salaryBeforeIncrement &&
                  errors?.salary?.salaryBeforeIncrement
              )}
              helperText={
                touched?.salary?.salaryBeforeIncrement &&
                errors?.salary?.salaryBeforeIncrement
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
              <TextField
                fullWidth
                label="Notice Period (in days)"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 3 }}
                {...getFieldProps(`salary.addNoticePeriod`)}
                error={Boolean(
                  touched?.salary?.addNoticePeriod &&
                    errors?.salary?.addNoticePeriod
                )}
                helperText={
                  touched?.salary?.addNoticePeriod &&
                  errors?.salary?.addNoticePeriod
                }
              />
            </FormControl>
          </Grid>
        </Grid>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          mb={3}
          mt={4}
        >
          <Button onClick={() => handleTabChange("2")} variant="contained" className="back-button" style={{marginRight:'10px'}} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
          <Button onClick={() => handleTabChange("4")} variant="contained" endIcon={<ArrowForwardIcon />}>
            Next
          </Button>
        </Stack>
      </Container>
    </Card>
  );
}
