import * as Yup from "yup";

const jobDescription = Yup.object().shape({
  departmentId: Yup.string().required("Department is required").nullable(),
  employmentType: Yup.string()
    .required("Employment Type is required")
    .nullable(),
  priority: Yup.string().required("Priority is required").nullable(),
  seniorityLevel: Yup.string()
    .required("Seniority Level is required")
    .nullable(),
  ctcType: Yup.string().required("Salary Type is required").nullable(),
  skills: Yup.array()
    .min(1, "Skills is required.")
    .required("Skills is required."),
  experienceFrom: Yup.number()
    .required("Experience From is required")
    .typeError("Experience From can only be number")
    .nullable(),
  experienceTo: Yup.number()
    .required("Experience To is required")
    .typeError("Experience To can only be number")
    .nullable(),
  minCTC: Yup.number()
    .required("Minimum ctc is required")
    .typeError("Minimum ctc can only be number")
    .nullable(),
  maxCTC: Yup.number()
    .required("Maximum ctc is required")
    .typeError("Maximum ctc can only be number")
    .test("superior", "maxCTC must be bigger than minCTC", function (f) {
      const ref = Yup.ref("minCTC");
      return f > this.resolve(ref);
    })
    .nullable(),
  noOfPosition: Yup.number()
    .required("Number of position is required")
    .typeError("Number of position only be number")
    .nullable(),
  title: Yup.string()
    .required("Title is required")
    .max(64, "Length of character must be less than 64")
    .nullable(),
  description: Yup.string()
    .required("Content is required")
    .max(65535, "Content is too long")
    .nullable(),
});

export default jobDescription;
