import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import NiceModal from "@ebay/nice-modal-react";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import { StatusModal } from "./ItemGroupComponent/StatusModal";
import DeleteIcon from "@mui/icons-material/Delete";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import Divider from "@mui/material/Divider";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import itemGroupSchema from "../../validations/itemGroupSchema";
import AddIcon from "@mui/icons-material/Add";
import {
  getAllItems,
  getAllItemById,
  getInventoryData,
  addItemInventoryGroup,
  getInventoryCountById,
  updateInventoryItem,
} from "../../redux/assestManagment/thunk";
import { getVendor } from "../../redux/vendor/vendorThunk";
import { getManufacturer } from "../../redux/manufacturer/manufacturerThunk";
import { BASE_URL } from "../../constants";
import { toast } from "react-toastify";
import {
  setInventoryDataPage,
  setInventroyDataLimit,
  setSearch,
  setInitialPageAndSearch,
  setItemInventoryStatus,
  setInventoryDataOrderBy,
  setInventoryDataSortBy,
  setInvetoryStatus,
} from "../../redux/assestManagment/assestManagmentSlice";
import { debounce } from "lodash";
import invertDirection from "../../utils/invertDirection";
import { useNavigate } from "react-router-dom";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import CustomPagination from "../../components/Pagination";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../utils/encodeString";
import LocalStorage from "../../service/localStorage";
import { makeStyles } from "@material-ui/core";
import { Button } from "@mui/material";
const staticColumns = [
  "Employee",
  "Purchase Date",
  "Serial Number",
  "Status",
  "Warranty (In Years)",
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: "0.4",
        cursor: "default",
        "-webkit-text-fill-color": "#919EAB",
      },
      "&:hover fieldset": {
        opacity: "0.4",
        cursor: "default",
        "-webkit-text-fill-color": "#919EAB",
      },
      "&.Mui-focused fieldset": {
        opacity: "0.4",
        cursor: "default",
        "-webkit-text-fill-color": "#919EAB",
      },
    },
    "& .MuiInputLabel-root": {
      opacity: "0.4",
      cursor: "default",
      "-webkit-text-fill-color": "#919EAB",
    },
  },
}));

function ItemGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const [attributes, setAtrributes] = useState([]);
  const [itemLoading, setItemLoading] = useState(false);
  const [itemName, setItemName] = useState("");
  const [columns, setColumns] = useState([]);

  const [gridData, setGridData] = useState([]);
  const [errorDisplay, setErrorDisplay] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [inventoryCounts, setInventoryCounts] = useState({
    USE: "",
    FREE: "",
    SCRAP: "",
    INSERVICE: "",
  });

  const [inventorySearchData, setinventorySearchData] = useState([]);
  const invoiceRef = useRef(null);
  const searchRef = useRef(null);

  let itemData = useSelector((state) => state.inventory.data);
  itemData = itemData.filter((item) => item.isInventoriable);
  const itemStatus = useSelector((state) => state.inventory.status);
  const loading = useSelector((state) => state.inventory.loading);
  const inventoryDataLimit = useSelector(
    (state) => state.inventory.inventoryDataLimit
  );
  const inventoryDataTotalCount = useSelector(
    (state) => state.inventory.inventoryDataTotalCount
  );
  const inventoryDataPages = useSelector(
    (state) => state.inventory.inventoryDataPages
  );
  const inventoryStatus = useSelector(
    (state) => state.inventory.inventoryStatus
  );
  const invetoryStatusFilter = useSelector(
    (state) => state.inventory.invetoryStatusFilter
  );
  const inventoryDataCurrentPage = useSelector(
    (state) => state.inventory.inventoryDataCurrentPage
  );
  const inventoryDataOrderBy = useSelector(
    (state) => state.inventory.inventoryDataOrderBy
  );
  const inventoryDataSortBy = useSelector(
    (state) => state.inventory.inventoryDataSortBy
  );
  const inventorySearch = useSelector((state) => state.inventory.search);

  const vendorData = useSelector((state) => state.vendor.dataForDropdown);
  const vendorStatus = useSelector((state) => state.vendor.dropdownStatus);

  const manufacturerData = useSelector((state) => state.manufacturer.data);
  const manufacturerStatus = useSelector((state) => state.manufacturer.status);

  const warrantyData = ["0.5", "1", "1.5", "2", "2.5", "3"];

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("itemInventory")) {
      navigate("/dashboard");
    }
  }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const resetFields = () => {
    /* 		const tempattrs = { ...values.attributes };
		for (const keys in values.attributes) {
			tempattrs[keys] = { value: "" };
		}

		setFieldValue("attributes", tempattrs);
		setFieldValue("purchaseDate", null);
		setFieldValue("warrantyPeriod", "");
		setFieldValue("manufacturerId", "");
		setFieldValue("vendorId", "");
		setFieldValue("serialNumber", "");
		setFieldValue("invoice", []);
		setFieldValue("item", "");
		setFieldValue("id", null); */
  };
  const formik = useFormik({
    initialValues: {
      id: null,
      itemId: "",
      itemAttributes: "",
      itemsMap: [
        {
          item: "",
          warrantyPeriod: "",
          manufacturerId: "",
          vendorId: "",
          attributes: [],
          serialNumber: [{ value: "" }],
        },
      ],
      purchaseDate: null,
      invoice: [],
      toBeDeletedInvoicePDFs: [],
    },
    validationSchema: itemGroupSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let EditItemId = values?.itemsMap[0]?.item;

        const formData = new FormData();
        for (let i = 0; i < values?.itemsMap?.length; i++) {
          formData.append("item", values.itemsMap[i]?.item);

          let attributeFormat = {};
          for (let j = 0; j < values?.itemsMap[i]?.attributes?.length; j++) {
            let key = values?.itemsMap[i]?.attributes[j]?.itemKey;
            let value = values?.itemsMap[i]?.attributes[j]?.value;
            attributeFormat = { ...attributeFormat, [key]: { value: value } };
          }

          let serialNumberFormat = [];
          for (let j = 0; j < values?.itemsMap[i]?.serialNumber?.length; j++) {
            let key = values?.itemsMap[i]?.serialNumber[j]?.value;
            let value = values?.itemsMap[i]?.serialNumber[j]?.value;
            if (value) {
              serialNumberFormat.push(value);
            }
          }

          // formData.append(
          //   "serialNumber",
          //   serialNumberFormat ? JSON.stringify(serialNumberFormat) : ""
          // );
          formData.append("serialNumber", JSON.stringify(serialNumberFormat));
          formData.append("attributes", JSON.stringify(attributeFormat));
          formData.append("purchaseDate", values.purchaseDate);
          formData.append(
            "warrantyPeriod",
            values.itemsMap[i]?.warrantyPeriod
              ? values.itemsMap[i]?.warrantyPeriod
              : ""
          );
          formData.append("manufacturerId", values.itemsMap[i]?.manufacturerId);
          formData.append("vendorId", values.itemsMap[i]?.vendorId);

          formData.append("id", values.id);
          formData.append(
            "toBeDeletedInvoicePDFs",
            JSON.stringify(values.toBeDeletedInvoicePDFs)
          );
        }
        values.invoice.forEach((file) => {
          formData.append("invoice", file);
        });
        // console.log('FFFFFFFFFFF________',formData)
        let response;

        if (values.id) {
          response = await dispatch(updateInventoryItem(formData)).unwrap();
        }
        if (!values.id) {
          response = await dispatch(addItemInventoryGroup(formData)).unwrap();
        }

        if (response.status === 200 || response.status === 201) {
          setFieldValue("itemsMap", [
            {
              item: "",
              manufacturerId: "",
              warrantyPeriod: "",
              vendorId: "",
              serialNumber: [{ value: "" }],
              attributes: [],
            },
          ]);
          setFieldValue("purchaseDate", null);
          setFieldValue("invoice", []);
          setFieldValue("id", null);
          setTouched({});
          setErrors({});

          if (!values.id) {
            setFieldValue("itemId", null);
            setFieldValue("itemAttributes", "");
            setAtrributes([]);
            setGridData([]);
          } else {
            if (EditItemId == values?.itemId) {
               
              setFieldValue("itemId", values?.itemId);
              let obj = {
                itemAttributes : values?.itemAttributes,
                attributes : attributes,
                gridData : gridData,
                columns : columns
              }
              getItemByIdforList(EditItemId,obj);
  
            } else {
              setFieldValue("itemId", EditItemId);
              setFieldValue("itemAttributes", "");
              getItemByIdforList(EditItemId);
            }

           
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const {
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    setValues,
    setTouched,
    setErrors,
    isSubmitting,
    touched,
    errors,
    resetForm,
  } = formik;

  const mapInitialState = (attributes) => {
    let initialState = {};

    for (const attribute of attributes) {
      initialState = { ...initialState, [attribute.id]: { value: "" } };
    }
    return initialState;
  };

  const getImageUrl = (file) => {
    if (file === null || file === undefined) {
      return null;
    }

    if (file && file["type"]) {
      return window.URL.createObjectURL(file);
    }

    return `${BASE_URL}/${file}`;
  };

  const handleDeleteInvoiceImage = (image, index) => {
    let temp = [...values.invoice];
    const deleted = temp.splice(index, 1);

    setFieldValue("toBeDeletedInvoicePDFs", [
      ...values.toBeDeletedInvoicePDFs,
      ...deleted,
    ]);
    setFieldValue("invoice", temp);
  };

  const createRows = (inventory) => {
    const mappedInventory = inventory.map((ele, idx) => {
      return {
        ...ele.itemAttributes,
        Employee: ele.employee?.firstName
          ? `${ele.employee?.firstName} ${ele.employee?.lastName}`
          : "Unassigned",
        "Purchase Date": moment(ele.purchaseDate).format("DD/MM/yyyy"),
        "Serial Number": ele.serialNumber,
        Status: ele.status,
        "Warranty (In Years)": ele.warrantyPeriod,
        id: ele.id,
        itemAttributeIds: ele.itemAttributeIds,
        manufacturerId: ele.manufacturerId,
        vendorId: ele.vendorId,
        itemInvoice: ele.itemInvoice,
        employeeId: ele?.employeeId || null,
      };
    });

    setGridData(mappedInventory);
  };

  const resetEdit = () => {
    setFieldValue("itemsMap", [
      {
        item: "",
        manufacturerId: "",
        warrantyPeriod: "",
        vendorId: "",
        serialNumber: [{ value: "" }],
        attributes: [],
      },
    ]);
    setFieldValue("purchaseDate", null);
    setFieldValue("invoice", []);
    setFieldValue("id", null);
    setTouched({});
    setErrors({});
  };

  const handleChangePage = async (event, newPage) => {
    dispatch(setInventoryDataPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = async (event) => {
    dispatch(setInventoryDataPage({ page: 0 }));
    dispatch(
      setInventroyDataLimit({ limit: parseInt(event.target.value, 10) })
    );
  };

  const createColumns = (attrs) => {
    const tempCols = attrs.map((cls) => cls.name);

    setColumns([...tempCols, ...staticColumns]);
  };

  const setCounts = async (id) => {
    try {
      const response = await dispatch(
        getInventoryCountById({ id, inventorySearchData })
      ).unwrap();
      setTotalCount(response?.data?.TOTAL);
      setInventoryCounts({ ...inventoryCounts, ...response.data });
    } catch (error) {
      setInventoryCounts({
        USE: "0",
        FREE: "0",
        SCRAP: "0",
        INSERVICE: "0",
      });
      return;
    }
  };

  const handleReset = () => {
    searchRef.current.value = "";
    dispatch(setSearch({ search: "" }));
  };

  const handleSearch = (e) => {
    dispatch(setSearch({ search: e.target.value }));
  };

  const searchWithDebounce = debounce(handleSearch, 500);

  const mapInventoryData = (attrId, attrValueId) => {
    let tempArr = [];

    for (const key in values?.itemAttributes) {
      if (parseInt(attrId) === parseInt(key)) {
        tempArr.push({
          itemId: values?.itemId,
          itemAttributeId: parseInt(key),
          itemAttributeValueId: attrValueId,
        });
      }
      if (
        values.itemAttributes[key]?.value &&
        parseInt(attrId) !== parseInt(key)
      ) {
        tempArr.push({
          itemId: values?.itemId,
          itemAttributeId: parseInt(key),
          itemAttributeValueId: values?.itemAttributes[key]?.value,
        });
      }
    }

    setinventorySearchData(tempArr);
    dispatch(setItemInventoryStatus({ status: "idle" }));
  };
  const handleOnChange = async (e, attrId) => {
    /* console.log(e); */
    /* setFieldValue(`attributes[${attrId}].value`, newValue?.id);

		mapInventoryData(attrId, newValue?.id); */
  };

  const handleEdit = (data) => {
    setItemLoading(true);
    let newItems = [];
    for (let key in data?.itemAttributeIds) {
      let findValue = attributes?.find((e) => e?.id == key);
      newItems?.push({
        itemKey: key,
        value: data?.itemAttributeIds[key],
        attributeDetails: findValue,
      });
    }

    let newItemobj = {
      item: values?.itemId,
      warrantyPeriod: data["Warranty (In Years)"]
        ? data["Warranty (In Years)"]
        : 0,
      manufacturerId: data["manufacturerId"],
      vendorId: data["vendorId"],
      serialNumber: [{ value: data["Serial Number"] }],
      attributes: newItems,
    };
    setFieldValue("itemsMap", [newItemobj]);
    // setFieldValue(
    //   "warrantyPeriod",
    //   data["Warranty (In Years)"] ? data["Warranty (In Years)"] : 0
    // );
    // setFieldValue("manufacturerId", data["manufacturerId"]);
    // setFieldValue("vendorId", data["vendorId"]);

    // let tempattrs = { ...values.itemAttributes };

    // for (const keys in tempattrs) {
    //   tempattrs[keys] = { value: "" };
    // }

    // for (const id in data.itemAttributeIds) {
    //   tempattrs[id].value = data.itemAttributeIds[id];
    // }

    // setFieldValue("attributes", tempattrs);
    setFieldValue("purchaseDate", moment(data["Purchase Date"], "DD/mm/yyyy"));

    // setFieldValue("serialNumber", data["Serial Number"]);
    setFieldValue("id", data?.id);
    setFieldValue("invoice", data?.itemInvoice ?? []);
    setItemLoading(false);
  };

  const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      let fileName = splittedFile[splittedFile.length - 1];

      return fileName.length > 10 ? fileName?.substr(0, 10) + "..." : fileName;
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };

  // To get item attributes
  // useEffect(() => {
  const getItemById = async (item, index) => {
    try {
       
      setItemLoading(true);
      const response = await dispatch(getAllItemById(item)).unwrap();

      let atttributesKey = mapInitialState(response.data.attributes);

      let attributeDetails = response?.data.attributes;
      let newItems = [];
      for (let key in atttributesKey) {
        let findValue = attributeDetails?.find((e) => e?.id == key);
        newItems?.push({
          itemKey: key,
          value: "",
          attributeDetails: findValue,
        });
      }

      let newItemobj = {
        item: response?.data?.id,
        warrantyPeriod: "",
        manufacturerId: "",
        vendorId: "",
        attributes: newItems,
        serialNumber: [{ value: "" }],
      };

      setFieldValue(`itemsMap[${index}]`, newItemobj);

      // dispatch(setInitialPageAndSearch({ page: 0, search: "" }));
      // searchRef.current.value = "";
    } catch (error) {
      setAtrributes([]);
    } finally {
      setItemLoading(false);
    }
  };

  const handleAddSerialNumber = (index) => {
    let items = values?.itemsMap[index];

    setFieldValue(`itemsMap[${index}]`, {
      ...items,
      serialNumber: [...items?.serialNumber, { value: "" }],
    });
  };

  const handlDeleteSerialNumber = (index, serialIndex) => {
    let items = values?.itemsMap[index];
    let serialNumber = items?.serialNumber;
    serialNumber.splice(serialIndex, 1);
    setFieldValue(`itemsMap[${index}]`, {
      ...items,
      serialNumber: serialNumber,
    });
  };

  const getItemByIdforList = async (item,filtervalue) => {
    try {

      setItemLoading(true);
      const response = await dispatch(getAllItemById(item)).unwrap();

      let atttributesKey = mapInitialState(response.data.attributes);

      setFieldValue("itemAttributes", filtervalue ? filtervalue?.itemAttributes : atttributesKey );
      setAtrributes(  filtervalue ? filtervalue?.attributes  : response.data.attributes);
      createColumns(response.data.attributes);
      setItemName(response.data.name);
      dispatch(setInitialPageAndSearch({ page: 0, search: "" }));
      searchRef.current.value = "";
    } catch (error) {
      setAtrributes([]);
    } finally {
      setItemLoading(false);
    }
  };

  useEffect(() => {
 


    if(values.itemId){
      getItemByIdforList(values.itemId);
    }

    return () => setAtrributes([]);
  }, [dispatch, values.itemId]);

  //
  // }, [dispatch, values.itemsMap]);

  /* 	useEffect(() => {
		setAtrributes(values.attributes);
	}, [values.attributes]); */

  // to get count
  useEffect(() => {
    const getCounts = async () => {
      try {
        await setCounts(values?.itemId);
      } catch (error) {}
    };

    if (values?.itemId) {
      getCounts();
    }
  }, [values?.itemId, values?.itemAttributes]);

  const handleItemChange = (item, index) => {
    let itemId = item?.id;
    setFieldValue(`itemsMap[${index}?.item]`, itemId);

    // console.log("itemId, index", itemId, index);
    if (itemId) {
      getItemById(itemId, index);
    }

    // setinventorySearchData([]);
    // setFieldValue("purchaseDate", null);
    // setFieldValue("warrantyPeriod", "");
    // setFieldValue("manufacturerId", "");
    // setFieldValue("vendorId", "");
    // setFieldValue("serialNumber", "");
    // setFieldValue("invoice", []);
    // setFieldValue("id", null);
  };
  // to get data
  const getGridData = async () => {
    try {
      const inventoryData = await dispatch(
        getInventoryData({
          id: values?.itemId,
          params: {
            page: inventoryDataCurrentPage + 1,
            limit: inventoryDataLimit,
            orderBy: inventoryDataOrderBy,
            sortBy: inventoryDataSortBy,
            search: inventorySearch,
            status: invetoryStatusFilter,
          },
          inventorySearchData,
        })
      ).unwrap();
      createRows(inventoryData.data.list);
    } catch (error) {
      createRows([]);
    }
  };
  useEffect(() => {
    if (values.itemId && inventoryStatus === "idle") {
      getGridData();
    }
  }, [
    values?.itemId,
    dispatch,
    inventoryStatus,
    inventoryDataCurrentPage,
    inventoryDataLimit,
    inventoryDataOrderBy,
    inventoryDataSortBy,
    inventorySearch,
    inventorySearchData,
    invetoryStatusFilter,
  ]);

  useEffect(() => {
    if (itemStatus === "idle") {
      getViewVisible("items") && dispatch(getAllItems({ limit: 10, page: 1 }));
    }
    if (vendorStatus === "idle") {
      getViewVisible("vendors") && dispatch(getVendor());
    }
    if (manufacturerStatus === "idle") {
      getViewVisible("manufacturers") && dispatch(getManufacturer());
    }
  }, [dispatch]);

  const openStatusModal = async (id, status, serialNumber, employeeId) => {
    try {
      const response = await NiceModal.show(StatusModal, {
        id,
        status,
        serialNumber,
        employeeId,
      });

      if (response.success) {
        dispatch(setItemInventoryStatus({ status: "idle" }));
        // console.log('values.item',values.item)
        await setCounts(values?.itemId);
      }
    } catch (error) {
      toast.error("Error while updating status");
    }
  };

  const handleSorting = (columnName) => {
    dispatch(setInventoryDataSortBy({ inventoryDataSortBy: columnName }));
    dispatch(
      setInventoryDataOrderBy({
        inventoryDataOrderBy: invertDirection(
          inventoryDataSortBy === columnName,
          inventoryDataOrderBy
        ),
      })
    );
  };

  const handleStatusChange = (newValue) => {
    dispatch(setInvetoryStatus({ status: newValue }));
  };

  const handleAdditem = () => {
    let allItems = [...values?.itemsMap];
    let newItem = {
      item: "",
      attributes: [],
      serialNumber: [{ value: "" }],
    };
    setFieldValue("itemsMap", [...allItems, newItem]);
  };

  const handlDeleteitem = (index) => {
    let allItems = [...values?.itemsMap];
    allItems.splice(index, 1);
    setFieldValue("itemsMap", [...allItems]);
  };

  function isEmpty(obj) {
    // console.log(
    //   "Object.entries(obj).length !== 0",
    //   Object.entries(obj).length !== 0
    // );

    return Object.entries(obj).length !== 0;
  }

  if (loading) {
    return <Loader />;
  }



  return (
    <Container maxWidth="xl" className="itemsGroup-page">
      <Stack mb={2}>
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Item Inventory
          </Typography>
        </div>
      </Stack>
      <Card>
        <Container maxWidth="xl">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Typography
              variant="h4"
              gutterBottom
              sx={{
                color: " #ff7f00",
                fontSize: " 18px",
                fontWeight: "600",
                lineHeight: "27px",
              }}
            >
              {values.id ? "Edit Item Inventory" : "Add Item Inventory"}
            </Typography>
          </div>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3} pt={3} columns={15}>
              <Grid item xs={12} md={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Purchase Date"
                    inputFormat="dd/MM/yyyy"
                    value={values.purchaseDate}
                    maxDate={new Date()}
                    onChange={(newValue) => {
                      setFieldValue("purchaseDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          disabled: true,
                        }}
                        classes={{
                          root: classes.root,
                        }}
                        fullWidth
                        size="small"
                        error={touched?.purchaseDate && errors?.purchaseDate}
                        helperText={
                          touched?.purchaseDate && errors?.purchaseDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid container spacing={3} pt={3} columns={15}>
              <Grid item xs={12}>
                <Stack my={1}>
                  <Typography>Invoice</Typography>
                </Stack>

                <Grid container spacing={3}>
                  {values.invoice &&
                    values.invoice.map((img, idx) => {
                      return (
                        <Grid key={idx} item xs={3}>
                          <div
                            className="custome-uploadBox small profile-image-part"
                            onClick={(e) => {
                              window.open(getImageUrl(img), "_blank");
                            }}
                          >
                            <CancelRoundedIcon
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteInvoiceImage(img, idx);
                              }}
                              className="closeIcon"
                              style={{
                                position: "absolute",
                                top: "-7px",
                                right: "-6px",
                              }}
                            />
                            <InsertDriveFileIcon
                              onClick={(e) => {
                                window.open(getImageUrl(img), "_blank");
                              }}
                            />
                            <Typography variant="caption">
                              {getFileName(img)}
                            </Typography>
                            {/*<Box
																	component="img"
																	src={getImageUrl(img) || ""}
																/>*/}
                          </div>
                          {errors?.invoice && errors?.invoice[idx] && (
                            <div
                              style={{
                                fontSize: "0.75rem",
                                color: "#FF4842",
                              }}
                            >
                              {errors.invoice[idx]}
                            </div>
                          )}
                        </Grid>
                      );
                    })}
                  <Grid item xs={3}>
                    <div
                      className="custome-uploadBox small profile-image-part"
                      onClick={() => {
                        invoiceRef.current.click();
                      }}
                    >
                      <div className="p-image ">
                        <i className="upload-button">
                          <AddCircleIcon />
                        </i>

                        <input
                          ref={invoiceRef}
                          className="file-upload"
                          type="file"
                          // accept="application/pdf"
                          accept=".doc, .docx, .pdf, .jpeg, .jpg, .png, .bmp"
                          onChange={(e) => {
                            setFieldValue("invoice", [
                              ...values.invoice,
                              e.target.files[0],
                            ]);
                            e.target.value = "";
                          }}
                        />
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!values.id && (
              <Stack
                direction="row"
                justifyContent="flex-end"
                onClick={handleAdditem}
              >
                <Button variant="contained">Add item</Button>
              </Stack>
            )}
            <Stack my={3}>
              <Divider />
            </Stack>

            {values?.itemsMap?.map((itemsingle, index) => (
              <Stack key={index}>
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    fontSize: " 16px",
                    fontWeight: "600",
                    lineHeight: "27px",
                  }}
                >
                  {`(${index + 1})`}
                </Typography>

                <Grid container spacing={3} pt={3} columns={15} mb={2} mt={1}>
                  <Grid item xs={6} md={4}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      disabled={getViewVisible("items") === false}
                      options={itemData || []}
                      getOptionLabel={(option) => option.name || ""}
                      key={(option) => option.id}
                      {...getFieldProps(`itemsMap[${index}].item]`)}
                      onChange={(e, newValue) => {
                        handleItemChange(newValue, index);
                      }}
                      value={
                        itemData?.find(
                          (item) => item.id === itemsingle?.item
                        ) || ""
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select an Item"
                          error={Boolean(
                            touched?.itemsMap?.length > 0 &&
                              errors?.itemsMap?.length > 0 &&
                              touched?.itemsMap[index]?.item &&
                              errors?.itemsMap[index]?.item
                          )}
                          helperText={
                            touched?.itemsMap?.length > 0 &&
                            errors?.itemsMap?.length > 0 &&
                            touched?.itemsMap[index]?.item &&
                            errors?.itemsMap[index]?.item
                          }
                        />
                      )}
                      // error={true}
                    />
                  </Grid>
                  <Grid item xs={6} md={4}>
                    {values?.itemsMap?.length > 1 && (
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        onClick={() => handlDeleteitem(index)}
                      >
                        <IconButton>
                          {/* <DeleteIcon color="error" /> */}
                          <img src="assets/images/trash-gray.svg" title="trash" />
                        </IconButton>
                      </Stack>
                    )}
                  </Grid>

                  <Grid item xs={12} md={4}>
                    {itemLoading && <CircularProgress size={25} />}
                  </Grid>
                </Grid>

                <Grid container spacing={3} pt={3} columns={15}>
                  {itemsingle?.attributes?.map((attr, idx) => {
                    return (
                      <>
                        <Grid key={idx + index} item xs={12} md={4}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                            defaultValue=""
                            options={
                              attr?.attributeDetails?.attributeValues || []
                            }
                            getOptionLabel={(option) => option.value || ""}
                            {...getFieldProps(
                              `itemsMap[${index}].attributes[${idx}]`
                            )}
                            //value =
                            value={
                              attr?.attributeDetails?.attributeValues.find(
                                (i) => i.id === attr?.value
                              ) || ""
                            }
                            onChange={(event, newValue) => {
                              setFieldValue(
                                `itemsMap[${index}].attributes[${idx}]`,
                                { ...attr, value: newValue?.id } || ""
                              );

                              // mapInventoryData(attr.id, newValue?.id);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={attr?.attributeDetails?.name}
                                error={Boolean(
                                  touched?.itemsMap &&
                                    touched?.itemsMap?.length > 0 &&
                                    touched?.itemsMap[index] &&
                                    touched?.itemsMap?.length > 0 &&
                                    touched?.itemsMap[index]?.attributes
                                      ?.length > 0 &&
                                    touched?.itemsMap[index]?.attributes[idx]
                                      ?.value &&
                                    errors?.itemsMap &&
                                    errors?.itemsMap?.length > 0 &&
                                    errors?.itemsMap[index] &&
                                    errors?.itemsMap?.length > 0 &&
                                    errors?.itemsMap[index]?.attributes
                                      ?.length > 0 &&
                                    errors?.itemsMap[index]?.attributes[idx]
                                      ?.value
                                )}
                                helperText={
                                  touched?.itemsMap &&
                                  touched?.itemsMap?.length > 0 &&
                                  touched?.itemsMap[index] &&
                                  touched?.itemsMap?.length > 0 &&
                                  touched?.itemsMap[index]?.attributes?.length >
                                    0 &&
                                  touched?.itemsMap[index]?.attributes[idx]
                                    ?.value &&
                                  errors?.itemsMap &&
                                  errors?.itemsMap?.length > 0 &&
                                  errors?.itemsMap[index] &&
                                  errors?.itemsMap?.length > 0 &&
                                  errors?.itemsMap[index]?.attributes?.length >
                                    0 &&
                                  errors?.itemsMap[index]?.attributes[idx]
                                    ?.value
                                }
                              />
                            )}
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>

                <Grid container spacing={3} pt={3} columns={15} mt={1}>
                  <Grid item xs={12} md={3}>
                    <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      <Autocomplete
                        labelid="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                        name={`itemsMap[${index}].warrantyPeriod`}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            `itemsMap[${index}].warrantyPeriod`,
                            newValue || ""
                          );
                        }}
                        value={values?.itemsMap[index]?.warrantyPeriod}
                        options={warrantyData || []}
                        key={(option) => option.id}
                        getOptionLabel={(option) => `${option}` || ""}
                        renderInput={(params) => (
                          <TextField {...params} label="Warranty (In Years)" />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      labelid="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      disabled={getViewVisible("manufacturers") === false}
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={manufacturerData || []}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`itemsMap[${index}].manufacturerId`)}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          `itemsMap[${index}].manufacturerId`,
                          newValue?.id || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Manufacturer"
                          error={
                            touched?.itemsMap?.length > 0 &&
                            errors?.itemsMap?.length > 0 &&
                            touched?.itemsMap[index]?.manufacturerId &&
                            errors?.itemsMap[index]?.manufacturerId
                          }
                          helperText={
                            touched?.itemsMap?.length > 0 &&
                            errors?.itemsMap?.length > 0 &&
                            touched?.itemsMap[index]?.manufacturerId &&
                            errors?.itemsMap[index]?.manufacturerId
                          }
                        />
                      )}
                      //value={values.interviewDetails[index].interviewType}
                      value={
                        manufacturerData.find(
                          (i) =>
                            i.id === values?.itemsMap[index]?.manufacturerId
                        ) || ""
                      }
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      labelid="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      disabled={getViewVisible("vendors") === false}
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={vendorData || []}
                      getOptionLabel={(option) =>
                        option
                          ? `${option.firstName} ${option.lastName}`
                          : "" || ""
                      }
                      {...getFieldProps(`itemsMap[${index}].vendorId`)}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          `itemsMap[${index}].vendorId`,
                          newValue?.id || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select Vendor"
                          error={
                            touched?.itemsMap?.length > 0 &&
                            errors?.itemsMap?.length > 0 &&
                            touched?.itemsMap[index]?.vendorId &&
                            errors?.itemsMap[index]?.vendorId
                          }
                          helperText={
                            touched?.itemsMap?.length > 0 &&
                            errors?.itemsMap?.length > 0 &&
                            touched?.itemsMap[index]?.vendorId &&
                            errors?.itemsMap[index]?.vendorId
                          }
                        />
                      )}
                      //value={values.interviewDetails[index].interviewType}
                      value={
                        vendorData.find(
                          (i) => i.id === values?.itemsMap[index]?.vendorId
                        ) || ""
                      }
                      error={true}
                    />
                  </Grid>
                </Grid>

                <Stack>
                  {itemsingle?.serialNumber?.map((sNo, idx) => {
                    return (
                      <Stack
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <Stack sx={{ width: "400px" }}>
                          <TextField
                            fullWidth
                            label="Serial Number"
                            variant="outlined"
                            size="small"
                            autoComplete="off"
                            placeholder="Input Serial Number"
                            {...getFieldProps(
                              `itemsMap[${index}].serialNumber[${idx}].value`
                            )}
                            value={
                              values?.itemsMap[index]?.serialNumber[idx]
                                ?.value || ""
                            }
                            onChange={(e) => {
                              setFieldValue(
                                `itemsMap[${index}].serialNumber[${idx}].value`,
                                e?.target?.value
                              );
                            }}
                          />
                        </Stack>

                        <Stack>
                          {values?.itemsMap[index]?.serialNumber?.length >
                            1 && (
                            <Stack direction="row" justifyContent="flex-start">
                              <IconButton
                                onClick={() =>
                                  handlDeleteSerialNumber(index, idx)
                                }
                              >
                                {/* <DeleteIcon color="error" /> */}
                                <img src="assets/images/trash-gray.svg" title="trash" />
                              </IconButton>
                            </Stack>
                          )}
                        </Stack>
                        <Stack>
                          {!values.id &&
                            values?.itemsMap[index]?.serialNumber?.length -
                              1 ===
                              idx && (
                              <Stack
                                direction="row"
                                justifyContent="flex-start"
                              >
                                <IconButton
                                  onClick={() => handleAddSerialNumber(index)}
                                >
                                  {/* <AddIcon color="primary" /> */}
                                  <img src="/assets/images/add-circle.svg" style={{marginRight: "8px"}} />
                                </IconButton>
                              </Stack>
                            )}
                        </Stack>
                      </Stack>
                    );
                  })}
                </Stack>

                <Stack mt={3}>
                  <Divider />
                </Stack>
              </Stack>
            ))}

            <Stack direction="row" justifyContent="flex-end" my={3}>
              <Stack direction="row" alignItems="center">
            
                  <Button
                    color="error"
                    variant="contained"
                    sx={{ marginRight: "15px" }}
                    onClick={resetEdit}
                  >
                    Cancel
                  </Button>
           
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  save
                </LoadingButton>
              </Stack>
            </Stack>
          </form>
        </Container>
      </Card>

      <Stack my={3}>
        <Divider />
      </Stack>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "20px",
        }}
      >
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: " #ff7f00",
            fontSize: " 18px",
            fontWeight: "600",
            lineHeight: "27px",
          }}
        >
          View Item Inventory
        </Typography>
      </div>
      <Autocomplete
        size="small"
        fullWidth
        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
        disabled={getViewVisible("items") === false}
        options={itemData || []}
        getOptionLabel={(option) => option.name || ""}
        key={(option) => option.id}
        {...getFieldProps(`itemId`)}
        onChange={(e, newValue) => {
          setFieldValue("itemId", newValue?.id);
        }}
        value={itemData?.find((item) => item.id === values?.itemId) || ""}
        renderInput={(params) => (
          <TextField {...params} label="Select an Item" />
        )}
      />

      <Grid container spacing={3} pt={3} columns={15} mb={3}>
        {attributes?.map((attr, idx) => {
          return (
            <Grid key={attr.id} item xs={12} md={4}>
              <Autocomplete
                size="small"
                fullWidth
                popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                defaultValue=""
                options={attr.attributeValues || []}
                getOptionLabel={(option) => option.value || ""}
                {...getFieldProps(`itemAttributes[${attr.id}].value`)}
                value={
                  attr?.attributeValues?.find(
                    (i) => i?.id === values?.itemAttributes[attr.id]["value"]
                  ) || ""
                }
                onChange={(event, newValue) => {
                  setFieldValue(
                    `itemAttributes[${attr.id}].value`,
                    newValue?.id || ""
                  );

                  mapInventoryData(attr.id, newValue?.id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={attr.name}
                    // error={Boolean(
                    //   touched.attributes &&
                    //     touched?.attributes[attr.id] &&
                    //     errors.attributes &&
                    //     errors?.attributes[attr.id]
                    // )}
                    // helperText={
                    //   touched?.attributes &&
                    //   touched?.attributes[attr.id] &&
                    //   errors?.attributes &&
                    //   errors?.attributes[attr.id]
                    // }
                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
      {(gridData?.length > 0 || values?.itemId) && (
        <>
          <Stack mb={3}>
            <Typography variant="h5">
              Current Inventory {itemName && ` of ${itemName}`} ({totalCount})
              {/* <Typography variant="body2">
                Total Records : {totalCount}
              </Typography> */}
            </Typography>
          </Stack>
          <Card
            style={{
              marginBottom: "10px",
              padding: "10px",
            }}
          >
            <Stack mb={2}>
              <Stack direction="row" alignItems="center" mb={2} ml={1}>
                {Object.keys(inventoryCounts).map((key) =>
                  key != "TOTAL" ? (
                    <Stack key={key} mr={5} direction="row" alignItems="center">
                      <span className="count">{inventoryCounts[key]}</span>
                      {key}
                    </Stack>
                  ) : (
                    ""
                  )
                )}
              </Stack>
              <Grid container xs={12} pt={2} pb={2} gap={1}>
                <Grid item xs={8}>
                  <Stack /* width="400px" */>
                    <FormControl size="small" variant="outlined">
                      <InputLabel htmlFor="item-search">
                        Search by Serial Number/Employee/Vendor
                      </InputLabel>
                      <OutlinedInput
                        inputRef={searchRef}
                        id="item-search"
                        fullWidth
                        label="Search by Serial Number/Employee/Vendor"
                        variant="outlined"
                        size="small"
                        onChange={searchWithDebounce}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton onClick={handleReset}>
                              <CloseIcon />
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Status"
                    size="small"
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    name="status"
                    onChange={(event, newValue) => {
                      handleStatusChange(newValue);
                    }}
                    options={["USE", "FREE", "SCRAP", "INSERVICE"] || []}
                    // getOptionLabel={(option) => option.title || ""}
                    value={invetoryStatusFilter}
                    renderInput={(params) => (
                      <TextField {...params} label="Filter by Status" />
                    )}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Card>
          {/*  */}
          {itemLoading ? (
            <Loader />
          ) : (
            <>
              <Typography variant="body2" mb={1}>
                {inventorySearch
                  ? `${inventoryDataTotalCount} records found`
                  : ""}
              </Typography>
              <Card>
                <TableContainer>
                  <Table className="grid-table">
                    <TableHead>
                      <TableRow>
                        {columns?.map((cols, index) => (
                          <TableCell key={index} align="center">
                            {cols === "Purchase Date" ? (
                              <TableSortLabel
                                active={inventoryDataSortBy === "purchaseDate"}
                                direction={
                                  inventoryDataSortBy === "purchaseDate"
                                    ? inventoryDataOrderBy
                                    : "asc"
                                }
                                onClick={() => handleSorting("purchaseDate")}
                              >
                                {cols}
                              </TableSortLabel>
                            ) : cols === "Warranty (In Years)" ? (
                              <TableSortLabel
                                active={
                                  inventoryDataSortBy === "warrantyPeriod"
                                }
                                direction={
                                  inventoryDataSortBy === "warrantyPeriod"
                                    ? inventoryDataOrderBy
                                    : "asc"
                                }
                                onClick={() => handleSorting("warrantyPeriod")}
                              >
                                {cols}
                              </TableSortLabel>
                            ) : (
                              <TableSortLabel hideSortIcon="true">
                                {cols}
                              </TableSortLabel>
                            )}
                          </TableCell>
                        ))}
                        <TableCell align="center">
                          <TableSortLabel hideSortIcon="true">
                            Action
                          </TableSortLabel>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {gridData.length === 0 ? (
                        <TableRow>
                          <TableCell align="center" colSpan={columns.length}>
                            No Inventory found
                          </TableCell>
                        </TableRow>
                      ) : (
                        gridData.map((data) => (
                          <TableRow key={data.id}>
                            {columns.map((col, idx) => (
                              <TableCell key={idx} align="center">
                                {data[col] ? data[col] : "-"}
                              </TableCell>
                            ))}
                            <TableCell className="td-action-button" align="center">
                              <ButtonGroup className="action-button">
                                <IconButton onClick={() => handleEdit(data)}>
                                  {/* <EditIcon color="primary" /> */}
                                  <img src="assets/images/edit.svg" title="Edit" />
                                </IconButton>

                                <IconButton
                                  onClick={() =>
                                    openStatusModal(
                                      data.id,
                                      data["Status"],
                                      data["Serial Number"],
                                      data["employeeId"]
                                    )
                                  }
                                >
                                  <SettingsIcon className="gray-icon" />
                                </IconButton>
                                {data?.itemInvoice &&
                                data?.itemInvoice.length > 0
                                  ? data?.itemInvoice?.map((ele) => (
                                      <a
                                        target="_blank"
                                        href={`${BASE_URL}/${ele}`}
                                      >
                                        <IconButton>
                                          <ReceiptIcon className="gray-icon" />
                                        </IconButton>
                                      </a>
                                    ))
                                  : ""}
                              </ButtonGroup>
                            </TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                  <CustomPagination
                    rowsPerPageOptions={[10, 20, 40]}
                    totalCount={inventoryDataTotalCount}
                    totalPage={inventoryDataPages}
                    limit={inventoryDataLimit}
                    page={inventoryDataCurrentPage}
                    handleRowsPerPageChange={handleChangeRowsPerPage}
                    handleChangePage={handleChangePage}
                  />
                </TableContainer>
              </Card>
            </>
          )}
        </>
      )}
    </Container>
  );
}

const componentConfig = {
  component: ItemGroup,
  path: "/item-group",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
