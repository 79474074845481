import { createSlice } from "@reduxjs/toolkit";
import { getAllAssetsMapping ,getAssestMapCount} from "./thunk";

const initialState = {
  data: [],
  loading: false,
  complete: false,
  sortBy: "id",
  orderBy: "DESC",
  status: "idle",
  totalPages: 1,
  limit: 40,
  totalRecords: 0,
  currentPage: 1,
  totalAssetMap : 0
};

export const itemAssetMappingSlice = createSlice({
  name: "ItemAssetMapping",
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.status = "idle";
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload.orderBy;
      state.status = "idle";
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.status = "idle";
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.page;
      state.status = "idle";
	
    },
    setStatus: (state, action) => {
      state.status = action.payload.status;
    },
  },
  extraReducers: {
    [getAllAssetsMapping.pending]: (state) => {
      state.loading = true;
    },
    [getAllAssetsMapping.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.list;
      state.complete = true;
      state.status = "fulfilled";
	  state.totalPages = payload.totalPages;
      state.totalRecords = payload.totalRecords || 0;
    },
    [getAllAssetsMapping.rejected]: (state, payload) => {
      state.loading = false;
      state.data = [];
      state.status = "rejected";
      state.totalPages = 0;
      state.totalRecords = 0;
    },
    [getAssestMapCount.fulfilled]: (state, { payload }) => {
			state.totalAssetMap = payload?.data
		  },
  },
});

// The reducer
export const itemAssetMappingReducer = itemAssetMappingSlice.reducer;
export const { setLimit, setCurrentPage, setSortBy, setOrderBy } =
  itemAssetMappingSlice.actions;
