import { createSlice } from "@reduxjs/toolkit";
import { getDashboardInterviewList } from "./dashboardInterviewListThunk";

const initialState = {
  data: [],
  loading: false,
  status: "idle",
  completed: false,
};

// A slice for dashboard with our 3 reducers
export const dashboardInterviewListSlice = createSlice({
  name: "dashboardInterviewList",
  initialState,
  reducers: {},
  extraReducers: {
    [getDashboardInterviewList.pending]: (state) => {
      state.loading = true;
    },
    [getDashboardInterviewList.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload;
      state.completed = true;
      state.status = "completed";
    },
    [getDashboardInterviewList.rejected]: (state, payload) => {
      state.loading = false;
      state.error = payload;
      state.data = [];
      state.status = "rejected";
      state.completed = true;
    },
  },
});

// The reducer
export const dashboardInterviewListReducer =
  dashboardInterviewListSlice.reducer;
