import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import DashboardLayout from '../../../layouts/dashboard';
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { decryption } from "../../../utils/encodeString";

function ViewPoll() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { id } = useParams();
  if (id) id = decryption(id);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if(id===-1){
			navigate("/poll");
		}
	}, [dispatch,id]);
	
	if (loading) {
		return <p>Loading</p>;
	}

	return (
		<Container maxWidth="xl">
			<form autoComplete="off" noValidate className="custom-space-layout">
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={3}
				>
					<Typography variant="h4" gutterBottom mb={3}>
						{id ? "Edit Poll" : "Add Poll" }
					</Typography>
					<Stack direction="row" alignItems="center">
						<Button
							variant="contained"
							component={RouterLink}
							to="/poll"
							startIcon={<ArrowBackIcon />}
							style={{ marginLeft: "10px" }}
						>
							Back To List
						</Button>
					</Stack>
				</Stack>
				<Card>
					<Container maxWidth="xl">
						<Grid
							container
							spacing={3}
							py={3}
							style={{ paddingBottom: "13px", paddingTop: "17px" }}
						>
							
						</Grid>
					</Container>
				</Card>
			</form>
		</Container>
	);
}

const componentConfig = [
  {
    component: ViewPoll,
    path: "/poll/view/:id",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
