import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";

import DashboardLayout from "../../../layouts/dashboard";
import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";

function ViewAssetsLog() {
	return (
		<>
			{/* <Container maxWidth="xl" sx={{ background: "#C8E5F1" }}>
			 <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        sx={{
          position: "sticky",
          top: "64px",
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(255, 255, 255, 0.72)",
          padding: "10px 0",
          zIndex: 1,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Asset Info
        </Typography>
			
			<Stack direction="row" justifyContent="flex-end" mb={2}>
				<Button
					variant="contained"
					component={RouterLink}
					to={`/asset/${encryption(assetId)}/view`}
					startIcon={<ArrowBackIcon />}
					style={{ marginLeft: "10px" }}
				>
					Back To List
				</Button>
			</Stack>
			<Stack direction="row" spacing={2.5}>
				<Stack className="left-box white-area" pb={5} sx={{ width: "33%" }}>
					<Stack className="stack-bg"></Stack>
					<Stack className="img-block">
						<Stack
							className="img"
							style={{
								background: `url(${BASE_URL}/${data["assetInfo"]?.employee?.employeeImage})`,
							}}
						></Stack>

						{data["assetInfo"]?.employee?.id ? (
							<Stack className="edit-button">
								<img
									onClick={() => {
										fileInput.current.click();
									}}
									src="/assets/images/edit-icon.svg"
									alt="edit"
									width="14px"
								/>
								<input
									ref={fileInput}
									className="file-upload"
									type="file"
									accept="image/*"
									onChange={(e) =>
										handleImageChange(e, data["assetInfo"]?.employee?.id)
									}
									hidden={true}
								/>
							</Stack>
						) : (
							""
						)}
					</Stack>
					<Stack className="mid-section">
						<Typography
							className="name"
							size="small"
							style={{ fontWeight: 600 }}
							textAlign="center"
							color="#FF9934"
							gutterBottom
						>
							{data["assetInfo"]?.employee?.firstName}{" "}
							{data["assetInfo"]?.employee?.lastName}
						</Typography>
						<Typography
							className="email"
							textAlign="center"
							color="#000"
							gutterBottom
							style={{ fontSize: 12, fontWeight: 400 }}
							mb={1.25}
						>
							{data["assetInfo"]?.employee?.emails &&
								data["assetInfo"]?.employee?.emails[0]?.email}
						</Typography>
						<Stack
							className="e-links-block"
							direction="row"
							alignItems="center"
							justifyContent="center"
							divider={<Divider orientation="vertical" flexItem />}
						>
							<Stack className="block" direction="row" alignItems="center">
								<img
									src={
										data["assetInfo"].location == "office"
											? "/assets/images/bag-icon.svg"
											: "/assets/images/bag-icon.svg"
									}
									alt="bag-icon"
									width="19px"
								/>
							</Stack>
							{data["assetInfo"]?.employee?.contact?.skype && (
								<Stack className="block">
									<a
										href={`skype:${data["assetInfo"]?.employee?.contact?.skype}?chat`}
									>
										<Box
											component="img"
											src="/assets/images/skype-hover.svg"
											className="linkdin"
											style={{ width: "18px", height: "18px" }}
										/>
									</a>
								</Stack>
							)}
							{data["assetInfo"]?.employee?.contact?.linkedIn && (
								<Stack className="block">
									<a
										target="_blank"
										rel="noopener noreferrer"
										href={data["assetInfo"]?.employee?.contact?.linkedIn}
									>
										<Box
											component="img"
											src="/assets/images/linkdin.svg"
											className="linkdin"
											style={{ width: "24px", height: "24px" }}
										/>
									</a>
								</Stack>
							)}
						</Stack>
					</Stack>
					<Stack my={2}>
						<Divider />
					</Stack>
					<Stack mx={2.5}>
						<Grid container spacing={2.5}>
							<Grid item xs={12}>
								<Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
									Asset Id
								</Typography>
								<Typography sx={{ fontSize: 13, fontWeight: 500 }}>
									{data["assetInfo"]?.assetUniqueId || "-"}
								</Typography>
								<Stack my={2}>
									<Divider />
								</Stack>
							</Grid>
						</Grid>
						<Grid container spacing={2.5}>
							<Grid item xs={6}>
								<Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
									System Name
								</Typography>
								<Typography sx={{ fontSize: 13, fontWeight: 500 }}>
									{data["assetInfo"]?.name || "-"}
								</Typography>
								<Stack my={2}>
									<Divider />
								</Stack>
							</Grid>
							<Grid item xs={6}>
								<Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
									Contact Name
								</Typography>
								<Typography sx={{ fontSize: 13, fontWeight: 500 }}>
									{data["assetInfo"]?.employee?.contact?.contactNumber || "-"}
								</Typography>
								<Stack my={2}>
									<Divider />
								</Stack>
							</Grid>
						</Grid>

						<Grid container spacing={2.5}>
							<Grid item xs={6}>
								<Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
									IP Address
								</Typography>
								<Typography sx={{ fontSize: 13, fontWeight: 500 }}>
									{data["assetInfo"]?.ipAddress || "-"}
								</Typography>
							</Grid>
							<Grid item xs={6}>
								<Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
									Asign Date
								</Typography>
								<Typography sx={{ fontSize: 13, fontWeight: 500 }}>
									{data["assetInfo"]?.assignDate
										? moment(data["assetInfo"]?.assignDate).format(
												"DD MMM YYYY",
										  )
										: "-"}
								</Typography>
							</Grid>
						</Grid>
					</Stack>
					<Stack>&nbsp;</Stack>
					{data["assetInfo"]?.employee && (
						<Stack className="experiance-box" px={2.5}>
							<Typography
								color="#000"
								sx={{
									background: "#C8E5F1",
									fontSize: 13,
									fontWeight: 500,
									borderRadius: "8px 8px 0 0",
								}}
								p={1}
								pl={1.25}
							>
								Experience
							</Typography>
							<Stack
								direction="row"
								justifyContent="center"
								divider={<Divider orientation="vertical" flexItem />}
								sx={{ background: "#F4F9FB" }}
								spacing={4}
								py={1.7}
								mb={3}
							>
								<Stack>
									<Typography
										color="#000"
										textAlign="center"
										sx={{ fontSize: 24, fontWeight: 500 }}
									>
										{getCCCexperience()}
									</Typography>
									<Typography
										color="#808080"
										textAlign="center"
										sx={{ fontSize: 12 }}
									>
										Years in CCC
									</Typography>
								</Stack>
								<Stack>
									<Typography
										color="#000"
										textAlign="center"
										sx={{ fontSize: 24, fontWeight: 500 }}
									>
										{getTotoalexperience()}
									</Typography>
									<Typography
										color="#808080"
										textAlign="center"
										sx={{ fontSize: 12 }}
									>
										Total Exp. Years
									</Typography>
								</Stack>
							</Stack>
						</Stack>
					)}
					<Button
						className="btn-edit-assets"
						variant="outlined"
						size="small"
						color="inherit"
						component={RouterLink}
						to={`/asset/${encryption(assetId)}/add/${encryption(generatedId)}`}
						sx={{
							maxWidth: 110,
							display: "flex",
							gridGap: 10,
							fontSize: 12,
							fontWeight: 500,
							margin: "0 auto 20px",
							borderColor: "#FF9934",
						}}
					>
						<img src="/assets/images/edit-icon.svg" alt="edit" width="14px" />{" "}
						Edit Assets
					</Button>
					{data["assetInfo"]?.employee?.id && (
						<Link
							href={`/employee/view/${encryption(
								data["assetInfo"]?.employee?.id,
							)}`}
							underline="always"
							color="#FF9934"
							textAlign="center"
							sx={{ fontSize: 12, fontWeight: 500, cursor: "pointer" }}
						>
							View Employee Profile
						</Link>
					)}
				</Stack>
				<Stack className="right-box" sx={{ width: "67%" }}>
					<Stack className="white-area" mb={2.5}>
						<Grid container spacing={2.5} p={3} className="configurations">
							{data["Operating System"] && data["Operating System"].length > 0 && (
								<Grid item xs={6} className="configuration-box">
									<Stack className="title" direction="row" spacing={1.5} mb={1}>
										<img
											src="/assets/images/icon-processor.svg"
											alt="processor-icon"
											width="19px"
										/>
										<Typography
											color="#000"
											sx={{ fontSize: 14, fontWeight: 500 }}
										>
											OS & Processor
										</Typography>
									</Stack>
									<Stack className="grey-box" py={2} px={2.5}>
										{data["Operating System"].map((op, index) =>
											Object.keys(op).map((assetName, index) => (
												<Grid container spacing={1.25}>
													<Grid item xs={4}>
														<Typography
															color="#808080"
															sx={{ fontSize: 12, fontWeight: 500 }}
														>
															{assetName}
														</Typography>
													</Grid>
													<Grid item xs={8}>
														<Typography
															color="#000"
															sx={{ fontSize: 12, fontWeight: 500 }}
														>
															{op[assetName]}
														</Typography>
													</Grid>
												</Grid>
											)),
										)}
										{data["Processor"].map((op, index) =>
											Object.keys(op).map((assetName, index) => (
												<Grid container spacing={1.25}>
													<Grid item xs={4}>
														<Typography
															color="#808080"
															sx={{ fontSize: 12, fontWeight: 500 }}
														>
															{assetName}
														</Typography>
													</Grid>
													<Grid item xs={8}>
														<Typography
															color="#000"
															sx={{ fontSize: 12, fontWeight: 500 }}
														>
															{op[assetName]}
														</Typography>
													</Grid>
												</Grid>
											)),
										)}
									</Stack>
								</Grid>
							)}
							{data["Motherboard"] && data["Motherboard"].length > 0 && (
								<Grid item xs={6} className="configuration-box">
									<Stack className="title" direction="row" spacing={1.5} mb={1}>
										<img
											src="/assets/images/icon-motherboard.svg"
											alt="icon-motherboard"
											width="16px"
										/>
										<Typography
											color="#000"
											sx={{ fontSize: 14, fontWeight: 500 }}
										>
											Motherboard
										</Typography>
									</Stack>
									<Stack className="grey-box" py={2} px={2.5}>
										{data["Motherboard"].map((mb, index) =>
											Object.keys(mb).map((assetName, index) => (
												<Grid container spacing={1.25}>
													<Grid item xs={4}>
														<Typography
															color="#808080"
															sx={{ fontSize: 12, fontWeight: 500 }}
														>
															{assetName}
														</Typography>
													</Grid>
													<Grid item xs={8}>
														<Typography
															color="#000"
															sx={{ fontSize: 12, fontWeight: 500 }}
														>
															{mb[assetName]}
														</Typography>
													</Grid>
												</Grid>
											)),
										)}
									</Stack>
								</Grid>
							)}
							{Object.keys(data).length > 0 &&
								Object.keys(data)
									.filter(
										(d) =>
											d !== "Operating System" &&
											d !== "Motherboard" &&
											d !== "assetLogs" &&
											d !== "assetInfo" &&
											d !== "Processor",
									)
									.map((assetName, index) => (
										<Grid item xs={6} className="configuration-box">
											<Stack
												className="title"
												direction="row"
												spacing={1.5}
												mb={1}
											>
												<img
													src={getAssetImage(assetName)}
													alt="icon-ram"
													width="21px"
												/>
												<Typography
													color="#000"
													sx={{ fontSize: 14, fontWeight: 500 }}
												>
													{assetName}
												</Typography>
											</Stack>
											<Stack className="grey-box" py={2} px={2.5}>
												<Grid container spacing={1}>
													{data[assetName].map((mb, index) => {
														return (
															<>
																<Grid item xs={5.9}>
																	{Object.keys(mb).map((assetName, index) => {
																		return (
																			<Grid container spacing={6}>
																				<Grid item xs={5}>
																					<Typography
																						color="#808080"
																						sx={{
																							fontSize: 12,
																							fontWeight: 500,
																						}}
																					>
																						{assetName}
																					</Typography>
																				</Grid>
																				<Grid item xs={7}>
																					<Typography
																						color="#000"
																						sx={{
																							fontSize: 12,
																							fontWeight: 500,
																						}}
																					>
																						{mb[assetName]}
																					</Typography>
																				</Grid>
																			</Grid>
																		);
																	})}
																</Grid>
																{data[assetName].length > 1 &&
																index % 2 == 0 ? (
																	<Divider orientation="vertical" flexItem />
																) : null}
															</>
														);
													})}
												</Grid>
											</Stack>
										</Grid>
									))}
						</Grid>
					</Stack>
					<Stack className="white-area">
						<Stack
							className="section-title"
							direction="row"
							alignItems="center"
							color="#000"
							sx={{
								background: "#F0F0F0",
								fontSize: 14,
								fontWeight: 500,
								gridGap: 10,
							}}
							py={1.5}
							px={2.5}
						>
							<img
								src="/assets/images/history-icon.svg"
								alt="history-icon"
								width="16px"
							/>
							History
						</Stack>
						<Stack className="area-content" py={3} px={2.5}>
							{data["assetLogs"] &&
								data["assetLogs"].length > 0 &&
								data["assetLogs"]
									.slice(0)
									.reverse()
									.map((log, index) => (
										<Stack
											key={index}
											className="history-row active"
											direction="row"
										>
											<Stack className="left" pb={3.75}>
												{log.date
													? moment(log.date).format("DD MMM YYYY")
													: "-"}
											</Stack>
											<Stack
												direction="row"
												alignItems="center"
												className="right"
												spacing={2}
												pl={3}
												pb={3.75}
											>
												{log.newEmployeeName && log.oldEmployeeName && (
													<p>
														{log.oldEmployeeName} <span>&gt;</span>
														<strong> {log.newEmployeeName}</strong>
													</p>
												)}
												{log.newEmployeeName && !log.oldEmployeeName && (
													<p>
														Assigned to <strong>{log.newEmployeeName}</strong>
													</p>
												)}
												{!log.newEmployeeName && log.oldEmployeeName && (
													<p>
														Assigned back from{" "}
														<strong>{log.oldEmployeeName}</strong>
													</p>
												)}
											</Stack>
										</Stack>
									))}
							<Stack className="history-row" direction="row">
								<Stack className="left" pb={3.75}>
									{data["assetInfo"]?.createdDate
										? moment(data["assetInfo"].createdDate).format(
												"DD MMM YYYY",
										  )
										: "-"}
								</Stack>
								<Stack
									direction="row"
									alignItems="center"
									className="right"
									spacing={2}
									pl={3}
									pb={3.75}
								>
									Cybercom System assigned
								</Stack>
							</Stack>
						</Stack>
						<Timeline
								sx={{
									[`& .${timelineOppositeContentClasses.root}`]: {
										flex: 0.2,
									},
								}}
							>
								<TimelineItem>
									<TimelineOppositeContent color="textSecondary">
										{data["assetInfo"]?.createdDate
											? moment(data["assetInfo"].createdDate).format(
													"DD MMM YYYY",
											  )
											: "-"}
									</TimelineOppositeContent>
									<TimelineSeparator>
										<TimelineDot />
										<TimelineConnector />
									</TimelineSeparator>
									<TimelineContent>Cybercom System assigned</TimelineContent>
								</TimelineItem>
								<TimelineItem>
									<TimelineOppositeContent color="textSecondary">
										{data["assetInfo"]?.createdDate
											? moment(data["assetInfo"].createdDate).format(
													"DD MMM YYYY",
											  )
											: "-"}
									</TimelineOppositeContent>
									<TimelineSeparator>
										<TimelineDot />
									</TimelineSeparator>
									<TimelineContent>Cybercom System assigned</TimelineContent>
								</TimelineItem>
							</Timeline> 
					</Stack>
				</Stack>
			</Stack>
		</Container> */}
		</>
	);
}

const componentConfig = {
	component: ViewAssetsLog,
	path: "/asset/:assetId/log/:id",
	public: false,
	layout: DashboardLayout,
	group: "asset",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
