import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../service/axios";
import { DASHBOARD_UPDATE_INTERVIEW_STATUS } from "../../constants/dashboard";

export const updateDashboardInterviewStatus = createAsyncThunk(
	//action type string
	"dashboardInterviewStatus/updateDashboardInterviewStatus",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.put(DASHBOARD_UPDATE_INTERVIEW_STATUS,{...params},{
				headers: {
					type:'update',module:'interview'
				}});
			toast.success(res.data.message);
			return res.data.message;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
