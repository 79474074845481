import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DesktopWindows from "@mui/icons-material/DesktopWindows";
import Storage from "@mui/icons-material/Storage";
import TabletAndroid from "@mui/icons-material/TabletAndroid";
import Print from "@mui/icons-material/Print";
import Smartphone from "@mui/icons-material/Smartphone";
import IconButton from "@mui/material/IconButton";
import LaptopWindows from "@mui/icons-material/LaptopWindows";
import LaptopMac from "@mui/icons-material/LaptopMac";

import { getAllAssets } from "../../redux/assets/thunk";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { encryption } from "../../utils/encodeString";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

const iconConfig = {
	DesktopWindows,
	Storage,
	TabletAndroid,
	Print,
	Smartphone,
	LaptopWindows,
	LaptopMac,
};

function Assets() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const assetData = useSelector((state) => state.assets.data);
	const assetStatus = useSelector((state) => state.assets.status);
	const loading = useSelector((state) => state.assets.loading);

	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	useEffect(() => {
		if (!getViewVisible("assets")) {
			navigate("/dashboard");
		}
	}, []);

	useEffect(() => {
		// if (assetStatus === "idle") {
			dispatch(getAllAssets());
		// }
	}, [dispatch]);

	if (loading) {
		return <Loader />;
	}

	return (
		<Container className="Employee-wrapper IT-Assets-main-container">
			<Stack>
			<div className="title-button-wrapper IT-Assets-main-title">
				<Typography variant="h4">IT Assets</Typography>
				</div>
			</Stack>
			<Grid container spacing={3} className="IT-Assets-main-grid">
				{assetData.map((asset) => {
					const AssetIcon = iconConfig[asset.iconPath];
					return (
						<Grid key={asset.id} item xs={4}>
							<Card className="IT-Assets-card-wrapper gray-box Employee_Detail_Content">
								<Paper elevation={3}>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
										p={2}
										sx={{ gridGap: "10px" }}
									>
										<Stack
											direction="row"
											alignItems="center"
											sx={{ gridGap: "5px" }}
										>
											<Typography>
												{asset.name} ({asset[asset.name]})
											</Typography>
										</Stack>

										{getAddVisible("assets") && (
											<IconButton
												component={RouterLink}
												to={`/assets/${encryption(asset.id)}/add`}
											>
												<AddCircleOutlineIcon
													style={{
														cursor: "pointer",
													}}
													color="primary"
												/>
											</IconButton>
										)}
									</Stack>
									<Grid
										sx={{ textDecoration: "none", color: "black" }}
										component={RouterLink}
										to={`/assets/${encryption(asset.id)}/view`}
										container
										spacing={3}
									>
										<Grid
											item
											xs={6}
											pb={3}
											display="flex"
											alignItems="center"
											justifyContent="center"
										>
											<AssetIcon
												color="primary"
												sx={{ width: "60px", height: "60px" }}
											/>
										</Grid>
										<Grid item xs={6} pb={1}>
											<Stack sx={{ borderLeft: "1px solid #dce0e4" }}>
												<ListItem secondaryAction={asset.InUse}>
													<ListItemText primary="Assigned" />
												</ListItem>
												<ListItem secondaryAction={asset.NotInUse}>
													<ListItemText primary="Available" />
												</ListItem>
											</Stack>
										</Grid>
									</Grid>
								</Paper>
							</Card>
						</Grid>
					);
				})}
			</Grid>
		</Container>
	);
}

const componentConfig = {
	component: Assets,
	path: "/assets",
	public: false,
	layout: DashboardLayout,
	group: "assMangment",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
