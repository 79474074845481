import React from "react";
import DashboardLayout from "../../../layouts/dashboard";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import { addHelpDeskTicketSchema } from "../../../validations/helpDeskSchema";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FormHelperText from "@mui/material/FormHelperText";
import Tooltip from "@mui/material/Tooltip";
import Compressor from "compressorjs";
import {
  getHelpdeskTicketById,
  insert,
  updateById,
} from "../../../redux/helpdeskTickets/helpdeskTicketsThunk";
import { getHelpdeskCategoriesDropDown } from "../../../redux/helpdeskCategories/helpdeskCategoriesThunk";
import { decryption } from "../../../utils/encodeString";
import { BASE_URL } from "../../../constants";
import Loader from "../../../components/Loader";
import { getViewVisible, getEditVisible } from "../../../utils/userPermission";


function AddHelpDeskTickets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const isAddMode = !params.id;
  const [id, setId] = React.useState();
  const [hasNotPermission, setHasNotPermission] = React.useState(false);
  const [status, setStatus] = React.useState("");
  const [state, setState] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const attachmentsFileInput = useRef(null);
  const assignedByData = useSelector((state) => state.employee.allEmployeeData);
  const assignedToData = useSelector((state) => state.employee.allEmployeeData);

  console.log('assignedToData',assignedToData)
  const reportingPersonData = useSelector(
    (state) => state.employee.allEmployeeData
  );
  const priorityData = ["LOW", "MEDIUM", "HIGH"];
  const categoryData = useSelector(
    (state) => state.helpdeskCategories.categoryData
  );


 
  // function for compression
  function compressFile(file, setFieldValue) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
  }

  // function for uploading attachments
  const handleAttachmentsChange = async (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("attachments", file);
      setFieldValue("attachments", [...values.attachments, file]);
    }
  };

  // function for deleting attachments
  const handleDeleteAttachments  = async(img, index) => {
    const temp = [...values.attachments];
    const deletedArray = values?.deletedFilesArray || [];
    if ("id" in img) {
      deletedArray?.push(img?.id);
    }
    temp.splice(index, 1);
   await setFieldValue("attachments", temp);
    setFieldValue("deletedFilesArray", deletedArray);
   validateField("attachments");
  };

  const formik = useFormik({
    initialValues: {
      assignedBy: "",
      assignedTo: "",
      categoryId: "",
      subCategoryId: "",
      priority: "MEDIUM",
      description: "",
      attachments: [],
      additionalNotes: "",
      subject: "",
      reporterPerson: "",
      deletedFilesArray: [],
    },
    validationSchema: addHelpDeskTicketSchema,
    // on save button click
    onSubmit: async (values) => {
      try {
        // using formdata for sending data
        const formData = new FormData();
        formData.append("assignedBy", values.assignedBy);
        formData.append("assignedTo", values.assignedTo);
        formData.append("categoryId", values.categoryId);
        formData.append("subCategoryId", values.subCategoryId);
        formData.append("priority", values.priority);
        formData.append("description", values.description);
        formData.append("subject", values.subject);
        formData.append("reporterPerson", values.reporterPerson);
        formData.append("additionalNotes", values.additionalNotes);
        formData.append("status", status);
        formData.append("state", state);
        values?.deletedFilesArray?.forEach((attachment) => {
          formData.append("deletedFilesArray", attachment);
        });
        values.attachments.forEach((attachment) => {
          if ("id" in attachment) {
            return;
          } else {
            formData.append("attachments", attachment);
          }
        });
        // if user is adding ticket
        if (isAddMode) {
          await dispatch(insert(formData)).unwrap();
          setSubmitting(false);
          resetForm();
          navigate("/tickets");
        }
        // if user is editing ticket
        if (!isAddMode) {
          await dispatch(updateById({ id, formData })).unwrap();
          setSubmitting(false);
          resetForm();
          navigate("/tickets");
        }
      } catch (error) {
        console.log(error.message);
      }
    },
  });

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    handleBlur,
    setSubmitting,
    resetForm,
    validateField
  } = formik;

  // subCategory data from category
  const subCategoryData =
    categoryData?.find((item) => values?.categoryId === item.id)
      ?.helpDeskSubCategories || [];

  // setting assigned by and reporter person value by default when user is not admin or super admin    
  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
    dispatch(getHelpdeskCategoriesDropDown());
    const roleId = localStorage?.getItem("roleId");
    const role = JSON.parse(localStorage?.getItem("role"));
    const userId = JSON.parse(localStorage.getItem("employeeId"));
   
    if (
      (roleId === "3" && role === "HR") ||
      (roleId === "4" && role === "Employee") ||
      (roleId === "43" && role === "Cybercom App User")
    ) {
      setFieldValue("assignedBy", userId);
      setFieldValue("reporterPerson",userId);
      setHasNotPermission(true);
    }
  }, [dispatch]);

  // if user is editing ticket then fetching and setting values
  useEffect(() => {
    const fetchById = async () => {
      if (!isAddMode) {
        setLoading(true);
        const decodeParam = decryption(params.id);
        setId(decodeParam);
        if (decodeParam === -1) {
          navigate("/tickets");
        }
        try {
          let record = await dispatch(
            getHelpdeskTicketById(decodeParam)
          ).unwrap();
          setState(record?.data?.state);
          setStatus(record?.data?.status);
          let body = {
            assignedBy: record?.data?.assignedBy,
            assignedTo: record?.data?.assignedTo,
            categoryId: record?.data?.categoryId,
            subCategoryId: record?.data?.subCategoryId,
            priority: record?.data?.priority,
            description: record?.data?.description,
            attachments: record?.data?.attachments,
            additionalNotes: record?.data?.additionalNotes,
            subject: record?.data?.subject,
            status: record?.data?.status,
            state: record?.data?.state,
            reporterPerson: record?.data?.reporterPerson,
          };
          setValues(body);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
            navigate("/categories");
          }
        }
      }
    };
    fetchById();
  }, []);

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          {isAddMode ? "Add Helpdesk Ticket" : "Edit Helpdesk Ticket"}
        </Typography>
        <Stack direction="row">
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            sx={{ marginRight: "15px" }}
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
          <Button
            variant="contained"
            component={RouterLink}
            to="/tickets"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back To List
          </Button>
        </Stack>
        </div>
      </Stack>
      <Card>
        {loading ? (
          <Loader />
        ) : (
          <Container maxWidth="xl">
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className="custom-space-layout"
            >
              <Grid
                container
                spacing={3}
                py={3}
                style={{ paddingBottom: "13px", paddingTop: "17px" }}
              >
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={categoryData || []}
                    {...getFieldProps(`categoryId`)}
                    value={
                      categoryData?.find((x) => x.id === values.categoryId) ||
                      null
                    }
                    getOptionLabel={(option) => option?.title}
                    onChange={(event, newValue) => {
                      setFieldValue("categoryId", newValue?.id || "");
                      setFieldValue(
                        "assignedTo",
                        categoryData.find((x) => x.id === newValue?.id)
                          ?.defaultAssignee || ""
                      );
                    }}
                    disabled={!isAddMode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        error={Boolean(
                          touched?.categoryId && errors?.categoryId
                        )}
                        helperText={touched?.categoryId && errors?.categoryId}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={subCategoryData || []}
                    {...getFieldProps("subCategoryId")}
                    value={
                      subCategoryData.length > 0
                        ? subCategoryData.find(
                            (x) => x.id === values?.subCategoryId
                          ) || null
                        : null
                    }
                    getOptionLabel={(option) => option?.title || ""}
                    onChange={(event, newValue) => {
                      setFieldValue("subCategoryId", newValue?.id || "");
                    }}
                    disabled={!isAddMode}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sub-Category"
                        error={Boolean(
                          touched?.subCategoryId && errors?.subCategoryId
                        )}
                        helperText={
                          touched?.subCategoryId && errors?.subCategoryId
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={reportingPersonData || []}
                    {...getFieldProps(`reporterPerson`)}
                    value={
                      reportingPersonData?.find(
                        (x) => x?.employeeId === values.reporterPerson
                      ) || null
                    }
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option?.employeeId} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue("reporterPerson", newValue?.employeeId || "");
                    }}
                    disabled={hasNotPermission}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Reporter"
                        error={Boolean(
                          touched?.reporterPerson && errors?.reporterPerson
                        )}
                        helperText={
                          touched?.reporterPerson && errors?.reporterPerson
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={assignedByData || []}
                    {...getFieldProps(`assignedBy`)}
                    value={
                      assignedByData?.find((x) => x?.employeeId === values.assignedBy) ||
                      null
                    }
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.employeeId} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue("assignedBy", newValue?.employeeId || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assigned By"
                        error={Boolean(
                          touched?.assignedBy && errors?.assignedBy
                        )}
                        helperText={touched?.assignedBy && errors?.assignedBy}
                      />
                    )}
                    disabled={hasNotPermission}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={assignedToData || []}
                    {...getFieldProps(`assignedTo`)}
                    value={
                      assignedToData?.find((x) => x.employeeId === values.assignedTo) ||
                      null
                    }
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.employeeId} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue("assignedTo", newValue?.employeeId || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Assigned To"
                        error={Boolean(
                          touched?.assignedTo && errors?.assignedTo
                        )}
                        helperText={touched?.assignedTo && errors?.assignedTo}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    id="outlined-basic"
                    autoComplete="off"
                    label="Subject"
                    variant="outlined"
                    size="small"
                    name="subject"
                    inputProps={{
                      "data-cy": "txt-subject-department",
                    }}
                    FormHelperTextProps={{
                      "data-cy": "txt-subject-err-department",
                    }}
                    value={values.subject}
                    onChange={handleChange}
                    error={Boolean(touched.subject && errors.subject)}
                    helperText={touched.subject && errors.subject}
                    fullWidth
                    onBlur={handleBlur}
                  />
                </Grid>

                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={priorityData || []}
                    value={values.priority}
                    onChange={(event, newValue) => {
                      setFieldValue("priority", newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Priority"
                        error={Boolean(touched?.priority && errors?.priority)}
                        helperText={touched?.priority && errors?.priority}
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                spacing={3}
                py={3}
                style={{ paddingBottom: "13px", paddingTop: "17px" }}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    multiline
                    label="Description"
                    minRows={4}
                    variant="outlined"
                    size="small"
                    name="description"
                    inputProps={{
                      inputComponent: TextareaAutosize,
                      maxLength: 512,
                      style: {
                        resize: "auto",
                      },
                    }}
                    onChange={handleChange}
                    value={values.description}
                    error={Boolean(touched?.description && errors?.description)}
                    helperText={touched?.description && errors?.description}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    multiline
                    label="Additional Notes (If any)"
                    minRows={4}
                    variant="outlined"
                    size="small"
                    name="additionalNotes"
                    inputProps={{
                      inputComponent: TextareaAutosize,
                      maxLength: 512,
                      style: {
                        resize: "auto",
                      },
                    }}
                    onChange={handleChange}
                    error={Boolean(touched?.additionalNotes && errors?.additionalNotes)}
                    helperText={touched?.additionalNotes && errors?.additionalNotes}
                    value={values.additionalNotes}
                  />
                </Grid>
              </Grid>
              <Grid container pt={3} sx={{ borderTop: "1px solid #dce0e4" }}>
                <Grid item xs={12}>
                  <Stack mb={1}>
                    <Typography>
                      Attachments{" "}
                      {errors &&
                        errors?.attachments &&
                        errors.attachments.length > 0 && (
                          <span
                            style={{
                              color: "red",
                              marginLeft: "10px",
                              fontSize: "12px",
                            }}
                          >
                            {errors.attachments}
                          </span>
                        )}
                    </Typography>
                  </Stack>
                  <div>
                    <Grid container>
                      {Array.isArray(values?.attachments) &&
                        values?.attachments?.length > 0 &&
                        values?.attachments?.map((img, idx) => {
                          return (
                            <Grid
                              key={idx}
                              item
                              xs={2}
                              style={{
                                marginRight: "20px",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                className="custome-uploadBox small profile-image-part"
                                onClick={(e) => {
                                  if ("path" in img) {
                                    window.open(
                                      BASE_URL + "/" + img?.path,
                                      "_blank"
                                    );
                                  } else {
                                    window.open(
                                      URL.createObjectURL(img),
                                      "_blank"
                                    );
                                  }
                                }}
                              >
                                <CancelRoundedIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteAttachments(img, idx);
                                  }}
                                  className="closeIcon"
                                />

                                <>
                                  <InsertDriveFileIcon />
                                  <Tooltip title={img?.name}>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        maxWidth: "180px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {img?.name}
                                    </Typography>
                                  </Tooltip>
                                </>
                              </div>
                            </Grid>
                          );
                        })}
                      <Grid
                        item
                        xs={2}
                        style={{
                          marginRight: "20px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          className="custome-uploadBox small profile-image-part"
                          onClick={(e) => {
                            attachmentsFileInput.current.click();
                          }}
                        >
                          <div className="p-image">
                            <i className="upload-button">
                              <AddCircleIcon />
                            </i>
                            <input
                              ref={attachmentsFileInput}
                              className="file-upload"
                              type="file"
                              accept=".pdf, .jpeg, .jpg,"
                              onChange={(e) => handleAttachmentsChange(e)}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
              </Grid>
            </form>
          </Container>
        )}
      </Card>
    </Container>
  );
}
const componentConfig = [
  {
    component: AddHelpDeskTickets,
    path: "/tickets/add",
    public: false,
    layout: DashboardLayout,
    group: "Helpdesk",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddHelpDeskTickets,
    path: "/tickets/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "Helpdesk",
    sidebar: true,
    role: ["admin"],
  },
];
export default componentConfig;
