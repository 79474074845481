import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getAllNotice = createAsyncThunk(
	//action type string
	"announcements/getAllNoticeForHrms",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.get("/getAllNoticeForHrms", {
				headers: { type: 'view',module:'announcements' },
				params,
			});
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const createNotice = createAsyncThunk(
	//action type string
	"announcements/createNotice",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.post(`/createNotice`, body,{
				headers: { type: 'create',module:'announcements' },
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);
export const deleteNotice = createAsyncThunk(
	//action type string
	"holiday/deleteNotice",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.delete(`/deleteNotice`, {
				headers: { noticeId: id,type: 'delete',module:'announcements' },
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const getNoticeById = createAsyncThunk(
    "announcements/getNoticeById",
    async (id, { rejectWithValue })=>{
        try{
            const res = await Axios.get(`/getNoticeById/`,{
                headers:{
                    noticeid:id,type:'view',module:'announcements'
                }
            })
            return res.data;
        }
        catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)
