import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import "./style.css";
import DashboardLayout from '../../../layouts/dashboard';
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../../../components/Loader";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import { FormControlLabel } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { decryption } from "../../../utils/encodeString";
import {
  getAllModule,
} from "../../../redux/userPermission/userPermissionthunk";
import { useRef } from "react";
import questionSchema from "../../../validations/questionSchema";
import { addQuestion, getQuestionById, updateQuestionById } from "../../../redux/question/questionThunk";
import { getAllSections, getAllQuestions } from "../../../redux/question/questionThunk";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Downshift from "downshift";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { getDesignation } from "../../../redux/designation/designationThunk";

const useStyles = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(0.5, 0.25)
  }
}));

function AddQuestion() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { id } = useParams();
  if (id) id = decryption(id);
	// console.log(id)
	const subjectRef = useRef();
	const [loading, setLoading] = useState(true);
	const [modules, setModules] = useState("");
	const designationData = useSelector((state) => state.designation);
	const sectionData = useSelector((state) => state.questions.sectionData);

	const classes = useStyles();
  // const { selectedTags, placeholder, tags, ...other } = props;
  const [inputValue, setInputValue] = React.useState("");
  const [selectedItem, setSelectedItem] = React.useState([]);
	// const sectionData = [{value: "A", title: "SECTION-A (Performance)"},{value: "B", title: "SECTION-B (Behaviour)"}];
  // useEffect(() => {
  //   setSelectedItem(tags);
  // }, [tags]);
  // useEffect(() => {
  //   selectedTags(selectedItem);
  // }, [selectedItem, selectedTags]);

	useEffect(() => {
      dispatch(getAllSections({ sortBy: "", orderBy: "" }));
  },[]);

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      const newSelectedItem = [...selectedItem];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim()
      );

      if (duplicatedValues !== -1) {
        setInputValue("");
        return;
      }
      if (!event.target.value.replace(/\s/g, "").length) return;

      newSelectedItem.push(event.target.value.trim());
      setSelectedItem(newSelectedItem);
			setFieldValue("questionTag", newSelectedItem)
      setInputValue("");
    }
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      setSelectedItem(selectedItem.slice(0, selectedItem.length - 1));
			setFieldValue("questionTag", selectedItem.slice(0, selectedItem.length - 1))
    }
  }
  function handleChange2(item) {
    let newSelectedItem = [...selectedItem];
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item];
    }
    setInputValue("");
    setSelectedItem(newSelectedItem);
		setFieldValue("questionTag", newSelectedItem)
  }

  const handleDelete = item => () => {
    const newSelectedItem = [...selectedItem];
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1);
    setSelectedItem(newSelectedItem);
		setFieldValue("questionTag", newSelectedItem)
  };

  function handleInputChange(event) {
    setInputValue(event.target.value);
  }

	useEffect(() => {
		if(id===-1){
			navigate("/questions");
		}
	}, [dispatch,id]);

	const formik = useFormik({
    initialValues: {
			title: "",
			answerType: "",
			sectionId: "",
    },
		validationSchema: questionSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!id) {
					await dispatch(addQuestion(values)).unwrap();
        }

        if (id) {
          await dispatch(updateQuestionById({ ...values, question_id: id })).unwrap();
        }
				navigate("/questions");
				resetForm();
       
      } catch (error) {
        // return toast.error(error.message);
      }
    },
  });
	
	const {
		handleSubmit,
		values,
		setFieldValue,
		getFieldProps,
		isSubmitting,
		handleChange,
		setValues,
		touched,
		errors,
		handleBlur
	} = formik;

	const getAllModuleData = async () => {
    try {
      const res = await dispatch(getAllModule()).unwrap();

      setModules(res.data)
    } catch (error) {
      // toast.error(error.message);
    }
  };
  useEffect(() => {
    getAllModuleData();
  }, [dispatch]);

	useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let record = await dispatch(getQuestionById(id)).unwrap();
          let body1 = {
            title: record?.title,
						answerType: record?.answerType,
						sectionId: record?.sectionId,
          };
          setValues(body1);
		  setLoading(false);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
            navigate(`/questions`);
        }
      }
      setLoading(false);
    };
    fetchById();
  }, [dispatch, setValues, id, navigate]);
	
	if (loading) {
		return <Loader />;
	}

	// console.log(designationData.data)
	return (
		<Container maxWidth="xl">
			<form autoComplete="off" noValidate className="custom-space-layout" onSubmit={handleSubmit}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={3}
				>
				<div className="title-button-wrapper">
					<Typography variant="h4" gutterBottom mb={3}>
						{id ? "Edit Question" : "Add Question" }
					</Typography>
					<Stack direction="row" alignItems="center">
						<LoadingButton
							loading={isSubmitting}
							type="submit"
							variant="contained"
						>
							Save
						</LoadingButton>
						<Button
							variant="contained"
							component={RouterLink}
							to="/questions"
							startIcon={<ArrowBackIcon />}
							style={{ marginLeft: "10px" }}
						>
							Back To List
						</Button>
					</Stack>
					</div>
				</Stack>
				<Card>
					<Container maxWidth="xl">
						<Grid
							container
							spacing={3}
							py={3}
							style={{ paddingBottom: "13px", paddingTop: "17px" }}
						>
								<Grid item xs={12}>
									<TextField
										label="Question Title"
										variant="outlined"
										size="small"
										multiline
										minRows={2}
										maxRows={5}
										inputProps={{
											// inputComponent: TextareaAutosize,
											maxLength: 512,
											// style: {
											// 	resize: "auto",
											// },
										}}
										// value={values.title}
										onChange={handleChange}
										autoComplete="off"
										{...getFieldProps("title")}
										fullWidth
										error={Boolean(touched?.title && errors?.title)}
										helperText={touched?.title && errors?.title}
									/>
								</Grid>
								
								{/* <Grid item xs={6}>
									<Downshift
										id="downshift-multiple"
										inputValue={inputValue}
										onChange={handleChange2}
										selectedItem={selectedItem}
									>
										{({ getInputProps }) => {
											const { onBlur, onChange, onFocus, ...inputProps } = getInputProps({
												onKeyDown: handleKeyDown,
												// placeholder
											});
											return (
												<div>
													<TextField
														InputProps={{
															startAdornment: selectedItem.map(item => (
																<Chip
																	key={item}
																	tabIndex={-1}
																	label={item}
																	className={classes.chip}
																	onDelete={handleDelete(item)}
																/>
															)),
															onBlur,
															onChange: event => {
																handleInputChange(event);
																onChange(event);
															},
															onFocus
														}}
														// {...other}
														label="Question Tag"
														variant="outlined"
														size="small"
														fullWidth
														error={Boolean(touched?.questionTag && errors?.questionTag)}
														helperText={touched?.questionTag && errors?.questionTag}
														{...inputProps}
													/>
												</div>
											);
										}}
									</Downshift>
								</Grid>
								<Grid item xs={6}>
								<Autocomplete
									size="small"
									fullWidth
									options={designationData.data || []}
									getOptionLabel={(option) => option.title || ""}
									{...getFieldProps(`designationId`)}
									onChange={
										(event, newValue) => {
											// handleTLPM(newValue);
											setFieldValue("designationId", newValue?.id)
										}
									}
									renderInput={(params) => (
										<TextField
											{...params}
											label="Designation"
											error={Boolean(touched?.designationId && errors?.designationId)}
											helperText={touched?.designationId && errors?.designationId}
										/>
									)}
									value={designationData.data.find(
										(designation) =>
											designation.id === values.designationId
									)}
								/>
							</Grid> */}
							<Grid item xs={6}>
									<Autocomplete
										size="small"
										fullWidth
										options={sectionData || []}
										getOptionLabel={(option) => option?.section || ""}
										onChange={(event, newValue) => {
											setFieldValue('sectionId', newValue?.id || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Section"
												autoComplete="off"
												error={Boolean(touched.sectionId && errors.sectionId)}
												helperText={touched.sectionId && errors.sectionId}
											/>
										)}

										value={
											sectionData?.find((x) => x.id === values.sectionId) ??
											""
										}
									/>
								</Grid>
							<Grid item xs={6} mt={2} className="radio-select-space">
									<FormControl
										component="fieldset"
										size="small"
										error={Boolean(touched.answerType && errors.answerType)}
									>
										<FormLabel component="legend">Answer Type</FormLabel>
										<RadioGroup
											row
											aria-label="answerType"
											name="answerType"
											onChange={handleChange}
											value={values.answerType}
										>
											<FormControlLabel
												value="Rating"
												control={<Radio size="small" />}
												label="Rating"
												style={{color:'#637381'}}
											/>
											<FormControlLabel
												value="Text"
												control={<Radio size="small" />}
												label="Text"
												style={{color:'#637381'}}
											/>
										</RadioGroup>
										<FormHelperText style={{ margin: "5px 0 0 0" }}>
											{touched.answerType && errors.answerType}
										</FormHelperText>
									</FormControl>
								</Grid>
							</Grid>
					</Container>
				</Card>
			</form>
		</Container>
	);
}

const componentConfig = [
  {
    component: AddQuestion,
    path: "/questions/add",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddQuestion,
    path: "/questions/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
