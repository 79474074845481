import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import itemSchema from "../../../validations/itemSchema";

import DashboardLayout from "../../../layouts/dashboard";

import FormGroup from "@mui/material/FormGroup";
import Switch from "@mui/material/Switch";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";

import {
  addItem,
  getAllItemById,
  getAllItems,
  updateItem,
} from "../../../redux/assestManagment/thunk";
import { decryption } from "../../../utils/encodeString";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { FormControlLabel } from "@mui/material";

const label = { inputProps: { "aria-label": "Switch demo" } };

function AddItem() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // if (!getAddVisible("items") || !getEditVisible("items")) {
  //   navigate("/dashboard");
  // }
  let { id } = useParams();
  if (id) id = decryption(id);

  useEffect(() => {
    if(id===-1){
      navigate("/items");
    }
  }, [dispatch, id]);
  const formik = useFormik({
    initialValues: {
      name: "",
      isActive: false,
      isInventoriable: false,
      attributes: [
        {
          name: "",
          dataType: "",
          unit: "",
          allowFreeText: false,
          isDeleted: false,
        },
      ],
    },
    validationSchema: itemSchema,
    onSubmit: async (values) => {
      try {
        // console.log(values);
        let response;

        if (!id) {
          let finalAttributes = values.attributes.filter(
            (attr) => !attr.isDeleted
          );
          values.attributes = finalAttributes;
          response = await dispatch(addItem(values)).unwrap();
        }

        if (id) {
          response = await dispatch(updateItem(values)).unwrap();
        }

        if (response.status === 200) {
          dispatch(getAllItems({ limit: 10, page: 1 }));
          return navigate("/items");
        }
      } catch (error) {
        return toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    setValues,
    touched,
    errors,
  } = formik;

  const handleAddAttribute = () => {
    setFieldValue("attributes", [
      ...values.attributes,
      {
        name: "",
        dataType: "",
        unit: "",
        allowFreeText: false,
        isDeleted: false,
      },
    ]);
  };


 const deleteFlag = (attributesArray) =>{
  
   let deleteCount = 0;

   attributesArray?.map((ele) =>{
   
      if(!ele.isDeleted){
        deleteCount++;
      }
     
   })
 
   return deleteCount > 1;

    
 } 


  const handleRemoveAttribute = (index) => {
    const temp = values.attributes.map((attr, idx) => {
      if (index === idx) {
        return { ...attr, isDeleted: true };
      }
      return attr;
    });
    setFieldValue("attributes", temp);
  };

  useEffect(() => {
    if (id) {
      dispatch(getAllItemById(id))
        .unwrap()
        .then((res) => {
          setValues(res.data);
        })
        .catch((err) => {
          toast.error(err.message);
          navigate("/items");
        });
    }
  }, []);


  return (
    <Container maxWidth="xl" py={3}>
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            {id ? "Edit Item" : "Add Item"}
          </Typography>
          <Stack direction="row" alignItems="center">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button
              variant="contained"
              component={RouterLink}
              to="/items"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          </Stack>
          </div>
        </Stack>
        <Card className="chips-card">
          <Container maxWidth="xl">
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <TextField
                  label="Item Name"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 256 }}
                  {...getFieldProps("name")}
                  fullWidth
                  error={Boolean(touched?.name && errors?.name)}
                  helperText={touched?.name && errors?.name}
                />
              </Grid>

              <Grid item xs={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isInventoriable}
                        {...getFieldProps("isInventoriable")}
                        {...label}
                      />
                    }
                    label="Invantoriable"
                  />
                </FormGroup>
              </Grid>
              <Grid item xs={2}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values.isActive}
                        {...getFieldProps("isActive")}
                        {...label}
                      />
                    }
                    label="Status"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={3}
        >
          <Typography variant="h6">Attributes</Typography>
          <Button
            onClick={handleAddAttribute}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add Attributes
          </Button>
        </Stack>

        {values.attributes.map((attr, index) => {
          if (!attr?.isDeleted) {
            return (
              <Card key={index} sx={{ marginBottom: "20px" }} mb={3}>
                <Container maxWidth="xl">
                  <Grid container spacing={3}>
                    <Grid item xs={4} my={3}>
                      <TextField
                        fullWidth
                        label="Attribute Name"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        inputProps={{ maxLength: 256 }}
                        {...getFieldProps(`attributes[${index}].name`)}
                        error={Boolean(
                          touched?.attributes &&
                            touched?.attributes[index] &&
                            touched?.attributes[index]?.name &&
                            errors?.attributes &&
                            errors?.attributes[index] &&
                            errors?.attributes[index]?.name
                        )}
                        helperText={
                          touched?.attributes &&
                          touched?.attributes[index] &&
                          touched?.attributes[index]?.name &&
                          errors?.attributes &&
                          errors?.attributes[index] &&
                          errors?.attributes[index]?.name
                        }
                      />
                    </Grid>
                    <Grid item xs={4} my={3}>
                      <TextField
                        label="Data Type"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        inputProps={{ maxLength: 64 }}
                        fullWidth
                        {...getFieldProps(`attributes[${index}].dataType`)}
                        error={Boolean(
                          touched?.attributes &&
                            touched?.attributes[index] &&
                            touched?.attributes[index]?.dataType &&
                            errors?.attributes &&
                            errors?.attributes[index] &&
                            errors?.attributes[index]?.dataType
                        )}
                        helperText={
                          touched?.attributes &&
                          touched?.attributes[index] &&
                          touched?.attributes[index]?.dataType &&
                          errors?.attributes &&
                          errors?.attributes[index] &&
                          errors?.attributes[index]?.dataType
                        }
                      />
                    </Grid>
                    <Grid item xs={2} my={3}>
                      <TextField
                        label="Unit"
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                        inputProps={{ maxLength: 64 }}
                        fullWidth
                        {...getFieldProps(`attributes[${index}].unit`)}
                        error={Boolean(
                          touched?.attributes &&
                            touched?.attributes[index] &&
                            touched?.attributes[index]?.unit &&
                            errors?.attributes &&
                            errors?.attributes[index] &&
                            errors?.attributes[index]?.unit
                        )}
                        helperText={
                          touched?.attributes &&
                          touched?.attributes[index] &&
                          touched?.attributes[index]?.unit &&
                          errors?.attributes &&
                          errors?.attributes[index] &&
                          errors?.attributes[index]?.unit
                        }
                      />
                    </Grid>
                    <Grid item xs={1} my={3}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={attr.allowFreeText}
                              {...getFieldProps(
                                `attributes[${index}].allowFreeText`
                              )}
                              {...label}
                            />
                          }
                          label="AllowFreeText"
                        />
                      </FormGroup>
                    </Grid>
                    <Grid item xs={1} my={3}>
                     { deleteFlag(values.attributes) && <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                      >
                        <IconButton
                          onClick={(e) => handleRemoveAttribute(index)}
                          color="error"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Stack>}
                    </Grid>
                  </Grid>
                </Container>
              </Card>
            );
          }
          return null;
        })}
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddItem,
    path: "/items/add",
    public: false,
    layout: DashboardLayout,
    group: "assMangment",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddItem,
    path: "/items/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "assMangment",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
