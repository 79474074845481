import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// routes
import Router from "./routes";
// theme
import ThemeConfig from "./theme";

import GlobalStyles from "./theme/globalStyles";
// components
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/charts/BaseOptionChart";
//toaster
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ConfirmProvider } from "material-ui-confirm";
import CloseIcon from "@mui/icons-material/Close";
import NiceModal from "@ebay/nice-modal-react";
import { getAllModule } from "./redux/userPermission/userPermissionthunk";
import { toast } from "react-toastify";
// ----------------------------------------------------------------------

export default function App() {
	const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
	const dispatch = useDispatch();
	useEffect(() => {
		const fetchData = async () => {
		  try {
			if (isAuthenticated) {
			  const res = await dispatch(getAllModule()).unwrap();
			}
		  } catch (error) {
			// toast.error(error.message);
		  }
		};
	
		fetchData();
	  }, [isAuthenticated]);
	return (
		<ThemeConfig>
			<ScrollToTop />
			<GlobalStyles />
			<BaseOptionChartStyle />
			<ConfirmProvider
				defaultOptions={{
					confirmationButtonProps: {
						variant: "contained",
						color: "error",
					},
					confirmationText: "Yes",
					cancellationText: "Cancel",
					cancellationButtonProps: {
						variant: "contained",
					},
				}}
			>
				<NiceModal.Provider>
					<Router isAuthenticated={isAuthenticated} />
				</NiceModal.Provider>
			</ConfirmProvider>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar
				newestOnTop={false}
				closeOnClick
				theme="colored"
				rtl={false}
				pauseOnFocusLoss={false}
				draggable
				pauseOnHover
				limit={3}
			/>
		</ThemeConfig>
	);
}
