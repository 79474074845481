import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import Loader from "../../../components/Loader";
import DashboardLayout from "../../../layouts/dashboard";

import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "@mui/material/Autocomplete";

import {
    getById,
    updateById,
    insert,
} from "../../../redux/company/companyThunk";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import companySchema from "../../../validations/companySchema";
import { decryption } from "../../../utils/encodeString";
import {
    getAreaData,
    getCityData,
    getPostalCode,
    getStateData,
} from "../../../utils/getLocationData";
import {
    NUMBER_OF_EMPLOYEE,
    WORKING_DAYS,
    APPRAISAL_CYCLES,
    NOTICE_PERIOD,
    EXPERIENCED_BOND,
    FRESHER_BOND,
} from "./data";
import { setStatus } from "../../../redux/company/companySlice";

function AddCompany() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    let { id } = useParams();
    if (id) id = decryption(id);
    const navigation = useNavigate();

    const [loading, setLoading] = useState(false);
    //const { data: technologyData } = useSelector((state) => state.technology);
    const technologyData = useSelector((state) => state.technology);

    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [areas, setArea] = useState([]);

    const countryData = ['India'];

    useEffect(() => {
        if(id===-1){
            navigate('/company')
        }
    }, [dispatch, id]);
    const formik = useFormik({
        initialValues: {
            name: "",
            noOfEmployees: "",
            technologies: [],
            website: "",
            contactNumber: "",
            contactEmail: "",
            streetLine1: "",
            streetLine2: "",
            area: "",
            city: "",
            postalCode: "",
            state: "Gujarat",
            country: "India",
            appraisalCycle: [],
            noticePeriod: "",
            bondDurationForExperienced: null,
            bondDurationForFreshers: null,
            weekends: "",
            notes: "",
        },
        validationSchema: companySchema,
        onSubmit: async (values, { resetForm }) => {
            try {
                if (id) {
                    await dispatch(updateById({ ...values, id })).unwrap();
                }
                if (!id) {
                    await dispatch(insert(values)).unwrap();
                }
                dispatch(setStatus({ status: "idle" }));
                resetForm();
                navigation("/company");
            } catch (error) {
                toast.error(error.message);
            }
        },
    });

    const {
        getFieldProps,
        handleSubmit,
        setValues,
        values,
        setFieldValue,
        handleChange,
        errors,
        touched,
    } = formik;

    const handleState = async (event,newValue) => {
        //const state = event.target.value;
        setFieldValue("city", "");
        setFieldValue("area", "");
        setFieldValue("state", newValue);
        setCities(await getCityData(newValue));
    };

    const handleArea = async (event,newValue) => {
        //const city = event.target.value;
        setFieldValue("area", "");
        setFieldValue("city", newValue);
        setArea(await getAreaData(newValue));
    };
    
    const handleZipCode = async (event,newValue) => {
        try {
            //const area = event.target.value;
            const postalCode = await getPostalCode({ city: values.city, newValue });
            setFieldValue("area", newValue);
            setFieldValue("postalCode", postalCode);
        } catch (error) {}
    };

    const handleAreaWhileEdit = async (city) => {
        const areas = await getAreaData(city);
        setArea(areas);
    };

    const handleCityWhileEdit = async (state) => {
        const cities = await getCityData(state);
        setCities(cities);
    };

    useEffect(() => {
        const fetchById = async () => {
            if (id) {
                setLoading(true);
                try {
                    let record = await dispatch(getById(id)).unwrap();
                    await handleCityWhileEdit(record.state);
                    await handleAreaWhileEdit(record.city);
                    setValues(record);
                    record.appraisalCycle = record.appraisalCycle ?? [];
                    record.technologies = record.technologies ?? [];
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    toast.error(error.message);
                    navigation("/company");
                }
            }
        };
        fetchById();
    }, [dispatch]);

    useEffect(() => {
        // dispatch(getTechnology());
      dispatch(getTechnology({sortBy:'',orderBy:''}));
    }, [dispatch]);

    useEffect(() => {
        getStateData()
            .then((states) => {
                setStates(states);
            })
            .catch((err) => {
                setStates([]);
            });
    }, [dispatch]);

    useEffect(() => {
        if (!id) {
            getCityData("Gujarat")
                .then((cities) => {
                    setCities(cities);
                })
                .catch((err) => {
                    setCities([]);
                });
        }
    }, [dispatch]);

    if (loading) {
        return <Loader />;
    }

    return (
        <Container className="Add-Company-Container" maxWidth="xl">
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
            >
                <div className="title-button-wrapper Add-Company-title">                
                    <Typography variant="h4" gutterBottom>
                        {id ? "Edit Company" : "Add Company"}
                    </Typography>
                    <Button
                        className="back-button"
                        variant="contained"
                        component={RouterLink}
                        to="/company"
                        startIcon={<ArrowBackIcon />}
                        style={{ marginLeft: "10px" }}
                    >
                        Back
                    </Button>
                </div>
            </Stack>

            <Card className="holiday-table-grid add-company_card-grid" mb={5}>
                <Container maxWidth="xl">
                    <Paper>
                        <form onSubmit={handleSubmit}>
                            <Grid className="add-company-form-grid gray-box Employee_Detail_Content" container spacing={3}>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Name*"
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 512 }}
                                        error={Boolean(
                                            touched.name && errors.name
                                        )}
                                        helperText={touched.name && errors.name}
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                      {/* <InputLabel id="demo-simple-select-helper-label">
                                            Number of Employee*
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Number of Employee*"
                                            size="small"
                                            name="noOfEmployees"
                                            value={values.noOfEmployees}
                                            onChange={handleChange}
                                            error={Boolean(
                                                touched.noOfEmployees &&
                                                    errors.noOfEmployees
                                            )}
                                        >
                                            {NUMBER_OF_EMPLOYEE.map(
                                                (noOfEmployee) => (
                                                    <MenuItem
                                                        value={
                                                            noOfEmployee.value
                                                        }
                                                    >
                                                        {noOfEmployee.text}
                                          
                                                      </MenuItem>
                                                )
                                            )}
                                        </Select> */}
                                       
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            options={NUMBER_OF_EMPLOYEE || []}
                                            {...getFieldProps(`noOfEmployees`)}
                                            //value={values.bloodGroup}
                                            value={NUMBER_OF_EMPLOYEE.find(
                                                (emp) => emp.text === values.noOfEmployees) ?? ""}
                                            getOptionLabel={(option) => option?.text || ""}
                                            onChange={(event, newValue) => {
                                                setFieldValue(
                                                    `noOfEmployees`,
                                                    newValue?.text || ""
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Number of Employee*" 
                                                    error = {Boolean(
                                                        touched.noOfEmployees &&
                                                            errors.noOfEmployees
                                                    )}
                                                    helperText = {touched.noOfEmployees &&
                                                        errors.noOfEmployees}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Technology
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Technology"
                                            size="small"
                                            error={Boolean(
                                                touched.technologies &&
                                                    errors.technologies
                                            )}
                                            multiple
                                            name="technologies"
                                            onChange={handleChange}
                                            value={values.technologies}
                                        >
                                            {technologyData &&
                                                Array.isArray(technologyData) &&
                                                technologyData.map(
                                                    (technology, idx) => (
                                                        <MenuItem
                                                            key={idx}
                                                            value={
                                                                technology.title
                                                            }
                                                        >
                                                            {technology.title}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>*/}
                                        <Autocomplete
                                            multiple
                                            size="small"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            defaultValue=""
                                            options={technologyData.data || []}
                                            key={technologyData.data || []}
                                            getOptionLabel={(option) => option.title || ""}
                                            {...getFieldProps(
                                            `technologies`
                                            )}
                                            onChange={(event, newValue) => {
                                            let tempArray = newValue.map(
                                                x => x.id
                                            )
                                            setFieldValue(
                                                `technologies`,
                                                tempArray || ""
                                            ); 
                                            }}
                                            renderInput={(params) => (
                                            <TextField {...params} label="Technology" 
                                                error = {Boolean(
                                                    touched.technologies &&
                                                        errors.technologies
                                                )}
                                                helperText = {touched.technologies &&
                                                    errors.technologies}
                                            />
                                            )}

                                            value={ technologyData.data.filter(
                                                (x) => values.technologies.includes(x.id)
                                            )}
                                        /> 
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Website*"
                                        name="website"
                                        value={values.website}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 1032 }}
                                        error={Boolean(
                                            touched.website && errors.website
                                        )}
                                        helperText={
                                            touched.website && errors.website
                                        }
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Contact*"
                                        name="contactNumber"
                                        value={values.contactNumber}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 10 }}
                                        error={Boolean(
                                            touched.contactNumber &&
                                                errors.contactNumber
                                        )}
                                        helperText={
                                            touched.contactNumber &&
                                            errors.contactNumber
                                        }
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Email*"
                                        name="contactEmail"
                                        value={values.contactEmail}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 512 }}
                                        error={Boolean(
                                            touched.contactEmail &&
                                                errors.contactEmail
                                        )}
                                        helperText={
                                            touched.contactEmail &&
                                            errors.contactEmail
                                        }
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Street line 1"
                                        name="streetLine1"
                                        value={values.streetLine1}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 512 }}
                                        error={Boolean(
                                            touched.streetLine1 &&
                                                errors.streetLine1
                                        )}
                                        helperText={
                                            touched.streetLine1 &&
                                            errors.streetLine1
                                        }
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Street line 2"
                                        name="streetLine2"
                                        value={values.streetLine2}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 512 }}
                                        error={Boolean(
                                            touched.streetLine2 &&
                                                errors.streetLine2
                                        )}
                                        helperText={
                                            touched.streetLine2 &&
                                            errors.streetLine2
                                        }
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Country*
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Country*"
                                            size="small"
                                            name="country"
                                            onChange={handleChange}
                                            value={values.country}
                                            error={Boolean(
                                                touched.country &&
                                                    errors.country
                                            )}
                                        >
                                            <MenuItem value={"India"}>
                                                India
                                            </MenuItem>
                                        </Select> */}
                                        <Autocomplete
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="country"
                                            size="small"
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            onChange={(event, newValue) => {
                                                setFieldValue(
                                                    "country",
                                                    newValue || ""
                                                );
                                            }}
                                        
                                            value={values.country}
                                            
                                            options={countryData || []}
                                            renderInput = {(params) => 
                                                <TextField {...params} label="Country" 
                                                    error = {Boolean(
                                                        touched.country && errors.country
                                                    )}
                                                    helperText = {touched.country &&
                                                        errors.country}
                                                />
                                            }   
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            State*
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="state"
                                            size="small"
                                            name="state"
                                            onChange={handleState}
                                            value={values.state}
                                            disabled={!values.country}
                                            error={Boolean(
                                                touched.state && errors.state
                                            )}
                                        >
                                            {Array.isArray(states) &&
                                                states.map((state, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={state}
                                                    >
                                                        {state}
                                                    </MenuItem>
                                                ))}
                                                </Select>*/}

                                        <Autocomplete
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="state"
                                            size="small"
                                            name="state"
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            onChange={handleState}
                                            value={values.state}
                                            disabled={!values.country}
                                            options={states || []}
                                            renderInput = {(params) => 
                                                <TextField {...params} label="State*" 
                                                    error = {Boolean(
                                                        touched.state && errors.state
                                                    )}  
                                                    helperText = {touched.state && errors.state}
                                                />
                                            }   
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            City*
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="city"
                                            size="small"
                                            name="city"
                                            onChange={handleArea}
                                            value={values.city}
                                            disabled={!values.state}
                                            error={Boolean(
                                                touched.city && errors.city
                                            )}
                                        >
                                            {Array.isArray(cities) &&
                                                cities.map((city, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={city}
                                                    >
                                                        {city}
                                                    </MenuItem>
                                                ))}
                                                </Select>*/}
                                            <Autocomplete
                                                labelId="demo-simple-select-helper-label"
                                                id="demo-simple-select-helper"
                                                label="city"
                                                size="small"
                                                name="city"
                                                popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}       
                                                disabled={!values.state || !values.country}
                                                options={cities || []}
                                                renderInput = {(params) => 
                                                    <TextField {...params} label="City*" 
                                                        error = {Boolean(
                                                            touched.city && errors.city
                                                        )}
                                                        helperText = {touched.city && errors.city}
                                                    />
                                                }
                                                value={values.city}
                                                onChange={handleArea}
                                            />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Area*
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="area"
                                            size="small"
                                            name="area"
                                            onChange={handleZipCode}
                                            value={values.area}
                                            disabled={!values.city}
                                            error={Boolean(
                                                touched.area && errors.area
                                            )}
                                        >
                                            {Array.isArray(areas) &&
                                                areas.map((areas, key) => (
                                                    <MenuItem
                                                        key={key}
                                                        value={areas}
                                                    >
                                                        {areas}
                                                    </MenuItem>
                                                ))}
                                                </Select> */}

                                        <Autocomplete
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            size="small"
                                            name="area"
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />} 
                                            value={values.area}      
                                            disabled={!values.city || !values.state}
                                            options={areas || []}
                                            renderInput = {(params) => 
                                                <TextField {...params} label="Area*" 
                                                    error = {Boolean(
                                                        touched.area && errors.area
                                                    )}
                                                    helperText = {touched.area && errors.area}
                                                />
                                            }
                                            
                                            onChange={handleZipCode}  
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Postal code*"
                                        name="postalCode"
                                        value={values.postalCode}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        inputProps={{ maxLength: 8 }}
                                        error={Boolean(
                                            touched.postalCode &&
                                                errors.postalCode
                                        )}
                                        helperText={
                                            touched.postalCode &&
                                            errors.postalCode
                                        }
                                    />
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Appraisal cycle
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Appraisal Cycle"
                                            size="small"
                                            name="appraisalCycle"
                                            value={values.appraisalCycle}
                                            onChange={handleChange}
                                            multiple
                                        >
                                            {APPRAISAL_CYCLES.map(
                                                (appraisalCycle) => (
                                                    <MenuItem
                                                        value={
                                                            appraisalCycle.value
                                                        }
                                                    >
                                                        {appraisalCycle.text}
                                                    </MenuItem>
                                                )
                                            )}
                                                    </Select> */}
                                        <Autocomplete
                                            multiple
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            size="small"
                                            name="appraisalCycle"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            options={APPRAISAL_CYCLES || []}
                                            key={APPRAISAL_CYCLES || []}
                                            getOptionLabel={(option) =>
                                            option.text || ""
                                            }
                                            {...getFieldProps(
                                            "appraisalCycle"
                                            )}
                                            onChange={(event, newValue) => {
                                            setFieldValue(
                                            "appraisalCycle",
                                            newValue || ""
                                            );
                        
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Appraisal cycle" 
                                                    error = {Boolean(
                                                        touched.appraisalCycle &&
                                                            errors.appraisalCycle
                                                    )}
                                                    helperText = {touched.appraisalCycle &&
                                                        errors.appraisalCycle}        
                                                />
                                            )}                        
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                       {/* <InputLabel id="demo-simple-select-helper-label">
                                            Notice period
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Notice period"
                                            size="small"
                                            name="noticePeriod"
                                            value={values.noticePeriod}
                                            onChange={handleChange}
                                            defaultValue=""
                                        >
                                            {NOTICE_PERIOD.map(
                                                (noticePeriod) => (
                                                    <MenuItem
                                                        value={
                                                            noticePeriod.value
                                                        }
                                                    >
                                                        {noticePeriod.text}
                                                    </MenuItem>
                                                )
                                            )}
                                                    </Select>*/}
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            options={NOTICE_PERIOD || []}
                                            {...getFieldProps(`noticePeriod`)}
                                            //value={values.bloodGroup}
                                            value={NOTICE_PERIOD.find(
                                                (np) => np.text === values.noticePeriod) ?? ""}
                                            getOptionLabel={(option) => option?.text || ""}
                                            onChange={(event, newValue) => {
                                                setFieldValue(
                                                    `noticePeriod`,
                                                    newValue?.text || ""
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Notice period" 
                                                    error = {Boolean(
                                                        touched.noticePeriod &&
                                                            errors.noticePeriod
                                                    )}
                                                    helperText = {touched.noticePeriod &&
                                                        errors.noticePeriod}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Bond duration for experienced
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Bond duration for experienced"
                                            size="small"
                                            name="bondDurationForExperienced"
                                            value={
                                                values.bondDurationForExperienced
                                            }
                                            onChange={handleChange}
                                            defaultValue=""
                                        >
                                            {EXPERIENCED_BOND.map(
                                                (
                                                    bondDurationForExperienced
                                                ) => (
                                                    <MenuItem
                                                        value={
                                                            bondDurationForExperienced.value
                                                        }
                                                    >
                                                        {
                                                            bondDurationForExperienced.text
                                                        }
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>*/}
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            options={EXPERIENCED_BOND || []}
                                            {...getFieldProps(`bondDurationForExperienced`)}
                                            value={EXPERIENCED_BOND.find(
                                                (bond) => bond.text === values.bondDurationForExperienced) ?? ""}
                                            getOptionLabel={(option) => option?.text || ""}
                                            onChange={(event, newValue) => {
                                                setFieldValue(
                                                    `bondDurationForExperienced`,
                                                    newValue?.text || ""
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Bond duration for experienced" 
                                                    error = {Boolean(
                                                        touched.bondDurationForExperienced &&
                                                            errors.bondDurationForExperienced
                                                    )}
                                                    helperText = {touched.bondDurationForExperienced &&
                                                        errors.bondDurationForExperienced}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Bond duration for freshers
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Bond duration for freshers"
                                            size="small"
                                            name="bondDurationForFreshers"
                                            value={
                                                values.bondDurationForFreshers
                                            }
                                            onChange={handleChange}
                                            defaultValue=""
                                        >
                                            {FRESHER_BOND.map(
                                                (bondDurationForFreshers) => (
                                                    <MenuItem
                                                        value={
                                                            bondDurationForFreshers.value
                                                        }
                                                    >
                                                        {
                                                            bondDurationForFreshers.text
                                                        }
                                                    </MenuItem>
                                                )
                                            )}
                                        </Select>*/}
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            options={FRESHER_BOND || []}
                                            {...getFieldProps(`bondDurationForFreshers`)}
                                            value={FRESHER_BOND.find(
                                                (fb) => fb.text === values.bondDurationForFreshers) ?? ""}
                                            getOptionLabel={(option) => option?.text || ""}
                                            onChange={(event, newValue) => {
                                                setFieldValue(
                                                    `bondDurationForFreshers`,
                                                    newValue?.text || ""
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Bond duration for freshers" 
                                                    error = {Boolean(
                                                        touched.bondDurationForFreshers &&
                                                            errors.bondDurationForFreshers
                                                    )}
                                                    helperText = {touched.bondDurationForFreshers &&
                                                        errors.bondDurationForFreshers}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <FormControl
                                        size="small"
                                        sx={{ minWidth: 120 }}
                                        fullWidth
                                    >
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Working Days
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Working Days"
                                            size="small"
                                            name="weekends"
                                            value={values.weekends}
                                            onChange={handleChange}
                                        >
                                            {WORKING_DAYS.map((workingDays) => (
                                                <MenuItem
                                                    value={workingDays.value}
                                                >
                                                    {workingDays.text}
                                                </MenuItem>
                                            ))}
                                            </Select>*/}
                                        <Autocomplete 
                                            size="small"
                                            fullWidth
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                            options={WORKING_DAYS || []}
                                            {...getFieldProps(`weekends`)}
                                            value={WORKING_DAYS.find(
                                                (wk) => wk.text === values.weekends) ?? ""}
                                            getOptionLabel={(option) => option?.text || ""}
                                            onChange={(event, newValue) => {
                                                setFieldValue(
                                                    `weekends`,
                                                    newValue?.text || ""
                                                );
                                            }}
                                            renderInput={(params) => (
                                                <TextField {...params} label="Working Days" 
                                                    error = {Boolean(
                                                        touched.weekends &&
                                                            errors.weekends
                                                    )}
                                                    helperText = {touched.weekends &&
                                                        errors.weekends}
                                                />
                                            )}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid className="grid-item col" item xs={4}>
                                    <TextField
                                        fullWidth
                                        label="Notes"
                                        name="notes"
                                        multiline
                                        rows={5}
                                        value={values.notes}
                                        inputProps={{ maxLength: 1032 }}
                                        onChange={handleChange}
                                        variant="outlined"
                                        size="small"
                                        error={Boolean(
                                            touched.notes && errors.notes
                                        )}
                                        helperText={
                                            touched.notes && errors.notes
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Stack
                                direction="row"
                                justifyContent="flex-end"
                                my={3}
                            >
                                <Button type="submit" variant="contained">
                                    Save
                                </Button>
                            </Stack>
                        </form>
                    </Paper>
                </Container>
            </Card>
        </Container>
    );
}

const componentConfig = [
    {
        component: AddCompany,
        path: "/company/add",
        public: false,
        layout: DashboardLayout,
        group: "candidate",
        sidebar: true,
        role: ["admin"],
    },
    {
        component: AddCompany,
        path: "/company/add/:id",
        public: false,
        layout: DashboardLayout,
        group: "candidate",
        sidebar: true,
        role: ["admin"],
    },
];

export default componentConfig;
