import React from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const EmployeeConfirmationModal = NiceModal.create(({ keywords }) => {
	const modal = useModal();

	const resolveModal = () => {
		modal.resolve({ success: true });
		return modal.hide();
	};

	return (
		<Dialog
			TransitionComponent={Transition}
			open={modal.visible}
			onClose={() => modal.hide()}
			TransitionProps={{
				onExited: () => modal.remove(),
			}}
			fullWidth
		>
			<DialogTitle id="alert-dialog-slide-title">Are you sure?</DialogTitle>
			<DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
				The following action will convert this current Candidate into an Employee. Do you
				want to proceed?
			</DialogContent>

			<DialogActions>
				<Button className='cancel-button' color="error" variant="contained" onClick={() => modal.hide()}>
					Cancel
				</Button>
				<Button className='submit-button' color="primary" variant="contained" onClick={resolveModal}>
					Yes
				</Button>
			</DialogActions>
		</Dialog>
	);
});
