import DashboardLayout from "../../../layouts/dashboard";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { decryption } from "../../../utils/encodeString";
import { ViewSubmittedEvaluationById } from "../../../redux/employeeEvaluation/employeeEvaluationThunk";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Grid,
  Rating,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import {
  currentEvaluationMonthFormat,
  getMonthDates,
  monthYearStringFormat,
  pastMonthStringFormat,
} from '../../../utils/dateFormat';
import Loader from "../../../components/Loader";

function ViewEmployeeEvalution() {
  let { id } = useParams();
  id = decryption(id);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [totalAverage, setTotalAverage] = useState(0);
  const [sectionAverage, setSectionAverage] = useState([]);
  const[loading, setLoading] = useState(false);
  const evaluation = useSelector(
    (state) => state.employeeEvaluation
  );

  // console.log(data)
  useEffect(() => {
    dispatch(ViewSubmittedEvaluationById({ submitevalutionid: id }))
      .unwrap()
      .catch((err) => {
        // navigate("/candidate");
      });
  }, []);

  const handleaverageRatingShow = (section) => {
    for (let i = 0; i < sectionAverage?.length; i++) {
      if (sectionAverage[i]?.sectionName == section?.sectionName) {
        if (sectionAverage[i]?.textField == section?.questions?.length) {
          return false;
        } else {
          return true;
        }
      }
    }
  };

  useEffect(() => {
    let averageRatingArray =
      evaluation?.viewSubmittedEvaluationData?.submitEvalutionDetail;
    let rating = 0;
    let totalRating = 0;
    let minusAverage = 0;
    let TextField = 0;

    if (!averageRatingArray?.[0]?.averageRating) {
      averageRatingArray = averageRatingArray?.map((section) => ({
        ...section,
        averageRating: 0,
        textField: 0,
      }));
    }

    averageRatingArray?.map((item) => {
      item?.questions?.map((ans) => {
        if (ans?.question?.answerType == 'Rating') {
          rating += parseInt(ans?.answer);
        } else {
          TextField += 1;
        }
      });
      item.averageRating = rating / (item?.questions?.length - TextField);
      item.textField = TextField;
      rating = 0;
      TextField = 0;
    });

    setSectionAverage(averageRatingArray);

    averageRatingArray?.map((item) => {
      if (item?.questions?.length == item?.textField) {
        item.avarageRating = 0;
        minusAverage += 1;
      } else {
        if (item?.questions?.length == 0) {
          minusAverage += 1;
        } else {
          totalRating += item?.averageRating;
        }
      }
    });

    totalRating = totalRating / (averageRatingArray?.length - minusAverage);
    setTotalAverage(totalRating);
  }, [evaluation?.viewSubmittedEvaluationData]);
  // return (
  //   <div>abc</div>
  // )
  return (
    <Container className="View-Evaluation-container" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="title-button-wrapper View-Evaluation-title">
          <Typography variant="h4" gutterBottom>
            View Evaluation Form
          </Typography>
          <Stack direction="row" alignItems="center">
            {/* <LoadingButton
                loading={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ marginRight: "15px" }}
              >
                Save
              </LoadingButton> */}
            <Button
              className="back-button"
              // onClick={() => navigate("/evaluation")}
              onClick={() => navigate(-1)}
              variant="contained"
              startIcon={<ArrowBackIcon />}
              sx={{
                backgroundColor: '#fd822e',
                '&:hover': {
                  backgroundColor: '#fd822e',
                },
                color: 'white',
              }}
            >
              Back
            </Button>
          </Stack>
        </div>
      </Stack>
      {loading ? (
        <Loader />
      ) : (
        <Card className="View-Evaluation_main-card gray-box Employee_Detail_Content">
          <Box sx={{ marginTop: '10px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} sx={{ marginBottom: '5px' }}>
                <Typography
                  variant="body1"
                  sx={{ marginLeft: '10px', fontSize: '18px' }}
                >
                  {evaluation?.viewSubmittedEvaluationData?.submittedByEmployee
                    ?.firstName &&
                  evaluation?.viewSubmittedEvaluationData?.submittedByEmployee
                    ?.lastName ? (
                    <>
                      <strong>By:- </strong>
                      {' ' +
                        evaluation?.viewSubmittedEvaluationData
                          ?.submittedByEmployee?.firstName +
                        ' ' +
                        evaluation?.viewSubmittedEvaluationData
                          ?.submittedByEmployee?.lastName}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginLeft: '10px', fontSize: '18px' }}
                >
                  {evaluation?.viewSubmittedEvaluationData?.submittedForEmployee
                    ?.firstName &&
                  evaluation?.viewSubmittedEvaluationData?.submittedForEmployee
                    ?.lastName ? (
                    <>
                      <strong>For:- </strong>
                      {' ' +
                        evaluation?.viewSubmittedEvaluationData
                          ?.submittedForEmployee?.firstName +
                        ' ' +
                        evaluation?.viewSubmittedEvaluationData
                          ?.submittedForEmployee?.lastName}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginLeft: '10px', fontSize: '18px' }}
                >
                  {getMonthDates(
                    evaluation?.viewSubmittedEvaluationData?.startMonth,
                    evaluation?.viewSubmittedEvaluationData?.startYear
                  )?.startDate ? (
                    <>
                      <strong>Evaluation Period From :-</strong>
                      {' ' +
                        getMonthDates(
                          evaluation?.viewSubmittedEvaluationData?.startMonth,
                          evaluation?.viewSubmittedEvaluationData?.startYear
                        )?.startDate}{' '}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{ marginLeft: '10px', fontSize: '18px' }}
                >
                  {getMonthDates(
                    evaluation?.viewSubmittedEvaluationData?.endMonth,
                    evaluation?.viewSubmittedEvaluationData?.endYear
                  )?.endDate ? (
                    <>
                      <strong>Evaluation Period To:- </strong>
                      {' ' +
                        getMonthDates(
                          evaluation?.viewSubmittedEvaluationData?.endMonth,
                          evaluation?.viewSubmittedEvaluationData?.endYear
                        )?.endDate}{' '}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    marginLeft: '10px',
                    marginTop: '15px',
                    fontSize: '18px',
                  }}
                >
                  {totalAverage > 0 ? (
                    <>
                      <strong>Total Average Rating:- </strong>
                      {' ' + totalAverage.toFixed(2) || 0.0}
                    </>
                  ) : (
                    ''
                  )}
                </Typography>
              </Grid>
            </Grid>
            {evaluation?.viewSubmittedEvaluationData?.submitEvalutionDetail?.map(
              (section, index) =>
                section?.questions?.length > 0 ? (
                  <React.Fragment key={index}>
                   
                    <Grid
                      container
                      spacing={{ xs: 2, md: 3 }}
                      columns={{ xs: 4, sm: 8, md: 12 }}
                      style={{
                        marginTop: '5px',
                        marginBottom: '10px',
                        paddingLeft: '0px',
                        marginLeft: '-14px',
                      }}
                    >
                      <Grid
                        item
                        xs={4}
                        sm={8}
                        md={12}
                       
                      >
                        <Typography
                          variant="h6"
                          
                        >
                          Section:- {section?.sectionName}
                        </Typography>
                        <Typography
                          variant="body1"
                        
                        >
                          {handleaverageRatingShow(section)
                            ? 'Average Rating:- ' +
                              (sectionAverage?.[index]?.averageRating?.toFixed(
                                2
                              ) || 0.0)
                            : ''}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        sm={8}
                        md={12}
                        sx={{ marginTop: 'auto', marginBottom: 'auto' }}
                      >
                        {section?.questions?.map(
                          (question, index1) => (
                            <Grid
                              container
                              spacing={{ xs: 2, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                              key={index1}
                              sx={{ marginTop: '10px', marginBottom: '10px' }}
                            >
                              <Grid item xs={4} sm={4} md={6}>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    marginLeft: '10px',
                                    marginTop: 'auto',
                                    marginBottom: 'auto',
                                  }}
                                >
                                  {index1 + 1}. {question?.question?.title}
                                </Typography>
                              </Grid>
                              <Grid
                                item
                                xs={4}
                                sm={4}
                                md={6}
                                
                              >
                                {question?.question?.answerType == 'Rating' && (
                                  <Rating
                                    name="read-only"
                                    value={Number(question?.answer)}
                                   
                                    readOnly
                                  />
                                )}
                                {question?.question?.answerType == 'Text' && (
                                  <Typography
                                    variant="body1"
                                   
                                  >
                                    Answer:- {question?.answer}
                                  </Typography>
                                )}
                              </Grid>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ) : (
                  ''
                )
            )}
            {evaluation?.viewSubmittedEvaluationData?.isProbationEvaluation ? (
              <Grid
                container
                spacing={3}
                style={{ paddingLeft: '20px', paddingRight: '20px' }}
              >
                <Grid item xs={12}>
                  <Divider sx={{ marginBottom: '5px', marginTop: '5px' }} />
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: '10px', marginTop: '10px' }}
                  >
                    {evaluation?.viewSubmittedEvaluationData?.comments
                      ? 'Commented:- ' +
                        evaluation?.viewSubmittedEvaluationData?.comments
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: '10px', marginTop: '10px' }}
                  >
                    {evaluation?.viewSubmittedEvaluationData
                      ?.trainingDevelopement
                      ? 'Training and/or Development Needs:- ' +
                        evaluation?.viewSubmittedEvaluationData
                          ?.trainingDevelopement
                      : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="body1"
                    sx={{ marginLeft: '10px', marginTop: '10px' }}
                  >
                    {evaluation?.viewSubmittedEvaluationData?.actionsAgreedTo
                      ? 'Actions Agreed:- ' +
                        evaluation?.viewSubmittedEvaluationData?.actionsAgreedTo
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              ''
            )}
          </Box>
        </Card>
      )}
    </Container>
  );

}

const componentConfig = {
  component: ViewEmployeeEvalution,
  path: "/employeeEvaluation/evaluation/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;