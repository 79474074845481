import React from "react";
import { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import CustomPagination from "../../components/Pagination";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { fDateCustom } from "../../utils/formatTime";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import CachedIcon from "@mui/icons-material/Cached";
import Chip from "@mui/material/Chip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import invertDirection from "../../utils/invertDirection";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import { encryption } from "../../utils/encodeString";
import { getEmployeeSubmittedEvalution } from "../../redux/employeeEvaluation/employeeEvaluationThunk";
import { setLimit, setPage, setOrderBy, setSortBy } from "../../redux/employeeEvaluation/employeeEvaluationHistorySlice";
import { getEmployeeForDropDown } from "../../redux/employee/employeeThunk";
import { getSubmittedEvalutionHistoryCount } from "../../redux/employeeEvaluation/employeeEvaluationThunk";

function EmployeeEvaluation() {
	const inputRef = React.useRef(null);
	let navigate = useNavigate();
	const [filterChips, setFilterChips] = useState([]);
	const dispatch = useDispatch();
	const [searchValue, setSearchValue] = useState("");
	const AuthRole = useSelector((state) => state.auth.role);

	const totalCounts = useSelector((state) => state.evaluationsCount.totalCounts);

	console.log("totalCount", totalCounts)
	const allEmployeeData = useSelector(
    (state) => state.employee.allEmployeeData
  );
	const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];
	const {
		totalRecords,
		page,
		totalPages,
		limit,
		status,
		loading,
		sortBy,
		orderBy,
		isSearchQuery,
		} = useSelector((state) => state.employeeEvalutionHistory);

	const data = useSelector((state) => state.employeeEvalutionHistory.data)
	
	// console.log("data", data)
	// console.log(page, limit, totalPages)

	const getUserPermission = async (Id) => {
		
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};

	useEffect(() => {
		dispatch(getSubmittedEvalutionHistoryCount({}));
	}, [dispatch]);

	useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		// if (!getViewVisible("employeeEvaluation")) {
			
			dispatch(
				getEmployeeSubmittedEvalution({
					page: page + 1,
					limit: limit,
					sortBy,
					orderBy,
					allEvaluation: 1
				}),
			);
			// navigate("/dashboard");
		// }
	}, []);

	const formik = useFormik({
		initialValues: {
			submittedBy:"",
			startYear:"",
			endYear : "",
			startMonth : "",
			endMonth : "",
			// startDate: null,
			// endDate: null
		},
		onSubmit: async (values) => {
			let queryParams = { ...values };
        try {
          await dispatch(getEmployeeSubmittedEvalution({ ...queryParams, limit, page: 1, allEvaluation: 1 }));
          dispatch(setPage({ page: 0 }));
          prepareChips();
      
        } catch (error) {
          toast.error(error.response.data.message);
        }
		},
	});

	const {
		handleSubmit,
		setValues,
		values,
		setFieldValue,
		handleChange,
		errors,
		touched,
		isSubmitting,
		resetForm,
		getFieldProps
		} = formik;

	useEffect(() => {
		if (data?.length === 0 && page !== 0) {
			dispatch(setPage({ page: page - 1 }));
		}
	}, [data?.length]);

	useEffect(() => {
		if (status == "idle") {
			dispatch(
				getEmployeeSubmittedEvalution({
					page: page + 1,
					limit: limit,
					sortBy,
					orderBy,
					allEvaluation: 1
				}),
			);
		}
	}, [dispatch, limit, page, status, sortBy, orderBy]);

	const handleReset = async () => {
    resetForm();
    await dispatch(getEmployeeSubmittedEvalution({ limit, page: 1, sortBy, orderBy,allEvaluation: 1 }));
    dispatch(setPage({ page: 0 }));
  
    setState({ ...state, right: true });
    setFilterChips([]);
  };

	const handleChangePage = (event, newPage) => {
		dispatch(setPage({ page: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
    dispatch(setPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

	const [state, setState] = React.useState({
    right: false,
  });

	const toggleDrawer = (anchor, open) => (event) => {
    // getYears()
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

	const prepareChips = () => {
    let filters = { ...values };
    let tempChips = [];
		let sYear = new Date(values.startDate).getFullYear()
		let eYear = new Date(values.endDate).getFullYear()
		let sMonth= monthNames[new Date(values.startDate).getMonth()]
		let eMonth= monthNames[new Date(values.endDate).getMonth()]
    for (const key in filters) {
			if (filters[key] && key === "submittedBy") {
        const res = allEmployeeData.find(
          (team) => team.id === filters[key]
        );
        if (res) filters[key] = `Submitted By : ${res.firstName} ${res.lastName}`;
      }
      if (filters[key] && key === "startYear") {
        filters[key] = "Start Year : " + filters[key];
      }
      if (filters[key] && key === "endYear") {
        filters[key] = "End Year : " + filters[key];
      }
			if (filters[key] && key === "startMonth ") {
        filters[key] = "Start Month  : " + filters[key];
      }
      if (filters[key] && key === "endMonth") {
        filters[key] = "End Month : " + filters[key];
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
  };

  const onDeleteChip = (value, length) => {
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    let filters = { ...values, [value["type"]]: "" };

    if (length === 1) {
      dispatch(getEmployeeSubmittedEvalution({ limit, page: 1, sortBy, orderBy, allEvaluation: 1 }));
      dispatch(setPage({ page: 0 }));
    } else {
      dispatch(getEmployeeSubmittedEvalution({ ...filters, limit, page: 1, sortBy, orderBy, allEvaluation: 1 }));
      dispatch(setPage({ page: 0 }));

    }

    setFieldValue(value.type, "");
    if(value.type==="search"){
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

	return (
		<Container className="Employee-wrapper Employee-Evalution-Main-Wrapper" maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
			<div className="title-button-wrapper Employee-Evalution-Title">
				<Typography variant="h4" gutterBottom>
					Employee Evalutions ({Number(totalCounts)>0?totalCounts:0})
					{/* <Typography variant="body2">Total Records : {Number(totalCounts)>0?totalCounts:0}</Typography> */}
				</Typography>
				<Stack
				 className="employee-header-right"
				direction="row"
				alignItems="center"
				justifyContent="space-between"
        		>
					{/* <Button
              // component={RouterLink}
              onClick={()=>navigate(-1)}
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              Back To Employee
            </Button> */}
          {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <span
                className="filter-icon-part"
                onClick={toggleDrawer(anchor, true)}
              >
                <IconButton style={{padding:"0"}}>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={filterChips.length !== 0 ? false : true}
                  >
					<img src="/assets/images/filter.svg" />
                    {/* <FilterAltIcon /> */}
                  </Badge>
                </IconButton>
              </span>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                style={{ zIndex: "1300" }}
              >
                <Box
                  pt={4}
                  px={3}
                  sx={{
                    width:
                      anchor === "top" || anchor === "bottom" ? "auto" : 400,
                  }}
                  role="presentation"
                >
                  <div className="content-filter-part">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <Typography variant="h6">Intern Filter</Typography>
                      <span>
                        <IconButton onClick={toggleDrawer(anchor, false)}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Stack>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3} mb={2}>
                        <Grid item xs={12}>
							<Autocomplete
								labelid="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								size="small"
								defaultValue=""
								fullWidth
								options={allEmployeeData || []}
								getOptionLabel={(option) =>
									option ? `${option?.firstName} ${option?.lastName}` : ""
								}

								renderOption={(props, option) => (
									<li {...props} value={option.id} key={option.id}>
										{option.firstName + " " + option.lastName || ""}
									</li>
								)}
								onChange={(event, newValue) => {
									setFieldValue(`submittedBy`, newValue?.id || "");
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Submitted By"
									/>
								)}
								value={
									allEmployeeData.find(
										(e) => e.id == values.submittedBy
									) || ""
								}
							/>
                        </Grid>
                        <Grid item xs={12}>
							<Autocomplete
								labelid="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								size="small"
								defaultValue=""
								fullWidth
								options={monthNames || []}
								onChange={(event, newValue) => {
									setFieldValue(`startMonth`, newValue || "");
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Start Month"
									/>
								)}
								value={
									monthNames.find(
										(e) => e == values.startMonth
									) || ""
								}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								autoComplete="off"
								label="Start Year"
								variant="outlined"
								size="small"
								inputProps={{ maxLength: 64 }}
								{...getFieldProps("startYear")}
							/>
						</Grid>
                        <Grid item xs={12}>
							<Autocomplete
								labelid="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								size="small"
								defaultValue=""
								fullWidth
								options={monthNames || []}
								onChange={(event, newValue) => {
									setFieldValue(`endMonth`, newValue || "");
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										label="End Month"
									/>
								)}
								value={
									monthNames.find(
										(e) => e == values.endMonth
									) || ""
								}
							/>
                        </Grid>
						<Grid item xs={12}>
							<TextField
								fullWidth
								autoComplete="off"
								label="End Year"
								variant="outlined"
								size="small"
								inputProps={{ maxLength: 64 }}
								{...getFieldProps("endYear")}
							/>
						</Grid>
                      </Grid>

                      <Stack direction="row" justifyContent="flex-end" mb={3}>
                        <Button
                          variant="contained"
                          startIcon={<SearchIcon />}
                          type="submit"
                          onClick={toggleDrawer(anchor, false)}
                        >
                          Search
                        </Button>
                        <Button
                          variant="contained"
                          type="reset"
                          onClick={handleReset}
                          startIcon={<CachedIcon />}
                          sx={{ marginLeft: "10px" }}
                        >
                          Reset
                        </Button>
                      </Stack>
                    </form>
                  </div>
                </Box>
              </Drawer>
            </React.Fragment>
          ))}
        </Stack>
				{AuthRole == "Super Admin" && getAddVisible("employeeEvaluation") && (
					<Button
						component={RouterLink}
						to="/employeeEvaluation/evaluation/add"
						variant="contained"
						// startIcon={<AddIcon />}
						className="filter-icon-part header-add-button"
					>
						<img src="/assets/images/add-circle.svg" />
						Add Evaluation
					</Button>
				)}
				</div>
			</Stack>
			<Stack
				direction="row"
				alignItems="start"
			>
				{filterChips.map((chips, idx) => {
				return (
					<Chip
					label={chips.filter}
					color="primary"
					size="small"
					key={chips.filter}
					style={{ margin:"10px 5px 5px 5px" }}
					variant="filled"
					onDelete={() => onDeleteChip(chips, filterChips.length)}
					/>
				);
				})}
			</Stack>

			{filterChips.length !== 0 || isSearchQuery ? (
				<Typography className="record-found-text" variant="body2" sx={{ mb: 1 }}>
				{totalRecords} {"records found"}
				</Typography>
			) : (
				""
			)}
			<Card className="employeeEvaluation_card">
				<Scrollbar sx={{ minWidth: 800 }}>
					<TableContainer sx={{ minWidth: 800 }}>
						{loading ? (
							<Loader />
						) : (
							<Table className="employeeEvaluation-table grid-table">
								<TableHead>
									<TableRow>
									<TableCell align="left">
											<TableSortLabel
												active={sortBy === "createdDate"}
												direction={sortBy === "createdDate" ? orderBy : "asc"}
												onClick={() => handleSorting("createdDate")}
											>
												Evalution Submitted Date
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											Evalution Period
										</TableCell>
										<TableCell align="left">
												Submitted By
										</TableCell>
										<TableCell align="left">
												Submitted For
										</TableCell>
										{/* <TableCell align="left">Comments</TableCell>
										<TableCell align="left">Actions Agreed To</TableCell> */}
										<TableCell align="left">Total Average Rating</TableCell>
										{(getEditVisible("employeeEvaluation") ||
											getDeleteVisible("employeeEvaluation")) && (
											<TableCell className="th-action-buttons" align="center" style={{ width: "140px" }}>
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
								{data?.length > 0 ? (
                    data.map((evaluation, idx) => {
											return (
												<TableRow key={idx}>
												<TableCell align="left">
													{fDateCustom(evaluation?.createdDate) || "-"}
												</TableCell>
												<TableCell align="left">
													{evaluation?.startMonth}-{evaluation?.startYear} To {evaluation?.endMonth}-{evaluation?.endYear}
												</TableCell>
												<TableCell align="left">{evaluation?.submittedByEmployee?.firstName || ""} {evaluation?.submittedByEmployee?.lastName || ""}</TableCell>
												<TableCell align="left">{evaluation?.submittedForEmployee?.firstName || ""} {evaluation?.submittedForEmployee?.lastName || ""}</TableCell>
												{/* <TableCell align="left">
													{evaluation?.comments || "-"}
												</TableCell>
												<TableCell align="left">
													{evaluation?.actionsAgreedTo || "-"}
												</TableCell> */}
												<TableCell align="left">
													{evaluation?.totalAverageRating || "-"}
												</TableCell>
												<TableCell className="td-action-buttons">
													<IconButton
														className="action-button-group"
														component={RouterLink}
														to={`/employeeEvaluation/evaluation/view/${encryption(evaluation.id)}`}
													>
														<img src="assets/images/vieweye-icon.svg" title="View" />
														{/* <VisibilityIcon /> */}
													</IconButton>
												</TableCell>
												
											</TableRow>
											); 
										})
											) : (
												<TableRow>
													<TableCell className="No-Record-text" colSpan={12} align="left">
														No Record(s) Found
													</TableCell>
												</TableRow>
											)}
									{/* {Array.isArray(data) && data?.length === 0 ? (
										<TableRow>
											<TableCell align="center" colSpan={4}>
											No Record(s) Found
											</TableCell>
										</TableRow>
									) : (
										data?.map((evaluation, idx) => (
											<TableRow key={idx}>
												<TableCell align="left">
													{fDateCustom(evaluation?.createdDate) || "-"}
												</TableCell>
												<TableCell align="left">
													{evaluation?.startMonth}-{evaluation?.startYear} To {evaluation?.endMonth}-{evaluation?.endYear}
												</TableCell>
												<TableCell align="left">{evaluation?.submittedByEmployee?.firstName || ""} {evaluation?.submittedByEmployee?.lastName || ""}</TableCell>
												<TableCell align="left">{evaluation?.submittedForEmployee?.firstName || ""} {evaluation?.submittedForEmployee?.lastName || ""}</TableCell>
												<TableCell align="left">
													{evaluation?.comments || "-"}
												</TableCell>
												<TableCell align="left">
													{evaluation?.actionsAgreedTo || "-"}
												</TableCell>
												<TableCell align="left">
													{evaluation?.totalAverageRating || "-"}
												</TableCell>
												<TableCell>
													<IconButton
														style={{ padding: "5px" }}
														component={RouterLink}
														to={`/employee/evaluation/view/${encryption(evaluation.id)}`}
													>
														<VisibilityIcon />
													</IconButton>
												</TableCell>
												
											</TableRow>
										))
									)} */}
								</TableBody>   
							</Table>
						)}
					</TableContainer>
				</Scrollbar>
				<CustomPagination
					totalPage={totalPages||1}
					totalCount={totalRecords||0}
					limit={limit}
					handleChangePage={handleChangePage}
					page={page}
					rowsPerPageOptions={[10, 20, 40]}
					handleRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Card>
		</Container>
	);
}

const componentConfig = {
	component: EmployeeEvaluation,
	path: "/employeeEvaluation",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;