import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import { debounce } from "lodash";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "../../components/Scrollbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { encryption } from "../../utils/encodeString";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import CustomPagination from "../../components/Pagination";
import invertDirection from "../../utils/invertDirection";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import {
  deleteEvalutionById,
  getAllEvalution,
  getEvalutionCount,
  updateEvaluation,
} from "../../redux/evaluation/evaluationThunk";
import {
  setCurrentPage,
  setLimit,
  setOrderBy,
  setSortBy,
} from "../../redux/evaluation/evaluationSlice";
import { makeStyles } from "@material-ui/core/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { fDateCustom } from "../../utils/formatTime";

import { getAllEmployeeEvaluation } from "../../redux/employeeEvaluation/employeeEvaluationThunk";
import { getEmployeeForDropDown } from "../../redux/employee/employeeThunk";
import EmployeeEvaluationSchema from "../../validations/employeeEvaluationSchema";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));
function EmployeeEvaluations() {
  const dispatch = useDispatch();
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [data, setData] = useState([])
  const [allAverage, setAllAverage] = useState({});

  const formik = useFormik({
		initialValues: {
			submittedFor: "",
			startDate: null,
			endDate: null,
		},
    validationSchema:EmployeeEvaluationSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
			  let res = await dispatch(getAllEmployeeEvaluation({ ...values })).unwrap();
        setData(res?.data)
        if(res?.data?.evaluations?.length <= 0){
          toast.success("No evaluation found")
        }
				// resetForm();
			} catch (error) {
				toast.error(error.message);
			}
		},
	});
  const {
		errors,
		values,
		touched,
		handleChange,
		handleSubmit,
		setValues,
		setFieldValue,
		isSubmitting,
		getFieldProps,
	} = formik;

  const allEmployeeData = useSelector(
    (state) => state?.employee?.allEmployeeData
  );


  const handleGetData = async () => {
    try {
      let res = await dispatch(getAllEmployeeEvaluation({ ...values })).unwrap();
      setData(res?.data)
      if(res?.data?.evaluations == []){
        toast.success("No data found")
      }
      // resetForm();
    } catch (error) {
      toast.error(error.message);
    }
  }
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const {
    // data,
    allEmployeeEvaluation,
    evaluations,
    currentPage,
    status,
    limit,
    totalRecords,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
  } = useSelector((state) => state.evaluation);

  const totalCount = useSelector((state) => state.evaluationCount.totalCounts);

  useEffect(() => {
    if (data?.evaluations?.length === 0 && currentPage !== 0) {
      dispatch(setCurrentPage({ page: currentPage - 1 }));
    }
  }, [data?.evaluations?.length]);


  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ currentPage: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  // const handleDelete = async (id) => {
  //   try {
  //     await confirm({
  //       description: `Are you sure you want to delete?`,
  //     });
  //     await dispatch(deleteEvalutionById(id)).unwrap();
  //     dispatch(
  //       getAllEvalution({
  //         page: currentPage + 1,
  //         limit: limit,
  //         sortBy,
  //         orderBy,
  //         search: "",
  //       })
  //     );
  //     dispatch(getEvalutionCount({})).unwrap();
  //   } catch (error) {
  //     toast.error(error?.message);
  //   }
  // };

  // useEffect(() => {
  //   if (!error) {
  //     dispatch(getEvalutionCount({}));
  //   }
  // }, [error]);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    if (e?.target?.value != "") {
      setIsSearching(true);
      await dispatch(
        getAllEvalution({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: e?.target?.value,
        })
      );
    } else {
      setIsSearching(false);
      await dispatch(
        getAllEvalution({
          limit,
          page: currentPage + 1,
          orderBy,
          sortBy,
          search: "",
        })
      );
    }
    setSearchValue(e?.target?.value);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const onDeleteChip = async () => {
    setSearchValue("");
    setIsSearching(false);
    inputRef.current.value = "";
    dispatch(
      getAllEvalution({
        page: currentPage + 1,
        limit: limit,
        sortBy,
        orderBy,
        search: "",
      })
    );
  };
  const handleToggle = async (evaluation) => {
    try {
      // console.log("EVALUATION______",evaluation)
      if (evaluation?.isActive == 1) {
        let editObj = {
          title: evaluation.title,
          isActive: 0,
          designationId: evaluation?.designationId?.map(
            (designation) => designation?.id
          ),
          sectionId: Object.keys(evaluation?.sectionId).map((key) => ({
            [key]: evaluation?.sectionId[key],
          })),
        };
        await dispatch(
          updateEvaluation({
            editEvaluationData: editObj,
            evaluation_id: evaluation?.id,
          })
        ).unwrap();
      }
      if (evaluation?.isActive == 0) {
        let editObj = {
          title: evaluation.title,
          isActive: 1,
          designationId: evaluation?.designationId?.map(
            (designation) => designation?.id
          ),
          sectionId: Object.keys(evaluation?.sectionId).map((key) => ({
            [key]: evaluation?.sectionId[key],
          })),
        };
        await dispatch(
          updateEvaluation({
            editEvaluationData: editObj,
            evaluation_id: evaluation?.id,
          })
        ).unwrap();
      }

      await dispatch(
        getAllEvalution({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    let avgTechnicalSkill = 0;
    let avgSystemUnderstanding = 0;
    let avgWorkInvolvement = 0;
    let avgReporting = 0;
    let temp = data?.evaluations;
    let l = data?.evaluations?.length;
    for (let i = 0; i < l; i++)
    {
      avgTechnicalSkill += parseInt(temp[i].technicalSkil)
      avgSystemUnderstanding += parseInt(temp[i].systemUnderstanding)
      avgWorkInvolvement += parseInt(temp[i].workInvolvementOwnership)
      avgReporting += parseInt(temp[i].taskAssignmentReporting)
    }
    setAllAverage({technicalSkil: avgTechnicalSkill / l, 
                    systemUnderstanding: avgSystemUnderstanding / l,
                  workInvolvementOwnership: avgWorkInvolvement / l,
                taskAssignmentReporting: avgReporting / l})

    // console.log(allAverage)
  }, [data]);
  
  return (
    <Container maxWidth="xl" className="item-component">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Evaluations
          <Typography variant="body2">
            {/* Total Records : {data?.totalRecords ? data?.totalRecords : "0"} */}
          </Typography>
        </Typography>

        {/* {getAddVisible("evaluations") && (
          <Button
            variant="contained"
            component={RouterLink}
            to="/evaluations/add"
            startIcon={<AddIcon />}
          >
            Add Evaluation
          </Button>
        )} */}
        </div>
      </Stack>

      <Card className="custom-grid" style={{padding:"10px"}}>
        <Container style={{ maxWidth: "100%" }}>
          <form 
              autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
            >
            <Grid container xs={12} pt={2} pb={2}>
              <Grid item xs={4} mr={2}>
                {/* <TextField
                  fullWidth
                  label="Search by Title"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                /> */}
                <Autocomplete
										size="small"
										fullWidth
										options={allEmployeeData || []}
										getOptionLabel={(option) =>
											option ? `${option?.firstName} ${option?.lastName}` : ""
										}

										renderOption={(props, option) => (
											<li {...props} value={option.id} key={option.id}>
												{option.firstName + " " + option.lastName || ""}
											</li>
										)}
										onChange={(event, newValue) => {
											setFieldValue(`submittedFor`, newValue?.id || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Submitted For"
												error={
													touched?.submittedFor &&
													errors?.submittedFor 
												}
												helperText={
													touched?.submittedFor &&
													errors?.submittedFor 
												}
											/>
										)}
										value={
											allEmployeeData?.find(
												(e) => e.id == values.submittedFor
											) || ""
										}
									/>
              </Grid>
              <Grid item xs={2} mr={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Start Date"
                    value={values.startDate}
                    inputFormat="dd/MM/yyyy"
                    // minDate={new Date('2017-01-01')}
                    onChange={(newValue) => {
                      setFieldValue("startDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
								</LocalizationProvider>
              </Grid>
              <Grid item xs={2} mr={2}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="End Date"
                    value={values.endDate}
                    inputFormat="dd/MM/yyyy"
                    // minDate={new Date('2017-01-01')}
                    onChange={(newValue) => {
                      setFieldValue("endDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                      />
                    )}
                  />
								</LocalizationProvider>
              </Grid>
              <Grid item xs={1}>
                <Stack className="button-part">
                  <LoadingButton
                    size="small"
                    type="submit"
                    // type="button"
                    variant="contained"
                    loading={isSubmitting}
                    // onClick={()=>handleGetData()}
                    data-cy="btn-submit-department"
                  >
                    Search
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      {/* <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearching ? (
          <Chip
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={() => onDeleteChip()}
          />
        ) : (
          ""
        )}
      </Stack> */}
      {/* {isSearching ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )} */}
      { data?.evaluations?.length > 0 && 
        <Card className="custom-grid">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                page={currentPage}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                // filter={isSearchQuery ? true : false}
              /> */}
              <Stack
                style={{ paddingBottom: "0" }}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                // pt={3}
                pb={3}
              >
                <Typography gutterBottom>
                  Average Technical Skills: {allAverage?.technicalSkil} &nbsp; System Understanding: {allAverage?.systemUnderstanding}  &nbsp; Work Involvement / Ownership: {allAverage?.workInvolvementOwnership}  &nbsp; Task Assignment / Reporting: {allAverage?.taskAssignmentReporting}
                </Typography>
              </Stack>
              <Scrollbar>
                <TableContainer
                  component={Paper}
                  sx={{ minWidth: 800 }}
                  className="radius-remove"
                  style={{ borderRadius: "0" }}
                >
                  <Table>
                  <TableHead>
									  <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "createdDate"}
                          direction={sortBy === "createdDate" ? orderBy : "asc"}
                          onClick={() => handleSorting("createdDate")}
                        >
                          Evalution Submitted Date
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        Submitted By
                      </TableCell>
                      <TableCell align="left">
                        Technical Skills
                      </TableCell>
                      <TableCell align="left">
                        System Understanding
                      </TableCell>
                      <TableCell align="left">
                        Work Involvement / Ownership
                      </TableCell>
                      <TableCell align="left">
                        Task Assignment / Reporting
                      </TableCell>
                      <TableCell align="left">
                        Average Rating
                      </TableCell>
                      {/* {(getEditVisible("employeeEvaluation") ||
                        getDeleteVisible("employeeEvaluation")) && (
                        <TableCell align="right" style={{ width: "140px" }}>
                          Actions
                        </TableCell>
                      )} */}
                    </TableRow>
                  </TableHead>

                    {loading ? (
                      <TableCell align="center" width="100%" colSpan={7}>
                        <Loader />
                      </TableCell>
                    ) : (
                      <TableBody>
                        {data?.evaluations?.length > 0 ? (
                          data?.evaluations?.map((evaluation, idx) => {
                            console.log("evaluation", evaluation)
                            return (
                              <TableRow key={idx}>
                              <TableCell align="left">
                                {fDateCustom(evaluation?.submittedDate) || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.employeesSubmittedBy?.firstName || ""} {evaluation?.employeesSubmittedBy?.lastName || ""}
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.technicalSkil}
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.systemUnderstanding }
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.workInvolvementOwnership }
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.taskAssignmentReporting }
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.average}
                              </TableCell>
                              {/* <TableCell>
                                <IconButton
                                  style={{ padding: "5px" }}
                                  component={RouterLink}
                                  to={`/employee/evaluation/view/${encryption(evaluation.id)}`}
                                >
                                  <VisibilityIcon />
                                </IconButton>
                              </TableCell> */}
                            </TableRow>
                            ); 
                          })
                            ) : (
                              <TableRow>
                                <TableCell className="No-Record-text" colSpan={12} align="left">
                                  No Record(s) Found
                                </TableCell>
                              </TableRow>
                        )}
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </Scrollbar>
            </Grid>
          </Grid>
        </Card>
      }
    </Container>
  );
}

const componentConfig = {
  component: EmployeeEvaluations,
  path: "/EmployeeEvaluations",
  public: false,
  layout: DashboardLayout,
  group: "evaluation",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
