const NUMBER_OF_EMPLOYEE = [
    { value: "0-50", text: "0-50" },
    { value: "50-100", text: "50-100" },
    { value: "100-150", text: "100-150" },
    { value: "150-200", text: "150-200" },
    { value: "200-250", text: "200-250" },
    { value: "250-300", text: "250-300" },
    { value: "300-350", text: "300-350" },
    { value: "350-400", text: "350-400" },
    { value: "400-500", text: "400-500" },
    { value: "1000+", text: "1000+" },
];

const WORKING_DAYS = [
    { value: "First and Third Saturday", text: "First and Third Saturday" },
    { value: "Second and Fourth Saturday", text: "Second and Fourth Saturday" },
    { value: "Five working days", text: "Five working days" },
    { value: "Six working days", text: "Six working days" },
];

const APPRAISAL_CYCLES = [
    { value: "January", text: "January" },
    { value: "February", text: "February" },
    { value: "March", text: "March" },
    { value: "April", text: "April" },
    { value: "May", text: "May" },
    { value: "June", text: "June" },
    { value: "July", text: "July" },
    { value: "August", text: "August" },
    { value: "September", text: "September" },
    { value: "October", text: "October" },
    { value: "November", text: "November" },
    { value: "December", text: "December" },
    { value: "Joining Date", text: "Joining Date" },
];

const NOTICE_PERIOD = [
    { value: "1 Month", text: "1 Month" },
    { value: "1.5 Months", text: "1.5 Months" },
    { value: "2 Months", text: "2 Months" },
    { value: "3 Months", text: "3 Months" },
];

const EXPERIENCED_BOND = [
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 1.5, text: 1.5 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
];

const FRESHER_BOND = [
    { value: 0, text: 0 },
    { value: 1, text: 1 },
    { value: 1.5, text: 1.5 },
    { value: 2, text: 2 },
    { value: 3, text: 3 },
];

export {
    NUMBER_OF_EMPLOYEE,
    WORKING_DAYS,
    APPRAISAL_CYCLES,
    NOTICE_PERIOD,
    EXPERIENCED_BOND,
    FRESHER_BOND,
};
