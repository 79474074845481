import * as Yup from "yup";
import { get, isEmpty } from "lodash";

function isPresentValidation(message) {
  return this.test("isPresentValidation", message, function (value) {
    const { path, createError } = this;
    if (value === undefined) {
      return createError({
        path,
        message: message ?? "check this field please",
      });
    }

    return true;
  });
}

function instituteValidation(message) {
  return this.test("instituteValidation", message, function (value) {
    const { path, createError } = this;

    if (!this.parent.instituteId && !this.parent.instituteName) {
      return createError({
        path,
        message: message ?? "check this field please",
      });
    }
    return true;
  });
}

const uniquePropertyTest = function (value, propertyName, message) {
  if (
    this.parent
      .filter((v) => v !== value)
      .some((v) => get(v, propertyName) === get(value, propertyName))
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    });
  }

  return true;
};

Yup.addMethod(Yup.object, "uniqueProperty", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    return uniquePropertyTest.call(this, value, propertyName, message);
  });
});

Yup.addMethod(Yup.object, "uniqueProperties", function (propertyNames) {
  return this.test("unique", "", function (value) {
    const errors = propertyNames
      .map(([propertyName, message]) => {
        try {
          return uniquePropertyTest.call(this, value, propertyName, message);
        } catch (error) {
          return error;
        }
      })
      .filter((error) => error instanceof Yup.ValidationError);

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});

Yup.addMethod(Yup.mixed, "isPresentValidation", isPresentValidation);
Yup.addMethod(Yup.mixed, "instituteValidation", instituteValidation);
// Yup.addMethod(Yup.mixed, 'unique', unique);

const personalSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .max(64, "First name is too long")
    .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")

    .nullable(),
  lastName: Yup.string()
    .required("Last name is required")
    .max(64, "Last name is too long")
    .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
    .nullable(),
  // dateOfBirth: Yup.date().required('Date of Birth is required').typeError('Invalid Date'),
  gender: Yup.string().required("Gender is required"),
  // referedBy:Yup.string().required("Referralname is required"),
  // maritalStatus: Yup.string().required('Marital Status is required'),
  // referedBy: Yup.string().required('Refered By is required').max(64, 'Refered by is too long'),
  contactNumber: Yup.string()
    .required("Contact number is required")
    .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
    .min(10, "Contact number must be 10 digits")
    .max(10, "Contact number must be 10 digits")
    .nullable(),

  alternateContactNumber: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Alternate contact number is invalid")
    .min(10, "Alternate contact number must be 10 digits")
    .max(10, "Alternate contact number must be 10 digits")
    /* .notOneOf(
			[Yup.ref('contactNumber')],
			'Conatct number and alternate contact number cannot be same',
		) */
    .nullable(),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .max(512, "Email is too long"),
  skype: Yup.string().max(512, "Skype is too long").nullable(),
  linkedIn: Yup.string()
    .url("Invalid URL")
    .max(1024, "linkedIn is too long")
    .nullable(),
  technologies: Yup.array()
    .min(1, "Technology is required.")
    .required("Technology is required."),
  candidateJobs: Yup.array()
    .min(1, "Candidate Jobs is required.")
    .required("Candidate Jobs is required."),
  totalExperience: Yup.number().required("Total Experience is required."),
  referedBy: Yup.string().when("candidateJobs", {
    is: (candidateJobs) => (candidateJobs[0]?.jobSource === "Reference" ? true : false),
    then: Yup.string()
      .required("Referral Name is required")
      .nullable(),
    otherwise: Yup.string()
      .nullable(),
  }),

  // max(512, 'LinkedIn is too long'),

  /* 	totalExperience: Yup.number().required("Total Experince Required").nullable(),
	technologies: Yup.array.when(["totalExperience"], {
		is: (totalExperience) => totalExperience === 0,
		then: Yup.array.of().min(1, "Please Select 1 Technology"),
		otherwise: Yup.string().nullable(),
	}), */
  // activity: Yup.object().shape({
  //   approachDate: Yup.date()
  //     .required("Approach date is required")
  //     .typeError("Approach date is required"),
  //   approachBy: Yup.string(),
  //   candidateStatusId: Yup.string().required("Candidate status is required"),
  //   note: Yup.string()
  //     .required("Note is required")
  //     .max(512, "Note is too long")
  //     .nullable(),
  // }),
});
export function checkPersonalDetails(error) {
  return Boolean(
    error.firstName ||
      error.lastName ||
      error.email ||
      error.dateOfBirth ||
      error.gender ||
      error.contactNumber ||
      error.email ||
      error.skype ||
      error.alternateContactNumber ||
      error.technologies
  );
}

export function checkActivityDetails(error) {
  return Boolean(error.activity);
}

export default personalSchema;
