export const ACTIVITY_STATUS = "/activityStatus";
export const ACTIVITY_STATUS_BYID = "/getActivityStatusById";
export const BLOODGROUP = "/bloodGroup";
export const COURSE = "/course";
export const DEPARTMENT = "/department";
export const DESIGNATION = "/designation";
export const EDUCATION = "/education";
export const INTERVIEW_STATUS = "/interviewStatus";
export const INTERVIEW_TYPE = "/interviewType";
export const JOB_CHANGE = "/jobChange";
export const JOB_DESCRIPTION = "/jobDescription";
export const SKILL = "/skill";
export const TECHNOLOGY = "/technology";
export const EXTERNAL_DESIGNATION = "/externalDesignation";
export const CHECK_LIST = "/checklist";
export const MANUFACTURER = "/manufacturer";
export const ADDRESS_MASTER = "/pomaster";
export const CANDIDATE_STATE = "/candidateState";
export const TEAM = "/team";
