import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import DashboardLayout from '../../../layouts/dashboard';
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import FormGroup from "@mui/material/FormGroup";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton"
import { FormControlLabel } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { decryption } from "../../../utils/encodeString";
import { useRef } from "react";
import pollSchema from "../../../validations/pollSchema";
import { createPoll, uploadPollImages } from "../../../redux/poll/pollThunk";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Switch from "@mui/material/Switch";

function AddPoll() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { id } = useParams();
  if (id) id = decryption(id);
	const [loading, setLoading] = useState(true);
	const questionInput = useRef(null);
	const refs = useRef([]);
	let [key1, setKey1] = useState({});
	let [optionText, setOptionText] = useState([]);
	let [questionOption, setQuestionOption] = useState([]);

	const pollObj = {
		options: "",
		optionText: "",
		optionsUrl: "",
	};

	useEffect(() => {
		if(id===-1){
			navigate("/poll");
		}
	}, [dispatch,id]);

	const formik = useFormik({
    initialValues: {
			questionText:"",
			questionUrl:"",
			multipleSelection:"", 
			startDate: null,
			endDate: null,
			status:"",
			pollQuestionOptions:[
				{
					options:"",
					optionText:"",
					optionsUrl:"",
				},
				{
					options:"",	
					optionText:"",
					optionsUrl:"",
				}
			]	
    },
		validationSchema: pollSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
				const formData = new FormData();
				let urls = {}
				values.pollQuestionOptions.map((opt) =>{
					urls[opt.options + "-" + opt.optionText] = opt.optionsUrl
					}
				)
				urls = {...urls, "pollImage": values.questionUrl}

				for (let key in urls) {
          formData.append(key, urls[key]);
        }

				if(values.multipleSelection == true)
				{
					values = {...values, multipleSelection: 1}
				}
				else{
					values = {...values, multipleSelection: 0}
				}

				if(values.status == true)
				{
					values = {...values, status: 1}
				}
				else{
					values = {...values, status: 0}
				}

				const poll = values.pollQuestionOptions.map((p) => {
					let a = {"options" : p.options, "optionText" : p.optionText, "optionsUrl": ""};
					return a
				})

				let body = {"questionText": values.questionText,"questionUrl": "", "multipleSelection": values.multipleSelection, "startDate": values.startDate,
					"endDate": values.endDate,"status": values.status, "pollQuestionOptions": poll
				}
				
				if (!id) {
					await dispatch(createPoll(body)).unwrap()
					.then((res) => {
						let id = res?.data?.id
						console.log(res)
						dispatch(uploadPollImages({id, body: formData}));
					});
        }

        // if (id) {
        //   await dispatch(updateQuestionById({ ...values, question_id: id })).unwrap();
        // }
				navigate("/poll");
				resetForm();
       
      } catch (error) {
        return toast.error(error.message);
      }
    },
  });
	
	const {
		handleSubmit,
		values,
		setFieldValue,
		getFieldProps,
		isSubmitting,
		handleChange,
		setValues,
		touched,
		errors,
		handleBlur
	} = formik;

	useEffect(() => {
		setLoading(false);
  }, [dispatch, setValues, id, navigate]);
	
	const checkIfImage = (file) => {
		if (file && file?.name) {
			const imageTypes = ["jpeg", "jpg", "png"];
			return imageTypes.includes(file?.name?.split(".")[1]);
		}
		return file && file["type"] && file["type"]?.split("/")[0] === "image";
	};

	const getFileName = (file) => {
		if (!file) {
			return "";
		}

		if (file && !file?.name) {
			const splittedFile = file?.split("/");
			return splittedFile[splittedFile?.length - 1];
		}

		return file?.name?.length > 10
			? file?.name?.substr(0, 10) + "..."
			: file?.name;
	};

	const handleQuestionUrl = (e) => {
    setFieldValue("questionUrl", e?.target?.files[0]);
  };

	const handleOptionsUrl = (e,index) => {
    setFieldValue(`pollQuestionOptions[${index}].optionsUrl`, e?.target?.files[0]);
  };

	const addPollQuestion = () => {
    setFieldValue("pollQuestionOptions", [...values.pollQuestionOptions, pollObj]);
  };

	const removeQuestion = (e, index) => {
    e.preventDefault();
    const temp = [...values.pollQuestionOptions];
    temp.splice(index, 1);
    setFieldValue("pollQuestionOptions", temp);
  };

	const handleOptionTextChange = (index , e) => {
		setFieldValue(`pollQuestionOptions[${index}].optionText`, e?.target?.value)
		setOptionText([...optionText, e?.target?.value])
	}

	const handleQuestionOptionChange = (index, e) => {
		setFieldValue(`pollQuestionOptions[${index}].optionText`, e?.target?.value)
		setQuestionOption([...questionOption, e?.target?.value])
	}

	if (loading) {
		return <p>Loading</p>;
	}

	return (
		<Container maxWidth="xl">
			<form autoComplete="off" noValidate className="custom-space-layout" onSubmit={handleSubmit}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={3}
				>
					<div className="title-button-wrapper">
						<Typography variant="h4" gutterBottom mb={3}>
							{id ? "Edit Poll" : "Add Poll" }
						</Typography>
						<Stack direction="row" alignItems="center">
							<LoadingButton
								loading={isSubmitting}
								type="submit"
								variant="contained"
							>
								Save
							</LoadingButton>
							<Button
								variant="contained"
								component={RouterLink}
								to="/poll"
								startIcon={<ArrowBackIcon />}
								style={{ marginLeft: "10px" }}
							>
								Back To List
							</Button>
						</Stack>
					</div>
				</Stack>
				<Card>
					<Container maxWidth="xl">
						<Grid
							container
							spacing={3}
							py={3}
							style={{ paddingBottom: "13px", paddingTop: "17px" }}
						>
							<Grid item xs={8}>
								<TextField
									fullWidth
									label="Question Text"
									name="questionText"
									variant="outlined"
									size="small"
									onChange={handleChange}
									inputProps={{ maxLength: 64 }}
									value={values.questionText}
									error={Boolean(touched.questionText && errors.questionText)}
									helperText={touched.questionText && errors.questionText}
								/>
							</Grid>
							<Grid item xs={2}>
								<div
									className="custome-uploadBox profile-image-part"
									onClick={() => {
										values?.questionUrl
											? window.open(values?.questionUrl?.webViewLink, "_blank")
											: questionInput?.current?.click();
									}}
									style={{maxHeight:"20px", display:"table-row-group"}}
								>
									{values?.questionUrl && (
										<>
											{checkIfImage(values?.questionUrl) ? (
												<>
													<InsertDriveFileIcon
														onClick={(e) => {
															window.open(
																values?.questionUrl?.webViewLink,
																"_blank"
															);
														}}
													/>
													{/* <Box
														component="img"
														src={getImageUrl(values.aadharCardImage)}
													/> */}
													<p>{getFileName(values?.questionUrl)}</p>
												</>
											) : (
												<>
													<InsertDriveFileIcon
														onClick={(e) => {
															window.open(
																values?.questionUrl?.webViewLink,
																"_blank"
															);
														}}
													/>
													<p>{values?.questionUrl && getFileName(values?.questionUrl)}</p>
												</>
											)}
										</>
									)}
									{values?.questionUrl && (
										<CancelRoundedIcon
											onClick={(e) => {
												e.stopPropagation();
												setFieldValue("questionUrl", null);
											}}
											className="closeIcon"
										/>
									)}
									<div className="p-image">
										{!values?.questionUrl && (
											<i className="upload-button">
												<CloudUploadIcon />
											</i>
										)}

										<input
											ref={questionInput}
											className="file-upload"
											type="file"
											accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
											onChange={handleQuestionUrl}
										/>
									</div>

									{/* {!values?.questionUrl && (
										<div className="file-support-text">
											(File support: pdf, png and jpg with max 20MB size)
										</div>
									)} */}
								</div>

								<FormHelperText
									error={Boolean(
										touched?.questionUrl && errors?.questionUrl
									)}
								>
									{touched?.questionUrl && errors?.questionUrl}
								</FormHelperText>
							</Grid>
							<Grid item xs={4}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="Start Date"
										value={values.startDate}
										minDate={new Date()}
										inputFormat="dd/MM/yyyy"
										onChange={(newValue) => {
											setFieldValue("startDate", newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												size="small"
												error={Boolean(touched?.startDate && errors?.startDate)}
												helperText={touched?.startDate && errors?.startDate}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={4}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<DesktopDatePicker
										label="End Date"
										value={values.endDate}
										minDate={new Date()}
										inputFormat="dd/MM/yyyy"
										onChange={(newValue) => {
											setFieldValue("endDate", newValue);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												fullWidth
												size="small"
												error={Boolean(touched?.endDate && errors?.endDate)}
												helperText={touched?.endDate && errors?.endDate}
											/>
										)}
									/>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={4}></Grid>
							<Grid item xs={4}>
								<FormGroup row>
									<FormControlLabel
										control={<Switch />}
										label="Multiple Option Selection"
										name="multipleSelection"
										onChange={handleChange}
										value={values.multipleSelection}
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={4}>
								<FormGroup row>
									<FormControlLabel
										control={<Switch />}
										label="Enable"
										name="status"
										onChange={handleChange}
										value={values.status}
									/>
								</FormGroup>
							</Grid>
						</Grid>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="space-between"
								my={3}>
								<Typography variant="h6" gutterBottom>
									Options
								</Typography>
								{values &&
								values.pollQuestionOptions && values.pollQuestionOptions.length < 6 &&
								<Button
									onClick={addPollQuestion}
									variant="contained"
									startIcon={<AddIcon />}>
									Add Poll Options
								</Button>
								}
							</Stack>
							{values &&
								values.pollQuestionOptions &&
								values.pollQuestionOptions.map((ex, index) => (
									<Stack key={index}>
										<Grid
											container
											spacing={3}
											py={3}
											style={{ paddingBottom: "13px", paddingTop: "17px" }}
										>
											<Grid item xs={4}>
												<TextField
													fullWidth
													label="Add Option"
													name="options"
													variant="outlined"
													size="small"
													onChange={handleChange}
													inputProps={{ maxLength: 64 }}
													{...getFieldProps(`pollQuestionOptions[${index}].options`)}
													error={Boolean(
															touched?.pollQuestionOptions &&
															touched.pollQuestionOptions[index] &&
															touched.pollQuestionOptions[index]?.options &&
															errors?.pollQuestionOptions &&
															errors.pollQuestionOptions[index] && 
															errors.pollQuestionOptions[index]?.options
													)}
													helperText={
														touched?.pollQuestionOptions &&
														touched.pollQuestionOptions[index] && 
														touched.pollQuestionOptions[index]?.options &&
														errors?.pollQuestionOptions &&
														errors.pollQuestionOptions[index] &&
														errors.pollQuestionOptions[index]?.options
													}
												/>
											</Grid>
											<Grid item xs={4}>
												<TextField
													fullWidth
													label="Option Text"
													name="optionText"
													variant="outlined"
													size="small"
													onChange={(e) => handleOptionTextChange(index,e)}
													inputProps={{ maxLength: 64 }}
													{...getFieldProps(`pollQuestionOptions[${index}].optionText`)}
													error={Boolean(
															touched?.pollQuestionOptions &&
															touched.pollQuestionOptions[index] &&
															touched.pollQuestionOptions[index]?.optionText &&
															errors?.pollQuestionOptions &&
															errors.pollQuestionOptions[index] &&
															errors.pollQuestionOptions[index]?.optionText
													)}
													helperText={
														touched?.pollQuestionOptions &&
														touched.pollQuestionOptions[index] &&
														touched.pollQuestionOptions[index]?.optionText &&
														errors?.pollQuestionOptions &&
														errors.pollQuestionOptions[index] &&
														errors.pollQuestionOptions[index]?.optionText
													}
												/>
											</Grid>
											<Grid item xs={2}>
												<div
													className="custome-uploadBox profile-image-part"
													onClick={() => {
														values?.pollQuestionOptions[index]?.optionsUrl
															? window.open(values.pollQuestionOptions[index]?.optionsUrl?.webViewLink, "_blank")
															: refs.current[index]?.click();
													}}
													style={{maxHeight:"20px", display:"table-row-group"}}
												>
													{values?.pollQuestionOptions[index]?.optionsUrl && (
														<>
															{checkIfImage(values?.pollQuestionOptions[index]?.optionsUrl) ? (
																<>
																	<InsertDriveFileIcon
																		onClick={(e) => {
																			window.open(
																				values?.pollQuestionOptions[index]?.optionsUrl?.webViewLink,
																				"_blank"
																			);
																		}}
																	/>
																	{/* <Box
																		component="img"
																		src={getImageUrl(values.aadharCardImage)}
																	/> */}
																	<p>{getFileName(values.pollQuestionOptions[index]?.optionsUrl)}</p>
																</>
															) : (
																<>
																	<InsertDriveFileIcon
																		onClick={(e) => {
																			window.open(
																				values?.pollQuestionOptions[index]?.optionsUrl?.webViewLink,
																				"_blank"
																			);
																		}}
																	/>
																	<p>{getFileName(values?.pollQuestionOptions[index]?.optionsUrl)}</p>
																</>
															)}
														</>
													)}
													{values?.pollQuestionOptions[index]?.optionsUrl && (
														<CancelRoundedIcon
															onClick={(e) => {
																e.stopPropagation();
																setFieldValue(`pollQuestionOptions[${index}].optionsUrl`, null);
															}}
															className="closeIcon"
														/>
													)}
													<div className="p-image">
														{!values?.pollQuestionOptions[index]?.optionsUrl && (
															<i className="upload-button">
																<CloudUploadIcon />
															</i>
														)}

														<input
															// ref={optionInput}
															ref={(element) => {
																refs.current[index] = element;
															}}
															className="file-upload"
															type="file"
															accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
															onChange={(e) => //handleOptionsUrl(index)
															 { setFieldValue(`pollQuestionOptions[${index}].optionsUrl`, e.target.files[0]);
															 }}
														/>
													</div>

													{!values?.pollQuestionOptions[index]?.optionsUrl && (
														<div className="file-support-text">
														</div>
													)}
												</div>

												<FormHelperText
													error={Boolean(
															touched?.pollQuestionOptions && 
															touched.pollQuestionOptions[index] &&
															touched.pollQuestionOptions[index]?.optionsUrl &&
															errors?.pollQuestionOptions &&
															errors.pollQuestionOptions[index] &&
															errors.pollQuestionOptions[index]?.optionsUrl
													)}
												>
													{touched?.pollQuestionOptions && 
															touched.pollQuestionOptions[index] &&
															touched.pollQuestionOptions[index]?.optionsUrl &&
															errors?.pollQuestionOptions &&
															errors.pollQuestionOptions[index] &&
															errors.pollQuestionOptions[index]?.optionsUrl}
												</FormHelperText>
											</Grid>
											{values &&
												values.pollQuestionOptions && values.pollQuestionOptions.length > 2 &&
												<Grid item xs={1}>
													<Stack
														direction="row"
														alignItems="center"
														justifyContent="end">
														{values &&
															values.pollQuestionOptions &&
															Array.isArray(values.pollQuestionOptions) && (
																<IconButton onClick={(e) => removeQuestion(e, index)}>
																	<DeleteIcon color="error" />
																</IconButton>
															)}
													</Stack>
												</Grid>
										}	
										</Grid>
										<Stack my={3}>
											<Divider />
										</Stack>
									</Stack>
								))
							}
						{/* </Grid> */}
					</Container>
				</Card>
			</form>
		</Container>
	);
}

const componentConfig = [
  {
    component: AddPoll,
    path: "/poll/add",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddPoll,
    path: "/poll/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
