import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";
import { TEAM } from "../../../constants/masters";
export const getTeam = createAsyncThunk(
	//action type string
	"team/getTeam",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.get(`${TEAM}/all`, {
				params: { sortBy: params.sortBy||'', orderBy: params.orderBy||'' },
			});
			sessionStorage.setItem(
				"team",
				JSON.stringify({
					data: res.data.data,
					timestamp: +new Date(),
				}),
			);
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const getTeamDropdown = createAsyncThunk(
	//action type string
	"team/getTeamDropdown",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.get(`${TEAM}/dropdown`, {
				params: { sortBy: params.sortBy||'', orderBy: params.orderBy||'' },
			});
			// sessionStorage.setItem(
			// 	"technology",
			// 	JSON.stringify({
			// 		data: res.data.data,
			// 		timestamp: +new Date(),
			// 	}),
			// );
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const getTeamById = createAsyncThunk(
	//action type string
	"team/getTeam",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.get('/team');
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const insert = createAsyncThunk(
	//action type string
	"team/insert",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.post(`${TEAM}/create`, body);
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);
export const deleteById = createAsyncThunk(
	//action type string
	"team/deleteById",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.delete(`${TEAM}/`, {
				headers: { id: id },
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const updateById = createAsyncThunk(
	//action type string
	"team/updateById",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.put(`${TEAM}/`, body, {
				headers: { id: body.id },
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
