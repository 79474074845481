import React from 'react';
import DashboardLayout from '../../layouts/dashboard';

function Content() {
	return <div>from content</div>;
}

const componentConfig = {
	component: Content,
	path: '/content',
	public: false,
	group: 'document',
	layout: DashboardLayout,
	sidebar: true,
	role: ['admin'],
};

export default componentConfig;
