import * as Yup from "yup";

const salarySlipSchema = Yup.object().shape({
  type : Yup.string()
    .required("Format is required")
    .nullable(),
  month :Yup.string()
    .required("Month is required")
    .nullable(),  
  salarySlip : Yup.string()
    .required("Please provide Salary Slip")
    .nullable(),
  year :Yup.string()
    .required("Year is required")
    .nullable(),  
});

export default salarySlipSchema;