import * as Yup from "yup";

const pattern = new RegExp(
  "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
  "i"
);

const url = (value) => pattern.test(value);
var regEx = (value) =>
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    value
  );

const vmMasterSchema = Yup.object().shape({
  serverName: Yup.string()
    .trim("Server Name cannot contain only whitespace")
    .strict(true)
    .required("Server Name is required")
    .max(256, "Maximum 256 characters required")
    .nullable(),
  assetName: Yup.string()
    .required("Host PC is required")
    .trim("Host PC cannot contain only whitespace")
    .strict(true)
    .nullable()
    .nullable(),
  // status: Yup.string()
  // 	.required('Status is required')
  // 	.nullable(),
  sshPort: Yup.number()
    // .required('SSH port is required')
    .typeError("Port number can only be number")
    .nullable(),

  vdiFilePath: Yup.string()
    .trim("VDI File Path cannot contain only whitespace")
    .strict(true)
    .nullable(),

  purpose: Yup.string()
    .trim("Purpose cannot contain only whitespace")
    .strict(true)
    .nullable(),

  // users: Yup.array()
  //   .of(Yup.mixed())
  // 	.min(1, "Primary user is required")
  //   .nullable(),
  ipAddress: Yup.string()
    .required("IP address is required")
    .test("", "Invalid IP address", regEx)
    .nullable(),
  diskSize: Yup.number()
    .required("Disk size is required")
    .typeError("Disk size can only be number")
    .nullable(),
  ramSize: Yup.number()
    .required("RAM size is required")
    .typeError("RAM size can only be number")
    .nullable(),
  os: Yup.string()
    .required("OS is required")
    .trim("OS cannot contain only whitespace")
    .strict(true)
    .nullable(),

  mountPoint: Yup.string()
    .trim("Mount Point cannot contain only whitespace")
    .strict(true)
    .nullable(),

  webRoot: Yup.string()
    .trim("Web Root cannot contain only whitespace")
    .strict(true)
    .nullable(),


  phpVersion: Yup.string()	
	  .trim("PHP version cannot contain only whitespace")
	  .strict(true)
	  .nullable()
	,
  apacheVersion: Yup.string()	
	  .trim("Apache version  cannot contain only whitespace")
	  .strict(true)
	  .nullable()
	,
  iisVersion: Yup.string()
 	  .trim("IIS version cannot contain only whitespace")
	  .strict(true)
	  .nullable()
	,
  miscInformation:Yup.string()	
	  .trim("MISC info. cannot contain only whitespace")
	  .strict(true)
	  .nullable()
	,
  deletedPath: Yup.string()
    .trim("Deleted path cannot contain only whitespace")
    .strict(true)
    .nullable(),

  backupDir: Yup.string()
    .trim("Backup Directory cannot contain only whitespace")
    .strict(true)
    .nullable(),
  backupServerDetails: Yup.string()
    .trim("Backup ServerDetails cannot contain only whitespace")
    .strict(true)
    .nullable(),

  webMinUrl: Yup.string()
    // .required("url req")
    .test("", "Invalid URL", url)
    .nullable(),

  webMinUsername: Yup.string()
    .trim("WebMin Username cannot contain only whitespace")
    .strict(true)
    .nullable(),
  webMinPassword: Yup.string()
    .trim("WebMin Password cannot contain only whitespace")
    .strict(true)
    .nullable(),

  notes: Yup.string()
    .trim("Notes cannot contain only whitespace")
    .strict(true)
    .nullable(),

  isRenewable: Yup.boolean().nullable(),
  renewRange: Yup.string().when("isRenewable", {
    is: true,
    then: Yup.string().required("Renew Range is required").nullable(),
    otherwise: Yup.string().nullable(),
  }),
  startDate: Yup.date().when("isRenewable", {
    is: true,
    then: Yup.date().required("Renew Start date is required").nullable(),
    otherwise: Yup.date().nullable(),
  }),

  // .required('URL is required')
  // ,
  // webMinUrl: Yup.string().matches(re,'URL is not valid').nullable(),
  // .required('Please enter website'),
  // webMinUsername: Yup.string()
  // 	.required('Username  is required')
  // 	.nullable(),
  // webMinPassword: Yup.string()
  // 	.required('Password  is required')
  // 	.nullable(),
  // notes: Yup.string()
  // 	.required('Password  is required')
  // 	.nullable(),
  accessDetails: Yup.array().of(
  	Yup.object()
  		.shape({
  			rootUsername: Yup.string()
        .trim("Username cannot contain only whitespace")
        .strict(true)
        .nullable(),
  			password: Yup.string()
        .trim("Access Password cannot contain only whitespace")
        .strict(true)
        .nullable(),
  		})
  ),
  databaseDetails: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .trim("Database cannot contain only whitespace")
        .strict(true)
        .nullable(),
      version: Yup.string()
        .trim("Version cannot contain only whitespace")
        .strict(true)
        .nullable(),
      username: Yup.string()
        .trim("Username cannot contain only whitespace")
        .strict(true)
        .nullable(),
      password: Yup.string()
        .trim("Password cannot contain only whitespace")
        .strict(true)
        .nullable(),
    })
  ),
});

export default vmMasterSchema;
