import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import "./style.css";
import DashboardLayout from '../../../layouts/dashboard';
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
// import { CKEditor } from "@ckeditor/ckeditor5-react";
import { CKEditor } from "ckeditor4-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Modal from '@mui/material/Modal';
import parse from "html-react-parser";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton"
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import { decryption } from "../../../utils/encodeString";
import { getInterview } from "../../../redux/interview/interviewThunk";
import {
	getCandidateByStatus,
	getCandidateStatus,
} from "../../../redux/candidateState/candidateStatethunk";
import { getStudentStatus } from "../../../redux/student/studentThunk";
import {
  getAllRoles,
  getAllModule,
  getUserPermissionById,
  updatePermission,
} from "../../../redux/userPermission/userPermissionthunk";

import { getEmailTemplatesDynamic, getEmailTemplatesDynamicById, updateEmailTemplateDynamicById } from "../../../redux/emailTemplateDynamic/emailTemplateDynamicThunk";
import { addEmailTemplate } from "../../../redux/emailTemplateDynamic/emailTemplateDynamicThunk";
import { useRef } from "react";
import emailTemplateSchema from "../../../validations/emailTemplateSchema";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
	height: "80%",
	maxHeight: "80%",
	overflowY: "auto"
};

function AddEmailTemplateDynamic() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { id } = useParams();
  if (id) id = decryption(id);
	const subjectRef = useRef();
	const [loading, setLoading] = useState(true);
	const [modules, setModules] = useState("");
	const [selectedId, setSelectedId] = useState("");
	const [candidateState, setCandidateState] = useState([]);
	const [open, setOpen] = React.useState(false);
	const [openVariable, setOpenVariable] = React.useState(false);
	const [mousePos, setMousePos] = useState({});
	const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
	const handleOpenVariable = () => setOpenVariable(true);
	const handleCloseVariable = () => setOpenVariable(false);
	const label = { inputProps: { "aria-label": "Switch demo" } };
	const candidateStatusData = useSelector((state) => state.candidateState.statusData);
	const candidateStateData = useSelector((state) => state.candidateState.stateData);
	const interviewData = useSelector((state) => state.interview);
	const internStatusData = useSelector((state) => state.student);
	const [bodyFocus, setBodyFocus] = useState(false);
	const [editor, setEditor] = useState("");
	const variableData = [
		{title: 'First Name', value:'firstName'},
		{title:'Full Name', value:'fullName'},
		{title: 'Position Applied For', value: 'positionAppliedFor'},
		{title: 'HR Executive Name', value: 'hrExecutiveName'},
		{title: 'HR Designation', value:'hrDesignation'},
		{title: 'Interview Date', value:'interviewDate'},
		{title: 'Interview Technology', value:'interviewTechnology'},
		{title: 'Interview Start Time', value: 'interviewStartTime'},
		{title: 'Interview End Time', value: 'interviewEndTime'},
	];
	const [openAuto, setOpenAuto] = React.useState(true);
	useEffect(() => {
		if(id===-1){
			navigate("/emailTemplateDynamic");
		}
	}, [dispatch,id]);

	useEffect(() => {
    if (
      !interviewData.complete &&
      !interviewData.loading &&
      !interviewData.error &&
      (!interviewData.data ||
        (Array.isArray(interviewData.data) && interviewData.data.length <= 0))
    )
      dispatch(getInterview());
  });
	useEffect(() => {
		if (Array.isArray(candidateStatusData) && candidateStatusData.length <= 0)
			dispatch(getCandidateStatus());
	}, []);

	useEffect(() => {
		dispatch(getStudentStatus({sortBy:'', orderBy:''}));
	}, []);

	const formik = useFormik({
    initialValues: {
			title: "",
			subject: "",
			body: "",
			moduleId: null,
			statusId: null,
			enable: false,
			stateId: null,
			variable: "",
    },
		validationSchema: emailTemplateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!id) {
					await dispatch(addEmailTemplate(values)).unwrap();
        }

        if (id) {
          await dispatch(updateEmailTemplateDynamicById({ ...values, id: id })).unwrap();
        }
				navigate("/emailTemplateDynamic");
				resetForm();
       
      } catch (error) {
        return toast.error(error.message);
      }
    },
  });
	
	const {
		handleSubmit,
		values,
		setFieldValue,
		getFieldProps,
		isSubmitting,
		handleChange,
		setValues,
		touched,
		errors,
		handleBlur
	} = formik;

	useEffect(() => {
		// setIsLoading(true);
		if (values.statusId ?? false) {
			dispatch(getCandidateByStatus(values.statusId))
				.unwrap()
				.then((res) => {
					setCandidateState(res.data);
				})
				.catch((err) => {
					setCandidateState([]);
				});
		}
		else {
			setCandidateState([]);
		}
	}, [dispatch, values.statusId]);

	const getAllModuleData = async () => {
    try {
      const res = await dispatch(getAllModule()).unwrap();

      setModules(res.data)
    } catch (error) {
      // toast.error(error.message);
    }
  };
  useEffect(() => {
    getAllModuleData();
  }, [dispatch]);

	useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let record = await dispatch(getEmailTemplatesDynamicById(id)).unwrap();
          let body1 = {
            title: record?.title,
						subject:  record?.subject,
            statusId: record?.statusId,
            stateId: record?.stateId,
            moduleId: record?.moduleId,
            enable: record?.enable,
            body: record?.body,
            state: record?.state,
          };
          setValues(body1);
					setLoading(false);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
            navigate(`/emailTemplateDynamic`);
        }
      }
      setLoading(false);
    };
    fetchById();
  }, [dispatch, setValues, id, navigate]);

	// const handleVariableChange = (newValue) => {
	// 	setFieldValue(
	// 		"variable",
	// 		newValue?.value || "",
	// 	);
	// 	if (document.activeElement.name == "subject") {
	// 		let pos = document.activeElement.selectionStart
	// 		let str = document.activeElement.value
	// 		let output = [str.slice(0, pos), " {{"+ newValue.value + "}} ", str.slice(pos)].join('');
	// 		setValues({...values, subject: output})
	// 	}
	// 	if(bodyFocus === true)
	// 	{
	// 		editor.model.change( writer => {
	// 			writer.insertText( "{{"+ newValue.value + "}}", editor.model.document.selection.getFirstPosition() );
	// 	} );
	// 	}
	// }
	
	if (loading) {
		return <p>Loading</p>;
	}

	return (
		<Container maxWidth="xl">
			<form autoComplete="off" noValidate className="custom-space-layout" onSubmit={handleSubmit}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={3}
				>
					<div className="title-button-wrapper">
						<Typography variant="h4" gutterBottom mb={3}>
							Email Template
						</Typography>
						<Stack direction="row" alignItems="center">
							<LoadingButton
								loading={isSubmitting}
								type="submit"
								variant="contained"
							>
								Save
							</LoadingButton>
							<Button
								variant="contained"
								component={RouterLink}
								to="/emailTemplateDynamic"
								startIcon={<ArrowBackIcon />}
								style={{ marginLeft: "10px" }}
							>
								Back To List
							</Button>
						</Stack>
					</div>
				</Stack>
				<Card>
					<Container maxWidth="xl">
						<Grid
							container
							spacing={3}
							py={3}
							style={{ paddingBottom: "13px", paddingTop: "17px" }}
						>
								<Grid item xs={10}>
									<TextField
										label="Template Title"
										variant="outlined"
										size="small"
										// value={values.title}
										onChange={handleChange}
										autoComplete="off"
										inputProps={{ maxLength: 64 }}
										{...getFieldProps("title")}
										fullWidth
										error={Boolean(touched?.title && errors?.title)}
										helperText={touched?.title && errors?.title}
									/>
								</Grid>
								
								{/* <Grid item xs={4}>
									<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										options={modules || []}
										getOptionLabel={(option) => option.moduleName || ""}
										onChange={(event, newValue) => {
											setFieldValue(`moduleId`, newValue?.id || "");
										}}
										value={modules && modules?.find(
											(m) =>
												m.id ==
												values.moduleId || ""
										) }
										renderInput={(params) => (
											<TextField
												{...params}
												label="Module"
												autoComplete="off"
												error={Boolean(touched?.moduleId && errors?.moduleId)}
												helperText={touched?.moduleId && errors?.moduleId}
											/>
										)}
									/>
								</Grid> */}
								
								{/* {modules && modules?.map((module, idx) => {
									return (
										module.moduleName == "Interview" && module.id === values.moduleId ?
									<Grid item xs={4}>
										{/* <Autocomplete
											labelId="demo-simple-select-helper-label"
											id="demo-simple-select-helper"
											size="small"
											name="Interview Status"
											options={interviewData.data || []}
											getOptionLabel={(option) => option.title || ""}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          `statusId`,
                          newValue?.id || ""
                        );
											}}
											value={interviewData.data.find(
                        (interview) =>
                          interview.id ==
                          values.statusId
                      )}
											
											renderInput={(params) => (
												<TextField
													{...params}
													label="Status"
													autoComplete="off"
													error={Boolean(touched?.statusId && errors?.statusId)}
													helperText={touched?.statusId && errors?.statusId}
												/>
											)}
										/> */}
										{/* <Autocomplete
											size="small"
											fullWidth
											options={interviewData.data || []}
											getOptionLabel={(option) => option?.title || ""}
											{...getFieldProps("stateId")}
											onChange={(event, newValue) => {
												setFieldValue(
													"stateId",
													newValue?.id || "",
												);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.stateId &&
															errors?.stateId,
													)}
													helperText={
														touched?.stateId &&
														errors?.stateId
													}
													label="State"
												/>
											)}
											value={interviewData?.data?.find(
												(status) =>
													status?.id === values.stateId,
											)}
											error={true}
										/>
									</Grid>
									 : "")
								} )} 

								{modules && modules?.map((module, idx) => {
									return (
										module.moduleName == "Candidates" && module.id === values.moduleId ?
								<>
									<Grid item xs={4}>
										<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
										<Autocomplete
											size="small"
											fullWidth
											options={candidateStatusData || []}
											getOptionLabel={(option) => option?.name || ""}
											{...getFieldProps("statusId")}
											onChange={(event, newValue) => {
												setFieldValue(
													"statusId",
													newValue?.id || "",
												);
												setFieldValue("stateId", null);
												setSelectedId(newValue?.id ?? "");
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.statusId &&
															errors?.statusId,
													)}
													helperText={
														touched?.statusId &&
														errors?.statusId
													}
													label="Status"
												/>
											)}
											value={candidateStatusData.find(
												(status) =>
													status.id === values.statusId,
											)}
											error={true}
										/>
									</FormControl>
								</Grid>
	
								<Grid item xs={4}>
									<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
										<Autocomplete
											size="small"
											fullWidth
											options={candidateStateData || []}
											getOptionLabel={(option) => option?.name || ""}
											{...getFieldProps("stateId")}
											onChange={(event, newValue) => {
												setFieldValue(
													"stateId",
													newValue?.id || "",
												);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.stateId &&
															errors?.stateId,
													)}
													helperText={
														touched?.stateId &&
														errors?.stateId
													}
													label="State"
												/>
											)}
											value={candidateStateData?.find(
												(state) =>
													state?.id === values.stateId,
											)}
											error={true}
										/>
										
									</FormControl>
									</Grid>
									</>
								: "")
							} )} 
						
							{modules && modules?.map((module, idx) => {
									return (
										module.moduleName == "Interns" && module.id === values.moduleId ?
								<>
									<Grid item xs={4}>
										<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
										<Autocomplete
											size="small"
											fullWidth
											options={internStatusData.data || []}
											getOptionLabel={(option) => option.status || ""}
											{...getFieldProps("statusId")}
											onChange={(event, newValue) => {
												setFieldValue(
													"statusId",
													newValue?.id || "",
												);
												setFieldValue("statusId", null);
												setSelectedId(newValue?.id ?? "");
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.statusId &&
															errors?.statusId,
													)}
													helperText={
														touched?.statusId &&
														errors?.statusId
													}
													label="Status"
												/>
											)}
											value={internStatusData.data.find(
												(status) =>
													status.id === values.statusId,
											)}
											error={true}
										/>
									</FormControl>
								</Grid>
									</>
								: "")
							} )}  */} 
							<Grid item xs={10}>
								<TextField
									label="Email Subject"
									variant="outlined"
									size="small"
									ref={subjectRef}
									// value={values.subject}
									onChange={handleChange}
									autoComplete="off"
									inputProps={{ maxLength: 256 }}
									{...getFieldProps("subject")}
									fullWidth
									error={Boolean(touched?.subject && errors?.subject)}
									helperText={touched?.subject && errors?.subject}
								/>
							</Grid>
							<Grid item xs={2}></Grid>
							<Grid item xs={1}>
								<FormGroup>
									<FormControlLabel
										control={
											<Switch
												checked={values.enable}
												{...getFieldProps("enable")}
												{...label}
											/>
										}
										label="Enable"
									/>
								</FormGroup>
							</Grid>
							<Grid item xs={11}></Grid>
							
							<Grid item xs={10}>
								<FormHelperText sx={{ fontSize: "14px", marginBottom:"5px" }}>
									Email Body:
								</FormHelperText>
								<CKEditor
									editor={ ClassicEditor }
									// style={{minHeight: "1000px"}}
									title="body"
									data={values?.body}
									initData={values?.body ? values.body : ""}
									name="body"
									onPluginsLoaded={(args) => {
										const editor = args?.editor;
										// console.log("onPluginsLoaded", args, editor);
										editor?.ui?.addRichCombo("my-combo", {
											label: "Insert Variable",
											title: "Insert Variable",
											toolbar: "basicstyles,0",
											panel: {
												// css: [ CKEDITOR.skin.getPath( 'editor' ) ].concat( config.contentsCss ),
												css: [
													"https://cdn.ckeditor.com/4.16.2/standard-all/skins/moono-lisa/editor.css?t=L7C8",
													"https://cdn.ckeditor.com/4.4.0/standard/contents.css?t=E3OD",
													"./styles.css"
												],
												multiSelect: false,
												attributes: { "aria-label": "Insert Variable" }
											},
											init: function () {
												this?.startGroup("Insert Variable");
												this?.add("{{firstName}}", "First Name");
												this?.add("{{fullName}}", "Full Name");
												this?.add("{{positionAppliedFor}}", "Position Applied For");
												this?.add("{{hrExecutiveName}}", "HR Executive Name");
												this?.add("{{hrDesignation}}", "HR Designation");
												this?.add("{{interviewDate}}", "Interview Date");
												this?.add("{{interviewTechnology}}", "Interview Technology");
												this?.add("{{interviewStartTime}}", "Interview Start Time");
												this?.add("{{interviewEndTime}}", "Interview End Time");
												
											},
											onClick: function (value) {
												editor?.focus();
												editor?.fire("saveSnapshot");
												editor?.insertHtml(value);
												editor?.fire("saveSnapshot");
											}
										});
									}}
									// onBlur={handleBlur}
									onChange={(event) => {
										setFieldValue("body", event?.editor?.getData());
									}}
								/>
								{Boolean(touched.body && errors.body) && (
									<FormHelperText
										style={{ margin: "5px 0 0 0" }}
										// error
										data-cy="txt-content-err-job-description"
									>
										{touched.body && errors.body}
									</FormHelperText>
								)}
							</Grid>
								{/* <Grid item xs={4}>
									<FormHelperText sx={{ fontSize: "14px", marginLeft:"5px", marginBottom:"5px" }}>
											Insert Variable:
										</FormHelperText>
									<Autocomplete
										size="small"
										fullWidth
										style={{position:"relative", listStyle:"none"}}
										open={openAuto}
										onOpen={() => setOpenAuto(true)}
										options={variableData || []}
										getOptionLabel={(option) => option.title || ""}
										onChange={(event, newValue) => handleVariableChange(newValue)}
										renderInput={(params) => (
											<TextField
												{...params}
												// label="Type to Select variable"
											/>
										)}
										value={variableData.find(
											(v) =>
												v.title === values.variable,
										)}
									/>
								</Grid> */}
							</Grid>
							<Stack
								direction="row"
								alignItems="center"
								justifyContent="flex-start"
								mb={3}
								mt={3}
							>
								<Button
									onClick={handleOpen}
									variant="contained"
								>
									Preview
								</Button>
							<Modal
								open={open}
								onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Stack sx={style}>
									<b>Body Content</b>
									{ values.body ? parse(values.body) : "No content in body"}

									<Button variant="contained" onClick={handleClose} style={{width:"10px", marginTop:"5px"}}>
										Close
									</Button>
								</Stack>

							</Modal>
						</Stack>
					</Container>
				</Card>
			</form>
		</Container>
	);
}

const componentConfig = [
  {
    component: AddEmailTemplateDynamic,
    path: "/emailTemplateDynamic/add",
    public: false,
    layout: DashboardLayout,
    group: "users",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddEmailTemplateDynamic,
    path: "/emailTemplateDynamic/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "users",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
