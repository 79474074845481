import * as Yup from "yup";

const questionSchema = Yup.object().shape({
  title: Yup.string()
    .required("Title is required")
    .trim("Title cannot contain only whitespace")
    .strict(true)
    .nullable(),
  answerType: Yup.string().required("Answer Type is required").nullable(),
  sectionId: Yup.string().required("Section is required").nullable(),
});

export default questionSchema;