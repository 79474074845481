import { createAsyncThunk } from "@reduxjs/toolkit";
import { Axios } from "../../../service/axios";
import { DASHBOARD_INTERVIEW_LIST } from "../../../constants/dashboard";

export const getDashboardInterviewList = createAsyncThunk(
	//action type string
	"dashboardInterviewList/getDashboardInterviewList",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.get(DASHBOARD_INTERVIEW_LIST, params);
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
