import { createSlice } from '@reduxjs/toolkit';
import { getTeam, getTeamById,getTeamDropdown } from './teamThunk';

let data = [];

const initialState = {
	data,
	loading: false,
  sortBy: 'id',
  orderBy: 'DESC',
};

let team = sessionStorage.getItem('team');

if (team) {
	try {
		let json = JSON.parse(team);
		if (+new Date() - json.timestamp < 1000 * 60 * 60) {
			data = json.data;
		}
	} catch (e) {}
}

// A slice for getBloodGroup with our 3 reducers
export const teamSlice = createSlice({
	name: 'team',
	initialState,
	reducers: {
		setSortBy: (state, action) => {
			state.sortBy = action.payload.sortBy;
			state.status = "idle";
		},
		setOrderBy: (state, action) => {
			state.orderBy = action.payload.orderBy;
			state.status = "idle";
		},
		setLimit: (state, action) => {
			state.limit = action.payload.limit;
			state.status = "idle";
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload.page;
			state.status = "idle";
		},
	},
	extraReducers: {
		[getTeam.pending]: (state) => {
			state.loading = true;
		},
		[getTeam.fulfilled]: (state, { payload }) => {
			state.loading = false;
			// console.log("Payload",payload)
			console.log(payload)
			state.data = payload;
		},
		[getTeam.rejected]: (state, payload) => {
			state.loading = false;
			state.data = [];
			state.error = payload;
		},
		// [getTeamById.pending]: (state) => {
		// 	state.loading = true;
		// },
		// [getTeamById.fulfilled]: (state, { payload }) => {
		// 	state.loading = false;
		// 	state.dataById = payload;
		// },
		// [getTeamById.rejected]: (state, payload) => {
		// 	state.loading = false;
		// 	state.dataById = [];
		// 	state.error = payload;
		// },
		[getTeamDropdown.pending]: (state) => {
			state.loading = true;
		},
		[getTeamDropdown.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.teamDropdown = payload;
		},
		[getTeamDropdown.rejected]: (state, payload) => {
			state.loading = false;
			state.teamDropdown = [];
			state.error = payload;
		},
	},
});

// The reducer
export const teamReducer = teamSlice.reducer;
export const { setLimit, setCurrentPage, setSortBy, setOrderBy } = teamSlice.actions;
