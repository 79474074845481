import DashboardLayout from "../../layouts/dashboard";
import Index from "./ItemAssetMapping/Grid";

const ItemAssetMapping = () => {
  return <Index></Index>;
};

const componentConfig = {
  component: ItemAssetMapping,
  path: "/item-asset-mapping",
  public: false,
  layout: DashboardLayout,
  group: "Asset Management",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
