import React, { useEffect } from "react";
import DashboardLayout from "../../layouts/dashboard";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { getViewVisible } from "../../utils/userPermission";
import Editor from "./NotebookComponents/Editor";
import FolderList from "./NotebookComponents/FolderList";

function Notebook() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getViewVisible("folder")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Container className="folder-wrapper" maxWidth="xl">
      {/* <Editor /> */}
      <FolderList />
    </Container>
  );
}

const componentConfig = {
  component: Notebook,
  path: "/notebook",
  public: false,
  layout: DashboardLayout,
  group: "notebook",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
