import * as Yup from "yup";

const companySchema = Yup.object().shape({
    name: Yup.string()
    .trim("Company name cannot contain only whitespace")
    .strict(true)
    .max(512, "Company name is too long").required("Company name is required"),
    // noOfEmployees: "",
    // technologies: Yup.string().required("Experience is required"),
    noOfEmployees: Yup.string()
        .required("Number of employee is required")
        .nullable(),
    website: Yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            "Enter correct url!"
        )
        .max(1032, "Website is too long")
        .required("Please enter website")
        .nullable(),
    contactNumber: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
        .min(10, "Contact number must be 10 digits")
        .max(10, "Contact number must be 10 digits")
        .required("Contact number is required")
        .nullable(),
    contactEmail: Yup.string()
        .email("Must be a valid email")
        .max(512)
        .required("Email is required")
        .nullable(),
    area: Yup.string().required("Area is required").nullable(),
    city: Yup.string().required("City is required").nullable(),
    postalCode: Yup.string()
        .length(6)
        .matches(/^[0-9]{6}/, "Please enter valid postalcode.")
        .required("Postal code is required")
        .nullable(),
    state: Yup.string().required("State is required").nullable(),
    country: Yup.string("Country is required").nullable(),
    streetLine1: Yup.string()
    .trim("Street line1 cannot contain only whitespace")
    .strict(true)
    .max(512, "Street line1 is too long 10 digits").nullable(),
    streetLine2: Yup.string()
    .trim("Street line2 cannot contain only whitespace")
    .strict(true)
    .max(512, "Street line2 is too long 10 digits").nullable(),
    notes: Yup.string()
    .trim("Notes cannot contain only whitespace")
    .strict(true)
    .max(1032, "Notes is too long 10 digits").nullable()
});

export default companySchema;
