import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import DashboardLayout from "../../../../layouts/dashboard";
import Loader from "../../../../components/Loader";
import UserMoreActions from "../../EmployeeComponents/UserMoreActions";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Badge from "@mui/material/Badge";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { FaSortAlphaDown, FaSortAlphaDownAlt } from "react-icons/fa";
import { getDesignation } from "../../../../redux/designation/designationThunk";
import { getDepartment } from "../../../../redux/department/departmentThunk";
import { getEmployee } from "../../../../redux/exEmployee/exEmployeeThunk";
import {
  setPage,
  setHasMore,
  setData,
  setOrderBy,
  setSortBy,
} from "../../../../redux/exEmployee/exEmployeeSlice";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import { getExEmployeeCount } from "../../../../redux/exEmployee/exEmployeeThunk";
import { BASE_URL } from "../../../../constants";
import { encryption } from "../../../../utils/encodeString";
import { Paper } from "@mui/material";
import { indianDateFormat } from "../../../../utils/dateFormat";
import invertDirection from "../../../../utils/invertDirection";
import {
  exportEmployeeFile,
  getAllPM,
  getEmployeeForDropDown,
  getEmployeeTechnology,
} from "../../../../redux/employee/employeeThunk";
import { yearFormat } from "../../../../utils/dateFormat";

import { getCityData } from "../../../../utils/getLocationData";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getTeamDropdown } from "../../../../redux/team/teamThunk";
import { getViewVisible } from "../../../../utils/userPermission";
import { getHoliday } from "../../../../redux/holiday/holidayThunk";
import { getCollege } from "../../../../redux/college/collegeThunk";

function ExEmployee() {
  const observer = useRef();
  const dispatch = useDispatch();
  const AuthRole = useSelector((state) => state.auth.role);
  const inputRef = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const params = location.state;
  const data = useSelector((state) => state.exEmployee.data);
  const limit = useSelector((state) => state.exEmployee.limit);
  const page = useSelector((state) => state.exEmployee.page);
  const status = useSelector((state) => state.exEmployee.status);
  const error = useSelector((state) => state.exEmployee.error);
  const loading = useSelector((state) => state.exEmployee.loading);
  const hasMore = useSelector((state) => state.exEmployee.hasMore);
  const isSearchQuery = useSelector((state) => state.exEmployee.isSearchQuery);
  const totalCount = useSelector((state) => state.exEmployee.totalCount);
  const holidayData = useSelector((state) => state.holiday.data);
  const sortByHoliday = useSelector((state) => state.holiday.sortBy);
  const orderByHoliday = useSelector((state) => state.holiday.orderBy);
  //designation & department

  const designation = useSelector((state) => state.designation);
  const department = useSelector((state) => state.department);
  const [isSearching, setIsSearching] = useState(false);
  const [scrollApiCall, setScrollApiCall] = useState(false);
  const [filter, setFilter] = useState(false);
  // sidebar-part functionality
  const [state, setState] = React.useState({
    right: false,
  });
  const [filterData, setFilterData] = useState({});
  const [filterChips, setFilterChips] = useState([]);
  const sortBy = useSelector((state) => state.exEmployee.sortBy);
  const orderBy = useSelector((state) => state.exEmployee.orderBy);
  const [searchText, setSerchText] = useState("");
  const totalExEmployeeCount = useSelector(
    (state) => state.exEmployeeCount.totalCount
  );
  const teamData = useSelector((state) => state.team.teamDropdown);
  const sortByTeam = useSelector((state) => state.team.sortBy);
  const orderByTeam = useSelector((state) => state.team.orderBy);
  const [employeeData, setEmployeeData] = useState([]);
  const [technologyForIcon, setTechnologyForIcon] = useState([]);
  const [allPMData, setAllPMData] = useState([]);
  const [cities, setCities] = useState([]);
  const locationDropdown = [
    { title: "HOME", id: "HOME" },
    { title: "OFFICE", id: "OFFICE" },
    { title: "HYBRID", id: "HYBRID" },
  ];
  const genderDropdown = [
    { title: "MALE", id: "M" },
    { title: "FEMALE", id: "F" },
    { title: "OTHER", id: "O" },
  ];
  const college = useSelector((state) => state.college);
  const technologyData = useSelector((state) => state.technology);
  const appraisalCycleData = ["July", "By Production Date"];
  const sortByData = [
    { title: "Ascending(By Name)", value: "asc" },
    { title: "Descending(By Name)", value: "desc" },
    { title: "By Joining Date", value: "joining" },
  ];
  const commitmentDuration = ["0", "1", "1.6"];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // const lastBookElementRef = useCallback(
  //   (node) => {
  //     console.log("PAge________________________01", page)
  //     if (!isSearching) {
  //       console.log("In searching___________________________")
  //       if (loading) return;
  //       if (observer.current) observer.current.disconnect();

  //       observer.current = new IntersectionObserver((entries) => {
  //         if (entries[0].isIntersecting && hasMore) {
  //           setScrollApiCall(true);
  //           dispatch(setPage({ page: page + 1 }));
  //         }
  //       });
  //       console.log("PAge________________________01",page)
  //       if (node) observer.current.observe(node);
  //     }
  //   },
  //   [loading, hasMore]
  // );

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setScrollApiCall(true);
          dispatch(setPage({ page: page + 1 }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        if (!getViewVisible("employee")) {
          await dispatch(
            getEmployee({
              limit,
              page,
              scrollApiCall,
              filter,
              ...filterData,
              orderBy: values?.sort === "asc" || values?.sort === "desc" ? values?.sort : orderBy,
              sortBy: values?.sort === "asc" || values?.sort === "desc" ? "firstName" : sortBy,
            })
          ).unwrap(); // Ensure you handle the promise correctly

          navigate("/dashboard");
        }
      } catch (error) {
        console.error("Failed to fetch employee data:", error);
        // Handle the error appropriately
      }
    };

    fetchEmployeeData();
  }, []);


  useEffect(() => {
    if (getViewVisible("holiday")) {
      dispatch(
        getHoliday({
          sortByHoliday,
          orderByHoliday,
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem("employeeData");
        if (storedData) {
          setEmployeeData(JSON.parse(storedData));
        } else {
          const res = await dispatch(
            getEmployeeForDropDown({ status: "employee" })
          );
          setEmployeeData(res?.payload?.data);
          localStorage.setItem(
            "employeeData",
            JSON.stringify(res?.payload?.data)
          );
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const fetchPMData = async () => {
      try {
        const res = await dispatch(getAllPM({})).unwrap();
        setAllPMData(res?.projectManager || []);
      } catch (error) {
        console.error("Failed to fetch project managers:", error);
        // Handle the error appropriately
      }
    };

    fetchPMData();
  }, [dispatch]);

  useEffect(() => {
    getCityData("Gujarat")
      .then((cities) => {
        setCities(cities);
        // setpermanentCities(cities);
      })
      .catch((err) => {
        setCities([]);
      });
  }, [dispatch]);

  useEffect(() => {
    // if (status === "idle" && !error) {
    // dispatch(getEmployee({ limit, page: page + 1 }));
    dispatch(
      getEmployee({
        limit,
        // search: searchText,
        page: page,
        scrollApiCall: scrollApiCall,
        filter: filter,
        ...filterData,
        orderBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? values?.sort
            : orderBy,
        sortBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? "firstName"
            : sortBy,
      })
    );
    // }
  }, [page, params]);

  useEffect(() => {
    // if (status === "idle" && !error) {
    // dispatch(getEmployee({ limit, page: page + 1 }));
    dispatch(
      getEmployee({
        limit,
        // search: searchText,
        page: 1,
        scrollApiCall: scrollApiCall,
        filter: filter,
        ...filterData,
        orderBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? values?.sort
            : orderBy,
        sortBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? "firstName"
            : sortBy,
      })
    );
    // }
  }, [sortBy, orderBy]);

  useEffect(() => {
    // if (getViewVisible("team")) {
    dispatch(
      getTeamDropdown({
        sortByTeam,
        orderByTeam,
      })
    );
    // }
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setScrollApiCall(false);
      dispatch(setHasMore(true));
      dispatch(setPage({ page: 1 }));
      dispatch(setData([]));
    };
  }, []);

  useEffect(() => {
    if (!error) {
      dispatch(getExEmployeeCount({}));
    }
    dispatch(getCollege({ search: "" }));
  }, [error, dispatch]);

  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      designationId: "",
      departmentId: "",
      search: "",
      teamId: "",
      teamLead: "",
      workLocation: null,
      city: "",
      gender: "",
      underPF: null,
      isUnderServiceAgreement: null,
      note: "",
      technologyId: "",
      underESIC: null,
      appraisalCycle: "",
      retentionBonus: "",
      collegeYear: "",
      projectManager: "",
      commitmentperiod: "",
      sort: "",
      collegeId: "",
      joiningYear:"",
    },
    onSubmit: async (values) => {
      try {
        // setFilter(true);
        // setFilterData({ ...values });
        // await dispatch(
        //   getEmployee({
        //     ...values,
        //     limit,
        //     page: 1,
        //     // search: searchText,
        //     scrollApiCall: scrollApiCall,
        //     filter: true,
        //     newData: true,
        //     orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : orderBy,
        //     sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : sortBy,
        //   })
        // );
        // setState({ ...state, right: false });
        // prepareChips(values);
        if (
          values.search === "" &&
          values.designationId === "" &&
          values.departmentId === "" &&
          values.teamId === "" &&
          values.teamLead === "" &&
          values.workLocation === null &&
          values.city === "" &&
          values.gender === "" &&
          values.underPF === null &&
          values.isUnderServiceAgreement === null &&
          values.technologyId === "" &&
          values.underESIC === null &&
          values.appraisalCycle === "" &&
          values.retentionBonus === "" &&
          values.collegeYear === "" &&
          values.commitmentperiod === "" &&
          values.projectManager === "" &&
          values.collegeId === "" &&
           values.joiningYear === ""
        ) {
          setFilter(false);
        } else {
          setFilter(true);
        }
        setFilterData({ ...values });
        await dispatch(
          getEmployee({
            ...values,
            limit,
            page: 1,
            scrollApiCall: scrollApiCall,
            filter:
              values.search === "" &&
              values.designationId === "" &&
              values.departmentId === "" &&
              values.teamId === "" &&
              values.teamLead === "" &&
              values.workLocation === null &&
              values.city === "" &&
              values.gender === "" &&
              values.underPF === null &&
              values.isUnderServiceAgreement === null &&
              values.technologyId === "" &&
              values.underESIC === null &&
              values.appraisalCycle === "" &&
              values.retentionBonus === "" &&
              values.collegeId === "" &&
              values.collegeYear === "" &&
              values.projectManager === "" &&
              values.joiningYear === "" &&
              values.commitmentperiod === ""
                ? false
                : true,
            newData: true,
            orderBy:
              values?.sort == "asc" || values?.sort == "desc"
                ? values?.sort
                : orderBy,
            sortBy:
              values?.sort == "asc" || values?.sort == "desc"
                ? "firstName"
                : sortBy,
          })
        );
        setState({ ...state, right: false });
        // console.log(values);
        prepareChips(values);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const setTotalExpTime = (data) => {
    let experience = {
      totalExpYears: 0,
      totalExpMonths: 0,
      totalCCCYears: 0,
      totalCCCMonths: 0,
    };

    let experienceArr = data?.experience;
    let cccExperienceArr = data?.workDetails;
    let totalYears = experience?.totalExpYears;
    let totalMonths = experience?.totalExpMonths;
    let tempCCCYears = experience?.totalCCCYears;
    let tempCCCMonths = experience?.totalCCCMonths;
    console.log("cccExperienceArr", cccExperienceArr);
    console.log("experienceArr", experienceArr);

    if (experienceArr?.length > 0) {
      for (let i = 0; i < experienceArr.length; i++) {
        let start = moment(experienceArr[i].from);
        let end = moment(
          experienceArr[i].to ? experienceArr[i].to : new Date()
        );
        let cccstart = start;
        let cccend = end;

        let floored = cccend.diff(cccstart, "years");
        cccstart.add(floored, "years");

        let finalValue = cccend.diff(cccstart, "months");
        cccstart.add(finalValue, "months");

        let days = cccend.diff(cccstart, "days");
        let dateFrom = moment(experienceArr[i].from);
        let dateTo = moment(experienceArr[i].to);

        let dateProducation = moment(cccExperienceArr?.productionDate);
        let dateReliving = moment(cccExperienceArr?.relievingDate);

        if (experienceArr[i]?.company?.name === "Cybercom Creation") {
          if (
            dateFrom.isSame(dateProducation, "month") &&
            dateFrom.isSame(dateProducation, "year") &&
            dateTo.isSame(dateReliving, "month") &&
            dateTo.isSame(dateReliving, "year")
          ) {
            tempCCCYears = tempCCCYears;
            tempCCCMonths = tempCCCMonths;
          } else {
            tempCCCYears = tempCCCYears + floored;
            tempCCCMonths = tempCCCMonths + finalValue;
          }

          if (tempCCCMonths >= 12) {
            tempCCCMonths = tempCCCMonths - 12;
            tempCCCYears = tempCCCYears + 1;
          }
        } else {
          totalYears = totalYears + floored;
          totalMonths = totalMonths + finalValue;
          if (totalMonths >= 12) {
            totalMonths = totalMonths - 12;
            totalYears = totalYears + 1;
          }
        }
      }

      // console.log("tempCCCYears", tempCCCYears, "tempCCCMonths", tempCCCMonths);
      // console.log("totalYears", totalYears, "totalMonths", totalMonths);

      if (
        moment(new Date()).diff(
          moment(cccExperienceArr?.productionDate),
          "days"
        ) > 0
      ) {
        let cccstart = moment(new Date(cccExperienceArr?.productionDate));
        let cccend = moment(
          cccExperienceArr?.relievingDate
            ? new Date(cccExperienceArr?.relievingDate)
            : new Date()
        );

        let cccyearCount = cccend.diff(cccstart, "years");
        cccstart.add(cccyearCount, "years");

        let cccmonthsCount = cccend.diff(cccstart, "months");
        cccstart.add(cccmonthsCount, "months");

        tempCCCYears = tempCCCYears + cccyearCount;
        tempCCCMonths = tempCCCMonths + cccmonthsCount;
      }

      let overallYears = totalYears + tempCCCYears;
      let overallMonths = totalMonths + tempCCCMonths;
      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      if (totalMonths >= 12) {
        totalMonths = totalMonths - 12;
        totalYears = totalYears + 1;
      }

      if (tempCCCMonths >= 12) {
        tempCCCMonths = tempCCCMonths - 12;
        tempCCCYears = tempCCCYears + 1;
      }

      experience = {
        ...experience,
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      };
    } else {
      if (
        moment(new Date()).diff(
          moment(cccExperienceArr?.productionDate),
          "days"
        ) > 0
      ) {
        let cccstart = moment(cccExperienceArr?.productionDate);
        let cccend = moment(
          cccExperienceArr?.relievingDate
            ? cccExperienceArr?.relievingDate
            : new Date()
        );

        let cccyearCount = cccend.diff(cccstart, "years");
        cccstart.add(cccyearCount, "years");

        let cccmonthsCount = cccend.diff(cccstart, "months");
        cccstart.add(cccmonthsCount, "months");

        tempCCCYears = tempCCCYears + cccyearCount;
        tempCCCMonths = tempCCCMonths + cccmonthsCount;
      }

      let overallYears = totalYears + tempCCCYears;
      let overallMonths = totalMonths + tempCCCMonths;

      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      if (totalMonths >= 12) {
        totalMonths = totalMonths - 12;
        totalYears = totalYears + 1;
      }

      if (tempCCCMonths >= 12) {
        tempCCCMonths = tempCCCMonths - 12;
        tempCCCYears = tempCCCYears + 1;
      }

      experience = {
        ...experience,
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      };
    }
    let exp_str = "";
    if (experience?.totalCCCYears && experience?.totalCCCMonths) {
      exp_str =
        exp_str +
        `${experience?.totalCCCYears}.${experience?.totalCCCMonths} Yrs `;
    }
    if (!experience?.totalCCCYears && experience?.totalCCCMonths) {
      exp_str = exp_str + `0.${experience?.totalCCCMonths} Yrs `;
    }
    if (experience?.totalCCCYears && !experience?.totalCCCMonths) {
      exp_str = exp_str + `${experience?.totalCCCYears} Yrs `;
    }
    if (!experience?.totalCCCYears && !experience?.totalCCCMonths) {
      exp_str = exp_str + `0.0 Yrs `;
    }
    exp_str = exp_str + '<div class="total-experience">';
    exp_str = exp_str + " ";
    if (experience?.overallYears && experience?.overallMonths) {
      exp_str =
        exp_str +
        `${experience?.overallYears}.${experience?.overallMonths} Yrs`;
    }
    if (!experience?.overallYears && experience?.overallMonths) {
      exp_str = exp_str + `0.${experience?.overallMonths} Yrs`;
    }
    if (experience?.overallYears && !experience?.overallMonths) {
      exp_str = exp_str + `${experience?.overallYears} Yrs`;
    }
    if (!experience.overallYears && !experience.overallMonths) {
      exp_str = exp_str + `0.0 Yrs`;
    }

    exp_str = exp_str + " ";
    exp_str = exp_str + "</div>";

    // return exp_str
    return <div dangerouslySetInnerHTML={{ __html: exp_str }} />;
  };

  const prepareChips = (values) => {
    let filters = { ...values };
    let tempChips = [];

    for (const key in filters) {
      if (filters[key] && key === "designationId") {
        const res = designation.data.find(
          (designation) => designation.id === filters[key]
        );
        if (res) filters[key] = `Designation : ${res.title}`;
      }

      if (filters[key] && key === "departmentId") {
        const res = department.data.find(
          (department) => department.id === filters[key]
        );
        if (res) filters[key] = `Department : ${res.title}`;
      }

      if (filters[key] && key === "teamId") {
        const res = teamData.find((team) => team.id === filters[key]);
        if (res) filters[key] = `Team : ${res.title}`;
      }
      if (filters[key] && key === "teamLead") {
        const res = employeeData?.find((team) => team.id === filters[key]);
        if (res) filters[key] = `Team Lead : ${res.firstName} ${res.lastName}`;
      }
      if (filters[key] && key === "projectManager") {
        const res = allPMData?.find((team) => team.id === filters[key]);
        if (res)
          filters[key] = `Project Manager : ${res.firstName} ${res.lastName}`;
      }

      if (filters[key] && key === "collegeId") {
        //console.log(filters[key]);
        const res = college.data.find((college) => college.id === filters[key]);
        if (res) filters[key] = `College : ${res?.collegeName}`;
      }
      if (filters[key] && key === "city") {
        const res = cities?.find((c) => c === filters[key]);
        if (res) filters[key] = `City : ${res}`;
      }

      if (filters[key] && key === "workLocation") {
        const res = locationDropdown?.find((w) => w.id === filters[key]);
        if (res) filters[key] = `Work Location : ${res.title}`;
      }

      if (filters[key] && key === "gender") {
        const res = genderDropdown.find((w) => w.id === filters[key]);
        if (res) filters[key] = `Gender : ${res.title}`;
      }

      if (filters[key] && key === "technologyId") {
        const res = technologyData.data.find((t) => t.id === filters[key]);
        if (res) filters[key] = `Technology : ${res.title}`;
      }
      if (filters[key] && key === "retentionBonus") {
        // console.log(filters[key]);
        filters[key] =
          filters[key] === 1 ? `Retention Bonus : Yes` : `Retention Bonus : No`;
      }

      // let d = new Date(values.collegeYear).getFullYear()
      if (filters[key] && key === "collegeYear") {
        filters[key] = `College Year : ${values.collegeYear}`;
      }

      if (filters[key] && key === "search") {
        filters[key] = `${values.search}`;
      }
      if (filters[key] && key === "appraisalCycle") {
        const res = appraisalCycleData.find((w) => w === filters[key]);
        if (res) filters[key] = `Appraisal Cycle : ${res}`;
      }
      if (filters[key] && key === "commitmentperiod") {
        const res = commitmentDuration.find((w) => w === filters[key]);
        if (res) filters[key] = `Commitment Period : ${res}`;
      }

      if (filters[key] && key === "isUnderServiceAgreement") {
        filters[key] =
          filters[key] === 1
            ? `Under Service Agreement : Yes`
            : `Under Service Agreement : No`;
      }

      if (filters[key] && key === "underPF") {
        filters[key] = filters[key] === 1 ? `Under PF : Yes` : `Under PF : No`;
      }

      if (filters[key] && key === "underESIC") {
        // console.log(filters[key]);
        filters[key] =
          filters[key] === 1 ? `Under ESIC : Yes` : `Under ESIC : No`;
      }

      if (filters[key] && key === "sort") {
        const res = sortByData?.find((t) => t.value === filters[key]);
        if (res) filters[key] = `Sort : ${res.title}`;
      }
      if (filters[key] && key === "joiningYear") {
        filters[key] = `Joining Year : ${values.joiningYear}`;
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

    setFilterChips(tempChips);
  };

  // const onDeleteChip = (value, length) => {
  //   let field = value?.type
  //   setFieldValue(field, "")
  //   setFilterChips([
  //     ...filterChips.filter((val) => val.filter !== value.filter),
  //   ]);
  //   setFieldValue(value.type, "");
  //   let filters = { ...filterData, [value["type"]]: "" };

  //   setFilterData(filters);
  //   if (length == 1) {
  //     dispatch(
  //       getEmployee({
  //         limit,
  //         // search: searchText,
  //         page: 1,
  //         newData: true,
  //         scrollApiCall: scrollApiCall,
  //         orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : orderBy,
  //         sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : sortBy,
  //       })
  //     );
  //   } else {
  //     dispatch(
  //       getEmployee({
  //         limit,
  //         page: 1,
  //         newData: true,
  //         // search: searchText,
  //         scrollApiCall: scrollApiCall,
  //         filter: filter,
  //         ...filters,
  //         orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : orderBy,
  //         sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : sortBy,
  //       })
  //     );
  //   }
  //   if (value.type === "search") {
  //     // setSearchValue("");
  //     inputRef.current.value = "";
  //   }
  // };
  const onDeleteChip = (value, length) => {
    let field = value?.type;
    setFieldValue(field, "");
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    setFieldValue(value.type, "");
    let filters = { ...filterData, [value["type"]]: "" };
    setFilterData(filters);
    if (length == 1) {
      dispatch(
        getEmployee({
          limit,
          page: 1,
          newData: true,
          scrollApiCall: scrollApiCall,
          // orderBy,
          // sortBy: sortBy,
          orderBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? values?.sort
              : orderBy,
          sortBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? "firstName"
              : sortBy,
        })
      );
    } else {
      dispatch(
        getEmployee({
          limit,
          page: 1,
          newData: true,
          scrollApiCall: scrollApiCall,
          filter: filter,
          ...filters,
          // orderBy,
          // sortBy: sortBy,
          orderBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? values?.sort
              : orderBy,
          sortBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? "firstName"
              : sortBy,
        })
      );
    }
    if (value.type === "search") {
      // setSearchValue("");
      inputRef.current.value = "";
    }
  };

  //fetching designation
  useEffect(() => {
    if (
      !designation.loading &&
      !designation.error &&
      (!designation.data ||
        (Array.isArray(designation.data) && designation.data.length <= 0))
    )
      dispatch(getDesignation());
  }, [designation, dispatch]);
  //fetching department
  useEffect(() => {
    getViewVisible("department") && dispatch(getDepartment());
    // dispatch(getDepartment());
  }, [dispatch]);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleReset = () => {
    resetForm();
    setTechnologyForIcon([]);

    dispatch(
      getEmployee({
        limit,
        // search: searchText,
        page: 1,
        scrollApiCall: scrollApiCall,
        filter: false,
        newData: true,
        orderBy,
        sortBy: sortBy,
      })
    );
    setState({ ...state, right: false });
    setFilter(false);
    setFilterData({});
    setFilterChips([]);
  };

  useEffect(() => {
    const fetchEmployeeTechnology = async () => {
      if (data && data.length > 0) {
        try {
          let idArray = data.map((emp) => emp.id);
          const res = await dispatch(getEmployeeTechnology(idArray)).unwrap();
          
          if (res?.success && Array.isArray(res?.data)) {
            setTechnologyForIcon(res.data);
          }
        } catch (error) {
          console.error("Failed to fetch employee technology:", error);
          // Handle error appropriately
        }
      }
    };

    fetchEmployeeTechnology();
  }, [data]);

  const handleSearch = async (e) => {
    setFieldValue("search", e?.target?.value || "");
    handleSubmit();
    setIsSearching(true);

    // setSerchText(e?.target?.value || "");
    // await dispatch(
    //   getEmployee({
    //     search: e?.target?.value,
    //     ...filterData,
    //     limit,
    //     page: 1,
    //     newData: true,
    //     orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : orderBy,
    //     sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : sortBy,
    //   })
    // );
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const exportFile = async () => {
    try {
      const response = await dispatch(
        exportEmployeeFile({ status: "ex-employee" })
      )
        .unwrap()
        .then((response) => {
          const url = `${BASE_URL}/${response.data}`;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleFilters = async (field, newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
    setIsSearching(true);
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
    } catch (err) {
      toast.error("Text Not Copied!");
    }

    document.body.removeChild(textArea);
  };

  const countExpTech = (id) => {
    if (
      technologyForIcon &&
      Array.isArray(technologyForIcon) &&
      technologyForIcon.length > 0
    ) {
      let EmpExp = technologyForIcon?.find((emp) => emp.id === id);
      if (EmpExp) return setTotalExpTime(EmpExp);
    }
  };

  return (
    <Container className="Employee-wrapper" maxWidth="xl">
      <Stack
        className="Employee-wrapper-detail"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Ex-Employees ({totalExEmployeeCount})
            {/* <Typography variant="body2">
            Total Records : {totalEmployeeCount}
          </Typography> */}
          </Typography>

          <Stack
            className="employee-header-right"
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
          >
            <Button
              className="back-button"
              component={RouterLink}
              to="/employee"
              variant="contained"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            {AuthRole === "Super Admin" &&
            Array.isArray(data) &&
            data.length !== 0 ? (
              <Button
                variant="contained"
                onClick={exportFile}
                // startIcon={<FileUploadSharpIcon />}
                className="filter-icon-part"
                title="Export Ex-Employees"
              >
                <img src="/assets/images/export.svg" />
                Export
              </Button>
            ) : (
              ""
            )}
          </Stack>
        </div>
      </Stack>
      <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div className="employee-classification-right">
                <TextField
                  className="employee-search-detail"
                  fullWidth
                  label="Search by Name/Email/Phone Number"
                  name="title"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />

                <div className="employee-classification-dropdown">
                  <Autocomplete
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    size="small"
                    defaultValue=""
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={employeeData || []}
                    getOptionLabel={(option) =>
                      option ? `${option?.firstName} ${option?.lastName}` : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    // {...getFieldProps(`workDetails.teamLead`)}
                    onChange={(event, newValue) => {
                      handleFilters("teamLead", newValue?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Lead" />
                    )}
                    value={
                      employeeData?.find(
                        (lead) => lead.id == values.teamLead
                      ) || ""
                    }
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={teamData || []}
                    getOptionLabel={(option) => option.title || ""}
                    // name="teamId"
                    onChange={(event, newValue) => {
                      handleFilters("teamId", newValue?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Team" />
                    )}
                    value={teamData?.find((t) => t.id === values.teamId) || ""}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    defaultValue=""
                    options={technologyData?.data || []}
                    getOptionLabel={(option) => option?.title || ""}
                    onChange={(event, newValue) => {
                      handleFilters("technologyId", newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Technology" />
                    )}
                    value={
                      technologyData?.data?.find(
                        (t) => t.id == values?.technologyId
                      ) || ""
                    }
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={locationDropdown || []}
                    getOptionLabel={(option) => option.title || ""}
                    name="teamId"
                    onChange={(event, newValue) => {
                      handleFilters("workLocation", newValue?.id);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Work Location" />
                    )}
                    value={
                      locationDropdown?.find(
                        (t) => t.id === values.workLocation
                      ) || ""
                    }
                  />
                </div>
                <div className="employee-classification-dropdown employee-classification-DatePicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="College Year"
                      value={values?.collegeYear || null}
                      views={["year"]}
                      maxDate={new Date()}
                      inputFormat="yyyy"
                      // minDate={new Date('2017-01-01')}
                      onChange={(newValue) => {
                        handleFilters("collegeYear", yearFormat(newValue));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="collegeYear"
                          inputProps={{
                            ...params.inputProps,
                            disabled: true, // Add the disabled attribute to the input element
                          }}
                          error={false}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="employee-classification-dropdown employee-classification-toggle">
                  {/* <Switch label="Under SA" /> */}
                  <FormControlLabel
                    className="classification-toggle-form"
                    control={
                      <Switch
                        checked={values.isUnderServiceAgreement || null}
                        onChange={(e, v) => {
                          if (v == true) {
                            handleFilters("isUnderServiceAgreement", 1);
                          } else {
                            handleFilters("isUnderServiceAgreement", 0);
                          }
                        }}
                        name="isUnderServiceAgreement"
                        inputProps={{ "data-cy": "chk-hasMany-item" }}
                        size="small"
                      />
                    }
                    label="SA"
                  />
                </div>

                {values.isUnderServiceAgreement == true ? (
                  <div className="employee-classification-dropdown employee-classification-Commitment">
                    <Autocomplete
                      className="employee-Commitment-wrapper"
                      popupIcon={
                        <img
                          src="/assets/images/arrow-down.svg"
                          alt="dropdown"
                        />
                      }
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      onChange={(event, newValue) => {
                        handleFilters("commitmentperiod", newValue);
                      }}
                      value={values?.commitmentperiod || ""}
                      options={commitmentDuration || []}
                      renderInput={(params) => (
                        <TextField {...params} label="Commitment" />
                      )}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="employee-classification-dropdown employee-classification-toggle">
                  {/* <Switch label="Under SA" /> */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values?.retentionBonus || null}
                        onChange={(e, v) => {
                          if (v == true) {
                            handleFilters("retentionBonus", 1);
                          } else {
                            handleFilters("retentionBonus", 0);
                          }
                        }}
                        name="retentionBonus"
                        inputProps={{ "data-cy": "chk-hasMany-item" }}
                        size="small"
                      />
                    }
                    label="Retention"
                  />
                </div>
                <div className="employee-classification-dropdown employee-classification-toggle">
                  {/* <Switch label="Under SA" /> */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values?.underESIC || null}
                        onChange={(e, v) => {
                          if (v == true) {
                            handleFilters("underESIC", 1);
                          } else {
                            handleFilters("underESIC", 0);
                          }
                        }}
                        name="underESIC"
                        inputProps={{ "data-cy": "chk-hasMany-item" }}
                        size="small"
                      />
                    }
                    label="ESIC"
                  />
                </div>

                <div className="employee-classification-dropdown employee-classification-toggle">
                  {/* <Switch label="Under SA" /> */}
                  <FormControlLabel
                    control={
                      <Switch
                        checked={values?.underPF || null}
                        onChange={(e, v) => {
                          if (v == true) {
                            handleFilters("underPF", 1);
                          } else {
                            handleFilters("underPF", 0);
                          }
                        }}
                        name="underPF"
                        inputProps={{ "data-cy": "chk-hasMany-item" }}
                        size="small"
                      />
                    }
                    label="PF"
                  />
                </div>
                <div className="employee-classification-dropdown employee-classification-Filter">
                  {["right"].map((anchor, idx) => (
                    <React.Fragment key={idx}>
                      <span
                        className="filter-icon-part FilterIcon"
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <IconButton>
                          {/* <Badge
                                color="error"
                                variant="dot"
                                invisible={filterChips.length ? false : true}
                              > */}
                          {/* <FilterAltIcon /> */}
                          <img src="/assets/images/filter.svg" />
                          {/* </Badge> */}
                        </IconButton>
                      </span>

                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        style={{ zIndex: "1300" }}
                      >
                        <Box
                          pt={4}
                          px={3}
                          sx={{
                            width:
                              anchor === "top" || anchor === "bottom"
                                ? "auto"
                                : 400,
                          }}
                          role="presentation"
                        >
                          <div className="content-filter-part">
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              mb={3}
                            >
                              <Typography variant="h6">
                                Employee Filter
                              </Typography>
                              <span>
                                <IconButton
                                  onClick={toggleDrawer(anchor, false)}
                                >
                                  <CloseIcon />
                                </IconButton>
                              </span>
                            </Stack>

                            <form onSubmit={handleSubmit}>
                              <Grid
                                container
                                spacing={3}
                                style={{ paddingTop: "0px" }}
                              >
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "5px" }}
                                >
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    options={designation.data || []}
                                    getOptionLabel={(option) =>
                                      option.title || ""
                                    }
                                    name="designationId"
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        "designationId",
                                        newValue?.id || ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Designation"
                                      />
                                    )}
                                    value={designation?.data?.find(
                                      (t) => t.id === values.designationId
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    size="small"
                                    disabled={
                                      getViewVisible("department") === false
                                    }
                                    sx={
                                      {
                                        // minWidth: 120,
                                      }
                                    }
                                    // style={{paddingTop:"0px"}}
                                    fullWidth
                                    options={department.data || []}
                                    getOptionLabel={(option) =>
                                      option.title || ""
                                    }
                                    name="departmentId"
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        "departmentId",
                                        newValue?.id || ""
                                      );
                                      // handleOnDepartmentChange(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Department"
                                      />
                                    )}
                                    value={department?.data?.find(
                                      (t) => t.id === values.departmentId
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    size="small"
                                    defaultValue=""
                                    fullWidth
                                    options={allPMData || []}
                                    getOptionLabel={(option) =>
                                      option
                                        ? `${option?.firstName} ${option?.lastName}`
                                        : ""
                                    }
                                    renderOption={(props, option) => (
                                      <li
                                        {...props}
                                        value={option.id}
                                        key={option.id}
                                      >
                                        {option.firstName +
                                          " " +
                                          option.lastName || ""}
                                      </li>
                                    )}
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        `projectManager`,
                                        newValue?.id || ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Project Manager"
                                      />
                                    )}
                                    value={
                                      allPMData?.find(
                                        (lead) =>
                                          lead.id == values.projectManager
                                      ) || ""
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    size="small"
                                    popupIcon={
                                      <img
                                        src="/assets/images/arrow-down.svg"
                                        alt="dropdown"
                                      />
                                    }
                                    disabled={
                                      getViewVisible("college") === false
                                    }
                                    fullWidth
                                    options={college.data || []}
                                    getOptionLabel={(option) =>
                                      option?.collegeName || ""
                                    }
                                    name="collegeId"
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        "collegeId",
                                        newValue?.id || ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        autoComplete="off"
                                        label="College"
                                      />
                                    )}
                                    value={
                                      college?.data?.find(
                                        (t) => t.id === values.collegeId
                                      ) || ""
                                    }
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    size="small"
                                    name="city"
                                    defaultValue=""
                                    options={cities || []}
                                    value={values.city}
                                    onChange={(event, newValue) => {
                                      setFieldValue(`city`, newValue || "");
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="City" />
                                    )}
                                  />
                                </Grid>

                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    size="small"
                                    fullWidth
                                    options={genderDropdown || []}
                                    getOptionLabel={(option) =>
                                      option.title || ""
                                    }
                                    name="gender"
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        "gender",
                                        newValue?.id || ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Gender" />
                                    )}
                                    value={genderDropdown?.find(
                                      (t) => t.id === values.gender
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    size="small"
                                    name="appraisalCycle"
                                    options={appraisalCycleData || []}
                                    value={values?.appraisalCycle}
                                    onChange={(event, newValue) => {
                                      setFieldValue(
                                        `appraisalCycle`,
                                        newValue || ""
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        label="Appraisal Cycle"
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ paddingTop: "10px" }}
                                >
                                  <Autocomplete
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    size="small"
                                    options={sortByData || []}
                                    name="sort"
                                    getOptionLabel={(option) =>
                                      option?.title || ""
                                    }
                                    value={
                                      sortByData?.find(
                                        (s) => s.value == values.sort
                                      ) || ""
                                    }
                                    onChange={(event, newValue) => {
                                      // setsort(newValue?.value)
                                      setFieldValue("sort", newValue?.value);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} label="Sort By" />
                                    )}
                                  />
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DesktopDatePicker
                                        label="Joining Year"
                                        value={values?.joiningYear || null}
                                        views={["year"]}
                                        maxDate={new Date()}
                                        inputFormat="yyyy"
                                        // minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                          setFieldValue('joiningYear',yearFormat(newValue))
                                      }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="joiningYear"
                                            inputProps={{
                                              ...params.inputProps,
                                              disabled: true, // Add the disabled attribute to the input element
                                            }}
                                            error={false}
                                            fullWidth
                                            size="small"
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                              </Grid>

                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                mb={3}
                                mt={3}
                              >
                                <LoadingButton
                                  type="submit"
                                  variant="contained"
                                  loading={isSubmitting}
                                  startIcon={<SearchIcon />}
                                >
                                  Search
                                </LoadingButton>
                                <Button
                                  variant="contained"
                                  type="reset"
                                  onClick={handleReset}
                                  startIcon={<CachedIcon />}
                                  sx={{ marginLeft: "10px" }}
                                >
                                  Reset
                                </Button>
                              </Stack>
                            </form>
                          </div>
                        </Box>
                      </Drawer>
                    </React.Fragment>
                  ))}
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack className="search-filter" direction="row" alignItems="start">
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="search-filter-detail"
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {isSearchQuery ? (
        <Typography variant="body2" mb={1}>
          {totalCount > 0 ? `${totalCount} records found` : ""}
        </Typography>
      ) : (
        ""
      )}
      <Grid
        container
        spacing={1}
        style={{
          display: "grid",
          // marginTop: "-50px",
        }}
        className="employee-grid"
      >
        {(Array.isArray(data) && data.length === 0 && !loading) ||
        (status === "rejected" && isSearchQuery) ? (
          <Typography
            className="No-Record-text"
            align="justify"
            variant="inherit"
            m={2}
            gutterBottom
          >
            No Record(s) Found
          </Typography>
        ) : (
          data.map((employee, idx) => {
            return (
              <Grid key={idx} ref={lastBookElementRef}>
                <div
                  className="view_employee candidate-details"
                  style={{
                    padding: "0",
                    maxWidth: "100%",
                    width: "100%",
                    height: "300px",
                    maxHeight: "300px",
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid
                      container
                      spacing={2}
                      className="grid_container"
                      style={{ marginBottom: "0px" }}
                    >
                      <Grid
                        className="view_employee_grid-item"
                        item
                        xs={12}
                        style={{ maxWidth: "100%", flexBasis: "100%" }}
                      >
                        <Card className="information-card card_height">
                          <Paper
                            sx={{
                              textDecoration: "none",
                              color: "#212B36",
                            }}
                            onClick={(e) =>
                              window.open(
                                `/employee/view/${encryption(employee.id)}`,
                                "_blank"
                              )
                            }
                          >
                            <Container
                              className="personal-details-section"
                              maxWidth="xl"
                              style={{
                                backgroundColor: "#F8F9FA",
                                padding: "16px",
                              }}
                            >
                              <div className="Special-Day-div">
                                {new Date(
                                  employee?.workDetails?.productionDate
                                ).getDate() == new Date().getDate() &&
                                new Date(
                                  employee?.workDetails?.productionDate
                                ).getMonth() == new Date().getMonth() ? (
                                  <p className="Work-Anniversary-tab">
                                    {`${ordinal_suffix_of(
                                      new Date().getFullYear() -
                                        new Date(
                                          employee?.workDetails?.productionDate
                                        ).getFullYear()
                                    )} Work Anniversary`}
                                  </p>
                                ) : (
                                  ""
                                )}

                                {new Date(employee?.dateOfBirth).getDate() ==
                                  new Date().getDate() &&
                                new Date(employee?.dateOfBirth).getMonth() ==
                                  new Date().getMonth() ? (
                                  <p className="Birthday-tab">Birthday</p>
                                ) : (
                                  ""
                                )}
                              </div>

                              <Stack
                                direction="row"
                                justifyContent="space-between"
                                mb={3}
                                style={{ marginBottom: "0px" }}
                              >
                                <div
                                  className="personal-image-section"
                                  style={{
                                    width: "-webkit-fill-available",
                                    maxWidth: "50%",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={4}
                                    style={{
                                      maxWidth: "100%",
                                      flexBasis: "100%",
                                      marginRight: "0px",
                                      textAlign: "-webkit-center",
                                    }}
                                  >
                                    <div className="personal-left-detail-content">
                                      <Avatar
                                        sx={{
                                          width: "90px",
                                          height: "90px",
                                          fontSize: "2.5rem",
                                          marginTop: "0px",
                                          marginLeft: "0px",
                                          marginBottom: "10px",
                                        }}
                                        src={
                                          employee?.employeeImage &&
                                          `${BASE_URL}/${employee?.employeeImage}`
                                        }
                                        alt={employee.firstName}
                                      ></Avatar>
                                      {/* <Typography align="center"> */}
                                      <h4
                                        className="employeeName"
                                        align="center"
                                      >{`${employee.firstName} ${employee.lastName}`}</h4>

                                      <p className="job-position-txt">
                                        {
                                          employee?.workDetails?.designation
                                            ?.title
                                        }
                                      </p>

                                      <p className="job-position-txt">
                                        {employee?.employeeId}
                                      </p>
                                      <Stack
                                        className="employee-contact"
                                        direction="row"
                                        justifyContent="center"
                                        flexWrap="wrap"
                                        sx={{
                                          gridGap: "3px",
                                          fontSize: ".8rem",
                                        }}
                                        // mt={1}
                                        mb={1}
                                      >
                                        <Link
                                          href={`tel: ${employee?.contact?.contactNumber}`}
                                          color="text.secondary"
                                        >
                                          {employee?.contact?.contactNumber}
                                        </Link>
                                        {employee?.contact
                                          ?.alternateContactNumber && (
                                          <>
                                            <span>|</span>
                                            <Link
                                              href={`tel: ${employee?.contact?.alternateContactNumber}`}
                                              color="text.secondary"
                                            >
                                              {
                                                employee?.contact
                                                  ?.alternateContactNumber
                                              }
                                            </Link>
                                          </>
                                        )}
                                      </Stack>
                                    </div>
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      justifyContent="center"
                                      style={{ marginLeft: "0px" }}
                                      sx={{ gridGap: "8px" }}
                                    >
                                      {employee?.contact?.companySkype ? (
                                        <Box
                                          component="img"
                                          src="/assets/images/skype-graybox.svg"
                                          className="skype"
                                          title="Skype"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(
                                              `skype:${employee?.contact?.companySkype}?chat`,
                                              "_blank"
                                            );
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {employee?.contact?.linkedIn ? (
                                        <Box
                                          component="img"
                                          src="/assets/images/linkedin-graybox.svg"
                                          className="linkdin"
                                          title="LinkedIn"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(
                                              employee?.contact?.linkedIn,
                                              "_blank"
                                            );
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {employee?.github ? (
                                        <Box
                                          component="img"
                                          src="/assets/images/copylink-graybox.svg"
                                          className="download-img"
                                          title="Open github"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(
                                              employee?.github,
                                              "_blank"
                                            );
                                          }}
                                        />
                                      ) : employee?.portfolioWebsite ? (
                                        <Box
                                          component="img"
                                          src="/assets/images/copylink-graybox.svg"
                                          className="mail-img"
                                          title="Open Portfolio"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            window.open(
                                              employee?.portfolioWebsite,
                                              "_blank"
                                            );
                                          }}
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {employee?.employeeDocuments
                                        ?.employeeResume &&
                                        employee?.employeeDocuments
                                          ?.employeeResume?.length > 0 && (
                                          <Box
                                            component="img"
                                            src="/assets/images/download-graybox.svg"
                                            className="copylink"
                                            title="Open Resume"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              window.open(
                                                employee?.employeeDocuments
                                                  ?.employeeResume &&
                                                  employee?.employeeDocuments
                                                    ?.employeeResume?.length >
                                                    0 &&
                                                  employee?.employeeDocuments
                                                    ?.employeeResume[0]
                                                    .webViewLink,
                                                "_blank"
                                              );
                                            }}
                                          />
                                        )}

                                      {employee?.emails &&
                                        employee?.emails?.map((email, index) =>
                                          email?.type == "Company Email" ? (
                                            <Tooltip
                                              title={email?.email}
                                              placement="bottom"
                                            >
                                              <Box
                                                component="img"
                                                src="/assets/images/mail-graybox.svg"
                                                className="edit"
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  ClicktoCopy(email?.email);
                                                }}
                                              />
                                            </Tooltip>
                                          ) : (
                                            ""
                                          )
                                        )}
                                      {/* {commitment &&
                              commitment?.map((c, idx) =>
                                c?.id == employee?.id && c?.commit == true ? (
                                  <Tooltip
                                    title="Under Commitment"
                                    placement="bottom"
                                  >
                                    <Box
                                      component="img"
                                      src="/assets/images/commitment.jpg"
                                      text="Under Commitment"
                                      // onClick={(e) => {
                                      // 	e.stopPropagation();
                                      // }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )
                              )} */}

                                      <IconButton
                                        style={{ padding: "0px" }}
                                        title="Edit employee"
                                        onClick={() =>
                                          window.open(
                                            `/employee/add/${encryption(
                                              employee.id
                                            )}`,
                                            "_blank"
                                          )
                                        }
                                      >
                                        <img src="/assets/images/edit-graybox.svg" />
                                        {/* <EditIcon color="primary" /> */}
                                      </IconButton>
                                    </Stack>
                                  </Grid>
                                </div>

                                <Grid
                                  className="personal-content-section"
                                  item
                                  xs={8}
                                >
                                  <Card
                                    style={{ padding: "16px" }}
                                    className="address-card information-card"
                                  >
                                    {/* <Container> */}
                                    {/* <TabContext value={value}> */}
                                    <Grid
                                      className="address-card-container"
                                      container
                                      spacing={2}
                                    >
                                      <Grid
                                        className="address-card-detail"
                                        item
                                        xs={10}
                                        style={{
                                          maxWidth: "100%",
                                          flexBasis: "100%",
                                        }}
                                      >
                                        <div className="address-blk">
                                          <div className="address-sub-section general_sec">
                                            <div className="address-div">
                                              <span>Joining</span>
                                              <p>
                                                {employee?.workDetails
                                                  ?.joiningDate != null
                                                  ? indianDateFormat(
                                                      employee?.workDetails
                                                        ?.joiningDate
                                                    )
                                                  : "-"}
                                              </p>
                                            </div>
                                            <div className="address-div">
                                              <span>Production</span>
                                              <p>
                                                {employee?.workDetails
                                                  ?.productionDate != null
                                                  ? indianDateFormat(
                                                      employee?.workDetails
                                                        ?.productionDate
                                                    )
                                                  : "-"}
                                              </p>
                                            </div>
                                            {/* <div className="address-div">
                                            <span>Commitment</span>
                                            <p>{employee?.workDetails?.commitmentStartDate != null 
                                              ? indianDateFormat(employee?.workDetails?.commitmentStartDate) 
                                              : "-" }
                                            </p>
                                          </div> */}
                                            {employee?.workDetails
                                              ?.relievingDate ? (
                                              //  && employee?.workDetails?.reJoiningDate == null ?
                                              <div className="address-div">
                                                <span style={{ color: "red" }}>
                                                  Relieving
                                                </span>
                                                <p style={{ color: "red" }}>
                                                  {employee?.workDetails
                                                    ?.relievingDate != null
                                                    ? indianDateFormat(
                                                        employee?.workDetails
                                                          ?.relievingDate
                                                      )
                                                    : "-"}
                                                </p>
                                              </div>
                                            ) : (
                                              //   <div className="address-div">
                                              //   <span>Rejoining</span>
                                              //   <p>{employee?.workDetails?.reJoiningDate != null
                                              //     ? indianDateFormat(employee?.workDetails?.reJoiningDate)
                                              //     : "-" }
                                              //   </p>
                                              // </div>
                                              ""
                                            )}
                                            <div className="address-div">
                                              <span>Lead</span>
                                              <p>
                                                {employee?.workDetails
                                                  ?.teamLeadName
                                                  ? `${employee?.workDetails?.teamLeadName?.firstName} ${employee?.workDetails?.teamLeadName?.lastName}`
                                                  : "-"}
                                              </p>
                                            </div>
                                            <div className="address-div work-experience-detail">
                                              <span>Exp.</span>
                                              <p className="work-experience">
                                                {countExpTech(employee?.id) ||
                                                  ""}
                                              </p>
                                            </div>
                                            {/* {employee?.workDetails?.productionDate != null ?  */}
                                          </div>
                                        </div>
                                        {/* <div style={{position:"relative"}}>
                                              <p style={{position: "absolute",bottom:"0",right:"0",}}>Bet 5 days ago</p>
                                        </div> */}
                                      </Grid>
                                    </Grid>
                                    {/* </TabContext> */}
                                    {/* </Container> */}
                                  </Card>

                                  <div className="technology-details">
                                    {technologyForIcon &&
                                    Array.isArray(technologyForIcon) &&
                                    technologyForIcon.length > 0
                                      ? technologyForIcon.map((exp) => {
                                          if (exp.id === employee?.id) {
                                            // && exp.workDetails?.length > 0) {
                                            //   return exp.workDetails.map((t) => {
                                            if (
                                              exp?.workDetails.technologies
                                                ?.length > 0
                                            ) {
                                              return exp?.workDetails?.technologies.map(
                                                (tech) => (
                                                  <img
                                                    key={tech?.technology?.id}
                                                    className="technology-icons"
                                                    src={
                                                      tech?.technology?.icon
                                                        ? `${BASE_URL}/${tech?.technology?.icon}`
                                                        : "/assets/images/technology-icon.svg"
                                                    }
                                                    title={
                                                      tech?.technology?.title
                                                    }
                                                  />
                                                )
                                              );
                                            } else {
                                              return null;
                                            }
                                            // });
                                          } else {
                                            return null;
                                          }
                                        })
                                      : null}
                                  </div>
                                </Grid>
                              </Stack>
                            </Container>
                          </Paper>
                        </Card>
                      </Grid>
                    </Grid>
                  </Box>
                  {loading && <Loader />}
                </div>
              </Grid>
            );
          })
        )}

        {/* {loading && <Loader />} */}
      </Grid>
    </Container>
    // <Container className="Ex-Employees-listing-wrapper" maxWidth="xl">
    //   <Stack
    //     className="Ex-Employees-details"
    //     direction="row"
    //     alignItems="center"
    //     justifyContent="space-between"
    //     mb={3}
    //   >
    //    <div className="title-button-wrapper">
    //     <Typography variant="h4" gutterBottom>
    //       Ex-Employees ({totalExEmployeeCount})
    //       {/* <Typography variant="body2">
    //         Total Records : {totalExEmployeeCount}
    //       </Typography>{" "} */}
    //     </Typography>

    //     <Stack
    //       direction="row"
    //       alignItems="center"
    //       justifyContent="space-between"
    //       className="employee-header-right"
    //     >
    //       <IconButton
    //         className="filter-icon-part ascending-order-icon"
    //         sx={{ backgroundColor: "#fda92d" }}
    //         onClick={handleSorting}
    //       >
    //         {/* <SortByAlphaIcon/> */}
    //         {orderBy == "desc" ? <FaSortAlphaDownAlt /> : <FaSortAlphaDown />}
    //       </IconButton>
    //       {["right"].map((anchor) => (
    //         <React.Fragment key={anchor}>
    //           <span
    //             className="filter-icon-part"
    //             onClick={toggleDrawer(anchor, true)}
    //           >
    //             <IconButton>
    //               <Badge
    //                 color="error"
    //                 variant="dot"
    //                 invisible={filterChips.length ? false : true}
    //               >
    //                 <img src="/assets/images/filter.svg" />
    //                 {/* <FilterAltIcon /> */}
    //               </Badge>
    //             </IconButton>
    //           </span>

    //           <Drawer
    //             anchor={anchor}
    //             open={state[anchor]}
    //             onClose={toggleDrawer(anchor, false)}
    //             style={{ zIndex: "1300" }}
    //           >
    //             <Box
    //               pt={4}
    //               px={3}
    //               sx={{
    //                 width:
    //                   anchor === "top" || anchor === "bottom" ? "auto" : 400,
    //               }}
    //               role="presentation"
    //             >
    //               <div className="content-filter-part">
    //                 <Stack
    //                   direction="row"
    //                   alignItems="center"
    //                   justifyContent="space-between"
    //                   mb={3}
    //                 >
    //                   <Typography variant="h6">Ex Employee Filter</Typography>
    //                   <span>
    //                     <IconButton onClick={toggleDrawer(anchor, false)}>
    //                       <CloseIcon />
    //                     </IconButton>
    //                   </span>
    //                 </Stack>

    //                 <form onSubmit={handleSubmit}>
    //                   <Grid container spacing={3}>
    //                     {/*<Grid item xs={12}>
    //                                                 <TextField
    // 																									fullWidth
    // 																									label="Search by Name/Email/PhoneNumber"
    // 																									name="search"
    // 																									value={values.search}
    // 																									onChange={handleChange}
    // 																									variant="outlined"
    // 																									size="small"
    //                                                 />
    //                                             </Grid>*/}
    //                     {/* <Grid item xs={12}>
    // 																							<LocalizationProvider
    // 																								dateAdapter={AdapterDateFns}
    // 																							>
    // 																								<DesktopDatePicker
    // 																									label="Start Date"
    // 																									// value={values.startDate}
    // 																									// minDate={new Date('2017-01-01')}
    // 																									// onChange={(newValue) => {
    // 																									//   formik.setFieldValue("startDate", newValue);
    // 																									// }}
    // 																									renderInput={(params) => (
    // 																										<TextField
    // 																											{...params}
    // 																											fullWidth
    // 																											size="small"
    // 																										/>
    // 																									)}
    // 																								/>
    // 																							</LocalizationProvider>
    // 																						</Grid> */}
    //                     {/* <Grid item xs={12}>
    // 																							<LocalizationProvider
    // 																								dateAdapter={AdapterDateFns}
    // 																							>
    // 																								<DesktopDatePicker
    // 																									label="End Date"
    // 																									// value={formik.values.endDate}
    // 																									// minDate={new Date('2017-01-01')}
    // 																									// onChange={(newValue) => {
    // 																									//   formik.setFieldValue("endDate", newValue);
    // 																									// }}
    // 																									renderInput={(params) => (
    // 																										<TextField
    // 																											{...params}
    // 																											fullWidth
    // 																											size="small"
    // 																										/>
    // 																									)}
    // 																								/>
    // 																							</LocalizationProvider>
    // 																						</Grid> */}
    //                     <Grid item xs={12}>
    //                       {/* <FormControl
    // 												size="small"
    // 												sx={{ minWidth: 120 }}
    // 												fullWidth
    // 											>
    // 												<InputLabel id="demo-simple-select-helper-label">
    // 													Designation
    // 												</InputLabel>
    // 												<Select
    // 													labelId="demo-simple-select-helper-label"
    // 													id="demo-simple-select-helper"
    // 													label="Designation"
    // 													size="small"
    // 													name="designationId"
    // 													value={values.designationId}
    // 													onChange={handleChange}
    // 												>
    // 													<MenuItem value="">None</MenuItem>
    // 													{Array.isArray(designation.data) &&
    // 														designation.data.map(({ id, title }, idx) => (
    // 															<MenuItem key={idx} value={id}>
    // 																{title}
    // 															</MenuItem>
    // 														))}
    // 												</Select>
    // 											</FormControl> */}
    //                       <Autocomplete
    //                         sx={{
    //                           minWidth: 120,
    //                         }}
    //                         size="small"
    //                         fullWidth
    //                         options={designation.data || []}
    //                         getOptionLabel={(option) => option.title || ""}
    //                         name="designationId"
    //                         onChange={(event, newValue) => {
    //                           setFieldValue(
    //                             "designationId",
    //                             newValue?.id || ""
    //                           );
    //                         }}
    //                         renderInput={(params) => (
    //                           <TextField {...params} label="Designation" />
    //                         )}
    //                         value={designation?.data?.find(
    //                           (t) => t.id === values.designationId
    //                         )}
    //                       />
    //                     </Grid>
    //                     <Grid item xs={12}>
    //                       <Autocomplete
    //                         size="small"
    //                         disabled={getViewVisible("department") === false}
    //                         sx={{
    //                           mb: 3,
    //                           minWidth: 120,
    //                         }}
    //                         fullWidth
    //                         options={department.data || []}
    //                         getOptionLabel={(option) => option.title || ""}
    //                         name="departmentId"
    //                         onChange={(event, newValue) => {
    //                           setFieldValue("departmentId", newValue?.id || "");
    //                         }}
    //                         renderInput={(params) => (
    //                           <TextField {...params} label="Department" />
    //                         )}
    //                         value={department?.data?.find(
    //                           (t) => t.id === values.departmentId
    //                         )}
    //                       />
    //                       {/* <FormControl
    // 												size="small"
    // 												name="technology"
    // 												sx={{
    // 													mb: 3,
    // 													minWidth: 120,
    // 												}}
    // 												fullWidth
    // 												// value={values.technology}
    // 												// onChange={handleChange}
    // 											>
    // 												<InputLabel id="demo-simple-select-helper-label">
    // 													Department
    // 												</InputLabel>
    // 												<Select
    // 													labelId="demo-simple-select-helper-label"
    // 													id="demo-simple-select-helper"
    // 													label="Department"
    // 													size="small"
    // 													name="departmentId"
    // 													value={values.departmentId}
    // 													onChange={handleChange}
    // 												>
    // 													<MenuItem value="">None</MenuItem>
    // 													{Array.isArray(department.data) &&
    // 														department.data.map(({ id, title }, idx) => (
    // 															<MenuItem key={idx} value={id}>
    // 																{title}
    // 															</MenuItem>
    // 														))}
    // 												</Select>
    // 											</FormControl> */}
    //                     </Grid>
    //                   </Grid>
    //                   <Stack direction="row" justifyContent="flex-end" mb={3}>
    //                     <LoadingButton
    //                       type="submit"
    //                       variant="contained"
    //                       loading={isSubmitting}
    //                       startIcon={<SearchIcon />}
    //                     >
    //                       Search
    //                     </LoadingButton>
    //                     <Button
    //                       variant="contained"
    //                       type="reset"
    //                       onClick={handleReset}
    //                       startIcon={<CachedIcon />}
    //                       sx={{ marginLeft: "10px" }}
    //                     >
    //                       Reset
    //                     </Button>
    //                   </Stack>
    //                 </form>
    //               </div>
    //             </Box>
    //           </Drawer>
    //         </React.Fragment>
    //       ))}
    //       {AuthRole === "Super Admin" && Array.isArray(data) && data.length !== 0 ?
    //       <Button
    //         variant="contained"
    //         onClick={exportFile}
    //         // startIcon={<FileUploadSharpIcon />}
    //         className="filter-icon-part"
    //       >
    //         <img src="/assets/images/export.svg" />
    //         Export
    //       </Button> : "" }
    //       <Button component={RouterLink} to="/employee" variant="contained" startIcon={<ArrowBackIcon />}>
    //         Back To Employees
    //       </Button>
    //     </Stack>
    //     </div>
    //   </Stack>
    //   <Card className="ex-employee-field-search employee-table-grid" pb={3}>
    //     <Container maxWidth="xl">
    //       <form onSubmit={(e) => e.preventDefault()}>
    //         <Grid className="Ex-Employees-search-form" container spacing={3}>
    //           <Grid className="employee-search search-filed-container" item xs={12} my={3}>
    //             <TextField
    //               fullWidth
    //               label="Search by Name/Email/PhoneNumber"
    //               name="title"
    //               variant="outlined"
    //               size="small"
    //               onChange={debounceWithSearch}
    //             />
    //           </Grid>
    //         </Grid>
    //       </form>
    //     </Container>
    //   </Card>
    //   <Stack
    //     // style={{ marginBottom: "10px", marginTop: "10px" }}
    //     direction="row"
    //     alignItems="start"
    //   >
    //     {filterChips.map((chips, idx) => {
    //       return (
    //         <Chip
    //           label={chips.filter}
    //           color="primary"
    //           size="small"
    //           key={chips.filter}
    //           style={{ margin:"10px 5px 10px 0" }}
    //           variant="filled"
    //           onDelete={() => onDeleteChip(chips, filterChips.length)}
    //         />
    //       );
    //     })}
    //   </Stack>
    //   {isSearchQuery ? (
    //     <Typography variant="body2" mb={1}>
    //       {totalCount} records found
    //     </Typography>
    //   ) : (
    //     ""
    //   )}
    //   <Grid
    //   className="ex-employee-detail-grid"
    //     container
    //     spacing={2}
    //     // style={{
    //     //   display: "flex",
    //     //   justifyContent: "flex-start",
    //     //   /* marginTop: "10px", */
    //     // }}
    //   >
    //     {Array.isArray(data) && data.length === 0 && !loading ? (
    //       <Typography align="justify" variant="inherit" m={2} gutterBottom>
    //         No Record(s) Found
    //       </Typography>
    //     ) : (
    //       data?.map((employee, idx) => {
    //         if (data.length === idx + 1) {
    //           return (
    //             <Grid className="ex-employee-detail-list" item key={idx} ref={lastBookElementRef}>
    //               <Card className="employee-block">
    //                 <CardHeader
    //                   className="Employee-card-header"
    //                   sx={{ paddingTop: "10px" }}
    //                   action={
    //                     <UserMoreActions
    //                       //  edit={() => window.location.replace(`/employee/add/${encryption(employee.id)}`)}
    //                       //  view={() =>window.location.replace(`/employee/view/${encryption(employee.id)}`)}
    //                       edit={() =>
    //                         window.open(
    //                           `/employee/add/${encryption(employee.id)}`,
    //                           "_blank"
    //                         )
    //                       }
    //                       view={() =>
    //                         window.open(
    //                           `/employee/view/${encryption(employee.id)}`,
    //                           "_blank"
    //                         )
    //                       }
    //                     />
    //                   }
    //                 />
    //                 <Paper
    //                 className="Employee-card-body"
    //                   // component={RouterLink}
    //                   sx={{
    //                     textDecoration: "none",
    //                     color: "#212B36",
    //                   }}
    //                   // to={`/employee/view/${encryption(employee.id)}`}
    //                   onClick={(e) =>
    //                     window.open(
    //                       `/employee/view/${encryption(employee.id)}`,
    //                       "_blank"
    //                     )
    //                   }
    //                 >
    //                   <figure className="employee-img">
    //                     <Avatar
    //                       src={
    //                         employee?.employeeImage &&
    //                         `${BASE_URL}/${employee?.employeeImage}`
    //                       }
    //                       alt={employee.firstName}
    //                       sx={{
    //                         width: 90,
    //                         height: 90,
    //                         fontSize: "2.5rem",
    //                       }}
    //                     ></Avatar>
    //                   </figure>
    //                   <CardContent
    //                     className="employeelist-CardContent"
    //                     sx={{
    //                       textAlign: "center",
    //                       paddingTop: "10px",
    //                     }}
    //                   >
    //                     <h3 className="employeeName">{`${employee.firstName} ${employee.lastName}`}</h3>
    //                     <Typography
    //                       className="designation"
    //                       variant="body2"
    //                       color="text.secondary"
    //                       mb={1.5}
    //                     >
    //                       {employee?.workDetails?.designation?.title}
    //                     </Typography>
    //                     <Stack
    //                       direction="row"
    //                       justifyContent="center"
    //                       flexWrap="wrap"
    //                       sx={{
    //                         gridGap: "5px",
    //                         fontSize: ".8rem",
    //                       }}
    //                     >
    //                       <Link
    //                         href={`tel: ${employee?.contact?.contactNumber}`}
    //                         color="text.secondary"
    //                       >
    //                         {employee?.contact?.contactNumber}
    //                       </Link>
    //                       {employee?.contact?.alternateContactNumber && (
    //                         <>
    //                           <span>|</span>
    //                           <Link
    //                             href={`tel: ${employee?.contact?.alternateContactNumber}`}
    //                             color="text.secondary"
    //                           >
    //                             {employee?.contact?.alternateContactNumber}
    //                           </Link>
    //                         </>
    //                       )}
    //                     </Stack>
    //                     <Link
    //                       className="mail-link"
    //                       href={`mailto: ${employee?.emails[0]?.email}`}
    //                       color="text.secondary"
    //                       sx={{ fontSize: ".8rem" }}
    //                     >
    //                       {employee?.emails?.length > 0 &&
    //                         employee?.emails[0]?.email}
    //                     </Link>

    //                     {employee.workDetails?.reJoiningDate &&
    //                     employee.workDetails?.reJoiningDate !== ""
    //                       ? ""
    //                       : employee.workDetails?.relievingDate &&
    //                         employee.workDetails?.relievingDate !== "" && (
    //                           <Typography
    //                             color="text.secondary"
    //                             sx={{ fontSize: ".8rem" }}
    //                           >{`${"Relieving Date"}: ${indianDateFormat(
    //                             employee?.workDetails?.relievingDate
    //                           )}`}</Typography>
    //                         )}

    //                     {Array.isArray(employee?.workDetails?.technologies) &&
    //                     employee?.workDetails?.technologies?.length > 0 ? (
    //                       <Stack
    //                         direction="row"
    //                         justifyContent="center"
    //                         flexWrap="wrap"
    //                         spacing={1}
    //                         rowGap="8px"
    //                         mb={2}
    //                         mt={2}
    //                       >
    //                         {employee?.workDetails?.technologies?.map(
    //                           (tech, index) => (
    //                             <Chip
    //                               key={index}
    //                               size="small"
    //                               sx={{
    //                                 fontWeight: "bold",
    //                               }}
    //                               label={tech?.technology?.title}
    //                             />
    //                           )
    //                         )}
    //                       </Stack>
    //                     ) : (
    //                       employee?.workDetails?.technology && (
    //                         <Stack
    //                           direction="row"
    //                           justifyContent="center"
    //                           flexWrap="wrap"
    //                           spacing={1}
    //                           rowGap="8px"
    //                           mb={2}
    //                           mt={2}
    //                         >
    //                           <Chip
    //                             size="small"
    //                             sx={{
    //                               fontWeight: "bold",
    //                             }}
    //                             label={
    //                               employee?.workDetails?.technology?.title
    //                                 ? employee?.workDetails?.technology?.title
    //                                 : "-"
    //                             }
    //                           />
    //                         </Stack>
    //                       )
    //                     )}

    //                     <Stack
    //                       direction="row"
    //                       alignItems="center"
    //                       justifyContent="center"
    //                       sx={{ gridGap: "15px" }}
    //                     >
    //                       <figure>
    //                         <a
    //                           rel="noreferrer"
    //                           target="_blank"
    //                           href={employee?.contact?.skype}
    //                         >
    //                           <img
    //                             style={{
    //                               width: "24px",
    //                             }}
    //                             alt="skype"
    //                             src="/assets/images/skype-graybox.svg"
    //                           />
    //                         </a>
    //                       </figure>
    //                       <figure>
    //                         <a
    //                           rel="noreferrer"
    //                           target="_blank"
    //                           href={employee?.contact?.linkedIn}
    //                         >
    //                           <img
    //                             style={{
    //                               width: "19px",
    //                             }}
    //                             alt="linkedin"
    //                             src="/assets/images/linkedin-graybox.svg"
    //                           />
    //                         </a>
    //                       </figure>
    //                       {employee?.github && (
    //                         <figure>
    //                           <a
    //                             rel="noreferrer"
    //                             target="_blank"
    //                             href={employee?.github}
    //                           >
    //                             <img
    //                               style={{
    //                                 width: "24px",
    //                               }}
    //                               alt="github"
    //                               src="/assets/images/github.svg"
    //                             />
    //                           </a>
    //                         </figure>
    //                       )}
    //                       {employee?.portfolioWebsite && (
    //                         <figure>
    //                           <a
    //                             rel="noreferrer"
    //                             target="_blank"
    //                             href={employee?.portfolioWebsite}
    //                           >
    //                             <img
    //                               style={{
    //                                 width: "24px",
    //                               }}
    //                               alt="portfolio"
    //                               src="/assets/images/portfolio.svg"
    //                             />
    //                           </a>
    //                         </figure>
    //                       )}
    //                       <figure>
    //                         <img
    //                           style={{
    //                             width: "24px",
    //                           }}
    //                           alt="resume"
    //                           src="/assets/images/download-graybox.svg"
    //                         />
    //                       </figure>

    //                       {employee.assetOutOfOffice && (
    //                         <figure>
    //                           {/* <a
    //                             rel="noreferrer"
    //                             target="_blank"
    //                             href={employee?.github}
    //                           > */}
    //                             <img
    //                               style={{
    //                                 width: "24px",
    //                               }}
    //                               alt="github"
    //                               src="/assets/images/asset.svg"
    //                               title="Asset"
    //                             />
    //                           {/* </a> */}
    //                         </figure>
    //                       )}
    //                     </Stack>
    //                   </CardContent>
    //                 </Paper>
    //               </Card>
    //             </Grid>
    //           );
    //         } else {
    //           return (
    //             <Grid className="ex-employee-detail-list" item key={idx}>
    //               {/* <div className="Special-Day-div">
    //                 {employee.workDetails?.reJoiningDate &&
    //                   employee.workDetails?.reJoiningDate !== ""
    //                   ? ""
    //                   : employee.workDetails?.relievingDate &&
    //                     employee.workDetails?.relievingDate !== "" && (
    //                     <Typography
    //                       color="text.secondary"
    //                       sx={{ fontSize: ".8rem" }}
    //                     >{`${"Relieving Date"}: ${indianDateFormat(
    //                       employee.workDetails?.relievingDate
    //                   )}`}</Typography>
    //                 )}
    //               </div> */}
    //               <Card className="employee-block">
    //                 <CardHeader
    //                   className="Employee-card-header"
    //                   sx={{ paddingTop: "10px" }}
    //                   action={
    //                     <UserMoreActions
    //                       // edit={`/employee/add/${encryption(employee.id)}`}
    //                       // view={`/employee/view/${encryption(employee.id)}`}
    //                       edit={() =>
    //                         window.open(
    //                           `/employee/add/${encryption(employee.id)}`,
    //                           "_blank"
    //                         )
    //                       }
    //                       view={() =>
    //                         window.open(
    //                           `/employee/view/${encryption(employee.id)}`,
    //                           "_blank"
    //                         )
    //                       }
    //                     />
    //                   }
    //                 />
    //                 <Paper
    //                   className="Employee-card-body"
    //                   sx={{
    //                     textDecoration: "none",
    //                     color: "#212B36",
    //                   }}
    //                   // to={`/employee/view/${encryption(employee.id)}`}
    //                   onClick={(e) =>
    //                     window.open(
    //                       `/employee/view/${encryption(employee.id)}`,
    //                       "_blank"
    //                     )
    //                   }
    //                 >
    //                   <figure className="employee-img">
    //                     <Avatar
    //                       src={
    //                         employee?.employeeImage &&
    //                         `${BASE_URL}/${employee?.employeeImage}`
    //                       }
    //                       alt={employee.firstName}
    //                       sx={{
    //                         width: 90,
    //                         height: 90,
    //                         fontSize: "2.5rem",
    //                       }}
    //                     ></Avatar>
    //                   </figure>
    //                   <CardContent
    //                     className="employeelist-CardContent"
    //                     sx={{
    //                       textAlign: "center",
    //                       paddingTop: "10px",
    //                     }}
    //                   >
    //                     <h3 className="employeeName">{`${employee.firstName} ${employee.lastName}`}</h3>
    //                     <Typography
    //                       className="designation"
    //                       variant="body2"
    //                       color="text.secondary"
    //                       mb={1.5}
    //                     >
    //                       {employee?.workDetails?.designation?.title}
    //                     </Typography>
    //                     <Stack
    //                       direction="row"
    //                       justifyContent="center"
    //                       flexWrap="wrap"
    //                       sx={{
    //                         gridGap: "5px",
    //                         fontSize: ".8rem",
    //                       }}
    //                     >
    //                       <Link
    //                         href={`tel: ${employee?.contact?.contactNumber}`}
    //                         color="text.secondary"
    //                       >
    //                         {employee?.contact?.contactNumber}
    //                       </Link>
    //                       {employee?.contact?.alternateContactNumber && (
    //                         <>
    //                           <span>|</span>
    //                           <Link
    //                             href={`tel: ${employee?.contact?.alternateContactNumber}`}
    //                             color="text.secondary"
    //                           >
    //                             {employee?.contact?.alternateContactNumber}
    //                           </Link>
    //                         </>
    //                       )}
    //                     </Stack>
    //                     <Link
    //                       className="mail-link"
    //                       href={`mailto: ${employee?.emails[0]?.email}`}
    //                       color="text.secondary"
    //                       sx={{ fontSize: ".8rem" }}
    //                     >
    //                       {employee?.emails?.length > 0 &&
    //                         employee?.emails[0]?.email}
    //                     </Link>

    //                     {employee.workDetails?.reJoiningDate &&
    //                     employee.workDetails?.reJoiningDate !== ""
    //                       ? ""
    //                       : employee.workDetails?.relievingDate &&
    //                         employee.workDetails?.relievingDate !== "" && (
    //                           <Typography
    //                             // color="text.secondary"
    //                             color="red"
    //                             sx={{ fontSize: ".8rem" }}
    //                           >{`${"Relieving Date"}: ${indianDateFormat(
    //                             employee?.workDetails?.relievingDate
    //                           )}`}</Typography>
    //                         )}

    //                     {Array.isArray(employee?.workDetails?.technologies) &&
    //                     employee?.workDetails?.technologies?.length > 0 ? (
    //                       <Stack
    //                         className="technology-details"
    //                         direction="row"
    //                         justifyContent="center"
    //                         flexWrap="wrap"
    //                         spacing={1}
    //                         rowGap="8px"
    //                         mb={2}
    //                         mt={2}
    //                       >
    //                         <Chip
    //                           size="small"
    //                           sx={{
    //                             fontWeight: "bold",
    //                           }}
    //                           label={
    //                             employee?.workDetails?.technologies[0]
    //                               .technology?.title
    //                           }
    //                         />
    //                       </Stack>
    //                     ) : (
    //                       employee?.workDetails?.technology && (
    //                         <Stack
    //                           direction="row"
    //                           justifyContent="center"
    //                           flexWrap="wrap"
    //                           spacing={1}
    //                           rowGap="8px"
    //                           mb={2}
    //                           mt={2}
    //                         >
    //                           <Chip
    //                             size="small"
    //                             sx={{
    //                               fontWeight: "bold",
    //                             }}
    //                             label={
    //                               employee?.workDetails?.technology?.title
    //                                 ? employee?.workDetails?.technology?.title
    //                                 : "-"
    //                             }
    //                           />
    //                         </Stack>
    //                       )
    //                     )}

    //                     <Stack
    //                       direction="row"
    //                       alignItems="center"
    //                       justifyContent="center"
    //                       sx={{ gridGap: "8px" }}
    //                     >
    //                       <figure>
    //                         <a
    //                           rel="noreferrer"
    //                           target="_blank"
    //                           href={employee?.contact?.skype}
    //                         >
    //                           <img
    //                             style={{
    //                               width: "24px",
    //                             }}
    //                             alt="skype"
    //                             src="/assets/images/skype-graybox.svg"
    //                           />
    //                         </a>
    //                       </figure>
    //                       <figure>
    //                         <a
    //                           rel="noreferrer"
    //                           target="_blank"
    //                           href={employee?.contact?.linkedIn}
    //                         >
    //                           <img
    //                             style={{
    //                               width: "24px",
    //                             }}
    //                             alt="linkedin"
    //                             src="/assets/images/linkedin-graybox.svg"
    //                           />
    //                         </a>
    //                       </figure>
    //                       {employee?.github && (
    //                         <figure>
    //                           <a
    //                             rel="noreferrer"
    //                             target="_blank"
    //                             href={employee?.github}
    //                           >
    //                             <img
    //                               style={{
    //                                 width: "24px",
    //                               }}
    //                               alt="github"
    //                               src="/assets/images/github.svg"
    //                             />
    //                           </a>
    //                         </figure>
    //                       )}
    //                       {employee?.portfolioWebsite && (
    //                         <figure>
    //                           <a
    //                             rel="noreferrer"
    //                             target="_blank"
    //                             href={employee?.portfolioWebsite}
    //                           >
    //                             <img
    //                               style={{
    //                                 width: "24px",
    //                               }}
    //                               alt="portfolio"
    //                               src="/assets/images/portfolio.svg"
    //                             />
    //                           </a>
    //                         </figure>
    //                       )}
    //                       <figure>
    //                         <img
    //                           style={{
    //                             width: "24px",
    //                           }}
    //                           alt="resume"
    //                           src="/assets/images/download-graybox.svg"
    //                         />
    //                       </figure>

    //                       {employee.assetOutOfOffice && (
    //                         <figure>
    //                           {/* <a
    //                             rel="noreferrer"
    //                             target="_blank"
    //                             href={employee?.github}
    //                           > */}
    //                             <img
    //                               style={{
    //                                 width: "24px",
    //                               }}
    //                               alt="Asset"
    //                               src="/assets/images/asset.svg"
    //                               title="Asset"
    //                             />
    //                           {/* </a> */}
    //                         </figure>
    //                       )}
    //                     </Stack>
    //                   </CardContent>
    //                 </Paper>
    //               </Card>
    //             </Grid>
    //           );
    //         }
    //       })
    //     )}
    //     {loading && <Loader />}
    //   </Grid>
    // </Container>
  );
}

const componentConfig = {
  component: ExEmployee,
  path: "/employee/ex-employee",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
