import * as Yup from "yup";

const itemModalSchema = Yup.object().shape({
  status: Yup.string().required("Item status is required").nullable(),
  employeeId: Yup.string().when(["status"], {
    is: (status) => status === "USE",
    then: Yup.string().required("Assigned Employee is required").nullable(),
    otherwise: Yup.string().nullable(),
  }),
});

export default itemModalSchema;
