import React, { useEffect, useRef, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboard";

import Card from "@mui/material/Card";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { useFormik } from "formik";
import QuillEditor from "./QuillEditor";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { BASE_URL } from "../../../constants";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Tooltip from "@mui/material/Tooltip";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import announcementsSchema from "../../../validations/announcementsSchema";
import {
  createNotice,
  getNoticeById,
} from "../../../redux/announcements/announcementsThunk";
import { decryption } from "../../../utils/encodeString";


function AddAnnouncement() {
  const navigate = useNavigate();
  const attachmentsFileInput = useRef(null);
  const dispatch = useDispatch();
  const params = useParams();
  const [isView, setIsView] = useState(false);
  const [data, setData] = useState(false);

  useEffect(() => {
    if (!getViewVisible("announcements")) {
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      setIsView(true);
      console.log("PARAMS ID", params.id);
      const id = decryption(params.id);
      if (isView) {
        dispatch(getNoticeById(id))
          .unwrap()
          .then((res) => {
            setData(res?.data);
            // console.log("DATUYADSUDADJK",data)
            setValues(res?.data);
          })
          .catch((err) => console.log(err));
      }
    }
  }, [isView]);

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      attachments: [],
      startDate: "",
      endDate: "",
      isActive: false,
    },
    validationSchema: announcementsSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("content", values.content);
      formData.append("startDate", values.startDate);
      formData.append("endDate", values.endDate);
      formData.append("isActive", values.isActive);
      values.attachments.forEach((attachment) => {
        if ("id" in attachment) {
          return;
        } else {
          formData.append("attachments", attachment);
        }
      });
      await dispatch(createNotice(formData)).unwrap();
      setSubmitting(false);
      resetForm();
      navigate("/announcements");
    },
  });
  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    handleBlur,
    setSubmitting,
    resetForm,
    validateField,
  } = formik;

  // if (!getAddVisible("policy") || !getEditVisible("policy")) {
  // 	navigate("/dashboard");
  // }
  // console.log("VALUES", values);
  // function for uploading attachments
  const handleAttachmentsChange = async (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("attachments", file);
      setFieldValue("attachments", [...values.attachments, file]);
    }
  };

  // function for deleting attachments
  const handleDeleteAttachments = async (img, index) => {
    const temp = [...values.attachments];
    const deletedArray = values?.deletedFilesArray || [];
    if ("id" in img) {
      deletedArray?.push(img?.id);
    }
    temp.splice(index, 1);
    await setFieldValue("attachments", temp);
    setFieldValue("deletedFilesArray", deletedArray);
    validateField("attachments");
  };
  console.log("ERRORS", errors);

  return (
    <Container className="Employee-wrapper Addannouncement-maincontainer" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
      <div className="title-button-wrapper Addannouncement-title">
        <Typography variant="h4" gutterBottom>
          Add Announcements
        </Typography>
        {/* <Button
          component={RouterLink}
          to="/announcements"
          variant="contained"
          startIcon={<ArrowBackIcon />}
        >
          Back To List
        </Button> */}
        
        <Stack className="employee-header-right" direction="row">
          {isView ? (
            ""
          ) : (
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              type="submit"
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          )}
          <Button
            className="back-button"
            variant="contained"
            component={RouterLink}
            to="/announcements"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
        </Stack>
      </div>
      </Stack>
      <Card className="add-announcement-card-wrapper">
        <Container maxWidth="xl">
          <form className="add-announcement-form gray-box Employee_Detail_Content">
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} mt={3}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 256 }}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                  disabled={isView}
                />
              </Grid>
              <Grid item xs={12} mt={3}>
                <label htmlFor="content" style={{ marginBottom: "50px" }}>
                  Content:
                </label>
                <div style={{ marginTop: "12px" }}>
                  <QuillEditor
                    value={values.content}
                    onChange={(newValue) => setFieldValue("content", newValue)}
                    flag={isView}
                  />
                  {touched.content && errors.content && (
                    <div style={{ color: "red" }}>{errors.content}</div>
                  )}
                </div>
              </Grid>
              <Grid item xs={6} mt={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns} item>
                  <Grid item>
                    <DesktopDatePicker
                      xs={6}
                      label="Effective From"
                      style={{ width: "100px" }}
                      value={values.startDate}
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      onChange={(newValue) => {
                        setFieldValue("startDate", newValue);
                      }}
                      disabled={isView}
                      helperText={touched.startDate && errors.startDate}
                      error={touched.startDate && !!errors.startDate}
                      name="startDate"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={Boolean(
                            touched?.startDate && errors?.startDate
                          )}
                          helperText={touched?.startDate && errors?.startDate}
                          size="small"
                          style={{
                            width: "45%",
                            marginRight: "10px",
                          }}
                        />
                      )}
                    />
                    <DesktopDatePicker
                      xs={6}
                      label="Effective until"
                      value={values.endDate}
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      onChange={(newValue) => {
                        setFieldValue("endDate", newValue);
                      }}
                      name="endDate"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={Boolean(touched?.endDate && errors?.endDate)}
                          helperText={touched?.endDate && errors?.endDate}
                          size="small"
                          style={{ width: "50%" }}
                          //                   sx={isView  ? {
                          //                     "&.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled": {
                          // opacity:1
                          //                     },
                          //                   } : {}}
                        />
                      )}
                      disabled={isView}
                      sx={{
                        "&.Mui-disabled": {
                          opacity: 1, // Set your desired opacity value
                        },
                      }}
                    />
                  </Grid>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6} mt={3}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isActive}
                      name="isActive"
                      onChange={handleChange}
                      inputProps={{
                        "aria-label": "controlled",
                        "data-cy": "chk-isActive-department",
                      }}
                      value={values?.isActive}
                    />
                  }
                  disabled={isView}
                  label="Status"
                  sx={isView ? { opacity: "1" } : {}}
                />
              </Grid>
            </Grid>
            <Grid className="add-announcement-form-bottom" container pt={3} sx={{ borderTop: "1px solid #dce0e4" }}>
              <Grid item xs={12}>
                <Stack mb={1}>
                  <Typography>
                    {data && data?.attachments?.length > 0
                      ? "Attachments"
                      : "No Attachments"}

                    {!isView ? (
                      <p style={{ fontSize: "12px", color: "gray" }}>
                        Only 5 attachments are allowed.
                      </p>
                    ) : (
                      ""
                    )}
                    {errors &&
                      errors?.attachments &&
                      errors.attachments.length > 0 && (
                        <span
                          style={{
                            color: "red",
                            marginLeft: "10px",
                            fontSize: "12px",
                          }}
                        >
                          {errors.attachments}
                        </span>
                      )}
                  </Typography>
                </Stack>
                <div>
                  <Grid container>
                    {Array.isArray(values?.attachments) &&
                      values?.attachments?.length > 0 &&
                      values?.attachments?.map((img, idx) => {
                        return (
                          <Grid
                            className="custome-uploadBox_grid"
                            key={idx}
                            item
                            xs={2}
                          >
                            <div
                              className="custome-uploadBox small profile-image-part"
                              onClick={(e) => {
                                if ("path" in img) {
                                  window.open(BASE_URL + img?.path, "_blank");
                                } else {
                                  window.open(
                                    URL.createObjectURL(img),
                                    "_blank"
                                  );
                                }
                              }}
                            >
                              {!isView ? (
                                <CancelRoundedIcon
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteAttachments(img, idx);
                                  }}
                                  className="closeIcon"
                                />
                              ) : (
                                ""
                              )}

                              <>
                                <InsertDriveFileIcon />
                                <Tooltip title={img?.name}>
                                  <Typography
                                    variant="caption"
                                    style={{
                                      maxWidth: "180px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {img?.name}
                                  </Typography>
                                </Tooltip>
                              </>
                            </div>
                          </Grid>
                        );
                      })}
                    {values?.attachments?.length < 5 && !isView ? (
                      <Grid
                        item
                        xs={2}
                        style={{
                          marginRight: "20px",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <div
                          className="custome-uploadBox small profile-image-part"
                          onClick={(e) => {
                            attachmentsFileInput.current.click();
                          }}
                        >
                          <div className="p-image">
                            <i className="upload-button">
                              <AddCircleIcon />
                            </i>
                            <input
                              ref={attachmentsFileInput}
                              className="file-upload"
                              type="file"
                              accept=".pdf, .jpeg, .jpg,"
                              onChange={(e) => handleAttachmentsChange(e)}
                            />
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      ""
                    )}
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </form>
          {/* <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mb={3}
          >
            <Button variant="contained">Save</Button>
          </Stack> */}
        </Container>
      </Card>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddAnnouncement,
    path: "/announcements/add",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddAnnouncement,
    path: "/announcements/view/:id",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];
export default componentConfig;
