import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboard";
import Personal from "./AddCandidateComponents/Personal";
import Education from "./AddCandidateComponents/Education";
import Experience from "./AddCandidateComponents/Experience";
import Activity from "./AddCandidateComponents/Activity";
import Loader from "../../../components/Loader";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import candidateSchema, {
  checkActivityDetails,
  checkEducationDetails,
  checkOptionCertificate,
  checkExperienceDetails,
  checkPersonalDetails,
} from "../../../validations/candidateSchema";
import { getDesignation } from "../../../redux/designation/designationThunk";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { getCompany } from "../../../redux/company/companyThunk";
import { getJobChange } from "../../../redux/jobChange/jobChangeThunk";
import { getEducation } from "../../../redux/education/educationThunk";
import { getCourse } from "../../../redux/course/courseThunk";
import { getCollege } from "../../../redux/college/collegeThunk";
import { BASE_URL } from "../../../constants";
import {
  insertCandidate,
  getCandidateById,
  updateCandidate,
  getCandidate,
  uploadCandidateImage,
  getCandidateFromResume,
} from "../../../redux/candidate/candidateThunk";
import { decryption, encryption } from "../../../utils/encodeString";
import { getActivity } from "../../../redux/activity/activityThunk";
import { getHRData } from "../../../redux/employee/employeeThunk";
import {
  getCandidateByStatus,
  getCandidateState,
  getCandidateStatus,
} from "../../../redux/candidateState/candidateStatethunk";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { getSkill } from "../../../redux/skill/skillThunk";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import moment from "moment";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";
// import { BASE_URL } from '../../../constants';
import { Chip } from "@mui/material";
const educationObject = {
  educationTypeId: "",
  courseId: "",
  passingYear: "",
  percentage: null,
  instituteName: "",
  instituteId: "",
};

function AddCandidateForm() {
  const location = useLocation();
  let params = location.state;
  const loading = useSelector((state) => state.candidate.loading);
  const [submitted, setSubmitted] = React.useState(false);

  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const [candidateResume, setCandidateResume] = useState(null);
  const [toBeDeletedCandidateResume, setToBeDeletedCandidateResume] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [attachmentLink, setAttachmentLink] = useState("");
  const [attachmentFilename, setAttachmentFilename] = useState("");
  const designationData = useSelector((state) => state.designation);
  const technologyData = useSelector((state) => state.technology);
  const skillData = useSelector((state) => state.skill);
  const companyData = useSelector((state) => state.company);
  const jobChangeData = useSelector((state) => state.jobChange);
  const educationData = useSelector((state) => state.education);
  const courseData = useSelector((state) => state.course);
  const collegeStatus = useSelector((state) => state.college.status);
  const collegeData = useSelector((state) => state.college.data);
  const activityData = useSelector((state) => state.activity);
  const HRDataStatus = useSelector((state) => state.employee.HRDataStatus);
  const [designationDisable, setDesignationDisable] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data, "db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  let { id, emailId, attachment } = useParams();
  if (id) id = decryption(id);
  if (emailId) emailId = decryption(emailId);
  if (attachment) attachment = decryption(attachment);
  const [value, setValue] = React.useState(
    params?.tabid ? params?.tabid : id ? "1" : "0"
  );
  // if (!getAddVisible("candidates") || !getEditVisible("candidates")) {
  // 	navigate("/dashboard");
  // }
  useEffect(() => {
    if (id === -1) {
      navigate("/candidate");
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (attachment && attachment != -1) {
      handleDownload(attachment);
    }
  }, [dispatch]);
  const [personal, setPersonal] = useState({});
  const formik = useFormik({
    initialValues: {
      firstName: "",
      linkEmailId: emailId,
      lastName: "",
      jobId: null,
      candidateJobs: [],
      dateOfBirth: null,
      gender: null,
      maritalStatus: "",
      referedBy: null,
      wishlist: false,
      isActive: false,
      contactNumber: null,
      designationId: null,
      alternateContactNumber: null,
      email: "",
      skype: "",
      linkedIn: "",
      github: "",
      portfolioWebsite: "",
      totalExperience: 0,
      technologies: [],
      joiningDate: null,
      education: [
        {
          educationTypeId: "",
          courseId: null,
          courseName: "",
          passingYear: "2022",
          percentage: null,
          instituteName: "",
          instituteId: null,
        },
      ],
      course: [
        {
          courseName: "",
          instituteName: "",
          certificateUrl: "",
          technologyId: "",
        },
      ],
      experience: [
        /* {
					companyId: null,
					designationId: null,
					technologies: [],
					from: null,
					to: null,
					reasonForJobChange: null,
					totalExperience: '',
					isDateVisible: false,
					isPresentVisible: false,
				}, */
      ],
      salary: {
        lastIncrementDate: null,
        currentCtc: null,
        status: null,
        salaryBeforeIncrement: null,
        expectedCtc: null,
        addNoticePeriod: "",
      },
      activity: {
        approachDate: null,
        candidateStatusId: "",
        candidateStateId: null,
        note: "",
        futureDate: null,
        appliedDate: null,
      },
    },
    validationSchema: candidateSchema,
    onSubmit: async (values, { resetForm }, isSubmitting) => {
      const today = moment();
      try {
        let response;
        let imageId;
        if (!values.contactNumber) {
          values.contactNumber = null;
        }
        values.firstName = values.firstName.trim();
        values.lastName = values.lastName.trim();
        values.totalExperience = values.totalExperience
          ? values.totalExperience
          : 0;
        if (!values.alternateContactNumber) {
          values.alternateContactNumber = null;
        }
        if (!values.designationId) {
          values.designationId = null;
        }
        values.referedBy = null;
        if (!id) {
          let newValue = { ...values };
          let finalobj = { ...newValue.candidateJobs[0] };
          let expe = [...values.experience];
          expe = expe.map((e) => {
            if (moment(e.to).isSame(today, "day")) {
              e.to = null;
            }
            return e;
          });
          let finalValue = {
            ...values,
            experience: [...expe],
            // activity:values.activity.candidateStateId&&values.activity.candidateStateId!==''?{...values.activity}:{...values.activity,candidateStateId:null},
            candidateJobs: [
              { ...finalobj, jobId: values.jobId, isActive: true },
            ],
          };
          response = await dispatch(insertCandidate(finalValue)).unwrap();
          imageId = response?.data?.id;
        }

        if (id) {
          let newValue = { ...values };
          let finalobj = { ...newValue.candidateJobs[0] };
          // console.log(finalobj, "pdpdpd");
          let expe = [...values.experience];
          expe = expe?.map((e) => {
            if (moment(e.to).isSame(today, "day")) {
              e.to = null;
            }
            return e;
          });
          let finalValue = {
            ...values,
            experience: [...expe],
            // activity:values.activity.candidateStateId&&values.activity.candidateStateId!==''?{...values.activity}:{...values.activity,candidateStateId:null},
            candidateJobs: [
              { ...finalobj, jobId: values.jobId, isActive: true },
            ],
          };
          response = await dispatch(
            updateCandidate({ id, finalValue })
          ).unwrap();
          imageId = id;
        }

        if (
          selectedFile ||
          (!candidateResume?.id && candidateResume) ||
          toBeDeletedCandidateResume
        )
          dispatch(
            uploadCandidateImage({
              id: imageId,
              file: selectedFile,
              candidateResume: candidateResume,
              toBeDeletedCandidateResume: toBeDeletedCandidateResume,
            })
          );

        if (response?.status === 200) {
          /* dispatch(
						getCandidate({
							page: 1,
							limit: 20,
							orderBy: "DESC",
							sortBy: "id",
						}),
					); */
          resetForm();
          const fullPath = window.location.pathname;
          const checkURL = "addusingemail";
          if (fullPath.includes(checkURL)) {
            navigate("/email-inbox");
          } else {
            navigate("/candidate");
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
      // }else{
      //
      //   setValue('3');
      //   setExperieneceErrorShow(!experieneceErrorShow)
      //   setExperieneceErrorCheck(true)
      // }
    },
  });

  const {
    handleSubmit,
    handleReset,
    getFieldProps,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    isSubmitting,
    touched,
    setErrors,
    setFieldTouched,
    setFieldError,
    validateField,
    handleBlur,
    setStatus,
    status,
  } = formik;

  const fileInput = useRef(null);
  const candidateResumeFileInput = useRef(null);
  const handleTabChange = async (value) => {
    /**
     * code to manually validate the form on next button
     * const errors = await validateForm();
     * setTouched(setNestedObjectValues(errors, true));
     * setErrors(errors);
     */
    setValue(value);
    setStatus({});
    navigate(location.pathname, { replace: true });
  };

  const mapTechnologyIds = (experienece) => {
    let technologyIds = [];

    experienece.map((exp, idx) => {
      technologyIds = exp.technologies.map((tech) => {
        return tech.technologyId;
      });
      setFieldValue(`experience[${idx}].technologies`, technologyIds);
    });
  };

  console.log(errors);
  // useEffect(()=>{
  //   const keys = Object.keys(errors);
  //   if(keys.length===1&&keys[0]==='experience'&&errors.experience.length<1){
  //     setErrors({})
  //   }
  // },[errors])

  useEffect(() => {
    if (
      !skillData.complete &&
      !skillData.loading &&
      !skillData.error &&
      (!skillData.data ||
        (Array.isArray(skillData.data) && skillData.data.length <= 0))
    )
      dispatch(getSkill({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  useEffect(() => {
    // if (
    // 	!educationData.complete &&
    // 	!educationData.loading &&
    // 	!educationData.error &&
    // 	(!educationData.data ||
    // 		(Array.isArray(educationData.data) && educationData.data.length <= 0))
    // )
    dispatch(getSkill({ sortBy: "", orderBy: "" }));
    dispatch(getEducation({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (
      !designationData.complete &&
      !designationData.loading &&
      !designationData.error &&
      (!designationData.data ||
        (Array.isArray(designationData.data) &&
          designationData.data.length <= 0))
    )
      dispatch(getDesignation({ sortBy: "", orderBy: "" }));
  }, [designationData]);

  useEffect(() => {
    // if (
    // 	!technologyData.complete &&
    // 	!technologyData.loading &&
    // 	!technologyData.error &&
    // 	(!technologyData.data ||
    // 		(Array.isArray(technologyData.data) && technologyData.data.length <= 0))
    // )
    // dispatch(getTechnology());
    dispatch(getDesignation({ sortBy: "", orderBy: "" }));
    dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (companyData.statusForDropDown === "idle") {
      getViewVisible("company") &&
        dispatch(getCompany({ sortBy: "", orderBy: "" }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !jobChangeData.complete &&
      !jobChangeData.loading &&
      !jobChangeData.error &&
      (!jobChangeData.data ||
        (Array.isArray(jobChangeData.data) && jobChangeData.data.length <= 0))
    )
      dispatch(getJobChange({ sortBy: "", orderBy: "" }));
  }, [jobChangeData]);

  useEffect(() => {
    // if (
    // 	!courseData.complete &&
    // 	!courseData.loading &&
    // 	!courseData.error &&
    // 	(!courseData.data ||
    // 		(Array.isArray(courseData.data) && courseData.data.length <= 0))
    // )
    dispatch(getJobChange({ sortBy: "", orderBy: "" }));
    dispatch(getCourse({ sortBy: "", orderBy: "" }));
    dispatch(getActivity({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (collegeStatus === "idle") {
      getViewVisible("college") &&
        dispatch(getCollege({ sortBy: "", orderBy: "" }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !activityData.complete &&
      !activityData.loading &&
      !activityData.error &&
      (!activityData.data ||
        (Array.isArray(activityData.data) && activityData.data.length <= 0))
    )
      dispatch(getActivity({ sortBy: "", orderBy: "" }));
  }, [activityData, dispatch]);

  useEffect(() => {
    if (HRDataStatus === "idle") {
      dispatch(getHRData({ sortBy: "", orderBy: "" }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getCandidateById(id))
        .unwrap()
        .then((res) => {
          res.data.activity?.candidateStatusId &&
            dispatch(
              getCandidateByStatus(res.data.activity?.candidateStatusId)
            );
          setFirstName(res.data.firstName);
          setLastName(res.data.lastName);
          let candidateImage = res.data.candidateImage
            ? `${BASE_URL}/${res.data.candidateImage}`
            : null;
          setSelectedFileUrl(candidateImage);
          setCandidateResume(res.data.candidateResume);
          let newTest = { ...res.data };
          let newexp = newTest.experience.map((e) => {
            if (e.to == null) {
              e.to = new Date();
            }
            return e;
          });
          setValues({ ...newTest, experience: newexp });
          setFieldValue("jobId", res.data?.candidateJobs[0]?.jobId);

          mapTechnologyIds(res.data.experience);

          if (
            res.data.activity?.candidateStatusId == 4 &&
            res.data.activity?.candidateStateId == 28
          ) {
            setDesignationDisable(false);
          }
          setStatus({});
          setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          setIsLoading(false);
          // return navigate("/candidate");
        });
    }
  }, [id, dispatch, setValues]);

  useEffect(() => {
    dispatch(getCandidateStatus({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  /* useEffect(() => {
		dispatch(getCandidateByStatus(values.activity.candidateStatusId));
	}, [dispatch, values.activity.candidateStatusId]); */

  if (isLoading) {
    return <Loader />;
  }
  if (Object.keys(values.salary).length == 0) {
    values.salary = {
      lastIncrementDate: null,

      currentCtc: null,
      status: "",
      salaryBeforeIncrement: null,
      expectedCtc: null,
      addNoticePeriod: "",
    };
  }
  const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["jpeg", "jpg", "png"];
      return imageTypes.includes(file.name.split(".")[1]);
    }
    return file && file["type"] && file["type"].split("/")[0] === "image";
  };
  const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };
  const handleDeleteCandidateResume = (image) => {
    setCandidateResume(null);
    setToBeDeletedCandidateResume(image?.id);
  };
  const handleDownload = async (attachment) => {
    const attachments = attachment.split("&&&&", 2);
    const attachmentPath = attachments[0];
    setAttachmentLink(attachmentPath);
    const fileName = attachments[1];
    setAttachmentFilename(fileName);
    const url = `${BASE_URL}/${attachmentPath}`; // Replace with your file URL
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: "application/pdf" }); // Replace with your file name and type
    // let e={};
    // e.target.files[0]=file;
    handleEmployeeResumeImageChange(file);
    // window.open(`/candidate/addusingemail/${encryption(emailData?.id)}`,"_self")
  };
  const handleEmployeeResumeImageChange = (e) => {
    handleReset();
    dispatch(getCandidateFromResume({ candidateResume: e })).then((res) => {
      let finalData = res.payload.data || res.payload;
      let ids = technologyData?.data?.filter((e) =>
        finalData.skills.some(
          (item) => item.toLowerCase() === e?.title?.toLowerCase()
        )
      );
      // console.log(ids);
      setFieldValue("technologies", ids || []);
      if (
        finalData?.total_exp &&
        finalData?.total_exp !== "not_found" &&
        finalData?.total_exp > 0 &&
        finalData?.experience_details.length > 0
      ) {
        let newExperience = [];
        finalData.experience_details.map((el) => {
          let company = companyData?.data?.find(
            (e) => el.company.toLowerCase() === e?.title?.toLowerCase()
          );
          let designation = designationData?.data?.find(
            (e) => el.designation.toLowerCase() === e?.title?.toLowerCase()
          );
          let technology = technologyData?.data?.find(
            (e) => el?.technology?.toLowerCase() === e?.title?.toLowerCase()
          );
          // if(educationArray?.id||coureArray?.id||institute?.id){
          newExperience = [
            ...newExperience,
            {
              companyId: company?.id || null,
              designationId: designation?.id || null,
              technologies: [technology.id] || [],
              from: el.date_from,
              to: el.present ? null : el.date_to,
              reasonForJobChange: null,
              totalExperience: el.total_experience || "",
              isPresentVisible: el.present,
            },
          ];
          setFieldValue("education", newExperience || []);
          // }
        });
      }
      // setFieldValue("experience", [
      //   ...values.experience,
      //   {
      //     companyId: null,
      //     designationId: null,
      //     technologies: ids.map((e)=>e.id)||[],
      //     from: null,
      //     to: "",
      //     reasonForJobChange: null,
      //     totalExperience: "",
      //   },
      // ]);
      let newarray = [];
      finalData?.education_details.map((el) => {
        let educationArray = educationData?.data?.find(
          (e) => el.course.toLowerCase() === e?.title?.toLowerCase()
        );
        let coureArray = courseData?.data?.find(
          (e) => el.course.toLowerCase() === e?.title?.toLowerCase()
        );
        let institute = collegeData?.find(
          (e) => el.institute.toLowerCase() === e?.collegeName?.toLowerCase()
        );
        // if(educationArray?.id||coureArray?.id||institute?.id){
        newarray = [
          ...newarray,
          {
            educationTypeId: educationArray?.id || "",
            courseId: educationArray?.id ? null : coureArray?.id || null,
            courseName:
              el.course !== "not_found" && !educationArray?.id ? el.course : "",
            passingYear: "",
            percentage: null,
            instituteName: el.institute !== "not_found" ? el.institute : "",
            instituteId: institute?.id || null,
          },
        ];
        setFieldValue("education", newarray || []);
        // }
      });
      // let educationArray=educationData.filter((e)=>finalData.course.some(item => item.toLowerCase() === e?.title?.toLowerCase()))

      setFieldValue(
        "firstName",
        finalData?.first_name && finalData?.first_name !== ""
          ? finalData?.first_name
          : values.firstName
      );
      setFieldValue(
        "lastName",
        finalData?.last_name && finalData?.last_name !== ""
          ? finalData?.last_name
          : values.lastName
      );
      setFieldValue(
        "skype",
        finalData?.social_links && finalData?.social_links?.skype !== ""
          ? finalData?.social_links?.skype
          : values.skype
      );
      setFieldValue(
        "linkedIn",
        finalData?.social_links && finalData?.social_links !== "not_found"
          ? finalData?.social_links?.linkedIn
          : values.linkedIn
      );
      setFieldValue(
        "github",
        finalData?.social_links && finalData?.social_links?.github !== ""
          ? finalData.social_links.github
          : values.github
      );
      setFieldValue(
        "portfolioWebsite",
        finalData?.social_links && finalData?.social_links?.portfolio !== ""
          ? finalData.social_links.portfolio
          : values.portfolio
      );
      setFieldValue(
        "contactNumber",
        finalData?.mobile && finalData?.mobile[0]
          ? finalData?.mobile[0].length > 10
            ? finalData?.mobile[0].substr(-10)
            : finalData?.mobile[0]
          : values.contactNumber
      );
      setFieldValue(
        "email",
        finalData?.email ? finalData?.email : values.email
      );
      setFieldValue(
        "gender",
        finalData?.gender && finalData?.gender !== ""
          ? finalData?.gender
          : values.gender
      );
      setFieldValue(
        "totalExperience",
        finalData?.total_exp && finalData?.total_exp !== ""
          ? finalData?.total_exp
          : values.totalExperience
      );
      setFieldValue(
        "activity",
        attachment && attachment != -1
          ? { ...values.activity, candidateStatusId: 1, candidateStateId: 10 }
          : values.candidateStatusId
      );
    });
    setCandidateResume(e);
  };
  // console.log(status);
  const handleBackLog = (e) => {
    if (e.view.navigation.canGoBack) {
      navigate(-1);
    } else {
      navigate("/candidate");
    }
  };
  return (
    <Container className="add-Candidate-container" maxWidth="xl">
      <form
        onSubmit={(...args) => {
          setSubmitted(true);
          handleSubmit(...args);
        }}
      >
        <Stack
          className="add-Candidate-header"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="title-button-wrapper add-Candidate-title">
            <Typography variant="h4" gutterBottom>
              {id
                ? `Edit Candidate: ${firstName} ${lastName}`
                : "Add Candidate"}
            </Typography>
            <Stack direction="row" alignItems="center">
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ marginRight: "15px" }}
              >
                Save
              </LoadingButton>

              <Button
                className="back-button"
                variant="contained"
                // component={RouterLink}
                // to="-1"
                onClick={handleBackLog}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Stack>
          </div>
        </Stack>
        <Box className="add-Candidate-box" sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Grid container>
              <Grid item xs={3}>
                <Box>
                  <Card className="left-heading-part">
                    <TabList
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                      onChange={(e, v) => handleTabChange(v)}
                      aria-label="lab API tabs example"
                      orientation="vertical"
                      className="tab-list-part"
                    >
                      {!id && (
                        <Tab
                          label="Resume"
                          value="0"
                          direction="row"
                          iconPosition="end"
                          sx={{
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        />
                      )}
                      <Tab
                        label="Personal"
                        value="1"
                        direction="row"
                        {...(submitted &&
                          checkPersonalDetails(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Education"
                        value="2"
                        direction="row"
                        {...(submitted &&
                          (checkEducationDetails(errors) ||
                            checkOptionCertificate(errors)) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />

                      <Tab
                        label="Experience"
                        value="3"
                        direction="row"
                        {...(submitted &&
                          checkExperienceDetails(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Activity"
                        value="4"
                        direction="row"
                        {...(submitted &&
                          checkActivityDetails(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        iconPosition="end"
                      />
                    </TabList>
                  </Card>
                  {attachment && attachment != -1 && (
                    <Card
                      className="left-heading-part"
                      direction="row"
                      sx={{ display: "flex", justifyContent: "center" }}
                    >
                      <div>
                        <Tooltip title={attachmentFilename}>
                          <Chip
                            color="primary"
                            variant="filled"
                            icon={<PictureAsPdfIcon />}
                            label={attachmentFilename}
                            style={{ maxWidth: 150, marginTop: "5px" }}
                          />
                        </Tooltip>
                      </div>
                      <div style={{ marginLeft: "5px" }}>
                        <a
                          href={`${BASE_URL}/${attachmentLink}`}
                          download={attachmentFilename}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <DownloadForOfflineIcon
                            color="primary"
                            fontSize="large"
                          />
                        </a>
                      </div>
                      {/* // <AttachmentPreview  attachmentData={attachment} /> */}
                    </Card>
                  )}
                </Box>
              </Grid>
              <Grid className="add-Candidate-box-grid" item xs={9}>
                <TabPanel value="0">
                  <Card className="right-side-content-part gray-box Contact-Detail-content">
                    <Grid
                      container
                      spacing={3}
                      sx={{ width: "95%", m: "auto", mb: 2 }}
                    >
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid item xs={12}>
                            <div
                              className="custome-uploadBox small profile-image-part"
                              style={{ position: "relative" }}
                              onClick={(e) => {
                                candidateResumeFileInput.current.click();
                              }}
                            >
                              {loading
                                ? ""
                                : candidateResume && (
                                    <>
                                      {checkIfImage(candidateResume) ? (
                                        <>
                                          <InsertDriveFileIcon
                                            onClick={(e) => {
                                              window.open(
                                                candidateResume.webViewLink,
                                                "_blank"
                                              );
                                            }}
                                          />
                                          {/* <Box
																component="img"
																src={getImageUrl(
																	values.presentAddressImage
																)}
															/> */}
                                          <Typography variant="caption">
                                            {getFileName(candidateResume)}
                                          </Typography>
                                        </>
                                      ) : (
                                        <>
                                          <InsertDriveFileIcon
                                            onClick={(e) => {
                                              window.open(
                                                candidateResume.webViewLink,
                                                "_blank"
                                              );
                                            }}
                                          />
                                          <Typography variant="caption">
                                            {getFileName(candidateResume)}
                                          </Typography>
                                        </>
                                      )}
                                    </>
                                  )}
                              {loading ? (
                                <Loader />
                              ) : (
                                candidateResume && (
                                  <CancelRoundedIcon
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleDeleteCandidateResume(
                                        candidateResume
                                      );
                                    }}
                                    className="closeIcon"
                                    style={{
                                      position: "absolute",
                                      top: "-7px",
                                      right: "-6px",
                                    }}
                                  />
                                )
                              )}
                              <div className="p-image">
                                {!candidateResume && (
                                  <i className="upload-button">
                                    <CloudUploadIcon />
                                  </i>
                                )}

                                {!candidateResume && (
                                  <input
                                    ref={candidateResumeFileInput}
                                    className="file-upload"
                                    type="file"
                                    accept=".pdf"
                                    onChange={(e) =>
                                      handleEmployeeResumeImageChange(
                                        e.target.files[0]
                                      )
                                    }
                                  />
                                )}
                                {!candidateResume && (
                                  <>
                                    <div className="file-support-text">
                                      Upload Candidate Resume
                                    </div>
                                    <div className="file-support-text">
                                      (File support: pdf with max 20MB size)
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                        {/* <FormHelperText
                                    error={Boolean(errors?.employeeResume)}
                                >
                                    {touched?.employeeResume &&
                                        errors?.employeeResume}
                                </FormHelperText> */}
                      </Grid>
                    </Grid>
                  </Card>
                  {/* <Personal
										selectedFile={selectedFile}
										setSelectedFile={setSelectedFile}
										candidateResume={candidateResume}
										setCandidateResume={setCandidateResume}
										personal={personal}
										setPersonal={setPersonal}
										setFieldValue={setFieldValue}
										values={values}
										handleChange={handleChange}
										touched={touched}
										errors={errors}
										handleTabChange={handleTabChange}
										selectedFileUrl={selectedFileUrl}
										setSelectedFileUrl={setSelectedFileUrl}
										helperText={errors}
										setToBeDeletedCandidateResume={
											setToBeDeletedCandidateResume
										}
										designationData={designationData}
										designationDisable={designationDisable}
										candidateId={id}
									/> */}
                </TabPanel>
                <TabPanel
                  className="add-Candidate-box-gridbox-wrapper"
                  value="1"
                >
                  <Personal
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    candidateResume={candidateResume}
                    setCandidateResume={setCandidateResume}
                    personal={personal}
                    setPersonal={setPersonal}
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    handleTabChange={handleTabChange}
                    selectedFileUrl={selectedFileUrl}
                    setSelectedFileUrl={setSelectedFileUrl}
                    helperText={errors}
                    setToBeDeletedCandidateResume={
                      setToBeDeletedCandidateResume
                    }
                    designationData={designationData}
                    designationDisable={designationDisable}
                    candidateId={id}
                  />
                </TabPanel>
                <TabPanel
                  className="add-Candidate-box-gridbox-wrapper"
                  value="2"
                >
                  <Education
                    educationObject={educationObject}
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    getFieldProps={getFieldProps}
                    handleTabChange={handleTabChange}
                  />
                </TabPanel>
                <TabPanel
                  className="add-Candidate-box-gridbox-wrapper"
                  value="3"
                >
                  <Experience
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    getFieldProps={getFieldProps}
                    handleTabChange={handleTabChange}
                    setFieldTouched={setFieldTouched}
                    setFieldError={setFieldError}
                    validateField={validateField}
                    handleBlur={handleBlur}
                    setStatus={setStatus}
                    status={status}
                    isSubmitting={isSubmitting}
                  />
                </TabPanel>
                <TabPanel
                  className="add-Candidate-box-gridbox-wrapper"
                  value="4"
                >
                  <Activity
                    setFieldValue={setFieldValue}
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    getFieldProps={getFieldProps}
                    handleTabChange={handleTabChange}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </TabContext>

          <TabContext value={value}></TabContext>
        </Box>
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddCandidateForm,
    path: "/candidate/add",
    public: false,
    layout: DashboardLayout,
    group: "candidate",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddCandidateForm,
    path: "/candidate/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "candidate",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddCandidateForm,
    path: "/candidate/addusingemail/:attachment/:emailId",
    public: false,
    layout: DashboardLayout,
    group: "candidate",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
