import { LOGIN_URL } from "./apiUrls/auth";
import { ADD_INVENOTRY_ITEM } from "./assetManagment";

// const BASE_URL = "http://localhost:3007";
/* LOCAL BASE URL */
// const BASE_URL = "http://192.168.0.176:5000";

/* LOCAL BASE URL */
// const BASE_URL = "http://10.0.0.189:3007";

//  HRMS-REACT BASE URL
// const BASE_URL = "http://192.168.0.204:3007";

/* HRMS STAGING BASE URL */
// const BASE_URL = "http://192.168.0.204:3011";

/* HRMS PRODUCTION BASE URL */
// const BASE_URL = "http://192.168.0.204:3013";

const BASE_URL = "https://team-api.cybercom.in";



/* HRMS STAGING/React - PYTHON SERVER HTTP*/
// const PYTHON_SERVER = "http://192.168.0.80:8000"


/* HRMS PRODUCATION - PYTHON SERVER HTTPS*/
const PYTHON_SERVER = "https://resumeparser.cybercom.in"

export { BASE_URL,PYTHON_SERVER, LOGIN_URL, ADD_INVENOTRY_ITEM };
