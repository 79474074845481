/* eslint-disable react-hooks/rules-of-hooks */
import React from "react";
import { useState, useEffect, useRef } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Loader from "../../../components/Loader";
import DashboardLayout from "../../../layouts/dashboard";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import { getCollege } from "../../../redux/college/collegeThunk";
import { getEducation } from "../../../redux/education/educationThunk";
import { getCourse } from "../../../redux/course/courseThunk";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { getGrade } from "../../../redux/grade/gradeThunk";
import {
  getStudentStatus,
  getStudentById,
  updateStudentById,
  addStudentInDrive,
  convertInternToCandidate,
  convertStudentToEmployee,
  getDrivesWithStudents,
} from "../../../redux/student/studentThunk";
import studentSchema from "../../../validations/studentSchema";
import { getHscCourse } from "../../../redux/hsccourse/hscCourseThunk";
import { BASE_URL } from "../../../constants";
import { decryption, encryption } from "../../../utils/encodeString";
import { Modal, TextareaAutosize } from "@mui/material";
import { ConstructionOutlined } from "@mui/icons-material";
import { getViewVisible } from "../../../utils/userPermission";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddStudents() {
  const location = useLocation();
  let { studentId } = useParams();
  if (studentId) studentId = decryption(studentId);
  let { driveId } = useParams();
  if (driveId) driveId = decryption(driveId);

  const [loading, setLoading] = useState(true);
  const [hscDisable, setHscDisable] = useState(false);
  const [hscCourseDisable, setHscCourseDisable] = useState(false);
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const collegeData = useSelector((state) => state.college);
  const courseData = useSelector((state) => state.course);
  const educationData = useSelector((state) => state.education);
  const studentData = useSelector((state) => state.student);
  const driveStudentData = useSelector((state) => state.student.studentData);
  const [driveData, setDriveData] = useState();
  const hscCourseData = useSelector((state) => state.hscCourse);
  const technologyData = useSelector((state) => state.technology);
  const gradeData = useSelector((state) => state?.grade?.data);
  const [college, setCollege] = useState();
  const fileInput = useRef(null);
  const modeList = ["Online", "Offline"];
  const serviceType = ["CHEQUE", "RETENTION"];
  const commitmentDurationList = ["0", "1", "1.6"];
  const internResumeFileInput = useRef(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [productionDate, setProductionDate] = useState("");
  const [productionDateError, setProductionDateError] = useState({
    flag: false,
    title: "",
  });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openNotes, setOpenNotes] = React.useState(false);
  const handleOpenNotes = () => setOpenNotes(true);
  const handleCloseNotes = () => setOpenNotes(false);
  const [newValues, setNewValue] = useState(false);
  let [notesError, setNotesError] = useState("");
  const [tempObj, setTempObj] = useState({});
  const [convertEmployeeLoading, setConvertEmployeeLoading] = useState(false);
  const params = location.state;
  const locationDropdown = [
    { title: "HOME", id: 1 },
    { title: "OFFICE", id: 2 },
    { title: "HYBRID", id: 3 },
  ];

  const dropdownStudent = (studentsStatus) => {
    // const newstudentsStatus = [];

    let newstudentsStatus = studentsStatus?.map((std) => {
      //  let stdStatus = std?.status?
      if (std?.status?.split(" ").join("").toLowerCase() === "registered") {
        return { ...std, newStatusId: 11 };
      } else if (
        std?.status?.split(" ").join("").toLowerCase() === "notappeared"
      ) {
        return { ...std, newStatusId: 2 };
      } else if (
        std?.status?.split(" ").join("").toLowerCase() === "aptitudeclear"
      ) {
        return { ...std, newStatusId: 3 };
      } else if (
        std?.status?.split(" ").join("").toLowerCase() === "technicalclear"
      ) {
        return { ...std, newStatusId: 4 };
      } else if (
        std?.status?.split(" ").join("").toLowerCase() === "internbackout"
      ) {
        return { ...std, newStatusId: 6 };
      } else if (
        std?.status?.split(" ").join("").toLowerCase() === "readyforproduction"
      ) {
        return { ...std, newStatusId: 1 };
      } else if (std?.status?.split(" ").join("").toLowerCase() === "intern") {
        return { ...std, newStatusId: 5 };
      } else {
        return { ...std, newStatusId: std?.id };
      }
    });

    return newstudentsStatus;
  };

  useEffect(() => {
    getViewVisible("college") &&
      dispatch(getCollege({ sortBy: "", orderBy: "" }));
  }, []);

  const formik = useFormik({
    initialValues: {
      studentImage: null,
      firstName: "",
      middleName: "",
      lastName: "",
      email: "",
      gender: "",
      contact: "",
      emergencyContactPerson: "",
      relationWithIntern: "",
      emergencyContactNumber: "",
      sscPercentage: "",
      hscPercentage: "",
      diplomaPercentage: "",
      graduationPercentage: "",
      masterPercentage: "",
      joiningDate: "",
      wishlist: false,
      batch: "",
      hscCourseId: "",
      pcm :"",
      collegeId:
        driveId && driveStudentData && driveStudentData.placement == "Single"
          ? driveStudentData?.college?.id
          : "",
      educationTypeId: "",
      courseId: "",
      technologyId: "",
      statusId: 5,
      driveId: driveId ? driveId : "",
      mode: "",
      internResume: "",
      isUnderServiceAgreement: false,
      type: [],
      isCheque: false,  
      isRetention: false,
      bankName: "",
      bankAccountNumber: "",
      IFSC: "",
      bankBranch: "",
      chequeAmount: "",
      retentionAmount: "",
      noOfCheque: "",
      chequeNumbers: "",
      toBeDeletedInternResume: null,
      commitmentDuration: "0",
      projectType: "",
      note: "",
      workLocation: "",
      seatNumber: "",
      productionDate: "",
      comments: "",
      enrollmentNumber: "",
      marks: "",
      evaluationGrade: "",
    },
    validationSchema: studentSchema,
    onSubmit: async (values) => {
      let status = dropdownStudent(studentData.data).find(
        (item) => item.newStatusId == values.statusId
      );

      let newValue = { ...values, statusId: status?.id };

      // if (
      //   studentId &&
      //   status &&
      //   status.status.split(" ").join("").toLowerCase() == "internbackout"
      // ) {
      //   let resp = await dispatch(convertInternToCandidate(studentId)).unwrap();
      //   if (resp.success) {
      //     const formData = new FormData();
      //     formData.append("resume", "");
      //     for (let key in values) {
      //       formData.append(key, values[key]);
      //     }
      //     try {
      //       if (!studentId) {
      //         await dispatch(
      //           addStudentInDrive({ body: formData, driveId })
      //         ).unwrap();
      //         if (!driveId) navigation(`/interns`);
      //         else navigation(`/drives/view/${encryption(driveId)}`);
      //       } else {
      //         await dispatch(
      //           updateStudentById({ driveId, studentId, body: formData })
      //         ).unwrap();
      //         if (!driveId) navigation(`/interns`);
      //         else navigation(`/drives/view/${encryption(driveId)}`);
      //       }
      //     } catch (error) {
      //       toast.error(error.message);
      //     }
      //   }
      // }
      // // else if (
      // //   studentId &&
      // //   status &&
      // //   status.status.split(" ").join("").toLowerCase() == "readyforproduction"
      // // ) {
      // //   setNewValue(values);
      // //   handleOpen();
      // // }
      // else {
      // if (
      //     studentId &&
      //     status &&
      //     status.status.split(" ").join("").toLowerCase() == "readyforproduction"
      //   ) {
      //     setFieldValue("productionDate","")
      //   }

      const formData = new FormData();
      formData.append("resume", "");
      for (let key in newValue) {
        formData.append(key, newValue[key]);
      }
      try {
        if (!studentId) {
          await dispatch(
            addStudentInDrive({ body: formData, driveId })
          ).unwrap();
          if (!driveId) navigation(`/interns`);
          else navigation(`/drives/view/${encryption(driveId)}`);
        } else {
          await dispatch(
            updateStudentById({ driveId, studentId, body: formData })
          ).unwrap();
          if (!driveId) navigation(`/interns`);
          else navigation(`/drives/view/${encryption(driveId)}`);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
    // },
  });

  const {
    errors,
    touched,
    setErrors,
    getFieldProps,
    values,
    setFieldValue,
    handleSubmit,
    isSubmitting,
    setValues,
    handleChange,
    handleTabChange,
    hanldeService,
  } = formik;
  useEffect(() => {
    if (
      !courseData.complete &&
      !courseData.loading &&
      !courseData.error &&
      (!courseData.data ||
        (Array.isArray(courseData.data) && courseData.data.length <= 0))
    )
      dispatch(getCourse({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !technologyData.complete &&
      !technologyData.loading &&
      !technologyData.error &&
      (!technologyData.data ||
        (Array.isArray(technologyData.data) && technologyData.data.length <= 0))
    )
      // dispatch(getTechnology());
      dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !hscCourseData.complete &&
      !hscCourseData.loading &&
      !hscCourseData.error &&
      (!hscCourseData.data ||
        (Array.isArray(hscCourseData.data) && hscCourseData.data.length <= 0))
    )
      dispatch(getHscCourse({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !educationData.complete &&
      !educationData.loading &&
      !educationData.error &&
      (!educationData.data ||
        (Array.isArray(educationData.data) && educationData.data.length <= 0))
    )
      dispatch(getEducation({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    dispatch(getStudentStatus({ sortBy: "", orderBy: "" }));
  }, []);

  useEffect(() => {
    dispatch(getGrade({ sortBy: "", orderBy: "" }));
  }, []);

  // console.log("Grade___________001", gradeData)
  useEffect(() => {
    setCollege(collegeData.data);
  }, [collegeData.data]);

  useEffect(() => {
    const fetchDriveData = async () => {
      try {
        const res = await dispatch(getDrivesWithStudents({ id: driveId })).unwrap();
        if (res && res?.data) {
          setDriveData(res?.data);
        }
      } catch (error) {
        // Handle errors, including the specific case where no records are found
        if (error.status === 404 && !error.success) {
          toast.error(error.message);
        } 
    };

    fetchDriveData();
  }}, [dispatch, params, loading]);
  useEffect(() => {
    const fetchById = async () => {
      if (studentId) {
        try {
          let response = await dispatch(getStudentById(studentId)).unwrap();
          let workLocation = locationDropdown?.find(
            (location) => location?.title === response?.workLocation
          );

          setFirstName(response?.firstName);
          setLastName(response?.lastName);

          const changeStatusId = (stdId) => {
            let newData = dropdownStudent(studentData?.data);

            let findStatusId = newData?.find((item) => item.id == stdId);
            return findStatusId?.newStatusId;
          };
          let body = {
            studentImage: response?.image || null,
            firstName: response?.firstName || "",
            middleName: response?.middleName || "",
            lastName: response?.lastName || "",
            email: response?.email || "",
            gender: response?.gender || "",
            contact: response?.contact || "",
            emergencyContactPerson: response?.emergencyContactPerson || "",
            relationWithIntern: response?.relationWithIntern || "",
            emergencyContactNumber: response?.emergencyContactNumber || "",
            sscPercentage: response?.sscPercentage || "",
            hscPercentage: response?.hscPercentage || "",
            pcm: response?.pcm || "",
            diplomaPercentage: response?.diplomaPercentage || "",
            graduationPercentage: response?.graduationPercentage || "",
            masterPercentage: response?.masterPercentage || "",
            joiningDate: response?.joiningDate || "",
            wishlist: response?.wishlist || false,
            batch: response?.batch || "",
            hscCourseId: response?.hscCourseId || "",
            collegeId: response?.collegeId || "",
            educationTypeId: response?.educationTypeId | "",
            courseId: response?.courseId || "",
            technologyId: response?.technologyId || "",
            statusId: changeStatusId(response?.statusId) || "",
            driveId: response?.driveId || "",
            mode: response?.mode || "",
            isUnderServiceAgreement: response.isUnderServiceAgreement || false,
            isCheque: response.isCheque || false,
            isRetention: response.isRetention || false,
            bankName: response.bankName || "",
            bankAccountNumber: response.bankAccountNumber || "",
            IFSC: response.IFSC || "",
            bankBranch: response.bankBranch || "",
            chequeAmount: response.chequeAmount || "",
            retentionAmount: response.retentionAmount || "",
            noOfCheque: response.noOfCheque || "",
            chequeNumbers: response.chequeNumbers || "",
            internResume: response?.resume ? response?.resume[0] : null,
            commitmentDuration: response?.commitmentDuration || "0",
            projectType: response?.projectType || "",
            note: response?.note || "",
            workLocation: workLocation?.id || "",
            seatNumber: response?.seatNumber || "",
            productionDate: response?.productionDate || "",
            comments: response?.comments || "",
            enrollmentNumber: response?.enrollmentNumber || "",
            marks: response?.marks || "",
            evaluationGrade: response?.evaluationGrade || "",
          };

          setValues(body);
          let type = [];
          if (
            response.isUnderServiceAgreement &&
            response.isCheque &&
            response.isRetention
          ) {
            setFieldValue("type", ["CHEQUE", "RETENTION"]);
            type = ["CHEQUE", "RETENTION"];
          }
          if (
            response.isUnderServiceAgreement &&
            response.isCheque &&
            !response.isRetention
          ) {
            setFieldValue("type", ["CHEQUE"]);
            type = ["CHEQUE"];
          }
          if (
            response.isUnderServiceAgreement &&
            !response.isCheque &&
            response.isRetention
          ) {
            setFieldValue("type", ["RETENTION"]);
            type = ["RETENTION"];
          }

          let newobj = { ...body, type };

          setValues(newobj);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
          if (!driveId) navigation(`/interns`);
          else navigation(`/drives/view/${driveId}`);
        }
      }
      setLoading(false);
    };
    fetchById();
  }, [dispatch, driveId, studentId, navigation, setValues, loading]);

  const handleImageChange = (e) => {
    if (e.target.files.length > 0) {
      setFieldValue("studentImage", e.target.files[0]);
    }
  };

  const handleEducationTypeChange = (e, newValue) => {
    educationData.data.map((e, idx) => {
      if (e.id === newValue?.id) {
        if (e.title == "DIPLOMA") {
          setHscDisable(true);
          setHscCourseDisable(true);
          setFieldValue("hscPercentage", "0");
          setFieldValue("hscCourseId", "");
        } else {
          setHscDisable(false);
          setHscCourseDisable(false);
          setFieldValue("hscPercentage", "");
        }
      }
      /*else
      {
        setHscDisable(false);
      }*/
    });
    setFieldValue(`educationTypeId`, newValue?.id || "");
  };

  const handleInternResumeChange = (e) => {
    setFieldValue("internResume", e.target.files[0]);
  };

  const handleDeleteInternResume = (image) => {
    setFieldValue("internResume", "");
    setFieldValue("toBeDeletedInternResume", image?.id);
  };

  const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["jpeg", "jpg", "png"];
      return imageTypes.includes(file.name.split(".")[1]);
    }
    return file && file["type"] && file["type"].split("/")[0] === "image";
  };

  const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };

  let statusId;
  useEffect(() => {
    if (!driveId) {
      dropdownStudent(studentData?.data).forEach((student) => {
        if (student.status == "Intern") {
          statusId = student?.newStatusId;
          delete errors.statusId;
          setFieldValue("statusId", statusId);
        }
      });
    } else {
      setFieldValue("statusId", "");
    }
  }, [dispatch, loading, driveId]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (driveId) {
      if (driveStudentData.placement == "Single") {
        setFieldValue(`collegeId`, driveStudentData.college.id || "");
      }
    }
  }, [dispatch, loading, params]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (values?.type?.includes("CHEQUE")) {
      setFieldValue("isCheque", true);
    } else {
      setFieldValue("isCheque", false);
    }

    if (values?.type?.includes("RETENTION")) {
      setFieldValue("isRetention", true);
    } else {
      setFieldValue("isRetention", false);
    }
  }, [values?.type]);

  useEffect(() => {
    if (params?.isDrive) {
      var tempArray = [];
      if (driveData?.participateColleges != null) {
        tempArray = driveData?.participateColleges?.map((d) => {
          return d.id;
        });
      }
      tempArray.push(driveData?.college?.id);
      let newArray = collegeData?.data?.filter((el) => {
        return tempArray.includes(el.id);
      });
      setCollege(newArray);
    } else if (params?.isIntern) {
      setCollege(collegeData?.data);
    } else {
      setCollege(collegeData?.data);
    }
  }, [dispatch, driveData, loading, params]);

  let projectTypeList = ["IDP", "UDP"];

  let convertToEmployee = async (productionDate) => {
    // setConvertEmployeeLoading(true)
    if (productionDate == "") {
      setProductionDateError({ flag: true, title: "This field is required" });
    } else if (!productionDate) {
      setProductionDateError({ flag: true, title: "This field is required" });
    } else if (productionDate != "") {
      setProductionDateError({ flag: false, title: "" });

      var defaultDate = new Date();
      defaultDate.setFullYear(1970, 0, 1);
      if (
        productionDate?.setHours(0, 0, 0, 0) == defaultDate.setHours(0, 0, 0, 0)
      ) {
        setProductionDateError({ flag: true, title: "This field is required" });
      } else if (
        new Date(productionDate)?.setHours(0, 0, 0, 0) <
        new Date()?.setHours(0, 0, 0, 0)
      ) {
        setProductionDateError({
          flag: true,
          title: "Date must be greater than today's date",
        });
      } else {
        setProductionDateError({ flag: false, title: "" });
        // if (productionDate !== "") {
        setConvertEmployeeLoading(true);
        try {
          let resp = await dispatch(
            convertStudentToEmployee({
              id: studentId,
              productionDate: productionDate,
            })
          ).unwrap();
          if (resp?.success) {
            setConvertEmployeeLoading(false);
            handleClose();
            const formData = new FormData();
            formData.append("resume", "");
            for (let key in newValues) {
              formData.append(key, newValues[key]);
            }
            try {
              if (!studentId) {
                await dispatch(
                  addStudentInDrive({ body: formData, driveId })
                ).unwrap();
                if (!driveId) navigation(`/interns`);
                else navigation(`/drives/view/${encryption(driveId)}`);
              } else {
                await dispatch(
                  updateStudentById({ driveId, studentId, body: formData })
                ).unwrap();
                if (!driveId) navigation(`/interns`);
                else navigation(`/drives/view/${encryption(driveId)}`);
              }
            } catch (error) {
              toast.error(error.message);
            }
          }
        } catch (error) {
          toast.error(error.message);
          handleClose();
        }
      }
    }
  };

  if (loading) {
    return <Loader />;
  }

  // 	const submitNotes = async () => {
  // console.log(values,"pdpdpd");
  // // {...getFieldProps(`statusId`)}
  // if(values?.note&&values?.note.length>0){
  // 	console.log("hello",tempObj);
  // 	setFieldValue(`statusId`, tempObj?.id || "")
  // 	handleCloseNotes();
  // 	setNotesError('')
  // }else{
  // setNotesError('Please enter notes')
  // }

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            {driveId
              ? studentId
                ? `Edit Student : ${firstName} ${lastName}`
                : "Add Student"
              : studentId
              ? `Edit Intern : ${firstName} ${lastName}`
              : "Add Intern"}
            {/* { studentId ? `Edit Student : ${firstName} ${lastName}` : "Add Student" }
            { driveId && studentId ? `Edit Student : ${firstName} ${lastName}` : "Add Student" } */}
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to={driveId ? `/drives/view/${encryption(driveId)}` : `/interns`}
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back
          </Button>
        </div>
      </Stack>
      <Card className="Edit-Intern-wrapper" pb={3}>
        <Container maxWidth="xl">
          <form className="Edit-Intern-form" onSubmit={handleSubmit}>
            {" "}
            {/*onload={myFunction()}>*/}
            <Grid
              className="gray-box Employee_Detail_Content Edit-Intern-form-grid"
              container
              spacing={3}
              sx={{ padding: "24px 0" }}
            >
              <Grid
                className="Intern-profile-detail"
                item
                xs={12}
                direction="row"
                justifyContent="space-between"
                mb={3}
              >
                <Grid
                  className="profile-item-left"
                  item
                  xs={6}
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "flex-start",
                  //   marginTop: "20px",
                  //   marginLeft: "20px",
                  // }}
                >
                  <div className="profile-image-part">
                    <div className="circle">
                      <Box
                        component="img"
                        src={
                          values.studentImage
                            ? typeof values.studentImage === "object"
                              ? URL.createObjectURL(values.studentImage)
                              : `${BASE_URL}/${values.studentImage}`
                            : values?.gender == "F"
                            ? "/assets/images/female.png"
                            : "/assets/images/male.png"
                        }
                      />
                      <div className="p-image">
                        <i
                          className="upload-button"
                          onClick={() => {
                            fileInput.current.click();
                          }}
                        >
                          <PhotoCameraIcon />
                        </i>
                        <input
                          ref={fileInput}
                          className="file-upload"
                          type="file"
                          accept="image/*"
                          onChange={handleImageChange}
                        />
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid className="profile-item-right" item xs={6}>
                  <Grid
                    className="profile-item-right-container"
                    container
                    pt={3}
                  >
                    <Grid className="profile-item-container-grid" item xs={6}>
                      <Grid container>
                        <Grid item>
                          <div
                            className="custome-uploadBox small profile-image-part"
                            style={{ position: "relative", width: "120px" }}
                            onClick={(e) => {
                              values.internResume
                                ? !values?.internResume?.webViewLink
                                  ? window.open(
                                      URL.createObjectURL(values?.internResume),
                                      "_blank"
                                    )
                                  : window.open(
                                      values?.internResume?.webViewLink,
                                      "_blank"
                                    )
                                : internResumeFileInput.current.click();
                            }}
                          >
                            {values.internResume && (
                              <>
                                {checkIfImage(values.internResume) ? (
                                  <>
                                    <InsertDriveFileIcon />
                                    <Typography variant="caption">
                                      {getFileName(values.internResume)}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                    <InsertDriveFileIcon />
                                    <Typography variant="caption">
                                      {getFileName(values.internResume)}
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}

                            {values.internResume && (
                              <CancelRoundedIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteInternResume(values.internResume);
                                }}
                                className="closeIcon"
                              />
                            )}

                            <div className="p-image">
                              {!values.internResume && (
                                <i className="upload-button">
                                  <AddCircleIcon />
                                </i>
                              )}

                              {!values.internResume && (
                                <input
                                  ref={internResumeFileInput}
                                  className="file-upload"
                                  type="file"
                                  accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
                                  onChange={handleInternResumeChange}
                                />
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <FormHelperText
                        error={Boolean(errors?.internResume)}
                        style={{ margin: "5px 0 0 0" }}
                      >
                        {touched?.internResume && errors?.internResume}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                  <Stack
                    mb={1}
                    style={{ alignItems: "center", width: "120px" }}
                  >
                    <Typography>Resume</Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Enrollment Number"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 64 }}
                  helperText={
                    touched?.enrollmentNumber && errors?.enrollmentNumber
                  }
                  error={Boolean(
                    touched?.enrollmentNumber && errors?.enrollmentNumber
                  )}
                  {...getFieldProps("enrollmentNumber")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                {/*<FormControl
                    error={Boolean(touched?.collegeId && errors?.collegeId)}
                    size="small"
                    fullWidth
                  >
                    <InputLabel>College Name</InputLabel>
                    <Select
                      label="Department"
                      size="small"
                      defaultValue={""}
                      {...getFieldProps("collegeId")}
                    >
                      {collegeData.map((college) => {
                        return (
                          <MenuItem key={college.id} value={college.id}>
                            {college.collegeName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText style= {{margin: '5px 0 0 0'}}>
                      {touched?.collegeId && errors?.collegeId}
                    </FormHelperText>
                    </FormControl> */}
                <Autocomplete
                  size="small"
                  fullWidth
                  options={college || []}
                  getOptionLabel={(option) => option.collegeName || ""}
                  //isOptionEqualToValue={(option, value) => option === value}
                  {...getFieldProps(`collegeId`)}
                  onChange={(event, newValue) => {
                    setFieldValue(`collegeId`, newValue?.id || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={Boolean(touched?.collegeId && errors?.collegeId)}
                      helperText={touched?.collegeId && errors?.collegeId}
                      label="College Name"
                    />
                  )}
                  value={
                    collegeData?.data?.find(
                      (clg) => clg.id === values.collegeId
                    ) || ""
                  }
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="First Name"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 64 }}
                  helperText={touched?.firstName && errors?.firstName}
                  error={Boolean(touched?.firstName && errors?.firstName)}
                  {...getFieldProps("firstName")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Middle Name"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 64 }}
                  helperText={touched?.middleName && errors?.middleName}
                  error={Boolean(touched?.middleName && errors?.middleName)}
                  {...getFieldProps("middleName")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Last Name"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 64 }}
                  helperText={touched?.lastName && errors?.lastName}
                  error={Boolean(touched?.lastName && errors?.lastName)}
                  {...getFieldProps("lastName")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Email"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 512 }}
                  helperText={touched?.email && errors?.email}
                  error={Boolean(touched?.email && errors?.email)}
                  {...getFieldProps("email")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Contact"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  helperText={touched?.contact && errors?.contact}
                  error={Boolean(touched?.contact && errors?.contact)}
                  {...getFieldProps("contact")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Year"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 32 }}
                  helperText={touched?.batch && errors?.batch}
                  error={Boolean(touched?.batch && errors?.batch)}
                  {...getFieldProps("batch")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                {/*<FormControl
                    error={Boolean(
                      touched?.educationTypeId && errors?.educationTypeId
                    )}
                    size="small"
                    sx={{ minWidth: 120 }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Education Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Education Type"
                      size="small"
                      onChange={handleChange}
                      {...getFieldProps(`educationTypeId`)}
                    >
                      {educationData.data &&
                        educationData.data.map((e, index) => (
                          <MenuItem key={index} value={e.id}>
                            {e.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style= {{margin: '5px 0 0 0'}}>
                      {touched?.educationTypeId && errors?.educationTypeId}
                    </FormHelperText>
                  </FormControl>*/}

                <Autocomplete
                  size="small"
                  fullWidth
                  options={educationData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`educationTypeId`)}
                  onChange={(event, newValue) =>
                    handleEducationTypeChange(event, newValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={Boolean(
                        touched?.educationTypeId && errors?.educationTypeId
                      )}
                      helperText={
                        touched?.educationTypeId && errors?.educationTypeId
                      }
                      label="Education Type"
                    />
                  )}
                  value={educationData.data.find(
                    (clg) => clg.id === values.educationTypeId
                  )}
                  error={Boolean(true)}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                {/*<FormControl
                    error={Boolean(touched?.courseId && errors?.courseId)}
                    size="small"
                    sx={{ minWidth: 120 }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Course"
                      size="small"
                      onChange={handleChange}
                      {...getFieldProps(`courseId`)}
                    >
                      {courseData.data &&
                        courseData.data.map((course, index) => (
                          <MenuItem key={index} value={course.id}>
                            {course.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style= {{margin: '5px 0 0 0'}}>
                      {touched?.courseId && errors?.courseId}
                    </FormHelperText>
                  </FormControl>*/}

                <Autocomplete
                  size="small"
                  fullWidth
                  options={courseData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`courseId`)}
                  onChange={(event, newValue) => {
                    setFieldValue(`courseId`, newValue?.id || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={Boolean(touched?.courseId && errors?.courseId)}
                      helperText={touched?.courseId && errors?.courseId}
                      label="Course"
                    />
                  )}
                  value={courseData.data.find(
                    (clg) => clg.id === values.courseId
                  )}
                  error={Boolean(true)}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="10th Percentage"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  helperText={touched?.sscPercentage && errors?.sscPercentage}
                  error={Boolean(
                    touched?.sscPercentage && errors?.sscPercentage
                  )}
                  {...getFieldProps("sscPercentage")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="12th Percentage"
                  variant="outlined"
                  disabled={hscDisable}
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  helperText={touched?.hscPercentage && errors?.hscPercentage}
                  error={Boolean(
                    touched?.hscPercentage && errors?.hscPercentage
                  )}
                  {...getFieldProps("hscPercentage")}
                />
              </Grid>

              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="12th (PCM)"
                  variant="outlined"
                  disabled={hscDisable}
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  {...getFieldProps("pcm")}
                  helperText={touched?.pcm && errors?.pcm}
                  error={Boolean(
                    touched?.pcm && errors?.pcm
                  )}
                />
              </Grid>

              <Grid className="add-Intern-field" item xs={4}>
                {/*<FormControl
                    error={Boolean(touched?.courseId && errors?.courseId)}
                    size="small"
                    sx={{ minWidth: 120 }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Hsc Course
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Course"
                      size="small"
                      onChange={handleChange}
                      {...getFieldProps(`hscCourseId`)}
                    >
                      {hscCourseData.data &&
                        hscCourseData.data.map((course, index) => (
                          <MenuItem key={index} value={course.id}>
                            {course.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style= {{margin: '5px 0 0 0'}}>
                      {touched?.hscCourseId && errors?.hscCourseId}
                    </FormHelperText>
                  </FormControl>*/}

                <Autocomplete
                  size="small"
                  fullWidth
                  disabled={hscCourseDisable}
                  options={hscCourseData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`hscCourseId`)}
                  onChange={(event, newValue) => {
                    setFieldValue(`hscCourseId`, newValue?.id || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={Boolean(
                        touched?.hscCourseId && errors?.hscCourseId
                      )}
                      helperText={touched?.hscCourseId && errors?.hscCourseId}
                      label="HSC Course"
                    />
                  )}
                  value={
                    hscCourseData.data.find(
                      (clg) => clg.id === values.hscCourseId
                    ) || ""
                  }
                  error={Boolean(true)}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  name="diplomaPercentage"
                  label="Diploma CGPA"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  helperText={
                    touched?.diplomaPercentage && errors?.diplomaPercentage
                  }
                  error={Boolean(
                    touched?.diplomaPercentage && errors?.diplomaPercentage
                  )}
                  {...getFieldProps("diplomaPercentage")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Graduation CGPA"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  helperText={
                    touched?.graduationPercentage &&
                    errors?.graduationPercentage
                  }
                  error={Boolean(
                    touched?.graduationPercentage &&
                      errors?.graduationPercentage
                  )}
                  {...getFieldProps("graduationPercentage")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Master CGPA"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 5 }}
                  helperText={
                    touched?.masterPercentage && errors?.masterPercentage
                  }
                  error={Boolean(
                    touched?.masterPercentage && errors?.masterPercentage
                  )}
                  {...getFieldProps("masterPercentage")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                {/* <FormControl
                    error={Boolean(touched?.statusId && errors?.statusId)}
                    size="small"
                    sx={{ minWidth: 120 }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Status
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Education Type"
                      {...getFieldProps(`statusId`)}
                      size="small"
                      disabled={!driveId ? true : false}
                      onChange={handleChange}
                      
                    >
                      {studentData.data &&
                        studentData.data.map((student, index) => (
                          <MenuItem key={index} value={student.id}>
                            {student.status}
                          </MenuItem>
                        ))}
                    </Select>
                    <FormHelperText style= {{margin: '5px 0 0 0'}}>
                      {touched?.statusId && errors?.statusId}
                    </FormHelperText>
                  </FormControl> */}

                <Autocomplete
                  size="small"
                  fullWidth
                  autoComplete
                  // {...getFieldProps(`statusId`)}
                  options={dropdownStudent(studentData.data) || []}
                  // disabled={!driveId ? true : false}
                  getOptionLabel={(option) => option?.status || ""}
                  // isOptionEqualToValue={(option, value) => option === value}
                  onChange={(event, newValue) => {
                    // console.log("newValue", newValue);
                    // if(newValue.status==='Intern Backout'){setTempObj(newValue);setFieldValue(`statusId`,"");handleOpenNotes()}else{setFieldValue(`statusId`, newValue?.id || "")};
                    setTempObj(newValue);
                    setFieldValue("statusId", newValue?.newStatusId || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={Boolean(touched?.statusId && errors?.statusId)}
                      helperText={touched?.statusId && errors?.statusId}
                      label="Status"
                    />
                  )}
                  value={
                    (statusId
                      ? dropdownStudent(studentData?.data)?.find(
                          (stu) => stu?.newStatusId == statusId
                        )
                      : dropdownStudent(studentData?.data)?.find(
                          (stu) => stu?.newStatusId == values.statusId
                        )) || ""
                  }

                  // value={studentData.data.find(
                  //   (stu) =>
                  //     stu.id == values.statusId,
                  // )}
                  // value={values.statusId}
                />
              </Grid>
              {![11, 2, 3, 4].includes(Number(values.statusId)) && (
                <>
                  <Grid className="add-Intern-field" item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Joining Date"
                        // disabled={driveId}
                        autoComplete="off"
                        name="joiningDate"
                        value={values.joiningDate}
                        inputFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        onChange={(newValue) => {
                          setFieldValue("joiningDate", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={Boolean(
                              touched?.joiningDate && errors?.joiningDate
                            )}
                            helperText={
                              touched?.joiningDate && errors?.joiningDate
                            }
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid className="add-Intern-field" item xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Production Date"
                        minDate={new Date()}
                        inputFormat="dd/MM/yyyy"
                        name="productionDate"
                        // value={productionDate}
                        value={values?.productionDate}
                        onChange={(newValue) => {
                          // if( new Date(newValue)?.setHours(0,0,0,0) > new Date()?.setHours(0,0,0,0))
                          // {
                          //   setErrors({...errors, "productionDate":""})
                          // }
                          // setProductionDate(newValue);
                          setFieldValue("productionDate", newValue || "");
                        }}
                        // {...getFieldProps("productionDate")}

                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            // helperText={productionDateError?.title && productionDateError?.title}
                            // error={Boolean(productionDateError?.flag && productionDateError?.flag)}
                            helperText={
                              touched?.productionDate && errors?.productionDate
                            }
                            error={Boolean(
                              touched?.productionDate && errors?.productionDate
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </>
              )}
              <Grid className="add-Intern-field" item xs={4}>
                {/*<FormControl
                    error={Boolean(touched?.technologyId && errors?.technologyId)}
                    size="small"
                    sx={{ minWidth: 120 }}
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-helper-label">
                      Technology
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Technology"
                      defaultValue=""
                      size="small"
                      {...getFieldProps(`technologyId`)}
                    >
                      {Array.isArray(technologyData.data) &&
                        technologyData.data.map((tech) => {
                          return (
                            <MenuItem key={tech.id} value={tech.id}>
                              {tech.title}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText style= {{margin: '5px 0 0 0'}}>
                      {touched?.technologyId && errors?.technologyId}
                    </FormHelperText>
                  </FormControl>*/}
                <Autocomplete
                  size="small"
                  fullWidth
                  options={technologyData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`technologyId`)}
                  onChange={(event, newValue) => {
                    setFieldValue(`technologyId`, newValue?.id || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      error={Boolean(
                        touched?.technologyId && errors?.technologyId
                      )}
                      helperText={touched?.technologyId && errors?.technologyId}
                      label="Technology"
                    />
                  )}
                  value={technologyData.data.find(
                    (clg) => clg.id === values.technologyId
                  )}
                  error={Boolean(true)}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  disabled={driveId}
                  size="small"
                  name="projectType"
                  onChange={(event, newValue) => {
                    setFieldValue(`projectType`, newValue || "");
                  }}
                  value={values.projectType}
                  options={projectTypeList || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Project Type"
                      disabled={driveId}
                      autoComplete="off"
                      // error={Boolean(touched?.mode && errors?.mode)}
                      // helperText={touched?.mode && errors?.mode}
                    />
                  )}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  name="mode"
                  disabled={driveId}
                  onChange={(event, newValue) => {
                    setFieldValue(`mode`, newValue || "");
                  }}
                  value={values.mode}
                  options={modeList || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Mode"
                      disabled={driveId}
                      autoComplete="off"
                      error={Boolean(touched?.mode && errors?.mode)}
                      helperText={touched?.mode && errors?.mode}
                    />
                  )}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // disabled={driveId ? true : false}
                  disabled={driveId}
                  size="small"
                  name="mode"
                  onChange={(event, newValue) => {
                    setFieldValue(`commitmentDuration`, newValue || "");
                  }}
                  value={values.commitmentDuration}
                  options={commitmentDurationList || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      disabled={driveId}
                      label="Commitment Duration"
                      autoComplete="off"
                      error={Boolean(
                        touched?.commitmentDuration &&
                          errors?.commitmentDuration &&
                          values.statusId === 5
                      )}
                      helperText={
                        touched?.commitmentDuration &&
                        errors?.commitmentDuration &&
                        values.statusId === 5
                      }
                    />
                  )}
                />
              </Grid>
              {(tempObj?.status === "Intern Backout" ||
                values.statusId === 6) && (
                <Grid className="add-Intern-field" item xs={4}>
                  <TextField
                    rows={4}
                    multiline
                    fullWidth
                    autoComplete="off"
                    label="Reason For Intern Back Out"
                    variant="outlined"
                    size="small"
                    // inputProps={{ maxLength: 5 }}
                    helperText={touched?.note && errors?.note}
                    error={Boolean(touched?.note && errors?.note)}
                    {...getFieldProps("note")}
                  />
                </Grid>
              )}
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Aptitude Marks"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 10 }}
                  helperText={touched?.marks && errors?.marks}
                  error={Boolean(touched?.marks && errors?.marks)}
                  {...getFieldProps("marks")}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  options={gradeData || []}
                  getOptionLabel={(option) => option?.grade || ""}
                  onChange={(event, newValue) => {
                    setFieldValue(`evaluationGrade`, newValue?.grade || "");
                  }}
                  value={
                    gradeData.find(
                      (g) => g?.grade == values?.evaluationGrade
                    ) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Evaluation Grade"
                      autoComplete="off"
                      error={Boolean(
                        touched?.evaluationGrade && errors?.evaluationGrade
                      )}
                      helperText={
                        touched?.evaluationGrade && errors?.evaluationGrade
                      }
                    />
                  )}
                />
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <TextField
                  rows={4}
                  multiline
                  fullWidth
                  autoComplete="off"
                  label="Comments"
                  variant="outlined"
                  size="small"
                  // inputProps={{ maxLength: 5 }}
                  helperText={touched?.comments && errors?.comments}
                  error={Boolean(touched?.comments && errors?.comments)}
                  {...getFieldProps("comments")}
                />
              </Grid>
              <Grid
                className="add-Intern-field radio-select-space"
                item
                mt={2}
                xs={4}
              >
                <FormControl
                  component="fieldset"
                  size="small"
                  error={Boolean(touched.gender && errors.gender)}
                >
                  <FormLabel component="legend">Gender</FormLabel>
                  <RadioGroup
                    row
                    aria-label="gender"
                    name="gender"
                    onChange={handleChange}
                    value={values.gender}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio size="small" />}
                      label="Male"
                      style={{ color: "#637381" }}
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio size="small" />}
                      label="Female"
                      style={{ color: "#637381" }}
                    />
                  </RadioGroup>
                  <FormHelperText style={{ margin: "5px 0 0 0" }}>
                    {touched.gender && errors.gender}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <Stack direction="row" alignItems="center" mb={2}>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={values.wishlist}
                            {...getFieldProps("wishlist")}
                          />
                        }
                        label="Wishlist"
                      />
                    </FormGroup>
                  </div>
                </Stack>
              </Grid>
              <Grid className="add-Intern-field" item xs={4}>
                <Stack direction="row" alignItems="center" mb={2}>
                  <div>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={driveId}
                            checked={values.isUnderServiceAgreement}
                            {...getFieldProps("isUnderServiceAgreement")}
                          />
                        }
                        label="Under Service Agreement?"
                      />
                    </FormGroup>
                  </div>
                </Stack>
              </Grid>
              <Grid className="add-Intern-field" m="2" container spacing={2}>
                {/* {console.log(values, "values",serviceType,"serviceType")} */}
                {values?.isUnderServiceAgreement && (
                  <Grid m={2} container spacing={2}>
                    <Grid item xs={4}>
                      <FormControl style={{ width: "100%" }}>
                        <Autocomplete
                          multiple
                          labelid="demo-simple-select-helper-label"
                          id="demo-simple-select-helper"
                          label="Agreement Type"
                          size="small"
                          name="type"
                          onChange={(event, newValue) => {
                            // console.log(newValue, "newValue");
                            setFieldValue(`type`, newValue || "");
                          }}
                          value={values?.type?.length > 0 ? values?.type : []}
                          options={serviceType || []}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Agreement Type"
                              error={Boolean(touched?.type && errors?.type)}
                              helperText={touched?.type && errors?.type}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                )}

                {values?.isCheque && values?.isUnderServiceAgreement && (
                  <>
                    <Grid m={1} container>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        m={1}
                      >
                        <Typography variant="h6" gutterBottom ml={3}>
                          Cheque Details
                        </Typography>
                      </Stack>
                      <Grid mt={0} mb={0} ml={1} mr={1} container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            style={{ width: "100%", paddingTop: "0px" }}
                            value={values?.bankName || ""}
                            label="Bank Name"
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            onChange={handleChange}
                            {...getFieldProps("bankName")}
                            error={Boolean(
                              touched?.bankName && errors?.bankName
                            )}
                            helperText={touched?.bankName && errors?.bankName}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            style={{ width: "100%", paddingTop: "0px" }}
                            value={values?.chequeAmount || ""}
                            label="Cheque Amount"
                            size="small"
                            autoComplete="off"
                            onChange={handleChange}
                            variant="outlined"
                            {...getFieldProps("chequeAmount")}
                            error={Boolean(
                              touched?.chequeAmount && errors?.chequeAmount
                            )}
                            helperText={
                              touched?.chequeAmount && errors?.chequeAmount
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            style={{ width: "100%" }}
                            label="IFSC Code"
                            value={values?.IFSC || ""}
                            variant="outlined"
                            autoComplete="off"
                            onChange={handleChange}
                            size="small"
                            {...getFieldProps("IFSC")}
                            error={Boolean(touched?.IFSC && errors?.IFSC)}
                            helperText={touched?.IFSC && errors?.IFSC}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            style={{ width: "100%" }}
                            value={values?.bankBranch || ""}
                            label="Branch Name"
                            autoComplete="off"
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            {...getFieldProps("bankBranch")}
                            error={Boolean(
                              touched?.bankBranch && errors?.bankBranch
                            )}
                            helperText={
                              touched?.bankBranch && errors?.bankBranch
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            style={{ width: "100%" }}
                            value={values?.bankAccountNumber || ""}
                            label="Account Number"
                            variant="outlined"
                            autoComplete="off"
                            onChange={handleChange}
                            size="small"
                            {...getFieldProps("bankAccountNumber")}
                            error={Boolean(
                              touched?.bankAccountNumber &&
                                errors?.bankAccountNumber
                            )}
                            helperText={
                              touched?.bankAccountNumber &&
                              errors?.bankAccountNumber
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            type="number"
                            autoComplete="off"
                            style={{ width: "100%" }}
                            value={values?.noOfCheque || ""}
                            label="Number of Cheque"
                            variant="outlined"
                            onChange={handleChange}
                            size="small"
                            {...getFieldProps("noOfCheque")}
                            error={Boolean(
                              touched?.noOfCheque && errors?.noOfCheque
                            )}
                            helperText={
                              touched?.noOfCheque && errors?.noOfCheque
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            style={{ width: "100%" }}
                            value={values?.chequeNumbers || ""}
                            label="Cheque Number"
                            autoComplete="off"
                            variant="outlined"
                            onChange={handleChange}
                            size="small"
                            {...getFieldProps("chequeNumbers")}
                            error={Boolean(
                              touched?.chequeNumbers && errors?.chequeNumbers
                            )}
                            helperText={
                              touched?.chequeNumbers && errors?.chequeNumbers
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {values?.isRetention && values?.isUnderServiceAgreement && (
                  <>
                    <Grid
                      m={1}
                      container
                      sx={values.isRetention && values.isCheque}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        m={1}
                      >
                        <Typography variant="h6" gutterBottom ml={3}>
                          Retention Details
                        </Typography>
                      </Stack>
                      <Grid mt={0} mb={3} ml={1} mr={1} container spacing={2}>
                        <Grid item xs={4}>
                          <TextField
                            type="number"
                            style={{ width: "100%" }}
                            label="Retention Amount"
                            value={values?.retentionAmount || ""}
                            variant="outlined"
                            autoComplete="off"
                            onChange={handleChange}
                            size="small"
                            {...getFieldProps("retentionAmount")}
                            error={Boolean(
                              touched?.retentionAmount &&
                                errors?.retentionAmount
                            )}
                            helperText={
                              touched?.retentionAmount &&
                              errors?.retentionAmount
                            }
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
            <Grid className="Misc-Fields-Grid">
              {/* Misc. fields part */}
              {![11, 2, 3, 4].includes(Number(values.statusId)) && (
                <>
                  <Grid className="Misc-Fields-Grid-detail" item xs={12}>
                    <Typography className="Misc-Fields-heading" variant="h6">
                      Misc. Fields
                    </Typography>
                  </Grid>
                  <Grid
                    className="gray-box Employee_Detail_Content Misc-Fields-detail"
                    item
                    xs={12}
                  >
                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Work Location"
                      size="small"
                      name="workLocation"
                      {...getFieldProps(`workLocation`)}
                      onChange={(event, newValue) => {
                        setFieldValue("workLocation", newValue?.id || "");
                      }}
                      value={locationDropdown?.find(
                        (location) => values?.workLocation === location?.id
                      )}
                      options={locationDropdown || []}
                      getOptionLabel={(option) => option.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Work Location"
                          error={Boolean(
                            touched?.workLocation && errors?.workLocation
                          )}
                          helperText={
                            touched?.workLocation && errors?.workLocation
                          }
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
              {values.workLocation === 2 || values.workLocation === 3 ? (
                <Grid item xs={4}>
                  <TextField
                    label="Seat Number"
                    name="seatNumber"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 64 }}
                    fullWidth
                    {...getFieldProps("seatNumber")}
                    onChange={handleChange}
                    error={Boolean(touched?.seatNumber && errors?.seatNumber)}
                    helperText={touched?.seatNumber && errors?.seatNumber}
                  />
                </Grid>
              ) : null}
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={3}
            >
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
              >
                Submit
              </LoadingButton>
            </Stack>
          </form>
        </Container>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack spacing={2} sx={style}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Select Production Date
              </Typography>
              <DesktopDatePicker
                label="Production Date"
                minDate={new Date()}
                inputFormat="dd/MM/yyyy"
                value={productionDate}
                onChange={(newValue) => {
                  if (
                    new Date(newValue)?.setHours(0, 0, 0, 0) >
                    new Date()?.setHours(0, 0, 0, 0)
                  ) {
                    setProductionDateError({ flag: false, title: "" });
                  }
                  setProductionDate(newValue);
                  setFieldValue("productionDate", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    helperText={
                      productionDateError?.title && productionDateError?.title
                    }
                    error={Boolean(
                      productionDateError?.flag && productionDateError?.flag
                    )}
                  />
                )}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              loading={convertEmployeeLoading}
              onClick={() => convertToEmployee(productionDate)}
            >
              Submit
            </Button>
          </Stack>
        </Modal>
        {/* <Modal
        open={openNotes}
        onClose={handleCloseNotes}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack spacing={2} sx={{...style,justifyContent:'center',alignItems:'center'}} >
		<Typography id="modal-modal-title" variant="h4" component="h2">
            write a note
          </Typography>
		<TextField
									fullWidth
									multiline
									aria-label="Note..."
									minRows={4}
									inputProps={{
										inputComponent: TextareaAutosize,
										maxLength: 512,
										style: {
											resize: "auto",
										},
									}}
									{...getFieldProps("note")}
									value={values?.note || ""}
									name="note"
									label="Note"
									onChange={handleChange}
									error={notesError||false}
									helperText={notesError}
								/>
								<Button variant="contained" onClick={submitNotes}>Submit</Button>
        </Stack>
      </Modal> */}
      </Card>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddStudents,
    path: "/drives/students/add/:driveId",
    public: false,
    layout: DashboardLayout,
    group: "TPA",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddStudents,
    path: "/interns/add/",
    public: false,
    layout: DashboardLayout,
    group: "TPA",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddStudents,
    path: "/drives/students/edit/:studentId/:driveId",
    public: false,
    layout: DashboardLayout,
    group: "TPA",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddStudents,
    path: "/interns/edit/:studentId",
    public: false,
    layout: DashboardLayout,
    group: "TPA",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
