import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getAllPoll = createAsyncThunk(
	//action type string
	"poll/getAllPoll",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/getAllPoll", params, {
			headers:{
				type:'view',module:'poll'
			}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

// export const getQuestionById = createAsyncThunk(
// 	//action type string
// 	"question/getQuestionsById",
// 	// callback function
// 	async (id, { rejectWithValue }) => {
// 		try {
// 			const res = await Axios.get(`question/`, { headers: { question_id: id,type:'view',module:'questions' } });
// 			return res.data.data;
// 		} catch (error) {
// 			return rejectWithValue(error.response.data);
// 		}
// 	},
// );

export const createPoll = createAsyncThunk(
	//action type string
	"poll/createPoll",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/createPoll", body,{
				headers:{
					type:'create',module:'poll'
				}
			});
			// toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const uploadPollImages = createAsyncThunk(
	//action type string
	"poll/uploadPollImages",
	// callback function
	async ({id, body}, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/uploadPollImages", body,{
				headers:{
					id : id, type:'create',module:'poll'
				}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const deletePollById = createAsyncThunk(
	//action type string
	"poll/deletePoll",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.delete("/deletePoll", { headers: { id : id,type:'delete',module:'poll' } });
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const updateStatus = createAsyncThunk(
	//action type string
	"poll/updateStatus",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.put("/updateStatus", body, {
				headers: { id: body.id, type:'update',module:'poll' },
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const getoTotalPoll = createAsyncThunk(
	"poll/getoTotalPoll",
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/getPollCount",{
        headers: { type: 'view',module:'poll' },
      });

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.message || error.message);
		}
	},
);