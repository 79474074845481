import React, { useEffect } from "react";
import DashboardLayout from "../../layouts/dashboard";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import Loader from "../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";

import Button from "@mui/material/Button";
import { Badge, IconButton, Modal } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Drawer from "@mui/material/Drawer";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import { debounce } from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { decryption, encryption } from "../../utils/encodeString";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import Chip from "@mui/material/Chip";
import invertDirection from "../../utils/invertDirection";
import CustomPagination from "../../components/Pagination";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  getViewVisible,
  getEditVisible,
  getAddVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import ModalComponent from "./LinksComponents/ModalComponent";
import {
  deleteLink,
  getAllEmployeesDropdown,
  getLinkCount,
  getLinks,
} from "../../redux/links/linksThunk";
import {
  setSortBy,
  setOrderBy,
  setCurrentPage,
  setLimit,
} from "../../redux/links/linksSlice";
import { useConfirm } from "material-ui-confirm";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import LanguageIcon from "@mui/icons-material/Language";

function Links() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [filterChips, setFilterChips] = React.useState([]);
  const inputRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [editLink, setEditLink] = React.useState(null);
  const [heading, setHeading] = React.useState("Add Link");
  const userId = JSON.parse(localStorage.getItem("employeeId"));

  const {
    totalLinksCount,
    isSearchQuery,
    error,
    currentPage,
    totalRecords,
    limit,
    totalPages,
    status,
    orderBy,
    sortBy,
    loading,
    data,
    categories,
  } = useSelector((state) => state.link);
  const employeesData = useSelector((state) => state.link.employeesData);

  const formattedData = Array.isArray(data)
    ? data.map((item) => {
        return {
          ...item,
          assignee: item?.assignee
            ?.map((assignee) => {
              const employee = employeesData.find(
                (emp) => emp.id === assignee.id
              );
              return {
                id: assignee.id,
                firstName: employee?.firstName || "",
                lastName: employee?.lastName || "",
                role: assignee.role,
              };
            })
            .filter(Boolean),
          owner:
            employeesData?.find((employee) => employee?.id === item?.owner) ||
            null,
        };
      })
    : [];
  const getUserName = (firstName, lastName) => {
    if (firstName && lastName) {
      return firstName + " " + lastName;
    } else if (firstName) {
      return firstName;
    } else {
      return "";
    }
  };
  const formatAssignees = (assignees) => {
    if (!Array.isArray(assignees) || assignees.length === 0) {
      return "-";
    }
    return assignees.map((assignee, index) => (
      <span
        key={assignee.id}
        style={
          filterChips?.some((chip) => chip?.type === "empIds") &&
          values?.empIds?.includes(assignee?.id)
            ? { background: "yellow" }
            : {}
        }
      >
        {getUserName(assignee?.firstName, assignee?.lastName)}
        {index < assignees.length - 1 && ", "}
      </span>
    ));
  };

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  useEffect(() => {
    if (!getViewVisible("link")) {
      navigate("/dashboard");
    }
  }, []);

  // useEffect for fetching data
  useEffect(() => {
    const fetchData = async () => {
      let empIds =
        values?.empIds?.length > 0 ? JSON.stringify(values?.empIds) : [];

      try {
        const response = await dispatch(
          getLinks({
            empIds,
            limit,
            page: currentPage + 1,
            orderBy,
            sortBy,
            search: searchValue,
          })
        ).unwrap();
      } catch (error) {
        toast.error(error?.message);
      }
    };

    fetchData();
  }, [limit, currentPage, sortBy, orderBy]);

  // function for sorting
  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  // functions for pagination
  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };
  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    setFieldValue,
    errors,
    touched,
    setErrors,
  } = useFormik({
    initialValues: {
      search: "",
      empIds: [],
      category: "",
    },

    // on submit of filter
    onSubmit: async (values) => {
      let search = values?.search;
      let empIds =
        values?.empIds?.length > 0 ? JSON.stringify(values?.empIds) : [];
        let category = values?.category;
      try {
        await dispatch(getLinks({ search, empIds, limit, page: 1,category }));
        dispatch(setCurrentPage({ page: 0 }));
        dispatch(getLinkCount());

        prepareChips();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  // function for search
  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    handleSubmit();
  };
  // function for debounce search
  const debounceWithSearch = debounce(handleSearch, 500);

  // functino for preparing filter and search chips
  const prepareChips = () => {
    let filters = { ...values };
    let tempChips = [];

    for (const key in filters) {
      if (
        key === "empIds" &&
        Array.isArray(filters[key]) &&
        filters[key].length > 0
      ) {
        let employeeIdsString = "";
        let count = 0;

        filters[key].forEach((f) => {
          employeesData.forEach((d) => {
            if (f == d.id) {
              count++;
              if (count > 1) {
                employeeIdsString = `${employeeIdsString} | ${d.firstName} ${d.lastName}`;
              } else {
                employeeIdsString = `${employeeIdsString} ${d.firstName} ${d.lastName}`;
              }
            }
          });
        });

        filters[key] = `Employees: ${employeeIdsString}`;
      }
      if (filters[key] && key === "category") {
          filters[key] =
            "Category : " +filters[key]
      }
      if (
        filters[key] &&
        !(Array.isArray(filters[key]) && filters[key].length === 0)
      ) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }


    setFilterChips(tempChips);
  };

  // function for delete chip
  const onDeleteChip = (value, length) => {
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    let filters = { ...values, [value["type"]]: "" };
    if (length === 1) {
      dispatch(getLinks({ limit, page: 1, sortBy, orderBy }));
      dispatch(setCurrentPage({ page: 0 }));
    } else {
      dispatch(getLinks({ ...filters, limit, page: 1, sortBy, orderBy }));
      dispatch(setCurrentPage({ page: 0 }));
    }
    setFieldValue(value.type, "");
    if (value.type === "search") {
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

  const handleFilters = async (field, newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
  };
  const handleAddLink = () => {
    setHeading("Add Link");
    setOpen(true);
  };

  const handleDelete = (link) => {
    try {
      confirm({
        description: `Are you sure you want to delete ${link?.title}?`,
      }).then(() => {
        try {
          dispatch(deleteLink(link)).then(() => {
            dispatch(getLinkCount());
            dispatch(getLinks({ limit, page: 1, sortBy, orderBy }));
            dispatch(setCurrentPage({ page: 0 }));
          });
        } catch (error) {
          console.log(
            "%c  error:",
            "color: #0e93e0;background: #aaefe5;",
            error
          );
        }
      });
    } catch (error) {
      console.log("%c  error:", "color: #0e93e0;background: #aaefe5;", error);
    }
  };
  const handleEdit = (link) => {
    setEditLink(link);
    setHeading("Edit Link");
    setOpen(true);
  };

  useEffect(() => {
    dispatch(getAllEmployeesDropdown());
    dispatch(getLinkCount());
  }, []);

  const copyToClipboard = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Link Copied!");
      /* console.log("Copying text command was " + msg); */
    } catch (err) {
      toast.error("Link Not Copied!");
      /* console.log("Oops, unable to copy"); */
    }

    document.body.removeChild(textArea);
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Links
            <Typography variant="body2">
              Total Records : {totalLinksCount || 0}
            </Typography>
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/notebook"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back
          </Button>
          {getAddVisible("link") && (
            <Button
              onClick={handleAddLink}
              variant="contained"
              startIcon={<AddIcon />}
              className="filter-icon-part"
            >
              Add Link
            </Button>
          )}
        </div>
      </Stack>

      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div
                className="employee-classification-right"
                style={{ paddingTop: "20px" }}
              >
                <TextField
                  className="employee-search-detail"
                  fullWidth
                  autoComplete="off"
                  label="Search by title"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
                <div style={{ minWidth: "220px", maxWidth: "220px" }}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    multiple={true}
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={employeesData || []}
                    getOptionLabel={(option) =>
                      option ? `${option?.firstName} ${option?.lastName}` : ""
                    }
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        value={option?.employeeId}
                        key={option?.id}
                      >
                        {option
                          ? `${option?.firstName} ${option?.lastName}`
                          : ""}
                      </li>
                    )}
                    name="empIds"
                    onChange={(event, newValue) => {
                      // setFieldValue("assignedBy", newValue?.id || "");
                      handleFilters(
                        "empIds",
                        newValue.map((option) => option?.id) || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Employees"
                      />
                    )}
                    value={
                      employeesData?.filter((t) =>
                        values.empIds.includes(t.id)
                      ) || []
                    }
                  />
                </div>
                <div style={{ minWidth: "220px", maxWidth: "220px" }}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={categories || []}
                    getOptionLabel={(option) => option || ""}
                    renderOption={(props, option) => (
                      <li {...props} value={option} key={option}>
                        {option || ""}
                      </li>
                    )}
                    name="category"
                    onChange={(event, newValue) => {
                      // setFieldValue("assignedBy", newValue?.id || "");
                      handleFilters(
                        "category",
                       newValue
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Category"
                      />
                    )}
                    value={
                      categories?.find(
                        (t) =>
                          t.toLowerCase() === values?.category?.toLowerCase()
                      ) || []
                    }
                  />
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
        className="employee-table-Stack search-filter"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="employee-table-Stack-label search-filter-detail"
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>

      {filterChips.length !== 0 || isSearchQuery ? (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}

      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Card>
              <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                page={currentPage}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                // filter={isSearchQuery ? true : false}
              />
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ width: 300 }}>
                      <TableSortLabel
                        active={sortBy === "title"}
                        direction={sortBy === "title" ? orderBy : "asc"}
                        onClick={() => handleSorting("title")}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ width: 300 }}>Category</TableCell>
                    <TableCell sx={{ width: 150 }}>Link</TableCell>
                    <TableCell sx={{ width: 300 }}>Assignee</TableCell>
                    <TableCell sx={{ width: 200 }}>Owner</TableCell>
                    {getEditVisible("link") && getDeleteVisible("link") && (
                      <TableCell sx={{ width: 200 }}>Actions</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell
                        className="No-Record-text"
                        align="center"
                        colSpan={3}
                      >
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    Array.isArray(formattedData) &&
                    formattedData.length > 0 &&
                    formattedData.map((link, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: 200 }}
                        >
                          {link?.title || "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: 200 }}
                        >
                          {link?.category || "-"}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            width: 300,
                            display: "flex",
                            // alignItems: "center",
                          }}
                        >
                          {link?.link ? (
                            <>
                              <IconButton
                                href={link?.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                aria-label="Visit website"
                                sx={{ paddingLeft: 0 }}
                              >
                                <LanguageIcon />
                              </IconButton>
                              <IconButton
                                onClick={() => copyToClipboard(link?.link)}
                                aria-label="Copy link"
                                sx={{ paddingLeft: 0 }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </>
                          ) : (
                            "-"
                          )}
                        </TableCell>

                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: 250 }}
                        >
                          {formatAssignees(link?.assignee)}
                        </TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: 200 }}
                        >
                          <span
                            style={
                              filterChips?.some(
                                (chip) => chip?.type == "empIds"
                              ) && values?.empIds?.includes(link?.owner?.id)
                                ? { background: "yellow", fontSize: "12px" }
                                : {}
                            }
                          >
                            {getUserName(
                              link?.owner?.firstName,
                              link?.owner?.lastName
                            ) || "-"}
                          </span>
                        </TableCell>
                        {getEditVisible("link") && getDeleteVisible("link") && (
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{ width: 50 }}
                          >
                            {getEditVisible("link") && (
                              <IconButton onClick={() => handleEdit(link)}>
                                <EditIcon sx={{ color: "#f19c63" }} />
                              </IconButton>
                            )}
                            {getDeleteVisible("link") && (
                              <IconButton onClick={() => handleDelete(link)}>
                                <DeleteIcon sx={{ color: "#f19c63" }} />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Card>
          </TableContainer>
        </Scrollbar>
      </Card>
      <ModalComponent
        open={open}
        setOpen={setOpen}
        employeesData={employeesData}
        callLinkApi={handleSubmit}
        editLink={editLink}
        setEditLink={setEditLink}
        heading={heading}
        categoriesData={categories}
      />
    </Container>
  );
}

const componentConfig = {
  component: Links,
  path: "/notebook/links",
  public: false,
  layout: DashboardLayout,
  group: "notebook",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
