import DashboardLayout from '../../layouts/dashboard';
import Index from './Department/Grid';

const Department = () => {
	return <Index></Index>;
};

const componentConfig = {
	component: Department,
	path: '/department',
	public: false,
	layout: DashboardLayout,
	group: 'organization',
	sidebar: true,
	role: ['admin'],
};

export default componentConfig;
