import { MenuItem, Select } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

// function for select dropdown color according to priority value
const getPriorityColor = (priority) => {
  switch (priority) {
    case "LOW":
      return "green";
    case "MEDIUM":
      return "#fda92d";
    case "HIGH":
      return "red";
  }
};

const useStyles = makeStyles((theme) => ({
  outlinedInput: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => getPriorityColor(props.priority),
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => getPriorityColor(props.priority),
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: (props) => getPriorityColor(props.priority),
    },

  },
}));

function CustomSelect({
  priority,
  handlePriorityChange,
  ticket,
  priorityData,
}) {
  const classes = useStyles({ priority });

  return (
    <Select
      value={ticket?.priority || ""}
      onChange={(e) => handlePriorityChange(ticket, e)}
      size="small"
      inputProps={{ icon: getPriorityColor(ticket.priority) }}
      style={{ color: getPriorityColor(ticket.priority) }}
      className={classes.outlinedInput}
    >
      {priorityData.map((priority) => (
        <MenuItem value={priority} key={priority}>
          {priority}
        </MenuItem>
      ))}
    </Select>
  );
}

export default CustomSelect;
