import React, { useEffect, useState, memo } from "react";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AddIcon from "@mui/icons-material/Add";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import FormHelperText from "@mui/material/FormHelperText";
import RemoveIcon from "@mui/icons-material/Remove";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  getSerailNumberByItemIds,
  checkSerialNumber,
} from "../../../../redux/assets/thunk";

import CircularProgress from "@mui/material/CircularProgress";

const filter = createFilterOptions();

function HasManyComponent({
  assetItemId,
  title,
  isFocused,
  handleFocus,
  isInventoriable,
  itemAttribute,
  values,
  setFieldValue,
  getAttributeLabel,
  getAttributteValuesById,
  assetErrors,
  touchedAssets,
  setErrors,
  errors,
  touched,
  handleBlur,
  status,
  setStatus,
  handleChange,
  addMore,
  removeField,
  assetId,
  required,
}) {
  const [loading, setLoading] = useState(false);
  return (
    <Container maxWidth="xl">
      <Stack mt={3} direction="row" spacing={2}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {loading && <CircularProgress size={25} />}
      </Stack>
      {values[assetItemId]?.map((itmattr, index) => {
        return (
          <ItemAttributeValueFields
            itemAttributes={itmattr}
            values={values}
            isFocused={isFocused}
            handleFocus={handleFocus}
            isInventoriable={isInventoriable}
            assetItemId={assetItemId}
            index={index}
            getAttributeLabel={getAttributeLabel}
            getAttributteValuesById={getAttributteValuesById}
            removeField={removeField}
            addMore={addMore}
            key={index}
            setFieldValue={setFieldValue}
            itemAttribute={itemAttribute}
            loading={loading}
            setLoading={setLoading}
            assetErrors={assetErrors}
            touchedAssets={touchedAssets}
            setErrors={setErrors}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            status={status}
            setStatus={setStatus}
            handleChange={handleChange}
            assetId={assetId}
            required={required}
          />
        );
      })}
    </Container>
  );
}

const ItemAttributeValueFields = ({
  itemAttributes,
  values,
  isFocused,
  handleFocus,
  isInventoriable,
  assetItemId,
  index,
  getAttributeLabel,
  getAttributteValuesById,
  removeField,
  addMore,
  setFieldValue,
  itemAttribute,
  loading,
  setLoading,
  assetErrors,
  touchedAssets,
  setErrors,
  errors,
  touched,
  handleBlur,
  status,
  setStatus,
  handleChange,
  assetId,
  required,
}) => {
  const keys = Object.keys(itemAttributes);
  const lastElement = values[assetItemId].length;
  const [serialNumberOPtions, setSerialNumberOPtions] = useState([]);
  const [serialNumberError, setSerialNumberError] = useState({});
  // const [isFocused, setIsFocused] = useState({});
  const handleOnChange = (event, newValue, assetItemId, index, id) => {
    setFieldValue(`assets.${assetItemId}.${index}.serialNumber`, "");
    setFieldValue(
      `assets.${assetItemId}.${index}.${id}.value`,
      newValue?.id || ""
    );

  };

  const handleTextChange = (e, assetItemId, index, id) => {
    setFieldValue(
      `assets.${assetItemId}.${index}.${id}.freeText`,
      e.target.value || ""
    );
    // handleFocus(index, id);
    // setIsFocused({ [index]: { [id]: true } });
    // setFieldValue(`assets.${assetItemId}.${index}.serialNumber`, "");
  };

  const getSerailNumber = async (itemAttributes) => {
    try {
      setLoading(true);
      const requestBody = { ...itemAttributes };

      delete requestBody["serialNumber"];

      const response = await getSerailNumberByItemIds(requestBody);
      setSerialNumberOPtions(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const createObject = (key, idx) => {
    if (!errors["assets"]) errors["assets"] = {};
    if (!errors["assets"][key]) errors["assets"][key] = [];
    if (!errors["assets"][key][idx]) errors["assets"][key][idx] = {};
  };
  const checkSerialNumberF = async (serialNumberValue, assetItemId, index) => {
    try {
      //console.log(errors)
      const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

      if (serialNumberValue.length < 256) {
        if (!specialChars.test(serialNumberValue)) {
          const response = await checkSerialNumber({
            serialNumber: serialNumberValue,
            itemId: assetItemId,
          });

          if (response.isExist) {
            let tmpAssetsItemIdSerialNumberError = {
              serialNumber: "Duplicate serial number entered",
            };

            createObject(assetItemId, index);

            errors["assets"][assetItemId][index] =
              tmpAssetsItemIdSerialNumberError;

            setErrors({ ...errors });
            //console.log(errors)
            let tmpSerialNumberObj = { [assetItemId]: { [index]: true } };

            setSerialNumberError({ ...tmpSerialNumberObj });
          } else {
            setFieldValue(
              `assets.${assetItemId}.${index}.serialNumber`,
              serialNumberValue || ""
            );
          }
        } else {
          let tmpAssetsItemIdSerialNumberError = {
            serialNumber: "Serial Number Has Special Character",
          };
          createObject(assetItemId, index);

          errors["assets"][assetItemId][index] =
            tmpAssetsItemIdSerialNumberError;

          setErrors({ ...errors });

          let tmpSerialNumberObj = { [assetItemId]: { [index]: true } };
          setSerialNumberError({ ...tmpSerialNumberObj });
        }
      } else {
        let tmpAssetsItemIdSerialNumberError = {
          serialNumber: "Serial Number not valid",
        };

        createObject(assetItemId, index);

        errors["assets"][assetItemId][index] = tmpAssetsItemIdSerialNumberError;
        setErrors({ ...errors });
        let tmpSerialNumberObj = { [assetItemId]: { [index]: true } };
        setSerialNumberError({ ...tmpSerialNumberObj });
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (isInventoriable) {
      getSerailNumber(itemAttributes);
    }
  }, [values[assetItemId][index]]);

  const getAllowFreeTextValueById = (id, itemAttribute) => {
    let res = itemAttribute.find((item) => item.id == id);
    if (res?.allowFreeText) {
      return true;
    } else {
      return false;
    }
  };

  const getItemValue = (assetItemId, index, id) => {
    const res = values[assetItemId][index][id].freeText || "";

    return res;
  };

  return (
    <Grid container key={index} spacing={2} py={1}>
      {keys.map((id) => {
        if (id === "serialNumber") return null;
        return (
          <Grid key={uuidv4()} item xs={3}>
            {/*<InputLabel>{getAttributeLabel(id, itemAttribute)}</InputLabel>
							<Select
								size="small"
								name={`assets.${assetItemId}.${index}.${id}.value`}
								value={values[assetItemId][index][id]["value"]}
								onChange={handleOnChange}
								disabled={loading}
								input={
									<OutlinedInput label={getAttributeLabel(id, itemAttribute)} />
								}
								defaultValue=""
							>
								{getAttributteValuesById(id, itemAttribute).map((attrvalues) => (
									<MenuItem key={attrvalues.id} value={attrvalues.id}>
										{attrvalues.value}
									</MenuItem>
								))}
								</Select> */}
            {!getAllowFreeTextValueById(id, itemAttribute) ? (
              <Autocomplete
                size="small"
                fullWidth
                disabled={loading}
                defaultValue=""
                input={
                  <OutlinedInput label={getAttributeLabel(id, itemAttribute)} />
                }
                name={`assets.${assetItemId}.${index}.${id}.value`}
                options={getAttributteValuesById(id, itemAttribute) || []}
                getOptionLabel={(option) => option.value || ""}
                value={
                  getAttributteValuesById(id, itemAttribute).find(
                    (i) => i.id === values[assetItemId][index][id]["value"]
                  ) || ""
                }
                onChange={(event, newValue) => {
                  handleOnChange(event, newValue, assetItemId, index, id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getAttributeLabel(id, itemAttribute)}
                    error={
                      required
                        ? Boolean(
                            touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                              "value"
                            ] && assetErrors?.[assetItemId]?.[index]?.[id]
                          )
                        : ""
                    }
                    helperText={
                      required
                        ? touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                            "value"
                          ] && assetErrors?.[assetItemId]?.[index]?.[id]
                        : ""
                    }
                  />
                )}
                //error={true}
              />
            ) : (
              <TextField
                size="small"
                name={`assets.${assetItemId}.${index}.${id}.freeText`}
                onChange={(e) => handleTextChange(e, assetItemId, index, id)}
                value={getItemValue(assetItemId, index, id) || ""}
                label={getAttributeLabel(id, itemAttribute)}
                fullWidth
                error={
                  required
                    ? Boolean(
                        touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                          "freeText"
                        ] && assetErrors?.[assetItemId]?.[index]?.[id]
                      )
                    : ""
                }
                helperText={
                  required
                    ? touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                        "freeText"
                      ] && assetErrors?.[assetItemId]?.[index]?.[id]
                    : ""
                }
                inputProps={isFocused?.[index]?.[id] ? { autoFocus: true } : {}}
              />
            )}
          </Grid>
        );
      })}

      {isInventoriable ? (
        <Grid item xs={4}>
          <Autocomplete
            value={
              /* serialNumberOPtions.find(
							(sl) => sl === */ values[assetItemId][index]["serialNumber"]
              /* ) || "" */
            }
            closeText="Close"
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                checkSerialNumberF(newValue, assetItemId, index);
              } else if (newValue && newValue.inputValue) {
                checkSerialNumberF(newValue.inputValue, assetItemId, index);
              } else {
                checkSerialNumberF(newValue, assetItemId, index);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            size="small"
            selectOnFocus
            clearOnBlur
            disabled={loading}
            fullWidth
            handleHomeEndKeys
            options={serialNumberOPtions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option;
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            freeSolo
            renderInput={(params) => (
              <TextField
                error={
                  Boolean(
                    serialNumberError?.[assetItemId]?.[index] &&
                      assetErrors?.[assetItemId]?.[index]?.["serialNumber"]
                  ) ||
                  Boolean(
                    touchedAssets?.[assetItemId]?.[index]?.["serialNumber"] &&
                      assetErrors?.[assetItemId]?.[index]?.["serialNumber"]
                  )
                }
                helperText={
                  (serialNumberError?.[assetItemId]?.[index] &&
                    assetErrors?.[assetItemId]?.[index]?.["serialNumber"]) ||
                  (touchedAssets?.[assetItemId]?.[index]?.["serialNumber"] &&
                    assetErrors?.[assetItemId]?.[index]?.["serialNumber"])
                }
                {...params}
                label="Serial Number"
              />
            )}
          />
        </Grid>
      ) : (
        ""
      )}

      {index + 1 === lastElement ? (
        <Grid item xs={2}>
          {lastElement !== 1 && (
            <IconButton onClick={() => removeField(assetItemId, index)}>
              <RemoveIcon></RemoveIcon>
            </IconButton>
          )}
          <IconButton onClick={() => addMore(assetItemId, itemAttributes)}>
            <AddIcon></AddIcon>
          </IconButton>
        </Grid>
      ) : (
        <Grid item xs={2}>
          <IconButton onClick={() => removeField(assetItemId, index)}>
            <RemoveIcon></RemoveIcon>
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};

function checkIsEqual(prevState, nextState) {
  if (!isEqual(prevState.values, nextState.values)) {
    return false;
  }

  if (!isEqual(prevState.touchedAssets, nextState.touchedAssets)) {
    return false;
  }

  if (
    prevState.assetErrors &&
    nextState.assetErrors &&
    !isEqual(prevState.assetErrors, nextState.assetErrors)
  ) {
    return false;
  }

  return true;
}
const MemoizedHasManyComponent = memo(HasManyComponent, checkIsEqual);

export default MemoizedHasManyComponent;

// export default memo(HasManyComponent);