import { useState } from "react";

import { useNavigate } from "react-router-dom";
import localStorage from "../../service/localStorage";
import { useDispatch } from "react-redux";
import { logOutUser } from "../../redux/auth/authSlice";
import Chat from "./Chat";
import packageJson from "../../../package.json";

import { useTheme } from "@mui/material/styles";
import { styled, alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Stack from "@mui/material/Stack";

import AccountPopover from "./AccountPopover";
import NotificationsPopover from "./NotificationsPopover";
import sidebarConfig from "./SidebarConfig";
import NavSection from "../../components/NavSection";
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";

const drawerWidth = 280;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: "hidden",
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create("width", {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: "hidden",
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up("sm")]: {
		width: `calc(${theme.spacing(9)} + 1px)`,
	},
});

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	justifyContent: "flex-end",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(["width", "margin"], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: "nowrap",
	boxSizing: "border-box",
	...(open && {
		...openedMixin(theme),
		"& .MuiDrawer-paper": openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		"& .MuiDrawer-paper": closedMixin(theme),
	}),
}));

const AppBarRootStyle = styled(AppBar)(({ theme }) => ({
	boxShadow:
		" 0 0 2px 0 rgba(145 158 171 0.24), 0 16px 32px -4px rgba(145 158 171 0.24)",
	backdropFilter: "blur(6px)",
	WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
	backgroundColor: alpha(theme.palette.background.default, 0.72),
}));

export default function MiniDrawer({ children }) {
	const theme = useTheme();
	const [open, setOpen] = useState(true);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const logOut = () => {
		localStorage.removeItem("accessToken");
		localStorage.removeItem("email");
		navigate("/login");
		dispatch(logOutUser());
	};

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	return (
		<div className="background-color">
			<Box sx={{ display: "flex" }}>
				<CssBaseline />
				<AppBarRootStyle className="header-wrapper" position="fixed" open={open}>
					<Toolbar className="header-wrapper-detail">
						<IconButton
							className="collapse-button"
							color="inherit"
							aria-label="open drawer"
							onClick={handleDrawerOpen}
							edge="start"
							sx={{
								paddingLeft: "15px",
								...(open && { display: "none" }),
							}}
						>
							<MenuIcon />
						</IconButton>
						<Box sx={{ flexGrow: 1 }} />

						<Stack
						className="header-right-wrapper-detail"
							direction="row"
							alignItems="center"
							spacing={{ xs: 0.5, sm: 1.5 }}
						>
							{/* <LanguagePopover /> */}
							{/* <NotificationsPopover /> */}
							<AccountPopover logOut={logOut} />
						</Stack>
					</Toolbar>
				</AppBarRootStyle>
				<Drawer variant="permanent" open={open} className="sidebar">
					<DrawerHeader className="sidebarheader">
						<Logo />
						<IconButton className="sidebar-back-button" onClick={handleDrawerClose}>
							{theme.direction === "rtl" ? (
								<ChevronRightIcon />
							) : (
								<ChevronLeftIcon />
							)}
						</IconButton>
					</DrawerHeader>
					<Divider />
					<Scrollbar>
						<NavSection navConfig={sidebarConfig} pb={5} />

						{open && (
							<Stack
								className="version-section"
								direction="row"
								justifyContent="space-between"
							>
								<p>Cybercom Creation</p>
								<p>{packageJson.version}</p>
							</Stack>
						)}
					</Scrollbar>
				</Drawer>
				<Box
					component="main"
					className="main-content"
					sx={{ flexGrow: 1, marginTop: "1rem", marginBottom: "2rem" }}
				>
					<div className="wrapper-container">
						<DrawerHeader className="main-drawerheader" />
						{children}
						{/* <Chat /> */}
					</div>
				</Box>
			</Box>
		</div>
	);
}
