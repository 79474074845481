import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import "./Dashboard.css";
import {
  indianDateFormat,
  getYearDiff,
  DateFormat,
  tomorrowDate,
  getOneWeekBeforeDate,
  getTwoWeekBeforeDate,
  dayAfterTomorrowDate,
  firstDayOfWeek,
  lastDayOfWeek,
  dateFormat,
} from "../utils/dateFormat";
import { getViewVisible, getEditVisible } from "../utils/userPermission";

import moment from "moment";

const NewsFeed = (data) => {
  const dispatch = useDispatch();
  const [commitment, setCommitment] = useState();
  const [commitmentOver, setCommitmentOver] = useState();
  const [commitmentOverMore, setCommitmentOverMore] = useState(5);
  const [anniversaryMore, setAnniversaryMore] = useState(5);
  const [relivingData, setRelivingData] = useState();
  const [joiningData, setJoiningData] = useState();
  const [relivingMore, setRelivingMore] = useState(5);
  const [joiningMore, setJoiningMore] = useState(5);
  const [producationMore, setProducationMore] = useState(5);
  const [specialMore, setSpecialMore] = useState(5);
  const [birthdayMore, setBirthdayMore] = useState(5);
  const {
    todayBirthday,
    employeeAniversary,
    employeeJoinignList,
    employeeRelivingList,
    futureSpecialDay,
    futureJoining,
    futureReliving,
    employeeCommitmentOver,
    employeeProductionList,
  } = data;

  useEffect(() => {
    let d = employeeCommitmentOver?.map((obj) => {
      return { ...obj, CommitmentOverDate: new Date(obj.CommitmentOverDate) };
    });

    const sortedAsc = d?.sort(
      (objA, objB) =>
        Number(objA.CommitmentOverDate) - Number(objB.CommitmentOverDate)
    );

    let d2 = sortedAsc?.map((obj) => {
      return { ...obj, CommitmentOverDate: dateFormat(obj.CommitmentOverDate) };
    });

    // employeeCommitmentOver?.map((employee) => {
    // 	if(employee?.workDetails?.commitmentStartDate && employee?.workDetails?.bondDuration)
    // 	{
    // 		let newDate = new Date(employee?.workDetails?.commitmentStartDate)
    // 		let n = Number(employee?.workDetails?.bondDuration)
    // 		if(Number.isInteger(n) == false)
    // 		{
    // 			let a = employee?.workDetails?.bondDuration.split(".");
    // 			if(a[0] == "1")
    // 			{
    // 				newDate.setMonth(newDate.getMonth() + 12)
    // 			}
    // 			newDate.setMonth(newDate.getMonth() + Number(a[1]))
    // 		}
    // 		else {
    // 			newDate.setFullYear(newDate.getFullYear() + n);
    // 		}
    // 		if(newDate.setHours(0,0,0,0) == new Date().setHours(0,0,0,0))
    // 		{
    // 			d = [...d, {id: employee?.id, commitDate: newDate, firstName: employee?.firstName, lastName: employee?.lastName}]
    // 		}
    // 	}
    // })
    setCommitmentOver(d2);
  }, [employeeCommitmentOver]);

  const handleCommitmentmore = () => {
    if (commitmentOverMore === 5) {
      setCommitmentOverMore(commitmentOver?.length);
    } else {
      setCommitmentOverMore(5);
    }
  };

  const handleRelivingMore = () => {
    if (relivingMore === 5) {
      setRelivingMore(relivingData?.length);
    } else {
      setRelivingMore(5);
    }
  };

  const handleJoiningMore = () => {
    if (joiningMore === 5) {
      setJoiningMore(joiningData?.length);
    } else {
      setJoiningMore(5);
    }
  };

  const handleProducationMore = () => {
    if (producationMore === 5) {
      console.log("employeeProductionList", employeeProductionList);
      setProducationMore(employeeProductionList?.length);
    } else {
      setProducationMore(5);
    }
  };

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  //Get Today Birthday List
  let birthdayList = Array.isArray(todayBirthday)
    ? todayBirthday
        .map(function (d, idx) {
          var today = moment(new Date()).format("DD-MM");
          var birthDate = moment(d.dateOfBirth).format("DD-MM");

          if (birthDate === today) {
            return { firstName: d.firstName, lastName: d.lastName };
          }
        })
        .filter(Boolean)
    : "";

  const handlBirthdayMore = () => {
    if (birthdayMore === 5) {
      setBirthdayMore(birthdayList?.length);
    } else {
      setBirthdayMore(5);
    }
  };

  //Get Today Work Aniversary List
  let workAniversaryList = Array.isArray(employeeAniversary)
    ? employeeAniversary
        .map(function (d, idx) {
          var years = moment().diff(
            moment(d.workDetails?.productionDate).format("YYYY-MM-DD"),
            "years",
            false
          );
          var today = moment(new Date()).format("DD-MM");
          var aniversaryDate = moment(d.workDetails?.productionDate).format(
            "DD-MM"
          );

          if (today === aniversaryDate) {
            return {
              firstName: d.firstName,
              lastName: d.lastName,
              aniversary: `${ordinal_suffix_of(years)} Anniversary`,
            };
            // 	<div className="span-flex">
            // 		{} {} ( )
            // 	</div>
            // );
          }
        })
        .filter(Boolean)
    : "";

  const handleAnniversaryMore = () => {
    if (anniversaryMore === 5) {
      setAnniversaryMore(workAniversaryList?.length);
    } else {
      setAnniversaryMore(5);
    }
  };

  //Get Tommorow Work Aniversary List
  let workAniversaryListTomorrow = Array.isArray(employeeAniversary)
    ? employeeAniversary
        .map(function (d, idx) {
          var years = moment().diff(
            moment(d.workDetails?.productionDate).format("YYYY-MM-DD"),
            "years",
            false
          );
          var tomorrow = moment(new Date()).add(1, "days").format("DD-MM");
          var aniversaryDate = moment(d.workDetails?.productionDate).format(
            "DD-MM"
          );
          if (tomorrow === aniversaryDate) {
            return {
              firstName: d.firstName,
              lastName: d.lastName,
              aniversary: `${ordinal_suffix_of(years + 1)} Anniversary`,
            };
            // 	<div className="span-flex">
            // 		{} {} ( )
            // 	</div>
            // );
          }
        })
        .filter(Boolean)
    : "";
  //Get Today Joining List

  let todayJoiningList = Array.isArray(employeeJoinignList)
    ? employeeJoinignList
        .map((employee) => {
          const JoinDate = DateFormat(employee.workDetails?.joiningDate);
          const TodayDate = DateFormat(new Date());

          // if (moment(JoinDate).isSame(TodayDate)) {
          return [
            `${employee?.workDetails?.joiningDate}`,
            `${employee?.firstName} ${employee?.lastName}`,
          ];
          // }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let joiningList21 = Array.isArray(futureJoining)
    ? futureJoining
        .map((employee) => {
          const JoinDate = DateFormat(employee?.workDetails?.joiningDate);
          const TodayDate = DateFormat(new Date());

          // if (moment(JoinDate).isSame(TodayDate)) {
          return {
            name: `${employee.firstName} ${employee.lastName}`,
            joiningDate: indianDateFormat(employee.workDetails?.joiningDate),
          };
          // }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let dayBeforeJoiningList = Array.isArray(futureJoining)
    ? futureJoining
        .map((employee) => {
          const JoinDate = DateFormat(employee.workDetails?.joiningDate);
          const dayBeforeDate = DateFormat(tomorrowDate());

          if (moment(JoinDate).isSame(dayBeforeDate)) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let thisWeekJoiningList = Array.isArray(futureJoining)
    ? futureJoining
        .map((employee) => {
          const JoinDate = DateFormat(employee.workDetails?.joiningDate);
          const firstDay = DateFormat(firstDayOfWeek());
          const lastDay = DateFormat(lastDayOfWeek());

          if (moment(JoinDate).isBetween(firstDay, lastDay, undefined, "(]")) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let oneWeekBeforeJoiningList = Array.isArray(futureJoining)
    ? futureJoining
        .map((employee) => {
          const JoinDate = DateFormat(employee.workDetails?.joiningDate);
          // const dayBeforeDate = DateFormat(dayAfterTomorrowDate());
          // const oneWeekBeforeDate = DateFormat(getOneWeekBeforeDate());
          // const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const lastDayCurrentWeek = lastDayOfWeek();
          const dayBeforeDate = DateFormat(
            moment(lastDayCurrentWeek).add(0, "days")
          );
          const oneWeekBeforeDate = DateFormat(
            moment(lastDayCurrentWeek).add(7, "days")
          );
          if (
            moment(JoinDate).isBetween(
              dayBeforeDate,
              oneWeekBeforeDate,
              undefined,
              "[]"
            )
          ) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let twoWeekBeforeJoiningList = Array.isArray(futureJoining)
    ? futureJoining
        .map((employee) => {
          const JoinDate = DateFormat(employee.workDetails?.joiningDate);
          // const oneWeekBeforeDate = DateFormat(getOneWeekBeforeDate());
          // const twoWeekBeforeDate = DateFormat(getTwoWeekBeforeDate());
          // const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const lastDayCurrentWeek = lastDayOfWeek();
          // const firstDayNextWeek = DateFormat(moment(lastDayCurrentWeek).add(0, 'days'));
          const lastDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(7, "days")
          );
          const oneWeekBeforeDate = DateFormat(
            moment(lastDayNextWeek).add(0, "days")
          );
          const twoWeekBeforeDate = DateFormat(
            moment(lastDayNextWeek).add(7, "days")
          );
          if (
            moment(JoinDate).isBetween(
              oneWeekBeforeDate,
              twoWeekBeforeDate,
              undefined,
              "(]"
            )
          ) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let relivingList = Array.isArray(futureReliving)
    ? futureReliving
        .map((employee) => {
          const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const TodayDate = DateFormat(new Date());

          // if (moment(RelievingDate).isSame(TodayDate)) {
          return {
            name: `${employee.firstName} ${employee.lastName}`,
            relievingDate: indianDateFormat(
              employee.workDetails?.relievingDate
            ),
          };
          // }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let dayBeforeRelivingList = Array.isArray(futureReliving)
    ? futureReliving
        .map((employee) => {
          const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const dayBeforeDate = DateFormat(tomorrowDate());

          if (moment(RelievingDate).isSame(dayBeforeDate)) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let thisWeekRelivingList = Array.isArray(futureReliving)
    ? futureReliving
        .map((employee) => {
          const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const firstDay = DateFormat(firstDayOfWeek());
          const lastDay = DateFormat(lastDayOfWeek());

          if (
            moment(RelievingDate).isBetween(firstDay, lastDay, undefined, "(]")
          ) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";
  // let oneWeekBeforeRelivingList = Array.isArray(futureReliving)
  // 	? futureReliving
  // 			.map((employee) => {
  // 				const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
  // 				const dayBeforeDate = DateFormat(dayAfterTomorrowDate());
  // 				const oneWeekBeforeDate = DateFormat(getOneWeekBeforeDate());

  // 				if (
  // 					moment(RelievingDate).isBetween(
  // 						dayBeforeDate,
  // 						oneWeekBeforeDate,
  // 						undefined,
  // 						"(]",
  // 					)
  // 				) {
  // 					return `${employee.firstName} ${employee.lastName}`;
  // 				}
  // 			})
  // 			.filter((notUndefined) => notUndefined !== undefined)
  // 	: "";

  let oneWeekBeforeRelivingList = Array.isArray(futureReliving)
    ? futureReliving
        .map((employee) => {
          const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const lastDayCurrentWeek = lastDayOfWeek();
          const firstDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(0, "days")
          );
          const lastDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(7, "days")
          );

          if (
            moment(RelievingDate).isBetween(
              firstDayNextWeek,
              lastDayNextWeek,
              undefined,
              "(]"
            )
          ) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let twoWeekBeforeRelivingList = Array.isArray(futureReliving)
    ? futureReliving
        .map((employee) => {
          const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
          const lastDayCurrentWeek = lastDayOfWeek();
          // const firstDayNextWeek = DateFormat(moment(lastDayCurrentWeek).add(0, 'days'));
          const lastDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(7, "days")
          );
          const firstDayTwoWeeksLater = DateFormat(
            moment(lastDayNextWeek).add(0, "days")
          );
          const lastDayTwoWeeksLater = DateFormat(
            moment(lastDayNextWeek).add(7, "days")
          );

          if (
            moment(RelievingDate).isBetween(
              firstDayTwoWeeksLater,
              lastDayTwoWeeksLater,
              undefined,
              "(]"
            )
          ) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";
  // let twoWeekBeforeRelivingList = Array.isArray(futureReliving)
  // 	? futureReliving
  // 			.map((employee) => {
  // 				const RelievingDate = DateFormat(employee.workDetails?.relievingDate);
  // 				const oneWeekBeforeDate = DateFormat(getOneWeekBeforeDate());
  // 				const twoWeekBeforeDate = DateFormat(getTwoWeekBeforeDate());

  // 				if (
  // 					moment(RelievingDate).isBetween(
  // 						oneWeekBeforeDate,
  // 						twoWeekBeforeDate,
  // 						undefined,
  // 						"(]",
  // 					)
  // 				) {
  // 					return `${employee.firstName} ${employee.lastName}`;
  // 				}
  // 			})
  // 			.filter((notUndefined) => notUndefined !== undefined)
  // 	: "";

  let specialDay = Array.isArray(futureSpecialDay)
    ? futureSpecialDay
        .map((specialDay) => {
          const SDate = DateFormat(specialDay?.startDate);
          const TodayDate = DateFormat(new Date());

          // if (moment(SDate).isSame(TodayDate)) {
          return [`${specialDay.startDate}`, `${specialDay.title}`];
          // }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  const handleSpecialMore = () => {
    if (specialMore === 5) {
      setSpecialMore(specialDay?.length);
    } else {
      setSpecialMore(5);
    }
  };

  let dayBeforeSpecialDay = Array.isArray(futureSpecialDay)
    ? futureSpecialDay
        .map((specialDay) => {
          const SDate = DateFormat(specialDay?.startDate);
          const dayBeforeDate = DateFormat(tomorrowDate());

          if (moment(SDate).isSame(dayBeforeDate)) {
            return `${specialDay.title}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let thisWeekSpecialDay = Array.isArray(futureSpecialDay)
    ? futureSpecialDay
        .map((specialDay) => {
          const SDate = DateFormat(specialDay?.startDate);
          const firstDay = DateFormat(firstDayOfWeek());
          const lastDay = DateFormat(lastDayOfWeek());

          if (moment(SDate).isBetween(firstDay, lastDay, undefined, "(]")) {
            return `${specialDay.title}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";
  let oneWeekBeforeSpecialDay = Array.isArray(futureSpecialDay)
    ? futureSpecialDay
        .map((specialDay) => {
          const SDate = DateFormat(specialDay?.startDate);
          // const dayBeforeDate = DateFormat(tomorrowDate());
          // const oneWeekBeforeDate = DateFormat(getOneWeekBeforeDate());
          const lastDayCurrentWeek = lastDayOfWeek();
          const firstDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(0, "days")
          );
          const lastDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(7, "days")
          );
          // console.log(dayBeforeDate, oneWeekBeforeDate, SDate,"specialDay");
          if (
            moment(SDate).isBetween(
              firstDayNextWeek,
              lastDayNextWeek,
              undefined,
              "(]"
            )
          ) {
            return `${specialDay.title}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let twoWeekBeforeSpecialDay = Array.isArray(futureSpecialDay)
    ? futureSpecialDay
        .map((specialDay) => {
          const lastDayCurrentWeek = lastDayOfWeek();
          // const firstDayNextWeek = DateFormat(moment(lastDayCurrentWeek).add(0, 'days'));
          const lastDayNextWeek = DateFormat(
            moment(lastDayCurrentWeek).add(7, "days")
          );
          const oneWeekBeforeDate = DateFormat(
            moment(lastDayNextWeek).add(0, "days")
          );
          const twoWeekBeforeDate = DateFormat(
            moment(lastDayNextWeek).add(7, "days")
          );
          const SDate = DateFormat(specialDay?.startDate);
          // const oneWeekBeforeDate = DateFormat(getOneWeekBeforeDate());
          // const twoWeekBeforeDate = DateFormat(getTwoWeekBeforeDate());

          if (
            moment(SDate).isBetween(
              oneWeekBeforeDate,
              twoWeekBeforeDate,
              undefined,
              "(]"
            )
          ) {
            return `${specialDay.title}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";

  let commitmentData = Array.isArray(commitment)
    ? commitment
        ?.map((employee) => {
          if (
            employee.commitDate.setHours(0, 0, 0, 0) <
            new Date(new Date().getTime() + 45 * 24 * 60 * 60 * 1000).setHours(
              0,
              0,
              0,
              0
            )
          ) {
            return `${employee.firstName} ${employee.lastName}`;
          }
        })
        .filter((notUndefined) => notUndefined !== undefined)
    : "";
  // console.log(DateFormat(firstDayOfWeek()),'pokjhgfd',DateFormat(lastDayOfWeek()));

  useEffect(() => {
    const groupedData = {};
    relivingList &&
      relivingList?.forEach((entry) => {
        const { name, relievingDate } = entry;
        if (groupedData[relievingDate]) {
          groupedData[relievingDate].push(name);
        } else {
          groupedData[relievingDate] = [name];
        }
      });

    // Sort the grouped data by relieving date
    const sortedGroupedData = Object.entries(groupedData).sort((a, b) => {
      const dateA = new Date(a[0].split("/").reverse().join("/"));
      const dateB = new Date(b[0].split("/").reverse().join("/"));
      return dateA - dateB;
    });

    setRelivingData(sortedGroupedData);
  }, [futureReliving]);

  useEffect(() => {
    const groupedData = {};
    joiningList21 &&
      joiningList21?.forEach((entry) => {
        const { name, joiningDate } = entry;
        if (groupedData[joiningDate]) {
          groupedData[joiningDate].push(name);
        } else {
          groupedData[joiningDate] = [name];
        }
      });

    // Sort the grouped data by relieving date
    const sortedGroupedData = Object.entries(groupedData).sort((a, b) => {
      const dateA = new Date(a[0].split("/").reverse().join("/"));
      const dateB = new Date(b[0].split("/").reverse().join("/"));
      return dateA - dateB;
    });

    setJoiningData(sortedGroupedData);
  }, [futureJoining]);

  return (
    <>
      <Grid item xs={12}>
        <Card className="news">
          <Typography variant="h4" style={{ marginBottom: "10px" }}>
            News Feed
          </Typography>
          {getViewVisible("employee") ? (
            <>
              {birthdayList?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail birthday-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/cake.svg"
                        style={{ width: "24px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title">Birthday</p>
                        {Array.isArray(birthdayList) &&
                          birthdayList?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handlBirthdayMore}
                            >
                              {birthdayMore > 5 ? "show less" : "show more"}
                            </span>
                          )}
                      </div>
                      <span className="birthday-employee-name news_item-title">
                        <div className="span-flex">
                          {Array.isArray(birthdayList)
                            ? [...birthdayList?.slice(0, birthdayMore)]
                                .map((e) => e.firstName + " " + e.lastName)
                                .join(", ")
                            : ""}
                        </div>
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {workAniversaryList?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/cup.svg"
                        style={{ width: "20px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title">Anniversary</p>
                        {Array.isArray(workAniversaryList) &&
                          workAniversaryList?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handleAnniversaryMore}
                            >
                              {anniversaryMore > 5
                                ? "show less"
                                : "show more"}
                            </span>
                          )}
                      </div>
                      <span>
                        {Array.isArray(workAniversaryList)
                          ? [
                              ...workAniversaryList?.slice(0, anniversaryMore),
                            ]?.map((e) => {
                              return (
                                <div className="span-flex">
                                  {/* <strong>{`${(dateFormat(e.workDetails?.productionDate))}`}</strong> */}
                                  {e.firstName +
                                    " " +
                                    e.lastName +
                                    " (" +
                                    e.aniversary +
                                    ")"}
                                </div>
                              );
                            })
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {/* {workAniversaryListTomorrow?.length > 0 && (
								<div className="news_item">
									<div>
										<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img
												src="/assets/images/aniversary.png"
												style={{ width: "20px" }}
											/>
											<p className="news_item-title">Tomorrow's Anniversary: (Reminder)</p>
										</div>
										<span>
											{Array.isArray(workAniversaryListTomorrow)
												? workAniversaryListTomorrow.map((e) => {
														return (
															<div className="span-flex">
																{e.firstName +
																	" " +
																	e.lastName +
																	" (" +
																	e.aniversary +
																	")"}
															</div>
														);
												  })
												: ""}
										</span>
									</div>
								</div>
							)} */}
              {joiningList21?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/profile-tick.svg"
                        style={{ width: "24px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title"> Joining</p>
                        {Array.isArray(joiningData) &&
                          joiningData?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handleJoiningMore}
                            >
                              {joiningMore > 5 ? "show less" : "show more"}
                            </span>
                          )}
                      </div>
                      <span>
                        {Array.isArray(joiningData)
                          ? [...joiningData?.slice(0, joiningMore)]?.map(
                              ([joiningDate, names]) => (
                                <div key={joiningDate} className="span-flex">
                                  <strong>{`${joiningDate}`}</strong>-{" "}
                                  {`${names.join(", ")}`}
                                </div>
                              )
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* {
								dayBeforeJoiningList?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "20px" }} />
											<p className="news_item-title"> Joining: (Tomorrow)</p>
										</div>
										<span>
											{Array.isArray(dayBeforeJoiningList)
												? dayBeforeJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {thisWeekJoiningList?.length > 0 &&thisWeekJoiningList.filter((oldObj) => ![...todayJoiningList,...dayBeforeJoiningList].some((newObj) => `${newObj}` === `${oldObj}`)).length > 0&& (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "20px" }} />
											<p className="news_item-title"> Joining: (This Week)</p>
										</div>
										<span>
											{Array.isArray(thisWeekJoiningList)
												? thisWeekJoiningList.filter((oldObj) => ![...todayJoiningList,...dayBeforeJoiningList].some((newObj) => `${newObj}` === `${oldObj}`)).map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)} */}
              {/* {
								oneWeekBeforeJoiningList?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "23px" }} />
											<p className="news_item-title"> Joining: (In 1 Week)</p>
										</div>
										<span>
											{Array.isArray(oneWeekBeforeJoiningList)
												? oneWeekBeforeJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {console.log("Future", twoWeekBeforeJoiningList)}
							{
								twoWeekBeforeJoiningList?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "22px" }} />
											<p className="news_item-title"> Joining: (In 2 Week)</p>
										</div>
										<span>
											{Array.isArray(twoWeekBeforeJoiningList)
												? twoWeekBeforeJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}

              {/* {
								relivingList?.length > 0 && (<div className="news_item">
									<div className="news_item_wrapper">
										<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/profile-remove.svg" style={{ width: "24px" }} />
										</div>
										<div className="news_item-right">
											<p className="news_item-title"> Relieving</p>
											<span>
												{console.log(relivingList)}
												
												{Array.isArray(relivingList)
													? relivingList.map((e) => {
															return (
																<div className="span-flex"> 
																<strong>{`${e?.relievingDate}`}</strong>
																{`${e?.name}`}
																</div>
															);
													})
													: ""}
											</span>
										</div>
									</div>
								</div>)
							} */}

              {relivingData?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/profile-remove.svg"
                        style={{ width: "24px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title"> Relieving</p>
                        {Array.isArray(relivingData) &&
                          relivingData?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handleRelivingMore}
                            >
                              {relivingMore > 5 ? "show less" : "show more"}
                            </span>
                          )}
                      </div>
                      <span>
                        {Array.isArray(relivingData)
                          ? [...relivingData?.slice(0, relivingMore)]?.map(
                              ([relievingDate, names]) => (
                                <div key={relievingDate} className="span-flex">
                                  <strong>{`${relievingDate}`}</strong>-{" "}
                                  {`${names.join(", ")}`}
                                </div>
                              )
                            )
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* {
								dayBeforeRelivingList?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "24px" }} />
											<p className="news_item-title"> Relieving: (Tomorrow) </p>
										</div>
										<span>
											{Array.isArray(dayBeforeRelivingList)
												? dayBeforeRelivingList.map((e) => {
														return (
															<div className="span-flex"> <strong> {`${(dateFormat(e.workDetails?.productionDate))}`}</strong> {`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {console.log(thisWeekRelivingList,"uuuuuuuuu")} */}
              {/* {thisWeekRelivingList.length > 0 && thisWeekRelivingList.filter((oldObj) => ![...relivingList,...dayBeforeRelivingList].some((newObj) => `${newObj}` === `${oldObj}`)).length > 0 &&(<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "27px"}} />
											<p className="news_item-title"> Relieving: (This Week)</p>
										</div>
										<span>
											{Array.isArray(thisWeekRelivingList)
												? thisWeekRelivingList.filter((oldObj) => ![...relivingList,...dayBeforeRelivingList].some((newObj) => `${newObj}` === `${oldObj}`)).map((e) => {
														return (
															<div className="span-flex"> <strong> {`${(dateFormat(e.workDetails?.productionDate))}`}</strong> {`${e}`}</div>
														);
												  })
												: ""}
										</span>
								</div>)} */}
              {/* {
								oneWeekBeforeRelivingList?.length > 0&&oneWeekBeforeRelivingList?.filter((oldObj) => !thisWeekRelivingList.some((newObj) =>`${newObj}` === `${oldObj}`)).length>0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "24px" }} />
											<p className="news_item-title"> Relieving: (In 1 Week)</p>
										</div>
										<span>
											{Array.isArray(oneWeekBeforeRelivingList)
												? oneWeekBeforeRelivingList.filter((oldObj) => !thisWeekRelivingList.some((newObj) =>`${newObj}` === `${oldObj}`)).map((e) => {
														return (
															<div className="span-flex"> <strong> {`${(dateFormat(e.workDetails?.productionDate))}`}</strong> {`${e}`}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {
								twoWeekBeforeRelivingList?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "20px"}} />
											<p className="news_item-title"> Relieving: (In 2 Week)</p>
										</div>
										<span>
											{Array.isArray(twoWeekBeforeRelivingList)
												? twoWeekBeforeRelivingList.map((e) => {
														return (
															<div className="span-flex"> <strong> {`${(dateFormat(e.workDetails?.productionDate))}`}</strong> {`${e}`}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {
								commitmentData?.length > 0 && (<div className="news_item">
									<div style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" />
											<p> Commitment Over</p>
										</div>

										<span>
											{Array.isArray(commitmentData)
												? commitmentData.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {commitmentOver?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/Commitment-Over-icon.svg"
                        style={{ width: "24px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title"> Commitment</p>
                        {Array.isArray(commitmentOver) &&
                          commitmentOver?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handleCommitmentmore}
                            >
                              {commitmentOverMore > 5
                                ? "show less"
                                : "show more"}
                            </span>
                          )}
                      </div>

                      <span>
                        {Array.isArray(commitmentOver)
                          ? [
                              ...commitmentOver?.slice(0, commitmentOverMore),
                            ]?.map((e) => {
                              return (
                                <div className="span-flex">
                                  <strong>{`${indianDateFormat(
                                    e?.CommitmentOverDate
                                  )}`}</strong>{" "}
                                  - {`${e.firstName} ${e.lastName}`}{" "}
                                </div>
                              );
                            })
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {employeeProductionList?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/monitor.svg"
                        style={{ width: "20px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title">Production</p>
                        {Array.isArray(employeeProductionList) &&
                          employeeProductionList?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handleProducationMore}
                            >
                              {producationMore > 5
                                ? "show less"
                                : "show more"}
                            </span>
                          )}
                      </div>
                      <span>
                        {Array.isArray(employeeProductionList)
                          ? [
                              ...employeeProductionList?.slice(
                                0,
                                producationMore
                              ),
                            ]?.map((e) => {
                              return (
                                <div className="span-flex">
                                  <strong>{`${indianDateFormat(
                                    e.workDetails?.productionDate
                                  )}`}</strong>{" "}
                                  - {`${e.firstName} ${e.lastName}`}
                                </div>
                              );
                            })
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* {(todayJoiningList?.length > 0 ||
								dayBeforeJoiningList?.length > 0 ||
								oneWeekBeforeJoiningList?.length > 0 ||
								twoWeekBeforeJoiningList?.length > 0) && (
								<div className="news_item">
									<div>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" />
											<p> Production:</p>
										</div>
										<span>
											{Array.isArray(todayJoiningList)
												? todayJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(dayBeforeJoiningList)
												? dayBeforeJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(oneWeekBeforeJoiningList)
												? oneWeekBeforeJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(twoWeekBeforeJoiningList)
												? twoWeekBeforeJoiningList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
									</div>
								</div>
							)} */}

              {/* {(relivingList?.length > 0 ||
								dayBeforeRelivingList?.length > 0 ||
								oneWeekBeforeRelivingList?.length > 0 ||
								twoWeekBeforeRelivingList?.length > 0) && (
								<div className="news_item">
									<div>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" />
											<p> Relieving:</p>
										</div>
										<span>
											{Array.isArray(relivingList)
												? relivingList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(dayBeforeRelivingList)
												? dayBeforeRelivingList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(oneWeekBeforeRelivingList)
												? oneWeekBeforeRelivingList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(twoWeekBeforeRelivingList)
												? twoWeekBeforeRelivingList.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
									</div>
								</div>
							)} */}

              {specialDay?.length > 0 && (
                <div className="news_item">
                  <div className="news_item_wrapper">
                    <div
                      className="news_item-detail"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <img
                        src="/assets/images/Special-Day-icon.svg"
                        style={{ width: "20px" }}
                      />
                    </div>
                    <div className="news_item-right">
                      <div className="flex_row">
                        <p className="news_item-title"> Special Day</p>
                        {Array.isArray(specialDay) &&
                          specialDay?.length > 5 && (
                            <span
                              className="show-more-less"
                              onClick={handleSpecialMore}
                            >
                              {specialMore > 5 ? "show less" : "show more"}
                            </span>
                          )}
                      </div>
                      <span>
                        {Array.isArray(specialDay)
                          ? [...specialDay?.slice(0, specialMore)]?.map((e) => {
                              return (
                                <div className="span-flex">
                                  <strong>{indianDateFormat(e[0])}</strong> -{" "}
                                  {`${e[1]}`}
                                </div>
                              );
                            })
                          : ""}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {/* {
								dayBeforeSpecialDay?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "24px" }} />
											<p className="news_item-title"> Special Day: (Tomorrow)</p>
										</div>
										<span>
											{Array.isArray(dayBeforeSpecialDay)
												? dayBeforeSpecialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {thisWeekSpecialDay?.length > 0 &&thisWeekSpecialDay.filter((oldObj) => ![...specialDay,...dayBeforeSpecialDay].some((newObj) => `${newObj}` === `${oldObj}`)).length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "20px" }} />
											<p className="news_item-title"> Special Day: (This Week)</p>
										</div>
										<span>
											{Array.isArray(thisWeekSpecialDay)
												? thisWeekSpecialDay.filter((oldObj) => ![...specialDay,...dayBeforeSpecialDay].some((newObj) => `${newObj}` === `${oldObj}`)).map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {
								oneWeekBeforeSpecialDay?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "20px" }} />
											<p className="news_item-title"> Special Day: (In 1 Week)</p>
										</div>
										<span>
											{Array.isArray(oneWeekBeforeSpecialDay)
												? oneWeekBeforeSpecialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}
              {/* {
								twoWeekBeforeSpecialDay?.length > 0 && (<div className="news_item">
									<div className="news_item-detail" style={{ display: "flex", alignItems: "center" }}>
											<img src="/assets/images/cm.svg" style={{ width: "20px"}} />
											<p className="news_item-title"> Special Day: (In 2 Week)</p>
										</div>
										<span>
											{Array.isArray(twoWeekBeforeSpecialDay)
												? twoWeekBeforeSpecialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
								</div>)
							} */}

              {/* {(specialDay?.length > 0 ||
								dayBeforeSpecialDay?.length > 0 ||
								oneWeekBeforeSpecialDay?.length > 0 ||
								twoWeekBeforeSpecialDay?.length > 0) && (
								<div className="news_item">
									<div>
										<div style={{ display: "flex", alignItems: "center" }}>
											<img
												src="/assets/images/aniversary.png"
												style={{ width: "20px", height: "20px" }}
											/>
											<p> Special Day:</p>
										</div>
										<span>
											{Array.isArray(specialDay)
												? specialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(dayBeforeSpecialDay)
												? dayBeforeSpecialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(oneWeekBeforeSpecialDay)
												? oneWeekBeforeSpecialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
											{Array.isArray(twoWeekBeforeSpecialDay)
												? twoWeekBeforeSpecialDay.map((e) => {
														return (
															<div className="span-flex">{`${e} `}</div>
														);
												  })
												: ""}
										</span>
									</div>
								</div>
							)} */}
            </>
          ) : (
            ""
          )}
        </Card>
      </Grid>
    </>
  );
};

export default NewsFeed;
