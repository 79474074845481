import DashboardLayout from "../../layouts/dashboard";
import { Link as RouterLink, useParams } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Container from "@mui/material/Container";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import { Base64 } from "js-base64";
import { useEffect, useState } from "react";
import AttachmentPreview from "./EmailInboxComp/AttachmentPreview";
import { useDispatch } from "react-redux";
import { decryption, encryption } from "../../utils/encodeString";
import { emailDate } from "../../utils/dateFormat";
import { getAddVisible } from "../../utils/userPermission";
import { getEmailById } from "../../redux/emailInbox/emailinboxThunk";
import { BASE_URL } from "../../constants";
const ViewEmail = () => {
  const [emailData, setEmailData] = useState({});
  const dispatch = useDispatch();
  let { id } = useParams();
  // const { data} = useSelector((res) => res.emailInbox)
  let [body, setBody] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const ParamId = decryption(id);
        const res = await dispatch(getEmailById({ id: ParamId })).unwrap();
        setEmailData(res.data);
        
        const code = res.data?.body;
        const encoded1 = code?.substr(code.length - 9);

        if (encoded1 === "undefined") {
          const encoded = code?.substr(0, code.length - 9);
          const htmlCode = Base64.decode(encoded);
          setBody(htmlCode);
        } else {
          const htmlCode = Base64.decode(code);
          setBody(htmlCode);
        }
      } catch (error) {
        // Handle error (e.g., display an error message)
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch]);

  
  return (
    <div className="view_employee">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="flex-end" mb={4}>
        {emailData?.candidateId&&getAddVisible("candidates") &&
          <Button
            variant="contained"
            component={RouterLink}
            to={`/candidate/view/${encryption(emailData?.candidateId)}`}
            startIcon={<VisibilityIcon />}
            style={{ marginLeft: "10px" }}>
            View candidate
          </Button>
}
          {emailData?.candidateId==null&&getAddVisible("candidates") && (
            <Button
              variant="contained"
              component={RouterLink}
              startIcon={<AddIcon />}
              style={{ marginLeft: "10px" }}
              // onClick={()=>handleDownload(emailData?.attachments[0]?.attachment)}
              // to=""
              to={`/candidate/addusingemail/${emailData?.attachments&&emailData?.attachments.length&&encryption(`${emailData?.attachments[0]?.attachment}&&&&${emailData?.attachments[0]?.fileName}`)}/${encryption(emailData?.id)}/`}
              >
              Create Candidate
            </Button>
          )}
          <Button
            variant="contained"
            component={RouterLink}
            to="/email-inbox"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}>
            Back To List
          </Button>
        </Stack>
        <Box>
          <Card style={{ padding: "25px 20px", marginTop: "10px" }} mb={3}>
            <Typography
              variant="h6"
              className="information-card-heading"
              style={{
                fontWeight: "400",
                fontSize: "1.375rem",
                marginBottom: "20px",
                width: "90%",
                marginLeft: "4.5%",
              }}>
              {emailData?.subject || "(no subject)"}
            </Typography>
            <Stack
              direction="row"
              justifyContent="space-between"
              mb={3}
              alignItem="center">
              <Stack direction="row" alignItem="center">
                <Avatar sx={{ bgcolor: "#fda92d" }}>
                  {emailData?.senderName?.charAt(0)?.toUpperCase()}
                </Avatar>
                <Stack
                  style={{ marginLeft: "10px" }}
                  direction="column"
                  spacing={0}>
                  <Stack direction="row" sx={{ height: "20px" }}>
                    {" "}
                    <Typography
                      variant="h6"
                      className="information-card-heading"
                      style={{
                        fontWeight: "700",
                        fontSize: "0.875rem",
                        lineHeight: "20px",
                        color: "#1f1f1f",
                      }}>
                      {emailData?.senderName}
                    </Typography>
                    <Typography
                      variant="h6"
                      className="information-card-heading"
                      style={{
                        fontWeight: "600",
                        fontSize: "0.75rem",
                        lineHeight: "20px",
                        color: "#5e5e5e",
                      }}>
                      {`<${emailData?.senderEmail}>`}
                    </Typography>
                  </Stack>
                  <Typography
                    variant="h6"
                    className="information-card-heading"
                    style={{
                      fontWeight: "600",
                      fontSize: "0.75rem",
                      lineHeight: "20px",
                      color: "#5e5e5e",
                    }}>
                    {`to ${emailData?.ccEmail}>`}
                  </Typography>
                </Stack>
              </Stack>
              <Typography
                variant="h6"
                className="information-card-heading"
                style={{
                  fontWeight: "600",
                  fontSize: "0.75rem",
                  lineHeight: "20px",
                  color: "#5e5e5e",
                }}>
                {emailDate(emailData?.sendDate)}
              </Typography>
            </Stack>
            <Stack mb={3} sx={{ ml: "4.5%", width: "90%" }}>
              <div dangerouslySetInnerHTML={{ __html: body }}></div>
            </Stack>
            {emailData?.attachments?.length ? (
              <div
                style={{
                  borderTop: "1px dotted #d8d8d8",
                  marginLeft: "4.5%",
                  width: "90%",
                }}>
                <Typography
                  variant="h6"
                  className="information-card-heading"
                  style={{
                    fontWeight: "700",
                    fontSize: "1.2",
                    lineHeight: "20px",
                    color: " #1f1f1f",
                    marginTop: "10px",
                  }}>
                  Attachment(s)
                </Typography>
                {emailData?.attachments.map((attachment, index) => (
                  <AttachmentPreview key={index} attachmentData={attachment} />
                ))}
              </div>
            ) : (
              ""
            )}
          </Card>
        </Box>
      </Container>
    </div>
  );
};

const componentConfig = {
  component: ViewEmail,
  path: "/email-inbox/view/:id",
  public: false,
  layout: DashboardLayout,
  group: null,
  sidebar: true,
  role: ["admin"],
};
export default componentConfig;
