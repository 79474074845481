import { createSlice } from "@reduxjs/toolkit";
import { updateDashboardInterviewStatus } from "./updateInterviewStatusThunk";
const initialState = {
  data: [],
  loading: false,
  status: "idle",
  completed: false,
};

// A slice for dashboard with our 3 reducers
export const updateDashboardInterviewStatusSlice = createSlice({
  name: "updateDashboardInterviewStatus",
  initialState,
  reducers: {},
  extraReducers: {
    [updateDashboardInterviewStatus.pending]: (state) => {
      state.loading = true;
    },
    [updateDashboardInterviewStatus.fulfilled]: (state,  {payload} ) => {
      state.loading = false;
      state.data = payload;
      state.completed = true;
      state.status = "completed";
    },
    [updateDashboardInterviewStatus.rejected]: (state, payload) => {
      state.loading = false;
      state.error = payload;
      state.data = [];
      state.status = "rejected";
      state.completed = true;
    },
  },
});

// The reducer
export const updateDashboardInterviewStatusReducer =
  updateDashboardInterviewStatusSlice.reducer;
