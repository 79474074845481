import * as Yup from "yup";
const yesterday = new Date(Date.now() -86400000);

const pollSchema = Yup.object().shape({
  questionText : Yup.string()
  .trim("Question Text cannot contain only whitespace")
  .strict(true)
    .required("Question Text is required")
    .nullable(),
  startDate :Yup.date()
    .required("Start date is required")
    // .min(new Date(), "Date cannot be in the past")
    .min(yesterday, "Date cannot be in the past")
    .typeError("Enter valid date")
    .nullable(),
  endDate : Yup.date()
    .required("End date is required")
    .min(new Date(), "Date cannot be in the past")
    .typeError("Enter valid date")
    .min(Yup.ref("startDate"), "End date can't be before start date")
    .nullable(),
  pollQuestionOptions: Yup.array().of(
    Yup.object()
      .shape({
        options: Yup.string()
        .trim("Option cannot contain only whitespace")
        .strict(true)
        .required("Option is required").nullable(),
        optionText: Yup.string()
        .trim("Option text cannot contain only whitespace")
        .strict(true)
        .required("Option text is required").nullable(),
        // optionsUrl: Yup.string()
        //   .required("URL is required")
        //   .nullable(),
      })
      .uniqueProperties([["options", "Option must be unique"]])
  ),
});

export default pollSchema;