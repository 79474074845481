import * as React from "react";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import invertDirection from "../../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  getCandidateState,
  getCandidateStatus,
  insert,
  updateById,
  deleteById,
} from "../../../redux/candidateState/candidateStatethunk";
import { candidateStatusValidation } from "../../../validations/mastersSchema";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";

import { setSortBy, setOrderBy } from "../../../redux/candidateState/candidateStateSlice";

const CandidateState = () => {
  const confirm = useConfirm();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.candidateState.data);
  const statusData = useSelector((state) => state.candidateState.statusData);
  const loading = useSelector((state) => state.candidateState.loading);
  const error = useSelector((state) => state.candidateState.error);
	const sortBy = useSelector((state) => state.candidateState.sortBy);
	const orderBy = useSelector((state) => state.candidateState.orderBy);
  const [id, setId] = React.useState(null);
  const ref = useRef(null);

  const formik = useFormik({
    initialValues: {
      name: "",
      isActive: false,
      candidateStatusId: "",
    },
    validationSchema: candidateStatusValidation,
    onSubmit: async (values, { resetForm }) => {
      if (!values.parentId) {
        values.parentId = null;
      }

      try {
        if (id) {
          await dispatch(updateById({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(insert(values)).unwrap();
        }
        resetForm();
        dispatch(getCandidateState({sortBy, orderBy}));

        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const { errors, setErrors, touched, handleSubmit, setValues, isSubmitting } =
    formik;

  useEffect(() => {
    if (Array.isArray(statusData) && statusData.length <= 0)
      dispatch(getCandidateStatus());
  }, []);

  useEffect(() => {
    if (
      !loading &&
      !error &&
      (!data || (Array.isArray(data) && data.length <= 0))
    )
      dispatch(getCandidateState({sortBy, orderBy}));
  }, [loading, dispatch]);

  useEffect(() => {
    dispatch(
      getCandidateState({
        sortBy,
        orderBy,
      }),
    );
}, [dispatch,sortBy, orderBy]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setErrors({});
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const handleUpdate = (data) => {
    setValues({
      name: data.name,
      isActive: data.isActive,
      candidateStatusId: data.candidateStatusId ?? "",
    });
    setId(data.id);
  };

  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteById(id));
      await dispatch(getCandidateState({sortBy, orderBy}));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleToggle = async (state) => {
    try {
      await dispatch(
        updateById({
          id: state.id,
          name: state.name,
          isActive: !state.isActive,
        })
      ).unwrap();
      dispatch(getCandidateState({sortBy, orderBy}));
    } catch (error) {}
  };
  const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

  return (
    <>
    {/* <Card className="inner-component">
  <Container className="custom-container-grid"> */}
   <Container
          className="custom-container-grid"
          style={{
            boxShadow: "initial !important",
            paddingLeft: "0",
            paddingRight: "0",
            minWidth: "100%",
            minHeight: "70%",
          }}
        >
        <div className="header">
          <form ref={ref} autoComplete="off" noValidate onSubmit={handleSubmit}>
            <h6 className="component-name">CANDIDATE STATE</h6>
            {getAddVisible("master") && (
              <div className="heading-part">
                <div className="input-field-part technology-field">
                  <TextField
                    id="outlined-basic"
                    label="Enter Candidate State"
                    variant="outlined"
                    size="small"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    fullWidth
                    inputProps={{ maxLength: 64 }}
                  />
                </div>

                <FormControl
                  error={Boolean(
                    touched.candidateStatusId && errors.candidateStatusId
                  )}
                  fullWidth
                >
                  <InputLabel id="technology-label" size="small">
                    Candidate Status
                  </InputLabel>
                  <Select
                    labelId="technology-label"
                    value={formik.values.candidateStatusId}
                    label="Candidate Status"
                    name="candidateStatusId"
                    onChange={formik.handleChange}
                    size="small"
                    fullWidth
                  >
                    {statusData
                      .filter((s) => s.isActive)
                      .map((status) => {
                        return (
                          <MenuItem key={status.id} value={status.id}>
                            {status.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <FormHelperText>
                    {touched.candidateStatusId && errors.candidateStatusId}
                  </FormHelperText>
                </FormControl>
                <div className="checkbox-part">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isActive}
                        name="isActive"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Status"
                  />
                </div>
                <div className="button-part">
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    save
                  </LoadingButton>
                </div>
              </div>
            )}
          </form>
        </div>
        <TableContainer component={Paper} >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell width="10%">ID</TableCell> */}
                  <TableCell width="30%">
                    <TableSortLabel
												active={sortBy === "name"}
												direction={sortBy === "name" ? orderBy : "asc"}
												onClick={() => handleSorting("name")}
											>
												Title
										</TableSortLabel>
                  </TableCell>
                  <TableCell width="37%">Status</TableCell>
                  <TableCell width="10%" align="center">
                    Active
                  </TableCell>
                  {(getEditVisible("master") || getDeleteVisible("master")) && (
                    <TableCell
                      width="13%"
                      align="right"
                      className="action-field"
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {loading ? (
                <Loader />
              ) : (
              <TableBody>
                {Array.isArray(data) && data.length === 0 ? (
                  <TableRow>
                    <TableCell className="No-Record-text" align="center" colSpan={3}>
                    No Record(s) Found
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((candidateState, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {/* <TableCell component="th" scope="row">
                        {candidateState.id}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {candidateState.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {candidateState.candidateStatus?.name}
                      </TableCell>
                      <TableCell align="right">
                        <Switch
                          checked={candidateState.isActive}
                          onChange={() => handleToggle(candidateState)}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                      </TableCell>
                      {(getEditVisible("master") ||
                        getDeleteVisible("master")) && (
                        <TableCell align="right">
                          {getEditVisible("master") && (
                            <IconButton
                              onClick={() => handleUpdate(candidateState)}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          )}

                          {getDeleteVisible("master") && (
                            <IconButton
                              onClick={() =>
                                handleDelete(
                                  candidateState.id,
                                  candidateState.name
                                )
                              }
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
              )}
            </Table>
        </TableContainer>
      </Container>
    {/*</Card> */}
    </>
  );
};

export default CandidateState;
