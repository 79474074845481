import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import LoadingButton from "@mui/lab/LoadingButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../../components/Loader";
import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {
  getDrives,
  deleteById,
  getDriveCount,
} from "../../redux/drives/drivesThunk";
import { setLimit, setCurrentPage } from "../../redux/drives/drivesSlice";
import invertDirection from "../../utils/invertDirection";
import { setOrderBy, setSortBy } from "../../redux/drives/drivesSlice";
import { useFormik } from "formik";
import { getCollege } from "../../redux/college/collegeThunk";
import { indianDateFormat } from "../../utils/dateFormat";
import { encryption } from "../../utils/encodeString";
import NiceModal from "@ebay/nice-modal-react";
import { ExportExcelModal } from "./DrivesComponent/ExportExcelModal";
import CustomPagination from "../../components/Pagination";
import Chip from "@mui/material/Chip";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import LocalStorage from "../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../redux/userPermission/userPermissionthunk";

function Drives() {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const {
    data,
    status,
    totalRecords,
    currentPage,
    limit,
    loading,
    sortBy,
    orderBy,
    totalPages,
    yearDropDown,
    year,
  } = useSelector((state) => state.drives);
  const totalCounts = useSelector((state) => state.driveCount.totalCounts);
  const collegeData = useSelector((state) => state.college.data);
  const collegeStatus = useSelector((state) => state.college.status);

  const placementType = ["On Campus", "Off Campus"];
  const placement = ["Single", "Pool"];
  const [searchCollege, setSearchCollege] = useState("");
  const [filterData, setFilterData] = useState(null);
  const [filterChips, setFilterChips] = useState([]);
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        getDrives({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          college: values.college,
          placement: values.placement,
          placementType: values.placementType,
          year: values.year,
        })
      );
    }
  }, [dispatch, sortBy, orderBy, limit, currentPage, status]);

  useEffect(() => {
    dispatch(
      getDrives({
        page: 1,
        limit: limit,
        sortBy,
        orderBy,
        college: searchCollege,
      })
    );
  }, [searchCollege]);

  useEffect(() => {
    //if (collegeStatus === "idle") {
    getViewVisible("college") && dispatch(getCollege({ search: "" }));

    //}
  }, [dispatch]);

  useEffect(() => {
    dispatch(getDriveCount({}));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      college: "",
      placement: "",
      placementType: "",
      year: "",
    },
    onSubmit: async (values) => {
      try {
        var searchParams = {};
        // searchParams.page = currentPage + 1;
        // searchParams.limit = limit;
        searchParams.college = values.college;
        searchParams.placement = values.placement;
        searchParams.placementType = values.placementType;
        searchParams.year = values.year;
        dispatch(
          getDrives({
            page: 1,
            limit: limit,
            sortBy,
            orderBy,
            ...searchParams,
          })
        );
        setFilterData({ ...searchParams });
        prepareChips({ ...searchParams });
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    handleSubmit,
    setValues,
    values,
    handleChange,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;

  const handleDelete = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to delete drive?`,
      });
      await dispatch(deleteById(id));
      dispatch(getDriveCount({}));
      handleSubmit();
      // await dispatch(
      // 	getDrives({
      // 		page: 1,
      // 		limit,
      // 		sortBy,
      // 		orderBy,
      // 	}),
      // );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleClear = () => {
    if (
      values.college === "" &&
      values.placement === "" &&
      values.placementType === ""
    ) {
      return;
    }
    setValues({
      college: "",
      placement: "",
      placementType: "",
    });
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: 20 }));
    handleSubmit();
  };

  const handleCollegeChange = (e, newValue) => {
    setFieldValue("college", newValue?.id);
    // dispatch(setCurrentPage({page:0}))
    setSearchCollege(newValue?.id);
    /*dispatch(
			getDrives({
				...values,
				page: currentPage + 1,
				limit: limit,
				sortBy,
				orderBy,
				college: newValue.id,
			}),
		);*/
    // prepareChips({ ...values })
    handleSubmit();
  };
  const handlePlacementChange = (e, newValue) => {
    setFieldValue("placement", newValue);
    // dispatch(setCurrentPage({page:0}))
    // prepareChips({ ...values })
    handleSubmit();
    dispatch(
      getDrives({
        ...values,
        page: 1,
        limit: limit,
        sortBy,
        orderBy,
        placement: newValue,
      })
    );
  };
  const handlePlacementTypeChange = (e, newValue) => {
    setFieldValue("placementType", newValue);
    // dispatch(setCurrentPage({page:0}))
    handleSubmit();
    dispatch(
      getDrives({
        ...values,
        page: 1,
        limit: limit,
        sortBy,
        orderBy,
        placementType: newValue,
      })
    );
  };

  const handleYearChange = (e, newValue) => {
    setFieldValue("year", newValue);
    // dispatch(setCurrentPage({page:0}))
    handleSubmit();
    dispatch(
      getDrives({
        ...values,
        page: 1,
        limit: limit,
        sortBy,
        orderBy,
        year: newValue,
      })
    );
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const openExcelModal = async (driveId) => {
    const modalResponse = await NiceModal.show(ExportExcelModal, {
      driveId: driveId,
    });
  };

  const prepareChips = (searchParams) => {
    let filters = { ...searchParams };
    let tempChips = [];
    for (const key in filters) {
      if (filters[key] && key === "college") {
        let collage = collegeData.find(
          (education) => education.id === values.college
        );
        filters[key] = `College : ${collage.collegeName}`;
      }
      if (filters[key] && key === "placement") {
        filters[key] = `Placement: ${filters[key]}`;
      }
      if (filters[key] && key === "placementType") {
        filters[key] = `Placement Type : ${filters[key]}`;
      }
      if (filters[key] && key === "year") {
        filters[key] = `Year : ${filters[key]}`;
      }
      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
  };

  const onDeleteChip = (value, length) => {
    let { type } = value;
    delete filterData[value.type];
    setFilterData({ ...filterData });
    setFieldValue(type, "");
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    setFilterChips([...updateChips]);
    dispatch(setCurrentPage({ page: 0 }));
    handleSubmit();
  };

  useEffect(() => {
    if (!getViewVisible("drives")) {
      dispatch(
        getDrives({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          college: values.college,
          placement: values.placement,
          placementType: values.placementType,
          year: values?.year,
        })
      );

      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (year) handleYearChange("e", yearDropDown[yearDropDown?.length - 1]);
  }, [year]);

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Drives
            <Typography variant="body2">
              Total Records : {totalCounts}
            </Typography>
          </Typography>

          {getAddVisible("drives") && (
            <Stack direction="row" alignItems="center">
              <Button
                component={Link}
                to="/drives/add"
                variant="contained"
                startIcon={<AddIcon />}
              >
                Add Drives
              </Button>
            </Stack>
          )}
        </div>
      </Stack>
      <Card className="holiday-table-grid" mb={5}>
        <Container maxWidth="xl">
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} mb={3}>
                <Grid item xs={3} mt={3}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/*<InputLabel id="demo-simple-select-helper-label">
                      College
                    </InputLabel>
                    <Select
                      inputProps={{
                        "data-cy": "txt-college-drives",
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="College"
                      size="small"
                      name="college"
                      onChange={handleCollegeChange}
                      value={values.college}
                    >
                      {collegeData &&
                        Array.isArray(collegeData) &&
                        collegeData.map((college, idx) => (
                          <MenuItem key={idx} value={college.id}>
                            {college.collegeName}
                          </MenuItem>
                        ))}
												</Select> */}
                    <Autocomplete
                      disabled={getViewVisible("college") === false}
                      size="small"
                      fullWidth
                      options={collegeData || []}
                      label="college"
                      name="college"
                      getOptionLabel={(option) => option.collegeName || ""}
                      onChange={handleCollegeChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="College"
                        />
                      )}
                      value={
                        collegeData
                          ? collegeData.find(
                              (education) => education.id === values.college
                            ) || ""
                          : ""
                      }
                      //error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} mt={3}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/*<InputLabel id="demo-simple-select-helper-label">
                      Placement
                    </InputLabel>
                    <Select
                      inputProps={{
                        "data-cy": "txt-collegeid-search-drives",
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Placement"
                      size="small"
                      name="placement"
                      onChange={handlePlacementChange}
                      value={values.placement}
                    >
                      <MenuItem value="Single">Single</MenuItem>
                      <MenuItem value="Pool">Pool</MenuItem>
                    </Select>*/}

                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="placement"
                      size="small"
                      name="placement"
                      onChange={handlePlacementChange}
                      value={values.placement}
                      options={placement || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="Placement"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={3} mt={3}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/*<InputLabel id="demo-simple-select-helper-label">
                      Placement Type
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="placementType"
                      value={values.placementType}
                      label="Placement Type"
                      size="small"
                      fullWidth
                      onChange={handlePlacementTypeChange}
                    >
                      <MenuItem value="On Campus">On Campus</MenuItem>
                      <MenuItem value="Off Campus">Off Campus</MenuItem>
                    </Select>*/}
                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Marital Status"
                      size="small"
                      name="placementType"
                      onChange={handlePlacementTypeChange}
                      value={values.placementType}
                      options={placementType || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="Placement Type"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={3} mt={3}>
                  <Autocomplete
                    // multiple

                    size="small"
                    fullWidth
                    options={yearDropDown || []}
                    // getOptionLabel={(option) => option.title}
                    name="year"
                    onChange={handleYearChange}
                    renderInput={(params) => (
                      <TextField {...params} label="Year" />
                    )}
                    value={values.year || ""}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "10px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {filterChips.length > 0 ? (
        <Typography variant="body2" mb={1}>
          {data.length} {"records found"}
        </Typography>
      ) : (
        ""
      )}

      <Card>
        <CustomPagination
          totalPage={totalPages}
          totalCount={totalRecords}
          limit={limit}
          handleChangePage={handleChangePage}
          page={currentPage}
          rowsPerPageOptions={[10, 20, 40]}
          handleRowsPerPageChange={handleChangeRowsPerPage}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ maxWidth: "100px" }} align="left">
                    <TableSortLabel
                      active={sortBy === "collegeName"}
                      direction={sortBy === "collegeName" ? orderBy : "asc"}
                      onClick={() => handleSorting("collegeName")}
                    >
                      College Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "placement"}
                      direction={sortBy === "placement" ? orderBy : "asc"}
                      onClick={() => handleSorting("placement")}
                    >
                      Placement
                    </TableSortLabel>
                  </TableCell>

                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "placementType"}
                      direction={sortBy === "placementType" ? orderBy : "asc"}
                      onClick={() => handleSorting("placementType")}
                    >
                      Placement Type
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "placementDate"}
                      direction={sortBy === "placementDate" ? orderBy : "asc"}
                      onClick={() => handleSorting("placementDate")}
                    >
                      Placement Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "year"}
                      direction={sortBy === "year" ? orderBy : "asc"}
                      onClick={() => handleSorting("year")}
                    >
                      Year
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" style={{ width: "220px" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>

              {loading ? (
                <TableCell align="center" width="100%" colSpan={10}>
                  <Loader />
                </TableCell>
              ) : (
                <TableBody>
                  {Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell
                        className="No-Record-text"
                        align="center"
                        colSpan={6}
                      >
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((drive, idx) => (
                      <TableRow key={idx}>
                        <TableCell
                          // sx={{ maxWidth: "100px" }}
                          size="small"
                          align="left"
                        >
                          {drive?.college?.collegeName}
                        </TableCell>
                        <TableCell align="left">{drive?.placement}</TableCell>
                        <TableCell align="left">
                          {drive?.placementType}
                        </TableCell>
                        <TableCell align="left">
                          {indianDateFormat(drive?.placementDate)}
                        </TableCell>
                        <TableCell align="left">{drive?.year}</TableCell>
                        <TableCell size="medium" align="left">
                          <div
                            className="action-button"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            {getEditVisible("drives") && (
                              <span
                                style={{
                                  marginRight: 8,
                                }}
                              >
                                <IconButton
                                  color="primary"
                                  component={Link}
                                  to={`/drives/edit/${encryption(drive.id)}`}
                                >
                                  <EditIcon />
                                </IconButton>
                              </span>
                            )}
                            {getDeleteVisible("drives") && (
                              <span
                                style={{
                                  marginRight: 8,
                                }}
                              >
                                <IconButton
                                  onClick={() => handleDelete(drive.id)}
                                >
                                  <DeleteIcon color="error" />
                                </IconButton>
                              </span>
                            )}
                            <span
                              style={{
                                marginRight: 8,
                              }}
                            >
                              <IconButton
                                component={Link}
                                to={`/drives/view/${encryption(drive.id)}`}
                              >
                                <VisibilityIcon />
                              </IconButton>
                            </span>
                            <span>
                              <IconButton
                                onClick={() => openExcelModal(drive.id)}
                              >
                                <DocumentScannerIcon />
                              </IconButton>
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: Drives,
  path: "/drives",
  public: false,
  layout: DashboardLayout,
  group: "TPA",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
