import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

import DashboardLayout from "../../../../layouts/dashboard";
import Loader from "../../../../components/Loader";
import UserMoreActions from "../UserMoreActions";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Badge from "@mui/material/Badge";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import * as XLSX from "xlsx";

import { getDesignation } from "../../../../redux/designation/designationThunk";
import { getDepartment } from "../../../../redux/department/departmentThunk";
import { getEmployeeTechnology, getJoiningEmployee } from "../../../../redux/employee/employeeThunk";
import {
	setPage,
	setHasMore,
	setData,
	setOrderBy,
	setSortBy
} from "../../../../redux/employee/joiningEmployeeSlice";
import { getNewJoiningEmployeeCount } from "../../../../redux/employee/employeeThunk";
import { BASE_URL } from "../../../../constants";
import { encryption } from "../../../../utils/encodeString";
import { Paper } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../../utils/userPermission";
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import { indianDateFormat } from "../../../../utils/dateFormat";
import { getUserPermissionByRoll } from "../../../../redux/userPermission/userPermissionthunk";
import localStorage from "../../../../service/localStorage";
import invertDirection from "../../../../utils/invertDirection";
import { exportEmployeeFile } from "../../../../redux/employee/employeeThunk";
import { getTechnology } from "../../../../redux/technology/technologyThunk";
import { getCityData } from "../../../../utils/getLocationData";
function JoiningEmployee() {
	const observer = useRef();
	const [isSearching, setIsSearching] = useState(false);
  const inputRef = React.useRef(null);

	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const AuthRole = useSelector((state) => state.auth.role);

	const params = location.state;
  const [cities, setCities] = useState([]);
	const data = useSelector((state) => state.joiningEmployee.data);
	const limit = useSelector((state) => state.joiningEmployee.limit);
	const page = useSelector((state) => state.joiningEmployee.page);
	const sortBy = useSelector((state) => state.joiningEmployee.sortBy);
	const orderBy = useSelector((state) => state.joiningEmployee.orderBy);
	const status = useSelector((state) => state.joiningEmployee.status);
	const error = useSelector((state) => state.joiningEmployee.error);
	const loading = useSelector((state) => state.joiningEmployee.loading);
	const isSearchQuery = useSelector(
		(state) => state.joiningEmployee.isSearchQuery,
	);
	const totalCount = useSelector((state) => state.joiningEmployee.totalCount);
	const hasMore = useSelector((state) => state.joiningEmployee.hasMore);
  const technologyData = useSelector((state) => state.technology);
  const locationDropdown = [{title:'HOME',id:'HOME'},{title:'OFFICE',id:'OFFICE'},{title :'HYBRID',id:'HYBRID'}];
  const genderDropdown = [{title:'MALE',id:'M'},{title:'FEMALE',id:'F'},{title :'OTHER',id:'O'}];
  const sortByData = [{title:"Ascending(By Name)", value:"asc"},{title:"Descending(By Name)", value:"desc"}, {title:"By Joining Date", value:"joining"}];

	const totalJoiningEmployeeCount = useSelector(
		(state) => state.joiningEmployeeCount.totalCount,
	);

	//designation & department
	const designation = useSelector((state) => state.designation);
	const department = useSelector((state) => state.department);
	const [scrollApiCall, setScrollApiCall] = useState(false);
	const [filter, setFilter] = useState(false);
	const [filterData, setFilterData] = useState({});
	const [filterChips, setFilterChips] = useState([]);
  const [employeeData, setEmployeeData]= useState([]);
  const [technologyForIcon, setTechnologyForIcon] = useState([]);


	// if (!getViewVisible("employee")) {
	// 	navigate("/dashboard");
	// }

	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			localStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=localStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])

  useEffect(() => {
    dispatch(getTechnology({}));
  }, [dispatch]);

  useEffect(() => {
		getCityData("Gujarat")
			.then((cities) => {
				setCities(cities);
				// setpermanentCities(cities);
			})
			.catch((err) => {
				setCities([]);
			});
	}, [dispatch]);

  useEffect(() => {
    const fetchEmployeeTechnology = async () => {
      if (data && data.length > 0) {
        try {
          const idArray = data.map((emp) => emp.id);
          const res = await dispatch(getEmployeeTechnology(idArray)).unwrap();
          
          if (res?.success && Array.isArray(res.data)) {
            setTechnologyForIcon(res.data);
          }
        } catch (error) {
          console.error("Failed to fetch employee technology:", error);
          // Handle the error appropriately, e.g., show a notification
        }
      }
    };

    fetchEmployeeTechnology();
  }, [data]);

	const lastBookElementRef = useCallback(
		(node) => {
			if (!isSearching) {
				if (loading) return;
				if (observer.current) observer.current.disconnect();

				observer.current = new IntersectionObserver((entries) => {
					if (entries[0].isIntersecting && hasMore) {
						dispatch(setPage({ page: page + 1 }));
					}
				});
				if (node) observer.current.observe(node);
			}
		},
		[loading, hasMore],
	);
	useEffect(() => {
		dispatch(getDesignation({sortBy: '', orderBy: ''}))
		dispatch(getNewJoiningEmployeeCount({}));
	}, [dispatch]);

	useEffect(() => {
		// if (status === "idle" && !error) {
			// dispatch(getJoiningEmployee({ limit, page: page + 1 }));
			dispatch(
				getJoiningEmployee({
					page,
					limit,
					...params,
					scrollApiCall: scrollApiCall,
					filter: filter,
					...filterData,
          status: "joining-employee",
					// sortBy,orderBy,
          orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
          sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
				}),
			);
		// }
	}, [dispatch, limit, page, sortBy, orderBy, params]);

	useEffect(() => {
		return () => {
			setScrollApiCall(false);
			dispatch(setHasMore(true));
			dispatch(setPage({ page: 1 }));
			dispatch(setData([]));
		};
	}, []);

	const {
		handleSubmit,
		values,
		handleChange,
		resetForm,
		isSubmitting,
		getFieldProps,
		setFieldValue,
	} = useFormik({
		initialValues: {
      search: "",
      workLocation: null,
      city: "",
      gender: "",
      technologyId: "",
      sort:"",
		},
		onSubmit: async (values) => {
			try {
				setFilter(true);
				setFilterData({ ...values });
				/* if (queryParams.designationId.length > 0) {
					queryParams["designationId"] = queryParams.designationId.map(
						(i) => i.id,
					);
				} else {
					queryParams["designationId"] = "";
				} */
				dispatch(
					getJoiningEmployee({
						...values,
						limit,
						page: 1,
						...params,
						scrollApiCall: scrollApiCall,
						filter: true,
						newData: true,
            status: "joining-employee",
						// sortBy,orderBy
            orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
            sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
					}),
				);
				setState({ ...state, right: false });
				prepareChips(values);
			} catch (error) {
				toast.error(error.response.data.message);
			}
		},
	});

	useEffect(() => {
		return () => {
			resetForm();
			dispatch(
				getJoiningEmployee({
					limit,
					page: page,
					scrollApiCall: scrollApiCall,
					filter: filter,
					...filterData,
					// sortBy,orderBy,
          status: "joining-employee",
          orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
          sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
				}),
			);

			setState({ ...state, right: false });
			setFilterChips([]);
		};
	}, []);

	// sidebar-part functionality
	const [state, setState] = React.useState({
		right: false,
	});

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};

	const handleReset = () => {
		resetForm();
		dispatch(
			getJoiningEmployee({
				limit,
				page: 1,
				scrollApiCall: scrollApiCall,
				filter: false,
				newData: true,
        status: "joining-employee",
				// sortBy,orderBy
        orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
        sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
			}),
		);
		setState({ ...state, right: false });
		setFilter(false);
		setFilterData({});
		setFilterChips([]);
	};

	// const handleSearch = async (e) => {
	// 	setIsSearching(true);
	// 	await dispatch(
	// 		getJoiningEmployee({
	// 			search: e?.target?.value,
	// 			...filterData,
	// 			limit,
	// 			page: 1,
	// 			newData: true,
	// 			// sortBy,orderBy
  //       orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
  //       sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
	// 		}),
	// 	);
	// };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    // handleSubmit();
    setIsSearching(true);
    // await dispatch(
    //   getJoiningEmployee({
    //     search: e?.target?.value,
    //     ...filterData,
    //     limit,
    //     page: 1,
    //     newData: true,
    //     // sortBy,orderBy
    //     orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
    //     sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
    //   }),
    // );
    handleSubmit();
    // await dispatch(
    // 	getEmployee({
    // 		search: e?.target?.value,
    // 		...params,
    // 		...filterData,
    // 		limit,
    // 		page: 1,
    // 		newData: true,
    // 		scrollApiCall: scrollApiCall,
    // 		orderBy,
    // 		sortBy,
    // 	}),
    // );
  };

	const debounceWithSearch = debounce(handleSearch, 500);

	const prepareChips = (values) => {
		let filters = { ...values };
		let tempChips = [];

		for (const key in filters) {
      if (filters[key] && key === "workLocation") {
        const res = locationDropdown?.find(
          (w) => w.id === filters[key]
        );
        if (res) filters[key] = `Work Location : ${res.title}`;
      }
      
      if (filters[key] && key === "gender") {
        const res = genderDropdown.find(
          (w) => w.id === filters[key]
        );
        if (res) filters[key] = `Gender : ${res.title}`;
      }

      if (filters[key] && key === "technologyId") {
        const res = technologyData.data.find(
          (t) => t.id === filters[key]
        );
        if (res) filters[key] = `Technology : ${res.title}`;
      }

      if (filters[key] && key === "city") {
        const res = cities?.find(
          (c) => c === filters[key]
        );
        if (res) filters[key] = `City : ${res}`;
      }

      if (filters[key] && key === "sort") {
        const res = sortByData?.find(
          (t) => t.value === filters[key]
        );
        if (res) filters[key] = `Sort : ${res.title}`;
      }

      if (filters[key] && key === "search") {
        filters[key] = `${values.search}`;
      }

			if (filters[key]) {
				tempChips = [...tempChips, { type: key, filter: filters[key] }];
			}
		}

		setFilterChips(tempChips);
	};

	const onDeleteChip = (value, length) => {
		setFilterChips([
			...filterChips.filter((val) => val.filter !== value.filter),
		]);
		setFieldValue(value.type, "");
		let filters = { ...filterData, [value["type"]]: "" };

		setFilterData(filters);
		if (length == 1) {
			dispatch(
				getJoiningEmployee({
					limit,
					page: 1,
					newData: true,
					scrollApiCall: scrollApiCall,
					// sortBy,orderBy
          status: "joining-employee",
          orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
          sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
				}),
			);
		} else {
			dispatch(
				getJoiningEmployee({
					limit,
					page: 1,
					newData: true,
					scrollApiCall: scrollApiCall,
					filter: filter,
					...filters,
          status: "joining-employee",
					// sortBy,orderBy
          orderBy: values?.sort == "asc" || values?.sort == "desc" ? values?.sort : "desc",
          sortBy: values?.sort == "asc" || values?.sort == "desc" ? "firstName" : "joiningDate",
				}),
			);
		}
    if (value.type === "search") {
      // setSearchValue("");
      inputRef.current.value = "";
    }
	};

  const setTotalExpTime = (data) => {
    let experience = {
      totalExpYears: 0,
      totalExpMonths: 0,
      totalCCCYears: 0,
      totalCCCMonths: 0
    }

    let experienceArr = data.experience;
    let totalYears = experience.totalExpYears;
    let totalMonths = experience.totalExpMonths;

    if (experienceArr?.length > 0) {
      for (let i = 0; i < experienceArr?.length; i++) {
        let start = moment(experienceArr[i].from, "YYYY-MM-01");
        let end = moment(
          experienceArr[i]?.to ? experienceArr[i]?.to : new Date(),
          "YYYY-MM-01"
        );
        let monthsCount = moment(end).diff(moment(start), "months");
        monthsCount += 1;
        let floored = Math.floor(monthsCount / 12);
        let finalValue = monthsCount - floored * 12;
        totalYears = totalYears + floored;
        totalMonths = totalMonths + finalValue;
        if (totalMonths >= 12) {
          totalMonths = totalMonths - 12;
          totalYears = totalYears + 1;
        }
      }


      let overallYears = totalYears ;
      let overallMonths = totalMonths;

      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      experience = {...experience , 
          overallMonths: overallMonths,
          overallYears: overallYears,
          totalExpYears: totalYears,
          totalExpMonths: totalMonths,
        }

    }
    let exp_str = ""
      exp_str = exp_str + '<div class="total-experience">';
      exp_str = exp_str + " "
      if(experience?.overallYears && experience?.overallMonths)
      {
        exp_str = exp_str +`${experience?.overallYears}.${experience?.overallMonths} Yrs`
      }
      if(!experience?.overallYears && experience?.overallMonths)
      {
        exp_str = exp_str + `0.${experience?.overallMonths} Yrs`
      }
      if(experience?.overallYears && !experience?.overallMonths)
      {
        exp_str = exp_str + `${experience?.overallYears} Yrs`
      }
      if(!experience.overallYears && !experience.overallMonths)
      {
        exp_str = exp_str + `0.0 Yrs`
      }

      exp_str = exp_str + " "
      exp_str = exp_str + "</div>";

      // return exp_str
      return (
        <div dangerouslySetInnerHTML={{ __html: exp_str }} />
      );
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
    } catch (err) {
      toast.error("Text Not Copied!");
    }

    document.body.removeChild(textArea);
  };

	const handleSorting = () => {
		dispatch(setSortBy({ sortBy: 'firstName' }));
		dispatch(
		  setOrderBy({
			orderBy: invertDirection(sortBy === 'firstName', orderBy),
		  })
		);
	  };

    const ordinal_suffix_of = (i) => {
      var j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return i + "st";
      }
      if (j === 2 && k !== 12) {
        return i + "nd";
      }
      if (j === 3 && k !== 13) {
        return i + "rd";
      }
      return i + "th";
    };

    const exportFile = async () => {
      try {
        const data = await dispatch(getJoiningEmployee({})).unwrap();
        const worksheet = XLSX.utils.json_to_sheet(data?.data?.data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "New_Joining_Employees.xlsx");
      } catch (error) {
        toast.error(error?.message);
      }
    };

    const handleFilters = async (field,newValue) => {
      setFieldValue(field, newValue || "");
      handleSubmit();
      setIsSearching(true);
    }

	return (
    <Container className="New-Joining-container" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={0}
      >
      <div className="title-button-wrapper New-Joining-header">
        <Typography variant="h4" gutterBottom>
          New-Joining Employees:{" "} {totalJoiningEmployeeCount}
          {/* <Typography variant="body2">
            Total Records : 
          </Typography> */}
        </Typography>
        
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            className="employee-header-right"
          >
           
            {AuthRole === "Super Admin" && Array.isArray(data) && data.length !== 0 ?
            <Button
              variant="contained"
              onClick={exportFile}
              startIcon={<FileUploadSharpIcon />}
              className="filter-icon-part"
            >
              Export
            </Button> : ""}
            <Button component={RouterLink} to="/employee" variant="contained" startIcon={<ArrowBackIcon />}>
              Back To Employees
            </Button>
          </Stack>
        </div>
      </Stack>

      {/* <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={3}>
              <Grid className="employee-table-grid-item" item xs={12} my={3}>
                <TextField
                  fullWidth
                  label="Search by Name/Email/PhoneNumber"
                  name="title"
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card> */}
      <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
             
                <div className="employee-classification-right" style={{paddingTop:"20px"}}>
                <TextField
                  className="employee-search-detail"
                  fullWidth
                  label="Search by Name/Email/Phone Number"
                  name="title"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
                  {/* <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      defaultValue=""
                      options={technologyData?.data || []}
                      getOptionLabel={(option) => option?.title || ""}
                      onChange={(event, newValue) => {
                        handleFilters("technologyId",newValue?.id || "")
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Technology"
                        />
                      )}
                      value={technologyData?.data?.find(
                        (t) => t.id == values?.technologyId
                      ) || ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete 
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={locationDropdown || []}
                      getOptionLabel={(option) => option?.title || ""}
                      name="teamId"
                      onChange={(event, newValue) => {
                        handleFilters("workLocation",newValue?.id)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Work Location" />
                      )}
                      value={locationDropdown?.find(
                        (t) => t.id === values.workLocation
                      ) || ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      name="city"
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      defaultValue=""
                      options={cities || []}
                      value={values.city || ""}
                      onChange={(event, newValue) => {
                        // setFieldValue(`city`, newValue || "");
                        handleFilters("city",newValue || "")
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="City"
                        />
                      )}
                    />
                  </div> */}
                  <div className="employee-classification-dropdown">
                    <Autocomplete 
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={genderDropdown || []}
                      getOptionLabel={(option) => option.title || ""}
                      name="gender"
                      onChange={(event, newValue) => {
                        // setFieldValue(
                        //   "gender",
                        //   newValue?.id || ""
                        // );
                        handleFilters("gender",newValue?.id || "")
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Gender" />
                      )}
                      value={genderDropdown?.find(
                        (t) => t.id === values.gender
                      ) || ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={sortByData || []}
                      name="sort"
                      getOptionLabel={(option) => option?.title || ""}
                      value={
                        sortByData?.find(
                          (s) => s.value == values.sort
                        ) || ""
                      }
                      onChange={(event, newValue) => {
                        // setsort(newValue?.value)
                        // setFieldValue("sort", newValue?.value)
                        
                        handleFilters("sort",newValue?.value)
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Sort By"
                        />
                      )}
                    />
                  </div>
                    {/* <div className="employee-classification-dropdown employee-classification-Filter">
                      {["right"].map((anchor, idx) => (
                        <React.Fragment key={idx}>
                          <span
                            className="filter-icon-part FilterIcon"
                            onClick={toggleDrawer(anchor, true)}
                          >
                            <IconButton>
                                <img src="/assets/images/filter.svg" />
                            </IconButton>
                          </span>

                          <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                            style={{ zIndex: "1300" }}
                          >
                            <Box
                              pt={4}
                              px={3}
                              sx={{
                                width:
                                  anchor === "top" || anchor === "bottom" ? "auto" : 400,
                              }}
                              role="presentation"
                            >
                              <div className="content-filter-part">
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  mb={3}
                                >
                                  <Typography variant="h6">Employee Filter</Typography>
                                  <span>
                                    <IconButton onClick={toggleDrawer(anchor, false)}>
                                      <CloseIcon />
                                    </IconButton>
                                  </span>
                                </Stack>

                                <form onSubmit={handleSubmit}>
                                  <Grid container spacing={3} style={{paddingTop:"0px"}}>
                                   
                                    <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete
                                        size="small"
                                        name="city"
                                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                        defaultValue=""
                                        options={cities || []}
                                        value={values.city}
                                        onChange={(event, newValue) => {
                                          setFieldValue(`city`, newValue || "");
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="City"
                                          />
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete 
                                        size="small"
                                        fullWidth
                                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                        options={genderDropdown || []}
                                        getOptionLabel={(option) => option.title || ""}
                                        name="gender"
                                        onChange={(event, newValue) => {
                                          setFieldValue(
                                            "gender",
                                            newValue?.id || ""
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} label="Gender" />
                                        )}
                                        value={genderDropdown?.find(
                                          (t) => t.id === values.gender
                                        )}
                                      />
                                    </Grid>
                                    <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete
                                        labelId="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        size="small"
                                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                                        options={sortByData || []}
                                        name="sort"
                                        getOptionLabel={(option) => option?.title || ""}
                                        value={
                                          sortByData?.find(
                                            (s) => s.value == values.sort
                                          ) || ""
                                        }
                                        onChange={(event, newValue) => {
                                          setFieldValue("sort", newValue?.value)
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Sort By"
                                          />
                                        )}
                                      />
                                    </Grid>
                                  </Grid>
                                  
                                  <Stack direction="row" justifyContent="flex-end" mb={3} mt={3}>
                                    <LoadingButton
                                      type="submit"
                                      variant="contained"
                                      loading={isSubmitting}
                                      startIcon={<SearchIcon />}
                                    >
                                      Search
                                    </LoadingButton>
                                    <Button
                                      variant="contained"
                                      type="reset"
                                      onClick={handleReset}
                                      startIcon={<CachedIcon />}
                                      sx={{ marginLeft: "10px" }}
                                    >
                                      Reset
                                    </Button>
                                  </Stack>
                                </form>
                              </div>
                            </Box>
                          </Drawer>
                        </React.Fragment>
                      ))}
                    </div> */}
                </div>
              </Grid>
            
          </form>
        </Container>
      </Card>

      <Stack
       className="employee-table-Stack search-filter"
        style={{ marginBottom: "10px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="employee-table-Stack-label search-filter-detail"
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>

      {isSearchQuery ? (
        <Typography variant="body2" mb={1}>
          {totalCount} records found
        </Typography>
      ) : (
        ""
      )}

      <Grid
        container
        spacing={1}
        style={{
          display: "grid",
          // marginTop: "-50px",
        }}
        className="employee-grid"
      >
        {(Array.isArray(data) && data?.length === 0 && !loading) ||
        (status === "rejected" && isSearchQuery) ? (
          <Typography className="No-Record-text" align="justify" variant="inherit" gutterBottom>
            No Record(s) Found
          </Typography>
        ) : (
          data.map((employee, idx) => {
            return (
              <Grid key={idx} ref={lastBookElementRef}>
                <div className="view_employee candidate-details" style={{padding: "0", maxWidth:"100%", width:"100%"}}>
                <Box sx={{ flexGrow: 1 }} >
                  <Grid container spacing={2} className="grid_container" style={{marginBottom:"0px"}}>
                    <Grid
                      className="view_employee_grid-item"
                      item
                      xs={12}
                      style={{ maxWidth: "100%", flexBasis: "100%"}}
                    >
                    
                    <Card className="information-card card_height">
                      <Paper
                        sx={{
                          textDecoration: "none",
                          color: "#212B36",
                        }}

                        // onClick={(e) =>
                        //   window.open(
                        //     `/employee/view/${encryption(employee.id)}`,
                        //     "_blank"
                        //   )
                        // }
                        onClick={employee?.type == "candidate" ? ()=>window.open(
                          `/candidate/view/${encryption(employee.id)}`,
                          "_blank"
                            ) : 
                            () => ()=>window.open(
                              `/employee/view/${encryption(employee.id)}`,
                              "_blank"
                            )
                          }
                        >
                        <Container className="personal-details-section" maxWidth="xl" style={{ backgroundColor:"#F8F9FA",padding:"16px" }} >
                            <div className="Special-Day-div">

                                {(new Date(employee?.dateOfBirth).getDate() == new Date().getDate() && 
                                  new Date(employee?.dateOfBirth).getMonth() == new Date().getMonth()) ?
                                    <p className="Birthday-tab">Birthday</p>
                                : ""}
                              </div>
                          <Stack className="personal-image-left" direction="row" justifyContent="space-between">
                            <div className="personal-image-section" style={{ width:"-webkit-fill-available", maxWidth:"50%"}}>
                              <Grid
                                item
                                xs={4}
                                className="personal-image-section-grid"                                
                              >
                                <div className="personal-left-detail-content">
                                  <Avatar
                                    className="personal-profile"
                                    src={
                                      employee?.employeeImage &&
                                      `${BASE_URL}/${employee?.employeeImage}`
                                    }
                                    alt={employee.firstName}
                                  ></Avatar>
                                  {/* <Typography align="center"> */}
                                  <h4 className="employeeName" align="center">{`${employee.firstName} ${employee.lastName}`}</h4>

                                  <p className="job-position-txt">
                                  {employee?.workDetails?.designation?.title}
                                  </p>
                                
                                  <p className="job-position-txt">
                                    {employee?.employeeId}
                                  </p>
                                  <Stack
                                    className="employee-contact"
                                    direction="row"
                                    justifyContent="center"
                                    flexWrap="wrap"
                                    sx={{
                                      gridGap: "3px",
                                      fontSize: ".8rem",
                                    }}
                                    // mt={1}
                                    mb={1}
                                  >
                                    <Link
                                      href={`tel: ${employee?.contactNumber}`}
                                      color="text.secondary"
                                    >
                                      {employee?.contactNumber}
                                    </Link>
                                    {employee?.alternateContactNumber && (
                                      <>
                                        <span>|</span>
                                        <Link
                                          href={`tel: ${employee?.alternateContactNumber}`}
                                          color="text.secondary"
                                        >
                                          {employee?.alternateContactNumber}
                                        </Link>
                                      </>
                                    )}
                                  </Stack>
                                </div>
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="center"
                                style={{marginLeft:"0px"}}
                                sx={{ gridGap: "8px" }}
                              >
                              {employee?.skype ? (
                                <Box
                                  component="img"
                                  src="/assets/images/skype-graybox.svg"
                                  title="Skype"
                                  className="skype"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(
                                      `skype:${employee?.skype}?chat`,
                                      "_blank"
                                    );
                                  }}
                                />
                              ) : (
                                ""
                              )}

                            {employee?.linkedIn ? (
                              <Box
                                component="img"
                                src="/assets/images/linkedin-graybox.svg"
                                className="linkdin"
                                title="LinkedIn"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(
                                    employee?.linkedIn,
                                    "_blank"
                                  );
                                }}
                              />
                            ) : (
                              ""
                            )}
                            {employee?.github ? (
                              <Box
                                component="img"
                                src="/assets/images/copylink-graybox.svg"
                                className="download-img"
                                Title="Open Github"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  window.open(employee?.github, "_blank");
                                }}
                              />
                            ) : (
                              employee?.portfolioWebsite ? (
                                <Box
                                  component="img"
                                  src="/assets/images/copylink-graybox.svg"
                                  className="mail-img"
                                  title="Open Portfolio"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(employee?.portfolioWebsite, "_blank");
                                  }}
                                />
                              ) : (
                                ""
                              )
                            )}
                            
                            {employee?.resume &&
                              (
                                <Box
                                  component="img"
                                  src="/assets/images/download-graybox.svg"
                                  className="copylink"
                                  title="Open Resume"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    window.open(
                                      employee?.resume &&
                                        employee?.resume
                                          ?.length > 0 &&
                                        employee?.resume[0]
                                          .webViewLink,
                                      "_blank"
                                    );
                                  }}
                                />
                              )}

                            {employee?.email &&
                                  <Tooltip title={employee?.email} placement="bottom">
                                    <Box
                                      component="img"
                                      src="/assets/images/mail-graybox.svg"
                                      className="edit"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        ClicktoCopy(employee?.email);
                                      }}
                                    />
                                  </Tooltip>
                                }

                              <IconButton
                                style={{ padding: "0px" }}
                              
                                onClick={employee?.type == "candidate" ? ()=>window.open(
                                        `/candidate/add/${encryption(employee.id)}`,
                                        "_blank"
                                      ) : 
                                      () => ()=>window.open(
                                        `/employee/add/${encryption(employee.id)}`,
                                        "_blank"
                                      )
                                    }
                              >
                                <img src="/assets/images/edit-graybox.svg" title="Edit Employee"/>
                              </IconButton>
                              </Stack>
                              </Grid>
                            </div>

                          <Grid className="personal-content-section" item xs={8}>
                            <Card 
                              style={{ padding:"16px" }} 
                              className="address-card information-card"
                            >
                                  <Grid className="address-card-container" container spacing={2}>
                                    <Grid
                                      className="address-card-detail"
                                      item
                                      xs={10}
                                      style={{ maxWidth: "100%", flexBasis: "100%" }}
                                    >
                                      <div className="address-blk">
                                        <div className="address-sub-section general_sec">
                                          <div className="address-div">
                                            <span>Joining</span>
                                            <p>
                                              {employee?.joiningDate != null
                                              ? indianDateFormat(employee?.joiningDate) 
                                              : "-"}
                                            </p>
                                          </div>
                                          <div className="address-div">
                                            <span>Production</span>
                                            <p>
                                              {employee?.productionDate != null
                                              ? indianDateFormat(employee?.productionDate) 
                                              : "-"}
                                            </p>
                                          </div>
                                          <div className="address-div">
                                            <span>Commitment</span>
                                            <p>{employee?.commitmentStartDate != null 
                                              ? indianDateFormat(employee?.commitmentStartDate) 
                                              : "-" }
                                            </p>
                                          </div>
                                            <div className="address-div">
                                            <span>Lead</span>
                                            <p>{employee?.teamLeadName
                                              ? `${employee?.teamLeadName?.firstName} ${employee?.teamLeadName?.lastName}` 
                                              : "-"}
                                            </p>
                                          </div>
                                          <div className="address-div work-experience-detail">
                                            <span>Exp.</span>
                                            <p className="work-experience">
                                            {setTotalExpTime(employee)}
                                            </p>
                                          </div>
                                          {/* {employee?.workDetails?.productionDate != null ?  */}
                                          
                                          </div>
                                        </div>
                                        {/* <div style={{position:"relative"}}>
                                              <p style={{position: "absolute",bottom:"0",right:"0",}}>Bet 5 days ago</p>
                                        </div> */}
                                      </Grid>
                                    </Grid>
                                  {/* </TabContext> */}
                                {/* </Container> */}
                              </Card>

                              <div className="technology-details">
                                {employee?.experience && Array.isArray(employee?.experience) && employee?.experience.length > 0 ? 
                                    employee?.experience.map((exp) => { 
                                      if (exp?.technologies?.length > 0) {
                                          return exp?.technologies.map((tech) => (
                                              <img
                                                key={tech?.technology?.id}
                                                className="technology-icons"
                                                src={tech?.technology?.icon ? `${BASE_URL}/${tech?.technology?.icon}` : "/assets/images/technology-icon.svg"}
                                                title={tech?.technology?.title}
                                              />
                                          ));
                                      } else {
                                          return null;
                                      }
                                    })
                                    : 
                                    employee?.technologies && Array.isArray(employee?.technologies) ? 
                                      employee?.technologies?.map((tech) => { 
                                        return(
                                        <img
                                          key={tech?.id}
                                          className="technology-icons"
                                          src={tech?.icon ? `${BASE_URL}/${tech?.icon}` : "/assets/images/technology-icon.svg"}
                                          title={tech?.title}
                                        />)
                                      })
                                  : null
                                }
                              </div>
                              {/* <div className="technology-details">
                                {employee?.technologies && Array.isArray(employee?.technologies) ? 
                                    employee?.technologies?.map((tech) => { 
                                      return(
                                      <img
                                        key={tech?.id}
                                        className="technology-icons"
                                        src={tech?.icon ? `${BASE_URL}/${tech?.icon}` : "/assets/images/technology-icon.svg"}
                                        title={tech?.title}
                                      />)
                                    })
                                    : null
                                }
                              </div> */}
                            </Grid>
                          </Stack>
                        </Container>
                      </Paper>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              {loading && <Loader />}
                </div>
              </Grid>
            )
          })
        )}

      </Grid>
      {/* <Grid
      className="new-employee-grid"
        container
        spacing={2}
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        {Array.isArray(data) && data.length === 0 && !loading ? (
          <p className="no-record">No Record(s) Found</p>
        ) : (
          data.map((employee, idx) => {
            if (data.length === idx + 1) {
              return (
                <Grid className="gray-box Contact-Detail-content" item xs={3} key={idx} ref={lastBookElementRef}>
                  <Card className="employee-block">
                    <CardHeader
                      className="Employee-card-header"
                      sx={{ paddingTop: "10px" }}
                      action={
                        <UserMoreActions
                          edit={() =>
                            employee.type === "employee"
                              ? window.open(
                                  `/employee/add/${encryption(employee.id)}`,
                                  "_blank"
                                )
                              : window.open(
                                  `/candidate/add/${encryption(employee.id)}`,
                                  "_blank"
                                )
                          }
                          view={() =>
                            employee.type === "employee"
                              ? window.open(
                                  `/employee/view/${encryption(employee.id)}`,
                                  "_blank"
                                )
                              : window.open(
                                  `/candidate/view/${encryption(employee.id)}`,
                                  "_blank"
                                )
                          }
                        />
                      }
                    />
                    <Paper
                    className="Employee-card-body"
                      sx={{
                        textDecoration: "none",
                        color: "#212B36",
                      }}

                      onClick={() =>
                        employee.type === "employee"
                          ? window.open(
                              `/employee/view/${encryption(employee.id)}`,
                              "_blank"
                            )
                          : window.open(
                              `/candidate/view/${encryption(employee.id)}`,
                              "_blank"
                            )
                      }
                    >
                      <figure className="employee-img">
                        <Avatar
                          src={
                            employee?.employeeImage &&
                            `${BASE_URL}/${employee?.employeeImage}`
                          }
                          alt={employee.firstName}
                          sx={{
                            width: 100,
                            height: 100,
                            fontSize: "2.5rem",
                          }}
                        ></Avatar>
                      </figure>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          paddingTop: "10px",
                        }}
                      >
                        <h3 className="employeeName">{`${employee.firstName} ${employee.lastName}`}</h3>
                        <Typography
                          className="designation"
                          variant="body2"
                          color="text.secondary"
                          mb={1.5}
                        >
                          {employee?.workDetails?.designation?.title}
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          flexWrap="wrap"
                          sx={{
                            gridGap: "5px",
                            fontSize: ".8rem",
                          }}
                        >
                          <Link
                            href={`tel: ${employee?.contactNumber}`}
                            color="text.secondary"
                          >
                            {employee?.contactNumber}
                          </Link>
                          {employee?.alternateContactNumber && (
                            <>
                              <span>|</span>
                              <Link
                                href={`tel: ${employee?.alternateContactNumber}`}
                                color="text.secondary"
                              >
                                {employee?.alternateContactNumber}
                              </Link>
                            </>
                          )}
                        </Stack>
                        <Link
                          href={`mailto: ${employee?.email}`}
                          color="text.secondary"
                          sx={{ fontSize: ".8rem" }}
                        >
                          {employee?.email}
                        </Link>
                        <Typography
                          color="text.secondary"
                          sx={{ fontSize: ".8rem", mb: 1 }}
                        >{`${employee?.dateType
                          .split(/(?=[A-Z])/)
                          .join(" ")
                          .replace(/^\w/, (c) =>
                            c.toUpperCase()
                          )}: ${indianDateFormat(
                          employee?.joiningDate
                        )}`}</Typography>

                        {employee?.technologies?.map((tech, index) => (
                          <Chip
                            key={index}
                            size="small"
                            sx={{
                              fontWeight: "bold",
                            }}
                            label={tech}
                          />
                        ))}

                        <Stack
                          direction="row"
                          mt={1}
                          alignItems="center"
                          justifyContent="center"
                          sx={{ gridGap: "15px" }}
                        >
                          <figure>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={employee?.skype}
                            >
                              <img
                                style={{
                                  width: "24px",
                                }}
                                alt="skype"
                                src="/assets/images/skype.png"
                              />
                            </a>
                          </figure>

                          <figure>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={employee?.linkedIn}
                            >
                              <img
                                style={{
                                  width: "19px",
                                }}
                                alt="linkedin"
                                src="/assets/images/linkedin.png"
                              />
                            </a>
                          </figure>
                          {employee?.github && (
                            <figure>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={employee?.github}
                              >
                                <img
                                  style={{
                                    width: "20px",
                                  }}
                                  alt="github"
                                  src="/assets/images/github.png"
                                />
                              </a>
                            </figure>
                          )}
                          {employee?.portfolioWebsite && (
                            <figure>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={employee?.portfolioWebsite}
                              >
                                <img
                                  style={{
                                    width: "27px",
                                  }}
                                  alt="portfolio"
                                  src="/assets/images/portfolio.png"
                                />
                              </a>
                            </figure>
                          )}
                          <figure>
                            <img
                              style={{
                                width: "15px",
                              }}
                              alt="resume"
                              src="/assets/images/resume-download-icon.png"
                            />
                          </figure>
                        </Stack>
                      </CardContent>
                    </Paper>
                  </Card>
                </Grid>
              );
            } else {
              return (
                <Grid item xs={3} key={idx}>
                  <Card className="employee-block">
                    <CardHeader
                      className="Employee-card-header"
                      sx={{ paddingTop: "10px" }}
                      action={
                        <UserMoreActions
                          edit={() =>
                            employee.type === "employee"
                              ? window.open(
                                  `/employee/add/${encryption(employee.id)}`,
                                  "_blank"
                                )
                              : window.open(
                                  `/candidate/add/${encryption(employee.id)}`,
                                  "_blank"
                                )
                          }
                          view={() =>
                            employee.type === "employee"
                              ? window.open(
                                  `/employee/view/${encryption(employee.id)}`,
                                  "_blank"
                                )
                              : window.open(
                                  `/candidate/view/${encryption(employee.id)}`,
                                  "_blank"
                                )
                          }
                        />
                      }
                    />
                    <Paper

                      sx={{
                        textDecoration: "none",
                        color: "#212B36",
                      }}
                      onClick={(e) =>
                        employee.type === "employee"
                          ? window.open(
                              `/employee/view/${encryption(employee.id)}`,
                              "_blank"
                            )
                          : window.open(
                              `/candidate/view/${encryption(employee.id)}`,
                              "_blank"
                            )
                      }
                    >
                      <figure className="employee-img">
                        <Avatar
                          src={
                            employee?.employeeImage &&
                            `${BASE_URL}/${employee?.employeeImage}`
                          }
                          alt={employee.firstName}
                          sx={{
                            width: 100,
                            height: 100,
                            fontSize: "2.5rem",
                          }}
                        ></Avatar>
                      </figure>
                      <CardContent
                        sx={{
                          textAlign: "center",
                          paddingTop: "10px",
                        }}
                      >
                        <h3 className="employeeName">{`${employee.firstName} ${employee.lastName}`}</h3>
                        <Typography
                          className="designation"
                          variant="body2"
                          color="text.secondary"
                          mb={1.5}
                        >
                          {employee?.workDetails?.designation?.title}
                        </Typography>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          flexWrap="wrap"
                          sx={{
                            gridGap: "5px",
                            fontSize: ".8rem",
                          }}
                        >
                          <Link
                            href={`tel: ${employee?.contactNumber}`}
                            color="text.secondary"
                          >
                            {employee?.contactNumber}
                          </Link>
                          {employee?.alternateContactNumber && (
                            <>
                              <span>|</span>
                              <Link
                                href={`tel: ${employee?.alternateContactNumber}`}
                                color="text.secondary"
                              >
                                {employee?.alternateContactNumber}
                              </Link>
                            </>
                          )}
                        </Stack>
                        <Link
                          href={`mailto: ${employee?.email}`}
                          color="text.secondary"
                          sx={{ fontSize: ".8rem" }}
                        >
                          {employee?.email}
                        </Link>
                        <Typography
                          color="text.secondary"
                          sx={{ fontSize: ".8rem", mb: 1 }}
                        >{`${employee?.dateType
                          .split(/(?=[A-Z])/)
                          .join(" ")
                          .replace(/^\w/, (c) =>
                            c.toUpperCase()
                          )}: ${indianDateFormat(
                          employee?.joiningDate
                        )}`}</Typography>
                        {employee?.technologies?.map((tech, index) => (
                          <Chip
                            key={index}
                            size="small"
                            sx={{
                              fontWeight: "bold",
                            }}
                            label={tech}
                          />
                        ))}

                        <Stack
                          mt={1}
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          sx={{ gridGap: "15px" }}
                        >
                          <figure>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={employee?.skype}
                            >
                              <img
                                style={{
                                  width: "24px",
                                }}
                                alt="skype"
                                src="/assets/images/skype.png"
                              />
                            </a>
                          </figure>
                          <figure>
                            <a
                              rel="noreferrer"
                              target="_blank"
                              href={employee?.linkedIn}
                            >
                              <img
                                style={{
                                  width: "19px",
                                }}
                                alt="linkedin"
                                src="/assets/images/linkedin.png"
                              />
                            </a>
                          </figure>
                          {employee?.github && (
                            <figure>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={employee?.github}
                              >
                                <img
                                  style={{
                                    width: "20px",
                                  }}
                                  alt="github"
                                  src="/assets/images/github.png"
                                />
                              </a>
                            </figure>
                          )}
                          {employee?.portfolioWebsite && (
                            <figure>
                              <a
                                rel="noreferrer"
                                target="_blank"
                                href={employee?.portfolioWebsite}
                              >
                                <img
                                  style={{
                                    width: "27px",
                                  }}
                                  alt="portfolio"
                                  src="/assets/images/portfolio.png"
                                />
                              </a>
                            </figure>
                          )}
                          <figure>
                            <img
                              style={{
                                width: "15px",
                              }}
                              alt="resume"
                              src="/assets/images/resume-download-icon.png"
                            />
                          </figure>
                        </Stack>
                      </CardContent>
                    </Paper>
                  </Card>
                </Grid>
              );
            }
          })
        )}
        {/*{loading && <Loader />}
        {loading}
      </Grid> */}
    </Container>
  );
}

const componentConfig = {
	component: JoiningEmployee,
	path: "/employee/joining-employee",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
