import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { debounce } from "lodash";
import { useConfirm } from "material-ui-confirm";
import { toast } from "react-toastify";
import DashboardLayout from "../../../layouts/dashboard";
import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DesktopWindowsOutlinedIcon from "@mui/icons-material/DesktopWindowsOutlined";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import { useFormik } from "formik";
import {
  getAllGeneratedAssets,
  getGeneratedAssetsCount,
  getAssettag,
} from "../../../redux/assets/thunk";
import {
  setCurrentPage,
  setLimit,
  setStatus,
  setSortBy,
  setOrderBy,
  setFilter,
  setLocation,
  setAssetStatus,
  setTags,
} from "../../../redux/assets/assetSlice";
import invertDirection from "../../../utils/invertDirection";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";

import { ContentCopy, Summarize } from "@mui/icons-material";
import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import { decryption, encryption } from "../../../utils/encodeString";
import CustomPagination from "../../../components/Pagination";

import { migrateServerToPC } from "../../../redux/assets/thunk";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../../service/localStorage";
import { FormControl } from "@mui/material";

function checkAssetIfAvailable(employeeFirstname, employeeLastname, asset) {
  if (asset?.assetStatus?.toUpperCase() === "WORKING") {
    if (!employeeFirstname && !employeeLastname) {
      return (
        <Chip
          style={{
            borderRadius: "6px",
            backgroundColor: "rgba(84, 214, 44, 0.16)",
            color: "rgb(34, 154, 22)",
            fontWeight: 400,
          }}
          label="Available"
          size="small"
          color="success"
          variant="contained"
        ></Chip>
      );
    }
    return `${employeeFirstname} ${employeeLastname}`;
  }
  let statusName = asset?.assetStatus;
  let statusMap = statusName?.split("_");
  statusName = "";
  statusMap?.map((e) => {
    statusName =
      statusName + `${e[0]?.toUpperCase()}${e?.slice(1)?.toLowerCase()} `;
  });

  statusName = statusName.trim();
  return (
    <Chip
      style={{
        borderRadius: "6px",
        backgroundColor: "rgba(214, 44, 44, 0.16)",
        color: "rgb(154, 22, 22)",
        fontWeight: 400,
      }}
      label={statusName}
      size="small"
      color="error"
      variant="contained"
    ></Chip>
  );
}
function ViewGeneratedAssets() {
  const confirm = useConfirm();
  let { assetId } = useParams();
  const inputRef = React.useRef(null);
  const inputRef1 = React.useRef(null);
  const inputRefIP = React.useRef(null);
  assetId = decryption(assetId);
  const generatedAssetsData = useSelector(
    (state) => state.assets.generatedAssets
  );
  const generatedAssetsStatus = useSelector(
    (state) => state.assets.generatedAssetsStatus
  );
  const loading = useSelector((state) => state.assets.loading);
  const currentPage = useSelector((state) => state.assets.currentPage);
  const limit = useSelector((state) => state.assets.limit);
  const sortBy = useSelector((state) => state.assets.sortBy);
  const filter = useSelector((state) => state.assets.filter);
  const orderBy = useSelector((state) => state.assets.orderBy);
  const location = useSelector((state) => state.assets.location);
  const employeeSearch = useSelector((state) => state.assets.employeeSearch);
  const totalRecords = useSelector((state) => state.assets.totalRecords);
  const totalPages = useSelector((state) => state.assets.totalPages);
  const assetCount = useSelector((state) => state.assetCount.totalAsset);
  const freeAssetCount = useSelector((state) => state.assetCount.freeAsset);
  const usedAssetCount = useSelector((state) => state.assetCount.usedAsset);
  const workingAsset = useSelector((state) => state.assetCount.workingAsset);
  const scrapedAsset = useSelector((state) => state.assetCount.scrapedAsset);
  const inRepairAsset = useSelector((state) => state.assetCount.inRepairAsset);
  const needUpdateAsset = useSelector(
    (state) => state.assetCount.needUpdateAsset
  );
  const assetName = useSelector((state) => state.assetCount.name);
  const assetTagDropdown = useSelector((state) => state.assets.tag);
  const statusDropDown = [
    { id: 1, title: "Working" },
    { id: 2, title: "In Repair" },
    { id: 3, title: "Scrap" },
    { id: 4, title: "Need Upgrade" },
  ];
  const filterData = ["All", "Assigned", "Available"];

  const locationData = [
    { value: "All", label: "All" },
    { value: "Outofoffice", label: "Out of office" },
    { value: "Office", label: "Office" },
  ];
  const dispatch = useDispatch();

  const [searchField, setSearchField] = useState("");
  const [employeeSearchField, setEmployeeSearchField] = useState("");
  const [allfilterData, setAllFilterData] = useState(null);
  const [filterChips, setFilterChips] = useState([]);
  const [allfilterChips, setAllfilterChips] = useState({});
  const navigate = useNavigate();
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  useEffect(() => {
    if (assetId !== -1) {
      dispatch(getGeneratedAssetsCount({ assetId: assetId }));
    } else {
      navigate("/assets");
    }
  }, [assetId, dispatch, generatedAssetsData]);
  const {
    handleSubmit,
    setValues,
    values,
    handleChange,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      location: "All",
      filter: "All",
      assetTag: "",
      assetStatus: 1,
      ipAddress: "",
    },
  });
  useEffect(() => {
    if (generatedAssetsStatus === "idle") {
      dispatch(
        getAllGeneratedAssets({
          assetId,
          page: currentPage + 1,
          limit,
          search: searchField,
          employeeSearch: employeeSearchField,
          orderBy,
          sortBy,
          filter: values.filter,
          location: values.location,
          assetTag: values.assetTag,
          assetStatus: values.assetStatus,
          ipAddress: values.ipAddress,
        })
      );
    }
    return () => {
      dispatch(setStatus({ status: "idle" }));
    };
  }, [dispatch, limit, currentPage, sortBy, orderBy, filter, location, values]);

  useEffect(() => {
    if (generatedAssetsData.length === 0 && currentPage !== 0) {
      dispatch(setCurrentPage({ page: currentPage - 1 }));
    }
  }, [generatedAssetsData.length]);

  useEffect(() => {
    dispatch(getAssettag());
  }, [dispatch]);
  useEffect(() => {
    handleChipStructure({ assetStatus: values?.assetStatus || 1 });
    return () => {
      dispatch(setFilter({ filter: "All", location: "All" }));
      dispatch(setLocation({ filter: "All", location: "All" }));
      dispatch(setAssetStatus({ assetStatus: values?.assetStatus || 1 }));
      dispatch(setAssetStatus({ ipAddress: "" }));
      dispatch(setTags({ assetTag: [] }));
      dispatch(setCurrentPage({ page: 0 }));
    };
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleSearch = async (e) => {
    dispatch(
      getAllGeneratedAssets({
        assetId,
        orderBy: "ASC",
        sortBy: "id",
        search: e.target.value,
        employeeSearch: employeeSearchField,
        page: 1,
        limit,
        filter: values.filter,
        location: values.location,
        assetTag: values.assetTag,
        assetStatus: values.assetStatus,
        ipAddress: values.ipAddress,
      })
    );
    setSearchField(e.target.value);
    handleChipStructure({ searchField: e.target.value });
  };

  const handleEmployeeSearch = (e) => {
    dispatch(
      getAllGeneratedAssets({
        assetId,
        orderBy: "ASC",
        sortBy: "id",
        search: searchField,
        employeeSearch: e.target.value,
        page: 1,
        limit,
        filter: values.filter,
        location: values.location,
        assetTag: values.assetTag,
        assetStatus: values.assetStatus,
        ipAddress: values.ipAddress,
      })
    );
    setEmployeeSearchField(e.target.value);
    handleChipStructure({ employeeSearchField: e.target.value });
  };

  const debounceWithSearch = debounce(handleSearch, 500);
  const debounceWithEmployeeSearch = debounce(handleEmployeeSearch, 500);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleChipStructure = (obj) => {
    var searchParams = {};
    searchParams.searchField = searchField;
    searchParams.employeeSearchField = employeeSearchField;
    setAllfilterChips({ ...allfilterChips, ...searchParams, ...obj });
    prepareChips({ ...allfilterChips, ...searchParams, ...obj });
  };

  const prepareChips = (searchParams) => {
    let filters = { ...searchParams };
    let searchFilter = {};
    let tempChips = [];
    filters.filter = filters.filter === "All" ? "" : filters.filter;
    filters.location = filters.location === "All" ? "" : filters.location;

    for (const key in filters) {
      if (filters[key] && key === "searchField") {
        filters[key] = filters[key];
        searchFilter.search = filters[key];
      }
      if (filters[key] && key === "employeeSearchField") {
        searchFilter.employeeSearch = filters[key];
        filters[key] = `Employee Name : ${filters[key]}`;
      }
      if (filters[key] && key === "filter") {
        searchFilter.filter = filters[key];
        filters[key] = `Availability : ${filters[key]}`;
      }
      if (filters[key] && key === "location") {
        const locationValue = locationData.find(
          (i) => i.label === filters[key]
        );
        searchFilter.location = locationValue.value;
        filters[key] = `Location : ${filters[key]}`;
      }

      if (filters[key] && key === "assetStatus") {
        const AssetValue = statusDropDown.find((i) => i.id === filters[key]);
        searchFilter.assetStatus = filters[key];
        filters[key] = `Asset Status : ${AssetValue.title}`;
      }

      if (filters[key] && key === "ipAddress") {
        // searchFilter.ipAddress = filters[key];
        filters[key] = `IP Address : ${filters[key]}`;
      }

      if (filters[key] && key === "assetTag" && filters[key].length > 0) {
        let tagString = "";
        let count = 0;
        filters[key].map((f) => {
          assetTagDropdown.map((d) => {
            if (f == d.id) {
              count++;
              if (count > 1) {
                tagString = tagString + `| ${d.name}`;
              } else {
                tagString = tagString + ` ${d.name}`;
              }
            }
          });
        });

        searchFilter.assetTag = filters[key];
        filters[key] = `Asset Tags : ${tagString}`;
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

    let newFilterChips = tempChips.filter((e) => e.filter.length > 0);

    setFilterChips(newFilterChips);
    setAllFilterData(searchFilter);
  };
  const onDeleteChip = (value, length) => {
    let { type } = value;

    if (type === "searchField") {
      inputRef.current.value = "";
      setSearchField("");
      delete allfilterData["search"];
    } else if (type === "employeeSearchField") {
      inputRef1.current.value = "";
      setEmployeeSearchField("");
      delete allfilterData["employeeSearch"];
    } else if (type === "assetStatus") {
      setFieldValue(type, "");
      delete allfilterData[type];
    } else if (type === "assetTag") {
      setFieldValue(type, "");
      delete allfilterData[type];
    } else if (type === "ipAddress") {
      inputRefIP.current.value = "";
      delete allfilterData[type];
    } else {
      setFieldValue(type, "All");
      delete allfilterData[type];
    }

    delete allfilterData[value.type];
    delete allfilterChips[value.type];
    setAllfilterChips({ ...allfilterChips });
    setAllFilterData({ ...allfilterData });
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    setFilterChips([...updateChips]);
    // dispatch(setCurrentPage({page : 0}))
    setAllFilterData(allfilterData);
    dispatch(
      getAllGeneratedAssets({
        assetId,
        orderBy: "ASC",
        sortBy: "id",
        page: 1,
        limit,
        ...allfilterData,
      })
    );
  };
  const handleFilterChange = (e, newValue) => {
    setFieldValue("filter", newValue);
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setFilter({ filter: newValue }));
    handleChipStructure({ filter: newValue });
  };

  const handleLocationChange = (e, newValue) => {
    setFieldValue("location", newValue?.value);
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLocation({ location: newValue?.value }));
    handleChipStructure({ location: newValue?.label });
  };

  const handleIPChange = (e) => {
    setFieldValue("ipAddress", inputRefIP.current.value);
    dispatch(setCurrentPage({ page: 0 }));
    // dispatch(setLocation({ location: newValue?.value }));
    handleChipStructure({ ipAddress: inputRefIP.current.value });
  };

  const handleAssetStatus = async (newValue) => {
    setFieldValue(`assetStatus`, newValue?.id || "");
    if (newValue?.id !== 1) {
      setFieldValue(`filter`, "All");
    }
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setAssetStatus({ assetStatus: newValue?.id }));
    dispatch(
      getAllGeneratedAssets({
        assetId,
        orderBy: "ASC",
        sortBy: "id",
        search: searchField,
        employeeSearch: employeeSearchField,
        page: 1,
        limit,
        filter: newValue?.id !== 1 ? "All" : values.filter,
        location: values.location,
        assetTag: values.assetTag,
        assetStatus: newValue?.id,
        ipAddress: values?.ipAddress,
      })
    );
    console.log("newValue?.id", newValue?.id);
    handleChipStructure({
      assetStatus: newValue?.id,
      filter: newValue?.id !== 1 ? "All" : values.filter,
    });
  };

  const handlechangeTags = (newValue) => {
    let tempArray = newValue.map((x) => x.id);
    setFieldValue(`assetTag`, tempArray || "");
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setTags({ assetTag: tempArray }));
    dispatch(
      getAllGeneratedAssets({
        assetId,
        orderBy: "ASC",
        sortBy: "id",
        search: searchField,
        employeeSearch: employeeSearchField,
        page: 1,
        limit,
        filter: values.filter,
        location: values.location,
        assetTag: tempArray,
        assetStatus: values?.assetStatus,
        ipAddress: values?.ipAddress,
      })
    );
    handleChipStructure({ assetTag: tempArray });
  };
  const serverToPc = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to Migrate to PC?`,
      });
      await dispatch(migrateServerToPC(id));
      dispatch(
        getAllGeneratedAssets({
          assetId,
          orderBy: "ASC",
          sortBy: "id",
          page: 1,
          limit,
          ...allfilterData,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Container
      className="detailAsset_main_container"
      style={{ maxWidth: "100%", paddingLeft: "0", paddingRight: "0" }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <div className="title-button-wrapper">
          <Stack>
            <Typography variant="h4" gutterBottom>
              Asset ({assetName}) ({" "}
              {assetCount == 0
                ? `Total Records: ${assetCount}`
                : `Total Records: ${assetCount}, Available: ${freeAssetCount}, Assigned: ${usedAssetCount}`}
              )
              {/* <Typography variant="body2">
              {assetCount == 0
                ? `Total Records: ${assetCount}`
                : `Total Records: ${assetCount}, Available: ${freeAssetCount}, Assigned: ${usedAssetCount}`}
            </Typography> */}
            </Typography>
            {assetCount != 0 && (
              <p style={{ marginTop: "2px" }}>
                {`Working: ${
                  workingAsset || 0
                }  |  Scrap: ${scrapedAsset}  |  In Repair: ${inRepairAsset}  |  Need Update: ${needUpdateAsset}`}
              </p>
            )}
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {getAddVisible("assets") && (
              <Button
                className="header-add-button"
                variant="contained"
                component={RouterLink}
                to={`/assets/${encryption(assetId)}/add`}
                // startIcon={<AddIcon />}
                sx={{ marginRight: "15px" }}
              >
                <img src="/assets/images/add-circle.svg" />
                Add Asset
              </Button>
            )}
            <Button
              className="back-button"
              variant="contained"
              component={RouterLink}
              to={`/assets`}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Stack>
        </div>
      </Stack>

      <Card>
        <Grid
          className="Asset_main_grid gray-box Employee_Detail_Content"
          spacing={2}
          container
          gutterBottom={0}
        >
          <Grid item xs={6}>
            <TextField
              size="small"
              name="search"
              inputProps={{ maxLength: 64 }}
              onChange={debounceWithSearch}
              fullWidth
              label="Search by Name"
              inputRef={inputRef}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              name="employeeSearch"
              inputProps={{ maxLength: 64 }}
              onChange={debounceWithEmployeeSearch}
              fullWidth
              label="Search by Employee Name"
              inputRef={inputRef1}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              name="filter"
              onChange={handleFilterChange}
              value={values.filter}
              options={filterData || []}
              renderInput={(params) => (
                <TextField {...params} label="Filter by Availability" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              name="location"
              onChange={handleLocationChange}
              options={locationData || []}
              getOptionLabel={(option) => option.label || ""}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Filter by Location"
                  autoComplete="off"
                />
              )}
              value={
                locationData.find((i) => i.value === values.location) || ""
              }
            />
            {/* 	<TextField
							select
							size="small"
							name="search"
							fullWidth
							label="Filter by Location"
							value={location}
							onChange={(e) => {
								dispatch(setCurrentPage({ page: 0 }));
								dispatch(setLocation({ location: e.target.value }));
							}}
						>
							<MenuItem value="all">All</MenuItem>
							<MenuItem value="outofoffice">outofoffice</MenuItem>
							<MenuItem value="office">office</MenuItem>
						</TextField> */}
          </Grid>

          <Grid item xs={3}>
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Asset Status"
              size="small"
              name="assetStatus"
              onChange={(event, newValue) => {
                handleAssetStatus(newValue);
              }}
              options={statusDropDown || []}
              getOptionLabel={(option) => option.title || ""}
              value={
                statusDropDown.find((as) => as.id == values.assetStatus) || ""
              }
              renderInput={(params) => (
                <TextField {...params} label="Filter by AssetStatus" />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
              <Autocomplete
                multiple
                size="small"
                fullWidth
                name="assetTag"
                defaultValue={[]}
                options={assetTagDropdown || []}
                key={assetTagDropdown || []}
                getOptionLabel={(option) => option.name || ""}
                {...getFieldProps(`assetTag`)}
                onChange={(event, newValue) => {
                  handlechangeTags(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Filter by Tags" />
                )}
                value={
                  assetTagDropdown?.filter((x) =>
                    values?.assetTag?.includes(x.id)
                  ) || ""
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              name="ipAddress"
              inputProps={{ maxLength: 64 }}
              onChange={(e) => handleIPChange()}
              fullWidth
              label="Filter by IP Address"
              inputRef={inputRefIP}
            />
          </Grid>
        </Grid>
      </Card>
      <Stack direction="row" alignItems="start">
        {Array.isArray(filterChips) &&
          filterChips?.map((chips, idx) => {
            return (
              <Chip
                label={chips.filter}
                color="primary"
                size="small"
                key={chips.filter}
                style={{ margin: "10px 5px 10px 0" }}
                variant="filled"
                onDelete={() => onDeleteChip(chips, filterChips.length)}
              />
            );
          })}
      </Stack>
      {filterChips.length > 0 ? (
        <Typography className="record-found-text" variant="body2" mb={1}>
          {totalRecords} records found
        </Typography>
      ) : (
        ""
      )}
      {loading ? (
        <Loader />
      ) : (
        <>
          <Card className="assets-detail-subcard" style={{ marginTop: "15px" }}>
            <Scrollbar>
              <TableContainer component={Paper} sx={{ minWidth: 800 }}>
                <Table className="grid-table" aria-label="simple table">
                  <TableHead textAlign="center">
                    <TableRow>
                      <TableCell>
                        {/* <TableSortLabel
												active={sortBy === "name"}
												direction={sortBy === "name" ? orderBy : "asc"}
												onClick={() => handleSorting("name")}
											> */}
                        <TableSortLabel hideSortIcon="true">
                          Asset Id
                        </TableSortLabel>

                        {/* 	</TableSortLabel> */}
                      </TableCell>
                      <TableCell>
                        <TableSortLabel
                          active={sortBy === "name"}
                          direction={sortBy === "name" ? orderBy : "asc"}
                          onClick={() => handleSorting("name")}
                        >
                          Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel hideSortIcon="true">
                          Location
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" className="action-field">
                        <TableSortLabel hideSortIcon="true">
                          Employee
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell align="left" className="action-field">
											IP
										</TableCell> */}
                      <TableCell align="left" className="action-field">
                        <TableSortLabel hideSortIcon="true">
                          Specifications
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left" className="action-field">
                        {/* <TableSortLabel
                          active={sortBy === "processorScore"}
                          direction={sortBy === "processorScore" ? orderBy : "asc"}
                          onClick={() => handleSorting("processorScore")}
                        > */}
                        Processor Score
                        {/* </TableSortLabel> */}
                      </TableCell>
                      <TableCell
                        style={{ textAlign: "center !important" }}
                        className="action-field"
                      >
                        <TableSortLabel hideSortIcon="true">
                          Actions
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {generatedAssetsData.length > 0 ? (
                      generatedAssetsData.map((genAsst) => {
                        return (
                          <TableRow key={genAsst.id}>
                            <TableCell>{genAsst.assetUniqueId}</TableCell>
                            <TableCell>{genAsst.name}</TableCell>
                            <TableCell>
                              <Chip
                                size="small"
                                label={genAsst.location}
                              ></Chip>
                            </TableCell>
                            <TableCell>
                              {checkAssetIfAvailable(
                                genAsst.assetEmployee?.employee?.firstName,
                                genAsst.assetEmployee?.employee?.lastName,
                                genAsst
                              )}
                            </TableCell>
                            {/* <TableCell>
														{genAsst?.assetEmployee?.ipAddress}
													</TableCell> */}
                            <TableCell>
                              {genAsst?.generatedAssetItems}
                            </TableCell>
                            <TableCell>
                              {genAsst?.processorScore
                                ? genAsst?.processorScore
                                : "-"}
                            </TableCell>
                            <TableCell
                              className="td-action-button"
                              align="left"
                            >
                              <Stack className="action-button" direction="row">
                                {getEditVisible("assets") && (
                                  <IconButton
                                    component={RouterLink}
                                    to={`/assets/${encryption(
                                      assetId
                                    )}/add/${encryption(genAsst.id)}`}
                                    title="Edit Asset"
                                  >
                                    <img
                                      src="/assets/images/edit.svg"
                                      title="Edit"
                                    />
                                    {/* <EditIcon color="primary" /> */}
                                  </IconButton>
                                )}
                                {/* <IconButton>
														<DeleteIcon color="error" />
													</IconButton> */}
                                <IconButton
                                  component={RouterLink}
                                  to={`/assets/${encryption(
                                    assetId
                                  )}/view/${encryption(genAsst.id)}`}
                                  title="View Asset"
                                >
                                  <img
                                    src="/assets/images/vieweye-icon.svg"
                                    title="View"
                                  />
                                  {/* <VisibilityIcon /> */}
                                </IconButton>
                                {getAddVisible("assets") && (
                                  <IconButton
                                    component={RouterLink}
                                    to={`/assets/${encryption(
                                      assetId
                                    )}/add/${encryption(
                                      genAsst.id
                                    )}?clone=${true}`}
                                    title="Clone Asset"
                                  >
                                    <ContentCopy />
                                  </IconButton>
                                )}
                                {assetName == "Server" ? (
                                  // && !(genAsst?.assetEmployee?.employee?.firstName) ?
                                  <IconButton
                                    onClick={() => serverToPc(genAsst?.id)}
                                    title="Migrate to PC"
                                  >
                                    <DesktopWindowsOutlinedIcon />
                                  </IconButton>
                                ) : (
                                  ""
                                )}

                                {/* {getAddVisible("assets") && ( */}
                                {/* <IconButton
																	component={RouterLink}
																	title="Assign Location"
																	to={`/assets/${encryption(
																		assetId,
																	)}/locate/${encryption(
																		genAsst.id,
																	)}`}
																>
																	<AddLocationAltOutlinedIcon />
																</IconButton> */}
                                {/* )} */}

                                {/* <IconButton
																	component={RouterLink}
																	to={`/asset/${encryption(
																		assetId,
																	)}/log/${encryption(genAsst.id)}`}
																>
																	<Summarize />
																</IconButton> */}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <TableRow>
                        <TableCell
                          className="No-Record-text"
                          align="center"
                          colSpan={5}
                        >
                          No Record(s) Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            <CustomPagination
              rowsPerPageOptions={[10, 20, 40]}
              totalCount={totalRecords}
              totalPage={totalPages}
              limit={limit}
              page={currentPage}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </Card>
        </>
      )}
    </Container>
  );
}

const componentConfig = {
  component: ViewGeneratedAssets,
  path: "/assets/:assetId/view",
  public: false,
  layout: DashboardLayout,
  group: "asset",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
