import React, { useEffect } from "react";
import { Grid, Typography, Link, Button, Box } from "@mui/material";
import FolderCard from "./FolderCard";
import AddIcon from "@mui/icons-material/Add";
import AddFolder from "./AddFolder";
import { useDispatch, useSelector } from "react-redux";
import {
  createFolder,
  getFolders,
} from "../../../redux/notebook/notebookThunk";
import Loader from "../../../components/Loader";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import { getAddVisible, getViewVisible } from "../../../utils/userPermission";
import { useNavigate } from "react-router-dom";
import { encryption } from "../../../utils/encodeString";
import localStorage from "../../../service/localStorage";
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';

const FolderList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, loading, error } = useSelector((state) => state.notebook);
  const [addFolder, setAddFolder] = React.useState(false);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      localStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  const handleAddFolder = () => {
    setAddFolder(true);
  };
  const createNewFolder = (folderName) => {
    const body = {
      folderName: folderName,
    };
    try {
      dispatch(createFolder(body)).then(() => {
        getFoldersData();
      });
    } catch (error) {
      console.log("%c  error:", "color: #0e93e0;background: #aaefe5;", error);
    }
  };
  const getFoldersData = () => {
    try {
      dispatch(getFolders()).unwrap();
    } catch (error) {
      console.log("%c  error:", "color: #0e93e0;background: #aaefe5;", error);
    }
  };
  useEffect(() => {
    getFoldersData();
  }, []);

  const handleLinks = () =>{
    navigate('/notebook/links')
  }

  return (
    <div>
      <Grid className="folder-grid" container justifyContent="space-between" alignItems="center">
        <Typography variant="h5">Folders</Typography>
        <Box gap={1} display="flex">
        {getViewVisible('link') && (
        <Button
            variant="contained"
            startIcon={<LinkOutlinedIcon />}
            className="filter-icon-part"
            onClick={handleLinks}
          >
            Links
          </Button>)}
        {getAddVisible('folder') && (
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className="filter-icon-part"
            onClick={handleAddFolder}
          >
            Add Folder
          </Button>
        )}
        </Box>
      </Grid>
      <Grid className="mainfolder-container" container spacing={1} margin={2}>
        {addFolder && (
          <Grid className="folder-detail" item xs={2}>
            <AddFolder
              setAddFolder={setAddFolder}
              createNewFolder={createNewFolder}
            />
          </Grid>
        )}
        {loading ? (
          <Loader />
        ) : data && data?.length > 0 && Array.isArray(data) ? (
          data?.map((folder, index) => (
            <Grid className="folder-detail" item key={index} xs={2}>
              <FolderCard folder={folder} getFoldersData={getFoldersData} />
            </Grid>
          ))
        ) : (
          !addFolder && <Typography variant="h6" style={{ width: "100%", display: "inline-block", textAlign: "center" }}>No Folder Found</Typography>
        )}
      </Grid>
    </div>
  );
};

export default FolderList;
