import { Button, Card, Container, Stack, Typography } from "@mui/material";
import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboard";
import { decryption } from "../../../utils/encodeString";
import {
  getChangeRequestById,
  updateRequestStatus,
} from "../../../redux/changeRequest/changeRequestThunk";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useEffect } from "react";
import { BASE_URL } from "../../../constants";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { useFormik } from "formik";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import LoadingButton from "@mui/lab/LoadingButton";
import "../EmployeeComponents/View-employee.css";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { encryption } from "../../../utils/encodeString";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: 2,
  p: 4,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ViewRequest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  id = decryption(id);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [status, setStatus] = useState();
  const [noteError, setNoteError] = useState({ flag: false, title: "" });
  const [expanded, setExpanded] = React.useState();
  const AuthName = useSelector((state) => state.auth.fullName);

  const formatedString = (str) => {
    let newstr = str
      .replace(/(_|-)/g, " ")
      .trim()
      .replace(/\w\S*/g, function (str) {
        return str.charAt(0).toUpperCase() + str.substr(1);
      })
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");

    return newstr;
  };

  useEffect(() => {
    if (id === -1) {
      navigate("/changeRequest");
    }
  }, [id, dispatch]);

  const { values, setFieldValue } = useFormik({
    initialValues: {
      note: "",
      username: "",
    },
  });
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const [changeRequestData, setChangeRequestData] = useState(null);
  const [count, setCount] = useState(0);

  const changeStatus = (status) => {
    setStatus(status);
    handleOpen();
  };
  const handleCancel = () => {
    handleClose();
    setFieldValue("note", "");
    setNoteError({ flag: false, title: "" });
  };
  const handleStatusChange = (noteValue) => {
    if (noteValue == "") {
      setNoteError({ flag: true, title: "Please enter notes" });
    }
    if (noteValue != "") {
      setNoteError({ flag: false, title: "" });
    }
    if (noteValue != "") {
      dispatch(
        updateRequestStatus({
          id: id,
          request_status: status,
          note: noteValue,
          username: AuthName,
        })
      )
        .unwrap()
        .then((res) => {
          setCount(count + 1);
        })
        .catch((err) => {
          navigate("/changeRequest");
        });
      setFieldValue("note", "");
      handleClose();
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getChangeRequestById(id))
      .unwrap()
      .then((res) => {
        setChangeRequestData(res.data);
      })
      .catch((err) => {
        navigate("/changeRequest");
      });
  }, [dispatch, id, navigate, count]);

  return (
    <Container className="View-Request-container" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
      <div className="title-button-wrapper View-Request-title">
        <Typography variant="h4" gutterBottom>
          View Request
        </Typography>
        
        {/* <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: "20%" }}
        ></Stack> */}

        <Stack
          direction="row"
          spacing={2}
          justifyContent="end"
        >
          <Button
          className="back-button"
            // component={Link}
            onClick={() => navigate(-1)}
            // to="/changeRequest"
            variant="contained"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          {changeRequestData?.requestStatus == "PENDING" && (
            <>
              <Button
                variant="contained"
                onClick={() => changeStatus("APPROVED")}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                onClick={() => changeStatus("REJECTED")}
              >
                Reject
              </Button>
            </>
          )}
        </Stack>
        </div>
      </Stack>

      <div className="view_employee View-Request-main-content" >
        <Card className="View-Request-card-wrapper gray-box Employee_Detail_Content">
          <div className="address-blk">
            <div className="address-sub-section">
              {changeRequestData !== null
                ? "requestBy" in changeRequestData &&
                  changeRequestData?.requestBy && (
                    <div
                      className="address-div"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <span>Employee Name</span>

                      <p>
                        {" "}
                        {changeRequestData?.requestBy}
                        <IconButton
                          component={Link}
                          target="_blank"
                          to={`/employee/view/${encryption(
                            changeRequestData?.employeeId
                          )}`}
                        >
                          <OpenInNewIcon />
                        </IconButton>
                      </p>
                    </div>
                  )
                : ""}
              {changeRequestData !== null
                ? "requestModule" in changeRequestData &&
                  changeRequestData?.requestModule && (
                    <div className="address-div">
                      <span>Module Name</span>
                      <p>
                        {changeRequestData?.requestModule
                          ? changeRequestData?.requestModule
                              .charAt(0)
                              .toUpperCase() +
                            changeRequestData?.requestModule.slice(1)
                          : ""}
                      </p>
                    </div>
                  )
                : ""}
              {changeRequestData !== null
                ? "requestDate" in changeRequestData &&
                  changeRequestData?.requestDate && (
                    <div className="address-div">
                      <span>Requested Date</span>
                      <p>
                        {changeRequestData?.requestDate
                          ? moment(changeRequestData?.requestDate).format(
                              "DD/MM/yyyy"
                            )
                          : ""}
                      </p>
                    </div>
                  )
                : ""}
            </div>
            {changeRequestData !== null ? (
              "statusupdatedDate" in changeRequestData ||
              "statusUpdatedBy" in changeRequestData ||
              "requestStatus" in changeRequestData ? (
                <div className="address-sub-section">
                  {"requestStatus" in changeRequestData &&
                  changeRequestData?.requestStatus ? (
                    <div className="address-div">
                      <span>Status</span>
                      {changeRequestData?.requestStatus === "APPROVED" && (
                        <p style={{ color: "green" }}>
                          {" "}
                          {changeRequestData?.requestStatus}{" "}
                        </p>
                      )}
                      {changeRequestData?.requestStatus === "REJECTED" && (
                        <p style={{ color: "red" }}>
                          {" "}
                          {changeRequestData?.requestStatus}{" "}
                        </p>
                      )}
                      {changeRequestData?.requestStatus === "PENDING" && (
                        <p style={{ color: "blue" }}>
                          {" "}
                          {changeRequestData?.requestStatus}{" "}
                        </p>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                  {"statusupdatedDate" in changeRequestData &&
                  changeRequestData?.statusupdatedDate ? (
                    <div className="address-div">
                      <span> Updated Date</span>
                      <p>
                        {moment(changeRequestData?.statusupdatedDate).format(
                          "DD/MM/yyyy"
                        )}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}

                  {"statusUpdatedBy" in changeRequestData &&
                  changeRequestData?.statusUpdatedBy ? (
                    <div className="address-div">
                      <span>Updated By</span>
                      <p>{changeRequestData?.statusUpdatedBy}</p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )
            ) : (
              ""
            )}
          </div>

          {changeRequestData !== null &&
          "note" in changeRequestData &&
          changeRequestData?.note ? (
            <div className="address-blk" style={{ width: "100%" }}>
              <div className="address-sub-section" style={{ padding: "0px" }}>
                <div className="address-div">
                  <span> Notes</span>
                  <p>{changeRequestData?.note}</p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </Card>
      </div>

      <TableContainer className="View-Request-TableContainer" component={Paper}>
        <Table className="grid-table" sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell align="center">Requested Details</TableCell>
              <TableCell align="center">Existing Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {changeRequestData ? (
              changeRequestData?.requestModule == "address" ||
              changeRequestData?.requestModule == "personal" ? (
                Object.keys(changeRequestData?.lastRequestedData).map((k) => (
                  <TableRow
                    key={k}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="td" scope="row">
                      {`${formatedString(k)}`}
                    </TableCell>
                    {k == "employeeImage" ? (
                      <>
                        <TableCell align="right">
                          <img
                            width="20%"
                            src={`${BASE_URL}/${changeRequestData?.lastRequestedData[k]}`}
                          ></img>
                          {/* {changeRequestData?.lastRequestedData[k] ?? "-"} */}
                        </TableCell>
                        {changeRequestData?.originalData[k] != null ? (
                          <TableCell align="right">
                            <img
                              width="20%"
                              src={`${BASE_URL}/${changeRequestData?.originalData[k]}`}
                            ></img>
                            {/* {changeRequestData?.originalData[k] ?? "-"} */}
                          </TableCell>
                        ) : (
                          <TableCell align="right">-</TableCell>
                        )}
                      </>
                    ) : formatedString(k).includes("Proof") ? (
                      <>
                        <TableCell align="right">
                          {/* {console.log("CRD_03", `${BASE_URL}/${changeRequestData?.lastRequestedData[k]}`)} */}
                          {changeRequestData?.lastRequestedData[k] ? (
                            <span>
                              <a
                                component={Link}
                                style={{ color: "#637381" }}
                                target="_blank"
                                href={`${BASE_URL}/${changeRequestData?.lastRequestedData[k]}`}
                              >
                                <img src="/assets/images/vieweye-icon.svg" title="View" />
                                {/* <VisibilityIcon /> */}
                              </a>
                            </span>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {/* {console.log("COD_03", `${BASE_URL}/${changeRequestData?.originalData[k]}`)} */}
                          {changeRequestData?.originalData[k] ? (
                            <span>
                              <a
                                component={Link}
                                style={{ color: "#637381" }}
                                target="_blank"
                                href={`${BASE_URL}/${changeRequestData?.originalData[k]}`}
                              >
                                <img src="/assets/images/vieweye-icon.svg" title="View" />
                                {/* <VisibilityIcon /> */}
                              </a>
                            </span>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                      </>
                    ) : (
                      <>
                        <TableCell align="right">
                          {changeRequestData?.lastRequestedData[k] ?? "-"}
                        </TableCell>
                        <TableCell align="right">
                          {changeRequestData?.originalData[k] ?? "-"}
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                ))
              ) : changeRequestData.requestModule == "education" ? (
                Object.keys(changeRequestData?.lastRequestedData).map((k) => (
                  <TableRow
                    key={k}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {`${formatedString(k)}`}
                    </TableCell>
                    <TableCell align="right">
                      {changeRequestData?.lastRequestedData[k]
                        ? changeRequestData?.lastRequestedData[k]?.map(
                            (element) =>
                            
                              k == "additionalCourse" ? (
                                <Accordion
                                  defaultExpanded={true}
                                  // expanded={expanded === element.courseName} onChange={handleChange(element.courseName)}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                  >
                                    <Typography>
                                      {element?.courseName}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <table>
                                        <tr>
                                          <td>Course Name:-</td>
                                          <td>{element?.courseName}</td>
                                        </tr>
                                        <tr>
                                          <td>Institute Name:-</td>
                                          <td>{element?.instituteName}</td>
                                        </tr>
                                        <tr>
                                          <td>Certificate Url:-</td>
                                          <td>
                                            <a href={element?.certificateUrl}>
                                              {element?.certificateUrl}
                                            </a>
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Technologies:-</td>
                                          <td>
                                            {element?.technologies
                                              ?.map((s) => s?.technology?.title)
                                              .join(",")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td>Certificate File:-</td>
                                          <td>{element && Array.isArray(element) &&
                                            <IconButton
                                              style={{ padding: "5px" }}
                                              onClick={() => {
                                                window.open(
                                                  `${element?.certificateFile[0]?.webViewLink}`,
                                                  "_blank"
                                                );
                                              }}
                                            >
                                              {element && element?.certificateFile[0]
                                                ?.webViewLink ? (
                                                <VisibilityIcon />
                                              ) : (
                                                ""
                                              )}
                                            </IconButton>}
                                          </td>
                                        </tr>
                                      </table>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              ) : (
                                <Accordion
                                  defaultExpanded={true}
                                  // expanded={expanded === element.passingYear} onChange={handleChange(element.passingYear)}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                  >
                                    <Typography>
                                      {element?.passingYear}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <table>
                                        <tr>
                                          <td>Institute Name:-</td>
                                          <td>{element?.instituteName}</td>
                                        </tr>
                                        <tr>
                                          <td>Passing Year:-</td>
                                          <td>{element?.passingYear}</td>
                                        </tr>
                                        <tr>
                                          <td>Percentage:-</td>
                                          <td>{element?.percentage}</td>
                                        </tr>
                                      </table>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              )
                          )
                        : "-"}
                    </TableCell>
                    <TableCell align="right">
                      {changeRequestData?.originalData[k]
                        ? changeRequestData?.originalData[k].map((element) =>
                            // <p>{element.courseName}</p>
                            k == "additionalCourse" ? (
                              <Accordion
                                defaultExpanded={true}
                                // expanded={expanded === element.courseName} onChange={handleChange(element.courseName)}
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                >
                                  <Typography>{element?.courseName}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <table>
                                      <tr>
                                        <td>Course Name:-</td>
                                        <td>{element?.courseName}</td>
                                      </tr>
                                      <tr>
                                        <td>Institute Name:-</td>
                                        <td>{element?.instituteName}</td>
                                      </tr>
                                      <tr>
                                        <td>Certificate Url:-</td>
                                        <td>
                                          <a href={element?.certificateUrl}>
                                            {element?.certificateUrl}
                                          </a>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Technologies:-</td>
                                        <td>
                                          {element?.technologies
                                            ?.map((s) => s?.technology?.title)
                                            .join(",")}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td>Certificate File:-</td>
                                        <td>
                                          {element && Array.isArray(element) &&
                                          <IconButton
                                            style={{ padding: "5px" }}
                                            onClick={() => {
                                              window.open(
                                                `${element?.certificateFile[0]?.webViewLink}`,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            {element?.certificateFile[0]
                                              ?.webViewLink ? (
                                              <VisibilityIcon />
                                            ) : (
                                              ""
                                            )}
                                          </IconButton> }
                                        </td>
                                      </tr>
                                    </table>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            ) : (
                              <Accordion
                                defaultExpanded={true}
                                // expanded={expanded === element.passingYear} onChange={handleChange(element.passingYear)}
                              >
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                >
                                  <Typography>{element?.passingYear}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Typography>
                                    <table>
                                      <tr>
                                        <td>Institute Name:-</td>
                                        <td>{element?.instituteName}</td>
                                      </tr>
                                      <tr>
                                        <td>Passing Year:-</td>
                                        <td>{element?.passingYear}</td>
                                      </tr>
                                      <tr>
                                        <td>Percentage:-</td>
                                        <td>{element?.percentage}</td>
                                      </tr>
                                    </table>
                                  </Typography>
                                </AccordionDetails>
                              </Accordion>
                            )
                          )
                        : "-"}
                    </TableCell>
                  </TableRow>
                ))
              ) : changeRequestData?.requestModule == "contact" ? (
                Object.keys(changeRequestData?.lastRequestedData).map((k) => (
                  <TableRow
                    key={k}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {`${formatedString(k)}`}
                    </TableCell>
                    <TableCell align="right">
                      {
                        // changeRequestData?.originalData[k] ? changeRequestData?.originalData[k].map((element) => (
                        k == "contact" ? (
                          <Accordion
                            defaultExpanded={true}
                            // expanded={expanded === element.courseName} onChange={handleChange(element.courseName)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{formatedString(k)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <table>
                                  <tr>
                                    <td>Relation With Employee:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.relationWithEmployee
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Contact Number:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.contactNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Personal Email:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.personalEmail
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Company Email:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.companyEmail
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Skype:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.skype
                                      }
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Alternate Contact Number:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.alternateContactNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Emergency Contact Name:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.emergencyContactName
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Emergency Contact Number:-</td>
                                    <td>
                                      {
                                        changeRequestData?.lastRequestedData[k]
                                          ?.emergencyContactNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>LinkedIn:-</td>
                                    <td>
                                      <a
                                        href={
                                          changeRequestData?.lastRequestedData[
                                            k
                                          ]?.linkedIn
                                        }
                                      >
                                        {
                                          changeRequestData?.lastRequestedData[
                                            k
                                          ]?.linkedIn
                                        }
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ) : k == "emails" ? (
                          <Accordion
                            defaultExpanded={true}
                            // expanded={expanded === element.courseName} onChange={handleChange(element.courseName)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{formatedString(k)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <table>
                                  {changeRequestData?.lastRequestedData[k]?.map(
                                    (element) => (
                                      <tr>
                                        <td>{element?.email}</td>
                                      </tr>
                                    )
                                  )}
                                </table>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          "-"
                        )
                        // )):'-'
                      }
                    </TableCell>
                    <TableCell align="right">
                      {
                        // changeRequestData?.originalData[k] ? changeRequestData?.originalData[k].map((element) => (
                        k == "contact" ? (
                          <Accordion
                            defaultExpanded={true}
                            // expanded={expanded === element.courseName} onChange={handleChange(element.courseName)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{formatedString(k)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <table>
                                  <tr>
                                    <td>Relation With Employee:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.relationWithEmployee
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Contact Number:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.contactNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Personal Email:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.personalEmail
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Company Email:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.companyEmail
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Skype:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.skype
                                      }
                                    </td>
                                  </tr>

                                  <tr>
                                    <td>Alternate Contact Number:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.alternateContactNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Emergency Contact Name:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.emergencyContactName
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Emergency Contact Number:-</td>
                                    <td>
                                      {
                                        changeRequestData?.originalData[k]
                                          ?.emergencyContactNumber
                                      }
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>LinkedIn:-</td>
                                    <td>
                                      <a
                                        href={
                                          changeRequestData?.originalData[k]
                                            ?.linkedIn
                                        }
                                      >
                                        {
                                          changeRequestData?.originalData[k]
                                            ?.linkedIn
                                        }
                                      </a>
                                    </td>
                                  </tr>
                                </table>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ) : k == "emails" ? (
                          <Accordion
                            defaultExpanded={true}
                            // expanded={expanded === element.courseName} onChange={handleChange(element.courseName)}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                            >
                              <Typography>{formatedString(k)}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                <table>
                                  {changeRequestData?.originalData[k]?.map(
                                    (element) => (
                                      <tr>
                                        <td>{element.email}</td>
                                      </tr>
                                    )
                                  )}
                                </table>
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ) : (
                          "-"
                        )
                        // )):'-'
                      }
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                "-"
              )
            ) : (
              <TableRow>
                <TableCell className="No-Record-text" colSpan={12} align="left">
                  No Record(s) Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style}>
          <TextField
            fullWidth
            multiline
            label="Note"
            aria-label="Note..."
            minRows={3}
            // placeholder="Minimum 3 rows"
            /* className="custom-textarea" */
            // {...getFieldProps("note")}
            inputProps={{
              inputComponent: TextareaAutosize,
              maxLength: 512,
              style: {
                resize: "auto",
              },
            }}
            onChange={(e) => {
              setFieldValue("note", e.target.value || "");
              if (e.target.value) {
                setNoteError({ flag: false, title: "" });
              }
              if (e.target.value == "") {
                setNoteError({ flag: true, title: "Please enter notes" });
              }
            }}
            helperText={noteError?.title && noteError?.title}
            error={Boolean(noteError?.flag && noteError?.flag)}
          />
          <Stack direction="row" alignItems="right" justifyContent="flex-end">
            <Button
              variant="contained"
              // type="submit"
              onClick={handleCancel}
              sx={{ marginRight: "15px" }}
            >
              Cancel
            </Button>
            <LoadingButton
              // component={RouterLink}
              onClick={() => handleStatusChange(values.note)}
              variant="contained"
            >
              {status == "APPROVED" ? "Approve" : "Reject"}
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    </Container>
  );
};
const componentConfig = {
  component: ViewRequest,
  path: "/changeRequest/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
