import * as React from "react";
import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Select from "@mui/material/Select";
import Autocomplete from "@mui/material/Autocomplete";
import invertDirection from "../../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
	getTechnology,
	updateById,
	deleteById,
	insert,
	uploadIcon
} from "../../../redux/technology/technologyThunk";
import { course } from "../../../validations/mastersSchema";

import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../utils/userPermission";
import { setSortBy, setOrderBy } from "../../../redux/technology/technologySlice";

const ActivityStatus = () => {
	const confirm = useConfirm();

	const dispatch = useDispatch();
	const data = useSelector((state) => state.technology.data);
	const loading = useSelector((state) => state.technology.loading);
	const error = useSelector((state) => state.technology.error);
	const sortBy = useSelector((state) => state.technology.sortBy);
	const orderBy = useSelector((state) => state.technology.orderBy);
	const [id, setId] = React.useState(null);
	const ref = useRef(null);
	const [iconImage, setIconImage] = useState(null);
	const iconImageFileInput = useRef(null);
	const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
	const [toBeDeletedCandidateResume, setToBeDeletedCandidateResume] =
	useState(null);
	const formik = useFormik({
		initialValues: {
			title: "",
			isActive: false,
			parentId: "",
			icon: null,
		},
		validationSchema: course,
		onSubmit: async (values, { resetForm }) => {
			if (!values.parentId) {
				values.parentId = null;
			}

			try {
				if (id) {
					await dispatch(updateById({ ...values, id })).unwrap()
					.then(async (res) => {
						if(res?.success){
							await dispatch(uploadIcon({id: id, icon: iconImage})).unwrap();
						}
					})
				}
				if (!id) {
					await dispatch(insert(values)).unwrap()
					.then(async (res) => {
						if(res?.success){
							await dispatch(uploadIcon({id: id, icon: iconImage})).unwrap();
						}
					})
				}
				setIconImage(null)
				resetForm();
				// dispatch(getTechnology());
				dispatch(getTechnology({sortBy:'',orderBy:''}));

				return setId(null);
			} catch (error) {
				toast.error(error.message);
			}
		},
	});
	const {
		errors,
		setErrors,
		touched,
		handleSubmit,
		setValues,
		isSubmitting,
		getFieldProps,
		setFieldValue,
		values,
	} = formik;

	const handleImageChange = (e) => {
		var reader = new FileReader();

		reader.onload = function (e) {
			setSelectedFileUrl(e.target.result);
		};

		reader.readAsDataURL(e.target.files[0]);
		setSelectedFile(e.target.files[0]);
	};

	useEffect(() => {
		if (
			!loading &&
			!error &&
			(!data || (Array.isArray(data) && data.length <= 0))
		)
			// dispatch(getTechnology());
			dispatch(getTechnology({sortBy:'',orderBy:''}));

	}, [loading]);

	useEffect(() => {
		dispatch(
			getTechnology({
				sortBy,
				orderBy,
			}),
		);
}, [dispatch,sortBy, orderBy]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setErrors({});
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [ref]);

	const handleUpdate = (data) => {
		setValues({
			title: data.title,
			isActive: data.isActive,
			parentId: data.parentId ?? ""
		});

		setIconImage(data?.icon)
		setId(data.id);
	};

	const handleDelete = async (id, title) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${title}?`,
			});
			await dispatch(deleteById(id));
			// await dispatch(getTechnology());
			await dispatch(getTechnology({sortBy:'',orderBy:''}));

		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleToggle = async (skill) => {
		try {
			setIconImage(skill?.icon)
			await dispatch(
				updateById({
					id: skill.id,
					title: skill.title,
					isActive: !skill.isActive,
					parentId: skill.parentId,
				}),
			).unwrap()
			.then((res) => {
				if(res?.success){
					try{
						dispatch(uploadIcon({id:skill?.id, icon: iconImage})).unwrap();
					}
					catch (error) {
						toast.error(error?.message);
					}
				}
			})
			setIconImage(null)
			// dispatch(getTechnology());
			dispatch(getTechnology({sortBy:'',orderBy:''}));

		} catch (error) {}
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

	const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["jpeg", "jpg", "png"];
      return imageTypes.includes(file?.name?.split(".")[1]);
    }
    return file && file["type"] && file["type"]?.split("/")[0] === "image";
  };
  const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file?.split("/");
      return splittedFile[splittedFile.length - 1];
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };

	const handleDeleteCandidateResume = (image) => {
    setIconImage(null);
    setToBeDeletedCandidateResume(image?.id);
  };

	return (
		<>
		{/* <Card className="inner-component">
	<Container className="custom-container-grid"> */}
	 <Container
          className="custom-container-grid"
          style={{
            boxShadow: "initial !important",
            paddingLeft: "0",
            paddingRight: "0",
            minWidth: "100%",
            minHeight: "70%",
          }}
        >
				<div className="header">
					<form ref={ref} autoComplete="off" noValidate onSubmit={handleSubmit}>
						<h6 className="component-name">TECHNOLOGY MASTER</h6>
						{getAddVisible("master") && (
							<div className="heading-part">
								<div className="input-field-part technology-field">
									<TextField
										id="outlined-basic"
										label="Enter Technology Title"
										variant="outlined"
										size="small"
										name="title"
										value={formik.values.title}
										onChange={formik.handleChange}
										error={Boolean(touched.title && errors.title)}
										helperText={touched.title && errors.title}
										fullWidth
										inputProps={{ maxLength: 64 }}
									/>
								</div>

								<FormControl fullWidth>
									<InputLabel id="technology-label" size="small">
										Technology
									</InputLabel>
									<Select
										labelId="technology-label"
										value={formik.values.parentId}
										label="Technology"
										name="parentId"
										onChange={formik.handleChange}
										size="small"
										fullWidth
									>
										{data
											.filter((s) => s.id !== id)
											.map((skill) => {
												return (
													<MenuItem key={skill.id} value={skill.id}>
														{skill.title}
													</MenuItem>
												);
											})}
									</Select>

									{/*<Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  fullWidth
                  options={data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`parentId`)}
                  onChange={(event, newValue) => {
                    setFieldValue(
                    `parentId`,
                    newValue?.id || ""
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Technology" />
                  )}
                  value={data.find(
                    (Course) => Course.id === values.parentId
                  )}
                />*/}
								</FormControl>
								{/* <div className="input-field-part technology-field" style={{paddingLeft:"10px"}}> */}
								<div
										className="small profile-image-part"
										style={{ position: "relative", paddingLeft:"20px", height:"auto" }}
										onClick={(e) => {
											iconImageFileInput?.current?.click();
										}}
									>
										{loading
											? ""
											: iconImage && (
													<>
														{checkIfImage(iconImage) ? (
															<>
																<InsertDriveFileIcon
																	onClick={(e) => {
																		window.open(
																			iconImage.webViewLink,
																			"_blank"
																		);
																	}}
																/>
																{/* <Typography variant="caption">
																	{getFileName(iconImage)}
																</Typography> */}
															</>
														) : (
															<>
																<InsertDriveFileIcon
																	onClick={(e) => {
																		window.open(
																			iconImage.webViewLink,
																			"_blank"
																		);
																	}}
																/>
																{/* <Typography variant="caption">
																	{getFileName(iconImage)}
																</Typography> */}
															</>
														)}
													</>
												)}
										{loading ? (
											<Loader />
										) : (
											iconImage && (
												<CancelRoundedIcon
													onClick={(e) => {
														e.stopPropagation();
														handleDeleteCandidateResume(
															iconImage
														);
													}}
													className="closeIcon"
													style={{
														position: "absolute",
														top: "-7px",
														right: "-6px",
													}}
												/>
											)
										)}
										<div className="p-image" style={{position:"static"}}>
											{!iconImage && (
												<i className="upload-button">
													<CloudUploadIcon />
												</i>
											)}

											{!iconImage && (
												<input
													ref={iconImageFileInput}
													className="file-upload"
													type="file"
													accept=".svg"
													onChange={(e) =>{
														if (e.target.files[0].type === 'image/svg+xml') {
															setIconImage(e.target.files[0])
														} else {
															alert('Please select an SVG file.');
														}
													  
													}
													}
												/>
											)}
											{!iconImage && (
												<>
													<div className="file-support-text">
														Icon
													</div>
												</>
											)}
										</div>
									</div>
								{/* </div> */}
								<div className="checkbox-part">
									<FormControlLabel
										control={
											<Checkbox
												checked={formik.values.isActive}
												name="isActive"
												onChange={formik.handleChange}
											/>
										}
										label="Status"
									/>
								</div>
								<div className="button-part">
									<LoadingButton
										size="small"
										type="submit"
										variant="contained"
										loading={isSubmitting}
									>
										save
									</LoadingButton>
								</div>
							</div>
						)}
					</form>
				</div>
				<TableContainer component={Paper}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead>
								<TableRow>
									{/* <TableCell width="10%">ID</TableCell> */}
									<TableCell width="67%">
											<TableSortLabel
												active={sortBy === "title"}
												direction={sortBy === "title" ? orderBy : "asc"}
												onClick={() => handleSorting("title")}
											>
												Title
											</TableSortLabel>
										</TableCell>
									<TableCell width="10%" align="center">
										Status
									</TableCell>
									{(getEditVisible("master") || getDeleteVisible("master")) && (
										<TableCell
											width="13%"
											align="right"
											className="action-field"
										>
											Actions
										</TableCell>
									)}
								</TableRow>
							</TableHead>
							{loading ? (
								<Loader />
							) : (
							<TableBody>
								{Array.isArray(data) && data.length === 0 ? (
									<TableRow>
										<TableCell className="No-Record-text" align="center" colSpan={3}>
										No Record(s) Found
										</TableCell>
									</TableRow>
								) : (
									data.map((technology, idx) => (
										<TableRow
											key={idx}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 },
											}}
										>
											{/* <TableCell component="th" scope="row">
												{technology.id}
											</TableCell> */}
											<TableCell component="th" scope="row">
												{technology.title}
											</TableCell>
											<TableCell align="right">
												<Switch
													checked={technology.isActive}
													onChange={() => handleToggle(technology)}
													inputProps={{ "aria-label": "controlled" }}
													size="small"
												/>
											</TableCell>
											{(getEditVisible("master") ||
												getDeleteVisible("master")) && (
												<TableCell align="right">
													{getEditVisible("master") && (
														<IconButton
															onClick={() => handleUpdate(technology)}
														>
															<EditIcon color="primary" />
														</IconButton>
													)}
													{getDeleteVisible("master") && (
														<IconButton
															onClick={() =>
																handleDelete(technology.id, technology.title)
															}
														>
															<DeleteIcon color="error" />
														</IconButton>
													)}
												</TableCell>
											)}
										</TableRow>
									))
								)}
							</TableBody>
							)}
						</Table>
				</TableContainer>
		  </Container>
    {/*</Card> */}
		</>
	);
};

export default ActivityStatus;
