import * as Yup from "yup";

const drives = Yup.object().shape({
    collegeId: Yup.string().required("Please select college.").nullable(),
    placement: Yup.string().required("Please select placement.").nullable(),
    startYear: Yup.string().required("Please select start year.").nullable(),
    endYear: Yup.string().required("Please select end year.").nullable(),
    placementDate: Yup.string().required("Placement date is required.").nullable(),
    placementType: Yup.string().required("Placement type is required.").nullable(),
    participateColleges: Yup.array()
        .of(Yup.string())
        .when("placement", {
            is: (value) => value === "Pool",
            then: Yup.array()
                .of(Yup.string())
                .min(1, "Minimum one placement college is required.")
                .nullable(),
        }),
        totalAptitudeMarks: Yup.number().required("Total Aptitude Marks is required.").nullable()
        .min(1, "Total Aptitude Marks must be > 0")
        .typeError("Please enter numeric value"),
});

export default drives;
