import React, { useState, useEffect } from 'react';
import NiceModal, { useModal } from '@ebay/nice-modal-react';
import { useDispatch } from 'react-redux';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { getCollegeById } from '../../../redux/college/collegeThunk';
import Loader from '../../../components/Loader';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const CollegeDetailsModal = NiceModal.create(({ id }) => {
	const [collegeData, setcollegeData] = useState({});
	const [loading, setLoading] = useState(true);

	const modal = useModal();
	const dispatch = useDispatch();

	useEffect(() => {
		const getCollegedata = async () => {
			try {
				const response = await dispatch(getCollegeById({ id: id })).unwrap();
				setcollegeData(response.data);
			} catch (error) {
				setcollegeData({});
			} finally {
				setLoading(false);
			}
		};

		getCollegedata();
	}, [dispatch, id]);

	console.log("P___________01",collegeData)
	return (
		<Dialog
			TransitionComponent={Transition}
			open={modal.visible}
			onClose={() => modal.hide()}
			TransitionProps={{
				onExited: () => modal.remove(),
			}}
			fullWidth
		>
			{loading ? (
				<div style={{ marginTop: '20px' }}>
					<Loader />
				</div>
			) : Object.keys(collegeData).length === 0 ? (
				<DialogTitle id="alert-dialog-slide-title">No college found</DialogTitle>
			) : (
				<>
					<DialogTitle id="alert-dialog-slide-title">
						{collegeData?.collegeName}
					</DialogTitle>
					<DialogContent sx={{ display: 'flex', flexDirection: 'column' }}>
						<div>
							<b>Address</b>:
							<p>{`${collegeData?.streetLine1} ${collegeData?.streetLine1 ?"," : ""}${collegeData?.streetLine2}${collegeData?.streetLine2 > 0 ?"," : ""}${collegeData?.area}${collegeData?.area  ?"," : ""}${collegeData?.city}${collegeData?.city ?"," : ""}${collegeData?.state}${collegeData?.state?.length > 0 ?"," : ""}${collegeData?.zipcode}`}</p>
						</div>
						<br />
						<div>
							<b>Contact</b>
							{collegeData?.contact.map((contact) => (
								<p>{`${contact?.name}${contact?.email ?" - " : ""}${contact?.email}${contact?.contactNumber ?" - " : ""} ${contact?.contactNumber}`}</p>
							))}
						</div>
						<br />
						<div>
							<b>Website</b>
							<p>
								<a target="_blank" rel="noreferrer" href={collegeData?.website}>
									{collegeData?.website}
								</a>
							</p>
						</div>
					</DialogContent>
				</>
			)}

			<DialogActions>
				<Button color="error" variant="contained" onClick={() => modal.hide()}>
					Close
				</Button>
			</DialogActions>
		</Dialog>
	);
});

export default CollegeDetailsModal;
