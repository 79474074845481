import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";

import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import CustomPagination from "../../components/Pagination";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Autocomplete } from "@mui/material";

import {
	getSpecialDay,
	updateById,
	deleteById,
	insert,
} from "../../redux/specialday/specialDayThunk";
import holidaySchema from "../../validations/holidaySchema";
import {
	fDateCustom,
	getFullNameOfMonth,
	getDayName,
} from "../../utils/formatTime";
import {
	setLimit,
	setCurrentPage,
	setOrderBy,
	setSortBy,
} from "../../redux/specialday/specialdaySlice";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import invertDirection from "../../utils/invertDirection";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import { encryption } from "../../utils/encodeString";
function Speicalday() {
	const [open, setOpen] = useState(false);
	const [specialDayYearSelected, setSpecialDayYearSelected] = useState('')

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const getUserPermission = async (Id) => {

		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
			// console.log(res.data,"db");
		} catch (error) {

		}
	};
	useEffect(() => {
		let roleId = LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	}, [dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("specialDay")) {
			dispatch(
				getSpecialDay({
					page: currentPage + 1,
					limit: limit,
					sortBy,
					orderBy,
					year: values.year
				}),
			);
			navigate("/dashboard");
		}
	}, []);

	const confirm = useConfirm();

	const {
		data,
		totalRecords,
		currentPage,
		totalPages,
		limit,
		status,
		loading,
		sortBy,
		orderBy,
	} = useSelector((state) => state.specialday);

	const [id, setId] = useState(null);

	// Get the current year
	const currentYear = new Date().getFullYear();

	const formik = useFormik({
		initialValues: {
			title: "",
			startDate: null,
			isHoliday: 0,
			year: currentYear.toString(),
			quoteMessage: ''
			// endDate: null,
		},
		validationSchema: holidaySchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				if (id) {
					await dispatch(updateById({ ...values, id })).unwrap();
				}
				if (!id) {
					await dispatch(insert(values)).unwrap();
				}
				resetForm();
				dispatch(
					getSpecialDay({
						page: currentPage + 1,
						limit: limit,
						sortBy,
						orderBy,
						year: values.year
					}),
				);
				handleClose();
				return setId(null);
			} catch (error) {
				// toast.error(error.message);
			}
		},
	});

	const {
		handleSubmit,
		setValues,
		values,
		setFieldValue,
		handleChange,
		errors,
		touched,
		isSubmitting,
	} = formik;

	useEffect(() => {
		if (data.length === 0 && currentPage !== 0) {
			dispatch(setCurrentPage({ page: currentPage - 1 }));
		}
	}, [data.length]);

	useEffect(() => {
		dispatch(
			getSpecialDay({
				page: currentPage + 1,
				limit: limit,
				sortBy,
				orderBy,
				year: values.year,
			}),
		);
	}, [dispatch, limit, currentPage, sortBy, orderBy]);
	//dispatch, limit, currentPage, status, sortBy, orderBy
	const handleUpdate = (data) => {
		setValues({
			title: data.title,
			startDate: data.startDate,
			quoteMessage: data.quoteMessage
		});
		setId(data.id);
		handleClickOpen();
	};

	const handleDelete = async (id, title) => {
		try {
			await confirm({
				description: `Are you sure you want to delete Special Day ${title}?`,
			});
			await dispatch(deleteById(id));
			await dispatch(
				getSpecialDay({
					page: currentPage + 1,
					limit: limit,
					sortBy,
					orderBy,
					year: values.year,
				}),
			);
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage({ page: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
		dispatch(setCurrentPage({ page: 0 }));
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

	const handleClickOpen = () => {
		setOpen(true);
		setFieldValue('year', specialDayYearSelected)
	};

	const handleClose = () => {
		setValues({
			title: "",
			startDate: "",
			isHoliday: 0,
			quoteMessage:''
		});
		setId(null);
		setOpen(false);
		setFieldValue('year', specialDayYearSelected)
	};

	let currentYearFilter = new Date().getFullYear();
	const startYear = 2022;
	const endYear = currentYearFilter + 2;

	const year = Array.from({ length: endYear - startYear + 1 }, (_, i) => (startYear + i).toString());

	useEffect(() => {
		setSpecialDayYearSelected(currentYear.toString())
	}, [])

	const handleFilters = async (field, newValue) => {

		console.log("C_______FFF___)1", newValue)
		setFieldValue(field, newValue || "");
		setSpecialDayYearSelected(newValue)
		dispatch(
			getSpecialDay({
				page: currentPage + 1,
				limit: limit,
				sortBy,
				orderBy,
				year: newValue || "",
			}),
		);
	}

	return (
		<Container className="Employee-wrapper SpecialDay-Main-Container" maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
				<div className="title-button-wrapper SpecialDay-Main-Title">
					<Typography variant="h4" gutterBottom>
						Special Day ({totalRecords})
						{/* <Typography variant="body2">Total Records : {totalRecords}</Typography> */}
					</Typography>

					{getAddVisible("specialDay") && (
						<Button
							className="header-add-button"
							variant="contained"
							// startIcon={<AddIcon />}
							onClick={handleClickOpen}
						>
							<img src="/assets/images/add-circle.svg" />
							Add Special Day
						</Button>

					)}
				</div>
			</Stack>
			<div className="employee-classification-dropdown" style={{ width: '10%', marginBottom: "20px" }}>
				<Autocomplete
					// multiple
					popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
					size="small"
					fullWidth
					options={year || []}
					// getOptionLabel={(option) => option.title}
					name="year"
					onChange={(event, newValue) => {
						handleFilters(`year`, newValue || "");
					}}
					renderInput={(params) => (
						<TextField {...params} label="Year" />
					)}
					value={values.year || ""}
				/>
			</div>
			<Card className="SpecialDay-maincard">
				{/* <TablePagination
					rowsPerPageOptions={[20, 40]}
					component="div"
					count={totalRecords}
					rowsPerPage={limit}
					page={currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
				<Scrollbar sx={{ minWidth: 800 }}>
					<TableContainer sx={{ minWidth: 800 }}>
						{loading ? (
							<Loader />
						) : (
							<Table className="grid-table SpecialDay-table">
								<TableHead>
									<TableRow>
										<TableCell align="left">Month</TableCell>
										<TableCell align="left">
											<TableSortLabel
												className="th-sorting"
												active={sortBy === "startDate"}
												direction={sortBy === "startDate" ? orderBy : "asc"}
												onClick={() => handleSorting("startDate")}
											>
												Special Day Date
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												className="th-sorting"
												active={sortBy === "title"}
												direction={sortBy === "title" ? orderBy : "asc"}
												onClick={() => handleSorting("title")}
											>
												Special Day Name
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												className="th-sorting"
												active={sortBy === "quoteMessage"}
												direction={sortBy === "quoteMessage" ? orderBy : "asc"}
												onClick={() => handleSorting("quoteMessage")}
											>
												Special Day Quote Message
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">Day</TableCell>
										{(getEditVisible("specialDay") ||
											getDeleteVisible("specialDay")) && (
												<TableCell className="th-action-buttons" align="center" style={{ width: "140px" }}>
													Actions
												</TableCell>
											)}
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(data) && data.length === 0 ? (
										<TableRow>
											<TableCell className="No-Record-text" align="center" colSpan={4}>
												No Record(s) Found
											</TableCell>
										</TableRow>
									) : (
										data.map((holiday, idx) => (
											<TableRow key={idx}>
												<TableCell align="left">
													{getFullNameOfMonth(holiday.startDate)}
												</TableCell>
												<TableCell align="left">
													{fDateCustom(holiday.startDate)}
												</TableCell>
												<TableCell align="left">{holiday.title}</TableCell>
												<TableCell style={{ textAlign: 'center' }}>{holiday.quoteMessage ? holiday.quoteMessage : '-'}</TableCell>
												<TableCell align="left">
													{getDayName(holiday.startDate)}
												</TableCell>
												{(getEditVisible("specialDay") ||
													getDeleteVisible("specialDay")) && (
														<TableCell className="td-action-button" align="center">
															<div className="action-button">
																{getEditVisible("specialDay") && (
																	<IconButton
																		data-cy="btn-edit-holiday"
																		onClick={() => handleUpdate(holiday)}
																	>
																		<img src="assets/images/edit.svg" title="Edit" />
																		{/* <EditIcon color="primary" /> */}
																	</IconButton>
																)}

																{getDeleteVisible("specialDay") && (
																	<IconButton
																		data-cy="btn-delete-holiday"
																		onClick={() =>
																			handleDelete(holiday.id, holiday.title)
																		}
																	>
																		<img src="assets/images/trash-gray.svg" title="trash" />
																		{/* <DeleteIcon color="error" /> */}
																	</IconButton>
																)}
															</div>
														</TableCell>
													)}
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						)}
					</TableContainer>
				</Scrollbar>
				<CustomPagination
					totalPage={totalPages}
					totalCount={totalRecords}
					limit={limit}
					handleChangePage={handleChangePage}
					page={currentPage}
					rowsPerPageOptions={[10, 20, 40]}
					handleRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Card>
			<Dialog open={open} onClose={handleClose}>
				<DialogTitle>Add Special Day</DialogTitle>
				<form onSubmit={handleSubmit} style={{ paddingBottom: "20px" }}>
					<DialogContent style={{ padding: "0 20px 20px" }}>
						<Grid container spacing={3}>
							<Grid item xs={12} mt={3}>
								<TextField
									fullWidth
									label="Special Day Name"
									name="title"
									value={values.title}
									inputProps={{
										maxLength: 256,
										"data-cy": "txt-title-holiday",
									}}
									FormHelperTextProps={{
										"data-cy": "txt-title-err-holiday",
									}}
									onChange={handleChange}
									variant="outlined"
									size="small"
									error={Boolean(touched.title && errors.title)}
									helperText={touched.title && errors.title}
								/>
							</Grid>
							<Grid item xs={12}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Stack spacing={3} direction="row">
										<DesktopDatePicker
											label="Start Date"
											value={values.startDate}
											inputFormat="dd/MM/yyyy"
											onChange={(newValue) => {
												const NewDate = new Date(newValue);
												const tempDate = new Date();
												const hour = tempDate.getHours();
												const minutes = tempDate.getMinutes();
												const second = tempDate.getSeconds();
												const milliSecond = tempDate.getMilliseconds();

												NewDate.setHours(hour);
												NewDate.setMinutes(minutes);
												NewDate.setSeconds(second);
												NewDate.setMilliseconds(milliSecond);

												setFieldValue("startDate", NewDate);
											}}
											inputProps={{
												"data-cy": "txt-start-date-holiday",
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													FormHelperTextProps={{
														"data-cy": "txt-start-date-err-holiday",
													}}
													fullWidth
													size="small"
													error={Boolean(touched.startDate && errors.startDate)}
													helperText={touched.startDate && errors.startDate}
												/>
											)}
										/>
									</Stack>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={12}>
								<TextField
									type="text"
									fullWidth
									name="quoteMessage"
									label="Special Day Quote Message"
									multiline
									rows={2}
									maxRows={4}
									value={values.quoteMessage}
									onChange={handleChange}
									variant="outlined"
									size="small"
									error={Boolean(touched.quoteMessage && errors.quoteMessage)}
									helperText={touched.quoteMessage && errors.quoteMessage}
								/>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions style={{ paddingRight: "20px" }}>
						<Button onClick={handleClose}>Cancel</Button>
						<LoadingButton
							type="submit"
							variant="contained"
							loading={isSubmitting}
							data-cy="btn-submit-holiday"
						>
							save
						</LoadingButton>
					</DialogActions>
				</form>
			</Dialog>
		</Container>
	);
}

const componentConfig = {
	component: Speicalday,
	path: "/specialday",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
