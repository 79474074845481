import * as Yup from 'yup';

const digitsOnly = (value) => /^\d+$/.test(value);
const lettersAndSpecialCharsOnly = (value) => /^[a-z][a-z\s,-_./)(&@#$]*$/i.test(value);
const lettersOnly = (value) => /^[a-z][a-z\s.]*$/i.test(value);
// const isNotOnlyWhitespace = (value) => {
// 	if(value == ""){
// 		return true
// 	}
// 	return value && value.trim() !== '';
//   };
  
const collegeSchema = Yup.object().shape({
	collegeName: Yup.string()
	.trim('College Name cannot contain only whitespace')
    .strict(true)
		.required('College Name is required')
		.max(256, 'Maximum 256 characters required')
		.test(
			"Letters only",
			"Invalid College name",
			lettersAndSpecialCharsOnly,
		)
		.nullable(),
	streetLine1: Yup.string()
	//  .test('not-only-whitespace', 'Field cannot contain only whitespace', isNotOnlyWhitespace)
	.trim('streetLine1 cannot contain only whitespace')
    .strict(true)
		.required('Field is required')
		.min(2, 'Minimum 2 characters required')
		.max(256, 'Maximum 15 characters required')
		.nullable(),
	streetLine2: Yup.string()
	.trim('streetLine2 cannot contain only whitespace')
    .strict(true)
		.min(2, 'Minimum 2 characters required')
		.max(256, 'Maximum 256 characters required')
		.nullable(),
	area: Yup.string()
		.required('Area is required')
		.min(2, 'Minimum 2 characters required')
		.max(64, 'Maximum 64 characters required')
		.nullable(),
	city: Yup.string().required('City is required').nullable(),
	state: Yup.string().required('State is required').nullable(),
	zipcode: Yup.string()
	.trim('zipcode cannot contain only whitespace')
    .strict(true)
		.required('Zipcode is required')
		.test(
			"Digits only",
			"Zipcode should not contain any alphabet and special characters.",
			digitsOnly,
		)
		.nullable(),
	website: Yup.string()
	.trim('website cannot contain only whitespace')
    .strict(true)
		.required('Website is required')
		//.matches(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g, "Invalid Website")
		.matches(/(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/, "Please enter valid website")
		.url("Please enter valid website")
		.min(2, 'Minimum 2 characters required')
		.max(1024, 'Maximum 1024 characters required')
		.nullable(),
	contact: Yup.array().of(
		Yup.object().shape(
			{
				name: Yup.string()
				.trim('Contact person cannot contain only whitespace')
              .strict(true)
				.required('Contact person is required').max(64, 'Maximum 64 characters required')
				.test(
					"Letters only",
					"Contact name must contain only letters",
					lettersOnly,
				)
				.nullable(),
				email: Yup.string().when('contactNumber', {
					is: (number) => number ? false : true,
					then: Yup.string()
						.email('Invalid email')
						.required('Email is required.')
						.max(512, 'Email is too long')
						.nullable(),
					otherwise: Yup.string().nullable(),
				}),
				contactNumber: Yup.string().when('email', {
					is: (email) => email ? false : true,
					then: Yup.string()
					.matches(/^[6-9]\d{9}$/, "Contact number is invalid")
					.min(10, "Contact number must be 10 digits")
					.max(10, "Contact number must be 10 digits")
					.required('Contact number is required')
					.nullable(),
					otherwise: Yup.string()
					.matches(/^[6-9]\d{9}$/, "Contact number is invalid")
					.min(10, "Contact number must be 10 digits")
					.max(10, "Contact number must be 10 digits")
					.nullable(),
				}),
			},
			[['email', 'contactNumber']]
		)
	),
});

export default collegeSchema;
