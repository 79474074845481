import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value);

const itemAttributeValuesSchema = Yup.object().shape({
	attributeValues: Yup.array().of(
		Yup.object().shape({
			value: Yup.string().when(["isDeleted"], {
				is: (isDeleted) => isDeleted === true,
				then: Yup.string().nullable(),
				otherwise: Yup.string()
				.required("Attribute Value is required")
				.max(256, "Maximum 256 length is Valid"),
			}),
			isDeleted: Yup.boolean(),
		}),
	),
});
export default itemAttributeValuesSchema;
