import React, { useEffect } from "react";
import DashboardLayout from "../../layouts/dashboard";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import Loader from "../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import {
  setSortBy,
  setOrderBy,
  setCurrentPage,
  setLimit,
} from "../../redux/helpdeskCategories/helpdeskCategoriesSlice";
import invertDirection from "../../utils/invertDirection";
import CustomPagination from "../../components/Pagination";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteById,
  getHelpdeskCategories,
  getTotalCategoryCount,
  updateById,
} from "../../redux/helpdeskCategories/helpdeskCategoriesThunk";
import { encryption } from "../../utils/encodeString";
import {
  getDeleteVisible,
  getEditVisible,
  getViewVisible,
} from "../../utils/userPermission";

function HelpDeskCategories() {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data,
    loading,
    sortBy,
    orderBy,
    totalRecords,
    limit,
    currentPage,
    totalPages,
    error,
    status,
    totalCategoriesCount
  } = useSelector((state) => state.helpdeskCategories);

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("categories")) {
      navigate("/dashboard");
    }
  }, []);

  // use effect for fetching helpdesk categories
  useEffect(() => {
    dispatch(
      getHelpdeskCategories({
        limit,
        page: currentPage + 1,
        orderBy,
        sortBy,
      })
    );
    dispatch(getTotalCategoryCount({})).unwrap();

    
  }, [limit, currentPage, error, sortBy, orderBy]);


  // function for update category
  const handleUpdate = (data) => {
    navigate("/categories/edit/" + encryption(data.id));
  };

  // function for delete category
  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteById(id));
      await dispatch(
        getHelpdeskCategories({
          limit,
          page: currentPage + 1,
          orderBy,
          sortBy,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // function for toggle status of category
  const handleToggle = async (category) => {
    try {
      if (category.isActive === true) {
        const subCategories = category.helpDeskSubCategories.map(
          (subCategory) => {
            return { ...subCategory, isActive: false };
          }
        );
        const cannedMessages = category.helpDeskCannedMessages.map(
          (cannedMessage) => {
            return { ...cannedMessage, isActive: false };
          }
        );
        await dispatch(
          updateById({
            id: category.id,
            title: category.title,
            defaultAssignee: category.defaultAssignee,
            responsiblePerson: category.responsiblePerson,
            prefix: category.prefix,
            isActive: false,
            responseTime: category.responseTime,
            subCategories: category?.helpDeskSubCategories,
            cannedMessages: category?.helpDeskCannedMessages,
          })
        ).unwrap();
      }
      if (category.isActive === false) {
        // const subCategories = category.helpDeskSubCategories.map(
        //   (subCategory) => {
        //     return { ...subCategory, isActive: true };
        //   }
        // );
        // const cannedMessages = category.helpDeskCannedMessages.map((cannedMessage) => {
        //   return { ...cannedMessage, isActive:true };
        // })
        await dispatch(
          updateById({
            id: category.id,
            title: category.title,
            defaultAssignee: category.defaultAssignee,
            responsiblePerson: category.responsiblePerson,
            prefix: category.prefix,
            isActive: true,
            responseTime: category.responseTime,
            subCategories: category.helpDeskSubCategories,
            cannedMessages: category.helpDeskCannedMessages,
          })
        ).unwrap();
      }

      await dispatch(
        getHelpdeskCategories({
          limit,
          page: currentPage + 1,
          orderBy,
          sortBy,
        })
      );
    } catch (error) {
      // toast.error(error.message);
    }
  };

  // function for sorting
  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  // functions for pagination
  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Categories
          <Typography variant="body2">
            Total Records : {totalCategoriesCount?.totalCategories || 0}
          </Typography>
        </Typography>

        <Button
          component={RouterLink}
          to="/categories/add"
          variant="contained"
          startIcon={<AddIcon />}
          className="filter-icon-part"
        >
          Add Helpdesk Category
        </Button>
         </div>
      </Stack>
      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Card>
              <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                page={currentPage}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                //filter={isSearchQuery ? true : false}
              />
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="20%">
                      <TableSortLabel
                        active={sortBy === "title"}
                        direction={sortBy === "title" ? orderBy : "asc"}
                        onClick={() => handleSorting("title")}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width="20%">Sub-Categories</TableCell>
                    <TableCell width="20%">
                      <TableSortLabel
                        active={sortBy === "responseTime"}
                        direction={sortBy === "responseTime" ? orderBy : "asc"}
                        onClick={() => handleSorting("responseTime")}
                      >
                        Response Time
                      </TableSortLabel>
                    </TableCell>
                    <TableCell width="20%" align="center">
                      <TableSortLabel
                        active={sortBy === "isActive"}
                        direction={sortBy === "isActive" ? orderBy : "asc"}
                        onClick={() => handleSorting("isActive")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    {(getEditVisible("categories") ||
                      getDeleteVisible("categories")) && (
                      <TableCell
                        width="10%"
                        align="right"
                        className="action-field"
                      >
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableCell align="center" colSpan={6}>
                      <Loader />
                    </TableCell>
                  ) : data && (data?.length == 0 || data?.length == 0) ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={3}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((category, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {category?.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {category?.helpDeskSubCategories?.map(
                            (subCategory, index) =>
                              subCategory?.title +
                              (index + 1 ===
                              category?.helpDeskSubCategories?.length
                                ? ""
                                : ", ")
                          )}
                        </TableCell>

                        <TableCell component="th" scope="row" align="center">
                          {category?.responseTime / 24 === 1
                            ? category?.responseTime / 24 + " Day"
                            : category?.responseTime / 24 + " Days"}
                        </TableCell>
                        <TableCell align="center">
                          <Switch
                            checked={category?.isActive}
                            onChange={() => handleToggle(category)}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                            disabled={!getEditVisible("categories")}
                          />
                        </TableCell>
                        {(getEditVisible("categories") ||
                          getDeleteVisible("categories")) && (
                          <TableCell align="right">
                            {getEditVisible("categories") && (
                              <IconButton
                                data-cy="btn-edit-holiday"
                                onClick={() => handleUpdate(category)}
                              >
                                <EditIcon color="primary" />
                              </IconButton>
                            )}
                            {getDeleteVisible("categories") && (
                              <IconButton
                                data-cy="btn-delete-holiday"
                                onClick={() =>
                                  handleDelete(category.id, category.title)
                                }
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Card>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: HelpDeskCategories,
  path: "/Categories",
  public: false,
  layout: DashboardLayout,
  group: "Helpdesk",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
