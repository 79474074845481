import * as Yup from "yup";

const seatSchema = Yup.object().shape({
  employeeId : Yup.string()
    .required("Employee is required")
    .nullable(),
  seatNumber : Yup.string()
    .required("Please select One Seat")
    .nullable(),
});

export default seatSchema;