import React, { useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";
import LoadingButton from "@mui/lab/LoadingButton";
import Scrollbar from "../../../components/Scrollbar";
import DashboardLayout from "../../../layouts/dashboard";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";

import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import FastfoodIcon from "@mui/icons-material/Fastfood";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";

import { decryption, encryption } from "../../../utils/encodeString";
import {
  convertToEmployee,
  getCandidateById,
} from "../../../redux/candidate/candidateThunk";
import { getDesignation } from "../../../redux/designation/designationThunk";
import Loader from "../../../components/Loader";
import { BASE_URL } from "../../../constants";
import {
  convertTo12HrsFormat,
  convertTo24HrsFormat,
  fDateCustom,
  fDateTime12,
  fDateTime,
  fDateCustomNew,
} from "../../../utils/formatTime";

import "./custom-candidate.css";
import { toast } from "react-toastify";
import { EmployeeConfirmationModal } from "./ViewCandidateComponents/EmployeeConfirmationModal";
import Modal from "@mui/material/Modal";
// import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import moment from "moment";
import { addMonths, getDay } from "date-fns";
import { getCurrentMonthHolidayList } from "../../../redux/dashboard/dashboardThunk";
import { getEditVisible, getViewVisible } from "../../../utils/userPermission";
import { sendOnboardingLink } from "../../../redux/onboardingCandidate/candidateThunk";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import localStorage from "../../../service/localStorage";
import { useConfirm } from "material-ui-confirm";
import { makeStyles } from "@material-ui/core";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: "0.4",
        cursor: "default",
        "-webkit-text-fill-color": "#919EAB",
      },
      "&:hover fieldset": {
        opacity: "0.4",
        cursor: "default",
        "-webkit-text-fill-color": "#919EAB",
      },
      "&.Mui-focused fieldset": {
        opacity: "0.4",
        cursor: "default",
        "-webkit-text-fill-color": "#919EAB",
      },
    },
    "& .MuiInputLabel-root": {
      opacity: "0.4",
      cursor: "default",
      "-webkit-text-fill-color": "#919EAB",
    },
  },
}));

function ViewCandidate() {
  let { id } = useParams();
  const classes = useStyles();
  id = decryption(id);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const confirm = useConfirm();
  const [value, setValue] = React.useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [isClickedOnboarding, setIsClickedOnboarding] = useState(false);
  const [joiningDate, setJoiningDate] = useState();
  const [productionDate, setProductionDate] = useState();
  const [candidateData, setCandidateData] = useState(null);
  const designationData = useSelector((state) => state.designation);
  const AuthRole = useSelector((state) => state.auth.role);
  const [sortExpData, setSortExpData] = useState([]);
  const [disabled, setDisabled] = useState(false);
  let [lodar, setLodar] = useState(false);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      localStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  useEffect(() => {
    // console.log(1);
    // console.log(getViewVisible('candidates'));
    if (id === -1) {
      // console.log(2);
      return navigate("/candidate");
    }
    if (!getViewVisible("candidates")) {
      // console.log(3);
      return navigate("/dashboard");
    }
  }, [id]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleJoinAndProductionDate = (date) => {
    // console.log(date,"pmd");
    setJoiningDate(date);
    if (getDay(date)) {
      // console.log(getDay(date),'pddd');
      let flag = false;
      let months = 15 > getDay(date) ? 3 : 4;
      let getDate = addMonths(date, months);
      dispatch(
        getCurrentMonthHolidayList({
          date: moment(getDate).format("YYYY-MM") || "",
        })
      )
        .unwrap()
        .then((data) => {
          if (data?.holidayData.length === 0) {
            // setFieldValue("workDetails.commitmentStartDate", getDate);
            return setProductionDate(getDate);
          }
          data?.holidayData.sort(function (a, b) {
            return new Date(a.start) - new Date(b.start);
          });

          for (let index = 0; index < data?.holidayData.length; index++) {
            const element = data?.holidayData[index];
            if (
              moment(getDate).format("DD-MM-YYYY") !=
              moment(element.start).format("DD-MM-YYYY")
            ) {
              flag = true;
              // setFieldValue("workDetails.productionDate", getDate);
              // setFieldValue("workDetails.commitmentStartDate", getDate);
              setProductionDate(getDate);
              break;
            } else {
              getDate = moment(getDate).add(1, "d");
            }
          }
          if (!flag) {
            // setFieldValue("workDetails.productionDate", getDate);
            // setFieldValue("workDetails.commitmentStartDate", getDate);
            setProductionDate(getDate);
          }
        })
        .catch((err) => {});
    }
  };

  const handleBackLog = (e) => {
    if (e.view.navigation.canGoBack) {
      navigate("/candidate");
    } else {
      navigate("/candidate");
    }
  };

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getCandidateById(id))
      .unwrap()
      .then((res) => {
        setCandidateData(res.data);
        if (res.data.joiningDate) {
          var date = new Date(res.data.joiningDate);
          var offset = 5.5 * 60 * 60 * 1000;
          date.setTime(date.getTime() + offset);
          handleJoinAndProductionDate(new Date(date.toString()));
        }
        res.data.experience.sort((a, b) => {
          return (
            (b.to === null) - (a.to === null) ||
            -(a.to > b.to) ||
            +(a.to < b.to)
          );
        });
        setIsLoading(false);
      })
      .catch((err) => {
        // navigate("/candidate");
      });
  }, [dispatch, id, navigate]);

  useEffect(() => {
    if (
      !designationData.complete &&
      !designationData.loading &&
      !designationData.error &&
      (!designationData.data ||
        (Array.isArray(designationData.data) &&
          designationData.data.length <= 0))
    )
      dispatch(getDesignation());
  });

  /* const getDesignationName = (designationId) => {
		const allDesignationData = designationData.data;

		allDesignationData.map((item) => {
			if (item.id === designationId) {
				return item.title;
			} else {
				return "-";
			}
		});
	}; */

  useEffect(() => {
    dispatch(getDesignation());
  }, [dispatch]);
  const getIntervieeNames = (interview) => {
    if (
      interview.interviewrefrences &&
      interview.interviewrefrences.length <= 0
    ) {
      return "-";
    }

    const intervieeNames = interview.interviewrefrences.map((names) => {
      return names?.interviewer?.firstName;
    });

    return intervieeNames.join(", ");
  };

  const getTechnolgyNames = (experience) => {
    if (experience.technologies && experience.technologies.length <= 0) {
      return "-";
    }

    const technologyNames = experience.technologies.map((techs) => {
      return techs.technology?.title;
    });

    return technologyNames.join(", ");
  };
  let convertToEmployeefun = async () => {
    try {
      setLodar(true);
      const response = await NiceModal.show(EmployeeConfirmationModal);
      if (response?.success) {
        const res = await dispatch(
          convertToEmployee({ id, joiningDate, productionDate })
        ).unwrap();
        if (res.status === 200) {
          setLodar(false);
          toast.success("Candidate successfully converted to employee");
          return navigate(`/joining-employee`);
        }
      }
      handleClose();
    } catch (error) {
      setLodar(false);
      toast.error(error.message);
      handleClose();
    }
  };

  // const openEmployeeConfirmationModal = useCallback(async () => {
  // 	try {
  // 		const response = await NiceModal.show(EmployeeConfirmationModal);

  // 		if (response?.success) {
  // 			const res = await dispatch(convertToEmployee({ id })).unwrap();

  // 			if (res.status === 200) {
  // 				toast.success("Candidate successfully converted to employee");
  // 				return navigate(`/employee/add/${encryption(res.data.id)}`);
  // 			}
  // 		}
  // 	} catch (error) {
  // 		toast.error(error.message);
  // 	}
  // }, [id, dispatch, navigate]);

  const formatDecimal = (num) => {
    let strArray = num.toString().split(".");
    // console.log(strArray,"pd");
    let secondArry = strArray[1]?.split("");
    return secondArry
      ? `${strArray[0]}.${
          secondArry[0] == "0"
            ? `${secondArry[1]}`
            : `${secondArry[0]}${secondArry[1] || 0}`
        }`
      : `${strArray[0]}.00`;
  };

  const getInterviewStatus = (candidateData) => {
    let interviewDetails;

    if (
      candidateData?.activity?.candidateStatusId != null &&
      candidateData?.activity?.candidateStatus != null
    ) {
      interviewDetails = `${candidateData?.activity?.candidateStatus?.name}`;
    }

    if (
      candidateData?.activity?.candidateStatusId != null &&
      candidateData?.activity?.candidateStatus != null &&
      candidateData?.activity?.candidateStateId != null &&
      candidateData?.activity?.candidateState != null
    ) {
      interviewDetails = `${candidateData?.activity?.candidateStatus?.name} ( ${candidateData?.activity?.candidateState?.name} )`;
    }

    return interviewDetails || "-";
  };

  const handleOnboarding = async (e) => {
    try {
      if (candidateData?.onboardingInvition == 1) {
        await confirm({
          description: "Are you sure want to Resend Onboarding Link?",
        });
        setIsClickedOnboarding(true);
        await dispatch(sendOnboardingLink(id))
          .unwrap()
          .then((res) => {
            toast.success("Onboarding request has been sent successfully");
            setIsClickedOnboarding(false);
            setDisabled(true);
          })
          .catch((err) => {
            toast.error(err?.message);
            setIsClickedOnboarding(false);
            setDisabled(true);
          });
      } else {
        await confirm({
          description: "Are you sure want to Send Onboarding Link?",
        });
        setIsClickedOnboarding(true);
        await dispatch(sendOnboardingLink(id))
          .unwrap()
          .then((res) => {
            setIsClickedOnboarding(false);
            toast.success("Onboarding request has been sent successfully");
            setDisabled(true);
          })
          .catch((err) => {
            toast.error(err?.message);
            setIsClickedOnboarding(false);
            setDisabled(true);
          });

        // if (res.status === 200) {
        //   toast.success("Onboarding request has been sent successfully")
        //   setDisabled(true);
        // }else{

        // }
      }
    } catch (error) {}
  };

  if (isLoading) {
    return <Loader></Loader>;
  }
  return (
    <div className="view_employee candidate-details candidate-view_employee">
      <Container className="candidate-view_employee_container" maxWidth="xl">
        <Stack direction="row" justifyContent="flex-end" mb={2}>
          <div className="title-button-wrapper candidate-view_employee-title">
            {!candidateData.employeeId &&
              candidateData.activity.candidateState?.name
                .toLowerCase()
                .replace(/\s+/g, "") === "offeraccepted" && (
                //  candidateData?.onboardingInvition !== 1 &&
                <LoadingButton
                  variant="contained"
                  onClick={(e) => handleOnboarding()}
                  startIcon={<ForwardToInboxIcon />}
                  loading={isClickedOnboarding}
                  disabled={
                    disabled ||
                    (candidateData?.onboardingProcessStatus != ""
                      ? false
                      : !candidateData?.onboardingProcessStatus
                      ? false
                      : true) ||
                    candidateData?.onboardingProcessStatus == "SUBMIT" ||
                    candidateData?.onboardingProcessStatus == "RESUBMIT" ||
                    candidateData?.onboardingProcessStatus == "VERIFIED"
                  }
                  // disabled={disabled || (candidateData?.onboardingInvition == 1 ? true : false)}
                >
                  {candidateData?.onboardingInvition == 1
                    ? "Resend Onboarding Form"
                    : "Send Onboarding Form"}
                </LoadingButton>
              )}
              {(candidateData?.editCandidate == "1") && (
                <Button
                className="clock-icon-button"
                variant="contained"
                component={RouterLink}
                to={`/interviewlist/schedule-interview/${encryption(
                  candidateData?.id
                )}`}
              >
                {/* <ScheduleIcon /> */}
                Schedule Interview
              </Button>
               )}
              {!candidateData.employeeId &&
                AuthRole === "Super Admin" &&
                candidateData.activity.candidateState?.name
                .toLowerCase()
                .replace(/\s+/g, "") === "offeraccepted" && (
                <Button
                  variant="contained"
                  // onClick={openEmployeeConfirmationModal}
                  onClick={handleOpen}
                  startIcon={<SwapHorizIcon />}
                >
                  Convert to Employee
                </Button>
              )}
              {(candidateData?.activity?.candidateStatus?.name !== "Lead" ||
                candidateData?.activity?.candidateState?.name !== "Employee") &&
                !candidateData?.employeeId && (
                  <Button
                    className="Edit-button"
                    variant="contained"
                    component={RouterLink}
                    to={`/candidate/add/${encryption(candidateData?.id)}`}
                  >
                    <img src="/assets/images/edit-white-icon.svg" />
                    Edit
                  </Button>
              )}
            
              {/* <Typography variant="h4" gutterBottom>
                
              </Typography> */}
              <Button
                variant="contained"
                /*component={RouterLink}
              to="/candidate" */
                onClick={handleBackLog}
                className="back-button"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </div>
        </Stack>
        <Box className="detail-candidate-view-box" sx={{ flexGrow: 1 }}>
          <Grid className="detail-candidate-view-box-grid" container spacing={2}>
            {/* /right personal details */}
            <Grid className="col" item xs={4}>
              <Card style={{ height: "100%" }}>
                <Container className="gray-box Employee_Detail_Content personal-details-section" maxWidth="xl">
                  <div className="profile-info-part personal-image-section">
                    <div className="profile-image-part">
                      <Avatar
                        src={
                          `${BASE_URL}/${candidateData.candidateImage}` || ""
                        }
                        alt={candidateData.firstName}
                        sx={{
                          width: "140px",
                          height: "140px",
                          fontSize: "2.5rem",
                          marginTop: "0",
                        }}
                      ></Avatar>
                    </div>
                    <Typography className="profile-image-heading" variant="h4" align="center">
                      {candidateData.firstName} {candidateData.lastName}
                    </Typography>
                    <div className="inner-info-part">
                      {candidateData.contactNumber && (
                        <Stack
                          className="inner-info-part-Stack"
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <p>
                            <img src="/assets/images/call.svg" />
                            {/* <PhoneIphoneIcon color="primary" /> */}
                            <Link>{candidateData.contactNumber}</Link>
                          </p>
                        </Stack>
                      )}
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Chip
                          label={getInterviewStatus(candidateData)}
                          color="primary"
                        />
                      </Stack>
                    </div>
                    <Stack
                      className="social-image-icon"
                      direction="row"
                      justifyContent={"center"}
                      style={{ marginBottom: "20px" }}
                    >
                      {candidateData?.skype ? (
                        <a href={`skype:${candidateData?.skype}?chat`}>
                          <Box
                            component="img"
                            src="/assets/images/skype-gray.svg"
                            className="skype-img"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {candidateData?.linkedIn ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            candidateData?.linkedIn
                              ? candidateData?.linkedIn
                              : ""
                          }
                        >
                          <Box
                            component="img"
                            src="/assets/images/linkedin-gray.svg"
                            className="linkdin-img"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {candidateData?.github ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            candidateData?.github ? candidateData?.github : ""
                          }
                        >
                          <Box
                            component="img"
                            src="/assets/images/copylink-graybox.svg"
                            className="github-img"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {candidateData?.portfolioWebsite ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            candidateData?.portfolioWebsite
                              ? candidateData?.portfolioWebsite
                              : ""
                          }
                        >
                          <Box
                            component="img"
                            src="/assets/images/copylink-graybox.svg"
                            className="portfolio-img"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {candidateData?.email && (
                        <a href={`mailto:${candidateData?.email}`}>
                          <Box
                            component="img"
                            src="/assets/images/mail-graybox.svg"
                            className="mail-img"
                          />
                        </a>
                      )}

                      {candidateData?.candidateResume ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            candidateData?.candidateResume
                              ? candidateData?.candidateResume?.webViewLink
                              : ""
                          }
                        >
                          <Box
                            component="img"
                            src="/assets/images/download-icon.svg"
                            className="download-img"
                            style={{
                              marginBottom: "0",
                              width: "16px !important",
                            }}
                          />
                        </a>
                      ) : (
                        ""
                      )}
                    </Stack>
                  </div>
                </Container>
              </Card>
            </Grid>
            {/* /right personal details */}
            {/* left personal details */}
            <Grid className="col" item xs={8}>
              <Card className="gray-box Employee_Detail_Content" style={{ minHeight: "100%" }}>
                <Container>
                  <TabContext value={value}>
                    <Grid className="candidate_card_Detail_Container-tab" container spacing={2}>
                      <Grid
                      className="candidate_card_Detail_tab_item"
                        item
                        xs={10}
                        style={{ maxWidth: "100%", flexBasis: "100%" }}
                      >
                        <Typography
                          variant="h6"
                          className="information-card-heading Employee_Detail-h6"
                          style={{marginTop:"0"}}
                        >
                          Personal Information
                        </Typography>
                        <div className="address-blk">
                          <div className="address-sub-section">
                            <div className="address-div">
                              <span>First Name</span>
                              <p>
                                {candidateData?.firstName
                                  ? candidateData?.firstName
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Last Name</span>
                              <p>
                                {candidateData?.lastName
                                  ? candidateData?.lastName
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Gender</span>
                              <p>
                                {candidateData?.gender
                                  ? candidateData?.gender == "M"
                                    ? "Male"
                                    : "Female"
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Designation</span>
                              <p>
                                {candidateData?.designation?.title
                                  ? candidateData?.designation?.title
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Joining Date</span>
                              <p>
                                {candidateData?.joiningDate
                                  ? fDateCustom(candidateData?.joiningDate)
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Production Date</span>
                              <p>
                                {candidateData?.productionDate
                                  ? fDateCustom(candidateData?.productionDate)
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Location</span>
                              <p>
                                {candidateData?.location
                                  ? candidateData?.location
                                  : "-"}
                              </p>
                            </div>

                            <div className="address-div">
                              <span>Seat Number</span>
                              <p>
                                {candidateData?.seatNumber
                                  ? candidateData?.seatNumber
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="address-sub-section">
                            <div className="address-div">
                              <span>Contact Number</span>
                              <p>
                                {candidateData?.contactNumber
                                  ? candidateData?.contactNumber
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Alternate Contact Number</span>
                              <p>
                                {candidateData?.alternateContactNumber
                                  ? candidateData?.alternateContactNumber
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>LinkedIn</span>
                              <p>
                                {candidateData?.linkedIn ? (
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    href={
                                      candidateData?.linkedIn
                                        ? candidateData?.linkedIn
                                        : "-"
                                    }
                                  >
                                    {candidateData?.firstName}{" "}
                                    {candidateData?.lastName}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>E-mail</span>
                              <p>
                                {candidateData?.email
                                  ? candidateData?.email
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Skype</span>
                              <p>
                                {candidateData?.skype
                                  ? candidateData?.skype
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>GitHub Profile</span>
                              <p>
                                {candidateData?.github
                                  ? candidateData?.github
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Portfolio Website</span>
                              <p>
                                {candidateData?.portfolioWebsite
                                  ? candidateData?.portfolioWebsite
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabContext>
                </Container>
              </Card>
            </Grid>
            {/* left personal details */}
          </Grid>
        </Box>
        {/* interview section */}
        <Typography className="information-card-heading Employee_Detail-h6" variant="h6" my={2}>
          Interview
        </Typography>
        <Card className="custom-inner-grid Interview-view-card-wrapper">
          <TableContainer>
            <Table className="grid-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Time</TableCell>
                  <TableCell align="left">Duration</TableCell>
                  <TableCell align="left">Interviewer</TableCell>
                  <TableCell align="left">Type</TableCell>

                  <TableCell align="left">Technology</TableCell>
                  <TableCell align="left">Comments</TableCell>
                  <TableCell className="th-action-buttons" align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {candidateData.interviewSchedule?.length > 0 ? (
                  candidateData.interviewSchedule
                    ?.sort(
                      (a, b) =>
                        new Date(b?.interviewDate) - new Date(a?.interviewDate)
                    )
                    ?.map((interview, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {fDateCustom(interview?.interviewDate)}
                          </TableCell>
                          <TableCell align="left">
                            {convertTo24HrsFormat(interview?.interviewTime) ||
                              "-"}
                          </TableCell>
                          <TableCell align="left">
                            {`${interview?.duration} MIN` || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {getIntervieeNames(interview) || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.interviewType || "-"}
                          </TableCell>

                          <TableCell align="left">
                            {interview?.technology?.title || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.comments || "-"}
                          </TableCell>
                          <TableCell className="td-action-buttons" align="left">
                            <Stack className="action-button" direction="row">
                              {getEditVisible("interview") ? (
                                <IconButton
                                  component={RouterLink}
                                  to={`/interviewlist/schedule-interview/${encryption(
                                    interview?.candidateId
                                  )}`}
                                >
                                  <img src="/assets/images/edit.svg" title="Edit" />
                                  {/* <EditIcon color="primary" /> */}
                                </IconButton>
                              ) : (
                                ""
                              )}
                              {interview?.googleMeetLink && (
                                <a
                                  href={`${interview?.googleMeetLink}`}
                                  target="_blank"
                                >
                                  <img
                                    src="/assets/images/google-meet.svg"
                                  />
                                </a>
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell className="No-Record-text" colSpan={12} align="center">
                      No Record(s) Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {/* interview section */}
        {/* experience section left from here */}
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Typography className="information-card-heading Employee_Detail-h6" variant="h6" mt={3} mb={2}>
            Experience(s) (Total : {candidateData?.totalExperience} yrs,
            Calculated :{" "}
            {candidateData?.calculatedExperience
              ? formatDecimal(candidateData?.calculatedExperience)
              : ""}{" "}
            yrs )
          </Typography>
          {/* <Stack direction="row" alignItems="center" justifyContent="space-between">
					<div className="expirience-right-side">
						<i>Total Experience:2</i>,<i> Counted: 0 year(s)</i>
					</div>
				</Stack> */}
        </Stack>
        <Card className="custom-inner-grid">
          <TableContainer sx={{ minWidth: 800 }}>
            <Table className="grid-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Company Name</TableCell>
                  <TableCell align="left">Designation</TableCell>
                  <TableCell align="left">Technology</TableCell>
                  <TableCell align="left">From</TableCell>
                  <TableCell align="left">To</TableCell>
                  <TableCell align="left">Experience(yrs)</TableCell>
                  <TableCell align="left">Reason</TableCell>
                </TableRow>
              </TableHead>
              {}
              <TableBody>
                {candidateData.experience?.length > 0 ? (
                  candidateData.experience
                    .sort((a, b) => new Date(b?.from) - new Date(a?.from))
                    .map((experience, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell align="left">
                            {experience?.company?.name || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {experience?.designation?.title ||
                              experience?.designationName ||
                              "-"}
                          </TableCell>
                          <TableCell align="left">
                            {getTechnolgyNames(experience) || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {fDateCustomNew(experience?.from)}
                          </TableCell>
                          <TableCell align="left">
                            {fDateCustomNew(experience?.to)}
                          </TableCell>
                          <TableCell align="left">
                            {/* {experience?.totalExperience || "-"} */}
                            {experience?.totalExperience
                              ? formatDecimal(experience?.totalExperience)
                              : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {experience?.reasonForChange?.title || "-"}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell className="No-Record-text" colSpan={12} align="center">
                      No Record(s) Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
        {/* experience section */}
        {/* salary section */}
        {localStorage.getItem("role").toLowerCase() == "super admin" ||
        localStorage.getItem("role").toLowerCase() == "hr" ? (
          <>
            <Typography className="information-card-heading Employee_Detail-h6" variant="h6" my={2}>
              Salary Details
            </Typography>
            <Card className="custom-inner-grid">
              <TableContainer>
                <Table className="grid-table">
                  <TableHead>
                    <TableRow>
                      {/* <TableCell align="left">Company</TableCell> */}
                      <TableCell align="left">Current</TableCell>
                      <TableCell align="left">Last Increment</TableCell>
                      <TableCell align="left">Before</TableCell>
                      <TableCell align="left">Expected</TableCell>
                      <TableCell align="left">NP (days)</TableCell>
                      {/* <TableCell align="left">Reason</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {/* <TableCell align="left">Cybercom Creations</TableCell> */}
                      <TableCell align="left">
                        {`${candidateData?.salary?.currentCtc ?? "-"}`}
                      </TableCell>
                      <TableCell align="left">
                        {candidateData?.salary?.lastIncrementDate
                          ? fDateCustom(
                              candidateData?.salary?.lastIncrementDate
                            )
                          : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {`${
                          candidateData?.salary?.salaryBeforeIncrement || "-"
                        }`}
                      </TableCell>
                      <TableCell align="left">
                        {`${candidateData?.salary?.expectedCtc || "-"}`}
                      </TableCell>
                      <TableCell align="left">
                        {candidateData?.salary?.addNoticePeriod || "-"}
                      </TableCell>
                      {/* <TableCell align="left">
                                    {candidateData.salary.status}
                                </TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        ) : (
          ""
        )}
        {/* salary section */}
        {/* status section */}
        {/* <Typography variant="h6" my={2}>
					Activity Logs
				</Typography>
				<Card className="custom-inner-grid" sx={{ marginBottom: "40px" }}>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left">Approached By</TableCell>
									<TableCell align="left">Approached Date</TableCell>
									<TableCell align="left">Activity Date</TableCell>
									<TableCell align="left">Status</TableCell>
									<TableCell align="left">State</TableCell>
									<TableCell align="left">Note</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{candidateData.candidateactivitylogs?.length > 0 ? (
									candidateData.candidateactivitylogs.map((logs) => {
										return (
											<TableRow key={logs.id}>
												<TableCell align="left">{`${logs?.user?.firstName} ${logs?.user?.lastName}`}</TableCell>
												<TableCell align="left">
													{logs.approachDate
														? fDateCustom(logs?.approachDate)
														: "-"}
												</TableCell>
												<TableCell align="left">
													{logs.updatedDate
														? fDateTime(logs?.updatedDate)
														: "-"}
												</TableCell>
												<TableCell align="left">
													<Chip
														size="small"
														label={logs?.candidateStatus?.name || "-"}
													/>
												</TableCell>
												<TableCell align="left">
													<Chip
														size="small"
														label={logs?.candidateState?.name || "-"}
													/>
												</TableCell>
												<TableCell align="left">{logs?.note || "-"}</TableCell>
											</TableRow>
										);
									})
								) : (
									<TableRow>
										<TableCell colSpan={8} align="center">
											No Record(s) Found
										</TableCell>
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Card> */}

        <Typography className="information-card-heading Employee_Detail-h6" variant="h6" my={2}>
          Activity Logs
        </Typography>
        <Card className="custom-inner-grid gray-box Employee_Detail_Content" sx={{ marginBottom: "40px" }}>
          <Timeline position="right">
            {candidateData.candidateactivitylogs?.length > 0 ? (
              candidateData.candidateactivitylogs.map((logs) => {
                return (
                  <TimelineItem sx={{ maxWidth: "100%" }}>
                    <TimelineOppositeContent
                      sx={{ m: "auto 0" }}
                      style={{ maxWidth: "160px" }}
                      align="right"
                      variant="body2"
                      color="text.secondary"
                    >
                      <Typography
                        style={{
                          fontSize: "14px",
                          fontWeight: "700",
                          color: "#000",
                        }}
                      >
                        {logs.updatedDate ? fDateTime(logs?.updatedDate) : "-"}
                      </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="primary">
                        <AccountCircleIcon />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Approached By :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}
                        >
                          {logs?.user
                            ? `${logs?.user?.firstName} ${logs?.user?.lastName}`
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Status :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}
                        >
                          {logs?.candidateStatus?.name || "-"}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          State :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}
                        >
                          {logs?.candidateState?.name || "-"}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}
                        >
                          Note :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}
                        >
                          {logs?.note || "-"}
                        </Typography>
                      </Stack>
                    </TimelineContent>
                  </TimelineItem>
                );
              })
            ) : (
              <TableRow>
                <TableCell className="No-Record-text" colSpan={12} align="center">
                  No Record(s) Found
                </TableCell>
              </TableRow>
            )}
            {/* <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          10:00 am
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary">
            <LaptopMacIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Code
          </Typography>
          <Typography>Because it&apos;s awesome!</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          <TimelineDot color="primary" variant="outlined">
            <HotelIcon />
          </TimelineDot>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Sleep
          </Typography>
          <Typography>Because you need rest</Typography>
        </TimelineContent>
      </TimelineItem>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
          <TimelineDot color="secondary">
            <RepeatIcon />
          </TimelineDot>
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Repeat
          </Typography>
          <Typography>Because this is the life you love!</Typography>
        </TimelineContent>
      </TimelineItem> */}
          </Timeline>
        </Card>
        {/* status section */}
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack spacing={2} sx={style}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Joining/Probation Date"
              value={joiningDate}
              inputFormat="dd/MM/yyyy"
              // minDate={new Date('2017-01-01')}
              // onChange={(newValue) => {
              //   setFieldValue("workDetails.joiningDate", newValue);
              // }}
              minDate={new Date()}
              maxDate={
                new Date(
                  moment(new Date()).add(3, "months").format("YYYY,MM,DD")
                )
              }
              onChange={(newValue) => handleJoinAndProductionDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{
                    root: classes.root,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    disabled: true, // Add the disabled attribute to the input element
                  }}
                  // error={Boolean(
                  // 	touched?.workDetails &&
                  // 		touched.workDetails?.joiningDate &&
                  // 		errors?.workDetails &&
                  // 		errors.workDetails?.joiningDate,
                  // )}
                  // helperText={
                  // 	touched?.workDetails &&
                  // 	touched.workDetails?.joiningDate &&
                  // 	errors?.workDetails &&
                  // 	errors.workDetails.joiningDate
                  // }
                  fullWidth
                  size="small"
                />
              )}
            />
          </LocalizationProvider>

          {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
							<DesktopDatePicker
								label="Production Date"
								value={productionDate}
								inputFormat="dd/MM/yyyy"
								// minDate={new Date('2017-01-01')}
								onChange={(newValue) => {
									setProductionDate(newValue);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										size="small"
										// error={Boolean(
										// 	touched?.workDetails &&
										// 		touched.workDetails?.productionDate &&
										// 		errors?.workDetails &&
										// 		errors.workDetails?.productionDate,
										// )}
										// helperText={
										// 	touched?.workDetails &&
										// 	touched.workDetails?.productionDate &&
										// 	errors?.workDetails &&
										// 	errors.workDetails.productionDate
										// }
									/>
								)}
							/>
						</LocalizationProvider> */}
          <Stack
            sx={{ display: "flex", flexDirection: "row", gap: 2 }}
            justifyContent="flex-end"
            alignItems="center"
          >
            {/* <Button variant="contained" onClick={() => convertToEmployeefun()}>
              Save
            </Button> */}
            <LoadingButton
              loading={lodar}
              onClick={() => convertToEmployeefun()}
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}

const componentConfig = {
  component: ViewCandidate,
  path: "/candidate/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "candidate",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
