import { createAsyncThunk } from "@reduxjs/toolkit";
import { Axios } from "../../../service/axios";
import { toast } from "react-toastify";

export const getFolders = createAsyncThunk(
    "notebook/getFolders",
    async (params, { rejectWithValue }) => {
        try {
          const res = await  Axios.get("/folders", {
                // params,
                headers:{
                    type:'view',module:'folder'
                },
            })
            // toast.success(res.data.message);
            return {
                data: res.data.data,
            };
        
        } catch (error) {
            // toast.error(error?.response?.data?.message || error.message); 
			return rejectWithValue(error.response.data.message || error.message);

        }
    }
)

export const createFolder = createAsyncThunk(
    "notebook/createFolder",
    async (body, { rejectWithValue })=>{
        try{
            const res = await Axios.post(
                "/folder/create", body,{
                    headers:{
                        type:'create',module:'folder'
                    }
                }
            )
            toast.success(res.data.message);
            return res.data;
        }catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)

export const editFolderName = createAsyncThunk(
    "notebook/editFolderName",
    async ({id,body}, { rejectWithValue })=>{
        try{
            const res = await Axios.put(`/folder/update`,body,{
                headers:{
                    id:id,
                    type:'update',
                    module:'folder'
                }
            })
            toast.success(res.data.message);
            return res.data;
        }catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)

export const deleteFolder = createAsyncThunk(
    "notebook/deleteFolder",
    async({id}, { rejectWithValue })=>{
        try{
            const res = await Axios.delete(`/folder/delete`,{
                headers:{
                    id:id,
                    type:'delete',
                    module:'folder'
                }
            })
            toast.success(res.data.message);
            return res.data;
        }
        catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)

export const getFolderById = createAsyncThunk(
    "notebook/getFolderById",
    async (id, { rejectWithValue })=>{
        try{
            const res = await Axios.get(`/folderById`,{
                headers:{
                    id:id,
                    type:'view',
                    module:'file'
                }
            })
            return res.data;
        }
        catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)
export const createFile = createAsyncThunk(
    "notebook/createFile",
    async (body, { rejectWithValue })=>{
        try{
            const res = await Axios.post(
                "/file/create", body,{
                    headers:{
                        type:'create',module:'file'
                    }
                }
            )
            toast.success(res.data.message);
            return res.data;
        }catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)

export const updateFile = createAsyncThunk(
    "notebook/updateFile",
    async ({id,body}, { rejectWithValue })=>{
        try{
            const res = await Axios.put(`/file/update`,body,{
                headers:{
                    id:id,
                    type:'update',
                    module:'file'
                }
            })
            toast.success(res.data.message);
            return res.data;
        }catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)
export const deleteFile = createAsyncThunk(
    "notebook/deleteFile",
    async(id, { rejectWithValue })=>{
        try{
            const res = await Axios.delete(`/file/delete`,{
                headers:{
                    id:id,
                    type:'delete',
                    module:'file'
                }
            })
            toast.success(res.data.message);
            return res.data;
        }
        catch(error){
            return rejectWithValue(error?.response?.data || error.message);
        }
    }
)
