import React, { useRef, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Grid from "@mui/material/Grid";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import TextField from "@mui/material/TextField";
const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const AddCertificateModal = NiceModal.create(({ handleAddCertificate }) => {
	const modal = useModal();

	const bachelorCertificatesFileInput = useRef(null);
	const [customFileName, setCustomFileName] = useState(null);

	const addCertificate = async (file) => {
		const fileName = file.name;
		const ext = fileName.split(".").pop();
		let NewFileName = `${customFileName}.${ext}`;
		NewFileName = NewFileName.trim();
		handleAddCertificate(NewFileName, file);

		modal.hide();
	};

	return (
		<Dialog
			TransitionComponent={Transition}
			open={modal.visible}
			onClose={() => modal.hide()}
			TransitionProps={{
				onExited: () => modal.remove(),
			}}
			fullWidth
		>
			<DialogTitle>Add Certificate</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item xs={12} mt={1} mb={1}>
						<TextField
							fullWidth
							value={customFileName}
							autoFocus
							id="fileName"
							// margin="dense"
							size="small"
							label="Certificate Name"
							variant="outlined"
							onChange={(e) => {
								setCustomFileName(e.target.value);
							}}
						/>
					</Grid>
					<div
						className="custome-uploadBox small profile-image-part"
						onClick={() => {
							// NiceModal.show(AddCertificateModel);
							bachelorCertificatesFileInput.current.click();
						}}
					>
						<div className="p-image">
							<i className="upload-button">
								<AddCircleIcon />
							</i>
							<input
								ref={bachelorCertificatesFileInput}
								className="file-upload"
								type="file"
								accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
								onChange={(e) => {
									addCertificate(e.target.files[0]);
								}}
							/>
						</div>
					</div>
				</Grid>
			</DialogContent>
		</Dialog>
	);
});

export default AddCertificateModal;
