import React from "react";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { debounce } from "lodash";
import { useConfirm } from "material-ui-confirm";

import DashboardLayout from "../../layouts/dashboard";
import Scrollbar from "../../components/Scrollbar";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import TablePagination from "@mui/material/TablePagination";
import TableSortLabel from "@mui/material/TableSortLabel";
import Link from "@mui/material/Link";
import LanguageIcon from "@mui/icons-material/Language";
import NiceModal from "@ebay/nice-modal-react";
import CustomPagination from "../../components/Pagination";
import Loader from "../../components/Loader";
import Chip from "@mui/material/Chip";
import {
  getCollege,
  deleteCollegeById,
  getCollegeCount,
} from "../../redux/college/collegeThunk";
import { setLimit, setCurrentPage } from "../../redux/college/collegeSlice";
import { encryption } from "../../utils/encodeString";
import invertDirection from "../../utils/invertDirection";
import { setOrderBy, setSortBy } from "../../redux/college/collegeSlice";
import CollegeDetailsModal from "./CollegeComponents/CollegeDetailsModal";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import { useFormik } from "formik";
import { FormControlLabel, Switch } from "@mui/material";

function College() {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [filterData, setFilterData] = useState(null);
  const [filterChips, setFilterChips] = useState([]);

  const formik = useFormik({
    initialValues: {
      isCollegeDrive: true,
    }
  });
  const {
    handleSubmit,
    setValues,
    values,
    handleChange,
    isSubmitting,
    setFieldValue,
    getFieldProps,
  } = formik;
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };

  const prepareChips = (searchParams) => {
    let filters = { ...searchParams };
    let tempChips = [];

    for (const key in filters) {
      if (filters[key] && key === "search") {
        filters[key] = `${searchValue}`;
      }
      if ( filters[key] && key === "isCollegeDrive") {
        filters[key] = `Campus Placement Drive: ${filters[key] ? "Yes" : "No"}`;
      }
	  if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

	
    setFilterChips(tempChips);
  };
  const handleIsCollageChange = (e, newValue) => {
    setFieldValue("isCollegeDrive", Boolean(newValue));

    dispatch(
      getCollege({
        page: currentPage + 1,
        limit: limit,
        sortBy,
        orderBy,
        search: searchValue,
        isCollegeDrive: Boolean(newValue),
      })
    );
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  //const isSearchQuery = useSelector((state) => state.college.isSearchQuery);

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("college")) {
		
      dispatch(
        getCollege({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: searchValue,
          isCollegeDrive: values?.isCollegeDrive,
        })
      );
      navigate("/dashboard");
    }
  }, []);


  useEffect(() => {
    let searchParams = {};

    searchParams.search = searchValue;
    searchParams.isCollegeDrive = values?.isCollegeDrive;
 
    setFilterData({ ...searchParams });
    prepareChips({ ...searchParams });
  },[searchValue,values?.isCollegeDrive])

  const {
    data,
    currentPage,
    status,
    limit,
    totalRecords,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
  } = useSelector((state) => state.college);

  //const data = useSelector((state) => state.college.data);
  // useEffect(() => {
  // 	dispatch(
  // 		getCollege({
  // 			page: currentPage + 1,
  // 			limit: limit,
  // 			sortBy,
  // 			orderBy,
  // 			search: "",
  // 		}),
  // 	);
  // }, []);
  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      getCollege({
        page: currentPage + 1,
        limit: limit,
        sortBy,
        orderBy,
        search: searchValue,
        isCollegeDrive: values?.isCollegeDrive,
      })
    );
    // }
  }, [dispatch, limit, status, currentPage, sortBy, orderBy]);

  const collegeCount = useSelector((state) => state.getCollegeCount.totalCount);

  const handleDelete = async (id, name) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${name}?`,
      });
      await dispatch(deleteCollegeById(id)).unwrap();
      dispatch(
        getCollege({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleInputChange = async (e) => {
    setIsSearching(true);
    dispatch(setCurrentPage({ page: 0 }));
    setSearchValue(e?.target?.value);
    if (e.target.value != "") {
      dispatch(
        getCollege({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: e.target.value,
          isCollegeDrive: values?.isCollegeDrive,
        })
      );
    } else {
      setIsSearching(false);
      dispatch(getCollege({ page: currentPage + 1, limit, sortBy, orderBy }));
    }
    setSearchValue(e?.target?.value);
  };

  const handleInputChangeByDebounce = debounce(handleInputChange, 300);
  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const onDeleteChip = (value, length) => {
    let { type } = value;
    delete filterData[value.type];
    setFilterData({ ...filterData });
    setFieldValue(type, "");
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    setFilterChips([...updateChips]);
    dispatch(setCurrentPage({ page: 0 }));
    if (value.type === "search") {
      // setSearchValue("");
      inputRef.current.value = "";
    }
    handleSubmit();
  };

  const showDetailsModal = async (id) => {
    try {
      await NiceModal.show(CollegeDetailsModal, { id: id });
    } catch (error) {}
  };

  useEffect(() => {
    if (!error) {
      dispatch(getCollegeCount({}));
    }
  }, [error, dispatch]);

  console.log("filterChips", filterChips)

  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            College
            <Typography variant="body2">Total Records : {collegeCount}</Typography>
          </Typography>
          {getAddVisible("college") && (
            <Button variant="contained" component={RouterLink} to="/college/add" startIcon={<AddIcon />}>
              Add College
            </Button>
          )}
        </div>
      </Stack>
      <Card className="holiday-table-grid" mb={5}>
        <Container maxWidth="xl">
          <Grid container spacing={3} py={3} style={{ paddingBottom: "17px", paddingTop: "17px" }}>
            <Grid item xs={10}>
              <TextField
                size="small"
                autoComplete="off"
                fullWidth
                label="Search by Name/Location"
                onChange={handleInputChangeByDebounce}
                inputRef={inputRef}
              />
            </Grid>

            <Grid item xs={2}>
              <FormControlLabel
                control={
                  <Switch
                    name="retentionBonus"
                    inputProps={{ "data-cy": "chk-hasMany-item" }}
                    size="small"
                    checked={values?.isCollegeDrive}
                    onChange={handleIsCollageChange}
                  />
                }
                label="Campus Placement Drive"
              />
            </Grid>
          </Grid>
        </Container>
      </Card>
      <Stack style={{ marginBottom: "5px", marginTop: "10px" }} direction="row" alignItems="start">
        {filterChips?.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>

      {filterChips.length > 0 ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid" style={{ marginTop: "10px" }}>
        <CustomPagination
          totalPage={totalPages}
          totalCount={totalRecords}
          limit={limit}
          handleChangePage={handleChangePage}
          page={currentPage}
          rowsPerPageOptions={[10, 20, 40]}
          handleRowsPerPageChange={handleChangeRowsPerPage}
          // filter={isSearchQuery ? true : false}
        />
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left" width="20%">
                    <TableSortLabel
                      active={sortBy === "collegeName"}
                      direction={sortBy === "collegeName" ? orderBy : "asc"}
                      onClick={() => handleSorting("collegeName")}
                    >
                      College Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left" width="10%">
                    <TableSortLabel
                      active={sortBy === "city"}
                      direction={sortBy === "city" ? orderBy : "asc"}
                      onClick={() => handleSorting("city")}
                    >
                      Location
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left" width="10%">
                    Contact Person
                  </TableCell>
                  <TableCell align="left" width="25%">
                    Email
                  </TableCell>
                  <TableCell align="left" width="15%">
                    Contact Number
                  </TableCell>
                  <TableCell align="left" width="10%">
                    Website
                  </TableCell>

                  <TableCell align="left" width="10%">
                    Campus Placement Drive
                  </TableCell>
                  {(getEditVisible("college") || getDeleteVisible("college")) && (
                    <TableCell align="center" width="10%">
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>

              {loading ? (
                <TableCell align="center" width="100%" colSpan={7} p={4}>
                  <Loader />
                </TableCell>
              ) : (
                <TableBody>
                  {Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={4}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((college, idx) => {
                      let primaryContact = college?.contact?.find((val) => val.isPrimaryContact);
                      let contactToDisplay = primaryContact || college?.contact?.[0];
                      return (
                        <>
                          <TableRow key={idx}>
                            <TableCell
                              onClick={() => showDetailsModal(college.id)}
                              align="left"
                              width="20%"
                              style={{
                                cursor: "pointer",
                                overflowWrap: "anywhere",
                              }}
                            >
                              {college.collegeName}
                            </TableCell>
                            <TableCell align="left" width="10%">
                              {college.city}
                            </TableCell>
                            {college?.contact?.length===0 && 
                            <>
                                
                                <TableCell align="left" width="10%" style={{ overflowWrap: "anywhere" }}>
                                  -
                                </TableCell>
                                <TableCell align="left" width="25%">
                                 -
                                </TableCell>
                                <TableCell align="left" width="15%">
                                  -
                                </TableCell>
                              
                            </>}
                            {contactToDisplay && (
                              <>
                                <TableCell align="left" width="10%" style={{ overflowWrap: "anywhere" }}>
                                  {contactToDisplay.name}
                                </TableCell>
                                <TableCell align="left" width="25%">
                                  {contactToDisplay.email}
                                </TableCell>
                                <TableCell align="left" width="15%">
                                  {contactToDisplay.contactNumber}
                                </TableCell>
                              </>
                            )}
                            <TableCell align="center" width="10%">
                              <Link target="_blank" href={college?.website}>
                                <LanguageIcon color="primary" />
                              </Link>
                            </TableCell>

                            <TableCell align="center" width="10%">
                              {college?.isCollegeDrive ? "Yes" : "No"}
                            </TableCell>
                            {(getEditVisible("college") || getDeleteVisible("college")) && (
                              <TableCell size="medium" align="left">
                                <div
                                  className="action-button"
                                  style={{
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {getEditVisible("college") && (
                                    <span>
                                      <IconButton
                                        color="primary"
                                        component={RouterLink}
                                        to={`/college/add/${encryption(college.id)}`}
                                      >
                                        <EditIcon />
                                      </IconButton>
                                    </span>
                                  )}
                                  {getDeleteVisible("college") && (
                                    <span>
                                      <IconButton onClick={() => handleDelete(college.id, college.collegeName)}>
                                        <DeleteIcon color="error" />
                                      </IconButton>
                                    </span>
                                  )}
                                </div>
                              </TableCell>
                            )}
                          </TableRow>
                        </>
                      );
                    })
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: College,
  path: "/college",
  public: false,
  group: "tpa",
  layout: DashboardLayout,
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
