import React, { useEffect, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
  TextField,
} from "@mui/material";
import DashboardLayout from "../../../layouts/dashboard";
import Editor from "./Editor";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { decryption, encryption } from "../../../utils/encodeString";
import {
  createFile,
  deleteFile,
  getFolderById,
  updateFile,
} from "../../../redux/notebook/notebookThunk";
import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Cancel as CancelIcon,
} from "@mui/icons-material";
import { useConfirm } from "material-ui-confirm";
import { getDeleteVisible, getEditVisible, getViewVisible,getAddVisible } from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import localStorage from "../../../service/localStorage";

const ViewFolder = () => {
  const encryptedId = useParams().id;
  const decryptedId = decryption(encryptedId);
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [fileName, setFileName] = useState("");
  const [isEdit, setIsEdit] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isAddPage, setIsAddPage] = useState(false);
  const { data, loading } = useSelector((state) => state.notebook);
  const pages = data?.files || [];
  const [isContentChanged, setIsContentChanged] = useState(false);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      localStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  useEffect(() => {
    if (!getViewVisible("file")) {
      navigate("/dashboard");
    }
  }, []);
  const handlePageSelect = (page) => {
    setSelectedPage(page);
  };
  const handleAddPage = () => {
    setIsAddPage(true);
    setSelectedPage(null);
  };
  const handleInputChange = (event) => {
    setFileName(event.target.value);
    if (event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleKeyDown = (event, operation) => {
    if (event.key === "Enter") {
      if (fileName.trim() === "") {
        setError(true);
      } else {
        if (operation === "create") {
          try {
            dispatch(
              createFile({ folderId: data?.id, fileName: fileName })
            ).then(() => {
              getFolder(data?.id);
            });
            setIsAddPage(false);
            setFileName("");
          } catch (error) {
            console.log(
              "%c  error:",
              "color: #0e93e0;background: #aaefe5;",
              error
            );
          }
        } else {
          const body = {
            fileName: fileName,
          };
          const id = isEdit;
          dispatch(updateFile({ id, body })).then(() => {
            setIsEdit(null);
            setFileName("");
            getFolder(data?.id);
          });
        }
      }
    }
  };

  const getFolder = async (id) => {
    console.log(id);
    try {
      await dispatch(getFolderById(id))
        .unwrap()
        .then((res) => {
          setSelectedPage(res?.data?.files?.[0]);
        });
    } catch (error) {
      console.log("%c  error:", "color: #0e93e0;background: #aaefe5;", error);
    }
  };
  const handleDelete = (e, page) => {
    try {
      confirm({
        description: `Are you sure you want to delete ${page?.fileName}?`,
      }).then(() => {
        dispatch(deleteFile(page?.id)).then(() => {
          getFolder(data?.id);
          setSelectedPage(pages[0]);
        });
      });
    } catch (error) {
      console.log("%c  error:", "color: #0e93e0;background: #aaefe5;", error);
    }
  };
  const handleEdit = (e, page) => {
    setFileName(page?.fileName);
    setIsEdit(page?.id);
  };
  const handleCancel = () => {
    setIsEdit(null);
    setFileName("");
  };
  const handleSave = (content) => {
    const body = {
      fileContent: content,
    };
    const id = selectedPage?.id;
    dispatch(updateFile({ id, body })).then(() => {
      getFolder(data?.id);
    });
  };
  useEffect(() => {
    getFolder(decryptedId);
    setSelectedPage(pages[0]);
  }, []);

  return (
    <Box className="newfolder-wrapper" sx={{ display: "flex", height: "100vh", flexDirection: "column" }}>
      <div className={!data?.isSystemDefault && pages?.length > 0? "newfolder-Default": "no-edit-button"}>
        <Stack
          // className="newfolder-Stack"
          className={isContentChanged? "newfolder-Stack" :'hide-discard-button'}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          margin={2}
        >
          <Typography variant="h6">{data?.folderName || ""}</Typography>
          <Button
          className="back_button"
            variant="contained"
            component={RouterLink}
            to="/notebook"
            startIcon={<ArrowBackIcon />}
            sx={{ marginLeft: 2 }}
          >
            Back
          </Button>
        </Stack>
      </div>
      <Box className="newfolder-content" sx={{ display: "flex", flexGrow: 1 }}>
        <Box
        className="newfolder-sidebar"
          sx={{
            width: 240,
            backgroundColor: "rgb(244, 245, 247)",
            border: "1px solid #ddd",
            overflowY: "auto",
          }}
        >
          <Box className="newfolder-sidebarbottom" sx={{ display: "flex", justifyContent: "space-between" }}>
            {/* <Typography variant="h6" sx={{ padding: 2 }}>
              Files
            </Typography> */}
            {getAddVisible('file') && !data?.isSystemDefault && (
              <IconButton onClick={handleAddPage}>
                <AddIcon /> Add Page
              </IconButton>
            )}
          </Box>
          <Divider />
          <List className="newfolder-list">
            {isAddPage && (
              <ListItem
                className="newfolder-listitem"
                button
                key={selectedPage?.id}
                onClick={() => handlePageSelect(selectedPage)}
                selected={true}
                sx={{
                  border: true ? "1px solid #1976d2" : "none",
                  marginBottom: 1,
                  borderRadius: 1,
                }}
              >
                <TextField
                  id="standard-basic"
                  label="File Name"
                  variant="standard"
                  value={fileName}
                  onChange={handleInputChange}
                  onKeyDown={(e) => handleKeyDown(e, "create")}
                  error={error}
                  helperText={error ? "File name is required" : ""}
                  autoComplete="off"
                />
                <IconButton
                  onClick={() => {
                    setIsAddPage(false);
                    setFileName("");
                  }}
                >
                  <CancelIcon />
                </IconButton>
              </ListItem>
            )}
            {pages &&
              pages?.length > 0 &&
              Array.isArray(pages) &&
              pages?.map((page) => (
                <ListItem
                  className="newfolder-listitem"
                  button
                  key={page?.id}
                  onClick={() => handlePageSelect(page)}
                  selected={selectedPage?.id === page?.id}
                  sx={{
                    border:
                      selectedPage?.id === page?.id
                        ? "1px solid #1976d2"
                        : "none",
                    marginBottom: 1,
                    borderRadius: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {isEdit === page?.id ? (
                    <Box display="flex">
                      <TextField
                        id="standard-basic"
                        label="Name"
                        variant="standard"
                        value={fileName || ""}
                        onChange={handleInputChange}
                        onKeyDown={(e) => handleKeyDown(e, "edit")}
                        error={error}
                        helperText={error ? "File name is required" : ""}
                        autoComplete="off"
                      />
                      <IconButton onClick={handleCancel}>
                        <CancelIcon />
                      </IconButton>
                    </Box>
                  ) : (
                    <>
                      <ListItemText primary={page?.fileName} />
                      {(getEditVisible("file") || getDeleteVisible("file")) && !data?.isSystemDefault && (
                        <Box>
                          {getEditVisible("file") && 
                          <IconButton onClick={(e) => handleEdit(e, page)}>
                            <EditIcon />
                          </IconButton>}
                          {getDeleteVisible("file") &&
                          <IconButton onClick={(e) => handleDelete(e, page)}>
                            <DeleteIcon />
                          </IconButton>}
                        </Box>
                      )}
                    </>
                  )}
                </ListItem>
              ))}
          </List>
        </Box>
        <Box className="newfolder-editor" component="main" sx={{ flexGrow: 1 }}>
          {pages?.length > 0 ? (
            
            <Editor
              editorContent={selectedPage?.fileContent}
              handleSave={handleSave}
              isSystemDefault={data?.isSystemDefault}
              isContentChanged = {isContentChanged}
              setIsContentChanged={setIsContentChanged}
            />
          ) : (
            <Typography variant="body1" sx={{ padding: 2 }}>
              Please create a new file
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

const componentConfig = {
  component: ViewFolder,
  path: "/folder/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "notebook",
  role: ["admin"],
};

export default componentConfig;
