import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import DashboardLayout from "../../layouts/dashboard";
import Card from "@mui/material/Card";

import ActivityStatus from "./MasterComponents/ActivityStatus";
import BloodGroup from "./MasterComponents/BloodGroup";
import Course from "./MasterComponents/Course";
import Designation from "./MasterComponents/Designation";
import Education from "./MasterComponents/Education";
import InterviewStatus from "./MasterComponents/InterviewStatus";
import InterviewType from "./MasterComponents/InterviewType";
import JobChangeReason from "./MasterComponents/JobChangeReason";
import TechnologyMaster from "./MasterComponents/TechnologyMaster";
import ExternalDesignation from "./MasterComponents/ExternalDesignation";
import CandidateState from "./MasterComponents/CandidateState";
import Team from "./MasterComponents/Team";
import Skill from "./MasterComponents/Skill";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TabContext from "@mui/lab/TabContext";
import Grade from "./MasterComponents/Grade";
import "./custom-organization.css";
import StudentStatus from "./MasterComponents/StudentStatus";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { toast } from "react-toastify";

function Master() {
	const navigate = useNavigate();
	const [value, setValue] = React.useState(0);

	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("master")) {
			toast.error('User is not authorized for this operation')
			navigate("/dashboard");
		}
	}, []);

	const handleChange = (event, newValue) => {

		setValue(newValue);
	};

	function TabPanel(TabPanelProps) {
		const { children, value, index, ...other } = TabPanelProps;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`full-width-tabpanel-${index}`}
				aria-labelledby={`full-width-tab-${index}`}
				{...other}
			>
				{value === index && (
					<Box>
						<Typography>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	function a11yProps(index) {
		return {
			id: `vertical-tab-${index}`,
			"aria-controls": `vertical-tabpanel-${index}`,
		};
	}

	return (
		<Container maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				mb={3}
			>
			      <div className="title-button-wrapper">
				<Typography variant="h4" gutterBottom>
					Master
				</Typography>
				</div>
			</Stack>
			<Card sx={{ maxWidth: { xs: 500, sm: 900 }, minWidth: "100%" }}>
				{/* <Card style={{minWidth: "100%"}}> */}
				<Card style={{ minWidth: "100%", width: "100%" }}>
					<TabContext value={value}>
						<Box sx={{ bgcolor: "#fff" }}>
							<Tabs
								value={value}
								onChange={handleChange}
								variant="scrollable"
								scrollButtons="auto"
								aria-label="scrollable auto tabs example"
								className="master-accordian-tabs"
							>
								<Tab label="Activity" {...a11yProps(0)} />
								<Tab label="Bloodgroup" {...a11yProps(1)} />
								<Tab label="Course" {...a11yProps(2)} />
								<Tab label="Designation" {...a11yProps(3)} />
								<Tab label="Education" {...a11yProps(4)} />
								<Tab label="Interview Type" {...a11yProps(5)} />
								<Tab label="Job Change Reason" {...a11yProps(6)} />
								<Tab label="Technology" {...a11yProps(7)} />
								<Tab label="External Designation" {...a11yProps(8)} />
								<Tab label="Candidate State" {...a11yProps(9)} />
								<Tab label="Skill" {...a11yProps(10)} />
								<Tab label="Student Status" {...a11yProps(11)} />
								<Tab label="Team" {...a11yProps(12)} />
								<Tab label="Grade" {...a11yProps(13)} />
							</Tabs>
						</Box>
						<TabPanel value={value} index={0}>
							<ActivityStatus />
						</TabPanel>
						<TabPanel value={value} index={1}>
							<BloodGroup />
						</TabPanel>
						<TabPanel value={value} index={2}>
							<Course />
						</TabPanel>
						<TabPanel value={value} index={3}>
							<Designation />
						</TabPanel>
						<TabPanel value={value} index={4}>
							<Education />
						</TabPanel>
						<TabPanel value={value} index={5}>
							<InterviewType />
						</TabPanel>
						<TabPanel value={value} index={6}>
							<JobChangeReason />
						</TabPanel>
						<TabPanel value={value} index={7}>
							<TechnologyMaster />
						</TabPanel>
						<TabPanel value={value} index={8}>
							<ExternalDesignation />
						</TabPanel>
						<TabPanel value={value} index={9}>
							<CandidateState />
						</TabPanel>
						<TabPanel value={value} index={10}>
							<Skill />
						</TabPanel>
						<TabPanel value={value} index={11}>
							<StudentStatus />
						</TabPanel>
						<TabPanel value={value} index={12}>
							<Team />
						</TabPanel>
						<TabPanel value={value} index={13}>
							<Grade />
						</TabPanel>
					</TabContext>
				</Card>
			</Card>
		</Container>
	);
}

const componentConfig = {
	component: Master,
	path: "/master",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
