import * as Yup from "yup";
const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/png",
  "application/pdf",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/msword",
  "image/bmp",
];

const itemGroupSchema = Yup.object().shape({
  // itemsMap: [
  //     {
  //       item: "",
  //       attributes: [],
  //     },
  //   ]

  itemsMap: Yup.array().of(
    Yup.object().shape({
      item: Yup.string().required("Select Item"),
      manufacturerId: Yup.number().required("Manufacturer Required"),
      vendorId: Yup.number().required("Vendor Required"),

      serialNumber: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().max(256, "Serial number is too long"),
        })
      ),
      attributes: Yup.array().of(
        Yup.object().shape({
          value: Yup.string().required("This Field Required"),
        })
      ),
    })
  ),

  purchaseDate: Yup.date()
    .required("Purchase Date Required")
    .typeError("Purchase Date Required"),

  invoice: Yup.array().of(
    Yup.mixed().test(
      "fileFormat",
      "You can upload png, jpg, jpeg, pdf, doc, docx, bmp files  only",
      (value) => {
        if (typeof value != "string") {
          if (SUPPORTED_FORMATS.includes(value.type)) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      }
    )
  ),
});

export default itemGroupSchema;
