import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import DashboardLayout from "../layouts/dashboard";

import InterviewList from "./InterviewList";
import NewsFeed from "./NewsFeed";
import Calendar from "./Calendar";
import {
	getDashboard,
	getNotification,
	getLincenceNotification,
	getExitCheckListNotification,
	getVmExpiryNotification
} from "../redux/dashboard/dashboardThunk";

import {
	Employees,
	EmployeeJoining,
	EmployeeExiting,
	Leads,
} from "../components/_dashboard/app";
import Loader from "../components/Loader";
import { currentMonth } from "../utils/currentMonth";
import LocalStorage from "../service/localStorage";
import "./Dashboard.css";
import { encryption } from "../utils/encodeString";
import { getViewVisible, getEditVisible } from "../utils/userPermission";
import { getUserPermissionByRoll } from "../redux/userPermission/userPermissionthunk";
import { Box } from "@mui/system";
import { Stack } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import { checkListReducer } from "../redux/checkList/checkListSlice";
import { filter } from "lodash";

function DashboardApp() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const userName = useSelector((state) => state.auth.fullName);
	const AuthRole = useSelector((state) => state.auth.role)
	const data = useSelector((state) => state.dashboard.data);
	const loading = useSelector((state) => state.dashboard.loading);
	const error = useSelector((state) => state.dashboard.error);
	const notification = useSelector((state) => state.dashboard.notification);
	const lincenceNotification = useSelector((state) => state.dashboard.lincenceNotification);
	const checklistNotification = useSelector((state) => state.dashboard.exitChecklist);
	const vmNotification = useSelector((state) => state.dashboard.vmNotification);
	const [flag, setFlag] = useState(false);
	const [pendingChecklist, setPendingChecklist] = useState([]); 
	const [completeChecklist, setCompleteChecklist] = useState([]);
	/* const Permission = useSelector((state) => {
		console.log(state.auth.userPermissions);
	}); */

	(function () {
		if (window.localStorage) {
			if (
				!localStorage.getItem("sidebarSet") &&
				localStorage.getItem("userPermissions")
			) {
				localStorage["sidebarSet"] = true;
				window.location.reload();
			}
		}
	})();

	useEffect(() => {
		dispatch(getDashboard({ date: currentMonth() }));
		dispatch(getNotification({}));
		dispatch(getVmExpiryNotification({}));
		if(getViewVisible('vmMaster')){
		dispatch(getLincenceNotification({}));
		}
		dispatch(getExitCheckListNotification({}));
	}, []);

	useEffect(() => {
		const filteredDataPending = checklistNotification?.filter((value, index, self) => 
			self.findIndex(v => v?.employeeId === value?.employeeId && v.status == "Pending") === index
		);
		const filteredDataComplete = checklistNotification?.filter((value, index, self) => 
			self.findIndex(v => v?.employeeId === value?.employeeId && v.status == "Complete") === index
		);

		var result = filteredDataComplete?.filter(function(o1){
			// filter out (!) items in result2
			return !filteredDataPending?.some(function(o2){
					return o1.employeeId === o2.employeeId;         
			});
		}) 
		setCompleteChecklist(result)
		setPendingChecklist(filteredDataPending)
		pendingChecklist?.map((chk) => {
			if(chk.status == "Pending" && userName == chk?.assigneeName)
				{
					setFlag(true);
				}
			}
		)
	}, [checklistNotification,data]);

	if (loading) {
		return <Loader />;
	}

	  const newNotification = (notification)=>{
		const newArray = notification.reduce((acc, obj) => {
			const hrIndex = acc.findIndex((item) => item.hrName === obj.hrName);
			const employee = { id: obj.id, candidateName: obj.candidateName };
			if (hrIndex !== -1) {
			  acc[hrIndex].employe.push(employee);
			} else {
			  acc.push({ hrName: obj.hrName, employe: [employee] });
			}
			return acc;
		  }, []);
		  return newArray;
	  }

		return (
		<div className="view_employee view_asset employee dashboard-main">
			<Container maxWidth="xl">
				{notification?.notUpdatedInterview?.length > 0 && notification?.notUpdatedInterview[0]?.isAdmin==true ?
				<Stack className="view_employee_main" style={{gap:'10px' }} mb={1}>
					{newNotification(notification?.notUpdatedInterview).length>0 &&
					(
						newNotification(notification?.notUpdatedInterview).map((hr, Idx) => (
						<Grid className="view_employee-status" container spacing={2}>
						<Grid className="view_employee-status-detail" item xs={12}>
						{/* {console.log(hr,"pd")} */}
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<strong>{hr.hrName}</strong>{" "}
								<p>please update interview status of</p>{" "}
								<strong>
									{hr.employe.map((candidate, Idx) => (
										<Link
											onClick={() => {
												navigate(`/candidate/add/${encryption(candidate.id)}`, {
													state: { tabid: "4" },
												});
											}}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{candidate.candidateName}
											{notification.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
							</div>
						</Grid>
					</Grid>
					)
				))}</Stack>:(notification.notUpdatedInterview?.length > 0 && <Grid container spacing={2} >
						<Grid item xs={12}>
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<strong>{userName}</strong>{" "}
								<p>please update interview status of</p>{" "}
								<strong>
									{notification?.notUpdatedInterview?.map((candidate, Idx) => (
										<Link
											onClick={() => {
												navigate(`/candidate/add/${encryption(candidate.id)}`, {
													state: { tabid: "4" },
												});
											}}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{candidate.candidateName}
											{notification.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
							</div>
						</Grid>
					</Grid>
				)}
				{notification?.onHoldInterview?.length > 0 && notification?.onHoldInterview[0]?.isAdmin==true?<Stack className="view_employee_main" style={{gap:'10px' }}>{newNotification(notification?.notUpdatedInterview).length>0&&(
				newNotification(notification?.onHoldInterview).map((hr, Idx) => (
						
						<Grid container spacing={2}>
						<Grid item xs={12}>
						{/* {console.log(hr,"pd")} */}
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<strong>{hr.hrName}</strong>{" "}
								<p>has put the interview of</p>{" "}
								<strong>
									{hr.employe.map((candidate, Idx) => (
										<Link
											onClick={() => {
												navigate(`/candidate/add/${encryption(candidate.id)}`, {
													state: { tabid: "4" },
												});
											}}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{candidate.candidateName}
											{notification.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
								{" "}<p>on hold</p>
							</div>
						</Grid>
					</Grid>
					)
					))}</Stack>:(notification.onHoldInterview?.length > 0 && <Grid container spacing={2} >
						<Grid item xs={12} >
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<strong>{userName}</strong>{" "}
								<p>has put the interview of</p>{" "}
								<strong>
									{notification?.onHoldInterview?.map((candidate, Idx) => (
										<Link
											onClick={() => {
												navigate(`/candidate/add/${encryption(candidate.id)}`, {
													state: { tabid: "4" },
												});
											}}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{candidate.candidateName}
											{notification.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
								{" "}<p>on hold</p>
							</div>
						</Grid>
					</Grid>
					)}
				
						{/* {lincenceNotification&&lincenceNotification.licenceExpiryTomorrow&&lincenceNotification.licenceExpiryTomorrow[0]&&<Grid container spacing={2} sx={{mt:'5px'}}><Grid className="pedremove" item xs={12}>
							<div className="warning">
								<img src="/assets/images/warning.svg" />{" "}
		
								<strong>
									{lincenceNotification.licenceExpiryTomorrow.map((product, Idx) => (
										<Link
										onClick={() => {
											navigate(`/licence-master/add/${encryption(product.id)}`);
										}}
											
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{product.productName}
											{lincenceNotification.licenceExpiryTomorrow.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
								<p>licence expire in tomorrow</p>{" "}
							</div>
						</Grid></Grid>} */}
						{lincenceNotification&&lincenceNotification?.licenceExpired&&lincenceNotification?.licenceExpired.map((licence,index)=>(
							<Grid container spacing={2} sx={{mt:'5px'}}>
							<Grid className="pedremove" item xs={12}>
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<p>Licence for</p>{" "}
								<strong>
									
										<Link
										onClick={() => {
											navigate(`/licence-master/add/${encryption(licence?.id)}`);
										}}
										// to={`/licence-master/add/`}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{licence?.productName}
											{/* {lincenceNotification.licenceExpired.length===Idx+1?" ":", "} */}
										</Link>
								</strong>
								{licence?.assignedEmployees?.length > 0 ?<p>issued to</p>:"" }
								
								<strong>
								{licence?.assignedEmployees?.map((user, Idx) => (
									 <strong>{user?.firstName+" "+user?.lastName} 
											{licence?.assignedEmployees?.length===Idx+1?"":","}</strong>
								))}
								</strong>
								<span>has been expired on {moment(licence?.expiryDate).format('DD/MM/YYYY')}</span>
							</div>
						</Grid>
						</Grid>
						))}
						{lincenceNotification&&lincenceNotification?.licenceExpiryTomorrow&&lincenceNotification?.licenceExpiryTomorrow.map((licence,index)=>(
							<Grid container spacing={2} sx={{mt:'5px'}}>
							<Grid className="pedremove" item xs={12}>
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<p>Licence for</p>{" "}
								<strong>
									
										<Link
										onClick={() => {
											navigate(`/licence-master/add/${encryption(licence?.id)}`);
										}}
										// to={`/licence-master/add/`}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{licence?.productName}
											{/* {lincenceNotification.licenceExpiryTomorrow.length===Idx+1?" ":", "} */}
										</Link>
								</strong>
								{licence?.assignedEmployees?.length > 0 ?<p>issued to</p>:"" }
								
								<strong>
								{licence?.assignedEmployees?.map((user, Idx) => (
									 <strong>{user?.firstName+" "+user?.lastName} 
											{licence?.assignedEmployees?.length===Idx+1?"":","}</strong>
								))}
								</strong>
								<span>expires Tomorrow.</span>
							</div>
						</Grid>
						</Grid>
						))}
						{lincenceNotification&&lincenceNotification?.licenceExpiryIn7Days&&lincenceNotification?.licenceExpiryIn7Days.map((licence,index)=>{
							const isExpiringTomorrow = lincenceNotification?.licenceExpiryTomorrow.some((product)=>product.id===licence.id);
							if(!isExpiringTomorrow){
							return(
							<Grid container spacing={2} sx={{mt:'5px'}}>
							<Grid className="pedremove" item xs={12}>
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<p>Licence for</p>{" "}
								<strong>
									
										<Link
										onClick={() => {
											navigate(`/licence-master/add/${encryption(licence?.id)}`);
										}}
										// to={`/licence-master/add/`}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{licence?.productName}
											{/* {lincenceNotification.licenceExpiryIn7Days.length===Idx+1?" ":", "} */}
										</Link>
								</strong>
								{licence?.assignedEmployees?.length > 0 ?<p>issued to</p>:"" }
								
								<strong sx={{marginRight:'0px'}}>
								{licence?.assignedEmployees?.map((user, Idx) => (
									 <strong >{user?.firstName+" "+user?.lastName} 
											{licence?.assignedEmployees?.length===Idx+1?"":","}</strong>
								))}
								</strong>
								<span>expires in 7 days.</span>
							</div>
						</Grid>
						</Grid>
						)}})}
						{lincenceNotification&&lincenceNotification?.licenceExpiryIn15Days&&lincenceNotification?.licenceExpiryIn15Days.map((licence,index)=>{
							const isExpiringTomorrow = lincenceNotification?.licenceExpiryTomorrow.some((product)=>product.id===licence.id);
							const isExpiringIn7Days = lincenceNotification?.licenceExpiryIn7Days.some((product)=>product.id===licence.id);
							if(!isExpiringTomorrow&&!isExpiringIn7Days){

							return(
							<Grid container spacing={2} sx={{mt:'5px'}}>
							<Grid className="pedremove" item xs={12}>
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								<p>Licence for</p>{" "}
								<strong>
									
										<Link
										onClick={() => {
											navigate(`/licence-master/add/${encryption(licence?.id)}`);
										}}
										// to={`/licence-master/add/`}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{licence?.productName}
											{/* {lincenceNotification.licenceExpiryIn15Days.length===Idx+1?" ":", "} */}
										</Link>
								</strong>

								{licence?.assignedEmployees?.length > 0 ?<p>issued to</p>:"" }

								<strong>
								{licence?.assignedEmployees?.map((user, Idx) => (
									 <strong>{user?.firstName+" "+user?.lastName} 
											{licence?.assignedEmployees?.length===Idx+1?"":","}</strong>
								))}
								</strong>
								<span>expires in 15 days.</span>
							</div>
						</Grid>
						</Grid>
						)}})}

				{flag == true && pendingChecklist && pendingChecklist?.map((checklist,index)=>{ 
						return(
							<Grid container spacing={2} sx={{mt:'5px'}}>
								<Grid className="pedremove" item xs={12}>
									<div className="warning">
										<img src="/assets/images/information-icon.svg" />{" "}
										<strong>{checklist?.assigneeName}</strong>{" "}
										<p>Please complete exit checklist for &nbsp;
										<strong>
											{/* {hr.employe.map((candidate, Idx) => ( */}
												<Link
													onClick={() => {
														navigate(`/employee/view/${encryption(checklist?.employeeId)}`, {
														});
													}}
													underline="none"
													color={"black"}
													sx={{ cursor: "pointer" }}
												>
													{checklist?.employees?.firstName + " " + checklist?.employees?.lastName}
												</Link>
											{/* ))} */}
										</strong>
										</p>{" "}
									</div>
								</Grid>
							</Grid>
						)})}

					{AuthRole == "Admin" && completeChecklist &&
						new Date(completeChecklist[0]?.relievingDate) == new Date() &&
						completeChecklist?.map((checklist,index)=>{ 
						return(
							<Grid container spacing={2} sx={{mt:'5px'}}>
								<Grid className="pedremove" item xs={12}>
									<div className="warning">
										<img src="/assets/images/information-icon.svg" />{" "}
										{/* <strong>{checklist?.assigneeName}</strong>{" "} */}
										<p>Exit checklist for &nbsp;
										<strong>
											{/* {hr.employe.map((candidate, Idx) => ( */}
												<Link
													onClick={() => {
														navigate(`/employee/view/${encryption(checklist?.employeeId)}`, {
														});
													}}
													underline="none"
													color={"black"}
													sx={{ cursor: "pointer" }}
												>
													{checklist?.employees?.firstName + " " + checklist?.employees?.lastName}
												</Link>
											{/* ))} */}
										</strong> &nbsp;
										is completed 
										</p>{" "}
									</div>
								</Grid>
							</Grid>
						)})}

					{vmNotification?.VmExpired?.length > 0 ? 
					<Stack className="view_employee_main" style={{gap:'10px' }}>
						<Grid container spacing={2}>
						<Grid item xs={12}>
						{/* {console.log(hr,"pd")} */}
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								{/* <strong>{hr.hrName}</strong>{" "} */}
								<p>VM/s</p>{" "}
								<strong>
									{vmNotification?.VmExpired?.map((vm, Idx) => (
										<Link
											onClick={() => {
												navigate(`/vm/view/${encryption(vm.id)}`, {
													state: { tabid: "4" },
												});
											}}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{vm?.serverName}({vm?.ipAddress})
											{vmNotification?.VmExpired?.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
								{" "}<p>has expired</p>
							</div>
						</Grid>
					</Grid>
				</Stack> : ""}

				{vmNotification?.VmExpiryIn15Days?.length > 0 ? 
					<Stack className="view_employee_main" style={{gap:'10px' }}>
						<Grid container spacing={2}>
						<Grid item xs={12}>
						{/* {console.log(hr,"pd")} */}
							<div className="warning">
								<img src="/assets/images/information-icon.svg" />{" "}
								{/* <strong>{hr.hrName}</strong>{" "} */}
								<p>VM/s</p>{" "}
								<strong>
									{vmNotification?.VmExpiryIn15Days?.map((vm, Idx) => (
										<Link
											onClick={() => {
												navigate(`/vm/view/${encryption(vm.id)}`, {
													state: { tabid: "4" },
												});
											}}
											underline="none"
											color={"black"}
											sx={{ cursor: "pointer" }}
										>
											{vm?.serverName} ({vm?.ipAddress})
											{vmNotification?.VmExpiryIn15Days?.length===Idx+1?" ":", "}
										</Link>
									))}
								</strong>
								{" "}<p>will expire in 15 days</p>
							</div>
						</Grid>
					</Grid>
				</Stack> : ""}
				
				<Grid container spacing={3} mt={3} className="dashboaed-slider">
					{/* {getViewVisible("employee") ? ( */}
					{data?.noOfEmployee >=0  ? (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							onClick={() =>
								navigate("/employee", { state:''})
							}
							className="dashboaed-slider-item dashboaed-slider-employee"
						>
							<Employees number={data?.noOfEmployee} loading={loading} />
						</Grid>
						):''}
					{/* ) : (
						""
					)} */}
					{/* {getViewVisible("employee") ? ( */}
					{data?.noOfEmployeeJoining >=0  ? (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							onClick={() =>
								navigate("/employee/joining-employee", { state: { newJoining: 1 } })
							}
							className="dashboaed-slider-item dashboaed-slider-Joining"
						>
							<EmployeeJoining
								number={data?.noOfEmployeeJoining}
								loading={loading}
							/>
						</Grid>):''}
					{/* ) : (
						""
					)} */}
					{/* {getViewVisible("employee") ? ( */}
					{data?.noOfEmployeeResignation >=0  ? (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							onClick={() => navigate("/employee", { state: { reliving: 1 } })}
							className="dashboaed-slider-item dashboaed-slider-Exiting"
						>
							<EmployeeExiting
								number={data?.noOfEmployeeResignation}
								loading={loading}
							/>
						</Grid>):''}
					{/* ) : (
						""
					)} */}
					{/* {getViewVisible("candidates") ? ( */}
					{data?.noofLeads >=0  ? (
						<Grid
							item
							xs={12}
							sm={6}
							md={3}
							onClick={() =>
								navigate("/candidate", { state: { candidateStatusId: 1 } })
							}
							className="dashboaed-slider-item dashboaed-slider-Leads"
						>
							<Leads number={data?.noofLeads} loading={loading} />
						</Grid>):''}
					{/* ) : (
						""
					)} */}
</Grid>
					{/* {getViewVisible("interview") ?  */}
					<Grid className="interview-schedules-wrapper" container spacing={3}><InterviewList/></Grid>
					{/* : ""} */}
					<Grid className="Newsdetail-wrapper" container spacing={3}>
					<Grid className="Newsdetail-wrapper-item" item xs={8} sm={8} md={8}>
						<Grid item xs={12} md={12} lg={12}>
							<Calendar {...data} />
						</Grid>
					</Grid>
					<Grid className="Newsdetail-wrapper-item NewsFeed-item" item xs={4} sm={4} md={4}>
						<Grid item xs={12} md={12} lg={12}>
							<NewsFeed {...data} />
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
}

const componentConfig = {
	component: DashboardApp,
	path: "/dashboard",
	public: false,
	layout: DashboardLayout,
	roles: ["admin"],
	group: null,
	sidebar: true,
};

export default componentConfig;
