import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authSlice";
import { bloodGroupReducer } from "./BloodGroup/bloodGroupSlice";
import { activityReducer } from "./activity/activitySlice";
import { courseReducer } from "./course/courseSlice";
import { departmentReducer } from "./department/departmentSlice";
import { designationReducer } from "./designation/designationSlice";
import { educationReducer } from "./education/educationSlice";
import { interviewReducer } from "./interview/interviewSlice";
import { interviewTypeReducer } from "./interviewType/interviewTypeSlice";
import { jobChangeReducer } from "./jobChange/jobChangeSlice";
import { skillReducer } from "./skill/skillSlice";
import { technologyReducer } from "./technology/technologySlice";
import { dashboardReducer } from "./dashboard/dashboardSlice";
import { holidayReducer } from "./holiday/holidaySlice";
import { specialdayReducer } from "./specialday/specialdaySlice";
import { companyReducer } from "./company/companySlice";
import { jobDesReducer } from "./jobDescription/jobDesSlice";
import { drivesReducer } from "./drives/drivesSlice";
import { pageMasterReducer } from "./pageMaster/pageMasterSlice";
import { collegeReducer } from "./college/collegeSlice";
import { candidateReducer } from "./candidate/candidateSlice";
import { candidateCountReducer } from "./candidate/candidateCountSlice";
import { employeeReducer } from "./employee/employeeSlice";
import { exEmployeeReducer } from "./exEmployee/exEmployeeSlice";
import { projectReducer } from "./project/projectSlice";
import { vendorReducer } from "./vendor/vendorSlice";
import { userRolesReducer } from "./userRoles/userRolesSlice";
import { userReducer } from "./user/userSlice";
import { externalDesignationReducer } from "./externalDesignation/externalDesignation";
import { checkListReducer } from "./checkList/checkListSlice";
import { scheduleInterviewReducer } from "./scheduleInterview/scheduleInterviewSlice";
import { itemMasterReducer } from "./assestManagment/assestManagmentSlice";
import { assetReducer } from "./assets/assetSlice";
import { assetCountReducer } from "./assets/assetCountSlice";
import { jobVacancyReducer } from "./jobVacancy/jobVacancySlice.js";
import { dashboardInterviewListReducer } from "./dashboardInterviewList/dashboardInterviewListSlice";
import { manufacturerReducer } from "./manufacturer/manufacturerSlice";
import { addressReducer } from "./adress/addressSlice";
import { studentReducer } from "./student/studentSlice";
import { hscCourseReducer } from "./hsccourse/hsccourseSlice";
import { InterviewListReducer } from "./InterviewList/InterviewListSlice";
import { calendarReducer } from "./calendar/calendarSlice";
import { employeeCountReducer } from "./employee/employeeCountSlice";
import { joiningEmployeeReducer } from "./employee/joiningEmployeeSlice";
import { InterviewCountReducer } from "./InterviewList/interviewCountSlice";
import { internReducer } from "./intern/internSlice";
import { candidateStateReducer } from "./candidateState/candidateStateSlice";
import { internCountReducer } from "./intern/internCountSlice";
import { companyCountReducer } from "./company/companyCountSlice";
import { collegeCountReducer } from "./college/collegeCountSlice";
import { userPermisssionReducer } from "./userPermission/userPermissionSlice";
import { itAssetReducer } from "./itAsset/itAssetSlice";
import { itemAssetMappingReducer } from "./itemAssetMapping/itemAssetMappingSlice";
import { jobCanReducer } from "./jobCandidates/jobCanSlice";
import { updateDashboardInterviewStatusReducer } from "./dashboardInterviewStatus/upadteInterviewStatusSlice";
import { cmsVariableReducer } from "./cmsVariable/cmsVariableSlice";
import { emailTemplateReducer } from "./emailTemplate/emailTemplateSlice";
import { changeRequestReducer } from "./changeRequest/changeRequestSlice";
import { studentByDriveCountReducer } from "./student/studentByDriveCountSlice";
import { internEvaluationReducer } from "./internEvaluation/internEvaluationSlice";
import { userCountReducer } from "./user/userCountSlice";
import { assetVMReducer } from "./assetVM/assetVMSlice";
import { assestLicenceReducer } from "./assestLicence/assestLicenceSlice";
import { joiningEmployeeCountReducer } from "./employee/joiningEmployeeCountSlice";
import { exEmployeeCountReducer } from "./exEmployee/exEmployeeCountSlice";
import { emailTemplateDynamicReducer } from "./emailTemplateDynamic/emailTemplateDynamicSlice";
import { changeRequestCountReducer } from "./changeRequest/changeRequestCountSlice";
import { jobDescCountReducer } from "./jobDescription/jobDescCountSlice";
import { projectCountReducer } from "./project/projectCountSlice";
import { manufacturerCountReducer } from "./manufacturer/manufacturerCountSlice";
import { driveCountReducer } from "./drives/driveCountSlice";
import { addressCountReducer } from "./adress/addressCountSlice";
import { salarySlipReducer } from "./salarySlip/salarySlipSlice";
import {emailInboxReducer} from "./emailInbox/emailInboxSlice";
import { workFlowReducer } from "./workFlow/workFlowSlice";
import { workFlowCountReducer } from "./workFlow/workFlowCountSlice";
import { questionsReducer } from "./question/questionSlice";
import { onboardingCandidateReducer } from "./onboardingCandidate/onboardedCandidateSlice";
import { onboardedCandidateCountReducer } from "./onboardingCandidate/onboardedCandidateCountSlice";
import { helpdeskCategoriesReducer } from "./helpdeskCategories/helpdeskCategoriesSlice";
import { helpdeskTicketsReducer } from "./helpdeskTickets/helpdeskTicketsSlice";
import { questionCountReducer } from "./question/questionCountSlice";
import { pollReducer } from "./poll/pollSlice";
import { pollCountReducer } from "./poll/pollCountSlice";
import { evaluationReducer } from "./evaluation/evaluationSlice";
import { evaluationCountReducer } from "./evaluation/evaluationCountSlice";
import { seatReducer } from "./seatArrangment/seatSlice";
import { faqsReducer } from "./faqs/faqsSlice";
import { reportsEmployeeExperienceReducer } from "./reportsEmployeeExperience/reportsEmployeeExperienceSlice";
import { reportsAssetsReducer } from "./reportsAssets/reportsAssetsSlice";
import {reportsJoiningRelievingReducer} from "./reportsJoiningRelieving/reportsJoiningRelievingSlice";
import { announcementsReducer } from "./announcements/announcementsSlice";
import { teamReducer } from "./team/teamSlice";
import { employeeEvaluationReducer } from "./employeeEvaluation/employeeEvaluationSlice";
import { employeeEvaluationHistoryReducer } from "./employeeEvaluation/employeeEvaluationHistorySlice";
import { evaluationsCountReducer } from "./employeeEvaluation/evaluationsCountSlice";
import { exitQuestionReducer } from "./exitQuestion/exitQuestionSlice";
import { exitQuestionCountReducer } from "./exitQuestion/exitQuestionCountSlice";
import { gradeReducer } from "./grade/gradeSlice";
import { parkingAllocationReducer } from "./parkingAllocation/parkingallocationSlice.js";
import { notebookReducer } from "./notebook/notebookSlice.js";
import { linksReducer } from "./links/linksSlice.js";

const combinedReducer = combineReducers({
	auth: authReducer,
	bloodGroup: bloodGroupReducer,
	activity: activityReducer,
	course: courseReducer,
	department: departmentReducer,
	designation: designationReducer,
	education: educationReducer,
	externalDesignation: externalDesignationReducer,
	checkList: checkListReducer,
	interview: interviewReducer,
	interviewType: interviewTypeReducer,
	skill: skillReducer,
	technology: technologyReducer,
	jobChange: jobChangeReducer,
	holiday: holidayReducer,
	specialday: specialdayReducer,
	company: companyReducer,
	jobDes: jobDesReducer,
	jobCan: jobCanReducer,
	college: collegeReducer,
	project: projectReducer,
	candidate: candidateReducer,
	employee: employeeReducer,
	exEmployee: exEmployeeReducer,
	exEmployeeCount: exEmployeeCountReducer,
	vendor: vendorReducer,
	user: userReducer,
	userRoles: userRolesReducer,
	pageMaster: pageMasterReducer,
	cmsVariable: cmsVariableReducer,
	emailTemplate: emailTemplateReducer,
	emailInbox : emailInboxReducer,
	scheduleInterview: scheduleInterviewReducer,
	dashboard: dashboardReducer,
	inventory: itemMasterReducer,
	assets: assetReducer,
	jobVacancy: jobVacancyReducer,
	dashboardInterviewList: dashboardInterviewListReducer,
	manufacturer: manufacturerReducer,
	drives: drivesReducer,
	address: addressReducer,
	student: studentReducer,
	hscCourse: hscCourseReducer,
	interviewList: InterviewListReducer,
	calendar: calendarReducer,
	candidateCount: candidateCountReducer,
	assetCount: assetCountReducer,
	employeeCount: employeeCountReducer,
	joiningEmployee: joiningEmployeeReducer,
	joiningEmployeeCount: joiningEmployeeCountReducer,
	getInterviewConut: InterviewCountReducer,
	intern: internReducer,
	candidateState: candidateStateReducer,
	internCount: internCountReducer,
	companyCount: companyCountReducer,
	parkingAllocation : parkingAllocationReducer,
	getCollegeCount: collegeCountReducer,
	userPermission: userPermisssionReducer,
	getAsset: itAssetReducer,
	ItemAssetMapping: itemAssetMappingReducer,
	updateDashboardInterviewStatus: updateDashboardInterviewStatusReducer,
	changeRequest: changeRequestReducer,
	totalStudentsByDrive: studentByDriveCountReducer,
	internEvaluation: internEvaluationReducer,
	userCount: userCountReducer,
	assetsLicence: assestLicenceReducer,
	assetVM: assetVMReducer,
	emailTemplateDynamic: emailTemplateDynamicReducer,
	changeRequestCount: changeRequestCountReducer,
	jobDescCount: jobDescCountReducer,
	projectCount: projectCountReducer,
	manufacturerCount: manufacturerCountReducer,
	driveCount: driveCountReducer,
	addressCount: addressCountReducer,
	salarySlip: salarySlipReducer,
	workFlow: workFlowReducer,
	workflowCount: workFlowCountReducer,
	questions: questionsReducer,
	questionCount: questionCountReducer,
	onboardingCandidate: onboardingCandidateReducer,
	onboardingCandidateCount: onboardedCandidateCountReducer,
	helpdeskCategories: helpdeskCategoriesReducer,
	helpdeskTickets:helpdeskTicketsReducer,
	poll: pollReducer,
	pollCount: pollCountReducer,
	evaluation:evaluationReducer,
	evaluationCount:evaluationCountReducer,
	seatAllocation: seatReducer,
	faqs:faqsReducer,
	reportsEmployeeExperience: reportsEmployeeExperienceReducer,
	reportsAssets: reportsAssetsReducer,
	reportsJoiningRelieving:reportsJoiningRelievingReducer,
	announcements:announcementsReducer,
	team: teamReducer,
	employeeEvaluation: employeeEvaluationReducer,
	employeeEvalutionHistory: employeeEvaluationHistoryReducer,
	evaluationsCount: evaluationsCountReducer,
	exitQuestions: exitQuestionReducer,
	exitQuestionCount: exitQuestionCountReducer,
	grade: gradeReducer,
	notebook:notebookReducer,
	link:linksReducer,
});

const rootReducer = (state, action) => {
	if (action.type === "auth/logOutUser") {
		state = undefined;
	}
	return combinedReducer(state, action);
};

export const store = configureStore({
	reducer: rootReducer,
});
