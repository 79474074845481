import * as Yup from "yup";

const announcementsSchema = Yup.object().shape({
    // content: Yup.string()
    // .test('is-not-empty', 'Content is required', (value) => {
    //   console.log('Content value:', value); // Log the value
    //   const plainText = value.replace(/<[^>]+>/g, '').trim();
    //   console.log('Plain text:', plainText); // Log the result of replace
    //   return plainText.length > 0;
    // }),
   content:Yup.string().required("Content is required")
   .trim("Evaluation Title cannot contain only whitespace")
   .strict(true)    ,
    title:Yup.string()
    .trim("Title cannot contain only whitespace")
    .strict(true)
    .required("Title is required"),
	startDate: Yup.date()
		.required("Effective from is required")
		.typeError("Invalid Date"),
	endDate: Yup.date()
        .required("Effective until is required")
	  .min(Yup.ref("startDate"), "End date can't be before start date")
	  .typeError("Invalid Date"),
      // attachments: Yup.array().test("fileType", "Only jpg, jpeg and pdf files are allowed", (value) => {
      //   if (!value || value.length === 0) {
      //     return true;
      //   }
      //   const validFileTypes = ["application/pdf"];
      //   return value.map((file) => validFileTypes.includes(file?.type))
      // }),
});

export default announcementsSchema;
