import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getDashboardInterviewList } from "../redux/dashboardInterviewList/dashboardInterviewListThunk";
import { updateDashboardInterviewStatus } from "../redux/dashboardInterviewStatus/updateInterviewStatusThunk";
import { useDispatch, useSelector } from "react-redux";
import {
  indianDateFormat,
  dateDiffrence,
  tomorrowDate,
  daysTwoAfterTomorrowDate,
  dayAfterTomorrowDate,
  getWeekDay,
} from "../utils/dateFormat";
import { convertTo24HrsFormat } from "../utils/formatTime";
import { encryption } from "../utils/encodeString";
import Loader from "../components/Loader";
import { getCandidateByStatus } from "../redux/candidateState/candidateStatethunk";
import { useConfirm } from "material-ui-confirm";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { style } from "@mui/system";
import { Autocomplete, TextField } from "@mui/material";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { values } from "lodash";
import LoadingButton from "@mui/lab/LoadingButton";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: 2,
  p: 4,
};

const InterviewList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [noteError, setNoteError] = useState({ flag: false, title: "" });
  const [interview, setInterview] = useState();
  const [nValue, setNValue] = useState("");
  const interviewList = useSelector(
    (state) => state.dashboardInterviewList.data
  );
  const confirm = useConfirm();
  const updateInterviewStatus = useSelector(
    (state) => state.updateDashboardInterviewStatus.data
  );

  const candidateState = useSelector((state) => state.candidateState);

  const loading = useSelector((state) => state.dashboardInterviewList.loading);

  const [todayInterviews, setTodayInterviews] = useState([]);
  const [tomorrowInterviews, setTomorrowInterviews] = useState([]);

  const ITEM_HEIGHT = 100;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT + ITEM_PADDING_TOP,
        width: 140,
      },
    },
  };

  useEffect(() => {
    dispatch(getDashboardInterviewList());
    dispatch(getCandidateByStatus(4));
  }, []);

  const formatInterviewerName = (interviewrefrences) => {
    return interviewrefrences
      ?.map(
        (ref) => `${ref?.interviewer?.firstName} ${ref?.interviewer?.lastName}`
      )
      .join(", ");
  };

  const { values, setFieldValue } = useFormik({
    initialValues: {
      note: "",
    },
  });

  const handleCancel = () => {
    handleClose();
    setFieldValue("note", "");
    setNoteError({ flag: false, title: "" });
  };

  const handleInterviewStatusChange = (noteValue) => {
    if (noteValue == "") {
      setNoteError({ flag: true, title: "Please enter notes" });
    }
    if (noteValue != "") {
      setNoteError({ flag: false, title: "" });
    }
    if (noteValue != "") {
      dispatch(
        updateDashboardInterviewStatus({
          interviewId: interview.id,
          candidateId: interview.candidateId,
          activityStateId: nValue.id,
          note: noteValue,
        })
      ).then(() => {
        dispatch(getDashboardInterviewList());
      });
      setFieldValue("note", "");
      handleClose();
    }
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
      /* console.log("Copying text command was " + msg); */
    } catch (err) {
      toast.error("Text Not Copied!");
      /* console.log("Oops, unable to copy"); */
    }

    document.body.removeChild(textArea);
  };

  const handleInterviewStateChange = (e) => {
    handleOpen();
  };

  const mapInterview = (interview, key) => {
    let role = localStorage
      .getItem("role")
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/"/g, "");
    // console.log(localStorage.getItem("role").toLowerCase().replace(/\s+/g, "").replace(/"/g, "")=="superadmin","popopopopop",localStorage.getItem("role").toLowerCase().replace(/\s+/g,'').replace(/"/g, ""));
    return (
      <div key={key} className="interview_item">
        <div className="interview_top">
          <Typography
            variant="h5"
            style={{ cursor: "pointer" }}
            onClick={() =>
              navigate(`/candidate/view/${encryption(interview.candidateId)}`)
            }
          >
            {interview?.candidateName}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span>{formatInterviewerName(interview?.interviewrefrences)}</span>
            {/* <div className="interview_call">
							{" "}
							{console.log(interview)}
							{interview?.interviewType == "Practical Offline" ||
							interview?.interviewType == "Practical Online" ? (
								<img src="/assets/images/moniter.svg" title={interview?.interviewType} />
							) : ( interview?.interviewType != "Technical Offline" ?
								<img src="/assets/images/orangecall.svg" title={interview?.interviewType} /> : ""
							) }{" "}
							{convertTo24HrsFormat(interview?.interviewTime)}
						</div>*/}
    
            <div className="interview_call">
              {interview?.interviewType?.toLowerCase().includes("practical") ? (
                <img
                  src="/assets/images/moniter.svg"
                  title={interview?.interviewType}
                />
              ) : (
                interview?.interviewMode?.toLowerCase().includes("online") ? 
                  <img
                    src="/assets/images/orangecall.svg"
                    title={interview?.interviewType}
                  /> : ""
                
              )}
              {convertTo24HrsFormat(interview?.interviewTime)}
            </div>
          </div>
        </div>
        <div className="interview_middle">
          <div>
            <strong>
              <span className="interview_title">
                {" "}
                &nbsp;{interview?.technology?.title}
              </span>
              <span>
                {`${interview?.technology?.title}` &&
                `${interview?.candidate?.totalExperience}`
                  ? " | "
                  : ""}
                {interview?.candidate?.totalExperience &&
                interview?.candidate?.totalExperience != 0
                  ? `${interview?.candidate?.totalExperience} Yrs `
                  : "0 Yrs"}
              </span>
            </strong>
            {role == "superadmin" || role == "hr" ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 15px 0 0",
                  }}
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src="/assets/images/roundcall.svg"
                  />{" "}
                  <a href={`tel:${interview?.candidate?.contactNumber}`}>
                    {interview?.candidate?.contactNumber}
                  </a>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    margin: "0 15px 0 0",
                  }}
                >
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src="/assets/images/roundmail.svg"
                  />{" "}
                  <a href={`mailto:${interview?.candidate?.email}`}>
                    {interview?.candidate?.email}
                  </a>
                  <span className="copy-icon">
                    <img
                      onClick={() => {
                        ClicktoCopy(interview?.candidate?.email);
                      }}
                      height="16px"
                      width="16px"
                      src="/assets/images/copy-icon.svg"
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="social-image-icon">
            {interview?.googleMeetLink && (
              <a href={`${interview?.googleMeetLink}`} target="_blank">
                <img
                  className="skype"
                  height="14px"
                  width="14px"
                  src="/assets/images/google-meet.png"
                />
              </a>
            )}
            {interview?.candidate?.skype && (
              <a href={`skype:${interview?.candidate?.skype}?chat`}>
                <img
                  className="skype"
                  height="20px"
                  width="20px"
                  src="/assets/images/skype-gray.svg"
                />
              </a>
            )}
            {interview?.candidate?.linkedIn && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={interview?.candidate?.linkedIn}
              >
                <img
                  className="skype"
                  height="14px"
                  width="14px"
                  src="/assets/images/linkedin-gray.svg"
                />
              </a>
            )}
            {interview?.candidate?.candidateResume && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={interview?.candidate?.candidateResume?.webViewLink}
              >
                <img
                  className="skype"
                  height="14px"
                  width="14px"
                  src="/assets/images/download-gray.svg"
                />
              </a>
            )}
          </div>
        </div>
        {role == "superadmin" || role == "hr" ? (
          <div className="interview_bottom" mb={0}>
            <div className="mail-dropdown-div">
              <Autocomplete
                size="small"
                fullWidth
                options={candidateState?.stateData || []}
                getOptionLabel={(option) => option.name || ""}
                disableClearable
                value={candidateState?.stateData?.find(
                  (status) =>
                    status.id === interview?.candidate?.interviewStatusId
                )}
                // value={interview?.candidate?.interviewStatusId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    //   label="State"
                  />
                )}
                onChange={(e, newValue) => {
                  setInterview(interview);
                  setNValue(newValue);
                  handleInterviewStateChange();
                }}
              />
            </div>
            <Link
              to={`/interviewlist/schedule-interview/${encryption(
                interview?.candidate?.id
              )}`}
            >
              Schedule Next Round
            </Link>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };
  return (
    <>
      <Grid className="interview-schedules-grid" item xs={6} sm={6} md={6}>
        {interviewList.todaysInterviews?.length > 0 ? (
          <Grid className="interview-date" item xs={12} sm={12} md={12}>
            <Typography variant="h6">
            {getWeekDay(interviewList.todaysInterviews[0]["interviewDate"])}
              {/* {indianDateFormat(
                new Date(interviewList.todaysInterviews[0]["interviewDate"])
              )} */}
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} sm={12} md={12} mb={0}>
          {loading ? (
            <Loader />
          ) : (
            <Card
              className="interview-card"
              sx={{ maxHeight: "500px", overflow: "auto" }}
            >
              {interviewList.todaysInterviews?.length > 0 ? (
                <div
                  className="interview-schedules-grid"
                  style={{ marginBottom: "5px" }}
                >
                  {interviewList.todaysInterviews.map((interview, idx) =>
                    mapInterview(interview, idx)
                  )}
                </div>
              ) : (
                <div className="no-schedule-data">
                  <Typography align="center">
                    No interview schedules Today
                  </Typography>
                </div>
              )}
            </Card>
          )}
        </Grid>
      </Grid>
      {/* {console.log(new Date(tomorrowDate()).getDay())} */}

      <Grid className="interview-schedules-grid" item xs={6} sm={6} md={6}>
        {interviewList.nextWorkingDayInterview?.length > 0 ? (
          <Grid className="interview-date" item xs={12} sm={12} md={12}>
            <Typography variant="h6">
              {getWeekDay(interviewList.nextWorkingDayInterview[0]["interviewDate"])}
              
              {/* // new Date(tomorrowDate()).getDay() == 0 || 
							//  new Date(tomorrowDate()).getDay() == 6 ?
							//  indianDateFormat(daysTwoAfterTomorrowDate()) :
							// 		indianDateFormat(tomorrowDate()) */}
         
            </Typography>
          </Grid>
        ) : (
          ""
        )}
        <Grid item xs={12} sm={12} md={12} mb={0}>
          {loading ? (
            <Loader />
          ) : (
            <Card
              className="interview-card"
              sx={{ maxHeight: "500px", overflow: "auto" }}
            >
              {interviewList?.nextWorkingDayInterview?.length > 0 ? (
                <div className="interview-schedules-grid">
                  {interviewList?.nextWorkingDayInterview?.map((interview, idx) =>
                    mapInterview(interview, idx)
                  )}
                </div>
              ) : (
                <div className="no-schedule-data">
                  <Typography align="center">
                  No interview schedules on next working day
                  </Typography>
                </div>
              )}
            </Card>
          )}

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Stack sx={style2}>
              Are you sure you want to change the interview status of{" "}
              {interview?.candidateName} to {nValue?.name} ?
              <TextField
                fullWidth
                multiline
                label="Note"
                aria-label="Note..."
                minRows={3}
                // placeholder="Minimum 3 rows"
                /* className="custom-textarea" */
                // {...getFieldProps("note")}
                inputProps={{
                  inputComponent: TextareaAutosize,
                  maxLength: 512,
                  style: {
                    resize: "auto",
                  },
                }}
                onChange={(e) => {
                  setFieldValue("note", e.target.value || "");
                  if (e.target.value) {
                    setNoteError({ flag: false, title: "" });
                  }
                  if (e.target.value == "") {
                    setNoteError({ flag: true, title: "Please enter notes" });
                  }
                }}
                helperText={noteError?.title && noteError?.title}
                error={Boolean(noteError?.flag && noteError?.flag)}
              />
              <Stack
                direction="row"
                alignItems="right"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  // type="submit"
                  onClick={handleCancel}
                  sx={{ marginRight: "15px" }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  // component={RouterLink}
                  onClick={() => handleInterviewStatusChange(values.note)}
                  variant="contained"
                >
                  Yes
                </LoadingButton>
              </Stack>
            </Stack>
          </Modal>
        </Grid>
      </Grid>
      {/* {interviewList?.tomorrowsInterviews?.length <= 0 &&
      interviewList?.interviewsOnNextMonday?.length > 0 ? (
        <Grid item xs={6} sm={6} md={6} style={{ paddingTop: "24px" }}>
          {interviewList.interviewsOnNextMonday?.length > 0 ? (
            <Grid item xs={12} sm={12} md={12}>
              <Typography variant="h6">
                {indianDateFormat(
                  new Date(
                    interviewList.interviewsOnNextMonday[0]["interviewDate"]
                  )
                )}
              </Typography>
            </Grid>
          ) : (
            ""
          )}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            mb={2}
            style={{ paddingTop: "10px" }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Card
                className="interview"
                sx={{ maxHeight: "500px", overflow: "auto" }}
              >
                {interviewList.interviewsOnNextMonday?.length > 0 ? (
                  <div
                    className="interview-schedules-grid"
                    style={{ marginBottom: "5px" }}
                  >
                    {interviewList.interviewsOnNextMonday.map(
                      (interview, idx) => mapInterview(interview, idx)
                    )}
                  </div>
                ) : (
                  <div className="no-schedule-data">
                    <Typography align="center">
                      No interview schedules Next Monday
                    </Typography>
                  </div>
                )}
              </Card>
            )}
          </Grid>
        </Grid>
      ) : (
        ""
      )} */}
    </>
  );
};

export default InterviewList;
