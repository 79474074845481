import { createAsyncThunk} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Axios } from '../../../service/axios';

export const getExitQuestions = createAsyncThunk(
  // action type string
  'exitInterview/getExitInterviewQuestions',
  // callback function
  async ({ rejectWithValue }) => {
    try {
      const res = await Axios.get(`getQuestions`);
      //  console.log("HISTORY", res.data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      //  return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const addExitQuestion = createAsyncThunk(
	"exitInterview/addExitQuestion",
	async (values, { rejectWithValue }) => {
		try {
			const response = await Axios.post("/createQuestion", values,{
				headers:{type:'create',module:'exitInterview'}
			});
			toast.success(response.data.message);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error?.message);
		}
	},
);

export const getExitQuestionCount = createAsyncThunk(
	"exitInterview/getExitQuestionCount",
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/getExitQuestionCount",{
				headers:{type:'view',module:'exitInterview'}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.message || error.message);
		}
	},
);

export const deleteExitQuestion = createAsyncThunk(
	"exitInterview/deleteExitQuestion",
	async (id, { rejectWithValue }) => {
		try {
			const response = await Axios.delete(`/deleteQuestion/`, {
				headers: { id, type:'delete',module:'exitInterview' },
			});
			toast.success(response.message);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error);
		}
	},
);

export const updateExitQuestion = createAsyncThunk(
	"exitInterview/updateExitQuestion",
	async ({id,values}, { rejectWithValue }) => {
		try {
			const response = await Axios.put("/updateQuestion", values,{
				headers: {
					id: id,
					type:'update',module:'exitInterview'
				},
			},);

			toast.success(response.data.message);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error);
		}
	},
);

export const getExitQuestionById = createAsyncThunk(
	"inventory/getExitQuestionById",
	async (id, { rejectWithValue }) => {
		try {
			const response = await Axios.get("/questionById", {
				headers: {
					id,type:'view',module:'exitInterview'
				},
			});
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error);
		}
	},
);

export const deleteExitOptions = createAsyncThunk(
	"exitInterview/deleteExitOptions",
	async (id, { rejectWithValue }) => {
		try {
			const response = await Axios.delete(`/deleteExitOptions/`, {
				headers: { id, type:'delete',module:'exitInterview' },
			});
			toast.success(response.message);
			return response.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error);
		}
	},
);