import { createAsyncThunk} from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { Axios } from '../../../service/axios';

export const getEvalutionByDesignation = createAsyncThunk(
  'evaluation/getEvalutionDataByUserWise',
  async (params,{ rejectWithValue }) => {
    try {
      const res = await Axios.post(
        '/getEvalutionByDesignation',
        params,
        {
          headers: { type: 'view', module: 'candidates' },
          // {
          // headers: { type: 'create',module:'candidates' },
        }
      );
      // console.log("EVALUATION__________01", res.data.data);
      return res.data.data;
    } catch (error) {
      // toast.error(error.response.data.message || error.message);
      // return rejectWithValue(error.response.data.message || error.message);
    }
  }
);

export const getEvaluation = createAsyncThunk(
  'evaluation/getEvalutionDataByUserWise',
  async ({ rejectWithValue }) => {
    try {
      const res = await Axios.post(
        '/getEvalutionDataByUserWise',
        {},
        {
          headers: { type: 'view', module: 'candidates' },
          // {
          // headers: { type: 'create',module:'candidates' },
        }
      );
      // console.log("EVALUATION__________01", res.data.data);
      return res.data.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      // return rejectWithValue(error.response.data.message || error.message);
    }
  }
);

export const getEvaluationById = createAsyncThunk(
  'evaluation/getEvaluationById',
  async (id, { rejectWithValue }) => {
    try {
      const res = await Axios.get('/getEvalutionById', {
        headers: {
          // submit_evaluation_id: body?.submittedEvaluationId,
          evalution_id: id,
          type: 'view',
          module: 'evaluation',
        },
        // {
        // headers: { type: 'create',module:'candidates' },
      });
      // console.log("EVALUATION_DATA__________________", res.data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      // return rejectWithValue(error.response.data.message || error.message);
    }
  }
);

export const submitEvalutionForm = createAsyncThunk(
  //action type string
  'evaluation/submitEvalutionForm',
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post('submitEvalutionForm', body);
      toast.success(res.data.message);
      // console.log("RES_EVEALUTION FORM_____________",res.data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      // return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const ViewSubmittedEvaluationById = createAsyncThunk(
  // action type string
  'evaluation/ViewSubmittedEvaluationById',
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.get('getSubmitEvalutionById', {
        headers: {
          submitevalutionid: body?.submitevalutionid,
          type: 'view',
          module: 'evaluation',
        },
      });
      //  console.log("RES_EVEALUTION_VIEW_HISTORY_____________", res.data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      //  return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const ViewSubmittedEvaluationHistory = createAsyncThunk(
  // action type string
  'evaluation/ViewSubmittedEvaluationHistory',
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post('getSubmittedEvalutionFormMonthiwse', body);
      //  console.log("EVALUATION_HISTORY_RESPONSE_____________", res.data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      //  return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const getEmployeeSubmittedEvalution = createAsyncThunk(
  // action type string
  'evaluation/getEmployeeSubmittedEvalution',
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      let isSearchQuery = false;
			if (body.search && body.search !== "") {
				isSearchQuery = true;
			}
      const res = await Axios.post(`getEmployeeSubmittedEvalution`, body);
      //  console.log("HISTORY", res.data);

      return {
				data: res?.data?.data,
				filter: body.filter || isSearchQuery,
			};

      // return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      //  return rejectWithValue(error?.response?.data || error.message);
    }
  }
);


export const getSubmittedEvalutionHistory = createAsyncThunk(
  // action type string
  'evaluation/getSubmittedEvalutionHistory',
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post(`getSubmittedEvalutionHistory`, body);
      //  console.log("HISTORY", res.data);
      return res?.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      //  return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const getTeamForEvaluation = createAsyncThunk(
  // action type string
  'evaluation/getTeamForEvaluation',
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.get('getTeamForEvaluation', {
        headers: {
          id: body?.id,
          type: 'view',
          module: 'evaluation',
        },
      });
      //  console.log("RES_EVEALUTION_VIEW_HISTORY_____________", res.data);
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
      //  return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const getSubmittedEvalutionHistoryCount = createAsyncThunk(
	"evaluation/getSubmittedEvalutionHistoryCount",
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/getSubmittedEvalutionHistoryCount",{
				headers:{type:'view',module:'evaluation'}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.message || error.message);
		}
	},
);

export const getAllEmployeeEvaluation = createAsyncThunk(
  'evaluation/getAllEmployeeEvaluation',
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post(
        '/getAllEmployeeEvaluation',
        body,
        {
          headers: { type: 'view', module: 'evaluation' },
        }
      );
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message || error.message);
    }
  }
);

