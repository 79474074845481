import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import DashboardLayout from "../../../layouts/dashboard";
import Loader from "../../../components/Loader";

import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";

import collegeSchema from "../../../validations/collegeSchema";

import {
  insertCollege,
  updateCollegeById,
} from "../../../redux/college/collegeThunk";
import { getCollegeById } from "../../../redux/college/collegeThunk";
import { decryption } from "../../../utils/encodeString";
import { setStatus } from "../../../redux/college/collegeSlice";
import {
  getAreaData,
  getStateData,
  getPostalCode,
  getCityData,
} from "../../../utils/getLocationData";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { FormControlLabel, Switch } from "@mui/material";
function AddCollege() {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const [paramsId, setParamsId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setArea] = useState([]);

  // if (!getAddVisible("college") || !getEditVisible("college")) {
  // 	navigate("/dashboard");
  // }

  const formik = useFormik({
    initialValues: {
      collegeName: "",
      streetLine1: "",
      streetLine2: "",
      area: "",
      city: "",
      state: "Gujarat",
      zipcode: "",
      website: "",
      serchString: "",
      isCollegeDrive: false,
      contact: [
        {
          name: "",
          email: "",
          contactNumber: "",
          isPrimaryContact:false,
        },
      ],
    },
    validationSchema: collegeSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (id) {
          await dispatch(
            updateCollegeById({ ...values, id: paramsId })
          ).unwrap();
        }
        if (!id) {
          await dispatch(insertCollege(values)).unwrap();
        }
        dispatch(setStatus({ status: "idle" }));
        navigate("/college");
        resetForm();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    handleSubmit,
    getFieldProps,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
    isSubmitting,
  } = formik;

  const handleAreaWhileEdit = async (city) => {
    const areas = await getAreaData(city);
    setArea(areas);
  };

  const handleCityWhileEdit = async (state) => {
    const cities = await getCityData(state);
    setCities(cities);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      var decodeParam = decryption(id);
      if (decodeParam === -1) {
        navigate("/college");
      }
      setParamsId(decodeParam);

      const collegeData = dispatch(
        getCollegeById({ id: decodeParam })
      ).unwrap();
      collegeData
        .then(async (values) => {
          let newvalue = values.data;
          setValues({ ...newvalue, zipcode: "" + newvalue?.zipcode });
          await handleCityWhileEdit(values.data.state);
          await handleAreaWhileEdit(values.data.city);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast.error(err.message);
          navigate("/college");
        });
    }
  }, [dispatch, setValues, id, navigate]);

  useEffect(() => {
    getStateData()
      .then((states) => {
        setStates(states);
      })
      .catch((err) => {
        setStates([]);
      });
  }, [dispatch]);

  useEffect(() => {
    if (!id) {
      getCityData("Gujarat")
        .then((cities) => {
          setCities(cities);
        })
        .catch((err) => {
          setCities([]);
        });
    }
  }, [dispatch]);

  const handleState = async (event, newValue) => {
    const state = newValue;
    setFieldValue("zipcode", "");
    setFieldValue("area", "");
    setFieldValue("city", "");
    setFieldValue("state", state);
    setCities(await getCityData(state));
  };

  const handleArea = async (event, newValue) => {
    const city = newValue;
    setFieldValue("area", "");
    setFieldValue("zipcode", "");
    setFieldValue("city", city);
    setArea(await getAreaData(city));
  };

  const handleAddMoreContacts = () => {
    setFieldValue("contact", [
      ...values.contact,
      { name: "", email: "", contactNumber: "" ,isPrimaryContact:false},
    ]);
  };

  const handleDeleteForm = (index) => {
    const newContact = [...values.contact];
    newContact.splice(index, 1);
    setFieldValue("contact", newContact);
  };

  const handleZipCode = async (event, newValue) => {
    try {
      const area = newValue;
      const postalCode = await getPostalCode({ city: values.city, area });
      setFieldValue("area", area);
      setFieldValue("zipcode", postalCode);
    } catch (error) {}
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <div className="title-button-wrapper">
            <Typography variant="h4" gutterBottom>
              {id ? "Edit College" : "Add College"}
            </Typography>
            <Stack direction="row" alignItems="center">
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
              <Button
                variant="contained"
                component={RouterLink}
                to="/college"
                startIcon={<ArrowBackIcon />}
                style={{ marginLeft: "10px" }}
              >
                Back To List
              </Button>
            </Stack>
          </div>
        </Stack>
        <Card className="holiday-table-grid" mb={5}>
          <Container maxWidth="xl">
            <Paper>
              <Grid container spacing={1}>
                <Grid item xs={4} mt={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="College Name"
                    name="collegeName"
                    value={values.collegeName}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 256 }}
                    error={Boolean(touched.collegeName && errors.collegeName)}
                    helperText={touched.collegeName && errors.collegeName}
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  <TextField
                    label="Street Line 1"
                    name="streetLine1"
                    autoComplete="off"
                    value={values.streetLine1}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 256 }}
                    error={Boolean(touched.streetLine1 && errors.streetLine1)}
                    helperText={touched.streetLine1 && errors.streetLine1}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  <TextField
                    label="Street Line 2"
                    name="streetLine2"
                    autoComplete="off"
                    value={values.streetLine2}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 256 }}
                    error={Boolean(touched.streetLine2 && errors.streetLine2)}
                    helperText={touched.streetLine2 && errors.streetLine2}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  {/*<InputLabel id="demo-simple-select-label" size="small">
											State
										</InputLabel>
										<Select
											id="demo-simple-select"
											name="state"
											value={values.state}
											label="State"
											size="small"
											fullWidth
											onChange={handleState}
											error={Boolean(touched.state && errors.state)}
										>
											{states.map((state, key) => (
												<MenuItem key={key} value={state}>
													{state}
												</MenuItem>
											))}
										</Select>*/}
                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="state"
                    size="small"
                    name="state"
                    options={states || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="state"
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                      />
                    )}
                    value={values.state}
                    onChange={handleState}
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  {/*<InputLabel id="demo-simple-select-label" size="small">
											City
										</InputLabel>
										<Select
											disabled={!values.state}
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											name="city"
											value={values.city}
											label="City"
											size="small"
											fullWidth
											onChange={handleArea}
											error={Boolean(touched.city && errors.city)}
										>
											{cities.map((city, key) => (
												<MenuItem key={key} value={city}>
													{city}
												</MenuItem>
											))}
										</Select>*/}
                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="city"
                    size="small"
                    name="city"
                    disabled={!values.state}
                    options={cities || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="City"
                        error={
                          formik.touched.city && Boolean(formik.errors.city)
                        }
                        helperText={formik.touched.city && formik.errors.city}
                      />
                    )}
                    value={values.city}
                    onChange={handleArea}
                  />
                </Grid>
                <Grid item xs={4} my={3}>
                  {/*<InputLabel id="demo-simple-select-label" size="small">
											Area
										</InputLabel>
										<Select
											disabled={!values.city}
											labelId="demo-simple-select-label"
											id="demo-simple-select"
											name="area"
											value={values.area}
											label="Area"
											size="small"
											fullWidth
											onChange={handleZipCode}
											error={Boolean(touched.area && errors.area)}
										>
											{areas.map((area, key) => (
												<MenuItem key={key} value={area}>
													{area}
												</MenuItem>
											))}
											</Select>*/}
                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="area"
                    size="small"
                    name="area"
                    disabled={!values.city}
                    options={areas || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Area"
                        error={
                          formik.touched.area && Boolean(formik.errors.area)
                        }
                        helperText={formik.touched.area && formik.errors.area}
                      />
                    )}
                    value={values.area}
                    onChange={handleZipCode}
                  />

                  {/* <TextField
											label="Area"
											name="area"
											value={values.area}
											onChange={handleArea}
											variant="outlined"
											size="small"
											error={Boolean(touched.area && errors.area)}
											helperText={touched.area && errors.area}
											fullWidth
										/> */}
                </Grid>
                <Grid item xs={4} mb={3}>
                  <TextField
                    label="Zip Code"
                    name="zipcode"
                    autoComplete="off"
                    value={values.zipcode}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 8 }}
                    error={Boolean(touched.zipcode && errors.zipcode)}
                    helperText={touched.zipcode && errors.zipcode}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={4} mb={3}>
                  <TextField
                    label="Website"
                    name="website"
                    autoComplete="off"
                    value={values.website}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 256 }}
                    error={Boolean(touched.website && errors.website)}
                    helperText={touched.website && errors.website}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3} ml={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        name="retentionBonus"
                        inputProps={{ "data-cy": "chk-hasMany-item" }}
                        size="small"
                        checked={values?.isCollegeDrive}
                        onChange={(e) => {
                          setFieldValue("isCollegeDrive", e?.target?.checked);
                        }}
                      />
                    }
                    label="Campus Placement Drive"
                  />
                </Grid>

                {paramsId && values.serchString ? (
                  <Grid item xs={4} mb={3}>
                    <TextField
                      label="Alias"
                      name="serchString"
                      autoComplete="off"
                      value={values.serchString}
                      onChange={handleChange}
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 256 }}
                      error={Boolean(touched.serchString && errors.serchString)}
                      helperText={touched.serchString && errors.serchString}
                      fullWidth
                      disabled
                    />
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Paper>
          </Container>
        </Card>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          my={3}
        >
          <Typography variant="h6" gutterBottom>
            Contact
          </Typography>
          <Button
            onClick={handleAddMoreContacts}
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add More Contact
          </Button>
        </Stack>

        {values.contact.map((contact, index) => {
          return (
            <Card key={index} className="holiday-table-grid" mb={5}>
              <Container maxWidth="xl">
                <Paper>
                  <Grid container spacing={1}>
                    <Grid item xs={3} my={2}>
                      <TextField
                        fullWidth
                        autoComplete="off"
                        label="Enter Contact Person"
                        onChange={handleChange}
                        {...getFieldProps(`contact[${index}].name`)}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 64 }}
                        error={Boolean(
                          touched?.contact &&
                            touched?.contact[index] &&
                            touched?.contact[index]?.name &&
                            errors?.contact &&
                            errors?.contact[index] &&
                            errors?.contact[index]?.name
                        )}
                        helperText={
                          touched?.contact &&
                          touched?.contact[index] &&
                          touched?.contact[index]?.name &&
                          errors?.contact &&
                          errors?.contact[index] &&
                          errors?.contact[index]?.name
                        }
                      />
                    </Grid>
                    <Grid item xs={3} my={2}>
                      <TextField
                        label="Email"
                        autoComplete="off"
                        onChange={handleChange}
                        {...getFieldProps(`contact[${index}].email`)}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 512 }}
                        error={Boolean(
                          touched?.contact &&
                            touched?.contact[index] &&
                            touched?.contact[index]?.email &&
                            errors?.contact &&
                            errors?.contact[index] &&
                            errors?.contact[index]?.email
                        )}
                        helperText={
                          touched?.contact &&
                          touched?.contact[index] &&
                          touched?.contact[index]?.email &&
                          errors?.contact &&
                          errors?.contact[index] &&
                          errors?.contact[index]?.email
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={3} my={2}>
                      <TextField
                        label="Number"
                        autoComplete="off"
                        onChange={handleChange}
                        {...getFieldProps(`contact[${index}].contactNumber`)}
                        variant="outlined"
                        size="small"
                        inputProps={{ maxLength: 10 }}
                        error={Boolean(
                          touched?.contact &&
                            touched?.contact[index] &&
                            touched?.contact[index]?.contactNumber &&
                            errors?.contact &&
                            errors?.contact[index] &&
                            errors?.contact[index]?.contactNumber
                        )}
                        helperText={
                          touched?.contact &&
                          touched?.contact[index] &&
                          touched?.contact[index]?.contactNumber &&
                          errors?.contact &&
                          errors?.contact[index] &&
                          errors?.contact[index]?.contactNumber
                        }
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={1} ml={4} my={2}>
                     <FormControlLabel
                        control={
                        <Switch
                        name="Primary"
                        size="small"
                        checked={values?.contact[index].isPrimaryContact}
                        onChange={(e) => {
                          setFieldValue(`contact[${index}].isPrimaryContact`, e?.target?.checked);
                          values?.contact?.map((val,idx)=>{
                            if(index!==idx){
                              setFieldValue(`contact[${idx}].isPrimaryContact`, false);
                            }
                          })
                        }}
                      />
                    }
                    label="Primary"
                  />
                   </Grid>
                    <Grid item xs={1} my={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="end"
                      >
                        {/* <Typography variant="h6">Enter details</Typography> */}
                        {values?.contact?.length > 1 &&  <IconButton
                          onClick={() => handleDeleteForm(index)}
                          color="error"
                        >
                          
                          <DeleteIcon />
                        </IconButton>}
                       
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              </Container>
            </Card>
          );
        })}
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddCollege,
    path: "/college/add",
    public: false,
    group: "tpa",
    layout: DashboardLayout,
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddCollege,
    path: "/college/add/:id",
    public: false,
    group: "tpa",
    layout: DashboardLayout,
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
