import { createAsyncThunk } from "@reduxjs/toolkit";
import { Axios } from "../../../service/axios";
import { toast } from "react-toastify";

export const getLinks = createAsyncThunk(
  "links/getLinks",
  async (params, { rejectWithValue }) => {
    try {
      let isSearchQuery = false;
      if (params.search && params.search !== "") {
        isSearchQuery = true;
      }
      const res = await Axios.get("/getLinks", {
        params,
        headers: {
          type: "view",
          module: "link",
        },
      });
      // toast.success(res.data.message);
      return {
        data: res.data.data,
        filter: params.filter || isSearchQuery,
      };
    } catch (error) {
      // toast.error(error?.response?.data?.message || error.message);
      return rejectWithValue(error.response.data.message || error.message);
    }
  }
);

export const addLink = createAsyncThunk(
  "links/addLink",
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post("/link/create", body, {
        headers: {
          type: "create",
          module: "link",
        },
      });
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);
export const getAllEmployeesDropdown = createAsyncThunk(
  "links/getAllEmployeesDropdown",
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getAllEmployeeDropdown");
      return res.data.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);
export const getLinkCount = createAsyncThunk(
  "links/getLinkCount",
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getLinksCount", {
        headers: {
          type: "view",
          module: "link",
        },
      });
      return res?.data?.data?.totalLinks;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);
export const updateLink = createAsyncThunk(
  "links/editLink",
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.put("/link/update", body, {
        headers: {
          type: "update", module: "link",id:body?.id
        },
      });
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    } 
  })
  export const deleteLink = createAsyncThunk(
    "links/deleteLink",
    async (body, { rejectWithValue }) => {
      try {
        const res = await Axios.delete("/link/delete", {
          headers: {
            type: "delete", module: "link",id:body?.id
          },
        });
        toast.success(res.data.message);
        return res.data;
      } catch (error) {
        return rejectWithValue(error?.response?.data || error.message);
      }
    }
  )