import React, { useEffect, useRef } from "react";
import DashboardLayout from "../../../layouts/dashboard";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Icon from "@mui/material/Icon";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { makeStyles, styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { useDispatch, useSelector } from "react-redux";
import { decryption } from "../../../utils/encodeString";
import {
  closeTicket,
  getHelpdeskTicketById,
  reOpenTicket,
  sendTicketResponse,
  ticketReply,
} from "../../../redux/helpdeskTickets/helpdeskTicketsThunk";
import { useState } from "react";
import Loader from "../../../components/Loader";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import { getHelpdeskCategoriesDropDown } from "../../../redux/helpdeskCategories/helpdeskCategoriesThunk";
import moment from "moment";
import Scrollbar from "../../../components/Scrollbar";
import TextField from "@mui/material/TextField";
import { values } from "lodash";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import localStorage from "../../../service/localStorage";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Tooltip from "@mui/material/Tooltip";
import Compressor from "compressorjs";
import { BASE_URL } from "../../../constants";
// import './ViewHelpDeskTicket.css';
import Chip from "@mui/material/Chip";
import { Autocomplete } from "@mui/material";
import { viewHelpDeskSchema } from "../../../validations/helpDeskSchema";
import { useConfirm } from "material-ui-confirm";

// function for accordian style
const Accordion = styled((props) => (
  <MuiAccordion elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    display: "none",
  },
}));

// function for accordian summary
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

// function for accordian details
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ViewHelpDeskTicket() {
  const confirm = useConfirm();

  const dispatch = useDispatch();
  const params = useParams();
  const [ticketId, setTicketId] = React.useState("");
  // const userId =decryption(JSON.parse((localStorage.getItem("userID"))));
  const userId = localStorage.getItem("employeeId");
  let roleId = localStorage.getItem("roleId");
  const employeeData = useSelector((state) => state.employee.allEmployeeData);
  const categoryData = useSelector(
    (state) => state.helpdeskCategories.categoryData
  );
  const attachmentsFileInput = useRef(null);

  const getStatusColor = (status) => {
    switch (status) {
      case "OPEN":
        return "primary";
      case "CLOSED":
        return "success";
      case "REOPEN":
        return "info";
      case "HIGH":
        return "error";
      case "MEDIUM":
        return "primary";
      case "LOW":
        return "success";
      default:
        return "default";
    }
  };

  // function for compression
  function compressFile(file, setFieldValue) {
    return new Promise((resolve, reject) => {
      new Compressor(file, {
        success(result) {
          resolve(result);
        },
        error(error) {
          reject(error);
        },
      });
    });
  }
  // function for uploading attachments
  const handleAttachmentsChange = async (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const formData = new FormData();
      formData.append("responseAttachments", file);
      setFieldValue("responseAttachments", [
        ...values.responseAttachments,
        file,
      ]);
    }
  };

  // function for deleting attachments
  const handleDeleteAttachments = (image, index) => {
    const temp = [...values.responseAttachments];
    temp.splice(index, 1);
    setFieldValue("responseAttachments", temp);
    const updatedFormData = new FormData();
    temp.forEach((file) => {
      updatedFormData.append("responseAttachments", file);
    });
  };

  const formik = useFormik({
    initialValues: {
      response: "",
      assignedTo: "",
      responseAttachments: [],
      // subject: "",
    },
    validationSchema: viewHelpDeskSchema,
    // on send button click
    onSubmit: async (values) => {
      try {
        const userId = localStorage.getItem("employeeId");
        const formData = new FormData();
        formData.append("assignedBy", userId);
        formData.append("assignedTo", values?.assignedTo);
        // formData.append("subject", values?.subject);
        formData.append("response", values.response);
        values.responseAttachments.forEach((file) => {
          formData.append("attachments", file);
        });
        // api call for sending response
        await dispatch(sendTicketResponse({ formData, id: data?.id })).unwrap();
        resetForm();
        await dispatch(getHelpdeskTicketById(decryption(params.id))).unwrap();
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    values,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    getFieldProps,
    resetForm,
    errors,
    touched,
  } = formik;

  const { data, loading, status, error } = useSelector(
    (state) => state.helpdeskTickets
  );

  // api call for getting helpdesk ticket, categories and employee

  useEffect(() => {
    const fetchData = async () => {
      try {
        const decodeParam = decryption(params.id);
        setTicketId(decodeParam);

        // Dispatch actions to fetch data
        await dispatch(getEmployeeForDropDown()).unwrap();
        await dispatch(getHelpdeskCategoriesDropDown()).unwrap();
        await dispatch(getHelpdeskTicketById(decodeParam)).unwrap();

        // Optionally handle the response if needed

      } catch (error) {
        // Handle error (e.g., display an error message)
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch, params])

  // setting values of assigned to
  useEffect(() => {
    // const userId =decryption(JSON.parse(localStorage.getItem("userID")));
    const userId = localStorage.getItem("employeeId");
    console.log("userId", userId);
    if (userId === data?.assignedBy) {
      setFieldValue("assignedTo", data?.assignedTo);
    } else {
      setFieldValue("assignedTo", data?.assignedBy);
    }
  }, [data]);

  // made lookup object for finding employee and category
  const employeeLookup = employeeData.reduce((lookup, employee) => {
    lookup[employee.employeeId] = employee;
    return lookup;
  }, {});
  const categoryLookup = categoryData.reduce((lookup, category) => {
    lookup[category.id] = category;
    return lookup;
  }, {});

  // getting names from ids
  const ticketData = {
    ...data,
    assignedByName:
      (employeeLookup[data?.assignedBy]?.firstName || "") +
      " " +
      (employeeLookup[data?.assignedBy]?.lastName || ""),
    assignedToName:
      (employeeLookup[data?.assignedTo]?.firstName || "") +
      " " +
      (employeeLookup[data?.assignedTo]?.lastName || ""),
    createdByName:
      (employeeLookup[data?.createdBy]?.firstName || "") +
      " " +
      (employeeLookup[data?.createdBy]?.lastName || ""),
    updatedByName:
      (employeeLookup[data?.updatedBy]?.firstName || "") +
      " " +
      (employeeLookup[data?.updatedBy]?.lastName || ""),
    category: categoryLookup[data?.categoryId]?.title,
    subCategory: categoryLookup[data?.categoryId]?.helpDeskSubCategories?.find(
      (e) => e.id === data?.subCategoryId
    )?.title,
    createdDate: moment(data?.createdDate).format(
      "ddd, MMM  D, YYYY, h:mm:ss A"
    ),
    reportingPersonName:
      (employeeLookup[data?.reporterPerson]?.firstName || "") +
      " " +
      (employeeLookup[data?.reporterPerson]?.lastName || ""),
  };

  // getting names from ids
  const ticketResponseData = (data?.helpDeskTicketResponses || [])
    .map((ticketResponse) => {
      return {
        ...ticketResponse,
        assignedByName:
          (employeeLookup[ticketResponse?.assignedBy]?.firstName || "") +
          " " +
          (employeeLookup[ticketResponse?.assignedBy]?.lastName || ""),
        assignedToName:
          (employeeLookup[ticketResponse?.assignedTo]?.firstName || "") +
          " " +
          (employeeLookup[ticketResponse?.assignedTo]?.lastName || ""),
        createdDate: moment(ticketResponse?.createdDate).format(
          "DD/MM/YYYY hh:mm:ss a"
        ),
      };
    })
    .sort(
      (a, b) =>
        moment(b?.createdDate, "DD/MM/YYYY hh:mm:ss a").toDate() -
        moment(a?.createdDate, "DD/MM/YYYY hh:mm:ss a").toDate()
    );

  // function for close ticket
  const handleCloseTicket = async () => {
    try {
      await confirm({ description: `Are you sure you want to close ticket?` });
      await dispatch(closeTicket(data?.id)).unwrap();
      await dispatch(getHelpdeskTicketById(ticketId)).unwrap();
    } catch (error) {
      toast.error(error.message);
    }
  };

  // function for reopen ticket
  const handleReOpenTicket = async () => {
    try {
      await confirm({ description: `Are you sure you want to reopen ticket?` });
      await dispatch(reOpenTicket(data?.id)).unwrap();
      await dispatch(getHelpdeskTicketById(ticketId)).unwrap();
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Support request: {data?.subject || ""}
          </Typography>
          <Stack direction="row">
            {(userId === data?.reporterPerson || Number(roleId) === 1) &&
              (data?.status === "OPEN" || data?.status === "REOPEN") && (
                <Button
                  variant="contained"
                  // to="/tickets"
                  onClick={handleCloseTicket}
                  startIcon={<CloseIcon />}
                  style={{ marginLeft: "10px", backgroundColor: "red" }}
                >
                  Close request
                </Button>
              )}
            {userId === data?.reporterPerson && data?.status === "CLOSED" && (
              <Button
                variant="contained"
                to="/tickets"
                startIcon={<SupportAgentIcon />}
                style={{ marginLeft: "10px" }}
                onClick={handleReOpenTicket}
              >
                Reopen request
              </Button>
            )}
            <Button
              variant="contained"
              component={RouterLink}
              to="/tickets"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          </Stack>
        </div>
      </Stack>
      <Grid container spacing={3} justifyContent={"space-between"}>
        <Grid item xs={4}>
          <Card sx={{ paddingX: 3, paddingY: 3 }}>
            {loading ? (
              <Loader />
            ) : (
              <>
                {" "}
                <Typography variant="h6" my={3}>
                  Overview
                </Typography>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <span>
                      <strong>Priority</strong>{" "}
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    {/* <span>{ticketData?.priority || ""}</span> */}
                    <Chip
                      color={getStatusColor(ticketData.priority)}
                      label={ticketData?.priority || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Status</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <Chip
                      color={getStatusColor(ticketData.status)}
                      label={ticketData?.status || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>State</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    {ticketData?.state === "NEW" ? (
                      <strong>
                        <span>{ticketData?.state || ""}</span>
                      </strong>
                    ) : (
                      <span>{ticketData?.state || ""}</span>
                    )}
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Support request ID</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.ticket || ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Created on</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.createdDate || ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Created by</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.createdByName || ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Reporter person</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.reportingPersonName || "-"}</span>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <span>
                      <strong>Contact method</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.contactMethod || ""}</span> */}
                  {/* <span>Email:pritee@cybercom.in</span> */}
                  {/* </Grid> */}
                  <Grid item xs={6}>
                    <span>
                      <strong>Category</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.category || ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Sub category</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.subCategory || ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>
                      <strong>Assigned to</strong>
                    </span>
                  </Grid>
                  <Grid item xs={6}>
                    <span>{ticketData?.assignedToName || ""}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      <strong>Assigned by</strong>
                    </Typography>
                  </Grid>
                  <Grid item xs={6} marginBottom="30px">
                    <span>{ticketData?.assignedByName || ""}</span>
                  </Grid>
                  {Array.isArray(ticketData?.attachments) &&
                    ticketData?.attachments &&
                    ticketData?.attachments?.length > 0 && (
                      <Grid item xs={6}>
                        <span>
                          <strong>Attachments</strong>
                        </span>
                      </Grid>
                    )}
                  <Grid item xs={12} marginBottom="30px">
                    <Grid Container>
                      {Array.isArray(ticketData?.attachments) &&
                        ticketData?.attachments?.length > 0 &&
                        ticketData?.attachments?.map((img, idx) => {
                          return (
                            <Grid
                              key={idx}
                              item
                              xs={12}
                              style={{
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                className="custome-uploadBox small profile-image-part"
                                onClick={(e) => {
                                  window.open(
                                    BASE_URL + "/" + img?.path,
                                    "_blank"
                                  );
                                }}
                              >
                                <>
                                  <InsertDriveFileIcon />
                                  <Tooltip title={img?.name}>
                                    <Typography
                                      variant="caption"
                                      style={{
                                        maxWidth: "210px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {img?.name}
                                    </Typography>
                                  </Tooltip>
                                </>
                              </div>
                            </Grid>
                          );
                        })}
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Card>
        </Grid>
        <Grid item xs={8} spacing={3}>
          {(userId === data?.assignedBy || userId === data?.assignedTo) &&
          data?.status !== "CLOSED" ? (
            <Grid item xs={12} marginBottom={3}>
              <Card sx={{ paddingX: 3, paddingY: 3 }}>
                {loading ? (
                  <Loader />
                ) : (
                  <>
                    {" "}
                    <Typography variant="h6" my={3}>
                      Response
                    </Typography>
                    <form>
                      <Grid container>
                        <Grid item xs={12}>
                          <Autocomplete
                            sx={{ marginBottom: "20px" }}
                            size="small"
                            fullWidth
                            options={employeeData || []}
                            {...getFieldProps(`assignedTo`)}
                            value={
                              employeeData?.find(
                                (x) => x?.employeeId === values.assignedTo
                              ) || null
                            }
                            getOptionLabel={(option) =>
                              option.firstName + " " + option.lastName || ""
                            }
                            renderOption={(props, option) => (
                              <li
                                {...props}
                                value={option?.employeeId}
                                key={option.id}
                              >
                                {option.firstName + " " + option.lastName || ""}
                              </li>
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "assignedTo",
                                newValue?.employeeId || ""
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Assigned To"
                                error={Boolean(
                                  touched?.assignedTo && errors?.assignedTo
                                )}
                                helperText={
                                  touched?.assignedTo && errors?.assignedTo
                                }
                              />
                            )}
                          />
                        </Grid>
                        {/* <Grid item xs={12}>
                          <TextField
                            sx={{ marginBottom: "20px" }}
                            id="outlined-basic"
                            autoComplete="off"
                            label="Subject"
                            variant="outlined"
                            size="small"
                            name="subject"
                            inputProps={{
                              "data-cy": "txt-title-department",
                            }}
                            FormHelperTextProps={{
                              "data-cy": "txt-title-err-department",
                            }}
                            fullWidth
                            value={values.subject}
                            onChange={handleChange}
                            error={Boolean(touched.subject && errors.subject)}
                            helperText={touched.subject && errors.subject}
                          />
                        </Grid> */}
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            multiline
                            label="Response"
                            minRows={4}
                            variant="outlined"
                            size="small"
                            name="response"
                            inputProps={{
                              inputComponent: TextareaAutosize,
                              maxLength: 512,
                              style: {
                                resize: "auto",
                                marginBottom: "20px",
                              },
                            }}
                            onChange={handleChange}
                            value={values.response}
                            error={Boolean(touched.response && errors.response)}
                            helperText={touched.response && errors.response}
                          />
                        </Grid>
                        <Grid item xs={12} marginTop={2}>
                          {data &&
                            data?.helpDeskCategory &&
                            data?.helpDeskCategory?.helpDeskCannedMessages &&
                            Array.isArray(
                              data?.helpDeskCategory?.helpDeskCannedMessages
                            ) &&
                            data?.helpDeskCategory?.helpDeskCannedMessages
                              ?.length > 0 &&
                            data?.helpDeskCategory?.helpDeskCannedMessages.map(
                              (msg, idx) => (
                                <Chip
                                  sx={{
                                    marginRight: "10px",
                                    marginBottom: "10px",
                                    cursor: "pointer",
                                    borderStyle: "dashed",
                                    borderColor: "#fda92d",
                                  }}
                                  label={msg?.template || ""}
                                  key={idx}
                                  variant="outlined"
                                  onClick={() => {
                                    // setFieldValue(
                                    //   "subject",
                                    //   msg?.subject || ""
                                    // );
                                    setFieldValue(
                                      "response",
                                      msg?.template || ""
                                    );
                                  }}
                                />
                              )
                            )}
                        </Grid>
                        <Grid container pt={3}>
                          <Grid item xs={12}>
                            <Stack mb={1}>
                              <Typography>
                                Attachments{" "}
                                {errors &&
                                  errors?.responseAttachments &&
                                  errors.responseAttachments.length > 0 && (
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                      }}
                                    >
                                      Only jpg, jpeg and pdf files are allowed.
                                    </span>
                                  )}
                              </Typography>
                            </Stack>
                            <div>
                              <Grid container>
                                {Array.isArray(values?.responseAttachments) &&
                                  values?.responseAttachments?.length > 0 &&
                                  values?.responseAttachments?.map(
                                    (img, idx) => {
                                      return (
                                        <Grid
                                          key={idx}
                                          item
                                          xs={2}
                                          style={{
                                            marginRight: "20px",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <div
                                            className="custome-uploadBox small profile-image-part"
                                            onClick={(e) => {
                                              if ("imageUrl" in img) {
                                                window.open(
                                                  BASE_URL +
                                                    "/" +
                                                    img?.imageUrl,
                                                  "_blank"
                                                );
                                              } else {
                                                window.open(
                                                  URL.createObjectURL(img),
                                                  "_blank"
                                                );
                                              }
                                            }}
                                          >
                                            <CancelRoundedIcon
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleDeleteAttachments(
                                                  img,
                                                  idx
                                                );
                                              }}
                                              className="closeIcon"
                                            />
                                            <>
                                              <InsertDriveFileIcon />
                                              <Tooltip title={img?.name}>
                                                <Typography
                                                  variant="caption"
                                                  style={{
                                                    maxWidth: "100px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {img?.name}
                                                </Typography>
                                              </Tooltip>
                                            </>
                                          </div>
                                        </Grid>
                                      );
                                    }
                                  )}
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    marginRight: "20px",
                                    marginTop: "10px",
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div
                                    className="custome-uploadBox small profile-image-part"
                                    onClick={(e) => {
                                      attachmentsFileInput.current.click();
                                    }}
                                  >
                                    <div className="p-image">
                                      <i className="upload-button">
                                        <AddCircleIcon />
                                      </i>
                                      <input
                                        ref={attachmentsFileInput}
                                        className="file-upload"
                                        type="file"
                                        accept=".pdf, .jpeg, .jpg,"
                                        onChange={(e) =>
                                          handleAttachmentsChange(e)
                                        }
                                      />
                                    </div>
                                  </div>
                                </Grid>
                              </Grid>
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item xs={2} marginTop={2} marginLeft={"auto"}>
                          <Button
                            variant="contained"
                            type="submit"
                            onClick={handleSubmit}
                            style={{ marginLeft: "10px" }}
                          >
                            Send
                          </Button>
                        </Grid>
                      </Grid>
                    </form>
                  </>
                )}
              </Card>
            </Grid>
          ) : null}

          <Grid item xs={12}>
            <Card sx={{ paddingX: 3, paddingY: 3 }}>
              {loading ? (
                <Loader />
              ) : Array.isArray(ticketResponseData) &&
                ticketResponseData.length > 0 ? (
                <>
                  <Grid item xs={12} height={"auto"}>
                    {ticketResponseData.length < 4 ? (
                      <>
                        {Array.isArray(ticketResponseData) &&
                          ticketResponseData.length > 0 &&
                          ticketResponseData?.map((log, index) => (
                            <Accordion defaultExpanded={true}>
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                {data.createdBy === log.assignedBy ? (
                                  <PersonIcon sx={{ marginRight: "3px" }} />
                                ) : (
                                  <SupervisorAccountIcon
                                    sx={{ marginRight: "3px" }}
                                  />
                                )}
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={8}>
                                    <Typography fontWeight="bold">
                                      {log?.assignedByName || ""}{" "}
                                      {"@" + log?.assignedToName || ""}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography
                                      textAlign="right"
                                      fontSize="small"
                                    >
                                      {log?.createdDate || ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {/* <p>Subject: {log?.subject || ""}</p> */}
                                      <p>Response: {log?.response || ""}</p>
                                    </Typography>
                                  </Grid>
                                  {Array.isArray(log?.attachments) &&
                                    log?.attachments?.length > 0 &&
                                    log?.attachments?.map((img, idx) => {
                                      return (
                                        <Grid
                                          key={idx}
                                          item
                                          xs={2}
                                          style={{
                                            marginRight: "20px",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <div
                                            className="custome-uploadBox small profile-image-part"
                                            onClick={(e) => {
                                              window.open(
                                                BASE_URL + "/" + img?.path,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <>
                                              <InsertDriveFileIcon />
                                              <Tooltip title={img?.name}>
                                                <Typography
                                                  variant="caption"
                                                  style={{
                                                    maxWidth: "100px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {img?.name}
                                                </Typography>
                                              </Tooltip>
                                            </>
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </>
                    ) : (
                      <Scrollbar
                        sx={{
                          height: "600px",
                        }}
                      >
                        {Array.isArray(ticketResponseData) &&
                          ticketResponseData.length > 0 &&
                          ticketResponseData?.map((log, index) => (
                            <Accordion defaultExpanded={true}>
                              <AccordionSummary
                                aria-controls="panel1d-content"
                                id="panel1d-header"
                              >
                                {data.createdBy === log.assignedBy ? (
                                  <PersonIcon sx={{ marginRight: "3px" }} />
                                ) : (
                                  <SupervisorAccountIcon
                                    sx={{ marginRight: "3px" }}
                                  />
                                )}
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item xs={8}>
                                    <Typography fontWeight="bold">
                                      {log?.assignedByName || ""}{" "}
                                      {"@" + log?.assignedToName || ""}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={4}>
                                    <Typography
                                      textAlign="right"
                                      fontSize="small"
                                    >
                                      {log?.createdDate || ""}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Typography>
                                      {/* <p>Subject: {log?.subject || ""}</p> */}
                                      <p>Response: {log?.response || ""}</p>
                                    </Typography>
                                  </Grid>
                                  {Array.isArray(log?.attachments) &&
                                    log?.attachments?.length > 0 &&
                                    log?.attachments?.map((img, idx) => {
                                      return (
                                        <Grid
                                          key={idx}
                                          item
                                          xs={2}
                                          style={{
                                            marginRight: "20px",
                                            marginTop: "10px",
                                            marginBottom: "10px",
                                          }}
                                        >
                                          <div
                                            className="custome-uploadBox small profile-image-part"
                                            onClick={(e) => {
                                              window.open(
                                                BASE_URL + "/" + img?.path,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <>
                                              <InsertDriveFileIcon />
                                              <Tooltip title={img?.name}>
                                                <Typography
                                                  variant="caption"
                                                  style={{
                                                    maxWidth: "100px",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                  }}
                                                >
                                                  {img?.name}
                                                </Typography>
                                              </Tooltip>
                                            </>
                                          </div>
                                        </Grid>
                                      );
                                    })}
                                </Grid>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </Scrollbar>
                    )}
                  </Grid>
                </>
              ) : (
                <Grid item xs={12}>
                  <Typography align="center">No response found</Typography>
                </Grid>
              )}{" "}
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

const componentConfig = {
  component: ViewHelpDeskTicket,
  path: "/tickets/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "Helpdesk",
  role: ["admin"],
};
export default componentConfig;
