import {
  Badge,
  Box,
  Button,
  Card,
  Chip,
  Container,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
  Modal
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DashboardLayout from "../../layouts/dashboard";
import AddIcon from "@mui/icons-material/Add";
import Paper from "@mui/material/Paper";
import { debounce } from "lodash";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import ItemRow from "./itemComponents/ItemRow";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { deleteIsp, getAllISP ,getAssestISPCount} from "../../redux/assestManagment/thunk";
import Loader from "../../components/Loader";
import EditIcon from "@mui/icons-material/Edit";
import { encryption } from "../../utils/encodeString";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import LanguageIcon from "@mui/icons-material/Language";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CallIcon from '@mui/icons-material/Call';
import {
  setCurrentPage,
  setLimit,
  setISPSortBy,
  setISPOrderBy,
} from "../../redux/assestManagment/assestManagmentSlice";
import CustomPagination from "../../components/Pagination";
import invertDirection from "../../utils/invertDirection";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { indianDateFormat } from "../../utils/dateFormat";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: 500,
  maxWidth: 500,
  // height: 500,
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  overflowY: "auto"
};

function Isp() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isp, setIsp] = useState();
  const ISPData = useSelector((state) => state.inventory.IspData);
  const ISPlimit = useSelector((state) => state.inventory.ISPlimit);
  const ISPCurrentPage = useSelector((state) => state.inventory.ISPCurrentPage);
  const ISPTotalRecords = useSelector(
    (state) => state.inventory.ISPTotalRecords
  );

  const ISPCount = useSelector(
    (state) => state.inventory.ISPCount
  )
  const ISPTotalPages = useSelector((state) => state.inventory.ISPTotalPages);
  const ISPSortBy = useSelector((state) => state.inventory.ISPSortBy);
  const ISPOrderBy = useSelector((state) => state.inventory.ISPOrderBy);
  // const {ISPlimit,
  //   ISPCurrentPage,
  //   ISPTotalRecords,
  //   ISPTotalPages} = useSelector((state) => state.inventory);
  const Ispstatus = useSelector((state) => state.inventory.Ispstatus);
  const loading = useSelector((state) => state.inventory.loading);
  const [filterdata, setFilterData] = useState({
    search: "",
  });
  const [filterChips, setFilterChips] = useState([]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("ispMaster")) {
      dispatch(
        getAllISP({
          limit: ISPlimit,
          page: ISPCurrentPage,
          sortBy: ISPSortBy,
          orderBy: ISPOrderBy,
          ...filterdata,
        })
      );
      navigate("/dashboard");
    }
  }, []);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  useEffect(() => {
    dispatch(
      getAllISP({
        limit: ISPlimit,
        page: ISPCurrentPage,
        sortBy: ISPSortBy,
        orderBy: ISPOrderBy,
        ...filterdata,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    // if (Ispstatus === "idle") {
    dispatch(
      getAllISP({
        limit: ISPlimit,
        page: ISPCurrentPage,
        sortBy: ISPSortBy,
        orderBy: ISPOrderBy,
        ...filterdata,
      })
    );
    // }
  }, [dispatch, ISPlimit, ISPCurrentPage, Ispstatus, ISPSortBy, ISPOrderBy]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    // dispatch(setCurrentPage({ page: 1}));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  let handleDelete = async (id) => {
    try {
      await confirm({
        description: "Are you sure want to delete this ISP?",
      });
      if (id) {
        let response = await dispatch(deleteIsp(id)).unwrap();
        toast.success(response.message);
      }
      dispatch(
        getAllISP({
          limit: ISPlimit,
          page: ISPCurrentPage,
          sortBy: ISPSortBy,
          orderBy: ISPOrderBy,
          ...filterdata,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleSorting = (columnName) => {
    dispatch(setISPSortBy({ sortBy: columnName }));
    dispatch(
      setISPOrderBy({
        orderBy: invertDirection(ISPSortBy === columnName, ISPOrderBy),
      })
    );
  };
  const [state, setState] = React.useState({
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleSubmit = async (search ,searchFlag) => {

    let newFilterdata = { ...filterdata };
    if (searchFlag) {
       newFilterdata = { ...filterdata, search: search };
       await dispatch(
        getAllISP({
          limit: ISPlimit,
          page: ISPCurrentPage,
          sortBy: ISPSortBy,
          orderBy: ISPOrderBy,
          ...newFilterdata,
        })
      );
    } else {
      await dispatch(
        getAllISP({
          limit: ISPlimit,
          page: ISPCurrentPage,
          sortBy: ISPSortBy,
          orderBy: ISPOrderBy,
          ...filterdata,
        })
      );
    }
    setFilterChips([])
    setState({ right: false });
    let temparr = [];
    Object.entries(newFilterdata).map(([key, value]) => {
      if (value !== "") {
        temparr = [
          ...temparr,
          {
            key: key,
            name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(
              /([a-z])([A-Z])/g,
              "$1 $2"
            ),
            value: value,
          },
        ];

        setFilterChips(temparr);
      }
      return { name: key, value: value };
    });
  };
  const handleSearch = async (e) => {
    
    setFilterData({
      ...filterdata,
      search: e.target.value,
    });
    handleSubmit(e.target.value ,true);
  };
  const debounceWithSearch = debounce(handleSearch, 300);
  const handleReset = async () => {
    setFilterData({
      search: "",
    });
      inputRef.current.value = "";
    await dispatch(
      getAllISP({
        limit: ISPlimit,
        page: ISPCurrentPage,
        sortBy: ISPSortBy,
        orderBy: ISPOrderBy,
        search: "",
      })
    );
    setFilterChips([]);
    setState({ right: false });
  };
  const onDeleteChip = async (value, length) => {
    setFilterChips([...filterChips.filter((val) => val.name !== value.name)]);
    setFilterData({ ...filterdata, [value.key]: "" });
     if (value?.key === "search") {
      inputRef.current.value = "";
    }
    await dispatch(
      getAllISP({
        limit: ISPlimit,
        page: ISPCurrentPage,
        sortBy: ISPSortBy,
        orderBy: ISPOrderBy,
        ...filterdata,
        [value.key]: "",
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAssestISPCount()).unwrap(); // Adjust according to your action's API
      } catch (error) {
        // Handle errors here
        console.error('Failed to fetch asset ISP count:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <Container maxWidth="xl" className="item-component">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          ISP
          <Typography variant="body2">
            Total Records : {ISPCount}
          </Typography>
        </Typography>
        <Stack direction="row">
          {getAddVisible("ispMaster") && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/isp/add"
              startIcon={<AddIcon />}
            >
              Add ISP
            </Button>
          )}
        </Stack>
        </div>
      </Stack>


      <Card className="custom-grid">
        <Container style={{ maxWidth: "100%" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container xs={12} pt={2} pb={2}>
            <Grid item xs={12}>
                          <TextField
                            label="Search By ISP Name/Data Limit/IP Address"
                            name="search"
                            variant="outlined"
                            size="small"
                            style={{
                              maxWidth: "100%",
                              width: "100%",
                              marginRight: "10px",
                            }}
                            inputRef={inputRef}
                            onChange={debounceWithSearch}
                          />
                        </Grid>
            </Grid>
          </form>
        </Container>
      </Card>

      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={`${chips.name}: ${chips.value}`}
              color="primary"
              size="small"
              key={chips}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>

      {filterChips?.length > 0 ? (
        <Typography variant="body2" mb={1}>
          {ISPTotalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}

      {/* {"true" ? (
        <TableCell align="center" width="100%" colSpan={10}>
          <Loader />
        </TableCell>
      ) : ( */}
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card className="custom-grid">
            {/* <TablePagination
          rowsPerPageOptions={[5, 10]}
          component="div"
          count={ISPTotalRecords}
          rowsPerPage={ISPlimit}
          page={ISPCurrentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
            {/* <CustomPagination
								totalPage={totalPages}
								totalCount={totalRecords}
								limit={limit}
								handleChangePage={handleChangePage}
								page={currentPage}
								rowsPerPageOptions={[10, 20, 40]}
								handleRowsPerPageChange={handleChangeRowsPerPage}
								filter={isSearchQuery ? true : false}
							/> */}

            <CustomPagination
              totalPage={ISPTotalPages}
              totalCount={ISPTotalRecords}
              limit={ISPlimit}
              handleChangePage={handleChangePage}
              page={ISPCurrentPage > 0 ? ISPCurrentPage - 1 : 0}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              // filter={isSearchQuery ? true : false}
            />
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={ISPSortBy === "ispName"}
                        direction={ISPSortBy === "ispName" ? ISPOrderBy : "asc"}
                        onClick={() => handleSorting("ispName")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>Data limit</TableCell>
                    {/* <TableCell>Support Person</TableCell> */}
                    <TableCell>Billing Cycle</TableCell>
                    <TableCell>Static IP</TableCell>

                    <TableCell>Portal Username</TableCell>
                    <TableCell>Tariff Plan</TableCell>
                    <TableCell>Portal</TableCell>
                    {getEditVisible("ispMaster") && (
                      <TableCell align="right" style={{ width: "100px" }}>
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>

                {loading ? (
                  <TableCell align="center" width="100%" colSpan={10}>
                    <Loader />
                  </TableCell>
                ) : (
                  <TableBody>
                    {ISPData.length > 0 ? (
                      ISPData.map((row) => (
                        <TableRow key={row.id}>
                          <TableCell style={{overflowWrap: "anywhere"}}>{row.ispName}</TableCell>
                          <TableCell>{row.dataLimitPerBillCycle}</TableCell>
                          {/* <TableCell>
                            {row?.supportPerson.map((e) => (
                              <>
                                <p>{`${e.name} (${e.phoneNumber})`}</p>
                              </>
                            ))}
                          </TableCell> */}
                          <TableCell>
                            {indianDateFormat(row.billingFrom)} -{" "}
                            {indianDateFormat(row.billingTo)}
                          </TableCell>
                          <TableCell>
                            {row?.staticIpDetail.map((e) => (
                              <>
                                <p>{`${e.staticIpAddress}`}</p>
                              </>
                            ))}
                          </TableCell>

                          <TableCell>{row.portalUsername}</TableCell>
                          <TableCell style={{overflowWrap: "anywhere"}}>{row.tariffPlanName}</TableCell>
                          <TableCell align="center">
                            <a target="_blank" href={row.portalUrl}>
                              <LanguageIcon color="primary" />
                            </a>
                          </TableCell>
                          {(getEditVisible("ispMaster") ||
                            getDeleteVisible("ispMaster")) && (
                            <TableCell>
                              <Stack direction="row">
                                {getEditVisible("ispMaster") && (
                                  <IconButton
                                    color="primary"
                                    // component={RouterLink}
                                    // to={`/isp/add/${encryption(row.id)}`}
                                    onClick={(e) => window.open(`/isp/add/${encryption(row.id)}`,"_blank") }
                                  >
                                    <EditIcon />
                                  </IconButton>
                                )}
                                <IconButton
                                  style={{ padding: "5px" }}
                                  // component={RouterLink}
                                  // to={`/isp/view/${encryption(row.id)}`}
                                  onClick={(e) => window.open(`/isp/view/${encryption(row.id)}`,"_blank") }
                                >
                                  <VisibilityIcon />
                                </IconButton>
                                {getDeleteVisible("ispMaster") && (
                                  <IconButton
                                    onClick={() => handleDelete(row.id)}
                                  >
                                    <DeleteIcon color="error" />
                                  </IconButton>
                                )}
                                <IconButton
                                    onClick={() => {handleOpen();setIsp(row?.supportPerson);}}
                                  >
                                    <CallIcon color="info" />
                                  </IconButton>
                              </Stack>
                            </TableCell>
                          )}
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell className="No-Record-text" colSpan={12} align="left">
                          No Record(s) Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <div className="view_employee candidate-details" style={{padding:"5px"}}>
                <Stack sx={style}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} className="grid_container">
                      <Grid
                        item
                        xs={12}
                        style={{ maxWidth: "100%", flexBasis: "100%" }}
                      >
                        <Typography variant="h6">Support Person(s)</Typography>
                        {Array.isArray(isp) && isp?.length > 0 ? 
                          isp?.map((i) => {
                            return (
                              <Card 
                                style={{ 
                                //   minHeight: "80%", 
                                marginTop:"10px", 
                                marginBottom:"10px", 
                                backgroundColor:"#f0f0f0" 
                                }} 
                                className="address-card information-card"
                              >                
                                <p>{i?.name} - {i?.phoneNumber}</p>
                              </Card>
                              )
                          })
                          : ""
                        }
                        
                      <Button variant="contained" style={{maxWidth:"50px"}} onClick={()=> handleClose()}>
                        Close
                      </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </div>
            </Modal>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

const componentConfig = {
  component: Isp,
  path: "/isp",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
