import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

import Loader from "../../../components/Loader";
import DashboardLayout from "../../../layouts/dashboard";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import DesktopTimePicker from "@mui/lab/DesktopTimePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker, DesktopTimePicker } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FormHelperText from "@mui/material/FormHelperText";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "@mui/material/Autocomplete";
import { decryption, encryption } from "../../../utils/encodeString";
import { getCandidateById } from "../../../redux/candidate/candidateThunk";
import { insertInterviewSchedule } from "../../../redux/scheduleInterview/scheduleInterviewThunk";
import { getCurrentMonthHolidayList } from "../../../redux/dashboard/dashboardThunk";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { getInterview } from "../../../redux/interview/interviewThunk";
import { getDesignation } from "../../../redux/designation/designationThunk";
import { getInterviewType } from "../../../redux/interviewType/interviewTypeThunk";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import scheduleInterviewSchema from "../../../validations/scheduleInterview";
import {
  getAvailableEmployeeForDropDown,
  getEmployeeForDropDown,
} from "../../../redux/employee/employeeThunk";
import { addMonths, getDay } from "../../../utils/formatTime";
import { getCandidateByStatus } from "../../../redux/candidateState/candidateStatethunk";
import { Box } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import localStorage from "../../../service/localStorage";
import { update } from "lodash";

function ScheduleInterview() {
  let { id } = useParams();
  if (id) id = decryption(id);
  // console.log(id, "dddqqq222");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const technologyData = useSelector((state) => state.technology);
  const interviewData = useSelector((state) => state.interview);
  const employeesStatus = useSelector((state) => state.employee.status);
  const employeeData = useSelector((state) => state.employee.allEmployeeData);
  const designationData = useSelector((state) => state.designation);
  const interviewTypeData = useSelector((state) => state.interviewType);
  const AuthName = useSelector((state) => state.auth.fullName);
  const [candidateTechnologies, setCandidateTechnology] = useState(null);
  const [loading, setIsLoading] = useState(false);
  const [loading2, setIsLoading2] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [offeredDateDisable, setOfferedDateDisable] = useState(true);
  const [selectDateDisable, setSelectDateDisable] = useState(true);
  const [tabValue, setTabValue] = React.useState(4);
  const [emp, setEmp] = useState();
  const [empIds, setEmpIds] = useState();
  const [interviewerData, setInterviewerData] = useState([])
  const [dateError,setDateError] = useState({});
  const [temp, setTemp] = useState([]);
  useEffect(() => {
    if(id===-1){
      navigate("/interviewlist");
    }
  }, [dispatch, id]);
  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
  };
  const stateData = useSelector((state) => state.candidateState.stateData);
  const interviewMode = [
    { id: "online", title: "Online" },
    { id: "offline", title: "Offline" },
  ];
  const durationOptions = ["30","45","60"];
  let [interviewListData, setInterviewListData] = useState([]);

  useEffect(() => {
    const fetchInterviewList = async () => {
      try {
        const response = await dispatch(
          getAvailableEmployeeForDropDown({
            interviewDate: '',
            interviewTime: '',
            duration: '',
          })
        ).unwrap();
        setInterviewListData(response.data ?? []);
      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching interview list data:", error);
        // toast.error(error.message); // Uncomment if you use a toast library
      }
    };

    fetchInterviewList();
  }, [dispatch]);
  const formik = useFormik({
    initialValues: {
      candidateName: "",
      candidateId: id,
      contactNumber: "",
      alternateContactNumber: "",
      email: "",
      linkedIn: "",
      offerDate: null,
      offeredDateDisable: "",
      joiningDate: null,
      reJoiningDate: null,
      productionDate: null,
      selectDateDisable: "",
      designationId: "",
      interviewDetails: [
        {
          id: null,
          interviewType: "",
          interviewDate: "",
          interviewTime: "",
          duration: "30",
          technologies: "",
          // comments: "",
          interviewMode: "",
          interviewrefrences: [],
        },
      ],
    },
    validationSchema: scheduleInterviewSchema,
    onSubmit: async (values) => {
      try {
        setIsLoading2(true);

        if (values.offerDate) {
          values.offerDate = moment(values.offerDate).format();
        } else {
          values.offerDate = null;
        }

        if (values.joiningDate) {
          values.joiningDate = moment(values.joiningDate).format();
        } else {
          values.joiningDate = null;
        }

        if (values.reJoiningDate) {
          values.reJoiningDate = moment(values.reJoiningDate).format();
        } else {
          values.reJoiningDate = null;
        }

        if (values.productionDate) {
          values.productionDate = moment(values.productionDate).format();
        } else {
          values.productionDate = null;
        }

        values.designationId = values.designationId.id;

        for (const details of values.interviewDetails) {
          if (moment(details.interviewTime).isValid()) {
            details.interviewTime = moment(details.interviewTime).format(
              "HH:mm:ss"
            );
          }
          details.interviewrefrences = details.interviewrefrences.map(
            (i) => i.id
          );
        }
        // const updatedInterviewDetails = values?.interviewDetails.map((detail, index) => ({
        //   ...detail,
        //   interviewType: temp[index] || detail.interviewType, // Use temp value if available
        //   // interviewType: `${temp[index] || detail.interviewType} ${detail.interviewMode || ""}`,

        // }));

        // const updatedInterviewDetails = values?.interviewDetails.map((detail, index) => {
        //   const combinedType = `${temp[index] || detail.interviewType}${detail.interviewMode ? ` ${detail.interviewMode}` : ''}`;
        //   return {
        //     ...detail,
        //     // Combine interview mode and type
        //     interviewType: combinedType.trim(), // Remove extra spaces
        //   };
        // });

        const updatedInterviewDetails = values?.interviewDetails.map((detail, index) => {
          const interviewType = temp[index] || detail.interviewType;
      
          // Split interview type into parts
          const typeParts = interviewType.split(' ');
      
          // Check if interview mode parts are already present in interview type
          const modeParts = detail.interviewMode ? detail.interviewMode.split(' ') : [];
          const filteredModeParts = modeParts.filter(modePart => !typeParts.includes(modePart));
      
          // Combine interview type and filtered interview mode parts
          const combinedType = `${interviewType}${filteredModeParts.length > 0 ? ` ${filteredModeParts.join(' ')}` : ''}`;
      
          return {
              ...detail,
              // Update interview type with combined value
              interviewType: combinedType.trim(), // Remove extra spaces
          };
      });
    
        const updatedValues = {
          ...values,
          interviewDetails: updatedInterviewDetails,
        };

        await dispatch(insertInterviewSchedule(updatedValues)).unwrap();
        /* navigate('/candidate') */
        setIsLoading2(false);
        // return navigate(-1);
        navigate("/interviewlist");
      } catch (error) {
        setIsLoading2(false);
        toast.error(error.message);
      }
    },
  });
  
  useEffect(() => {
    dispatch(getCandidateByStatus(4));
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     getEmployeeForDropDown({sortBy:'',orderBy:''}),
  //   );
	// }, [dispatch]);

  const {
    getFieldProps,
    setFieldValue,
    values,
    handleSubmit,
    handleBlur,
    handleChange,
    errors,
    touched,
    setErrors,
    setValues
  } = formik;

   useEffect(() => {
    const fetchInterviewList = async () => {
      try {
        const response = await dispatch(
          getAvailableEmployeeForDropDown({
            interviewDate: '',
            interviewTime: '',
            duration: '',
          })
        ).unwrap();
        setInterviewListData(response.data ?? []);

        // Uncomment and modify the following logic if needed
        /*
        const userId = Number(decryption(localStorage.getItem("userID")));
        const data = response?.data?.find((i) => i.id === userId);
        console.log(data, "pd");
        console.log(values.interviewDetails[values.interviewDetails.length - 1], "ppp", values.interviewDetails.length === 1);

        if (values.interviewDetails.length !== 1 && values.interviewDetails[values.interviewDetails.length - 1]?.interviewrefrences[0]?.id) {
          console.log('pp');
        } else {
          setFieldValue(
            `interviewDetails[${values.interviewDetails.length - 1}].interviewrefrences`,
            [data]
          );
        }

        if (values.contactNumber && values.contactNumber !== '' && values.interviewDetails[0].interviewrefrences[0].duration && values.interviewDetails[0].interviewrefrences[0].duration === null) {
          console.log(values.interviewDetails, "pqwqw");
          if (values.interviewDetails.length === 1 && values.interviewDetails[0].interviewrefrences.length === 0) {
            setFieldValue(`interviewDetails[0].interviewrefrences`, [data]);
          }
        }
        */

      } catch (error) {
        // Handle errors if needed
        console.error("Error fetching interview list data:", error);
        // toast.error(error.message); // Uncomment if you use a toast library
      }
    };

    fetchInterviewList();
  }, [dispatch])
  
  const mapInterviewIds = (values) => {
    let temp = []
    values.forEach((element, idx) => {
      const ids = element.interviewrefrences.map((itr) => itr.interviewer);
      temp = [...temp, ids[0]?.id]
      

      setFieldValue(`interviewDetails[${idx}]['interviewrefrences']`, ids);
      
      setFieldValue(
        `interviewDetails[${idx}]['interviewTime']`,
        moment(element.interviewTime, "HH:mm a")
      );

      setFieldValue(`interviewDetails[${idx}]['id']`, element?.id)
    });
    
    setEmpIds(temp)
  };

  /* const setDefaultValues = (values) => {
		const candidateTechnologies = [];

		values.experience.forEach((candidateData) => {
			candidateData.technologies.forEach((element) => {
				candidateTechnologies.push(element.technology.title);
			});
		});

		console.log(candidateTechnologies);
		setCandidateTechnology(candidateTechnologies);

		setFieldValue(`interviewDetails[0].technologies`, values.technologies);
	}; */

  const setLoginEmployee = async() => {
    let response = await dispatch(
      getAvailableEmployeeForDropDown({
        interviewDate: '',
        interviewTime:'',
        duration: '',
      })
    ).unwrap();
    setInterviewListData(response.data ?? []);
    
    let data = response?.data?.find((i) => i.id === Number(decryption(localStorage.getItem("userID"))));
    setFieldValue(`interviewDetails[${0}].interviewrefrences`,[data]);
    // if(values.contactNumber&&values.contactNumber!==''&&values.interviewDetails[0].interviewrefrences[0].duration&&values.interviewDetails[0].interviewrefrences[0].duration===null){
    //   console.log(values.interviewDetails,"pqwqw")
    // values.interviewDetails.length===1&&values.interviewDetails[0].interviewrefrences.length===0&&setFieldValue(`interviewDetails[${0}].interviewrefrences`,
    //   [data]);
    // }
  }

  // useEffect(async() => {
  //   if(interviewListData)
  //   {
  //     setInterviewerData(interviewListData)
  //   }
  //  }, [interviewListData]);

  useEffect(() => {
    const updateInterviewerData = () => {
      if (interviewListData.length > 0) {
        // Extracting ids from interviewrefrences
        const arr = values.interviewDetails.map((i) => i.interviewrefrences).flat();
        const newArr = arr.map((i) => i?.id);

        // Filter out the interviewListData based on the conditions
        const filteredData = interviewListData.filter(
          (item) => !newArr.includes(item.id)
        );

        setInterviewerData(filteredData);
      }
    };

    updateInterviewerData();
  }, [interviewListData, values]);

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      dispatch(getCandidateById(id))
        .unwrap()
        .then((res) => {
          setFieldValue(
            "candidateName",
            `${res?.data?.firstName} ${res?.data.lastName}`
          );
          setFieldValue("contactNumber", res?.data?.contactNumber);
          setFieldValue(
            "alternateContactNumber",
            res?.data?.alternateContactNumber
          );
          setFieldValue("email", res?.data?.email);
          setFieldValue("linkedIn", res?.data?.linkedIn);
          setCandidateData(res?.data);
          // console.log(res.data, "res.data.darshit");
          //   console.log(res.data.interviewSchedule, "darshit9123");
          /* console.log(
						res.data.interviewSchedule.interviewDetails[
							res.data.interviewSchedule.interviewDetails.length - 1
						].interviewStatus,
					); */
          if (res?.data?.interviewSchedule?.length === 0) {
            setLoginEmployee();
          }

          if (res?.data?.offerDate) {
            setOfferedDateDisable(false);
            setFieldValue("offerDate", res.data.offerDate);
          }

          if (res?.data?.joiningDate) {
            setOfferedDateDisable(false);
            setSelectDateDisable(false);
            setFieldValue("joiningDate", res.data.joiningDate);
          }

          if (res?.data?.reJoiningDate) {
            setOfferedDateDisable(false);
            setSelectDateDisable(false);
            setFieldValue("reJoiningDate", res.data.reJoiningDate);
          }

          if (res?.data?.productionDate) {
            setOfferedDateDisable(false);
            setSelectDateDisable(false);
            setFieldValue("productionDate", res.data.productionDate);
          }

          if (res?.data?.designation) {
            setFieldValue("designationId", res.data.designation);
          }
          let temp = res?.data?.interviewSchedule?.sort((a, b) => b.id - a.id);
          if (res?.data?.interviewSchedule?.length > 0) {
            setFieldValue("interviewDetails", res.data.interviewSchedule);
            mapInterviewIds(res.data.interviewSchedule);
          } else {
            // setFieldValue(`interviewDetails[0].interviewStatus`, 3);
          }
          if (res?.data?.interviewSchedule?.length > 0) {
            res?.data?.interviewSchedule?.map((element, idx) => {
              const combinedValue = element?.interviewType;
              const delimiter = " ";
              const parts = combinedValue.split(delimiter);

              const firstPortion = parts.length > 0 ? parts[0] : "";
              // Extract the second portion
              const secondPortion = parts.length > 1 ? parts[1] : "";

              setFieldValue(`interviewDetails[${idx}]['interviewType']`, firstPortion)
              setFieldValue(`interviewDetails[${idx}]['interviewMode']`, secondPortion)
            })
          }
          setIsLoading(false);
        })
        .catch((err) => {
         
          return navigate("/candidate");
        });
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !technologyData.complete &&
      !technologyData.loading &&
      !technologyData.error &&
      (!technologyData.data ||
        (Array.isArray(technologyData.data) && technologyData.data.length <= 0))
    )
      // dispatch(getTechnology());
      dispatch(getTechnology({sortBy:'',orderBy:''}));

  });

  useEffect(() => {
    if (
      !interviewData.complete &&
      !interviewData.loading &&
      !interviewData.error &&
      (!interviewData.data ||
        (Array.isArray(interviewData.data) && interviewData.data.length <= 0))
    )
      dispatch(getInterview());
  });

  useEffect(() => {
    // if (
    //   !interviewTypeData.complete &&
    //   !interviewTypeData.loading &&
    //   !interviewTypeData.error &&
    //   (!interviewTypeData.data ||
    //     (Array.isArray(interviewTypeData.data) &&
    //       interviewTypeData.data.length <= 0))
    // )
    dispatch(getTechnology({sortBy:'',orderBy:''}));
    dispatch(getInterview());
    dispatch(getDesignation());
      dispatch(getInterviewType({ sortBy:'', orderBy:'' }));
  },[dispatch]);

  useEffect(() => {
    if (
      !designationData.complete &&
      !designationData.loading &&
      !designationData.error &&
      (!designationData.data ||
        (Array.isArray(designationData.data) &&
          designationData.data.length <= 0))
    )
      dispatch(getDesignation());
  });

  /*  useEffect(() => {
        if (teamLeadStatus === "idle") {
            dispatch(getTeamLeadData());
        }
    }, [dispatch, teamLeadStatus]);

    useEffect(() => {
        if (projectManagerStatus === "idle") {
            dispatch(getProjectManagerData());
        }
    }, [dispatch, projectManagerStatus]); */

  useEffect(() => {
    if (employeesStatus === "idle") {
      dispatch(getEmployeeForDropDown({ is_interview: 1 }));
    }
  }, [dispatch, employeesStatus]);
  
  useEffect(() => {
    let myArray = AuthName.split(" ");
    let firstName = myArray[0];
    let lastName = myArray[1];
    // console.log(employeeData,"1",myArray);
    employeeData.map((emp) => {
      if(firstName == emp.firstName && lastName == emp.lastName)
      {
        // console.log(emp,"dar");
        setEmp(emp)
      }
    })
}, [dispatch, loading,employeeData]);

  const handleAddMoreRounds = () => {
    // setFieldValue("interviewDetails", [
    //   ...values.interviewDetails,
    //   {
    //     interviewType: "",
    //     interviewDate: "",
    //     interviewTime: "",
    //     duration: "",
    //     interviewMode: "",
    //     technologies: candidateTechnologies,
    //     comments: "",
    //     interviewrefrences: emp?[emp]:[],
    //     id: null,
    //   },
    // ]);
    // interviewerList(values.interviewDetails.length);
    const newRound = {
        interviewType: "",
        interviewDate: "",
        interviewTime: "",
        duration: "30",
        interviewMode: "",
        technologies: candidateTechnologies,
        // comments: "",
        interviewrefrences: emp?[emp]:[],
        id: null,
  }
    setValues(prevValues => ({
      ...prevValues,
      interviewDetails: [newRound, ...prevValues.interviewDetails]
    }));
  };

  const removeRounds = (index) => {
    let temp = values.interviewDetails?.sort((a, b) => b.id - a.id);

    // const newInterviewDetails = [...values.interviewDetails];
    const newInterviewDetails =[...temp];
    newInterviewDetails.pop(index, 1);
    setFieldValue("interviewDetails", newInterviewDetails);
  };

  const handleJoinAndProductionDate = (date) => {
    setFieldValue("joiningDate", date);
    let flag = false;
    let months = 15 > getDay(date) ? 3 : 4;

    let getDate = addMonths(date, months);
    dispatch(
      getCurrentMonthHolidayList({
        date: moment(getDate).format("YYYY-MM"),
      })
    )
      .unwrap()
      .then((data) => {
        if (data?.holidayData.length === 0) {
          return setFieldValue("productionDate", getDate);
        }
        data?.holidayData.sort(function (a, b) {
          return new Date(a.start) - new Date(b.start);
        });

        for (let index = 0; index < data?.holidayData.length; index++) {
          const element = data?.holidayData[index];
          if (
            moment(getDate).format("DD-MM-YYYY") !=
            moment(element.start).format("DD-MM-YYYY")
          ) {
            flag = true;
            setFieldValue("productionDate", getDate);
            break;
          } else {
            getDate = moment(getDate).add(1, "d");
          }
        }
        if (!flag) {
          setFieldValue("productionDate", getDate);
        }
      })
      .catch((err) => {
     
      });
  };
  
  const interviewerList = async (index) => {
    // alert(index);
    if (values?.interviewDetails &&
      (values?.interviewDetails[index]?.interviewDate ?? false) &&
      (values?.interviewDetails[index]?.interviewTime ?? false) &&
      (values?.interviewDetails[index]?.duration ?? false) && 
      (errors?.interviewDetails[index]?.interviewTime != "Invalid Interview Time" )
    ) {
      //   alert("ppppppp");
      let response = await dispatch(
        getAvailableEmployeeForDropDown({
          interviewDate: values?.interviewDetails[index]?.interviewDate,
          interviewTime: moment(
            values?.interviewDetails[index]?.interviewTime
          ).format("HH:mm:ss"),
          duration: values?.interviewDetails[index]?.duration,
        })
      ).unwrap();
      // setFieldValue(`interviewDetails[${index}].interviewrefrences`, []);
      setInterviewListData(response?.data ?? []);
    } else {
      setInterviewListData([]);
    }
  };

  const interviewerListForOpen = async (index) => {
    // alert(index);
    if (
      (values.interviewDetails[index]?.interviewDate ?? false) &&
      (values.interviewDetails[index]?.interviewTime ?? false) &&
      (values.interviewDetails[index]?.duration ?? false) 
      && (errors?.interviewDetails[index]?.interviewTime != "Invalid Interview Time" )
    ) {
      //   alert("ppppppp");
      let response = await dispatch(
        getAvailableEmployeeForDropDown({
          interviewDate: values.interviewDetails[index].interviewDate,
          interviewTime: moment(
            values.interviewDetails[index].interviewTime
          ).format("HH:mm:ss"),
          duration: values.interviewDetails[index].duration,
        })
      ).unwrap();
      //   setFieldValue(`interviewDetails[${index}].interviewrefrences`, []);
      setInterviewListData(response.data ?? []);
    } else {
      setInterviewListData([]);
    }
  };

  const interviewerListTab = async (index, duration) => {
    if (
      (values.interviewDetails[index]?.interviewDate ?? false) &&
      (values?.interviewDetails[index]?.interviewTime ?? false) &&
      (duration ?? false) && 
      (errors?.interviewDetails && errors?.interviewDetails[index]?.interviewTime != "Invalid Interview Time" )
    ) {
      let response = await dispatch(
        getAvailableEmployeeForDropDown({
          interviewDate: values.interviewDetails[index].interviewDate,
          interviewTime: moment(
            values.interviewDetails[index].interviewTime
          ).format("HH:mm:ss"),
          duration: duration,
        })
      ).unwrap();
      // setFieldValue(`interviewDetails[${index}].interviewrefrences`, []);
      setInterviewListData(response.data ?? []);
    } else {
      setInterviewListData([]);
    }
  };

  const onKeyDown = (e) => {
    e.preventDefault();
 };

  if (loading) {
    return <Loader></Loader>;
  }
  return (
    <Container className="EditInterview-Schedule-Container" maxWidth="xl">
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="title-button-wrapper EditInterview-Schedule-title">
            <Typography variant="h4" gutterBottom>
              {id ? "Edit Interview Schedule" : "Schedule Interview"}
            </Typography>

            <Stack className="Edit-buttons employee-header-right" direction="row" alignItems="center">
              <Button
                className="back-button"
                variant="contained"
                /* component={RouterLink}
                      to="/candidate" */
                onClick={() => navigate("/interviewlist")}
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to={`/candidate/add/${encryption(id)}`}
              style={{ marginRight: "0px" }}>
              <img src="/assets/images/edit-white-icon.svg" />
              Edit Candidate
            </Button>
            <LoadingButton
                loading={loading2}
                variant="contained"
                type="submit"
                sx={{ marginRight: "15px" }}
              >
                Save
              </LoadingButton>
              {/* <Button
                type="submit"
                variant="contained"
                sx={{ marginRight: "15px" }}
              >
                {loading2?<Loader></Loader>:'Save'}
              </Button> */}
            </Stack>
          </div>
        </Stack>
        <Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
          Candidate Details
        </Typography>
        <Card className="Edit-Interview-Candidate-Details-card Candidate-Details-card">
          <Grid className="Candidate-Details-grid" container spacing={3}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Candidate Name"
                name="candidateName"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 64 }}
                disabled
                {...getFieldProps("candidateName")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Contact No"
                name="contactNumber"
                variant="outlined"
                size="small"
                disabled
                {...getFieldProps("contactNumber")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Alternate Contact No"
                name="alternateContactNumber"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 10 }}
                disabled
                {...getFieldProps("alternateContactNumber")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                type="email"
                label="Email Id"
                name="email"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 512 }}
                disabled
                {...getFieldProps("email")}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Linkedin"
                name="linkedIn"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 1024 }}
                {...getFieldProps("linkedIn")}
                disabled
              />
            </Grid>
            {
              // offeredDateDisable  && selectDateDisable
              // ? (
              //   ""
              // ) :
              candidateData?.activity?.candidateState?.name ===
              "Offer Selected" ? (
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="Offered Date"
                        value={values.offerDate}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setFieldValue(`offerDate`, newValue);
                        }}
                        name="offerDate"
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={touched?.offerDate && errors?.offerDate}
                            error={Boolean(
                              touched?.offerDate && errors?.offerDate
                            )}
                            size="small"
                          />
                        )}
                        // disabled={selectDateDisable && offeredDateDisable}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              ) : (
                ""
              )
            }

            {
              // selectDateDisable
              //  ? (
              //   ""
              // ) :
              candidateData?.activity?.candidateState?.name ===
              "Offer Selected" ? (
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="Joining Date"
                        value={values.joiningDate}
                        inputFormat="dd/MM/yyyy"
                        name="joiningDate"
                        /* onChange={(newValue) => {
												setFieldValue(`joiningDate`, newValue);
											}} */
                        onChange={(newValue) =>
                          handleJoinAndProductionDate(newValue)
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={
                              touched?.joiningDate && errors?.joiningDate
                            }
                            error={Boolean(
                              touched?.joiningDate && errors?.joiningDate
                            )}
                            size="small"
                          />
                        )}
                        // disabled={selectDateDisable}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              ) : (
                ""
              )
            }

            {
              // selectDateDisable ? (
              //   ""
              // ) :
              candidateData?.activity?.candidateState?.name ===
              "Offer Selected" ? (
                <Grid item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="Production Date"
                        value={values.productionDate}
                        inputFormat="dd/MM/yyyy"
                        name="productionDate"
                        onChange={(newValue) => {
                          setFieldValue(`productionDate`, newValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            helperText={
                              touched?.productionDate && errors?.productionDate
                            }
                            error={Boolean(
                              touched?.productionDate && errors?.productionDate
                            )}
                            size="small"
                          />
                        )}
                        // disabled={selectDateDisable}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
              ) : (
                ""
              )
            }
            {candidateData?.activity?.candidateState?.name ===
            "Offer Selected" ? (
              <Grid item xs={4}>
                <FormControl
                  size="small"
                  sx={{ minWidth: 120 }}
                  fullWidth
                  error={Boolean(touched?.designation && errors?.designation)}
                >
                  {/*<InputLabel id="demo-multiple-name-label">
                        Interviewer Name
                      </InputLabel>
                       <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        multiple
                        defaultValue=""
                        label="Interviewer Name"
                        size="small"
                        {...getFieldProps(
                          `interviewDetails[${index}].interviewrefrences`
                        )}
                        >
                        {employeeData.map((item) => {
                                                   
                            return(
                            <MenuItem
                            key={item.id}
                            value={item.id}
                            >
                            {`${item.firstName} ${item.lastName}`}
                            </MenuItem>
                            )
                          })}
                        </Select> */}

                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    value={values.designationId}
                    options={designationData.data || []}
                    key={designationData.data.Id || []}
                    getOptionLabel={(option) => option?.title || ""}
                    name="designationId"
                    isOptionEqualToValue={(option, value) => option === value}
                    {...getFieldProps(`designationId`)}
                    onChange={(event, newValue) => {
                      setFieldValue(`designationId`, newValue);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Designation" />
                    )}
                    // disabled={selectDateDisable}
                  />

                  <FormHelperText style={{ margin: "5px 0 0 0" }}>
                    {touched?.designation && errors?.designation}
                  </FormHelperText>
                </FormControl>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Card>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          className="Interview-Details-title"
        >
          <Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
            Interview Details
          </Typography>
        
          <Button
            className="header-add-button"
            variant="contained"
            onClick={handleAddMoreRounds}
            // startIcon={<AddIcon />}
          >
            <img src="/assets/images/add-circle.svg" />
            Add Round
          </Button>
        </Stack>

        {values.interviewDetails &&
          values.interviewDetails.map((details, index) => {
            const roundNumber = values.interviewDetails.length - index;
            return (
              <Card className="edit-Interview-Details-card gray-box Employee_Detail_Content" sx={{ padding: "1rem", marginBottom: "2rem" }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={3}
                  className="edit-Interview-Details-card-title"
                >
                  <Typography marginBottom={2} variant="h6">{`Round ${
                    roundNumber
                  }`}</Typography>
                  <Grid item>
                    <Stack
                      direction="row"
                      justifyContent="end"
                      marginBottom={2}
                    >
                      {values &&
                        values.interviewDetails &&
                        Array.isArray(values.interviewDetails) &&
                        details.id == null && (
                          <IconButton onClick={(e) => removeRounds(index)}>
                            {/* <DeleteIcon color="error" /> */}
                            <img src="/assets/images/trash.svg" />
                          </IconButton>
                        )}
                    </Stack>
                  </Grid>
                </Stack>

                <Grid className="edit-Interview-grid-container" key={index} container spacing={3} pb={3}>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack>
                        <DesktopDatePicker
                          label="Date"
                          value={values?.interviewDetails[index]?.interviewDate || null}
                          minDate={new Date()}
                          inputFormat="dd/MM/yyyy"
                          name = {index == values?.interviewDetails?.length ? "newErr" : "" }
                          onChange={(newValue) => {
                            setFieldValue(
                              `interviewDetails[${index}].interviewDate`,
                              newValue || ""
                            );
                            interviewerList(index);
                          }}
                          
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              // onKeyDown={onKeyDown}
                              helperText={
                                touched?.interviewDetails &&
                                touched?.interviewDetails[index] &&
                                touched?.interviewDetails[index]
                                  ?.interviewDate &&
                                errors?.interviewDetails &&
                                errors?.interviewDetails[index] &&
                                errors?.interviewDetails[index]?.interviewDate
                              }
                              error={Boolean(
                                touched?.interviewDetails &&
                                  touched?.interviewDetails[index] &&
                                  touched?.interviewDetails[index]
                                    ?.interviewDate &&
                                  errors?.interviewDetails &&
                                  errors?.interviewDetails[index] &&
                                  errors?.interviewDetails[index]?.interviewDate
                              )}
                              size="small"
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <Stack>
                        <DesktopTimePicker
                          label="Time"
                          ampm={false}
                          minTime={new Date(values?.interviewDetails[index]?.interviewDate).toLocaleDateString() == new Date().toLocaleDateString() ? new Date().getTime() : ""}
                          value={values.interviewDetails[index].interviewTime || null}
                          fullWidth
                          onChange={(newValue) => {
                            setFieldValue(
                              `interviewDetails[${index}].interviewTime`,
                              newValue || ""
                            );
                            interviewerList(index);
                          }}
                          onBlur={(e) => {
                            interviewerList(index);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              helperText={
                                touched?.interviewDetails &&
                                touched?.interviewDetails[index] &&
                                touched?.interviewDetails[index]
                                  ?.interviewTime &&
                                errors?.interviewDetails &&
                                errors?.interviewDetails[index] &&
                                errors?.interviewDetails[index]?.interviewTime
                              }
                              error={Boolean(
                                touched?.interviewDetails &&
                                  touched?.interviewDetails[index] &&
                                  touched?.interviewDetails[index]
                                    ?.interviewTime &&
                                  errors?.interviewDetails &&
                                  errors?.interviewDetails[index] &&
                                  errors?.interviewDetails[index]?.interviewTime
                              )}
                              size="small"
                            />
                          )}
                        />
                      </Stack>
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={3}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      <Autocomplete
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        fullWidth
                        defaultValue="30"
                        options={durationOptions || []}
                        // {...getFieldProps(
                        //   `interviewDetails[${index}].duration`
                        // )}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            `interviewDetails[${index}].duration`,
                            newValue || ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.interviewDetails &&
                                touched?.interviewDetails[index] &&
                                touched?.interviewDetails[index]
                                  .duration &&
                                errors?.interviewDetails &&
                                errors?.interviewDetails[index] &&
                                errors?.interviewDetails[index].duration
                            )}
                            helperText={
                              touched?.interviewDetails &&
                              touched?.interviewDetails[index] &&
                              touched?.interviewDetails[index].duration &&
                              errors?.interviewDetails &&
                              errors?.interviewDetails[index] &&
                              errors?.interviewDetails[index].duration
                            }
                            label="Interview Duration In Minutes"
                          />
                        )}
                        onBlur={(e) => {
                          interviewerList(index);
                        }}
                        value={
                          durationOptions?.find(
                            (i) => i == values?.interviewDetails[index].duration
                          ) || ""
                        }
                        error={true}
                      />
                    </FormControl>
                    {/* <TextField
                      fullWidth
                      label="Interview Duration In Minutes"
                      name="duration"
                      variant="outlined"
                      size="small"
                      type="number"
                      onChange={(e) => {
                        setFieldValue(
                          `interviewDetails[${index}].duration`,
                          e.target.value
                        );
                      }}
                      // onBlur={handleBlur}
                      onBlur={(e) => {
                        interviewerList(index);
                      }}
                      InputLabelProps={{
                        shrink:
                          values.interviewDetails[index].duration ?? false,
                      }}
                      // onChange={handleChange}
                      inputProps={{ maxLength: 64 }}
                      value={values.interviewDetails[index].duration}
                      // error={Boolean(touched.duration && errors.duration)}
                      // helperText={touched.duration && errors.duration}
                      error={Boolean(
                        touched?.interviewDetails &&
                          touched?.interviewDetails[index] &&
                          touched?.interviewDetails[index]?.duration &&
                          errors?.interviewDetails &&
                          errors?.interviewDetails[index] &&
                          (errors?.interviewDetails[index]?.duration ?? false)
                      )}
                      helperText={
                        touched?.interviewDetails &&
                        touched?.interviewDetails[index] &&
                        touched?.interviewDetails[index]?.duration &&
                        errors?.interviewDetails &&
                        errors?.interviewDetails[index] &&
                        (errors?.interviewDetails[index]?.duration ?? "")
                      }
                    /> */}
                  </Grid>
                  {/* <Grid item xs={3}>
                    <Tabs
                      value={
                        values.interviewDetails[index].duration == 15
                          ? 0
                          : values.interviewDetails[index].duration == 30
                          ? 1
                          : values.interviewDetails[index].duration == 45
                          ? 2
                          : values.interviewDetails[index].duration == 60
                          ? 3
                          : 4
                      }
                      onChange={handleChangeTab}
                      variant="scrollable"
                      scrollButtons={false}
                      sx={{
                        "& .MuiTabs-indicator": {
                          display: "none",
                        },
                      }}
                      aria-label="scrollable prevent tabs example"
                    >
                      <Tab
                        sx={{
                          border: "1px solid",
                          borderTopLeftRadius: "10px",
                          borderBottomLeftRadius: "10px",
                        }}
                        label="15 MIN"
                        onClick={() => {
                          setFieldValue(
                            `interviewDetails[${index}].duration`,
                            15
                          );
                          interviewerListTab(index, 15);
                        }}
                      />
                      <Tab
                        sx={{ border: "1px solid" }}
                        label="30 MIN"
                        onClick={() => {
                          setFieldValue(
                            `interviewDetails[${index}].duration`,
                            30
                          );
                          interviewerListTab(index, 30);
                        }}
                      />
                      <Tab
                        sx={{
                          border: "1px solid",
                        }}
                        label="45 MIN"
                        onClick={() => {
                          setFieldValue(
                            `interviewDetails[${index}].duration`,
                            45
                          );
                          interviewerListTab(index, 45);
                        }}
                      />
                      <Tab
                        sx={{
                          border: "1px solid",
                          borderTopRightRadius: "10px",
                          borderBottomRightRadius: "10px",
                        }}
                        label="60 MIN"
                        onClick={() => {
                          setFieldValue(
                            `interviewDetails[${index}].duration`,
                            60
                          );
                          interviewerListTab(index, 60);
                        }}
                      />
                    </Tabs>
                  </Grid> */}
                  <Grid item xs={3}>
                    {
                      <FormControl
                        size="small"
                        sx={{ minWidth: 120 }}
                        fullWidth
                      >
                        {
                          <Autocomplete
                            size="small"
                            fullWidth
                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                            options={technologyData.data || []}
                            key={technologyData.data || []}
                            getOptionLabel={(option) => option?.title || ""}
                            {...getFieldProps(
                              `interviewDetails[${index}].technologies`
                            )}
                            onChange={(event, newValue) => {
                              setFieldValue(
                                `interviewDetails[${index}].technologies`,
                                newValue?.id || ""
                              );
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                error={Boolean(
                                  touched?.interviewDetails &&
                                    touched?.interviewDetails[index] &&
                                    touched?.interviewDetails[index]
                                      ?.technologies &&
                                    errors?.interviewDetails &&
                                    errors?.interviewDetails[index] &&
                                    errors?.interviewDetails[index]
                                      ?.technologies
                                )}
                                helperText={
                                  touched?.interviewDetails &&
                                  touched?.interviewDetails[index] &&
                                  touched?.interviewDetails[index]
                                    ?.technologies &&
                                  errors?.interviewDetails &&
                                  errors?.interviewDetails[index] &&
                                  errors?.interviewDetails[index]?.technologies
                                }
                                label="Technology"
                              />
                            )}
                            //value={values.interviewDetails[index].technologies}
                            value={technologyData.data.find(
                              (interview) =>
                                interview.id ===
                                values.interviewDetails[index].technologies
                            )}
                          />
                        }

                       
                      </FormControl>
                    }
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      
                      <Autocomplete
                        multiple
                        size="small"
                        fullWidth
                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                        // value={
												// 	values.interviewDetails[index].interviewrefrences
												// } 
                        onOpen={() => interviewerListForOpen(index)}
                        options={interviewerData ? interviewerData : interviewListData || []}
                        getOptionLabel={(option) => option ?
                          option?.firstName + " " + option?.lastName : ""
                        }
                        renderOption={(props, option) => (
                          <Box component="li" {...props}>
                            {option ? option?.firstName + " " + option?.lastName : ""}
                            {option && option.isBusy == true ? " - " : ""}
                            <div
                              style={{
                                color: "red",
                              }}
                            >
                              {option && option.isBusy == true ? "busy" : ""}
                            </div>
                          </Box>
                          // <li {...props} value={option.id} key={option.id}>
                          //   {option.firstName + " " + option.lastName || ""}
                          // </li>
                          
                        )}
                        {...getFieldProps(
                          `interviewDetails[${index}].interviewrefrences`
                        )}
                        onChange={(event, newValue) => {
                          // console.log("newValue", newValue);
                          setFieldValue(
                            `interviewDetails[${index}].interviewrefrences`,
                            newValue || ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.interviewDetails &&
                                touched?.interviewDetails[index] &&
                                touched?.interviewDetails[index]
                                  ?.interviewrefrences &&
                                errors?.interviewDetails &&
                                errors?.interviewDetails[index] &&
                                errors?.interviewDetails[index]
                                  ?.interviewrefrences
                            )}
                            helperText={
                              touched?.interviewDetails &&
                              touched?.interviewDetails[index] &&
                              touched?.interviewDetails[index]
                                ?.interviewrefrences &&
                              errors?.interviewDetails &&
                              errors?.interviewDetails[index] &&
                              errors?.interviewDetails[index]
                                ?.interviewrefrences
                            }
                            label="Interviewer Name"
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      <Autocomplete
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                        fullWidth
                        options={interviewTypeData.data || []}
                        getOptionLabel={(option) => option?.title || ""}
                        {...getFieldProps(
                          `interviewDetails[${index}].interviewType`
                        )}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            `interviewDetails[${index}].interviewType`,
                            newValue?.title || ""
                          );
                          // setTemp(newValue?.title ? `${newValue.title} ${values.interviewDetails[index].interviewMode || ""}` : "");
                          const updatedTemp = [...temp];
                          updatedTemp[index] = newValue?.title ? `${newValue?.title} ${values.interviewDetails[index].interviewMode || ""}` : "";
                          setTemp(updatedTemp);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.interviewDetails &&
                                touched?.interviewDetails[index] &&
                                touched?.interviewDetails[index]
                                  .interviewType &&
                                errors?.interviewDetails &&
                                errors?.interviewDetails[index] &&
                                errors?.interviewDetails[index].interviewType
                            )}
                            helperText={
                              touched?.interviewDetails &&
                              touched?.interviewDetails[index] &&
                              touched?.interviewDetails[index].interviewType &&
                              errors?.interviewDetails &&
                              errors?.interviewDetails[index] &&
                              errors?.interviewDetails[index].interviewType
                            }
                            label="Interview Type"
                          />
                        )}
                        //value={values.interviewDetails[index].interviewType}
                        value={
                          interviewTypeData.data.find(
                            (i) =>
                              i?.title ===
                              values.interviewDetails[index].interviewType
                          ) || ""
                        }
                        error={true}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                      <Autocomplete
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        fullWidth
                        popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                        options={interviewMode || []}
                        getOptionLabel={(option) => option?.title || ""}
                        {...getFieldProps(
                          `interviewDetails[${index}].interviewMode`
                        )}
                        onChange={(event, newValue) => {
                          setFieldValue(
                            `interviewDetails[${index}].interviewMode`,
                            newValue?.title || ""
                          );

                          const updatedTemp = [...temp];
                          updatedTemp[index] = values.interviewDetails[index].interviewType ? `${values.interviewDetails[index].interviewType} ${newValue?.title || ""}` : "";
                          setTemp(updatedTemp);
                          // setTemp(values.interviewDetails[index].interviewType ? `${values.interviewDetails[index].interviewType} ${newValue.title || ""}` : "");

                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            error={Boolean(
                              touched?.interviewDetails &&
                                touched?.interviewDetails[index] &&
                                touched?.interviewDetails[index]
                                  .interviewMode &&
                                errors?.interviewDetails &&
                                errors?.interviewDetails[index] &&
                                errors?.interviewDetails[index].interviewMode
                            )}
                            helperText={
                              touched?.interviewDetails &&
                              touched?.interviewDetails[index] &&
                              touched?.interviewDetails[index].interviewMode &&
                              errors?.interviewDetails &&
                              errors?.interviewDetails[index] &&
                              errors?.interviewDetails[index].interviewMode
                            }
                            label="Interview Mode"
                          />
                        )}
                        //value={values.interviewDetails[index].interviewType}
                        value={
                          interviewMode.find(
                            (i) =>
                              i?.title ===
                              values.interviewDetails[index].interviewMode
                          ) || ""
                        }
                        error={true}
                      />
                    </FormControl>
                  </Grid>
                  {/* <Grid item xs={3}>
                    <FormControl
                      error={Boolean(
                        touched?.interviewDetails &&
                          touched?.interviewDetails[index] &&
                          touched?.interviewDetails[index]?.comments &&
                          errors?.interviewDetails &&
                          errors?.interviewDetails[index] &&
                          errors?.interviewDetails[index]?.comments
                      )}
                      fullWidth
                    >
                      <TextareaAutosize
                        aria-label="Comments"
                        minRows={4}
                        placeholder="E.g. Good Communication Skill"
                        className="custom-textarea"
                        {...getFieldProps(
                          `interviewDetails[${index}].comments`
                        )}
                      />
                      <FormHelperText style={{ margin: "5px 0 0 0" }}>
                        {touched?.interviewDetails &&
                          touched?.interviewDetails[index] &&
                          touched?.interviewDetails[index]?.comments &&
                          errors?.interviewDetails &&
                          errors?.interviewDetails[index] &&
                          errors?.interviewDetails[index]?.comments}
                      </FormHelperText>
                    </FormControl>
                  </Grid> */}
                </Grid>
              </Card>
            );
          })}
        {/* <Stack direction="row" justifyContent="flex-end">
          <Button
            variant="contained"
            onClick={handleAddMoreRounds}
            startIcon={<AddIcon />}
          >
            Add Round
          </Button>
        </Stack> */}
      </form>
    </Container>
  );
}

const componentConfig = {
  component: ScheduleInterview,
  path: "/interviewlist/schedule-interview/:id",
  public: false,
  layout: DashboardLayout,
  group: "candidate",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
