import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import Scrollbar from "../../components/Scrollbar";
import CustomPagination from "../../components/Pagination";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Chip from "@mui/material/Chip";
import TableSortLabel from "@mui/material/TableSortLabel";
import invertDirection from "../../utils/invertDirection";
import Autocomplete from "@mui/material/Autocomplete";
import { getActivity } from "../../redux/activity/activityThunk";
import {
  exportCandidateFile,
  getCandidate,
  getTotalCandidateCount,
} from "../../redux/candidate/candidateThunk";
import { encryption } from "../../utils/encodeString";
import {
  setLimit,
  setPage,
  setSortBy,
  setOrderBy,
} from "../../redux/candidate/candidateSlice";
import { getTechnology } from "../../redux/technology/technologyThunk";
import { getCompany } from "../../redux/company/companyThunk";
import Switch from "@mui/material/Switch";
import { Badge } from "@mui/material";
import {
  getCandidateByStatus,
  getCandidateStatus,
} from "../../redux/candidateState/candidateStatethunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { ImportCandidates } from "./CandidateComponent/ImportCandidates";
import { BASE_URL } from "../../constants";
import { LoadingButton } from "@mui/lab";
import LocalStorage from "../../service/localStorage";
// import LocalStorage from "../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../redux/userPermission/userPermissionthunk";
function Candidate() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const data = useSelector(
    (state) => state.candidate.data || state.candidateState.data
  );
  const loading = useSelector((state) => state.candidate.loading);
  const AuthRole = useSelector((state) => state.auth.role);
  const limit = useSelector((state) => state.candidate.limit);
  const page = useSelector((state) => state.candidate.page);
  const totalPage = useSelector((state) => state.candidate.totalPage);
  const status = useSelector((state) => state.candidate.status);
  const error = useSelector((state) => state.candidate.error);
  const totalCount = useSelector((state) => state.candidate.totalCount);
  const sortBy = useSelector((state) => state.candidate.sortBy);
  const orderBy = useSelector((state) => state.candidate.orderBy);
  const activityData = useSelector((state) => state.activity);
  const statusData = useSelector((state) => state.candidateState.statusData);
  const stateData = useSelector((state) => state.candidateState.stateData);
  let [lodar, setLodar] = useState(false);
  let [lodar2, setLodar2] = useState(false);
  const totalCandidateCount = useSelector(
    (state) => state.candidateCount.totalCounts
  );
  // const totalCandidateCount = useSelector(
  //   (state) => state.candidate.totalCount
  // );

  const technology = useSelector((state) => state.technology);
  const company = useSelector((state) => state.company);

  const [filterChips, setFilterChips] = useState([]);

  let params = location.state;
  const [queryFilter, setQueryFilter] = useState({});
  const inputRef = React.useRef(null);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("candidates")) {
      dispatch(getCandidateStatus());
      navigate("/dashboard");
    }
  }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      search: "",
      technology: "",
      company: "",
      candidateStatusId: "",
      candidateStateId: [],
      wishlist: 0,
      experienceFrom: "",
      experienceTo: "",
      isPhoneNumber: 0,
    },
    onSubmit: async (values) => {
      const isEmpty = Object.values(values).every(
        (x) => x === null || x === "" || x === 0
      );

      if (isEmpty) {
        handleReset();
      }

      let queryParams = { ...values };
      try {
        if (queryParams.candidateStateId.length > 0) {
          queryParams["candidateStateId"] = queryParams.candidateStateId.map(
            (s) => s.id
          );
        } else {
          values["candidateStateId"] = "";
        }
        // if (values.candidateStatusId ?? false) {
        //   values["candidateStatusId"] = values.candidateStateId.id;
        // } else {
        //   values["candidateStateId"] = "";
        // }
        queryParams.candidateStatusId = queryParams.candidateStatusId[0]
          ? queryParams.candidateStatusId.map((item) => item.id)
          : "";
        dispatch(
          getCandidate({
            ...queryParams,
            limit,
            page: page + 1,
            orderBy,
            sortBy,
          })
        );
        setQueryFilter(queryParams);
        prepareChips(values);
        setState({ ...state, right: false });
        // toggleDrawer("right", false);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  // let filterDataFetch = () => {
  //   dispatch(getCandidateByStatus(params.candidateStatusId));
  //   filterLabel();
  // };

  useEffect(() => {
    if (params?.candidateStatusId) {
      dispatch(getCandidateByStatus(params.candidateStatusId))
        .unwrap()
        .then((res) => {
          // filterLabel(res, params.candidateStatusId);
        })
        .catch((err) => {});

      setFieldValue(
        "candidateStatusId",
        statusData.filter((item) => item.id === params.candidateStatusId)
      );

      handleSubmit({
        ...values,
        candidateStatusId: statusData.filter(
          (item) => item.id === params.candidateStatusId
        ),
      });
    } else {
      dispatch(
        getCandidate({
          limit,
          page: page + 1,
          orderBy,
          sortBy,
          ...queryFilter,
          search: searchValue,
        })
      );
    }
  }, [dispatch, limit, page, sortBy, orderBy, params, statusData]);

  useEffect(() => {
    if (Array.isArray(statusData) && statusData.length <= 0)
      dispatch(getCandidateStatus());
  }, [dispatch]);

  useEffect(() => {
    // if (values.candidateStatusId ?? false) {
    //   dispatch(getCandidateByStatus(values.candidateStatusId.id));
    // }
    if (values.candidateStatusId && values.candidateStatusId[0]) {
      let ids = values.candidateStatusId.map((item) => item.id);
      dispatch(getCandidateByStatus(ids));
    }
  }, [dispatch, values.candidateStatusId]);

  useEffect(() => {
    return () => {
      resetForm();
      /* dispatch(getCandidate({ limit, page: 1 })); */
      dispatch(setPage({ page: 0 }));
      setState({ ...state, right: false });
      setFilterChips([]);
      setQueryFilter({});
    };
  }, [dispatch]);

  useEffect(() => {
    if (!error) {
      dispatch(getTotalCandidateCount({}));
    }
  }, [error]);

  useEffect(() => {
    if (
      !technology.loading &&
      !technology.error &&
      (!technology.data ||
        (Array.isArray(technology.data) && technology.data.length <= 0))
    )
      dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  }, [technology]);

  // useEffect(() => {
  //   console.log('tttt');
  //     dispatch(getTechnology({sortBy:'',orderBy:''})).then((res)=>{
  //       console.log(res,"12");
  //     });
  // }, []);

  useEffect(() => {
    if (
      !activityData.complete &&
      !activityData.loading &&
      !activityData.error &&
      (!activityData.data ||
        (Array.isArray(activityData.data) && activityData.data.length <= 0))
    )
      dispatch(getActivity());
  }, [activityData, dispatch]);

  useEffect(() => {
    dispatch(getTechnology({ sortBy: "", orderBy: "" }));
    dispatch(getActivity());
  }, [dispatch]);

  useEffect(() => {
    if (company.statusForDropDown === "idle") {
      getViewVisible("company") && dispatch(getCompany());
    }
  }, [dispatch]);

  /*  useEffect(() => {
    if (technology.statusForDropDown === "idle") dispatch(getTechnology());
  }, [dispatch]); */

  // sidebar-part functionality
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleReset = () => {
    navigate(location?.pathname, { replace: true });
    // setParams({});
    resetForm();
    dispatch(getCandidate({ limit, orderBy, sortBy, page: page + 1 }));

    setState({ ...state, right: false });
    setFilterChips([]);
  };

  // let filterLabel = (AllState, candidateStatusId) => {
  //   // console.log(statusData);

  //   const res =
  //     statusData.length === 0
  //       ? {
  //           id: 1,
  //           name: "Lead",
  //           isActive: true,
  //           createdDate: "2022-09-20T05:50:00.000Z",
  //           updatedDate: "2022-09-20T05:50:00.000Z",
  //           deletedDate: null,
  //         }
  //       : statusData.find((tech) => tech.id === 1);

  //   // tech.id == candidateStatusId);
  //   /* console.log(res); */
  //   let tempChips = [];

  //   let status = `Status : ${res?.name}`;
  //   let state1 = `State : ${AllState.map((s) => s.name).join(",")}`;
  //   tempChips = [
  //     ...tempChips,
  //     { type: "candidateStatusId", filter: status },
  //     // { type: "candidateStateId", filter: state },
  //   ];
  //   setFilterChips(tempChips);
  //   // let newValue = {
  //   //   ...values,
  //   // };
  //   // newValue["candidateStatusId"] = res;
  //   // newValue["candidateStateId"] = stateData;
  //   setFieldValue("candidateStatusId", res);
  //   // setFieldValue("candidateStateId", stateData);
  //   // setFieldValue({ ...newValue });
  // };

  const prepareChips = (values) => {
    let filters = { ...values };
    let tempChips = [];

    for (const key in filters) {
      /* if (filters[key] && key === "interviewDate") {
        filters[key] = moment(filters[key], "DD/MM/yyyy").format("DD/MM/yyyy");
      } */

      if (filters[key] && key === "technology") {
        const res = technology.data.find((tech) => tech.id === filters[key]);
        if (res) filters[key] = res.title;
      }

      if (filters[key] && key === "candidateStatusId") {
        let filterKey = filters[key].map((candidate) => candidate.id);
        const res = statusData.filter((status) =>
          filterKey.includes(status.id)
        );
        if (res && res.length > 0)
          filters[key] = `Status : ${res.map((s) => s.name).join(",")}`;
      }
      if (filters[key] && key === "candidateStateId") {
        let filterKey = filters[key].map((candidate) => candidate.id);
        const res = stateData.filter((status) => filterKey.includes(status.id));
        if (res && res.length > 0)
          filters[key] = `State : ${res.map((s) => s.name).join(",")}`;
        // filters[key] = `Status : ${values.candidateStatusId.id}`;
      }
      if (filters[key] && key === "company") {
        const res = company.companyForDropdown.find(
          (tech) => tech.id === filters[key]
        );
        if (res) filters[key] = res.name;
      }

      if (filters[key] && key === "wishlist") {
        filters[key] = "wishlist";
      }

      if (filters[key] && key === "isPhoneNumber") {
        filters[key] = "Phone Number";
      }

      if (filters[key] && key === "search") {
        filters["search"] = `${filters[key]}`;
      }

      if (filters[key] && key === "experienceFrom") {
        filters["experienceFrom"] = `Experience From : ${filters[key]}`;
      }

      if (filters[key] && key === "experienceTo") {
        filters["experienceTo"] = `Experience To : ${filters[key]}`;
      }

      if (filters[key]) {
        if (key === "candidateStateId" && !Array.isArray(filters[key])) {
          tempChips = [...tempChips, { type: key, filter: filters[key] }];
        }
        else if(key === "candidateStatusId" && !Array.isArray(filters[key])) {
          tempChips = [...tempChips, { type: key, filter: filters[key] }];
        }
        if (key !== "candidateStateId" && key !== "candidateStatusId") {
          tempChips = [...tempChips, { type: key, filter: filters[key] }];
        }
      }
    }

    setFilterChips(tempChips);
    // console.log("filterChips", filterChips)
  };

  const onDeleteChip = (value, length) => {
    if (
      value.type === "candidateStatusId" ||
      value.type === "candidateStateId"
    ) {
      let filterArray = [...filterChips];
      let filterData = filterArray.filter((val) =>
        // val.type !== "candidateStatusId" && val.type !== "candidateStateId"
        value.type === "candidateStatusId"
          ? val.type !== "candidateStatusId" && val.type !== "candidateStateId"
          : val.type !== value.type
      );
      setFilterChips([...filterData]);
      if (value.type === "candidateStatusId") {
        setFieldValue("candidateStatusId", "");
        setFieldValue("candidateStateId", []);
      } else if (value.type === "candidateStateId") {
        setFieldValue("candidateStateId", []);
      }

      navigate(location?.pathname, { replace: true });
      // setParams({});
      let queryParams = { ...values };
      let filters =
        value.type === "candidateStatusId"
          ? { ...queryParams, candidateStatusId: "", candidateStateId: "" }
          : value.type === "candidateStateId"
          ? {
              ...queryParams,
              candidateStateId: "",
              candidateStatusId: queryParams.candidateStatusId.map(
                (item) => item.id
              ),
            }
          : "";
      setQueryFilter(filters);
      if (filterData.length < 1) {
        dispatch(getCandidate({ limit, orderBy, sortBy, page: page + 1 }));
      } else {
        dispatch(
          getCandidate({ ...filters, orderBy, sortBy, limit, page: page + 1 })
        );
      }
    } else {
      setFilterChips([
        ...filterChips.filter((val) => val.filter !== value.filter),
      ]);
      setFieldValue(value.type, "");
      let queryParams = { ...values };
      if (queryParams.candidateStateId.length > 0) {
        queryParams["candidateStateId"] = queryParams.candidateStateId.map(
          (s) => s.id
        );
      } else {
        values["candidateStateId"] = "";
      }
      if (queryParams.candidateStatusId.length > 0) {
        queryParams.candidateStatusId = queryParams.candidateStatusId.map(
          (item) => item.id
        );
      } else {
        values["candidateStatusId"] = "";
      }
      // queryParams.candidateStatusId = queryParams.candidateStatusId.id;
      let filters = { ...queryParams, [value["type"]]: "" };

      setQueryFilter(filters);
      if (length == 1) {
        dispatch(getCandidate({ limit, orderBy, sortBy, page: page + 1 }));
      } else {
        dispatch(
          getCandidate({ ...filters, orderBy, sortBy, limit, page: page + 1 })
        );
      }
    }
    if (value.type === "search") {
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

  const getData = (ExpArr) => {
    // Case 1: No Experience -  - on list page
    // Case 2: 1 exp then - show that exp details
    // Case 3: multiple exp with present - show present data
    // Case 4: multiple exp without any present - show latest exp details

    let currTechArr = [];
    let CompId;
    let latestExp;

    if (ExpArr?.length > 0) {
      latestExp = ExpArr.reduce((m, v, i) => (v.to > m.to && i ? v : m));

      ExpArr.map((exp, idx) => {
        if (ExpArr.length === 1) {
          currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
          CompId = exp.companyId;

          return "";
        } else if (ExpArr.length > 1) {
          if (exp.to === null) {
            currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
            CompId = exp.companyId;
          } else if (latestExp.to === exp.to) {
            currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
            CompId = exp.companyId;
          }
        }

        return { currTechArr, CompId };
      });
    }

    let finalTech = technology.data
      .filter((t) => currTechArr.includes(t.id))
      .map((t) => t.title);

    let companyName = company?.companyForDropdown?.find((c) => {
      if (c.id === CompId) return c.name;
    });

    return {
      // Technology: finalTech.join(",") ? finalTech.join(",") : "-",
      Technology: finalTech[0] ? finalTech[0] : "-",
      companyName: companyName?.name ? companyName?.name : "-",
    };
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    handleSubmit();
    // setSearchValue(e?.target?.value);
    // if (e?.target?.value != "") {
    //   setIsSearching(true);
    //   await dispatch(
    //     getCandidate({
    //       search: e?.target?.value,
    //       limit,
    //       page: page + 1,
    //       orderBy,
    //       sortBy,
    //       ...params,
    //     })
    //   );
    // } else {
    //   setIsSearching(false);
    //   await dispatch(
    //     getCandidate({
    //       limit,
    //       page: page + 1,
    //       orderBy,
    //       sortBy,
    //       ...params,
    //     })
    //   );
    // }
  };

  let boldtext = (text) => {
    return <span style={{ fontWeight: "bold" }}>{text}</span>;
  };

  const exportFile = async () => {
    setLodar(true);

    let queryParams = { ...values };
    try {
      if (queryParams.candidateStateId.length > 0) {
        queryParams["candidateStateId"] = queryParams.candidateStateId.map(
          (s) => s.id
        );
      } else {
        values["candidateStateId"] = "";
      }
      // if (values.candidateStatusId ?? false) {
      //   values["candidateStatusId"] = values.candidateStateId.id;
      // } else {
      //   values["candidateStateId"] = "";
      // }
      queryParams.candidateStatusId = queryParams.candidateStatusId[0]
        ? queryParams.candidateStatusId.map((item) => item.id)
        : "";
      const response = await dispatch(
        exportCandidateFile({ ...queryParams, limit, page: page + 1 })
      )
        .unwrap()
        .then((response) => {
          const url = `${BASE_URL}/${response.data}`;
          const link = document.createElement("a");
          link.href = url;
          link.click();
          setLodar(false);
        });
      // dispatch(getCandidate({ ...queryParams, limit, page: page + 1 }));
      // setQueryFilter(queryParams);
      // prepareChips(values);
      // setState({ ...state, right: false });
      // toggleDrawer("right", false);
    } catch (error) {
      setLodar(false);
      toast.error(error?.message);
    }
  };

  const openExcelModal = async () => {
    const modalResponse = await NiceModal.show(ImportCandidates);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const handleFilters = async (field,newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
    setIsSearching(true);
  }

  return (
    <Container className="Employee-wrapper Candidates-Wrapper-Container" maxWidth="xl">
      <Stack
        className="Candidates-wrapper-detail"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
      <div className="title-button-wrapper Candidates-title-wrapper">
        <Typography variant="h4" gutterBottom>
          Candidates:  ({totalCandidateCount})
          {/* <Typography variant="body2">
            Total Records : 
          </Typography> */}
        </Typography>
       <Stack
          className="employee-header-right"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
           {/* {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <span
                className="filter-icon-part"
                onClick={toggleDrawer(anchor, true)}
              >
                <IconButton>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={filterChips.length !== 0 ? false : true}
                  >
                    <img src="/assets/images/filter.svg" />
                  </Badge>
                </IconButton>
              </span>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                style={{ zIndex: "1300" }}
              >
                <Box
                  pt={4}
                  px={3}
                  sx={{
                    width:
                      anchor === "top" || anchor === "bottom" ? "auto" : 400,
                  }}
                  role="presentation"
                >
                  <div className="content-filter-part">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <Typography variant="h6">Candidate Filter</Typography>
                      <span>
                        <IconButton onClick={toggleDrawer(anchor, false)}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Stack>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                            fullWidth
                            options={technology.data || []}
                            getOptionLabel={(option) => option.title || ""}
                            name="technology"
                            onChange={(event, newValue) => {
                              setFieldValue("technology", newValue?.id || "");
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Technology" />
                            )}
                            value={technology?.data?.find(
                              (t) => t.id === values.technology
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                            size="small"
                            fullWidth
                            options={statusData || []}
                            getOptionLabel={(option) => option.name || ""}
                            name="candidateStatusId"
                            onChange={(event, newValue) => {
                              setFieldValue("candidateStatusId", newValue);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Status" />
                            )}
                            value={values.candidateStatusId || []}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            multiple
                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                            size="small"
                            disabled={values.candidateStatusId?.length === 0}
                            fullWidth
                            options={stateData || []}
                            getOptionLabel={(option) =>
                              `${option.name} (${option.candidateStatus.name})` ||
                              ""
                            }
                            name="candidateStateId"
                            onChange={(event, newValue) => {
                              setFieldValue("candidateStateId", newValue);
                            }}
                            renderOption={(props, option) => (
                              <Box component="li" {...props}>
                                {`${option.name}  ` || ""}
                                <div
                                  style={{
                                    fontWeight: "bold",
                                    marginLeft: "5px",
                                  }}
                                >
                                  ({option.candidateStatus.name || ""})
                                </div>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField {...params} label="State" />
                            )}
                            value={values.candidateStateId || []}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                            size="small"
                            disabled={getViewVisible("company") === false}
                            fullWidth
                            options={company.companyForDropdown || []}
                            getOptionLabel={(option) => option.name || ""}
                            name="company"
                            onChange={(event, newValue) => {
                              setFieldValue("company", newValue?.id || "");
                            }}
                            renderInput={(params) => (
                              <TextField {...params} label="Company" />
                            )}
                            value={company?.companyForDropdown?.find(
                              (c) => c.id === values.company
                            )}
                          />
                        </Grid>
                      </Grid>
                      <Grid item xs={12} style={{ marginTop: "20px" }}>
                        <InputLabel
                          id="demo-simple-select-helper-label"
                          style={{ marginBottom: "10px" }}
                        >
                          Experience
                        </InputLabel>
                        <TextField
                          label="From"
                          name="experienceFrom"
                          variant="outlined"
                          size="small"
                          style={{ maxWidth: "80px", marginRight: "10px" }}
                          value={values.experienceFrom}
                          onChange={handleChange}
                        />
                        <TextField
                          label="To"
                          name="experienceTo"
                          variant="outlined"
                          size="small"
                          style={{ maxWidth: "80px" }}
                          value={values.experienceTo}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: "10px", marginBottom: "10px" }}
                      >
                        <Grid item xs={3}>
                          <InputLabel id="demo-simple-select-helper-label">
                            WishList
                          </InputLabel>
                          <Switch
                            checked={values.wishlist}
                            onChange={() =>
                              setFieldValue(
                                "wishlist",
                                values.wishlist === 0 ? 1 : 0
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <InputLabel id="demo-radio-buttons-group-label">
                            Phone Number
                          </InputLabel>

                          <Switch
                            checked={values.isPhoneNumber}
                            onChange={() =>
                              setFieldValue(
                                "isPhoneNumber",
                                values.isPhoneNumber === 0 ? 1 : 0
                              )
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          />
                        </Grid>
                      </Grid>

                      <Stack direction="row" justifyContent="flex-end" mb={3}>
                        <Button
                          variant="contained"
                          startIcon={<SearchIcon />}
                          type="submit"
                        >
                          Search
                        </Button>
                        <Button
                          variant="contained"
                          type="reset"
                          onClick={handleReset}
                          startIcon={<CachedIcon />}
                          sx={{ marginLeft: "10px" }}
                        >
                          Reset
                        </Button>
                      </Stack>
                    </form>
                  </div>
                </Box>
              </Drawer>
            </React.Fragment>
          ))}*/}
          {AuthRole === "Super Admin" ? (
            <>
              <Button
                variant="contained"
                onClick={() => openExcelModal()}
                // startIcon={<FileDownloadSharpIcon />}
                className="filter-icon-part"
              >
                <img src="/assets/images/import.svg" />
                Import
              </Button>
              <LoadingButton
                loading={lodar}
                onClick={exportFile}
                variant="contained"
                className="filter-icon-part"
              >
                <img src="/assets/images/export.svg" />
                Export
              </LoadingButton>
            </>
          ) : (
            ""
          )}
          {getAddVisible("candidates") && (
            <Button
              component={RouterLink}
              to="/candidate/add"
              variant="contained"
              // startIcon={<AddIcon />}
            >
              <img src="/assets/images/add-circle.svg" />
              Add Candidate
            </Button>
          )}
        </Stack> 
        </div>
      </Stack>

      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div className="employee-classification-right" style={{paddingTop:"20px"}}>
                <TextField
                  className="employee-search-detail"
                  fullWidth
                  label="Search by Name/Email/Phone Number"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={technology.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    name="technology"
                    onChange={(event, newValue) => {
                      handleFilters("technology", newValue?.id || "")
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Technology" />
                    )}
                    value={technology?.data?.find(
                      (t) => t.id === values.technology
                    ) || ""}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    multiple
                    size="small"
                    fullWidth
                    // options={activityData.data || []}
                    options={statusData || []}
                    getOptionLabel={(option) => option.name || ""}
                    name="candidateStatusId"
                    onChange={(event, newValue) => {
                      let tempArray = newValue.map((x) => x);
                      handleFilters("candidateStatusId", tempArray || "")
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    // value={values.candidateStatusId || []}
                    value={statusData.filter((x) =>
                      values.candidateStatusId.includes(x)
                    )}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    multiple
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    size="small"
                    autoComplete="off"
                    disabled={values.candidateStatusId?.length === 0}
                    fullWidth
                    // options={activityData.data || []}
                    options={stateData || []}
                    getOptionLabel={(option) =>
                      `${option.name} (${option.candidateStatus.name})` ||
                      ""
                    }
                    name="candidateStateId"
                    onChange={(event, newValue) => {
                      // console.log(newValue, "newValue");
                      handleFilters("candidateStateId", newValue)
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {`${option.name}  ` || ""}
                        <div
                          style={{
                            fontWeight: "bold",
                            marginLeft: "5px",
                          }}
                        >
                          ({option.candidateStatus.name || ""})
                        </div>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} label="State" autoComplete="off"/>
                    )}
                    value={values.candidateStateId || []}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    disabled={getViewVisible("company") === false}
                    fullWidth
                    options={company.companyForDropdown || []}
                    getOptionLabel={(option) => option.name || ""}
                    name="company"
                    onChange={(event, newValue) => {
                      handleFilters("company", newValue?.id || "")
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Company" />
                    )}
                    value={company?.companyForDropdown?.find(
                      (c) => c.id === values.company
                    )}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <TextField
                    label="Experience From(Yrs)"
                    name="experienceFrom"
                    variant="outlined"
                    size="small"
                    style={{ maxWidth: "150px", marginRight: "10px" }}
                    value={values.experienceFrom || ""}
                    // onChange={handleChange}
                    onChange={(event, newValue) => {
                      handleFilters("experienceFrom",  event?.target?.value || "")
                    }}
                  />
                  <TextField
                    label="Experience To(Yrs)"
                    name="experienceTo"
                    variant="outlined"
                    size="small"
                    style={{ maxWidth: "150px" }}
                    value={values.experienceTo}
                    // onChange={handleChange}
                    onChange={(event, newValue) => {
                      handleFilters("experienceTo",  event?.target?.value || "")
                    }}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <InputLabel id="demo-simple-select-helper-label">
                    WishList
                  </InputLabel>
                  <Switch
                    checked={values.wishlist}
                    // onChange={() => {
                    //     handleFilters("wishlist",  values.wishlist === 0 ? 1 : 0)
                    //   }
                    // }
                    onChange={(e, v) =>{
                      if(v==true){
                        handleFilters("wishlist",1)
                      }
                      else{
                        handleFilters("wishlist",0)
                      }
                      }
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <InputLabel id="demo-radio-buttons-group-label">
                    Phone Number
                  </InputLabel>

                  <Switch
                    checked={values.isPhoneNumber}
                    // onChange={() =>
                    //   handleFilters("isPhoneNumber",  values.wishlist === 0 ? 1 : 0)
                    // }
                    onChange={(e, v) =>{
                      if(v==true){
                        handleFilters("isPhoneNumber",1)
                      }
                      else{
                        handleFilters("isPhoneNumber",0)
                      }
                      }
                    }
                    inputProps={{ "aria-label": "controlled" }}
                    size="small"
                  />
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        direction="row"
        alignItems="start"
        className="employee-table-Stack search-filter"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ margin: "10px 5px 10px 0" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
              className="employee-table-Stack-label search-filter-detail"
            />
          );
        })}
      </Stack>

      {filterChips.length !== 0 || isSearching ? (
        <Typography className="record-found-text" variant="body2" sx={{ mb: 1 }}>
          {totalCount} records found
        </Typography>
      ) : (
        ""
      )}

      {loading ? (
        <Loader />
      ) : (
        <Card className="Candidates-main-Card-table grid-table">
          <Scrollbar>
            <TableContainer
              component={Paper}
              sx={{ minWidth: 800 }}
              className="radius-remove"
              style={{ borderRadius: "0" }}
            >
              <Table className="Candidates-main-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortBy === "firstName"}
                        direction={sortBy === "firstName" ? orderBy : "asc"}
                        onClick={() => handleSorting("firstName")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Technology</TableCell>
                    <TableCell align="left">Contact</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Company</TableCell>
                    <TableCell align="left">Status</TableCell>
                    <TableCell align="left">Experience(Yrs)</TableCell>
                    <TableCell align="left">Wishlist</TableCell>
                    <TableCell className="th-action-buttons" align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length > 0 ? (
                    data.map((candidate, idx) => {
                      return (
                        <TableRow key={candidate?.id}>
                          <TableCell align="center">
                            {candidate?.firstName} {candidate?.lastName}
                          </TableCell>
                          {/*                           <TableCell align="center">
                            
                          </TableCell> */}
                          <TableCell align="left">
                            {getData(candidate?.experience)?.Technology === "-"
                              ? (candidate?.technologies && Array.isArray(candidate?.technologies) && candidate?.technologies?.map((t)=>{
                                  return t?.title
                              }).join(","))
                                  // candidate.technologies[0]?.title) ||
                                // "-"
                              : getData(candidate?.experience)?.Technology ||
                                "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.contactNumber || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.email || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {getData(candidate?.experience)?.companyName || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.activity?.candidateStatus?.name || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.totalExperience || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.wishlist ? "Yes" : "No"}
                          </TableCell>
                          <TableCell align="left" className="table-td-action">
                            <Stack className="td-action-buttons" direction="row">
                              {getEditVisible("candidates") && (candidate?.editCandidate == "1") && (
                                <IconButton
                                  // className="view-icon-button"
                                  className="edit-icon-button"
                                  // component={RouterLink}
                                  // disabled={true}
                                  // to={`/candidate/add/${encryption(
                                  //   candidate.id
                                  // )}`}
                                  onClick={(e) => window.open(`/candidate/add/${encryption(candidate?.id)}`,"_blank") }
                                >
                                  <img src="assets/images/edit.svg" title="Edit"/>
                                </IconButton>
                              )}
                              <IconButton
                                // className="edit-icon-button"
                                className="view-icon-button"
                                // component={RouterLink}
                                // to={`/candidate/view/${encryption(
                                //   candidate.id
                                // )}`}
                                onClick={(e) => window.open(`/candidate/view/${encryption(candidate?.id)}`,"_blank") }
                              >
                                {/* <VisibilityIcon /> */}
                                <img src="assets/images/vieweye-icon.svg" title="View" />
                              </IconButton>
                              {(candidate?.editCandidate == "1") && (<IconButton
                                className="clock-icon-button"
                                component={RouterLink}
                                to={`/interviewlist/schedule-interview/${encryption(
                                  candidate.id
                                )}`}
                              >
                                {/* <ScheduleIcon /> */}
                                <img src="assets/images/clock.svg" title="Interview"/>
                              </IconButton>
                              )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="No-Record-text" colSpan={12} align="left">
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomPagination
            totalPage={totalPage}
            totalCount={totalCount}
            limit={limit}
            handleChangePage={handleChangePage}
            page={page}
            rowsPerPageOptions={[10, 20, 40]}
            handleRowsPerPageChange={handleRowsPerPageChange}
            filter={filterChips.length !== 0 ? true : false}
          />
        </Card>
      )}
    </Container>
  );
}

const componentConfig = {
  component: Candidate,
  path: "/candidate",
  public: false,
  layout: DashboardLayout,
  group: "candidate",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
