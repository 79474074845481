import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import LoadingButton from "@mui/lab/LoadingButton"
import TextField from "@mui/material/TextField";
import { FormControlLabel, Switch } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import { createMattermost } from "../../../redux/systemConfig/thunk";
const ConfigForm = (props) =>{
    const {
        values,
        handleChange,
        setFieldValue,
        touched,
        errors,
        handleSubmit,
        getFieldProps,
        handleBlur,
        setValues,
        moduleName
      } = props;
    const dispatch = useDispatch();
    const employeeData = useSelector((state)=> state.employee.allEmployeeData);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      dispatch(
        getEmployeeForDropDown({sortBy:'',orderBy:''}),
      );
    }, [dispatch]);

    const handleCreateMattermostAC = async () => {
      setLoading(true)

      try {
        const res = await dispatch(
          createMattermost({}),
        ).unwrap();
      } catch (error) {
        setLoading(false);
      } finally {
        setLoading(false);
      }
    }

    return(
        <Card pb={3}>
           <Container maxWidth="xl">
             <form  onSubmit={handleSubmit}>
             {moduleName == "mattermost" ? <>
             <h6 style = {{fontSize: "18px",fontWeight: "700", marginTop : "25px", textTransform : 'capitalize'}}> {moduleName} Config 
             <Stack
                 direction="row"
                 alignItems="center"
                 justifyContent="flex-end"
                 mb={3}
               >
                 <LoadingButton 
                  loading={loading} 
                  variant="contained" 
                  onClick={() => handleCreateMattermostAC()}
                >
                  Create Mattermost Account
                 </LoadingButton>
               </Stack>
             </h6>
             {/* <Button alignItems="right" justifyContent="right" variant="contained">Create Mattermost Account</Button> */}
             
             </>
              //  <Grid item xs={12} mt={3}>
               
            //  </Grid>
               : 
               <h6 style = {{fontSize: "18px",fontWeight: "700", textTransform : 'capitalize'}}> {moduleName} Config</h6>}
               <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                
              {
                moduleName === 'mail' && (
                  <>
                   <Grid item xs={3} mt={3}>
                    <Stack className="checkbox-part">
											<FormControlLabel
												control={
													<Switch
													   checked={values.enableMailCommunication}
														 onChange={handleChange}
														name="enableMailCommunication"
														inputProps={{ "data-cy": "chk-enableMailCommunication-item" }}
														size="small"
													/>
												}
												label="Enable MailCommunication"
											/>
										</Stack>
                  </Grid>
                  <Grid item xs={3} mt={3}>
                    <Stack className="checkbox-part">
											<FormControlLabel
												control={
													<Switch
                            checked={values.sandBox}
                            onChange={handleChange}
														name="sandBox"
														inputProps={{ "data-cy": "chk-sandBox-item" }}
														size="small"
													/>
												}
												label="Enable Sandbox"
											/>
										</Stack>
                  </Grid>
                  
                  <Grid item xs={3} mt={3}>
                   <TextField
                     fullWidth
                     label="Default Receiver Email"
                     name="defaultReceiverEmail"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.defaultReceiverEmail || ""}
                     onChange={handleChange}
                     error={Boolean(touched.defaultReceiverEmail && errors.defaultReceiverEmail)}
                     helperText={touched.defaultReceiverEmail && errors.defaultReceiverEmail}
                   />
                 </Grid>
                 <Grid item xs={3} mt={3}>
                   <TextField
                     fullWidth
                     label="Admin Receiver Email"
                     name="mail_adminReceiverEmail"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.mail_adminReceiverEmail || ""}
                     onChange={handleChange}
                     error={Boolean(touched.mail_adminReceiverEmail && errors.mail_adminReceiverEmail)}
                     helperText={touched.mail_adminReceiverEmail && errors.mail_adminReceiverEmail}
                   />
                 </Grid>
                  {/* <Grid item xs={6} mt={3}>
                   <TextField
                     fullWidth
                     label="Default SenderEmail"
                     name="defaultSenderEmail"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.defaultSenderEmail || ""}
                     onChange={handleChange}
                     error={Boolean(touched.defaultSenderEmail && errors.defaultSenderEmail)}
                     helperText={touched.defaultSenderEmail && errors.defaultSenderEmail}
                   />
                 </Grid> */}
                  </>
                )
              }

              {
                moduleName === 'employee' && (
                  <>
                   <Grid item xs={6} mt={3}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={employeeData || []}
                        key={employeeData || []}
                        
                        getOptionLabel={(option) => option?.firstName ?
                          `${option.firstName} ${option.lastName}` : ""
                        }
                        
                        {...getFieldProps(`exitCheckListResponsibleHr`)}
                        renderOption={(props, option) => (
                          <li {...props} value={option.id} key={option.id}>
                            {option.firstName + " " + option.lastName || ""}
                          </li>
                          )
                        }
                        onChange={(event, newValue) => {
                          setFieldValue(
                            `exitCheckListResponsibleHr`,
                            newValue?.id || "",
                          );
                          setFieldValue("exitCheckListResponsibleHrName", newValue?.firstName + " " + newValue.lastName)
                        }}
                        value={
                          employeeData?.find((vendor) => {
                            return vendor.id == values.exitCheckListResponsibleHr;
                          }) || ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Responsible HR"
                            error={Boolean(touched.exitCheckListResponsibleHr && errors.exitCheckListResponsibleHr)}
                            helperText={touched.exitCheckListResponsibleHr && errors.exitCheckListResponsibleHr}
                          />
                        )}
									    />
                    </Grid>
                    <Grid item xs={6} mt={3}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        options={employeeData || []}
                        key={employeeData || []}

                        getOptionLabel={(option) => option?.firstName ?
                          `${option.firstName} ${option.lastName}` : ""
                        }
                        {...getFieldProps(`exitCheckListResponsibleSystemAdmin`)}
                        renderOption={(props, option) => (
                          <li {...props} value={option.id} key={option.id}>
                            {option.firstName + " " + option.lastName || ""}
                          </li>
                          )
                        }
                        onChange={(event, newValue) => {
                          setFieldValue(
                            `exitCheckListResponsibleSystemAdmin`,
                            newValue?.id || "",
                          );
                          setFieldValue("exitCheckListResponsibleSystemAdminName", newValue?.firstName + " " + newValue?.lastName)
                        }}
                        // value={employeeData.find(
                        // 	(emp) => emp.id == values.exitCheckListResponsibleSystemAdmin,
                        // )}

                        value={
                          employeeData?.find((vendor) => {
                            return vendor.id == values.exitCheckListResponsibleSystemAdmin;
                          }) || ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Responsible Admin"
                            error={Boolean(touched.exitCheckListResponsibleSystemAdmin && errors.exitCheckListResponsibleSystemAdmin)}
                            helperText={touched.exitCheckListResponsibleSystemAdmin && errors.exitCheckListResponsibleSystemAdmin}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )
              }

              { moduleName == 'mattermost' && <>
                  
                 <Grid item xs={6} mt={3}>
                   <TextField
                     fullWidth
                     label="Mattermost Token"
                     name="mattermostToken"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.mattermostToken || ""}
                     onChange={handleChange}
                     error={Boolean(touched.mattermostToken && errors.mattermostToken)}
                     helperText={touched.mattermostToken && errors.mattermostToken}
                   />
                 </Grid>
                 <Grid item xs={6} mt={3}>
                   <TextField
                     fullWidth
                     label="Mattermost URL"
                     name="mattermostUrl"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.mattermostUrl  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.mattermostUrl && errors.mattermostUrl)}
                     helperText={touched.mattermostUrl && errors.mattermostUrl}
                   />
                 </Grid>
                 <Grid item xs={6} >
                   <TextField
                     fullWidth
                     multiline
                     rows={2}
                     label="Admin E-Mails"
                     name="adminEmailForMatterMost"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.adminEmailForMatterMost  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.adminEmailForMatterMost && errors.adminEmailForMatterMost)}
                     helperText={touched.adminEmailForMatterMost && errors.adminEmailForMatterMost 
                      ? touched.adminEmailForMatterMost && errors.adminEmailForMatterMost 
                      : "Note: Comma separated Email-ids. This emails will be used to send notifications related to mattermost process"}
                   />
                 </Grid>
                 </> }
              
              { moduleName !== "employee" && moduleName !== "mattermost" && <>
                 <Grid item xs={6} mt={3}>
                   <TextField
                     fullWidth
                     label="Client Email"
                     name="clientEmail"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.clientEmail || ""}
                     onChange={handleChange}
                     error={Boolean(touched.clientEmail && errors.clientEmail)}
                     helperText={touched.clientEmail && errors.clientEmail}
                   />
                 </Grid>
                 <Grid item xs={6} mt={3}>
                   <TextField
                     fullWidth
                     label="Client Id"
                     name="clientId"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.clientId  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.clientId && errors.clientId)}
                     helperText={touched.clientId && errors.clientId}
                   />
                 </Grid></> }
               </Grid>
               { moduleName !== "employee" && moduleName !== "mattermost" && <>
               <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="Client SecretKey"
                     name="clientSecretId"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.clientSecretId  || ""}
                     onChange={handleChange}
                     error={Boolean(
                       touched.clientSecretId && errors.clientSecretId
                     )}
                     helperText={touched.clientSecretId && errors.clientSecretId}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="Refresh Token"
                     name="refreshToken"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.refreshToken  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.refreshToken && errors.refreshToken)}
                     helperText={touched.refreshToken && errors.refreshToken}
                   />
                 </Grid>
               </Grid>
               </> }
               
               <Grid container spacing={3} style={{ marginBottom: "20px" }}>
               { moduleName !== "employee" && moduleName !== "mattermost" && <>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="Redirect Url"
                     name="redirectUrl"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.redirectUrl  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.redirectUrl && errors.redirectUrl)}
                     helperText={touched.redirectUrl && errors.redirectUrl}
                   />
                 </Grid>
                 </> }
                { moduleName === 'drives' && ( <Grid item xs={6}>
                 <TextField
                     fullWidth
                     label="Checklist FolderKey"
                     name="checklistFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.checklistFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(
                       touched.checklistFolderKey && errors.checklistFolderKey
                     )}
                     helperText={touched.checklistFolderKey && errors.checklistFolderKey}
                   />
               </Grid>)}
               </Grid>
              { moduleName === 'drives' && (
                <>
                <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="PAN FolderKey"
                     name="panFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                    //  InputLabelProps={{shrink: true}}
                     autoComplete="off"
                     value={values.panFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(
                       touched.panFolderKey && errors.panFolderKey
                     )}
                     helperText={touched.panFolderKey && errors.panFolderKey}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="EmployeeResumes FolderKey"
                     name="employeeResumesFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.employeeResumesFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.employeeResumesFolderKey && errors.employeeResumesFolderKey)}
                     helperText={touched.employeeResumesFolderKey && errors.employeeResumesFolderKey}
                   />
                 </Grid>
               </Grid>
               <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="CandidateResumes FolderKey"
                     name="candidateResumesFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.candidateResumesFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(
                       touched.candidateResumesFolderKey && errors.candidateResumesFolderKey
                     )}
                     helperText={touched.candidateResumesFolderKey && errors.candidateResumesFolderKey}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="InternResumes FolderKey"
                     name="internResumesFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.internResumesFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(touched.internResumesFolderKey && errors.internResumesFolderKey)}
                     helperText={touched.internResumesFolderKey && errors.internResumesFolderKey}
                   />
                 </Grid>
               </Grid>
               <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="ProjectDefination FolderKey"
                     name="projectDefinationFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.projectDefinationFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(
                       touched.projectDefinationFolderKey && errors.projectDefinationFolderKey
                     )}
                     helperText={touched.projectDefinationFolderKey && errors.projectDefinationFolderKey}
                   />
                 </Grid>
                 <Grid item xs={6}>
                   <TextField
                     fullWidth
                     label="AdharCard FolderKey"
                     name="adharFolderKey"
                     variant="outlined"
                     size="small"
                     // inputProps={{ maxLength: 64 }}
                     autoComplete="off"
                     value={values.adharFolderKey  || ""}
                     onChange={handleChange}
                     error={Boolean(
                       touched.adharFolderKey && errors.adharFolderKey
                     )}
                     helperText={touched.adharFolderKey && errors.adharFolderKey}
                   />
                 </Grid>
                 </Grid>

                 <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidateAdhar FolderKey"
                      name="candidateAdharFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidateAdharFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidateAdharFolderKey && errors.candidateAdharFolderKey
                      )}
                      helperText={touched.candidateAdharFolderKey && errors.candidateAdharFolderKey}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidateEducationCertificates FolderKey"
                      name="CandidateEducationCertificatesFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.CandidateEducationCertificatesFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.CandidateEducationCertificatesFolderKey && errors.CandidateEducationCertificatesFolderKey
                      )}
                      helperText={touched.CandidateEducationCertificatesFolderKey && errors.CandidateEducationCertificatesFolderKey}
                    />
                  </Grid>
                 </Grid>
                 <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidateForm16OfPreviousEmployer FolderKey"
                      name="candidateForm16OfPreviousEmployerFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidateForm16OfPreviousEmployerFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidateForm16OfPreviousEmployerFolderKey && errors.candidateForm16OfPreviousEmployerFolderKey
                      )}
                      helperText={touched.candidateForm16OfPreviousEmployerFolderKey && errors.candidateForm16OfPreviousEmployerFolderKey}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidateLatestExperienceLetter FolderKey"
                      name="candidateLatestExperienceLetterFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidateLatestExperienceLetterFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidateLatestExperienceLetterFolderKey && errors.candidateLatestExperienceLetterFolderKey
                      )}
                      helperText={touched.candidateLatestExperienceLetterFolderKey && errors.candidateLatestExperienceLetterFolderKey}
                    />
                  </Grid>
                 </Grid>
                 <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidateLatestRelievingLetter FolderKey"
                      name="candidateLatestRelievingLetterFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidateLatestRelievingLetterFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidateLatestRelievingLetterFolderKey && errors.candidateLatestRelievingLetterFolderKey
                      )}
                      helperText={touched.candidateLatestRelievingLetterFolderKey && errors.candidateLatestRelievingLetterFolderKey}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidatePan FolderKey"
                      name="candidatePanFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidatePanFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidatePanFolderKey && errors.candidatePanFolderKey
                      )}
                      helperText={touched.candidatePanFolderKey && errors.candidatePanFolderKey}
                    />
                  </Grid>
                 </Grid>
                 <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidatePermentAddress FolderKey"
                      name="candidatePermentAddressFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidatePermentAddressFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidatePermentAddressFolderKey && errors.candidatePermentAddressFolderKey
                      )}
                      helperText={touched.candidatePermentAddressFolderKey && errors.candidatePermentAddressFolderKey}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidatePresentAddress FolderKey"
                      name="candidatePresentAddressFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidatePresentAddressFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidatePresentAddressFolderKey && errors.candidatePresentAddressFolderKey
                      )}
                      helperText={touched.candidatePresentAddressFolderKey && errors.candidatePresentAddressFolderKey}
                    />
                  </Grid>
                 </Grid>
                 <Grid container spacing={3} style={{ marginBottom: "20px" }}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="CandidateSalarySlip FolderKey"
                      name="candidateSalarySlipFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidateSalarySlipFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidateSalarySlipFolderKey && errors.candidateSalarySlipFolderKey
                      )}
                      helperText={touched.candidateSalarySlipFolderKey && errors.candidateSalarySlipFolderKey}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="Candidatepassport FolderKey"
                      name="candidatepassportFolderKey"
                      variant="outlined"
                      size="small"
                      // inputProps={{ maxLength: 64 }}
                      autoComplete="off"
                      value={values.candidatepassportFolderKey  || ""}
                      onChange={handleChange}
                      error={Boolean(
                        touched.candidatepassportFolderKey && errors.candidatepassportFolderKey
                      )}
                      helperText={touched.candidatepassportFolderKey && errors.candidatepassportFolderKey}
                    />
                  </Grid>
                 </Grid>
                 
                 </>
                 )}
               <Stack
                 direction="row"
                 alignItems="center"
                 justifyContent="flex-end"
                 mb={3}
               >
                 <Button variant="contained" type="submit" >
                   Save
                 </Button>
               </Stack>
             </form>
           </Container>
         </Card>)
}

export default ConfigForm;