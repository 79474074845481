import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getAllSeatNumberAllocation = createAsyncThunk(
	//action type string
	"seat/getAllocatedSeatNumber",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.get("/getAllocatedSeatNumber", params, {
			headers:{
				type:'view',module:'employee'
			}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const createSeatNumberAllocation = createAsyncThunk(
	//action type string
	"seat/setSeatNumber",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/setSeatNumber", body,{
				headers:{
					type:'create',module:'employee'
				}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);


export const resetSeatNumber = createAsyncThunk(
  //action type string
  "seat/resetSeatNumber",
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post("/resetSeatNumber", body,{
        headers: {
          type: "delete",
          module: "employee",
        },
      });
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);