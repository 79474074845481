import React, { useEffect, useState, useCallback } from "react";
import {  useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce, filter } from "lodash";
import { toast } from "react-toastify";

import { BASE_URL } from "../../constants";
import Scrollbar from "../../components/Scrollbar";
import CustomPagination from "../../components/Pagination";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import FileDownloadSharpIcon from "@mui/icons-material/FileDownloadSharp";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import VisibilityIcon from "@mui/icons-material/Visibility";
// import { LocalizationProvider } from "@mui/lab";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import NiceModal from "@ebay/nice-modal-react";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Chip from "@mui/material/Chip";
import TableSortLabel from "@mui/material/TableSortLabel";
import invertDirection from "../../utils/invertDirection";
import Autocomplete from "@mui/material/Autocomplete";
import Switch from "@mui/material/Switch";
import { Badge, Checkbox, Modal } from "@mui/material";
import moment from "moment";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { getCurrentMonthHolidayList } from "../../redux/dashboard/dashboardThunk";
import Avatar from '@mui/material/Avatar';
import {
	addMonthsExactPosition,
	addMonths,
	getDay,
	getArrayOfDates,
} from "../../utils/formatTime";

import { getActivity } from "../../redux/activity/activityThunk";
import {
  getInternData,
  getTotalInternCount,
} from "../../redux/intern/internThunk";
import { encryption } from "../../utils/encodeString";
import { fDateCustom } from "../../utils/formatTime";
import {
  setLimit,
  setPage,
  setSortBy,
  setOrderBy,
} from "../../redux/intern/internSlice";
import { convertAllInternsToCandidate } from "../../redux/intern/internThunk";
import { yearMonthDateFormat,yearFormat } from "../../utils/dateFormat";
import { getTechnology } from "../../redux/technology/technologyThunk";
import { getCollege } from "../../redux/college/collegeThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { ImportIntern } from "./InternComponents/ImportIntern";
import { exportInternFile, changeInternStatusToProduction } from "../../redux/intern/internThunk";
import { getDrives } from "../../redux/drives/drivesThunk";

import {
  convertInternToCandidate,
  convertInternToEmployee,
  convertStudentToEmployee,
} from "../../redux/student/studentThunk";
import { logDOM } from "@testing-library/react";
import { convertToEmployee } from "../../redux/candidate/candidateThunk";
import internEvaluationSchema from "../../validations/internEvaluationSchema";
import LocalStorage from "../../service/localStorage";
import { getUserPermissionById, getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import { makeStyles } from "@material-ui/core";
import { current } from "@reduxjs/toolkit";
import { getGrade } from "../../redux/grade/gradeThunk";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 500,
  // bgcolor: "background.paper",
  // border: "none",
  // boxShadow: 24,
  // gap: 4,
  // p: 4,
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const styleError = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Interns() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const AuthRole = useSelector((state) => state.auth.role);
  const data = useSelector((state) => state.intern.data);
  const uniqueYear = useSelector((state) => state.intern.uniqueYear);
  const year = useSelector((state) => state.intern.year);

  const loading = useSelector((state) => state.intern.loading);
  const error = useSelector((state) => state.intern.error);
  const limit = useSelector((state) => state.intern.limit);
  const page = useSelector((state) => state.intern.page);
  const status = useSelector((state) => state.intern.status);
  const totalPage = useSelector((state) => state.intern.totalPage);
  const totalCount = useSelector((state) => state.intern.totalCount);
  const sortBy = useSelector((state) => state.intern.sortBy);
  const orderBy = useSelector((state) => state.intern.orderBy);
  const isSearchQuery = useSelector((state) => state.intern.isSearchQuery);
  const [productionDate, setProductionDate] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openImage, setOpenImage] = React.useState(false);
  const handleOpenImage = () => setOpenImage(true);
  const handleCloseImage = () => setOpenImage(false);
  const params = location.state;
  const [openError, setOpenError] = React.useState(false);
  const handleOpenError = () => setOpenError(true);
  const handleCloseError = () => setOpenError(false);
  const [pdError, setPdError] = useState({ flag: false, title: "" });
  const modeList = ["Online", "Offline"];
  // const [driveYears, setDriveYears] = useState([]);
  const[internImage,setInternImage]= useState(null)
  const totalinternCount = useSelector(
    (state) => state.internCount.totalCounts
  );
  const gradeData = useSelector((state) => state?.grade?.data);


  const [value, setValue] = React.useState("1");

  const drivesData = useSelector((state) => state.drives.data);
  const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=localStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("interns")) {
      dispatch(
        getInternData({
          limit,
          ...values,
          page: page+1,
          orderBy,
          sortBy,
          search: searchValue,
        })
      );
			navigate("/dashboard");
		}
	}, []);

  useEffect(() => {
    dispatch(getGrade({ sortBy: "", orderBy: "" }));
  }, []);

  /* const params = location.state; */
  const [errorData, setErrorData] = useState([]);
  const technology = useSelector((state) => state.technology);
  const college = useSelector((state) => state.college);
  const [filterChips, setFilterChips] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectAll, setSelectAll] = useState(false);
  const [checkedInterns, setCheckedInterns] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  // const [years, setYears] = useState([{title: "2022-2023", value: "2022-2023"}]);
  const classes = useStyles();
  const { handleSubmit, values, handleChange, resetForm, setFieldValue, errors, touched, setErrors} =
    useFormik({
      initialValues: {
        search: "",
        collegeId: "",
        year: "",
        technology: "",
        wishlist: "",
        // joiningFrom: "",
        // joiningTo: "",
        grade: "",
        projectType: "",
        mode: "",
        productionDate: "",
      },
      
      // validationSchema: internEvaluationSchema,
      onSubmit: async (values) => {
        let queryParams = { ...values };
        // queryParams["search"]=searchValue;
        try {
          /* if (queryParams.candidateStatusId.length > 0) {
						queryParams["candidateStatusId"] =
							queryParams.candidateStatusId.map((s) => s.id);
					} else {
						queryParams["candidateStatusId"] = "";
					} */
          if (queryParams.grade && queryParams.grade.length === 0) {
            delete queryParams.grade; // Delete the grade key if it's an empty array
        }
          await dispatch(getInternData({ ...queryParams, limit, page: 1 }));
          dispatch(setPage({ page: 0 }));
          prepareChips();
      
        } catch (error) {
          toast.error(error.response.data.message);
        }
      },
    });


  let projectTypeList = ["IDP", "UDP"];

  useEffect(() => {
    if (status === "idle" && !error) {
      dispatch(
        getInternData({
          limit,
          ...values,
          page: page+1,
          orderBy,
          sortBy,
          search: searchValue,
        })
      );
      prepareChips();

    }
  }, [ limit, page, status, error, sortBy, orderBy]);

  useEffect(() => {
    // if (status === "idle" && !error) {
      dispatch(
        getInternData({
          limit,
          ...values,
          page: page+1,
          orderBy,
          sortBy,
          search: searchValue,
        })

      );
      prepareChips();

    // }
  }, [dispatch]);

  useEffect(() => {
    setCheckedInterns([]);
  }, [dispatch, limit, page])

  // useEffect(() => {
  //   let currentYear = new Date().getFullYear().toString()
  //   let nextYear = new Date().getFullYear() + 1
  //   setYears([...years, {title: `${currentYear}-${nextYear}`, value: `${currentYear}-${nextYear}`}])
  //   console.log("years", years)
  // },[])

  const inputRef = React.useRef(null);
  useEffect(() => {
    return () => {
      resetForm();
      dispatch(getInternData({ limit, page: page+1, sortBy, orderBy }));
      // dispatch(setPage({ page: 1 }));
      setState({ ...state, right: false });
      setFilterChips([]);
    };
  }, []);

  useEffect(() => {
    if (!error) {
      const fetchInternCount = async () => {
        try {
          await dispatch(getTotalInternCount({})).unwrap(); // Use unwrap if using RTK Query
        } catch (err) {
          console.error('Failed to fetch intern count:', err);
        }
      };

      fetchInternCount();
    }
  }, [dispatch]);

  useEffect(() => {
    // dispatch(getTechnology());
    dispatch(getTechnology({sortBy:'',orderBy:''}));

  }, [dispatch]);
  useEffect(() => {
    getViewVisible("college")&&dispatch(getCollege({ search: "" }));
  }, [dispatch]);

  // useEffect(async () => {
  //   let res = await dispatch(
  //     getDrives({
  //       page: 1,
  //       limit: limit,
  //       // sortBy,
  //       // orderBy,
  //     })).unwrap()
      
  //     let y = res?.data?.list?.map((drive) => {
  //       return drive?.year
  //     })
  //     function onlyUnique(value, index, array) {
  //       return array?.indexOf(value) === index;
  //     }
  //     var unique = y?.filter(onlyUnique);
  //     setDriveYears(unique?.sort())

  // },[])






  useEffect(() => {
    let temp = []
      data?.map((intern) => {
        if(intern?.status?.status == "Intern" ) {
          temp = [...temp, intern.id]
        }
      })
      const equalsCheck = (a, b) => {
        return JSON.stringify(a) === JSON.stringify(b);
    }
 
    if(checkedInterns.length > 0)
    {
      if (equalsCheck(temp.sort(), checkedInterns.sort()))
      {
        setIsChecked(true);
      }
      else
      {
        setIsChecked(false);
      }
    }
    else{
      setIsChecked(false)
    }
  }, [checkedInterns]);

  // sidebar-part functionality
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    // getYears()
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(setPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleReset = async () => {
    resetForm();
    // dispatch(getInternData({ limit, page: page + 1 }));
    await dispatch(getInternData({ limit, page: 1, sortBy, orderBy }));
    dispatch(setPage({ page: 0 }));
  
    setState({ ...state, right: true });
    setFilterChips([]);
  };

  const prepareChips = () => {
    let filters = { ...values };
    let tempChips = [];
    for (const key in filters) {
      if (filters[key] && key === "technology") {
        //console.log(filters[key]);
        const res = technology.data.find((tech) => tech.id === filters[key]);
        if (res) filters[key] = `Technology : ${res.title}`;
      }

      if (filters[key] && key === "collegeId") {
        //console.log(filters[key]);
        const res = college.data.find((college) => college.id === filters[key]);
        if (res) filters[key] = `College : ${res?.collegeName}`;
      }

      if (filters[key] && key === "projectType") {
        //console.log(filters[key]);
        const res = projectTypeList.find((p) => p == filters[key]);
        if (res) filters[key] = `Project Type : ${res}`;
      }

      if (filters[key] && key === "mode") {
        //console.log(filters[key]);
        const res = modeList.find((p) => p == filters[key]);
        if (res) filters[key] = `Interview mode : ${res}`;
      }

      if (filters[key] && key === "grade") {
        filters[key] = "Grade : " + filters[key];
      }
      if (filters[key] && key === "year") {
        filters[key] = "Year : " + filters[key];
      }

      if (filters[key] && key === "wishlist") {
        filters[key] = "Wishlist";
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
  };

  const onDeleteChip = (value, length) => {
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    let filters = { ...values, [value["type"]]: "" };

    if (length === 1) {
      // dispatch(getInternData({ limit, page: page + 1 }));
      dispatch(getInternData({ limit, page: 1, sortBy, orderBy }));
      dispatch(setPage({ page: 0 }));
    } else {
      // dispatch(getInternData({ ...filters, limit, page: page + 1 }));
      dispatch(getInternData({ ...filters, limit, page: 1, sortBy, orderBy }));
      dispatch(setPage({ page: 0 }));

    }

    setFieldValue(value.type, "");
    if(value.type==="search"){
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

  // const getData = (ExpArr) => {
  // 	// Case 1: No Experience -  - on list page
  // 	// Case 2: 1 exp then - show that exp details
  // 	// Case 3: multiple exp with present - show present data
  // 	// Case 4: multiple exp without any present - show latest exp details

  // 	let currTechArr = [];
  // 	let CompId;
  // 	let latestExp;

  // 	if (ExpArr.length > 0) {
  // 		latestExp = ExpArr.reduce((m, v, i) => (v.to > m.to && i ? v : m));

  // 		ExpArr.map((exp, idx) => {
  // 			if (ExpArr.length === 1) {
  // 				currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
  // 				CompId = exp.companyId;

  // 				return "";
  // 			} else if (ExpArr.length > 1) {
  // 				if (exp.to === null) {
  // 					currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
  // 					CompId = exp.companyId;
  // 				} else if (latestExp.to === exp.to) {
  // 					currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
  // 					CompId = exp.companyId;
  // 				}
  // 			}

  // 			/* if (exp.to === null) {
  // 				currTechArr = exp.technologies?.map((t) => t.technologyId) || [];
  // 				CompId = exp.companyId;
  // 			} */

  // 			return { currTechArr, CompId };
  // 		});
  // 	}

  // 	let finalTech = technology.data
  // 		.filter((t) => currTechArr.includes(t.id))
  // 		.map((t) => t.title);

  // 	let companyName = company?.companyForDropdown?.find((c) => {
  // 		if (c.id === CompId) return c.name;
  // 	});

  // 	return {
  // 		Technology: finalTech.join(",") ? finalTech.join(",") : "-",
  // 		companyName: companyName?.name ? companyName?.name : "-",
  // 	};
  // };

  const handleCancel = () => {
    handleCloseImage();
    // setFieldValue("note", "");
    // setNoteError({ flag: false, title: "" });
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    handleSubmit()
    // await dispatch(
    //   getInternData({
    //     search: e?.target?.value,
    //     limit,
    //     page: page + 1,
    //     orderBy,
    //     sortBy,
    //   })
    // );
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const openExcelModal = async () => {
    const modalResponse = await NiceModal.show(ImportIntern);
  };

  const exportFile = async () => {
    try {
     const response = await dispatch(exportInternFile({})).unwrap()
        .then((res) => {
          const url = `${BASE_URL}/${res.data}`;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  let checkBoxSelectAll = (e, id) => {
      if (e.target.checked) {
        setCheckedInterns([...checkedInterns, id]);
      } else {
        let newarray = [...checkedInterns].filter((item) => item !== id);
        setCheckedInterns(newarray);
        setSelectAll(false)
      }        
  };

  const handleJoiningDate = (date) => {
		let flag = false;
		let months = 15 > getDay(date) ? 3 : 4;

		let getDate = addMonths(date, months);
		dispatch(
			getCurrentMonthHolidayList({
				date: moment(getDate).format("YYYY-MM"),
			}),
		)
			.unwrap()
			.then((data) => {
				if (data?.holidayData.length === 0) {
          setProductionDate(getDate)
				}
				data?.holidayData.sort(function (a, b) {
					return new Date(a.start) - new Date(b.start);
				});

				for (let index = 0; index < data?.holidayData.length; index++) {
					const element = data?.holidayData[index];
					if (
						moment(getDate).format("DD-MM-YYYY") !=
						moment(element.start).format("DD-MM-YYYY")
					) {
						flag = true;
            setProductionDate(getDate)
						break;
					} else {
						getDate = moment(getDate).add(1, "d");
					}
				}
				if (!flag) {
          setProductionDate(getDate)
				}
			})
			.catch((err) => {
				
			});
	};

  let readyForProduction = async () => {
    try {
      if (productionDate !== "") {
        let resp = await dispatch(
          changeInternStatusToProduction(
            {ids: checkedInterns.join(","),
            productionDate: productionDate,
            }
          )
        ).unwrap();
        if(resp) //?.duplicateData?.length > 0)
        {
          setOpen(false)
          handleOpenError();
          setCheckedInterns([])
          dispatch(getInternData({ limit, page: page + 1, sortBy, orderBy}));
        }
        else
        {
          if (resp.success) {
            toast.success(resp.message);
            
            dispatch(getInternData({ limit, page: page + 1, sortBy, orderBy}));
            handleClose();
          } else {
            toast.error(resp?.massage);
          }
        }
      }
      else
        {
          setPdError({ flag: true, title: "Please select Production date" });
        }
    }
    catch(error){
      handleClose();
      // toast.error("Already converted to an Employee");
    }
  };

  let ChangeStatus = () => {
    handleOpen();
  };

  const handleAllSelect = (e) => {
    setIsChecked(!isChecked);
    if(e.target.checked == true) {
      let temp = []
      data.map((intern) => {
        if(intern?.status?.status == "Intern" ) {
          temp = [...temp, intern.id]
        }
      })
      setCheckedInterns(temp)
    }
    else{
      setCheckedInterns([])
    }
  }

  const handleOpenImageInModal = (i) => {
    setInternImage(i)
    handleOpenImage();
  }

  const handleImageChange = (e) => {
    // if (e.target.files.length > 0) {
    //   setFieldValue("studentImage", e.target.files[0]);
    // }
  };

  const handleFilters = async (field,newValue) => {

    console.log("C_______FFF___)1",newValue)
    setFieldValue(field, newValue || "");
    handleSubmit();
  }


useEffect(() => {
  if(year)   handleFilters(`year`, year || "");
},[year])
 
  return (
    <Container className="Interns-wrapper-container wrapper-container" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
        className="Interns-wrapper-content"
      >
       <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Interns ({totalinternCount})
            {/* <Typography variant="body2">
              Total Records : {totalinternCount}
            </Typography> */}
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <span
                  className="filter-icon-part"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <IconButton>
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={filterChips.length !== 0 ? false : true}
                    >
                      <img src="/assets/images/filter.svg" />
                      {/* <FilterAltIcon /> 
                    </Badge>
                  </IconButton>
                </span>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  style={{ zIndex: "1300" }}
                >
                  <Box
                    pt={4}
                    px={3}
                    sx={{
                      width:
                        anchor === "top" || anchor === "bottom" ? "auto" : 400,
                    }}
                    role="presentation"
                    /* onClick={toggleDrawer(anchor, false)}
                    // onKeyDown={toggleDrawer(anchor, false)}
                  >
                    <div className="content-filter-part">
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <Typography variant="h6">Intern Filter</Typography>
                        <span>
                          <IconButton onClick={toggleDrawer(anchor, false)}>
                            <CloseIcon />
                          </IconButton>
                        </span>
                      </Stack>
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3} mb={2}>
                          <Grid item xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              options={technology.data || []}
                              getOptionLabel={(option) => option.title || ""}
                              name="technology"
                              onChange={(event, newValue) => {
                                setFieldValue("technology", newValue?.id || "");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoComplete="off"
                                  label="Technology"
                                />
                              )}
                              value={technology?.data?.find(
                                (t) => t.id === values.technology
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              size="small"
                              disabled={getViewVisible("college") === false}
                              fullWidth
                              options={college.data || []}
                              getOptionLabel={(option) =>
                                option?.collegeName || ""
                              }
                              name="collegeId"
                              onChange={(event, newValue) => {
                                setFieldValue("collegeId", newValue?.id || "");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoComplete="off"
                                  label="College"
                                />
                              )}
                              value={college?.data?.find(
                                (t) => t.id === values.collegeId
                              )}
                            />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              item
                            >
                              <Grid item>
                                <DesktopDatePicker
                                  xs={6}
                                  label="From"
                                  style={{ width: "100px" }}
                                  value={values.joiningFrom}
                                  inputFormat="dd/MM/yyyy"
                                  // minDate={new Date('2017-01-01')}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "joiningFrom",
                                      yearMonthDateFormat(newValue)
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      error={false}
                                      size="small"
                                      style={{
                                        width: "45%",
                                        marginRight: "10px",
                                      }}
                                    />
                                  )}
                                />
                                <DesktopDatePicker
                                  xs={6}
                                  label="To"
                                  value={values.joiningTo}
                                  inputFormat="dd/MM/yyyy"
                                  // minDate={new Date('2017-01-01')}
                                  onChange={(newValue) => {
                                    setFieldValue(
                                      "joiningTo",
                                      yearMonthDateFormat(newValue)
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      fullWidth
                                      error={false}
                                      size="small"
                                      style={{ width: "50%" }}
                                    />
                                  )}
                                />
                              </Grid>
                            </LocalizationProvider>
                          </Grid> 
                          <Grid item xs={12}>
                            <Autocomplete
                              // multiple
                              size="small"
                              fullWidth
                              options={driveYears || []}
                              // getOptionLabel={(option) => option.title}
                              name="year"
                              onChange={(event, newValue) => {
                                setFieldValue(`year`, newValue);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Year" />
                              )}
                              value={driveYears?.find((x) =>
                                x == values.year
                              )}
                            />
                          </Grid>

                          {/* <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              size="small"
                              fullWidth
                              options={grades || []}
                              getOptionLabel={(option) => option.title || ""}
                              name="grade"
                              onChange={(event, newValue) => {
                                setFieldValue("grade", newValue?.value || "");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoComplete="off"
                                  label="Grade"
                                />
                              )}
                              value={grades?.find(
                                (t) => t.value === values.grade
                              )}
                            />
                          </Grid> 
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              size="small"
                              fullWidth
                              options={gradeData || []}
                              getOptionLabel={(option) => option.grade}
                              name="grade"
                              onChange={(event, newValue) => {
                                let tempArray = newValue.map((x) => x.grade);
                                setFieldValue(`grade`, tempArray);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Grade" />
                              )}
                              value={gradeData.filter((x) =>
                                values.grade.includes(x.grade)
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              size="small"
                              name="projectType"
                              onChange={(event, newValue) => {
                                setFieldValue(`projectType`, newValue || "");
                              }}
                              value={values.projectType}
                              options={projectTypeList || []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Project Type"
                                  autoComplete="off"
                                  // error={Boolean(touched?.mode && errors?.mode)}
                                  // helperText={touched?.mode && errors?.mode}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              size="small"
                              name="mode"
                              onChange={(event, newValue) => {
                                setFieldValue(`mode`, newValue || "");
                              }}
                              value={values.mode}
                              options={modeList || []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Mode"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Stack direction="row">
                              <Switch
                                checked={values.wishlist}
                                onChange={() =>
                                  setFieldValue(
                                    "wishlist",
                                    values.wishlist === 0 ? 1 : 0
                                  )
                                }
                                inputProps={{ "aria-label": "controlled" }}
                                size="small"
                              />
                              <InputLabel id="demo-simple-select-helper-label">
                                WishList
                              </InputLabel>
                            </Stack>
                          </Grid>
                        </Grid>

                        <Stack direction="row" justifyContent="flex-end" mb={3}>
                          <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            type="submit"
                            onClick={toggleDrawer(anchor, false)}
                          >
                            Search
                          </Button>
                          <Button
                            variant="contained"
                            type="reset"
                            onClick={handleReset}
                            startIcon={<CachedIcon />}
                            sx={{ marginLeft: "10px" }}
                          >
                            Reset
                          </Button>
                        </Stack>
                      </form>
                    </div>
                  </Box>
                </Drawer>
              </React.Fragment>
            ))} */}
            <span className="filter-group-button">
              {AuthRole === "Super Admin" ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => openExcelModal()}
                    // startIcon={<FileDownloadSharpIcon />}
                    className="filter-icon-part"
                  >
                    <img src="/assets/images/import.svg" />
                    Import
                  </Button>
                  <Button
                    variant="contained"
                    onClick={exportFile}
                    // startIcon={<FileUploadSharpIcon />}
                    className="filter-icon-part"
                  >
                    <img src="/assets/images/export.svg" />
                    Export
                  </Button>
                </>
              ) : (
                ""
              )}
              {checkedInterns && checkedInterns[0] ? (
                <>
                  <Button
                    variant="contained"
                    onClick={ChangeStatus}
                    // startIcon={<FileDownloadSharpIcon />}
                    className="filter-icon-part"
                  >
                    Ready For Production
                  </Button>
                  {/* <Button
                    variant="contained"
                    onClick={converToCandidate}
                    // startIcon={<FileDownloadSharpIcon />}
                    className="filter-icon-part">
                    Convert To Candidate
                  </Button> */}
                </>
              ) : (
                ""
              )}
            </span>
            {getAddVisible("interns") && (
              <Button
                // component={RouterLink}
                // to="/students/add/"
                onClick={() =>
                  navigate(`/interns/add/`, { state: { isIntern: 1 } })
                }
                variant="contained"
                // startIcon={<AddIcon />}
                className="filter-icon-part filter-Add-Intern"
              >
                <img src="/assets/images/add-circle.svg" />
                Add Intern
              </Button>
            )}
            {/* <Button
              component={RouterLink}
              to="/candidate/add"
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add Candidate
            </Button> */}
          </Stack>
        </div>
      </Stack>
      {/* {console.log(searchValue,"paw")} */}
      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div className="employee-classification-right" style={{paddingTop:"20px"}}>
                <TextField
                    fullWidth
                    className="employee-search-detail"
                    autoComplete="off"
                    label="Search by Name/Email/Phone Number"
                    name="search"
                    inputRef={inputRef}
                    // value={searchValue}
                    variant="outlined"
                    size="small"
                    onChange={debounceWithSearch}
                  />
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={technology.data || []}
                      getOptionLabel={(option) => option.title || ""}
                      name="technology"
                      onChange={(event, newValue) => {
                        // setFieldValue("technology", newValue?.id || "");
                        handleFilters("technology", newValue?.id || "")
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="Technology"
                        />
                      )}
                      value={technology?.data?.find(
                        (t) => t.id === values.technology
                      ) || ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      disabled={getViewVisible("college") === false}
                      fullWidth
                      options={college.data || []}
                      getOptionLabel={(option) =>
                        option?.collegeName || ""
                      }
                      name="collegeId"
                      onChange={(event, newValue) => {
                        // setFieldValue("collegeId", newValue?.id || "");
                        handleFilters("collegeId", newValue?.id || "")
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="College"
                        />
                      )}
                      value={college?.data?.find(
                        (t) => t.id === values.collegeId
                      ) || ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      // multiple
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      size="small"
                      fullWidth
                      options={uniqueYear || []}
                      // getOptionLabel={(option) => option.title}
                      name="year"
                      onChange={(event, newValue) => {
                        handleFilters(`year`, newValue || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Year" />
                      )}
                      value={ values.year ||  ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      multiple
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      size="small"
                      fullWidth
                      options={gradeData || []}
                      getOptionLabel={(option) => option.grade}
                      name="grade"
                      onChange={(event, newValue) => {
                        let tempArray = newValue.map((x) => x.grade);
                        handleFilters(`grade`, tempArray);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Grade" />
                      )}
                      value={gradeData.filter((x) =>
                        values.grade.includes(x.grade)
                      ) || ""}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      name="projectType"
                      onChange={(event, newValue) => {
                        handleFilters(`projectType`, newValue || "");
                      }}
                      value={values.projectType}
                      options={projectTypeList || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Project Type"
                          autoComplete="off"
                          // error={Boolean(touched?.mode && errors?.mode)}
                          // helperText={touched?.mode && errors?.mode}
                        />
                      )}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      name="mode"
                      onChange={(event, newValue) => {
                        handleFilters(`mode`, newValue || "");
                      }}
                      value={values.mode}
                      options={modeList || []}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Mode"
                          autoComplete="off"
                        />
                      )}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                      <Stack direction="row">
                        <Switch
                          className="classification-toggle-Switch"
                          checked={values.wishlist}
                          // onChange={() =>
                          //   handleFilters(
                          //     "wishlist",
                          //     values.wishlist === 0 ? 1 : 0
                          //   )
                          // }
                          onChange={(e, v) =>{
                            if(v==true){
                              handleFilters("wishlist",1)
                            }
                            else{
                              handleFilters("wishlist",0)
                            }
                            }
                          }
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                        <InputLabel id="demo-simple-select-helper-label">
                          WishList
                        </InputLabel>
                      </Stack>
                  </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      {/* <Card className="Interns_cards">
        <Container style={{ maxWidth: "100%" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container xs={12} pt={2} pb={2}>
              <Grid className="employee-search-detail" item xs={12}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  label="Search by Name/Email/Phone Number"
                  name="search"
                  inputRef={inputRef}
                  // value={searchValue}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card> */}
      <Stack
        direction="row"
        alignItems="start"
        className="employee-table-Stack search-filter"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              style={{ margin: "10px 5px 5px 0" }}
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
              className="employee-table-Stack-label search-filter-detail"
            />
          );
        })}
      </Stack>

      {filterChips.length !== 0 || isSearchQuery ? (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {totalCount} {"records found"}
        </Typography>
      ) : (
        ""
      )}

      <Card>

        <Scrollbar>
          <TableContainer
            component={Paper}
            sx={{ minWidth: 800 }}
            className="radius-remove intern-detail-table"
            style={{ borderRadius: "0" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="th-checkbox" align="center">
                    <Checkbox
                    className="checkbox-cell"
                      // checked={selectAll}
                      checked={isChecked}
                      onChange={(e) => handleAllSelect(e)}
                    />
                  </TableCell>
                  {/* <TableCell>Image</TableCell> */}
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "firstName"}
                      direction={sortBy === "firstName" ? orderBy : "asc"}
                      onClick={() => handleSorting("firstName")}
                    >
                      Name
                    </TableSortLabel>
                  </TableCell>
                  {/* <TableCell align="left">Email</TableCell> */}
                  {/* <TableCell align="center">
                      <TableSortLabel
                        active={sortBy === "lastName"}
                        direction={sortBy === "lastName" ? orderBy : "asc"}
                        onClick={() => handleSorting("lastName")}
                      >
                        Last Name
                      </TableSortLabel>
                    </TableCell> */}
                  <TableCell align="left">Contact</TableCell>
                  <TableCell align="left">College</TableCell>
                  {/* <TableCell align="left">Type</TableCell> */}
                  <TableCell align="left">

                
                    <TableSortLabel
                      active={sortBy === "technology"}
                      direction={sortBy === "technology" ? orderBy : "asc"}
                      onClick={() => handleSorting("technology")}
                    >
                      Technology
                    </TableSortLabel>

                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "marks"}
                      direction={sortBy === "marks" ? orderBy : "asc"}
                      onClick={() => handleSorting("marks")}
                    >
                      Aptitude Marks
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "evaluationGrade"}
                      direction={sortBy === "evaluationGrade" ? orderBy : "asc"}
                      onClick={() => handleSorting("evaluationGrade")}
                    >
                      Grade
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "joiningDate"}
                      direction={sortBy === "joiningDate" ? orderBy : "asc"}
                      onClick={() => handleSorting("joiningDate")}
                    >
                      Joining Date
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Action</TableCell>
                </TableRow>
              </TableHead>
              {loading ? (
                <TableCell align="center" width="100%" colSpan={9}>
                  <Loader />
                </TableCell>
              ) : (
                <TableBody>
                  {data?.length > 0 ? (
                    data.map((intern, idx) => {
                      let flag = checkedInterns.includes(intern.id);
                      return (
                        <TableRow key={intern.id}>
                          {intern?.status?.status == "Intern" ? (
                            <TableCell align="center">
                              <Checkbox
                                className="checkbox-cell"
                                id="chk"
                                name="chk"
                                checked={flag}
                                onChange={(e) =>
                                  checkBoxSelectAll(e, intern.id)
                                }
                              />
                            </TableCell>
                          ) : (
                            <TableCell align="center"></TableCell>
                          )}

                          <TableCell align="center">
                            <div className="interns-detail">
                              <Avatar 
                                className="interns-profile-icon"
                                alt={intern.firstName}
                                src={
                                  intern?.image &&
                                  `${BASE_URL}/${intern?.image}`
                                }
                                onClick={() => handleOpenImageInModal(intern)}
                              />
                              {intern?.firstName}{" "}
                              {intern?.middleName &&
                                intern?.middleName !== "" &&
                                `${intern?.middleName
                                  .charAt(0)
                                  .toUpperCase()}.`}{" "}
                              {intern?.lastName}
                            </div>
                          </TableCell>
                          {/*                           <TableCell align="center">
                            
                          </TableCell> */}
                          {/* <TableCell align="left">
														{intern.email || "-"}
														
													</TableCell> */}
                          <TableCell align="left">
                            {intern?.contact || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {intern?.college?.collegeName || "-"}
                          </TableCell>
                          {/* <TableCell align="left">
                            {intern.projectType || "-"}
                          </TableCell> */}
                          <TableCell align="left">
                            {(intern?.technology && intern.technology.title) ||
                              "-"}
                            {/* {getData(candidate.experience).companyName || "-"} */}
                          </TableCell>
                          <TableCell align="left">
                            {(intern?.marks && intern.marks) ||
                              "-"}
                          </TableCell>
                          <TableCell align="left">
                            {""}
                            {(intern?.evaluationGrade &&
                              intern.evaluationGrade) ||
                              "-"}
                          </TableCell>

                          <TableCell align="left">
                            {fDateCustom(intern.joiningDate) || "-"}
                          </TableCell>

                          <TableCell align="left">
                            <Stack className="button-container" direction="row">
                            {getViewVisible("interns") && (
                                <IconButton
                                  className="tablecell-button cellview-button"
                                  // style={{ padding: "5px" }}
                                  // component={RouterLink}
                                  // to={`/interns/view/${encryption(intern.id)}`}
                                  onClick={(e) => window.open(`/interns/view/${encryption(intern.id)}`,"_blank") }
                                >
                                  {/* <VisibilityIcon /> */}
                                  <img title="View" src="/assets/images/vieweye-icon.svg" />
                                </IconButton>
                              )}
                              {getEditVisible("interns") &&
                               !(intern?.status?.status?.split(" ").join("").toLowerCase() == "readyforproduction") && (
                                  <IconButton
                                    className="tablecell-button celledit-button"
                                    // style={{ padding: "5px " }}
                                    // component={RouterLink}
                                    // to={`/students/edit/${encryption(
                                    //   intern.id
                                    // )}`}
                                    // onClick={() =>
                                    //   navigate(
                                    //     `/interns/edit/${encryption(
                                    //       intern.id
                                    //     )}`,
                                    //     { state: { isIntern: 1 } }
                                    //   )
                                    // }

                                    onClick={(e) => window.open(`/interns/edit/${encryption(
                                          intern.id
                                        )}`,"_blank") }
                                  >
                                    {/* <EditIcon color="primary" /> */}
                                    <img src="assets/images/edit_icon.svg" title="Edit"/>
                                  </IconButton>
                                )}
                              
                              {/* {getEditVisible("interns") &&
                                !(intern?.candidateId || intern.employeeId) && (
                                  <IconButton
                                    style={{ padding: "5px" }}
                                    // component={RouterLink}
                                    onClick={(e) => window.open(`/interns/evaluate/${encryption(
                                      intern.id
                                    )}`,"_blank") }
                                    // to={`/interns/evaluate/${encryption(
                                    //   intern.id
                                    // )}`}
                                  >
                                    <ContentPasteSearchIcon />
                                  </IconButton>
                                )} */}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="No-Record-text" colSpan={12} align="left">
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <CustomPagination
          totalPage={totalPage}
          totalCount={totalCount}
          limit={limit}
          handleChangePage={handleChangePage}
          page={page}
          rowsPerPageOptions={[10, 20, 40]}
          handleRowsPerPageChange={handleRowsPerPageChange}
          // filter={isSearchQuery ? true : false}
        />
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack spacing={2} sx={style}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Typography id="modal-modal-title" variant="h4" component="h2">
                Production Date
              </Typography>
              <DesktopDatePicker
                label="Production Date"
                inputFormat="dd/MM/yyyy"
                minDate={new Date()}
                value={productionDate}
                onChange={(newValue) => {
                  newValue = new Date(newValue);
                  newValue.setHours(9, 0, 0, 0);
                  setProductionDate(newValue);
                  var CurrentDate = new Date();
                  var pDate = newValue;
                  var defaultDate = new Date();
                  defaultDate.setFullYear(1970, 0, 1);
                  pDate = new Date(pDate);
                  if (
                    newValue.setHours(0, 0, 0, 0) ==
                    defaultDate.setHours(0, 0, 0, 0)
                  ) {
                    setPdError({
                      flag: true,
                      title: "Please select Production date",
                    });
                  } else {
                    if (
                      pDate.setHours(0, 0, 0, 0) >=
                      CurrentDate.setHours(0, 0, 0, 0)
                    ) {
                      setPdError({ flag: false, title: "" });
                    } else {
                      setPdError({
                        flag: true,
                        title: "Production date should not be Past date",
                      });
                    }
                  }
                  // handleJoiningDate(new Date(newValue))
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    helperText={pdError?.title && pdError?.title}
                    error={Boolean(pdError?.flag && pdError?.flag)}
                  />
                )}
              />
            </LocalizationProvider>
            <Button variant="contained" onClick={readyForProduction}>
              Submit
            </Button>
          </Stack>
        </Modal>
        <Modal
          open={openImage}
          onClose={handleCloseImage}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
        <div className="view_employee candidate-details">
          <Stack className="Intern-Profile-popup" sx={style2}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2} className="grid_container">
                <Grid
                  item
                  xs={12}
                  style={{ maxWidth: "100%", flexBasis: "100%" }}
                  className="Intern-Profile-detail"
                >
                  
                  <Card className="information-card card_height">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    // mb={1}
                    // mt={2} 
                    ml={2}
                    mr={2}
                    className="headerTop"
                  >
                      <Typography variant="h6">Intern Profile</Typography>
                      <IconButton
                        onClick={() => handleCloseImage()}
                        className="icon-wrapper"
                      >
                          {/* <CloseIcon /> */}
                          <img src="/assets/images/close-circle.svg" />
                      </IconButton>
                    </Stack>
                    <Container className="personal-details-section" maxWidth="xl" >
                      <Stack className="personal-Stack" direction="row" justifyContent="space-between" mb={3} style={{ marginBottom:"0px"}}>
                        <div className="personal-image-section">
                          <Grid
                            item
                            xs={4}
                            style={{ maxWidth: "100%", flexBasis: "100%" , marginRight:"0"}}
                          >
                            
                            <Avatar
                              src={`${BASE_URL}/${internImage?.image}`}
                              alt={internImage?.image}
                              sx={{
                                width: "150px",
                                height: "150px",
                                fontSize: "2.5rem",
                                marginLeft: "auto",
                                marginRight: "auto",
                                marginBottom: "8px"
                              }}
                              >
                            </Avatar>
                            <Typography align="center">
                              <b>
                                {`${internImage?.firstName} ${internImage?.lastName}`}
                              </b>
                            </Typography>
                            <p className="job-position-txt">
                              {internImage?.technology?.title
                                ? internImage?.technology?.title
                                : "-"}
                            </p>
                            <p className="job-position-txt">
                              {internImage?.contact
                                ? internImage?.contact
                                : "-"}
                            </p>
                            <div className="profile-social-icon">
                              {internImage?.companySkype && (
                                <Box
                                  component="img"
                                  src="/assets/images/skype-graybox.svg"
                                  className="skype"
                                />
                              )}
                              {internImage?.linkedin && (
                                <Box
                                  component="img"
                                  src="/assets/images/linkedin-graybox.svg"
                                  className="linkdin"
                                  
                                />
                              )}
                              {internImage?.email && (
                                <a href={`mailto:${internImage?.email}`}>
                                  <Box
                                    component="img"
                                    src="/assets/images/mail.svg"
                                    className="mail-img"
                                    style={{
                                      width: "16px !important",
                                    }}
                                  />
                                </a>
                              )}
                            </div>
                          </Grid>
                        </div>

                        <Grid className="Intern-Profile-grid" item xs={8}>
                          <Card 
                            style={{ minHeight: "80%" }} 
                            className="address-card information-card"
                          >
                            {/* <Container> */}
                              {/* <TabContext value={value}> */}
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={10}
                                    style={{ maxWidth: "100%", flexBasis: "100%" }}
                                  >
                                    <div className="address-blk">
                                      <div className="address-sub-section general_sec">
                                        <div className="address-div">
                                          <span>Education</span>
                                            <p>{internImage?.course?.title+" (" + internImage?.batch+ ")" || "-"}</p>
                                        </div>
                                        <div className="address-div">
                                          <span>Graduation %</span>
                                          <p>{internImage?.graduationPercentage || "-"}</p>
                                        </div>
                                        <div className="address-div">
                                          <span>HSC/Diploma %</span>
                                          <p>{internImage?.hscPercentage != null ? internImage?.hscPercentage : internImage?.diplomaPercentage || "-"}</p>
                                        </div>
                                        <div className="address-div">
                                          <span>Type</span>
                                          <p>{internImage?.projectType || "-"}</p>
                                        </div>
                                        <div className="address-div">
                                          <span>College</span>
                                          <p>{internImage?.college?.collegeName || "-"}</p>
                                        </div>
                                        <div className="address-div">
                                          <span>Aptitude Mark</span>
                                          <p>{internImage?.marks || "-"}</p>
                                        </div>
                                        <div className="address-div blood-group-div">
                                          <p>{internImage?.evaluationGrade || ""}</p>
                                        </div>
                                      </div>
                                    </div>
                                    {/* <div style={{position:"relative"}}>
                                          <p style={{position: "absolute",bottom:"0",right:"0",}}>Bet 5 days ago</p>
                                    </div> */}
                                  </Grid>
                                </Grid>
                              {/* </TabContext> */}
                            {/* </Container> */}
                          </Card>
                        </Grid>
                      </Stack>
                    </Container>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </div>
      </Modal>
    </Card>
      
    </Container>
  );
}

const componentConfig = {
  component: Interns,
  path: "/interns",
  public: false,
  group: "tpa",
  layout: DashboardLayout,
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
