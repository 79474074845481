import DashboardLayout from "../../../layouts/dashboard";
import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  setSortBy,
  setOrderBy,
  setCurrentPage,
  setLimit,
} from "../../../redux/reportsEmployeeExperience/reportsEmployeeExperienceSlice";
import { encryption } from "../../../utils/encodeString";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { Paper } from "@mui/material";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import invertDirection from "../../../utils/invertDirection";
import Scrollbar from "../../../components/Scrollbar";
import CustomPagination from "../../../components/Pagination";
import {
  getExperienceDetails,
  getExperienceDetailsForExcel,
  totalCountExperienceDetails,
} from "../../../redux/reportsEmployeeExperience/reportsEmployeeExperienceThunk";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import * as XLSX from "xlsx";

function EmployeeExperienceReports() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isSearchQuery = useSelector(
    (state) => state.reportsEmployeeExperience.isSearchQuery
  );
  const [filterData, setFilterData] = useState({});
  const [filterChips, setFilterChips] = useState([]);
  const [searchValue, setSearchValue] = React.useState("");
  const inputRef = React.useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getViewVisible("reports")) {
      navigate("/dashboard");
    }
  }, []);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  const {
    data,
    loading,
    sortBy,
    orderBy,
    totalRecords,
    limit,
    currentPage,
    totalPages,
    status,
    error,
    totalCount,
  } = useSelector((state) => state.reportsEmployeeExperience);
 
  useEffect(() => {
    try {
      dispatch(totalCountExperienceDetails({})).unwrap();
      dispatch(
        getExperienceDetails({
          limit,
          page: currentPage + 1,
          sortBy,
          orderBy,
          search: searchValue,
        })
      ).unwrap();
    } catch (error) {
      toast.error(error?.message);
    }
  }, [limit, currentPage, error, sortBy, orderBy]);
  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    isSubmitting,
    setFieldValue,
  } = useFormik({
    initialValues: {
      search: "",
    },
    onSubmit: async (values) => {
      let { search } = values;
      try {
        await dispatch(getExperienceDetails({ search, limit, page: 1 }));
        dispatch(setCurrentPage({ page: 0 }));
        prepareChips(values);
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  const handleSearch = async (e) => {
    setFieldValue("search", e?.target?.value || "");
    setSearchValue(e?.target?.value);
    handleSubmit();
  };
  const debounceWithSearch = debounce(handleSearch, 500);

  const prepareChips = (values) => {
    let filters = { ...values };
    let tempChips = [];
    console.log("FILETERs", filters);
    for (const key in filters) {
      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
  };

  const onDeleteChip = (value, length) => {
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    let filters = { ...filterData, [value["type"]]: "" };
    setFilterData(filters);
    dispatch(getExperienceDetails({ limit, page: 1, sortBy, orderBy }));
    if (value.type === "search") {
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

  const exportFile = async () => {
    try {
      const data = await dispatch(getExperienceDetailsForExcel({})).unwrap();
      const worksheet = XLSX.utils.json_to_sheet(data?.data?.data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "EmployeesExperienceReport.xlsx");
    } catch (error) {
      toast.error(error?.message);
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <Typography variant="h4" gutterBottom>
          Employee Experience Reports
          <Typography variant="body2">
            Total Records : {totalCount || 0}
          </Typography>
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="flex-start">
          {/* 
            {AuthRole === "Super Admin" ? ( */}
          <>
            <Button
              variant="contained"
              onClick={()=>exportFile()}
              startIcon={<FileUploadSharpIcon />}
              className="filter-icon-part"
            >
              Export
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/reports"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back
            </Button>
          </>
        </Stack>
      </Stack>
      <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container spacing={3}>
              <Grid
                item
                xs={12}
                my={3}
                style={{ marginBottom: "17px", paddingTop: "17px" }}
              >
                <TextField
                  fullWidth
                  label="Search by Name"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "10px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {filterChips.length !== 0 || isSearchQuery ? (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Card>
              <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                page={currentPage}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                // filter={isSearchQuery ? true : false}
              />
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "fullName"}
                        direction={sortBy === "fullName" ? orderBy : "asc"}
                        onClick={() => handleSorting("fullName")}
                      >
                        Full Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "externalExp"}
                        direction={sortBy === "externalExp" ? orderBy : "asc"}
                        onClick={() => handleSorting("externalExp")}
                      >
                        External experience
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "cybercomExp"}
                        direction={sortBy === "cybercomExp" ? orderBy : "asc"}
                        onClick={() => handleSorting("cybercomExp")}
                      >
                        Cybercom experience
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "totalExperience"}
                        direction={
                          sortBy === "totalExperience" ? orderBy : "asc"
                        }
                        onClick={() => handleSorting("totalExperience")}
                      >
                        Total experience
                      </TableSortLabel>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : Array.isArray(data) && data?.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={3}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    Array.isArray(data) &&
                    data?.length > 0 &&
                    data?.map((record, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {record?.fullName || ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {record?.externalExp || ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {record?.cybercomExp || ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {record?.totalExperience || ""}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Card>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: EmployeeExperienceReports,
  path: "reports/employee-experience-report",
  public: false,
  layout: DashboardLayout,
  group: "reports",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
