import React, { useEffect, useRef, useState } from "react";
import DashboardLayout from "../../../../layouts/dashboard";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Timeline from "@mui/lab/Timeline";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent, {
  timelineOppositeContentClasses,
} from "@mui/lab/TimelineOppositeContent";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Link as RouterLink } from "react-router-dom";
import Button from "@mui/material/Button";
import { getGeneratedAssetsById } from "../../../../redux/assets/thunk";
import moment from "moment";
import Divider from "@mui/material/Divider";
import "./ViewSingleGeneratedAsset.css";
import { Chip, Link } from "@mui/material";
import { BASE_URL } from "../../../../constants";
import Loader from "../../../../components/Loader";
import { decryption, encryption } from "../../../../utils/encodeString";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { updateEmployeeProfile } from "../../../../redux/employee/employeeThunk";
import { getEditVisible } from "../../../../utils/userPermission";

function ViewSingleGeneratedAsset() {
  let { assetId, generatedId } = useParams();
  assetId = decryption(assetId);
  generatedId = decryption(generatedId);
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const statusDropDown = [
    { id: 1, title: "Working" },
    { id: 2, title: "In Repair" },
    { id: 3, title: "Scrap" },
    { id: 4, title: "Need Upgrade" },
  ];
  const fileInput = useRef(null);
  const navigate = useNavigate();
  const getGeneratedAsset = async () => {
    try {
      const response = await dispatch(
        getGeneratedAssetsById(generatedId)
      ).unwrap();

      console.log('response.data',response.data)
      let assetLogs = response.data.assetsLogs || [];
      let dataObj = {};
      let tempObj = {};
      let findStatus = statusDropDown.find(
        (t) => t.id == response.data.assetStatus
      );
      let newTags = response?.data?.assetTagGeneratedAssetsMapping?.map(
        (e) => e.assetTagsMaster
      );
      tempObj["createdDate"] = response.data.createdDate;
      tempObj["assetUniqueId"] = response.data.assetUniqueId;
      tempObj["name"] = response.data?.name;
      tempObj["location"] = response?.data?.location;
      tempObj["employee"] = response.data?.assetEmployee?.employee;
      tempObj["ipAddress"] = response.data.assetEmployee?.ipAddress;
      tempObj["assignDate"] = response.data.assetEmployee?.assignDate;
      tempObj["assetStatus"] = findStatus?.title;
      tempObj["assetNotes"] = response?.data.assetNotes || "";
      tempObj["assetTags"] = newTags || [];
	  
      dataObj["assetInfo"] = tempObj;

      const generatedAssetItems = response.data.generatedAssetItems;
      for (const key in generatedAssetItems) {
        let tempgenerateddata = generatedAssetItems[key];
        for (const innerKey in tempgenerateddata) {
          let tempdata = tempgenerateddata[innerKey];
          let tempObj = {};
          let dataObjKey;
          for (let i = 0; i < tempdata.length; i++) {
            dataObjKey = tempdata[i].item.name;
            if (tempdata[i].itemAttribute.allowFreeText) {
              tempObj[tempdata[i].itemAttribute.name] =
                `${tempdata[i].freeText}` || "-";
            } else {
              tempObj[tempdata[i].itemAttribute.name] =
                `${tempdata[i].itemAttributeValue?.value} ${
                  tempdata[i].itemAttribute?.unit || ""
                }` || "-";
            }
          }
          dataObj[dataObjKey] = [...(dataObj[dataObjKey] || []), tempObj];
        }
      }

      dataObj["assetLogs"] = assetLogs;
      setData(dataObj);
    } catch (error) {
      toast.error(error.message);
      // navigate('/assets');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    if (generatedId !== -1 && assetId !== -1) {
      getGeneratedAsset();
    } else {
      navigate("/assets");
    }
  }, [dispatch, generatedId]);

  const getCCCexperience = () => {
    let joiningDate =
      data["assetInfo"].employee?.workDetails?.joiningDate || null;
    let exp = moment().diff(joiningDate, "months");
    exp = exp / 12;
    return joiningDate ? exp.toFixed(1) : "-";
  };

  const getTotoalexperience = () => {
    let experience = data["assetInfo"].employee?.experience || [];
    if (experience.length) {
      let totalExp = experience.reduce((prev, curr) => {
        let dateDiff = moment(curr.to).diff(curr.from, "months");
        return prev + dateDiff;
      }, 0);
      totalExp = totalExp / 12;
      return totalExp.toFixed(1);
    }
    return "-";
  };

  const getAssetImage = (assetName) => {
    let asset = assetName.toLowerCase();
    if (asset === "ram") {
      return "/assets/images/icon-ram.svg";
    } else if (asset === "monitor") {
      return "/assets/images/icon-monitor.svg";
    } else if (asset === "storage disk") {
      return "/assets/images/icon-storageDisk.svg";
    } else if (asset === "headphone") {
      return "/assets/images/icon-headphone.svg";
    } else {
      return "/assets/images/icon-motherboard.svg";
    }
  };

  const handleImageChange = async (e, id) => {
    const formData = new FormData();

    formData.set("employeeImage", e.target.files[0]);

    let res = await dispatch(
      updateEmployeeProfile({ values: formData, id })
    ).unwrap();

    if (res.status === 200) {
      getGeneratedAsset();
    }
  };

  const mapAssetChangeData = (key, value) => {
    let data = "";
    Object.entries(value).forEach(([k, v]) => {
      if (k !== "item") {
        data += ` ${k}: ${v},`;
      }
    });
    data = data.replace(/,*$/, "");
    return <TreeItem label={`${value["item"]} ( ${data} )`} />;
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className="view_employee_view_asset">
      <Container maxWidth="xl">
        {/* <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
        sx={{
          position: "sticky",
          top: "64px",
          backdropFilter: "blur(6px)",
          backgroundColor: "rgba(255, 255, 255, 0.72)",
          padding: "10px 0",
          zIndex: 1,
        }}
      >
         <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Asset Info
        </Typography>
        </div>
			*/}
        <Stack direction="row" justifyContent="flex-end" mb={2}>
          <Button
            variant="contained"
            component={RouterLink}
            to={`/assets/${encryption(assetId)}/view`}
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back To List
          </Button>
        </Stack>
        <Stack direction="row" spacing={2.5}>
          <Stack className="left-box gray-box" pb={5} sx={{ width: "33%" }}>
            <Stack className="stack-bg"></Stack>
            <Stack className="img-block">
              <Stack
                className="img"
                style={{
                  background: `url(${BASE_URL}/${data["assetInfo"]?.employee?.employeeImage})`,
                }}
              ></Stack>

              {data["assetInfo"]?.employee?.id ? (
                <Stack className="edit-button">
                  <img
                    onClick={() => {
                      fileInput.current.click();
                    }}
                    src="/assets/images/edit-icon.svg"
                    alt="edit"
                    width="14px"
                  />
                  <input
                    ref={fileInput}
                    className="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={(e) =>
                      handleImageChange(e, data["assetInfo"]?.employee?.id)
                    }
                    hidden={true}
                  />
                </Stack>
              ) : (
                ""
              )}
            </Stack>
            <Stack className="mid-section">
              <Typography
                className="name"
                size="small"
                style={{ fontWeight: 600 }}
                textAlign="center"
                color="#FF9934"
                gutterBottom
              >
                {data["assetInfo"]?.employee?.firstName}{" "}
                {data["assetInfo"]?.employee?.lastName}
              </Typography>
              <Typography
                className="email"
                textAlign="center"
                color="#000"
                gutterBottom
                style={{ fontSize: 12, fontWeight: 400 }}
                mb={1.25}
              >
                {data["assetInfo"]?.employee?.emails &&
                  data["assetInfo"]?.employee?.emails[0]?.email}
              </Typography>
              <Stack
                className="e-links-block"
                direction="row"
                alignItems="center"
                justifyContent="center"
                divider={<Divider orientation="vertical" flexItem />}
              >
                <Stack className="block" direction="row" alignItems="center">
                  <img
                    src={
                      data["assetInfo"]?.location == "office"
                        ? "/assets/images/bag-icon.svg"
                        : "/assets/images/bag-icon.svg"
                    }
                    alt="bag-icon"
                    width="19px"
                  />

                  {/* {data["assetInfo"]?.location || "-"} */}
                </Stack>
                {data["assetInfo"]?.employee?.contact?.skype && (
                  <Stack className="block">
                    <a
                      href={`skype:${data["assetInfo"]?.employee?.contact?.skype}?chat`}
                    >
                      <Box
                        component="img"
                        src="/assets/images/skype-hover.svg"
                        className="linkdin"
                        style={{ width: "18px", height: "18px" }}
                      />
                    </a>
                  </Stack>
                )}
                {data["assetInfo"]?.employee?.contact?.linkedIn && (
                  <Stack className="block">
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={data["assetInfo"]?.employee?.contact?.linkedIn}
                    >
                      <Box
                        component="img"
                        src="/assets/images/linkdin.svg"
                        className="linkdin"
                        style={{ width: "24px", height: "24px" }}
                      />
                    </a>
                  </Stack>
                )}
              </Stack>
            </Stack>
            <Stack my={2}>
              <Divider />
            </Stack>
            <Stack mx={2.5}>
              <Grid container spacing={2.5}>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
                    Asset Id
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.assetUniqueId || "-"}
                  </Typography>
                  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
                    Employee Id
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.employee?.employeeId}
                  </Typography>
                  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
              </Grid>
              <Grid container spacing={2.5}>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
                    System Name
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.name || "-"}
                  </Typography>
                  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
                    Contact Name
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.employee?.contact?.contactNumber || "-"}
                  </Typography>
                  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
              </Grid>

              <Grid container spacing={2.5}>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
                    IP Address
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.ipAddress || "-"}
                  </Typography>
				  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
                    Asign Date
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.assignDate
                      ? moment(data["assetInfo"]?.assignDate).format(
                          "DD MMM YYYY"
                        )
                      : "-"}
                  </Typography>
				  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
              </Grid>
			  <Grid container spacing={2.5}>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
			              	  Asset Status
					     </Typography>
						 
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.assetStatus || "-"}
                  </Typography>
				  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
                <Grid item xs={6}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
				  Asset Tags
                  </Typography>
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.assetTags?.length > 0 ? (
                   data["assetInfo"]?.assetTags?.map((t) => 
				     (
						<Chip label= {t.name}
						size="small"
						key={t.id}
						variant="filled"
						style = {{margin : '6px 6px 0px 0px'}}
						/>

					 )  
				   )
					)  : "-"}
                  </Typography>
				  <Stack my={2}>
                    <Divider />
                  </Stack>
                  
                </Grid>
              </Grid>
			  <Grid container spacing={2.5}>
                <Grid item xs={12}>
                  <Typography mb={0.4} color="#808080" sx={{ fontSize: 10 }}>
			              	  Asset Notes
					     </Typography>
						 
                  <Typography sx={{ fontSize: 13, fontWeight: 500 }}>
                    {data["assetInfo"]?.assetNotes || "-"}
                  </Typography>
				  <Stack my={2}>
                    <Divider />
                  </Stack>
                </Grid>
              </Grid>
            </Stack>
            <Stack>&nbsp;</Stack>
            {data["assetInfo"]?.employee && (
              <Stack className="experiance-box" px={2.5}>
                <Typography
                  color="#000"
                  sx={{
                    background: "#f0f0f0",
                    fontSize: 13,
                    fontWeight: 500,
                    borderRadius: "8px 8px 0 0",
                  }}
                  p={1}
                  pl={1.25}
                >
                  Experience
                </Typography>
                <Stack
                  direction="row"
                  justifyContent="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  sx={{ background: "#F5F5F5" }}
                  spacing={4}
                  py={1.7}
                  mb={3}
                >
                  <Stack>
                    <Typography
                      color="#000"
                      textAlign="center"
                      sx={{ fontSize: 24, fontWeight: 500 }}
                    >
                      {getCCCexperience()}
                    </Typography>
                    <Typography
                      color="#808080"
                      textAlign="center"
                      sx={{ fontSize: 12 }}
                    >
                      Years in CCC
                    </Typography>
                  </Stack>
                  <Stack>
                    <Typography
                      color="#000"
                      textAlign="center"
                      sx={{ fontSize: 24, fontWeight: 500 }}
                    >
                      {getTotoalexperience()}
                    </Typography>
                    <Typography
                      color="#808080"
                      textAlign="center"
                      sx={{ fontSize: 12 }}
                    >
                      Total Exp. Years
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            )}
            {getEditVisible("assets") && (
              <Button
                className="btn-edit-assets"
                variant="outlined"
                size="small"
                color="inherit"
                component={RouterLink}
                to={`/assets/${encryption(assetId)}/add/${encryption(
                  generatedId
                )}`}
                sx={{
                  maxWidth: 110,
                  display: "flex",
                  gridGap: 10,
                  fontSize: 12,
                  fontWeight: 500,
                  margin: "0 auto 20px",
                  borderColor: "#FF9934",
                }}
              >
                <img
                  src="/assets/images/edit-icon.svg"
                  alt="edit"
                  width="14px"
                />{" "}
                Edit Assets
              </Button>
            )}
            {data["assetInfo"]?.employee?.id && (
              <Link
                href={`/employee/view/${encryption(
                  data["assetInfo"]?.employee?.id
                )}`}
                underline="always"
                color="#FF9934"
                textAlign="center"
                sx={{ fontSize: 12, fontWeight: 500, cursor: "pointer" }}
              >
                View Employee Profile
              </Link>
            )}
          </Stack>
          <Stack className="right-box" sx={{ width: "67%" }}>
            <Stack className="white-area" mb={2.5}>
              <Grid container spacing={2.5} p={3} className="configurations">
                {data["Operating System"] &&
                  data["Operating System"].length > 0 && (
                    <Grid item xs={6} className="configuration-box grey-box">
                      <Stack
                        className="title"
                        direction="row"
                        spacing={1.5}
                        mb={1}
                      >
                        <img
                          src="/assets/images/icon-processor.svg"
                          alt="processor-icon"
                          width="19px"
                        />
                        <Typography
                          color="#000"
                          sx={{ fontSize: 14, fontWeight: 500 }}
                        >
                          OS & Processor
                        </Typography>
                      </Stack>
                      <Stack className="grey-box" py={2} px={2.5}>
                        {data["Operating System"].map((op, index) =>
                          Object.keys(op).map((assetName, index) => (
                            <Grid container spacing={1.25}>
                              <Grid item xs={4}>
                                <Typography
                                  color="#808080"
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {assetName}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  color="#000"
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {op[assetName]}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))
                        )}
                        {data["Processor"].map((op, index) =>
                          Object.keys(op).map((assetName, index) => (
                            <Grid container spacing={1.25}>
                              <Grid item xs={4}>
                                <Typography
                                  color="#808080"
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {assetName}
                                </Typography>
                              </Grid>
                              <Grid item xs={8}>
                                <Typography
                                  color="#000"
                                  sx={{ fontSize: 12, fontWeight: 500 }}
                                >
                                  {op[assetName]}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))
                        )}
                      </Stack>
                    </Grid>
                  )}
                {data["Motherboard"] && data["Motherboard"].length > 0 && (
                  <Grid item xs={6} className="configuration-box grey-box">
                    <Stack
                      className="title"
                      direction="row"
                      spacing={1.5}
                      mb={1}
                    >
                      <img
                        src="/assets/images/icon-motherboard.svg"
                        alt="icon-motherboard"
                        width="16px"
                      />
                      <Typography
                        color="#000"
                        sx={{ fontSize: 14, fontWeight: 500 }}
                      >
                        Motherboard
                      </Typography>
                    </Stack>
                    <Stack className="grey-box" py={2} px={2.5}>
                      {data["Motherboard"].map((mb, index) =>
                        Object.keys(mb).map((assetName, index) => (
                          <Grid container spacing={1.25}>
                            <Grid item xs={4}>
                              <Typography
                                color="#808080"
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {assetName}
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography
                                color="#000"
                                sx={{ fontSize: 12, fontWeight: 500 }}
                              >
                                {mb[assetName]}
                              </Typography>
                            </Grid>
                          </Grid>
                        ))
                      )}
                    </Stack>
                  </Grid>
                )}
                {Object.keys(data).length > 0 &&
                  Object.keys(data)
                    .filter(
                      (d) =>
                        d !== "Operating System" &&
                        d !== "Motherboard" &&
                        d !== "assetLogs" &&
                        d !== "assetInfo" &&
                        d !== "Processor"
                    )
                    .map((assetName, index) => (
                      <Grid item xs={6} className="configuration-box grey-box">
                        <Stack
                          className="title"
                          direction="row"
                          spacing={1.5}
                          mb={1}
                        >
                          <img
                            src={getAssetImage(assetName)}
                            alt="icon-ram"
                            width="21px"
                          />
                          <Typography
                            color="#000"
                            sx={{ fontSize: 14, fontWeight: 500 }}
                          >
                            {assetName}
                          </Typography>
                        </Stack>
                        <Stack className="grey-box" py={2} px={2.5}>
                          <Grid container spacing={1}>
                            {data[assetName].map((mb, index) => {
                              return (
                                <>
                                  <Grid item xs={5.9}>
                                    {Object.keys(mb).map((assetName, index) => {
                                      return (
                                        <Grid container spacing={6}>
                                          <Grid item xs={5}>
                                            <Typography
                                              color="#808080"
                                              sx={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                            >
                                              {assetName}
                                            </Typography>
                                          </Grid>
                                          <Grid item xs={7}>
                                            <Typography
                                              color="#000"
                                              sx={{
                                                fontSize: 12,
                                                fontWeight: 500,
                                              }}
                                            >
                                              {mb[assetName]}
                                            </Typography>
                                          </Grid>
                                        </Grid>
                                      );
                                    })}
                                  </Grid>
                                  {data[assetName].length > 1 &&
                                  index % 2 == 0 ? (
                                    <Divider orientation="vertical" flexItem />
                                  ) : null}
                                </>
                              );
                            })}
                          </Grid>
                        </Stack>
                      </Grid>
                    ))}
              </Grid>
            </Stack>
            <Stack className="white-area grey-box">
              <Stack
                className="section-title"
                direction="row"
                alignItems="center"
                color="#000"
                sx={{
                  background: "#F0F0F0",
                  fontSize: 14,
                  fontWeight: 500,
                  gridGap: 10,
                }}
                py={1.5}
                px={2.5}
              >
                <img
                  src="/assets/images/history-icon.svg"
                  alt="history-icon"
                  width="16px"
                />
                History
              </Stack>
              {/* {console.log(data["assetLogs"])} */}
              <Stack className="area-content" py={3} px={2.5}>
                {data["assetLogs"] &&
                  data["assetLogs"].length > 0 &&
                  data["assetLogs"]
                    .slice(0)
                    .reverse()
                    .map((log, index) => (
                      <Timeline
                        sx={{
                          [`& .${timelineOppositeContentClasses.root}`]: {
                            flex: 0.2,
                          },
                        }}
                        className="history-row active"
                      >
                        <TimelineItem sx={{ minHeight: "fit-content" }}>
                          <TimelineOppositeContent color="textSecondary">
                            {log.date
                              ? moment(log.date).format("DD MMM YYYY")
                              : "-"}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot sx={{ bgcolor: "primary.main" }} />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ height: "fit-content" }}>
                            {log?.newEmployeeName &&
                              log?.oldEmployeeName &&
                              log?.isMigrate == false && (
                                <p>
                                  {log?.oldEmployeeName} <span>&gt;</span>
                                  <strong> {log?.newEmployeeName}</strong>
                                </p>
                              )}
                            {log?.newEmployeeName &&
                              !log?.oldEmployeeName &&
                              log?.isMigrate == false && (
                                <p>
                                  Assigned to{" "}
                                  <strong>{log?.newEmployeeName}</strong>
                                </p>
                              )}
                            {!log?.newEmployeeName &&
                              log?.oldEmployeeName &&
                              log?.isMigrate == false && (
                                <p>
                                  Assigned back from{" "}
                                  <strong>{log?.oldEmployeeName}</strong>
                                </p>
                              )}
                            {!log?.newEmployeeName &&
                              !log?.oldEmployeeName &&
                              log?.isMigrate == false && (
                                <p>System Available</p>
                              )}
                            {log?.isMigrate == true ? (
                              <p>Asset has been Migrate from Server to PC</p>
                            ) : (
                              ""
                            )}
                            <TreeView
                              aria-label="file system navigator"
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpandIcon={<ChevronRightIcon />}
                              sx={{
                                overflowY: "auto",
                              }}
                            >
                              {Object.keys(log?.newAsset).length > 0 ? (
                                <TreeItem nodeId="1" label="New Added Item">
                                  {Object.keys(log?.newAsset).map(
                                    (assetItemName, index) =>
                                      mapAssetChangeData(
                                        assetItemName,
                                        log?.newAsset[assetItemName]
                                      )
                                  )}
                                </TreeItem>
                              ) : (
                                ""
                              )}

                              {Object.keys(log?.deletedAsset).length > 0 ? (
                                <TreeItem nodeId="2" label="Deleted Item">
                                  {Object.keys(log?.deletedAsset).map(
                                    (assetItemName, index) =>
                                      mapAssetChangeData(
                                        assetItemName,
                                        log?.deletedAsset[assetItemName]
                                      )
                                  )}
                                </TreeItem>
                              ) : (
                                ""
                              )}
                            </TreeView>
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    ))}

                <Timeline
                  sx={{
                    [`& .${timelineOppositeContentClasses.root}`]: {
                      flex: 0.2,
                    },
                  }}
                  className="history-row active"
                >
                  <TimelineItem sx={{ minHeight: "fit-content" }}>
                    <TimelineOppositeContent color="textSecondary">
                      {data["assetInfo"]?.createdDate
                        ? moment(data["assetInfo"].createdDate).format(
                            "DD MMM YYYY"
                          )
                        : "-"}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot sx={{ bgcolor: "primary.main" }} />
                    </TimelineSeparator>
                    <TimelineContent sx={{ height: "fit-content" }}>
                      Cybercom System assigned
                    </TimelineContent>
                  </TimelineItem>
                </Timeline>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Container>
    </div>
  );
}

const componentConfig = {
  component: ViewSingleGeneratedAsset,
  path: "/assets/:assetId/view/:generatedId",
  public: false,
  layout: DashboardLayout,
  group: "asset",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
