import { createSlice } from '@reduxjs/toolkit';
import { getGrade } from './gradeThunk';

let data = [];

const initialState = {
	data,
	loading: false,
  sortBy: 'id',
  orderBy: 'DESC',
};

let team = sessionStorage.getItem('team');

if (team) {
	try {
		let json = JSON.parse(team);
		if (+new Date() - json.timestamp < 1000 * 60 * 60) {
			data = json.data;
		}
	} catch (e) {}
}

// A slice for getBloodGroup with our 3 reducers
export const gradeSlice = createSlice({
	name: 'team',
	initialState,
	reducers: {
		setSortBy: (state, action) => {
			state.sortBy = action.payload.sortBy;
			state.status = "idle";
		},
		setOrderBy: (state, action) => {
			state.orderBy = action.payload.orderBy;
			state.status = "idle";
		},
		setLimit: (state, action) => {
			state.limit = action.payload.limit;
			state.status = "idle";
		},
		setCurrentPage: (state, action) => {
			state.currentPage = action.payload.page;
			state.status = "idle";
		},
	},
	extraReducers: {
		[getGrade.pending]: (state) => {
			state.loading = true;
		},
		[getGrade.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.data = payload;
		},
		[getGrade.rejected]: (state, payload) => {
			state.loading = false;
			state.data = [];
			state.error = payload;
		},
	},
});

// The reducer
export const gradeReducer = gradeSlice.reducer;
export const { setLimit, setCurrentPage, setSortBy, setOrderBy } = gradeSlice.actions;
