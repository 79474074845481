import * as Yup from "yup";

const cardboard = Yup.object().shape({
  approachBy: Yup.string(),
  candidateStatusId: Yup.string().required("Candidate status is required"),
  candidateStateId:Yup.string().test("superior", "Candidate state is required", function (f) {
    // const ref = Yup.ref("cardCollem");
    const ref = Yup.ref("candidateStatusId");
    if(this.resolve(ref)==1||this.resolve(ref)==3||this.resolve(ref)==4||this.resolve(ref)==6){
      if(f){
        return true
      }else{
      return false
      }
    }else{
      return true
    }
  }).nullable(),
  joiningDate:Yup.string().test("superior", "Joining date is required", function (f) {
    const ref = Yup.ref("candidateStatusId");
    const ref2= Yup.ref("candidateStatename");
    if(this.resolve(ref)==5||this.resolve(ref2)=="Offer Accepted"){
      if(f){
      return true
      }else{
        return false
      }
    }else{
      return true
    }
    // return f > this.resolve(ref);
  }).nullable(),
  note: Yup.string()
    .required("Note is required")
    .max(512, "Note is too long")
    .nullable(),
});

export default cardboard;
