import Grid from "@mui/material/Grid";
import DashboardLayout from "../../layouts/dashboard";
import Container from "@mui/material/Container";
import PollCard from "../../components/_dashboard/app/PollCard";
import {
  getAllPoll,
  getoTotalPoll,
  updateStatus,
  deletePollById,
} from "../../redux/poll/pollThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { encryption } from "../../utils/encodeString";
import Loader from "../../components/Loader";
import SinglePoll from "./PollComponents/SinglePoll";
import {BASE_URL} from "../../constants"
const DashboardPoll = () => {
  const dispatch = useDispatch();
  const pollCount = useSelector((state) => state.pollCount.data);

  const pollCountLoading = useSelector((state) => state.pollCount.loading);

  const {
    data,
    currentPage,
    status,
    limit,
    count,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
  } = useSelector((state) => state.poll);

  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      getAllPoll({
        sortBy,
        orderBy,
        search: "",
        status: "1",
      })
    );
    // }
    // return () => {
    // 	dispatch(setStatus({ status: "idle" }));
    // };
  }, [dispatch, limit, currentPage, sortBy, orderBy]);

  useEffect(() => {
    dispatch(getoTotalPoll({}));
  }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  console.log("DATA_________01", data.data);

  return (
    <Container maxWidth="xl">
      <div className="view_employee view_asset employee">
        <Container maxWidth="xl">
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            <Grid
              item
              xs={2}
              sm={4}
              md={3}
              //   onClick={() => filterFunction("ALL")}
            >
              <PollCard
                number={pollCount?.count || 0}
                loading={pollCountLoading}
                type="total"
                title="Total Poll"
              />
            </Grid>

            <Grid
              item
              xs={2}
              sm={4}
              md={3}
              //   onClick={() => filterFunction("OPEN")}
            >
              <PollCard
                number={pollCount?.activeCount || 0}
                loading={pollCountLoading}
                type="active"
                title="Active Poll"
              />
            </Grid>

            <Grid
              item
              xs={2}
              sm={4}
              md={3}
              //   onClick={() => filterFunction("CLOSE")}
            >
              <PollCard
                number={pollCount?.inactiveCount || 0}
                loading={pollCountLoading}
                type="inactive"
                title="InActive Poll"
              />
            </Grid>

            <Grid
              item
              xs={2}
              sm={4}
              md={3}
              //   onClick={() => filterFunction("ALL")}
            >
              <PollCard
                number={pollCount?.activeUsers || 0}
                loading={pollCountLoading}
                type="user"
                title="Active User"
              />
            </Grid>
          </Grid>
        </Container>
      </div>
      <Container maxWidth="xl">
        {loading ? (
          <Loader />
        ) : data?.data?.length > 0 ? (
          <div>
            {data?.data?.map((ele) => (
              <SinglePoll ele={ele} />
            ))}
          </div>
        ) : (
          <div className="No-Record-text">No Record(s) Found</div>
        )}
      </Container>
    </Container>
  );
};

const componentConfig = {
  component: DashboardPoll,
  path: "/polldashboard",
  public: false,
  layout: DashboardLayout,
  group: "poll",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
