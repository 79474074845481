import React, { useEffect, useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useFormik } from "formik";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import LoadingButton from "@mui/lab/LoadingButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { updateInventoryItemStatus } from "../../../redux/assestManagment/thunk";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk/index";
import { Autocomplete, Grid, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import itemModalSchema from "../../../validations/itemModalSchema"
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const StatusModal = NiceModal.create(
  ({ id, status, serialNumber, employeeId }) => {
    const [loading, setLoading] = useState(false);
    // const [itemStatus, setItemStatus] = useState(status);
    const dispatch = useDispatch();
    const modal = useModal();
    const StatusDropdown = ["FREE", "USE", "SCRAP", "INSERVICE"];
    const EmployeeDropDown = useSelector(
      (state) => state.employee.allEmployeeData
    );

 ;

    const formik = useFormik({
      initialValues: {
        id: "",
        status: "",
        serialNumber: "",
        employeeId: null,
      },
       validationSchema: itemModalSchema,
      onSubmit: async (values, { resetForm }) => {
     
         if(values.status !== 'USE'){
           values = {...values,employeeId: null}
         }
    	try {
    		setLoading(true);

    		const response = await dispatch(
    			updateInventoryItemStatus({ ...values })
    		).unwrap();

    		if (response.status === 200) {
    			modal.resolve({ success: true });
    			modal.hide();
    		}
    	} catch (error) {
    		// console.log(error);
    	} finally {
    		setLoading(false);
    	}
    }
    });

    const {
      values,
      handleChange,
      setFieldValue,
      touched,
      errors,
      handleSubmit,
      getFieldProps,
      handleBlur,
      setValues,
    } = formik;

    useEffect(() => {
      setValues({
        id: id,
        status: status || "",
        serialNumber: serialNumber || "",
        employeeId: employeeId || null,
      });
    }, []);

    useEffect(() => {
      const fetchEmployeeData = async () => {
        try {
          await dispatch(getEmployeeForDropDown()).unwrap();
        } catch (error) {
          // Handle errors if needed (e.g., show a toast message)
          console.error("Error fetching employee data:", error);
          // toast.error(error.message); // Uncomment if you use a toast library
        }
      };
  
      fetchEmployeeData();
    }, []);

    return (
      <Dialog
        TransitionComponent={Transition}
        open={modal.visible}
        onClose={() => modal.hide()}
        TransitionProps={{
          onExited: () => modal.remove(),
        }}
        fullWidth
      >
        <form onSubmit={handleSubmit}>
          <DialogTitle id="alert-dialog-slide-title">Change status</DialogTitle>
          <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
            {/* <FormControl variant="standard" fullWidth>
					<InputLabel id="status-label">Status</InputLabel>
					<Select
						labelId="status-label"
						value={itemStatus}
						label="Status"
						onChange={(e) => setItemStatus(e.target.value)}
					>
						<MenuItem value="FREE">FREE</MenuItem>
						<MenuItem value="USE">USE</MenuItem>
						<MenuItem value="SCRAP">SCRAP</MenuItem>
						<MenuItem value="INSERVICE">INSERVICE</MenuItem>
					</Select>
				</FormControl> */}

            <Grid container spacing={3} pt={2}>
              <Grid item xs={6}>
                <Autocomplete
                  autoComplete="off"
                  size="small"
                  name="status"
                  fullWidth
                  options={StatusDropdown || []}
                  key={StatusDropdown || []}
                  onChange={(event, newValue) => {
                    setFieldValue("status", newValue);
                  }}
                  getOptionLabel={(option) => option}
                  value={values?.status}
                  renderInput={(params) => (
                    <TextField {...params} label="Status" 
                    error={Boolean(
                      touched?.status && errors?.status
                    )}
                    helperText={touched?.status && errors?.status}
                    

                    />
                  )}
                />
              </Grid>
             { values?.status  === 'USE' &&  <Grid item xs={6}>
                <Autocomplete
                  autoComplete="off"
                  size="small"
                  name="employeeId"
                  fullWidth
                  options={EmployeeDropDown || []}
                  key={EmployeeDropDown || []}
                  onChange={(event, newValue) => {
                    setFieldValue("employeeId", newValue.id);
                  }}
                  getOptionLabel={(option) => `${option?.firstName} ${option.lastName}`}
                  value={EmployeeDropDown?.find((ele) => ele.id == values?.employeeId)}
                  renderInput={(params) => (
                    <TextField {...params} label="Assigned To" 
                    
                    error={Boolean(
                      touched?.employeeId && errors?.employeeId
                    )}
                    helperText={touched?.employeeId && errors?.employeeId}
                    />
                  )}
                />
              </Grid>}
            </Grid>
          </DialogContent>

          <DialogActions>
            <Button
              color="error"
              variant="contained"
              onClick={() => modal.hide()}
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
);
