import DashboardLayout from "../../layouts/dashboard";
import { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import LoadingButton from "@mui/lab/LoadingButton";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import React from "react";
import { debounce } from "lodash";
// import MailIcon from '@mui/icons-material/Mail';
import Autocomplete from "@mui/material/Autocomplete";
import SyncIcon from "@mui/icons-material/Sync";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Loader from "../../components/Loader";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import {
  getAllEmail,
  getNewEmail,
  getMessagesCount
} from "../../redux/emailInbox/emailinboxThunk";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../components/Pagination";
import { emailListDate } from "../../utils/dateFormat";
import { encryption } from "../../utils/encodeString";
import {
  setLimit,
  setcurrentPage,
  setSortBy,
  setOrderBy,
} from "../../redux/emailInbox/emailInboxSlice";
import { Chip } from "@mui/material";
function EmailInbox() {
  const [selectedFilter, setSelectedFilter] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  let [searchsting, setSearchString] = useState("");
	const [filterChips, setFilterChips] = useState([]);
  const[totalEmail,setTotalEmail] = useState(0);
  const inputRef = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [bool,setBool] = useState(false);

  const { data, loading, limit, totalPages, totalRecords, currentPage, sync ,totalEmails} =
    useSelector((res) => res.emailInbox);
  const filterArray = [
    { id: "", title: "All" },
    { id: "read", title: "Read Email" },
    { id: "unread", title: "Unread Email" },
  ];
  const handleClick = async () => {
    await dispatch(getNewEmail()).unwrap();
    await dispatch(
      getAllEmail({
        page: 1,
        limit: 40,
        orderBy: "",
        sortBy: "",
        search: searchsting,
        emailStatus: selectedFilter
      })
    ).unwrap();
  };

  function handleViewemail(emailId) {
    navigate(`/email-inbox/view/${encryption(emailId)}`);
  }
  const handleChangePage = async (event, newPage) => {
    await dispatch(setcurrentPage({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = async (event) => {
    await dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };
  const handleSearch = async (e) => {
    setIsSearching(true);
    setSearchString(e.target.value);
  };

  const ccEmailName = (ccemails) =>{
      let ccnames = ccemails.split(",")
      let firstccEmail = ccnames[0]
      let name = firstccEmail.split('@')
    return name[0];
  }
  const debounceWithSearch = debounce(handleSearch, 500);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getAllEmail({
            page: currentPage,
            limit: limit,
            orderBy: "",
            sortBy: "",
            search: searchsting,
            emailStatus: selectedFilter,
          })
        ).unwrap();

        // Optionally handle the response if needed
      } catch (error) {
        // Handle error (e.g., display an error message)
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch, limit, currentPage, selectedFilter, searchsting, isSearching]);

// for getting chips data
  useEffect(() => {
    let searchParams = {}
    searchParams.search = searchsting
    searchParams.selectedFilter = selectedFilter
    prepareChips(searchParams);
  },[searchsting,selectedFilter])

  // making chips from data
  const prepareChips = (searchParams) => {
		let filters = { ...searchParams };
		let tempChips = [];
    searchParams.search = searchsting
    searchParams.selectedFilter = selectedFilter
    for (const key in filters){
      if(filters[key] && filters[key] === "search"){
        filters[key] = filters[key]
      }
      if (filters[key] && key === 'selectedFilter') {
				filters[key] = `Email Status : ${filters[key]}`
			}
      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
	}

  // Delete chips logic
  const onDeleteChip = (value, length) => {
    let { type } = value;
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    setFilterChips([...updateChips]);
    if (type === "search") {
      setSearchString("");
      inputRef.current.value = "";
    }
    if (type === "selectedFilter") {
      setSelectedFilter("");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getMessagesCount()).unwrap();
        // Optionally handle the response if needed
      } catch (error) {
        // Handle error (e.g., display an error message)
        console.error(error);
      }
    };

    fetchData();
  }, []);



  return (
    <Container className="Email-Inbox-container Employee-wrapper" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
       <div className="title-button-wrapper Email-Inbox-title">
        <Typography variant="h4" gutterBottom>
          Email Inbox {totalEmails }{" "}
          {/* <Typography variant="body2">
            Total Emails : {totalEmails }{" "}
          </Typography> */}
        </Typography>
        <Stack direction="row" justifyContent="flex-end">
          <LoadingButton
            onClick={handleClick}
            loading={sync}
            loadingPosition="start"
            startIcon={<SyncIcon />}
            variant="contained"
            style={{ marginLeft: "10px" }}
          >
            <span>New Emails</span>
          </LoadingButton>
        </Stack>
        </div>
      </Stack>
      {
        <>
          <Card className="Email-Inbox-main-card gray-box Employee_Detail_Content">
            <Container style={{ maxWidth: "100%" }}>
              <Grid container xs={12} pt={2} pb={2} spacing={3}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label="Search by Sender ccEmail"
                    name="search"
                    inputRef={inputRef}
                    variant="outlined"
                    size="small"
                    onChange={debounceWithSearch}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    // sx={{ backgroundColor: "white" }}
                    options={filterArray || []}
                    getOptionLabel={(option) => option?.title || ""}
                    onChange={(event, newValue) => {
                      setSelectedFilter(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Email Status" />
                    )}
                    value={filterArray.find(
                      (dept) => dept?.id === selectedFilter
                    )}
                  />
                </Grid>
              </Grid>
            </Container>
          </Card>
          <Stack
            className="search-filter"
            direction="row"
            alignItems="start"
          >
          {filterChips.map((chips, idx) => {
            return (
              <Chip
                className="search-filter-detail"
                label={chips?.filter}
                color="primary"
                size="small"
                key={chips?.filter}
                style={{ margin:"10px 5px 10px 0" }}
                variant="filled"
                onDelete={() => onDeleteChip(chips, filterChips?.length)}
              />
            );
          })}
        </Stack>
      {filterChips?.length > 0 ? (
        <Typography className="record-found-text" variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
          <Card>
            <TableContainer component={Paper} sx={{ minWidth: 800 }}>
              <Table className="Email-Inbox-table grid-table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      Sender Name
                    </TableCell>
                    <TableCell>Subject</TableCell>
                    <TableCell>ccEmail</TableCell>
                    <TableCell>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableCell align="center" colSpan={6}>
                      <Loader />
                    </TableCell>
                  ) : Array.isArray(data) && data?.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={6}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((email, index) => (
                      <TableRow
                        sx={{
                          height: "40px",
                          backgroundColor: email?.candidateId
                            ? "#f2f6fc"
                            : "white",
                          cursor: "pointer",
                          "&:hover": {
                            boxShadow:
                              "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;",
                            borderLeft: "3px solid #FDA92D",
                          },
                        }}
                        onClick={() => handleViewemail(email?.id)}
                        key={index}
                      >
                        <TableCell>
                          <p
                            style={{
                              display: "block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              fontWeight: email?.candidateId ? 400 : 700,
                              color: email?.candidateId ? "#5f6368" : "#202124",
                              textOverflow: "ellipsis",
                              width: "100%",
                            }}
                          >
                            {email?.senderName}
                          </p>
                        </TableCell>

                        <TableCell>
                          <p
                            style={{
                              display: "block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              fontWeight: email?.candidateId ? 400 : 700,
                              color: email?.candidateId ? "#5f6368" : "#202124",
                              border: "none",
                              width: "100%",
                            }}
                          >
                            {email?.subject || "(no subject)"}
                          </p>
                        </TableCell>
                        <TableCell>
                          <p
                            style={{
                              display: "block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              border: "none",
                              width: "100%",
                              color: email?.candidateId ? "#5f6368" : "#202124",
                              fontWeight: email?.candidateId ? 400 : 700,
                            }}
                          >
                            {email?.ccEmail?.length > 0 ? ccEmailName(email?.ccEmail) :"(no ccEmail)"}
                          </p>
                        </TableCell>
                        <TableCell
                          style={{
                            maxWidth: "0",
                            fontWeight: 700,
                            color: email?.candidateId ? "#5f6368" : "#202124",
                          }}
                        >
                          {emailListDate(email?.sendDate)}
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <CustomPagination
              totalPage={totalPages}
              totalCount={totalRecords}
              limit={limit}
              handleChangePage={handleChangePage}
              // page={currentPage}
              page={currentPage > 0 ? currentPage - 1 : 0}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </>
      }
    </Container>
  );
}

const componentConfig = {
  component: EmailInbox,
  path: "/email-inbox",
  public: false,
  layout: DashboardLayout,
  group: null,
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
