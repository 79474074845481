import React, { useEffect, useState, useRef } from "react";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Icon, Modal } from "@mui/material";

import NiceModal from "@ebay/nice-modal-react";
import { toast } from "react-toastify";
import { styled, alpha } from "@mui/material/styles";
import DashboardLayout from "../../../layouts/dashboard";
import Loader from "../../../components/Loader";
import CustomPagination from "../../../components/Pagination";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { decryption, encryption } from "../../../utils/encodeString";
import Scrollbar from "../../../components/Scrollbar";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import LoadingButton from "@mui/lab/LoadingButton";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import TimelineDot from "@mui/lab/TimelineDot";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VisibilityIcon from "@mui/icons-material/Visibility";
import LaptopMacIcon from "@mui/icons-material/LaptopMac";
import HotelIcon from "@mui/icons-material/Hotel";
import RepeatIcon from "@mui/icons-material/Repeat";
import DescriptionIcon from "@mui/icons-material/Description";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import { getChangeRequest } from "../../../redux/changeRequest/changeRequestThunk";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

import {
  getEmployeeById,
  updateEmployeeProfile,
  sendPasswordMail,
  getEmployeeForDropDown,
  createEmployeeEvaluationGroup,
  getEmployeeEvaluationGroupByEmployeeId,
  getEmployeeExitInterview,
  getEmployeeRetentionHistory,
} from "../../../redux/employee/employeeThunk";
import { BASE_URL } from "../../../constants";
import { indianDateFormat, monthYearFormat } from "../../../utils/dateFormat";
import { fDateTime } from "../../../utils/formatTime";
import moment from "moment";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./View-employee.css";
import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import MailIcon from "@mui/icons-material/Email";
import Checkbox from "@mui/material/Checkbox";
import ImageCropModal from "../../../components/ImageCrop";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import DesktopWindows from "@mui/icons-material/DesktopWindows";
import Storage from "@mui/icons-material/Storage";
import TabletAndroid from "@mui/icons-material/TabletAndroid";
import Print from "@mui/icons-material/Print";
import Smartphone from "@mui/icons-material/Smartphone";
import LaptopWindows from "@mui/icons-material/LaptopWindows";
import LaptopMac from "@mui/icons-material/LaptopMac";
import { Autocomplete, IconButton, TextField } from "@mui/material";
import {
  setLimit,
  setPage,
  setSortBy,
  setOrderBy,
} from "../../../redux/changeRequest/changeRequestSlice";
import invertDirection from "../../../utils/invertDirection";
import { useFormik } from "formik";
import viewEmployeeSchema from "../../../validations/viewEmployeeSchema";
import { updateTaskStatus } from "../../../redux/employee/employeeThunk";
import { checkList } from "../../../validations/mastersSchema";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { LightTooltip } from "../ParkingAllocation";
const ITEM_HEIGHT = 100;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT + ITEM_PADDING_TOP,
      width: 140,
    },
  },
};

let totalYears = 0;
let totalMonths = 0;
const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const iconConfig = {
  DesktopWindows,
  Storage,
  TabletAndroid,
  Print,
  Smartphone,
  LaptopWindows,
  LaptopMac,
};

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  height: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  overflow: "auto",
  overflowY: "auto",
};

function ViewEmployee() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = React.useState("1");
  const allEmployeeData = useSelector(
    (state) => state.employee.allEmployeeData
  );
  // console.log("ALL_EMPLOYEE_____",allEmployeeData)
  const navigation = useNavigate();
  const fileInput = useRef(null);
  let { id } = useParams();
  if (id) id = decryption(id);
  const userName = useSelector((state) => state.auth.fullName);
  const AuthRole = useSelector((state) => state.auth.role);
  const [employee, setEmployee] = useState({});
  const [employeeLog, setEmployeeLog] = useState([]);
  const [loading, setLoading] = useState(true);
  const [note, setNote] = useState("");
  const [showNote, setShowNote] = useState(false);
  const [experience, setExperience] = useState({
    totalExpYears: 0,
    totalExpMonths: 0,
    totalCCCYears: 0,
    totalCCCMonths: 0,
  });
  const [mailDropDownClasses, setMailDropDownClasses] = useState([
    "mail-dropdown-list",
    "mail-drop-close",
  ]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [defaultEmail, setDefaultEmail] = useState(null);
  const [dropDownIcon, setDropDownIconIcon] = useState(true);
  const [emailloading, setemailLoading] = React.useState(false);
  const [backlink, setBackLink] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [toolTipData,setToolTipData]=useState('')
  const EmployeeEvaluationData = useSelector(
    (state) => state?.employee?.getEmployeeEvaluationGroupByEmployeeId
  );
  const data = useSelector((state) => state.changeRequest.data);
  const page = useSelector((state) => state.changeRequest.page);
  const totalPage = useSelector((state) => state.changeRequest.totalPage);
  const totalCount = useSelector((state) => state.changeRequest.totalCount);
  const limit = useSelector((state) => state.changeRequest.limit);
  const sortBy = useSelector((state) => state.changeRequest.sortBy);
  const orderBy = useSelector((state) => state.changeRequest.orderBy);
  const { data : serviceRetentionHistory} = useSelector((state) => state.employee.serviceRetentionHistoryDetails);
  // const loading = useSelector((state) => state?.ChangeRequest?.loading)
  // console.log("EmployeeEvaluationData",EmployeeEvaluationData)



  const getEducationCourse = (education) => {
    let courseType = education?.course;
    let educationType = education?.educationtype?.title;

    if (courseType && educationType) {
      return `${educationType} - ${courseType?.title}`;
    }

    if (!courseType && educationType) {
      return educationType;
    }
    if (courseType && !educationType) {
      return courseType?.title;
    }

    return "";
  };
  useEffect(() => {
    dispatch(getEmployeeEvaluationGroupByEmployeeId({ id: id })).unwrap();
    dispatch(getEmployeeRetentionHistory({employeeId:id}))
  }, []);

  let total=0

  useEffect(()=>{
    const bool = serviceRetentionHistory?.every((val)=>val.isPaid)
    if(!bool){
      serviceRetentionHistory?.map((val)=>{
        if(!val.isPaid && !val.isDefault){
          total = total+val.amount
        }
        return total
      })
       setToolTipData(total)
    }
  },[serviceRetentionHistory])

  const employeeExitInterviewData = useSelector(
    (state) => state.employee.employeeExitInterviewData
  );

  // console.log("employeeExitInterviewData",employeeExitInterviewData)

  useEffect(() => {
    if (id === -1) {
      navigation("/employee");
    }
  }, [id]);

  useEffect(() => {
    dispatch(getEmployeeExitInterview(id));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      employeeGroupingIds: [],
      notes: "",
      status: "",
    },
    // validationSchema: viewEmployeeSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // console.log("VALUES____",values)
        let createEvaluationGroupObj = {
          employeeId: id,
          employeeGroupingIds:
            values?.employeeGroupingIds?.length > 0
              ? values?.employeeGroupingIds
              : null,
        };
        await dispatch(
          createEmployeeEvaluationGroup(createEvaluationGroupObj)
        ).unwrap();
        // navigate("/employee");
        // resetForm();
      } catch (error) {
        return toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    handleChange,
    setValues,
    touched,
    errors,
    handleBlur,
  } = formik;

  // console.log("VALUES____",values)

  useEffect(() => {
    let Obj = {
      employeeGroupingIds: EmployeeEvaluationData?.employeeGroupingIds || [],
    };
    setValues(Obj);
  }, [EmployeeEvaluationData]);

  useEffect(() => {
    if (getViewVisible("dataChangeRequest")) {
      dispatch(
        getChangeRequest({
          id,
          limit,
          page: page + 1,
          request_status: "",
          employeeId: id,
          orderBy,
          sortBy,
        })
      );
    }
  }, [dispatch, limit, page, sortBy, orderBy]);

  const mapTechnologies = (technologies) => {
    if (
      !Array.isArray(technologies) ||
      !technologies ||
      technologies.length === 0
    ) {
      return "-";
    }

    const mappedTechnologies = technologies.map((tech) => {
      if (tech.technologyId && tech.technology) {
        return tech.technology.title;
      }
      return "";
    });

    if (mappedTechnologies.length === 0) {
      return "-";
    }

    return mappedTechnologies.join(", ");
  };

  const getPresentAddress = (address) => {
    if (
      (address.presentStreetLine1 || address.presentStreetLine2) &&
      // address.presentStreetLine2 &&
      address.presentArea &&
      address.presentCity &&
      address.presentState &&
      address.presentZipCode
    ) {
      return `${address.presentStreetLine1 || ""} ${
        address.presentStreetLine2 || ""
      } , ${address.presentArea} ${address.presentCity} , ${
        address.presentState
      } , ${address.presentZipCode}`;
    } else {
      return "-";
    }
  };
  const getPermanetAddress = (address) => {
    if (
      (address.presentStreetLine1 || address.presentStreetLine2) &&
      // address.permenentStreetLine2 &&
      address.permenentArea &&
      address.permenentCity &&
      address.permenentState &&
      address.permenentZipCode
    ) {
      return `${address.permenentStreetLine1 || ""} ${
        address.permenentStreetLine2 || ""
      } , ${address.permenentArea} ${address.permenentCity} , ${
        address.permenentState
      } , ${address.permenentZipCode}`;
    } else {
      return "-";
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };
  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
    const fetchById = async () => {
      try {
        let record = await dispatch(getEmployeeById(id)).unwrap();
        setEmployee(record?.data);
        setDefaultEmail(record?.data?.emails[0]?.email);
        setTotalExpTime(record?.data);
        setEmployeeLog(record?.data?.employeeapplogs);
        const tempBackLink =
          record?.data?.status === "employee"
            ? moment(record?.data?.workDetails?.joiningDate).isAfter(new Date())
              ? "employee/joining-employee"
              : "employee"
            : "employee/ex-employee";

        setBackLink(tempBackLink);
      } catch (error) {
        navigation("/employee");
        //if there is no data then it gives error msg "No records found"
        if (error.status === 404 && !error.success) {
          toast.error(error.message);
          navigation("/employee");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchById();
  }, []);

  const handlePassword = () => {
    setShowPassword(!showPassword);
  };

  // const setTotalExpTime = (data) => {
  //   setExperience({
  //     totalExpYears: 0,
  //     totalExpMonths: 0,
  //     totalCCCYears: 0,
  //     totalCCCMonths: 0,
  //   });

  //   let experienceArr = data.experience;
  //   let cccExperienceArr = data.workDetails;
  //   let totalYears = experience.totalExpYears;
  //   let totalMonths = experience.totalExpMonths;
  //   let tempCCCYears = experience.totalCCCYears;
  //   let tempCCCMonths = experience.totalCCCMonths;

  //   if (experienceArr.length > 0) {
  //     for (let i = 0; i < experienceArr.length; i++) {
  //       let start = moment(experienceArr[i].from, "YYYY-MM-01");
  //       let end = moment(
  //         experienceArr[i].to ? experienceArr[i].to : new Date(),
  //         "YYYY-MM-01"
  //       );
  //       let monthsCount = moment(end).diff(moment(start), "months");
  //       monthsCount += 1;
  //       let floored = Math.floor(monthsCount / 12);
  //       let finalValue = monthsCount - floored * 12;
  //       totalYears = totalYears + floored;
  //       totalMonths = totalMonths + finalValue;
  //       if (totalMonths >= 12) {
  //         totalMonths = totalMonths - 12;
  //         totalYears = totalYears + 1;
  //       }
  //       /* if (
  // 	  experienceArr[i].company?.name &&
  // 	  experienceArr[i].company?.name?.toLowerCase() === "cybercom creation"
  // 	) {
  // 	  tempCCCYears = tempCCCYears + floored;
  // 	  tempCCCMonths = tempCCCMonths + finalValue;
  // 	  if (tempCCCMonths > 12) {
  // 		tempCCCMonths = tempCCCMonths - 12;
  // 		tempCCCYears = tempCCCYears + 1;
  // 	  }
  // 	} */
  //     }

  //     if (
  //       moment(new Date()).diff(moment(cccExperienceArr.joiningDate), "days") >
  //       0
  //     ) {
  //       let cccstart = moment(
  //         new Date(cccExperienceArr.joiningDate),
  //         "YYYY-MM"
  //       );
  //       let cccend = moment(
  //         cccExperienceArr.relievingDate
  //           ? new Date(cccExperienceArr.relievingDate)
  //           : new Date(),
  //         "YYYY-MM"
  //       )
  //         .subtract(1, "months")
  //         .endOf("month");
  //       let cccmonthCountRejoinCase = 0;
  //       if (cccExperienceArr.reJoiningDate) {
  //         let cccMid = moment(
  //           new Date(cccExperienceArr.reJoiningDate),
  //           "YYYY-MM"
  //         )
  //           .subtract(1, "months")
  //           .endOf("month");

  //         let cccMidEnd = moment(new Date(), "YYYY-MM")
  //           .subtract(1, "months")
  //           .endOf("month");
  //         cccmonthCountRejoinCase += moment(cccMidEnd).diff(
  //           moment(cccMid),
  //           "months"
  //         );
  //       }

  //       let cccmonthsCount =
  //         moment(cccend).diff(moment(cccstart), "months") +
  //         cccmonthCountRejoinCase;
  //       cccmonthsCount += 1;
  //       let cccfloored = Math.floor(cccmonthsCount / 12);
  //       let cccfinalValue = cccmonthsCount - cccfloored * 12;

  //       tempCCCYears = tempCCCYears + cccfloored;
  //       tempCCCMonths = tempCCCMonths + cccfinalValue;
  //     }

  //     let overallYears = totalYears + tempCCCYears;
  //     let overallMonths = totalMonths + tempCCCMonths;

  //     if (overallMonths >= 12) {
  //       overallMonths = overallMonths - 12;
  //       overallYears = overallYears + 1;
  //     }

  //     setExperience({
  //       overallMonths: overallMonths,
  //       overallYears: overallYears,
  //       totalExpYears: totalYears,
  //       totalExpMonths: totalMonths,
  //       totalCCCYears: tempCCCYears,
  //       totalCCCMonths: tempCCCMonths,
  //     });
  //   } else {
  //     if (
  //       moment(new Date()).diff(moment(cccExperienceArr.joiningDate), "days") >
  //       0
  //     ) {
  //       let cccstart = moment(cccExperienceArr.joiningDate, "YYYY-MM-01");
  //       let cccend = moment(
  //         cccExperienceArr.relievingDate
  //           ? cccExperienceArr.relievingDate
  //           : new Date(),
  //         "YYYY-MM-01"
  //       );
  //       let cccmonthCountRejoinCase = 0;
  //       if (cccExperienceArr.reJoiningDate) {
  //         let cccMid = moment(
  //           new Date(cccExperienceArr.reJoiningDate),
  //           "YYYY-MM"
  //         )
  //           .subtract(1, "months")
  //           .endOf("month");

  //         let cccMidEnd = moment(new Date(), "YYYY-MM")
  //           .subtract(1, "months")
  //           .endOf("month");

  //         cccmonthCountRejoinCase += moment(cccMidEnd).diff(
  //           moment(cccMid),
  //           "months"
  //         );
  //       }

  //       let cccmonthsCount =
  //         moment(cccend).diff(moment(cccstart), "months") +
  //         cccmonthCountRejoinCase;

  //       cccmonthsCount += 1;
  //       let cccfloored = Math.floor(cccmonthsCount / 12);
  //       let cccfinalValue = cccmonthsCount - cccfloored * 12;

  //       tempCCCYears = tempCCCYears + cccfloored;
  //       tempCCCMonths = tempCCCMonths + cccfinalValue;
  //     }

  //     let overallYears = totalYears + tempCCCYears;
  //     let overallMonths = totalMonths + tempCCCMonths;

  //     if (overallMonths >= 12) {
  //       overallMonths = overallMonths - 12;
  //       overallYears = overallYears + 1;
  //     }

  //     setExperience({
  //       overallMonths: overallMonths,
  //       overallYears: overallYears,
  //       totalExpYears: totalYears,
  //       totalExpMonths: totalMonths,
  //       totalCCCYears: tempCCCYears,
  //       totalCCCMonths: tempCCCMonths,
  //     });
  //   }
  // };

  const setTotalExpTime = (data) => {
    let experience = {
      totalExpYears: 0,
      totalExpMonths: 0,
      totalCCCYears: 0,
      totalCCCMonths: 0,
    };

    let experienceArr = data?.experience;
    let cccExperienceArr = data?.workDetails;
    let totalYears = experience?.totalExpYears;
    let totalMonths = experience?.totalExpMonths;
    let tempCCCYears = experience?.totalCCCYears;
    let tempCCCMonths = experience?.totalCCCMonths;

    if (experienceArr?.length > 0) {
      for (let i = 0; i < experienceArr.length; i++) {
        let start = moment(experienceArr[i].from);
        let end = moment(
          experienceArr[i].to ? experienceArr[i].to : new Date()
        );
        let cccstart = start;
        let cccend = end;

        let floored = cccend.diff(cccstart, "years");
        cccstart.add(floored, "years");

        let finalValue = cccend.diff(cccstart, "months");
        cccstart.add(finalValue, "months");

        let days = cccend.diff(cccstart, "days");
        let dateFrom = moment(experienceArr[i].from);
        let dateTo = moment(experienceArr[i].to);

        let dateProducation = moment(cccExperienceArr?.productionDate);
        let dateReliving = moment(cccExperienceArr?.relievingDate);

        if (experienceArr[i]?.company?.name === "Cybercom Creation") {
          if (
            dateFrom.isSame(dateProducation, "month") &&
            dateFrom.isSame(dateProducation, "year") &&
            dateTo.isSame(dateReliving, "month") &&
            dateTo.isSame(dateReliving, "year")
          ) {
            tempCCCYears = tempCCCYears;
            tempCCCMonths = tempCCCMonths;
          } else {
            tempCCCYears = tempCCCYears + floored;
            tempCCCMonths = tempCCCMonths + finalValue;
          }

          if (tempCCCMonths >= 12) {
            tempCCCMonths = tempCCCMonths - 12;
            tempCCCYears = tempCCCYears + 1;
          }
        } else {
          totalYears = totalYears + floored;
          totalMonths = totalMonths + finalValue;
          if (totalMonths >= 12) {
            totalMonths = totalMonths - 12;
            totalYears = totalYears + 1;
          }
        }
      }

      // console.log("tempCCCYears", tempCCCYears, "tempCCCMonths", tempCCCMonths);
      // console.log("totalYears", totalYears, "totalMonths", totalMonths);

      if (
        moment(new Date()).diff(
          moment(cccExperienceArr?.productionDate),
          "days"
        ) > 0
      ) {
        let cccstart = moment(new Date(cccExperienceArr?.productionDate));
        let cccend = moment(
          cccExperienceArr?.relievingDate
            ? new Date(cccExperienceArr?.relievingDate)
            : new Date()
        );

        let cccyearCount = cccend.diff(cccstart, "years");
        cccstart.add(cccyearCount, "years");

        let cccmonthsCount = cccend.diff(cccstart, "months");
        cccstart.add(cccmonthsCount, "months");

        tempCCCYears = tempCCCYears + cccyearCount;
        tempCCCMonths = tempCCCMonths + cccmonthsCount;
      }

      let overallYears = totalYears + tempCCCYears;
      let overallMonths = totalMonths + tempCCCMonths;

      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      if (totalMonths >= 12) {
        totalMonths = totalMonths - 12;
        totalYears = totalYears + 1;
      }

      if (tempCCCMonths >= 12) {
        tempCCCMonths = tempCCCMonths - 12;
        tempCCCYears = tempCCCYears + 1;
      }

      experience = {
        ...experience,
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      };

      setExperience({
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      });
    } else {
      if (
        moment(new Date()).diff(
          moment(cccExperienceArr?.productionDate),
          "days"
        ) > 0
      ) {
        let cccstart = moment(cccExperienceArr?.productionDate);
        let cccend = moment(
          cccExperienceArr?.relievingDate
            ? cccExperienceArr?.relievingDate
            : new Date()
        );

        let cccyearCount = cccend.diff(cccstart, "years");
        cccstart.add(cccyearCount, "years");

        let cccmonthsCount = cccend.diff(cccstart, "months");
        cccstart.add(cccmonthsCount, "months");

        tempCCCYears = tempCCCYears + cccyearCount;
        tempCCCMonths = tempCCCMonths + cccmonthsCount;
      }

      let overallYears = totalYears + tempCCCYears;
      let overallMonths = totalMonths + tempCCCMonths;

      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      if (totalMonths >= 12) {
        totalMonths = totalMonths - 12;
        totalYears = totalYears + 1;
      }

      if (tempCCCMonths >= 12) {
        tempCCCMonths = tempCCCMonths - 12;
        tempCCCYears = tempCCCYears + 1;
      }

      experience = {
        ...experience,
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      };

      setExperience({
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      });
    }
    let exp_str = "";
    if (experience?.totalCCCYears && experience?.totalCCCMonths) {
      exp_str =
        exp_str +
        `${experience?.totalCCCYears}.${experience?.totalCCCMonths} Yrs `;
    }
    if (!experience?.totalCCCYears && experience?.totalCCCMonths) {
      exp_str = exp_str + `0.${experience?.totalCCCMonths} Yrs `;
    }
    if (experience?.totalCCCYears && !experience?.totalCCCMonths) {
      exp_str = exp_str + `${experience?.totalCCCYears} Yrs `;
    }
    if (!experience?.totalCCCYears && !experience?.totalCCCMonths) {
      exp_str = exp_str + `0.0 Yrs `;
    }
    exp_str = exp_str + '<div class="total-experience">';
    exp_str = exp_str + " ";
    if (experience?.overallYears && experience?.overallMonths) {
      exp_str =
        exp_str +
        `${experience?.overallYears}.${experience?.overallMonths} Yrs`;
    }
    if (!experience?.overallYears && experience?.overallMonths) {
      exp_str = exp_str + `0.${experience?.overallMonths} Yrs`;
    }
    if (experience?.overallYears && !experience?.overallMonths) {
      exp_str = exp_str + `${experience?.overallYears} Yrs`;
    }
    if (!experience.overallYears && !experience.overallMonths) {
      exp_str = exp_str + `0.0 Yrs`;
    }

    exp_str = exp_str + " ";
    exp_str = exp_str + "</div>";

    // return exp_str
    return <div dangerouslySetInnerHTML={{ __html: exp_str }} />;
  };

  const handleTotalExperience = (from, to) => {
    let start = moment(from);
    let end = moment(to ? to : new Date());

    let floored = end.diff(start, "years");
    start.add(floored, "years");

    let finalValue = end.diff(start, "months");
    start.add(finalValue, "months");

    totalYears = totalYears + floored;
    totalMonths = totalMonths + finalValue;
    if (totalMonths >= 12) {
      totalMonths = totalMonths - 12;
      totalYears = totalYears + 1;
    }
    if (floored === 0 && finalValue === 0) {
      return "No experience";
    }
    if (floored > 0 && finalValue === 0) {
      return `${floored} Yrs`;
    }
    if (floored === 0 && finalValue > 0) {
      return `0.${finalValue} Yrs`;
    }
    return `${floored}.${finalValue} Yrs`;
  };

  const renderInstituteName = (education) => {
    if (education?.instituteName) {
      return education?.instituteName;
    }
    if (education?.institute && education?.instituteId) {
      return education?.institute?.collegeName;
    }
    return "-";
  };

  const getDocumentImage = (type) => {
    if (type) {
      if (type.includes("image")) {
        return "/assets/images/image-icon-rounded.png";
      } else if (type.includes("pdf")) {
        return "/assets/images/pdf-icon.png";
      } else if (type.includes("msword")) {
        return "/assets/images/word-doc-icon.png";
      } else {
        return "/assets/images/pdf-icon.png";
      }
    } else {
      return "/assets/images/pdf-icon.png";
    }
  };

  const handleEmailDropDown = () => {
    if (mailDropDownClasses.includes("mail-drop-close")) {
      setMailDropDownClasses(["mail-dropdown-list", "mail-drop-open"]);
    }
    if (mailDropDownClasses.includes("mail-drop-open")) {
      setMailDropDownClasses(["mail-dropdown-list", "mail-drop-close"]);
    }
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
      /* console.log("Copying text command was " + msg); */
    } catch (err) {
      toast.error("Text Not Copied!");
      /* console.log("Oops, unable to copy"); */
    }

    document.body.removeChild(textArea);
  };

  const handleTabChange = (v) => {
    setTabValue(v);
  };

  const handleImageChange = async (e, id) => {
    if (e.target.files && e.target.files[0]) {
      var FR = new FileReader();
      FR.onload = async function (e) {
        const res = await NiceModal.show(ImageCropModal, {
          image: e.target.result?.toString() || "",
        });

        const formData = new FormData();
        formData.set(
          "employeeImage",
          new File([res.image], "newImage", {
            lastModified: new Date().getTime(),
            type: res.image.type,
          })
        );
        let resAPI = await dispatch(
          updateEmployeeProfile({ values: formData, id })
        ).unwrap();

        if (resAPI.status === 200) {
          let record = await dispatch(getEmployeeById(id)).unwrap();
          setEmployee(record.data);
          setTotalExpTime(record.data);
        }
      };
      FR.readAsDataURL(e.target.files[0]);
      e.target.value = "";
    }

    // if (e.target.files && e.target.files.length > 0) {
    // 	const reader = new FileReader();
    // 	reader.addEventListener("load", async () => {
    // 		const res = await NiceModal.show(ImageCropModal, {
    // 			image: reader.result?.toString() || "",
    // 		});
    // 		setSelectedFileUrl(
    // 			window.URL.createObjectURL(

    // 			),
    // 		);
    // 		setFieldValue(
    // 			`employeeImage`,
    // 			new File([res.image], "newImage", {
    // 				lastModified: new Date().getTime(),
    // 				type: res.image.type,
    // 			}),
    // 		);
    // 	});
    // 	reader.readAsDataURL(e.target.files[0]);
    // }
  };

  function handleChangeObj(event, obj) {
    setDefaultEmail(obj.props.value);
  }

  const sendPassword = async (employeeData) => {
    let EmployeeEmail;

    EmployeeEmail = employeeData.emails.filter((email) => {
      if (email.type === "Gmail") {
        return email.email;
      }
    });

    if (EmployeeEmail.length > 0) {
      EmployeeEmail = EmployeeEmail[0].email;
    } else {
      EmployeeEmail = employeeData.emails[0].email;
    }

    const res = await dispatch(
      sendPasswordMail({
        values: {
          firstName: employeeData.firstName,
          lastName: employeeData.lastName,
          email: EmployeeEmail,
          username: employeeData.employeeId,
        },
        id: employeeData.id,
      })
    );

    if (res) {
      setemailLoading(false);
    }
  };

  const handleCheckbox = async (checklist) => {
    try {
      let body = { status: "Complete", notes: note };
      let resAPI = await dispatch(
        updateTaskStatus({
          id: checklist?.id,
          ...body,
        })
      ).unwrap();

      if (resAPI.status === 200) {
        setNote("");
        let record = await dispatch(getEmployeeById(id)).unwrap();
        setEmployee(record?.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  function stringAvatar(name) {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: name
        ? `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`
        : "",
    };
  }

  if (loading) {
    return <Loader />;
  }

  const handleShowRetentionDetail = () => {
    setTooltipOpen(!tooltipOpen);
  };

  let agreementText = '';

  if (!employee.serviceAgreement.isCheque && !employee.serviceAgreement.isRetention) {
      agreementText = '-';
  } else {
      let parts = [];
      if (employee.serviceAgreement.isCheque) {
          parts.push('Cheque');
      }
      if (employee.serviceAgreement.isRetention) {
          parts.push('Retention');
      }
      agreementText = parts.join(', ');
  }

  const middleName=employee?.middleName

  return (
    <div className="view_employee">
      <Container maxWidth="xl" className="container">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          className="headerTop view_employee-header"
        >
          <Typography variant="h4" gutterBottom>
            {id ? `Employee Profile` : "Add Employee"}
          </Typography>

          <div className="view-header-right employee-header-right">
            <Button
              variant="contained"
              /* component={RouterLink}
              to="/employee" */
              component={RouterLink}
              to={`/${backlink}`}
              // onClick={()=>navigate(-1)}
              className="backtolist back-button"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
            {/* <a href="#" className="index_link">
              Employee Index
            </a> */}

            {getEditVisible("employee") ? (
              <>
                <Button
                  className="border-button"
                  variant="contained"
                  component={RouterLink}
                  // to={`/employee/add/${encryption(id)}`}
                  to={`/employeeEvaluation`}
                >
                  View Evalution
                </Button>
                {employee.status !== "ex-employee" ? (
                  <LoadingButton
                    className="border-button"
                    onClick={() => {
                      setemailLoading(true);
                      sendPassword(employee);
                    }}
                    loading={emailloading}
                    // startIcon={<MailIcon />}
                    loadingPosition="start"
                    variant="contained"
                  >
                    <img src="/assets/images/mail-border-icon.svg" />
                    Send password email
                  </LoadingButton>
                ) : (
                  ""
                )}
                <Button
                  className="orange-bg-button"
                  variant="contained"
                  component={RouterLink}
                  to={`/employee/add/${encryption(id)}`}
                >
                  <img src="/assets/images/edit-white-icon.svg" />
                  Edit
                </Button>
              </>
            ) : (
              ""
            )}
          </div>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            className="grid_container General-grid_container"
          >
            {/* /left personal details */}
            <Grid className="General-information-grid" item xs={4}>
              <Card className="information-card card_height">
                <Container className="personal-details-section" maxWidth="xl">
                  <div className="personal-image-section">
                    <div className="profile-image-part">
                      <div className="circle_blk">
                        <div className="circle">
                          <Avatar
                            src={`${BASE_URL}/${employee.employeeImage}`}
                            alt={employee.firstName}
                            sx={{
                              width: "140px",
                              height: "140px",
                              fontSize: "2.5rem",
                            }}
                          >
                            {" "}
                          </Avatar>
                        </div>
                        <div className="image">
                          <i
                            className="upload-button"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              fileInput.current.click();
                            }}
                          >
                            <img
                              src="/assets/images/vedit.svg"
                              className="vedit"
                            ></img>
                          </i>
                          <input
                            ref={fileInput}
                            id="profileImage"
                            className="file-upload"
                            type="file"
                            accept="image/*"
                            onChange={(e) => handleImageChange(e, employee?.id)}
                          />
                        </div>
                      </div>
                    </div>
                    <Typography
                      className="profile-image-heading"
                      variant="h5"
                      align="center"
                      mb={1}
                      mt={2}
                    >
                     {`${employee?.firstName} ${middleName?.trim()!=null  && middleName?.trim()!='' ? `${middleName?.charAt(0)}.` : ''} ${employee?.lastName}`}
                    </Typography>
                    <p className="job-position-txt job-position-txt-top">
                      {employee?.workDetails?.designation?.title
                        ? employee?.workDetails?.designation?.title
                        : "-"}
                    </p>
                    <p className="job-position-txt">
                      {" "}
                      Employee ID: {employee?.employeeId || "-"}
                    </p>
                    {/* <p className="job-position-txt">
                      {" "}
                      Role: {employee?.role?.role || "-"}
                    </p> */}
                    <Stack
                      className="social-image-icon"
                      direction="row"
                      justifyContent={"center"}
                    >
                      {employee?.contact?.companySkype ? (
                        <a
                          href={`skype:${employee?.contact?.companySkype}?chat`}
                        >
                          <Box
                            component="img"
                            src="/assets/images/skype-gray.svg"
                            className="skype"
                          />
                        </a>
                      ) : (
                        ""
                      )}

                      {employee?.contact?.linkedIn ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            employee?.contact?.linkedIn
                              ? employee?.contact?.linkedIn
                              : ""
                          }
                        >
                          <Box
                            component="img"
                            src="/assets/images/linkedin-gray.svg"
                            className="linkdin"
                          />
                        </a>
                      ) : (
                        ""
                      )}

                      {employee?.github ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={employee?.github ? employee?.github : ""}
                        >
                          <Box
                            component="img"
                            src="/assets/images/github-gray.svg"
                            className="github"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {employee?.portfolioWebsite ? (
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={
                            employee?.portfolioWebsite
                              ? employee?.portfolioWebsite
                              : ""
                          }
                        >
                          <Box
                            component="img"
                            src="/assets/images/portfolio-gray.svg"
                            className="portfolio"
                          />
                        </a>
                      ) : (
                        ""
                      )}
                      {employee?.documents?.employeeResume &&
                        employee?.documents?.employeeResume?.length > 0 && (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={
                              employee?.documents?.employeeResume &&
                              employee?.documents?.employeeResume?.length > 0 &&
                              employee?.documents?.employeeResume[0].webViewLink
                            }
                          >
                            <Box
                              component="img"
                              src="/assets/images/vdownload.svg"
                              sx={{ width: "20px", marginLeft: "4px" }}
                            />
                          </a>
                        )}
                    </Stack>
                    {/* <div className="phone-blk">
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Password
                        </Typography>
                      </div>
                      <div>
                        <div className="phone-link" style={{ fontSize: "13px" }}>
                        {employee?.password ? (
                          !showPassword ? (
                            <p>
                             {'******'} &nbsp; <VisibilityIcon onClick={handlePassword} />
                            </p>
                          ) :
                          (employee?.password
                            ?
                              (
                                <p>
                                {decryption(employee?.password)}  &nbsp; <VisibilityOffIcon onClick={handlePassword} />
                                </p>
                              )
                            : "")
                        ) : ("")
                      }
                      {employee?.password ?
                      <img
                        src="/assets/images/vfile.svg"
                        onClick={() => {
                          ClicktoCopy(decryption(employee?.password));
                        }}
                        style={{ cursor: "pointer" }}
                      ></img> : "-"}
                        </div>
                      </div>
                    </div> */}
                    <div className="phone-blk">
                      <div className="phone-blk-label">
                        <Typography variant="caption" className="detail-label">
                          Phone
                        </Typography>
                      </div>
                      <div>
                        <div className="phone-link">
                          {employee?.contact?.contactNumber ? (
                            <>
                              <Link
                                href={`tel:(+91) ${employee?.contact?.contactNumber}`}
                                className="profile-specific-info"
                              >
                                +91 {employee?.contact?.contactNumber}
                              </Link>

                              <img
                                src="/assets/images/vfile.svg"
                                onClick={() => {
                                  ClicktoCopy(
                                    "+91 " + employee?.contact?.contactNumber
                                  );
                                  /* navigator.clipboard.writeText(
																		"+91" + employee?.contact?.contactNumber,
																	); */
                                }}
                                style={{ cursor: "pointer" }}
                              ></img>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="phone-blk">
                      <div className="phone-blk-label">
                        <Typography variant="caption" className="detail-label">
                          Alt. No
                        </Typography>
                      </div>
                      <div>
                        <div className="phone-link">
                          {employee?.contact?.alternateContactNumber ? (
                            <>
                              <Link
                                href={`tel:(+91) ${employee?.contact?.alternateContactNumber}`}
                                className="profile-specific-info"
                              >
                                +91 {employee?.contact?.alternateContactNumber}
                              </Link>
                              <img
                                src="/assets/images/vfile.svg"
                                onClick={() => {
                                  ClicktoCopy(
                                    "+91 " +
                                      employee?.contact?.alternateContactNumber
                                  );
                                  /* navigator.clipboard.writeText(
																		"+91" +
																			employee?.contact?.alternateContactNumber,
																	); */
                                }}
                                style={{ cursor: "pointer" }}
                              ></img>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="phone-blk">
                      {employee?.contact?.emergencyContactNumber ? (
                        <>
                          <div className="phone-blk-label">
                            <Typography
                              variant="caption"
                              className="detail-label"
                            >
                              Eme. No.
                            </Typography>
                          </div>
                          <div>
                            <div className="phone-link">
                              <Link
                                href={`tel:(+91) ${employee?.contact?.emergencyContactNumber}`}
                                className="profile-specific-info"
                              >
                                +91 {employee?.contact?.emergencyContactNumber}
                              </Link>
                              <img
                                src="/assets/images/vfile.svg"
                                onClick={() => {
                                  ClicktoCopy(
                                    "+91 " +
                                      employee?.contact?.emergencyContactNumber
                                  );
                                  /* navigator.clipboard.writeText(
																		"+91" +
																			employee?.contact?.emergencyContactNumber,
																	); */
                                }}
                                style={{ cursor: "pointer" }}
                              ></img>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                    {/* <div className="phone-blk">
                      {employee?.github ? (
                        <>
                          <div>
                            <Typography
                              variant="caption"
                              className="detail-label"
                            >
                              GitHub Profile
                            </Typography>
                          </div>
                          <div>
                            <div className="phone-link">
                              {employee?.github != "null" &&
                              employee.github != null
                                ? employee?.github
                                : "-"}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div> */}
                    {/* <div className="phone-blk">
                      {employee?.portfolioWebsite ? (
                        <>
                          <div>
                            <Typography
                              variant="caption"
                              className="detail-label"
                            >
                              Portfolio Website
                            </Typography>
                          </div>
                          <div>
                            <div className="phone-link">
                              <a
                                href={employee?.portfolioWebsite}
                                className="profile-specific-info"
                              >
                                {employee?.portfolioWebsite != "null" &&
                                employee.portfolioWebsite != null
                                  ? employee?.portfolioWebsite
                                  : "-"}
                              </a>
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div> */}

                    <div className="phone-blk">
                      <div className="phone-blk-label">
                        <Typography variant="caption" className="detail-label">
                          Email
                        </Typography>
                      </div>
                      {/* {<div>
												<Button
													id="fade-button"
													aria-controls={open ? "fade-menu" : undefined}
													aria-haspopup="true"
													aria-expanded={open ? "true" : undefined}
													onClick={handleClick}
													endIcon={<KeyboardArrowDownIcon />}
												>
													{employee?.emails[0].email}
												</Button>
												<StyledMenu
													id="demo-customized-menu"
													MenuListProps={{
														"aria-labelledby": "demo-customized-button",
													}}
													anchorEl={anchorEl}
													open={open}
													onClose={handleClose}
												>
													{employee?.emails.map((email, index) => {
														return (
															<MenuItem
																key={email.id}
																value={email.email}
																className="menu-item-blk"
																sx={{ display: "flex" }}
																onClick={() => {
																	ClicktoCopy(value);
																	// navigator.clipboard.writeText(
																	// 	employee?.emails[0].email,
																	// );
																}}
															>
																<Stack
																	direction="row"
																	alignItems="center"
																	spacing={2}
																>
																	{email.email}
																	{<img
																		src="/assets/images/vfile.svg"
																		onClick={() => {
																			ClicktoCopy(`${email.email}`);
																			// navigator.clipboard.writeText(
																			// 	employee?.emails[0].email,
																			// );
																		}}
																		style={{
																			cursor: "pointer",
																			marginLeft: "10px",
																		}}
																	></img>}
																</Stack>
															</MenuItem>
														);
													})}
													<MenuItem onClick={handleClose} disableRipple>
														<EditIcon />
														Edit
													</MenuItem>
													<MenuItem onClick={handleClose} disableRipple>
														<FileCopyIcon />
														Duplicate
													</MenuItem>
													<Divider sx={{ my: 0.5 }} />
													<MenuItem onClick={handleClose} disableRipple>
														<ArchiveIcon />
														Archive
													</MenuItem>
													<MenuItem onClick={handleClose} disableRipple>
														<MoreHorizIcon />
														More
													</MenuItem>
												</StyledMenu>
											</div>} */}
                      {/* {setDefaultEmail(employee?.emails[0].email)} */}
                      <Select
                        labelId="demo-select-small"
                        style={{ width: "100%" }}
                        id="demo-select-small"
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#dce0e4",
                            border: "1px solid #dce0e4",
                            display: "flex",
                          },
                        }}
                        value={defaultEmail}
                        onChange={handleChangeObj}
                        MenuProps={MenuProps}
                      >
                        {employee?.emails.map((email, index) => {
                          return (
                            <MenuItem
                              key={email.id}
                              value={email.email}
                              className="menu-item-blk"
                              sx={{ display: "flex" }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                {email.email}
                              </Stack>
                            </MenuItem>
                          );
                        })}
                      </Select>
                      <img
                        src="/assets/images/vfile.svg"
                        onClick={() => {
                          ClicktoCopy(defaultEmail);
                        }}
                        style={{
                          cursor: "pointer",
                          marginLeft: "10px",
                        }}
                      ></img>

                      {/* {Array.isArray(employee?.emails) &&
											employee?.emails.length > 0 ? (
												<div className="mail-dropdown-div">
													<div
														className="mail-display-block"
														style={{ marginBottom: "0" }}
													>
														<span>
															{employee?.emails
																? employee?.emails[0].email
																: ""}
															<img
																src="/assets/images/vfile.svg"
																onClick={() => {
																	ClicktoCopy(employee?.emails[0].email);
																	// navigator.clipboard.writeText(
																	// 	employee?.emails[0].email,
																	// );
																}}
																style={{ cursor: "pointer" }}
															></img>
														</span>
														{employee?.emails?.length > 1 && (
															<p
																class="email-down-arrow"
																onClick={handleEmailDropDown}
															>
																{">"}
															</p>
														)}
													</div>

													<div className={mailDropDownClasses.join(" ")}>
														{employee?.emails?.length > 1 &&
															employee?.emails?.map((email, index) => (
																<div
																	key={index}
																	className="mail-dropdown-list-item"
																	onClick={() => {
																		ClicktoCopy(email?.email);
																		// navigator.clipboard.writeText(email?.email);
																	}}
																>
																	<p>
																		{email?.type} : {email?.email}
																	</p>
																	<div>
																		<img
																			src="/assets/images/vfile.svg"
																			alt="copy"
																			st
																		/>
																	</div>
																</div>
															))}
													</div>
												</div>
											) : (
												"-"
											)} */}
                    </div>
                    <div className="phone-blk" style={{ marginTop: "10px" }}>
                      <div className="phone-blk-label">
                        <Typography variant="caption" className="detail-label">
                          Work Location
                        </Typography>
                      </div>
                      <div style={{ fontSize: "13px" }}>
                        {employee?.workLocation ? employee?.workLocation : "-"}
                      </div>
                    </div>
                    {employee && employee.seatNumber && (
                      <div className="phone-blk" style={{ marginTop: "10px" }}>
                        <div className="phone-blk-label">
                          <Typography
                            variant="caption"
                            className="detail-label"
                          >
                            Seat Number
                          </Typography>
                        </div>
                        <div style={{ fontSize: "13px" }}>
                          {employee?.seatNumber ? employee?.seatNumber : "-"}
                        </div>
                      </div>
                    )}
                  </div>
                </Container>
              </Card>
            </Grid>
            <Grid className="General-information-grid" item xs={8}>
              <Card className="address-card information-card ">
                <Typography variant="h6" className="information-card-heading">
                  Work Details
                </Typography>
                <div className="address-blk">
                  <div className="address-sub-section">
                    <div className="address-div">
                      {" "}
                      <span>DOJ</span>
                      <p>
                        {employee?.workDetails?.joiningDate
                          ? indianDateFormat(employee?.workDetails?.joiningDate)
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Production Date</span>
                      <p>
                        {employee?.workDetails?.productionDate
                          ? indianDateFormat(
                              employee?.workDetails?.productionDate
                            )
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Commitment Date</span>
                      <p>
                        {employee?.workDetails?.commitmentStartDate
                          ? indianDateFormat(
                              employee?.workDetails?.commitmentStartDate
                            )
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Bond Duration</span>
                      <p>
                        {employee?.workDetails?.bondDuration
                          ? employee?.workDetails?.bondDuration
                          : "-"}
                      </p>
                    </div>

                    {employee?.workDetails?.resignationAcceptanceDate ? (
                      <div className="address-div">
                        <span>Resignation Acceptance</span>
                        <p>
                          {employee?.workDetails?.resignationAcceptanceDate
                            ? indianDateFormat(
                                employee?.workDetails?.resignationAcceptanceDate
                              )
                            : "-"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    {employee?.workDetails?.relievingDate ? (
                      <div className="address-div">
                        <span>Relieving Date</span>
                        <p>
                          {employee?.workDetails?.relievingDate
                            ? indianDateFormat(
                                employee?.workDetails?.relievingDate
                              )
                            : "-"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}

                    {employee?.workDetails?.reJoiningDate ? (
                      <div className="address-div">
                        <span>Rejoining Date</span>
                        <p>
                          {employee?.workDetails?.reJoiningDate
                            ? indianDateFormat(
                                employee?.workDetails?.reJoiningDate
                              )
                            : "-"}
                        </p>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="address-div">
                      <span>Appraisal Cycle</span>{" "}
                      <p>
                        {employee?.workDetails?.appraisalCycle
                          ? employee?.workDetails?.appraisalCycle
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Service Agreement</span>{" "}
                      <p>
                      {agreementText}
                      {AuthRole === 'Super Admin' && (
                      employee.serviceAgreement.isRetention && <LightTooltip title={toolTipData ? `Unpaid Amount: ${toolTipData}` : "No Due"}
                                  placement="top"
                                  open={tooltipOpen}
                                  onClose={() => setTooltipOpen(false)}>
                      <RemoveRedEyeIcon style={{ fontSize: 18, marginLeft: '5px' }} onClick={handleShowRetentionDetail} />
                    </LightTooltip>
                    )}
                      </p>
                    </div>
                  </div>
                  <div className="address-sub-section">
                    <div className="address-div">
                      <span>Designation</span>
                      <p>
                        {employee?.workDetails?.designation?.title
                          ? employee?.workDetails?.designation?.title
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>PM</span>
                      <p>{employee?.projectManagerName || "-"}</p>
                    </div>
                    <div className="address-div">
                      <span>Lead</span>
                      <p>{employee?.teamLeadName || "-"}</p>
                    </div>
                    <div className="address-div">
                      <span>Techonology</span>
                      <p>
                        {employee?.workDetails?.technologies
                          ? mapTechnologies(employee?.workDetails?.technologies)
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>CCC Exp.</span>
                      <p>
                        {" "}
                        {experience.totalCCCYears && experience.totalCCCMonths
                          ? `${experience.totalCCCYears}.${experience.totalCCCMonths} Yrs `
                          : ""}
                        {!experience.totalCCCYears && experience.totalCCCMonths
                          ? `0.${experience.totalCCCMonths} Yrs `
                          : ""}
                        {experience.totalCCCYears && !experience.totalCCCMonths
                          ? `${experience.totalCCCYears} Yrs `
                          : " "}
                        {!experience.totalCCCYears && !experience.totalCCCMonths
                          ? `0.0 Yrs `
                          : ""}
                        (Total:{" "}
                        {experience.overallYears && experience.overallMonths
                          ? `${experience.overallYears}.${experience.overallMonths} Yrs`
                          : ""}
                        {!experience.overallYears && experience.overallMonths
                          ? `0.${experience.overallMonths} Yrs`
                          : ""}
                        {experience.overallYears && !experience.overallMonths
                          ? `${experience.overallYears} Yrs`
                          : ""}
                        {!experience.overallYears && !experience.overallMonths
                          ? `0.0 Yrs`
                          : ""}
                        )
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
              <Card className="address-card information-card information-card-blk ">
                <Typography variant="h6" className="information-card-heading">
                  Bank Details
                </Typography>
                <div className="address-blk">
                  <div className="address-sub-section">
                    <div className="address-div">
                      {" "}
                      <span>Bank Name</span>
                      <p>
                        {employee?.documents?.bankName || "UNION BANK OF INDIA"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>AC No.</span>
                      <p>
                        {employee?.documents?.bankAccountNumber ? (
                          <>
                            {employee?.documents?.bankAccountNumber}

                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(
                                  employee?.documents?.bankAccountNumber
                                );
                                /* 	navigator.clipboard.writeText(
																	employee?.documents?.bankAccountNumber,
																); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Branch</span>
                      <p>
                        {employee?.documents?.bankBranch ? (
                          <>{employee?.documents?.bankBranch}</>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>IFSC Code</span>
                      <p>
                        {employee?.documents?.IFSCCode ? (
                          <>
                            {" "}
                            {employee?.documents?.IFSCCode}
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(employee?.documents?.IFSCCode);
                                /* navigator.clipboard.writeText(
																	employee?.documents?.IFSCCode,
																); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </>
                        ) : (
                          "-"
                        )}{" "}
                      </p>
                    </div>
                  </div>

                  <div className="address-sub-section">
                    <div className="address-div">
                      <span>Aadhar No.</span>
                      <p>
                        {employee?.documents?.aadharCardNumber ? (
                          <>
                            {employee?.documents?.aadharCardNumber}
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(
                                  employee?.documents?.aadharCardNumber
                                );

                                /* navigator.clipboard.writeText(
																	employee?.documents?.aadharCardNumber,
																); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </>
                        ) : (
                          "-"
                        )}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>PAN No.</span>
                      <p>
                        {employee?.documents?.panCardNumber ? (
                          <>
                            {" "}
                            {employee?.documents?.panCardNumber}{" "}
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(employee?.documents?.panCardNumber);
                                /* navigator.clipboard.writeText(
																	employee?.documents?.aadharCardNumber,
																); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </>
                        ) : (
                          "-"
                        )}{" "}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>UAN Number</span>
                      <p>
                        {employee?.pf?.UAN ? (
                          <>
                            {" "}
                            {employee?.pf?.UAN}
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(employee?.pf?.UAN);
                                /* navigator.clipboard.writeText(
																	employee?.documents?.aadharCardNumber,
																); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </>
                        ) : (
                          "-"
                        )}{" "}
                        {/* <img src="/assets/images/vfile.svg"></img> */}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>ESIC</span>
                      <p>
                        {employee?.pf?.esicNumber ? (
                          <>
                            {" "}
                            {employee?.pf?.esicNumber}
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(employee?.pf?.esicNumber);
                                /* navigator.clipboard.writeText(
																	employee?.documents?.aadharCardNumber,
																); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </>
                        ) : (
                          "-"
                        )}{" "}
                        {/* <img src="/assets/images/vfile.svg"></img> */}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Grid
            container
            spacing={2}
            className="grid_container General-grid_container"
          >
            {/* /left personal details */}
            <Grid className="General-information-grid" item xs={4}>
              <Card
                className="address-card information-card"
                style={{ height: "100%" }}
              >
                <Typography variant="h6" className="information-card-heading">
                  General Information
                </Typography>
                <div className="address-blk ">
                  <div className="address-sub-section general_sec">
                    <div className="address-div">
                      {" "}
                      <span>Date Of Birth</span>
                      <p>
                        {employee?.dateOfBirth
                          ? indianDateFormat(employee?.dateOfBirth)
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Maritial Status </span>
                      <p>
                        {employee?.maritalStatus
                          ? employee?.maritalStatus
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>B. Group</span>
                      <p>{employee?.bloodGroup ? employee?.bloodGroup : "-"}</p>
                    </div>
                    <div className="address-div">
                      <span>Present Address</span>
                      <p>
                        {employee?.address
                          ? getPresentAddress(employee?.address)
                          : "-"}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Permanent Address</span>{" "}
                      <p>
                        {employee?.address
                          ? getPermanetAddress(employee?.address)
                          : "-"}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
            <Grid className="General-information-grid" item xs={8}>
              <Box>
                <TabContext value={tabValue}>
                  <Card className="left-heading-part">
                    <div className="tab_list">
                      <TabList
                        sx={{
                          borderColor: "divider",
                        }}
                        // onChange={handleChange}
                        orientation="horizontal"
                        className="tab-list-part"
                        onChange={(e, v) => handleTabChange(v)}
                      >
                        <Tab
                          label={`Experience (${experience.overallYears}.${experience.overallMonths} Yrs)`}
                          value="1"
                          direction="row"
                          style={{ width: "20%" }}
                        />
                        <Tab
                          label="Education"
                          value="2"
                          direction="row"
                          style={{ width: "18%" }}
                        />
                        <Tab
                          label="Documents"
                          value="3"
                          direction="row"
                          style={{ width: "18%" }}
                        />
                        <Tab
                          label="Exit Checklist"
                          value="4"
                          direction="row"
                          style={{ width: "20%" }}
                        />

                        <Tab
                          label="Vehicle Details"
                          value="6"
                          direction="row"
                          style={{ width: "20%" }}
                        />
                        {employeeExitInterviewData?.userExitForm?.length > 0 ? (
                          <Tab
                            label="Exit Form"
                            value="5"
                            direction="row"
                            style={{ width: "20%" }}
                            onClick={() => handleOpen()}
                          />
                        ) : (
                          ""
                        )}
                      </TabList>
                    </div>

                    <TabPanel value="1">
                      {employee?.experience.length > 0 ? (
                        <div>
                          {Array.isArray(employee?.experience) &&
                            employee?.experience
                              ?.sort(
                                (a, b) => new Date(b?.to) - new Date(a?.to)
                              )
                              ?.map((exp, idx) => (
                                // employee?.experience.map((exp, index) => (
                                <div className="tab_inner">
                                  <div className="tab_item">
                                    <span>{exp?.designation ?? "-"}</span>
                                    <p>
                                      {exp?.company ? exp?.company?.name : "-"}
                                    </p>
                                  </div>
                                  <div className="tab_item tab_flex">
                                    <Typography variant="h6">
                                      {monthYearFormat(exp?.from)} -{" "}
                                      {monthYearFormat(exp?.to)}
                                    </Typography>
                                    <span>
                                      {handleTotalExperience(
                                        exp?.from,
                                        exp?.to
                                      )}
                                    </span>
                                  </div>
                                </div>
                              ))}
                        </div>
                      ) : (
                        "No Experience"
                      )}
                    </TabPanel>

                    <TabPanel value="2">
                      <div>
                        {Array.isArray(employee?.education) &&
                        employee?.education?.length === 0
                          ? "No Education Found"
                          : employee?.education
                              ?.sort((a, b) => b?.passingYear - a?.passingYear)
                              ?.map((education, idx) => (
                                <div className="tab_inner">
                                  <div className="tab_item">
                                    <span>{getEducationCourse(education)}</span>
                                    <p>{renderInstituteName(education)}</p>
                                  </div>
                                  <div className="tab_item tab_flex">
                                    <Typography variant="h6">
                                      {education?.percentage} %
                                    </Typography>
                                    <span>{education?.passingYear}</span>
                                  </div>
                                </div>
                              ))}
                      </div>
                    </TabPanel>
                    <TabPanel value="3">
                      <div>
                        {employee?.documents?.aadharCardImage &&
                          employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner tab_Card_inner">
                              <div className="tab_item">
                                <span>Aadhar Card</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.documents?.aadharCardImage[0]
                                      .webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.documents?.aadharCardImage[0]
                                        .mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )}

                        {employee?.documents?.panCardImage &&
                          employee?.documents?.panCardImage.length > 0 && (
                            <div className="tab_inner tab_Card_inner">
                              <div className="tab_item">
                                <span>Pan Card</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.documents?.panCardImage[0]
                                      .webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.documents?.panCardImage[0]
                                        .mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )}

                        {employee?.checklists?.residentialAddress &&
                          employee?.checklists?.residentialAddress?.present
                            ?.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Present Address</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.checklists?.residentialAddress
                                      ?.present[0].webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.checklists?.residentialAddress
                                        ?.present[0].mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )}

                        {employee?.checklists?.residentialAddress &&
                          employee?.checklists?.residentialAddress?.permanent
                            ?.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Permanent Address</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.checklists?.residentialAddress
                                      ?.permanent[0].webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.checklists?.residentialAddress
                                        ?.permanent[0].mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )}
                        {employee?.checklists?.bachelorCertificates &&
                          Array.isArray(
                            employee?.checklists?.bachelorCertificates
                          ) &&
                          employee?.checklists?.bachelorCertificates.length >
                            0 &&
                          employee?.checklists?.bachelorCertificates.map(
                            (certificate, index) => (
                              <div className="tab_inner">
                                <div className="tab_item">
                                  <span>
                                    {" "}
                                    Certificate{" "}
                                    {
                                      certificate.name
                                        ?.split("Certificate_")[1]
                                        ?.split(".")[0]
                                        ?.split("_")[0]
                                    }
                                  </span>
                                </div>
                                <div className="tab_item tab_flex">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={certificate.webViewLink}
                                    className="document-img-anchor"
                                  >
                                    <Box
                                      component="img"
                                      src={getDocumentImage(
                                        certificate.mimeType
                                      )}
                                      className="documnet-img-pdf"
                                      width={17}
                                    />
                                  </a>
                                </div>
                              </div>
                            )
                          )}

                        {employee?.documents?.aadharCardImage === null &&
                          employee?.documents?.panCardImage === null &&
                          employee?.checklists?.residentialAddress?.present ===
                            null &&
                          employee?.checklists?.residentialAddress
                            ?.permanent === null &&
                          (employee?.checklists?.bachelorCertificates ===
                            null ||
                            (Array.isArray(
                              employee?.checklists?.bachelorCertificates
                            ) &&
                              employee?.checklists?.bachelorCertificates
                                .length === 0)) && (
                            <div>No Documents uploaded</div>
                          )}
                      </div>
                    </TabPanel>
                    <TabPanel value="4">
                      {/* {employee?.employeeexitchecklist?.length > 0 ? (
                        <div>
                          {Array.isArray(employee?.employeeexitchecklist) &&
                            employee?.employeeexitchecklist?.map((checklist, index) => (
                              checklist?.assigneeName == userName || AuthRole == "HR" || AuthRole == "Super Admin" &&
                              <div className="tab_inner">
                                <div className="tab_item">
                                  <Checkbox 
                                    checked={checklist.status == "Complete" ? true : false}
                                    disabled={checklist.status == "Complete" ? true : false}
                                    onChange={() => handleCheckbox(checklist)}
                                    inputProps={{ "aria-label": "controlled" }}
                                    size="small"
                                  >
                                  </Checkbox> 
                                  {checklist.status == "Complete" ? 
                                    <>
                                    <span style={{textDecoration: 'line-through'}}>
                                      {checklist?.taskTitle ?? "-"} <br/>
                                      {showNote == true ? 
                                        <TextField
                                          label="Enter Note.."
                                          autoComplete="off"
                                          // style={{width:"50%"}}
                                          id={checklist.id}
                                          style={{width:"400px"}}
                                          disabled={checklist.notes != "" || checklist.notes != null ? true : false}
                                          onChange={(e) => setNote(e.target.value)}
                                          value={checklist?.notes ? checklist.notes : values.notes}
                                          variant="outlined"
                                          size="small"
                                          // inputProps={{ maxLength: 10 }}
                                          fullWidth
                                        /> 
                                      : ""} 
                                    </span>
                                  </> :
                                   <>
                                   <span>
                                     {checklist?.taskTitle ?? "-"} <br/>
                                     {showNote == true ? 
                                       <TextField
                                         label="Enter Note.."
                                         autoComplete="off"
                                         style={{width:"400px"}}
                                         onChange={(e) => setNote(e.target.value)}
                                         value={checklist?.notes ? checklist.notes : values.notes}
                                         variant="outlined"
                                         size="small"
                                         inputProps={{ maxLength: 10 }}
                                         fullWidth
                                       /> 
                                     : ""} 
                                   </span>
                                 </>
                                  }
                                </div>
                                <div className="tab_item tab_flex">
                                <DescriptionTwoToneIcon onClick={(e) => setShowNote(!showNote)} title="Notes"/>&nbsp;
                                <Avatar 
                                  {...stringAvatar(checklist?.assigneeName)}
                                />
                                </div>
                              </div> 
                            ))}
                        </div>
                      ) : (
                        "No Checklist"
                      )} */}
                      {employee?.employeeexitchecklist?.length > 0 ? (
                        <div>
                          {Array.isArray(employee?.employeeexitchecklist) &&
                            employee?.employeeexitchecklist?.map(
                              (checklist, index) =>
                                (checklist?.assigneeName === userName ||
                                  AuthRole === "HR" ||
                                  AuthRole === "Super Admin") && (
                                  <div className="tab_inner" key={index}>
                                    <div className="tab_item">
                                      <Checkbox
                                        checked={
                                          checklist?.status === "Complete"
                                        }
                                        disabled={
                                          checklist?.status === "Complete"
                                        }
                                        onChange={() =>
                                          handleCheckbox(checklist)
                                        }
                                        inputProps={{
                                          "aria-label": "controlled",
                                        }}
                                        size="small"
                                      />
                                      <span
                                        style={{
                                          textDecoration:
                                            checklist?.status === "Complete"
                                              ? "line-through"
                                              : "none",
                                        }}
                                      >
                                        {checklist?.taskTitle ?? "-"} <br />
                                        {showNote && (
                                          <TextField
                                            label="Enter Note.."
                                            autoComplete="off"
                                            style={{ width: "400px" }}
                                            id={checklist?.id}
                                            disabled={
                                              checklist?.notes !== "" ||
                                              checklist?.notes !== null
                                            }
                                            onChange={(e) =>
                                              setNote(e?.target?.value)
                                            }
                                            value={
                                              checklist?.notes
                                                ? checklist?.notes
                                                : values?.notes
                                            }
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                          />
                                        )}
                                      </span>
                                    </div>
                                    <div className="tab_item tab_flex">
                                      <DescriptionTwoToneIcon
                                        onClick={() => setShowNote(!showNote)}
                                        title="Notes"
                                      />
                                      &nbsp;
                                      <Avatar
                                        {...stringAvatar(
                                          checklist?.assigneeName
                                        )}
                                      />
                                    </div>
                                  </div>
                                )
                            )}
                        </div>
                      ) : (
                        "No Checklist"
                      )}
                    </TabPanel>

                    <TabPanel value="6">
                      {(employee?.parkingBlock ||
                        employee?.parkingNumber ) && (
                        <>
                          <div className="address-blk">
                            <div className="address-sub-section general_sec">
                              {
                                <div className="address-div">
                                  <span>Parking Allocation</span>
                                  <p>
                                    {employee?.parkingBlock +
                                      "-" +
                                      employee?.parkingNumber}
                                  </p>
                                </div>
                              }
                            </div>
                          </div>
                          <Divider sx={{ margin: "0px 0px 16px 0px" }} />
                        </>
                      )}
                      {employee?.employeeVehicles?.length > 0 ? (
                        <div className="address-blk ">
                          {employee?.employeeVehicles?.map((emp) => {
                            return (
                              <div className="address-sub-section">
                                <div className="address-div">
                                  <span>Vehicle Type</span>
                                  <p>
                                    {emp?.vehicleType ? (
                                      <>
                                       {emp?.vehicleType ==='CAR' && "Car"}
                                       {emp?.vehicleType ==='TWO_WHEELER' && "Two Wheeler"}
                                       {emp?.vehicleType ==='OTHER' && "Other"}
                                       {emp?.vehicleType ==='PUBLIC_TRANSPORT' && "Public Transport"}
                                      </>
                                    ) : (
                                      "-"
                                    )}
                                  </p>
                                </div>
                                <div className="address-div">
                                  <span>Vehicle Name</span>
                                  <p>
                                    {emp?.vehicleName ? (
                                      <>{emp?.vehicleName}</>
                                    ) : (
                                      "-"
                                    )}
                                  </p>
                                </div>
                                <div className="address-div">
                                  <span>Vehicle Number</span>
                                  <p>
                                    {emp?.vehicleNumber ? (
                                      <>{emp?.vehicleNumber}</>
                                    ) : (
                                      "-"
                                    )}
                                  </p>
                                </div>
                                <div className="address-div">
                                  <span>Remarks</span>
                                  <p>
                                    {emp?.remarks ? <>{emp?.remarks}</> : "-"}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        "No Vehicle"
                      )}
                    </TabPanel>
                  </Card>
                </TabContext>
                <TabContext value={tabValue}></TabContext>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {getViewVisible("assets") && employee?.asset?.length > 0 && (
          <>
            <Typography variant="h6" my={2}>
              Assets
            </Typography>
            <Card className="custom-inner-grid">
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left"></TableCell>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Asset Unique Id</TableCell>
                      <TableCell align="left">Location</TableCell>
                      <TableCell align="left">Assign Date</TableCell>
                      <TableCell align="left">IP Address</TableCell>
                      <TableCell align="left">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {employee?.asset?.length > 0 ? (
                      employee?.asset
                        ?.sort(
                          (a, b) =>
                            new Date(b?.assignDate) - new Date(a?.assignDate)
                        )
                        ?.map((asset, index) => {
                          const AssetIcon = asset?.generatedAsset?.assetMaster
                            ? iconConfig[
                                asset?.generatedAsset?.assetMaster?.iconPath ||
                                  ""
                              ]
                            : "";
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {AssetIcon && (
                                  <AssetIcon
                                    color="primary"
                                    sx={{ width: "40px", height: "40px" }}
                                  />
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {asset?.generatedAsset?.name || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {asset?.generatedAsset?.assetUniqueId || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {asset?.generatedAsset?.location || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {(asset?.assignDate &&
                                  indianDateFormat(asset?.assignDate)) ||
                                  "-"}
                              </TableCell>
                              <TableCell align="left">
                                {asset?.ipAddress || "-"}
                              </TableCell>
                              <TableCell align="left">
                                <Stack
                                  className="action-button"
                                  direction="row"
                                >
                                  {getEditVisible("assets") && (
                                    <IconButton
                                      component={RouterLink}
                                      to={`/assets/${encryption(
                                        asset?.generatedAsset?.assetId
                                      )}/add/${encryption(
                                        asset?.generatedAsset?.id
                                      )}`}
                                    >
                                      <EditIcon color="primary" />
                                    </IconButton>
                                  )}
                                  {/* <IconButton>
														<DeleteIcon color="error" />
													</IconButton> */}
                                  <IconButton
                                    component={RouterLink}
                                    to={`/assets/${encryption(
                                      asset?.generatedAsset?.assetId
                                    )}/view/${encryption(
                                      asset?.generatedAsset?.id
                                    )}`}
                                  >
                                    <VisibilityIcon />
                                  </IconButton>
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                    ) : (
                      <TableRow>
                        <TableCell
                          className="No-Record-text"
                          colSpan={12}
                          align="center"
                        >
                          No Record(s) Found
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        )}
        {employee?.pfLogs?.length > 0 && (
          <>
            <Typography variant="h6" my={2}>
              Activity Logs
            </Typography>
            <Card className="gray-box Employee_Detail_Content custom-inner-grid">
              <Timeline position="right">
                {employee?.pfLogs?.length > 0 ? (
                  employee?.pfLogs
                    .sort((a, b) => b.id - a.id)
                    .map((logs) => {
                      return (
                        <TimelineItem sx={{ maxWidth: "100%" }}>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            style={{ maxWidth: "160px" }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000",
                              }}
                            >
                              {logs.approachDate
                                ? fDateTime(logs?.approachDate)
                                : "-"}
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                              <AccountCircleIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                Approached By :-
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#616161",
                                }}
                              >
                                {logs?.user
                                  ? `${logs?.user?.firstName} ${logs?.user?.lastName}`
                                  : "-"}
                              </Typography>
                            </Stack>
                            {/* <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}>
                          Status :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}>
                          {logs?.candidateStatus?.name || "-"}
                        </Typography>
                      </Stack>
                      <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}>
                          State :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}>
                          {logs?.candidateState?.name || "-"}
                        </Typography>
                      </Stack> */}
                            <Stack direction="row" spacing={1}>
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                Note :-
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#616161",
                                }}
                              >
                                {logs?.note || "-"}
                              </Typography>
                            </Stack>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      className="No-Record-text"
                      colSpan={12}
                      align="center"
                    >
                      No Record(s) Found
                    </TableCell>
                  </TableRow>
                )}
              </Timeline>
            </Card>
          </>
        )}
        {employee?.serviceAgreementlogs?.length > 0 &&
        (AuthRole == "HR" || AuthRole == "Super Admin") ? (
          <>
            <Typography variant="h6" my={2}>
              Service Agreement Logs
            </Typography>
            <Card className="custom-inner-grid">
              <Timeline position="right">
                {employee?.serviceAgreementlogs?.length > 0 ? (
                  employee?.serviceAgreementlogs
                    .sort((a, b) => b.id - a.id)
                    .map((logs) => {
                      return (
                        <TimelineItem sx={{ maxWidth: "100%" }}>
                          <TimelineOppositeContent
                            sx={{ m: "auto 0" }}
                            style={{ maxWidth: "160px" }}
                            align="right"
                            variant="body2"
                            color="text.secondary"
                          >
                            <Typography
                              style={{
                                fontSize: "14px",
                                fontWeight: "700",
                                color: "#000",
                              }}
                            >
                              {logs.approachDate
                                ? fDateTime(logs?.approachDate)
                                : "-"}
                            </Typography>
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineConnector />
                            <TimelineDot color="primary">
                              <AccountCircleIcon />
                            </TimelineDot>
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent sx={{ py: "12px", px: 2 }}>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                Approached By :-
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#616161",
                                }}
                              >
                                {logs?.user
                                  ? `${logs?.user?.firstName} ${logs?.user?.lastName}`
                                  : "-"}
                              </Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                              <Typography
                                component="span"
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                              >
                                Note :-
                              </Typography>
                              <Typography
                                style={{
                                  fontSize: "14px",
                                  fontWeight: "400",
                                  color: "#616161",
                                }}
                              >
                                {logs?.note || "-"}
                              </Typography>
                            </Stack>
                          </TimelineContent>
                        </TimelineItem>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      className="No-Record-text"
                      colSpan={12}
                      align="center"
                    >
                      No Record(s) Found
                    </TableCell>
                  </TableRow>
                )}
              </Timeline>
            </Card>
          </>
        ) : (
          ""
        )}
        {data?.length > 0 && (
          <>
            <Typography variant="h6" my={2}>
              Change Request History
            </Typography>
            <Card className="custom-inner-grid">
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table className="Change-Request-table grid-table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <TableSortLabel
                            active={sortBy === "requestDate"}
                            direction={
                              sortBy === "requestDate" ? orderBy : "asc"
                            }
                            onClick={() => handleSorting("requestDate")}
                          >
                            Requested Date
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">
                          <TableSortLabel
                            active={sortBy === "statusupdatedDate"}
                            direction={
                              sortBy === "statusupdatedDate" ? orderBy : "asc"
                            }
                            onClick={() => handleSorting("statusupdatedDate")}
                          >
                            Updated On
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">Requested Module</TableCell>
                        <TableCell align="left">
                          <TableSortLabel
                            active={sortBy === "requestStatus"}
                            direction={
                              sortBy === "requestStatus" ? orderBy : "asc"
                            }
                            onClick={() => handleSorting("requestStatus")}
                          >
                            Status
                          </TableSortLabel>
                        </TableCell>
                        <TableCell align="left">Status Updated By</TableCell>
                        <TableCell align="left">Note</TableCell>
                        <TableCell className="th-action-buttons" align="center">
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data?.length > 0 ? (
                        data?.map((actlog, index) => {
                          return (
                            <TableRow key={index}>
                              <TableCell align="left">
                                {actlog?.requestDate
                                  ? moment(actlog?.requestDate).format(
                                      "DD/MM/yyyy"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {actlog?.statusupdatedDate
                                  ? moment(actlog?.statusupdatedDate).format(
                                      "DD/MM/yyyy"
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {actlog?.requestType.charAt(0).toUpperCase() +
                                  actlog?.requestType.slice(1) || "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                sx={
                                  actlog?.requestStatus === "APPROVED"
                                    ? { color: "green" }
                                    : actlog?.requestStatus === "REJECTED"
                                    ? { color: "red" }
                                    : { color: "blue" }
                                }
                              >
                                {actlog?.requestStatus || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {actlog?.statusUpdatedBy || "-"}
                              </TableCell>
                              <TableCell
                                align="left"
                                style={{ maxWidth: "0", width: "25%" }}
                              >
                                <p
                                  style={{
                                    display: "block",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    border: "none",
                                  }}
                                >
                                  {actlog?.note || "-"}
                                </p>
                              </TableCell>
                              <TableCell
                                className="td-action-button"
                                align="left"
                              >
                                <IconButton
                                  className="action-button"
                                  component={RouterLink}
                                  to={`/changeRequest/view/${encryption(
                                    actlog?.id
                                  )}`}
                                  style={{ padding: "0" }}
                                >
                                  <img
                                    src="/assets/images/vieweye-icon.svg"
                                    title="View"
                                  />
                                  {/* <VisibilityIcon /> */}
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell
                            className="No-Record-text"
                            colSpan={12}
                            align="center"
                          >
                            No Record(s) Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              <CustomPagination
                totalPage={totalPage}
                totalCount={totalCount}
                limit={limit}
                handleChangePage={handleChangePage}
                page={page}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleRowsPerPageChange}
              />
            </Card>
          </>
        )}
        <Typography variant="h6" my={2}>
          Evaluation Group
        </Typography>
        <Card className="custom-inner-grid Evaluation-Group-inner">
          <form
            autoComplete="off"
            noValidate
            className="custom-space-layout"
            onSubmit={handleSubmit}
          >
            <Grid
              container
              spacing={2}
              className="grid_container"
              mb={2}
              mt={2}
            >
              {/* /left personal details */}

              {/* <Grid item xs={6}>
            <Autocomplete
                    size="small"
                    fullWidth
                    defaultValue={{id:id,firstName:employee?.firstName,lastName:employee?.lastName}}
                    options={[{id:id,firstName:employee?.firstName,lastName:employee?.lastName}] || []}
                    key={[{id:id,firstName:employee?.firstName,lastName:employee?.lastName}] || []}
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    {...getFieldProps("employeeId")}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        `employeeId`,
                        newValue?.id || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          if (!e.target.value) {
                            values.employeeId = null;
                          }
                        }}
                        label="Employee"
                        name="employeeId"
                        error={Boolean(
                          touched?.employeeId && errors?.employeeId
                        )}
                        helperText={
                          touched?.employeeId && errors?.employeeId
                        }
                      />
                    )}
                    value={[{id:id,firstName:employee?.firstName,lastName:employee?.lastName}].find(
                      (emp) => emp.id === values.employeeId
                    )}
                  />
              </Grid> */}
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  //   disabled={getViewVisible("department") === false}
                  size="small"
                  fullWidth
                  popupIcon={
                    <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                  }
                  options={allEmployeeData || []}
                  key={allEmployeeData || []}
                  getOptionLabel={(option) =>
                    option.firstName + " " + option.lastName || ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} value={option.id} key={option.id}>
                      {option.firstName + " " + option.lastName || ""}
                    </li>
                  )}
                  {...getFieldProps(`employeeGroupingIds`)}
                  onChange={(event, newValue) => {
                    let tempArray = newValue.map((x) => x.id);
                    setFieldValue("employeeGroupingIds", tempArray);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Employee Group"
                      name="employeeGroupingIds"
                      error={Boolean(
                        touched?.employeeGroupingIds &&
                          errors?.employeeGroupingIds
                      )}
                      helperText={
                        touched?.employeeGroupingIds &&
                        errors?.employeeGroupingIds
                      }
                    />
                  )}
                  value={allEmployeeData.filter((x) =>
                    values.employeeGroupingIds.includes(x.id)
                  )}
                />
              </Grid>
              <Grid
                item
                xs={6}
                style={{ paddingLeft: "10px", paddingRight: "10px" }}
              >
                <LoadingButton
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        </Card>

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack spacing={2} sx={style}>
            <h2>
              Exit Interview - {employee?.firstName ? employee?.firstName : ""}{" "}
              {employee?.lastName ? employee?.lastName : ""}
            </h2>
            {employeeExitInterviewData?.userExitForm?.length > 0
              ? employeeExitInterviewData?.userExitForm?.map((que, idx) => {
                  return (
                    <div>
                      <Typography>
                        <b>{que?.exitInterviewQuestions?.question}</b>
                      </Typography>
                      <Typography>{que?.answer}</Typography>
                    </div>
                  );
                })
              : ""}
            <Button
              variant="contained"
              style={{ maxWidth: "50px" }}
              onClick={() => handleClose()}
            >
              Close
            </Button>
          </Stack>
        </Modal>
      </Container>
    </div>
  );
}

const componentConfig = {
  component: ViewEmployee,
  path: "/employee/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
