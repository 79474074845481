import React, { useEffect } from "react";
import DashboardLayout from "../../layouts/dashboard";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import { Link, useNavigate } from "react-router-dom";
import { getViewVisible } from "../../utils/userPermission";



function ReportsDashboard() {
  const navigate = useNavigate();
  useEffect(() => {
    if (!getViewVisible("reports")) {
      navigate("/dashboard");
    }
  }, []);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} py={3}>
        <Grid item xs={4}>
        <Link to={"employee-experience-report"} style={{ textDecoration: "none" }}>
        {/* <Link onClick={()=>navigate("/employee-experience-report")} style={{ textDecoration: "none" }}> */}

          <Card width="100%" sx={{textAlign: "center", height: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}><strong>Employee Experience Report</strong></Card>
          </Link>
        </Grid>
        <Grid item xs={4}>
        <Link to={"assets-report"} style={{ textDecoration: "none" }}>
          <Card  width="100%" sx={{textAlign: "center", height: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}><strong>Assets Report</strong></Card>
        </Link>
        </Grid>
        <Grid item xs={4}>
        <Link to={"joining-relieving-report"} style={{ textDecoration: "none" }}>
          <Card  width="100%" sx={{textAlign: "center", height: "100px", display: "flex", alignItems: "center", justifyContent: "center"}}><strong>Joining/Relieving Report</strong></Card>
        </Link>
        </Grid>
      </Grid>
    </Container>
  );
}

const componentConfig = {
  component: ReportsDashboard,
  path: "/reports",
  public: false,
  layout: DashboardLayout,
  group: "reports",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
