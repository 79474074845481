import React, { useState } from "react";
import {
	Autocomplete,
	Card,
	Container,
	IconButton,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import CustomPagination from "../../components/Pagination";
import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import { useDispatch, useSelector } from "react-redux";
import { getChangeRequest } from "../../redux/changeRequest/changeRequestThunk";
import { getTotalChangeRequestCount } from "../../redux/changeRequest/changeRequestThunk";
import {
	setLimit,
	setPage,
	setSortBy,
	setOrderBy,
} from "../../redux/changeRequest/changeRequestSlice";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { useEffect } from "react";
import { encryption } from "../../utils/encodeString";
import moment from "moment/moment";
import invertDirection from "../../utils/invertDirection";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

const ChangeRequest = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const data = useSelector((state) => state.changeRequest.data);
	const page = useSelector((state) => state.changeRequest.page);
	const totalPage = useSelector((state) => state.changeRequest.totalPage);
	const totalCount = useSelector((state) => state.changeRequest.totalCount);
	const limit = useSelector((state) => state.changeRequest.limit);
	const sortBy = useSelector((state) => state.changeRequest.sortBy);
	const orderBy = useSelector((state) => state.changeRequest.orderBy);
	const loading = useSelector((state) => state?.ChangeRequest?.loading)
	const totalRecords = useSelector((state) => state.changeRequestCount.totalCounts)
	const [status, setStatus] = useState("pending");
	const getUserPermission = async (Id) => {
		
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("dataChangeRequest")) {
			dispatch(
				getChangeRequest({
					limit,
					page: page + 1,
					request_status: status,
					orderBy,
					sortBy,
				}),
			);
			navigate("/dashboard");
		}
	}, []);

	const statusArray = [
		{
			id: "pending",
			title: "Pending",
		},
		{
			id: "approved",
			title: "Approved",
		},
		{
			id: "rejected",
			title: "Rejected",
		},
	];
	const handleChangePage = (event, newPage) => {
		dispatch(setPage({ page: newPage }));
	};
	useEffect(() => {
		
		dispatch(
			getChangeRequest({
				limit,
				page: page + 1,
				request_status: status,
				orderBy,
				sortBy,
			}),
		);
	}, [dispatch, limit, page, status, sortBy, orderBy]);

	useEffect(() => {
		dispatch(getTotalChangeRequestCount({}))
	},[dispatch])

	const handleRowsPerPageChange = (event) => {
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		 console.log("CCCCCCCCCCCCCCCC______________01",invertDirection(sortBy === columnName, orderBy),"S__01",orderBy)
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

	return (
		<Container className="Employee-wrapper Change-Request-Container" maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
			<div className="title-button-wrapper Change-Request-title">
				<Typography variant="h4" gutterBottom>
					Change Request ({totalRecords})
					{/* <Typography variant="body2">Total Records : {totalRecords}</Typography> */}
					{/* <Typography variant="body2">
            Total Records : {totalCandidateCount}
          </Typography> */}
				</Typography>
				</div>
			</Stack>
			<Stack
				className="Change-Request-main-wrapper"
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				sx={{ width: "100%", marginBottom: "10px" }}
			>
				<Card className="Change_Request_main_card" sx={{ width: "100%", p: 2 }}>
					<Autocomplete
						// size="small"
						fullWidth
						popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
						disableClearable
						options={statusArray || []}
						getOptionLabel={(option) => option.title || ""}
						onChange={(event, newValue) => {
							setStatus(newValue?.id || "");
						}}
						renderInput={(params) => <TextField {...params} label="Status" />}
						value={statusArray.find((dept) => dept.id === status)}
						// error={true}
					/>
				</Card>
			</Stack>
				<Typography className="record-found-text" variant="body2" mb={1}>
					{totalCount} {"records found"}
				</Typography>
	
			<Card className="change-request-main-card">
				<Scrollbar>
					<TableContainer
						component={Paper}
						sx={{ minWidth: 800 }}
						className="radius-remove"
						style={{ borderRadius: "0" }}
					>
						<Table className="change-request-table grid-table">
							<TableHead>
								<TableRow>
									<TableCell align="left">
									<TableSortLabel
										  active={sortBy === " requestBy"}
										  direction={sortBy === " requestBy" ? orderBy : "asc"}
										  onClick={() => handleSorting(" requestBy")}
										>
											Name
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">
									<TableSortLabel
										  active={sortBy === "requestDate"}
										  direction={sortBy === "requestDate" ? orderBy : "asc"}
										  onClick={() => handleSorting("requestDate")}
										>
											Requested Date
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">
									<TableSortLabel
									className="th-sorting"
										  active={sortBy === "statusupdatedDate"}
										  direction={sortBy === "statusupdatedDate" ? orderBy : "asc"}
										  onClick={() => handleSorting("statusupdatedDate")}
										>
											Updated On
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">Requested Module </TableCell>
									<TableCell align="left">Status Updated By </TableCell>
									<TableCell align="left">Notes </TableCell>
									<TableCell align="center" className="th-action-buttons">
										Action
									</TableCell>
								</TableRow>
							</TableHead>
							{loading ? (
				<Loader />
			) : (
							<TableBody>
								{data?.length > 0 ? (
									data.map((request, idx) => {
										return (
											<TableRow key={request?.id}>
												<TableCell align="center">
													{request?.employee?.firstName}{" "}
													{request?.employee?.lastName}
												</TableCell>
												<TableCell align="left">
													{request.requestDate
														? moment(request.requestDate).format("DD/MM/yyyy")
														: "-"}
												</TableCell>
												<TableCell align="left">
													{request?.statusupdatedDate
														? moment(request.statusupdatedDate).format("DD/MM/yyyy")
														: "-"}
												</TableCell>
												<TableCell align="left">
													{request?.requestType
														? request?.requestType.charAt(0).toUpperCase() +
														  request?.requestType.slice(1)
														: "-"}
												</TableCell>
												<TableCell align="left">
													{request?.statusUpdatedBy
														? request?.statusUpdatedBy
														: "-"}
												</TableCell>
												<TableCell align="left" style={{ maxWidth: '0', width:"25%"}}>
												<TableCell align="left" style={{ display: "block",
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            border: 'none',
                                            }}>
													{request?.note
														? request?.note
														: "-"}
												</TableCell>
												</TableCell>
												<TableCell className="td-action-buttons" align="center">
													<IconButton
														className="action-button-group"
														component={RouterLink}
														to={`/changeRequest/view/${encryption(request.id)}`}
													>
														{/* <VisibilityIcon /> */}
														<img src="assets/images/vieweye-icon.svg" title="View" />
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})
								) : (
									<TableRow>
										<TableCell className="No-Record-text" colSpan={12} align="left">
											No Record(s) Found
										</TableCell>
									</TableRow>
								)}
							</TableBody>
							)}
						</Table>
					</TableContainer>
				</Scrollbar>
				<CustomPagination
					totalPage={totalPage}
					totalCount={totalCount}
					limit={limit}
					handleChangePage={handleChangePage}
					page={page}
					rowsPerPageOptions={[10,20, 40]}
					handleRowsPerPageChange={handleRowsPerPageChange}
				/>
			</Card>
		</Container>
	);
};

const componentConfig = {
	component: ChangeRequest,
	path: "/changeRequest",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
