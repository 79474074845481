import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NiceModal from "@ebay/nice-modal-react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import AddCollegeModel from "../../../../components/Model/AddCollegeModel";
import AddCourseModel from "../../../../components/Model/AddCourseModel";
import { getAddVisible, getViewVisible } from "../../../../utils/userPermission";
import { course } from "../../../../validations/mastersSchema";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const educationObj = {
	educationTypeId: "",
	courseId: null,
	courseName: "",
	instituteName: "",
	instituteId: "",
	percentage: null,
	passingYear: "",
	courseAvailable: "",
};

const courseObj = {
	courseName: "",
	instituteName: "",
	certificateUrl: "",
	skills: [],
};

export default function Education(props) {
	const filter = createFilterOptions();

	const {
		values,
		handleChange,
		setFieldValue,
		touched,
		errors,
		setErrors,
		getFieldProps,
		handleTabChange,
		id,
	} = props;

	const educationData = useSelector((state) => state.education);
	const courseData = useSelector((state) => state.course);
	const technologyData = useSelector((state) => state.technology);
	const collegeData = useSelector((state) => state.college.data);
	const skillData = useSelector((state) => state.skill.data);
	const [value, setValue] = React.useState(null);
	const [courseAvailable, setCourseAvailable] = useState(1);
	const [tempIndex, setTempIndex] = useState();
	const addEducation = () => {
		setFieldValue("education", [...values.education, educationObj]);
	};
	const removeEducation = (e, index) => {
		e.preventDefault();
		const temp = [...values.education];
		temp.splice(index, 1);
		setFieldValue("education", temp);
	};

	const addCourse = () => {
		setFieldValue("additionalCourse", [...values.additionalCourse, courseObj]);
	};

	const removeCourse = (e, index) => {
		e.preventDefault();
		const temp = [...values.additionalCourse];
		temp.splice(index, 1);
		setFieldValue("additionalCourse", temp);
	};

	const handleCreatableSelecInstituteChange = (event, newValue, index) => {
		if (typeof newValue === "string") {
			let updatedValues = values.education.map((val, key) => {
				if (key === index) {
					return { ...val, instituteName: newValue, instituteId: null };
				} else {
					return val;
				}
			});
			setFieldValue("education", updatedValues);
		} else if (newValue && newValue.inputValue) {
			// Create a new value from the user input
			let updatedValues = values.education.map((val, key) => {
				if (key === index) {
					return {
						...val,
						instituteName: newValue.inputValue,
						instituteId: null,
					};
				} else {
					return val;
				}
			});
			setFieldValue("education", updatedValues);
		} else {
			let updatedValues = values.education.map((val, key) => {
				if (key === index) {
					return {
						...val,
						instituteId: newValue && newValue.id,
						instituteName: "",
					};
				} else {
					return val;
				}
			});
			setFieldValue("education", updatedValues);
		}
	};

	const handleCreatableSelecCourseChange = (event, newValue, index) => {
		if (typeof newValue === "string") {
			let updatedValues = values.education.map((val, key) => {
				if (key === index) {
					return { ...val, courseName: newValue, courseId: null };
				} else {
					return val;
				}
			});
			setFieldValue("education", updatedValues);
			setValue({
				title: newValue,
			});
		} else if (newValue && newValue.inputValue) {
			// Create a new value from the user input
			let updatedValues = values.education.map((val, key) => {
				if (key === index) {
					return {
						...val,
						courseName: newValue.inputValue,
						courseId: null,
					};
				} else {
					return val;
				}
			});
			setFieldValue("education", updatedValues);
			setValue({
				title: newValue.inputValue,
			});
		} else {
			let updatedValues = values.education.map((val, key) => {
				if (key === index) {
					return {
						...val,
						courseId: newValue && newValue.id,
						courseName: "",
					};
				} else {
					return val;
				}
			});
			setFieldValue("education", updatedValues);
			setValue(newValue);
		}
	};

	const handleSkillChange = (event, index) => {
		setFieldValue(`additionalCourse[${index}].skills`, event.target.value);
	};

	if (values.education.length === 0) {
		values.education = [educationObj];
	}

	return (
		<Card className="right-side-content-part">
			<Container maxWidth="xl">
				<div className="education">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mt={3}
						mb={2}
						className="Employee_Detail-heading"
					>
						<Typography className="Employee_Detail-h6" variant="h6" gutterBottom style={{ marginTop: "0"}}>
							Education Details
						</Typography>
						<Button
							className="header-add-button"
							onClick={addEducation}
							variant="contained"
							// startIcon={<AddIcon />}
						>
							<img src="/assets/images/add-circle.svg" />
							Add Education
						</Button>
					</Stack>
					{values &&
						values.education &&
						values.education.map((edu, index) => (
							<Stack key={index}>
								<Grid className="gray-box Employee_Detail_Content" key={index} container spacing={3}>
									<Grid item xs={4}>
										{/*<InputLabel id="demo-simple-select-helper-label">
												Education Type
											</InputLabel>
											<Select
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												label="Education Type"
												size="small"
												onChange={handleChange}
												{...getFieldProps(
													`education[${index}].educationTypeId`
												)}
											>
												{educationData.data &&
													educationData.data.map((e, index) => (
														<MenuItem key={index} value={e.id}>
															{e.title}
														</MenuItem>
													))}
													</Select> */}

										<Autocomplete
											size="small"
											fullWidth
											popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
											options={educationData.data || []}
											getOptionLabel={(option) => option.title || ""}
											{...getFieldProps(`education[${index}].educationTypeId`)}
											onChange={(event, newValue) => {
												console.log(newValue)
												setCourseAvailable(newValue?.courseAvailable);
												setFieldValue(`education[${index}].courseAvailable`, newValue?.courseAvailable)
												setTempIndex(index)
												setFieldValue(
													`education[${index}].educationTypeId`,
													newValue?.id || "",
												);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Education Type"
													error={Boolean(
														touched?.education &&
															touched?.education[index] &&
															touched?.education[index]?.educationTypeId &&
															errors?.education &&
															errors?.education[index] &&
															errors?.education[index]?.educationTypeId,
													)}
													helperText={
														touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.educationTypeId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.educationTypeId
													}
												/>
											)}
											value={educationData.data.find(
												(education) =>
													education.id ===
													values.education[index].educationTypeId,
											)}
											error={true}
										/>
									</Grid>
									<Grid item xs={6}>
										<Autocomplete
											size="small"
											disabled={!getViewVisible("college")}
											popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
											options={
												collegeData &&
												collegeData.map((clg) => ({
													...clg,
													title: clg.collegeName,
												}))
											}
											getOptionLabel={(option) => {
												// Value selected with enter, right from the input
												if (typeof option === "string") {
													return option;
												}
												// Add "xxx" option created dynamically
												if (option.title) {
													return option.title;
												}
												// Regular option
												return option.collegeName;
											}}
											filterOptions={(options, params) => {
												const filtered = filter(options, params);

												const { inputValue } = params;
												// Suggest the creation of a new value
												const isExisting = options.some(
													(option) => inputValue === option.title,
												);
												if (inputValue !== "" && !isExisting) {
													filtered.push({
														inputValue,
														title: `Add "${inputValue}"`,
													});
												}

												return filtered;
											}}
											{...getFieldProps(`education[${index}].instituteId`)}
											onChange={async (event, newValue) => {
												handleCreatableSelecInstituteChange(
													event,
													newValue,
													index,
												);
											}}
											/* onChange={async (event, newValue) => {
												event.preventDefault();
												if (typeof newValue === "string") {
													// timeout to avoid instant validation of the dialog's form.
													setTimeout(async () => {
														const res = await NiceModal.show(AddCollegeModel, {
															name: newValue,
														});
														setFieldValue(
															`education[${index}].instituteId`,
															res.id,
														);
													});
												} else if (newValue && newValue.inputValue) {
													const res = await NiceModal.show(AddCollegeModel, {
														name: newValue.inputValue,
													});
													setFieldValue(
														`education[${index}].instituteId`,
														res.id,
													);
												} else {
													setFieldValue(
														`education[${index}].instituteId`,
														newValue.id,
													);
												}
											}} */
											// selectOnFocus
											// clearOnBlur
											// handleHomeEndKeys
											// id="institute-name"
											// renderOption={(props, option) => (
											// 	<li key={option.title} {...props}>
											// 		{option.title}
											// 	</li>
											// )}
											// sx={{ width: 300 }}
											// freeSolo
											renderInput={(params) => (
												<TextField
													{...params}
													error={Boolean(
														touched?.education &&
															touched?.education[index] &&
															touched?.education[index]?.instituteId &&
															errors?.education &&
															errors?.education[index] &&
															errors?.education[index]?.institute,
													)}
													helperText={
														touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.instituteId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.institute
													}
													label="Institute Name"
												/>
											)}
											value={
												values.education[index].instituteId
													? collegeData &&
													  collegeData
															.map((clg) => ({
																...clg,
																title: clg.collegeName,
															}))
															.find(
																(clg) =>
																	clg.id ===
																	values.education[index].instituteId,
															)
													: values.education[index].instituteName
													? { title: values.education[index].instituteName }
													: ""
											}
										/>
									</Grid>

									<Grid item xs={1}>
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="end"
										>
											{values &&
												values.education &&
												Array.isArray(values.education) &&
												values.education.filter((e) => !e.deleted).length >
													1 && (
													<IconButton
														onClick={(e) => removeEducation(e, index)}
													>
														<DeleteIcon color="error" />
													</IconButton>
												)}
										</Stack>
									</Grid>
									{console.log(index,tempIndex)}
									{
										index !== tempIndex && edu?.educationtype?.courseAvailable !== 0 ? 
										<Grid item xs={4}>
										<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										fullWidth
										popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										options={courseData.data || []}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.title;
											}
											// Regular option
											return option.title;
										}}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);

											const { inputValue } = params;
											// Suggest the creation of a new value

											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== "" && !isExisting) {
												filtered.push({
													inputValue,
													title: `Add "${inputValue}"`,
												});
											}
											return filtered;
										}}
										{...getFieldProps(`education[${index}].courseId`)}
										onChange={async (event, newValue) => {
											handleCreatableSelecCourseChange(
												event,
												newValue,
												index,
											);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Course"
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.courseId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.courseId,
												)}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.courseId &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.courseId
												}
											/>
										)}
										value={
											values.education[index].courseId
												? courseData &&
													courseData.data
														.map((clg) => ({
															...clg,
															title: clg.title,
														}))
														.find(
															(clg) =>
																clg.id === values.education[index].courseId,
														)
												: values.education[index].courseName
												? { title: values.education[index].courseName }
												: ""
										}
									/>
								</Grid> : 
								id && edu?.educationtype?.courseAvailable == 1 && courseAvailable == 1 && index === tempIndex ? 
								<Grid item xs={4}>
										<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										fullWidth
										options={courseData.data || []}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.title;
											}
											// Regular option
											return option.title;
										}}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);

											const { inputValue } = params;
											// Suggest the creation of a new value

											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== "" && !isExisting) {
												filtered.push({
													inputValue,
													title: `Add "${inputValue}"`,
												});
											}
											return filtered;
										}}
										{...getFieldProps(`education[${index}].courseId`)}
										onChange={async (event, newValue) => {
											handleCreatableSelecCourseChange(
												event,
												newValue,
												index,
											);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Course"
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.courseId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.courseId,
												)}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.courseId &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.courseId
												}
											/>
										)}
										value={
											values.education[index].courseId
												? courseData &&
													courseData.data
														.map((clg) => ({
															...clg,
															title: clg.title,
														}))
														.find(
															(clg) =>
																clg.id === values.education[index].courseId,
														)
												: values.education[index].courseName
												? { title: values.education[index].courseName }
												: ""
										}
									/>
								</Grid> : 
								courseAvailable == 1 && index === tempIndex ?
								<Grid item xs={4}>
									<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										fullWidth
										popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
										options={courseData.data || []}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.title;
											}
											// Regular option
											return option.title;
										}}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);

											const { inputValue } = params;
											// Suggest the creation of a new value

											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== "" && !isExisting) {
												filtered.push({
													inputValue,
													title: `Add "${inputValue}"`,
												});
											}
											return filtered;
										}}
										{...getFieldProps(`education[${index}].courseId`)}
										onChange={async (event, newValue) => {
											handleCreatableSelecCourseChange(
												event,
												newValue,
												index,
											);
										}}
										
										renderInput={(params) => (
											<TextField
												{...params}
												label="Course"
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.courseId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.courseId,
												)}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.courseId &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.courseId
												}
											/>
										)}
										value={
											values.education[index].courseId
												? courseData &&
													courseData.data
														.map((clg) => ({
															...clg,
															title: clg.title,
														}))
														.find(
															(clg) =>
																clg.id === values.education[index].courseId,
														)
												: values.education[index].courseName
												? { title: values.education[index].courseName }
												: ""
										}
									/>
								</Grid> : ""
								
									}
									{/* {id && edu?.educationtype?.courseAvailable == 1 && courseAvailable == 1 && index === tempIndex ? 
									<Grid item xs={4}>
										<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										fullWidth
										options={courseData.data || []}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.title;
											}
											// Regular option
											return option.title;
										}}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);

											const { inputValue } = params;
											// Suggest the creation of a new value

											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== "" && !isExisting) {
												filtered.push({
													inputValue,
													title: `Add "${inputValue}"`,
												});
											}
											return filtered;
										}}
										{...getFieldProps(`education[${index}].courseId`)}
										onChange={async (event, newValue) => {
											handleCreatableSelecCourseChange(
												event,
												newValue,
												index,
											);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Course"
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.courseId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.courseId,
												)}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.courseId &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.courseId
												}
											/>
										)}
										value={
											values.education[index].courseId
												? courseData &&
													courseData.data
														.map((clg) => ({
															...clg,
															title: clg.title,
														}))
														.find(
															(clg) =>
																clg.id === values.education[index].courseId,
														)
												: values.education[index].courseName
												? { title: values.education[index].courseName }
												: ""
										}
									/>
								</Grid> : 
								courseAvailable == 1 && index === tempIndex ?
								<Grid item xs={4}>
									<Autocomplete
										labelid="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										fullWidth
										options={courseData.data || []}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === "string") {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.title;
											}
											// Regular option
											return option.title;
										}}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);

											const { inputValue } = params;
											// Suggest the creation of a new value

											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== "" && !isExisting) {
												filtered.push({
													inputValue,
													title: `Add "${inputValue}"`,
												});
											}
											return filtered;
										}}
										{...getFieldProps(`education[${index}].courseId`)}
										onChange={async (event, newValue) => {
											handleCreatableSelecCourseChange(
												event,
												newValue,
												index,
											);
										}}
										
										renderInput={(params) => (
											<TextField
												{...params}
												label="Course"
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.courseId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.courseId,
												)}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.courseId &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.courseId
												}
											/>
										)}
										value={
											values.education[index].courseId
												? courseData &&
													courseData.data
														.map((clg) => ({
															...clg,
															title: clg.title,
														}))
														.find(
															(clg) =>
																clg.id === values.education[index].courseId,
														)
												: values.education[index].courseName
												? { title: values.education[index].courseName }
												: ""
										}
									/>
								</Grid> : ""
								} */}
									<Grid item xs={4}>
										<TextField
											label="CGPA/Percentage"
											name="percentage"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 4 }}
											fullWidth

											helperText={
												touched?.education &&
												touched?.education[index] &&
												touched?.education[index]?.percentage &&
												errors?.education &&
												errors?.education[index] &&
												errors?.education[index]?.percentage
											}
											error={Boolean(
												touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.percentage &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.percentage,
											)}
											{...getFieldProps(`education[${index}].percentage`)}
										/>
									</Grid>
									<Grid item xs={4}>
										<FormControl
											error={
												touched?.education &&
												touched?.education[index] &&
												touched?.education[index]?.passingYear &&
												errors?.education &&
												errors?.education[index] &&
												errors?.education[index]?.passingYear
											}
											size="small"
											sx={{ minWidth: 120 }}
											fullWidth
										>
											<TextField
												label="Passing Year"
												name="percentage"
												variant="outlined"
												size="small"
												fullWidth
												inputProps={{ maxLength: 4 }}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.passingYear &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.passingYear
												}
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.passingYear &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.passingYear,
												)}
												{...getFieldProps(`education[${index}].passingYear`)}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<Stack my={3}>
									<Divider />
								</Stack>
							</Stack>
						))}
				</div>

				<div className="other-skill">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mt={4}
						mb={2}
						className="Employee_Detail-heading"
					>
						<Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
							ADDITIONAL CERTIFIED COURSES & SKILLS
						</Typography>
						<Button
						className="header-add-button"
							onClick={addCourse}
							variant="contained"
							// startIcon={<AddIcon />}
						>
							<img src="/assets/images/add-circle.svg" />
							Add Courses
						</Button>
					</Stack>
					{values &&
						values.additionalCourse &&
						values.additionalCourse.map((crs, index) => (
							<Stack key={index}>
								<Grid className="gray-box Employee_Detail_Content" key={index} container spacing={3}>
									<Grid item xs={5}>
										<TextField
											fullWidth
											label="Course Name"
											variant="outlined"
											size="small"
											onChange={handleChange}
											inputProps={{ maxLength: 256 }}
											helperText={
												touched?.additionalCourse &&
												touched?.additionalCourse[index] &&
												touched?.additionalCourse[index]?.courseName &&
												errors?.additionalCourse &&
												errors?.additionalCourse[index] &&
												errors?.additionalCourse[index]?.courseName
											}
											error={Boolean(
												touched?.additionalCourse &&
													touched?.additionalCourse[index] &&
													touched?.additionalCourse[index]?.courseName &&
													errors?.additionalCourse &&
													errors?.additionalCourse[index] &&
													errors?.additionalCourse[index]?.courseName,
											)}
											{...getFieldProps(
												`additionalCourse[${index}].courseName`,
											)}
										/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											fullWidth
											label="Certificate Url"
											variant="outlined"
											size="small"
											onChange={handleChange}
											inputProps={{ maxLength: 512 }}
											helperText={
												touched?.additionalCourse &&
												touched?.additionalCourse[index] &&
												touched?.additionalCourse[index]?.certificateUrl &&
												errors?.additionalCourse &&
												errors?.additionalCourse[index] &&
												errors?.additionalCourse[index]?.certificateUrl
											}
											error={Boolean(
												touched?.additionalCourse &&
													touched?.additionalCourse[index] &&
													touched?.additionalCourse[index]?.certificateUrl &&
													errors?.additionalCourse &&
													errors?.additionalCourse[index] &&
													errors?.additionalCourse[index]?.certificateUrl,
											)}
											{...getFieldProps(
												`additionalCourse[${index}].certificateUrl`,
											)}
										/>
									</Grid>
									<Grid item xs={1}>
										<Stack
											direction="row"
											alignItems="center"
											justifyContent="end"
										>
											{values &&
												values.additionalCourse &&
												Array.isArray(values.additionalCourse) &&
												values.additionalCourse.filter((e) => !e.deleted)
													.length > 0 && (
													<IconButton onClick={(e) => removeCourse(e, index)}>
														<DeleteIcon color="error" />
													</IconButton>
												)}
										</Stack>
									</Grid>
									<Grid item xs={5}>
										{/*<InputLabel>Skills</InputLabel>
											<Select
												multiple
												size="small"
												value={values.additionalCourse[index].skills}
												onChange={(e) => handleSkillChange(e, index)}
												input={<OutlinedInput label="Skills" />}
											>
												{technologyData.data &&
													technologyData.data.map((skill) => (
														<MenuItem key={skill.id} value={skill.id}>
															{skill.title}
														</MenuItem>
													))}
											</Select> */}
										<Autocomplete
											multiple
											size="small"
											fullWidth
											popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
											options={skillData || []}
											getOptionLabel={(option) => option.title || ""}
											{...getFieldProps(`additionalCourse[${index}].skills`)}
											onChange={(event, newValue) => {
												let tempArray = newValue.map((x) => x.id);
												setFieldValue(
													`additionalCourse[${index}].skills`,
													tempArray || "",
												);
											}}
											value={skillData.filter((x) =>
												values.additionalCourse[index].skills.includes(x.id),
											)}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Skills"
													error={Boolean(
														touched?.additionalCourse &&
															touched?.additionalCourse[index] &&
															touched?.additionalCourse[index]?.skills &&
															errors?.additionalCourse &&
															errors?.additionalCourse[index] &&
															errors?.additionalCourse[index]?.skills,
													)}
													helperText={
														touched?.additionalCourse &&
														touched?.additionalCourse[index] &&
														touched?.additionalCourse[index]?.skills &&
														errors?.additionalCourse &&
														errors?.additionalCourse[index] &&
														errors?.additionalCourse[index]?.skills
													}
												/>
											)}
										/>
									</Grid>
									<Grid item xs={5}>
										<TextField
											fullWidth
											label="Institute Name"
											name="instituteName"
											variant="outlined"
											size="small"
											onChange={handleChange}
											inputProps={{ maxLength: 512 }}
											helperText={
												touched?.additionalCourse &&
												touched?.additionalCourse[index] &&
												touched?.additionalCourse[index]?.instituteName &&
												errors?.additionalCourse &&
												errors?.additionalCourse[index] &&
												errors?.additionalCourse[index]?.instituteName
											}
											error={Boolean(
												touched?.additionalCourse &&
													touched?.additionalCourse[index] &&
													touched?.additionalCourse[index]?.instituteName &&
													errors?.additionalCourse &&
													errors?.additionalCourse[index] &&
													errors?.additionalCourse[index]?.instituteName,
											)}
											{...getFieldProps(
												`additionalCourse[${index}].instituteName`,
											)}
										/>
									</Grid>
								</Grid>
								<Stack my={3}>
									<Divider />
								</Stack>
							</Stack>
						))}
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						mb={3}
						mt={4}
					>
						<Button
							className="back-button"
							onClick={() => handleTabChange("3")}
							variant="contained"
							startIcon={<ArrowBackIcon />}
							style={{ marginRight: "15px" }}
						>
							Back
						</Button>
						<Button onClick={() => handleTabChange("5")} variant="contained" endIcon={<ArrowForwardIcon />}>
							Next
						</Button>
					</Stack>
				</div>
			</Container>
		</Card>
	);
}
