import React, { useState, useEffect, useCallback } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { findIndex } from "lodash";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import assetSchema from "../../../validations/assetSchema";

import DashboardLayout from "../../../layouts/dashboard";
import Loader from "../../../components/Loader";
import MemoizedHasOneComponent from "./addAssetComponents/HasOneComponent";
import MemoizedHasManyComponent from "./addAssetComponents/HasManyComponent";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete , { createFilterOptions }from "@mui/material/Autocomplete";

import {
  generateAsset,
  getAssetById,
  getGeneratedAssetsById,
  updateGeneratedAssets,
  getGeneratedAssetsCount,
  getAssettag,
} from "../../../redux/assets/thunk";

import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { decryption, encryption } from "../../../utils/encodeString";

function AddAsset() {
  let { assetId, id } = useParams();
  assetId = decryption(assetId);
  id = id ? decryption(id) : null;
  const params = new URLSearchParams(window.location.search);
  const clone = params.get("clone");
  const navigate = useNavigate();
  const filter = createFilterOptions();
  // if (!getAddVisible("assets") || !getEditVisible("assets")) {
  // 	navigate("/dashboard");
  // }
  const [loading, setLoading] = useState(true);
  const [asset, setAsset] = useState([]);
  const dispatch = useDispatch();
  const allEmployeeData = useSelector(
    (state) => state.employee.allEmployeeData
  );
  const [isFocused, setIsFocused] = useState({});
  const allEmployeeDataStatus = useSelector(
    (state) => state.employee.allEmployeeDataStatus
  );
  const assetName = useSelector((state) => state.assetCount.name);
  const assetTagDropdown = useSelector((state) => state.assets.tag);
  // const assetTagDropdown = [{id : 1 , name : "Akash"},{id : 2 , name : "Axay"}]
  const stateAll = useSelector((state) => state);

  useEffect(() => {
    if (assetId === -1) {
      navigate("/assets");
    } else {
      dispatch(getGeneratedAssetsCount({ assetId: assetId }));
   
    }
  }, [assetId, dispatch]);
  useEffect(()=>{
    dispatch(getAssettag());
  },[dispatch])
  const locationData = ["Office", "Out of office"];
  const statusDropDown = [{id : 1 , title : "Working"}, {id : 2 , title :"In Repair"}, {id : 3 , title :"Scrap"}, {id : 4 , title :"Need Upgrade"}];
  const [allowFreeText, setAllowFreeText] = useState(false);
  const formik = useFormik({
    initialValues: {
      assetId: assetId,
      id: null,
      name: "",
      location: "",
	  assetStatus : "",
	  assetTag : [],
	  assetNotes : "",
      assetEmployee: {
        employeeId: "",
        assignDate: null,
        ipAddress: "",
      },

      assets: {},
      deleteGeneratedAssets: [],
    },
    validate: async (values) => {
      let errors = {};

      if (!values.name) errors = { ...errors, name: "This field is required" };
      if(!values?.assetStatus) errors = {...errors, assetStatus : "This field is required" }
      if (!values.location)
        errors = { ...errors, location: "This field is required" };

      //let regEx = /\b((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}\b/;
      let regEx =
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
      if (values.assetEmployee.ipAddress) {
        if (!regEx.test(values.assetEmployee.ipAddress))
          errors = {
            ...errors,
            assetEmployee: { ipAddress: "Please enter valid ip address." },
          };
      }
      /* if (!values.assetEmployee.ipAddress)
    		errors = {
    			...errors,
    			assetEmployee: { ipAddress: "This field is required" },
    		}; */

      const createObject = (key, idx) => {
        if (!errors["assets"]) errors["assets"] = {};
        if (!errors["assets"][key]) errors["assets"][key] = [];
        if (!errors["assets"][key][idx]) errors["assets"][key][idx] = {};
      };

      const isSerialNumberUnique = [];

      for (const key in values.assets) {
        values["assets"][key].forEach((val, idx) => {
          const isValueEmpty = [];

          for (const k in val) {
            const temp = {};

            if (k !== "serialNumber") {
              temp.value = val[k]?.value;
              temp.freeText = val[k]?.freeText;
              temp.k = k;
              temp.assetId = assetId;
            }

            if (k === "serialNumber") {
              temp.value = val[k];
              temp.k = k;
              temp.assetId = assetId;

              isSerialNumberUnique.push({
                index: key,
                id: idx,
                serialNumber: val[k],
              });
            }

            isValueEmpty.push(temp);
          }
          asset.assetItemMapping.forEach((e) => {
            isValueEmpty.forEach((errorKey) => {
              setAllowFreeText(e?.itemMaster?.attributes[0]?.allowFreeText);
              // setAllowFreeText(e?.itemMaster?.attributes[0]?.allowFreeText || null);
              if (
                !errorKey.value &&
                !errorKey.freeText &&
                errorKey.k != "serialNumber" &&
                errorKey.k != "ipAddress"
              ) {
                if (
                  e.assetId == errorKey.assetId &&
                  e.itemId == key &&
                  e.isRequired == true
                ) {
                  createObject(key, idx);
                  errors["assets"][key][idx][errorKey.k] =
                    "This field is required";
                }
              }
            });
          });
        });
      }
      if (!allowFreeText) {
        for (let index = 0; index < isSerialNumberUnique.length; index++) {
          for (let index2 = 1; index2 < isSerialNumberUnique.length; index2++) {
            const element = isSerialNumberUnique[index];
            const element1 = isSerialNumberUnique[index2];

            if (element.serialNumber != "" && element1.serialNumber != "") {
              if (element.index != element1.index) {
                if (
                  element.serialNumber == element1.serialNumber &&
                  index < index2
                ) {
                  createObject(element.index, element.id);
                  createObject(element1.index, element1.id);

                  errors["assets"][element.index][element.id]["serialNumber"] =
                    "Duplicate serial number entered";
                  errors["assets"][element1.index][element1.id][
                    "serialNumber"
                  ] = "Duplicate serial number entered";
                }
              } else {
                if (element.id != element1.id) {
                  if (
                    element.serialNumber == element1.serialNumber &&
                    index < index2
                  ) {
                    createObject(element.index, element.id);
                    createObject(element1.index, element1.id);

                    errors["assets"][element.index][element.id][
                      "serialNumber"
                    ] = "Duplicate serial number entered";
                    errors["assets"][element1.index][element1.id][
                      "serialNumber"
                    ] = "Duplicate serial number entered";
                  }
                }
              }
            }
          }
        }
      }

      // let hardwarError = true;
      // for (const key in values.assets) {
      //   values["assets"][key].forEach((val, idx) => {
      //     for (const k in val) {
      //       if (val[k] && val[k]?.value !== "" && val[k]?.value !== null) {
      //         console.log(val[k]?.value !== "", val[k]?.value !== null);
      //         hardwarError = false;
      //       }
      //     }
      //   });
      // }

      // if (hardwarError) {
      //   // setMinimumOnehardwarerror(hardwarError);
      //   errors["minimumOnehardwarerror"] = hardwarError;
      // } else {
      //   delete errors["minimumOnehardwarerror"];
      // }

      /* console.log(errors); */

      return errors;
    },
    validationSchema: assetSchema,
    onSubmit: async (values) => {
      try {
        let response;
        for (const key in values.assets) {
          values.assets[key].forEach((val, idx) => {
            const assetKeys = Object.keys(val);
            //console.log(assetKeys)
            const isValueEmpty = assetKeys.map((k) => {
              if (k !== "serialNumber" && !val[k].value && !val[k].freeText) {
                return true;
              }
              return false;
            });
            if (isValueEmpty.some((isEmpty) => isEmpty)) {
              values.assets[key].splice(idx, 1);
              if (values.assets[key].length === 0) {
                delete values.assets[key];
              }
            }
          });
        }
        if (!values.assetEmployee.employeeId)
          values.assetEmployee.employeeId = null;
        if (!id || (id && clone)) {
          delete values.deleteGeneratedAssets;
          delete values.id;
          delete values.assetEmployee.id;
          delete values.assetEmployee?.createdDate;
          delete values.assetEmployee?.deletedDate;
          delete values.assetEmployee?.updatedDate;
          if (values.assetEmployee.employeeId == null) {
            values.assetEmployee.employee = null;
            values.assetEmployee.assignDate = null;
          }

          response = await dispatch(generateAsset(values)).unwrap();
        }
        if (id && !clone) {
          if (values.assetEmployee.employeeId == null) {
            values.assetEmployee.employeeId = null;
            values.assetEmployee.employee = null;
            values.assetEmployee.assignDate = null;
          }
          response = await dispatch(updateGeneratedAssets(values)).unwrap();
        }
        if (response.status === 200) {
          navigate(`/assets/${encryption(assetId)}/view`);
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const {
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    setValues,
    errors,
    touched,
    submitCount,
    setErrors,
    handleBlur,
    handleChange,
  } = formik;

  const prepareInitialState = (assetItems) => {
    const mappedItemMaster = {};

    for (const asstitm of assetItems) {
      const mappedAttributes = asstitm.itemMaster.attributes.map(
        (itmast) => itmast.id
      );

      const tempAttributeId = {};

      mappedAttributes.forEach((element) => {
        tempAttributeId[element] = {
          value: null,
          freeText: "",
          id: null,
          groupId: null,
        };
      });
      tempAttributeId["serialNumber"] = "";

      mappedItemMaster[asstitm.itemId] = [tempAttributeId];
    }

    setFieldValue("assets", mappedItemMaster);

    return mappedItemMaster;
  };

  const prepareInitialStateWithValue = async (assetItems) => {
    try {
      const tempAssets = prepareInitialState(assetItems);
      const response = await dispatch(getGeneratedAssetsById(id)).unwrap();

      const generatedAssetItems = response.data.generatedAssetItems;
      let assetTags = response?.data?.assetTagGeneratedAssetsMapping?.map((tag) => tag.assetTagsMaster ) || []
      setValues({
        assets: tempAssets,
        assetEmployee: response.data.assetEmployee ?? values.assetEmployee,
        name: response.data.name,
        assetStatus :  response.data.assetStatus || "",
        assetTag : assetTags || [],
        assetNotes : response.data.assetNotes || "",
        location: response.data.location,
        id: response.data.id,
        deleteGeneratedAssets: [],
        assetId: assetId,
      });

      for (const generatedAssetKey in generatedAssetItems) {
        const totalGroup = Object.keys(generatedAssetItems[generatedAssetKey]);
        const totalGroupLength = totalGroup.length;

        let tempArray = new Array(totalGroupLength)
          .fill(
            tempAssets[generatedAssetKey] && tempAssets[generatedAssetKey][0]
              ? {
                  ...tempAssets[generatedAssetKey][0],
                }
              : {}
          )
          .map((mapped) => {
            let temps = {};
            for (const key in mapped) {
              temps[key] = {
                value: null,
                freeText: "",
                id: null,
                groupId: null,
              };
            }
            temps["serialNumber"] = "";
            return temps;
          });

        tempAssets[generatedAssetKey] = tempArray;

        totalGroup.forEach((element, index) => {
          generatedAssetItems[generatedAssetKey][element].forEach((el, idx) => {
            tempAssets[generatedAssetKey][index][el.itemAttributeId] = {
              value: el.itemAttributeValueId,
              freeText: el?.freeText,
              id: el.id ? el.id : null,
              groupId: el.assetItemGroupId ? el.assetItemGroupId : null,
            };
            if (id && clone) {
              tempAssets[generatedAssetKey][index]["serialNumber"] = "";
            } else {
              tempAssets[generatedAssetKey][index]["serialNumber"] =
                el?.serialNumber;
            }
          });
        });
      }
      // setValues({
      // 	assets: tempAssets,
      // 	assetEmployee: response.data.assetEmployee ?? values.assetEmployee,
      // 	name: response.data.name,
      // 	location: response.data.location,
      // 	id: response.data.id,
      // 	deleteGeneratedAssets: [],
      // 	assetId: assetId,
      // });
      // setValues({ ...values,, });
    } catch (error) {}
  };

  const getAttributteValuesById = useCallback((id, itemAttribute) => {
    const attribute = itemAttribute.find((attr) => {
      return parseInt(attr.id) === parseInt(id);
    });
    return attribute?.attributeValues || [];
  }, []);




  const getAttributeLabel = useCallback((id, itemAttribute) => {
    const attributeLabel = itemAttribute.find(
      (attr) => parseInt(attr.id) === parseInt(id)
    );
    return attributeLabel?.name || "Label Not Found";
  }, []);

  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

  useEffect(() => {
    const getAsset = async () => {
      try {
        const res = await dispatch(getAssetById(assetId)).unwrap();
        setAsset(res.data);

        if (!id) {
          prepareInitialState(res.data.assetItemMapping);
        }
        if (id) {
          await prepareInitialStateWithValue(res.data.assetItemMapping);
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.message);
      }
    };
    getAsset();
  }, [dispatch]);

  const handleItemValueChange = useCallback(
    (assetItemId, itemAttributeId, itemAttributeValueId) => {
      const tempArr = [...values.assetItems];
      // console.log("handleItemValueChange")
      const assetItemIndex = findIndex(
        tempArr,
        (t) =>
          t.assetItemId === assetItemId && t.itemAttributeId === itemAttributeId
      );

      if (assetItemIndex === -1) {
        setFieldValue("assetItems", [
          ...values.assetItems,
          {
            assetItemId,
            itemAttributeId,
            itemAttributeValueId,
          },
        ]);
        return;
      }

      tempArr[assetItemIndex].itemAttributeValueId = itemAttributeValueId;

      setFieldValue("assetItems", tempArr);
    },
    [setFieldValue, values.assetItems]
  );

  const addMore = useCallback(
    (assetItemId, mck) => {
      const toBeAddedFields = {};
      console.log("ADD")
      for (const key in mck) {
        toBeAddedFields[key] = { value: "", id: null };
        toBeAddedFields["serialNumber"] = "";
      }

      const newState = [...values.assets[assetItemId], toBeAddedFields];
      setFieldValue(`assets.${assetItemId}`, newState);
    },
    [values.assets, setFieldValue]
  );
  const handleFocus = (index, id) => {
    setIsFocused({ [index]: { [id]: true } });
  };
  const removeField = useCallback(
    (assetItemId, index) => {
      console.log("REMOVE")
      const tempValues = [...values.assets[assetItemId]];
      const splicedValue = tempValues.splice(index, 1);

      if (id) {
        let ids = [];

        for (const key in splicedValue[0]) {
          ids = [...ids, splicedValue[0][key]?.["id"]];
        }

        setFieldValue("deleteGeneratedAssets", [
          ...values.deleteGeneratedAssets,
          ...ids,
        ]);
      }

      setFieldValue(`assets[${assetItemId}]`, tempValues);
    },
    [id, setFieldValue, values.assets, values.deleteGeneratedAssets]
  );



  if (loading) {
    return <Loader />;
  }

  /* console.log("Errors", errors); */
  // console.log("Touched", values);

  return (
    <Container maxWidth="xl">
      <form onSubmit={formik.handleSubmit} style={{ marginBottom: "40px" }}>
        <Stack
          direction="row"
        
          alignItems="center"
          justifyContent="space-between"
          mb={2}
          sx={{
            top: "64px",
            backdropFilter: "blur(6px)",
            padding: "10px 0",
          }}
        >
           <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            {!id || (id && clone) ? "Add" : "Edit"} Assets
          </Typography>
          

          <Stack direction="row" alignItems="center">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              save
            </LoadingButton>
            <Button
            className="back-button"
              variant="contained"
              component={RouterLink}
              to={`/assets/${encryption(assetId)}/view`}
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back
            </Button>
          </Stack>
          </div>
        </Stack>
        <Stack mt={2}>
          <Typography variant="h6" gutterBottom>
            {assetName}
          </Typography>
        </Stack>
        <Card className="Asset-grid-card gray-box Employee_Detail_Content" style={{ marginBottom: "15px" }}>
          <Container maxWidth="xl">
            <Grid container spacing={3} py={3}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Name"
                  name = "name"
                  {...getFieldProps("name")}
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 256 }}
                  error={Boolean(touched.name && errors.name)}
                  helperText={touched.name && errors.name}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.location && errors.location)}
                >
                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Location"
                    size="small"
                    name="location"
                    onChange={(event, newValue) => {
                      setFieldValue(`location`, newValue || "");
                    }}
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    value={values.location}
                    options={locationData || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        error={Boolean(touched.location && errors.location)}
                      />
                    )}
                  />
                  <FormHelperText>
                    {touched.location && errors.location}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={allEmployeeData || []}
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    {...getFieldProps("assetEmployee.employeeId")}
                    onChange={(event, newValue) => {
                      if (newValue == null) {
                        setFieldValue(`assetEmployee.employeeId`, null);
                      } else {
                        setFieldValue(
                          `assetEmployee.employeeId`,
                          newValue.id || ""
                        );
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onChange={(e) => {
                          if (!e.target.value) {
                            values.assetEmployee.employeeId = null;
                          }
                        }}
                        label="Employee"
                      />
                    )}
                    value={allEmployeeData.find(
                      (emp) => emp.id === values.assetEmployee.employeeId
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="IP"
                  {...getFieldProps("assetEmployee.ipAddress")}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  error={Boolean(
                    touched.assetEmployee?.ipAddress &&
                      errors.assetEmployee?.ipAddress
                  )}
                  helperText={
                    touched.assetEmployee?.ipAddress &&
                    errors.assetEmployee?.ipAddress
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Assign Date"
                    value={values.assetEmployee.assignDate}
                    inputFormat="dd/MM/yyyy"
                    maxDate={new Date()}
                    onChange={(newValue) => {
                      setFieldValue("assetEmployee.assignDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        autoComplete="off"
                        error={Boolean(
                          touched?.assetEmployee?.assignDate &&
                            errors?.assetEmployee?.assignDate
                        )}
                        helperText={
                          touched?.assetEmployee?.assignDate &&
                          errors?.assetEmployee?.assignDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={4}>
                <FormControl
                  fullWidth
                  error={Boolean(touched.location && errors.location)}
                >
                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Asset Status"
                    size="small"
                    name="assetStatus"
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    onChange={(event, newValue) => {
                      setFieldValue(`assetStatus`, newValue.id || "");
                    }}
                    options={statusDropDown || []}
                    getOptionLabel={(option) => option.title || ""}
                    value={statusDropDown.find((as) => as.id == values.assetStatus )}
                  
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Asset Status"
                        error={Boolean(
                          touched.assetStatus && errors.assetStatus
                        )}
                        helperText={
                          touched?.assetStatus &&
                          errors?.assetStatus
                        }
                      />
                    )}
                  />
                  {/* <FormHelperText>
                    {touched.assetStatus && errors.assetStatus}
                  </FormHelperText> */}
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                  <Autocomplete
                    multiple
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    defaultValue={[]}
                     options={assetTagDropdown || []}
                      key={assetTagDropdown || []}
                     getOptionLabel={(option) => option.name || ""}
                     isOptionEqualToValue={(option, value) => option.name === value.name}
                    {...getFieldProps(`assetTag`)}
                    onChange={(event, newValue) => {
                     
                     let tags = newValue?.map((t) => t?.inputValue ? {name : t?.inputValue , id : null  } : t )
                      
                      setFieldValue(
                        `assetTag`,
                        tags || ""
                      );
                    }}

                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.name
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          name : `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tags"
                      />
                    )}
                    value={
                        values?.assetTag || []
                   
                    }
                  />
                </FormControl>
              </Grid>
			  <Grid item xs={4}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Notes"
                  multiline
                  rows={3}
                  rowsmax={6}
                  name="assetNotes"
                  // inputProps={{ maxLength: 64 }}
                  autoComplete="off"
                  value={values.assetNotes }
                  onChange={handleChange}
                 
                />
              </Grid>
            </Grid>
          </Container>
        </Card>
        {submitCount > 0 && errors.minimumOnehardwarerror && (
          <div
            style={{ color: "#FF4842", fontSize: "0.75rem", fontWeight: 400 }}
          >
            Minimmum One Hardware Required.
          </div>
        )}
        <Card className="Asset-grid-card gray-box Employee_Detail_Content" style={{ paddingBottom: "40px" }}>
          {asset.assetItemMapping.map((itmasst, index) => {
            if (!itmasst.hasMany) {
              return (
                <MemoizedHasOneComponent
                  key={index}
                  title={itmasst.itemName}
                  isFocused={isFocused}
                  handleFocus={handleFocus}
                  isInventoriable={itmasst.itemMaster.isInventoriable}
                  getFieldProps={getFieldProps}
                  itemAttribute={itmasst.itemMaster.attributes}
                  required={itmasst.isRequired}
                  values={values.assets}
                  assetItemId={itmasst.itemId}
                  setFieldValue={setFieldValue}
                  getAttributteValuesById={getAttributteValuesById}
                  getAttributeLabel={getAttributeLabel}
                  assetErrors={errors.assets}
                  touchedAssets={touched.assets}
                  setErrors={setErrors}
                  errors={errors}
                  touched={touched}
                  handleBlur={handleBlur}
                  handleChange={handleChange}
                  assetId={assetId}
                />
               
              );
            }
            return (
              <MemoizedHasManyComponent
                key={index}
                title={itmasst.itemName}
                isFocused={isFocused}
                handleFocus={handleFocus}
                isInventoriable={itmasst.itemMaster.isInventoriable}
                getFieldProps={getFieldProps}
                itemAttribute={itmasst.itemMaster.attributes}
                handleItemValueChange={handleItemValueChange}
                assetItemId={itmasst.itemId}
                required={itmasst.isRequired}
                values={values.assets}
                addMore={addMore}    
                removeField={removeField}
                setFieldValue={setFieldValue}
                getAttributteValuesById={getAttributteValuesById}
                getAttributeLabel={getAttributeLabel}
                assetErrors={errors.assets}
                touchedAssets={touched.assets}
                setErrors={setErrors}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                assetId={assetId}
              />
            );
          })}
        </Card>
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddAsset,
    path: "/assets/:assetId/add",
    public: false,
    layout: DashboardLayout,
    group: "asset",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddAsset,
    path: "/assets/:assetId/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "asset",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
