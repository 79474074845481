import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
// import "./style.css";
import DashboardLayout from "../../../layouts/dashboard";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import RemoveIcon from "@mui/icons-material/Remove";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import Radio from "@mui/material/Radio";
import FormLabel from "@mui/material/FormLabel";
import { Divider, FormControlLabel, IconButton, Switch } from "@mui/material";
import RadioGroup from "@mui/material/RadioGroup";
import { decryption } from "../../../utils/encodeString";
import Loader from "../../../components/Loader";

import {
  getAllQuestionSectionWise,
} from "../../../redux/question/questionThunk";
import {
  getAllSections,
  getAllQuestions,
} from "../../../redux/question/questionThunk";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Downshift from "downshift";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { getDesignation } from "../../../redux/designation/designationThunk";
import { getTLandPMByDepartmentIds } from "../../../redux/employee/employeeThunk";
import evaluationSchema from "../../../validations/evaluationSchema";
import { createEvalution, evaluationById, updateEvaluation } from "../../../redux/evaluation/evaluationThunk";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

function AddEvaluation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  if (id) id = decryption(id);
  // console.log(id);
  const classes = useStyles();
  const sectionData = useSelector((state) => state.questions.sectionData);
  const loading = useSelector((state) => state.evaluation.loading);
  const designationData = useSelector((state) => state.designation);
  const questionsSectionWiseData = useSelector(
    (state) => state?.questions?.questionsSectionWiseData
  );
  // console.log("DESIGNATION", designationData.data);
  // console.log("SECTIONS", sectionData);
  // console.log("QUESTIONS", questionsSectionWiseData);

  useEffect(() => {
    dispatch(getDesignation({ sortBy: "", orderBy: "" }));
    dispatch(getAllSections({ sortBy: "", orderBy: "" }));
    dispatch(getAllQuestionSectionWise({}));
  }, []);
  useEffect(() => {
		if(id===-1){
			navigate("/evaluations");
		}
	}, [dispatch,id]);

  const formik = useFormik({
    initialValues: {
      title: "",
      designationId: [],
      isActive: false,
      sections: [
        {
          section: "",
          questions: [
            {
              question: "",
            },
          ],
        },
      ],
    },
    validationSchema: evaluationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {

        const addEvaluationObj={
          title : values?.title ,
          designationId : values?.designationId,
          isActive : values?.isActive ,
          sectionId :  values?.sections.map((section, index) => ({
            [section.section]: section?.questions.map((question) => question?.question),
            })),
        }
        // console.log("addEvaluationObj",addEvaluationObj)
        if (!id) {
          await dispatch(createEvalution(addEvaluationObj)).unwrap();
        }

        if (id) {
          await dispatch(
            // evaluationById({ ...values, question_id: id })
            updateEvaluation({editEvaluationData:addEvaluationObj,evaluation_id:id})
          ).unwrap();
        }
        navigate("/evaluations");
        resetForm();
      } catch (error) {
        return toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    handleChange,
    setValues,
    touched,
    errors,
    handleBlur,
  } = formik;

  useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let evaluationData = await dispatch(evaluationById(id)).unwrap();
          // console.log("evaluationById",evaluationData?.data)
          const convertedObject = {
            title: evaluationData?.data?.title,
            designationId:evaluationData?.data?.designationId?.map(designation=>  designation.id),
            isActive: evaluationData?.data?.isActive==1 ? true : false,
            sections: Object.entries(evaluationData?.data?.sectionId).map(([section, questions]) => ({
              section: section,
              questions: questions.map(question => ({
                question: question
              }))
            }))
          };
          // console.log("BODY___________________",convertedObject)
          setValues(convertedObject);
					// setLoading(false);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
            navigate(`/evaluations`);
        }
      }
      // setLoading(false);
    };
    fetchById();
  // }, [dispatch, setValues, id, navigate]);
  }, [dispatch,setValues, id, navigate]);




  const removeField = (e, index, index1, fieldName) => {
    e.preventDefault();
    if (fieldName === "sections") {
      const temp = [...values.sections];
      temp.splice(index, 1);
      setFieldValue("sections", temp);
    }
    if (fieldName === "cannedMessages") {
      const temp = [...values.cannedMessages];
      temp.splice(index, 1);
      setFieldValue("cannedMessages", temp);
    }
    if (fieldName === "questions") {
      const temp = [...values.sections[index].questions];
      temp.splice(index1, 1);
      setFieldValue(`sections[${index}].questions`, temp);
    }
  };

  // add fields dynamically on click of " + " button
  const addMore = (fieldName, index, index1) => {
    if (fieldName === "sections") {
      setFieldValue("sections", [
        ...values.sections,
        {
          section: "",
          questions: [
            {
              question: "",
            },
          ],
        },
      ]);
    }
    // if (fieldName === "cannedMessages") {
    //   setFieldValue("cannedMessages", [
    //     ...values.cannedMessages,
    //     {
    //       subject: "",
    //       isActive: false,
    //       template: "",
    //     },
    //   ]);
    // }
    if (fieldName === "questions") {
      setFieldValue(`sections[${index}].questions`, [
        ...values.sections[index].questions,
        {
          question: "",
        },
      ]);
    }
  };

  // console.log("ERRORS", errors);

  // console.log(designationData.data)

  // console.log("VALUES", values);
  const repeatQuestion = (fieldName, index, index1, sectionId) => {
    if (fieldName == "questions") {
      let QuestionsArray = [];
      questionsSectionWiseData?.[
        values?.sections?.[index]?.section
      ]?.questions?.map((question) => {
        // console.log("QUESTION", question);
        let selected = values?.sections?.[index]?.questions?.find(
          (selectedQuestion) => selectedQuestion?.question == question?.id
        );
        // console.log("selected", selected);
        if (!selected || question?.id == sectionId) {
          QuestionsArray.push(question);
          // console.log("QuestionsArray", QuestionsArray);
        }
      });
      // console.log(QuestionsArray);
      return QuestionsArray;
    }
    if (fieldName == "sections") {
      let newSections = [];
      sectionData?.map((section, index) => {
        let check = values?.sections?.find(
          (sec) => sec?.section == section?.id
        );
        // console.log("CHECK", check);
        // console.log("sectionId", sectionId);
        // console.log(
        //   "section?.id != sectionId__________",
        //   section?.id != sectionId
        // );
        if (!check || section?.id == sectionId) {
          // console.log("section", section);
          newSections.push(section);
        }
      });
      // console.log("newSections", newSections);
      return newSections || [];
    }
  };

  return (
    <Container maxWidth="xl">
      <form
        autoComplete="off"
        noValidate
        className="custom-space-layout AddEvaluation-Form"
        onSubmit={handleSubmit}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom mb={3}>
            {id ? "Edit Evaluation" : "Add Evaluation"}
          </Typography>
          <Stack className="employee-header-right" direction="row" alignItems="center">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button
              className="back-button"
              variant="contained"
              component={RouterLink}
              to="/evaluations"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Stack>
          </div>
        </Stack>
          {loading ? <Loader /> : (
        <Card>
          <Container maxWidth="xl">
            <Grid
              container
              spacing={3}
              py={3}
              // style={{ paddingBottom: "13px", paddingTop: "17px" }}
            >
              <Grid item xs={12}>
                <TextField
                  label="Evaluation Title"
                  variant="outlined"
                  size="small"
                  multiline
                  // inputProps={{
                  // 	// inputComponent: TextareaAutosize,
                  // 	maxLength: 512,
                  // 	// style: {
                  // 	// 	resize: "auto",
                  // 	// },
                  // }}
                  // value={values.title}
                  onChange={handleChange}
                  autoComplete="off"
                  {...getFieldProps("title")}
                  fullWidth
                  error={Boolean(touched?.title && errors?.title)}
                  helperText={touched?.title && errors?.title}
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  //   disabled={getViewVisible("department") === false}
                  size="small"
                  fullWidth
                  options={designationData.data || []}
                  key={designationData.data || []}
                  getOptionLabel={(option) => option.title || ""}
                  {...getFieldProps(`designationId`)}
                  onChange={(event, newValue) => {
                    let tempArray = newValue.map((x) => x.id);
                    setFieldValue("designationId", tempArray);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Designation"
                      name="designationId"
                      error={Boolean(
                        touched?.designationId && errors?.designationId
                      )}
                      helperText={
                        touched?.designationId && errors?.designationId
                      }
                    />
                  )}
                  value={designationData.data.filter((x) =>
                    values.designationId.includes(x.id)
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack className="checkbox-part">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values.isActive}
                          name="isActive"
                          onChange={handleChange}
                          inputProps={{
                            "aria-label": "controlled",
                            "data-cy": "chk-isActive-department",
                          }}
                        />
                      }
                      label="Status"
                    />
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Divider sx={{ my: 1 }} />
            {values &&
              values?.sections.map((sections, index) => (
                <Grid
                  container
                  spacing={3}
                  style={{ paddingTop: "10px",paddingBottom:"10px" }}
                >
                  {index != 0 && (
                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={
                        repeatQuestion(
                          "sections",
                          index,
                          null,
                          values.sections[index]?.section
                        ) || []
                      }
                      isOptionEqualToValue={(option, value) =>
                        option?.section == value?.section
                      }
                      getOptionLabel={(option) => option?.section || ""}
                      onChange={(event, newValue) => {
                        setFieldValue(
                          `sections[${index}].section`,
                          newValue?.id || ""
                        );
                        setFieldValue(`sections[${index}].questions`, [
                          {
                            question: "",
                          },
                        ]);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Section"
                          name={`sections[${index}].section`}
                          autoComplete="off"
                          error={Boolean(
                            touched.sections &&
                              touched.sections[index] &&
                              errors.sections &&
                              errors.sections[index]?.section
                          )}
                          helperText={
                            touched.sections &&
                            touched.sections[index] &&
                            errors.sections &&
                            errors.sections[index]?.section
                          }
                        />
                      )}
                      value={
                        sectionData?.find(
                          (x) => x.id == values.sections[index]?.section
                        ) ?? ""
                      }
                    />
                    {/* <TextField
                            id={`SectionsSection-${index}`}
                            autoComplete="off"
                            label="Section"
                            variant="outlined"
                            size="small"
                            name={`sections[${index}].section`}
                            inputProps={{
                              "data-cy": `txt-section-sections-${index}`,
                            }}
                            FormHelperTextProps={{
                              "data-cy": `txt-section-err-sections-${index}`,
                            }}
                            value={values.sections[index]?.section}
                            onChange={handleChange}
                            error={Boolean(
                              touched.sections &&
                                touched.sections[index] &&
                                errors.sections &&
                                errors.sections[index]?.section
                            )}
                            helperText={
                              touched.sections &&
                              touched.sections[index] &&
                              errors.sections &&
                              errors.sections[index]?.section
                            }
                            fullWidth
                          />*/}
                  </Grid>

                  <Grid item xs={4}>
                    {index + 1 === values.sections.length ? (
                      <Grid item xs={4}>
                        {values.sections.length !== 1 && (
                          <IconButton
                            onClick={(e) =>
                              removeField(e, index, null, "sections")
                            }
                          >
                            <RemoveIcon></RemoveIcon>
                          </IconButton>
                        )}
                        <IconButton
                          onClick={() => addMore("sections", index, null)}
                        >
                          <AddIcon></AddIcon>
                        </IconButton>
                      </Grid>
                    ) : (
                      <Grid item xs={2}>
                        <IconButton
                          onClick={(e) =>
                            removeField(e, index, null, "sections")
                          }
                        >
                          <RemoveIcon></RemoveIcon>
                        </IconButton>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {values &&
                      values?.sections[index]?.questions.map(
                        (questions, index1) => (
                          <Grid
                            container
                            spacing={3}
                            style={{ paddingLeft: "20px",paddingTop:'10px',paddingBottom:'10px' }}
                          >
                            <Grid item xs={6}>
                              {/* <TextField
                            id={`questionsQuestion-${index}`}
                            autoComplete="off"
                            label="Question"
                            variant="outlined"
                            size="small"
                            name={`sections[${index}].questions[${index1}].question`}
                            inputProps={{
                              "data-cy": `txt-question-questions-${index}`,
                            }}
                            FormHelperTextProps={{
                              "data-cy": `txt-question-err-questions-${index}`,
                            }}
                            value={values.sections[index]?.questions[index1]?.question}
                            onChange={handleChange}
                            error={Boolean(
                              touched?.sections?.[index]?.questions &&
                                touched?.sections?.[index]?.questions?.[index1]?.question &&
                                errors?.sections?.[index]?.questions &&
                                errors?.sections?.[index]?.questions[index1]?.question
                            )}
                            helperText={
                              touched.sections?.[index]?.questions &&
                              touched.sections?.[index]?.questions?.[index1] &&
                              errors.sections?.[index]?.questions &&
                              errors.sections?.[index]?.questions?.[index1]?.question
                            }
                            fullWidth
                          /> */}
                              <Autocomplete
                                size="small"
                                fullWidth
                                // options={questionsSectionWiseData?.[values?.sections?.[index]?.section]?.questions || []}
                                options={
                                  repeatQuestion(
                                    "questions",
                                    index,
                                    index1,
                                    values.sections[index]?.questions[index1]
                                        ?.question
                                  ) || []
                                }
                                isOptionEqualToValue={(option, value) =>
                                  option?.title == value?.title
                                }
                                getOptionLabel={(option) => option?.title || ""}
                                onChange={(event, newValue) => {
                                  setFieldValue(
                                    `sections[${index}].questions[${index1}].question`,
                                    newValue?.id || ""
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Question"
                                    name={`sections[${index}].questions[${index1}].question`}
                                    autoComplete="off"
                                    error={Boolean(
                                      touched?.sections?.[index]?.questions &&
                                        touched?.sections?.[index]?.questions?.[
                                          index1
                                        ]?.question &&
                                        errors?.sections?.[index]?.questions &&
                                        errors?.sections?.[index]?.questions[
                                          index1
                                        ]?.question
                                    )}
                                    helperText={
                                      touched.sections?.[index]?.questions &&
                                      touched.sections?.[index]?.questions?.[
                                        index1
                                      ] &&
                                      errors.sections?.[index]?.questions &&
                                      errors.sections?.[index]?.questions?.[
                                        index1
                                      ]?.question
                                    }
                                  />
                                )}
                                value={
                                  questionsSectionWiseData?.[
                                    values?.sections?.[index]?.section
                                  ]?.questions?.find(
                                    (x) =>
                                      x.id ==
                                      values.sections[index]?.questions[index1]
                                        ?.question
                                  ) ?? ""
                                }
                              />
                            </Grid>
                            <Grid item xs={4}>
                              {index1 + 1 ===
                              values.sections[index]?.questions.length ? (
                                <Grid item xs={4}>
                                  {values.sections[index]?.questions.length !==
                                    1 && (
                                    <IconButton
                                      onClick={(e) =>
                                        removeField(
                                          e,
                                          index,
                                          index1,
                                          "questions"
                                        )
                                      }
                                    >
                                      <RemoveIcon></RemoveIcon>
                                    </IconButton>
                                  )}
                                  <IconButton
                                    onClick={() =>
                                      addMore("questions", index, index1)
                                    }
                                  >
                                    <AddIcon></AddIcon>
                                  </IconButton>
                                </Grid>
                              ) : (
                                <Grid item xs={2}>
                                  <IconButton
                                    onClick={(e) =>
                                      removeField(e, index, index1, "questions")
                                    }
                                  >
                                    <RemoveIcon></RemoveIcon>
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </Grid>
                        )
                      )}
                  </Grid>
                </Grid>
              ))}
          </Container>
        </Card>
          )}
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddEvaluation,
    path: "/evaluations/add",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddEvaluation,
    path: "/evaluations/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
