import * as Yup from "yup";

const holidaySchema = Yup.object().shape({
	title: Yup.string()
		.trim("Title cannot contain only whitespace")
		.required("Title is required")
		.strict(true)
		.min(2, "Minimum 2 characters required")
		.max(256, "Maximum 256 characters required")
		.nullable(),
	startDate: Yup.date()
		.required("Start date is required")
		.typeError("Invalid Date"),
	quoteMessage:Yup.string().required("Message is required").nullable()
	// endDate: Yup.date()
	//   .min(Yup.ref("startDate"), "End date can't be before start date")
	//   .typeError("Invalid Date"),
});

export default holidaySchema;
