import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from 'react-router-dom';
import NiceModal from '@ebay/nice-modal-react';
import { useConfirm } from 'material-ui-confirm';

import DashboardLayout from '../../layouts/dashboard';
import Scrollbar from '../../components/Scrollbar';
import Loader from '../../components/Loader';
import { ExportCvModal } from './JobVacancy/ExportCvModal';

import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';

import { dateFormat } from '../../utils/dateFormat';
import { encryption } from '../../utils/encodeString';
import { deleteJobVacancy, getJobVacancies } from '../../redux/jobVacancy/jobVacancyThunks';
import { setLimit, setCurrentPage } from '../../redux/jobVacancy/jobVacancySlice.js';
import { toast } from 'react-toastify';

function JobVacancy() {
	const dispatch = useDispatch();
	const confirm = useConfirm();

	const { data, loading, limit, totalRecords, currentPage, sortBy, orderBy, status } =
		useSelector((state) => state.jobVacancy);

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage({ page: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
	};

	const handleDeleteJobVacancy = async (id, title) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${title}?`,
			});

			await dispatch(deleteJobVacancy(id)).unwrap();
			dispatch(getJobVacancies({ page: currentPage + 1, limit, orderBy, sortBy }));
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const openCvModal = async (keywords) => {
		const modalResponse = await NiceModal.show(ExportCvModal, { keywords: keywords });
	};

	useEffect(() => {
		if (status === 'idle') {
			dispatch(getJobVacancies({ page: currentPage + 1, limit, orderBy, sortBy }));
		}
	}, [currentPage, limit, status, orderBy, sortBy]);

	if (loading) {
		return <Loader />;
	}

	return (
		<Container maxWidth="xl">
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
				<Typography variant="h4">Job Vacancies</Typography>
				<Button
					variant="contained"
					component={RouterLink}
					to="/job-vacancy/add"
					startIcon={<AddIcon />}
				>
					Add Job vacany
				</Button>
			</Stack>

			<Card mb={5} className="jobsTable">
				<Scrollbar>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left">Job Title</TableCell>
									<TableCell align="left">Department</TableCell>
									<TableCell align="left">Start Date</TableCell>
									<TableCell align="left">Expire Date</TableCell>
									<TableCell align="center">Job Type</TableCell>
									<TableCell align="center">Status</TableCell>
									<TableCell align="center">Applicants</TableCell>
									<TableCell align="center">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{data.length === 0 ? (
									<TableRow>
										<TableCell className='No-Record-text' align="center" colSpan={12}>
										No Record(s) Found
										</TableCell>
									</TableRow>
								) : (
									data.map((d) => {
										return (
											<TableRow key={d.id}>
												<TableCell>{d.jobTitle}</TableCell>
												<TableCell>{d?.departments?.title}</TableCell>
												<TableCell>{dateFormat(d.startDate)}</TableCell>
												<TableCell>{dateFormat(d.expireDate)}</TableCell>
												<TableCell align="center">{d.jobType}</TableCell>
												<TableCell align="center">
													<Chip
														label={d.status}
														size="small"
														color={
															d.status === 'closed'
																? 'error'
																: 'success'
														}
														variant="outlined"
													/>
												</TableCell>

												<TableCell align="center">
													{d.applicants} Candidates
												</TableCell>

												<TableCell align="center">
													<div className="action-button">
														<IconButton
															component={Link}
															to={`/job-vacancy/add/${encryption(
																d.id
															)}`}
														>
															<EditIcon color="primary" />
														</IconButton>
														<IconButton
															onClick={() =>
																handleDeleteJobVacancy(
																	d.id,
																	d.jobTitle
																)
															}
														>
															<DeleteIcon color="error" />
														</IconButton>
														<IconButton
															onClick={() =>
																openCvModal(
																	JSON.parse(
																		d.jobdescription.keywords
																	)
																)
															}
														>
															<DocumentScannerIcon />
														</IconButton>
													</div>
												</TableCell>
											</TableRow>
										);
									})
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Scrollbar>

				<TablePagination
					rowsPerPageOptions={[5, 25]}
					component="div"
					count={totalRecords}
					rowsPerPage={limit}
					page={currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Card>
		</Container>
	);
}

const componentConfig = {
	component: JobVacancy,
	path: '/job-vacancy',
	public: false,
	layout: DashboardLayout,
	group: 'candidate',
	sidebar: true,
	role: ['admin'],
};

export default componentConfig;
