import React from "react";
import DashboardLayout from "../../../layouts/dashboard";

function AddPageMater() {
    return <h1>Add</h1>;
}

const componentConfig = [
    {
        component: AddPageMater,
        path: "/address-master/add",
        public: false,
        layout: DashboardLayout,
        group: "CMS",
        sidebar: true,
        role: ["admin"],
    },
    {
        component: AddPageMater,
        path: "/address-master/add/:id",
        public: false,
        layout: DashboardLayout,
        group: "organization",
        sidebar: true,
        role: ["admin"],
    },
];

export default componentConfig;
