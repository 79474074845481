import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";



export const getFAQs = createAsyncThunk(
	//action type string
	"faqs/getFAQs",
	// callback function
	async ( { rejectWithValue }) => {
		try {
			const res = await Axios.get("/getFAQs",{
			headers:{
				type:'view',module:'faqs'
			}
			});
			// console.log("EDITED_RES__",res.data)
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const getFAQById = createAsyncThunk(
	//action type string
	"faqs/getFAQById",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.get("/getFAQById",{
			headers:{
				faqCategoryId:id,type:'view',module:'faqs'
			}
			});
			// console.log("EDITED_RES__",res.data)
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
	);

	export const updateFAQ = createAsyncThunk(
		//action type string
		"faqs/updateFAQ",
		// callback function
		async (body,{ rejectWithValue }) => {
			try {
				// console.log("_____body____",body)
				const res = await Axios.put("/updateFAQ", body?.editFAQsData, {
				headers:{
					faqCategoryId:body?.faqCategoryId,type:'update',module:'faqs'
				}
				});
				// console.log("EDITED_RES__",res.data)
				toast.success(res.data.message);
				return res.data;
			} catch (error) {
				return rejectWithValue(error?.response?.data?.message || error.message);
			}
		},
	);

export const addFAQ = createAsyncThunk(
	//action type string
	"faqs/addFAQ",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/addFAQ", params, {
			headers:{
				type:'create',module:'faqs'
			}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);


export const deleteFAQ = createAsyncThunk(
	//action type string
	"faqs/deleteFAQ",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.delete("/deleteFAQ", {
			headers:{
				faqCategoryId:id,type:'delete',module:'faqs'
			}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
export const getEvalutionCount = createAsyncThunk(
	//action type string
	"evaluation/getEvalutionCount",
	// callback function
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/getEvalutionCount", {
			headers:{
				type:'view',module:'evaluations'
			}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);
