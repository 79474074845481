import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getAllparkingAllocation = createAsyncThunk(
  //action type string
  "parkingAllocation/getAllparking",
  // callback function
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getAllParkingAllocation", {
        headers: {
          type: "view",
          module: "parkingAllocation",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);

export const gethavecarEmployee = createAsyncThunk(
  //action type string
  "parkingAllocation/getcarEmployee",
  // callback function
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getEmployeeDropdownForParkingAllocation", {
        headers: {
          type: "view",
          module: "parkingAllocation",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);

export const createparkingAllocation = createAsyncThunk(
  //action type string
  "parkingAllocation/setparking",
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.post("/allocateOrReallocateParking", body, {
        headers: {
          type: "create",
          module: "parkingAllocation",
        },
      });
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

export const removeparkingAllocation = createAsyncThunk(
  //action type string
  "parkingAllocation/resetparking",
  // callback function
  async (body, { rejectWithValue }) => {
    try {
      const res = await Axios.delete("/removeParkingAllocation", {
        headers: {
          type: "delete",
          module: "parkingAllocation",
          ...body,
        },
      });
      toast.success(res.data.message);
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);


export const exportAllparkingAllocation = createAsyncThunk(
  //action type string
  "parkingAllocation/exportarking",
  // callback function
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/exportParkingAndVehicleDetails", {
        headers: {
          type: "view",
          module: "parkingAllocation",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);

export const getAllparkingDetailsForFile = createAsyncThunk(
  //action type string
  "parkingAllocation/getAllparkingDetailsForFile",
  // callback function
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/parkingAndVehicleDetails", {
        headers: {
          type: "view",
          module: "parkingAllocation",
        },
      });
      return res.data;
    } catch (error) {
      return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);