import * as Yup from "yup";

const jdScheduleInterviewSchema = Yup.object().shape({
  interviewDetails: Yup.array().of(
    Yup.object().shape({
      interviewrefrences: Yup.array().min(
        1,
        "Minimum one interviewer is required"
      ),
      interviewType: Yup.string()
        .required("Interview Type is required")
        .nullable(),
      // interviewMode: Yup.string()
      //   .required("Interview Mode is required")
      //   .nullable(),
      interviewDate: Yup.date()
        .required("Interview Date is required")
        .typeError("Invalid Date"),
      interviewTime: Yup.string()
        .required("Interview Time is required")
        .test("", "Invalid Interview Time",  (value) => {
            if(value == "Invalid Date"){
              return false;
            }
            else{
              return true
            }
          }
        )
        .nullable(),
      duration: Yup.number()
        .required("Interview duration is required")
        .nullable(),
      technologies: Yup.string().required("Technology is required").nullable(),
      comments: Yup.string().nullable(),
    })
  ),
});

export default jdScheduleInterviewSchema;
