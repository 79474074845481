import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import "./style.css";
import DashboardLayout from '../../../layouts/dashboard';
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import DeleteIcon from "@mui/icons-material/Delete";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {IconButton} from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import Loader from "../../../components/Loader";
import { decryption } from "../../../utils/encodeString";
import AddIcon from "@mui/icons-material/Add";
import {
  getAllModule,
} from "../../../redux/userPermission/userPermissionthunk";
import { addExitQuestion, updateExitQuestion, getExitQuestionById, deleteExitOptions} from "../../../redux/exitQuestion/exitQuestionThunk";
import { makeStyles } from "@material-ui/core/styles";
import exitQuestionSchema from "../../../validations/exitQuestionSchema";
import { ElectricalServicesOutlined } from "@mui/icons-material";

function AddExitQuestion() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	let { id } = useParams();
  if (id) id = decryption(id);
	const [loading, setLoading] = useState(true);
	const [ isDelete, setIsDelete] = useState(false)
	const [deletedId, setDeletedId] = useState([]);
	
	useEffect(() => {
		if(id===-1){
			navigate("/exitQuestions");
		}
	}, [dispatch,id]);
	const formik = useFormik({
    initialValues: {
			question: "",
			exitinterviewoptions: [
				{
					options: "",
				}
			],
    },
		validationSchema: exitQuestionSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
				if (!id) {
					let response = await dispatch(addExitQuestion(values)).unwrap();
					if (response.status === 200) {
						resetForm();
						navigate("/exitQuestions");
					}
				}
				if(id) {
					if(isDelete)
					{
						let temp = values?.exitinterviewoptions?.map((d) => { return d?.id})
						let a = deletedId?.filter((element) => !temp?.includes(element));
						await dispatch(deleteExitOptions(a)).unwrap()
						.then(async (res) => {
							if(res?.success)
							{
								await dispatch(updateExitQuestion({id, values})).unwrap();
							}
						})
						.catch((err) => {

						});
					}
					else{
						await dispatch(updateExitQuestion({id, values})).unwrap();
					}
				}
				setIsDelete(false)
				navigate("/exitQuestions");
				resetForm();
      } catch (error) {
        // return toast.error(error.message);
      }
    },
  });

	const {
		handleSubmit,
		values,
		setFieldValue,
		getFieldProps,
		isSubmitting,
		handleChange,
		setValues,
		touched,
		errors,
		handleBlur
	} = formik;

	useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let record = await dispatch(getExitQuestionById(id)).unwrap();
          let body1 = {
						id: record?.data?.id,
            question: record?.data?.question,
						exitinterviewoptions: record?.data?.exitinterviewoptions,
          };
          setValues(body1);
					setDeletedId(body1?.exitinterviewoptions?.map((d) => { return d.id}))
		  		setLoading(false);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
            navigate(`/exitQuestions`);
        }
      }
      setLoading(false);
    };
    fetchById();
  }, [dispatch, setValues, id, navigate]);

	const AddOption = () => {
    setFieldValue("exitinterviewoptions", [...values.exitinterviewoptions, {
      options: "",
			questionId: values?.exitinterviewoptions[0]?.questionId,
    }]);
  };

	const removeOption = async (e, index) => {
    e.preventDefault();
		if(id)
		{
			setIsDelete(true)
		}
    const temp = [...values.exitinterviewoptions];
    temp.splice(index, 1);
    setFieldValue("exitinterviewoptions", temp);
  };
	
	if (loading) {
		return <Loader />;
	}
	
	// console.log(values)
	return (
		<Container maxWidth="xl">
			<form autoComplete="off" noValidate className="custom-space-layout" onSubmit={handleSubmit}>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={3}
				>
				<div className="title-button-wrapper">
					<Typography variant="h4" gutterBottom mb={3}>
						{id ? "Edit Exit Question" : "Add Exit Question" }
					</Typography>
					<Stack direction="row" alignItems="center">
						<LoadingButton
							loading={isSubmitting}
							type="submit"
							variant="contained"
						>
							Save
						</LoadingButton>
						<Button
							variant="contained"
							component={RouterLink}
							to="/exitQuestions"
							startIcon={<ArrowBackIcon />}
							style={{ marginLeft: "10px" }}
						>
							Back To List
						</Button>
					</Stack>
					</div>
				</Stack>
				<Card>
					<Container maxWidth="xl">
						<Grid
							container
							spacing={3}
							py={3}
							style={{ paddingBottom: "13px", paddingTop: "17px" }}
						>
								<Grid item xs={10}>
									<TextField
										label="Question Title"
										variant="outlined"
										size="small"
										multiline
										minRows={2}
										maxRows={5}
										inputProps={{
											// inputComponent: TextareaAutosize,
											maxLength: 512,
											// style: {
											// 	resize: "auto",
											// },
										}}
										// value={values.title}
										onChange={handleChange}
										autoComplete="off"
										{...getFieldProps("question")}
										fullWidth
										error={Boolean(touched?.question && errors?.question)}
										helperText={touched?.question && errors?.question}
									/>
								</Grid>
								<Grid item xs={10} mt={0} mb={2}>
									<Stack direction="row" justifyContent="space-between">
										<Typography variant="h5" gutterBottom>
										Options
										</Typography>
										{values &&
											values.exitinterviewoptions &&
											values.exitinterviewoptions[0] ? 
											<Button
												variant="contained"
												sx={{height: 'fit-content'}}
												onClick={AddOption}
												startIcon={<AddIcon />}
											> 
											Add Option
										</Button>:''}
										</Stack>
								</Grid>
								{values &&
									values.exitinterviewoptions &&
									values.exitinterviewoptions.map((edu, index) => (
									<>
                  <Grid item xs={10} mt={0} mb={2}>
                  	<TextField
                        fullWidth
                        autoComplete="off"
                        label="Option"
                        name={`exitinterviewoptions[${index}].options`}
                        value={values.exitinterviewoptions[index]?.options}
                        onChange={handleChange}
                        variant="outlined"
                        size="small"
                        onBlur={handleBlur}
                        error={Boolean(touched?.exitinterviewoptions &&
                          touched?.exitinterviewoptions[index] &&
                          touched?.exitinterviewoptions[index]?.options &&
                          errors?.exitinterviewoptions &&
                          errors?.exitinterviewoptions[index] &&
                          errors?.exitinterviewoptions[index]?.options)}
                        helperText={touched?.exitinterviewoptions &&
                          touched?.exitinterviewoptions[index] &&
                          touched?.exitinterviewoptions[index]?.options &&
                          errors?.exitinterviewoptions &&
                          errors?.exitinterviewoptions[index] &&
                          errors?.exitinterviewoptions[index]?.options}
                      />
                  </Grid>
									{index!==0&&(<IconButton  onClick={(e) => removeOption(e, index)}>
                          <DeleteIcon color="error" />
                        </IconButton>)}
									</>))
                }
								
								</Grid>
					</Container>
				</Card>
			</form>
		</Container>
	);
}

const componentConfig = [
  {
    component: AddExitQuestion,
    path: "/exitQuestions/add",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddExitQuestion,
    path: "/exitQuestions/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "evaluation",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
