import * as React from "react";
import { useRef, useEffect } from "react";
import { useConfirm } from "material-ui-confirm";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import invertDirection from "../../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";

import {
	getActivity,
	insert,
	updateById,
	deleteById,
} from "../../../redux/activity/activityThunk";
import { activity } from "../../../validations/mastersSchema";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../utils/userPermission";
import { setSortBy, setOrderBy } from "../../../redux/activity/activitySlice";

const ActivityStatus = () => {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const data = useSelector((state) => state.activity.data);
	const loading = useSelector((state) => state.activity.loading);
	const error = useSelector((state) => state.activity.error);
	const sortBy = useSelector((state) => state.activity.sortBy);
	const orderBy = useSelector((state) => state.activity.orderBy);
	const [id, setId] = React.useState(null);
	const ref = useRef(null);

	// if (!getViewVisible("master")) {
	// 	navigate("/dashboard");
	// }

	const {
		errors,
		setErrors,
		touched,
		handleSubmit,
		setValues,
		values,
		handleChange,
		isSubmitting,
	} = useFormik({
		initialValues: {
			name: "",
			isActive: false,
		},
		validationSchema: activity,
		onSubmit: async (values, { resetForm }) => {
			try {
				if (id) {
					await dispatch(updateById({ ...values, id })).unwrap();
				}
				if (!id) {
					await dispatch(insert(values)).unwrap();
				}
				resetForm();
				dispatch(getActivity());
				return setId(null);
			} catch (error) {
				toast.error(error.message);
			}
		},
	});

	useEffect(() => {
		if (
			!loading &&
			!error &&
			(!data || (Array.isArray(data) && data.length <= 0))
		)
			dispatch(getActivity());
	}, [loading]);

	useEffect(() => {
			dispatch(
				getActivity({
					sortBy,
					orderBy,
				}),
			);
	}, [dispatch,sortBy, orderBy]);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (ref.current && !ref.current.contains(event.target)) {
				setErrors({});
			}
		};
		document.addEventListener("click", handleClickOutside, true);
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, [ref]);

	const handleUpdate = (data) => {
		setValues({
			name: data.name,
			isActive: data.isActive,
		});
		setId(data.id);
	};

	const handleDelete = async (id, name) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${name}?`,
			});
			await dispatch(deleteById(id));
			await dispatch(getActivity());
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleToggle = async (activity) => {
		try {
			await dispatch(
				updateById({
					id: activity.id,
					name: activity.name,
					isActive: !activity.isActive,
				}),
			).unwrap();
			dispatch(getActivity());
		} catch (error) {
			toast.error(error.message);
		}
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

	return (
		<>
			<Card
				className="inner-component"
				style={{
					boxShadow: "initial !important",
					padding: "0 !important",
					borderRadius: "0",
					boxShadow: "0 0 2px 0 #fff, 0 16px 32px -4px rgb(145 158 171 / 0%)",
				}}
			>
				<Container
					className="custom-container-grid Activity-Status-Master"
					style={{
						boxShadow: "initial !important",
						paddingLeft: "0",
						paddingRight: "0",
						minWidth: "100%",
						minHeight: "70%",
					}}
				>
					<div className="header">
						<form
							ref={ref}
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
						>
							<h6 className="component-name">ACTIVITY STATUS MASTER</h6>

							{getAddVisible("master") && (
								<div className="heading-part">
									<div className="input-field-part">
										<TextField
											label="Enter Activity"
											variant="outlined"
											size="small"
											name="name"
											value={values.name}
											onChange={handleChange}
											error={Boolean(touched.name && errors.name)}
											helperText={touched.name && errors.name}
											inputProps={{ maxLength: 64 }}
										/>
									</div>
									<div className="checkbox-part">
										<FormControlLabel
											control={
												<Checkbox
													checked={values.isActive}
													name="isActive"
													onChange={handleChange}
												/>
											}
											label="Status"
										/>
									</div>
									<div className="button-part">
										<LoadingButton
											size="small"
											type="submit"
											variant="contained"
											loading={isSubmitting}
										>
											save
										</LoadingButton>
									</div>
								</div>
							)}
						</form>
					</div>
					<TableContainer component={Paper}>
						
							<Table className="Activity-Status-table grid-table" stickyHeader aria-label="sticky table">
								<TableHead>
									<TableRow style={{ borderRadius: "0" }}>
										{/* <TableCell width="10%">ID</TableCell> */}
										<TableCell width="67%">
											<TableSortLabel
												active={sortBy === "name"}
												direction={sortBy === "name" ? orderBy : "asc"}
												onClick={() => handleSorting("name")}
											>
												Title
											</TableSortLabel>
										</TableCell>
										<TableCell width="10%" align="center">
											Status
										</TableCell>
										{(getEditVisible("master") ||
											getDeleteVisible("master")) && (
											<TableCell
												width="13%"
												align="right"
												className="master-actions action-field"
											>
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								{loading ? (
									<Loader />
									) : (
								<TableBody>
									{Array.isArray(data) && data.length === 0 ? (
										<TableRow>
											<TableCell className="No-Record-text" align="center" colSpan={3}>
											No Record(s) Found
											</TableCell>
										</TableRow>
									) : (
										data.map((activity, idx) => (
											<TableRow
												key={idx}
												sx={{
													"&:last-child td, &:last-child th": {
														border: 0,
													},
												}}
											>
												{/* <TableCell component="th" scope="row">
                        {activity.id}
                      </TableCell> */}
												<TableCell component="td" scope="row">
													{activity.name}
												</TableCell>
												<TableCell align="right">
													<Switch
														checked={activity.isActive}
														onChange={() => handleToggle(activity)}
														inputProps={{ "aria-label": "controlled" }}
														size="small"
													/>
												</TableCell>
												{(getEditVisible("master") ||
													getDeleteVisible("master")) && (
													<TableCell className="td-action-button" align="center">
														<div className="action-button">
															{getEditVisible("master") && (
																<IconButton
																	onClick={() => handleUpdate(activity)}
																>
																	{/* <EditIcon color="primary" /> */}
																	<img src="assets/images/edit.svg" title="Edit" />
																</IconButton>
															)}
															{getDeleteVisible("master") && (
																<IconButton
																	onClick={() =>
																		handleDelete(activity.id, activity.name)
																	}
																>
																	{/* <DeleteIcon color="error" /> */}
																	<img src="assets/images/trash-gray.svg" title="trash" />
																</IconButton>
															)}
														</div>
													</TableCell>
												)}
											</TableRow>
										))
									)}
								</TableBody>
								)}
							</Table>
					</TableContainer>
				</Container>
			</Card>
		</>
	);
};

export default ActivityStatus;
