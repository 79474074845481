import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import FormHelperText from "@mui/material/FormHelperText";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import LoadingButton from "@mui/lab/LoadingButton";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
	getViewVisible,
	getAddVisible,
} from "../../utils/userPermission";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import { addSalarySlip } from "../../redux/salarySlip/salarySlipThunk";
import salarySlipSchema from "../../validations/salarySlipSchema";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import { encryption } from "../../utils/encodeString";

function SalarySlip() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [salarySlip, setSalarySlip] = useState(null);
	const formatData = [{value: "one-by-one", title: "One By One"},{value: "single", title: "Single"}];
	let [dateDropdown, setDateDropdown] = useState([]);
	const monthData = [
		{
			value: "January",
			name: "January",
		},
		{
			value: "February",
			name: "February",
		},
		{
			value: "March",
			name: "March",
		},
		{
			value: "April",
			name: "April",
		},
		{
			value: "May",
			name: "May",
		},
		{
			value: "June",
			name: "June",
		},
		{
			value: "July",
			name: "July",
		},
		{
			value: "August",
			name: "August",
		},
		{
			value: "September",
			name: "September",
		},
		{
			value: "October",
			name: "October",
		},
		{
			value: "November",
			name: "November",
		},
		{
			value: "December",
			name: "December",
		},
	];
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("salarySlip")) {
			navigate("/dashboard");
		}
	}, []);

	useEffect(() => {
		let currentYear = new Date().getFullYear();
		let earliestYear = 2021;     
		let dateOption = []
		let temp = []
		while (currentYear >= earliestYear) {
			dateOption.push(currentYear)
			currentYear -= 1;   
		}
		dateOption.map((x) => {
			temp = [...temp, { text: x, value: x }];
		})
		setDateDropdown(temp)
	}, []);
	
	const {
		currentPage,
		status,
		limit,
		sortBy,
		orderBy,
		loading,
	} = useSelector((state) => state.emailTemplateDynamic);

	const formik = useFormik({
		initialValues: {
			type: "",
			month: "",
			salarySlip: null,
			year: "",
		},
		validationSchema: salarySlipSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				const formData = new FormData();
				formData.append("type", values.type);
            formData.append("month", values.month);
				formData.append("salarySlip", values.salarySlip);
				formData.append("year", values.year);
				let res = await dispatch(addSalarySlip(formData)).unwrap();
				if (res.status === 200) {
					resetForm();
					setValues({type: "", month:"", salarySlip:null, year:""});
					setSalarySlip(null);
				}
			} catch (error) {
				toast.error(error.message);
			}
		},
	});
	const {
		errors,
		values,
		touched,
		handleSubmit,
		setFieldValue,
		isSubmitting,
		getFieldProps,
		setValues,
	} = formik;

	const excelFileInput = useRef(null);

	const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["xlsx", "xls", "png"];
      return imageTypes.includes(file.name.split(".")[1]);
    }
    return file && file["type"] && file["type"].split("/")[0] === "image";
  };

	const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };

	const handleDeleteFile = (image) => {
    setSalarySlip(null);
  };

	const handleEmployeeResumeImageChange = (e) => {
    setSalarySlip(e.target.files[0]);
		setFieldValue("salarySlip", e.target.files[0])
  };

	return (
		<Container maxWidth="xl">
		<div className="title-button-wrapper">
			<Typography variant="h4" gutterBottom mb={3}>
					Salary Slip
				</Typography>
				</div>
				{getAddVisible("salarySlip") && (
				<Card>
					<Container maxWidth="xl">
						<form
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
							className="custom-space-layout"
						>
							<Grid
								container
								spacing={3}
								py={3}
								style={{ paddingBottom: "13px", paddingTop: "17px" }}
							>
								<Grid item xs={4}>
									<Autocomplete
										size="small"
										fullWidth
										options={formatData || []}
										getOptionLabel={(option) => option?.title || ""}
										onChange={(event, newValue) => {
											setFieldValue('type', newValue?.value || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Type"
												autoComplete="off"
												error={Boolean(touched.type && errors.type)}
												helperText={touched.type && errors.type}
											/>
										)}

										value={
											formatData.find((x) => x.value === values.type) ??
											""
										}
									/>
								</Grid>
								<Grid item xs={4}>
									<Autocomplete
										size="small"
										fullWidth
										options={monthData || []}
										key={monthData || []}
										getOptionLabel={(option) => option?.name || ""}
										{...getFieldProps('month')}
										onChange={(event, newValue) => {
											setFieldValue('month', newValue?.value || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Month"
												autoComplete="off"
												error={Boolean(touched.month && errors.month)}
												helperText={touched.month && errors.month}
											/>
										)}

										value={
											monthData.find((x) => x.value === values.month) ??
											""
										}
									/>
								</Grid>
								<Grid item xs={4}>
									<Autocomplete
										size="small"
										fullWidth
										options={dateDropdown || []}
										getOptionLabel={(option) => option?.text || ""}
										onChange={(event, newValue) => {
											setFieldValue('year', newValue?.value || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Year"
												autoComplete="off"
												error={Boolean(touched.year && errors.year)}
												helperText={touched.year && errors.year}
											/>
										)}

										value={
											dateDropdown?.find((x) => x.value === values.year) ??
											""
										}
									/>
								</Grid>
								<Grid item xs={4}>
									<div
										className="custome-uploadBox small profile-image-part"
										style={{ position: "relative" }}
										onClick={(e) => {
											excelFileInput.current.click();
										}}>
										{loading?'':salarySlip && (
											<>
												<InsertDriveFileIcon
													onClick={(e) => {
														window.open(
															salarySlip.webViewLink,
															"_blank"
														);
													}}
												/>
												<Typography variant="caption">
													{getFileName(salarySlip)}
												</Typography>
											</>
										)}
										{loading?<Loader />:salarySlip && (
											<CancelRoundedIcon
												onClick={(e) => {
													e.stopPropagation();
													handleDeleteFile(
														salarySlip
													);
												}}
												className="closeIcon"
												style={{
													position: "absolute",
													top: "-7px",
													right: "-6px",
												}}
											/>
											)}
										<div className="p-image">
											{!salarySlip && (
												<i className="upload-button">
													<CloudUploadIcon />
												</i>
											)} 

											{!salarySlip && (
												<input
													ref={excelFileInput}
													className="file-upload"
													type="file"
													accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
													onChange={handleEmployeeResumeImageChange}
												/>
											)}
											{!salarySlip && (
												<>
													<div className="file-support-text">
														Upload File
													</div>
													<div className="file-support-text">
														(File support: Excel File)
													</div>
												</>
											)}
										</div>
									
									</div>
									<FormHelperText
										error={Boolean(
											touched?.salarySlip && errors?.salarySlip,
										)}
									>
										{touched?.salarySlip && errors?.salarySlip}
									</FormHelperText>
								</Grid>
								<Grid item xs={6}></Grid>
								</Grid>
								<Stack direction="row" justifyContent="flex-end" my={3}>
									<LoadingButton
										type="submit"
										variant="contained"
										loading={isSubmitting}
										data-cy="btn-submit-job-description"
									>
										Submit
									</LoadingButton>
								</Stack>
							{/* </Grid> */}
						</form>
					</Container>
				</Card>
			)}
		</Container>
	);
}

const componentConfig = {
	component: SalarySlip,
	path: "/salarySlip",
	public: false,
	layout: DashboardLayout,
	group: "users",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;

