import React, { useEffect, useCallback, useRef, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce, forEach } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import * as Yup from 'yup';
import Switch from "@mui/material/Switch";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import AddIcon from "@mui/icons-material/Add";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import SearchIcon from "@mui/icons-material/Search";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Autocomplete from "@mui/material/Autocomplete";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CachedIcon from "@mui/icons-material/Cached";
import LoadingButton from "@mui/lab/LoadingButton";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Tooltip from "@mui/material/Tooltip";

import { getDesignation } from "../../redux/designation/designationThunk";
import { getDepartment } from "../../redux/department/departmentThunk";
import { getHoliday } from "../../redux/holiday/holidayThunk";
import { getTLandPMByDepartmentIds } from "../../redux/employee/employeeThunk";
import { yearFormat } from "../../utils/dateFormat";
import {
  getEmployee,
  getTotalEmployeeCount,
  exportEmployeeFile,
  backoutEmployeeById,
  exportEmployeeFileAll,
  getEmployeeTechnology,
} from "../../redux/employee/employeeThunk";
import {
  setPage,
  setHasMore,
  setData,
  setNewData,
  setOrderBy,
  setSortBy,
} from "../../redux/employee/employeeSlice";
import { BASE_URL } from "../../constants";
import { encryption } from "../../utils/encodeString";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import {
  List,
  ListItem,
  ListItemText,
  Modal,
  Paper,
  TextareaAutosize,
} from "@mui/material";
import moment from "moment";
import { ImportEmployee } from "./EmployeeComponents/AddEmployeeComponent/importEmployee.js";
import LocalStorage from "../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../redux/userPermission/userPermissionthunk";
import { indianDateFormat } from "../../utils/dateFormat";
import invertDirection from "../../utils/invertDirection";
import { FaSortAlphaDown, FaSortAlphaDownAlt } from "react-icons/fa";
import { getTeamDropdown } from "../../redux/team/teamThunk/index.js";
import {
  getEmployeeForDropDown,
  getAllPM,
} from "../../redux/employee/employeeThunk";
import { getCityData } from "../../utils/getLocationData";
import { getTechnology } from "../../redux/technology/technologyThunk";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getCollege } from "../../redux/college/collegeThunk/index.js";

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: 2,
  p: 4,
};

function Employee() {
  const observer = useRef();
  const [isSearching, setIsSearching] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const AuthRole = useSelector((state) => state.auth.role);
  let data = useSelector((state) => state.employee.data);
  const limit = useSelector((state) => state.employee.limit);
  const page = useSelector((state) => state.employee.page);
  const status = useSelector((state) => state?.employee?.status);
  const error = useSelector((state) => state.employee?.error);
  const sortBy = useSelector((state) => state.employee.sortBy);
  const orderBy = useSelector((state) => state.employee.orderBy);
  const totalCount = useSelector((state) => state.employee.totalCount);
  const onlyEmployeeFilterTotalCount = useSelector((state) => state.employee.onlyEmployeeFilterTotalCount);
  const loading = useSelector((state) => state.employee.loading);
  const hasMore = useSelector((state) => state.employee.hasMore);
  const newData = useSelector((state) => state.employee.newData);
  const holidayData = useSelector((state) => state.holiday.data);
  const sortByHoliday = useSelector((state) => state.holiday.sortBy);
  const orderByHoliday = useSelector((state) => state.holiday.orderBy);
  const teamData = useSelector((state) => state.team.teamDropdown);
  const sortByTeam = useSelector((state) => state.team.sortBy);
  const orderByTeam = useSelector((state) => state.team.orderBy);
  const navigate = useNavigate();
  const isSearchQuery = useSelector((state) => state.employee.isSearchQuery);
  //designation & department
  const designation = useSelector((state) => state.designation);
  const [scrollApiCall, setScrollApiCall] = useState(false);
  const [filter, setFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [filterChips, setFilterChips] = useState([]);
  const [commitment, setCommitment] = useState();
  const [emp, setEmp] = useState(null);
  const params = location.state;
  const inputRef = React.useRef(null);
  const totalEmployeeCount = useSelector(
    (state) => state.employeeCount.totalCount
  );
  const department = useSelector((state) => state.department);
  const [noteError, setNoteError] = useState({ flag: false, title: "" });
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = useState();
  const [cities, setCities] = useState([]);
  const [employeeFilterSearchBtn, setEmployeeFilterSearchBtn] = useState(false)
  const allEmployeeData = useSelector(
    (state) => state.employee.allEmployeeData
  );

  // console.log("employeeFilterSearchBtn",employeeFilterSearchBtn)

  // console.log("allEmployeeData", allEmployeeData)
  const [allPMData, setAllPMData] = useState([]);
  const locationDropdown = [
    { title: "HOME", id: "HOME" },
    { title: "OFFICE", id: "OFFICE" },
    { title: "HYBRID", id: "HYBRID" },
  ];
  const genderDropdown = [
    { title: "MALE", id: "M" },
    { title: "FEMALE", id: "F" },
    { title: "OTHER", id: "O" },
  ];
  const technologyData = useSelector((state) => state.technology);
  const appraisalCycleData = ["July", "By Production Date"];
  const sortByData = [
    { title: "Ascending(By Name)", value: "asc" },
    { title: "Descending(By Name)", value: "desc" },
    { title: "By Joining Date", value: "joining" },
  ];

  const info = {
    title: "When you back out an employee, the following changes will happen:",
    description: [
      "All employee-related details will be deleted.",
      `The candidate's status will be updated to Drop and state to Back-Out.`,
      `This action will also be stored in the candidate's activity logs.`,
    ],
  };

  const commitmentDuration = ["0", "1", "1.6"];

  const [employeeData, setEmployeeData] = useState([]);
  const [sort, setsort] = React.useState("");
  // const [years, setYears] = useState(["2000","2001","2002","2003","2004","2005","2006","2007",
  //                                     "2008","2009","2010","2011","2012","2013","2014","2015",
  //                                   "2016","2017","2018","2019","2020","2021","2022","2023"]);

  const [TLandPM, setTLandPM] = useState({});
  const [technologyForIcon, setTechnologyForIcon] = useState([]);
  const college = useSelector((state) => state.college);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  const handleSorting = (field) => {
    if (field == "asc" || field == "desc") {
      dispatch(setSortBy({ sortBy: "firstName" }));
      dispatch(setOrderBy({ orderBy: field }));
    } else {
      dispatch(setSortBy({ sortBy: "" }));
      dispatch(setOrderBy({ orderBy: "" }));
    }
  };

  useEffect(() => {
    getCityData("Gujarat")
      .then((cities) => {
        setCities(cities);
        // setpermanentCities(cities);
      })
      .catch((err) => {
        setCities([]);
      });
  }, [dispatch]);

  const setTotalExpTime = (data) => {
    let experience = {
      totalExpYears: 0,
      totalExpMonths: 0,
      totalCCCYears: 0,
      totalCCCMonths: 0,
    };

    let experienceArr = data?.experience;
    // console.log("DATAA",experienceArr)
    let cccExperienceArr = data?.workDetails;
    let totalYears = experience?.totalExpYears;
    let totalMonths = experience?.totalExpMonths;
    let tempCCCYears = experience?.totalCCCYears;
    let tempCCCMonths = experience?.totalCCCMonths;

    if (experienceArr?.length > 0) {
      for (let i = 0; i < experienceArr.length; i++) {
        let start = moment(experienceArr[i].from);
        let end = moment(
          experienceArr[i].to ? experienceArr[i].to : new Date()
        );
        let cccstart = start;
        let cccend = end;

        let floored = cccend.diff(cccstart, "years");
        cccstart.add(floored, "years");

        let finalValue = cccend.diff(cccstart, "months");
        cccstart.add(finalValue, "months");

        let days = cccend.diff(cccstart, "days");
        let dateFrom = moment(experienceArr[i].from);
        let dateTo = moment(experienceArr[i].to);

        let dateProducation = moment(cccExperienceArr?.productionDate);
        let dateReliving = moment(cccExperienceArr?.relievingDate);

        if (experienceArr[i]?.company?.name === "Cybercom Creation") {
          if (
            dateFrom.isSame(dateProducation, "month") &&
            dateFrom.isSame(dateProducation, "year") &&
            dateTo.isSame(dateReliving, "month") &&
            dateTo.isSame(dateReliving, "year")
          ) {
            tempCCCYears = tempCCCYears;
            tempCCCMonths = tempCCCMonths;
          } else {
            tempCCCYears = tempCCCYears + floored;
            tempCCCMonths = tempCCCMonths + finalValue;
          }

          if (tempCCCMonths >= 12) {
            tempCCCMonths = tempCCCMonths - 12;
            tempCCCYears = tempCCCYears + 1;
          }
        } else {
          totalYears = totalYears + floored;
          totalMonths = totalMonths + finalValue;
          if (totalMonths >= 12) {
            totalMonths = totalMonths - 12;
            totalYears = totalYears + 1;
          }
        }
      }

      // console.log("tempCCCYears", tempCCCYears, "tempCCCMonths", tempCCCMonths);
      // console.log("totalYears", totalYears, "totalMonths", totalMonths);

      if (
        moment(new Date()).diff(
          moment(cccExperienceArr?.productionDate),
          "days"
        ) > 0
      ) {
        let cccstart = moment(new Date(cccExperienceArr?.productionDate));
        let cccend = moment(
          cccExperienceArr?.relievingDate
            ? new Date(cccExperienceArr?.relievingDate)
            : new Date()
        );

        let cccyearCount = cccend.diff(cccstart, "years");
        cccstart.add(cccyearCount, "years");

        let cccmonthsCount = cccend.diff(cccstart, "months");
        cccstart.add(cccmonthsCount, "months");

        tempCCCYears = tempCCCYears + cccyearCount;
        tempCCCMonths = tempCCCMonths + cccmonthsCount;
      }

      let overallYears = totalYears + tempCCCYears;
      let overallMonths = totalMonths + tempCCCMonths;

      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      if (totalMonths >= 12) {
        totalMonths = totalMonths - 12;
        totalYears = totalYears + 1;
      }

      if (tempCCCMonths >= 12) {
        tempCCCMonths = tempCCCMonths - 12;
        tempCCCYears = tempCCCYears + 1;
      }

      experience = {
        ...experience,
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      };
    } else {
      if (
        moment(new Date()).diff(
          moment(cccExperienceArr?.productionDate),
          "days"
        ) > 0
      ) {
        let cccstart = moment(cccExperienceArr?.productionDate);
        let cccend = moment(
          cccExperienceArr?.relievingDate
            ? cccExperienceArr?.relievingDate
            : new Date()
        );

        let cccyearCount = cccend.diff(cccstart, "years");
        cccstart.add(cccyearCount, "years");

        let cccmonthsCount = cccend.diff(cccstart, "months");
        cccstart.add(cccmonthsCount, "months");

        tempCCCYears = tempCCCYears + cccyearCount;
        tempCCCMonths = tempCCCMonths + cccmonthsCount;
      }

      let overallYears = totalYears + tempCCCYears;
      let overallMonths = totalMonths + tempCCCMonths;

      if (overallMonths >= 12) {
        overallMonths = overallMonths - 12;
        overallYears = overallYears + 1;
      }

      if (totalMonths >= 12) {
        totalMonths = totalMonths - 12;
        totalYears = totalYears + 1;
      }

      if (tempCCCMonths >= 12) {
        tempCCCMonths = tempCCCMonths - 12;
        tempCCCYears = tempCCCYears + 1;
      }
      experience = {
        ...experience,
        overallMonths: overallMonths,
        overallYears: overallYears,
        totalExpYears: totalYears,
        totalExpMonths: totalMonths,
        totalCCCYears: tempCCCYears,
        totalCCCMonths: tempCCCMonths,
      };
    }
    let exp_str = "";
    if (experience?.totalCCCYears && experience?.totalCCCMonths) {
      exp_str =
        exp_str +
        `${experience?.totalCCCYears}.${experience?.totalCCCMonths} Yrs `;
    }
    if (!experience?.totalCCCYears && experience?.totalCCCMonths) {
      exp_str = exp_str + `0.${experience?.totalCCCMonths} Yrs `;
    }
    if (experience?.totalCCCYears && !experience?.totalCCCMonths) {
      exp_str = exp_str + `${experience?.totalCCCYears} Yrs `;
    }
    if (!experience?.totalCCCYears && !experience?.totalCCCMonths) {
      exp_str = exp_str + `0.0 Yrs `;
    }
    exp_str = exp_str + '<div class="total-experience">';
    exp_str = exp_str + " ";
    if (experience?.overallYears && experience?.overallMonths) {
      exp_str =
        exp_str +
        `${experience?.overallYears}.${experience?.overallMonths} Yrs`;
    }
    if (!experience?.overallYears && experience?.overallMonths) {
      exp_str = exp_str + `0.${experience?.overallMonths} Yrs`;
    }
    if (experience?.overallYears && !experience?.overallMonths) {
      exp_str = exp_str + `${experience?.overallYears} Yrs`;
    }
    if (!experience.overallYears && !experience.overallMonths) {
      exp_str = exp_str + `0.0 Yrs`;
    }

    exp_str = exp_str + " ";
    exp_str = exp_str + "</div>";
    // console.log('EXPPPP',exp_str);

    // return exp_str
    return <div dangerouslySetInnerHTML={{ __html: exp_str }} />;
  };

  const handleOnDepartmentChange = async (value) => {
    try {
      let body = [value?.id];
      const response = await dispatch(getTLandPMByDepartmentIds(body)).unwrap();
      setTLandPM({ tl: response.teamLead, pm: response.projectManager });
    } catch (error) {
      setTLandPM({ tl: [], pm: [] });
    }
  };

  // useEffect(() => {
  //   dispatch(getEmployeeForDropDown({ status: "employee" }));
  // }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(getAllPM({})).unwrap();
        setAllPMData(res?.projectManager || []);
      } catch (error) {
        // Handle error (e.g., log the error or show a notification)
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
    dispatch(getCollege({ search: "" }));
  }, [dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedData = localStorage.getItem("employeeData");
        if (storedData) {
          setEmployeeData(JSON.parse(storedData));
        } else {
          const res = await dispatch(
            getEmployeeForDropDown({ status: "employee" })
          );
          setEmployeeData(res?.payload?.data);
          localStorage.setItem(
            "employeeData",
            JSON.stringify(res?.payload?.data)
          );
        }
      } catch (error) {
        console.error("Error fetching employee data:", error);
      }
    };

    fetchData();
  }, [dispatch]);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        if (!getViewVisible("employee")) {
          await dispatch(
            getEmployee({
              limit,
              page: page,
              ...params,
              scrollApiCall: scrollApiCall,
              filter: filter,
              ...filterData,
              orderBy:
                values?.sort === "asc" || values?.sort === "desc"
                  ? values?.sort
                  : orderBy,
              sortBy:
                values?.sort === "asc" || values?.sort === "desc"
                  ? "firstName"
                  : sortBy,
            })
          );
          navigate("/dashboard");
        }
      } catch (error) {
        // Handle the error if needed
        console.error(error);
      }
    };

    fetchEmployeeData();
  }, []);

  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setScrollApiCall(true);
          dispatch(setPage({ page: page + 1 }));
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    if (getViewVisible("holiday")) {
      dispatch(
        getHoliday({
          sortByHoliday,
          orderByHoliday,
        })
      );
    }
    // }else{
    // 	console.log('permition denide');
    // }
  }, [dispatch]);

  useEffect(() => {
    // if (getViewVisible("team")) {
    dispatch(
      getTeamDropdown({
        sortByTeam,
        orderByTeam,
      })
    );
    // }
  }, [dispatch]);

  useEffect(() => {
    dispatch(
      getEmployee({
        limit,
        page: page,
        ...params,
        scrollApiCall: scrollApiCall,
        filter: filter,
        ...filterData,
        // orderBy,
        // sortBy: sortBy,
        orderBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? values?.sort
            : orderBy,
        sortBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? "firstName"
            : sortBy,
      })
    );
  }, [page, params]);

  useEffect(() => {
    dispatch(
      getEmployee({
        limit,
        page: 1,
        ...params,
        scrollApiCall: scrollApiCall,
        filter: filter,
        ...filterData,
        // orderBy,
        // sortBy: sortBy,
        orderBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? values?.sort
            : orderBy,
        sortBy:
          values?.sort == "asc" || values?.sort == "desc"
            ? "firstName"
            : sortBy,
      })
    );
  }, [sortBy, orderBy]);

  useEffect(() => {
    return () => {
      setScrollApiCall(false);
      dispatch(setHasMore(true));
      dispatch(setPage({ page: 1 }));
      dispatch(setData([]));
    };
  }, []);

  useEffect(() => {
    if (!error) {
      dispatch(getTotalEmployeeCount({}));
    }
  }, [error, dispatch]);

  // Experience validation schema
  const validationSchema = Yup.object().shape({
    expFromYear: Yup.string()
      .nullable()
      .test('is-valid-year', 'Fractional part should be between 0 to 11', (value) => {
        if (!value) return true;
        const [wholePart, fractionalPart] = value?.toString()?.split('.');
        if (fractionalPart && (fractionalPart < 0 || fractionalPart > 11)) {
          return false;
        }
        return true;
      }),

    expToYear: Yup.string()
      .nullable()
      .test('is-valid-year', 'Fractional part should be between 0 to 11', (value) => {
        if (!value) return true;
        const [wholePart, fractionalPart] = value?.toString()?.split('.');
        if (fractionalPart && (fractionalPart < 0 || fractionalPart > 11)) {
          return false;
        }
        return true;
      })
      .test('is-greater', 'Exp. To must be greater than Exp. From', function (value) {
        if (!value || !this.parent.expFromYear) return true;
        const { expFromYear } = this.parent;

        // Extract whole number and fractional part (months)
        const [fromWhole, fromFraction] = expFromYear.toString().split('.').map(Number);
        const [toWhole, toFraction] = value.toString().split('.').map(Number);

        const fromMonths = fromFraction || 0;
        const toMonths = toFraction || 0;

        // Calculate total months for both values
        const fromTotalMonths = fromWhole * 12 + fromMonths;
        const toTotalMonths = toWhole * 12 + toMonths;

        // Ensure Exp. To is greater than Exp. From
        return toTotalMonths >= fromTotalMonths;
      })
  });
  

  const {
    handleSubmit,
    values,
    dirty,
    setValues,
    handleChange,
    resetForm,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    handleBlur,
  } = useFormik({
    initialValues: {
      designationId: "",
      departmentId: "",
      search: "",
      teamId: "",
      teamLead: "",
      workLocation: null,
      city: "",
      gender: "",
      collegeId: "",
      underPF: null,
      isUnderServiceAgreement: null,
      note: "",
      technologyId: "",
      underESIC: null,
      appraisalCycle: "",
      retentionBonus: "",
      collegeYear: "",
      projectManager: "",
      commitmentperiod: "",
      sort: "",
      joiningYear:"",
      expFromYear:'',
      expToYear:''
    },
    validationSchema:validationSchema,
    onSubmit: async (values) => {
      try {
        // if(values?.sort == "asc" || values?.sort == "desc"){
        //   dispatch(setSortBy({ sortBy: "firstName" }));
        //   dispatch(setOrderBy({orderBy: values?.sort }));
        // }
        // else{
        //   dispatch(setSortBy({ sortBy: "" }));
        //   dispatch(setOrderBy({orderBy: "" }));
        // }

        if (
          values.search === "" &&
          values.designationId === "" &&
          values.departmentId === "" &&
          values.teamId === "" &&
          values.teamLead === "" &&
          values.workLocation === null &&
          values.city === "" &&
          values.gender === "" &&
          values.underPF === null &&
          values.isUnderServiceAgreement === null &&
          values.technologyId === "" &&
          values.underESIC === null &&
          values.appraisalCycle === "" &&
          values.retentionBonus === "" &&
          values.collegeYear === "" &&
          values.commitmentperiod === "" &&
          values.projectManager === "" &&
          values.collegeId === "" && 
          values.joiningYear === "" &&
          values.expFromYear === "" &&
          values.expToYear === ""
        ) {
          setFilter(false);
        } else {
          setFilter(true);
        }
        setFilterData({ ...values });
        await dispatch(
          getEmployee({
            ...values,
            // ...params,
            limit,
            page:1,
            scrollApiCall: scrollApiCall,
            filter:
              values.search === "" &&
              values.designationId === "" &&
              values.departmentId === "" &&
              values.teamId === "" &&
              values.teamLead === "" &&
              values.collegeId === "" &&
              values.workLocation === null &&
              values.city === "" &&
              values.gender === "" &&
              values.underPF === null &&
              values.isUnderServiceAgreement === null &&
              values.technologyId === "" &&
              values.underESIC === null &&
              values.appraisalCycle === "" &&
              values.retentionBonus === "" &&
              values.collegeYear === "" &&
              values.projectManager === "" && 
              values.joiningYear === "" &&
              values.expFromYear === "" &&
              values.expToYear === "" &&
              values.commitmentperiod === ""
                ? false
                : true,
            newData: true,
            // activeEmployees: true,
            orderBy:
              values?.sort == "asc" || values?.sort == "desc"
                ? values?.sort
                : orderBy,
            sortBy:
              values?.sort == "asc" || values?.sort == "desc"
                ? "firstName"
                : sortBy,
          })
        );
        setState({ ...state, right: false });
        // console.log(values);
        prepareChips(values);
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });
  // Key Down Event Handler
  function validateKeyDown(event, type) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Home', 'End', '.'];
    const inputValue = event.target.value;

    if (type === 'integer') {
      // Check if the key is allowed (numeric, or in the allowed keys)
      if (!allowedKeys.includes(event.key) && (isNaN(Number(event.key)) || event.key === ' ')) {
        event.preventDefault();
      }
      // Prevent entering more than one dot (Prevent another dot)
      if (event.key === '.' && inputValue?.includes('.')) {
        event.preventDefault();
      }
    }
  }

  // console.log("VALLLLL",values)
  // console.log("errors",errors)
  // console.log("dirty",dirty)
  // console.log("touched",touched)

  const handleCancel = () => {
    handleClose();
    setFieldValue("note", "");
    setNoteError({ flag: false, title: "" });
  };

  const ordinal_suffix_of = (i) => {
    var j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return i + "st";
    }
    if (j === 2 && k !== 12) {
      return i + "nd";
    }
    if (j === 3 && k !== 13) {
      return i + "rd";
    }
    return i + "th";
  };

  const handleBackout2 = async (noteValue, id) => {
    if (noteValue == "") {
      setNoteError({ flag: true, title: "Please enter notes" });
    }
    if (noteValue != "") {
      setNoteError({ flag: false, title: "" });
    }
    if (noteValue != "") {
      try {
        dispatch(
          backoutEmployeeById({
            id: id,
            note: noteValue,
          })
        ).unwrap();
        setFieldValue("note", "");

        var index = data.findIndex((item) => item.id == id);
        let arrayforsort = [...data];
        if (index > -1) {
          arrayforsort.splice(index, 1);
        }
        data = arrayforsort;

        dispatch(setNewData({ data: arrayforsort }));
        await dispatch(getTotalEmployeeCount({}));
        await dispatch(
          getEmployee({
            limit,
            page: 1,
            scrollApiCall: scrollApiCall,
            filter: false,
            newData: true,
            orderBy,
            sortBy: sortBy,
          })
        );
        setState({ ...state, right: false });
        setFilter(false);
        setFilterData({});
        setFilterChips([]);
        setEmp(null);
        inputRef.current.value = "";
      } catch (error) {
        toast.error(error?.message);
      }

      handleClose();
    }
  };

  const handleBackout = async (id) => {
    setEmp(id);
    handleOpen();
    // try {
    // 	await dispatch(backoutEmployeeById(id)).unwrap();
    // 	var index = data.findIndex(item => item.id == id)
    // 	let arrayforsort = [...data];
    // 	if (index > -1) {
    // 		arrayforsort.splice(index, 1);
    // 	}
    // 	data = arrayforsort

    // 	dispatch(setNewData({ data: arrayforsort }));
    // 	dispatch(getTotalEmployeeCount({}));
    // 	dispatch(
    // 		getEmployee({
    // 			limit,
    // 			page: 1,
    // 			scrollApiCall: scrollApiCall,
    // 			filter: false,
    // 			newData: true,
    // 			orderBy,
    // 			sortBy:sortBy,
    // 		}),
    // 	);
    // 	setState({ ...state, right: false });
    // 	setFilter(false);
    // 	setFilterData({});
    // 	setFilterChips([]);
    // 	inputRef.current.value = "";
    // 	}
    // catch (error) {
    // 	toast.error(error?.message);
    // }
  };

  //fetching designation
  useEffect(() => {
    // if (
    // 	!designation.loading &&
    // 	!designation.error &&
    // 	(!designation.data ||
    // 		(Array.isArray(designation.data) && designation.data.length <= 0))
    // )
    dispatch(getDesignation({ sortBy: "", orderBy: "" }));
  }, [dispatch]);
  //fetching department
  useEffect(() => {
    getViewVisible("department") && dispatch(getDepartment());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTechnology({}));
  }, [dispatch]);

  useEffect(() => {
    let d = [];
    data.map((employee) => {
      if (
        employee?.workDetails?.commitmentStartDate &&
        employee?.workDetails?.bondDuration
      ) {
        let newDate = new Date(employee?.workDetails?.commitmentStartDate);
        let n = Number(employee?.workDetails?.bondDuration);
        if (Number.isInteger(n) == false) {
          let a = employee?.workDetails?.bondDuration.split(".");
          if (a[0] == "1") {
            newDate.setMonth(newDate.getMonth() + 12);
          }
          newDate.setMonth(newDate.getMonth() + Number(a[1]));
        } else {
          newDate.setFullYear(newDate.getFullYear() + n);
        }
        if (newDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0)) {
          d = [...d, { id: employee?.id, commit: true }];
        }
      }
    });
    setCommitment(d);
  }, [data]);

  // sidebar-part functionality
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    console.log("<<<<",event)
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
    setEmployeeFilterSearchBtn(false)
  };

  const handleReset = () => {
    resetForm();
    setTechnologyForIcon([]);
    dispatch(
      getEmployee({
        limit,
        page: 1,
        ...params,
        scrollApiCall: scrollApiCall,
        filter: false,
        newData: true,
        orderBy,
        sortBy: sortBy,
      })
    );
    setState({ ...state, right: false });
    setFilter(false);
    setFilterData({});
    setFilterChips([]);
  };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    handleSubmit();
    setIsSearching(true);
    // await dispatch(
    // 	getEmployee({
    // 		search: e?.target?.value,
    // 		...params,
    // 		...filterData,
    // 		limit,
    // 		page: 1,
    // 		newData: true,
    // 		scrollApiCall: scrollApiCall,
    // 		orderBy,
    // 		sortBy,
    // 	}),
    // );
  };

  const prepareChips = (values) => {
    let filters = { ...values };
    let tempChips = [];

    for (const key in filters) {
      if (filters[key] && key === "designationId") {
        const res = designation.data.find(
          (designation) => designation.id === filters[key]
        );
        if (res) filters[key] = `Designation : ${res.title}`;
      }

      if (filters[key] && key === "departmentId") {
        const res = department.data.find(
          (department) => department.id === filters[key]
        );
        if (res) filters[key] = `Department : ${res.title}`;
      }

      if (filters[key] && key === "teamId") {
        const res = teamData.find((team) => team.id === filters[key]);
        if (res) filters[key] = `Team : ${res.title}`;
      }
      if (filters[key] && key === "teamLead") {
        const res = employeeData?.find((team) => team.id === filters[key]);
        if (res) filters[key] = `Team Lead : ${res.firstName} ${res.lastName}`;
      }
      if (filters[key] && key === "collegeId") {
        //console.log(filters[key]);
        const res = college.data.find((college) => college.id === filters[key]);
        if (res) filters[key] = `College : ${res?.collegeName}`;
      }
      if (filters[key] && key === "projectManager") {
        const res = allPMData?.find((team) => team.id === filters[key]);
        if (res)
          filters[key] = `Project Manager : ${res.firstName} ${res.lastName}`;
      }
      if (filters[key] && key === "city") {
        const res = cities?.find((c) => c === filters[key]);
        if (res) filters[key] = `City : ${res}`;
      }

      if (filters[key] && key === "workLocation") {
        const res = locationDropdown?.find((w) => w.id === filters[key]);
        if (res) filters[key] = `Work Location : ${res.title}`;
      }

      if (filters[key] && key === "gender") {
        const res = genderDropdown.find((w) => w.id === filters[key]);
        if (res) filters[key] = `Gender : ${res.title}`;
      }

      if (filters[key] && key === "technologyId") {
        const res = technologyData.data.find((t) => t.id === filters[key]);
        if (res) filters[key] = `Technology : ${res.title}`;
      }
      if (filters[key] && key === "retentionBonus") {
        // console.log(filters[key]);
        filters[key] =
          filters[key] === 1 ? `Retention Bonus : Yes` : `Retention Bonus : No`;
      }

      // let d = new Date(values.collegeYear).getFullYear()
      if (filters[key] && key === "collegeYear") {
        filters[key] = `College Year : ${values.collegeYear}`;
      }

      if (filters[key] && key === "search") {
        filters[key] = `${values.search}`;
      }
      if (filters[key] && key === "appraisalCycle") {
        const res = appraisalCycleData.find((w) => w === filters[key]);
        if (res) filters[key] = `Appraisal Cycle : ${res}`;
      }
      if (filters[key] && key === "commitmentperiod") {
        const res = commitmentDuration.find((w) => w === filters[key]);
        if (res) filters[key] = `Commitment Period : ${res}`;
      }

      if (filters[key] && key === "isUnderServiceAgreement") {
        filters[key] =
          filters[key] === 1
            ? `Under Service Agreement : Yes`
            : `Under Service Agreement : No`;
      }

      if (filters[key] && key === "underPF") {
        filters[key] = filters[key] === 1 ? `Under PF : Yes` : `Under PF : No`;
      }

      if (filters[key] && key === "underESIC") {
        // console.log(filters[key]);
        filters[key] =
          filters[key] === 1 ? `Under ESIC : Yes` : `Under ESIC : No`;
      }

      if (filters[key] && key === "sort") {
        const res = sortByData?.find((t) => t.value === filters[key]);
        if (res) filters[key] = `Sort : ${res.title}`;
      }
      if (filters[key] && key === "joiningYear") {
        filters[key] = `Joining Year : ${values.joiningYear}`;
      }

      if (filters[key] && key === "expFromYear") {
        filters[key] = `Exp. From (year) : ${values.expFromYear}`;
      }

      if (filters[key] && key === "expToYear") {
        filters[key] = `Exp. To (year) : ${values.expToYear}`;
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

    setFilterChips(tempChips);
  };

  const onDeleteChip = (value, length) => {
    let field = value?.type;
    setFieldValue(field, "");
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    setFieldValue(value.type, "");
    let filters = { ...filterData, [value["type"]]: "" };

    setFilterData(filters);
    if (length == 1) {
      dispatch(
        getEmployee({
          limit,
          page: 1,
          ...params,
          newData: true,
          scrollApiCall: scrollApiCall,
          // orderBy,
          // sortBy: sortBy,
          orderBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? values?.sort
              : orderBy,
          sortBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? "firstName"
              : sortBy,
        })
      );
    } else {
      dispatch(
        getEmployee({
          limit,
          page: 1,
          ...params,
          newData: true,
          scrollApiCall: scrollApiCall,
          filter: filter,
          ...filters,
          // orderBy,
          // sortBy: sortBy,
          orderBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? values?.sort
              : orderBy,
          sortBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? "firstName"
              : sortBy,
        })
      );
    }
    if (value.type === "search") {
      // setSearchValue("");
      inputRef.current.value = "";
    }
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const checkBirthDay = (date) => {
    var today = moment(new Date()).format("MM-DD");
    var birthDate = moment(date).format("MM-DD");

    if (today === birthDate) {
      return true;
    }

    return false;
  };

  const checkResignationAcceptanceDate = (
    resignationAcceptanceDate,
    reJoiningDate
  ) => {
    if (
      resignationAcceptanceDate &&
      resignationAcceptanceDate !== "" &&
      params?.reliving != 1 &&
      !moment(reJoiningDate).isAfter(resignationAcceptanceDate)
    ) {
      return "Employee-card-header onNotice";
    }

    return "Employee-card-header";
  };

  const openExcelModal = async () => {
    const modalResponse = await NiceModal.show(ImportEmployee);
    // console.log(modalResponse);
    if (modalResponse?.status === 200) {
      dispatch(
        getEmployee({
          limit,
          page: 1,
          ...params,
          scrollApiCall: scrollApiCall,
          filter: false,
          newData: true,
          // orderBy,
          // sortBy: sortBy,
          orderBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? values?.sort
              : orderBy,
          sortBy:
            values?.sort == "asc" || values?.sort == "desc"
              ? "firstName"
              : sortBy,
        })
      );
    }
  };

  useEffect(() => {
    const fetchTechnologyData = async () => {
      try {
        if (data?.length) {
          const idArray = data.map((emp) => emp.id);
          const res = await dispatch(getEmployeeTechnology(idArray)).unwrap();

          if (res?.success && Array.isArray(res?.data)) {
            setTechnologyForIcon(res.data);
            // If you need to merge previous state, uncomment the next line
            // setTechnologyForIcon(prevState => [...prevState, ...res.data]);
          }
        }
      } catch (error) {
        console.error("Failed to fetch technology data:", error);
        // Handle the error appropriately
      }
    };

    fetchTechnologyData();
  }, [data]);

  const exportFileAll = async () => {
    try {
      const response = await dispatch(
        exportEmployeeFileAll({ status: "employee" })
      )
        .unwrap()
        .then((response) => {
          const url = `${BASE_URL}/${response.data}`;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const exportFile = async () => {
    try {
      const response = await dispatch(
        exportEmployeeFile({ status: "employee", ...filterData })
      )
        .unwrap()
        .then((response) => {
          const url = `${BASE_URL}/${response.data}`;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  function isSameDay(date1, date2) {
    return (
      date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear()
    );
  }
  const handleShowBackout = (employee) => {
    var todaysDate = new Date();
    var dt = new Date(employee?.createdDate);
    dt.setDate(dt.getDate());

    if (
      new Date(employee?.createdDate).setHours(0, 0, 0, 0) ==
      new Date().setHours(0, 0, 0, 0)
    ) {
      return true;
    }

    if (dt.getDay() == 6 || dt.getDay() == 0) {
      var sat = new Date(employee?.createdDate);
      var sun = new Date(employee?.createdDate);
      sun.setDate(sun.getDate() + 1);
      sat.setDate(sat.getDate() + 2);
      // console.log()
      if (
        (todaysDate?.getDay() === sat.getDay() && isSameDay(todaysDate, sat)) ||
        (todaysDate?.getDay() === sun.getDay() && isSameDay(todaysDate, sun))
      ) {
        console.log("C______________01");
        return true;
      }
    }

    holidayData.map((h) => {
      var holiday = new Date(h?.startDate);
      var todaysDate = new Date();

      if (
        holiday.setHours(0, 0, 0, 0) ==
        new Date(employee.createdDate).setHours(0, 0, 0, 0)
      ) {
        holiday.setDate(holiday.getDate() + 1);
        if (todaysDate.getDay() == holiday.getDay()) {
          return true;
        }
      }
    });

    return false;
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
    } catch (err) {
      toast.error("Text Not Copied!");
    }

    document.body.removeChild(textArea);
  };

  const handleFilters = async (field, newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
    // await dispatch(
    //   	getEmployee({
    //   		// field: newValue,
    //   		...params,
    //   		...filterData,
    //   		limit,
    //   		page: 1,
    //   		newData: true,
    //   		scrollApiCall: scrollApiCall,
    //   		orderBy,
    //   		sortBy,
    //   	}),
    //   );
    setIsSearching(true);
  };

  // console.log("technologyForIcon", technologyForIcon)
  // console.log("Data_________________________01", data)

  const showWorkAnniversary = (employee) => {
    let producationDate = employee?.workDetails?.productionDate;
    return (
      new Date(producationDate).getDate() == new Date().getDate() &&
      new Date(producationDate).getMonth() == new Date().getMonth() &&
      new Date(producationDate).getFullYear() != new Date().getFullYear() && (
        <p className="Work-Anniversary-tab">
          {`${ordinal_suffix_of(
            new Date().getFullYear() - new Date(producationDate).getFullYear()
          )} Work Anniversary`}
        </p>
      )
    );
  };

  const countExpTech = (id) => {
    if (
      technologyForIcon &&
      Array.isArray(technologyForIcon) &&
      technologyForIcon.length > 0
    ) {
      let EmpExp = technologyForIcon?.find((emp) => emp.id === id);
      if (EmpExp) return setTotalExpTime(EmpExp);
    }
  };

  return (
    <>
      <Container className="Employee-wrapper" maxWidth="xl">
        <Stack
          className="Employee-wrapper-detail"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <div className="title-button-wrapper">
            <Typography variant="h4" gutterBottom>
              Employees ({totalEmployeeCount})
              {/* <Typography variant="body2">
            Total Records : {totalEmployeeCount}
          </Typography> */}
            </Typography>

            <Stack
              className="employee-header-right"
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
            >
              {/* <IconButton
            className="filter-icon-part ascending-order-icon"
            sx={{ backgroundColor: "#fda92d" }}
            onClick={() => handleSorting("firstName")}
          >
            {/* <SortByAlphaIcon/> */}
              {/* {orderBy == "desc" ? <FaSortAlphaDownAlt /> : <FaSortAlphaDown />}
          </IconButton>  */}

              {AuthRole === "Super Admin" ? (
                <>
                  <Button
                    variant="contained"
                    onClick={() => openExcelModal()}
                    // startIcon={<FileDownloadSharpIcon />}
                    className="filter-icon-part"
                    title="Import Employees"
                  >
                    <img src="/assets/images/import.svg" />
                    Import
                  </Button>
                  <Button
                    variant="contained"
                    onClick={exportFile}
                    // startIcon={<FileUploadSharpIcon />}
                    className="filter-icon-part"
                    title="Export Employees"
                  >
                    <img src="/assets/images/export.svg" />
                    Export
                  </Button>
                  <Button
                    variant="contained"
                    onClick={exportFileAll}
                    // startIcon={<FileUploadSharpIcon />}
                    className="filter-icon-part"
                    title="Export all employees"
                  >
                    <img src="/assets/images/export.svg" />
                    Export All
                  </Button>
                </>
              ) : (
                ""
              )}

              {getAddVisible("employee") && (
                <Button
                  component={RouterLink}
                  to="/employee/add"
                  variant="contained"
                  // startIcon={<AddIcon />}
                  className="filter-icon-part"
                  title="Add new employee"
                >
                  <img src="/assets/images/add-circle.svg" />
                  Add Employee
                </Button>
              )}
              <Button
                component={RouterLink}
                to="/employee/ex-employee"
                variant="contained"
                sx={{ marginRight: "15px" }}
                title="Show Ex-Employees"
              >
                Ex-Employees
              </Button>
              <Button
                component={RouterLink}
                to="/employee/joining-employee"
                variant="contained"
                title="Show new-joinings"
              >
                New-Joinings
              </Button>
            </Stack>
          </div>
        </Stack>
        <Card className="employee-table-grid" pb={3}>
          <Container className="employee-table-grid_container" maxWidth="xl">
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid className="employee-classification" container spacing={2}>
                <div className="employee-classification-right">
                  <TextField
                    className="employee-search-detail"
                    fullWidth
                    label="Search by Name/Email/Phone Number/Vehicle Number"
                    name="title"
                    inputRef={inputRef}
                    variant="outlined"
                    size="small"
                    onChange={debounceWithSearch}
                  />

                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      labelid="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      defaultValue=""
                      fullWidth
                      popupIcon={
                        <img
                          src="/assets/images/arrow-down.svg"
                          alt="dropdown"
                        />
                      }
                      options={employeeData || []}
                      getOptionLabel={(option) =>
                        option ? `${option?.firstName} ${option?.lastName}` : ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} value={option.id} key={option.id}>
                          {option.firstName + " " + option.lastName || ""}
                        </li>
                      )}
                      // {...getFieldProps(`workDetails.teamLead`)}
                      onChange={(event, newValue) => {
                        handleFilters("teamLead", newValue?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Lead" />
                      )}
                      value={
                        employeeData?.find(
                          (lead) => lead.id == values?.teamLead
                        ) || ""
                      }
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={
                        <img
                          src="/assets/images/arrow-down.svg"
                          alt="dropdown"
                        />
                      }
                      options={teamData || []}
                      getOptionLabel={(option) => option?.title || ""}
                      // name="teamId"
                      onChange={(event, newValue) => {
                        handleFilters("teamId", newValue?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Team" />
                      )}
                      value={
                        teamData?.find((t) => t.id === values?.teamId) || ""
                      }
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={
                        <img
                          src="/assets/images/arrow-down.svg"
                          alt="dropdown"
                        />
                      }
                      defaultValue=""
                      options={technologyData?.data || []}
                      getOptionLabel={(option) => option?.title || ""}
                      onChange={(event, newValue) => {
                        handleFilters("technologyId", newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Technology" />
                      )}
                      value={
                        technologyData?.data?.find(
                          (t) => t.id == values?.technologyId
                        ) || ""
                      }
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={
                        <img
                          src="/assets/images/arrow-down.svg"
                          alt="dropdown"
                        />
                      }
                      options={locationDropdown || []}
                      getOptionLabel={(option) => option?.title || ""}
                      name="teamId"
                      onChange={(event, newValue) => {
                        handleFilters("workLocation", newValue?.id);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Work Location" />
                      )}
                      value={
                        locationDropdown?.find(
                          (t) => t.id === values.workLocation
                        ) || ""
                      }
                    />
                  </div>
                  <div className="employee-classification-dropdown employee-classification-DatePicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="College Year"
                        value={values?.collegeYear || null}
                        views={["year"]}
                        maxDate={new Date()}
                        inputFormat="yyyy"
                        // minDate={new Date('2017-01-01')}
                        onChange={(newValue) => {
                          handleFilters("collegeYear", yearFormat(newValue));
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            name="collegeYear"
                            inputProps={{
                              ...params.inputProps,
                              disabled: true, // Add the disabled attribute to the input element
                            }}
                            error={false}
                            fullWidth
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="employee-classification-dropdown employee-classification-toggle">
                    {/* <Switch label="Under SA" /> */}
                    <FormControlLabel
                      className="classification-toggle-form"
                      control={
                        <Switch
                          className="classification-toggle-Switch"
                          checked={values?.isUnderServiceAgreement}
                          onChange={(e, v) => {
                            if (v == true) {
                              handleFilters("isUnderServiceAgreement", 1);
                            } else {
                              handleFilters("isUnderServiceAgreement", 0);
                            }
                          }}
                          name="isUnderServiceAgreement"
                          inputProps={{ "data-cy": "chk-hasMany-item" }}
                          size="small"
                        />
                      }
                      label="SA"
                    />
                  </div>

                  {values.isUnderServiceAgreement == true ? (
                    <div className="employee-classification-dropdown employee-classification-Commitment">
                      <Autocomplete
                        className="employee-Commitment-wrapper"
                        popupIcon={
                          <img
                            src="/assets/images/arrow-down.svg"
                            alt="dropdown"
                          />
                        }
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        size="small"
                        onChange={(event, newValue) => {
                          handleFilters("commitmentperiod", newValue);
                        }}
                        value={values?.commitmentperiod || ""}
                        options={commitmentDuration || []}
                        renderInput={(params) => (
                          <TextField {...params} label="Commitment" />
                        )}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="employee-classification-dropdown employee-classification-toggle">
                    {/* <Switch label="Under SA" /> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.retentionBonus}
                          onChange={(e, v) => {
                            if (v == true) {
                              handleFilters("retentionBonus", 1);
                            } else {
                              handleFilters("retentionBonus", 0);
                            }
                          }}
                          name="retentionBonus"
                          inputProps={{ "data-cy": "chk-hasMany-item" }}
                          size="small"
                        />
                      }
                      label="Retention"
                    />
                  </div>
                  <div className="employee-classification-dropdown employee-classification-toggle">
                    {/* <Switch label="Under SA" /> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.underESIC}
                          onChange={(e, v) => {
                            if (v == true) {
                              // setFieldValue("isUnderServiceAgreement", v)
                              handleFilters("underESIC", 1);
                            } else {
                              handleFilters("underESIC", 0);
                            }
                          }}
                          name="underESIC"
                          inputProps={{ "data-cy": "chk-hasMany-item" }}
                          size="small"
                        />
                      }
                      label="ESIC"
                    />
                  </div>

                  <div className="employee-classification-dropdown employee-classification-toggle">
                    {/* <Switch label="Under SA" /> */}
                    <FormControlLabel
                      control={
                        <Switch
                          checked={values?.underPF}
                          onChange={(e, v) => {
                            if (v == true) {
                              handleFilters("underPF", 1);
                            } else {
                              handleFilters("underPF", 0);
                            }
                          }}
                          name="underPF"
                          inputProps={{ "data-cy": "chk-hasMany-item" }}
                          size="small"
                        />
                      }
                      label="PF"
                    />
                  </div>
                  <div className="employee-classification-dropdown employee-classification-Filter">
                    {["right"].map((anchor, idx) => (
                      <React.Fragment key={idx}>
                        <span
                          className="filter-icon-part FilterIcon"
                          onClick={toggleDrawer(anchor, true)}
                        >
                          <IconButton>
                            {/* <Badge
                                color="error"
                                variant="dot"
                                invisible={filterChips.length ? false : true}
                              > */}
                            {/* <FilterAltIcon /> */}
                            <img src="/assets/images/filter.svg" />
                            {/* </Badge> */}
                          </IconButton>
                        </span>

                        <Drawer
                          anchor={anchor}
                          open={state[anchor]}
                          onClose={toggleDrawer(anchor, false)}
                          style={{ zIndex: "1300" }}
                        >
                          <Box
                            pt={4}
                            px={3}
                            sx={{
                              width:
                                anchor === "top" || anchor === "bottom"
                                  ? "auto"
                                  : 400,
                            }}
                            role="presentation"
                          >
                            <div className="content-filter-part">
                              <Stack
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                mb={3}
                              >
                                <Typography variant="h6">
                                  Employee Filter
                                </Typography>
                                <span>
                                  <IconButton
                                    onClick={toggleDrawer(anchor, false)}
                                  >
                                    <CloseIcon />
                                  </IconButton>
                                </span>
                              </Stack>

                              <form onSubmit={handleSubmit}>
                                <Grid
                                  container
                                  spacing={3}
                                  style={{ paddingTop: "0px" }}
                                >
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "5px" }}
                                  >
                                    <Autocomplete
                                      size="small"
                                      fullWidth
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      options={designation.data || []}
                                      getOptionLabel={(option) =>
                                        option.title || ""
                                      }
                                      name="designationId"
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          "designationId",
                                          newValue?.id || ""
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Designation"
                                        />
                                      )}
                                      value={designation?.data?.find(
                                        (t) => t.id === values.designationId
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      size="small"
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      disabled={
                                        getViewVisible("department") === false
                                      }
                                      sx={
                                        {
                                          // minWidth: 120,
                                        }
                                      }
                                      // style={{paddingTop:"0px"}}
                                      fullWidth
                                      options={department.data || []}
                                      getOptionLabel={(option) =>
                                        option.title || ""
                                      }
                                      name="departmentId"
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          "departmentId",
                                          newValue?.id || ""
                                        );
                                        // handleOnDepartmentChange(newValue);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Department"
                                        />
                                      )}
                                      value={department?.data?.find(
                                        (t) => t.id === values.departmentId
                                      )}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete
                                        size="small"
                                        fullWidth
                                        defaultValue=""
                                        options={technologyData.data || []}
                                        getOptionLabel={(option) => option.title || ""}
                                        onChange={(event, newValue) => {
                                          setFieldValue(
                                            `technologyId`,
                                            newValue?.id || ""
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Technology"
                                          />
                                        )}
                                        value={technologyData?.data?.find(
                                          (t) => t.id == values.technologyId
                                        )}
                                      />
                                    </Grid> */}
                                  {/* <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete
                                        size="small"
                                        fullWidth
                                        options={teamData || []}
                                        getOptionLabel={(option) => option.title || ""}
                                        name="teamId"
                                        onChange={(event, newValue) => {
                                          setFieldValue(
                                            "teamId",
                                            newValue?.id || ""
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} label="Team" />
                                        )}
                                        value={teamData?.find(
                                          (t) => t.id === values.teamId
                                        )}
                                      />
                                    </Grid> */}
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      size="small"
                                      defaultValue=""
                                      fullWidth
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      options={allPMData || []}
                                      getOptionLabel={(option) =>
                                        option
                                          ? `${option?.firstName} ${option?.lastName}`
                                          : ""
                                      }
                                      renderOption={(props, option) => (
                                        <li
                                          {...props}
                                          value={option.id}
                                          key={option.id}
                                        >
                                          {option.firstName +
                                            " " +
                                            option.lastName || ""}
                                        </li>
                                      )}
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          `projectManager`,
                                          newValue?.id || ""
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Project Manager"
                                        />
                                      )}
                                      value={
                                        allPMData?.find(
                                          (lead) =>
                                            lead.id == values.projectManager
                                        ) || ""
                                      }
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      size="small"
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      disabled={
                                        getViewVisible("college") === false
                                      }
                                      fullWidth
                                      options={college.data || []}
                                      getOptionLabel={(option) =>
                                        option?.collegeName || ""
                                      }
                                      name="collegeId"
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          "collegeId",
                                          newValue?.id || ""
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          autoComplete="off"
                                          label="College"
                                        />
                                      )}
                                      value={
                                        college?.data?.find(
                                          (t) => t.id === values.collegeId
                                        ) || ""
                                      }
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete
                                        labelid="demo-simple-select-helper-label"
                                        id="demo-simple-select-helper"
                                        size="small"
                                        defaultValue=""
                                        fullWidth
                                        options={allEmployeeData || []}
                                        getOptionLabel={(option) =>
                                          option ? `${option?.firstName} ${option?.lastName}` : ""
                                        }

                                        renderOption={(props, option) => (
                                          <li {...props} value={option.id} key={option.id}>
                                            {option.firstName + " " + option.lastName || ""}
                                          </li>
                                        )}
                                        onChange={(event, newValue) => {
                                          setFieldValue(`teamLead`, newValue?.id || "");
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            label="Lead"
                                          />
                                        )}
                                        value={
                                          allEmployeeData?.find(
                                            (lead) => lead.id == values.teamLead
                                          ) || ""
                                        }
                                      />
                                    </Grid> */}
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      size="small"
                                      name="city"
                                      defaultValue=""
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      options={cities || []}
                                      value={values.city}
                                      onChange={(event, newValue) => {
                                        setFieldValue(`city`, newValue || "");
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} label="City" />
                                      )}
                                    />
                                  </Grid>
                                  {/* <Grid item xs={12} style={{paddingTop:"10px"}}>
                                      <Autocomplete 
                                        size="small"
                                        fullWidth
                                        options={locationDropdown || []}
                                        getOptionLabel={(option) => option.title || ""}
                                        name="teamId"
                                        onChange={(event, newValue) => {
                                          setFieldValue(
                                            "workLocation",
                                            newValue?.id || ""
                                          );
                                        }}
                                        renderInput={(params) => (
                                          <TextField {...params} label="Work Location" />
                                        )}
                                        value={locationDropdown?.find(
                                          (t) => t.id === values.workLocation
                                        )}
                                      />
                                    </Grid> */}
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      size="small"
                                      fullWidth
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      options={genderDropdown || []}
                                      getOptionLabel={(option) =>
                                        option.title || ""
                                      }
                                      name="gender"
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          "gender",
                                          newValue?.id || ""
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField {...params} label="Gender" />
                                      )}
                                      value={genderDropdown?.find(
                                        (t) => t.id === values.gender
                                      )}
                                    />
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      size="small"
                                      name="appraisalCycle"
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      options={appraisalCycleData || []}
                                      // {...getFieldProps("workDetails.appraisalCycle")}
                                      //value={appraisalCycleData.find(
                                      //  (ac) => ac.text == values.workDetails.appraisalCycle) }
                                      value={values?.appraisalCycle}
                                      onChange={(event, newValue) => {
                                        setFieldValue(
                                          `appraisalCycle`,
                                          newValue || ""
                                        );
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Appraisal Cycle"
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <Autocomplete
                                      labelId="demo-simple-select-helper-label"
                                      id="demo-simple-select-helper"
                                      size="small"
                                      options={sortByData || []}
                                      name="sort"
                                      popupIcon={
                                        <img
                                          src="/assets/images/arrow-down.svg"
                                          alt="dropdown"
                                        />
                                      }
                                      getOptionLabel={(option) =>
                                        option?.title || ""
                                      }
                                      value={
                                        sortByData?.find(
                                          (s) => s.value == values.sort
                                        ) || ""
                                      }
                                      onChange={(event, newValue) => {
                                        // setsort(newValue?.value)
                                        setFieldValue("sort", newValue?.value);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          label="Sort By"
                                        />
                                      )}
                                    />
                                    {/* <FormControl className="employee-Sort-By">
                                        <InputLabel>Sort By</InputLabel>
                                        <Select
                                          labelId="demo-simple-select-label"
                                          id="demo-simple-select"
                                          value={sort}
                                          label="Sort By"
                                          onChange={(e) =>{
                                            setsort(e?.target?.value)
                                            handleSorting(e?.target?.value)
                                          }}
                                        >
                                          <MenuItem value={"asc"}>Asc (Name)</MenuItem>
                                          <MenuItem value={"desc"}>Desc (Name)</MenuItem>
                                          <MenuItem value={"joining"}>Joining Date</MenuItem>
                                        </Select>
                                      </FormControl> */}
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <LocalizationProvider
                                      dateAdapter={AdapterDateFns}
                                    >
                                      <DesktopDatePicker
                                        label="Joining Year"
                                        value={values?.joiningYear || null}
                                        views={["year"]}
                                        maxDate={new Date()}
                                        inputFormat="yyyy"
                                        // minDate={new Date('2017-01-01')}
                                        onChange={(newValue) => {
                                            setFieldValue('joiningYear',yearFormat(newValue))
                                        }}
                                        renderInput={(params) => (
                                          <TextField
                                            {...params}
                                            name="joiningYear"
                                            inputProps={{
                                              ...params.inputProps,
                                              disabled: true, // Add the disabled attribute to the input element
                                            }}
                                            error={false}
                                            fullWidth
                                            size="small"
                                          />
                                        )}
                                      />
                                    </LocalizationProvider>
                                  </Grid>
                                  <Grid
                                   item
                                   xs={12}
                                   style={{ paddingTop: "10px" }}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Exp. From"
                                      name="expFromYear"
                                      variant="outlined"
                                      size="small"
                                      value={values.expFromYear}
                                      onChange={handleChange}
                                      helperText={(touched.expFromYear || dirty) && errors.expFromYear}
                                      error={Boolean((touched.expFromYear || dirty) && errors.expFromYear)}
                                      onBlur={handleBlur}
                                      onKeyDown={(e) => validateKeyDown(e, 'integer')}
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ paddingTop: "10px" }}
                                  >
                                    <TextField
                                      fullWidth
                                      label="Exp. To"
                                      name="expToYear"
                                      variant="outlined"
                                      size="small"
                                      value={values.expToYear}
                                      onChange={handleChange}
                                      helperText={(touched.expToYear || dirty) && errors.expToYear}
                                      error={Boolean((touched.expToYear || dirty) && errors.expToYear)}
                                      onBlur={handleBlur}
                                      onKeyDown={(e) => validateKeyDown(e, 'integer')}
                                    />
                                  </Grid>
                                </Grid>
                                <Stack
                                  direction="row"
                                  justifyContent="flex-end"
                                  mb={3}
                                  mt={3}
                                >
                                  <LoadingButton
                                    type="submit"
                                    variant="contained"
                                    loading={isSubmitting}
                                    onClick={()=>setEmployeeFilterSearchBtn(true)}
                                    startIcon={<SearchIcon />}
                                  >
                                    Search
                                  </LoadingButton>
                                  <Button
                                    variant="contained"
                                    type="reset"
                                    onClick={handleReset}
                                    startIcon={<CachedIcon />}
                                    sx={{ marginLeft: "10px" }}
                                  >
                                    Reset
                                  </Button>
                                </Stack>
                              </form>
                            </div>
                          </Box>
                        </Drawer>
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              </Grid>
              {/* <Grid item xs={8} mt={3}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Stack spacing={3} direction="row">
										<DesktopDatePicker
											label="Start Date"
											renderInput={(params) => (
												<TextField {} fullWidth size="small" />
											)}
										/>
									</Stack>
								</LocalizationProvider>
							</Grid> */}
            </form>
          </Container>
        </Card>
        <Stack className="search-filter" direction="row" alignItems="start">
          {filterChips.map((chips, idx) => {
            return (
              <Chip
                className="search-filter-detail"
                label={chips.filter}
                color="primary"
                size="small"
                key={chips.filter}
                variant="filled"
                onDelete={() => onDeleteChip(chips, filterChips.length)}
              />
            );
          })}
        </Stack>
        {isSearchQuery ? (
          (<Typography className="record-found-text" variant="body2" mb={1}>
            {totalCount > 0 ? `${totalCount} records found` : ""}
          </Typography>)
        ) : (
          ""
        )}
        {employeeFilterSearchBtn ? (
          (<Typography className="record-found-text" variant="body2" mb={1}>
            {onlyEmployeeFilterTotalCount > 0 ? `${onlyEmployeeFilterTotalCount} records found` : ""}
          </Typography>)
        ) : (
          ""
        )}
        <Grid
          container
          spacing={1}
          style={{
            display: "grid",
            // marginTop: "-50px",
          }}
          className="employee-grid"
        >
          {(Array.isArray(data) && data.length === 0 && !loading) ||
          (status === "rejected" && isSearchQuery) ? (
            <Typography
              className="No-Record-text"
              align="justify"
              variant="inherit"
              gutterBottom
            >
              No Record(s) Found
            </Typography>
          ) : (
            data.map((employee, idx) => {
              // (new Date(employee?.createdDate).setHours(0,0,0,0) ==  new Date().setHours(0,0,0,0) ? setEmpId(employee.id) : "")
              /* 	if (data.length === idx + 1) { */
              // new Date(employee?.createdDate).setHours(0,0,0,0) === new Date().setHours(0,0,0,0) && setEmpId(employee.id)
              return (
                <Grid key={idx} ref={lastBookElementRef}>
                  <div
                    className="view_employee candidate-details"
                    style={{ padding: "0", maxWidth: "100%", width: "100%" }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        className="grid_container"
                        style={{ marginBottom: "0px" }}
                      >
                        <Grid
                          className="view_employee_grid-item"
                          item
                          xs={12}
                          style={{ maxWidth: "100%", flexBasis: "100%" }}
                        >
                          <Card className="information-card card_height">
                            <Paper
                              sx={{
                                textDecoration: "none",
                                color: "#212B36",
                              }}
                              onClick={(e) =>
                                getViewVisible("employee")
                                  ? window.open(
                                      `/employee/view/${encryption(
                                        employee.id
                                      )}`,
                                      "_blank"
                                    )
                                  : ""
                              }
                            >
                              <Container
                                className="personal-details-section"
                                maxWidth="xl"
                                style={{
                                  backgroundColor: "#F8F9FA",
                                  padding: "16px",
                                }}
                              >
                                {/* { (new Date(employee?.dateOfBirth).getDate() == new Date().getDate() && 
                              new Date(employee?.dateOfBirth).getMonth() == new Date().getMonth()) ||
                              (new Date(employee?.workDetails?.productionDate).getDate() == new Date().getDate() && 
                              new Date(employee?.workDetails?.productionDate).getMonth() == new Date().getMonth() ) ?
                              <div className="Special-Day-div">
                                <p className="Work-Anniversary-tab">
                                  {new Date(employee?.workDetails?.productionDate).getDate() == new Date().getDate() && 
                                  new Date(employee?.workDetails?.productionDate).getMonth() == new Date().getMonth() ?
                                  `${ordinal_suffix_of(new Date().getFullYear() - new Date(employee?.workDetails?.productionDate).getFullYear())} Work Anniversary`
                                  : ""}
                                </p>
                                <p className="Birthday-tab">
                                  {(new Date(employee?.dateOfBirth).getDate() == new Date().getDate() && 
                                  new Date(employee?.dateOfBirth).getMonth() == new Date().getMonth()) ? "Birthday":""}
                                </p>
                                <p className="Relieving-tab">
                                  {(new Date(employee?.dateOfBirth).getDate() == new Date().getDate() && 
                                    new Date(employee?.dateOfBirth).getMonth() == new Date().getMonth()) ? "Relieving" :""}
                                </p>
                                </div> 
                                
                              : ""} */}
                                <div className="Special-Day-div">
                                  {showWorkAnniversary(employee)}
                                  {new Date(employee?.dateOfBirth).getDate() ==
                                    new Date().getDate() &&
                                  new Date(employee?.dateOfBirth).getMonth() ==
                                    new Date().getMonth() ? (
                                    <p className="Birthday-tab">Birthday</p>
                                  ) : (
                                    ""
                                  )}
                                  {employee?.workDetails?.relievingDate &&
                                  employee?.workDetails?.reJoiningDate ==
                                    null ? (
                                    <p className="Relieving-tab">Relieving</p>
                                  ) : employee?.workDetails?.relievingDate &&
                                    employee?.workDetails?.reJoiningDate &&
                                    employee?.workDetails?.relievingDate >
                                      employee?.workDetails?.reJoiningDate ? (
                                    <p className="Relieving-tab">Relieving</p>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <Stack
                                  className="personal-image-left"
                                  direction="row"
                                  justifyContent="space-between"
                                >
                                  <div
                                    className="personal-image-section"
                                    style={{
                                      width: "-webkit-fill-available",
                                      maxWidth: "50%",
                                    }}
                                  >
                                    <Grid
                                      item
                                      xs={4}
                                      className="personal-image-section-grid"
                                    >
                                      <div className="personal-left-detail-content">
                                        <Avatar
                                          className="personal-profile"
                                          src={
                                            employee?.employeeImage &&
                                            `${BASE_URL}/${employee?.employeeImage}`
                                          }
                                          alt={employee.firstName}
                                        ></Avatar>
                                        {/* <Typography align="center"> */}
                                        <h4
                                          className="employeeName"
                                          align="center"
                                        >{`${employee.firstName} ${employee.lastName}`}</h4>

                                        <p className="job-position-txt">
                                          {
                                            employee?.workDetails?.designation
                                              ?.title
                                          }
                                        </p>

                                        <p className="job-position-txt">
                                          {employee?.employeeId}
                                        </p>
                                        <Stack
                                          className="employee-contact"
                                          direction="row"
                                          justifyContent="center"
                                          flexWrap="wrap"
                                          sx={{
                                            gridGap: "3px",
                                            fontSize: ".8rem",
                                          }}
                                          // mt={1}
                                          mb={1}
                                        >
                                          <Link
                                            href={`tel: ${employee?.contact?.contactNumber}`}
                                            color="text.secondary"
                                          >
                                            {employee?.contact?.contactNumber}
                                          </Link>
                                          {employee?.contact
                                            ?.alternateContactNumber && (
                                            <>
                                              <span>|</span>
                                              <Link
                                                href={`tel: ${employee?.contact?.alternateContactNumber}`}
                                                color="text.secondary"
                                              >
                                                {
                                                  employee?.contact
                                                    ?.alternateContactNumber
                                                }
                                              </Link>
                                            </>
                                          )}
                                        </Stack>
                                      </div>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        justifyContent="center"
                                        style={{ marginLeft: "0px" }}
                                        sx={{ gridGap: "8px" }}
                                      >
                                        {employee?.contact?.companySkype ? (
                                          <Box
                                            component="img"
                                            src="/assets/images/skype-graybox.svg"
                                            title="Skype"
                                            className="skype"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              window.open(
                                                `skype:${employee?.contact?.companySkype}?chat`,
                                                "_blank"
                                              );
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {employee?.contact?.linkedIn ? (
                                          <Box
                                            component="img"
                                            src="/assets/images/linkedin-graybox.svg"
                                            className="linkdin"
                                            title="LinkedIn"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              window.open(
                                                employee?.contact?.linkedIn,
                                                "_blank"
                                              );
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {employee?.github ? (
                                          <Box
                                            component="img"
                                            src="/assets/images/copylink-graybox.svg"
                                            className="download-img"
                                            Title="Open Github"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              window.open(
                                                employee?.github,
                                                "_blank"
                                              );
                                            }}
                                          />
                                        ) : employee?.portfolioWebsite ? (
                                          <Box
                                            component="img"
                                            src="/assets/images/copylink-graybox.svg"
                                            className="mail-img"
                                            title="Open Portfolio"
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              window.open(
                                                employee?.portfolioWebsite,
                                                "_blank"
                                              );
                                            }}
                                          />
                                        ) : (
                                          ""
                                        )}

                                        {employee?.employeeDocuments
                                          ?.employeeResume &&
                                          employee?.employeeDocuments
                                            ?.employeeResume?.length > 0 && (
                                            <Box
                                              component="img"
                                              src="/assets/images/download-graybox.svg"
                                              className="copylink"
                                              title="Open Resume"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                window.open(
                                                  employee?.employeeDocuments
                                                    ?.employeeResume &&
                                                    employee?.employeeDocuments
                                                      ?.employeeResume?.length >
                                                      0 &&
                                                    employee?.employeeDocuments
                                                      ?.employeeResume[0]
                                                      .webViewLink,
                                                  "_blank"
                                                );
                                              }}
                                            />
                                          )}

                                        {employee?.emails &&
                                          employee?.emails?.map(
                                            (email, index) =>
                                              email?.type == "Company Email" ? (
                                                <Tooltip
                                                  title={email?.email}
                                                  placement="bottom"
                                                >
                                                  <Box
                                                    component="img"
                                                    src="/assets/images/mail-graybox.svg"
                                                    className="edit"
                                                    onClick={(e) => {
                                                      e.stopPropagation();
                                                      ClicktoCopy(email?.email);
                                                    }}
                                                  />
                                                </Tooltip>
                                              ) : (
                                                ""
                                              )
                                          )}
                                        {/* {commitment &&
                              commitment?.map((c, idx) =>
                                c?.id == employee?.id && c?.commit == true ? (
                                  <Tooltip
                                    title="Under Commitment"
                                    placement="bottom"
                                  >
                                    <Box
                                      component="img"
                                      src="/assets/images/commitment.jpg"
                                      text="Under Commitment"
                                      // onClick={(e) => {
                                      // 	e.stopPropagation();
                                      // }}
                                    />
                                  </Tooltip>
                                ) : (
                                  ""
                                )
                              )} */}
                                        {getEditVisible("employee") && (
                                          <IconButton
                                            style={{ padding: "0px" }}
                                            onClick={() =>
                                              window.open(
                                                `/employee/add/${encryption(
                                                  employee.id
                                                )}`,
                                                "_blank"
                                              )
                                            }
                                          >
                                            <img
                                              src="assets/images/edit-graybox.svg"
                                              title="Edit Employee"
                                            />
                                            {/* <EditIcon color="primary" /> */}
                                          </IconButton>
                                        )}
                                        {/* {getViewVisible("interns") && ( */}
                                        {/* <IconButton
                                      style={{ padding: "0px" }}
                                      onClick={()=> window.open(
                                          `/employee/view/${encryption(employee.id)}`,
                                          "_blank"
                                        )}
                                    >
                                      <VisibilityIcon />
                                    </IconButton> */}
                                        {/* )} */}

                                        {handleShowBackout(employee) ? (
                                          <IconButton
                                            style={{ padding: "0px" }}
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleBackout(employee.id);
                                            }}
                                          >
                                            <img
                                              src="assets/images/delete-graybox.svg"
                                              title="Backout Employee"
                                            />
                                            {/* <DeleteIcon /> */}
                                          </IconButton>
                                        ) : (
                                          ""
                                        )}

                                        {employee?.workLocation == "HOME" && (
                                          <IconButton
                                            style={{ padding: "0px" }}
                                          >
                                            <img
                                              src="assets/images/home-icon.svg"
                                              title="Work From Home"
                                            />
                                          </IconButton>
                                        )}
                                      </Stack>

                                      {/* {internImage?.email && (
                                <a href={`mailto:${internImage?.email}`}>
                                  <Box
                                    component="img"
                                    src="/assets/images/mail.svg"
                                    className="mail-img"
                                    style={{
                                      width: "16px !important",
                                    }}
                                  />
                                </a>
                              )} */}
                                    </Grid>
                                  </div>

                                  <Grid
                                    className="personal-content-section"
                                    item
                                    xs={8}
                                  >
                                    <Card
                                      style={{ padding: "16px" }}
                                      className="address-card information-card"
                                    >
                                      {/* <Container> */}
                                      {/* <TabContext value={value}> */}
                                      <Grid
                                        className="address-card-container"
                                        container
                                        spacing={2}
                                      >
                                        <Grid
                                          className="address-card-detail"
                                          item
                                          xs={10}
                                          style={{
                                            maxWidth: "100%",
                                            flexBasis: "100%",
                                          }}
                                        >
                                          <div className="address-blk">
                                            <div className="address-sub-section general_sec">
                                              <div className="address-div">
                                                <span>Joining</span>
                                                <p>
                                                  {employee?.workDetails
                                                    ?.joiningDate != null
                                                    ? indianDateFormat(
                                                        employee?.workDetails
                                                          ?.joiningDate
                                                      )
                                                    : "-"}
                                                </p>
                                              </div>
                                              <div className="address-div">
                                                <span>Production</span>
                                                <p>
                                                  {employee?.workDetails
                                                    ?.productionDate != null
                                                    ? indianDateFormat(
                                                        employee?.workDetails
                                                          ?.productionDate
                                                      )
                                                    : "-"}
                                                </p>
                                              </div>
                                              <div className="address-div">
                                                <span>Commitment</span>
                                                <p>
                                                  {employee?.workDetails
                                                    ?.commitmentStartDate !=
                                                  null
                                                    ? indianDateFormat(
                                                        employee?.workDetails
                                                          ?.commitmentStartDate
                                                      )
                                                    : "-"}
                                                </p>
                                              </div>
                                              {/* {employee?.workDetails?.relievingDate && employee?.workDetails?.reJoiningDate == null ?
                                            <div className="address-div">
                                              <span style={{color:"red"}}>Relieving</span>
                                              <p style={{color:"red"}}>{employee?.workDetails?.relievingDate != null 
                                                ? indianDateFormat(employee?.workDetails?.relievingDate) 
                                                : "-" }
                                              </p>
                                            </div> 
                                          : 
                                            <div className="address-div">
                                            <span>Rejoining</span>
                                            <p>{employee?.workDetails?.reJoiningDate != null 
                                              ? indianDateFormat(employee?.workDetails?.reJoiningDate) 
                                              : "-" }
                                            </p>
                                          </div> 
                                          } */}

                                              {employee?.workDetails
                                                ?.relievingDate &&
                                              employee?.workDetails
                                                ?.reJoiningDate == null ? (
                                                <div className="address-div">
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Relieving
                                                  </span>
                                                  <p style={{ color: "red" }}>
                                                    {employee?.workDetails
                                                      ?.relievingDate != null
                                                      ? indianDateFormat(
                                                          employee?.workDetails
                                                            ?.relievingDate
                                                        )
                                                      : "-"}
                                                  </p>
                                                </div>
                                              ) : employee?.workDetails
                                                  ?.relievingDate &&
                                                employee?.workDetails
                                                  ?.reJoiningDate &&
                                                employee?.workDetails
                                                  ?.relievingDate >
                                                  employee?.workDetails
                                                    ?.reJoiningDate ? (
                                                <div className="address-div">
                                                  <span
                                                    style={{ color: "red" }}
                                                  >
                                                    Relieving
                                                  </span>
                                                  <p style={{ color: "red" }}>
                                                    {employee?.workDetails
                                                      ?.relievingDate != null
                                                      ? indianDateFormat(
                                                          employee?.workDetails
                                                            ?.relievingDate
                                                        )
                                                      : "-"}
                                                  </p>
                                                </div>
                                              ) : employee?.workDetails
                                                  ?.reJoiningDate != null ? (
                                                <div className="address-div">
                                                  <span>Rejoining</span>
                                                  <p>
                                                    {indianDateFormat(
                                                      employee?.workDetails
                                                        ?.reJoiningDate
                                                    )}
                                                  </p>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              <div className="address-div">
                                                <span>Lead</span>
                                                <p>
                                                  {employee?.workDetails
                                                    ?.teamLeadName
                                                    ? `${employee?.workDetails?.teamLeadName?.firstName} ${employee?.workDetails?.teamLeadName?.lastName}`
                                                    : "-"}
                                                </p>
                                              </div>
                                              <div className="address-div work-experience-detail">
                                                <span>Exp.</span>
                                                <p className="work-experience">
                                                  {countExpTech(employee?.id) ||
                                                    ""}
                                                </p>
                                              </div>
                                              {/* {employee?.workDetails?.productionDate != null ?  */}
                                            </div>
                                          </div>
                                          {/* <div style={{position:"relative"}}>
                                              <p style={{position: "absolute",bottom:"0",right:"0",}}>Bet 5 days ago</p>
                                        </div> */}
                                        </Grid>
                                      </Grid>
                                      {/* </TabContext> */}
                                      {/* </Container> */}
                                    </Card>

                                    <div className="technology-details">
                                      {technologyForIcon &&
                                      Array.isArray(technologyForIcon) &&
                                      technologyForIcon.length > 0
                                        ? technologyForIcon.map((exp) => {
                                            if (exp.id === employee?.id) {
                                              // && exp.workDetails?.length > 0) {
                                              //   return exp.workDetails.map((t) => {
                                              if (
                                                exp?.workDetails?.technologies
                                                  ?.length > 0
                                              ) {
                                                return exp?.workDetails?.technologies.map(
                                                  (tech) => (
                                                    <img
                                                      key={tech?.technology?.id}
                                                      className="technology-icons"
                                                      src={
                                                        tech?.technology?.icon
                                                          ? `${BASE_URL}/${tech?.technology?.icon}`
                                                          : "/assets/images/technology-icon.svg"
                                                      }
                                                      title={
                                                        tech?.technology?.title
                                                      }
                                                    />
                                                  )
                                                );
                                              } else {
                                                return null;
                                              }
                                              // });
                                            } else {
                                              return null;
                                            }
                                          })
                                        : null}
                                    </div>
                                  </Grid>
                                </Stack>
                              </Container>
                            </Paper>
                          </Card>
                        </Grid>
                      </Grid>
                    </Box>
                    {loading && <Loader />}
                  </div>
                </Grid>
                // <Grid item xs={3} key={idx} ref={lastBookElementRef}>
                //   <Card className="employee-block" style={{ height: "100%" }}>
                //     <CardHeader
                //       className={checkResignationAcceptanceDate(
                //         employee?.workDetails?.resignationAcceptanceDate,
                //         employee?.workDetails?.reJoiningDate
                //       )}
                //       sx={{ paddingTop: "10px" }}
                //       action={
                //         <UserMoreActions
                //           edit={() =>
                //             window.open(
                //               `/employee/add/${encryption(employee.id)}`,
                //               "_blank"
                //             )
                //           }
                //           view={() =>
                //             window.open(
                //               `/employee/view/${encryption(employee.id)}`,
                //               "_blank"
                //             )
                //           }
                //           showBackout={handleShowBackout(employee)}
                //           backout={() => handleBackout(employee.id)}
                //         />
                //       }
                //     />
                //     <Paper
                //       /* component={RouterLink} */
                //       sx={{
                //         textDecoration: "none",
                //         color: "#212B36",
                //       }}
                //       /* to={`/employee/view/${encryption(employee.id)}`} */

                //       onClick={(e) =>
                //         window.open(
                //           `/employee/view/${encryption(employee.id)}`,
                //           "_blank"
                //         )
                //       }
                //       // 	{
                //       // 	e.preventDefault();
                //       // 	navigate(`/employee/view/${encryption(employee.id)}`);
                //       // }}
                //     >
                //       <Stack className="employee-propfile-part">
                //         {employee.dateOfBirth &&
                //           checkBirthDay(employee.dateOfBirth) && (
                //             <Stack className="bday-part">
                //               <img
                //                 alt="birthday"
                //                 src="/assets/images/b-ribbion.jpeg"
                //                 className="birthday_image"
                //               />
                //             </Stack>
                //           )}
                //         {/*  */}
                //         <figure className="employee-img">
                //           <Avatar
                //             src={
                //               employee?.employeeImage &&
                //               `${BASE_URL}/${employee?.employeeImage}`
                //             }
                //             alt={employee.firstName}
                //             sx={{
                //               width: 100,
                //               height: 100,
                //               fontSize: "2.5rem",
                //             }}
                //           ></Avatar>
                //         </figure>
                //       </Stack>
                //       <CardContent
                //         sx={{
                //           textAlign: "center",
                //           paddingTop: "10px",
                //         }}
                //       >
                //         <h3 className="employeeName">{`${employee.firstName} ${employee.lastName}`}</h3>
                //         <Typography
                //           className="designation"
                //           variant="body2"
                //           color="text.secondary"
                //           mb={1.5}
                //         >
                //           {employee?.workDetails?.designation?.title}
                //         </Typography>
                //         <Stack
                //           direction="row"
                //           justifyContent="center"
                //           flexWrap="wrap"
                //           sx={{
                //             gridGap: "5px",
                //             fontSize: ".8rem",
                //           }}
                //         >
                //           <Link
                //             href={`tel: ${employee?.contact?.contactNumber}`}
                //             color="text.secondary"
                //           >
                //             {employee?.contact?.contactNumber}
                //           </Link>
                //           {employee?.contact?.alternateContactNumber && (
                //             <>
                //               <span>|</span>
                //               <Link
                //                 href={`tel: ${employee?.contact?.alternateContactNumber}`}
                //                 color="text.secondary"
                //               >
                //                 {employee?.contact?.alternateContactNumber}
                //               </Link>
                //             </>
                //           )}
                //         </Stack>
                //         {/* <span
                // 					color="text.secondary"
                // 					sx={{ fontSize: ".8rem" }}
                // 					onClick={(e) => {
                // 						e.stopPropagation();
                // 						window.open(
                // 							`mailto: ${employee?.emails[0]?.email}`,
                // 							"_blank",
                // 						);
                // 					}}
                // 				>
                // 					{employee?.emails?.length > 0 &&
                // 						employee?.emails[0]?.email}
                // 				</span> */}
                //         {
                //         (employee.workDetails?.reJoiningDate  && employee.workDetails?.relievingDate
                //           ?
                //            new Date(employee.workDetails?.relievingDate) > new Date(employee.workDetails?.reJoiningDate)  : true) &&
                //             employee.workDetails?.relievingDate  && (
                //               <Typography
                //                 color="text.secondary"
                //                 sx={{ fontSize: ".8rem" }}
                //               >{`${"Relieving Date"}: ${indianDateFormat(
                //                 employee.workDetails?.relievingDate
                //               )}`}</Typography>
                //             )}
                //         {Array.isArray(employee?.workDetails?.technologies) &&
                //         employee?.workDetails?.technologies?.length > 0 ? (
                //           <Stack
                //             direction="row"
                //             justifyContent="center"
                //             flexWrap="wrap"
                //             spacing={1}
                //             rowGap="8px"
                //             mb={2}
                //             mt={2}
                //           >
                //             {employee?.workDetails?.technologies?.map(
                //               (tech, index) => (
                //                 <Chip
                //                   key={index}
                //                   size="small"
                //                   sx={{
                //                     fontWeight: "bold",
                //                   }}
                //                   label={tech?.technology?.title}
                //                 />
                //               )
                //             )}
                //           </Stack>
                //         ) : (
                //           employee?.workDetails?.technology && (
                //             <Stack
                //               direction="row"
                //               justifyContent="center"
                //               flexWrap="wrap"
                //               spacing={1}
                //               rowGap="8px"
                //               mb={2}
                //               mt={2}
                //             >
                //               <Chip
                //                 size="small"
                //                 sx={{
                //                   fontWeight: "bold",
                //                 }}
                //                 label={
                //                   employee?.workDetails?.technology?.title
                //                     ? employee?.workDetails?.technology?.title
                //                     : "-"
                //                 }
                //               />
                //             </Stack>
                //           )
                //         )}

                // <Stack
                //   direction="row"
                //   alignItems="center"
                //   justifyContent="center"
                //   sx={{ gridGap: "15px" }}
                // >
                //   {employee?.contact?.companySkype ? (
                //     <Box
                //       component="img"
                //       src="/assets/images/skype.png"
                //       className="skype"
                //       onClick={(e) => {
                //         e.stopPropagation();
                //         window.open(
                //           `skype:${employee?.contact?.companySkype}?chat`,
                //           "_blank"
                //         );
                //       }}
                //     />
                //   ) : (
                //     ""
                //   )}

                //   {employee?.contact?.linkedIn ? (
                //     <Box
                //       component="img"
                //       src="/assets/images/linkedin.png"
                //       className="linkdin"
                //       onClick={(e) => {
                //         e.stopPropagation();
                //         window.open(
                //           employee?.contact?.linkedIn,
                //           "_blank"
                //         );
                //       }}
                //     />
                //   ) : (
                //     ""
                //   )}
                //   {employee?.github ? (
                //     <Box
                //       component="img"
                //       src="/assets/images/github.png"
                //       className="github-img"
                //       onClick={(e) => {
                //         e.stopPropagation();
                //         window.open(employee?.github, "_blank");
                //       }}
                //     />
                //   ) : (
                //     ""
                //   )}
                //   {employee?.portfolioWebsite ? (
                //     <Box
                //       component="img"
                //       src="/assets/images/portfolio.png"
                //       className="portfolio-img"
                //       onClick={(e) => {
                //         e.stopPropagation();
                //         window.open(employee?.portfolioWebsite, "_blank");
                //       }}
                //     />
                //   ) : (
                //     ""
                //   )}
                //   {employee?.documents?.employeeResume &&
                //     employee?.documents?.employeeResume?.length > 0 && (
                //       <Box
                //         component="img"
                //         src="/assets/images/resume-download-icon.png"
                //         className="download"
                //         onClick={(e) => {
                //           e.stopPropagation();
                //           window.open(
                //             employee?.documents?.employeeResume &&
                //               employee?.documents?.employeeResume
                //                 ?.length > 0 &&
                //               employee?.documents?.employeeResume[0]
                //                 .webViewLink,
                //             "_blank"
                //           );
                //         }}
                //       />
                //     )}

                //   {employee?.emails &&
                //     employee?.emails?.map((email, index) =>
                //       email?.type == "Company Email" ? (
                //         <Tooltip title={email?.email} placement="bottom">
                //           <Box
                //             component="img"
                //             src="/assets/images/email.png"
                //             className="email"
                //             onClick={(e) => {
                //               e.stopPropagation();
                //               ClicktoCopy(email?.email);
                //             }}
                //           />
                //         </Tooltip>
                //       ) : (
                //         ""
                //       )
                //     )}
                //   {/* {console.log(employee?.workDetails?.commitmentStartDate)}
                // 	{console.log(employee?.workDetails?.bondDuration)} */}
                //   {/* { employee?.workDetails?.commitmentStartDate && employee?.workDetails?.bondDuration &&
                // 		// setEmp(employee)
                // 	} */}
                //   {commitment &&
                //     commitment?.map((c, idx) =>
                //       c?.id == employee?.id && c?.commit == true ? (
                //         <Tooltip
                //           title="Under Commitment"
                //           placement="bottom"
                //         >
                //           <Box
                //             component="img"
                //             src="/assets/images/commitment.jpg"
                //             text="Under Commitment"
                //             // onClick={(e) => {
                //             // 	e.stopPropagation();
                //             // }}
                //           />
                //         </Tooltip>
                //       ) : (
                //         ""
                //       )
                //     )}
                //   {/* {commitment == true ? (
                // 		<Box
                // 			component="img"
                // 			src="/assets/images/commitment.jpg"
                // 			className="linkdin"
                // 			onClick={(e) => {
                // 				e.stopPropagation();
                // 				window.open(
                // 					employee?.contact?.linkedIn,
                // 					"_blank",
                // 				);
                // 			}}
                // 		/>
                // 	) : (
                // 		""
                // 	)} */}
                // </Stack>
                //       </CardContent>
                //     </Paper>
                //   </Card>

                // </Grid>
              );
              // } else {
              // 	return (
              // 		<Grid item xs={3} key={idx}>
              // 			<Card className="employee-block">
              // 				<CardHeader
              // 					className={checkResignationAcceptanceDate(
              // 						employee?.workDetails?.resignationAcceptanceDate,
              // 					)}
              // 					sx={{ paddingTop: "10px" }}
              // 					action={
              // 						<UserMoreActions
              // 							edit={`/employee/add/${encryption(employee.id)}`}
              // 							view={`/employee/view/${encryption(employee.id)}`}
              // 						/>
              // 					}
              // 				/>
              // 				<Paper
              // 					/* component={RouterLink} */
              // 					sx={{
              // 						textDecoration: "none",
              // 						color: "#212B36",
              // 					}}
              // 					/* to={`/employee/view/${encryption(employee.id)}`} */
              // 					onClick={(e) => {
              // 						e.preventDefault();
              // 						navigate(`/employee/view/${encryption(employee.id)}`);
              // 					}}
              // 				>
              // 					<Stack className="employee-propfile-part">
              // 						{employee.dateOfBirth &&
              // 							checkBirthDay(employee.dateOfBirth) && (
              // 								<Stack className="bday-part">
              // 									<img
              // 										alt="birthday"
              // 										src="/assets/images/b-ribbion.jpeg"
              // 										className="birthday_image"
              // 									/>
              // 								</Stack>
              // 							)}

              // 						<figure className="employee-img">
              // 							<Avatar
              // 								src={
              // 									employee?.employeeImage &&
              // 									`${BASE_URL}/${employee?.employeeImage}`
              // 								}
              // 								alt={employee.firstName}
              // 								sx={{
              // 									width: 100,
              // 									height: 100,
              // 									fontSize: "2.5rem",
              // 								}}
              // 							></Avatar>
              // 						</figure>
              // 					</Stack>
              // 					<CardContent
              // 						sx={{
              // 							textAlign: "center",
              // 							paddingTop: "10px",
              // 						}}
              // 					>
              // 						<h3 className="employeeName">{`${employee.firstName} ${employee.lastName}`}</h3>
              // 						<Typography
              // 							className="designation"
              // 							variant="body2"
              // 							color="text.secondary"
              // 							mb={1.5}
              // 						>
              // 							{employee?.workDetails?.designation?.title}
              // 						</Typography>
              // 						<Stack
              // 							direction="row"
              // 							justifyContent="center"
              // 							flexWrap="wrap"
              // 							sx={{
              // 								gridGap: "5px",
              // 								fontSize: ".8rem",
              // 							}}
              // 						>
              // 							<Paper
              // 								onClick={(e) => {
              // 									e.stopPropagation();
              // 									window.open(
              // 										`tel: ${employee?.contact?.contactNumber}`,
              // 										"_blank",
              // 									);
              // 								}}
              // 								color="text.secondary"
              // 								style={{ cursor: "pointer" }}
              // 							>
              // 								{employee?.contact?.contactNumber}
              // 							</Paper>
              // 							{employee?.contact?.alternateContactNumber && (
              // 								<>
              // 									<span>|</span>
              // 									<Paper
              // 										color="text.secondary"
              // 										onClick={(e) => {
              // 											e.stopPropagation();
              // 											window.open(
              // 												`tel: ${employee?.contact?.alternateContactNumber}`,
              // 												"_blank",
              // 											);
              // 										}}
              // 										style={{ cursor: "pointer" }}
              // 									>
              // 										{employee?.contact?.alternateContactNumber}
              // 									</Paper>
              // 								</>
              // 							)}
              // 						</Stack>
              // 						<Paper
              // 							components="span"
              // 							color="text.secondary"
              // 							sx={{ fontSize: ".8rem" }}
              // 							onClick={(e) => {
              // 								e.stopPropagation();
              // 								window.open(
              // 									`mailto: ${employee?.emails[0]?.email}`,
              // 									"_blank",
              // 								);
              // 							}}
              // 							style={{ cursor: "pointer" }}
              // 						>
              // 							{employee?.emails?.length > 0 &&
              // 								employee?.emails[0]?.email}
              // 						</Paper>

              // 						{Array.isArray(employee?.workDetails?.technologies) &&
              // 						employee?.workDetails?.technologies?.length > 0 ? (
              // 							<Stack
              // 								direction="row"
              // 								justifyContent="center"
              // 								flexWrap="wrap"
              // 								spacing={1}
              // 								rowGap="8px"
              // 								mb={2}
              // 								mt={2}
              // 							>
              // 								<Chip
              // 									size="small"
              // 									sx={{
              // 										fontWeight: "bold",
              // 									}}
              // 									label={
              // 										employee?.workDetails?.technologies[0]
              // 											.technology?.title
              // 									}
              // 								/>
              // 							</Stack>
              // 						) : (
              // 							employee?.workDetails?.technology && (
              // 								<Stack
              // 									direction="row"
              // 									justifyContent="center"
              // 									flexWrap="wrap"
              // 									spacing={1}
              // 									rowGap="8px"
              // 									mb={2}
              // 									mt={2}
              // 								>
              // 									<Chip
              // 										size="small"
              // 										sx={{
              // 											fontWeight: "bold",
              // 										}}
              // 										label={
              // 											employee?.workDetails?.technology?.title
              // 												? employee?.workDetails?.technology?.title
              // 												: "-"
              // 										}
              // 									/>
              // 								</Stack>
              // 							)
              // 						)}

              // 						<Stack
              // 							direction="row"
              // 							alignItems="center"
              // 							justifyContent="center"
              // 							sx={{ gridGap: "15px" }}
              // 						>
              // 							<figure>
              // 								{employee?.contact?.companySkype ? (
              // 									<Box
              // 										component="img"
              // 										src="/assets/images/skype.png"
              // 										className="skype"
              // 										onClick={(e) => {
              // 											e.stopPropagation();
              // 											window.open(
              // 												`skype:${employee?.contact?.companySkype}?chat`,
              // 												"_blank",
              // 											);
              // 										}}
              // 										style={{ cursor: "pointer" }}
              // 									/>
              // 								) : (
              // 									""
              // 								)}

              // 								{/* <a
              // 									rel="noreferrer"
              // 									target="_blank"
              // 									href={employee?.contact?.skype}
              // 								>
              // 									<img
              // 										style={{
              // 											width: "24px",
              // 										}}
              // 										alt="skype"
              // 										src="/assets/images/skype.png"
              // 									/>
              // 								</a> */}
              // 							</figure>
              // 							<figure>
              // 								{employee?.contact?.linkedIn ? (
              // 									<Box
              // 										component="img"
              // 										src="/assets/images/linkedin.png"
              // 										className="linkdin"
              // 										onClick={(e) => {
              // 											e.stopPropagation();
              // 											window.open(
              // 												employee?.contact?.linkedIn,
              // 												"_blank",
              // 											);
              // 										}}
              // 										style={{ cursor: "pointer" }}
              // 									/>
              // 								) : (
              // 									""
              // 								)}
              // 								{/* <a
              // 									rel="noreferrer"
              // 									target="_blank"
              // 									href={employee?.contact?.linkedIn}
              // 								>
              // 									<img
              // 										style={{
              // 											width: "19px",
              // 										}}
              // 										alt="linkedin"
              // 										src="/assets/images/linkedin.png"
              // 									/>
              // 								</a> */}
              // 							</figure>
              // 							<figure>
              // 								{employee?.documents?.employeeResume &&
              // 									employee?.documents?.employeeResume?.length >
              // 										0 && (
              // 										<Box
              // 											component="img"
              // 											src="/assets/images/resume-download-icon.png"
              // 											className="download"
              // 											onClick={(e) => {
              // 												e.stopPropagation();
              // 												window.open(
              // 													employee?.documents?.employeeResume &&
              // 														employee?.documents?.employeeResume
              // 															?.length > 0 &&
              // 														employee?.documents?.employeeResume[0]
              // 															.webViewLink,
              // 													"_blank",
              // 												);
              // 											}}
              // 											style={{ cursor: "pointer" }}
              // 										/>
              // 									)}
              // 								{/* <img
              // 									style={{
              // 										width: "15px",
              // 									}}
              // 									alt="resume"
              // 									src="/assets/images/resume-download-icon.png"
              // 								/> */}
              // 							</figure>
              // 						</Stack>
              // 					</CardContent>
              // 				</Paper>
              // 			</Card>
              // 		</Grid>
              // 	);
              // }
            })
          )}

          {/* {loading && <Loader />} */}
        </Grid>
      </Container>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack className="delete-employee-popup" sx={style2}>
          <Typography variant="h6">{info?.title}</Typography>
          <ul style={{ paddingLeft: "19px" }}>
            {/* <ListItem>
              <ListItemText primary="All employee-related details will be deleted." />
            </ListItem>
			<ListItem>
              <ListItemText primary={`The candidate's status will be updated to Drop and state to Backed Out.`} />
            </ListItem>
			<ListItem>
              <ListItemText primary={`This action will also be stored in the candidate's activity logs.`} />
            </ListItem> */}
            {info?.description?.map((e, index) => (
              <li key={index}>{e}</li>
            ))}
          </ul>
          <TextField
            fullWidth
            multiline
            label="Note"
            aria-label="Note..."
            minRows={3}
            inputProps={{
              inputComponent: TextareaAutosize,
              maxLength: 512,
              style: {
                resize: "auto",
              },
            }}
            onChange={(e) => {
              setFieldValue("note", e.target.value || "");
              if (e.target.value) {
                setNoteError({ flag: false, title: "" });
              }
              if (e.target.value == "") {
                setNoteError({
                  flag: true,
                  title: "Please enter notes",
                });
              }
            }}
            helperText={noteError?.title && noteError?.title}
            error={Boolean(noteError?.flag && noteError?.flag)}
          />
          <Stack
            direction="row"
            spacing={2}
            justifyContent="end"
            sx={{ mt: 2 }}
          >
            <Button
              variant="outlined"
              // type="submit"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <LoadingButton
              // component={RouterLink}
              onClick={() => handleBackout2(values.note, emp)}
              variant="contained"
              sx={{ marginRight: "15px", width: "35px" }}
            >
              Yes
            </LoadingButton>
          </Stack>
        </Stack>
      </Modal>
    </>
  );
}

const componentConfig = {
  component: Employee,
  path: "/employee",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
