import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import ItemRow from "./itemComponents/ItemRow";
import Chip from "@mui/material/Chip";
import { debounce } from "lodash";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import CustomPagination from "../../components/Pagination";
import {
  deleteItem,
  getAllItems,
  getAssestItemCount,
} from "../../redux/assestManagment/thunk";
import {
  setItemStatus,
  setItemCurrentPage,
  setItemLimit,
  setItemOrderBy,
  setItemSortBy,
} from "../../redux/assestManagment/assestManagmentSlice";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";

import "./assetsItems.css";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import { encryption } from "../../utils/encodeString";
import { Card } from "@mui/material";

function Items() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const itemData = useSelector((state) => state.inventory.data);
  const itemStatus = useSelector((state) => state.inventory.status);
  const loading = useSelector((state) => state.inventory.loading);
  const totalRecordsItem = useSelector((state) => state.inventory.totalRecordsItem);
  const limitItem = useSelector((state) => state.inventory.limitItem);
  const totalPagesItem = useSelector((state) => state.inventory.totalPagesItem);
  const totalItem = useSelector((state) => state.inventory.totalItem);
  const currentPageItem = useSelector((state) => state.inventory.currentPageItem);
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const handleChangePage = (event, newPage) => {

    dispatch(setItemCurrentPage({ page: newPage+1}));
  };

  const handleChangeRowsPerPage = (event) => {
    // dispatch(setLimit({ limit: parseInt(event.target.value, 20) }));
    dispatch(setItemCurrentPage({ page: 0 }));
    dispatch(setItemLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("items")) {
      dispatch(getAllItems({ page: currentPageItem , limit: limitItem }));
      navigate("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (itemStatus === "idle") {
      dispatch(
        getAllItems({
          page: currentPageItem ,
          limit: limitItem,
          search : ""
        })
      );
    }
  }, [dispatch, limitItem,currentPageItem]);

  const handleDelete = async (id, name) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${name}?`,
      });
      await dispatch(deleteItem(id)).unwrap();
      dispatch(getAllItems({page: currentPageItem  ,
		limit: limitItem, }));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    // setFieldValue("search", e?.target?.value || "");
    // handleSubmit();
    setSearchValue(e?.target?.value);
    if (e?.target?.value != "") {
      setIsSearching(true);
      await dispatch(
        getAllItems({
          page: currentPageItem ,
          limit: limitItem,
          search : e?.target?.value
        })
      );
    } else {
      setIsSearching(false);
      await dispatch(
        getAllItems({
          page: currentPageItem ,
          limit: limitItem,
          search : ""
        })
      );
    }
    setSearchValue(e?.target?.value);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const onDeleteChip = async () => {
    setSearchValue("");
    setIsSearching(false);
    inputRef.current.value = "";
    await dispatch(
      getAllItems({
        page: currentPageItem ,
        limit: limitItem,
        search : ""
      })
    );
  };
  useEffect(() => {
    dispatch(getAssestItemCount());
  }, []);



//   if (loading) {
//     return <Loader />;
//   }
  return (
    <Container maxWidth="xl" className="item-component item-component-wrapper">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Items ({totalItem})
          {/* <Typography variant="body2">Total Records : {totalItem}</Typography> */}
        </Typography>

        {getAddVisible("items") && (
          <Button
            variant="contained"
            component={RouterLink}
            to="/items/add"
            // startIcon={<AddIcon />}
          >
            <img src="/assets/images/add-circle.svg" style={{marginRight: "8px"}} />
            Add Item
          </Button>
         
        )} </div>
      </Stack>
      <Grid container spacing={3}>
        <Grid item xs={12}>
        <Card className="custom-grid item-component-custom-grid">
        <Container style={{ maxWidth: "100%" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container xs={12} pt={2} pb={2}>
              <Grid item xs={12}>
              <TextField
                  className="employee-search-detail"
                  fullWidth
                  label="Search by Item Name"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearching ? (
          <Chip
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={() => onDeleteChip()}
          />
        ) : (
          ""
        )}
      </Stack>
      {isSearching ? (
        <Typography variant="body2" mb={1}>
          {totalRecordsItem} {"records found"}
        </Typography>
      ) : (
        ""
      )}
		 <Card className="custom-grid">
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Item</TableCell>
                  <TableCell>Status</TableCell>
                  {getEditVisible("items") && (
                    <TableCell align="right" style={{ width: "100px" }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>

			  {loading ? (
                <TableCell align="center" width="100%" colSpan={7}>
                  <Loader />
                </TableCell>
              ) :

              (
                itemData && itemData.length == 0 ? (
                  <TableRow>
                    <TableCell className="No-Record-text">No Record(s) Found</TableCell>
                  </TableRow>
                )

                :
                (itemData &&
                  itemData.map((row) => (
                    <ItemRow
                      handleDelete={handleDelete}
                      key={row.id}
                      row={row}
                    />
                  ))
                )
              )
                 }
              </TableBody>
            </Table>
            <CustomPagination
              rowsPerPageOptions={[10, 20, 40]}
              totalCount={totalRecordsItem}
              totalPage={totalPagesItem}
              limit={limitItem}
              page={ currentPageItem > 0 ? currentPageItem - 1 : 0}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </TableContainer>
		  </Card>
        </Grid>
      </Grid>
    </Container>
  );
}

const componentConfig = {
  component: Items,
  path: "/items",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
