import React, { useRef, useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import NiceModal from "@ebay/nice-modal-react";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import { getBloodGroup } from "../../../../redux/BloodGroup/bloddGroupThunk";
import { BASE_URL } from "../../../../constants";
import ImageCropModal from "../../../../components/ImageCrop";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getAllSeatNumberAllocation } from "../../../../redux/seatArrangment/seatThunk";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { encryption, decryption } from "../../../../utils/encodeString";
import { toast } from "react-toastify";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Divider } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { getAllparkingAllocation } from "../../../../redux/parkingAllocation/parkingThunk";
export default function Personal(props) {
  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleTabChange,
    getFieldProps,
    getImageUrl,
    getFileName,
    checkIfImage,
    selectedFileUrl,
    setSelectedFileUrl,
    selectedFile,
    setSelectedFile,
    handleBlur,
    id,
  } = props;
  const filter = createFilterOptions();
  const { status, data } = useSelector((state) => state.bloodGroup);
  const parkigSlot = useSelector(
    (payload) => payload?.parkingAllocation?.freeSlot
  );
  const allocatedSeats = useSelector((state) => state.seatAllocation.data);
  const [finalSeats, setFinalSeats] = useState([]);
  const [showProductKey, setshowProductKey] = useState(false);
  const [fildname, setFildname] = useState("");

  const dispatch = useDispatch();
  const maritalDetail = [
    "Married",
    "Unmarried",
    "Widow",
    "Widower",
    "Divorcee",
  ];
  const bank = ["Union Bank of India"];
  let vehicle = {
    vehicleType: "",
    vehicleName: "",
    vehicleNumber: "",
  };

  const blockList = ["B2", "B3"];

  let vehicleTypeList = [
    { title: "Car", value: "CAR" },
    { title: "Two Wheeler", value: "TWO_WHEELER" },
    { title: "Other", value: "OTHER" },
    { title: "Public Transport", value: "PUBLIC_TRANSPORT" },
  ];
  const locationDropdown = [
    { title: "HOME", id: 1 },
    { title: "OFFICE", id: 2 },
    { title: "HYBRID", id: 3 },
  ];
  const fileInput = useRef(null);
  const adharCardFileInput = useRef(null);
  const panCardFileInput = useRef(null);
  let seatData = [
    "A0",
    "A1",
    "A2",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "B1",
    "B2",
    "B3",
    "B4",
    "B5",
    "B6",
    "B7",
    "B8",
    "B9",
    "C0",
    "C1",
    "C2",
    "C3",
    "C4",
    "C5",
    "C6",
    "C7",
    "C8",
    "C9",
    "D1",
    "D2",
    "D3",
    "D4",
    "D5",
    "D6",
    "D7",
    "D8",
    "D9",
    "E0",
    "E1",
    "E2",
    "E3",
    "E4",
    "E5",
    "E6",
    "E7",
    "F0",
    "F1",
    "F2",
    "F3",
    "F4",
    "F5",
    "F6",
    "F7",
    "G1",
    "G2",
    "G3",
    "G4",
    "G5",
    "G6",
    "G7",
    "H0",
    "H1",
    "H2",
    "H3",
    "H4",
    "H5",
    "H6",
    "H7",
    "I1",
    "I2",
    "I3",
    "I4",
    "I5",
    "I6",
    "I7",
    "J0",
    "J1",
    "J2",
    "J3",
    "J4",
    "J5",
    "J6",
    "J7",
    "K1",
    "K2",
    "K3",
    "K4",
    "K5",
    "K6",
    "K7",
    "L0",
    "L1",
    "L2",
    "L3",
    "L4",
    "L5",
    "L6",
    "L7",
    "M1",
    "M2",
    "M3",
    "M4",
    "M5",
    "M6",
    "N1",
    "N2",
    "N3",
    "N4",
    "N5",
    "N6",
    "O1",
    "O2",
    "O3",
    "O4",
    "O5",
    "O6",
    "P1",
    "P2",
    "P3",
    "P4",
    "P5",
    "P6",
    "Q1",
    "Q2",
    "Q3",
    "Q4",
    "Q5",
    "Q6",
    "R1",
    "R2",
    "R3",
    "R4",
    "R5",
    "R6",
  ];

  useEffect(() => {
    let a = [];
    let temp = seatData;
    allocatedSeats.map((allocatedSeat) => {
      // dataItem.condition = dataItem.condition ? rulesMap[dataItem.condition] : null;
      seatData.forEach((seat) => {
        if (seat == allocatedSeat.seatNumber) {
          a = [...a, seat];
        }
      });
    });

    temp = temp.filter((val) => !a.includes(val));
    setFinalSeats(temp);
  }, [allocatedSeats]);

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", async () => {
        const res = await NiceModal.show(ImageCropModal, {
          image: reader.result?.toString() || "",
        });
        setSelectedFileUrl(
          window.URL.createObjectURL(
            new File([res.image], "newImage", {
              lastModified: new Date().getTime(),
              type: res.image.type,
            })
          )
        );
        setFieldValue(
          `employeeImage`,
          new File([res.image], "newImage", {
            lastModified: new Date().getTime(),
            type: res.image.type,
          })
        );
      });
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = "";
    }
  };

  const handleAdharCardImageChange = (e) => {
    setFieldValue("aadharCardImage", e.target.files[0]);
  };

  const handlePanCardImageChange = (e) => {
    setFieldValue("panCardImage", e.target.files[0]);
  };

  const fetchParkingandSeatData = async () => {
    dispatch(getAllparkingAllocation());
    dispatch(getAllSeatNumberAllocation());
  };

  useEffect(() => {
    // if (status === "idle") {
    dispatch(getBloodGroup({ sortBy: "", orderBy: "" }));
    // }
  }, [dispatch]);

  useEffect(() => {
    fetchParkingandSeatData();
  }, [dispatch]);

  const handleProductKey = () => {
    setshowProductKey(!showProductKey);
  };

  const addVehicle = () => {
    setFieldValue("vehicleData", [...values?.vehicleData, vehicle]);
  };

  const removeVehicle = (e, index) => {
    e.preventDefault();
    const temp = [...values?.vehicleData];
    temp.splice(index, 1);
    setFieldValue("vehicleData", temp);
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
      /* console.log("Copying text command was " + msg); */
    } catch (err) {
      toast.error("Text Not Copied!");
      /* console.log("Oops, unable to copy"); */
    }

    document.body.removeChild(textArea);
  };

  const checkIsCar = (data) => {
    let isCar = false;

    data?.map((ele) => {
      if (ele?.vehicleType === "CAR") {
        isCar = true;
      }
    });

    return isCar;
  };

  return (
    <Card className="right-side-content-part">
      <Container maxWidth="xl">
        <Typography
          className="Employee_Detail-h6"
          variant="h6"
          my={3}
          style={{ marginTop: "0" }}
        >
          Personal Details
        </Typography>
        <Grid
          className="gray-box Employee_Detail_Content Personal-Detail-content"
          container
          spacing={3}
        >
          <Grid className="grid-item col" item xs={12}>
            <div className="profile-image-part">
              <div className="circle" style={{ overflow: "hidden" }}>
                <Box
                  component="img"
                  src={
                    selectedFileUrl || "/assets/images/profile.png"
                    // values.employeeImage && values.employeeImage !== null
                    // 	? typeof values.employeeImage == "string"
                    // 		? `${BASE_URL}/${values.employeeImage}`
                    // 		: values.employeeImage[0]
                    // 	: "/assets/images/profile.png"
                  }
                />
                <div className="p-image">
                  <i
                    className="upload-button"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <PhotoCameraIcon />
                  </i>
                  <input
                    ref={fileInput}
                    className="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 64 }}
              value={values.firstName}
              onChange={handleChange}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="Middle Name"
              name="middleName"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 64 }}
              value={values.middleName}
              onChange={handleChange}
              error={Boolean(touched.middleName && errors.middleName)}
              helperText={touched.middleName && errors.middleName}
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 64 }}
              value={values.lastName}
              onChange={handleChange}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DesktopDatePicker
                label="Date of Birth"
                value={values.dateOfBirth}
                maxDate={new Date()}
                inputFormat="dd/MM/yyyy"
                // minDate={new Date('2017-01-01')}
                onChange={(newValue) => {
                  setFieldValue("dateOfBirth", newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    size="small"
                    error={Boolean(touched?.dateOfBirth && errors?.dateOfBirth)}
                    helperText={touched?.dateOfBirth && errors?.dateOfBirth}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="GitHub Profile"
              name="github"
              variant="outlined"
              size="small"
              value={values.github}
              inputProps={{ maxLength: 512 }}
              onChange={handleChange}
              error={Boolean(touched.github && errors.github)}
              helperText={touched.github && errors.github}
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="Portfolio Website"
              name="portfolioWebsite"
              variant="outlined"
              size="small"
              value={values.portfolioWebsite}
              inputProps={{ maxLength: 1024 }}
              onChange={handleChange}
              error={Boolean(
                touched.portfolioWebsite && errors.portfolioWebsite
              )}
              helperText={touched.portfolioWebsite && errors.portfolioWebsite}
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            {/*<InputLabel id="demo-simple-select-helper-label">
                                Blood Group
                            </InputLabel>
                            <Select
                                labelId="demo-simple-select-helper-label"
                                id="demo-simple-select-helper"
                                label="Blood Group"
                                size="small"
                                value={values.bloodGroup}
                                name="bloodGroup"
                                onChange={handleChange}
                            >
                                {data &&
                                    data.map((bldgrp) => {
                                        return (
                                            <MenuItem
                                                key={bldgrp.id}
                                                value={bldgrp.title}
                                            >
                                                {bldgrp.title}
                                            </MenuItem>
                                        );
                                    })}
                                </Select> */}
            <Autocomplete
              size="small"
              fullWidth
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              options={data || []}
              {...getFieldProps(`bloodGroup`)}
              //value={values.bloodGroup}
              value={data.find((bg) => bg.title === values.bloodGroup) ?? ""}
              getOptionLabel={(option) => option?.title || ""}
              onChange={(event, newValue) => {
                setFieldValue(`bloodGroup`, newValue?.title || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Blood Group"
                  error={Boolean(touched?.bloodGroup && errors?.bloodGroup)}
                  helperText={touched?.bloodGroup && errors?.bloodGroup}
                />
              )}
            />
          </Grid>
          <Grid className="grid-item col radio-select-space" item xs={6}>
            <FormControl
              error={Boolean(touched.gender && errors.gender)}
              component="fieldset"
              size="small"
            >
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                value={values.gender}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio size="small" />}
                  label="Male"
                  style={{ color: "#637381" }}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio size="small" />}
                  label="Female"
                  style={{ color: "#637381" }}
                />
              </RadioGroup>
              <FormHelperText>{touched.gender && errors.gender}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid className="Marital-Status-Details grid-item col" item xs={6}>
            {/*<InputLabel component="legend">
                                Marital Status
                            </InputLabel>
                            <Select
                                label="Marital Status"
                                size="small"
                                defaultValue=""
                                fullWidth
                                {...getFieldProps("maritalStatus")}
                            >
                                <MenuItem value="married">Married</MenuItem>
                                <MenuItem value="unmarried">Unmarried</MenuItem>
                                <MenuItem value="widow">Widow</MenuItem>
                                <MenuItem value="widower">Widower</MenuItem>
                                <MenuItem value="divorcee">Divorcee</MenuItem>
                            </Select>*/}

            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Marital Status"
              size="small"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              name="maritalStatus"
              onChange={(event, newValue) => {
                setFieldValue("maritalStatus", newValue || "");
              }}
              value={values.maritalStatus}
              options={maritalDetail || []}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Marital Status"
                  error={Boolean(touched.maritalStatus && errors.maritalStatus)}
                  helperText={touched.maritalStatus && errors.maritalStatus}
                />
              )}
            />
          </Grid>
          {values.maritalStatus === "Married" ? (
            <>
              <Grid className="grid-item col" item xs={6}>
                <TextField
                  label="Spouse Name"
                  name="spouseName"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 64 }}
                  fullWidth
                  value={values.spouseName}
                  onChange={handleChange}
                  error={Boolean(touched.spouseName && errors.spouseName)}
                  helperText={touched.spouseName && errors.spouseName}
                />
              </Grid>
              {!id ? (
                <Grid
                  className="Personal-Details-Cybercom-Password grid-item col"
                  item
                  xs={5}
                >
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type={showProductKey ? "text" : "password"}
                    label="Cybercom Password"
                    name="password"
                    value={values?.password}
                    onChange={handleChange}
                    variant="outlined"
                    // disabled={(id && showProductKey === false  && flagProductKey) ? true : false}
                    size="small"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleProductKey} edge="end">
                            {showProductKey ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  className="Personal-Details-Cybercom-Password grid-item col"
                  item
                  xs={5}
                >
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type={showProductKey ? "text" : "password"}
                    label="Cybercom Password"
                    name="password"
                    value={values?.password}
                    onChange={handleChange}
                    variant="outlined"
                    // disabled={(id && showProductKey === false  && flagProductKey) ? true : false}
                    size="small"
                    onBlur={handleBlur}

                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton onClick={handleProductKey} edge="end">
                    //         {showProductKey ? (
                    //           <VisibilityOffIcon />
                    //         ) : (
                    //           <VisibilityIcon />
                    //         )}
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
              )}
            </>
          ) : (
            <>
              {!id ? (
                <Grid
                  className="Personal-Details-Cybercom-Password grid-item col"
                  item
                  xs={5}
                >
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type={showProductKey ? "text" : "password"}
                    label="Cybercom Password"
                    name="password"
                    value={values?.password}
                    onChange={handleChange}
                    variant="outlined"
                    // disabled={(id && showProductKey === false  && flagProductKey) ? true : false}
                    size="small"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleProductKey} edge="end">
                            {showProductKey ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              ) : (
                <Grid
                  className="Personal-Details-Cybercom-Password grid-item col"
                  item
                  xs={5}
                >
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type={showProductKey ? "text" : "password"}
                    label="Cybercom Password"
                    name="password"
                    value={values?.password}
                    onChange={handleChange}
                    variant="outlined"
                    // disabled={(id && showProductKey === false  && flagProductKey) ? true : false}
                    size="small"
                    onBlur={handleBlur}
                    // InputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <IconButton onClick={handleProductKey} edge="end">
                    //         {showProductKey ? (
                    //           <VisibilityOffIcon />
                    //         ) : (
                    //           <VisibilityIcon />
                    //         )}
                    //       </IconButton>
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </Grid>
              )}
            </>
          )}

          {/* { (!id || values?.password == "" ) ?
          <> */}
        </Grid>

        {/* Document-info part */}
        <Typography className="Employee_Detail-h6" variant="h6" my={3}>
          Document
        </Typography>
        <Grid className="gray-box Document-content" container spacing={3}>
          <Grid className="grid-item col" item xs={6}>
            <Stack mb={1}>
              <TextField
                fullWidth
                label="Aadhar Card Number"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 12 }}
                {...getFieldProps("documents.aadharCardNumber")}
                error={Boolean(
                  touched?.documents?.aadharCardNumber &&
                    errors?.documents?.aadharCardNumber
                )}
                helperText={
                  touched?.documents?.aadharCardNumber &&
                  errors?.documents?.aadharCardNumber
                }
              />
            </Stack>
            <div
              className="custome-uploadBox profile-image-part"
              onClick={() => {
                values.aadharCardImage
                  ? window.open(values.aadharCardImage.webViewLink, "_blank")
                  : adharCardFileInput.current.click();
              }}
            >
              {values.aadharCardImage && (
                <>
                  {checkIfImage(values.aadharCardImage) ? (
                    <>
                      <InsertDriveFileIcon
                        onClick={(e) => {
                          window.open(
                            values.aadharCardImage.webViewLink,
                            "_blank"
                          );
                        }}
                      />
                      {/* <Box
												component="img"
												src={getImageUrl(values.aadharCardImage)}
											/> */}
                      <p>{getFileName(values.aadharCardImage)}</p>
                    </>
                  ) : (
                    <>
                      <InsertDriveFileIcon
                        onClick={(e) => {
                          window.open(
                            values.aadharCardImage.webViewLink,
                            "_blank"
                          );
                        }}
                      />
                      <p>{getFileName(values.aadharCardImage)}</p>
                    </>
                  )}
                </>
              )}
              {values.aadharCardImage && (
                <CancelRoundedIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setFieldValue("aadharCardImage", null);
                  }}
                  className="closeIcon"
                />
              )}
              <div className="p-image">
                {!values.aadharCardImage && (
                  <i className="upload-button">
                    <CloudUploadIcon />
                  </i>
                )}

                <input
                  ref={adharCardFileInput}
                  className="file-upload"
                  type="file"
                  accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
                  onChange={handleAdharCardImageChange}
                />
              </div>

              {!values.aadharCardImage && (
                <div className="file-support-text">
                  (File support: pdf, png and jpg with max 20MB size)
                </div>
              )}
            </div>

            <FormHelperText
              error={Boolean(
                touched?.aadharCardImage && errors?.aadharCardImage
              )}
            >
              {touched?.aadharCardImage && errors?.aadharCardImage}
            </FormHelperText>
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <Stack mb={1}>
              <TextField
                fullWidth
                label="Pan Card Number"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 10 }}
                {...getFieldProps("documents.panCardNumber")}
                error={Boolean(
                  touched?.documents?.panCardNumber &&
                    errors?.documents?.panCardNumber
                )}
                helperText={
                  touched?.documents?.panCardNumber &&
                  errors?.documents?.panCardNumber
                }
              />
            </Stack>
            <div
              className="custome-uploadBox profile-image-part"
              onClick={() => {
                values.panCardImage
                  ? window.open(values.panCardImage.webViewLink, "_blank")
                  : panCardFileInput.current.click();
              }}
            >
              {values.panCardImage && (
                <>
                  {checkIfImage(values.panCardImage) ? (
                    <>
                      <InsertDriveFileIcon />
                      {/* <Box
												component="img"
												src={getImageUrl(values.panCardImage)}
											/> */}
                      <p>{getFileName(values.panCardImage)}</p>
                    </>
                  ) : (
                    <>
                      <InsertDriveFileIcon />
                      <p>{getFileName(values.panCardImage)}</p>
                    </>
                  )}
                </>
              )}
              {values.panCardImage && (
                <CancelRoundedIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setFieldValue("panCardImage", null);
                  }}
                  className="closeIcon"
                />
              )}
              <div className="p-image">
                {!values.panCardImage && (
                  <i className="upload-button">
                    <CloudUploadIcon />
                  </i>
                )}
                <input
                  ref={panCardFileInput}
                  className="file-upload"
                  type="file"
                  accept=".gif, .doc, .docx, .pdf, .jpeg, .jpg, .png"
                  onChange={handlePanCardImageChange}
                />
              </div>
              {!values.panCardImage && (
                <div className="file-support-text">
                  (File support: pdf, png and jpg with max 20MB size)
                </div>
              )}
            </div>
            <FormHelperText
              error={Boolean(touched?.panCardImage && errors?.panCardImage)}
            >
              {touched?.panCardImage && errors?.panCardImage}
            </FormHelperText>
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            {/*InputLabel id="demo-simple-select-helper-label">
                            Bank Name
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            label="Bank Name"
                            size="small"
                            {...getFieldProps("documents.bankName")}
                        >
                            <MenuItem value="Union Bank of India">
                                Union Bank of India
                            </MenuItem>
                        </Select> */}

            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="bankName"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              size="small"
              onChange={(event, newValue) => {
                // setFieldValue(`documents.bankName`, newValue);
                if (typeof newValue === "string") {
                  // if(newValue.trim())
                  setFieldValue(`documents.bankName`, newValue.trim());
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setFieldValue(
                    `documents.bankName`,
                    newValue.inputValue.trim()
                  );
                } else {
                  setFieldValue(
                    `documents.bankName`,
                    newValue ? newValue.trim() : ""
                  );
                }
              }}
              value={values.documents?.bankName || ""}
              options={bank || []}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.title
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    title: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.title;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Bank Name"
                  error={Boolean(
                    touched?.documents?.bankName && errors?.documents?.bankName
                  )}
                  helperText={
                    touched?.documents?.bankName && errors?.documents?.bankName
                  }
                />
              )}
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="Bank Account Number"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 256 }}
              {...getFieldProps("documents.bankAccountNumber")}
              error={Boolean(
                touched?.documents?.bankAccountNumber &&
                  errors?.documents?.bankAccountNumber
              )}
              helperText={
                touched?.documents?.bankAccountNumber &&
                errors?.documents?.bankAccountNumber
              }
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="IFSC Code"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 11 }}
              {...getFieldProps("documents.IFSCCode")}
              error={Boolean(
                touched?.documents?.IFSCCode && errors?.documents?.IFSCCode
              )}
              helperText={
                touched?.documents?.IFSCCode && errors?.documents?.IFSCCode
              }
            />
          </Grid>
          <Grid className="grid-item col" item xs={6}>
            <TextField
              fullWidth
              label="Bank Branch"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 32 }}
              {...getFieldProps("documents.bankBranch")}
              error={Boolean(
                touched?.documents?.bankBranch && errors?.documents?.bankBranch
              )}
              helperText={
                touched?.documents?.bankBranch && errors?.documents?.bankBranch
              }
            />
          </Grid>
        </Grid>

        {/* Misc. fields part */}
        <Typography className="Employee_Detail-h6" variant="h6" my={3}>
          Misc. Fields
        </Typography>
        <Grid className="gray-box Misc-Fields-content" container spacing={3}>
          <Grid className="grid-item col" item xs={6}>
            <Autocomplete
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              size="small"
              popupIcon={
                <img src="/assets/images/arrow-down.svg" alt="dropdown" />
              }
              options={locationDropdown || []}
              getOptionLabel={(option) => option.title || ""}
              onChange={(event, newValue) => {
                setFieldValue("workLocation", newValue?.id || "");
                // if(newValue?.id === 1) {
                //   setFieldValue("seatNumber","");
                // }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Work Location"
                  error={Boolean(touched.workLocation && errors.workLocation)}
                  helperText={touched.workLocation && errors.workLocation}
                />
              )}
              value={locationDropdown.find(
                (location) => location.id == values.workLocation
              )}
            />
          </Grid>
          {values.workLocation === 2 || values.workLocation === 3 ? (
            <>
              {/* <Grid item xs={6} >
              <TextField
                label="Seat Number"
                name="seatNumber"
                variant="outlined"
                size="small"
                inputProps={{ maxLength: 64 }}
                fullWidth
                value={values.seatNumber}
                onChange={handleChange}
                error={Boolean(touched.seatNumber && errors.seatNumber)}
                helperText={touched.seatNumber && errors.seatNumber}
              />
            </Grid> */}

              <Grid className="grid-item col" item xs={4} mb={3}>
                <Autocomplete
                  size="small"
                  fullWidth
                  defaultValue=""
                  options={finalSeats ? finalSeats : seatData}
                  // getOptionLabel={(option) => option?.firstName ?
                  //   `${option?.firstName} ${option?.lastName}` : ""
                  // }
                  onChange={(event, newValue) => {
                    setFieldValue(`seatNumber`, newValue || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Seat Number"
                      error={Boolean(touched?.seatNumber && errors?.seatNumber)}
                      helperText={touched?.seatNumber && errors?.seatNumber}
                    />
                  )}
                  value={seatData?.find((x) => x === values?.seatNumber) || ""}
                />
              </Grid>
            </>
          ) : (
            ""
          )}
        </Grid>

        <Typography className="Employee_Detail-h6" variant="h6" my={3}>
          Vehicle Details
        </Typography>

        <Grid
          className="gray-box mail-Detail-content"
          container
          spacing={3}
          mb={3}
        >
          {values?.vehicleData?.map((val, index) => (
            <React.Fragment key={index}>
              <Grid item className="grid-item col" xs={2.5}>
                <Autocomplete
                  size="small"
                  fullWidth
                  defaultValue=""
                  options={vehicleTypeList}
                  getOptionLabel={(option) => option?.title}
                  onChange={(event, newValue) => {
                    if (
                      newValue?.value === "TWO_WHEELER" &&
                      !checkIsCar(values?.vehicleData)
                    ) {
                      setFieldValue(`parkingBlock`, "");
                      setFieldValue(`parkingNumber`, "");
                    }
                    setFieldValue(
                      `vehicleData[${index}]vehicleType`,
                      newValue?.value || ""
                    );

                    setFieldValue(`vehicleData[${index}]vehicleName`, "");
                    setFieldValue(`vehicleData[${index}]vehicleNumber`, "");
                    setFieldValue(`vehicleData[${index}]remarks`, "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vehicle Type"
                      error={Boolean(
                        touched.vehicleData &&
                          touched.vehicleData[index]?.vehicleType &&
                          errors.vehicleData &&
                          errors.vehicleData[index]?.vehicleType
                      )}
                      helperText={
                        touched.vehicleData &&
                        touched.vehicleData[index]?.vehicleType &&
                        errors.vehicleData &&
                        errors.vehicleData[index]?.vehicleType
                      }
                    />
                  )}
                  value={
                    vehicleTypeList?.find(
                      (e) =>
                        e?.value === values?.vehicleData[index]?.vehicleType
                    ) || null
                  }
                />
              </Grid>
               <Grid className="grid-item col" item xs={2.5}>
                <TextField
                  fullWidth
                  label="Vehicle Name"
                  variant="outlined"
                  size="small"
                  disabled={val.vehicleType == "OTHER" || val.vehicleType == "PUBLIC_TRANSPORT"}
                  inputProps={{ maxLength: 32 }}
                  {...getFieldProps(`vehicleData[${index}].vehicleName`)}
                  error={Boolean(
                    touched.vehicleData &&
                      touched.vehicleData[index]?.vehicleName &&
                      errors.vehicleData &&
                      errors.vehicleData[index]?.vehicleName
                  )}
                  helperText={
                    touched.vehicleData &&
                    touched.vehicleData[index]?.vehicleName &&
                    errors.vehicleData &&
                    errors.vehicleData[index]?.vehicleName
                  }
                />
              </Grid>

              <Grid className="grid-item col" item xs={2.5}>
                <TextField
                  fullWidth
                  label="Vehicle Number"
                  variant="outlined"
                  size="small"
                  disabled={val.vehicleType == "OTHER" || val.vehicleType == "PUBLIC_TRANSPORT"}
                  inputProps={{ maxLength: 32 }}
                  {...getFieldProps(`vehicleData[${index}].vehicleNumber`)}
                  error={Boolean(
                    touched.vehicleData &&
                      touched.vehicleData[index]?.vehicleNumber &&
                      errors.vehicleData &&
                      errors.vehicleData[index]?.vehicleNumber
                  )}
                  helperText={
                    touched.vehicleData &&
                    touched.vehicleData[index]?.vehicleNumber &&
                    errors.vehicleData &&
                    errors.vehicleData[index]?.vehicleNumber
                  }
                />
              </Grid>

              <Grid className="grid-item col" item xs={3}>
                <TextField
                  fullWidth
                  label="Remarks"
                  variant="outlined"
                  size="small"
                  inputProps={{ maxLength: 32 }}
                  {...getFieldProps(`vehicleData[${index}].remarks`)}
                />
              </Grid>

              <Grid item className="grid-item col" xs={1.5}>
                {values?.vehicleData?.length !== 1 && (
                  <IconButton onClick={(e) => removeVehicle(e, index)}>
                    <RemoveIcon></RemoveIcon>
                  </IconButton>
                )}
                {values?.vehicleData?.length - 1 === index && (
                  <IconButton onClick={() => addVehicle()}>
                    <AddIcon></AddIcon>
                  </IconButton>
                )}

                {/* <Stack
											direction="row"
											alignItems="center"
										
										>
											{values &&
												Array.isArray(values?.vehicleData) &&
												 index > 0 && (
													<IconButton onClick={(e) => removeVehicle(e, index)}>
														<DeleteIcon color="error" />
													</IconButton>
												)}
										</Stack> */}
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
        {checkIsCar(values?.vehicleData) && (
          <>
            <Typography className="Employee_Detail-h6" variant="h6" my={3}>
              Allocated Parking
            </Typography>

            <Grid
              className="gray-box mail-Detail-content"
              container
              spacing={3}
              mb={3}
            >
              <Grid item className="grid-item col" xs={6}>
                <Autocomplete
                  size="small"
                  defaultValue=""
                  options={blockList || []}
                  onChange={(event, newValue) => {
                    setFieldValue(`parkingBlock`, newValue || "");
                    setFieldValue(`parkingNumber`, "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Location"
                      error={Boolean(
                        touched?.parkingBlock && errors?.parkingBlock
                      )}
                      helperText={touched?.parkingBlock && errors?.parkingBlock}
                    />
                  )}
                  value={values?.parkingBlock || ""}
                />
              </Grid>
              <Grid item className="grid-item col" xs={6}>
                <Autocomplete
                  size="small"
                  defaultValue=""
                  options={parkigSlot[values?.parkingBlock] || []}
                  onChange={(event, newValue) => {
                    console.log("newValue", newValue);
                    setFieldValue(`parkingNumber`, newValue || "");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Parking Number"
                      error={Boolean(
                        touched?.parkingNumber && errors?.parkingNumber
                      )}
                      helperText={
                        touched?.parkingNumber && errors?.parkingNumber
                      }
                    />
                  )}
                  value={values?.parkingNumber || ""}
                />
              </Grid>
            </Grid>
          </>
        )}
        <Stack direction="row" justifyContent="flex-end" my={2}>
          <Button
            onClick={() => handleTabChange("2")}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </Stack>
      </Container>
    </Card>
  );
}
