import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";
import ClearIcon from "@mui/icons-material/Clear";
import Loader from "../../components/Loader";
import DashboardLayout from "../../layouts/dashboard";
import { useParams, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import {
  getJobDes,
  deleteById,
  getJobDescCount,
} from "../../redux/jobDescription/jobDesThunk";
import { encryption } from "../../utils/encodeString";
import invertDirection from "../../utils/invertDirection";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import parse from "html-react-parser";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Popover from "@mui/material/Popover";
import { setStatus } from "../../redux/jobDescription/jobDesSlice";
import Chip from "@mui/material/Chip";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import "./jobDescription.css";
import {
  getById,
  updateById,
  insert,
} from "../../redux/jobDescription/jobDesThunk";
import {
  setOrderBy,
  setSortBy,
  setCurrentPage,
  setLimit,
} from "../../redux/jobDescription/jobDesSlice";
import {
  Autocomplete,
  Avatar,
  Divider,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import AddCandidate from "./JobDescriptionComponent/AddCandidate";
import AddActivity from "./JobDescriptionComponent/AddActivity";
import jobCandidateSchema, {
  // checkActivityDetails,
  checkPersonalDetails,
} from "../../validations/jobCandidateSchema";
import {
  insertCandidate,
  getCandidateById,
  addCandidateJd,
  getCandidate,
  uploadCandidateImage,
} from "../../redux/candidate/candidateThunk";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { debounce } from "lodash";
import { getDepartment } from "../../redux/department/departmentThunk";
import { getAllMemberDropdown } from "../../redux/employee/employeeThunk";
import { BASE_URL } from "../../constants";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import LocalStorage from "../../service/localStorage";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  maxHeight: "80vh",
  maxWidth: "80%",
  width: "70%",
  overflow: "scroll",
  scrollbarWidth: "none",
  p: 4,
};
const stylehr = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};
const styleThird = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function JobDescription() {
  const confirm = useConfirm();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [jobId, setJobId] = useState("");
  const [value, setValue] = useState({});
  const [submitted, setSubmitted] = React.useState(false);
  const [jobStatus, setJobStatus] = useState(false);
  const [jobCloseStatus, setJobCloseStatus] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const designationData = useSelector((state) => state.designation);
  const [designationDisable, setDesignationDisable] = useState(true);
  const [candidateResume, setCandidateResume] = useState(null);
  const [toBeDeletedCandidateResume, setToBeDeletedCandidateResume] =
    useState(null);
  const [openSecondModal, setOpenSecondModel] = useState(false);
  const handleOpenSecondModal = () => setOpenSecondModel(true);
  const handleCloseSecondModal = () => setOpenSecondModel(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openThirdModal, setOpenThirdModel] = useState(false);
  const handleOpenThirdModal = () => setOpenThirdModel(true);
  const handleCloseThirdModal = () => {
    setOpenThirdModel(false);
    setJobStatus(false);
  };
  const [openSecondHrModal, setOpenSecondHrModel] = useState(false);
  const handleOpenSecondHrModal = () => {
    let selectids = value?.hiringTeam?.map((element) => element?.employee?.id);
    // console.log(selectids,'selectids');
    let filterdate = MemberDropdown.filter((element) =>
      selectids?.includes(element?.id)
    );
    // console.log(MemberDropdown,'MemberDropdown');
    // console.log(filterdate,"filterdate");
    setSelectedHr(filterdate);
    setOpenSecondHrModel(true);
  };
  const handleCloseSecondHrModal = () => {
    handleCloseClick();
    setSelectedHr([]);
    setOpenSecondHrModel(false);
  };
  const [openSixModal, setOpenSixModel] = useState(false);
  const handleOpenSixModal = () => setOpenSixModel(true);
  const handleCloseSixModal = () => setOpenSixModel(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openPopOver = Boolean(anchorEl);
  const id = openPopOver ? "simple-popover" : undefined;
  const [isSearching, setIsSearching] = useState(false);
  const [expanded, setExpanded] = React.useState("panel1");
  const departmentStatus = useSelector((state) => state.department.status);
  const departmentData = useSelector((state) => state.department.data);
  const MemberDropdown = useSelector((state) => state.employee.MemberDropdown);
  const [candidateUpdateId, setCandidateUpdateId] = useState(null);
  const [selectedHr, setSelectedHr] = useState([]);
  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const totalCounts = useSelector((state) => state.jobDescCount.totalCounts);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("jobDescription")) {
      dispatch(
        getJobDes({
          // page: currentPage + 1,
          // limit: limit,
          // limit: 10,
          sortBy,
          orderBy,
          search: searchsting,
          jobStatus: selectedFilter,
          seniority: selectedSeniorityLevelFilter,
          employment: selectedEmploymentFilter,
          departmentId: selectedDepartmentFilter,
        })
      );
      navigation("/dashboard");
    }
  }, []);

  useEffect(() => {
    if (departmentStatus === "idle") {
      getViewVisible("department") && dispatch(getDepartment());
    }
  }, [dispatch]);
  useEffect(() => {
    // if (MemberDropdownStatus === "idle") {
    dispatch(getAllMemberDropdown());
    // }
  }, [dispatch]);

  useEffect(() => {
    dispatch(getJobDescCount({}));
  }, [dispatch]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseClick = () => {
    setAnchorEl(null);
  };
  const {
    data,
    totalRecords,
    currentPage,
    limit,
    status,
    loading,
    sortBy,
    orderBy,
  } = useSelector((state) => state.jobDes);
  const state = useSelector((state) => state.jobDes);

  const [personal, setPersonal] = useState({});
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      dateOfBirth: null,
      gender: "",
      contactNumber: null,
      alternateContactNumber: "",
      email: "",
      skype: "",
      linkedIn: "",
      totalExperience: 0,
      technologies: [],
      candidateJobs: [],
      referedBy: "",
      // activity: {
      //   approachDate: null,
      //   candidateStatusId: "",
      //   candidateStateId: null,
      //   note: "",
      //   futureDate: null,
      // },
    },
    validationSchema: jobCandidateSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // let response;
        let imageId;
        if (!values.contactNumber) {
          values.contactNumber = null;
        }
        if (!values.alternateContactNumber) {
          values.alternateContactNumber = "";
        }
        if (!id) {
          // let response = await dispatch(
          //   addCandidateJd({ candidateResume: candidateResume, values })
          // );
          let response = await dispatch(
            addCandidateJd({
              candidateResume: candidateResume?.id
                ? JSON.stringify(candidateResume)
                : candidateResume,
              values,
              id: candidateUpdateId,
            })
          );
          // handleCloseSecondModal();
          // if (id) {
          //   response = await dispatch(addCandidateJd({ values })).unwrap();
          //   // imageId = id;
          // }
          // if (selectedFile || candidateResume || toBeDeletedCandidateResume)
          //   dispatch(
          //     uploadCandidateImage({
          //       id: imageId,
          //       file: selectedFile,
          //       candidateResume: candidateResume,
          //       toBeDeletedCandidateResume: toBeDeletedCandidateResume,
          //     })
          //   );

          if (response.payload.status === 200) {
            resetForm();
            setCandidateResume(null);
            setCandidateUpdateId(null);
            handleCloseSecondModal();
            dispatch(
              getJobDes({
                sortBy,
                orderBy,
              })
            );
          }
        }
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const seniorityLevel = [
    { id: "", title: "All" },
    { id: "Intern", title: "Intern" },
    { id: "Entry Level", title: "Entry Level" },
    { id: "Associate", title: "Associate" },
    { id: "Mid-Senior Level", title: "Mid-Senior Level" },
    { id: "Senior Level", title: "Senior Level" },
  ];
  const filterArray = [
    { id: "", title: "All" },
    { id: "Created", title: "Created" },
    { id: "Published", title: "Published" },
    { id: "Closed", title: "Closed" },
  ];
  const employmentType = [
    { id: "", title: "All" },
    { id: "Full Time", title: "Full Time" },
    { id: "Part Time", title: "Part Time" },
    { id: "Freelancer", title: "Freelancer" },
  ];
  const [selectedFilter, setSelectedFilter] = useState("Created");
  const [jobList, setJobList] = useState([]);
  const [selectedSeniorityLevelFilter, setSelectedSeniorityLevelFilter] =
    useState("");
  const inputRef = React.useRef(null);
  const [selectedEmploymentFilter, setSelectedEmploymentFilter] = useState("");
  const [selectedDepartmentFilter, setSelectedDepartmentFilter] = useState("");
  let [searchsting, setSearchString] = useState("");
  const [filterData, setFilterData] = useState(null);
  const [filterChips, setFilterChips] = useState([]);
  useEffect(() => {
    let searchParams = {};
    searchParams.search = searchsting;
    searchParams.selectedFilter = selectedFilter;
    searchParams.selectedSeniorityLevelFilter = selectedSeniorityLevelFilter;
    searchParams.selectedEmploymentFilter = selectedEmploymentFilter;
    searchParams.selectedDepartmentFilter = selectedDepartmentFilter;

    prepareChips(searchParams);
    setFilterData({ ...searchParams });
    dispatch(
      getJobDes({
        // page: currentPage + 1,
        // limit: limit,
        // limit: 10,
        sortBy,
        orderBy,
        search: searchsting,
        jobStatus: selectedFilter,
        seniority: selectedSeniorityLevelFilter,
        employment: selectedEmploymentFilter,
        departmentId: selectedDepartmentFilter,
      })
    );
  }, [
    selectedFilter,
    searchsting,
    selectedSeniorityLevelFilter,
    selectedEmploymentFilter,
    selectedDepartmentFilter,
    sortBy,
    orderBy,
  ]);
  const {
    handleSubmit,
    getFieldProps,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    isSubmitting,
    touched,
    initialValues,
    setErrors,
    setFieldTouched,
    setFieldError,
    resetForm,
  } = formik;
  // useEffect(() => {
  //   if (status === "idle") {
  //     dispatch(
  //       getJobDes({
  //         // page: currentPage + 1,
  //         // limit: limit,
  //         // limit: 10,
  //         sortBy,
  //         orderBy,
  //       })
  //     );
  //   }
  // }, [sortBy, orderBy, status]);

  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteById(id));
      await dispatch(
        getJobDes({
          // page: currentPage + 1,
          // limit: limit,
          // limit: 10,
          sortBy,
          orderBy,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const prepareChips = (searchParams) => {
    let filters = { ...searchParams };
    let tempChips = [];
    searchParams.search = searchsting;
    searchParams.selectedFilter = selectedFilter;
    searchParams.selectedSeniorityLevelFilter = selectedSeniorityLevelFilter;
    searchParams.selectedEmploymentFilter = selectedEmploymentFilter;
    searchParams.selectedDepartmentFilter = selectedDepartmentFilter;
    for (const key in filters) {
      if (filters[key] && key === "search") {
        filters[key] = filters[key];
      }

      if (filters[key] && key === "selectedFilter") {
        filters[key] = `Job Status : ${filters[key]}`;
      }
      if (filters[key] && key === "selectedSeniorityLevelFilter") {
        filters[key] = `Seniority Level : ${filters[key]}`;
      }
      if (filters[key] && key === "selectedEmploymentFilter") {
        filters[key] = `Employment Type  : ${filters[key]}`;
      }

      if (filters[key] && key === "selectedDepartmentFilter") {
        let department = departmentData.find(
          (dep) => dep.id === searchParams.selectedDepartmentFilter
        );
        filters[key] = `Department : ${department.title}`;
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

    setFilterChips(tempChips);
  };

  let changeJobStatus = (value) => {
    setValue(value);
    handleOpenThirdModal();
  };
  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
    // setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  let viewPreview = (value) => {
    setValue(value);
    handleOpen();
  };

  let popOverModal = (e, value) => {
    setValue(value);
    setJobId(value.id);
    handleClick(e);
  };
  let submitFunction = async () => {
    if (jobStatus) {
      let hiringTeam = value.hiringTeam
        .map((s) => s?.employee?.id || "")
        .filter((e) => e !== "");
      let finalRequest = {
        ...value,
        hiringTeam: hiringTeam,
        jobStatus: jobStatus ? "PUBLISHED" : "CREATED",
      };
      await dispatch(updateById({ ...finalRequest })).unwrap();
    }

    dispatch(setStatus({ status: "idle" }));
    handleCloseThirdModal();
    // resetForm();
    // navigation("/job-description");
  };

  let updateHrInJd = async () => {
    if (selectedHr[0]) {
      let hiringTeam = selectedHr.map((s) => s.id);
      let finalRequest = {
        ...value,
        hiringTeam: hiringTeam,
        // jobStatus: jobStatus ? "PUBLISHED" : "CREATED",
      };
      await dispatch(updateById({ ...finalRequest })).unwrap();
      dispatch(setStatus({ status: "idle" }));
      setSelectedHr([]);
      handleCloseSecondHrModal();
    } else {
      toast.error("Please Select HR");
    }
  };
  useEffect(() => {
    setJobList(data);
  }, [data]);

  let closeJobStatus = async () => {
    if (jobCloseStatus) {
      let hiringTeam = value.hiringTeam.map((s) => s?.employee?.id);
      let finalRequest = {
        ...value,
        hiringTeam: hiringTeam,
        jobStatus: "CLOSED",
      };
      await dispatch(updateById({ ...finalRequest })).unwrap();
    }
    dispatch(setStatus({ status: "idle" }));
    handleCloseThirdModal();
    setJobCloseStatus(false);
    handleCloseSixModal();
  };
  // console.log(value,"darshit");

  const handleSearch = async (e) => {
    setIsSearching(true);
    setSearchString(e.target.value);
    // if (e?.target?.value.length >= 3) {

    // let filterData = data.filter((element) => {
    // 	return (
    // 		element.title.toLowerCase().includes(e.target.value.toLowerCase()) ||
    // 		element.jobId.toLowerCase().includes(e.target.value.toLowerCase())
    // 	);
    // });
    // setJobList(filterData);

    // }
    // await dispatch(
    //   getInterviewList({
    //     search: e?.target?.value,
    //     limit,
    //     page: page + 1,
    //     orderBy,
    //     sortBy,
    //     ...params,
    //   })
    // );
  };

  const removeHR = (element) => {
    let tempArray = selectedHr.filter((x) => {
      return x.id !== element.id;
    });
    setSelectedHr(tempArray);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const onDeleteChip = (value, length) => {
    let { type } = value;
    delete filterData[value.type];
    setFilterData({ ...filterData });
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    setFilterChips([...updateChips]);
    dispatch(setCurrentPage({ page: 0 }));

    if (type === "search") {
      setSearchString("");
      inputRef.current.value = "";
    }

    if (type === "selectedFilter") {
      setSelectedFilter("");
    }
    if (type === "selectedSeniorityLevelFilter") {
      setSelectedSeniorityLevelFilter("");
    }
    if (type === "selectedEmploymentFilter") {
      setSelectedEmploymentFilter("");
    }

    if (type === "selectedDepartmentFilter") {
      setSelectedDepartmentFilter("");
    }
  };

  return (
    <Container
      className="Employee-wrapper Job-Description-Container"
      maxWidth="xl"
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
        <div className="title-button-wrapper Job-Description-title">
          <Typography
            className="Employee_Detail-h6"
            variant="h4"
            gutterBottom
            style={{ marginTop: "0" }}
          >
            Job Description ({totalCounts})
            {/* <Typography variant="body2">Total Records : {totalCounts}</Typography> */}
          </Typography>
          {getAddVisible("jobDescription") && (
            <Button
              className="header-add-button"
              component={Link}
              to="/job-description/add"
              variant="contained"
              // startIcon={<AddIcon />}
            >
              <img src="/assets/images/add-circle.svg" />
              Add job description
            </Button>
          )}
        </div>
      </Stack>
      <Card
        className="Job-Description-main-card gray-box Employee_Detail_Content"
        sx={{ mb: 2 }}
      >
        <Stack>
          <Container>
            {/* <form onSubmit={(e) => e.preventDefault()}> */}
            <Grid className="Job-Description-search-grid" container xs={12}>
              <Grid className="employee-search-detail" item xs={4}>
                <TextField
                  fullWidth
                  label="Search by Title/Job Id"
                  name="search"
                  variant="outlined"
                  inputRef={inputRef}
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
              <Grid className="Job-Description-grid-right" item xs={8}>
                <Stack
                  className="Job-Description-grid-row"
                  direction="row"
                  alignItems="center"
                >
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    // sx={{ backgroundColor: "white" }}
                    options={filterArray || []}
                    getOptionLabel={(option) => option.title || ""}
                    onChange={(event, newValue) => {
                      setSelectedFilter(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Job Status" />
                    )}
                    value={filterArray.find(
                      (dept) => dept.id === selectedFilter
                    )}
                  />

                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={seniorityLevel || []}
                    // onBlur={handleBlur}
                    getOptionLabel={(option) => option.title || ""}
                    onChange={(event, newValue) => {
                      setSelectedSeniorityLevelFilter(newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Seniority Level" />
                    )}
                    value={seniorityLevel.find(
                      (dept) => dept.id === selectedSeniorityLevelFilter
                    )}
                    // error={true}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid className="Job-Description-employee-grid" item xs={12}>
              <Stack
                direction="row"
                spacing={2}
                alignItems="center"
                marginBottom="10px"
              >
                <Autocomplete
                  size="small"
                  fullWidth
                  popupIcon={
                    <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                  }
                  // sx={{ backgroundColor: "white" }}
                  options={employmentType || []}
                  getOptionLabel={(option) => option.title || ""}
                  onChange={(event, newValue) => {
                    setSelectedEmploymentFilter(newValue?.id || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Employment Type" />
                  )}
                  value={employmentType.find(
                    (dept) => dept.id === selectedEmploymentFilter
                  )}
                />

                <Autocomplete
                  size="small"
                  fullWidth
                  popupIcon={
                    <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                  }
                  disabled={getViewVisible("department") === false}
                  options={departmentData || []}
                  getOptionLabel={(option) => option.title || ""}
                  onChange={(event, newValue) => {
                    setSelectedDepartmentFilter(newValue?.id || "");
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Department" />
                  )}
                  value={departmentData?.find(
                    (dept) => dept.id === selectedDepartmentFilter
                  )}
                  // error={true}
                />
              </Stack>
            </Grid>
            {/* </form> */}
          </Container>
        </Stack>
      </Card>

      <Stack className="search-filter" direction="row" alignItems="start">
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
              className="search-filter-detail"
            />
          );
        })}
      </Stack>
      {filterChips.length > 0 ? (
        <Typography className="record-found-text" variant="body2">
          {jobList.length} {"records found"}
        </Typography>
      ) : (
        ""
      )}

      {loading ? (
        <Loader />
      ) : (
        <Stack
          className="Job-Description-data-detail"
          direction="row"
          sx={{ flexWrap: "wrap", ml: 0, justifyContent: "space-between" }}
        >
          {Array.isArray(jobList) && jobList.length === 0 && !isSearching ? (
            <TableRow>
              <TableCell className="No-Record-text" align="center" colSpan={4}>
                No Record(s) Found
              </TableCell>
            </TableRow>
          ) : (
            jobList.map((jobDes, idx) => (
              <Card
                sx={{ flex: "0 0 30%", mb: 2, mr: 3 }}
                key={jobDes.id}
                style={{ padding: "15px", cursor: "pointer" }}
                className="job-blk gray-box Employee_Detail_Content"
                onClick={() =>
                  getAddVisible("candidates") &&
                  navigation(`/job-description-details/${jobDes?.jobId}`, {
                    state: {
                      id: jobDes?.id,
                      name: jobDes?.title,
                      hrList:
                        jobDes?.hiringTeam &&
                        jobDes.hiringTeam[0] &&
                        jobDes.hiringTeam
                          .map(
                            (employee) =>
                              `${employee?.employee?.firstName || ""} ${
                                employee?.employee?.lastName || ""
                              }`
                          )
                          .join(", "),
                      disableData: jobDes.jobStatus == "CLOSED",
                    },
                  })
                }
              >
                <Stack direction="column" spacing={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Stack
                      direction="column"
                      spacing={0}
                      onClick={() =>
                        getAddVisible("candidates") &&
                        navigation(
                          `/job-description-details/${jobDes?.jobId}`,
                          {
                            state: {
                              id: jobDes?.id,
                              name: jobDes?.title,
                              hrList:
                                jobDes?.hiringTeam &&
                                jobDes.hiringTeam[0] &&
                                jobDes.hiringTeam
                                  .map(
                                    (employee) =>
                                      `${employee?.employee?.firstName || ""} ${
                                        employee?.employee?.lastName || ""
                                      }`
                                  )
                                  .join(", "),
                              disableData: jobDes.jobStatus == "CLOSED",
                            },
                          }
                        )
                      }
                    >
                      <Typography variant="h4" sx={{ fontWeight: "600" }}>
                        {jobDes.title ?? "-"}
                        {"-"}
                        {jobDes.jobId ?? "-"}
                      </Typography>
                      <Stack
                        // style={{marginBottom:'10px'}}
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <Typography
                          // variant="h6"
                          sx={{
                            color: "#adb1b3",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {jobDes.seniorityLevel ?? "-"}
                        </Typography>
                        <Typography
                          // variant="h6"
                          sx={{
                            color: "#adb1b3",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {jobDes.isRemote == "office"
                            ? "Ahmedabad"
                            : jobDes.isRemote == "remote"
                            ? "Remote"
                            : "Hybrid"}
                        </Typography>
                        <Typography
                          // variant="h6"
                          sx={{
                            color: "#adb1b3",
                            fontWeight: "500",
                            fontSize: "16px",
                          }}
                        >
                          {jobDes.employmentType ?? "-"}
                        </Typography>
                      </Stack>
                      <Stack
                        direction="row"
                        divider={<Divider orientation="vertical" flexItem />}
                        spacing={1}
                      >
                        <p style={{ fontSize: "12px", color: "#adb1b3" }}>
                          {jobDes?.skills &&
                            jobDes.skills[0] &&
                            jobDes.skills
                              .map((skill) => skill.title)
                              .join(", ")}{" "}
                        </p>
                        <Stack direction="row" spacing={1}>
                          <Typography sx={{ fontWeight: "regular" }}>
                            No. of Position(s):
                          </Typography>
                          <p style={{ fontSize: "12px", color: "#adb1b3" }}>
                            {jobDes?.noOfPosition}
                          </p>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={1}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={(e) => {
                          e.stopPropagation();
                          viewPreview(jobDes);
                        }}
                      >
                        <RemoveRedEyeOutlinedIcon />
                      </IconButton>
                      <IconButton
                        className="dots-icon"
                        sx={{ height: "16px" }}
                        aria-describedby={id}
                        onClick={(e) => {
                          e.stopPropagation();
                          popOverModal(e, jobDes);
                        }}
                        disabled={jobDes.jobStatus == "CLOSED"}
                      >
                        <MoreVertOutlinedIcon />
                      </IconButton>
                    </Stack>
                  </Stack>
                  <Stack
                    onClick={() =>
                      getAddVisible("candidates") &&
                      navigation(`/job-description-details/${jobDes?.jobId}`, {
                        state: {
                          id: jobDes?.id,
                          name: jobDes?.title,
                          disableData: jobDes.jobStatus == "CLOSED",
                        },
                      })
                    }
                    direction="row"
                    divider={<Divider orientation="vertical" flexItem />}
                    spacing={2}
                    sx={{ width: "90%" }}
                  >
                    <Stack direction="column" spacing={0} sx={{ width: "25%" }}>
                      <Typography sx={{ fontWeight: "regular" }}>
                        Total
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: "600" }}
                        style={{ fontSize: "15px" }}
                      >
                        {jobDes?.totalCount}
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={0} sx={{ width: "25%" }}>
                      <Typography sx={{ fontWeight: "regular" }}>
                        Active
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: "600" }}
                        style={{ fontSize: "15px" }}
                      >
                        {jobDes?.activeCount}
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={0} sx={{ width: "25%" }}>
                      <Typography sx={{ fontWeight: "regular" }}>
                        Hired
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: "600" }}
                        style={{ fontSize: "15px" }}
                      >
                        {jobDes.hiredCount ?? "-"}
                      </Typography>
                    </Stack>
                    <Stack direction="column" spacing={0} sx={{ width: "25%" }}>
                      <Typography sx={{ fontWeight: "regular" }}>
                        Rejected
                      </Typography>
                      <Typography
                        variant="h4"
                        sx={{ fontWeight: "600" }}
                        style={{ fontSize: "15px" }}
                      >
                        {jobDes.rejectedCount ?? "-"}
                      </Typography>
                    </Stack>
                  </Stack>
                  <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                  >
                    <Stack
                      direction="row"
                      // divider={<Divider orientation="vertical" flexItem />}
                      spacing={1}
                    >
                      <Typography sx={{ fontWeight: "regular" }}>
                        Team :
                      </Typography>
                      <p style={{ fontSize: "12px", color: "#adb1b3" }}>
                        {jobDes?.hiringTeam && jobDes.hiringTeam[0]
                          ? jobDes.hiringTeam
                              .map(
                                (employee) =>
                                  `${employee.employee?.firstName || ""} ${
                                    employee.employee?.lastName || ""
                                  }`
                              )
                              .join(", ")
                          : "No team is assigned"}{" "}
                      </p>
                    </Stack>
                    {jobDes.jobStatus != "PUBLISHED" &&
                    jobDes.jobStatus != "CLOSED" ? (
                      getEditVisible("jobDescription") && (
                        <Button
                          variant="contained"
                          data-cy="btn-submit-job-description"
                          onClick={(e) => {
                            e.stopPropagation();
                            changeJobStatus(jobDes);
                          }}
                        >
                          Publish
                        </Button>
                      )
                    ) : (
                      <Button variant="contained" disabled sx={{ mr: 2 }}>
                        {jobDes.jobStatus.toLowerCase() ?? "-"}
                      </Button>
                    )}
                    {/* <Button
			component={Link}
			// to="/job-description/add"
			variant="contained"
			sx={{ mr: 2 }}
			onClick={() => {
			  setJobId(jobDes.id);
			  handleOpenSecondModal();
			}}
			startIcon={<AddIcon />}
		  >
			Add Candidate
		  </Button> */}
                  </Stack>
                </Stack>
              </Card>
            ))
          )}
        </Stack>
      )}

      {/* <Card>
				<Scrollbar>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell align="left">Technology</TableCell>
									<TableCell align="left">
										<TableSortLabel
											active={sortBy === "experience"}
											direction={sortBy === "experience" ? orderBy : "asc"}
											onClick={() => handleSorting("experience")}
										>
											Experience
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">
										<TableSortLabel
											active={sortBy === "content"}
											direction={sortBy === "content" ? orderBy : "asc"}
											onClick={() => handleSorting("content")}
										>
											Content
										</TableSortLabel>
									</TableCell>
									<TableCell align="left">Keywords</TableCell>
									<TableCell align="center">Actions</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{Array.isArray(data) && data.length === 0 ? (
									<TableRow>
										<TableCell align="center" colSpan={4}>
											No Records
										</TableCell>
									</TableRow>
								) : (
									data.map((jobDes, idx) => (
										<TableRow key={idx}>
											<TableCell align="left">
												{jobDes?.Technology?.title || "-"}
											</TableCell>
											<TableCell align="left">
												{jobDes?.experience || "-"}
											</TableCell>
											<TableCell align="left">
												{jobDes?.content || "-"}
											</TableCell>
											<TableCell align="left">
												<p className="eclips-line1">
													
												</p>
											</TableCell>
											<TableCell align="left">
												<div
													className="action-button"
													style={{
														whiteSpace: "nowrap",
													}}
												>
													<span
														style={{
															marginRight: 8,
														}}
													>
														<IconButton
															color="primary"
															component={Link}
															to={`/job-description/add/${encryption(
																jobDes.id,
															)}`}
														>
															<EditIcon />
														</IconButton>
													</span>
													<span>
														<IconButton
															onClick={() =>
																handleDelete(jobDes.id, jobDes.Technology.title)
															}
														>
															<DeleteIcon color="error" />
														</IconButton>
													</span>
												</div>
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Scrollbar>

				<TablePagination
					rowsPerPageOptions={[5, 10]}
					component="div"
					count={totalRecords}
					rowsPerPage={limit}
					page={currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Card> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            overflowY: "scroll",
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0px 0px 30px 0",
            outline: "none",
          }}
          className="scroll-blk"
        >
          <Stack direction="row" spacing={1} sx={{ height: "40px" }}>
            <Typography
              variant="h5"
              className="personal-title"
              style={{ paddingBottom: "33px" }}
            >
              Preview Job Details
              <button
                className="close-btn-blk"
                onClick={() => {
                  handleClose();
                  setValue({});
                }}
              >
                X
              </button>
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ padding: "40px 30px", flexDirection: "column" }}
          >
            <Typography className="details-model" variant="h2">
              {value?.title} / {value?.seniorityLevel}
            </Typography>
            <p
              style={{
                display: "block",
                fontSize: "13px",
                marginLeft: "4px",
                marginTop: "4px",
                color: "#898989",
              }}
            >
              Ahmedabad &nbsp; &nbsp;
            </p>
          </Stack>
          <Grid
            style={{ padding: "0px 30px 0px" }}
            container
            divider={<Divider orientation="vertical" flexItem />}
            spacing={3}
          >
            <Grid
              item
              xs={8}
              style={{ paddingLeft: "30px", paddingRight: "20px" }}
              className="details-ul"
            >
              {value?.description ? parse(value?.description) : ""}
            </Grid>
            <Grid item xs={4} style={{ borderLeft: "1px solid #ebebeb" }}>
              <Stack direction="column" spacing={3} className="details-right">
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Employment Type
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {value?.employmentType ?? "-"}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Industry Type
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    It-Software/software Services
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Seniority Level
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {value?.seniorityLevel}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    CTC Type
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {value?.ctcType}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    CTC
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {value?.minCTC} - {value?.maxCTC}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Work Experience(in years)
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {value?.experienceFrom} - {value?.experienceTo}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Skills
                  </Typography>
                  <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                    {value?.skills?.map((element, index) => (
                      <Button
                        variant="contained"
                        disabled
                        sx={{ mb: 2, mr: 2 }}
                      >
                        {element.title}
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="end"
            sx={{ mt: 2 }}
          >
            <Button
              style={{ marginRight: "20px" }}
              variant="contained"
              data-cy="btn-submit-job-description"
              onClick={() => {
                handleClose();
                setValue({});
              }}
            >
              Close
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openThirdModal}
        onClose={() => {
          handleCloseThirdModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleThird}>
          <Stack direction="column" spacing={2}>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={jobStatus} />}
                label="Do you want to publish job on Cybercom Creation career page?"
                name="jobStatus"
                onChange={(e) => setJobStatus(e.target.checked)}
                value={jobStatus}
              />
            </FormGroup>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleCloseThirdModal();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                data-cy="btn-submit-job-description"
                onClick={() => submitFunction()}
                disabled={!jobStatus}
              >
                Proceed
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openSecondModal}
        onClose={() => {
          resetForm();
          setCandidateResume(null);
          setCandidateUpdateId(null);
          handleCloseSecondModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            overflowY: "scroll",
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0",
          }}
        >
          <Typography variant="h5" className="personal-title">
            {/* Add Candidate To Quailty Analyst */}
            Add Candidate To {value?.title}
          </Typography>
          <form
            style={{ padding: "20px 30px" }}
            onSubmit={(...args) => {
              setSubmitted(true);
              handleSubmit(...args);
            }}
          >
            {/* <Accordion
              style={{
                marginBottom: "10px",
                border: "1px solid #919eab3d",
                borderRadius: "5px",
              }}
              expanded={expanded === "panel1"}
              onChange={handleChangeAccordion("panel1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6" className="personal-h6">
                    Personal Details
                  </Typography>
                  {submitted && checkPersonalDetails(errors) && (
                    <ErrorOutlineIcon color="error" />
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails> */}
            <AddCandidate
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              candidateResume={candidateResume}
              setCandidateResume={setCandidateResume}
              personal={personal}
              setPersonal={setPersonal}
              setFieldValue={setFieldValue}
              values={values}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              jobId={jobId}
              // handleTabChange={handleTabChange}
              selectedFileUrl={selectedFileUrl}
              setSelectedFileUrl={setSelectedFileUrl}
              helperText={errors}
              setToBeDeletedCandidateResume={setToBeDeletedCandidateResume}
              designationData={designationData}
              designationDisable={designationDisable}
              candidateId={id}
              setCandidateUpdateId={setCandidateUpdateId}
            />
            {/* </AccordionDetails>
            </Accordion> */}
            {/* <Accordion
              style={{
                marginBottom: "10px",
                border: "1px solid #919eab3d",
                borderRadius: "5px",
              }}
              expanded={expanded === "panel2"}
              onChange={handleChangeAccordion("panel2")}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                  sx={{ width: "100%" }}
                >
                  <Typography variant="h6" className="personal-h6">
                    Activity
                  </Typography>{" "}
                  {submitted && checkActivityDetails(errors) && (
                    <ErrorOutlineIcon color="error" />
                  )}
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <AddActivity
                  setFieldValue={setFieldValue}
                  values={values}
                  handleChange={handleChange}
                  touched={touched}
                  errors={errors}
                  getFieldProps={getFieldProps}
                />
              </AccordionDetails>
            </Accordion> */}
            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  resetForm();
                  setCandidateResume(null);
                  setCandidateUpdateId(null);
                  handleCloseSecondModal();
                }}
              >
                Close
              </Button>
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                // onClick={() => handleSubmit()}
                type="submit"
                sx={{ marginRight: "15px", width: "35px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </form>
        </Box>
      </Modal>

      <Popover
        id={id}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleCloseClick}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Stack direction="column" spacing={0} className="job-modal">
          {getAddVisible("candidates") && (
            <Button
              onClick={() => {
                handleOpenSecondModal();
                handleCloseClick();
              }}
            >
              Add Candidate
            </Button>
          )}
          {/* <Button
            // onClick={() => {
            //   handleOpenSecondModal();
            //   handleCloseClick();
            // }}
            >
            Edit
          </Button> */}
          <Button
            onClick={() => handleOpenSecondHrModal()}
            // component={Link}
            // to="/job-description/add"
            // variant="contained"
            // startIcon={<AddIcon />}
          >
            Edit
          </Button>
          <Button
            onClick={() => {
              handleOpenSixModal();
              handleCloseClick();
            }}
          >
            Close Hiring
          </Button>
          <Button
            onClick={() =>
              navigation(`/job-description/add`, {
                state: {
                  id: jobId,
                  clone: true,
                },
              })
            }
          >
            Clone JD
          </Button>
        </Stack>
      </Popover>

      <Modal
        open={openSixModal}
        onClose={() => {
          handleCloseSixModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styleThird}>
          <Stack direction="column" spacing={2}>
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={jobCloseStatus} />}
                // label="Do you want to this job?"
                label="Do you want to close hiring?"
                name="jobCloseStatus"
                onChange={(e) => setJobCloseStatus(e.target.checked)}
                value={jobCloseStatus}
              />
            </FormGroup>
            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  handleCloseSixModal();
                }}
              >
                Close
              </Button>
              <Button
                variant="contained"
                data-cy="btn-submit-job-description"
                disabled={!jobCloseStatus}
                onClick={() => closeJobStatus()}
              >
                Proceed
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openSecondHrModal}
        onClose={handleCloseSecondHrModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={stylehr}
          style={{
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0 0px 30px 0px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            // minHeight: "80vh",
            width: "70%",
            outline: "none",
          }}
          className="scroll-blk"
        >
          <Stack direction="row" spacing={1} sx={{ height: "40px" }}>
            <Typography
              variant="h5"
              className="personal-title"
              style={{ paddingBottom: "33px" }}
            >
              Select Member
            </Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            style={{ marginTop: "30px", padding: "0 30px" }}
          >
            <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
              <Autocomplete
                multiple
                size="small"
                fullWidth
                defaultValue={[]}
                options={
                  MemberDropdown.filter((x) => {
                    return !selectedHr.map((y) => y.id).includes(x.id);
                  }) ?? []
                }
                // key={technologyData.data || []}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}` || ""
                }
                onChange={(event, newValue) => {
                  // let tempArray = newValue.map((x) => {
                  //   return {
                  //     id: x.id,
                  //     firstName: x.firstName,
                  //     lastName: x.lastName,
                  //     emails: x.emails,
                  //   };
                  // });
                  let tempArray = [...selectedHr, ...newValue];

                  setSelectedHr(tempArray || []);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // helperText={hrError?.title && hrError?.title}
                    // error={Boolean(hrError?.flag && hrError?.flag)}
                    label="Select HR"
                  />
                )}
                // value={selectedHr.filter((x) => {
                //   return x.id;
                // })}
                value={[]}
              />
              {/* <Grid container spacing={3}>
              {selectedHr.map((element) => (
                <Grid item xs={6} mt={3}>
                  {element.title}
                </Grid>
              ))}
            </Grid> */}
            </FormControl>

            <Stack direction="row" className="add-member">
              <Grid
                item
                xs={12}
                style={{ position: "relative", width: "100%" }}
              >
                {selectedHr?.map((element) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    className="add-member-wrap"
                    key={element.id}
                  >
                    {/* <img src="/assets/images/user-img2@2x.png" /> */}
                    <Avatar
                      src={`${BASE_URL}/${element.employeeImage}`}
                      alt={element.firstName}
                      sx={{
                        width: "40px",
                        height: "40px",
                        fontSize: "1.5rem",
                        marginRight: "10px",
                      }}
                    ></Avatar>
                    {/* <img src={`${BASE_URL}/${element.employeeImage}`} /> */}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Typography variant="h5" className="member-blk">
                        {element.firstName} {element.lastName}
                        {/* <p>
                          {element?.emails &&
                            element.emails[0] &&
                            element.emails
                              .map((email) => email.email)
                              .join(",")}{" "}
                        </p> */}
                        <p>
                          {element?.jobHiring &&
                            element.jobHiring[0] &&
                            element.jobHiring
                              .filter(
                                (jobs) => jobs.jobs.jobStatus == "PUBLISHED"
                              )
                              .map((jobs) => jobs.jobs.title)
                              .join(", ")}{" "}
                          {/* {element?.workDetails?.designation?.title} */}
                        </p>
                      </Typography>
                      <Button onClick={() => removeHR(element)}>
                        <ClearIcon />
                      </Button>
                    </Stack>
                  </Stack>
                ))}
                {/* <Stack
                  direction="row"
                  alignItems="center"
                  className="add-member-wrap"
                >
                  <img src="/assets/images/user-img2@2x.png" />
                  <Typography variant="h5" className="member-blk">
                    Ankita Delvadiya
                    <p>ankita.cybercom@gmail.com</p>
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="add-member-wrap"
                >
                  <img src="/assets/images/user-img2@2x.png" />
                  <Typography variant="h5" className="member-blk">
                    Ankita Delvadiya
                    <p>ankita.cybercom@gmail.com</p>
                  </Typography>
                </Stack> */}
              </Grid>
              {/* <Grid item xs={7}>
              <Typography variant="h5"  className="member-blk" style={{marginTop:'5px'}}>
                    Front-end Developer
                  </Typography>
              </Grid> */}
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button variant="outlined" onClick={handleCloseSecondHrModal}>
                Cancel
              </Button>
              <Button
                variant="contained"
                data-cy="btn-submit-job-description"
                onClick={() => updateHrInJd()}
              >
                Save
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
}

const componentConfig = {
  component: JobDescription,
  path: "/job-description",
  public: false,
  layout: DashboardLayout,
  group: "candidate",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
