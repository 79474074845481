import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import { useFormik } from "formik";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import Autocomplete from "@mui/material/Autocomplete";
import { useConfirm } from "material-ui-confirm";
import { useState } from "react";
import { Modal } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function PF(props) {
	const confirm = useConfirm();
	const AuthRole = useSelector((state) => state.auth.role);
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	const [openEsic, setOpenEsic] = React.useState(false);
	const handleOpenEsic = () => setOpenEsic(true);
  const handleCloseEsic = () => setOpenEsic(false);

	const reasonData = ["Exit","Voluntary", "Other"];
	const {
		values,
		setFieldValue,
		handleTabChange,
		getFieldProps,
		handlePFChange,
		touched,
		errors,
		setErrors,
		generatePfPdf,
		id,
		esic,
		pfError,
		setPfError,
		esicError,
		setEsicError
	} = props;

	// console.log(values)

	// const {
	// 	handleSubmit,
	// 	setValues,
	// 	values,
	// 	setFieldValue,
	// 	handleChange,
	// 	getFieldProps,
	// 	errors,
	// 	touched,
	// } = formik;

	const [isUnderPf, setIsUnderPf] = useState(values?.pf.underPF);

	const maritalDetail = [
		"Married",
		"Unmarried",
		"Widow",
		"Widower",
		"Divorcee",
	];

	const checkForDisabled = () => {
		if (
			!values?.pf?.name ||
			!values?.pf?.dob ||
			!values?.pf?.presentAddress ||
			!values?.pf?.permanentAddress ||
			!values?.pf?.email ||
			!values?.pf?.maritalStatus ||
			!values?.pf?.fatherName ||
			!values?.pf?.fatherDOB ||
			!values?.pf?.motherName ||
			!values?.pf?.motherDOB ||
			!values?.pf?.bankName ||
			!values?.pf?.IFSC ||
			!values?.pf?.bankAccountNumber ||
			!values?.pf?.aadharNumber
		) {
			return true;
		}
		return false;
	};

	const handleRemoveEsicChange = async (e) => {	
		if(values.pf.underESIC == true)
		{
			// await confirm({
			// 	description: `Are you sure you want to remove ESIC Details ?`,
			// });
			// await setFieldValue("pf.underESIC", false)
			handleOpenEsic();
		}
	}

	const handleRemovePf = async () => {
		if(values?.pf?.underPF === true && isUnderPf == true)
		{
			// await confirm({
			// 	description: `Are you sure you want to remove PF Details ?`,
			// });
			// await setFieldValue("pf.underPF", false)
			handleOpen()
		}
		else
		{
			setFieldValue("pf.underESIC", false)
			setFieldValue("pf.underPF", false)
			setErrors({...errors, pf:""})
			handleClose()
		}
	}

	const handleOk = (removePFReason,removePFDate, removePFNote) => {
		let nError = {}
		if(!removePFReason || removePFDate == null) {
			if(!removePFReason || removePFReason == "")
			{
				nError = {...nError, removePFReason:"This field is required"}
			}
			if(removePFDate == null)
			{
				nError = {...nError, removePFDate:"This field is required"}
			}
			setPfError(nError)
		}
		else if(removePFReason == "Other" && !removePFNote)
		{
			nError = {...nError, removePFNote:"This field is required"}
			setPfError(nError)
		}
		else{
			setFieldValue("pf.underESIC", false)
			setFieldValue("pf.underPF", false)
			setErrors({...errors, pf:""})
			handleClose()
		}
	}

	const handleOkEsic = (removeESICDate, removeESICNote) => {
		let nError = {}
		if(!removeESICNote || removeESICDate == null) {
			if(removeESICDate == null)
			{
				nError = {...nError, removeESICDate:"This field is required"}
			}
			else{
				nError = {...nError, removeESICDate:""}
			}
			if(!removeESICNote || removeESICNote == "")
			{
				nError = {...nError, removeESICNote:"This field is required"}
			}
			else{
				nError = {...nError, removeESICNote:""}
			}
			setEsicError(nError)
		}
		else{
			setFieldValue("pf.underESIC", false)
			handleCloseEsic()
		}
	}

	const handleEsicChange = async (e) => {

	
		if(!id)
		{
			setFieldValue("pf.underESIC", e.target.checked)
		}
		else if(id && esic == false)
		{
			setFieldValue("pf.underESIC", e.target.checked)
		}
		else if(id && esic == true && AuthRole !== "Super Admin" )
			return
		else if(AuthRole === "Super Admin" && e.target.checked == false)
		{
			setFieldValue("pf.underESIC", e.target.checked)
		}
		else if(AuthRole === "Super Admin" && e.target.checked == true)
		{
			
			setFieldValue("pf.underESIC", e.target.checked)
		}
		setFieldValue("pf.underESIC", e.target.checked)
	};
	
	const handleCancel = () => {
    handleClose();
		setFieldValue("pf.removePFReason","")
		setFieldValue("pf.removePFDate","")
		setFieldValue("pf.removePFNote","");
  }

	const handleCancelEsic = () => {
    handleCloseEsic();
		setFieldValue("pf.removeESICNote","")
		setFieldValue("pf.removeESICDate","")
  }

	return (
		<Card className="right-side-content-part">
			<Container maxWidth="xl">
				<Stack className="pf-container-wrapper" direction="row" justifyContent="space-between" mt={3}>
					<Stack direction="row">
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={values?.pf?.underPF}
										disabled={id && values?.pf?.underPF === true && values?.pf?.IFSC === true}
										// {...getFieldProps('pf.underPF')}
										name="pf.underPF"
										onChange={handlePFChange}
									/>
								}
								label="Under PF?"
							/>
						</FormGroup>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										checked={values?.pf?.underESIC}
										{...getFieldProps("pf.underESIC")}
										disabled={!values?.pf?.underPF || (values?.pf?.underESIC === true && id && values?.pf?.IFSC === true)}
										onChange={handleEsicChange}
									/>
								}
								label="Under ESIC?"
							/>
						</FormGroup>
					</Stack>
					<Stack direction="row" justifyContent="space-between">
					{AuthRole == "Super Admin" ? 

					<>
						<Button
						disabled={!values?.pf?.underPF === true ? true : false}
						variant="outlined"
						onClick={handleRemovePf}
						size="small"
						style={{marginRight:"10px"}}
					>
						Remove From PF
					</Button>
					<Button
						disabled={!values?.pf?.underESIC === true ? true : false}
						variant="outlined"
						onClick={handleRemoveEsicChange}
						size="small"
						style={{marginRight:"10px"}}
					>
						Remove From ESIC
					</Button>
					</>
					: ""}
					<Button
						disabled={checkForDisabled()}
						variant="outlined"
						onClick={generatePfPdf}
						size="small"
						endIcon={<ImportExportIcon />}
					>
						EXPORT TO PDF
					</Button>
					</Stack>
				</Stack>

				{values?.pf?.underPF && (
					<>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Stack className="Employee_Detail-heading" mt={3}>
									<Typography className="Employee_Detail-h6" variant="h6">Personal Details</Typography>
								</Stack>
							</Grid>
							<Grid className="gray-box Employee_Detail_Content pf_container_Detail_Content_grid" item xs={12}>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Name of The Member"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 64 }}
											{...getFieldProps("pf.name")}
											error={Boolean(touched?.pf?.name && errors?.pf?.name)}
											helperText={touched?.pf?.name && errors?.pf?.name}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Universal Account Number"
											variant="outlined"
											size="small"
											{...getFieldProps("pf.UAN")}
											error={Boolean(touched?.pf?.UAN && errors?.pf?.UAN)}
											helperText={touched?.pf?.UAN && errors?.pf?.UAN}
										/>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Member Id"
											variant="outlined"
											size="small"
											{...getFieldProps("pf.memberId")}
											error={Boolean(
												touched?.pf?.memberId && errors?.pf?.memberId,
											)}
											helperText={touched?.pf?.memberId && errors?.pf?.memberId}
										/>
									</Grid>
									{values?.pf?.underESIC && (
										<Grid className="grid-item" item xs={6}>
											<TextField
												fullWidth
												label="ESIC Number"
												variant="outlined"
												size="small"
												{...getFieldProps("pf.esicNumber")}
												error={Boolean(
													touched?.pf?.esicNumber && errors?.pf?.esicNumber,
												)}
												helperText={
													touched?.pf?.esicNumber && errors?.pf?.esicNumber
												}
											/>
										</Grid>
									)}
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DesktopDatePicker
												label="Date of Birth"
												maxDate={new Date()}
												renderInput={(params) => (
													<TextField
														{...params}
														fullWidth
														size="small"
														error={Boolean(touched?.pf?.dob && errors?.pf?.dob)}
														helperText={touched?.pf?.dob && errors?.pf?.dob}
													/>
												)}
												value={values?.pf?.dob}
												onChange={(newDate) => setFieldValue("pf.dob", newDate)}
												inputFormat="dd/MM/yyyy"
											/>
										</LocalizationProvider>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Mobile Number"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 10 }}
											{...getFieldProps("pf.mobileNumber")}
											error={Boolean(
												touched?.pf?.mobileNumber && errors?.pf?.mobileNumber,
											)}
											helperText={
												touched?.pf?.mobileNumber && errors?.pf?.mobileNumber
											}
										/>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Present Address"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 512 }}
											multiline
											maxRows={4}
											{...getFieldProps("pf.presentAddress")}
											error={Boolean(
												touched?.pf?.presentAddress && errors?.pf?.presentAddress,
											)}
											helperText={
												touched?.pf?.presentAddress && errors?.pf?.presentAddress
											}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Permanent Address"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 512 }}
											multiline
											maxRows={4}
											{...getFieldProps("pf.permanentAddress")}
											error={Boolean(
												touched?.pf?.permanentAddress &&
													errors?.pf?.permanentAddress,
											)}
											helperText={
												touched?.pf?.permanentAddress &&
												errors?.pf?.permanentAddress
											}
										/>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Email ID"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 512 }}
											{...getFieldProps("pf.email")}
											error={Boolean(touched?.pf?.email && errors?.pf?.email)}
											helperText={touched?.pf?.email && errors?.pf?.email}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<FormControl size="small" fullWidth>
											{/*<TextField
											select
											label="Marital Status"
											size="small"
											defaultValue=""
											{...getFieldProps("pf.maritalStatus")}
											error={Boolean(
												touched?.pf?.maritalStatus &&
													errors?.pf?.maritalStatus
											)}
											helperText={
												touched?.pf?.maritalStatus &&
												errors?.pf?.maritalStatus
											}
										>
											<MenuItem value="married">
												Married
											</MenuItem>
											<MenuItem value="unmarried">
												Unmarried
											</MenuItem>
											<MenuItem value="widow">Widow</MenuItem>
											<MenuItem value="widower">
												Widower
											</MenuItem>
											<MenuItem value="divorcee">
												Divorcee
											</MenuItem>
										</TextField>*/}
											<Autocomplete
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												label="Marital Status"
												popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
												size="small"
												name="maritalStatus"
												onChange={(event, newValue) => {
													setFieldValue(`pf.maritalStatus`, newValue || "");
													setFieldValue(`maritalStatus`, newValue || "");
												}}
												value={values.maritalStatus}
												options={maritalDetail || []}
												renderInput={(params) => (
													<TextField
														{...params}
														label="Marital Status"
														error={Boolean(
															touched?.pf?.maritalStatus &&
																errors?.pf?.maritalStatus,
														)}
														helperText={
															touched?.pf?.maritalStatus &&
															errors?.pf?.maritalStatus
														}
													/>
												)}
											/>
										</FormControl>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Father's Name"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 64 }}
											{...getFieldProps("pf.fatherName")}
											error={Boolean(
												touched?.pf?.fatherName && errors?.pf?.fatherName,
											)}
											helperText={
												touched?.pf?.fatherName && errors?.pf?.fatherName
											}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DesktopDatePicker
												label="Father's date of birth"
												maxDate={new Date()}
												renderInput={(params) => (
													<TextField
														{...params}
														fullWidth
														size="small"
														error={Boolean(
															touched?.pf?.fatherDOB && errors?.pf?.fatherDOB,
														)}
														helperText={
															touched?.pf?.fatherDOB && errors?.pf?.fatherDOB
														}
													/>
												)}
												value={values?.pf?.fatherDOB}
												onChange={(newDate) =>
													setFieldValue("pf.fatherDOB", newDate)
												}
												inputFormat="dd/MM/yyyy"
												// {...getFieldProps('pf.dob')}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Mother's Name"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 64 }}
											{...getFieldProps("pf.motherName")}
											error={Boolean(
												touched?.pf?.motherName && errors?.pf?.motherName,
											)}
											helperText={
												touched?.pf?.motherName && errors?.pf?.motherName
											}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DesktopDatePicker
												label="Mother's date of birth"
												maxDate={new Date()}
												renderInput={(params) => (
													<TextField
														{...params}
														fullWidth
														size="small"
														error={Boolean(
															touched?.pf?.motherDOB && errors?.pf?.motherDOB,
														)}
														helperText={
															touched?.pf?.motherDOB && errors?.pf?.motherDOB
														}
													/>
												)}
												value={values?.pf?.motherDOB}
												onChange={(newDate) =>
													setFieldValue("pf.motherDOB", newDate)
												}
												inputFormat="dd/MM/yyyy"
												// {...getFieldProps('pf.dob')}
											/>
										</LocalizationProvider>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Bank Name"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 128 }}
											{...getFieldProps("pf.bankName")}
											error={Boolean(
												touched?.pf?.bankName && errors?.pf?.bankName,
											)}
											helperText={touched?.pf?.bankName && errors?.pf?.bankName}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Bank Account Number"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 64 }}
											{...getFieldProps("pf.bankAccountNumber")}
											error={Boolean(
												touched?.pf?.bankAccountNumber &&
													errors?.pf?.bankAccountNumber,
											)}
											helperText={
												touched?.pf?.bankAccountNumber &&
												errors?.pf?.bankAccountNumber
											}
										/>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="Addhar Number"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 32 }}
											{...getFieldProps("pf.aadharNumber")}
											error={Boolean(
												touched?.pf?.aadharNumber && errors?.pf?.aadharNumber,
											)}
											helperText={
												touched?.pf?.aadharNumber && errors?.pf?.aadharNumber
											}
										/>
									</Grid>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="PAN Number"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 32 }}
											{...getFieldProps("pf.panNumber")}
											error={Boolean(
												touched?.pf?.panNumber && errors?.pf?.panNumber,
											)}
											helperText={touched?.pf?.panNumber && errors?.pf?.panNumber}
										/>
									</Grid>
								</Grid>
								<Grid container xs={12} spacing={3} mt={1} ml={1}>
									<Grid className="grid-item" item xs={6}>
										<TextField
											fullWidth
											label="IFSC"
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 32 }}
											{...getFieldProps("pf.IFSC")}
											error={Boolean(touched?.pf?.IFSC && errors?.pf?.IFSC)}
											helperText={touched?.pf?.IFSC && errors?.pf?.IFSC}
										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid className="Other-Details_heading" item xs={12}>
								<Typography className="Employee_Detail-h6" variant="h6">Other Details</Typography>
							</Grid>
							<Grid className="gray-box Employee_Detail_Content Employee_Other_Detail_grid Other-Details" item xs={12}>
								<Grid className="grid-item" item xs={6}>
									<TextField
										fullWidth
										label="Husband's Name"
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 64 }}
										{...getFieldProps("pf.husbandName")}
										error={Boolean(
											touched?.pf?.husbandName && errors?.pf?.husbandName,
										)}
										helperText={
											touched?.pf?.husbandName && errors?.pf?.husbandName
										}
									/>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											label="Husband's date of birth"
											maxDate={new Date()}
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													size="small"
													error={Boolean(
														touched?.pf?.husbandDOB && errors?.pf?.husbandDOB,
													)}
													helperText={
														touched?.pf?.husbandDOB && errors?.pf?.husbandDOB
													}
												/>
											)}
											value={values?.pf?.husbandDOB}
											onChange={(newDate) =>
												setFieldValue("pf.husbandDOB", newDate)
											}
											inputFormat="dd/MM/yyyy"
											// {...getFieldProps("pf.husbandDOB")}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<TextField
										fullWidth
										label="Election Card Number"
										variant="outlined"
										size="small"
										{...getFieldProps("pf.electionCardNumber")}
										error={Boolean(
											touched?.pf?.electionCardNumber &&
												errors?.pf?.electionCardNumber,
										)}
										helperText={
											touched?.pf?.electionCardNumber &&
											errors?.pf?.electionCardNumber
										}
									/>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<TextField
										fullWidth
										label="License Number"
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 32 }}
										{...getFieldProps("pf.licenseNumber")}
										error={Boolean(
											touched?.pf?.licenseNumber && errors?.pf?.licenseNumber,
										)}
										helperText={
											touched?.pf?.licenseNumber && errors?.pf?.licenseNumber
										}
									/>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											label="License expiry date"
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													size="small"
													error={Boolean(
														touched?.pf?.husbandDOB &&
															errors?.pf?.licenseValidityTo,
													)}
													helperText={
														touched?.pf?.husbandDOB &&
														errors?.pf?.licenseValidityTo
													}
												/>
											)}
											value={values?.pf?.licenseValidityTo}
											onChange={(newDate) =>
												setFieldValue("pf.licenseValidityTo", newDate)
											}
											inputFormat="dd/MM/yyyy"
											// {...getFieldProps("pf.licenseValidityTo")}
										/>
									</LocalizationProvider>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<TextField
										fullWidth
										label="Ration card number"
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 32 }}
										{...getFieldProps("pf.rationCardNumber")}
										error={Boolean(
											touched?.pf?.rationCardNumber &&
												errors?.pf?.rationCardNumber,
										)}
										helperText={
											touched?.pf?.rationCardNumber &&
											errors?.pf?.rationCardNumber
										}
									/>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<TextField
										fullWidth
										label="Passport Number"
										variant="outlined"
										size="small"
										inputProps={{ maxLength: 128 }}
										{...getFieldProps("pf.passportNumber")}
										error={Boolean(
											touched?.pf?.passportNumber && errors?.pf?.passportNumber,
										)}
										helperText={
											touched?.pf?.passportNumber && errors?.pf?.passportNumber
										}
									/>
								</Grid>
								<Grid className="grid-item" item xs={6}>
									<LocalizationProvider dateAdapter={AdapterDateFns}>
										<DesktopDatePicker
											label="Passport expiry date"
											renderInput={(params) => (
												<TextField
													{...params}
													fullWidth
													size="small"
													error={Boolean(
														touched?.pf?.husbandDOB &&
															errors?.pf?.passportValidityTo,
													)}
													helperText={
														touched?.pf?.husbandDOB &&
														errors?.pf?.passportValidityTo
													}
												/>
											)}
											value={values?.pf?.passportValidityTo}
											onChange={(newDate) =>
												setFieldValue("pf.passportValidityTo", newDate)
											}
											inputFormat="dd/MM/yyyy"
											// {...getFieldProps("pf.passportValidityTo")}
										/>
									</LocalizationProvider>
								</Grid>
							</Grid>
						</Grid>
					</>
				)}

				<Stack
					direction="row"
					alignItems="center"
					justifyContent="flex-end"
					mb={3}
					mt={4}
				>
					<Button className="back-button" onClick={() => handleTabChange("5")} variant="contained" style={{marginRight:'15px'}} startIcon={<ArrowBackIcon />}>
						Back
					</Button>
					<Button onClick={() => handleTabChange("7")} variant="contained" endIcon={<ArrowForwardIcon />}>
						Next
					</Button>
				</Stack>

				<Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Stack spacing={2} sx={style}>
						<Autocomplete
              size="small"
              fullWidth
              defaultValue=""
              options={reasonData}
              onChange={(e, newValue) => {
                setFieldValue(
                  `pf.removePFReason`,
                  newValue || "",
                );
                if(newValue)
								{
									setPfError({...pfError, removePFReason:""});
								}
								if(newValue == null){
									setPfError({ ...pfError, removePFReason:"This field is required" });
								}
								if(!newValue)
								{
									setPfError({ ...pfError, removePFReason:"This field is required" });
								}
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Reason"
                  helperText={pfError?.removePFReason}
                  error={Boolean(pfError?.removePFReason)}
                />
              )}
            
              value={reasonData?.find((x) =>
                x === values?.pf?.removePFReason 
                ) || ""}
            	/>
							<LocalizationProvider dateAdapter={AdapterDateFns}>
								<DesktopDatePicker
									label="Date"
									// maxDate={new Date()}
									renderInput={(params) => (
										<TextField
											{...params}
											fullWidth
											size="small"
											helperText={pfError?.removePFDate}
                 			error={Boolean(pfError?.removePFDate)}
										/>
									)}
									value={values?.pf?.removePFDate}
									onChange={(newDate) => {
										setFieldValue("pf.removePFDate", newDate)
											if(newDate)
											{
												setPfError({...pfError, removePFDate:""});
											}
											if(newDate == null){
												setPfError({ ...pfError, removePFDate:"This field is required" });
											}
										}
									}
									inputFormat="dd/MM/yyyy"
								/>
							</LocalizationProvider>
						
							{values?.pf?.removePFReason == "Other" ? 
							 	<TextField
								 fullWidth
								 label="Enter Note"
								 variant="outlined"
								 size="small"
								 multiline
								 minRows={3}
								 value={values?.pf?.removePFNote}
								{...getFieldProps("pf.removePFNote")}
								 onChange={(e) => {
									setFieldValue("pf.removePFNote", e.target.value)
										if(e.target.value)
										{
											setPfError({...pfError, removePFNote:""});
										}
										if(e.target.value == null && values?.pf?.removePFReason == "Other"){
											setPfError({ ...pfError, removePFReason:"This field is required" });
										}
										if(!e.target.value && values?.pf?.removePFReason == "Other")
										{
											setPfError({ ...pfError, removePFReason:"This field is required" });
										}
										if(e.target.value == "" && values?.pf?.removePFReason == "Other")
										{
											setPfError({ ...pfError, removePFReason:"This field is required" });
										}
									}
								}
								helperText={pfError?.removePFNote}
                error={Boolean(pfError?.removePFNote)}
							 />
							 : ""}
            <Stack direction="row" alignItems="right" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              onClick={() => handleOk(values?.pf?.removePFReason, values?.pf?.removePFDate, values?.pf?.removePFNote)}
              sx={{ marginRight: "15px" }}
            >
              Ok
            </Button>
            <Button variant="contained" onClick={handleCancel}>
              Cancel
            </Button>
            </Stack>
          </Stack>
        </Modal>

				<Modal
          open={openEsic}
          onClose={handleCloseEsic}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description">
          <Stack spacing={2} sx={style2}>
						<TextField
							fullWidth
							label="Reason"
							variant="outlined"
							size="small"
							value="Salary Revision"
						/>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DesktopDatePicker
								label="Date"
								// maxDate={new Date()}
								renderInput={(params) => (
									<TextField
										{...params}
										fullWidth
										size="small"
										helperText={esicError?.removeESICDate}
										error={Boolean(esicError?.removeESICDate)}
									/>
								)}
								value={values?.pf.removeESICDate}
								onChange={(newDate) => {
									setFieldValue("pf.removeESICDate", newDate)
										if(newDate)
										{
											setEsicError({...esicError, removeESICDate:""});
										}
										if(newDate == null){
											setEsicError({ ...esicError, removeESICDate:"This field is required" });
										}
									}
								}
								inputFormat="dd/MM/yyyy"
							/>
						</LocalizationProvider>
					
						<TextField
							fullWidth
							label="Enter Note"
							variant="outlined"
							size="small"
							multiline
							minRows={3}
							value={values?.pf?.removeESICNote}
							{...getFieldProps("pf.removeESICNote")}
							onChange={(e) => {
							setFieldValue("pf.removeESICNote", e.target.value)
								if(e.target.value)
								{
									setEsicError({...esicError, removeESICNote:""});
								}
								if(e.target.value == null || !e.target.value || e.target.value == ""){
									setEsicError({ ...esicError, removeESICNote:"This field is required" });
								}
							}
						}
						helperText={esicError?.removeESICNote}
						error={Boolean(esicError?.removeESICNote)}
						/>
            <Stack direction="row" alignItems="right" justifyContent="flex-end">
            <Button
              type="submit"
              variant="contained"
              onClick={() => handleOkEsic(values?.pf?.removeESICDate, values?.pf?.removeESICNote)}
              sx={{ marginRight: "15px" }}
            >
              Ok
            </Button>
            <Button variant="contained" onClick={handleCancelEsic}>
              Cancel
            </Button>
          </Stack>
          </Stack>
        </Modal>
			</Container>
		</Card>
	);
}

// <Grid item xs={6}>
// <FormControl>
//     <RadioGroup
//         row
//         aria-labelledby="demo-row-radio-buttons-group-label"
//         name="row-radio-buttons-group"
//         {...getFieldProps('pf.isFatherName')}
//     >
//         <FormControlLabel
//             value={true}
//             control={<Radio size="small" />}
//             label="Father's Name"
//         />
//         <FormControlLabel
//             value={false}
//             control={<Radio size="small" />}
//             label="Spouse's Name"
//         />
//     </RadioGroup>
// </FormControl>
// </Grid>
// <Grid item xs={6}>
// <TextField
//     fullWidth
//     label="Father/Sppouse Name"
//     variant="outlined"
//     size="small"
//     {...getFieldProps('pf.middleName')}
// />
// </Grid>
// <Grid item xs={6}>
// <FormControl
//     component="fieldset"
//     size="small"
//     // error={Boolean(touched.gender && errors.gender)}
//     // helperText={touched.gender && errors.gender}
// >
//     <FormLabel component="legend">Gender</FormLabel>
//     <RadioGroup
//         row
//         aria-label="gender"
//         name="gender"
//         {...getFieldProps('pf.gender')}
//     >
//         <FormControlLabel
//             value="M"
//             control={<Radio size="small" />}
//             label="Male"
//         />
//         <FormControlLabel
//             value="F"
//             control={<Radio size="small" />}
//             label="Female"
//         />
//         <FormControlLabel
//             value="T"
//             control={<Radio size="small" />}
//             label="Transgender"
//         />
//     </RadioGroup>
// </FormControl>
// </Grid>

// <Grid item xs={6}>
// <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
//     <FormLabel id="demo-radio-buttons-group-label">
//         Whether earlier a member of Employee's Provident Fund
//         Scheme, 1952
//     </FormLabel>
//     <RadioGroup
//         row
//         aria-labelledby="demo-radio-buttons-group-label"
//         name="radio-buttons-group"
//         {...getFieldProps('pf.earlierMemberOfEPF1952')}
//     >
//         <FormControlLabel
//             value={true}
//             control={<Radio size="small" />}
//             label="Yes"
//         />
//         <FormControlLabel
//             value={false}
//             control={<Radio size="small" />}
//             label="No"
//         />
//     </RadioGroup>
// </FormControl>
// </Grid>
// <Grid item xs={6}>
// <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
//     <FormLabel id="demo-radio-buttons-group-label">
//         Whether earlier a member of Employee's Pension Scheme, 1995
//     </FormLabel>
//     <RadioGroup
//         row
//         aria-labelledby="demo-radio-buttons-group-label"
//         name="radio-buttons-group"
//         {...getFieldProps('pf.earlierMemberOfEPension1995')}
//     >
//         <FormControlLabel
//             value={true}
//             control={<Radio size="small" />}
//             label="Yes"
//         />
//         <FormControlLabel
//             value={false}
//             control={<Radio size="small" />}
//             label="No"
//         />
//     </RadioGroup>
// </FormControl>
// </Grid>

// <Grid item xs={12}>
// <Typography variant="h6">Previous Employment Details</Typography>
// </Grid>

// <Grid item xs={6}>
// <TextField
//     fullWidth
//     label="Previos pf Account Number"
//     variant="outlined"
//     size="small"
//     {...getFieldProps('pf.previousPFNumber')}
// />
// </Grid>
// <Grid item xs={6}>
// <LocalizationProvider dateAdapter={AdapterDateFns}>
//     <DesktopDatePicker
//         label="Date Exit From Previos Development"
//         inputFormat="dd/MM/yyyy"
//         renderInput={(params) => (
//             <TextField {...params} fullWidth size="small" />
//         )}
//         value={values?.pf?.dateExitFromPreviosDevelopment}
//         onChange={(newDate) =>
//             setFieldValue(
//                 'pf.dateExitFromPreviosDevelopment',
//                 newDate
//             )
//         }
//     />
// </LocalizationProvider>
// </Grid>
// <Grid item xs={6}>
// <TextField
//     fullWidth
//     label="Scheme Certificate Number"
//     variant="outlined"
//     size="small"
//     {...getFieldProps('pf.schemeCertificateNumber')}
// />
// </Grid>
// <Grid item xs={6}>
// <TextField
//     fullWidth
//     label="Pension Payment Order No"
//     variant="outlined"
//     size="small"
//     {...getFieldProps('pf.pensionPaymentOrderNumber')}
// />
// </Grid>
// <Grid item xs={6}>
// <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
//     <FormLabel id="demo-radio-buttons-group-label">
//         International Worker
//     </FormLabel>
//     <RadioGroup
//         row
//         aria-labelledby="demo-radio-buttons-group-label"
//         name="radio-buttons-group"
//         {...getFieldProps('pf.internationalWorker')}
//     >
//         <FormControlLabel
//             value={true}
//             control={<Radio size="small" />}
//             label="Yes"
//         />
//         <FormControlLabel
//             value={false}
//             control={<Radio size="small" />}
//             label="No"
//         />
//     </RadioGroup>
// </FormControl>
// </Grid>
// <Grid item xs={6}>
// <TextField
//     fullWidth
//     label="State Country Of Origin"
//     variant="outlined"
//     size="small"
//     {...getFieldProps('pf.country')}
// />
// </Grid>
// <Grid item xs={6}>
// <TextField
//     fullWidth
//     label="Passport Number"
//     variant="outlined"
//     size="small"
//     {...getFieldProps('pf.passportNumber')}
// />
// </Grid>
// <Grid item xs={6}>
// <LocalizationProvider dateAdapter={AdapterDateFns}>
//     <DesktopDatePicker
//         label="Validity Passport From"
//         inputFormat="dd/MM/yyyy"
//         renderInput={(params) => (
//             <TextField {...params} fullWidth size="small" />
//         )}
//         value={values?.pf?.passportValidityFrom}
//         onChange={(newDate) =>
//             setFieldValue('pf.passportValidityFrom', newDate)
//         }
//     />
// </LocalizationProvider>
// </Grid>
// <Grid item xs={6}>
// <LocalizationProvider dateAdapter={AdapterDateFns}>
//     <DesktopDatePicker
//         label="Validity Passport To"
//         inputFormat="dd/MM/yyyy"
//         renderInput={(params) => (
//             <TextField {...params} fullWidth size="small" />
//         )}
//         value={values?.pf?.passportValidityTo}
//         onChange={(newDate) =>
//             setFieldValue('pf.passportValidityTo', newDate)
//         }
//     />
// </LocalizationProvider>
// </Grid>
// <Grid item xs={6}>
// <LocalizationProvider dateAdapter={AdapterDateFns}>
//     <DesktopDatePicker
//         label="Validity Passport To"
//         inputFormat="dd/MM/yyyy"
//         renderInput={(params) => (
//             <TextField {...params} fullWidth size="small" />
//         )}
//         value={values?.pf?.passportValidityTo}
//         onChange={(newDate) =>
//             setFieldValue('pf.passportValidityTo', newDate)
//         }
//     />
// </LocalizationProvider>
// </Grid>
