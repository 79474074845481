import React, { useEffect, useState } from "react";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRetentionHistory,
  getEmployeeRetentionHistory,
  postEmployeeRetentionHistory,
  updateRetentionHistory,
} from "../../../../redux/employee/employeeThunk";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  setResetDeleteHistoyDetails,
  setResetupdateRetentionDetailsReset,
} from "../../../../redux/employee/employeeSlice";
import { useConfirm } from "material-ui-confirm";
import { calculateDueDate, monthYearFormat } from "../../../../utils/dateFormat";
import * as Yup from "yup";
import { format } from "date-fns";
import CancelIcon from '@mui/icons-material/Cancel';
import { LightTooltip } from "../../ParkingAllocation";
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';

const SERVICEAGREEMENT = {
  isUnderServiceAgreement: false,
  type: [],
  isCheque: false,
  isRetention: false,
  bankName: "",
  bankAccountNumber: "",
  chequeAmount: "",
  IFSC: "",
  bankBranch: "",
  noOfCheque: "",
  chequeNumbers: "",
};

const serviceType = ["CHEQUE", "RETENTION"];
export default function Service(props) {
  const {
    values: valuesFromProps,
    setFieldValue: setFieldValueFromProps,
    getFieldProps: getFieldPropsFromProps,
    touched,
    errors,
    hanldeService,
    handleTabChange,
  } = props;
  const [isClickedOnEditIndex, setIsClickedOnEditIndex] = useState("");
  const [clickedOnCheckBoxtoCheckIndex, setClickedOnCheckBoxtoCheckIndex] = useState("");
  const [retentionHistoryDetailsData, setRetentionHistoryDetailsData] = useState([]);
  const [openDefaultIconModal, setOpenDefaultIconModal] = useState(false)
  const [clickedOnDefaultIconIndex, setClickedOnDefaultIconIndex] = useState("");
  const [markAsDefaultChecked, setMarkAsDefaultChecked] = useState(false)
  const [notesValue, setNotesValue] = useState('')
  const [noteError, setNoteError] = useState('')
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state.employee.serviceRetentionHistoryDetails);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false)
    setOpenDefaultIconModal(false)
    setFieldValue('isDefault', false)
    setNotesValue('')
    setNoteError('')
  };
  const { status: updateServiceRetention } = useSelector(
    (state) => state.employee.updateServiceRetentionHistoryDetails
  );
  const { status: deleteRetentionHistoy } = useSelector((state) => state.employee.deleteRetentionHostoryDetail);

  const confirm = useConfirm();

  const retentionSchema = Yup.object().shape({
    amount: Yup.string().required("Amount is required").max(10, "Upto 10 characters required"),
    dueDate: Yup.date().required("Due Date is required").nullable(),
  });

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 4,
  };

  const commitmentStartDate = valuesFromProps.workDetails.commitmentStartDate;
  const commitmentPeriod = valuesFromProps.workDetails.bondDuration;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      employeeId: valuesFromProps.id,
      amount: "",
      isPaid: false,
      dueDate: valuesFromProps.workDetails.bondDuration
        ? calculateDueDate(commitmentStartDate, commitmentPeriod)
        : null,
      paidDate: null,
      isDefault: false
    },
    validationSchema: retentionSchema,
    onSubmit: async (values, { resetForm }) => {
      const res = await dispatch(postEmployeeRetentionHistory(values));
      resetForm();
      dispatch(
        getEmployeeRetentionHistory({
          employeeId: valuesFromProps.id,
        })
      );
    },
  });

  useEffect(() => {
    dispatch(
      getEmployeeRetentionHistory({
        employeeId: valuesFromProps.id,
      })
    );
  }, [dispatch, valuesFromProps.id]);

  useEffect(() => {
    setRetentionHistoryDetailsData(data);
    setMarkAsDefaultChecked(data)
  }, [data]);

  const {
    handleSubmit,
    values,
    touched: retentionTouched,
    errors: retentionError,
    setFieldValue,
    getFieldProps,
    setFieldTouched,
  } = formik;

  useEffect(() => {
    if (valuesFromProps.workDetails.bondDuration === "0") {
      setFieldValueFromProps("serviceAgreement", SERVICEAGREEMENT);
    }
  }, []);

  useEffect(() => {
    if (valuesFromProps?.serviceAgreement?.type?.includes("CHEQUE")) {
      setFieldValueFromProps("serviceAgreement.isCheque", true);
    } else {
      setFieldValueFromProps("serviceAgreement.isCheque", false);
    }

    if (valuesFromProps?.serviceAgreement?.type?.includes("RETENTION")) {
      setFieldValueFromProps("serviceAgreement.isRetention", true);
    } else {
      setFieldValueFromProps("serviceAgreement.isRetention", false);
    }
  }, [valuesFromProps?.serviceAgreement?.type]);

  const handleEditRetentionHistory = (res) => {
    setFieldValue("amount", res?.amount);
    setFieldValue("dueDate", res?.dueDate);
    setFieldValue("paidDate", res?.paidDate);
    setFieldTouched("amount", false);
    setIsClickedOnEditIndex(res?.id);
  }

  const handleCheckBoxChange = async (e, res) => {
    if (!res.isPaid) {
      handleOpen();
      setClickedOnCheckBoxtoCheckIndex(res?.id);
    } else {
      try {
        await confirm({
          description: `${res.isPaid && "Are you sure you want to mark this payment as unpaid? Please confirm to proceed"
            }`,
        });
        dispatch(
          updateRetentionHistory({
            id: res.id,
            employeeId: valuesFromProps.id,
            amount: res.amount,
            isPaid: false,
            dueDate: res.dueDate,
          })
        );
      } catch (error) { }
    }
  };

  const handleDefaultChange = (e) => {
    setFieldValue("isDefault", e.target.checked || "");
    setFieldValue('paidDate', null)
  }

  const handleSavePaid = async (res) => {

    // Check if there are any validation errors
    if ((notesValue.length > 0 && notesValue.length <= 255) || values.paidDate) {
      retentionHistoryDetailsData.map((val) => {
        if (clickedOnCheckBoxtoCheckIndex === val.id) {
          dispatch(
            updateRetentionHistory({
              id: clickedOnCheckBoxtoCheckIndex,
              employeeId: valuesFromProps.id,
              amount: val?.amount,
              isPaid: val?.isPaid,
              dueDate: val?.dueDate,
              paidDate: values?.paidDate ? moment(values?.paidDate).format(`YYYY-MM-DD`) + ' ' + moment().format('HH:mm:ss') : null,
              isDefault: values.isDefault,
              note: notesValue
            })
          );
        }
      });
      setClickedOnCheckBoxtoCheckIndex("");
      setNoteError('')
      handleClose(); // Close the modal after saving
    } else {
      { notesValue.length <= 0 && setNoteError('Note is Required') }
      { notesValue.length >= 255 && setNoteError('Up to 255 characters required') }

    }
  };


  const handleUpdate = (e) => {
    e.preventDefault();
    retentionHistoryDetailsData.map((val) => {
      if (isClickedOnEditIndex === val.id) {
        dispatch(
          updateRetentionHistory({
            id: isClickedOnEditIndex,
            employeeId: valuesFromProps.id,
            amount: values?.amount,
            isPaid: values?.isPaid,
            dueDate: values?.dueDate,
            paidDate: values?.paidDate ? moment(values?.paidDate).format(`YYYY-MM-DD`) + ' ' + moment().format('HH:mm:ss') : null,
          })
        );
      }
    });
    setIsClickedOnEditIndex("");
    setFieldValue("amount", "");
    setFieldValue("dueDate", null);
  };

  const handleDelete = async (e, id) => {
    e.preventDefault();
    try {
      await confirm({
        description: `Are you sure you want to delete retention detail ?`,
      });
      dispatch(deleteRetentionHistory(id));
      dispatch(setResetDeleteHistoyDetails());
    } catch (error) { }
  };

  useEffect(() => {
    if (updateServiceRetention) {
      dispatch(
        getEmployeeRetentionHistory({
          employeeId: valuesFromProps.id,
        })
      );
      dispatch(setResetupdateRetentionDetailsReset());
    }
  }, [updateServiceRetention]);

  useEffect(() => {
    if (deleteRetentionHistoy) {
      dispatch(
        getEmployeeRetentionHistory({
          employeeId: valuesFromProps.id,
        })
      );
      dispatch(setResetDeleteHistoyDetails());
    }
  }, [deleteRetentionHistoy]);

  const handleDefaultDate = (res) => {
    setOpenDefaultIconModal(true)
    setClickedOnDefaultIconIndex(res.id)
  }

  const handleRemoveMarkAsDefault = (e, res) => {
    dispatch(
      updateRetentionHistory({
        id: res.id,
        employeeId: valuesFromProps.id,
        amount: res?.amount,
        isPaid: false,
        dueDate: res?.dueDate,
        paidDate: res?.paidDate ? res?.paidDate : null,
        isDefault: false,
      })
    );
    setClickedOnDefaultIconIndex('')
  }


  return (
    <Card className="right-side-content-part">
      <Container maxWidth="xl">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={3} mb={2}>
          <Typography variant="h6" gutterBottom>
            Service Agreement
          </Typography>
        </Stack>

        <FormControlLabel
          className="Service-Agreement-Checkbox"
          control={
            <Checkbox
              checked={valuesFromProps?.serviceAgreement?.isUnderServiceAgreement}
              // {...getFieldPropsFromProps(
              //     "serviceAgreement.isUnderServiceAgreement"
              // )}
              // value={
              //     valuesFromProps?.serviceAgreement
              //         ?.isUnderServiceAgreement
              // }
              disabled={valuesFromProps?.workDetails?.bondDuration === "0"}
              // disabled={valuesFromProps.commitmentDuration === "0"}
              onChange={hanldeService}
            />
          }
          label="Under Service Agreement?"
        />

        <Grid className="Service-Agreement-checbox-detail" m="2" container spacing={2}>
          {valuesFromProps?.serviceAgreement?.isUnderServiceAgreement && (
            <Grid className="gray-box Employee_Detail_Content" m={2} container spacing={2}>
              <Grid item xs={6}>
                <FormControl style={{ width: "100%" }}>
                  <Autocomplete
                    multiple
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Agreement Type"
                    size="small"
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    name="serviceAgreementType"
                    onChange={(event, newValue) => {
                      setFieldValueFromProps(`serviceAgreement.type`, newValue || "");
                    }}
                    value={valuesFromProps?.serviceAgreement?.type || []}
                    options={serviceType || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Agreement Type"
                        error={Boolean(touched?.serviceAgreement?.type && errors?.serviceAgreement?.type)}
                        helperText={touched?.serviceAgreement?.type && errors?.serviceAgreement?.type}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          )}

          {valuesFromProps.serviceAgreement.isCheque && valuesFromProps?.serviceAgreement?.isUnderServiceAgreement && (
            <>
              <Grid className="Service_checbox_detail" m={1} container>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  m={1}
                  className="Employee_Detail-heading"
                >
                  <Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
                    Cheque Details
                  </Typography>
                </Stack>
                <Grid
                  className="gray-box Employee_Detail_Content Service-Agreement-Cheque-Details"
                  mt={0}
                  mb={0}
                  ml={1}
                  mr={1}
                  container
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "100%", paddingTop: "0px" }}
                      value={values?.serviceAgreement?.bankName || ""}
                      label="Bank Name"
                      variant="outlined"
                      size="small"
                      {...getFieldPropsFromProps("serviceAgreement.bankName")}
                      error={Boolean(touched?.serviceAgreement?.bankName && errors?.serviceAgreement?.bankName)}
                      helperText={touched?.serviceAgreement?.bankName && errors?.serviceAgreement?.bankName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "100%", paddingTop: "0px" }}
                      value={valuesFromProps?.serviceAgreement?.chequeAmount || ""}
                      label="Cheque Amount"
                      size="small"
                      variant="outlined"
                      {...getFieldPropsFromProps("serviceAgreement.chequeAmount")}
                      error={Boolean(touched?.serviceAgreement?.chequeAmount && errors?.serviceAgreement?.chequeAmount)}
                      helperText={touched?.serviceAgreement?.chequeAmount && errors?.serviceAgreement?.chequeAmount}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "100%" }}
                      label="IFSC Code"
                      value={valuesFromProps?.serviceAgreement?.IFSC || ""}
                      variant="outlined"
                      size="small"
                      {...getFieldPropsFromProps("serviceAgreement.IFSC")}
                      error={Boolean(touched?.serviceAgreement?.IFSC && errors?.serviceAgreement?.IFSC)}
                      helperText={touched?.serviceAgreement?.IFSC && errors?.serviceAgreement?.IFSC}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "100%" }}
                      value={valuesFromProps?.serviceAgreement?.bankBranch || ""}
                      label="Branch Name"
                      variant="outlined"
                      size="small"
                      {...getFieldPropsFromProps("serviceAgreement.bankBranch")}
                      error={Boolean(touched?.serviceAgreement?.bankBranch && errors?.serviceAgreement?.bankBranch)}
                      helperText={touched?.serviceAgreement?.bankBranch && errors?.serviceAgreement?.bankBranch}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "100%" }}
                      value={valuesFromProps?.serviceAgreement?.bankAccountNumber || ""}
                      label="Account Number"
                      variant="outlined"
                      size="small"
                      {...getFieldPropsFromProps("serviceAgreement.bankAccountNumber")}
                      error={Boolean(
                        touched?.serviceAgreement?.bankAccountNumber && errors?.serviceAgreement?.bankAccountNumber
                      )}
                      helperText={
                        touched?.serviceAgreement?.bankAccountNumber && errors?.serviceAgreement?.bankAccountNumber
                      }
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type="number"
                      style={{ width: "100%" }}
                      value={valuesFromProps?.serviceAgreement?.noOfCheque || ""}
                      label="Number of Cheque"
                      variant="outlined"
                      size="small"
                      {...getFieldPropsFromProps("serviceAgreement.noOfCheque")}
                      error={Boolean(touched?.serviceAgreement?.noOfCheque && errors?.serviceAgreement?.noOfCheque)}
                      helperText={touched?.serviceAgreement?.noOfCheque && errors?.serviceAgreement?.noOfCheque}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      style={{ width: "100%" }}
                      value={valuesFromProps?.serviceAgreement?.chequeNumbers || ""}
                      label="Cheque Number"
                      variant="outlined"
                      size="small"
                      {...getFieldPropsFromProps("serviceAgreement.chequeNumbers")}
                      error={Boolean(
                        touched?.serviceAgreement?.chequeNumbers && errors?.serviceAgreement?.chequeNumbers
                      )}
                      helperText={touched?.serviceAgreement?.chequeNumbers && errors?.serviceAgreement?.chequeNumbers}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
          {valuesFromProps.serviceAgreement.isRetention &&
            valuesFromProps?.serviceAgreement?.isUnderServiceAgreement && (
              <>
                <Grid
                  className="Service_checbox_detail"
                  m={1}
                  container
                  sx={
                    valuesFromProps.serviceAgreement.isRetention &&
                    valuesFromProps.serviceAgreement.isCheque &&
                    {
                      // borderTop: "1px solid #dce0e4",
                    }
                  }
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    m={1}
                    className="Employee_Detail-heading"
                  >
                    <Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
                      Retention Histoy Details
                    </Typography>
                  </Stack>
                  <Grid
                    className="gray-box Employee_Detail_Content"
                    mt={0}
                    mb={0}
                    ml={1}
                    mr={1}
                    container
                    spacing={2}
                    alignItems="center"
                  >
                    <Grid item xs={3}>
                      <TextField
                        type="number"
                        fullWidth
                        label="Retention Amount"
                        value={values.amount}
                        onChange={(newValue) => setFieldValue("amount", newValue)}
                        variant="outlined"
                        size="small"
                        {...getFieldProps("amount")}
                        error={Boolean(retentionTouched?.amount && retentionError?.amount)}
                        helperText={retentionTouched?.amount && retentionError?.amount}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DesktopDatePicker
                          views={["month", "year"]}
                          label="Due Date"
                          value={values.dueDate}
                          inputFormat="MM/yyyy"
                          onChange={(newValue) => {
                            setFieldValue("dueDate", newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              size="small"
                              error={Boolean(retentionTouched?.dueDate && retentionError.dueDate)}
                              helperText={retentionTouched?.dueDate && retentionError.dueDate}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    {isClickedOnEditIndex && (
                      <Grid item xs={3}>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DesktopDatePicker
                            label="Paid Date"
                            value={values.paidDate}
                            inputFormat="dd/MM/yyyy"
                            autoComplete="off"
                            onChange={(newValue) => {
                              setFieldValue("paidDate", newValue || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size="small"
                                error={Boolean(touched?.paidDate && errors?.paidDate)}
                                helperText={touched?.paidDate && errors?.paidDate}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    )}

                    <Grid item xs={3}>
                      {isClickedOnEditIndex ? (
                        <LoadingButton
                          size="small"
                          type="submit"
                          variant="contained"
                          sx={{ maxWidth: "Auto" }}
                          onClick={handleUpdate}
                        >
                          Save
                        </LoadingButton>
                      ) : (
                        <LoadingButton
                          size="small"
                          type="submit"
                          variant="contained"
                          sx={{ maxWidth: "Auto" }}
                          onClick={handleSubmit}
                        >
                          Add
                        </LoadingButton>
                      )}
                    </Grid>
                  </Grid>
                  {retentionHistoryDetailsData?.length > 0 && (
                    <TableContainer sx={{ minWidth: 1020 }}>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell style={{ maxWidth: "200px" }} align="left">
                              Amount
                            </TableCell>
                            <TableCell style={{ maxWidth: "200px" }}>Paid on</TableCell>
                            <TableCell style={{ maxWidth: "200px" }}>Added on</TableCell>
                            <TableCell style={{ maxWidth: "200px" }}>Due Date</TableCell>
                            <TableCell style={{ maxWidth: "200px" }}>Last Updated Date</TableCell>
                            <TableCell align="center" style={{ width: "220px" }}>
                              Actions
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {retentionHistoryDetailsData?.map((res, idx) => {
                            const isOverdue = moment().isAfter(res?.dueDate);
                            const paid = res?.isPaid;
                            return (
                              <TableRow key={idx} sx={{ backgroundColor: isOverdue && !paid && "#FFC1C3" }}>
                                <TableCell align="left">
                                  {res?.isDefault ? <CancelIcon sx={{ ml: 1, color: 'red' }} onClick={() => handleDefaultDate(res)} /> : <Checkbox checked={res?.isPaid} onClick={(e) => handleCheckBoxChange(e, res)} />}
                                  {clickedOnDefaultIconIndex === res.id && <Modal
                                    open={openDefaultIconModal}
                                    onClose={handleClose}
                                    shouldCloseOnOverlayClick={false}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                  >
                                    <Stack spacing={2} sx={style}>
                                      <Typography>
                                        Are You sure want to remove mark as default?
                                      </Typography>
                                      <div>
                                        <Checkbox checked={markAsDefaultChecked[idx]?.isDefault} onChange={(e) => {
                                          if (res.id === clickedOnDefaultIconIndex) {
                                            setMarkAsDefaultChecked((prev) => ({
                                              ...prev,
                                              [idx]: {
                                                ...prev[idx],
                                                isDefault: e.target.checked
                                              }
                                            }));
                                          }
                                        }} />
                                        <label style={{ marginTop: '7px',fontSize: '15px' }}>Mark as Default </label>
                                      </div>
                                      <Stack
                                        sx={{ display: "flex", flexDirection: "row", gap: 2 }}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      >
                                        <LoadingButton variant="contained" onClick={(e) => handleRemoveMarkAsDefault(e, res)} disabled={markAsDefaultChecked[idx]?.isDefault}>
                                          Save
                                        </LoadingButton>
                                        <Button variant="contained" onClick={handleClose}>
                                          Cancel
                                        </Button>
                                      </Stack>
                                    </Stack>
                                  </Modal>}
                                  <Modal
                                    open={open}
                                    onClose={handleClose}
                                    shouldCloseOnOverlayClick={false}
                                    aria-labelledby="modal-modal-title"
                                    aria-describedby="modal-modal-description"
                                  >
                                    <Stack spacing={2} sx={style}>
                                      {!values.isDefault &&
                                        <>
                                          <Typography id="modal-modal-title" variant="h6" component="h2">
                                            Update payment status
                                          </Typography>
                                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                                            <DesktopDatePicker
                                              label="Paid Date"
                                              value={values.paidDate}
                                              inputFormat="dd/MM/yyyy"
                                              onChange={(newValue) => {
                                                setFieldValue("paidDate", newValue || "");
                                              }}
                                              renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                                            />
                                          </LocalizationProvider>
                                        </>
                                      }
                                      <div>
                                        <Checkbox checked={values.isDefault} onChange={(e) => handleDefaultChange(e, res)} />
                                        <label style={{ marginTop: '7px', fontSize: '15px' }}>Mark as Default </label>
                                      </div>
                                      {values.isDefault && <TextField
                                        type="text"
                                        fullWidth
                                        label="Notes"
                                        multiline
                                        rows={2}
                                        maxRows={4}
                                        value={notesValue}
                                        onChange={(e) => {
                                          setNotesValue(e.target.value)
                                          setNoteError('')
                                        }}
                                        variant="outlined"
                                        size="small"
                                      />}
                                      <div style={{ color: 'red', marginLeft: '10px', fontSize: '15px' }}>
                                        {noteError && Boolean(values.isDefault) && noteError}
                                      </div>
                                      <Stack
                                        sx={{ display: "flex", flexDirection: "row", gap: 2 }}
                                        justifyContent="flex-end"
                                        alignItems="center"
                                      >
                                        <LoadingButton variant="contained" onClick={() => handleSavePaid(res)} disabled={!values.paidDate && !Boolean(values.isDefault)}>
                                          Save
                                        </LoadingButton>
                                        <Button variant="contained" onClick={handleClose}>
                                          Cancel
                                        </Button>
                                      </Stack>
                                    </Stack>
                                  </Modal>
                                </TableCell>
                                <TableCell size="small" align="left">
                                  {res?.amount}
                                </TableCell>
                                <TableCell>
                                  {res?.paidDate
                                    ? moment(res?.paidDate).format("DD/MM/YYYY h:mm A")
                                    : "-"}
                                </TableCell>
                                <TableCell>{moment(res?.createdDate).format("DD/MM/YYYY h:mm A")}</TableCell>
                                <TableCell>{res?.dueDate ? monthYearFormat(res?.dueDate) : '-'}</TableCell>
                                <TableCell>{moment(res?.updatedDate).format("DD/MM/YYYY h:mm A")}</TableCell>
                                <TableCell size="medium" align="left">
                                  <div
                                    className="action-button"
                                    style={{
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    <span>
                                      <IconButton color="primary" onClick={() => handleEditRetentionHistory(res)} disabled={res.isDefault}>
                                        <EditIcon />
                                      </IconButton>
                                      <IconButton onClick={(e) => handleDelete(e, res?.id)}>
                                        <DeleteIcon color="error" />
                                      </IconButton>
                                      {res?.isDefault && <IconButton sx={{ mt: 0.5 }}>
                                        <LightTooltip
                                          title={res.note ? res.note : 'No Notes'}
                                          placement="top"

                                        ><SpeakerNotesIcon /></LightTooltip>
                                      </IconButton>}
                                      {res?.id === isClickedOnEditIndex && (
                                        <IconButton
                                          onClick={() => {
                                            setFieldValue("amount", "");
                                            setFieldValue("dueDate", null);
                                            setIsClickedOnEditIndex("");
                                          }}
                                        >
                                          <CloseIcon />
                                        </IconButton>
                                      )}
                                    </span>
                                  </div>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Grid>
              </>
            )}
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" mb={3} mt={4}>
          <Button
            onClick={() => handleTabChange("6")}
            variant="contained"
            style={{ marginRight: "15px" }}
            className="back-button"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>
          <Button onClick={() => handleTabChange("8")} variant="contained" endIcon={<ArrowForwardIcon />}>
            Next
          </Button>
        </Stack>
      </Container>
    </Card>
  );
}
