import PropTypes from "prop-types";
// material
import Box from "@mui/material/Box";

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  return (
    <Box
      className="logo-img"
      component="img"
      src="/assets/images/logo1.svg"
      sx={{ width: 225, margin: "1rem auto", userSelect: "none" }}
    />
  );
}
