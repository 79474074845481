import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import Loader from "../../../components/Loader";
import DashboardLayout from "../../../layouts/dashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { getCollege } from "../../../redux/college/collegeThunk";
import moment from "moment";
import { useFormik } from "formik";
import { setOrderBy, setSortBy } from "../../../redux/student/studentSlice";
import {
  getDrivesWithStudents,
  totalStudentsByDrive,
} from "../../../redux/student/studentThunk";
import { deleteStudentById } from "../../../redux/student/studentThunk";
import invertDirection from "../../../utils/invertDirection";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import Scrollbar from "../../../components/Scrollbar";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { decryption, encryption } from "../../../utils/encodeString";
import {
  getAddVisible,
  getDeleteVisible,
  getEditVisible,
  getViewVisible,
} from "../../../utils/userPermission";
import { BASE_URL } from "../../../constants";
import { Autocomplete } from "@mui/material";
import { getStudentStatus } from "../../../redux/student/studentThunk";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FileUploadSharpIcon from "@mui/icons-material/FileUploadSharp";
import { exportInternFile } from "../../../redux/intern/internThunk";
import { exportStudents } from "../../../redux/intern/internThunk";

function ViewDrive() {
  let { id } = useParams();
  if (id) id = decryption(id);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const [loadings, setLoadings] = useState(true);
  const [driveData, setDriveData] = useState({});
  const [studentData, setStudentData] = useState([]);
  const [statusObj, setStatusObj] = useState({});
  const [filterData, setFilterData] = useState(null);
  const [filterChips, setFilterChips] = useState([]);
  const collegeData = useSelector((state) => state.college.data);
  const collegeStatus = useSelector((state) => state.college.status);
  const AuthRole = useSelector((state) => state.auth.role);
  const { sortBy, orderBy, isSearchQuery, loading, data } = useSelector(
    (state) => state.student
  );

  const totalStudent = useSelector(
    (state) => state.totalStudentsByDrive.totalCount
  );
  const [searchValue, setSearchValue] = useState("");
  const inputRef = React.useRef(null);
  useEffect(() => {
    if (id !== -1) {
      dispatch(totalStudentsByDrive(id));
    } else {
      navigate("/drives");
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (collegeStatus === "idle") {
      getViewVisible("college") && dispatch(getCollege({ search: "" }));
    }
  }, [dispatch, collegeStatus]);

  // useEffect(() => {
  //     dispatch(
  //       getDrivesWithStudents({
  //         id,
  //         sortBy,
  //         orderBy,
  //         search: searchString ? searchString : "",
  //       })
  //     );
  // }, [dispatch, sortBy, orderBy]);

  // const setParticipatingColleges = (participateCollege) => {
  //   let participateCollegeArray = collegeData.filter((college) =>
  //     participateCollege.includes(college.id)
  //   );
  //   setParticipatedColleges(participateCollegeArray);
  // };

  const prepareChips = (searchParams) => {
    let filters = { ...searchParams };
    let tempChips = [];

    for (const key in filters) {
      if (filters[key] && key === "search") {
        filters[key] = `${filters[key]}`;
      }

      if (filters[key] && key === "statusId") {
        let statusName = data.find((ele) => ele.id == filters[key]);
        filters[key] = `Status : ${statusName?.status}`;
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

    setFilterChips(tempChips);
  };

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    fetchDriveWithStudent({ type: "search", value: e?.target?.value });
  };
  const handleStatusChange = (newValue) => {
    setStatusObj(newValue);
    fetchDriveWithStudent({ type: "status", value: newValue.id });
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const fetchDriveWithStudent = async ({ type, value }) => {
    setLoadings(true);
    let obj = { search: searchValue, statusId: statusObj?.id };

    if (type === "status") {
      obj = { ...obj, statusId: value };
    } else if (type === "search") {
      obj = { ...obj, search: value };
    } else {
      obj = { search: "", statusId: "" };
    }
    prepareChips(obj);
    try {
      const response = await dispatch(
        getDrivesWithStudents({
          id,
          sortBy,
          orderBy,
          ...obj,
        })
      ).unwrap();
      let body = {
        college: response?.data?.college?.collegeName,
        placement: response?.data?.placement,
        startYear: response?.data?.year.split("-")[0],
        endYear: response?.data?.year.split("-")[1],
        placementDate: response?.data?.placementDate,
        placementType: response?.data?.placementType,
        participatedColleges: response?.data?.participateColleges,
        totalAptitudeMarks: response?.data?.totalAptitudeMarks,
      };
      // setParticipatedColleges(response?.participateColleges || []);
      setStudentData(response?.data?.students ? response.data.students : []);
      setDriveData({ ...driveData, ...body });
    } catch (error) {
      //navigate("/drives");
      //if there is no data then it gives error msg "No records found"]
      // setParticipatedColleges([]);
      setStudentData([]);
      setDriveData({ ...driveData });
      if (error.status === 404 && !error.success) {
        toast.error(error.message);
        //navigate("/drives");
      }
    } finally {
      setLoadings(false);
    }
  };

  useEffect(() => {
    fetchDriveWithStudent({ type: "all", value: "" });
  }, [sortBy, orderBy]);

  const onDeleteChip = (value, length) => {
    let { type } = value;
    //  delete filterData[value.type];
    //  setFilterData({ ...filterData });
    if (type === "search") {
      setSearchValue("");
      inputRef.current.value = "";
      fetchDriveWithStudent({ type: "search", value: "" });
    }

    if (type === "statusId") {
      setStatusObj({});
      fetchDriveWithStudent({ type: "status", value: "" });
    }
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    console.log("updateChips_________01", updateChips);
    setFilterChips([...updateChips]);
  };

  const handleDelete = async (id) => {
    try {
      await dispatch(deleteStudentById(id));
      fetchDriveWithStudent(searchValue);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const exportFile = () => {
    try {
      const response = dispatch(exportStudents({ id }))
        .unwrap()
        .then((response) => {
          const url = `${BASE_URL}/${response?.data}`;
          const link = document.createElement("a");
          link.href = url;
          link.click();
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  // const exportFile = async () => {
  //   try {
  //     const response = await dispatch(exportInternFile({id}))
  //       .unwrap()
  //       .then((response) => {
  //         const url = `${BASE_URL}/${response.data}`;
  //         const link = document.createElement("a");
  //         link.href = url;
  //         link.click();
  //       });
  //   } catch (error) {
  //     toast.error(error?.message);
  //   }
  // };

  useEffect(() => {
    dispatch(getStudentStatus({ sortBy: "", orderBy: "" }));
  }, []);

  return (
    <div className="view_employee">
      <Container
        maxWidth="xl"
        className="container view_employee_main-container"
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="title-button-wrapper view_employee_main_title">
            <Typography variant="h4" gutterBottom>
              Drive
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              {AuthRole === "Super Admin" &&
              Array.isArray(studentData) &&
              studentData.length != 0 ? (
                <Button
                  variant="contained"
                  onClick={exportFile}
                  startIcon={<FileUploadSharpIcon />}
                  // className="filter-icon-part"
                >
                  Export Students
                </Button>
              ) : (
                ""
              )}
              <Button
                className="back-button"
                variant="contained"
                component={RouterLink}
                to="/drives"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Stack>
          </div>
        </Stack>

        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="grid_container">
            <Grid className="view_grid_main_container_card" item xs={12}>
              <Card
                style={{ padding: "25px 15px" }}
                mb={3}
                className="address-card information-card "
              >
                <div className="address-blk">
                  <div className="address-sub-section">
                    <div className="address-div">
                      {" "}
                      <span>College</span>
                      <p>{driveData?.college}</p>
                    </div>
                    <div className="address-div">
                      <span>Placement</span>
                      <p>{driveData?.placement}</p>
                    </div>
                    <div className="address-div">
                      <span>Start Year</span>
                      <p>{driveData?.startYear}</p>
                    </div>
                    <div className="address-div">
                      <span>End Year</span>
                      <p>{driveData?.endYear}</p>
                    </div>
                    <div className="address-div">
                      <span>Placement Date</span>{" "}
                      <p>
                        {moment(driveData.placementDate).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Placement Type</span>
                      <p>{driveData?.placementType}</p>
                    </div>
                  </div>
                  {Array.isArray(driveData?.participatedColleges) ? ( //&& driveData.participateColleges.length > 0 ?
                    <div className="address-sub-section">
                      <span>Participate College</span>
                      {driveData?.participatedColleges.map((college, index) => (
                        <p>
                          {index + 1}) {college?.collegeName}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <div className="address-sub-section">
                      <div className="address-div">
                        <span>Participate Colleges</span>
                        <p>-</p>
                      </div>
                      <div className="address-div">
                        <span>Total Aptitude Marks</span>
                        <p>
                          {driveData?.totalAptitudeMarks
                            ? driveData?.totalAptitudeMarks
                            : "-"}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{ marginTop: "40px" }}
        >
          {/* <Typography variant="h4" gutterBottom>
          {`${totalStudent} Students`}
        </Typography> */}
          <div className="title-button-wrapper" style={{ borderBottom: "0" }}>
            <Typography variant="h4" gutterBottom>
              Students ({totalStudent})
              {/* <Typography variant="body2">
              Total Records : {totalStudent}
            </Typography> */}
            </Typography>
            {getAddVisible("interns") && (
              <Button
                variant="contained"
                // component={RouterLink}
                // to={`/students/add/${encryption(id)}`}
                onClick={() =>
                  navigate(`/drives/students/add/${encryption(id)}`, {
                    state: { isDrive: 1 },
                  })
                }
                startIcon={<AddIcon />}
              >
                Add Student
              </Button>
            )}
          </div>
        </Stack>
        <Card className="employee-table-grid" pb={3} mb={1}>
          <Container maxWidth="xl">
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container spacing={1} my={3}>
                <Grid item xs={8}>
                  <TextField
                    fullWidth
                    label={`Search by Name/Email/Phone Number/College Name`}
                    name="title"
                    variant="outlined"
                    size="small"
                    onChange={debounceWithSearch}
                    inputRef={inputRef}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    autoComplete
                    //  {...getFieldProps(`statusId`)}
                    options={data || []}
                    getOptionLabel={(option) => option.status || ""}
                    // isOptionEqualToValue={(option, value) => option === value}
                    onChange={(event, newValue) => {
                      handleStatusChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        // error={Boolean(touched?.statusId && errors?.statusId)}
                        // helperText={touched?.statusId && errors?.statusId}
                        error={false}
                        label="Status"
                      />
                    )}
                    value={statusObj}
                  />
                </Grid>
              </Grid>
            </form>
          </Container>
        </Card>
        <Stack className="search-filter" direction="row" alignItems="start">
          {/* {isSearchQuery && searchValue.length > 0  && filterData.map((ele) => 
            <Chip
              label={ele.label}
              color="primary"
              size="small"
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip()}
            />
          ) : (
            ""
          )} */}

          {filterChips.map((chips, idx) => {
            return (
              <Chip
                className="search-filter-detail"
                label={chips.filter}
                color="primary"
                size="small"
                key={chips.filter}
                style={{ margin: "10px 5px 10px 0" }}
                variant="filled"
                onDelete={() => onDeleteChip(chips, filterChips.length)}
              />
            );
          })}
        </Stack>
        {searchValue.length > 0 ? (
          <Typography variant="body2" mb={1}>
            {studentData.length} {"records found"}
          </Typography>
        ) : (
          ""
        )}

        {loading ? (
          <Loader />
        ) : (
          <Card style={{ marginTop: "10px" }}>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table className="grid-table student-drive-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">Name</TableCell>
                      <TableCell align="left">Contact</TableCell>
                      <TableCell align="left">Email</TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "sscPercentage"}
                          direction={
                            sortBy === "sscPercentage" ? orderBy : "asc"
                          }
                          onClick={() => handleSorting("sscPercentage")}
                        >
                          SSC
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "hscPercentage"}
                          direction={
                            sortBy === "hscPercentage" ? orderBy : "asc"
                          }
                          onClick={() => handleSorting("hscPercentage")}
                        >
                          HSC
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "pcm"}
                          direction={sortBy === "pcm" ? orderBy : "asc"}
                          onClick={() => handleSorting("pcm")}
                        >
                          12th (PCM)
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "graduationPercentage"}
                          direction={
                            sortBy === "graduationPercentage" ? orderBy : "asc"
                          }
                          onClick={() => handleSorting("graduationPercentage")}
                        >
                          Graduate
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "marks"}
                          direction={sortBy === "marks" ? orderBy : "asc"}
                          onClick={() => handleSorting("marks")}
                        >
                          Atp. Marks
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="center">Resume</TableCell>
                      <TableCell className="th-action-buttons" align="left">
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array.isArray(studentData) && studentData.length === 0 ? (
                      <TableRow>
                        <TableCell
                          className="No-Record-text"
                          align="center"
                          colSpan={11}
                        >
                          No Record(s) Found
                        </TableCell>
                      </TableRow>
                    ) : (
                      studentData.map((student, idx) => (
                        <TableRow key={idx}>
                          <TableCell align="left">
                            {student.firstName} {student.lastName}
                          </TableCell>
                          <TableCell align="left">{student?.contact}</TableCell>
                          <TableCell align="left">{student?.email}</TableCell>
                          <TableCell align="left">
                            {student?.sscPercentage}
                          </TableCell>
                          <TableCell align="left">
                            {student?.hscPercentage}
                          </TableCell>
                          <TableCell align="left">{student?.pcm}</TableCell>
                          <TableCell align="left">
                            {student?.graduationPercentage}
                          </TableCell>
                          <TableCell align="left">{student?.marks}</TableCell>
                          <TableCell align="left">
                            {student?.status?.status}
                          </TableCell>
                          <TableCell align="center">
                            {student?.resume && student?.resume[0] ? (
                              <IconButton
                                onClick={() => {
                                  window.open(student?.resume[0]?.webViewLink);
                                }}
                              >
                                <PictureAsPdfIcon />
                              </IconButton>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell
                            className="td-action-button"
                            size="medium"
                            align="left"
                          >
                            <div
                              className="action-button"
                              style={{
                                whiteSpace: "nowrap",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                {getEditVisible("interns") && (
                                  <IconButton
                                    color="primary"
                                    // component={Link}
                                    // to={`/students/edit/${encryption(student.id)}/${encryption(id)}`}
                                    onClick={() =>
                                      navigate(
                                        `/drives/students/edit/${encryption(
                                          student.id
                                        )}/${encryption(id)}`,
                                        { state: { isDrive: 1 } }
                                      )
                                    }
                                  >
                                    {/* <EditIcon /> */}
                                    <img
                                      src="/assets/images/edit.svg"
                                      title="Edit"
                                    />
                                  </IconButton>
                                )}
                              </span>
                              <span>
                                <IconButton
                                  color="primary"
                                  // component={Link}
                                  // to={`/students/edit/${encryption(student.id)}/${encryption(id)}`}
                                  onClick={() =>
                                    navigate(
                                      `/drives/students/view/${encryption(
                                        student.id
                                      )}/${encryption(id)}`
                                    )
                                  }
                                >
                                  {/* <VisibilityIcon /> */}
                                  <img
                                    title="View"
                                    src="/assets/images/vieweye-icon.svg"
                                  />
                                </IconButton>
                              </span>
                              <span>
                                {getDeleteVisible("interns") && (
                                  <IconButton
                                    onClick={() => handleDelete(student.id)}
                                  >
                                    {/* <DeleteIcon color="error" /> */}
                                    <img
                                      src="/assets/images/trash-gray.svg"
                                      title="trash"
                                    />
                                  </IconButton>
                                )}
                              </span>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </Card>
        )}
      </Container>
    </div>
  );
}

const componentConfig = {
  component: ViewDrive,
  path: "/drives/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "TPA",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
