import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboard";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Link from "@mui/material/Link";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { toast } from "react-toastify";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { decryption, encryption } from "../../../utils/encodeString";
import { getStudentById } from "../../../redux/student/studentThunk";
// import { getProject } from "../../../../redux/project/projectThunk";
import Loader from "../../../components/Loader";
import { BASE_URL } from "../../../constants";
import { fDateCustom, fDateTime } from "../../../utils/formatTime";
import { indianDateFormat } from "../../../utils/dateFormat";
import { Autocomplete, Divider, Radio, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { getEditVisible } from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../../service/localStorage";

function ViewStudent() {
  let { id } = useParams();
  if (id) id = decryption(id);
  let { driveId } = useParams();
  if (driveId) driveId = decryption(driveId);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [employeeId, setEmployeeId] = useState([]);
  // let evalutiondate = [];
  const [evolutionDateArrat, setEvolutionDateArrat] = useState([]);
  const [evolutionDate, setEvolutionDate] = useState("");
  const [startEvolutionDate, setStartEvolutionDate] = useState("");
  const [endEvolutionDate, setEndEvolutionDate] = useState("");
  const [monthList, setMonthList] = useState([]);
  const [selectedMonthEvolution, setSelectedMonthEvolution] = useState([]);
  const [evolutionList, setEvolutionList] = useState([]);
  const [internData, setInternData] = useState(null);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  // const [projectData, setProjectData] = useState([]);
  // const { sortBy, orderBy } = useSelector((state) => state.project);
  useEffect(() => {
    if (id === -1) {
      navigate(-1);
    }
  }, [id]);

  useEffect(() => {
    dispatch(getStudentById(id))
      .unwrap()
      .then((res) => {
        setInternData(res);
        
        setIsLoading(false);
      })
      .catch((err) => {
        navigate(-1);
      });
  }, [dispatch, id, navigate]);

  // const openCandidateConfirmationModal = useCallback(async () => {
  // 	try {
  // 		const response = await NiceModal.show(CandidateConfirmationModal);

  // 		if (response?.success) {
  // 			const res = await dispatch(convertToCandidate({ id })).unwrap();

  // 			if (res.status === 200) {
  // 				toast.success("Intern successfully converted to Candidate");
  // 				return navigate(`/candidate`);
  // 			}
  // 		}
  // 	} catch (error) {
  // 		toast.error(error.message);
  // 	}
  // }, [id, dispatch, navigate]);

  // useEffect(() => {
  // 	dispatch(getProject({ sortBy, orderBy }))
  // 		.unwrap()
  // 		.then((res) => {
  // 			setProjectData(res.data);
  // 		})
  // 		.catch((error) => {
  // 			toast.error(error.message);
  // 		});
  // }, [dispatch, navigate]);

  // useEffect(() => {
  // 	dispatch(getProject({ sortBy, orderBy }));
  // }, []);

  if (isLoading) {
    return <Loader></Loader>;
  }

  let selectMonth = (value) => {
    if (value.name) {
      setEvolutionList([]);
      let employeeIds = [];
      let employee = [];
      let evalutionarray = [...internData?.evalution];
      let finalevalution = evalutionarray?.filter((element, index) => {
        return new Date(element.evalDate).getMonth() + 1 === value.id;
      });
      setSelectedMonthEvolution(finalevalution);
      finalevalution.map((element, index) => {
        if (employeeIds.includes(element.employeeId)) {
          return element;
        } else {
          employeeIds.push(element.employeeId);
          employee.push(element.employee);
          return element;
        }
      });
      setEmployeeId(employee);
      // console.log(finalevalution);
    }
  };

  const groupBy = (array) => {
    return array.reduce((result, currentValue) => {
      (result[currentValue.question.questionGroup] =
        result[currentValue.question.questionGroup] || []).push(currentValue);
      //   console.log(result);
      return result;
    }, {});
  };

  let showEvolution = async (value) => {
    // console.log(value,"value")
    if (value) {
      let evolutionId = [];
      // let evolution=[]
      let evalutionarray = [...selectedMonthEvolution];
      let finalevalution = evalutionarray?.filter((element, index) => {
        return element.employeeId === value.id;
      });
      await finalevalution.map((element, index) => {
        if (evolutionId.includes(element.evalutionUniqueId)) {
          return element;
        } else {
          evolutionId.push(element.evalutionUniqueId);
          // employee.push(element.employee);
          return element;
        }
      });
      let fullEvolution = [];
      await evolutionId.map((elementId, index) => {
        // console.log(elementId, "pp");
        let evol = finalevalution.filter((element, index) => {
          return element.evalutionUniqueId == elementId;
        });
        if (evol[0]) {
          setEvolutionDate(fDateCustom(evol[0].evalDate));
          setStartEvolutionDate(fDateCustom(evol[0].evalFromDate));
          setEndEvolutionDate(fDateCustom(evol[0].evalToDate));
          let finalEvol = groupBy(evol);
          fullEvolution.push(finalEvol);

          setEvolutionDateArrat(evol);
          setEvolutionList(fullEvolution);
        }
        return elementId;
      });
    }
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
      /* console.log("Copying text command was " + msg); */
    } catch (err) {
      toast.error("Text Not Copied!");
      /* console.log("Oops, unable to copy"); */
    }

    document.body.removeChild(textArea);
  };

  return (
    <div className="view_employee candidate-details">
      <Container maxWidth="xl" className="container">
        <Stack direction="row" justifyContent="flex-end" mb={2}>
          {getEditVisible("drives") && (
            <>
              {/* <Button
                variant="contained"
                component={RouterLink}
                to={`/interns/evaluate/${encryption(internData.id)}`}
              >
                Add Evaluation
              </Button> */}
              <Button
                variant="contained"
                component={RouterLink}
                // to={`/interns/edit/${encryption(internData?.id)}`}
                to={`/drives/students/edit/${encryption(id)}/${encryption(driveId)}`}
                style={{ marginLeft: "10px" }}
              >
                Edit
              </Button>
            </>
          )}
          <Button
            variant="contained"
            onClick={() => navigate(-1)}
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back To List
          </Button>
        </Stack>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2} className="grid_container">
            <Grid item xs={4}>
              <Card style={{ height: "100%" }}>
                {/* className="address-card information-card "> */}
                <Container maxWidth="xl">
                  {" "}
                  {/*style={{ height: "100%" }}*/}
                  <div className="profile-info-part">
                    <div className="profile-image-part">
                      <Avatar
                        // src={
                        // 	internData?.gender && internData?.gender == "M"
                        // 		? "/assets/images/male.png"
                        // 		: "/assets/images/female.png"
                        // }
                        src={
                          internData?.image
                            ? `${BASE_URL}/${internData.image}`
                            : internData?.gender == "F"
                            ? "/assets/images/female.png"
                            : "/assets/images/male.png"
                        }
                        // alt={internData?.firstName}
                        sx={{
                          width: "100px",
                          height: "100px",
                          fontSize: "2.5rem",
                          // marginTop: "5px",
                        }}
                      ></Avatar>
                    </div>
                    <Typography variant="h4" align="center">
                      {internData?.firstName ? internData?.firstName : ""}{" "}
                      {internData.middleName &&
                        internData.middleName !== "" &&
                        `${internData.middleName.charAt(0).toUpperCase()}. `}
                      {internData?.lastName ? internData?.lastName : ""}
                      {""}
                      {/* {internData?.gender
                            ? internData?.gender == "M"
                              ? <Box
                              component="img"
                              src="/assets/images/male-avatar.png"
                              className="download-img"
                              style={{display: "inline"}}
                              // style={{
                              // 	marginBottom: "0",
                              // 	marginTop: "3px",
                              // 	marginLeft: "4px",
                              // 	width: "16px !important",
                              // }}
                            />
                              : <Box
                              component="img"
                              src="/assets/images/female-worker.png"
                              className="download-img"
                              style={{display: "inline"}}
                              // style={{
                              // 	marginBottom: "0",
                              // 	marginTop: "3px",
                              // 	marginLeft: "4px",
                              // 	width: "16px !important",
                              // }}
                            />
                            : ""} */}
                    </Typography>
                    <p
                      className="job-position-txt"
                      style={{ textAlign: "center" }}
                    >
                      {internData?.technology?.title
                        ? internData?.technology?.title
                        : ""}
                    </p>
                    <div className="inner-info-part" style={{ marginTop: "0" }}>
                      <Stack
                        style={{ marginTop: "3px" }}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        // my={3}
                      >
                        {internData.contact && (
                          <p>
                            <PhoneIphoneIcon color="primary" />
                            <Link>
                              {internData?.contact ? internData?.contact : ""}
                            </Link>
                            &nbsp;
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy("+91 " + internData?.contact);
                                /* navigator.clipboard.writeText(
                                  "+91" + employee?.contact?.contactNumber,
                                ); */
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </p>
                        )}
                      </Stack>
                      {/* &nbsp;&nbsp;&nbsp; */}
                      <Stack
                        style={{ marginTop: "5px" }}
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        // my={3}
                      >
                        {internData.email && (
                          <p>
                            <a href={`mailto:${internData?.email}`}>
                              <Box
                                component="img"
                                src="/assets/images/mail.svg"
                                className="mail-img"
                                style={{
                                  minWidth: "20px",
                                }}
                              />
                            </a>
                            <Link>{internData?.email}</Link> &nbsp;
                            <img
                              src="/assets/images/vfile.svg"
                              onClick={() => {
                                ClicktoCopy(internData?.email);
                              }}
                              style={{ cursor: "pointer" }}
                            ></img>
                          </p>
                        )}
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        mt={1}
                      >
                        <Chip
                          style={{ marginRight: "8px" }}
                          label={
                            internData?.status?.status
                              ? internData?.status?.status
                              : ""
                          }
                          color="primary"
                        />

                        {internData?.candidateId ? (
                          <Chip
                            style={{ marginRight: "8px" }}
                            label={
                              internData?.candidateId ? (
                                <Button
                                  style={{
                                    padding: "5px",
                                    fontSize: "13px",
                                    fontWeight: "600",
                                  }}
                                  component={RouterLink}
                                  to={`/candidate/add/${encryption(
                                    internData?.candidateId
                                  )}`}
                                >
                                  Candidate
                                </Button>
                              ) : (
                                ""
                              )
                            }
                            color="primary"
                          />
                        ) : (
                          ""
                        )}

                        {/* {internData?.employeeId ? (
													<Chip
														style={{ marginRight: "8px" }}
														label={
															internData?.employeeId ? (
																<Button
																	style={{
																		padding: "3px",
																		fontSize: "13px",
																		fontWeight: "600",
																	}}
																	component={RouterLink}
																	to={`/employee/add/${encryption(
																		internData?.employeeId,
																	)}`}
																>
																	Employee
																</Button>
															) : (
																""
															)
														}
														color="primary"
													/>
												) : (
													""
												)} */}

                        {internData?.resume ? (
                          <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                              internData?.resume
                                ? internData?.resume[0]?.webViewLink
                                : ""
                            }
                            // style={{marginLeft:"10px"}}
                          >
                            <Box
                              component="img"
                              src="/assets/images/download-icon.svg"
                              className="download-img"
                              // style={{
                              // 	marginBottom: "0",
                              // 	marginTop: "3px",
                              // 	marginLeft: "4px",
                              // 	width: "16px !important",
                              // }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                      </Stack>
                    </div>

                    <div className="phone-blk">
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Joining Date
                        </Typography>
                      </div>
                      <div
                        className="mail-dropdown-div"
                        style={{ marginLeft: "10px" }}
                      >
                        <span>
                          {internData?.joiningDate
                            ? indianDateFormat(internData?.joiningDate)
                            : "-"}
                        </span>
                      </div>
                    </div>
                    <div className="phone-blk">
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Mode
                        </Typography>
                      </div>
                      <div
                        className="mail-dropdown-div"
                        style={{ marginLeft: "10px" }}
                      >
                        <span>{internData?.mode ? internData?.mode : "-"}</span>
                      </div>
                    </div>
                    {/* <div className="phone-blk">
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Gender
                        </Typography>
                      </div>
                      <div
                        className="mail-dropdown-div">
                        <span style={{ marginLeft: "10px", marginRight:"20px" }}>
                          {internData?.gender
                            ? internData?.gender == "M"
                              ? <Box
                              component="img"
                              src="/assets/images/male-avatar.png"
                              className="download-img"
                              // style={{
                              // 	marginBottom: "0",
                              // 	marginTop: "3px",
                              // 	marginLeft: "4px",
                              // 	width: "16px !important",
                              // }}
                            />
                              : <Box
                              component="img"
                              src="/assets/images/female-worker.png"
                              className="download-img"
                              // style={{
                              // 	marginBottom: "0",
                              // 	marginTop: "3px",
                              // 	marginLeft: "4px",
                              // 	width: "16px !important",
                              // }}
                            />
                            : ""}
                        </span>
                      </div>
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Mode
                        </Typography>
                      </div>
                      <div
                        className="mail-dropdown-div"
                        // style={{ marginLeft: "10px" }}
                        >
                        <span>
                          {internData?.mode
                            ? internData?.mode
                            : "-"}
                        </span>
                      </div>
                    </div> */}
                    <div className="phone-blk">
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Project Type
                        </Typography>
                      </div>
                      <div className="mail-dropdown-div">
                        <span
                          style={{ marginLeft: "10px", marginRight: "20px" }}
                        >
                          {internData?.projectType
                            ? internData?.projectType
                            : "-"}

                          {internData?.projectType &&
                          internData?.projectType == "IDP" &&
                          // <span>
                          internData?.projects &&
                          internData?.projects.length > 0
                            ? internData.projects.map((p) => {
                                return (
                                  <IconButton
                                    onClick={() => {
                                      window.open(
                                        p.project.projectDefination[0]
                                          .webViewLink
                                      );
                                    }}
                                    title={p.project.projectName}
                                  >
                                    <PictureAsPdfIcon />
                                  </IconButton>
                                );
                              })
                            : ""}
                        </span>
                      </div>
                      {/* <div >
                      <Typography variant="caption" className="detail-label">
                          Project Definition
                        </Typography>
                      </div> */}
                      {/* {internData?.projectType
                            &&internData?.projectType=="IDP"?
                      <div
                        className="mail-dropdown-div"
                        >
                        <span>
                          {internData?.projects && internData?.projects.length > 0
                            ? internData.projects.map((p) => {
                              return (<IconButton
                                onClick={() => {window.open(p.project.projectDefination[0].webViewLink)}}
                                title={p.project.projectName}
                              >
                                <PictureAsPdfIcon/>
                               </IconButton>)
                            })
                            : ""}
                        </span>
                      </div>:''} */}
                    </div>
                    <div className="phone-blk">
                      <div>
                        <Typography variant="caption" className="detail-label">
                          Work Location
                        </Typography>
                      </div>
                      <div style={{ fontSize: "13px", marginLeft: "10px" }}>
                        {internData && internData.workLocation
                          ? internData?.workLocation
                          : "-"}
                      </div>
                    </div>
                    {internData && internData.seatNumber && (
                      <div className="phone-blk">
                        <div>
                          <Typography
                            variant="caption"
                            className="detail-label"
                          >
                            Seat Number
                          </Typography>
                        </div>
                        <div style={{ fontSize: "13px", marginLeft: "10px" }}>
                          {internData && internData.seatNumber
                            ? internData?.seatNumber
                            : "-"}
                        </div>
                      </div>
                    )}
                  </div>
                </Container>
              </Card>
            </Grid>
            <Grid item xs={8}>
              <Card className="address-card information-card ">
                <Typography variant="h6" className="information-card-heading">
                  Education Details
                </Typography>
                <Stack direction="row" spacing={2}>
                  <div className="address-blk" style={{ width: "90%" }}>
                    <div className="address-sub-section">
                      <div className="address-div">
                        {" "}
                        <span>Type</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.education?.title
                            ? internData?.education?.title
                            : ""}{" "}
                        </p>
                      </div>
                      <div className="address-div">
                        {" "}
                        <span>Course Name</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.course?.title
                            ? internData?.course?.title
                            : "-"}
                        </p>
                      </div>
                      <div className="address-div">
                        <span>Year</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.batch ? internData?.batch : "-"}
                        </p>
                      </div>
                      <div className="address-div">
                        {" "}
                        <span>Graduation (CGPA)</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.graduationPercentage
                            ? `${internData?.graduationPercentage}`
                            : "-"}{" "}
                        </p>
                      </div>
                      <div className="address-div">
                        {" "}
                        <span>Master (CGPA)</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.masterPercentage
                            ? `${internData?.masterPercentage}`
                            : "-"}{" "}
                        </p>
                      </div>
                    </div>
                    <div className="address-sub-section">
                      <div className="address-div">
                        <span>Diploma (CGPA)</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.diplomaPercentage ? (
                            <>{internData?.diplomaPercentage}</>
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      <div className="address-div">
                        <span>HSC %</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.education?.title === "DIPLOMA"
                            ? "-"
                            : internData?.hscPercentage
                            ? `${internData?.hscPercentage}%`
                            : "-"}
                        </p>
                      </div>
                      <div className="address-div">
                        <span>HSC Stream</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.hscCource?.title ? (
                            <>{internData?.hscCource?.title}</>
                          ) : (
                            "-"
                          )}
                        </p>
                      </div>
                      <div className="address-div">
                        <span>{`12th (PCM)`}</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.education?.title === "DIPLOMA"
                            ? "-"
                            : internData?.pcm
                            ? `${internData?.pcm}`
                            : "-"}
                        </p>
                      </div>
                      <div className="address-div">
                        <span>SSC %</span>
                        <p style={{ justifyContent: "space-evenly" }}>
                          {internData?.sscPercentage
                            ? `${internData?.sscPercentage}%`
                            : "-"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <img
                    height="186px"
                    src={
                      internData?.evaluationGrade?.toLowerCase() == "a"
                        ? "/assets/images/A.png"
                        : internData?.evaluationGrade?.toLowerCase() == "b"
                        ? "/assets/images/B.png"
                        : internData?.evaluationGrade?.toLowerCase() == "c"
                        ? "/assets/images/C.png"
                        : internData?.evaluationGrade?.toLowerCase() == "d"
                        ? "/assets/images/D.png"
                        : ""
                    }
                  />
                </Stack>
              </Card>
              <Card
                className="address-card information-card "
                style={{ marginTop: "15px" }}
              >
                <Typography variant="h6" className="information-card-heading">
                  College Details
                </Typography>
                <div className="address-blk" style={{ width: "90%" }}>
                  <div>
                    <div className="address-div">
                      <span>Name</span>
                      <p style={{ width: "700px", alignContent: "flex-start" }}>
                        {internData?.college?.website ? (
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              textDecoration: "none",
                              color: "black",
                            }}
                            href={
                              internData?.college?.website
                                ? internData?.college?.website
                                : "-"
                            }
                          >
                            {internData?.college?.collegeName}
                          </a>
                        ) : (
                          internData?.college?.collegeName
                        )}
                      </p>
                    </div>
                    <div className="address-div">
                      <span>Address</span>
                      <p style={{ width: "700px", alignContent: "flex-start" }}>
                        {" "}
                        {internData?.college?.streetLine1
                          ? internData?.college?.streetLine1
                          : ""}
                        {internData?.college?.streetLine2
                          ? `, ${internData?.college?.streetLine2}`
                          : ""}
                        {internData?.college?.area
                          ? `, ${internData?.college?.area}`
                          : ""}
                        {internData?.college?.city
                          ? `, ${internData?.college?.city}`
                          : ""}
                        {internData?.college?.zipcode
                          ? `-${internData?.college?.zipcode}`
                          : ""}
                        {internData?.college?.state
                          ? `, ${internData?.college?.state}`
                          : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </Card>
            </Grid>
          </Grid>
        </Box>
        
        <Typography variant="h6" my={2}>
          Activity Logs
        </Typography>
        <Card className="custom-inner-grid" sx={{ marginBottom: "40px" }}>
          <Timeline position="right">
            {internData.activitylogs?.length > 0 ? (
              internData.activitylogs
                ?.slice()
                .reverse()
                .map((logs) => {
                  return (
                    <TimelineItem sx={{ maxWidth: "100%" }}>
                      <TimelineOppositeContent
                        sx={{ m: "auto 0" }}
                        style={{ maxWidth: "160px" }}
                        align="right"
                        variant="body2"
                        color="text.secondary"
                      >
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                            color: "#000",
                          }}
                        >
                          {logs.approachDate
                            ? fDateTime(logs?.approachDate)
                            : "-"}
                        </Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot color="primary">
                          <AccountCircleIcon />
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 2 }}>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Approached By :-
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#616161",
                            }}
                          >
                            {logs?.employee
                              ? `${logs?.employee?.firstName} ${logs?.employee?.lastName}`
                              : "-"}
                          </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Status :-
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#616161",
                            }}
                          >
                            {logs?.status?.status || "-"}
                          </Typography>
                        </Stack>
                        {/* <Stack direction="row" spacing={1}>
                        <Typography
                          component="span"
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            color: "#000",
                          }}>
                          State :-
                        </Typography>
                        <Typography
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            color: "#616161",
                          }}>
                          {logs?.candidateState?.name || "-"}
                        </Typography>
                      </Stack> */}
                        <Stack direction="row" spacing={1}>
                          <Typography
                            component="span"
                            style={{
                              fontSize: "14px",
                              fontWeight: "600",
                              color: "#000",
                            }}
                          >
                            Note :-
                          </Typography>
                          <Typography
                            style={{
                              fontSize: "14px",
                              fontWeight: "400",
                              color: "#616161",
                            }}
                          >
                            {logs?.note || "-"}
                          </Typography>
                        </Stack>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })
            ) : (
              <TableRow>
                <TableCell className="No-Record-text" colSpan={12} align="center">
                  No Record(s) Found
                </TableCell>
              </TableRow>
            )}
          </Timeline>
        </Card>
      </Container>
    </div>
  );
}

const componentConfig = {
  component: ViewStudent,
  // path: "/drives/students/view/:id",
  path: "/drives/students/view/:id/:driveId",
  public: false,
  layout: DashboardLayout,
  group: "interns",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
