import React from "react";
import { useFormik } from "formik";

import DashboardLayout from "../../../layouts/dashboard";

import Link from "@mui/material/Link";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Autocomplete from "@mui/material/Autocomplete";

import "./AddManageProjects.css";

const priorityData = ['Normal', 'Urgent', 'Less'];

function AddManageProject() {

  const formik = useFormik({
    initialValues: {
      title: "",
      startDate: null,
      endDate: null,
    },
    onSubmit: (values) => {
      // console.log(values);
    },
  });

  const {
    values,
    setFieldValue,
    getFieldProps,
  } = formik;

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
            <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Create Project
        </Typography>
        </div>
      </Stack>
      <Card className="create-project-grid">
        <Container maxWidth="xl">
          <Paper>
            <form>
              <Grid container spacing={3} sx={{ padding: "24px 0" }}>
                <Grid item xs={6}>
                  <TextField
                    label="Project Name"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="client">Client</InputLabel>
                    <Select
                      labelId="client"
                      id="demo-simple-select-helper"
                      label="Client"
                      size="small"
                    >
                      <MenuItem value={10}>globel Technologies</MenuItem>
                      <MenuItem value={20}>BE</MenuItem>
                      <MenuItem value={30}>MCA</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3} direction="row">
                      <DesktopDatePicker
                        label="Start Date"
                        value={formik.values.startDate}
                        // minDate={new Date('2017-01-01')}
                        onChange={(newValue) => {
                          formik.setFieldValue("startDate", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth size="small" />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3} direction="row">
                      <DesktopDatePicker
                        label="End Date"
                        value={formik.values.endDate}
                        // minDate={new Date('2017-01-01')}
                        onChange={(newValue) => {
                          formik.setFieldValue("endDate", newValue);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} fullWidth size="small" />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        label="Rate"
                        variant="outlined"
                        size="small"
                        placeholder="$50"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        size="small"
                        sx={{ minWidth: 120 }}
                        fullWidth
                      >
                        <InputLabel id="hourly">Hourly</InputLabel>
                        <Select
                          labelId="hourly"
                          id="demo-simple-select-helper"
                          label="Hourly"
                          size="small"
                        >
                          <MenuItem value={10}>Hourly</MenuItem>
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    <InputLabel id="priority">Priority</InputLabel>
                    <Select
                      labelId="priority"
                      id="demo-simple-select-helper"
                      label="Priority"
                      size="small"
                    >
                      <MenuItem value={10}>Normal</MenuItem>
                      <MenuItem value={20}>Urgent</MenuItem>
                      <MenuItem value={30}>Less</MenuItem>
                    </Select>

                    {/*<Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="priority"
                      size="small"
                      //{...getFieldProps("formik.priority")}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(`formik.priority`, newValue);
                      }}

                      value={values.formik.priority || ""}
                      
                      options={priorityData || []}
                      renderInput = {(params) => 
                      <TextField {...params} label="Priority" />}   
                      />*/}
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Add Project Leader"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Add Team"
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} className="team-group">
                  <h5>Team Leader</h5>
                  <Link href="#" title="Joan Due">
                    <figure>
                      <img
                        src="/assets/images/user-img1@2x.png"
                        alt="Team Leader"
                      />
                    </figure>
                  </Link>
                </Grid>
                <Grid item xs={6} className="team-group">
                  <h5>Team Members</h5>
                  <div className="projectTeam-member-group">
                    <Link href="#" title="Joan Due">
                      <figure>
                        <img
                          src="/assets/images/user-img1@2x.png"
                          alt="Team Leader"
                        />
                      </figure>
                    </Link>
                    <Link href="#" title="Joan Abrama">
                      <figure>
                        <img
                          src="/assets/images/user-img2@2x.png"
                          alt="Team Leader"
                        />
                      </figure>
                    </Link>
                    <Link href="#" title="Corana">
                      <figure>
                        <img
                          src="/assets/images/user-img3@2x.png"
                          alt="Team Leader"
                        />
                      </figure>
                    </Link>
                    <Link href="#" title="Emicron">
                      <figure>
                        <img
                          src="/assets/images/user-img4@2x.png"
                          alt="Team Leader"
                        />
                      </figure>
                    </Link>
                    <h5 className="more">+2</h5>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="Description"
                      minRows={4}
                      placeholder="Enter your message here"
                      fullWidth
                      className="custom-textarea"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <div className="form-group custom-upload-part">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        disabled
                        placeholder="Upload File"
                        aria-label="Upload File"
                        aria-describedby="basic-addon1"
                      />
                      <div className="input-group-append">
                        <input type="file" className="file-part" />
                        <Button
                          variant="contained"
                          className="upload-button"
                          startIcon={<FileUploadIcon />}
                        >
                          {/* Upload */}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </form>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              mb={3}
            >
              <Button variant="contained">Submit</Button>
            </Stack>
          </Paper>
        </Container>
      </Card>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddManageProject,
    path: "/manage-project/add",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddManageProject,
    path: "/pmanage-project/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
