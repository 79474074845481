import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import "./style.css";
import DashboardLayout from "../../../layouts/dashboard";
import { Link as RouterLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { decryption } from "../../../utils/encodeString";
import Loader from "../../../components/Loader";

import {
  addQuestion,
  getALLQuestions,
  getAllQuestionSectionWise,
  getQuestionById,
  updateQuestionById,
} from "../../../redux/question/questionThunk";
import {
  getAllSections,
  getAllQuestions,
} from "../../../redux/question/questionThunk";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";
import { makeStyles } from "@material-ui/core/styles";
import Downshift from "downshift";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { getDesignation } from "../../../redux/designation/designationThunk";
import { getTLandPMByDepartmentIds } from "../../../redux/employee/employeeThunk";
import evaluationSchema from "../../../validations/evaluationSchema";
import {
  createEvalution,
  evaluationById,
  updateEvaluation,
} from "../../../redux/evaluation/evaluationThunk";
import { Divider } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));

function ViewEvaluation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [evaluationData, setEvaluationData] = useState({});
  const loading = useSelector((state) => state.evaluation.loading);
  let { id } = useParams();
  if (id) id = decryption(id);
//   console.log(id);
  const classes = useStyles();
  const sectionData = useSelector((state) => state.questions.sectionData);
  const questionsSectionWiseData = useSelector(
    (state) => state?.questions?.questionsSectionWiseData
  );
//   console.log("DESIGNATION", designationData.data);
//   console.log("SECTIONS", sectionData);
//   console.log("QUESTIONS", questionsSectionWiseData);

  useEffect(() => {
    dispatch(getDesignation({ sortBy: "", orderBy: "" }));
    dispatch(getAllSections({ sortBy: "", orderBy: "" }));
    dispatch(getAllQuestionSectionWise({}));
  }, []);
  useEffect(() => {
    if (id === -1) {
      navigate("/evaluations");
    }
  }, [dispatch, id]);

  useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let evaluationData = await dispatch(evaluationById(id)).unwrap();
        //   console.log("evaluationById", evaluationData?.data);
          const convertedObject = {
            title: evaluationData?.data?.title,
            designationId: evaluationData?.data?.designationId,
            isActive: evaluationData?.data?.isActive == 1 ? true : false,
            sections: Object.entries(evaluationData?.data?.sectionId).map(
              ([section, questions]) => ({
                section: section,
                questions: questions.map((question) => ({
                  question: question,
                })),
              })
            ),
          };
        //   console.log("BODY___________________", convertedObject);
          setEvaluationData(convertedObject);
          // setLoading(false);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
          navigate(`/evaluations`);
        }
      }
      // setLoading(false);
    };
    fetchById();
    // }, [dispatch, setValues, id, navigate]);
  }, [dispatch, id, navigate]);

  const getSectionAndQuestion = (sectionId,questionId) => {
    if(sectionId  && questionId==null){
        let section = sectionData?.find((sec) => sec?.id == sectionId);
        return section?.section || "-";
    }
    if(questionId){
        let section = questionsSectionWiseData?.[sectionId];
        let questionArr=section?.questions
        let questionObj=questionArr?.find((ques)=>ques?.id==questionId)
        // console.log("questionObj",questionObj)
        return questionObj?.title || "-";  
    }
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom mb={3}>
          View Evaluation
        </Typography>
        <Stack direction="row" alignItems="center">
          <Button
            variant="contained"
            component={RouterLink}
            to="/evaluations"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back To List
          </Button>
        </Stack>
        </div>
      </Stack>
      {loading ? <Loader /> : (
      <Card>
        <Container maxWidth="xl">
          <Grid
            container
            spacing={3}
            py={3}
            // style={{ paddingBottom: "13px", paddingTop: "17px" }}
          >
            <Grid item xs={12}>
              <span>
                <strong>Evaluation Title : </strong>
              </span>{" "}
              <span>{evaluationData?.title}</span>
            </Grid>

            <Grid item xs={12}>
              <span>
                <strong>Designation : </strong>
              </span>
              {evaluationData?.designationId?.map((designation, index) => (
                <Chip
                  key={index}
                  tabIndex={-1}
                  label={designation?.title}
                  className={classes.chip}
                />
              )) || "-"}
            </Grid>
            <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
            </Grid>
            <Grid item xs={12}>
              {evaluationData?.sections?.map((sec, index) => (
                <Grid
                  container
                  spacing={3}
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  {index != 0 && (
                    <Grid item xs={12}>
                      <Divider sx={{ my: 1 }} />
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    <span>
                      <strong>Section {index + 1}: </strong>
                    </span>
                    {getSectionAndQuestion(sec?.section,null)}
                  </Grid>
                  <Grid item xs={12}>
                    {sec &&
                      sec?.questions.map(
                        (que, index1) => (
                          <Grid
                            container
                            spacing={3}
                            style={{ paddingLeft: "20px",paddingTop:'10px',paddingBottom:'10px' }}
                          >
                            <Grid item xs={6}>
                              <span><strong>Question {index1 + 1} : </strong></span>
                              {getSectionAndQuestion(sec?.section,que?.question)}
                            </Grid>
                            
                          </Grid>
                        )
                      )}
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Container>
      </Card>
      )}
    </Container>
  );
}

const componentConfig = {
  component: ViewEvaluation,
  path: "/evaluations/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "evaluation",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
