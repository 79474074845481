
import DashboardLayout from "../../layouts/dashboard";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";

import Scrollbar from "../../components/Scrollbar";
import Loader from "../../components/Loader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import {
  getCandidateState,
  getCandidateStatus
} from "../../redux/candidateState/candidateStatethunk";
import { 
	setSortBy,
	setOrderBy,
	setCurrentPage,
	setLimit
 } from "../../redux/workFlow/workFlowSlice";
import invertDirection from "../../utils/invertDirection";
import CustomPagination from "../../components/Pagination";

import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../utils/encodeString";
import localStorage from "../../service/localStorage";
import { getEmailTemplatesDynamic } from "../../redux/emailTemplateDynamic/emailTemplateDynamicThunk";
import { workFlowSchema } from "../../validations/workFlowSchema";
import { insertWorkFlow, getWorkFlow, deleteById, updateById, getTotalWorkflowCount } from "../../redux/workFlow/workFlowThunk";
import { constrainPoint } from "@fullcalendar/react";

function WorkFlow() {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const emailTemplateData = useSelector((state) => state.emailTemplateDynamic.data);
	const stateData = useSelector((state) => state.candidateState.data);
  const statusData = useSelector((state) => state.candidateState.statusData);
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	const workflowCount = useSelector((state) => state.workflowCount.totalCounts);
	let [emailData, setEmailData] = useState();
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			localStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=localStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	const {
		data,
		loading,
		sortBy,
		orderBy,
		totalRecords,
		limit,
		currentPage,
		totalPages,
	} = useSelector((state) => state.workFlow);

	const [id, setId] = React.useState(null);
	const formik = useFormik({
		initialValues: {
			emailTemplateId : "",
   		candidateStatusId : "",
    	candidateStateId : "",
		},
		validationSchema: workFlowSchema,
		onSubmit: async (values, { resetForm }) => {
			try {
				if (id) {
					await dispatch(updateById({ ...values, id })).unwrap();
				}
				if (!id) {
					await dispatch(insertWorkFlow(values)).unwrap();
				}
				resetForm();
				dispatch(getWorkFlow({ sortBy, orderBy, page: currentPage + 1, limit: limit }));
				return setId(null);
			} catch (error) {
				toast.error(error.message);
			}
		},
	});
	const {
		errors,
		values,
		touched,
		handleChange,
		handleSubmit,
		setValues,
		setFieldValue,
		isSubmitting,
		getFieldProps,
	} = formik;

	useEffect(() => {
		dispatch(
			getWorkFlow({ sortBy, orderBy, page: currentPage + 1, limit: limit }),
		);
	}, [dispatch, limit, currentPage, sortBy, orderBy]);

	useEffect(() => {
		if (!getViewVisible("workFlow")) {
			dispatch(
				getWorkFlow({ sortBy, orderBy, page: currentPage + 1, limit: limit }),
			);
			navigate("/dashboard");
		}
	}, []);

	useEffect(() => {
    if (Array.isArray(statusData) && statusData.length <= 0)
      dispatch(getCandidateStatus());
  }, []);

  useEffect(() => {
    if (
      !loading &&
      (!stateData || (Array.isArray(stateData) && stateData.length <= 0))
    )
      dispatch(getCandidateState({sortBy, orderBy}));
  }, [dispatch, data]);

	useEffect(() => {
		// if (!error) {
			dispatch(getTotalWorkflowCount({}));
		// }
	}, [loading, dispatch, loading]);

//   useEffect(() => {
//     dispatch(
//       getCandidateState({
//         sortBy,
//         orderBy,
//       }),
//     );
// }, [dispatch,sortBy, orderBy]);

	const handleUpdate = (data) => {
		setValues({
			emailTemplateId : data.emailTemplateId,
   		candidateStatusId : data.candidateStatusId,
    	candidateStateId : data.candidateStateId,
		});
		setFieldValue("emailTemplateId", data.emailTemplateId)
		setFieldValue("candidateStatusId", data.candidateStatusId)
		setFieldValue("candidateStateId", data.candidateStateId)
		setId(data.id);
	};

	const handleDelete = async (id, title) => {
		try {
			await confirm({
				description: `Are you sure you want to delete?`,
			});
			await dispatch(deleteById(id));
			await dispatch(getWorkFlow({ sortBy, orderBy }));
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) }),
		);
	};

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage({ page: newPage }));
	};
	const handleChangeRowsPerPage = (event) => {
		dispatch(setCurrentPage({ page: 0 }));
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
	};

	useEffect(() => {		
		const temp = [...emailTemplateData];
		temp?.map((e, index) => {
			if(e.title.includes("Header"))
			{
				temp.splice(index, 1)
			}	
		})
		setEmailData(temp)
		// console.log("Email Data",emailData)
		// console.log("email template data", emailTemplateData)
	}, [loading, dispatch]);

	return (
		<Container maxWidth="xl">
		<div className="title-button-wrapper">
			<Typography variant="h4" gutterBottom mb={3}>
				Work Flows
				<Typography variant="body2">
						Total Records : {workflowCount}
					</Typography>
			</Typography>
			</div>
			{getAddVisible("workFlow") && (
				<Card>
					<Container maxWidth="xl">
						<form
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
							className="custom-space-layout"
						>
							<Grid
								container
								spacing={3}
								py={3}
								style={{ paddingBottom: "13px", paddingTop: "17px" }}
							>
								<Grid item xs={5}>
									<Autocomplete
										size="small"
										fullWidth
										options={emailData || []}
										getOptionLabel={(option) => option?.title || ""}
										// {...getFieldProps("emailTemplateId")}
										onChange={(event, newValue) => {
											setFieldValue(
												"emailTemplateId",
												newValue?.id || "",
											);
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.emailTemplateId &&
														errors?.emailTemplateId,
												)}
												helperText={
													touched?.emailTemplateId &&
													errors?.emailTemplateId
												}
												label="Email Template"
											/>
										)}
										value={emailData?.find(
											(temp) =>
												temp.id == values.emailTemplateId,
										) || ""}
										error={true}
									/>
								</Grid>
								<Grid item xs={5}>
								<Autocomplete
										size="small"
										fullWidth
										options={stateData || []}
										getOptionLabel={(option) => option?.candidateStatus?.name ? `${option?.candidateStatus?.name} => ${option?.name}` : ""}
										// {...getFieldProps("candidateStateId")}
										onChange={(event, newValue) => {
											setFieldValue(
												"candidateStateId",
												newValue?.id || "",
											);
											setFieldValue("candidateStatusId", newValue?.candidateStatusId)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.candidateStateId &&
														errors?.candidateStateId,
												)}
												helperText={
													touched?.candidateStateId &&
													errors?.candidateStateId
												}
												label="Action"
											/>
										)}
										value={stateData?.find(
											(temp) =>
												temp.id == values.candidateStateId,
										) || ""}
										error={true}
									/>
								</Grid>
								
								<Grid item xs={2}>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<Stack className="button-part">
											<LoadingButton
												size="small"
												type="submit"
												variant="contained"
												loading={isSubmitting}
												data-cy="btn-submit-department"
											>
												save
											</LoadingButton>
										</Stack>
									</Stack>
								</Grid>
							</Grid>
							{/* <div className="heading-part"></div> */}
						</form>
					</Container>
				</Card>
			)}

			<Card className="custom-grid">
				<Scrollbar>
					<TableContainer component={Paper} sx={{ minWidth: 800 }}>
						{loading ? (
							<Loader />
						) : (
							<Card>
								<CustomPagination
									totalPage={totalPages}
									totalCount={totalRecords}
									limit={limit}
									handleChangePage={handleChangePage}
									page={currentPage}
									rowsPerPageOptions={[10, 20, 40]}
									handleRowsPerPageChange={handleChangeRowsPerPage}
									//filter={isSearchQuery ? true : false}
								/>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell width="30%">
												<TableSortLabel
													active={sortBy === "emailtemplate"}
													direction={sortBy === "emailtemplate" ? orderBy : "asc"}
													onClick={() => handleSorting("emailtemplate")}
												>
													Email Template
												</TableSortLabel>
											</TableCell>
											<TableCell width="20%" align="center">
												<TableSortLabel
													active={sortBy === "candidatestatus"}
													direction={sortBy === "candidatestatus" ? orderBy : "asc"}
													onClick={() => handleSorting("candidatestatus")}
												>
													Status
												</TableSortLabel>
											</TableCell>
											<TableCell width="20%" align="center">
												<TableSortLabel
													active={sortBy === "candidatestate"}
													direction={sortBy === "candidatestate" ? orderBy : "asc"}
													onClick={() => handleSorting("candidatestate")}
												>
													State
												</TableSortLabel>
											</TableCell>
											{(getEditVisible("workFlow") ||
												getDeleteVisible("workFlow")) && (
												<TableCell
													width="10%"
													align="right"
													className="action-field"
												>
													Actions
												</TableCell>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{Array.isArray(data) && data.length === 0 ? (
											<TableRow>
												<TableCell className="No-Record-text" align="center" colSpan={3}>
												No Record(s) Found
												</TableCell>
											</TableRow>
										) : (
											data?.map((workflow, idx) => (
												<TableRow
													key={idx}
													sx={{
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													<TableCell component="th" scope="row">
														{workflow?.emailtemplate?.title ? workflow?.emailtemplate?.title : "-"}
													</TableCell>
													<TableCell align="center">
														{workflow?.candidatestatus?.name ? workflow?.candidatestatus?.name : "-"}
													</TableCell>
													<TableCell align="center">
														{workflow?.candidatestate?.name ? workflow?.candidatestate?.name : "-"}
													</TableCell>
													{(getEditVisible("workFlow") ||
														getDeleteVisible("workFlow")) && (
														<TableCell align="right">
															{getEditVisible("workFlow") && (
																<IconButton
																	data-cy="btn-edit-holiday"
																	onClick={() => handleUpdate(workflow)}
																>
																	<EditIcon color="primary" />
																</IconButton>
															)}
															{getDeleteVisible("workFlow") && (
																<IconButton
																	data-cy="btn-delete-holiday"
																	onClick={() =>
																		handleDelete(
																			workflow.id,
																			// workflow.title,
																		)
																	}
																>
																	<DeleteIcon color="error" />
																</IconButton>
															)}
														</TableCell>
													)}
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</Card>
						)}
					</TableContainer>
				</Scrollbar>
			</Card>
		</Container>
	);
}

const componentConfig = {
	component: WorkFlow,
	path: "/workFlow",
	public: false,
	layout: DashboardLayout,
	group: "users",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;


