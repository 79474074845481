import React, { useEffect, useRef, useState } from "react";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import MagicUrl from "quill-magic-url";
import QuillBetterTable from "quill-better-table";
import "quill-better-table/dist/quill-better-table.css";
import { Button, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import "./editor.css";
import { useConfirm } from "material-ui-confirm";
import { getEditVisible } from "../../../utils/userPermission";


Quill.register("modules/magicUrl", MagicUrl);
Quill.register("modules/better-table", QuillBetterTable);

const Editor = ({ editorContent,handleSave,isSystemDefault,isContentChanged,setIsContentChanged }) => {
  const confirm = useConfirm();

  const quillRef = useRef(null);
  const [quillInstance, setQuillInstance] = useState(null);
  const [content, setContent] = useState(editorContent);
  const [initialContent, setInitialContent] = useState(editorContent);

  const insertTableToEditor = (quill) => {
    if (quill) {
      const tableModule = quill.getModule("better-table");
      tableModule.insertTable(3, 3);
    } else {
      console.log("quillInstance is not ready.");
    }
  };

  // const formats = [
  //   'background',
  //   'bold',
  //   'color',
  //   'font',
  //   'code',
  //   'italic',
  //   'link',
  //   'size',
  //   'strike',
  //   'script',
  //   'underline',
  //   'blockquote',
  //   'header',
  //   'indent',
  //   'list',
  //   'align',
  //   'direction',
  //   'code-block',
  //   'formula',
  //   // 'table',
  //   "better-table",
  //   // 'image'
  //   // 'video'
  // ];

  useEffect(() => {
    const toolbarOptions = [
      ["undo", "redo"],
      [{ font: [] }],
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike"],
      [{ color: [] }, { background: [] }],
      [{ script: "sub" }, { script: "super" }],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      [{ align: [] }],
      ["link"],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ direction: "rtl" }],
      ["clean"],
      ["table"],
    ];

    let icons = Quill.import("ui/icons");
    icons.undo =
      '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 28 28"><path fill="currentColor" d="M13.251 5.501a5.125 5.125 0 0 1 7.248 7.248L8.955 24.293a1 1 0 0 0 1.414 1.414l11.544-11.544A7.125 7.125 0 1 0 11.837 4.087l-4.5 4.499V3a1 1 0 1 0-2 0v8a1 1 0 0 0 1 1h8.003a1 1 0 1 0 0-2H8.752z"/></svg>';
    icons.redo =
      '<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 28 28"><path fill="currentColor" d="M7.134 4.655a6.26 6.26 0 0 1 8.028.648l6.27 6.196h-7.679a.75.75 0 0 0 0 1.5h9.5a.75.75 0 0 0 .75-.75V2.75a.75.75 0 0 0-1.5 0v7.7l-6.287-6.214a7.76 7.76 0 0 0-9.951-.804c-3.991 2.838-4.388 8.62-.82 11.975l11.04 10.39a.75.75 0 0 0 1.029-1.093L6.472 14.314c-2.877-2.707-2.558-7.37.662-9.66"/></svg>';

    const undoHandler = () => {
      quill.history.undo();
    };
    const redoHandler = () => {
      quill.history.redo();
    };
    const tableHandler = () => {
      insertTableToEditor(quill);
    };

    const quill = new Quill(quillRef?.current, {
      modules: {
        toolbar: {
          container: toolbarOptions,
          handlers: {
            undo: undoHandler,
            redo: redoHandler,
            table: tableHandler,
          },
        },
        history: {
          delay: 2000,
          maxStack: 500,
          userOnly: true,
        },
        magicUrl: true,
        "better-table": {
          operationMenu: {
            items: {
              unmergeCells: {
                text: "Another unmerge cells name",
              },
            },
          },
        },
        keyboard: {
          bindings: QuillBetterTable.keyboardBindings,
        },
      },
      bounds: document.body,
      theme: "snow",
      // formats: formats,
      
    });
    
  
    // Set initial content
    quill?.clipboard?.dangerouslyPasteHTML(editorContent);

    setQuillInstance(quill);

    // Track content changes
    quill.on('text-change', () => {
      const currentContent = quill?.root?.innerHTML;
      setIsContentChanged(currentContent !== initialContent);
    });

    return () => {
      quill.disable();
      quillRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (quillInstance) {
      quillInstance?.clipboard?.dangerouslyPasteHTML(editorContent);
      setInitialContent(editorContent); 
      setIsContentChanged(false); 
    }
  }, [editorContent, quillInstance]);

  const getContent = () => {
      if (quillInstance) {
          const htmlContent = quillInstance.root.innerHTML;
          setContent(htmlContent);
          handleSave(htmlContent);
        }
    };
    


  const handleDiscard = () => {
    confirm({ description: `Are you sure you want to discard unsaved changes?` })
      .then(() => {
        if (quillInstance) {
          quillInstance.clipboard.dangerouslyPasteHTML(initialContent);
          setIsContentChanged(false);
        }
      })
      .catch(() => {
      });
  };


  return (
    <div style={{ margin: "0px 20px" }}>
      {getEditVisible("file") && !isSystemDefault && (

      <Stack
        className="button-part"
        direction="row-reverse"
        spacing={2}
        margin={2}
      >
        <LoadingButton
          size="small"
          type="submit"
          variant="contained"
          onClick={getContent}
          // loading={isSubmitting}
        >
          Save
        </LoadingButton>

        {isContentChanged && (
          <Button
            className="cancel-button"
            color="error"
            variant="contained"
            size="small"
            onClick={handleDiscard}
          >
            Discard
          </Button>
        )}
      </Stack>
      )}
      
      <div ref={quillRef} style={{ height: "70vh" }} />
    </div>
  );
};

export default Editor;
