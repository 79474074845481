import * as Yup from "yup";

const assetSchema = Yup.object().shape({
  name: Yup.string()
  .trim("Name cannot contain only whitespace")
        .required("Field is required")
        .max(256, "Maximum 256 characters required")
        .nullable(),
});

export default assetSchema;
