import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "../../components/Scrollbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { encryption } from "../../utils/encodeString";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import CustomPagination from "../../components/Pagination";
import invertDirection from "../../utils/invertDirection";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import {
  setLimit,
  setCurrentPage,
  setOrderBy,
  setSortBy,
} from "../../redux/assetVM/assetVMSlice";
import { debounce } from "lodash";
import { getAssetVM, deleteAssetVM,getAssestVMCount } from "../../redux/assetVM/assetVMThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

import "./assetsItems.css";
import {
  Autocomplete,
  Badge,
  Box,
  Chip,
  Drawer,
  TextField,
} from "@mui/material";
import { LocalizationProvider } from "@mui/lab";
import { getGeneratedAssetsForDropdown } from "../../redux/assets/thunk";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

function VmMaster() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const generatedAssetsData = useSelector(
    (state) => state.assets.generatedAssetsForDropdown
  );
  const [filterdata, setFilterData] = useState({
    search: "",
    assetId: "",
    status: "",
  });
  useEffect(() => {
    getViewVisible("assets") &&
      dispatch(getGeneratedAssetsForDropdown({ isFromVM: true }));
  }, [dispatch]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
  	if (!getViewVisible("vmMaster")) {
      dispatch(
        getAssetVM({
          page: currentPage,
          limit: limit,
          sortBy: sortBy,
          orderBy: orderBy,
          ...filterdata,
        })
      );
  		navigate("/dashboard");
  	}
  }, []);

  // const loading = useSelector((state) => state.assetVM.loading);
  // const totalRecord = useSelector((state) => state.assetVM.totalRecords);
  // const totalPage = useSelector((state) => state.assetVM.totalPages);
  const [filterChips, setFilterChips] = useState([]);

  const data = useSelector((state) => state.assetVM.data);
  const {
    currentPage,
    status,
    limit,
    totalRecords,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
    totalVM
  } = useSelector((state) => state.assetVM);
  const [state, setState] = React.useState({
    right: false,
  });
  const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=localStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
  useEffect(() => {
    dispatch(
      getAssetVM({
        page: currentPage,
        limit: limit,
        sortBy: sortBy,
        orderBy: orderBy,
        ...filterdata,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        getAssetVM({
          page: currentPage,
          limit: limit,
          sortBy,
          orderBy,
          ...filterdata,
        })
      );
    }
  }, [dispatch, limit, status, currentPage, sortBy, orderBy]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage+1 }));
  };

  const handleChangeRowsPerPage = (event) => {
    // dispatch(setCurrentPage({ page: 1 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleDelete = async (id, name) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${name}?`,
      });
      await dispatch(deleteAssetVM(id)).unwrap();
      dispatch(
        getAssetVM({
          page: 1,
          limit: limit,
          sortBy,
          orderBy,
          ...filterdata,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };


  const statusData = ["Running", "Closed", "Removed"];
  useEffect(() => {
    const fetchData = async () => {
      try {
        let newFilterData = { ...filterdata };

        await dispatch(
          getAssetVM({
            page: 1,
            limit: limit,
            sortBy,
            orderBy,
            ...newFilterData,
          })
        ).unwrap(); // Make sure to handle the result or error as needed

        setFilterChips([]);

        let tempArr = [];
        Object.entries(newFilterData).forEach(([key, value]) => {
          if (value !== "") {
            if (key === "assetId") {
              let obj = generatedAssetsData.find((x) => value === x.id);
              tempArr = [
                ...tempArr,
                {
                  key: key,
                  name: 'Asset',
                  value: `${obj.assetUniqueId}-${obj.name}-${obj.generatedAssetItems}`,
                },
              ];
            } else {
              tempArr = [
                ...tempArr,
                {
                  key: key,
                  name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, '$1 $2'),
                  value: value,
                },
              ];
            }
          }
        });

        setFilterChips(tempArr);
        setState({ right: false });

      } catch (error) {
        // Handle any errors that occurred during the async operation
        console.error('Failed to fetch asset data:', error);
      }
    };

    fetchData();
  }, [filterdata]);

  const handleSearch = async (e) => {
    
    setFilterData({
      ...filterdata,
      search: e.target.value,
    });
    // handleSubmit(e.target.value ,true);
  };
  const debounceWithSearch = debounce(handleSearch, 300);
  const handleReset = async () => {
    setFilterData({
      search: "",
      assetId: "",
      status: "",
    });
    await dispatch(
      getAssetVM({
        page: currentPage,
        limit: limit,
        sortBy,
        orderBy,
        search: "",
        assetId: "",
        status: "",
      })
    );
    setFilterChips([]);
    setState({ right: false });
  };
  const onDeleteChip = async (value, length) => {
    // console.log(value, "pdpdp", filterdata);
    setFilterChips([...filterChips.filter((val) => val.name !== value.name)]);
    setFilterData({ ...filterdata, [value.key]: "" });
    if (value?.key === "search") {
      inputRef.current.value = "";
    }
    await dispatch(
      getAssetVM({
        page: currentPage,
        limit: limit,
        sortBy,
        orderBy,
        ...filterdata,
        [value.key]: "",
      })
    );
    // let filters = { ...values, [value["type"]]: "" };

    // if (length === 1) {
    // 	// getInterviewList({ ...queryParams, orderBy, sortBy, ...params }),
    //   dispatch(getInterviewList({ orderBy, sortBy, ...params }));
    // } else {
    //   dispatch(getInterviewList({ ...filters, orderBy, sortBy, ...params }));
    // }
    // dispatch(setPage({ page: 0 }));
    // setFieldValue(value.type, "");
    // if(value.type==="search"){
    //   setSearchValue("");
    //   inputRef.current.value = "";
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAssestVMCount()).unwrap(); // Make sure to handle the result or error as needed
      } catch (error) {
        // Handle any errors that occurred during the async operation
        console.error('Failed to fetch asset VM count:', error);
      }
    };

    fetchData();
  }, [dispatch]);
  return (
    <Container maxWidth="xl" className="item-component VM-main-wrapper">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          VM ({totalVM ? totalVM : "0"})
          {/* <Typography variant="body2">
            Total Records : {totalVM ? totalVM : "0"}
          </Typography> */}
        </Typography>
      
          <Stack direction="row">
            <Stack
              direction="row"
              alignitems="center"
              justifyContent="space-between"
            >
              {/* {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                  <span
                    className="filter-icon-part"
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <IconButton alignitems="right">
                      <Badge
                        color="error"
                        variant="dot"
                        invisible={filterChips.length !== 0 ? false : true}
                      >
                        <FilterAltIcon />
                      </Badge>
                    </IconButton>
                  </span>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    style={{ zIndex: "1300" }}
                  >
                    <Box
                      pt={4}
                      px={3}
                      sx={{
                        width:
                          anchor === "top" || anchor === "bottom" ? "auto" : 400,
                      }}
                      role="presentation"
                    >
                      <div className="content-filter-part">
                        <Stack
                          direction="row"
                          alignitems="center"
                          justifyContent="space-between"
                          mb={3}
                        >
                          <Typography variant="h6" style={{ marginTop: "5px" }}>
                            Vm filter
                          </Typography>
                          <span>
                            <IconButton onClick={toggleDrawer(anchor, false)}>
                              <CloseIcon />
                            </IconButton>
                          </span>
                        </Stack>

                        <Grid container spacing={3} mb={3}>
                         
                          <Grid item xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              disabled={!getViewVisible("assets")}
                              options={generatedAssetsData || []}
                              getOptionLabel={
                                (option) => {
                                  if (
                                    option?.assetEmployee &&
                                    option?.assetEmployee?.employee != null
                                  ) {
                                    return (
                                      option?.assetUniqueId +
                                      " - " +
                                      option?.assetEmployee?.employee.firstName +
                                      " " +
                                      option?.assetEmployee?.employee.lastName +
                                      " - " +
                                      option.name +
                                      " - " +
                                      option.generatedAssetItems
                                    );
                                  } else if (
                                    option.assetEmployee &&
                                    option.assetEmployee.employee == null
                                  ) {
                                    return (
                                      option?.assetUniqueId +
                                      " - " +
                                      option?.name +
                                      " - " +
                                      option?.generatedAssetItems
                                    );
                                  } else {
                                    return "";
                                  }
                                }
                              }
                              renderOption={(props, option) => (
                                <li {...props} value={option.id} key={option.id}>
                                  {option.assetEmployee &&
                                  option.assetEmployee.employee != null
                                    ? option?.assetUniqueId +
                                      " - " +
                                      option?.assetEmployee?.employee.firstName +
                                      " " +
                                      option?.assetEmployee?.employee.lastName +
                                      " - " +
                                      option.name +
                                      " - " +
                                      option?.generatedAssetItems
                                    : option?.assetUniqueId +
                                        " - " +
                                        option?.name +
                                        " - " +
                                        option?.generatedAssetItems || ""}
                                </li>
                              )}
                              onChange={(event, newValue) => {
                                setFilterData({
                                  ...filterdata,
                                  assetId: newValue?.id || "",
                                });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoComplete="off"
                                  label="Host PC"
                                />
                              )}
                              value={
                                generatedAssetsData?.find(
                                  (a) => a.id == filterdata.assetId
                                ) || ""
                              }
                              error={Boolean(true)}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              size="small"
                              name="Status"
                              onChange={(event, newValue) => {
                                setFilterData({
                                  ...filterdata,
                                  status: newValue || "",
                                });
                              }}
                              value={filterdata.status}
                              options={statusData || []}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Status"
                                  autoComplete="off"
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Stack direction="row" justifyContent="flex-end" mb={3}>
                          <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            type="submit"
                          >
                            Search
                          </Button>
                          <Button
                            variant="contained"
                            type="reset"
                            onClick={handleReset}
                            startIcon={<CachedIcon />}
                            sx={{ marginLeft: "10px" }}
                          >
                            Reset
                          </Button>
                        </Stack>
                      </div>
                    </Box>
                  </Drawer>
                </React.Fragment>
              ))} */}
            </Stack>
            {getAddVisible("vmMaster") && (
              <Button
                variant="contained"
                component={RouterLink}
                to="/vm/add"
                // startIcon={<AddIcon />}
              >
                <img src="/assets/images/add-circle.svg" style={{marginRight: "8px"}}/>
                Add VM
              </Button>
            )}
          </Stack>
        </div>
      </Stack>


      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div className="employee-classification-right" style={{paddingTop:"20px"}}>
                <TextField
                  className="employee-search-detail"
                  label="Search By Server Name/IP Address"
                  name="search"
                  variant="outlined"
                  size="small"
                  inputRef={inputRef}
                  onChange={debounceWithSearch}
                />
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    disabled={!getViewVisible("assets")}
                    options={generatedAssetsData || []}
                    getOptionLabel={
                      (option) => {
                        if (
                          option?.assetEmployee &&
                          option?.assetEmployee?.employee != null
                        ) {
                          return (
                            option?.assetUniqueId +
                            " - " +
                            option?.assetEmployee?.employee.firstName +
                            " " +
                            option?.assetEmployee?.employee.lastName +
                            " - " +
                            option.name +
                            " - " +
                            option.generatedAssetItems
                          );
                        } else if (
                          option.assetEmployee &&
                          option.assetEmployee.employee == null
                        ) {
                          return (
                            option?.assetUniqueId +
                            " - " +
                            option?.name +
                            " - " +
                            option?.generatedAssetItems
                          );
                        } else {
                          return "";
                        }
                      }
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {option.assetEmployee &&
                        option.assetEmployee.employee != null
                          ? option?.assetUniqueId +
                            " - " +
                            option?.assetEmployee?.employee.firstName +
                            " " +
                            option?.assetEmployee?.employee.lastName +
                            " - " +
                            option.name +
                            " - " +
                            option?.generatedAssetItems
                          : option?.assetUniqueId +
                              " - " +
                              option?.name +
                              " - " +
                              option?.generatedAssetItems || ""}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      // setFieldValue('assetName', newValue ? newValue?.assetUniqueId + "-" + newValue?.name + " - " + newValue.generatedAssetItems :  "");
                      setFilterData({
                        ...filterdata,
                        assetId: newValue?.id || "",
                      });
                      
                      // handleSubmit();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        // error={Boolean(touched?.assetName && errors?.assetName)}
                        // helperText={touched?.assetName && errors?.assetName}
                        label="Host PC"
                      />
                    )}
                    value={
                      generatedAssetsData?.find(
                        (a) => a.id == filterdata.assetId
                      ) || ""
                    }
                    error={Boolean(true)}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    size="small"
                    name="Status"
                    onChange={(event, newValue) => {
                      setFilterData({
                        ...filterdata,
                        status: newValue || "",
                      });
                      // handleSubmit();
                      // setFieldValue(`status`, newValue || "");
                    }}
                    value={filterdata.status || ""}
                    options={statusData || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Status"
                        autoComplete="off"
                        // error={Boolean(touched?.status && errors?.status)}
                        // helperText={touched?.status && errors?.status}
                      />
                    )}
                  />
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>



      <Stack
        className="employee-table-Stack search-filter"
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="employee-table-Stack-label search-filter-detail"
              label={`${chips.name}: ${chips.value}`}
              color="primary"
              size="small"
              key={chips}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>

      {filterChips?.length > 0 ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Scrollbar>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 800 }}
                className="radius-remove"
                style={{ borderRadius: "0" }}
              >
                <Table className="grid-table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "serverName"}
                          direction={sortBy === "serverName" ? orderBy : "asc"}
                          onClick={() => handleSorting("serverName")}
                        >
                          Server Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "assetName"}
                          direction={sortBy === "assetName" ? orderBy : "asc"}
                          onClick={() => handleSorting("assetName")}
                        >
                          Host PC Name
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">Primary User</TableCell>

                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "ipAddress"}
                          direction={sortBy === "ipAddress" ? orderBy : "asc"}
                          onClick={() => handleSorting("ipAddress")}
                        >
                          IP Address
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "status"}
                          direction={sortBy === "status" ? orderBy : "asc"}
                          onClick={() => handleSorting("status")}
                        >
                          Status
                        </TableSortLabel>
                      </TableCell>

                      <TableCell align="center" style={{ width: "100px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {loading ? (
                    <TableCell align="center" width="100%" colSpan={10}>
                      <Loader />
                    </TableCell>
                  ) : (
                    <TableBody>
                      {data?.length > 0 ? (
                        data.map((vm, idx) => {
                          return (
                            <TableRow key={vm.id}>
                              <TableCell align="center">
                                {vm.serverName || ""}
                              </TableCell>
                              <TableCell align="left">
                                {vm.assetName || ""}
                              </TableCell>

                              <TableCell align="left">
                                {vm?.users?.map((e) => (
                                  <tr>
                                    <td>{e?.employee ? `${e?.employee?.firstName} ${e?.employee?.lastName}` : ""}</td>
                                  </tr>
                                ))}
                              </TableCell>
                              <TableCell align="left">
                                {vm.ipAddress || ""}
                              </TableCell>
                              <TableCell align="left">
                                {vm.status || ""}
                              </TableCell>
                              <TableCell className="td-action-button" align="left">
                                <Stack className="action-button" direction="row">
                                  {getEditVisible("vmMaster") && (
                                    <IconButton
                                      
                                      // component={RouterLink}
                                      // to={`/vm/edit/${encryption(vm.id)}`}
                                      onClick={(e) => window.open(`/vm/edit/${encryption(vm.id)}`,"_blank") }
                                    >
                                      {/* <EditIcon color="primary" /> */}
                                      <img src="assets/images/edit.svg" title="Edit" />
                                    </IconButton>
                                  )}
                                  <IconButton
                                    
                                    // component={RouterLink}
                                    // to={`/vm/view/${encryption(vm.id)}`}
                                    onClick={(e) => window.open(`/vm/view/${encryption(vm.id)}`,"_blank") }
                                  >
                                    {/* <VisibilityIcon /> */}
                                    <img src="assets/images/vieweye-icon.svg" title="View" />
                                  </IconButton>
                                  {getDeleteVisible("vmMaster") && (
                                    <span>
                                      <IconButton
                                        onClick={() =>
                                          handleDelete(vm.id, vm.serverName)
                                        }
                                      >
                                        {/* <DeleteIcon color="error" /> */}
                                        <img src="assets/images/trash-gray.svg" title="trash" />
                                      </IconButton>
                                    </span>
                                  )}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell className="No-Record-text" colSpan={12} align="left">
                            No Record(s) Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
                <CustomPagination
                  totalPage={totalPages}
                  totalCount={totalRecords}
                  limit={limit}
                  handleChangePage={handleChangePage}
                  // page={currentPage}
                  page={currentPage > 0 ? currentPage - 1 : 0}
                  rowsPerPageOptions={[10, 20, 40]}
                  handleRowsPerPageChange={handleChangeRowsPerPage}
                  filter={isSearchQuery ? true : false}
                />
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: VmMaster,
  path: "/vm",
  public: false,
  layout: DashboardLayout,
  group: "Asset Management",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
