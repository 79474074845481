import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import DashboardLayout from "../../layouts/dashboard";
import Scrollbar from "../../components/Scrollbar";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import TablePagination from "@mui/material/TablePagination";
import Autocomplete from "@mui/material/Autocomplete";
import invertDirection from "../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";
import AddIcon from "@mui/icons-material/Add";
import {
  getVendor,
  deleteVendorById,
  updateVendorById,
  insertVendor,
  getVendercount
} from "../../redux/vendor/vendorThunk";
import {
  setLimit,
  setCurrentPage,
  setSortBy,
  setOrderBy,
} from "../../redux/vendor/vendorSlice";
import vendorSchema from "../../validations/vendorSchema";
import {
  getAreaData,
  getCityData,
  getPostalCode,
  getStateData,
} from "../../utils/getLocationData";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import Chip from "@mui/material/Chip";
import { debounce } from "lodash";
import CustomPagination from "../../components/Pagination";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../utils/encodeString";
import LocalStorage from "../../service/localStorage";
import { Link as RouterLink, useNavigate } from "react-router-dom";
function VendorMaster() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data,
    currentPage,
    status,
    limit,
    totalRecords,
    totalPages,
    sortBy,
    orderBy,
    totalVenderCount
  } = useSelector((state) => state.vendor);
  const confirm = useConfirm();
  const [id, setId] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setArea] = useState([]);
  const countryData = ["India"];
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("vendors")) {
      // dispatch(getVendor({ page: currentPage + 1, limit: limit, orderBy, sortBy }));
      navigate("/dashboard");
    }
  }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

 

  const handleDelete = async (id) => {
    try {
      await confirm({
        description: "Are you sure want to delete this vendor?",
      });
      if (id) {
        await dispatch(deleteVendorById(id)).unwrap();
      }
      dispatch(getVendor({ page: currentPage + 1, limit: limit }));
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      streetLine1: "",
      streetLine2: "",
      area: "",
      city: "Ahmedabad",
      country: "India",
      state: "Gujarat",
      postalCode: "",
    },
    validationSchema: vendorSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (id) {
          await dispatch(updateVendorById({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(insertVendor(values)).unwrap();
        }
        resetForm();
        // dispatch(setStatus({ status: 'idle' }));
        dispatch(getVendor({ page: currentPage + 1, limit: limit }));
        return setId(null);
      } catch (error) {}
    },
  });

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    // dispatch(setLimit({ limit: parseInt(event.target.value, 20) }));
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleState = async (event, newValue) => {
    const state = newValue;
    setFieldValue("city", "");
    setFieldValue("area", "");
    setFieldValue("postalCode", "");
    setFieldValue("state", state);
    setCities(await getCityData(state));
  };

  const handleArea = async (event, newValue) => {
    const city = newValue;
    setFieldValue("area", "");
    setFieldValue("postalCode", "");
    setFieldValue("city", city);
    setArea(await getAreaData(city));
  };

  const handleZipCode = async (event, newValue) => {
    try {
      const area = newValue;
      const postalCode = await getPostalCode({ city: values.city, area });
      setFieldValue("area", area);
      setFieldValue("postalCode", postalCode);
    } catch (error) {}
  };

  const handleAreaWhileEdit = async (city) => {
    const areas = await getAreaData(city);
    setArea(areas);
  };

  const handleCityWhileEdit = async (state) => {
    const cities = await getCityData(state);
    setCities(cities);
  };

  const {
    handleSubmit,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
  } = formik;

  const handleUpdate = async (data) => {
    setValues({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      contactNumber: data.contactNumber,
      streetLine1: data.streetLine1,
      streetLine2: data.streetLine2,
      area: data.area,
      city: data.city,
      state: data.state,
      country: data.country,
      postalCode: data.postalCode,
    });
    // dispatch(getCity(data.state));
    // dispatch(getArea(data.city));
    await handleCityWhileEdit(data.state);
    await handleAreaWhileEdit(data.city);
    setId(data.id);
  };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    // setFieldValue("search", e?.target?.value || "");
    // handleSubmit();
    setSearchValue(e?.target?.value);
    if (e?.target?.value != "") {
      setIsSearching(true);
      await dispatch(
        getVendor({
          page: currentPage + 1,
          limit: limit,
          orderBy,
          sortBy,
          search: e?.target?.value,
        })
      );
    } else {
      setIsSearching(false);
      await dispatch(
        getVendor({
          page: currentPage + 1,
          limit: limit,
          orderBy,
          sortBy,
          search: "",
        })
      );
    }
    setSearchValue(e?.target?.value);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const onDeleteChip = async () => {
    setSearchValue("");
    setIsSearching(false);
    inputRef.current.value = "";
    await dispatch(
      getVendor({
        page: currentPage + 1,
        limit: limit,
        orderBy,
        sortBy,
        search: "",
      })
    );
  };
  useEffect(() => {
    getStateData()
      .then((states) => {
        setStates(states);
      })
      .catch((err) => {
        setStates([]);
      });
  }, [dispatch]);

  useEffect(() => {
    if (!id) {
      getCityData("Gujarat")
        .then((cities) => {
          setCities(cities);
        })
        .catch((err) => {
          setCities([]);
        });

      getAreaData("Ahmedabad")
        .then((areas) => {
          setArea(areas);
        })
        .catch((err) => {
          setArea([]);
        });
    }
  }, [dispatch]);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      getVendor({ page: currentPage + 1, limit: limit, orderBy, sortBy,search : searchValue })
    );
    // }
  }, [dispatch, limit, status, currentPage, sortBy, orderBy]);


 useEffect(() =>{
  
  dispatch(getVendercount())

 },[])

  return (
    <Container maxWidth="xl" className="item-component Vendor-item-wrapper">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom mb={3}>
          Vendors ({totalVenderCount})
          {/* <Typography variant="body2">
            Total Records : {totalVenderCount}
          </Typography> */}
        </Typography>

        {getAddVisible("vendors") && (
          <Button
            variant="contained"
            component={RouterLink}
            to="/vendor/add"
            // startIcon={<AddIcon />}
          >
            <img src="/assets/images/add-circle.svg" style={{marginRight: "8px"}} />
            Add Vendor
          </Button>
        )}
        </div>
      </Stack>

      <Card className="custom-grid">
        <Container style={{ maxWidth: "100%" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container xs={12} pt={2} pb={2}>
              <Grid className="employee-search-detail" item xs={12}>
                <TextField
                  fullWidth
                  label="Search by Vendor Name"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearching ? (
          <Chip
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={() => onDeleteChip()}
          />
        ) : (
          ""
        )}
      </Stack>
      {isSearching ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        {/* <TablePagination
					rowsPerPageOptions={[10, 20,40]}
					component="div"
					count={totalRecords}
					rowsPerPage={limit||40}
					page={currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/> */}
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Table aria-label="simple table" className="grid-table">
              <TableHead>
                <TableRow>
                  <TableCell width="20%">
                    <TableSortLabel
                      active={sortBy === "firstName"}
                      direction={sortBy === "firstName" ? orderBy : "asc"}
                      onClick={() => handleSorting("firstName")}
                    >
                      Vendor
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="48%" align="left">
                    Address
                  </TableCell>
                  <TableCell width="20%" align="left" className="action-field">
                    Contact Number
                  </TableCell>
                  {(getEditVisible("vendors") ||
                    getDeleteVisible("vendors")) && (
                    <TableCell
                      width="11%"
                      align="left"
                      className="action-field"
                    >
                      Action
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length == 0 ? (
                  <TableRow>
                    <TableCell className="No-Record-text">No Record(s) Found</TableCell>
                  </TableRow>
                ) : (
                  data.map((vendor, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {vendor.firstName} {vendor.lastName}
                      </TableCell>
                      <TableCell>
                        {vendor.streetLine1} {vendor.streetLine2} {vendor.area}{" "}
                        {vendor.city} {vendor.state} {vendor.country} -{" "}
                        {vendor.postalCode}
                      </TableCell>
                      <TableCell>{vendor.contactNumber}</TableCell>
                      {(getEditVisible("vendors") ||
                        getDeleteVisible("vendors")) && (
                        <TableCell className="td-action-button">
                          <div className="action-button">
                            {getEditVisible("vendors") && (
                              <IconButton
                                to={`/vendor/add/${encryption(vendor.id)}`}
                                component={RouterLink}
                                // onClick={() => handleUpdate(vendor)}
                              >
                                {/* <EditIcon color="primary" /> */}
                                <img src="assets/images/edit.svg" title="Edit" />
                              </IconButton>
                            )}
                            {getDeleteVisible("vendors") && (
                              <IconButton onClick={() => handleDelete(vendor.id)}>
                                {/* <DeleteIcon color="error" /> */}
                                <img src="assets/images/trash-gray.svg" title="trash" />
                              </IconButton>
                            )}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <CustomPagination
              rowsPerPageOptions={[10, 20, 40]}
              totalCount={totalRecords}
              totalPage={totalPages}
              limit={limit}
              page={currentPage}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              handleChangePage={handleChangePage}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: VendorMaster,
  path: "/vendor",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
