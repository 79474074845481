//
import Login from "./Login";
// import Register from './Register';
import DashboardApp from "./DashboardApp";
import Master from "./Organization/Master";
import Employee from "./Organization/Employee";
import ExEmployee from "./Organization/EmployeeComponents/ExEmployee/Index";
import JoiningEmployee from "./Organization/EmployeeComponents/JoiningEmployee/Index";
import Holiday from "./Organization/Holiday";
import Specialday from "./Organization/Specialday";
import Candidate from "./Candidate/Candidate";
import JobDescription from "./Candidate/JobDescription";
import Company from "./Candidate/Company";
import College from "./TPA/College";
import Interns from "./TPA/Interns";
import Project from "./TPA/Project";
import Drives from "./TPA/Drives";
import AddDrives from "./TPA/DrivesComponent/AddDrives";
import ViewDrive from "./TPA/DrivesComponent/ViewDrive";
import UserList from "./Users/UserList";
import UserRole from "./Users/UserRole";
import UserPermissions from "./Users/UserPermissions";
import DocumentList from "./Documents/DocumentList";
import Content from "./Documents/Content";
import PageMaster from "./CMS/PageMaster";
import CmsVariable from "./CMS/CmsVariable";
import EmailTemplate from "./CMS/EmailTemplate";
import History from "./Documents/History";
import AddHoliday from "./Organization/HolidayComponents/AddHoliday";
import AddEmployee from "./Organization/EmployeeComponents/AddEmployee";
import ViewEmployee from "./Organization/EmployeeComponents/ViewEmployee";
// import AddUser from "./Users/UserListComponents/AddUser";
import Items from "./AssetsManagment/Items";
import Isp from "./AssetsManagment/Isp";
import AddIsp from "./AssetsManagment/IpsComponents/AddIsp";
import ViewIsp from "./AssetsManagment/IpsComponents/ViewIsp";
import AddItem from "./AssetsManagment/itemComponents/AddItem";
import Vendor from "./AssetsManagment/VendorMaster";
import Configuration from "./AssetsManagment/Configuration";
import AddPagemaster from "./CMS/PageMaster/AddPageMater";
import AddCmsVariable from "./CMS/CmsVariable/AddCmsVariable";
import AddJobDescription from "./Candidate/JobDescriptionComponent/AddJobDescription";
import ManageJobStatus from "./Candidate/JobDescriptionComponent/ManageJobStatus";
import AddCompany from "./Candidate/CompanyComponent/AddCompany";
import Department from "./Organization/Department";
import AddressMaster from "./Organization/AddressMaster/addressMaster";
import AddAddressMaster from "./Organization/AddressMaster/AddAddressMaster";

import Manufacturer from "./AssetsManagment/Manufacturer";
import AddCandidateForm from "./Candidate/CandidateComponent/AddCandidateForm";
import ScheduleInterview from "./Candidate/CandidateComponent/ScheduleInterview";
import ViewCandidate from "./Candidate/CandidateComponent/ViewCandidate";
import AddCollege from "./TPA/CollegeComponents/AddCollege";
import AddProject from "./TPA/ProjectComponents/AddProject";
import Policy from "./Organization/Policy";
import AddPolicy from "./Organization/Policy/AddPolicyForm";
import JobVacancy from "./Candidate/JobVacancy";
import AddJobVacancy from "./Candidate/JobVacancy/AddJobVacancy";
import ManageProject from "./Organization/ManageProject";
import AddManageProject from "./Organization/ManageProjectComponent/AddManageProject";
import Assets from "./AssetsManagment/Assets";
import AddAsset from "./AssetsManagment/AssetsComponent/AddAsset";
import ViewGeneratedAssets from "./AssetsManagment/AssetsComponent/ViewGeneratedAssets";
import AssetLog from "./AssetsManagment/AssetsComponent/AssetLog";
import ItemGroup from "./AssetsManagment/ItemGroup";
import ViewSingleGeneratedAsset from "./AssetsManagment/AssetsComponent/viewAssetComponents/ViewSingleGeneratedAsset";
import AddStudents from "./TPA/StudentComponents/AddStudent";
import ChangeRequest from "./Organization/ChangeRequest";
import InterviewList from "./Candidate/InterviewList";
import AssetMaster from "./AssetsManagment/AssetMaster";
import ItemAssetMapping from "./AssetsManagment/ItemAssetMapping";
import ViewChangeRequest from "./Organization/ChangeRequestComponents/ViewRequest";
import ViewIntern from "./TPA/InternComponents/ViewInternComponent/ViewIntern";
import InternEvaluation from "./TPA/InternComponents/InternEvaluation";
import LicenceMaster from "./AssetsManagment/LicenceMaster";
import AddLicence from "./AssetsManagment/AddLicenceMaster/AddLicence";
import ViewLicence from "./AssetsManagment/AddLicenceMaster/ViewLicence";
import VmMaster from "./AssetsManagment/VmMaster";
import AddVM from "./AssetsManagment/VmMaster/AddVM";
import ViewVM from "./AssetsManagment/VmMaster/ViewVM";
import EmailTemplateDynamic from "./Email/EmailTemplateDynamic";
import AddEmailTemplateDynamic from "./Email/EmailComponents/AddEmailTemplateDynamic";
import ViewEmailTemplateDynamic from "./Email/EmailComponents/ViewEmailTemplateDynamic";
import SalarySlip from "./SalarySlip/SalarySlip"
import EmailInbox from './Inbox/EmailInbox'
import ViewEmail from './Inbox/ViewEmail'
import SystemConfig from "./config/SystemConfig";
import WorkFlow from "./WorkFlow/WorkFlow";
import Questions from "./Evaluation/Questions";
import AddQuestion from "./Evaluation/QuestionsComponents/AddQuestion";
import LocateAsset from "./AssetsManagment/AssetsComponent/LocateAsset";
import OnboardedCandidates from "./OnboardedCandidates/OnboardedCandidates";
import ViewOnboardedCandidate from "./OnboardedCandidates/ViewOnboardedCandidate";
import AddHelpDeskCategories from "./Helpdesk/HelpDeskCategoriesComponents/AddHelpDeskCategories";
import AddHelpDeskTickets from "./Helpdesk/HelpDeskTicketsComponents/AddHelpDeskTickets";
import HelpDeskCategories from "./Helpdesk/HelpDeskCategories"
import HelpDeskTickets from "./Helpdesk/HelpDeskTickets";
import ViewHelpDeskTicket from "./Helpdesk/HelpDeskTicketsComponents/ViewHelpDeskTicket";
import Poll from "./Poll/Poll";
import AddPoll from "./Poll/PollComponents/AddPoll";
import ViewPoll from "./Poll/PollComponents/ViewPoll";
import DashboardPoll from "./Poll/DashboardPoll";
import Evaluations from "./Evaluation/Evaluations";
import AddEvaluation from "./Evaluation/EvaluationsComponents/AddEvaluation";
import ViewEvaluation from "./Evaluation/EvaluationsComponents/ViewEvaluation";
import SeatAllocation from "./Organization/SeatAllocation";
import FAQs from "./Organization/FAQs";
import AddFAQs from "./Organization/FAQs/AddFAQs";
import ReportsDashboard from "./Reports/ReportsDashboard"
import EmployeeExperienceReports from "./Reports/ReportsComponents/EmployeeExperienceReports"
import AssetsReports from "./Reports/ReportsComponents/AssetsReports"
import AddVendor from "./AssetsManagment/VendorMater/AddVendor"
import ViewStudent from "./TPA/StudentComponents/ViewStudent";
import JoiningRelievingReports from './Reports/ReportsComponents/JoiningRelievingReports'
import Announcements from './Organization/Announcements'
import AddAnnouncement from './Organization/AnnouncementsComponents/AddAnnouncement';
import AddEmployeeEvaluation from "./Organization/EmployeeEvaluationComponent/AddEmployeeEvaluation"
import EmployeeEvaluation from "./Organization/EmployeeEvaluation"
import ViewEmployeeEvalution from "./Organization/EmployeeEvaluationComponent/ViewEmployeeEvalution"
import EvalutionFromCybercom from "./Evaluation/EmployeeEvaluations"
import ExitQuestions from "./ExitQuestions/ExitQuestions"
import AddExitQuestion from "./ExitQuestions/ExitQuestionsComponents/AddExitQuestion"
import ParkingAllocation from "./Organization/ParkingAllocation";
import Notebook from "./Notebook/Notebook";
import ViewFolder from "./Notebook/NotebookComponents/ViewFolder";
import Links from "./Notebook/Links";
const config = [
	Login,
	DashboardApp,
	Master,
	EmailInbox,
	ViewEmail,
	Employee,
	ExEmployee,
	JoiningEmployee,
	Holiday,
	Specialday,
	Candidate,
	JobDescription,
	Company,
	College,
	Interns,
	Project,
	Drives,
	...AddDrives,
	ViewDrive,
	UserList,
	UserRole,
	DocumentList,
	Content,
	AddressMaster,
	...AddAddressMaster,
	...AddVendor,
	PageMaster,
	CmsVariable,
	EmailTemplate,
	History,
	AddHoliday,
	...AddEmployee,
	Items,
	Isp,
	...AddIsp,
	ViewIsp,
	...AddPagemaster,
	...AddCmsVariable,
	...AddJobDescription,
	...ManageJobStatus,
	...AddCompany,
	Department,
	...AddCandidateForm,
	...AddCollege,
	ViewCandidate,
	...AddProject,
	Vendor,
	Configuration,
	// ...AddUser,
	UserPermissions,
	Policy,
	...AddPolicy,
	...ParkingAllocation,
	JobVacancy,
	...AddJobVacancy,
	ManageProject,
	...AddManageProject,
	ScheduleInterview,
	ViewEmployee,
	...AddItem,
	Assets,
	...AddAsset,
	LicenceMaster,
	...AddLicence,
	ViewLicence,
	ViewGeneratedAssets,
	AssetLog,
	Manufacturer,
	ItemGroup,
	ViewSingleGeneratedAsset,
	...AddStudents,
	InterviewList,
	AssetMaster,
	ItemAssetMapping,
	ChangeRequest,
	ViewChangeRequest,
	ViewIntern,
	InternEvaluation,
	VmMaster,
	...AddVM,
	ViewVM,
	EmailTemplateDynamic,,
	...AddEmailTemplateDynamic,
	ViewEmailTemplateDynamic,
	SalarySlip,
	SystemConfig,
	WorkFlow,
	Questions,
	...AddQuestion,
	Evaluations,
	...AddEvaluation,
	ViewEvaluation,
	...LocateAsset,
	OnboardedCandidates,
	ViewOnboardedCandidate,
	HelpDeskCategories,
	...AddHelpDeskCategories,
	HelpDeskTickets,
	...AddHelpDeskTickets,
	ViewHelpDeskTicket,
	Poll,
	...AddPoll,
	ViewPoll,
	DashboardPoll,
	...SeatAllocation,
	FAQs,
	...AddFAQs,
	ReportsDashboard,
	EmployeeExperienceReports,
	AssetsReports,
	JoiningRelievingReports,
	ViewStudent,
	Announcements,
	...AddAnnouncement,
	...AddEmployeeEvaluation,
	EmployeeEvaluation,
	EvalutionFromCybercom,
	ViewEmployeeEvalution,
	ExitQuestions,
	...AddExitQuestion,
	Notebook,
	ViewFolder,
	Links

];

export default config;
