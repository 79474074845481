import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { CKEditor, useCKEditor } from "ckeditor4-react";
import { Link as RouterLink } from "react-router-dom";

import Loader from "../../../components/Loader";
import DashboardLayout from "../../../layouts/dashboard";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import parse from "html-react-parser";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
// import TableContainer from "@mui/material/TableContainer";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { getDepartment } from "../../../redux/department/departmentThunk";
import {
  getById,
  updateById,
  insert,
} from "../../../redux/jobDescription/jobDesThunk";
import { setStatus } from "../../../redux/jobDescription/jobDesSlice";

import jobDescreptionSchema from "../../../validations/jobDescreptionSchema";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { decryption, encryption } from "../../../utils/encodeString";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { getAllMemberDropdown } from "../../../redux/employee/employeeThunk";
import {
  Avatar,
  Divider,
  Radio,
  RadioGroup,
  TableContainer,
} from "@mui/material";
import { getDesignation } from "../../../redux/designation/designationThunk";
import ClearIcon from "@mui/icons-material/Clear";
import { BASE_URL } from "../../../constants";
// import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { element } from "prop-types";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import { getViewVisible } from "../../../utils/userPermission";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const styleThird = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

function AddJobDescription() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigation = useNavigate();
  const isAddMode = !params.id;
  const { state } = useLocation();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [openSecondModal, setOpenSecondModel] = useState(false);
  const handleOpenSecondModal = () => setOpenSecondModel(true);
  const handleCloseSecondModal = () => setOpenSecondModel(false);
  const [openThirdModal, setOpenThirdModel] = useState(false);
  const handleOpenThirdModal = () => setOpenThirdModel(true);
  const handleCloseThirdModal = () => setOpenThirdModel(false);
  const [jobStatus, setJobStatus] = useState(false);
  const [hrError, setHrError] = useState({ flag: false, title: "" });
  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [selectedHr, setSelectedHr] = useState([]);
  const [seniorityLevel, setSeniorityLevel] = useState([
    { id: "Intern", title: "Intern" },
    { id: "Entry Level", title: "Entry Level" },
    { id: "Associate", title: "Associate" },
    { id: "Mid-Senior Level", title: "Mid-Senior Level" },
    { id: "Senior Level", title: "Senior Level" },
  ]);
  const [priority, setPriority] = useState([
    { id: "High", title: "High" },
    { id: "Low", title: "Low" },
    { id: "Medium", title: "Medium" },
  ]);
  const [employeementType, setEmployeementType] = useState([
    { id: "Full Time", title: "Full Time" },
    { id: "Part Time", title: "Part Time" },
    { id: "Freelancer", title: "Freelancer" },
  ]);
  const [salaryType, setSalaryType] = useState([
    { id: "monthly", title: "Monthly" },
    { id: "annual", title: "Annual" },
  ]);
  // const seniorityLevel = [
  //   { id: "Intern", title: "Intern" },
  //   { id: "Entry Level", title: "Entry Level" },
  //   { id: "Associate", title: "Associate" },
  //   { id: "Mid-Senior Level", title: "Mid-Senior Level" },
  //   { id: "Senior Level", title: "Senior Level" },
  // ];

  // const priority = [
  //   { id: "High", title: "High" },
  //   { id: "Low", title: "Low" },
  //   { id: "Medium", title: "Medium" },
  // ];
  // const employeementType = [
  //   { id: "Full Time", title: "Full Time" },
  //   { id: "Part Time", title: "Part Time" },
  //   { id: "Freelancer", title: "Freelancer" },
  // ];
  // const salaryType = [
  //   { id: "monthly", title: "Monthly" },
  //   { id: "annual", title: "Annual" },
  // ];
  // let {editor}=useCKEditor();
  // console.log(editor,"d432")
  const technologyData = useSelector((state) => state.technology);
  const departmentStatus = useSelector((state) => state.department.status);
  const departmentData = useSelector((state) => state.department.data);
  const MemberDropdownStatus = useSelector(
    (state) => state.employee.MemberDropdownStatus
  );
  const MemberDropdown = useSelector((state) => state.employee.MemberDropdown);
  //const { data: technologyData } = useSelector((state) => state.technology);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      departmentId: "",
      employmentType: "",
      seniorityLevel: "",
      ctcType: "",
      isRemote: "office",
      hideSalaryDetails: false,
      skills: [],
      startDate: null,
      endDate: null,
      title: "",
      designationId: "",
      description: "",
      minCTC: "",
      maxCTC: "",
      experienceFrom: "",
      experienceTo: "",
      priority: "",
      noOfPosition: null,
    },
    validationSchema: jobDescreptionSchema,
    onSubmit: async (values) => {
      try {
        handleOpen();
        // if (!isAddMode) {
        //     await dispatch(updateById({ ...values, id })).unwrap();
        // }

        // if (isAddMode) {
        //     await dispatch(insert(values)).unwrap();
        // }
        // dispatch(setStatus({ status: "idle" }));
        // resetForm();
        // navigation("/job-description");
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  useEffect(() => {
    // if (MemberDropdownStatus === "idle") {
    dispatch(getAllMemberDropdown());
    // }
  }, [dispatch]);

  useEffect(() => {
    if (state?.id) {
      dispatch(getById(state.id))
        .unwrap()
        .then((res) => {
          setValues(res);
        });
    }
  }, [state?.id]);

  useEffect(() => {
    if (departmentStatus === "idle") {
      getViewVisible("department") && dispatch(getDepartment());
    }
  }, [dispatch]);
  // console.log(selectedHr,"d2134555");
  const {
    getFieldProps,
    handleSubmit,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    handleBlur,
    touched,
    isSubmitting,
    resetForm,
  } = formik;

  useEffect(() => {
    if (state?.id) {
      let selectids = values?.hiringTeam?.map(
        (element) => element?.employee?.id
      );
      // console.log(selectids,'selectids');
      let filterdate = MemberDropdown.filter((element) =>
        selectids?.includes(element.id)
      );
      // console.log(MemberDropdown,'MemberDropdown');
      // console.log(filterdate,"filterdate");
      setSelectedHr(filterdate);
    }
  }, [MemberDropdown, values?.hiringTeam]);
  // useEffect(() => {
  //   if (isSubmitting) {
  //     handleOpen();
  //   }
  // }, [isSubmitting]);
  //const { getFieldProps,values } = props;

  // const handleKeywordChange = (event, values) => {
  //     setFieldValue("keywords", values);
  // };
  const filter = createFilterOptions();
  let submitFunction = async () => {
    let hiringTeam = selectedHr.map((s) => s?.id);
    let finalRequest = {
      ...values,
      hiringTeam: hiringTeam,
      jobStatus: jobStatus ? "PUBLISHED" : "CREATED",
    };
    if (isAddMode) {
      delete finalRequest.id;
      await dispatch(insert({...finalRequest})).unwrap();

      // if(state?.id&&state.clone===false){
      // let updatedData={
      //   ...finalRequest,id:state?.id
      // }
      // await dispatch(updateById(updatedData)).unwrap();
      // }else{
      //       await dispatch(insert(finalRequest)).unwrap();
      // }
    }
    dispatch(setStatus({ status: "idle" }));
    resetForm();
    navigation("/job-description");
  };

  let thirdModalOpen = () => {
    if (selectedHr[0]) {
      setHrError({ flag: false, title: "" });
      handleCloseSecondModal();
      handleOpenThirdModal();
      // alert("done");
    } else {
      setHrError({ flag: true, title: "Please select HR" });
    }
  };
  let secondModalOpen = () => {
    handleClose();
    handleOpenSecondModal();
  };

  let thirdModelBack = () => {
    handleOpenSecondModal();
    handleCloseThirdModal();
  };

  let proceedJob = () => {
    if (selectedHr[0]) {
      setHrError({ flag: false, title: "" });
      handleCloseSecondModal();
      submitFunction();
      // alert("done");
    } else {
      setHrError({ flag: true, title: "Please select hr" });
    }
  };

  let firstModalOpen = () => {
    handleCloseSecondModal();
    handleOpen();
  };
  // useEffect(() => {
  //   const fetchById = async () => {
  //     if (!isAddMode) {
  //       setLoading(true);
  //       var decodeParam = decryption(params.id);
  //       setId(decodeParam);
  //       // get user and set form fields
  //       try {
  //         let record = await dispatch(getById(decodeParam)).unwrap();
  //         setValues(record);
  //         setFieldValue("keywords", JSON.parse(record.keywords));
  //         setLoading(false);
  //       } catch (error) {
  //         setLoading(false);
  //         navigation("/job-description");
  //         //if there is no data then it gives error msg "No records found"
  //         if (error.status === 404 && !error.success) {
  //           toast.error(error.message);
  //           navigation("/job-description");
  //         }
  //       }
  //     }
  //   };
  //   fetchById();
  // }, []);
  const designation = useSelector((state) => state.designation);
  useEffect(() => {
    if (
      !designation.loading &&
      !designation.error &&
      (!designation.data ||
        (Array.isArray(designation.data) && designation.data.length <= 0))
    )
      dispatch(getDesignation({ sortBy: "", orderBy: "" }));
  }, [designation, dispatch]);
  useEffect(() => {
    Array.isArray(technologyData.data) &&
      technologyData.data.length === 0 &&
      // dispatch(getTechnology());
      dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  }, []);

  if (loading) {
    return <Loader />;
  }

  const removeHR = (element) => {
    let tempArray = selectedHr.filter((x) => {
      return x.id !== element.id;
    });
    setSelectedHr(tempArray);
  };
  // console.log("NEW_____01",values)
  return (
    <Container className='Employee-wrapper Add-Job-Container' maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="title-button-wrapper Add-Job-title">
          <Typography variant="h4" gutterBottom>
            {id ? "Edit Job" : "Add Job"}
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="end">
            <Button
              className="back-button"
              variant="contained"
              component={RouterLink}
              to="/job-description"
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </Stack>
        </div>
      </Stack>
      <Card className="holiday-table-grid add-job-card" mb={5}>
        <Container maxWidth="xl">
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid className="add-job-grid-card gray-box Employee_Detail_Content" container spacing={3}>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" fullWidth>
                    {/* <TextField
                    autoComplete="off"
                    fullWidth
                    label="Title"
                    name="title"
                    onBlur={handleBlur}
                    value={values.title}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      maxLength: 64,
                      "data-cy": "txt-experience-job-description",
                    }}
                    FormHelperTextProps={{
                      "data-cy": "txt-experience-err-job-description",
                    }}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  /> */}
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={designation.data || []}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.title) {
                          return option.title;
                        }
                        // Regular option
                        return option.title;
                      }}
                      onChange={(e, newValue) => {

                    

                        if (newValue?.inputValue ) {
                          setFieldValue("title", newValue ? newValue?.inputValue  : "");
                        } else if (typeof newValue === "object") {
                          setFieldValue(
                            "designationId",
                            newValue ? newValue.id : null
                          );
                          setFieldValue(
                            "title",
                            newValue ? newValue?.title : ""
                          );
                        }
                      }}
                      filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some(
                          (option) => inputValue === option.title
                        );
                        if (inputValue !== "" && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Title"
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      )}
                      value={
                        values.designationId
                          ? { id: values.designationId, title: values.title }
                          : { title: values.title }
                      }

                      /* error={true} */
                    />
                    {/* <Autocomplete
                    size="small"
                    id="free-solo-demo"
                    fullWidth
                    freeSolo
                    value={
                      values.designationId
                        ? { id: values.designationId, title: values.title }
                        : { title: values.title }
                    }
                    options={designation.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    onChange={(e, newValue) => {
                      if (typeof newValue === "string") {
                        setFieldValue("title", newValue ?? "");
                      } else if (typeof newValue === "object") {
                        setFieldValue(
                          "designationId",
                          newValue ? newValue.id : null
                        );
                        setFieldValue("title", newValue ? newValue.title : "");
                      }
                      
                    }}
                    // options={top100Films.map((option) => option.title)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Title"
                        error={Boolean(touched.title && errors.title)}
                        helperText={touched.title && errors.title}
                        
                      />
                    )}
                  /> */}
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" fullWidth>
                    {/*<InputLabel>Department</InputLabel>
									<Select
										label="Department"
										size="small"
										defaultValue={''}
										{...getFieldProps('departmentId')}
									>
										{departmentData.map((department) => {
											return (
												<MenuItem key={department.id} value={department.id}>
													{department.title}
												</MenuItem>
											);
										})}
									</Select> */}
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      disabled={getViewVisible("department") === false}
                      onBlur={handleBlur}
                      options={departmentData || []}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`departmentId`)}
                      onChange={(event, newValue) => {
                        setFieldValue(`departmentId`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Department"
                          error={Boolean(
                            touched.departmentId && errors.departmentId
                          )}
                          helperText={
                            touched.departmentId && errors.departmentId
                          }
                        />
                      )}
                      value={
                        values.departmentId
                          ? departmentData.find(
                              (dept) => dept.id === values.departmentId
                            )
                          : {}
                      }
                      // value={departmentData.find(
                      //   (dept) => dept.id === values.departmentId
                      // )}
                      // error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" fullWidth>
                    <Autocomplete
                      size="small"
                      // value={{id: "Full Time",title: "Full Time"}}
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      onBlur={handleBlur}
                      options={employeementType || []}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`employmentType`)}
                      onChange={(event, newValue) => {
                        setFieldValue(`employmentType`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Employment Type"
                          error={Boolean(
                            touched.employmentType && errors.employmentType
                          )}
                          helperText={
                            touched.employmentType && errors.employmentType
                          }
                        />
                      )}
                      value={
                        values.employmentType
                          ? employeementType.find(
                              (dept) => dept.id === values.employmentType
                            )
                          : {}
                      }
                      // error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={seniorityLevel || []}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`seniorityLevel`)}
                      onChange={(event, newValue) => {
                        setFieldValue(`seniorityLevel`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Seniority Level"
                          error={Boolean(
                            touched.seniorityLevel && errors.seniorityLevel
                          )}
                          helperText={
                            touched.seniorityLevel && errors.seniorityLevel
                          }
                        />
                      )}
                      value={
                        values.seniorityLevel
                          ? seniorityLevel.find(
                              (dept) => dept.id === values.seniorityLevel
                            )
                          : {}
                      }
                      // error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Experience From"
                    name="experienceFrom"
                    value={values.experienceFrom}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(
                      touched.experienceFrom && errors.experienceFrom
                    )}
                    helperText={touched.experienceFrom && errors.experienceFrom}
                  />
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Experience To"
                    name="experienceTo"
                    value={values.experienceTo}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.experienceTo && errors.experienceTo)}
                    helperText={touched.experienceTo && errors.experienceTo}
                  />
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={salaryType || []}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`ctcType`)}
                      onChange={(event, newValue) => {
                        setFieldValue(`ctcType`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Salary Type"
                          error={Boolean(touched.ctcType && errors.ctcType)}
                          helperText={touched.ctcType && errors.ctcType}
                        />
                      )}
                      value={
                        values.ctcType
                          ? salaryType.find((dept) => dept.id == values.ctcType)
                          : {}
                      }
                      // error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Min CTC"
                    name="minCTC"
                    value={values.minCTC}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.minCTC && errors.minCTC)}
                    helperText={touched.minCTC && errors.minCTC}
                  />
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Max CTC"
                    name="maxCTC"
                    value={values.maxCTC}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    onBlur={handleBlur}
                    error={Boolean(touched.maxCTC && errors.maxCTC)}
                    helperText={touched.maxCTC && errors.maxCTC}
                  />
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="Start Date"
                        name="startDate"
                        value={values.startDate}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setFieldValue(`startDate`, newValue);
                        }}
                        // onBlur={(e) => {
                        //   interviewerList(index);
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={false}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack>
                      <DesktopDatePicker
                        label="End Date"
                        name="endDate"
                        value={values.endDate}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          setFieldValue(`endDate`, newValue);
                        }}
                        // onBlur={(e) => {
                        //   interviewerList(index);
                        // }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            size="small"
                            error={false}
                          />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Number Of Position"
                    name="noOfPosition"
                    value={values.noOfPosition || ""}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    type="number"
                    onBlur={handleBlur}
                    error={Boolean(touched.noOfPosition && errors.noOfPosition)}
                    helperText={touched.noOfPosition && errors.noOfPosition}
                  />
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/* <Autocomplete
									multiple
									size="small"
									fullWidth
									defaultValue={[]}
                                    onBlur={handleBlur}
									options={technologyData.data.filter((x) => {
                                        return !values.skills.map(y=>y.id).includes(x.id)
                                    }) || []}
									// options={technologyData.data || []}
									getOptionLabel={(option) => option.title || ""}
									{...getFieldProps(`skills`)}
									onChange={(event, newValue) => {
										let tempArray = newValue.map((x) => {
											return { id: x.id, title: x.title };
										});

										setFieldValue(`skills`, tempArray || []);
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											helperText={
												touched.skills
											}
											error={Boolean(
												touched.skills,
											)}
											label="Skills"
										/>
									)}
									value={values.skills}
								/> */}
                    <Autocomplete
                      multiple
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      defaultValue={[]}
                      options={
                        technologyData.data.filter((x) => {
                          return !values.skills.map((y) => y.id).includes(x.id);
                        }) || []
                      }
                      key={technologyData.data || []}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`skills`)}
                      onChange={(event, newValue) => {
                        let tempArray = newValue.map((x) => {
                          return { id: x.id, title: x.title };
                        });

                        setFieldValue(`skills`, tempArray || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          helperText={touched?.skills && errors?.skills}
                          error={Boolean(touched?.skills && errors?.skills)}
                          label="Technology"
                        />
                      )}
                      value={values.skills.filter((x) => {
                        return x.id;
                      })}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={4} mt={3}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={values.isRemote} />}
                      label="Is this job for remote?"
                      name="isRemote"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.isRemote}
                    />
                  </FormGroup>
                </Grid> */}
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl
                    component="fieldset"
                    size="small"
                    // error={Boolean(touched.gender && errors.gender)}
                  >
                    {/* <FormLabel component="legend">Gender</FormLabel> */}
                    <RadioGroup
                      row
                      aria-label="isRemote"
                      name="isRemote"
                      onChange={handleChange}
                      value={values.isRemote}
                    >
                      <FormControlLabel
                        value="office"
                        control={<Radio size="small" />}
                        label="Office"
                        style={{ color: "#637381" }}
                      />
                      <FormControlLabel
                        value="remote"
                        control={<Radio size="small" />}
                        label="Remote"
                        style={{ color: "#637381" }}
                      />
                      <FormControlLabel
                        value="hybrid"
                        control={<Radio size="small" />}
                        label="Hybrid"
                        style={{ color: "#637381" }}
                      />
                    </RadioGroup>
                    {/* <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched.gender && errors.gender}
              </FormHelperText> */}
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={values.hideSalaryDetails} />}
                      label="Hide salary details from candidates"
                      name="hideSalaryDetails"
                      onChange={handleChange}
                      value={values.hideSalaryDetails}
                    />
                  </FormGroup>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={4}>
                  <FormControl size="small" fullWidth>
                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={priority || []}
                      onBlur={handleBlur}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`priority`)}
                      onChange={(event, newValue) => {
                        setFieldValue(`priority`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Priority"
                          error={Boolean(touched.priority && errors.priority)}
                          helperText={touched.priority && errors.priority}
                        />
                      )}
                      value={
                        values.priority
                          ? priority.find((dept) => dept.id === values.priority)
                          : {}
                      }
                      // error={true}
                    />
                  </FormControl>
                </Grid>
                <Grid className="grid-item add-job-grid-item" item xs={12}>
                  <FormHelperText sx={{ fontSize: "14px" }}>
                    Description:
                  </FormHelperText>

                  {values?.description ? (
                    <CKEditor
                      data-cy="txt-content-job-description"
                      initData={values?.description ? values?.description : ""}
                      onBlur={handleBlur}
                      onChange={(event) => {
                        setFieldValue("description", event?.editor?.getData());
                      }}
                    />
                  ) : (
                    state?.id == undefined && (
                      <CKEditor
                        data-cy="txt-content-job-description"
                        initData={values?.description ? values?.description : ""}
                        onBlur={handleBlur}
                        onChange={(event) => {
                          setFieldValue("description", event?.editor?.getData());
                        }}
                      />
                    )
                  )}
                  {Boolean(touched?.description && errors?.description) && (
                    <FormHelperText
                      style={{ margin: "5px 0 0 0" }}
                      error
                      data-cy="txt-content-err-job-description"
                    >
                      {touched?.description && errors?.description}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>

              <Stack direction="row" justifyContent="flex-end" my={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  data-cy="btn-submit-job-description"
                >
                  Preview and Proceed
                </LoadingButton>
              </Stack>
            </form>
          </Paper>
        </Container>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            overflowY: "scroll",
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0px 20px 30px 0",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "80vh",
            overflowY: "scroll",
            width: "70%",
            outline: "none",
          }}
          className="scroll-blk"
        >
          <Stack direction="row" spacing={1} sx={{ height: "40px" }}>
            <Typography
              variant="h5"
              className="personal-title"
              style={{ paddingBottom: "33px" }}
            >
              Preview Job Details
            </Typography>
          </Stack>
          <Stack
            direction="row"
            spacing={2}
            style={{ padding: "40px 30px", flexDirection: "column" }}
          >
            <Typography
              variant="h2"
              className="details-model"
              sx={{ color: "#505152", fontWeight: "600" }}
            >
              {values.title} / {values.seniorityLevel}
            </Typography>
            <p
              style={{
                display: "block",
                fontSize: "13px",
                marginLeft: "4px",
                marginTop: "4px",
                color: "#898989",
              }}
            >
              Ahmedabad &nbsp; &nbsp;
            </p>
          </Stack>
          <Grid
            style={{ padding: "0px 30px 0px" }}
            container
            divider={<Divider orientation="vertical" flexItem />}
            spacing={3}
          >
            <Grid
              item
              xs={8}
              mt={3}
              style={{ paddingLeft: "30px", marginTop: "0" }}
              className="details-ul"
            >
              {values.description ? parse(values.description) : ""}
            </Grid>
            <Grid
              item
              xs={4}
              mt={3}
              style={{ borderLeft: "1px solid #ebebeb" }}
            >
              <Stack direction="column" spacing={3} className="details-right">
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Employment Type
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.employmentType ?? "-"}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Industry Type
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    It-Software/software Services
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Seniority Level
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.seniorityLevel}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    CTC Type
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.ctcType}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    CTC
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.minCTC} - {values.maxCTC}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Work Experience(in years)
                  </Typography>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.experienceFrom} - {values.experienceTo}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h6" sx={{ fontWeight: "regular" }}>
                    Skills
                  </Typography>
                  <Stack direction="row" sx={{ flexWrap: "wrap" }}>
                    {values.skills.map((element, index) => (
                      <Button
                        variant="contained"
                        disabled
                        sx={{ mb: 2, mr: 2 }}
                      >
                        {element.title}
                      </Button>
                    ))}
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
          <Stack
            direction="row"
            spacing={2}
            justifyContent="end"
            sx={{ mt: 2 }}
          >
            <Button variant="outlined" onClick={() => handleClose()}>
              Edit
            </Button>
            <Button
              variant="contained"
              data-cy="btn-submit-job-description"
              onClick={() => secondModalOpen()}
            >
              Proceed
            </Button>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openSecondModal}
        onClose={handleCloseSecondModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={style}
          style={{
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0 0px 30px 0px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            minHeight: "80vh",
            width: "70%",
            outline: "none",
          }}
          className="scroll-blk"
        >
          <Stack direction="row" spacing={1} sx={{ height: "40px" }}>
            <Typography
              variant="h5"
              className="personal-title"
              style={{ paddingBottom: "33px" }}
            >
              Select Member
            </Typography>
          </Stack>
          <Stack
            direction="column"
            spacing={2}
            style={{ marginTop: "30px", padding: "0 30px" }}
          >
            <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
              <Autocomplete
                multiple
                size="small"
                fullWidth
                defaultValue={[]}
                options={
                  MemberDropdown.filter((x) => {
                    return !selectedHr.map((y) => y.id).includes(x.id);
                  }) ?? []
                }
                // key={technologyData.data || []}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.lastName}` || ""
                }
                onChange={(event, newValue) => {
                  // let tempArray = newValue.map((x) => {
                  //   return {
                  //     id: x.id,
                  //     firstName: x.firstName,
                  //     lastName: x.lastName,
                  //     emails: x.emails,
                  //   };
                  // });
                  let tempArray = [...selectedHr, ...newValue];

                  setSelectedHr(tempArray || []);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={hrError?.title && hrError?.title}
                    error={Boolean(hrError?.flag && hrError?.flag)}
                    label="Select HR"
                  />
                )}
                // value={selectedHr.filter((x) => {
                //   return x.id;
                // })}
                value={[]}
              />
              {/* <Grid container spacing={3}>
              {selectedHr.map((element) => (
                <Grid item xs={6} mt={3}>
                  {element.title}
                </Grid>
              ))}
            </Grid> */}
            </FormControl>

            <Stack direction="row" className="add-member">
              <Grid
                item
                xs={12}
                style={{ position: "relative", width: "100%" }}
              >
                {selectedHr?.map((element) => (
                  <Stack
                    direction="row"
                    alignItems="center"
                    className="add-member-wrap"
                    key={element.id}
                  >
                    {/* <img src="/assets/images/user-img2@2x.png" /> */}
                    <Avatar
                      src={`${BASE_URL}/${element.employeeImage}`}
                      alt={element.firstName}
                      sx={{
                        width: "40px",
                        height: "40px",
                        fontSize: "1.5rem",
                        marginRight: "10px",
                      }}
                    ></Avatar>
                    {/* <img src={`${BASE_URL}/${element.employeeImage}`} /> */}
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{ width: "100%" }}
                    >
                      <Typography variant="h5" className="member-blk">
                        {element.firstName} {element.lastName}
                        {/* {console.log(element)} */}
                        {/* <p>
                          {element?.emails &&
                            element.emails[0] &&
                            element.emails
                              .map((email) => email.email)
                              .join(",")}{" "}
                        </p> */}
                        <p>
                          {element?.jobHiring &&
                            element.jobHiring[0] &&
                            element.jobHiring
                              .filter(
                                (jobs) => jobs.jobs.jobStatus == "PUBLISHED"
                              )
                              .map((jobs) => jobs.jobs.title)
                              .join(", ")}{" "}
                          {/* {element?.workDetails?.designation?.title} */}
                        </p>
                      </Typography>
                      <Button onClick={() => removeHR(element)}>
                        <ClearIcon />
                      </Button>
                    </Stack>
                  </Stack>
                ))}
                {/* <Stack
                  direction="row"
                  alignItems="center"
                  className="add-member-wrap"
                >
                  <img src="/assets/images/user-img2@2x.png" />
                  <Typography variant="h5" className="member-blk">
                    Ankita Delvadiya
                    <p>ankita.cybercom@gmail.com</p>
                  </Typography>
                </Stack>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="add-member-wrap"
                >
                  <img src="/assets/images/user-img2@2x.png" />
                  <Typography variant="h5" className="member-blk">
                    Ankita Delvadiya
                    <p>ankita.cybercom@gmail.com</p>
                  </Typography>
                </Stack> */}
              </Grid>
              {/* <Grid item xs={7}>
              <Typography variant="h5"  className="member-blk" style={{marginTop:'5px'}}>
                    Front-end Developer
                  </Typography>
              </Grid> */}
            </Stack>

            <Stack
              direction="row"
              spacing={2}
              justifyContent="end"
              sx={{ mt: 2 }}
            >
              <Button variant="outlined" onClick={() => firstModalOpen()}>
                Back
              </Button>
              <Button
                variant="contained"
                data-cy="btn-submit-job-description"
                onClick={() => thirdModalOpen()}
              >
                Proceed
              </Button>
            </Stack>
          </Stack>
        </Box>
      </Modal>

      <Modal
        open={openThirdModal}
        onClose={handleCloseThirdModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={styleThird}
          style={{
            overflowX: "initial",
            borderRadius: "5px",
            padding: "0px 0px 30px 0px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxHeight: "80vh",
            overflow: "auto",
            width: "70%",
            outline: "none",
            minHeight: "610px",
            position: "relative",
          }}
          className="scroll-blk"
        >
          <Typography variant="h5" className="personal-title">
            Publish Your Job
          </Typography>
          <Stack
            direction="column"
            spacing={2}
            style={{
              margin: "20px 30px 0px",
              border: "1px solid #d3d3d3",
              borderRadius: "5px",
              paddingBottom: "20px",
            }}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontWeight: "600",
                        fontSize: "14px !important",
                        color: "#787878",
                      }}
                    >
                      Job Board Name
                    </TableCell>
                    {/* <TableCell
                      style={{
                        width: "200px",
                        fontWeight: "600",
                        fontSize: "14px !important",
                        color: "#787878",
                      }}
                    >
                      Actions
                    </TableCell> */}
                  </TableRow>
                </TableHead>

                <TableBody>
                  <TableRow>
                    <TableCell>
                      <FormGroup row className="check-blk">
                        <FormControlLabel
                          style={{ width: "100%", marginRight: "0" }}
                          control={<Checkbox checked={jobStatus} />}
                          label="Do you want to publish job on Cybercom Creation career page?"
                          name="jobStatus"
                          onChange={(e) => setJobStatus(e.target.checked)}
                          value={jobStatus}
                        />
                      </FormGroup>
                    </TableCell>
                    {/* <TableCell>
                      <button className="gray-button">published</button>
                    </TableCell> */}
                  </TableRow>
                  {/* <TableRow>
                    <TableCell>
                      <FormGroup row className="check-blk">
                        <FormControlLabel
                          style={{ width: "100%", marginRight: "0" }}
                          control={<Checkbox checked={jobStatus} />}
                          label="Do you want to publish job on Cybercom Creation career page?"
                          name="jobStatus"
                          onChange={(e) => setJobStatus(e.target.checked)}
                          value={jobStatus}
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <button className="red-button">Unpublished</button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormGroup row className="check-blk">
                        <FormControlLabel
                          style={{ width: "100%", marginRight: "0" }}
                          control={<Checkbox checked={jobStatus} />}
                          label="Do you want to publish job on Cybercom Creation career page?"
                          name="jobStatus"
                          onChange={(e) => setJobStatus(e.target.checked)}
                          value={jobStatus}
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <button className="blue-button">Integrate</button>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <FormGroup row className="check-blk">
                        <FormControlLabel
                          style={{ width: "100%", marginRight: "0" }}
                          control={<Checkbox checked={jobStatus} />}
                          label="Do you want to publish job on Cybercom Creation career page?"
                          name="jobStatus"
                          onChange={(e) => setJobStatus(e.target.checked)}
                          value={jobStatus}
                        />
                      </FormGroup>
                    </TableCell>
                    <TableCell>
                      <button className="gray-button">published</button>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </Stack>
          <Stack
            style={{ paddingRight: "30px" }}
            className="position-blk"
            direction="row"
            spacing={2}
            justifyContent="end"
            sx={{ mt: 2 }}
          >
            <Button variant="outlined" onClick={() => thirdModelBack()}>
              Back
            </Button>
            <Button
              variant="contained"
              data-cy="btn-submit-job-description"
              onClick={() => proceedJob()}
            >
              Proceed
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddJobDescription,
    path: "/job-description/add",
    public: false,
    layout: DashboardLayout,
    group: "candidate",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddJobDescription,
    path: "/job-description/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "candidate",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
