import React from "react";

import DashboardLayout from "../../layouts/dashboard";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

function Configuration() {
  return (
    <Container maxWidth="xl">
      <Typography variant="h4" gutterBottom mb={3}>
        Configuration
      </Typography>
      <Card>
        <Container maxWidth="xl">
        <div className="title-button-wrapper">
          <Typography variant="h6" gutterBottom my={2}>
            IP Range
          </Typography>
          </div>
          <form>
            <Grid container spacing={3} pb={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="IP Range From"
                  name="title"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  placeholder="192.168.0.1"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="IP Range To"
                  name="title"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  placeholder="192.168.0.254"
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: Configuration,
  path: "/config",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
