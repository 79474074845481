import React from 'react';
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import { CKEditor } from 'ckeditor4-react';
import { Link as RouterLink } from 'react-router-dom';

import DashboardLayout from '../../../layouts/dashboard';
import Loader from '../../../components/Loader';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from "@mui/material/Autocomplete";

import {
	getPageMasterById,
	updatePageMasterById,
	createPageMaster,
} from '../../../redux/pageMaster/pageMasterThunk';
import pageMaster from '../../../validations/pageMaster';

import { decryption } from '../../../utils/encodeString';

function AddPageMater() {
	const dispatch = useDispatch();
	const params = useParams();
	const navigation = useNavigate();
	const isAddMode = !params.id;

	const [id, setId] = useState(null);
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			title: '',
			content: '',
			metaTitle: '',
			metaKeyword: '',
			metaDescription: '',
			accessKey: '',
			status: '',
		},
		validationSchema: pageMaster,
		onSubmit: async (values, { resetForm }) => {
			try {
				if (!isAddMode) {
					await dispatch(updatePageMasterById({ ...values, id })).unwrap();
				}
				if (isAddMode) {
					await dispatch(createPageMaster(values)).unwrap();
				}
				resetForm();
				navigation('/page-master');
			} catch (error) {
				toast.error(error.response.data.message);
			}
		},
	});

	const {
		handleSubmit,
		setValues,
		values,
		setFieldValue,
		handleChange,
		errors,
		touched,
		isSubmitting,
	} = formik;

	const statusData = ['Enable', 'Disable'];
	useEffect(() => {
		const fetchById = async () => {
			if (!isAddMode) {
				setLoading(true);
				var decodeParam = decryption(params.id);
				if(decodeParam===-1){
					navigation('/page-master');
				}
				setId(decodeParam);
				// get user and set form fields
				try {
					let record = await dispatch(getPageMasterById(decodeParam)).unwrap();
					setValues(record);
					setLoading(false);
				} catch (error) {
					setLoading(false);
					navigation('/page-master');
					//if there is no data then it gives error msg "No records found"
					if (error.status === 404 && !error.success) {
						toast.error(error.message);
						navigation('/page-master');
					}
				}
			}
		};
		fetchById();
	}, []);

	return (
		<Container maxWidth="xl">
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
				<Typography variant="h4" gutterBottom>
					{id ? "Edit Page" : "Add Page"}
				</Typography>
				<Stack direction="row" alignItems="center" justifyContent="end">
					<Button
						variant="contained"
						component={RouterLink}
						to="/page-master"
						startIcon={<ArrowBackIcon />}
						style={{ marginLeft: '10px' }}
					>
						Back To List
					</Button>
				</Stack>
			</Stack>
			<Card className="holiday-table-grid" mb={5}>
				{loading ? (
					<Loader />
				) : (
					<Container maxWidth="xl">
						<Paper>
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item xs={12} mt={3}>
										<TextField
											fullWidth
											label="Title"
											name="title"
											value={values.title}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(touched.title && errors.title)}
											helperText={touched.title && errors.title}
										/>
									</Grid>

									<Grid item xs={12}>
										<CKEditor
											initData={values.content}
											onChange={(event) => {
												setFieldValue('content', event.editor.getData());
											}}
										/>
										{Boolean(touched.content && errors.content) && (
											<FormHelperText error style= {{margin: '5px 0 0 0'}}>
												{touched.content && errors.content}
											</FormHelperText>
										)}
									</Grid>
									<Grid item xs={6}>
										<TextField
											fullWidth
											label="Meta title"
											name="metaTitle"
											value={values.metaTitle}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(touched.metaTitle && errors.metaTitle)}
											helperText={touched.metaTitle && errors.metaTitle}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											fullWidth
											label="Meta keyword"
											name="metaKeyword"
											value={values.metaKeyword}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(
												touched.metaKeyword && errors.metaKeyword
											)}
											helperText={touched.metaKeyword && errors.metaKeyword}
										/>
									</Grid>
									<Grid item xs={12}>
										<FormControl fullWidth>
											<TextareaAutosize
												aria-label="Meta description"
												minRows={4}
												placeholder="Enter your meta description here"
												fullWidth
												className="custom-textarea"
												name="metaDescription"
												vlaue={values.metaDescription}
												onChange={handleChange}
												inputProps={{ maxLength: 512 }}
												error={Boolean(
													touched.metaDescription &&
														errors.metaDescription
												)}
												helperText={
													touched.metaDescription &&
													errors.metaDescription
												}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={6}>
										<TextField
											fullWidth
											label="Access key"
											name="accessKey"
											value={values.accessKey}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(touched.accessKey && errors.accessKey)}
											helperText={touched.accessKey && errors.accessKey}
										/>
									</Grid>
									<Grid item xs={6}>
											{/*<InputLabel id="demo-simple-select-helper-label">
												Status
											</InputLabel>
											<Select
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												label="Status"
												size="small"
												error={Boolean(touched.status && errors.status)}
												name="status"
												onChange={handleChange}
												value={values.status}
											>
												<MenuItem value="Enable">Enable</MenuItem>
												<MenuItem value="Disable">Disable</MenuItem>
											</Select> */}

											<Autocomplete
												labelId="demo-simple-select-helper-label"
												id="demo-simple-select-helper"
												size="small"
												name="status"
												onChange={(event, newValue) => {
													setFieldValue(
														"status",
														newValue || ""
													);
												}}
											
												value={values.status}
												options={statusData || []}
												renderInput = {(params) => 
													<TextField {...params} label="Status"
														error={Boolean(touched.status && errors.status)}
														helperText = {touched.status && errors.status}
													/>
												}   
											/>
									</Grid>
								</Grid>
								<Stack direction="row" justifyContent="flex-end" my={3}>
									<LoadingButton
										type="submit"
										variant="contained"
										loading={isSubmitting}
									>
										save
									</LoadingButton>
								</Stack>
							</form>
						</Paper>
					</Container>
				)}
			</Card>
		</Container>
	);
}

const componentConfig = [
	{
		component: AddPageMater,
		path: '/page-master/add',
		public: false,
		layout: DashboardLayout,
		group: 'CMS',
		sidebar: true,
		role: ['admin'],
	},
	{
		component: AddPageMater,
		path: '/page-master/add/:id',
		public: false,
		layout: DashboardLayout,
		group: 'CMS',
		sidebar: true,
		role: ['admin'],
	},
];

export default componentConfig;
