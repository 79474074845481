// Footer.js
import React from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
   display: 'flex',
   marginTop:'auto',
  },
  footer: {
    textAlign: 'center',
    fontSize: 14,
    backgroundColor: '#FACA37',
    padding: 10,
    width: '100%',
    marginBottom:10
  },
  pageNumber: {
    textAlign: 'center',
    fontSize: 8,
    color: 'grey',
    marginBottom: 5,
  },
});

const Footer = ({ pageNumber, totalPages }) => (
  <View style={styles.container} fixed>
    <Text style={styles.pageNumber}>
      Page {pageNumber} of {totalPages}
    </Text>
    {/* <View style={styles.footer}>
      <Text>B-305, The First, B/h ITC Narmada, Vastrapur, Ahmedabad – 380015.</Text>
    </View> */}
  </View>
);

export default Footer;
