import React from 'react';
// import './email.css'
import Chip from '@mui/material/Chip';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { BASE_URL } from '../../../constants';
function AttachmentPreview({ attachmentData }) {
  const { fileName, mimeType,attachment } = attachmentData;
  return (
    <div  style = {{marginTop : '10px',display : 'flex',flexDirection : 'row',alignItems : 'center'}}>
      <div>
        <Chip color="primary"
              variant="filled"
              icon={<PictureAsPdfIcon />} 
              label={fileName} />
       </div>
        <div  style = {{marginLeft : '5px' }}>
        <a href={`${BASE_URL}/${attachment}`} download={fileName} target="_blank" rel="noopener noreferrer">
          <DownloadForOfflineIcon color="primary" fontSize = 'large' />
        </a>
      </div>
    </div>
  );
}

export default AttachmentPreview;



