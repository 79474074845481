import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import vmMasterSchema from "../../../validations/vmMasterSchema";
import DashboardLayout from "../../../layouts/dashboard";

import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { insertAssetVM, updateAssetVMById, getAssetVMById } from "../../../redux/assetVM/assetVMThunk";
import { getGeneratedAssetsForDropdown } from "../../../redux/assets/thunk";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import { encryption,decryption } from "../../../utils/encodeString";
import { yearMonthDateFormat } from "../../../utils/dateFormat";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { ErrorSharp } from "@mui/icons-material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
};

const statusData = ["Running", "Closed", "Removed"];
const renewData = ["Yearly", "Monthly"];
const accessDetailsObj = {
	rootUsername: "",
	password: "",
};
const databaseDetailsObj = {
	name: "",
	version: "",
  username: "",
  password: ""
};

function AddVM() {

  let { id } = useParams();
	if (id) id = decryption(id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [verifyId, setVerifyId] = useState("");
  const [verifyIds, setVerifyIds] = useState([]);
  const [verifyDBIds, setVerifyDBIds] = useState([]);
  const [verifyDBId, setVerifyDBId] = useState("");

  const [accessPasswordKey, setAccessPasswordKey] = useState('');
  const [databasePasswordKey, setDatabasePasswordKey] = useState('');
  const [webMinPasswordKey, setWebminPasswordKey] = useState('');

  const [showPassword, setShowPassword] = useState(false);
  const [showDBPassword, setshowDBPassword] = useState(false);
  const [showWebMinPassword, setshowWebMinPassword] = useState(false);

  const[accessPasswordFlag,setAccessPasswordFlag] = useState(true);
  const[dbPasswordFlag,setDbPasswordFlag] = useState(true);
  const[webminPasswordFlag,setWebminPasswordFlag] = useState(true);

  const [fildname, setFildname] = useState('');
	const generatedAssetsData = useSelector(
		(state) => state.assets.generatedAssetsForDropdown,
	);
  const employeeData = useSelector((state)=> state.employee.allEmployeeData);

  // if (!getAddVisible("vmMaster")) {
  //   navigate("/dashboard");
  // }

  // if(id && !getEditVisible("vmMaster")) {
  //   navigate("/dashboard");
  // }

  useEffect(() => {
    if(id===-1){
      navigate("/vm");
    }
  }, [dispatch,id]);

  useEffect(() => {
			dispatch(
				getGeneratedAssetsForDropdown(),
			);
	}, [dispatch]);

  useEffect(() => {
    dispatch(
      getEmployeeForDropDown(),
    );
}, [dispatch]);

   const formik = useFormik({
    initialValues:{
      serverName: "",
      assetName: "",
      assetId: "",
      status: "",
      purpose: "",
      sshPort: "",
      vdiFilePath: "",
      ipAddress: "",
      diskSize: "",
      ramSize: "",
      os: "",
      mountPoint: "",
      webRoot: "",
      phpVersion: "",
      apacheVersion: "",
      iisVersion: "",
      miscInformation: "",
      deletedPath: "",
      backupDir: "",
      backupServerDetails: "",
      webMinUrl: "",
      webMinUsername: "",
      webMinPassword: "",
      notes: "",
      accessDetails: [],
      databaseDetails: [],
      users: [],
      isRenewable: false,
      renewRange: "",
      startDate: null,
  },
    validationSchema: vmMasterSchema,
    onSubmit: async (values, { resetForm }) => {
			try {
        if(values?.users?.length > 0){
        values.users = values.users.map((user) => {
          if(user?.userId){
            return user
          }else{
            return {userId: user}
          }
         
        })
        }
        if(values.accessDetails)
        {
        values.accessDetails = values.accessDetails.map((access) => {
            return {...access, password: encryption(access.password)}
          })
        }
        if(values.databaseDetails)
        {
          values.databaseDetails = values.databaseDetails.map((db) => {
            return {...db, password: encryption(db.password)}
          })
        }
        if(values.webMinPassword)
        {
          values.webMinPassword = encryption(values.webMinPassword)
        }  
        if (id) {
					await dispatch(
						updateAssetVMById({ ...values, id : id }),
					).unwrap();
				}
				if (!id) {
					await dispatch(insertAssetVM(values)).unwrap();
				}
				navigate("/vm");
				 resetForm();
			} catch (error) {
				toast.error(error.message);
			}
		},
  });
 
  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    isSubmitting,
    handleChange,
    setValues,
    touched,
    errors,
    handleBlur
  } = formik;

  // let temp = values.users.map((user) => user.userId);

  const addAccess = () => {
    setFieldValue("accessDetails", [
      ...values.accessDetails,
      {
        rootUsername: "",
	      password: "",
      },
    ]);
  };

  const removeAccess = (index) => {
    const newAccessDetails = [...values.accessDetails];
    newAccessDetails.pop(index, 1);
    setFieldValue("accessDetails", newAccessDetails);
  };
  const addDatabase = () => {
		setFieldValue("databaseDetails", [...values.databaseDetails, databaseDetailsObj]);
	};

  const removeDatabase = (e, index) => {
		e.preventDefault();
		const temp = [...values.databaseDetails];
		temp.splice(index, 1);
		setFieldValue("databaseDetails", temp);
	};

  const handleShowPassword = (accessId) => {
        setFildname(`accessPassword`);
        setVerifyId(accessId);
        handleOpen();
	};

  const handleHidePassword = (accessId) => {
    // setShowPassword(!showPassword);
    let access = verifyIds.filter((e) => {
      return e != accessId
    })
    setVerifyIds(access)
  };
  const handleShowDBPassword = (dbId) => {
    setFildname(`dbPassword`);
    setVerifyDBId(dbId);
    handleOpen();
};

const handleHideDBPassword = (dbId) => {
  // setShowPassword(!showPassword);
  let db = verifyDBIds.filter((e) => {
    return e != dbId
  })
  setVerifyDBIds(db)
};

const handleShowWebMinPassword = () => {
  if(!id){
    setshowWebMinPassword(!showWebMinPassword);
  }
  else{
    if(showWebMinPassword===false){
      setFildname(`webMinPassword`);
      handleOpen();
    }
    else{
      setshowWebMinPassword(!showWebMinPassword);
    }
  }
};

  useEffect(() => {
    const fetchById = async () => {
      if (id) {
        try {
          let record = await dispatch(getAssetVMById(id)).unwrap();
          let body = {
            serverName: record?.serverName,
						assetName:  record?.assetName,
            assetId: record?.assetId,
            status: record?.status,
            purpose: record?.purpose,
            sshPort: record?.sshPort,
            vdiFilePath: record?.vdiFilePath,
            ipAddress: record?.ipAddress,
            diskSize: record?.diskSize,
            ramSize: record?.ramSize,
            os: record?.os,
            mountPoint: record?.mountPoint,
            webRoot: record?.webRoot,
            phpVersion: record?.phpVersion,
            apacheVersion: record?.apacheVersion,
            iisVersion: record?.iisVersion,
            miscInformation: record?.miscInformation,
            deletedPath: record?.deletedPath,
            backupDir: record?.backupDir,
            backupServerDetails: record?.backupServerDetails,
            webMinUrl: record?.webMinUrl,
            webMinUsername: record?.webMinUsername,
            webMinPassword: record?.webMinPassword ? record.webMinPassword: "",
            notes: record?.notes,
            isRenewable: record?.isRenewable,
            renewRange: record?.renewRange,
            startDate: record?.startDate,
            accessDetails: record?.accessDetails
              ? record.accessDetails.map((access) => {
                return {...access, password: access.password}
              })
              : [],
            databaseDetails: record?.databaseDetails
              ? record.databaseDetails.map((db) => {
                return {...db, password: db.password}
              })
              : [],
            users: record?.users
              ? record.users.map((user) => {return user.userId})
              : [],
          };

          if(body.accessDetails.length === 0){
            setAccessPasswordFlag(false)
          }
          if(body.databaseDetails.length === 0){
            setDbPasswordFlag(false)
          }
          if(!body.webMinPassword){
            setWebminPasswordFlag(false)
          }

          setValues(body);
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
          }
            navigate(`/vm`);
        }
      }
      // setLoading(false);
    };
    fetchById();
  }, [dispatch, id, navigate, setValues]);

   if(values?.users.length > 0 && values?.users[0]?.userId){
    
    values.users =  values?.users?.map((ele) => {
         if(ele?.userId){
            return ele?.userId;
         }
    })
          
   }

	// if (values.accessDetails?.length === 0) {
	// 	values.accessDetails = [accessDetailsObj];
	// }

  // if (values.databaseDetails?.length === 0) {
	// 	values.databaseDetails = [databaseDetailsObj];
	// }

  console.log("databaseDetails",values.databaseDetails);

  let handleVerify=()=>{
    if(fildname==='accessPassword'){
      if(accessPasswordKey==='1234'){
        setVerifyIds([...verifyIds, verifyId]);
        setShowPassword(!showPassword);
        handleClose();
      }else{
        toast.error('Wrong Key');
        handleClose();
      }
    }else if(fildname==='dbPassword'){
      if(databasePasswordKey==='1234'){
        setVerifyDBIds([...verifyDBIds, verifyDBId])
        setshowDBPassword(!showDBPassword);
        handleClose();
      }else{
        toast.error('Wrong Key');
        handleClose();
      }
    }else if(fildname==='webMinPassword'){
      if(webMinPasswordKey==='1234'){
        setshowWebMinPassword(!showWebMinPassword);
        handleClose();
      }else{
        toast.error('Wrong Key');
        handleClose();
      }
    }
  }
  if(!values.webMinUrl)
  {
    delete errors.webMinUrl
  }

  return (
    <Container maxWidth="xl" py={3}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            {id ? "Edit VM" : "Add VM"}
          </Typography>
          <Stack direction="row" alignItems="center">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button
              variant="contained"
              component={RouterLink}
              to="/vm"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          </Stack>
          </div>
        </Stack>
        <Card className="chips-card">
          <Container maxWidth="xl">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h6" gutterBottom>
                Basic Details
              </Typography>
            </Stack>
            <Grid container spacing={3} mb={6}>
              <Grid item xs={4}>
                <TextField
                  label="Server Name"
                  variant="outlined"
                  size="small"
                  // value={values.serverName}
                  onChange={handleChange}
                  autoComplete="off"
                  inputProps={{ maxLength: 256 }}
                  {...getFieldProps("serverName")}
                  fullWidth
                  error={Boolean(touched?.serverName && errors?.serverName)}
                  helperText={touched?.serverName && errors?.serverName}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
									size="small"
									fullWidth
									options={generatedAssetsData || []}
									
                  getOptionLabel={(option) =>
                    {
                      if(option?.assetEmployee && option?.assetEmployee?.employee != null)
                      {
                        return option?.assetUniqueId +
                        " - " +
                        option?.assetEmployee?.employee.firstName +
                        " " +
                        option?.assetEmployee?.employee.lastName +
                        " - " +
                        option.name +
                        " - " +
                        option.generatedAssetItems
                      }
                      else if(option.assetEmployee && option.assetEmployee.employee == null)
                      {
                        return option?.assetUniqueId + " - " + 
                        option?.name + " - "  + 
                        option?.generatedAssetItems 
                      }
                      else {
                        return "";
                      }
                    }
                    // option.assetEmployee && option.assetEmployee.employee != null
                    //   ? option?.assetUniqueId +
                    //     " - " +
                    //     option?.assetEmployee?.employee.firstName +
                    //     " " +
                    //     option?.assetEmployee?.employee.lastName +
                    //     " - " +
                    //     // option.name +
                    //     // " - " +
                    //     option.generatedAssetItems
                    //   : option?.assetUniqueId + " - " || "" + 
                    //   option?.name + " - " || "" + 
                    //   option?.generatedAssetItems || ""
                  }
                
                  renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        { option.assetEmployee && option.assetEmployee.employee != null
                      ? option?.assetUniqueId +
                        " - " +
                        option?.assetEmployee?.employee.firstName +
                        " " +
                        option?.assetEmployee?.employee.lastName +
                        " - " +
                        option.name +
                        " - " +
                        option?.generatedAssetItems
                      : option?.assetUniqueId + " - " + option?.name + " - " + option?.generatedAssetItems || ""}
                      </li>
                    )
                  }
									{...getFieldProps(`assetId`)}
									onChange={(event, newValue) => { 
										setFieldValue('assetName', newValue ? newValue?.assetUniqueId + "-" + newValue?.name + " - " + newValue.generatedAssetItems :  "");
                    setFieldValue("assetId", newValue?.id || "")
									}}
									renderInput={(params) => (
										<TextField
											{...params}
                      autoComplete="off"
											error={Boolean(touched?.assetName && errors?.assetName)}
											helperText={touched?.assetName && errors?.assetName}
											label="Host PC"
										/>
									)}
									value={generatedAssetsData?.find(
										(a) =>
                    a.id == values.assetId
									) || ""}
                  
									error={Boolean(true)}
								/>
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  name="Status"
                  onChange={(event, newValue) => {
                    setFieldValue(`status`, newValue || "");
                  }}
                  value={values.status}
                  options={statusData || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      autoComplete="off"
											error={Boolean(touched?.status && errors?.status)}
											helperText={touched?.status && errors?.status}
                    />
                  )}
                />
              </Grid>
              
              <Grid item xs={4}>
                <TextField
                  label="SSH Port Number"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  onChange={handleChange}
                  value={values.sshPort}
                  inputProps={{ maxLength: 256 }}
                  {...getFieldProps("sshPort")}
                  fullWidth
                  error={Boolean(touched?.sshPort && errors?.sshPort)}
                  helperText={touched?.sshPort && errors?.sshPort}
                />
              </Grid>
              <Grid item xs={4}>
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  // defaultValue=""
                  options={employeeData || []}
                  key={employeeData || []}
                  getOptionLabel={(option) =>
                    option.firstName + " " + option.lastName || ""
                  }
                  
                  renderOption={(props, option) => (
                    <li {...props} value={option.id} key={option.id}>
                      {option.firstName + " " + option.lastName || ""}
                     </li>
                  )
                }
                  {...getFieldProps(`users`)}
                  onChange={(event, newValue) => {
                    let tempArray = newValue.map((x) =>
                      {return x.id}
                    );
                  
                    setFieldValue(
                      `users`,
                      tempArray || [],
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Primary Users"
                      error={Boolean(
                        touched?.users &&
                        errors?.users 
                      )}
                      helperText={
                        touched?.users &&
                        errors?.users
                      }
                    />
                  )}
               
                  value={employeeData?.filter((x) =>
                    values.users.includes(x.id)
                    )}
                 
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  label="VDI File Path (Ex. /var/www/html)"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.vdiFilePath}
                  inputProps={{ maxLength: 256 }}
                  {...getFieldProps("vdiFilePath")}
                  fullWidth
                  error={Boolean(touched?.vdiFilePath && errors?.vdiFilePath)}
                  helperText={touched?.vdiFilePath && errors?.vdiFilePath}
                />
              </Grid>
              

            <Grid item xs={4}>
              <Stack direction="row" alignItems="center" mb={2}>
                <div>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.isRenewable}
                          {...getFieldProps("isRenewable")}
                        />
                      }
                      label="Is Renewable?"
                    />
                  </FormGroup>
                </div>
              </Stack>
            </Grid>
            {values?.isRenewable && (
              <>
              <Grid item xs={4}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  name="Status"
                  onChange={(event, newValue) => {
                    setFieldValue(`renewRange`, newValue || "");
                  }}
                  value={values?.renewRange}
                  options={renewData || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Renew Range"
                      autoComplete="off"
											error={Boolean(touched?.renewRange && errors?.renewRange)}
											helperText={touched?.renewRange && errors?.renewRange}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Renew Start Date"
                    autoComplete="off"
                    name="startDate"
                    value={values?.startDate || null}
                    inputFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    onChange={(newValue) => {
                      setFieldValue("startDate", yearMonthDateFormat(newValue));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={Boolean(
                          touched?.startDate && errors?.startDate
                        )}
                        helperText={
                          touched?.startDate && errors?.startDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </>
              
            )}
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  multiline
                  aria-label="Note..."
                  minRows={2}
                  onChange={handleChange}
                  value={values.purpose}
                  placeholder="Purpose"
                  /* className="custom-textarea" */
                  inputProps={{
                    inputComponent: TextareaAutosize,
                    maxLength: 512,
                    style: {
                      resize: "auto",
                    },
                  }}
                  {...getFieldProps("purpose")}
                  error={Boolean(
                    touched?.purpose && errors?.purpose,
                  )}
                  helperText={touched?.purpose && errors?.purpose}
                />
            </Grid>
          </Grid>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={2} 
            mt={4}
          >
            <Typography variant="h6" gutterBottom>
              Hardware Details
            </Typography>
          </Stack>
          <Grid container spacing={3} mb={6}>
            <Grid item xs={4}>
              <TextField
                label="IP Address"
                variant="outlined"
                size="small"
                onChange={handleChange}
                autoComplete="off"
                value={values.ipAddress}
                inputProps={{ maxLength: 256 }}
                {...getFieldProps("ipAddress")}
                fullWidth
                error={Boolean(touched?.ipAddress && errors?.ipAddress)}
                helperText={touched?.ipAddress && errors?.ipAddress}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Disk Size(In GB)"
                variant="outlined"
                size="small"
                onChange={handleChange}
                autoComplete="off"
                value={values.diskSize}
                inputProps={{ maxLength: 4 }}
                {...getFieldProps("diskSize")}
                fullWidth
                error={Boolean(touched?.diskSize && errors?.diskSize)}
                helperText={touched?.diskSize && errors?.diskSize}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Ram Size(In GB)"
                variant="outlined"
                size="small"
                onChange={handleChange}
                autoComplete="off"
                value={values.ramSize}
                inputProps={{ maxLength: 4 }}
                {...getFieldProps("ramSize")}
                fullWidth
                error={Boolean(touched?.ramSize && errors?.ramSize)}
                helperText={touched?.ramSize && errors?.ramSize}
              />
            </Grid>
            
            <Grid item xs={4}>
              <TextField
                label="Operating System"
                variant="outlined"
                size="small"
                onChange={handleChange}
                autoComplete="off"
                value={values.os}
                inputProps={{ maxLength: 256 }}
                {...getFieldProps("os")}
                fullWidth
                error={Boolean(touched?.os && errors?.os)}
                helperText={touched?.os && errors?.os}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Mount Point"
                variant="outlined"
                size="small"
                onChange={handleChange}
                autoComplete="off"
                value={values.mountPoint}
                inputProps={{ maxLength: 256 }}
                {...getFieldProps("mountPoint")}
                fullWidth
                error={Boolean(touched?.mountPoint && errors?.mountPoint)}
                helperText={touched?.mountPoint && errors?.mountPoint}
              />
              </Grid>
              <Grid item xs={4} >
                <TextField
                  label="Web Root (Ex. /var/www/html)"
                  variant="outlined"
                  size="small"
                  onChange={handleChange}
                  autoComplete="off"
                  value={values.webRoot}
                  inputProps={{ maxLength: 256 }}
                  {...getFieldProps("webRoot")}
                  fullWidth
                  error={Boolean(touched?.webRoot && errors?.webRoot)}
                  helperText={touched?.webRoot && errors?.webRoot}
                />
              </Grid>
            </Grid>

            <div className="accessDetails">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={2}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  Access Details
                </Typography>
                <Button
                  onClick={addAccess}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Add Access
                </Button>
              </Stack>
              {values &&
                values.accessDetails &&
                values.accessDetails.map((edu, index) => (
                  <Stack >
                    <Grid key={index} container spacing={3}>
                      <Grid item xs={5}>
                        <TextField
                          label="Username"
                          variant="outlined"
                          size="small"
                          value={values.accessDetails[index].rootUsername}
                          // onChange={handleChange
                          // onChange={(newValue) => {
                          //   console.log(newValue)
                          //   setFieldValue(`accessDetails[${index}].rootUsername`, newValue);
                          // }}
                          // autoComplete="new-password"
                          inputProps={{ maxLength: 256 }}
                          {...getFieldProps(
                            `accessDetails[${index}].rootUsername`,
                          )}                          
                          fullWidth
                          helperText={
                            touched?.accessDetails &&
                            touched?.accessDetails[index] &&
                            touched?.accessDetails[index]
                              ?.rootUsername &&
                            errors?.accessDetails &&
                            errors?.accessDetails[index] &&
                            errors?.accessDetails[index]?.rootUsername
                          }
                          error={Boolean(
                            touched?.accessDetails &&
                              touched?.accessDetails[index] &&
                              touched?.accessDetails[index]
                                ?.rootUsername &&
                              errors?.accessDetails &&
                              errors?.accessDetails[index] &&
                              errors?.accessDetails[index]?.rootUsername
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          type={(verifyIds.includes(values.accessDetails[index].id)) ? "text" : "password"}
                          label="Access Password"
                          name="accessPassword"
                          value={values.accessDetails[index].password }
                          onChange={handleChange}
                          {...getFieldProps(
                            `accessDetails[${index}].password`,
                          )}                            
                          variant="outlined"
                          disabled={(id && showPassword===false && accessPasswordFlag) ? true : false}
                          size="small"
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton  edge="end">
                                  {verifyIds.includes(values.accessDetails[index].id) ? (
                                    <VisibilityOffIcon onClick={() => handleHidePassword(values.accessDetails[index].id)} />
                                  ) : (
                                    <VisibilityIcon onClick={() => handleShowPassword(values.accessDetails[index].id)} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            touched?.accessDetails &&
                            touched?.accessDetails[index] &&
                            touched?.accessDetails[index]
                              ?.password &&
                            errors?.accessDetails &&
                            errors?.accessDetails[index] &&
                            errors?.accessDetails[index]?.password
                          }
                          error={Boolean(
                            touched?.accessDetails &&
                              touched?.accessDetails[index] &&
                              touched?.accessDetails[index]
                                ?.password &&
                              errors?.accessDetails &&
                              errors?.accessDetails[index] &&
                              errors?.accessDetails[index]?.password
                          )}
                        />
                      </Grid>
                      
                      <Grid item xs={1}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="end"
                        >
                          {values &&
                            values.accessDetails &&
                            Array.isArray(values.accessDetails) &&
                            // values.accessDetails.filter((e) => !e.deleted).length >
                            //   1 && 
                              (
                              <IconButton
                                onClick={(e) => removeAccess(e, index)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                        </Stack>
                      </Grid>
                    </Grid>
                    <Stack my={3}>
                      {/* <Divider /> */}
                    </Stack>
                  </Stack>
              ))}
				    </div>

            <div className="accessDetails">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  Database Details
                </Typography>
                <Button
                  onClick={addDatabase}
                  variant="contained"
                  startIcon={<AddIcon />}
                >
                  Add Databse Detail
                </Button>
              </Stack>
              {values &&
                values.databaseDetails &&
                values.databaseDetails.map((edu, index) => (
                  <Stack key={index}>
                    <Grid key={index} container spacing={3}>
                      <Grid item xs={5}>
                        <TextField
                          label="Name"
                          variant="outlined"
                          size="small"
                          onChange={handleChange}
                          value={values.databaseDetails[index].name}
                          autoComplete="off"
                          inputProps={{ maxLength: 256 }}
                          {...getFieldProps(
                            `databaseDetails[${index}].name`,
                          )}
                          fullWidth
                          helperText={
                            touched?.databaseDetails &&
                            touched?.databaseDetails[index] &&
                            touched?.databaseDetails[index]
                              ?.name &&
                            errors?.databaseDetails &&
                            errors?.databaseDetails[index] &&
                            errors?.databaseDetails[index]?.name
                          }
                          error={Boolean(
                            touched?.databaseDetails &&
                              touched?.databaseDetails[index] &&
                              touched?.databaseDetails[index]
                                ?.name &&
                              errors?.databaseDetails &&
                              errors?.databaseDetails[index] &&
                              errors?.databaseDetails[index]?.name
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Version"
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          value={values.databaseDetails[index].version}
                          onChange={handleChange}
                          inputProps={{ maxLength: 256 }}
                          {...getFieldProps(
                            `databaseDetails[${index}].version`,
                          )}
                          fullWidth
                          helperText={
                            touched?.databaseDetails &&
                            touched?.databaseDetails[index] &&
                            touched?.databaseDetails[index]
                              ?.version &&
                            errors?.databaseDetails &&
                            errors?.databaseDetails[index] &&
                            errors?.databaseDetails[index]?.version
                          }
                          error={Boolean(
                            touched?.databaseDetails &&
                              touched?.databaseDetails[index] &&
                              touched?.databaseDetails[index]
                                ?.version &&
                              errors?.databaseDetails &&
                              errors?.databaseDetails[index] &&
                              errors?.databaseDetails[index]?.version
                          )}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="end"
                        >
                          {values &&
                            values.databaseDetails &&
                            Array.isArray(values.databaseDetails) &&
                            // values.databaseDetails.filter((e) => !e.deleted).length >
                            //   1 &&
                               (
                              <IconButton
                                onClick={(e) => removeDatabase(e, index)}
                              >
                                <DeleteIcon color="error" />
                              </IconButton>
                            )}
                        </Stack>
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          label="Username"
                          variant="outlined"
                          size="small"
                          // type={pass}
                          autoComplete="off"
                          onChange={handleChange}
                          value={values.databaseDetails[index].username}
                          inputProps={{ maxLength: 256 }}
                          {...getFieldProps(
                            `databaseDetails[${index}].username`,
                          )}
                          fullWidth
                          helperText={
                            touched?.databaseDetails &&
                            touched?.databaseDetails[index] &&
                            touched?.databaseDetails[index]
                              ?.username &&
                            errors?.databaseDetails &&
                            errors?.databaseDetails[index] &&
                            errors?.databaseDetails[index]?.username
                          }
                          error={Boolean(
                            touched?.databaseDetails &&
                              touched?.databaseDetails[index] &&
                              touched?.databaseDetails[index]
                                ?.username &&
                              errors?.databaseDetails &&
                              errors?.databaseDetails[index] &&
                              errors?.databaseDetails[index]?.username
                          )}
                        />
                      </Grid>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          autoComplete="off"
                          type={(verifyDBIds.includes(values.databaseDetails[index].id)) ? "text" : "password"}
                          label="Database Password"
                          name="dbPassword"
                          value={values.databaseDetails[index].password}
                          onChange={handleChange}
                          {...getFieldProps(
                            `databaseDetails[${index}].password`,
                          )}
                          variant="outlined"
                          disabled={(id && showDBPassword===false && dbPasswordFlag) ? true : false}
                          size="small"
                          onBlur={handleBlur}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton  edge="end">
                                  {verifyDBIds.includes(values.databaseDetails[index].id) ? (
                                    <VisibilityOffIcon onClick={() => handleHideDBPassword(values.databaseDetails[index].id)} />
                                  ) : (
                                    <VisibilityIcon onClick={() => handleShowDBPassword(values.databaseDetails[index].id)} />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          helperText={
                            touched?.databaseDetails &&
                            touched?.databaseDetails[index] &&
                            touched?.databaseDetails[index]
                              ?.password &&
                            errors?.databaseDetails &&
                            errors?.databaseDetails[index] &&
                            errors?.databaseDetails[index]?.password
                          }
                          error={Boolean(
                            touched?.databaseDetails &&
                              touched?.databaseDetails[index] &&
                              touched?.databaseDetails[index]
                                ?.password &&
                              errors?.databaseDetails &&
                              errors?.databaseDetails[index] &&
                              errors?.databaseDetails[index]?.password
                          )}
                        />
                      </Grid>
                    </Grid>
                    <Stack my={3}>
                    </Stack>
                  </Stack>
              ))}
				    </div>

            <div className="accessDetails">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  Web Server Details
                </Typography>
              </Stack>
              
              <Grid container spacing={3} mb={6}>
                <Grid item xs={5}>
                  <TextField
                    label="PHP Version"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    autoComplete="off"
                    value={values.phpVersion}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("phpVersion")}
                    fullWidth
                    error={Boolean(touched?.phpVersion && errors?.phpVersion)}
                    helperText={touched?.phpVersion && errors?.phpVersion}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="Apache Version"
                    variant="outlined"
                    size="small"
                    // type={pass}
                    autoComplete="off"
                    value={values.apacheVersion}
                    onChange={handleChange}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("apacheVersion")}
                    fullWidth
                    error={Boolean(touched?.apacheVersion && errors?.apacheVersion)}
                    helperText={touched?.apacheVersion && errors?.apacheVersion}
                  />
                </Grid>
                
                <Grid item xs={5}>
                  <TextField
                    label="IIS Version"
                    variant="outlined"
                    size="small"
                    // type={pass}
                    autoComplete="off"
                    value={values.iisVersion}
                    onChange={handleChange}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("iisVersion")}
                    fullWidth
                    error={Boolean(touched?.iisVersion && errors?.iisVersion)}
                    helperText={touched?.iisVersion && errors?.iisVersion}
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    placeholder="Misc Information"
                    onChange={handleChange}
                    value={values.miscInformation}
                    /* className="custom-textarea" */
                    inputProps={{
                      inputComponent: TextareaAutosize,
                      maxLength: 512,
                      style: {
                        resize: "auto",
                      },
                    }}
                    {...getFieldProps("miscInformation")}
                    error={Boolean(
                      touched?.miscInformation && errors?.miscInformation,
                    )}
                    helperText={touched?.miscInformation && errors?.miscInformation}
                  />
                </Grid>
              </Grid>
				    </div>
            <div className="accessDetails">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mt={1}
                mb={2}
              >
                <Typography variant="h6" gutterBottom>
                  Other Details
                </Typography>
              </Stack>
              
              <Grid container spacing={3} mb={6}>
                <Grid item xs={4}>
                  <TextField
                    label="Deleted Path"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.deletedPath}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("deletedPath")}
                    fullWidth
                    error={Boolean(touched?.deletedPath && errors?.deletedPath)}
                    helperText={touched?.deletedPath && errors?.deletedPath}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="Backup Directory"
                    variant="outlined"
                    size="small"
                    // type={pass}
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.backupDir}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("backupDir")}
                    fullWidth
                    error={Boolean(touched?.backupDir && errors?.backupDir)}
                    helperText={touched?.backupDir && errors?.backupDir}
                  />
                </Grid>
                
                <Grid item xs={4}>
                  <TextField
                    label="Backup Server Details"
                    variant="outlined"
                    size="small"
                    // type={pass}
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.backupServerDetails}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("backupServerDetails")}
                    fullWidth
                    error={Boolean(touched?.backupServerDetails && errors?.backupServerDetails)}
                    helperText={touched?.backupServerDetails && errors?.backupServerDetails}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="WebMin URL"
                    variant="outlined"
                    size="small"
                    // type={pass}
                    autoComplete="off"
                    onChange={handleChange}
                    value={values.webMinUrl}
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("webMinUrl")}
                    fullWidth
                    error={values.webMinUrl != "" ? Boolean(touched?.webMinUrl && errors?.webMinUrl) : ""}
                    helperText={ values.webMinUrl != "" ? touched?.webMinUrl && errors?.webMinUrl : ""}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    label="WebMin Username"
                    variant="outlined"
                    size="small"
                    onChange={handleChange}
                    value={values.webMinUsername}
                    // type={pass}
                    autoComplete="off"
                    inputProps={{ maxLength: 256 }}
                    {...getFieldProps("webMinUsername")}
                    fullWidth
                    error={Boolean(touched?.webMinUsername && errors?.webMinUsername)}
                    helperText={touched?.webMinUsername && errors?.webMinUsername}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    type={showWebMinPassword ? "text" : "password"}
                    label="Webmin Password"
                    name="webMinPassword"
                    value={values.webMinPassword}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={(id && showWebMinPassword===false && webminPasswordFlag) ? true : false}
                    size="small"
                    onBlur={handleBlur}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowWebMinPassword} edge="end">
                            {showWebMinPassword ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    error={Boolean(touched?.webMinPassword && errors?.webMinPassword)}
                    helperText={touched?.webMinPassword && errors?.webMinPassword}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={2}
                    placeholder="Notes"
                    value={values.notes}
                    onChange={handleChange}
                    /* className="custom-textarea" */
                    inputProps={{
                      inputComponent: TextareaAutosize,
                      maxLength: 512,
                      style: {
                        resize: "auto",
                      },
                    }}
                    {...getFieldProps("notes")}
                    error={Boolean(
                      touched?.notes && errors?.notes,
                    )}
                    helperText={touched?.notes && errors?.notes}
                  />
                </Grid>
              </Grid>
				    </div>
          </Container>
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack sx={style}>
            <TextField
              fullWidth
              autoComplete="off"
              label="Verification Code"
              
              // value={values.ispName}
              onChange={(e)=>{fildname=='accessPassword'?setAccessPasswordKey(e.target.value):fildname=='dbPassword'?setDatabasePasswordKey(e.target.value):setWebminPasswordKey(e.target.value)}}
              variant="outlined"
              size="small"
            />
            <Button variant="contained" onClick={handleVerify}>Submit</Button>
          </Stack>
        </Modal>
      </form>
      
    </Container>
  );
}

const componentConfig = [
  {
    component: AddVM,
    path: "/vm/add",
    public: false,
    layout: DashboardLayout,
    group: "Asset Management",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddVM,
    path: "/vm/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "Asset Management",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
