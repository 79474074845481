import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';

import DashboardLayout from '../../../layouts/dashboard';
import Loader from '../../../components/Loader';

import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
// import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from "@mui/material/Autocomplete";

import jobVacancySchema from "../../../validations/jobVacancySchema";

import { getDepartment } from '../../../redux/department/departmentThunk';
import { getJobDes } from '../../../redux/jobDescription/jobDesThunk';
import { decryption } from '../../../utils/encodeString';
import {
	addJobVacancy,
	getJobVacancies,
	getJobVacancyId,
	updateJobVacancy,
} from '../../../redux/jobVacancy/jobVacancyThunks';
import { setStatus } from '../../../redux/jobDescription/jobDesSlice';

function AddJobVacancy() {
	let { id } = useParams();

	if (id) id = decryption(id);

	const [loading, setLoading] = useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const departmentStatus = useSelector((state) => state.department.status);
	const departmentData = useSelector((state) => state.department.data);

	const jobDescriptionStatus = useSelector((state) => state.jobDes.status);
	const jobDescriptionData = useSelector((state) => state.jobDes.data);

	const JOB_TYPE = ['Full Time', 'Part Time'];
	const STATUS = ['Open', 'Closed'];

	useEffect(() => {
		if(id===-1){
			navigate('/job-vacancy')
		}
	}, [dispatch,id]);

	const formik = useFormik({
		initialValues: {
			jobTitle: '',
			departmentId: '',
			startDate: null,
			expireDate: null,
			jobType: '',
			status: '',
			applicants: '',
			jobDescriptionId: '',
			isActive: true,
		},
		validationSchema: jobVacancySchema,
		onSubmit: async (values) => {
			try {
				let response;

				if (!id) {
					response = await dispatch(addJobVacancy(values)).unwrap();
				}

				if (id) {
					response = await dispatch(updateJobVacancy(values)).unwrap();
				}

				if (response.status === 200) {
					navigate('/job-vacancy');
					dispatch(getJobVacancies({ page: 1, limit: 5, orderBy: 'DESC', sortBy: 'id' }));
				}
			} catch (error) {
				toast.error(error.message);
			}
		},
	});

	const { getFieldProps, values, setFieldValue, handleSubmit, isSubmitting, setValues, errors, touched} = formik;

	useEffect(() => {
		if (departmentStatus === 'idle') {
			dispatch(getDepartment());
		}
	}, [dispatch]);

	useEffect(() => {
		if (jobDescriptionStatus === 'idle') {
			dispatch(getJobDes());
		}

		return () => {
			dispatch(setStatus('idle'));
		};
	}, [dispatch]);

	useEffect(() => {
		if (id) {
			setLoading(true);
			dispatch(getJobVacancyId(id))
				.unwrap()
				.then((data) => {
					setValues(data);
					setLoading(false);
				})
				.catch((err) => {
					toast.error(err.message);
					return navigate('/job-vacancy');
				});
		}
	}, [dispatch]);

	if (loading) {
		return <Loader />;
	}

	return (
		<Container maxWidth="xl">
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={3}>
				<Typography variant="h4" gutterBottom>
					{id ? "Edit Job" : "Add Job"}
				</Typography>
				<Button
					component={RouterLink}
					to="/job-vacancy/"
					variant="contained"
					startIcon={<ArrowBackIcon />}
				>
					Back To List
				</Button>
			</Stack>
			<Card pb={3}>
				<Container maxWidth="xl">
					<form onSubmit={handleSubmit}>
						<Grid container spacing={3} sx={{ padding: '24px 0' }}>
							<Grid item xs={6}>
								<TextField
									fullWidth
									label="Job Title"
									variant="outlined"
									size="small"
									inputProps={{ maxLength: 256 }}
									{...getFieldProps('jobTitle')}
									error={Boolean(
										touched.jobTitle && errors.jobTitle
										)}
										helperText={
												touched.jobTitle && errors.jobTitle
										}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl size="small" fullWidth>
									{/*<InputLabel>Department</InputLabel>
									<Select
										label="Department"
										size="small"
										defaultValue={''}
										{...getFieldProps('departmentId')}
									>
										{departmentData.map((department) => {
											return (
												<MenuItem key={department.id} value={department.id}>
													{department.title}
												</MenuItem>
											);
										})}
									</Select> */ }
									<Autocomplete
										size="small"
										fullWidth
										options={departmentData || []}
										getOptionLabel={(option) =>option.title || ""}
										{...getFieldProps(`departmentId`)}
										onChange={(event, newValue) => {
												setFieldValue(
													`departmentId`,
													newValue?.id || ""
											);
										}}
									renderInput={(params) => (
												<TextField {...params} label="Department" />
										)}
										value={departmentData.find(
											(dept) => dept.id === values.departmentId
										)}
									error={true}
									/>
								</FormControl>
							</Grid>

							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Stack spacing={3} direction="row">
										<DesktopDatePicker
											label="Start Date"
											value={values.startDate}
											onChange={(newValue) => {
												setFieldValue('startDate', newValue);
											}}
											renderInput={(params) => (
												<TextField {...params} fullWidth size="small" />
											)}
										/>
									</Stack>
								</LocalizationProvider>
							</Grid>
							<Grid item xs={6}>
								<LocalizationProvider dateAdapter={AdapterDateFns}>
									<Stack spacing={3} direction="row">
										<DesktopDatePicker
											label="Expired Date"
											value={values.expireDate}
											// minDate={new Date('2017-01-01')}
											onChange={(newValue) => {
												setFieldValue('expireDate', newValue);
											}}
											renderInput={(params) => (
												<TextField {...params} fullWidth size="small" />
											)}
										/>
									</Stack>
								</LocalizationProvider>
							</Grid>

							<Grid item xs={6}>
								<FormControl size="small" fullWidth>
									{/*<InputLabel>Job Type</InputLabel>
									<Select
										label="Job Type"
										size="small"
										defaultValue={''}
										{...getFieldProps('jobType')}
									>
										<MenuItem value="Full Time">Full Time</MenuItem>
										<MenuItem value="Part Time">Part Time</MenuItem>
											</Select> */}

									<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="Job Type"
										size="small"
										name="jobType"
										{...getFieldProps('jobType')}
										onChange={(event, newValue) => {
											setFieldValue(
												"jobType",
												newValue || ""
											);
										}}
									
										value={values.jobType}
										options={JOB_TYPE || []}
										renderInput = {(params) => 
										<TextField {...params} label="Job Type" />}   
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<FormControl size="small" fullWidth>
									{/*<InputLabel>Status</InputLabel>
									<Select
										label="Status"
										size="small"
										defaultValue={''}
										{...getFieldProps('status')}
									>
										<MenuItem value="open">Open</MenuItem>
										<MenuItem value="closed">Closed</MenuItem>
										</Select>*/}
									<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="Status"
										size="small"
										name="status"
										{...getFieldProps('status')}
										onChange={(event, newValue) => {
											setFieldValue(
												"status",
												newValue || ""
											);
										}}
										
										value={values.status}
										options={STATUS || []}
										renderInput = {(params) => 
										<TextField {...params} label="Status" />}   
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
								<TextField
									fullWidth
									label="Applicants"
									type="number"
									variant="outlined"
									size="small"
									{...getFieldProps('applicants')}
									error={Boolean(
										touched.applicants && errors.applicants
										)}
										helperText={
												touched.applicants && errors.applicants
										}
								/>
							</Grid>
							<Grid item xs={6}>
								<FormControl size="small" fullWidth>
									{/*<InputLabel>Job Description</InputLabel>
									<Select
										label="Job Description"
										size="small"
										defaultValue={''}
										{...getFieldProps('jobDescriptionId')}
									>
										{jobDescriptionData.map((jobdes) => {
											return (
												<MenuItem key={jobdes.id} value={jobdes.id}>
													{`${jobdes.Technology.title}(${
														jobdes.experience
													}) - ${
														JSON.parse(jobdes.keywords)?.join(', ') ||
														'-'
													}`}
												</MenuItem>
											);
										})}
									</Select>*/}
									<Autocomplete                                           
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="Job Description"
										size="small"
										fullWidth
										name="jobDescription"
										inputProps={{ maxLength: 8 }}
										options={jobDescriptionData || []}
										//value={values.jobDescriptionId}
										getOptionLabel={(option) => {
											if(option)
											{
												if(option.hasOwnProperty('keywords') && option.hasOwnProperty('Technology') && option.hasOwnProperty('experience')) 
												{
													return `${option?.Technology?.title}(${option?.experience})- ${JSON.parse(option?.keywords)?.join(', ')}`;
												}else
												{
													return "";
												}
											}else
											{
												return "";
											}
										} }
										
										{...getFieldProps(
											'jobDescriptionId'
										)}
										onChange={(event, newValue) => {
										setFieldValue(
											`jobDescriptionId`,
											newValue?.id || '',
										);
										}}
										value={jobDescriptionData.find(
											(i) =>
												i.id ===
												values.jobDescriptionId,
										) || ""}
										renderInput={(params) => (
										<TextField {...params} label="Job Description" />
										)}                      
									/> 

								</FormControl>
							</Grid>
						</Grid>
						<Stack direction="row" alignItems="center" justifyContent="flex-end" mb={3}>
							<LoadingButton type="submit" loading={isSubmitting} variant="contained">
								Submit
							</LoadingButton>
						</Stack>
					</form>
				</Container>
			</Card>
		</Container>
	);
}

const componentConfig = [
	{
		component: AddJobVacancy,
		path: '/job-vacancy/add',
		public: false,
		layout: DashboardLayout,
		group: 'candidate',
		sidebar: true,
		role: ['admin'],
	},
	{
		component: AddJobVacancy,
		path: '/job-vacancy/add/:id',
		public: false,
		layout: DashboardLayout,
		group: 'candidate',
		sidebar: true,
		role: ['admin'],
	},
];

export default componentConfig;
