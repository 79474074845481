import React, { useEffect, useState } from "react";

import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { debounce } from "lodash";

import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Autocomplete from "@mui/material/Autocomplete";
import { Badge, Chip } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
// import { LocalizationProvider } from "@mui/lab";
import Box from "@mui/material/Box";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import TableSortLabel from "@mui/material/TableSortLabel";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { getInterview } from "../../redux/interview/interviewThunk";
import CustomPagination from "../../components/Pagination";
import {
	getInterviewList,
	getInterviewCount,
} from "../../redux/InterviewList/InterviewListThunk";
import { getCandidateByStatus } from "../../redux/candidateState/candidateStatethunk";
import { useDispatch, useSelector } from "react-redux";
import {
	indianDateFormat,
	convertTo24HrsFormat,
	yearMonthDateFormat,
  indianDateFormat2,
  yearFormat,
} from "../../utils/dateFormat";
import { getTechnology } from "../../redux/technology/technologyThunk";
import invertDirection from "../../utils/invertDirection";

import { encryption } from "../../utils/encodeString";
import {
	setLimit,
	setPage,
	setTotalPage,
	setSortBy,
	setOrderBy,
} from "../../redux/InterviewList/InterviewListSlice";

import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

const InterviewList = () => {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();
	const [isSearching, setIsSearching] = useState(false);
	const interviewList = useSelector((state) => state.interviewList.data);
	const loading = useSelector((state) => state.interviewList.loading);
	const error = useSelector((state) => state.interviewList.error);
	const limit = useSelector((state) => state.interviewList.limit);
	const page = useSelector((state) => state.interviewList.page);
	const status = useSelector((state) => state.interviewList.status);
	const totalCount = useSelector((state) => state.interviewList.totalCount);
	const totalPage = useSelector((state) => state.interviewList.totalPage);
	const sortBy = useSelector((state) => state.interviewList.sortBy);
	const orderBy = useSelector((state) => state.interviewList.orderBy);
	const technologyData = useSelector((state) => state.technology);
	const interviewData = useSelector((state) => state.interview);
	const [searchValue, setSearchValue] = useState("");
	const inputRef = React.useRef(null);
	const InterviewStatusData = useSelector(
		(state) => state.candidateState.stateData,
	);
	const interviewCount = useSelector(
		(state) => state.getInterviewConut.data.totalCount,
	);
	const getAllInterviewCount = useSelector(
		(state) => state.getInterviewConut.data,
	);
	const params = location.state;
	const [state, setState] = React.useState({
		right: false,
	});
	const [filterChips, setFilterChips] = useState([]);
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("interview")) {
			dispatch(getInterviewList({ orderBy, sortBy, page: 1, limit }));
			navigate("/dashboard");
		}
	}, []);

	const { handleSubmit, values, handleChange, resetForm, setFieldValue } =
		useFormik({
			initialValues: {
				search: "",
				is_dashboard: 0,
				technologyIds: [],
				interviewStatusId: "",
				interviewDateFrom: null,
				interviewDateTo: null,
				interviewTime: "",
				totalExperienceFrom: "",
				totalExperienceTo: "",
				// interviewer: [],
			},
			onSubmit: async (values) => {
        console.log("values", values)
				let queryParams = { ...values };
				try {
					if (queryParams.interviewStatusId.length > 0) {
						queryParams["interviewStatusId"] =
							queryParams.interviewStatusId; /* .map((i) => i.id); */
					} else {
						queryParams["interviewStatusId"] = "";
					}

					/* console.log(queryParams["interviewStatusId"]); */
					dispatch(
						getInterviewList({ ...queryParams, orderBy, sortBy,page:1}),
					);
					// dispatch(setPage({ page: 0 }));

					prepareChips();
				} catch (error) {
					toast.error(error.response.data.message);
				}
			},
		});
    useEffect(() => {
      if (status === "idle" && !error) {
        dispatch(
          getInterviewList({
            limit,
            page: page,
            orderBy,
            sortBy,
          }),
        );
      }
    }, [dispatch, limit, page, sortBy, orderBy, params]);
		
	useEffect(() => {
		// dispatch(getInterviewList({ orderBy, sortBy }));
		return () => {
			resetForm();
			dispatch(getInterviewList({ limit, orderBy, sortBy, page:1 }));
			
			// dispatch(setPage({ page: 0 }));
			setState({ ...state, right: false });
			setFilterChips([]);
		};
	}, []);

  // useEffect(() => {
  //   // if (status === "idle" && !error) {
  //     dispatch(
  //       getInterviewList({
  //         limit,
  //         ...params,
  //         page: page+1,
  //         orderBy,
  //         sortBy,
  //       })

  //     );
  //     prepareChips();

  //   // }
  // }, [dispatch]);

	

	useEffect(() => {
		if (
			!technologyData.loading &&
			!technologyData.error &&
			(!technologyData.data ||
				(Array.isArray(technologyData.data) && technologyData.data.length <= 0))
		)
			// dispatch(getTechnology());
			dispatch(getTechnology({sortBy:'',orderBy:''}));

	}, [technologyData]);

	useEffect(() => {
		if (
			!interviewData.complete &&
			!interviewData.loading &&
			!interviewData.error &&
			(!interviewData.data ||
				(Array.isArray(interviewData.data) && interviewData.data.length <= 0))
		)
			dispatch(getInterview());
	});

	useEffect(() => {
		dispatch(getTechnology({sortBy:'',orderBy:''}));
		dispatch(getInterview());
		dispatch(getCandidateByStatus(4));
	}, [dispatch]);

	useEffect(() => {
		if (!error) {
			dispatch(getInterviewCount({}));
		}
	}, [error, dispatch]);

	const handleRowsPerPageChange = (event) => {
    // dispatch(setPage({ page: 0 }));
		dispatch(setLimit({ limit: parseInt(event?.target?.value, 10) }));
		// dispatch(
		// 	setTotalPage({
		// 		totalPage: Math.ceil(totalCount / parseInt(event.target.value, 10)),
		// 	}),
		// );
		
	};

  const handleFilters = async (field,newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
    // await dispatch(
    //   	getEmployee({
    //   		// field: newValue,
    //   		...params,
    //   		...filterData,
    //   		limit,
    //   		page: 1,
    //   		newData: true,
    //   		scrollApiCall: scrollApiCall,
    //   		orderBy,
    //   		sortBy,
    //   	}),
    //   );
      setIsSearching(true);
  }

	const handleChangePage = (event, newPage) => {
		dispatch(setPage({ page: newPage+1}));
	};

	const formatInterviewerName = (interviewrefrences) => {
		return interviewrefrences
			?.map(
				(ref) => `${ref?.interviewer?.firstName} ${ref?.interviewer?.lastName}`,
			)
			.join(", ");
	};

	const toggleDrawer = (anchor, open) => (event) => {
		if (
			event.type === "keydown" &&
			(event.key === "Tab" || event.key === "Shift")
		) {
			return;
		}

		setState({ ...state, [anchor]: open });
	};
	const handleReset = () => {
		resetForm();
		dispatch(getInterviewList({ orderBy, sortBy, page:1, limit }));
		dispatch(setPage({ page: 0 }));

		setState({ ...state, right: false });
		setFilterChips([]);
	};
	const prepareChips = () => {
		let filters = { ...values };
		let tempChips = [];
		
		for (const key in filters) {
			if (filters[key] && key === "technologyIds") {
				// const res = filters[key].map((tech) => tech.title);
				const res = technologyData.data.filter(obj => filters[key].includes(obj.id)).map(obj => obj.title)
				
				if (res[0]) filters[key] = `Technology:${res.join(",")}`;
			}
			// technologyData.data.filter(obj => filters[key].includes(obj.id)).map(obj => obj.title);

			if (filters[key] && key === "interviewStatusId") {
				
				// let filterKey = filters[key].map((interview) => interview.id);
				// console.log(filterKey, "filterKey");
				const res = InterviewStatusData.filter((status) =>
				filters[key]?.includes(status.id),
				);
				
				if (res && res.length > 0)
					filters[key] = `Status : ${res.map((s) => s.name).join(",")}`;
				// const res = technologyData.data.find(
				// 	(tech) => tech.id === filters[key][0],
				// );
				// if (res) filters[key] = res.title;
			}

			if (filters[key] && key === "totalExperienceFrom") {
				filters["totalExperienceFrom"] = `Experience From : ${filters[key]} yrs`;
			}

			if (filters[key] && key === "totalExperienceTo") {
				filters["totalExperienceTo"] = `Experience To : ${filters[key]} yrs`;
			}

			if (filters[key] && key === "interviewDateFrom") {
        let temp = indianDateFormat(filters[key])
				filters["interviewDateFrom"] = `Interview Date From : ${temp}`;
			}

			if (filters[key] && key === "interviewDateTo") {
        let temp = indianDateFormat(filters[key])
				filters["interviewDateTo"] = `Interview Date To : ${temp}`;
			}

			if (filters[key] && key === "interviewTime") {
				filters["interviewTime"] = `Interview Time : ${filters[key]}`;
			}

			if (filters[key]) {
				
				if (key === "interviewStatusId" && !Array.isArray(filters[key])) {
					tempChips = [...tempChips, { type: key, filter: filters[key] }];
				}
				if (key === "technologyIds" && !Array.isArray(filters[key])) {
					tempChips = [...tempChips, { type: key, filter: filters[key] }];
				}
				if (key !== "interviewStatusId"&& key !== "technologyIds") {
					tempChips = [...tempChips, { type: key, filter: filters[key] }];
				}
			}
		}
		setFilterChips(tempChips);
	};

	const handleSearch = async (e) => {
		// if (e?.target?.value != "") {
		// 	setIsSearching(true);
		// 	await dispatch(
		// 		getInterviewList({
		// 			search: e?.target?.value,
		// 			orderBy,
		// 			sortBy,
		// 			...params,
		// 		}),
		// 	);
		// } else {
		// 	setIsSearching(false);
		// 	await dispatch(
		// 		getInterviewList({
		// 			orderBy,
		// 			sortBy,
		// 		}),
		// 	);
		// }
		setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    handleSubmit()
	};


	const onDeleteChip = (value, length) => {
    
    let field = value?.type
    setFieldValue(field, "")
		setFilterChips([
		  ...filterChips.filter((val) => val.filter !== value.filter),
		]);
    setFieldValue(value.type, "");
		let filters = { ...values, [value["type"]]: "" };
	
		if (length === 1) {
			// getInterviewList({ ...queryParams, orderBy, sortBy, ...params }),
		  dispatch(getInterviewList({ orderBy, sortBy, ...params,page: 1 }));
		} else {
		  dispatch(getInterviewList({ ...filters, orderBy, sortBy, ...params,page: 1 }));
		}
		// dispatch(setPage({ page: 0 }));
		setFieldValue(value.type, "");
		if(value.type==="search"){
		  setSearchValue("");
		  inputRef.current.value = "";
		}
	  };
	
	const debounceWithSearch = debounce(handleSearch, 500);

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

	return (
    <Container className="Interview-Schedule-Container Employee-wrapper" maxWidth="xl">
      <Stack
        direction="row"
        alignitems="center"
        justifyContent="space-between"
      >
         <div className="title-button-wrapper Interview-Schedule-title">
          <Typography variant="h4">
            Interview Schedules ({interviewCount})
            {/* <Typography variant="body2">
              Total Records : 
            </Typography> */}
          </Typography>
          
          {/* <Stack
            direction="row"
            alignitems="center"
            justifyContent="space-between"
          >
            {["right"].map((anchor) => (
              <React.Fragment key={anchor}>
                <span
                  className="filter-icon-part"
                  onClick={toggleDrawer(anchor, true)}
                >
                  <IconButton alignitems="right">
                    <Badge
                      color="error"
                      variant="dot"
                      invisible={filterChips.length !== 0 ? false : true}
                    >
                      <img src="/assets/images/filter.svg" title="Filters"/>
                    </Badge>
                  </IconButton>
                </span>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  style={{ zIndex: "1300" }}
                >
                  <Box
                    pt={4}
                    px={3}
                    sx={{
                      width:
                        anchor === "top" || anchor === "bottom" ? "auto" : 400,
                    }}
                    role="presentation"
                  >
                    <div className="content-filter-part">
                      <Stack
                        direction="row"
                        alignitems="center"
                        justifyContent="space-between"
                        mb={3}
                      >
                        <Typography variant="h6" style={{ marginTop: "5px" }}>
                          Interview Schedule filter
                        </Typography>
                        <span>
                          <IconButton onClick={toggleDrawer(anchor, false)}>
                            <CloseIcon />
                          </IconButton>
                        </span>
                      </Stack>
                      <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>

                          <Grid item xs={12}>
                            <InputLabel
                              id="demo-simple-select-helper-label"
                              style={{ marginBottom: "10px" }}
                            >
                              Interview Date
                            </InputLabel>
                            <LocalizationProvider
                              dateAdapter={AdapterDateFns}
                              item
                            >
                              <Stack
                                direction="row"
                                alignitems="center"
                                justifyContent="space-between"
                              >
                                <Grid>
                                  <DesktopDatePicker
                                    xs={6}
                                    label="From"
                                    style={{ width: "100%" }}
                                    value={values.interviewDateFrom}
                                    inputFormat="dd/MM/yyyy"
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        "interviewDateFrom",
                                        yearMonthDateFormat(newValue)
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        error={false}
                                        size="small"
                                        style={{
                                          width: "95%",
                                          marginRight: "10px",
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid>
                                  <DesktopDatePicker
                                    xs={6}
                                    label="To"
                                    value={values.interviewDateTo}
                                    inputFormat="dd/MM/yyyy"
                                    // minDate={new Date('2017-01-01')}
                                    onChange={(newValue) => {
                                      setFieldValue(
                                        "interviewDateTo",
                                        yearMonthDateFormat(newValue)
                                      );
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        fullWidth
                                        error={false}
                                        size="small"
                                        style={{ width: "100%" }}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Stack>
                            </LocalizationProvider>
                          </Grid>

                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              size="small"
                              fullWidth
                              options={technologyData.data || []}
                              getOptionLabel={(option) => option.title || ""}
                              name="technology"
                              onChange={(event, newValue) => {
                                let tempArray = newValue.map((x) => x.id);
                                setFieldValue(`technologyIds`, tempArray || "");
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Technology" />
                              )}
                              value={technologyData.data.filter((x) =>
                                values.technologyIds.includes(x.id)
                              )}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              size="small"
                              fullWidth
                              options={InterviewStatusData || []}
                              getOptionLabel={(option) => option.name || ""}
                              name="interviewStatusId"
                              onChange={(event, newValue) => {
                                let tempArray = newValue.map((x) => x.id);

                                setFieldValue(
                                  `interviewStatusId`,
                                  tempArray || ""
                                );
                              }}
                              renderInput={(params) => (
                                <TextField {...params} label="Interview Status" />
                              )}
                              
                              value={InterviewStatusData.filter((x) =>
                                values.interviewStatusId.includes(x.id)
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop: "20px" }}>
                          <InputLabel
                            id="demo-simple-select-helper-label"
                            style={{ marginBottom: "10px" }}
                          >
                            Experience
                          </InputLabel>
                          <Stack
                            direction="row"
                            alignitems="center"
                            justifyContent="space-between"
                          >
                            <TextField
                              label="From"
                              name="totalExperienceFrom"
                              variant="outlined"
                              size="small"
                              style={{
                                maxWidth: "100%",
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                              value={values.totalExperienceFrom}
                              onChange={handleChange}
                            />
                            <TextField
                              label="To"
                              name="totalExperienceTo"
                              variant="outlined"
                              size="small"
                              style={{ maxWidth: "100%" }}
                              value={values.totalExperienceTo}
                              onChange={handleChange}
                            />
                          </Stack>
                        </Grid>
                        <Stack direction="row" justifyContent="flex-end" mb={3}>
                          <Button
                            variant="contained"
                            startIcon={<SearchIcon />}
                            onClick={toggleDrawer(anchor, false)}
                            type="submit"
                          >
                            Search
                          </Button>
                          <Button
                            variant="contained"
                            type="reset"
                            onClick={handleReset}
                            startIcon={<CachedIcon />}
                            sx={{ marginLeft: "10px" }}
                          >
                            Reset
                          </Button>
                        </Stack>
                      </form>
                    </div>
                  </Box>
                </Drawer>
              </React.Fragment>
            ))}
          </Stack> */}
        </div>
      </Stack>

      {/* {
    "onHoldCount": 0,
  
} */}
      

      <Card className="employee-table-grid" pb={3}>
        <Container maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
              <Grid className="employee-classification" container spacing={2}>
                <div className="employee-classification-right">
                  <TextField
                    fullWidth
                    className="employee-search-detail"
                    label="Search by Name/Email/Contact/Interview Type"
                    name="search"
                    inputRef={inputRef}
                    variant="outlined"
                    size="small"
                    onChange={debounceWithSearch}
                  />
                  <div className="employee-classification-dropdown employee-classification-DatePicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Interview From"
                        value={values.interviewDateFrom || null}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          handleFilters("interviewDateFrom", yearMonthDateFormat(newValue))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={false}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="employee-classification-dropdown employee-classification-DatePicker">
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        label="Interview To"
                        value={values.interviewDateTo || null}
                        inputFormat="dd/MM/yyyy"
                        onChange={(newValue) => {
                          handleFilters("interviewDateTo", yearMonthDateFormat(newValue))
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            fullWidth
                            error={false}
                            size="small"
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      multiple
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={technologyData.data || []}
                      getOptionLabel={(option) => option.title || ""}
                      name="technology"
                      // onChange={(event, newValue) => {
                      //   let tempArray = newValue.map((x) => x.id);
                      //   setFieldValue(`technologyIds`, tempArray || "");
                      // }}
                      onChange={(event, newValue) => {
                        let tempArray = newValue.map((x) => x.id);
                        handleFilters("technologyIds",  tempArray || "")
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Technology" />
                      )}
                      value={technologyData.data.filter((x) =>
                        values.technologyIds.includes(x.id)
                      )}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <Autocomplete
                      multiple
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={InterviewStatusData || []}
                      getOptionLabel={(option) => option.name || ""}
                      name="interviewStatusId"
                      onChange={(event, newValue) => {
                        let tempArray = newValue.map((x) => x.id);
                        handleFilters("interviewStatusId",  tempArray || "")
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Interview Status" />
                      )}
                      value={InterviewStatusData.filter((x) =>
                        values.interviewStatusId.includes(x.id)
                      )}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <TextField
                      label="Experience From(Yrs)"
                      name="totalExperienceFrom"
                      variant="outlined"
                      size="small"
                      value={values.totalExperienceFrom}
                      // onChange={handleChange}
                      onChange={(event, newValue) => {
                        handleFilters("totalExperienceFrom",  event?.target?.value || "")
                      }}
                    />
                  </div>
                  <div className="employee-classification-dropdown">
                    <TextField
                      label="Experience To(Yrs)"
                      name="totalExperienceTo"
                      variant="outlined"
                      size="small"
                      value={values.totalExperienceTo}
                      // onChange={handleChange}
                      onChange={(event, newValue) => {
                        handleFilters("totalExperienceTo",  event?.target?.value || "")
                      }}
                    />
                  </div>
                </div>
              </Grid>
          </form>
        </Container>
      </Card>

      <Stack className="Interview-Schedule-tab" direction="row" mb={3}>
        <Typography variant="body2">
          Scheduled:{" "}
          {getAllInterviewCount?.scheduledCount
            ? getAllInterviewCount?.scheduledCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Interviewed:{" "}
          {getAllInterviewCount?.interviewedCount
            ? getAllInterviewCount?.interviewedCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Next Round:{" "}
          {getAllInterviewCount?.nextRoundCount
            ? getAllInterviewCount?.nextRoundCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Selected:{" "}
          {getAllInterviewCount?.selectedCount
            ? getAllInterviewCount?.selectedCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Offered:{" "}
          {getAllInterviewCount?.offeredCount
            ? getAllInterviewCount?.offeredCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Offer Accepted:{" "}
          {getAllInterviewCount?.offerAcceptedCount
            ? getAllInterviewCount?.offerAcceptedCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Offer Rejected:{" "}
          {getAllInterviewCount?.offerRejectedCount
            ? getAllInterviewCount?.offerRejectedCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Not Appeared:{" "}
          {getAllInterviewCount?.notAppearedCount
            ? getAllInterviewCount?.notAppearedCount
            : 0}
        </Typography>
        {/* <Typography variant="body2" style={{ paddingRight: "18px" }}>
					Rejected:{" "}
					{getAllInterviewCount?.rejectedCount
						? getAllInterviewCount?.rejectedCount
						: 0}
				</Typography>
				<Typography variant="body2" style={{ paddingRight: "18px" }}>
					Dropped:{" "}
					{getAllInterviewCount?.droppedCount
						? getAllInterviewCount?.droppedCount
						: 0}
				</Typography> */}
        <Typography variant="body2">
          On Hold:{" "}
          {getAllInterviewCount?.onHoldCount
            ? getAllInterviewCount?.onHoldCount
            : 0}
        </Typography>
        <Typography variant="body2">
          Blacklist:{" "}
          {getAllInterviewCount?.blacklistCount
            ? getAllInterviewCount?.blacklistCount
            : 0}
        </Typography>
      </Stack>

      <Stack
      className="search-filter"
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="search-filter-detail"
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      <Grid className="Interview-Schedule-grid" item xs={12} sm={12} md={12} mb={2}>
        {filterChips.length !== 0 || isSearching ? (
          <Typography className="record-found-text" variant="body2" sx={{ mb: 1 }}>
            {totalCount} records found
          </Typography>
        ) : (
          ""
        )}
        <Card>
          <Scrollbar>
            <TableContainer component={Paper} sx={{ minWidth: 800 }}>
              <Table className="Interview-Schedule-grid-table grid-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortBy === "candidateName"}
                        direction={sortBy === "candidateName" ? orderBy : "asc"}
                        onClick={() => handleSorting("candidateName")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      Email
                    </TableCell>
                    <TableCell align="left">
                      Contact
                    </TableCell>
                    <TableCell align="left">
                      Technology
                    </TableCell>
                    <TableCell align="left">
                      Experience(Yrs)
                    </TableCell>
                    <TableCell align="left" className="th-date th-sorting">
                      <TableSortLabel
                        active={sortBy === "interviewDate"}
                        direction={sortBy === "interviewDate" ? orderBy : "asc"}
                        onClick={() => handleSorting("interviewDate")}
                      >
                        Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">
                      Time
                    </TableCell>
                    {/* <TableCell align="left" width="20%">
												Interviewer
											</TableCell> */}
                    <TableCell align="left">
                      Type
                    </TableCell>
                    <TableCell align="left">
                      Status
                    </TableCell>
                    {/* <TableCell align="left" width="20%">
												Comments
											</TableCell> */}
                    <TableCell className="th-action-buttons" align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                {loading ? (
                  <TableCell align="center" width="100%" colSpan={10}>
                    <Loader />
                  </TableCell>
                ) : (
                  <TableBody>
                    {interviewList?.length > 0 ? 
                    (
                      // (limit > 0
                      //   ? interviewList.slice(
                      //       page * limit,
                      //       page * limit + limit
                      //     )
                      //   : interviewList
                      // )
                      interviewList.map((interview, idx) => (
                        <TableRow key={interview?.id}>
                          <TableCell align="left">
                            {interview?.candidateName}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.candidate?.email}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.candidate?.contactNumber}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.technology?.title}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.candidate?.totalExperience &&
                            interview?.candidate?.totalExperience != 0
                              ? `${interview?.candidate?.totalExperience} Yrs `
                              : "0 Yrs"}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.interviewDate ? indianDateFormat(interview?.interviewDate) : "-"}
                          </TableCell>
                          <TableCell align="left">
                            {interview?.interviewTime ? convertTo24HrsFormat(interview?.interviewTime) : "-"}
                          </TableCell>
                          {/* <TableCell align="left" width="20%">
														{formatInterviewerName(
															interview?.interviewrefrences,
														)}
													</TableCell> */}
                          <TableCell align="left">
                            {interview?.interviewType}
                          </TableCell>
                          <TableCell align="left">
                            {
                              interview?.candidate?.activity?.candidateState
                                ?.name
                            }
                          </TableCell>
                          {/* <TableCell align="left" width="20%">
														{interview?.comments}
													</TableCell> */}
                          <TableCell className="td-action-buttons" align="right">
                            <Stack className="action-button-group" direction="row">
                              {getEditVisible("interview") && (
                                <IconButton
                                  className="edit-icon-button"
                                  // component={RouterLink}
                                  // to={`/interviewlist/schedule-interview/${encryption(
                                  //   interview?.candidateId
                                  // )}`}
                                  onClick={(e) => window.open(`/interviewlist/schedule-interview/${encryption(
                                    interview?.candidateId
                                  )}`,"_blank") }
                                >
                                  {/* <EditIcon color="primary" /> */}
                                  <img src="assets/images/edit.svg" title="Edit"/>
                                </IconButton>
                              )}
                              <IconButton
                                className="view-icon-button"
                                // component={RouterLink}
                                // to={`/candidate/view/${encryption(
                                //   interview?.candidateId
                                // )}`}
                                onClick={(e) => window.open(`/candidate/view/${encryption(
                                  interview?.candidateId
                                )}`,"_blank") }
                              >
                                {/* <VisibilityIcon /> */}
                                <img src="assets/images/vieweye-icon.svg" title="View"/>
                              </IconButton>
                            </Stack>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell className="No-Record-text" colSpan={12} align="center">
                          No interview schedules
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomPagination
            totalPage= {totalPage}
            totalCount={totalCount}
            limit={limit}
            handleChangePage={handleChangePage}
            page={page > 0 ? page-1 : 0}
            rowsPerPageOptions={[10, 20, 40]}
            handleRowsPerPageChange={handleRowsPerPageChange}
            filter={filterChips.length !== 0 ? true : false}
          />
        </Card>
      </Grid>
    </Container>
  );
};

const componentConfig = {
	component: InterviewList,
	path: "/interviewlist",
	public: false,
	layout: DashboardLayout,
	group: "candidate",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
