import { useFormik } from "formik";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Autocomplete from "@mui/material/Autocomplete";
import DashboardLayout from "../../../layouts/dashboard";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import moment from "moment";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { encryption, decryption } from "../../../utils/encodeString";
import { getVendor } from "../../../redux/vendor/vendorThunk/index";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk/index";
import { getGeneratedAssetsForDropdown } from "../../../redux/assets/thunk/index";
import licenceSchema from "../../../validations/licenceSchema";
import { insertAssestLicence, getAssestLicenceById, updateAssestLicence } from "../../../redux/assestLicence/thunk/index"
import { getAddVisible, getEditVisible, getViewVisible } from "../../../utils/userPermission";
import Loader from "../../../components/Loader"; import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Modal from '@mui/material/Modal';
import IconButton from "@mui/material/IconButton";


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
};


const AddAssetLicence = () => {
  const [productKey1, setProductKey1] = useState('');
  const [showProductKey, setshowProductKey] = useState(false);
  const [open, setOpen] = useState(false);
  const [fildname, setFildname] = useState('');
  const[flagProductKey,setFlagProductKey] = useState(true)
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const VenderDropDown = useSelector((state) => state.vendor.dataForDropdown);
  const EmployeeDropDown = useSelector(
    (state) => state.employee.allEmployeeData
  );
  const generatedAssetsForDropdown = useSelector((state) => state.assets.generatedAssetsForDropdown)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  const [load, setLoad] = useState(false)

  // if (!getAddVisible("licenceMaster") || !getEditVisible("licenceMaster")) {
  //   navigate("/dashboard");
  // }
  const formik = useFormik({
    initialValues: {
      productName: "",
      vendorId: "",
      productKey: "",
      assignedEmployees: [],
      licencePeriod: "",
      purchasedDate: "",
      assignedDate: "",
      expiryDate: "",
      notes: "",
      assetId: "",
    },
    validationSchema: licenceSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        values.vendorId = values.vendorId !== "" ? values.vendorId : null;
        values.assignedEmployees =
          values.assignedEmployees.length !== 0
            ? values.assignedEmployees
            : null;
        values.notes = values.notes !== "" ? values.notes : null;
        values.assetId = values.assetId !== "" ? values.assetId : null;

        values.purchasedDate = moment(values.purchasedDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        values.assignedDate = values.assignedDate
          ? moment(values.assignedDate).format("YYYY-MM-DD HH:mm:ss")
          : null;
        values.expiryDate = values.expiryDate
          ? moment(values.expiryDate).format("YYYY-MM-DD HH:mm:ss")
          : null;

        values.productKey = values.productKey
          ? encryption(values.productKey)
          : null;
        if (!id) {
          // console.log("value",values)
          await dispatch(insertAssestLicence(values)).unwrap();
        }
        if (id) {
          id = +decryption(id);

          await dispatch(
            updateAssestLicence({ value: { ...values, id: id }, id })
          ).unwrap();
        }

        resetForm();
        navigate("/licence-master");
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    values,
    handleChange,
    setFieldValue,
    touched,
    errors,
    handleSubmit,
    getFieldProps,
    handleBlur,

    setValues,
  } = formik;
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          setLoad(true);
          const ParamId = decryption(id);
          if (ParamId === -1) {
            navigate('/licence-master');
            return;
          }

          const editData = await dispatch(getAssestLicenceById(ParamId)).unwrap();
          if (editData.success) {
            const body = {
              productName: editData.data.productName || "",
              vendorId: editData.data.vendorId || "",
              productKey: editData.data.productKey ? decryption(editData.data.productKey) : "",
              assignedEmployees: editData.data.assignedEmployees || [],
              licencePeriod: editData.data.licencePeriod || "",
              purchasedDate: editData.data.purchasedDate || "",
              assignedDate: editData.data.assignedDate || "",
              expiryDate: editData.data.expiryDate || "",
              notes: editData.data.notes || "",
              assetId: editData.data.assetId || "",
            };

            if (!body.productKey) {
              setFlagProductKey(false);
            }

            setValues(body);
          }
          setLoad(false);
        }
      } catch (error) {
        navigate('/licence-master');
        toast.error(error.message);
        setLoad(false); // Ensure that loading state is reset on error
      }
    };

    fetchData();
  }, [dispatch, setValues]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getGeneratedAssetsForDropdown()).unwrap();
        await dispatch(getEmployeeForDropDown()).unwrap();
        
        if (getViewVisible("vendors")) {
          await dispatch(getVendor()).unwrap();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // toast.error(error.message);
      }
    };

    fetchData();
  }, []);
  const handleProductKey = () => {
    if (!id) {
      setshowProductKey(!showProductKey);
    }
    else {
      if (showProductKey === false) {
        setFildname(`productKey`);
        handleOpen();
      }
      else {
        setshowProductKey(!showProductKey);
      }
    }
  };
  let handleVerify = () => {
    if (fildname === 'productKey') {
      if (productKey1 === '1234') {
        setshowProductKey(!showProductKey);
        handleClose();
      } else {
        toast.error('Wrong Password');
        handleClose();
      }
    }
  }
  if (load) {
    return (
      <Loader />
    )
  }
  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
           <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            {id ? "Edit Asset Licence" : " Add Asset Licence"}
          </Typography>
        
          <Stack direction="row" alignItems="center">
            <Button type="submit" variant="contained">
              Save
            </Button>
            <Button
              variant="contained"
              component={RouterLink}
              to="/licence-master"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          </Stack>
            </div>
        </Stack>
        <Card className="chips-card">
          <Container maxWidth="xl">
            <Typography variant="h6" my={3}>
              Basic Information
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Product Name"
                  name="productName"
                  variant="outlined"
                  size="small"
                  // inputProps={{ maxLength: 64 }}
                  autoComplete="off"
                  value={values.productName}
                  onChange={handleChange}
                  error={Boolean(touched.productName && errors.productName)}
                  helperText={touched.productName && errors.productName}
                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Purchased Date"
                    value={values.purchasedDate}
                    maxDate={new Date()}
                    inputFormat="dd/MM/yyyy"
                    // minDate={new Date('2017-01-01')}
                    autoComplete="off"
                    onChange={(newValue) => {
                      setFieldValue("purchasedDate", newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={Boolean(
                          touched?.purchasedDate && errors?.purchasedDate
                        )}
                        helperText={
                          touched?.purchasedDate && errors?.purchasedDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  disabled={getViewVisible("vendors")===false}
                  label="Vendor"
                  size="small"
                  name="vendorId"
                  autoComplete="off"
                  options={VenderDropDown || []}
                  {...getFieldProps("vendorId")}
                  getOptionLabel={(option) =>
                    `${option?.firstName || ""} ${option?.lastName || ""
                      }`.trim()
                  }
                  onChange={(event, newValue) => {
                    setFieldValue("vendorId", newValue?.id || "");
                  }}
                  value={
                    VenderDropDown?.find((vendor) => {
                      return vendor.id == values.vendorId;
                    }) || ""
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Vendor"
                      
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type={showProductKey ? "text" : "password"}
                  label="Product key"
                  name="productKey"
                  value={values.productKey}
                  onChange={handleChange}
                  variant="outlined"
                  disabled={(id && showProductKey === false  && flagProductKey) ? true : false}
                  size="small"
                  onBlur={handleBlur}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleProductKey} edge="end">
                          {showProductKey ? (
                            <VisibilityOffIcon />
                          ) : (
                            <VisibilityIcon />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Licence Period"
                  name="licencePeriod"
                  variant="outlined"
                  autoComplete="off"
                  size="small"
                  // inputProps={{ maxLength: 64 }}
                  value={values.licencePeriod}
                  onChange={handleChange}
                  error={Boolean(touched.licencePeriod && errors.licencePeriod)}
                  helperText={touched.licencePeriod && errors.licencePeriod}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  placeholder="Notes"
                  multiline
                  rows={3}
                  rowsmax={6}
                  name="notes"
                  // inputProps={{ maxLength: 64 }}
                  autoComplete="off"
                  value={values.notes}
                  onChange={handleChange}
                  error={Boolean(touched.notes && errors.notes)}
                  helperText={touched.notes && errors.notes}
                />
              </Grid>
            </Grid>
            <Typography variant="h6" my={3}>
              Assignment Details
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  autoComplete="off"
                  size="small"
                  fullWidth
                  options={EmployeeDropDown || []}
                  key={EmployeeDropDown || []}
                  onChange={(event, newValue) => {
                    let tempArray = newValue.map((x) => x.id);
                    setFieldValue("assignedEmployees", tempArray || []);
                  }}
                  getOptionLabel={(option) =>
                    `${option?.firstName || ""} ${option?.lastName || ""}`.trim()
                  }
                  value={EmployeeDropDown.filter((x) =>
                    values.assignedEmployees.includes(x.id)
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Assigned To"
                    
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  size="small"
                  fullWidth
                  options={generatedAssetsForDropdown || []}
                  // getOptionLabel={(option) =>
                  //   option.assetEmployee && option.assetEmployee.employee !== null
                  //     ? option.assetUniqueId + " - " + option.assetEmployee.employee.firstName +
                  //     " " +
                  //     option.assetEmployee.employee.lastName +
                  //     " - " +
                  //     option.generatedAssetItems 
                  //     : option?.assetUniqueId + " - " + option?.generatedAssetItems || ""
                  // }
                  getOptionLabel={(option) =>
                    {
                      if(option?.assetEmployee && option?.assetEmployee?.employee != null)
                      {
                        return option?.assetUniqueId +
                        " - " +
                        option?.assetEmployee?.employee.firstName +
                        " " +
                        option?.assetEmployee?.employee.lastName +
                        " - " +
                        option.name +
                        " - " +
                        option.generatedAssetItems
                      }
                      else if(option.assetEmployee && option.assetEmployee.employee == null)
                      {
                        return option?.assetUniqueId + " - " + 
                        option?.name + " - "  + 
                        option?.generatedAssetItems 
                      }
                      else {
                        return "";
                      }
                    }}
                    {...getFieldProps(`assetId`)}
                    onChange={(event, newValue) => { 
                      setFieldValue('assetName', newValue ? newValue?.assetUniqueId + "-" + newValue?.name + " - " + newValue.generatedAssetItems :  "");
                      setFieldValue("assetId", newValue?.id || "")
                    }}
                    value={generatedAssetsForDropdown?.find(
                      (a) =>
                      a.id == values.assetId
                    ) || ""}
                    
                  // onChange={(event, newValue) => {
                  //   setFieldValue("assetId", newValue?.id || "");
                  // }}
                  // value={
                  //   generatedAssetsForDropdown?.find((asset) => {
                  //     return asset.id === values.assetId;
                  //   } ) || ""
                  // } 
                //   renderOption={(props, option) => (
                //     <li {...props} value={option.id} key={option.id}>
                //       { option.assetEmployee && option.assetEmployee.employee !== null
                //       ? option.assetUniqueId + " - " + option.assetEmployee.employee.firstName +
                //       " " +
                //       option.assetEmployee.employee.lastName +
                //       " - " +
                //       option.generatedAssetItems 
                //       : option?.assetUniqueId + " - " + option?.generatedAssetItems || ""}
                //     </li>
                //   )
                // }
                renderOption={(props, option) => (
                  <li {...props} value={option.id} key={option.id}>
                    { option.assetEmployee && option.assetEmployee.employee != null
                  ? option?.assetUniqueId +
                    " - " +
                    option?.assetEmployee?.employee.firstName +
                    " " +
                    option?.assetEmployee?.employee.lastName +
                    " - " +
                    option.name +
                    " - " +
                    option?.generatedAssetItems
                  : option?.assetUniqueId + " - " + option?.name + " - " + option?.generatedAssetItems || ""}
                  </li>
                )
              }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Associated Asset"
                    />
                  )}

                />
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Assigned Date"
                    value={values.assignedDate}
                    maxDate={new Date()}
                    inputFormat="dd/MM/yyyy"
                    autoComplete="off"
                    onChange={(newValue) => {
                      setFieldValue("assignedDate", newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={Boolean(
                          touched?.assignedDate && errors?.assignedDate
                        )}
                        helperText={
                          touched?.assignedDate && errors?.assignedDate
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DesktopDatePicker
                    label="Expiry Date"
                    autoComplete="off"
                    value={values.expiryDate}
                    inputFormat="dd/MM/yyyy"
                    onChange={(newValue) => {
                      setFieldValue("expiryDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        size="small"
                        error={Boolean(
                          touched?.expiryDate && errors?.expiryDate
                        )}
                        helperText={touched?.expiryDate && errors?.expiryDate}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Container>
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Stack sx={style}>
            <TextField
              fullWidth
              autoComplete="off"
              label="Varification Code"
              onChange={(e) => setProductKey1(e.target.value)}
              variant="outlined"
              size="small"
            />
            <Button variant="contained" onClick={handleVerify}>Submit</Button>
          </Stack>
        </Modal>
      </form>
    </Container>
  );
};

const componentConfig = [{
  component: AddAssetLicence,
  path: "/licence-master/add",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
},
{
  component: AddAssetLicence,
  path: "/licence-master/add/:id",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
}
]

export default componentConfig;