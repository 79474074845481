import React from 'react';
import DashboardLayout from '../../layouts/dashboard';

function ManageProject() {
	return <div>Manage project view</div>;
}

const componentConfig = {
	component: ManageProject,
	path: '/manage-project',
	public: false,
	layout: DashboardLayout,
	group: 'organization',
	sidebar: true,
	role: ['admin'],
};

export default componentConfig;
