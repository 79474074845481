import React from 'react';
import { indianDateFormat } from '../../../../utils/dateFormat';

function PFForm({ values }) {
	return (
		<div
			style={{
				minWidth: '586.5pt',
				maxWidth: '586.5pt',
				minHeight: '830pt',
				maxHeight: '830pt',
			}}
		>
			<table
				style={{ width: '100%', textAlign: 'center', marginLeft: 285, marginRight: 300 }}
			>
				<tr>
					<td>
						<img
							src="/assets/images/cc-logo-3.png"
							alt="cc-logo"
							style={{ width: 200 }}
						/>
					</td>
				</tr>
			</table>
			<hr />
			<table style={{ width: '100%' }}>
				<tr>
					<td
						style={{
							textAlign: 'center',
							fontSize: 18,
							fontWeight: '700',
							textTransform: 'uppercase',
						}}
					>
						PF&nbsp;&nbsp;DETAILS
					</td>
				</tr>
			</table>

			<div>
				<table style={{ fontSize: '12px' }}>
					<tr>
						<td>Employee Full Name</td>
						<td>:</td>
						<td>{values.name}</td>
					</tr>
					<tr>
						<td>Date Of Birth</td>
						<td>:</td>
						<td>{indianDateFormat(values.dob)}</td>
					</tr>
					<tr>
						<td>Present Address</td>
						<td>:</td>
						<td>{values.presentAddress}</td>
					</tr>
					<tr>
						<td>Permanent Address</td>
						<td>:</td>
						<td>{values.permanentAddress}</td>
					</tr>
					<tr>
						<td>Mobile Number</td>
						<td>:</td>
						<td>{values.mobileNumber}</td>
					</tr>
					<tr>
						<td>Email ID</td>
						<td>:</td>
						<td>{values.email}</td>
					</tr>
					<tr>
						<td>Martial Status</td>
						<td>:</td>
						<td>{values.maritalStatus}</td>
					</tr>
					<tr>
						<td>Dispensary</td>
						<td>:</td>
						<td></td>
					</tr>
					<tr>
						<td>Father's Name & DOB</td>
						<td>:</td>
						<td>{`${values.fatherName} ${values.fatherDOB}`}</td>
					</tr>
					<tr>
						<td>Mother's Name & DOB</td>
						<td>:</td>
						<td>{`${values.motherName} ${indianDateFormat(values.motherDOB)}`}</td>
					</tr>
					<tr>
						<td>Husband's Name & DOB</td>
						<td>:</td>
						<td>{`${values.husbandName} ${indianDateFormat(values.husbandDOB)}`}</td>
					</tr>
					<tr>
						<td>Bank Name</td>
						<td>:</td>
						<td>{values.bankName}</td>
					</tr>
					<tr>
						<td>Banck A/c Number</td>
						<td>:</td>
						<td>{values.bankAccountNumber}</td>
					</tr>
					<tr>
						<td>IFSC Code</td>
						<td>:</td>
						<td>{values.IFSC}</td>
					</tr>
					<tr>
						<td>Pan Card Number</td>
						<td>:</td>
						<td>{values.panNumber}</td>
					</tr>
					<tr>
						<td>Aadhar Card Number</td>
						<td>:</td>
						<td>{values.aadharNumber}</td>
					</tr>
					<tr>
						<td>Election Card Number</td>
						<td>:</td>
						<td>{values.electionCardNumber}</td>
					</tr>
					<tr>
						<td>License Number /Exp. Date</td>
						<td>:</td>
						<td>{`${values.licenseNumber} ${indianDateFormat(
							values.licenseValidityTo
						)}`}</td>
					</tr>
					<tr>
						<td>Ration Card Number</td>
						<td>:</td>
						<td>{values.rationCardNumbe}</td>
					</tr>
					<tr>
						<td>Passport Number /Exp. Date</td>
						<td>:</td>
						<td>{`${values.passportNumber} ${indianDateFormat(
							values.passportValidityTo
						)}`}</td>
					</tr>
				</table>
			</div>
			<hr style={{ marginTop: 20 }} />
		</div>
	);
}

export default PFForm;
