import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";

import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import TablePagination from "@mui/material/TablePagination";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";

import { department } from "../../../validations/mastersSchema";
import {
	getDepartment,
	updateById,
	deleteById,
	insert,
} from "../../../redux/department/departmentThunk";
import {
	getProjectManagerData,
	getTeamLeadData,
} from "../../../redux/employee/employeeThunk";
import {
	setSortBy,
	setOrderBy,
	setCurrentPage,
	setLimit,
} from "../../../redux/department/departmentSlice";
import invertDirection from "../../../utils/invertDirection";
import CustomPagination from "../../../components/Pagination";

import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../../utils/encodeString";
import localStorage from "../../../service/localStorage";

function Index() {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("department")) {
			dispatch(
				getDepartment({ sortBy, orderBy, page: currentPage + 1, limit: limit }),
			);
			navigate("/dashboard");
		}
	}, []);
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			localStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=localStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	const {
		data,
		loading,
		sortBy,
		orderBy,
		totalRecords,
		limit,
		currentPage,
		totalPages,
	} = useSelector((state) => state.department);
	/*const data = useSelector(
		(state) => state.department.data.list,
	);*/
	const teamLeadStatus = useSelector(
		(state) => state.employee.teamLeadDataStatus,
	);
	const teamLeadData = useSelector((state) => state.employee.teamLeadData);
	const projectManagerStatus = useSelector(
		(state) => state.employee.projectManagerDataStatus,
	);
	const projectManagerData = useSelector(
		(state) => state.employee.projectManagerData,
	);

	const [id, setId] = React.useState(null);
	const formik = useFormik({
		initialValues: {
			title: "",
			isActive: false,
			teamLead: [],
			projectManager: [],
		},
		validationSchema: department,
		onSubmit: async (values, { resetForm }) => {
			try {
				if (id) {
					await dispatch(updateById({ ...values, id })).unwrap();
				}
				if (!id) {
					await dispatch(insert(values)).unwrap();
				}
				resetForm();
				dispatch(getDepartment({ sortBy, orderBy }));
				return setId(null);
			} catch (error) {
				toast.error(error.message);
			}
		},
	});
	const {
		errors,
		values,
		touched,
		handleChange,
		handleSubmit,
		setValues,
		setFieldValue,
		isSubmitting,
		getFieldProps,
	} = formik;
	React.useEffect(() => {
		dispatch(
			getDepartment({ sortBy, orderBy, page: currentPage + 1, limit: limit }),
		);
	}, [dispatch, sortBy, orderBy, limit, currentPage]);

	useEffect(() => {
		if (teamLeadStatus === "idle") {
			dispatch(getTeamLeadData());
		}
	}, [dispatch, teamLeadStatus]);

	useEffect(() => {
		if (projectManagerStatus === "idle") {
			dispatch(getProjectManagerData());
		}
	}, [dispatch, projectManagerStatus]);

	const handleUpdate = (data) => {
		setValues({
			title: data.title,
			isActive: data.isActive,
			teamLead: data.teamLead,
			projectManager: data.projectManager,
		});
		setId(data.id);
	};

	const handleDelete = async (id, title) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${title}?`,
			});
			await dispatch(deleteById(id));
			await dispatch(getDepartment({ sortBy, orderBy }));
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleToggle = async (department) => {
		try {
			await dispatch(
				updateById({
					id: department.id,
					title: department.title,
					isActive: !department.isActive,
				}),
			).unwrap();
			dispatch(getDepartment());
		} catch (error) {
			toast.error(error.message);
		}
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) }),
		);
	};

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage({ page: newPage }));
	};
	const handleChangeRowsPerPage = (event) => {
		dispatch(setCurrentPage({ page: 0 }));
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
	};
	return (
		<Container maxWidth="xl">
		<div className="title-button-wrapper">
			<Typography variant="h4" gutterBottom mb={3}>
				Department
				<Typography variant="body2">Total Records : {totalRecords}</Typography>
			</Typography>
			</div>

			{getAddVisible("department") && (
				<Card>
					<Container maxWidth="xl">
						<form
							autoComplete="off"
							noValidate
							onSubmit={handleSubmit}
							className="custom-space-layout"
						>
							<Grid
								container
								spacing={3}
								py={3}
								style={{ paddingBottom: "13px", paddingTop: "17px" }}
							>
								<Grid item xs={3}>
									<TextField
										id="outlined-basic"
										autoComplete="off"
										label="Enter Department Title"
										variant="outlined"
										size="small"
										name="title"
										inputProps={{
											"data-cy": "txt-title-department",
										}}
										FormHelperTextProps={{
											"data-cy": "txt-title-err-department",
										}}
										value={values.title}
										onChange={handleChange}
										error={Boolean(touched.title && errors.title)}
										helperText={touched.title && errors.title}
										fullWidth
									/>
								</Grid>
								<Grid item xs={3}>
									{/*<InputLabel id="demo-simple-select-helper-label">
                    Team Lead
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Team Lead"
                    size="small"
                    multiple
                    {...getFieldProps(`teamLead`)}
                    inputProps={{
                      "data-cy": "txt-team-lead-department",
                    }}
                  >
                    {teamLeadData &&
                      teamLeadData.map((teamLead) => {
                        return (
                          <MenuItem key={teamLead.id} value={teamLead.id}>
                            {`${teamLead.firstName} ${teamLead.lastName}`}
                          </MenuItem>
                        );
                      })}
                    </Select>*/}
									{/*}	<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										defaultValue=""
										fullWidth
										multiple
										options={teamLeadData || []}
										getOptionLabel={(option) =>
											option ? `${option?.firstName} ${option?.lastName}` : ""
										}
										{...getFieldProps(`teamLead`)}
										onChange={(event, newValue) => {
											setFieldValue(`teamLead`, newValue?.id || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Team Lead"
												error={Boolean(touched.teamLead && errors.teamLead)}
												helperText={touched.teamLead && errors.teamLead}
											/>
										)}
										value={
											teamLeadData.find((lead) => lead.id == values.teamLead) ||
											""
										}
										error={true}
									/> */}

									<Autocomplete
										multiple
										size="small"
										fullWidth
										options={teamLeadData || []}
										key={teamLeadData || []}
										getOptionLabel={(option) =>
											option ? `${option?.firstName} ${option?.lastName}` : ""
										}
										{...getFieldProps(`teamLead`)}
										onChange={(event, newValue) => {
											let tempArray = newValue.map((x) => x.id);
											setFieldValue(`teamLead`, tempArray || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Team Lead"
												autoComplete="off"
												error={Boolean(touched.teamLead && errors.teamLead)}
												helperText={touched.teamLead && errors.teamLead}
											/>
										)}
										value={teamLeadData.filter((x) =>
											values.teamLead.includes(x.id),
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									{/*<InputLabel id="demo-simple-select-helper-label">
                    Project Manager
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Project Manager"
                    size="small"
                    multiple
                    {...getFieldProps(`projectManager`)}
                    inputProps={{
                      "data-cy": "txt-project-manager-department",
                    }}
                  >
                    {projectManagerData &&
                      projectManagerData.map((projectManager) => {
                        return (
                          <MenuItem
                            key={projectManager.id}
                            value={projectManager.id}
                          >
                            {`${projectManager.firstName} ${projectManager.lastName}`}
                          </MenuItem>
                        );
                      })}
                    </Select> */}

									{/*<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										size="small"
										defaultValue=""
										fullWidth
										options={projectManagerData || []}
										getOptionLabel={(option) =>
											option ? `${option?.firstName} ${option?.lastName}` : ""
										}
										{...getFieldProps(`projectManager`)}
										onChange={(event, newValue) => {
											setFieldValue(`projectManager`, newValue?.id || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Project Manager"
												error={Boolean(
													touched.projectManager && errors.projectManager,
												)}
												helperText={
													touched.projectManager && errors.projectManager
												}
											/>
										)}
										value={
											projectManagerData.find(
												(pm) => pm.id == values.projectManager,
											) || ""
										}
										error={true}
									/>*/}

									<Autocomplete
										multiple
										size="small"
										fullWidth
										options={projectManagerData || []}
										key={projectManagerData || []}
										getOptionLabel={(option) =>
											option ? `${option?.firstName} ${option?.lastName}` : ""
										}
										{...getFieldProps(`projectManager`)}
										onChange={(event, newValue) => {
											let tempArray = newValue.map((x) => x.id);
											setFieldValue(`projectManager`, tempArray || "");
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Project Manager"
												autoComplete="off"
												error={Boolean(
													touched.projectManager && errors.projectManager,
												)}
												helperText={
													touched.projectManager && errors.projectManager
												}
											/>
										)}
										value={projectManagerData.filter((x) =>
											values.projectManager.includes(x.id),
										)}
									/>
								</Grid>
								<Grid item xs={3}>
									<Stack
										direction="row"
										alignItems="center"
										justifyContent="space-between"
									>
										<Stack className="checkbox-part">
											<FormControlLabel
												control={
													<Checkbox
														checked={formik.values.isActive}
														name="isActive"
														onChange={formik.handleChange}
														inputProps={{
															"data-cy": "chk-isActive-department",
														}}
													/>
												}
												label="Status"
											/>
										</Stack>
										<Stack className="button-part">
											<LoadingButton
												size="small"
												type="submit"
												variant="contained"
												loading={isSubmitting}
												data-cy="btn-submit-department"
											>
												save
											</LoadingButton>
										</Stack>
									</Stack>
								</Grid>
							</Grid>
							{/* <div className="heading-part"></div> */}
						</form>
					</Container>
				</Card>
			)}

			<Card className="custom-grid">
				<Scrollbar>
					<TableContainer component={Paper} sx={{ minWidth: 800 }}>
						{loading ? (
							<TableRow style = {{display : 'flex',flexDirection : 'row', justifyContent : 'center'}}>
							<TableCell align="center"  colSpan = {3} >
							<Loader  />
						   </TableCell>
						   </TableRow>
						) : (
							<Card>
								<CustomPagination
									totalPage={totalPages}
									totalCount={totalRecords}
									limit={limit}
									handleChangePage={handleChangePage}
									page={currentPage}
									rowsPerPageOptions={[10, 20, 40]}
									handleRowsPerPageChange={handleChangeRowsPerPage}
									//filter={isSearchQuery ? true : false}
								/>
								<Table aria-label="simple table">
									<TableHead>
										<TableRow>
											{/* <TableCell width="20%">
											<TableSortLabel
												active={sortBy === "id"}
												direction={sortBy === "id" ? orderBy : "asc"}
												onClick={() => handleSorting("id")}
											>
												ID
											</TableSortLabel>
										</TableCell> */}
											<TableCell width="30%">
												<TableSortLabel
													active={sortBy === "title"}
													direction={sortBy === "title" ? orderBy : "asc"}
													onClick={() => handleSorting("title")}
												>
													Title
												</TableSortLabel>
											</TableCell>
											{/* <TableCell width="25%">
                      <TableSortLabel>Team Lead</TableSortLabel>
                    </TableCell> */}
											{/* <TableCell width="25%">
                      <TableSortLabel> Project Manager</TableSortLabel>
                    </TableCell> */}
											<TableCell width="20%" align="center">
												Status
											</TableCell>
											{(getEditVisible("department") ||
												getDeleteVisible("department")) && (
												<TableCell
													width="10%"
													align="right"
													className="action-field"
												>
													Actions
												</TableCell>
											)}
										</TableRow>
									</TableHead>
									<TableBody>
										{Array.isArray(data) && data.length === 0 ? (
											<TableRow>
												<TableCell className="No-Record-text" align="center" colSpan={3}>
												No Record(s) Found
												</TableCell>
											</TableRow>
										) : (
											data?.map((department, idx) => (
												<TableRow
													key={idx}
													sx={{
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													{/* <TableCell component="th" scope="row">
													{department.id}
												</TableCell> */}
													<TableCell component="th" scope="row">
														{department.title}
													</TableCell>
													{/* <TableCell component="th" scope="row">
                          {department.title}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {department.title}
                        </TableCell> */}
													<TableCell align="center">
														<Switch
															checked={department.isActive}
															onChange={() => handleToggle(department)}
															inputProps={{ "aria-label": "controlled" }}
															size="small"
														/>
													</TableCell>
													{(getEditVisible("department") ||
														getDeleteVisible("department")) && (
														<TableCell align="right">
															{getEditVisible("department") && (
																<IconButton
																	data-cy="btn-edit-holiday"
																	onClick={() => handleUpdate(department)}
																>
																	<EditIcon color="primary" />
																</IconButton>
															)}
															{getDeleteVisible("department") && (
																<IconButton
																	data-cy="btn-delete-holiday"
																	onClick={() =>
																		handleDelete(
																			department.id,
																			department.title,
																		)
																	}
																>
																	<DeleteIcon color="error" />
																</IconButton>
															)}
														</TableCell>
													)}
												</TableRow>
											))
										)}
									</TableBody>
								</Table>
							</Card>
						)}
					</TableContainer>
				</Scrollbar>
			</Card>
		</Container>
	);
}

export default Index;
