import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import DashboardLayout from "../../../layouts/dashboard";

import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Autocomplete from "@mui/material/Autocomplete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import LoadingButton from "@mui/lab/LoadingButton";
import projectSchema from "../../../validations/projectSchema";
import { decryption, encryption } from "../../../utils/encodeString";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import { getInternForDropdown } from "../../../redux/intern/internThunk";
import {
  getProjectById,
  createProject,
  updateProjectById,
} from "../../../redux/project/projectThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { makeStyles } from "@material-ui/core";
import { yearMonthDateFormat,yearFormat } from "../../../utils/dateFormat";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));
function Addproject() {
  let { id } = useParams();
  if (id) id = decryption(id);
  const projectInfoFileInput = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const classes = useStyles();
  // if (!getAddVisible("drives") || !getEditVisible("drives")) {
  // 	navigate("/dashboard");
  // }
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      console.log(res.data, "db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  useEffect(() => {
    if (id === -1) {
      navigate("/project");
    }
  }, [dispatch, id]);
  const formik = useFormik({
    initialValues: {
      technologyId: "",
      projectName: "",
      description: "",
      projectManagerId: "",
      year:new Date().getFullYear().toString(),
      projectDefination: "",
      toBeDeletedProjectDefination: "",
      interns: [],
    },
    validationSchema: projectSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const techId = data.filter((tech) => {
          if (tech.title == values.technologyId) {
            return tech.id;
          }
        });
        if (values?.interns && values?.interns.length > 0) {
          values.interns = values.interns.map((i) => {
            return { internId: i };
          });
        }
        const body = { ...values, technologyId: techId[0].id };
        const formData = new FormData();
        // for (let key in body) {
        // 	formData.append(key, body[key]);
        // }

        formData.set("technologyId", JSON.stringify(techId[0].id));
        formData.set("projectName", values.projectName);
        formData.set("description", values.description.trim());
        formData.set(
          "projectManagerId",
          JSON.stringify(values.projectManagerId)
        );
        formData.set("year", values.year);
        formData.set("projectDefination", values.projectDefination);
        formData.set("interns", JSON.stringify(values.interns));
        // console.log(body)
        if (id) {
           await dispatch(updateProjectById({ id, body: formData })).unwrap();
        }
        if (!id) {
          await dispatch(createProject(formData)).unwrap();
        }
        navigate("/project");
        resetForm();
      } catch (error) {
        // toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    setValues,
    values,
    getFieldProps,
    setFieldValue,
    handleChange,
    errors,
    touched,
    isSubmitting,
  } = formik;

  useEffect(() => {
    if (id) {
      const projectData = dispatch(getProjectById({ id })).unwrap();
      projectData
        .then((values) => {
          const val = values.data;
          let body = {
            technologyId: values.data.technology.title,
            projectName: values.data.projectName,
            description: values.data.description,
            projectManagerId: values.data.projectManagerId,
            year: values.data.year,
            projectDefination: values.data.projectDefination.length > 0
              ? values.data.projectDefination[0]
              : "", //? values.data.projectDefination[0] : ""
            interns: values.data.interns
              ? values.data.interns.map((i) => {
                  return i.intern.id;
                })
              : [],
          };
          // console.log(values.data.interns.map((i) => {return i.id}))
          setValues(body);
          setLoading(false);
        })
        .catch(() => {
          navigate("/project");
        });
    } else {
      setLoading(false);
    }
  }, [dispatch, setValues, id, navigate]);

  useEffect(() => {
    dispatch(getEmployeeForDropDown({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  // useEffect(() => {
  // 	dispatch(
  // 		getInternForDropdown({id: values.technologyId}),
  // 	);
  // }, [dispatch]);
  const { data } = useSelector((state) => state.technology);
  const employeeData = useSelector((state) => state.employee.allEmployeeData);
  let internData = useSelector((state) => state.intern.internForDropdown);
  let tempInternData = internData;
  // internData = {internData.length > 0 ? [{id: 0, firstName: "Select", lastName:"All"}, ...internData] : "" };
  if (internData?.length > 1) {
    internData = [
      { id: 0, firstName: "Select", lastName: "All" },
      ...internData,
    ];
  }
  const handleProjectDescChange = (e) => {
    setFieldValue("projectDefination", e.target.files[0]);
  };

  const handleDeleteProjectDesc = (image) => {
    setFieldValue("projectDefination", "");
    setFieldValue("toBeDeletedProjectDefination", image?.id);
  };

  const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["jpeg", "jpg", "png"];
      return imageTypes.includes(file.name.split(".")[1]);
    }
    return file && file["type"] && file["type"].split("/")[0] === "image";
  };

  const getFileName = (file) => {

    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };
  // const handleTechnologyChange =(newValue) => {
  useEffect(() => {
    // setFieldValue(`technologyId`, values.technologyId || "");
    const techId = data.filter((tech) => {
      if (tech.title == values.technologyId) {
        return tech.id;
      }
    });

    getViewVisible("interns") &&
      dispatch(
        getInternForDropdown({
          technologyId: techId[0]?.id ? techId[0]?.id : "",
          projectId: id ? id : "",
          batch: values?.year,
        })
      );
  }, [dispatch, values.technologyId, values.year]);


  if (loading) {
    return <p>Loading</p>;
  }

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={2}
        >
          <div className="title-button-wrapper">
            <Typography variant="h4" gutterBottom>
              {id ? "Edit Project" : "Add Project"}
            </Typography>
            
            <Stack direction="row" alignItems="center">
              {/* <Button type="submit" variant="contained">
                Save
              </Button> */}
              <LoadingButton
                type="submit"
                loading={isSubmitting}
                variant="contained"
              >
                Save
              </LoadingButton>
              <Button
                variant="contained"
                component={RouterLink}
                to="/project"
                startIcon={<ArrowBackIcon />}
                style={{ minWidth: "140px" , marginLeft: "10px" }}
              >
                Back To List
              </Button>
            </Stack>
          </div>
        </Stack>
        <Card className="holiday-table-grid" mb={5}>
          <Container maxWidth="xl">
            <Paper>
              <Grid container spacing={1}>
                {/* <Grid item xs={4} mt={3}>
									<MultiSelect
										items={internData}
										// getOptionDisabled={getOptionDisabled}
										// getOptionLabel={(option)=>}
										label="Select complex values"
										placeholder="Placeholder for textbox"
										selectAllLabel="Select all"
										onChange={setValue}
									/>
								</Grid> */}
                <Grid item xs={4} mt={3}>
                  <TextField
                    label="Project Name"
                    autoComplete="off"
                    name="projectName"
                    value={values.projectName}
                    onChange={handleChange}
                    inputProps={{ maxLength: 64 }}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={Boolean(touched.projectName && errors.projectName)}
                    helperText={touched.projectName && errors.projectName}
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  {/*<InputLabel id="demo-simple-select-helper-label">
                                            Technology Name
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            name="technologyId"
                                            value={values.technologyId}
                                            label="Technology Name"
                                            size="small"
                                            fullWidth
                                            onChange={handleChange}
                                            error={Boolean(
                                                touched.technologyId &&
                                                    errors.technologyId
                                            )}
                                            helperText={
                                                touched.technologyId &&
                                                errors.technologyId
                                            }
                                        >
                                            {data.length > 0 &&
                                                data.map((technology, key) => (
                                                    <MenuItem
                                                        key={technology.id}
                                                        value={technology.title}
                                                    >
                                                        {technology.title}
                                                    </MenuItem>
                                                ))}
                                        </Select>*/}

                  <Autocomplete
                    size="small"
                    fullWidth
                    options={data || []}
                    {...getFieldProps(`technologyId`)}
                    //value={values.bloodGroup}
                    value={
                      data.find((tech) => tech.title === values.technologyId) ??
                      ""
                    }
                    getOptionLabel={(option) => option?.title || ""}
                    onChange={(event, newValue) => {
                      setFieldValue(`technologyId`, newValue?.title || "");
                      // handleTechnologyChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Technology Name"
                        error={Boolean(
                          touched?.technologyId && errors?.technologyId
                        )}
                        helperText={
                          touched?.technologyId && errors?.technologyId
                        }
                      />
                    )}
                  />
                </Grid>
                {/* <Grid item xs={6} mt={3}>
									<TextField
										label="Project Manager"
										autoComplete='off'
										name="projectManagerId"
										value={values.projectManagerId}
										onChange={handleChange}
										inputProps={{ maxLength: 64 }}
										variant="outlined"
										size="small"
										fullWidth
										error={Boolean(
											touched.projectManagerId && errors.projectManagerId,
										)}
										helperText={touched.projectManagerId && errors.projectManagerId}
									/>
								</Grid> */}

                <Grid item xs={4} mt={3}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    options={employeeData || []}
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    {...getFieldProps(`projectManagerId`)}
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue(`projectManagerId`, newValue?.id || "");
                    }}
                    value={employeeData.find(
                      (emp) => emp.id == values.projectManagerId
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Project Manager"
                        error={Boolean(
                          touched?.projectManagerId && errors?.projectManagerId
                        )}
                        helperText={
                          touched?.projectManagerId && errors?.projectManagerId
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} mt={3}>
                  {/* <TextField
                    label="Year"
                    autoComplete="off"
                    name="year"
                    value={values.year}
                    inputProps={{ maxLength: 9 }}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                    fullWidth
                    error={Boolean(touched.year && errors.year)}
                    helperText={touched.year && errors.year}
                  /> */}

<LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            item>
                              <DesktopDatePicker
                                xs={12}
                                views={["year"]}
                                label="Year"
                                value={values.year}
                                inputFormat="yyyy"
                                minDate={new Date().getFullYear().toString()}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "year",
                                    yearFormat(newValue)
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    classes={{
                                      root: classes.root}}
                                    inputProps={{
                                      ...params.inputProps,
                                      disabled: true, // Add the disabled attribute to the input element
                                    }}
                                    fullWidth
                                    error={Boolean(touched.year && errors.year)}
                                    helperText={touched.year && errors.year}
                                    size="small"
                                  />
                                )}
                              />
                          </LocalizationProvider>
                </Grid>
                {/* <Grid item xs={4} mt={3}>
									<Autocomplete
										multiple
										size="small"
										fullWidth
										defaultValue=""
										options={internData || []}
										key={internData || []}
										getOptionLabel={(option) =>
											option.firstName + " " + option.lastName || ""
										}
										{...getFieldProps(`interns`)}
										renderOption={(props, option) => (
											<li {...props} value={option.id} key={option.id}>
												{option.firstName + " " + option.lastName || ""}
											</li>
											)
										}
										onChange={(event, newValue) => {
											let tempArray = newValue.map((x) => x.id);
											setFieldValue(`interns`, tempArray || "");
											console.log(tempArray)
										}}
										renderInput={(params) => (
											<TextField
												{...params}
												label="Interns"
												error={Boolean(touched?.interns && errors?.interns)}
												helperText={ touched?.interns && errors?.interns}
											/>
										)}
										value={internData.filter((x) =>
											values?.interns?.includes(x.id),
										)}
									/>
								</Grid> */}
                <Grid item xs={4} mt={3}>
                  <Autocomplete
                    multiple
                    size="small"
                    fullWidth
                    defaultValue=""
                    disabled={getViewVisible("interns") === false}
                    options={internData || []}
                    key={internData || []}
                    getOptionLabel={(option) =>
                      option.firstName + " " + option.lastName || ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {option.firstName + " " + option.lastName || ""}
                      </li>
                    )}
                    {...getFieldProps(`interns`)}
                    onChange={(event, newValue) => {
                      let tempArray = newValue.map((x) => {
                        return x.id;
                      });
                      // console.log(tempArray)
                      if (!tempArray.length) {
                        setFieldValue(`interns`, []);
                      }
                      tempArray.map((x) => {
                        if (x == 0) {
                          let arr = tempInternData.map((x) => {
                            return x.id;
                          });
                          setFieldValue(`interns`, arr || []);
                        } else {
                          setFieldValue(`interns`, tempArray || []);
                        }
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Interns"
                        error={Boolean(touched?.interns && errors?.interns)}
                        helperText={touched?.interns && errors?.interns}
                      />
                    )}
                    value={
                      internData.filter((x) =>
                        values?.interns?.includes(x.id)
                      ) || []
                    }
                  />
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}>
                  <Grid container pt={3}>
                    <Grid item xs={6} style={{ width: "200px" }}>
                      <Stack mb={1}>
                        <Typography>Project Definition</Typography>
                      </Stack>
                      <Grid container>
                        <Grid item>
                          <div
                            className="custome-uploadBox small profile-image-part"
                            style={{ position: "relative", width: "120px" }}
                            onClick={(e) => {
                              values.projectDefination
                                ? !values?.projectDefination?.webViewLink
                                  ? window.open(
                                      URL.createObjectURL(
                                        values?.projectDefination
                                      ),
                                      "_blank"
                                    )
                                  : window.open(
                                      values?.projectDefination?.webViewLink,
                                      "_blank"
                                    )
                                : projectInfoFileInput.current.click();
                            }}
                          >
                            {values.projectDefination && (
                              <>
                                {checkIfImage(values.projectDefination) ? (
                                  <>
                                    <InsertDriveFileIcon
                                            onClick={(e) => {
                                              window.open(
                                                values?.projectDefination?.webViewLink,
                                                "_blank"
                                              );
                                            }}
                                          />
                                    <Typography variant="caption">
                                      {getFileName(values.projectDefination)}
                                    </Typography>
                                  </>
                                ) : (
                                  <>
                                   <InsertDriveFileIcon
                                            onClick={(e) => {
                                              window.open(
                                                values?.projectDefination?.webViewLink,
                                                "_blank"
                                              );
                                            }}
                                          />
                                    <Typography variant="caption">
                                      {getFileName(values?.projectDefination)}
                                    </Typography>
                                  </>
                                )}
                              </>
                            )}

                            {values.projectDefination && (
                              <CancelRoundedIcon
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteProjectDesc(
                                    values.projectDefination
                                  );
                                }}
                                className="closeIcon"
                              />
                            )}

                            <div className="p-image">
                              {!values.projectDefination && (
                                <i className="upload-button">
                                  <AddCircleIcon />
                                </i>
                              )}

                              {!values.projectDefination && (
                                <input
                                  ref={projectInfoFileInput}
                                  className="file-upload"
                                  type="file"
                                  accept=".pdf "
                                  onChange={handleProjectDescChange}
                                />
                              )}
                            </div>
                          </div>
                        </Grid>
                      </Grid>
                      <FormHelperText
                        error={Boolean(errors?.projectDefination)}
                        style={{ margin: "5px 0 0 0" }}
                      >
                        {touched?.projectDefination &&
                          errors?.projectDefination}
                      </FormHelperText>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} my={3}>
                  <CKEditor
                    initData={values.description ? values.description : ""}
                    onChange={(event) => {
                      setFieldValue("description", event.editor.getData());
                    }}
                  />
                  {Boolean(touched.description && errors.description) && (
                    <FormHelperText error style={{ margin: "5px 0 0 0" }}>
                      {touched.description && errors.description}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Container>
        </Card>
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: Addproject,
    path: "/project/add",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: Addproject,
    path: "/project/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
