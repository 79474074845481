import React from "react";
import { useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useConfirm } from "material-ui-confirm";

import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import TableSortLabel from "@mui/material/TableSortLabel";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import CustomPagination from "../../components/Pagination";
import {
  getProject,
  deleteProjectById,
  getProjectCount,
} from "../../redux/project/projectThunk";
import { setLimit, setCurrentPage } from "../../redux/project/projectSlice";
import { getTechnology } from "../../redux/technology/technologyThunk";
import invertDirection from "../../utils/invertDirection";
import {
  setOrderBy,
  setSortBy,
  setPage,
} from "../../redux/project/projectSlice";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import Loader from "../../components/Loader";
import { decryption, encryption } from "../../utils/encodeString";
import moment from "moment";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getEmployeeForDropDown } from "../../redux/employee/employeeThunk";
import { useState } from "react";
import { debounce, filter } from "lodash";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import { yearMonthDateFormat,yearFormat } from "../../utils/dateFormat";
import LocalStorage from "../../service/localStorage";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));
function Project() {
const classes = useStyles();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState(null);
  const [filterChips, setFilterChips] = useState([]);
  const [searchValue, setSearchValue] = useState("");
 const inputRef = React.useRef(null);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("project")) {
      dispatch(
        getProject({
          ...filterData,
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
        })
      );
      navigate("/dashboard");
    }
  }, []);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const {
    data,
    totalRecords,
    currentPage,
    limit,
    status,
    loading,
    sortBy,
    orderBy,
    totalPages,
    isSearchQuery,
    error,
  } = useSelector((state) => state.project);

  const totalCounts = useSelector((state) => state.projectCount.totalCounts);

  const formik = useFormik({
    initialValues: {
      technologyId: "",
      projectName: "",
      projectManagerId: "",
      year: "",
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        const techId = techData.filter((tech) => {
          if (tech.title == values.technologyId) {
            return tech.id;
          }
        });
        var searchParams = {};
        // searchParams.page = currentPage + 1;
        // searchParams.limit = limit;
        searchParams.technologyId = techId[0]?.id ? techId[0].id : "";
        searchParams.projectName = values.projectName;
        searchParams.projectManagerId = values.projectManagerId;
        searchParams.year = values.year;
        // searchParams.sortBy = sortBy;
        // searchParams.orderBy = orderBy
        prepareChips(searchParams);
        setFilterData({ ...searchParams });
        dispatch(
          getProject({
            ...searchParams,
            page: 1,
            limit: limit,
            sortBy,
            orderBy,
          })
        );
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    handleSubmit,
    setValues,
    values,
    setFieldValue,
    handleChange,
    getFieldProps,
    errors,
    touched,
  } = formik;

  // useEffect(() => {
  // 	dispatch(
  // 		getProject({ page: currentPage + 1, limit: limit, sortBy, orderBy }),
  // 	);
  // }, []);

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    setFieldValue("projectName", e?.target?.value || "");
    handleSubmit()
 
  };
  const debounceWithSearch = debounce(handleSearch, 500);
  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      getProject({
        ...filterData,
        page: currentPage + 1,
        limit: limit,
        sortBy,
        orderBy,
      })
    );
    // }
  }, [dispatch, limit, currentPage, sortBy, orderBy]);

  useEffect(() => {
    // dispatch(getTechnology());
    dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProjectCount({}));
  }, [dispatch]);

  useEffect(() => {
    if (data?.length === 0 && currentPage !== 0) {
      dispatch(setCurrentPage({ page: currentPage - 1 }));
    }
  }, [data?.length]);

  useEffect(() => {
    dispatch(getEmployeeForDropDown({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  // const handleYear = async (date) => {
  //   if (!date) {
  //     return;
  //   }
  //   try {
  //     await setFieldValue(`year`, moment(new Date(date)).format("YYYY"));
  //     handleSubmit();
  //   } catch (error) {}
  // };

  const prepareChips = (searchParams) => {
    let filters = { ...searchParams };
    let tempChips = [];

    for (const key in filters) {
      if (filters[key] && key === "projectName") {
        filters[key] = `Project Name : ${filters[key]}`;
      }

      if (filters[key] && key === "year") {
        filters[key] = `Year : ${filters[key]}`;
      }
      if (filters[key] && key === "projectManagerId") {
        let projectManager = employeeData.find(
          (emp) => emp.id == filters.projectManagerId
        );

        filters[
          key
        ] = `Project Manager : ${projectManager.firstName} ${projectManager.lastName}`;
      }
      if (filters[key] && key === "technologyId") {
        let technology = techData.find(
          (tech) => tech.id === searchParams.technologyId
        );

        filters[key] = `Technology Name : ${technology.title}`;
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }

    setFilterChips(tempChips);
  };
  const handleDelete = async (id, name) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${name}?`,
      });
      await dispatch(deleteProjectById(id));
      // await dispatch(
      // 	getProject({ page: currentPage + 1, limit: limit, sortBy, orderBy }),
      // );
      handleSubmit();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };
  const onDeleteChip = (value, length) => {
    let { type } = value;
    delete filterData[value.type];
    setFilterData({ ...filterData });
     setFieldValue(type, "");
     if(type === 'projectName'){
      setSearchValue("");
      inputRef.current.value = "";
     }
    let updateChips = filterChips.filter((chip) => {
      return chip.type !== type;
    });
    setFilterChips([...updateChips]);
    dispatch(setCurrentPage({ page: 0 }));
    handleSubmit();
  };
  const handleClear = () => {
    setValues({
      technologyId: "",
      projectName: "",
      projectManagerId: "",
      year: "",
    });
    setFieldValue("projectManagerId", "");
    setFilterData(null);
    dispatch(setCurrentPage({ page: 0 }));
    handleSubmit();
  };
  const { data: techData } = useSelector((state) => state.technology);
  const employeeData = useSelector((state) => state.employee.allEmployeeData);
  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };
  return (
    <Container className="Project-main-wrapper" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Project ({totalCounts})
          {/* <Typography variant="body2">Total Records : {totalCounts}</Typography> */}
        </Typography>

        {getAddVisible("project") && (
          <Button
            component={RouterLink}
            to="/project/add"
            variant="contained"
            // startIcon={<AddIcon />}
          >
            <img src="/assets/images/add-circle.svg" style={{marginRight: "8px"}}/>
            Add Project
          </Button>
        )}  
        </div>
      </Stack>
      <Card className="holiday-table-grid" mb={5}>
        <Container maxWidth="xl">
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={1} pb={2}>
                <Grid item xs={3} mt={3}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/*<InputLabel>Technology Name</InputLabel>
										<Select
											name="technologyId"
											value={values.technologyId}
											label="Technology Name"
											size="small"
											fullWidth
											onChange={handleChange}
										>
											{techData.length > 0 &&
												techData.map((technology, key) => (
													<MenuItem
														key={technology.id}
														value={technology.title}
													>
														{technology.title}
													</MenuItem>
												))}
										</Select>*/}

                    <Autocomplete
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={techData || []}
                      {...getFieldProps(`technologyId`)}
                      //value={values.technologyId}
                      value={
                        techData.find(
                          (tech) => tech.title === values.technologyId
                        ) ?? ""
                      }
                      getOptionLabel={(option) => option?.title || ""}
                      onChange={(event, newValue) => {
                        setFieldValue(`technologyId`, newValue?.title || "");
                        handleSubmit();
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="Technology Name"
                        />
                      )}
                    />
                  </FormControl>
                </Grid>
                {/* <Grid item xs={6} mt={3}>
									<TextField
										label="Project Manager"
										autoComplete="off"
										name="projectManager"
										value={values.projectManager}
										onChange={handleChange}
										variant="outlined"
										size="small"
										fullWidth
									/>
								</Grid> */}
                <Grid item xs={3} mt={3}>
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={employeeData || []}
                    getOptionLabel={(option) =>
                      option?.firstName
                        ? `${option.firstName} ${option.lastName}`
                        : ""
                    }
                    // {...getFieldProps(`projectManagerId`)}
                    renderOption={(props, option) => (
                      <li {...props} value={option.id} key={option.id}>
                        {`${option.firstName} ${option.lastName}` || ""}
                      </li>
                    )}
                    onChange={(event, newValue) => {
                      setFieldValue(`projectManagerId`, newValue?.id || "");
                      handleSubmit();
                    }}
                    value={
                      values.projectManagerId
                        ? employeeData.find(
                            (emp) => emp.id == values.projectManagerId || ""
                          )
                        : ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Project Manager" />
                    )}
                  />
                </Grid>
                <Grid item xs={3} mt={3}>
                  <TextField
                    label="Project Name"
                    autoComplete="off"
                    name="projectName"
                    // value={values.projectName}
                    inputRef={inputRef} 
                    onChange={debounceWithSearch}
                    variant="outlined"
                    size="small"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={3} mt={3}>
                  {/* <TextField
										label="Year"
										autoComplete="off"
										name="year"
										value={values.year}
										onChange={handleChange}
										variant="outlined"
										size="small"
										fullWidth
									/> */}
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      views={["year"]}
                      key = {values.year}
                      label="Year"
                      name="year"
                      autoComplete="off"
                      value={values.year || ""}
                      inputFormat="yyyy"
                      // minDate={new Date('2017-01-01')
                      {...getFieldProps(`year`)}
                      onChange={(newValue) => {
                        setFieldValue(
                          "year",
                          yearFormat(newValue)
                        );
                        handleSubmit();
                      }}
                      renderInput={(params) => (
                        <TextField
                        
                          classes={{
                          root: classes.root}}
                          {...params}
                          name="year"
                          inputProps={{
                            ...params.inputProps,
                            disabled: true, // Add the disabled attribute to the input element
                          }}
                          error={false}
                          // error={Boolean(touched.year && errors.year)}
                          // helperText={touched.year && errors.year}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                {/* <Grid item xs={1} mt={3}>
								<Button type="submit" variant="contained" style={{ marginLeft: "10px" }}>
									Search
								</Button>
								</Grid>
								<Grid item xs={1} mt={3} mb={3}>
								<Button
									type="button"
									variant="contained"
									// style={{ marginLeft: "10px" }}
									onClick={handleClear}
								>
									Clear
								</Button>
								</Grid> */}
              </Grid>
              {/* <Stack direction="row" justifyContent="flex-end" my={3}>
								<Button type="submit" variant="contained">
									Search
								</Button>
								<Button
									type="button"
									variant="contained"
									style={{ marginLeft: "10px" }}
									onClick={handleClear}
								>
									Clear
								</Button>
							</Stack> */}
            </form>
          </Paper>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "10px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {filterChips.length > 0 ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table className="grid-table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "projectName"}
                      direction={sortBy === "projectName" ? orderBy : "asc"}
                      onClick={() => handleSorting("projectName")}
                    >
                      Project Name
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Technology Name</TableCell>
                  <TableCell align="left">
                    {/* <TableSortLabel
												active={sortBy === "projectManager"}
												direction={
													sortBy === "projectManager" ? orderBy : "asc"
												}
												onClick={() => handleSorting("projectManager")}
											> */}
                    Project Manager
                    {/* </TableSortLabel> */}
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                      active={sortBy === "year"}
                      direction={sortBy === "year" ? orderBy : "asc"}
                      onClick={() => handleSorting("year")}
                    >
                      Year
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center">Project Definition</TableCell>
                  {(getEditVisible("project") ||
                    getDeleteVisible("project")) && (
                    <TableCell align="right" style={{ width: "130px" }}>
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              {loading ? (
                <TableCell align="center" width="100%" colSpan={10}>
                  <Loader />
                </TableCell>
              ) : (
                <TableBody>
                  {Array.isArray(data) && data?.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={4}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((project, idx) => (
                      <TableRow key={idx}>
                        <TableCell align="left">
                          {project?.projectName ? project?.projectName : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {project?.technology?.title
                            ? project?.technology?.title
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {project?.projectManager
                            ? `${project?.projectManager?.firstName || ""} ${
                                project?.projectManager?.lastName || ""
                              }`
                            : "-"}
                        </TableCell>
                        <TableCell align="left">
                          {project?.year ? project?.year : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {project?.projectDefination &&
                          project?.projectDefination[0] ? (
                            <IconButton
                              onClick={() => {
                                window.open(
                                  project?.projectDefination[0]?.webViewLink
                                );
                              }}
                            >
                              <PictureAsPdfIcon />
                            </IconButton>
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        {(getEditVisible("project") ||
                          getDeleteVisible("project")) && (
                          <TableCell className="td-action-button" align="center">
                            <div className="action-button">
                              {getEditVisible("project") && (
                                <span>
                                  <IconButton
                                    color="primary"
                                    component={RouterLink}
                                    to={`/project/add/${encryption(
                                      project.id
                                    )}`}
                                  >
                                    {/* <EditIcon /> */}
                                    <img src="assets/images/edit.svg" title="Edit" />
                                  </IconButton>
                                </span>
                              )}
                              {getDeleteVisible("project") && (
                                <span>
                                  <IconButton
                                    onClick={() =>
                                      handleDelete(
                                        project?.id,
                                        project?.projectName
                                      )
                                    }
                                  >
                                    {/* <DeleteIcon color="error" /> */}
                                    <img src="assets/images/trash-gray.svg" title="trash" />
                                  </IconButton>
                                </span>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              )}
            </Table>
            <CustomPagination
              totalPage={totalPages}
              totalCount={totalRecords}
              limit={limit}
              handleChangePage={handleChangePage}
              page={currentPage}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              //filter={isSearchQuery ? true : false}
            />
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: Project,
  path: "/project",
  public: false,
  group: "tpa",
  layout: DashboardLayout,
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
