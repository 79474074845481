import DashboardLayout from "../../../layouts/dashboard";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { getViewVisible, getEditVisible } from "../../../utils/userPermission";
import { useEffect, useState } from "react";
import { decryption, encryption } from "../../../utils/encodeString";
import { getAssestLicenceById } from "../../../redux/assestLicence/thunk/index"
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Card from "@mui/material/Card";
import { getGeneratedAssetsForDropdown } from "../../../redux/assets/thunk/index";
import Loader from "../../../components/Loader";
import { indianDateFormat } from '../../../utils/dateFormat'
import Box from "@mui/material/Box";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk/index";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
};
const ViewLicence = () => {
  let { id } = useParams();
  const [viewData, setViewData] = useState({})
  const [loading, setLoading] = useState(false);
  const [assetData, setAssetData] = useState({})
  const [employee, setEmployee] = useState([])
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showProductKey, setshowProductKey] = useState(false);
  const [open, setOpen] = useState(false);
  const [productKey1, setProductKey1] = useState('');
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleProductKey = () => {
    if (showProductKey === false) {
      handleOpen();
    }
    else {
      setshowProductKey(!showProductKey);
    }
  }



  let handleVerify = () => {
    if (productKey1 === '1234') {
      setshowProductKey(!showProductKey);
      handleClose();
    } else {
      toast.error('Wrong Password');
      handleClose();
    }
  }


  useEffect(() => {
    const fetchData = async () => {
      try {
        if (id) {
          setLoading(true);
          const ParamId = decryption(id);
          if (ParamId === -1) {
            navigate('/licence-master');
            return;
          }

          const assetResponse = await dispatch(getGeneratedAssetsForDropdown()).unwrap();
          const dataResponse = await dispatch(getAssestLicenceById(ParamId)).unwrap();
          const employeesResponse = await dispatch(getEmployeeForDropDown()).unwrap();

          if (dataResponse.success) {
            setViewData(dataResponse.data);

            if (dataResponse.data.assetId) {
              const findAssets = (ele) => ele.id === dataResponse.data.assetId;
              if (assetResponse.success) {
                const asset = assetResponse.data.find(findAssets);
                setAssetData({ ...asset });
              }

              if (employeesResponse.success && dataResponse.data.assignedEmployees) {
                const employeeData = employeesResponse.data.filter((x) =>
                  dataResponse.data.assignedEmployees.includes(x.id)
                );

                const employeesData = employeeData.map((ele) =>
                  `${ele?.firstName} ${ele?.lastName}`
                );
                if (employeesData.length !== 0) {
                  setEmployee(employeesData.join(", "));
                }
              }
            }
          }
          setLoading(false);
        }
      } catch (error) {
        navigate('/licence-master');
        toast.error(error.message);
        setLoading(false); // Ensure loading state is reset on error
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Loader />
    )
  }
  // console.log(viewData)
  return (
    <div className="view_employee">
      <Container maxWidth="xl">
        <Stack direction="row" justifyContent="flex-end" mb={2}>
          {getEditVisible("licenceMaster") && (
            <Button
              variant="contained"
              component={RouterLink}
              to={`/licence-master/add/${id}`}
              style={{ marginLeft: "10px" }}
            >
              Edit
            </Button>
          )}
          {getViewVisible("licenceMaster") && (
            <Button
              variant="contained"
              component={RouterLink}
              to="/licence-master"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          )}
        </Stack>
  
        <Box>
          <Card style={{ padding: "25px 15px" , marginTop: "10px"}} mb={3} >
          <Typography
          variant="h6"
          className="information-card-heading"
          style={{
            fontWeight: "700",
            fontSize: "1.125rem",
            marginBottom: "20px",
          }}
        >
          Basic Information
        </Typography>
            <div className="address-blk">
              
              <div className="address-sub-section">
                <div className="address-div">
                  <span>Product Name</span>
                  <p>
                    {viewData?.productName
                      ? viewData?.productName
                      : ""}
                  </p>
                </div>
                <div className="address-div">
                  <span>Purchased Date</span>
                  <p>
                    {viewData?.purchasedDate
                      ? indianDateFormat(viewData.purchasedDate)
                      : ""}
                  </p>
                </div>
                <div className="address-div">
                  <span>Vendor</span>
                  <p>
                    { viewData?.vendor !== null ? `${viewData?.vendor?.firstName} ${viewData?.vendor?.lastName}` : ""}
                  </p>
                </div>
              </div>
              <div className="address-sub-section">
                <div className="address-div">
                  <span>Licence Period</span>
                  <p>
                    {viewData?.licencePeriod
                      ? viewData?.licencePeriod
                      : ""}
                  </p>
                </div>
                <div className="address-div">
                  <span>Product Key</span>
                  {viewData?.productKey ? (
                          !showProductKey ? (

                            <p>
                             {'******'} &nbsp; <VisibilityIcon onClick={handleProductKey} />
                            </p>
                          ) :
                            (viewData?.productKey
                              ?
                              (<p>
                                {decryption(viewData.productKey)}  &nbsp; <VisibilityOffIcon onClick={handleProductKey} />
                              </p>)
        
                              : "")
                          
                  ) : ("")
                              }
                 
                </div>
                <div className="address-div">
                  <span>Notes</span>
                  <p>
                    {viewData?.notes
                      ? viewData?.notes
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </Card>
        </Box>
        {employee?.length > 0 || viewData.assetId || viewData.assignedDate || viewData.expiryDate ?(
          <Box>
          <Card style={{ padding: "25px 15px" , marginTop: "20px"}} mb={3} >
          <Typography
          variant="h6"
          className="information-card-heading"
          style={{
            fontWeight: "700",
            fontSize: "1.125rem",
            marginBottom: "20px",
          }}
        >
          Assignment Details
        </Typography>
            <div className="address-blk">
              <div className="address-sub-section">
                <div className="address-div">
                  <span>Assigned To</span>
                  <p>
                    {employee ? employee : ''}
                  </p>
                </div>
                <div className="address-div">
                  <span>Associated Asset</span>
                  <p>
                    {viewData?.assetId && 
                      assetData.assetEmployee && assetData.assetEmployee.employee !== null
                      ? viewData?.asset?.assetUniqueId + " - "  + assetData.assetEmployee.employee.firstName +
                      " " +
                      assetData.assetEmployee.employee.lastName +
                      " - " +
                      assetData.generatedAssetItems
                      : assetData?.generatedAssetItems || ""}
                  </p>
                </div>
              </div>
              <div className="address-sub-section">
                <div className="address-div">
                  <span>Assigned Date</span>
                  <p>
                    {viewData?.assignedDate
                      ? indianDateFormat(viewData.assignedDate)
                      : ""}
                  </p>
                </div>
                <div className="address-div">
                  <span>Expiry Date</span>
                  <p>
                    {viewData?.expiryDate
                      ? indianDateFormat(viewData.expiryDate)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Stack sx={style}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Varification Code"
                onChange={(e) => setProductKey1(e.target.value)}
                variant="outlined"
                size="small"
              />
              <Button variant="contained" onClick={handleVerify}>Submit</Button>
            </Stack>
          </Modal>
        </Box>

        ): "" }
        {/* <Box>
          <Card style={{ padding: "25px 15px" , marginTop: "20px"}} mb={3} >
          <Typography
          variant="h6"
          className="information-card-heading"
          style={{
            fontWeight: "700",
            fontSize: "1.125rem",
            marginBottom: "20px",
          }}
        >
          Assignment Details
        </Typography>
            <div className="address-blk">
              <div className="address-sub-section">
                <div className="address-div">
                  <span>Assigned To</span>
                  <p>
                    {employee ? employee : ''}
                  </p>
                </div>
                <div className="address-div">
                  <span>Associated Asset</span>
                  <p>
                    {viewData?.assetId && 
                      assetData.assetEmployee && assetData.assetEmployee.employee !== null
                      ? viewData?.asset?.assetUniqueId + " - "  + assetData.assetEmployee.employee.firstName +
                      " " +
                      assetData.assetEmployee.employee.lastName +
                      " - " +
                      assetData.generatedAssetItems
                      : assetData?.generatedAssetItems || ""}
                  </p>
                </div>
              </div>
              <div className="address-sub-section">
                <div className="address-div">
                  <span>Assigned Date</span>
                  <p>
                    {viewData?.assignedDate
                      ? indianDateFormat(viewData.assignedDate)
                      : ""}
                  </p>
                </div>
                <div className="address-div">
                  <span>Expiry Date</span>
                  <p>
                    {viewData?.expiryDate
                      ? indianDateFormat(viewData.expiryDate)
                      : ""}
                  </p>
                </div>
              </div>
            </div>
          </Card>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Stack sx={style}>
              <TextField
                fullWidth
                autoComplete="off"
                label="Varification Code"
                onChange={(e) => setProductKey1(e.target.value)}
                variant="outlined"
                size="small"
              />
              <Button variant="contained" onClick={handleVerify}>Submit</Button>
            </Stack>
          </Modal>
        </Box> */}
      </Container >
    </div>
  )
}


const componentConfig = {
  component: ViewLicence,
  path: "/licence-master/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
}
export default componentConfig;