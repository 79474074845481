import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import { debounce } from "lodash";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "../../components/Scrollbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { encryption } from "../../utils/encodeString";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import CustomPagination from "../../components/Pagination";
import invertDirection from "../../utils/invertDirection";
import Switch from "@mui/material/Switch";
import Chip from "@mui/material/Chip";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import {
  deleteEvalutionById,
  getAllEvalution,
  getEvalutionCount,
  updateEvaluation,
} from "../../redux/evaluation/evaluationThunk";
import {
  setCurrentPage,
  setLimit,
  setOrderBy,
  setSortBy,
} from "../../redux/evaluation/evaluationSlice";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
}));
function Evaluations() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  // useEffect(() => {
  //   if (!getViewVisible("evaluation")) {
  //     dispatch(
  //       getAllQuestions({
  //         page: currentPage + 1,
  //         limit: limit,
  //         sortBy: sortBy,
  //         orderBy: orderBy,
  //         search: "",
  //       })
  //     );
  //     navigate("/dashboard");
  //   }
  // }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const {
    data,
    currentPage,
    status,
    limit,
    totalRecords,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
  } = useSelector((state) => state.evaluation);
  const totalCount = useSelector((state) => state.evaluationCount.totalCounts);

  useEffect(() => {
    if (data?.length === 0 && currentPage !== 0) {
      dispatch(setCurrentPage({ page: currentPage - 1 }));
    }
  }, [data?.length]);

  // console.log(currentPage)

  useEffect(() => {
    // if (status === "idle") {
    dispatch(
      getAllEvalution({
        page: currentPage + 1,
        limit,
        sortBy,
        orderBy,
        search: "",
      })
    );
    // }
    // return () => {
    // 	dispatch(setStatus({ status: "idle" }));
    // };
  }, [dispatch, limit, currentPage, sortBy, orderBy]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ currentPage: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ currentPage: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleDelete = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to delete?`,
      });
      await dispatch(deleteEvalutionById(id)).unwrap();
      dispatch(
        getAllEvalution({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
      dispatch(getEvalutionCount({})).unwrap();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    if (!error) {
      dispatch(getEvalutionCount({}));
    }
  }, [error]);

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    // setFieldValue("search", e?.target?.value || "");
    // handleSubmit();
    setSearchValue(e?.target?.value);
    if (e?.target?.value != "") {
      setIsSearching(true);
      await dispatch(
        getAllEvalution({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: e?.target?.value,
        })
      );
    } else {
      setIsSearching(false);
      await dispatch(
        getAllEvalution({
          limit,
          page: currentPage + 1,
          orderBy,
          sortBy,
          search: "",
        })
      );
    }
    setSearchValue(e?.target?.value);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const onDeleteChip = async () => {
    setSearchValue("");
    setIsSearching(false);
    inputRef.current.value = "";
    dispatch(
      getAllEvalution({
        page: currentPage + 1,
        limit: limit,
        sortBy,
        orderBy,
        search: "",
      })
    );
  };
  const handleToggle = async (evaluation) => {
    try {
      // console.log("EVALUATION______",evaluation)
      if (evaluation?.isActive == 1) {
        let editObj = {
          title: evaluation.title,
          isActive: 0,
          designationId: evaluation?.designationId?.map(
            (designation) => designation?.id
          ),
          sectionId: Object.keys(evaluation?.sectionId).map((key) => ({
            [key]: evaluation?.sectionId[key],
          })),
        };
        await dispatch(
          updateEvaluation({
            editEvaluationData: editObj,
            evaluation_id: evaluation?.id,
          })
        ).unwrap();
      }
      if (evaluation?.isActive == 0) {
        let editObj = {
          title: evaluation.title,
          isActive: 1,
          designationId: evaluation?.designationId?.map(
            (designation) => designation?.id
          ),
          sectionId: Object.keys(evaluation?.sectionId).map((key) => ({
            [key]: evaluation?.sectionId[key],
          })),
        };
        await dispatch(
          updateEvaluation({
            editEvaluationData: editObj,
            evaluation_id: evaluation?.id,
          })
        ).unwrap();
      }

      await dispatch(
        getAllEvalution({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <Container maxWidth="xl" className="item-component">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Evaluations ({totalCount ? totalCount : "0"})
          {/* <Typography variant="body2">
            Total Records : {totalCount ? totalCount : "0"}
          </Typography> */}
        </Typography>

        {getAddVisible("evaluations") && (
          <Button
            className="header-add-button"
            variant="contained"
            component={RouterLink}
            to="/evaluations/add"
            // startIcon={<AddIcon />}
          >
            <img src="/assets/images/add-circle.svg" />
            Add Evaluation
          </Button>
        )}
        </div>
      </Stack>

      <Card className="custom-grid">
        <Container style={{ maxWidth: "100%" }}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid container xs={12} pt={2} pb={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search by Title"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearching ? (
          <Chip
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={() => onDeleteChip()}
          />
        ) : (
          ""
        )}
      </Stack>
      {isSearching ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomPagination
              totalPage={totalPages}
              totalCount={totalRecords}
              limit={limit}
              handleChangePage={handleChangePage}
              page={currentPage}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              // filter={isSearchQuery ? true : false}
            />
            <Scrollbar>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 800 }}
                className="radius-remove"
                style={{ borderRadius: "0" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "title"}
                          direction={sortBy === "title" ? orderBy : "asc"}
                          onClick={() => handleSorting("title")}
                        >
                          Evaluation Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">Designation</TableCell>
                      <TableCell width="20%" align="center">
                        {/* <TableSortLabel
                          active={sortBy === "isActive"}
                          direction={sortBy === "isActive" ? orderBy : "asc"}
                          onClick={() => handleSorting("isActive")}
                        > */}
                        Status
                        {/* </TableSortLabel> */}
                      </TableCell>
                      {(getDeleteVisible("evaluations") ||
                        getEditVisible("evaluations") ||
                        getViewVisible("evaluations")) && (
                        <TableCell align="center">Actions</TableCell>
                      )}
                      {/* <TableCell align="left">Question Tag</TableCell> */}
                      {/* <TableCell align="left">Enabled</TableCell> */}
                    </TableRow>
                  </TableHead>

                  {loading ? (
                    <TableCell align="center" width="100%" colSpan={7}>
                      <Loader />
                    </TableCell>
                  ) : (
                    <TableBody>
                      {data?.evalution?.length > 0 ? (
                        data?.evalution?.map((evaluation, idx) => {
                          return (
                            <TableRow key={evaluation.id}>
                              <TableCell align="center">
                                {evaluation?.title || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {evaluation?.designationId.map(
                                  (designation, index) => (
                                    <Chip
                                      size="small"
                                      key={index}
                                      style={{ marginTop: "5px",marginBottom: "5px",marginRight:"5px"}}
                                      variant="filled"
                                      tabIndex={-1}
                                      label={designation?.title}
                                      // className={classes.chip}
                                    />
                                  )
                                ) || "-"}
                              </TableCell>
                              {/*<TableCell align="left">
                                {email?.status ? email.status.name : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {email?.state ? email.state.name : "-"}
                              </TableCell> */}
                              {/* <TableCell align="center">
                                
                              </TableCell>
                              <TableCell align="center">
                                
                              </TableCell> */}

                              <TableCell align="center">
                                <Switch
                                  checked={evaluation?.isActive}
                                  onChange={() => handleToggle(evaluation)}
                                  inputProps={{ "aria-label": "controlled" }}
                                  size="small"
                                  disabled={!getEditVisible("evaluations")}
                                />
                              </TableCell>
                              {(getDeleteVisible("evaluations") ||
                                getEditVisible("evaluations") ||
                                getViewVisible("evaluations")) && (
                                <TableCell align="center">
                                  <Stack direction="row">
                                    {getEditVisible("evaluations") && (
                                      <IconButton
                                        style={{ padding: "5px " }}
                                        component={RouterLink}
                                        to={`/evaluations/edit/${encryption(
                                          evaluation?.id
                                        )}`}
                                      >
                                        <EditIcon color="primary" />
                                      </IconButton>
                                    )}
                                    {getViewVisible("evaluations") &&
                                      evaluation?.isActive == 1 && (
                                        <IconButton
                                          style={{ padding: "5px" }}
                                          component={RouterLink}
                                          to={`/evaluations/view/${encryption(
                                            evaluation?.id
                                          )}`}
                                        >
                                          <VisibilityIcon />
                                        </IconButton>
                                      )}
                                    {getDeleteVisible("evaluations") && (
                                      <span>
                                        <IconButton
                                          onClick={() =>
                                            handleDelete(evaluation?.id)
                                          }
                                        >
                                          <DeleteIcon color="error" />
                                        </IconButton>
                                      </span>
                                    )}
                                  </Stack>
                                </TableCell>
                              )}
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell className="No-Record-text" colSpan={12} align="left">
                            No Record(s) Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: Evaluations,
  path: "/evaluations",
  public: false,
  layout: DashboardLayout,
  group: "evaluation",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
