import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import DashboardLayout from "../../../layouts/dashboard";
import { encryption,decryption } from "../../../utils/encodeString";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getVendorById,
  deleteVendorById,
  updateVendorById,
  insertVendor,
} from "../../../redux/vendor/vendorThunk";
import vendorSchema from "../../../validations/vendorSchema";
import {
  getAreaData,
  getCityData,
  getPostalCode,
  getStateData,
} from "../../../utils/getLocationData";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../../service/localStorage";
import { Link as RouterLink, useNavigate ,useParams } from "react-router-dom";
const AddVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { id } = useParams();
  let vendorId;
	if (id){
    vendorId = decryption(id);
  }  
  const {
    data,
    currentPage,
    status,
    limit,
    totalRecords,
    totalPages,
    sortBy,
    orderBy,
  } = useSelector((state) => state.vendor);
  const confirm = useConfirm();
  // const [id, setId] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setArea] = useState([]);
  const countryData = ["India"];

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("vendors")) {
      // dispatch(getVendor({ page: currentPage + 1, limit: limit, orderBy, sortBy }));
      navigate("/dashboard");
    }
  }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      contactNumber: "",
      streetLine1: "",
      streetLine2: "",
      area: "",
      city: "Ahmedabad",
      country: "India",
      state: "Gujarat",
      postalCode: "",
    },
    validationSchema: vendorSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (vendorId) {
          await dispatch(updateVendorById({ ...values, vendorId })).unwrap();
        }
        if (!vendorId) {
          await dispatch(insertVendor(values)).unwrap();
        }
        navigate("/vendor");
        resetForm();

      } catch (error) {}
    },
  });

  const handleState = async (event, newValue) => {
    const state = newValue;
    setFieldValue("city", "");
    setFieldValue("area", "");
    setFieldValue("postalCode", "");
    setFieldValue("state", state);
    setCities(await getCityData(state));
  };

  const handleArea = async (event, newValue) => {
    const city = newValue;
    setFieldValue("area", "");
    setFieldValue("postalCode", "");
    setFieldValue("city", city);
    setArea(await getAreaData(city));
  };

  const handleZipCode = async (event, newValue) => {
    try {
      const area = newValue;
      const postalCode = await getPostalCode({ city: values.city, area });
      setFieldValue("area", area);
      setFieldValue("postalCode", postalCode);
    } catch (error) {}
  };

  const handleAreaWhileEdit = async (city) => {
    const areas = await getAreaData(city);
    setArea(areas);
  };

  const handleCityWhileEdit = async (state) => {
    const cities = await getCityData(state);
    setCities(cities);
  };

  const {
    handleSubmit,
    setValues,
    values,
    setFieldValue,
    handleChange,
    isSubmitting,
    errors,
    touched,
  } = formik;

  const handleUpdate = async (data) => {
    setValues({
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      contactNumber: data.contactNumber,
      streetLine1: data.streetLine1,
      streetLine2: data.streetLine2,
      area: data.area,
      city: data.city,
      state: data.state,
      country: data.country,
      postalCode: data.postalCode,
    });
    // dispatch(getCity(data.state));
    // dispatch(getArea(data.city));
    await handleCityWhileEdit(data.state);
    await handleAreaWhileEdit(data.city);
    // setId(data.id);
  };

  useEffect(() => {
    getStateData()
      .then((states) => {
        setStates(states);
      })
      .catch((err) => {
        setStates([]);
      });
  }, [dispatch]);

  useEffect(() => {
    if (!vendorId) {
      getCityData("Gujarat")
        .then((cities) => {
          setCities(cities);
        })
        .catch((err) => {
          setCities([]);
        });

      getAreaData("Ahmedabad")
        .then((areas) => {
          setArea(areas);
        })
        .catch((err) => {
          setArea([]);
        });
    }
  }, [dispatch]);




  useEffect(() => {
    const fetchById = async () => {
      if (vendorId) {
        try {
          let data = await dispatch(getVendorById( vendorId)).unwrap();
          handleUpdate(data?.data)
        } catch (error) {
          //if there is no data then it gives error msg "No records found"
            navigate(`/vendor`);
        }
      }
      // setLoading(false);
    };
    fetchById();
  }, [dispatch, vendorId]);

  return (
    // {getAddVisible("vendors") && (

    <Container maxWidth="xl" py={3}>
      <form onSubmit={handleSubmit} autoComplete="off">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
         <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            {vendorId ? "Edit Vendors" : "Add Vendors"}
          </Typography>
          <Stack direction="row" alignItems="center">
            <LoadingButton
              loading={isSubmitting}
              type="submit"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button
              variant="contained"
              component={RouterLink}
              to="/vendor"
              startIcon={<ArrowBackIcon />}
              style={{ marginLeft: "10px" }}
            >
              Back To List
            </Button>
          </Stack>
          </div>
        </Stack>
        <Card className="chips-card">
          <Container maxWidth="xl">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
            >
              <Typography variant="h6" gutterBottom>
                Basic Details
              </Typography>
            </Stack>
            <Grid container spacing={3} py={3}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="First Name"
                  name="firstName"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 64 }}
                  value={values.firstName}
                  onChange={handleChange}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Last Name"
                  name="lastName"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 64 }}
                  value={values.lastName}
                  onChange={handleChange}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 512 }}
                  value={values.email}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Contact Number"
                  name="contactNumber"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  /* inputProps={{ minLength: 10, maxLength: 11 }} */
                  value={values.contactNumber}
                  onChange={handleChange}
                  error={Boolean(touched.contactNumber && errors.contactNumber)}
                  helperText={touched.contactNumber && errors.contactNumber}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Street Line 1"
                  name="streetLine1"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 512 }}
                  value={values.streetLine1}
                  onChange={handleChange}
                  error={Boolean(touched.streetLine1 && errors.streetLine1)}
                  helperText={touched.streetLine1 && errors.streetLine1}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  label="Street Line 2"
                  name="streetLine2"
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 512 }}
                  value={values.streetLine2}
                  onChange={handleChange}
                  error={Boolean(touched.streetLine2 && errors.streetLine2)}
                  helperText={touched.streetLine2 && errors.streetLine2}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  size="small"
                  sx={{ minWidth: 120 }}
                  fullWidth
                  error={Boolean(touched.streetLine1 && errors.streetLine1)}
                >
                  {/*<InputLabel id="demo-simple-select-helper-label">
										Country
									</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="Country"
										size="small"
										value={'India'}
									>
										<MenuItem value={'India'}>India</MenuItem>
								</Select>*/}

                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="country"
                    size="small"
                    onChange={(event, newValue) => {
                      setFieldValue("country", newValue || "");
                    }}
                    clearOnBlur={false}
                    clearOnEscape={false}
                    disableClearable
                    value={values.country}
                    options={countryData || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Country"
                        inputProps={{
                          ...params.inputProps,
                          disabled: true,
                          disableClearable: true,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                  {Boolean(touched.country && errors.country) && (
                    <FormHelperText error>
                      {touched.country && errors.country}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                {/*<InputLabel id="demo-simple-select-label" size="small">
										State
									</InputLabel>
									<Select
										id="demo-simple-select"
										name="state"
										value={values.state}
										label="State"
										size="small"
										fullWidth
										onChange={handleState}
									>
										{states.map((state, key) => (
											<MenuItem key={key} value={state}>
												{state}
											</MenuItem>
										))}
										</Select>*/}

                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="state"
                  size="small"
                  name="state"
                  disabled={!values.country}
                  onChange={handleState}
                  value={values.state}
                  options={states || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="State"
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={formik.touched.state && formik.errors.state}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                {/*<InputLabel size="small">City</InputLabel>
									<Select
										disabled={!values.state}
										name="city"
										value={values.city}
										label="City"
										size="small"
										fullWidth
										onChange={handleArea}
									>
										{cities.map((city, key) => (
											<MenuItem key={key} value={city}>
												{city}
											</MenuItem>
										))}
									</Select>*/}
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="city"
                  size="small"
                  name="city"
                  disabled={!values.state || !values.country}
                  options={cities || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="City"
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    />
                  )}
                  value={values.city}
                  onChange={handleArea}
                />
              </Grid>
              <Grid item xs={6}>
                {/*<InputLabel id="demo-simple-select-label" size="small">
										Area
									</InputLabel>
									<Select
										disabled={!values.city}
										labelId="demo-simple-select-label"
										id="demo-simple-select"
										name="area"
										value={values.area}
										label="Area"
										size="small"
										fullWidth
										onChange={handleZipCode}
										error={Boolean(touched.area && errors.area)}
									>
										{areas.map((area, key) => (
											<MenuItem key={key} value={area}>
												{area}
											</MenuItem>
										))}
									</Select>*/}
                <Autocomplete
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  size="small"
                  name="area"
                  value={values.area}
                  disabled={!values.city || !values.state}
                  options={areas || []}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Area"
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      helperText={formik.touched.area && formik.errors.area}
                    />
                  )}
                  onChange={handleZipCode}
                />
                {/* <TextField
											label="Area"
											name="area"
											value={values.area}
											onChange={handleArea}
											variant="outlined"
											size="small"
											error={Boolean(touched.area && errors.area)}
											helperText={touched.area && errors.area}
											fullWidth
										/> */}
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Postal Code"
                  name="postalCode"
                  value={values.postalCode}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                  autoComplete="off"
                  inputProps={{ maxLength: 8 }}
                  error={Boolean(touched.postalCode && errors.postalCode)}
                  helperText={touched.postalCode && errors.postalCode}
                  fullWidth
                />
              </Grid>
            </Grid>
            {/* <Stack direction="row" justifyContent="flex-end" mt={2} mb={3}>
              <Button type="submit" variant="contained">
                Save
              </Button>
            </Stack> */}
          </Container>
        </Card>
      </form>
    </Container>
    //   )}
  );
};

const componentConfig = [
  {
    component: AddVendor,
    path: "/vendor/add",
    public: false,
    layout: DashboardLayout,
    group: "assMangment",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddVendor,
    path: "/vendor/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "assMangment",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
