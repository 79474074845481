import { Button, Card, Container, Grid, Paper, Stack, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { createCmsVariable, getCmsVariableById, updateCmsVariableById } from "../../../redux/cmsVariable/cmsVariableThunk";
import { decryption } from "../../../utils/encodeString";
import cmsVariable from "../../../validations/cmsVariable";
import { Link as RouterLink } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Loader from "../../../components/Loader";
import { LoadingButton } from "@mui/lab";

import DashboardLayout from '../../../layouts/dashboard';



function AddCmsVariable() {
	const dispatch = useDispatch();
	const params = useParams();
	const navigation = useNavigate();
	const isAddMode = !params.id;

	const [id, setId] = useState(null);
	const [loading, setLoading] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			code: '',
			default: '',
			
		},
		validationSchema: cmsVariable,
		onSubmit: async (values, { resetForm }) => {
			try {
				if (!isAddMode) {
					await dispatch(updateCmsVariableById({ ...values, id })).unwrap();
				}
				if (isAddMode) {
					await dispatch(createCmsVariable(values)).unwrap();
				}
				resetForm();
				navigation('/cms-variable');
			} catch (error) {
				toast.error(error.response.data.message);
			}
		},
	});

	const {
		handleSubmit,
		setValues,
		values,
		setFieldValue,
		handleChange,
		errors,
		touched,
		isSubmitting,
	} = formik;

	//const statusData = ['Enable', 'Disable'];
	useEffect(() => {
      
		const fetchById = async () => {
			if (!isAddMode) {
				setLoading(true);
				var decodeParam = decryption(params.id);
				if(decodeParam===-1){
					navigation('/cms-variable');
					return;
				}
				setId(decodeParam);
				// get user and set form fields
				try {
					let record = await dispatch(getCmsVariableById(decodeParam)).unwrap();
					setValues(record);
					setLoading(false);
				} catch (error) {
					setLoading(false);
					navigation('/cms-variable');
					//if there is no data then it gives error msg "No records found"
					if (error.status === 404 && !error.success) {
						toast.error(error.message);
						navigation('/cms-variable');
					}
				}
			}
		};
		fetchById();
	}, []);

	return (
		<Container maxWidth="xl">
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
				<Typography variant="h4" gutterBottom>
					{id ? "Edit Variable" : "Add Variable"}
				</Typography>
				<Stack direction="row" alignItems="center" justifyContent="end">
					<Button
						variant="contained"
						component={RouterLink}
						to="/cms-variable"
						startIcon={<ArrowBackIcon />}
						style={{ marginLeft: '10px' }}
					>
						Back To List
					</Button>
				</Stack>
			</Stack>
			<Card className="holiday-table-grid" mb={5}>
				{loading ? (
					<Loader />
				) : (
					<Container maxWidth="xl">
						<Paper>
							<form onSubmit={handleSubmit}>
								<Grid container spacing={3}>
									<Grid item xs={12} mt={3}>
										<TextField
											fullWidth
											label="Variable Name"
											name="name"
											value={values.name}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(touched.name && errors.name)}
											helperText={touched.name && errors.name}
										/>
									</Grid>

									
									<Grid item xs={12}>
										<TextField
											fullWidth
											label="Variable Code"
											name="code"
											value={values.code}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(touched.code && errors.code)}
											helperText={touched.code && errors.code}
										/>
									</Grid>
									<Grid item xs={12}>
										<TextField
											fullWidth
											label="Default Value"
											name="default"
											value={values.default}
											onChange={handleChange}
											variant="outlined"
											size="small"
											inputProps={{ maxLength: 112 }}
											error={Boolean(
												touched.default && errors.default
											)}
											helperText={touched.default && errors.default}
										/>
									</Grid>
									
                                </Grid>
								<Stack direction="row" justifyContent="flex-end" my={3}>
									<LoadingButton
										type="submit"
										variant="contained"
										loading={isSubmitting}
									>
										save
									</LoadingButton>
								</Stack>
							</form>
						</Paper>
					</Container>
				)}
			</Card>
		</Container>
	);
}

const componentConfig = [
	{
		component: AddCmsVariable,
		path: '/cms-variable/add',
		public: false,
		layout: DashboardLayout,
		group: 'CMS',
		sidebar: true,
		role: ['admin'],
	},
	{
		component: AddCmsVariable,
		path: '/cms-variable/add/:id',
		public: false,
		layout: DashboardLayout,
		group: 'CMS',
		sidebar: true,
		role: ['admin'],
	},
];

export default componentConfig;