import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import NiceModal from "@ebay/nice-modal-react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import AddCollegeModel from "../../../../components/Model/AddCollegeModel";
import AddCourseModel from "../../../../components/Model/AddCourseModel";
import { getCollege } from "../../../../redux/college/collegeThunk";
import { getSkill } from "../../../../redux/skill/skillThunk";
import { getViewVisible } from "../../../../utils/userPermission";
import LocalStorage from "../../../../service/localStorage";
import { getUserPermissionById, getUserPermissionByRoll } from "../../../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../../../utils/encodeString";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

export default function Education(props) {
  const {
    getFieldProps,
    setFieldValue,
    values,
    // setErrors,
    handleChange,
    touched,
    errors,
    educationObject,
    handleTabChange,
  } = props;
  const dispatch = useDispatch();
  const educationData = useSelector((state) => state.education);
  const skillData = useSelector((state) => state.skill);
  const courseData = useSelector((state) => state.course);
  const technologyData = useSelector((state) => state.technology);
  const collegeStatus = useSelector((state) => state.college.status);
  const collegeData = useSelector((state) => state.college.data);

  //  console.log(collegeData,"educationData");
  const [value, setValue] = React.useState(null);
  const filter = createFilterOptions();
  const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=localStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
  useEffect(() => {
		// if (
		// 	!skillData.complete &&
		// 	!skillData.loading &&
		// 	!skillData.error &&
		// 	(!skillData.data ||
		// 		(Array.isArray(skillData.data) && skillData.data.length <= 0))
		// )
			dispatch(getSkill({sortBy: '', orderBy: ''}));

	},[dispatch]);
  const addEducation = () => {
    setFieldValue("education", [...values.education, educationObject]);
  };
  const removeEducation = (e, index) => {
    e.preventDefault();
    const temp = [...values.education];
    temp.splice(index, 1);
    setFieldValue("education", temp);
  };

  const handleAddCourse = () => {
    setFieldValue("course", [
      ...values.course,
      {
        courseName: "",
        instituteName: "",
        certificateUrl: "",
        skills: "",
      },
    ]);
  };

  useEffect(() => {
    // if (collegeStatus === "idle") {
      getViewVisible("college")&&dispatch(getCollege({sortBy: '', orderBy: ''}));
    // }
  }, [dispatch]);

  

  const removeCourse = (e, index) => {
    e.preventDefault();
    const temp = [...values.course];
    temp.splice(index, 1);
    setFieldValue("course", temp);
  };

  const handleCreatableSelecInstituteChange = (event, newValue, index) => {
    if (typeof newValue === "string") {
      let updatedValues = values.education.map((val, key) => {
        if (key === index) {
          return { ...val, instituteName: newValue, instituteId: null };
        } else {
          return val;
        }
      });
      setFieldValue("education", updatedValues);
      setValue({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      let updatedValues = values.education.map((val, key) => {
        if (key === index) {
          return {
            ...val,
            instituteName: newValue.inputValue,
            instituteId: null,
          };
        } else {
          return val;
        }
      });
      setFieldValue("education", updatedValues);
      setValue({
        title: newValue.inputValue,
      });
    } else {
      let updatedValues = values.education.map((val, key) => {
        if (key === index) {
          return {
            ...val,
            instituteId: newValue && newValue.id,
            instituteName: "",
          };
        } else {
          return val;
        }
      });
      setFieldValue("education", updatedValues);
      setValue(newValue);
    }
  };

  const handleCreatableSelecCourseChange = (event, newValue, index) => {
    if (typeof newValue === "string") {
      let updatedValues = values.education.map((val, key) => {
        if (key === index) {
          return { ...val, courseName: newValue, courseId: null };
        } else {
          return val;
        }
      });
      setFieldValue("education", updatedValues);
      setValue({
        title: newValue,
      });
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      let updatedValues = values.education.map((val, key) => {
        if (key === index) {
          return {
            ...val,
            courseName: newValue.inputValue,
            courseId: null,
          };
        } else {
          return val;
        }
      });
      setFieldValue("education", updatedValues);
      setValue({
        title: newValue.inputValue,
      });
    } else {
      let updatedValues = values.education.map((val, key) => {
        if (key === index) {
          return {
            ...val,
            courseId: newValue && newValue.id,
            courseName: "",
          };
        } else {
          return val;
        }
      });
      setFieldValue("education", updatedValues);
      setValue(newValue);
    }
  };
  // useEffect(()=>{
	// 	const keys = Object.keys(errors);
	// 	if(keys.length===1&&keys[0]==='experience'&&errors.experience.length<1){
	// 	  setErrors({})
	// 	}
	//   },[errors])

  console.log("EE_______01",errors)
  return (
    <Card className="right-side-content-part">
      <Container maxWidth="xl">
        <div className="education">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={3}
            style={{ marginTop: "0"}}
          >
            <Typography className="Employee_Detail-h6" variant="h6" gutterBottom style={{marginTop: "0"}}>
              Education Details
            </Typography>
            <Button
              className="header-add-button"
              variant="contained"
              onClick={addEducation}
              // startIcon={<AddIcon />}
            >
              <img src="/assets/images/add-circle.svg" />
              Add Education
            </Button>
          </Stack>
          {values &&
            values.education &&
            values.education.map((edu, index) => (
              <Grid
                container
                spacing={3}
                className="gray-box Employee_Detail_Content form-bottom-border"
                key={index}
              >
                <Grid item xs={4}>
                  {/* <InputLabel id="demo-simple-select-helper-label">
                      Education Type
                    </InputLabel>
                    <Select
                      label="Education Type"
                      size="small"
                      onChange={handleChange}
                      {...getFieldProps(`education[${index}].educationTypeId`)}
                    >
                      {educationData.data &&
                        educationData.data.map((edu, index) => (
                          <MenuItem key={index} value={edu.id}>
                            {edu.title}
                          </MenuItem>
                        ))}
                    </Select> */}

                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={educationData.data || []}
                    getOptionLabel={(option) => option.title || ""}
                    {...getFieldProps(`education[${index}].educationTypeId`)}
                    onChange={(event, newValue) => {
                      setFieldValue(
                        `education[${index}].educationTypeId`,
                        newValue?.id || ""
                      );
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          touched?.education &&
                            touched?.education[index] &&
                            touched?.education[index]?.educationTypeId &&
                            errors?.education &&
                            errors?.education[index] &&
                            errors?.education[index]?.educationTypeId
                        )}
                        helperText={
                          touched?.education &&
                          touched?.education[index] &&
                          touched?.education[index]?.educationTypeId &&
                          errors?.education &&
                          errors?.education[index] &&
                          errors?.education[index]?.educationTypeId
                        }
                        label="Education Type"
                      />
                    )}
                    value={educationData.data.find(
                      (education) =>
                        education.id === values.education[index].educationTypeId
                    )}
                    error={true}
                  />
                </Grid>
                <Grid item xs={7}>
                  {/*<Autocomplete
										size="small"
										value={
											values.education[index].instituteId
												? collegeData &&
												  collegeData
														.map((clg) => ({
															...clg,
															title: clg.collegeName,
														}))
														.find(
															(clg) =>
																clg.id === values.education[index].instituteId,
														)
												: { title: values.education[index].instituteName }
										}
										// value={values.education[index].instituteId}
										// value={values.education[index].instituteName}
										// value={value}
										onChange={(e, nv) =>
											handleCreatableSelectChange(e, nv, index)
										}
										filterOptions={(options, params) => {
											const filtered = filter(options, params);

											const { inputValue } = params;
											// Suggest the creation of a new value
											const isExisting = options.some(
												(option) => inputValue === option.title,
											);
											if (inputValue !== '' && !isExisting) {
												filtered.push({
													inputValue,
													title: `Add "${inputValue}"`,
												});
											}

											return filtered;
										}}
										selectOnFocus
										// clearOnBlur
										handleHomeEndKeys
										id="institute-name"
										options={
											collegeData &&
											collegeData.map((clg) => ({
												...clg,
												title: clg.collegeName,
											}))
										}
										getOptionLabel={(option) => {
											// Value selected with enter, right from the input
											if (typeof option === 'string') {
												return option;
											}
											// Add "xxx" option created dynamically
											if (option.inputValue) {
												return option.inputValue;
											}
											// Regular option
											return option.title;
										}}
										renderOption={(props, option) => (
											<li {...props}>{option.title}</li>
										)}
										// sx={{ width: 300 }}
										freeSolo
										renderInput={(params) => (
											<TextField
												{...params}
												error={Boolean(
													touched?.education &&
														touched?.education[index] &&
														touched?.education[index]?.instituteId &&
														errors?.education &&
														errors?.education[index] &&
														errors?.education[index]?.institute,
												)}
												helperText={
													touched?.education &&
													touched?.education[index] &&
													touched?.education[index]?.instituteId &&
													errors?.education &&
													errors?.education[index] &&
													errors?.education[index]?.institute
												}
												label="Institute Name"
											/>
										)}
											/>*/}
                  <Autocomplete
                    size="small"
                    disabled={getViewVisible("college") === false}
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={
                      collegeData &&
                      collegeData?.map((clg) => ({
                        ...clg,
                        title: clg.collegeName,
                      }))
                    }
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.title) {
                        return option.title;
                      }
                      // Regular option
                      return option.collegeName;
                    }}
                    {...getFieldProps(`education[${index}].instituteId`)}
                    onChange={async (event, newValue) => {
                      handleCreatableSelecInstituteChange(
                        event,
                        newValue,
                        index
                      );
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          touched?.education &&
                            touched?.education[index] &&
                            touched?.education[index]?.instituteId &&
                            errors?.education &&
                            errors?.education[index] &&
                            errors?.education[index]?.institute
                        )}
                        helperText={
                          touched?.education &&
                          touched?.education[index] &&
                          touched?.education[index]?.instituteId &&
                          errors?.education &&
                          errors?.education[index] &&
                          errors?.education[index]?.institute
                        }
                        label="Institute Name"
                      />
                    )}
                    value={
                      values.education[index].instituteId
                        ? collegeData &&
                          collegeData
                            .map((clg) => ({
                              ...clg,
                              title: clg.collegeName,
                            }))
                            .find(
                              (clg) =>
                                clg.id === values.education[index].instituteId
                            )
                        : values.education[index].instituteName
                        ? { title: values.education[index].instituteName }
                        : ""
                    }

                    /* error={true} */
                  />
                </Grid>

                <Grid item xs={1}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="end"
                  >
                    {values &&
                      values.education &&
                      Array.isArray(values.education) &&
                      values.education.filter((e) => !e.deleted).length >= 1 && (
                        <IconButton onClick={(e) => removeEducation(e, index)}>
                          <DeleteIcon color="error" />
                        </IconButton>
                      )}
                  </Stack>
                </Grid>
                {/*  */}
                <Grid item xs={4}>
                  <Autocomplete
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={courseData.data || []}
                    {...getFieldProps(`education[${index}].courseId`)}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.title) {
                        return option.title;
                      }
                      // Regular option
                      return option.title;
                    }}
                    {...getFieldProps(`education[${index}].courseId`)}
                    onChange={async (event, newValue) => {
                      handleCreatableSelecCourseChange(event, newValue, index);
                    }}
                    // onChange={async (event, newValue) => {
                    // 	event.preventDefault();
                    // 	if (typeof newValue === "string") {
                    // 		// timeout to avoid instant validation of the dialog's form.
                    // 		setTimeout(async () => {
                    // 			const res = await NiceModal.show(AddCourseModel, {
                    // 				name: newValue,
                    // 			});
                    // 			setFieldValue(`education[${index}].courseId`, res.id);
                    // 		});
                    // 	} else if (newValue && newValue.inputValue) {
                    // 		const res = await NiceModal.show(AddCourseModel, {
                    // 			name: newValue.inputValue,
                    // 		});
                    // 		setFieldValue(`education[${index}].courseId`, res.id);
                    // 	} else {
                    // 		setFieldValue(
                    // 			`education[${index}].courseId`,
                    // 			newValue.id,
                    // 		);
                    // 	}
                    // }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.title
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          title: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={Boolean(
                          touched?.education &&
                            touched?.education[index] &&
                            touched?.education[index]?.courseId &&
                            errors?.education &&
                            errors?.education[index] &&
                            errors?.education[index]?.courseId
                        )}
                        helperText={
                          touched?.education &&
                          touched?.education[index] &&
                          touched?.education[index]?.courseId &&
                          errors?.education &&
                          errors?.education[index] &&
                          errors?.education[index]?.courseId
                        }
                        label="Course"
                      />
                    )}
                    value={
                      values.education[index].courseId
                        ? courseData &&
                          courseData.data
                            .map((clg) => ({
                              ...clg,
                              title: clg.title,
                            }))
                            .find(
                              (clg) =>
                                clg.id === values.education[index].courseId
                            )
                        : values.education[index].courseName
                        ? { title: values.education[index].courseName }
                        : ""
                    }
                    /* value={
											courseData.data.find(
												(Course) =>
													Course.id === values.education[index].courseId,
											) || ""
										} */
                  />
                  {/*
                    <InputLabel id="demo-simple-select-helper-label">
                      Course
                    </InputLabel>

                    <Select
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Course"
                      size="small"
                      {...getFieldProps(`education[${index}].courseId`)}
                    >
                      {courseData.data &&
                        courseData.data.map((course, index) => (
                          <MenuItem key={index} value={course.id}>
                            {course.title}
                          </MenuItem>
                        ))}
                        </Select> */}
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    fullWidth
                    label="CGPA/Percentage"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 4 }}
                    helperText={
                      touched?.education &&
                      touched?.education[index] &&
                      touched?.education[index]?.percentage &&
                      errors?.education &&
                      errors?.education[index] &&
                      errors?.education[index]?.percentage
                    }
                    error={Boolean(
                      touched?.education &&
                        touched?.education[index] &&
                        touched?.education[index]?.percentage &&
                        errors?.education &&
                        errors?.education[index] &&
                        errors?.education[index]?.percentage
                    )}
                    {...getFieldProps(`education[${index}].percentage`)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Passing Year"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 4 }}
                    helperText={
                      touched?.education &&
                      touched?.education[index] &&
                      touched?.education[index]?.passingYear &&
                      errors?.education &&
                      errors?.education[index] &&
                      errors?.education[index]?.passingYear
                    }
                    error={Boolean(
                      touched?.education &&
                        touched?.education[index] &&
                        touched?.education[index]?.passingYear &&
                        errors?.education &&
                        errors?.education[index] &&
                        errors?.education[index]?.passingYear
                    )}
                    {...getFieldProps(`education[${index}].passingYear`)}
                  />
                </Grid>
              </Grid>
            ))}
        </div>

        <div className="other-skill">
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mt={4}
            mb={2}
          >
            <Typography variant="h4" className="Employee_Detail-h6" gutterBottom style={{fontSize:'18px', marginTop: "0"}}>
              
              Additional Certified Courses & Skills
            </Typography>
            <Button
            className="header-add-button"
              variant="contained"
              onClick={handleAddCourse}
              // startIcon={<AddIcon />}
            >
              <img src="/assets/images/add-circle.svg" />
              Add Courses
            </Button>
          </Stack>

          {values.course &&
            values.course.map((cr, index) => {
              return (
                <Grid
                  key={index}
                  container
                  spacing={3}
                  className="gray-box Employee_Detail_Content form-bottom-border"
                >
                  <Grid item xs={4}>
                    <TextField
                      fullWidth
                      label="Course Name"
                      name="Course Name"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 256 }}
                      {...getFieldProps(`course[${index}].courseName`)}
                      error={Boolean(
                        touched?.course &&
                          touched?.course[index] &&
                          touched?.course[index]?.courseName &&
                          errors?.course &&
                          errors?.course[index] &&
                          errors?.course[index]?.courseName
                      )}
                      helperText={
                        touched?.course &&
                        touched?.course[index] &&
                        touched?.course[index]?.courseName &&
                        errors?.course &&
                        errors?.course[index] &&
                        errors?.course[index]?.courseName
                      }
                    />
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      label="Certificate Url"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 512 }}
                      {...getFieldProps(`course[${index}].certificateUrl`)}
                      error={Boolean(
                        touched?.course &&
                          touched?.course[index] &&
                          touched?.course[index]?.certificateUrl &&
                          errors?.course &&
                          errors?.course[index] &&
                          errors?.course[index]?.certificateUrl
                      )}
                      helperText={
                        touched?.course &&
                        touched?.course[index] &&
                        touched?.course[index]?.certificateUrl &&
                        errors?.course &&
                        errors?.course[index] &&
                        errors?.course[index]?.certificateUrl
                      }
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="end"
                    >
                      {values &&
                        values.course &&
                        Array.isArray(values.course) &&
                        values.course.filter((e) => !e.deleted).length >= 1 && (
                          <IconButton onClick={(e) => removeCourse(e, index)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      size="small"
                      fullWidth
                      popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                      options={skillData.data || []}
                      getOptionLabel={(option) => option.title || ""}
                      {...getFieldProps(`course[${index}].technologyId`)}
                      value={
                        skillData.data.find(
                          (skill) =>
                            skill.id === values.course[index].technologyId
                        ) || ""
                      }
                      onChange={(event, newValue) => {
                        setFieldValue(
                          `course[${index}].technologyId`,
                          newValue?.id || ""
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Skills"
                          error={Boolean(
                            touched?.course &&
                              touched?.course[index] &&
                              touched?.course[index]?.technologyId &&
                              errors?.course &&
                              errors?.course[index] &&
                              errors?.course[index]?.technologyId
                          )}
                          helperText={
                            touched?.course &&
                            touched?.course[index] &&
                            touched?.course[index]?.technologyId &&
                            errors?.course &&
                            errors?.course[index] &&
                            errors?.course[index]?.technologyId
                          }
                        />
                      )}
                    />
                    {/* 
									<InputLabel id="demo-simple-select-helper-label">
										Skills
									</InputLabel>
									<Select
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="Skills"
										size="small"
										{...getFieldProps(`course[${index}].technologyId`)}
									>
										{technologyData.data &&
										technologyData.data.map((skill, index) => (
											<MenuItem key={index} value={skill.id}>
											{skill.title}
											</MenuItem>
										))}
									</Select>
										*/}
                  </Grid>
                  <Grid item xs={7}>
                    <TextField
                      fullWidth
                      label="Institute Name"
                      name="Institute Name"
                      variant="outlined"
                      size="small"
                      inputProps={{ maxLength: 256 }}
                      {...getFieldProps(`course[${index}].instituteName`)}
                      error={Boolean(
                        touched?.course &&
                          touched?.course[index] &&
                          touched?.course[index]?.instituteName &&
                          errors?.course &&
                          errors?.course[index] &&
                          errors?.course[index]?.instituteName
                      )}
                      helperText={
                        touched?.course &&
                        touched?.course[index] &&
                        touched?.course[index]?.instituteName &&
                        errors?.course &&
                        errors?.course[index] &&
                        errors?.course[index]?.instituteName
                      }
                    />
                  </Grid>
                </Grid>
              );
              <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
            })}
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            mb={3}
            mt={4}
          >
            <Button className="back-button" onClick={() => handleTabChange("1")} variant="contained" startIcon={<ArrowBackIcon />} style={{marginRight:'10px'}}>
              Back
            </Button>
            <Button onClick={() => handleTabChange("3")} variant="contained" endIcon={<ArrowForwardIcon />}>
              Next
            </Button>
          </Stack>
        </div>
      </Container>
    </Card>
  );
}
