import * as Yup from "yup";

const emailTemplateSchema = Yup.object().shape({
  title : Yup.string()
    .required("Title is required")
    .nullable(), 
  subject : Yup.string()
    .required("Subject is required")
    .nullable(),
});

export default emailTemplateSchema;