import { createSlice } from "@reduxjs/toolkit";
import { getAllEmployeesDropdown, getLinkCount, getLinks } from "./linksThunk";

const initialState = {
  data: [],
  loading: false,
  complete: false,
  sortBy: "title",
  orderBy: "asc",
  status: "idle",
  totalPages: 0,
  limit: 40,
  totalRecords: 0,
  currentPage: 0,
  error: null,
  isSearchQuery: false,
  totalLinksCount: 0,
  employeesData: [],  
  categories:[],
};

const linksSlice = createSlice({
  name: "links",
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload.sortBy;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload.orderBy;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.status = "idle";
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload.page;
      state.status = "idle";
    },
  },
  extraReducers: {
    [getLinks.pending]: (state) => {
      state.loading = true;
    },
    [getLinks.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.data = payload?.data?.list;
      state.totalPages = payload.data.totalPages;
      state.limit = payload.data.limit;
      state.totalRecords = payload.data.totalRecords;
      state.currentPage = payload.data.currentPage - 1;
      state.complete = true;
      state.status = "fulfilled";
      state.isSearchQuery = payload.filter;
      state.categories = payload?.data?.uniqueCategories || [];
    },
    [getLinks.rejected]: (state) => {
      state.loading = false;
      state.data = [];
      state.complete = true;
      state.status = "rejected";
      state.totalPages = 0;
      state.totalRecords = 0;
      state.currentPage = 0;
      state.categories = [];
    },
    [getAllEmployeesDropdown.pending]: (state) => {
      state.loading = true;
    },
    [getAllEmployeesDropdown.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.employeesData = payload?.employees || [];
      state.complete = true;
      state.status = "fulfilled";
    },
    [getAllEmployeesDropdown.rejected]: (state) => {
      state.loading = false;
      state.employeesData = [];
      state.complete = true;
      state.status = "rejected";
    },
    [getLinkCount.pending]: (state) => {
      state.loading = true;
    },
    [getLinkCount.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.totalLinksCount = payload || 0;
      state.complete = true;
      state.status = "fulfilled";
    },
    [getLinkCount.rejected]: (state) => {
      state.loading = false;
      state.totalLinksCount = 0;
      state.complete = true;
      state.status = "rejected";
    },
  },
});

export const linksReducer = linksSlice.reducer;
export const { setSortBy, setOrderBy, setLimit, setCurrentPage } =
  linksSlice.actions;
