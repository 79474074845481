import * as Yup from "yup";

const cmsVariable = Yup.object().shape({
  name: Yup.string()
    .required("Variable Name is required")
    .max(112, "Length of character must be less than 112")
    .nullable(),
  code: Yup.string().required("Variable Code is required").nullable(),
  default: Yup.string()
    .required("Default Value is required")
    .max(112, "Length of character must be less than 112")
    .nullable(),
 
});

export default cmsVariable;
