import React from "react";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { useConfirm } from "material-ui-confirm";
import Autocomplete from "@mui/material/Autocomplete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const emailsObj = { type: "", email: "" };

export default function Contact(props) {
    const {
        values,
        setFieldValue,
        touched,
        errors,
        handleTabChange,
        getFieldProps,
    } = props;
    const confirm = useConfirm();
    const addEmails = () => {
        setFieldValue("emails", [...values.emails, emailsObj]);
    };

    const emailType = ['Personal Email','Local Email','Company Email','Gmail','1sb Email','2ez Email'];

    const removeEmails = async (e, index) => {
        e.preventDefault();
        try {
            await confirm({
                description: `Are you sure you want to remove email?`,
            });
            const temp = [...values.emails];
            temp.splice(index, 1);
            setFieldValue("emails", temp);
        } catch (error) {}
    };

    return (
        <Card className="right-side-content-part">
            <Container className="Employee_Detail_card-wrapper" maxWidth="xl">
                <div>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={3}
                        mb={2}
                        className="Employee_Detail-heading"
                    >
                        <Typography className="Employee_Detail-h6" variant="h6" gutterBottom style={{marginTop: "0"}}>
                            Contact Details
                        </Typography>
                    </Stack>
                    <Grid className="gray-box Contact-Detail-content" container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Contact Number"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 10 }}
                                {...getFieldProps(`contact.contactNumber`)}
                                error={Boolean(
                                    touched?.contact?.contactNumber &&
                                        errors?.contact?.contactNumber
                                )}
                                helperText={
                                    touched?.contact?.contactNumber &&
                                    errors?.contact?.contactNumber
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Alternate Contact Number"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 10 }}
                                {...getFieldProps(
                                    `contact.alternateContactNumber`
                                )}
                                error={Boolean(
                                    touched?.contact?.alternateContactNumber &&
                                        errors?.contact?.alternateContactNumber
                                )}
                                helperText={
                                    touched?.contact?.alternateContactNumber &&
                                    errors?.contact?.alternateContactNumber
                                }
                            />
                        </Grid>
                        {/* <Grid item xs={6}>
							<TextField
								fullWidth
								label="Company E-mail"
								variant="outlined"
								size="small"
								inputProps={{ maxLength: 512 }}
								{...getFieldProps(`contact.companyEmail`)}
								error={Boolean(
									touched?.contact?.companyEmail && errors?.contact?.companyEmail
								)}
								helperText={
									touched?.contact?.companyEmail && errors?.contact?.companyEmail
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Personal E-mail"
								variant="outlined"
								size="small"
								inputProps={{ maxLength: 512 }}
								{...getFieldProps(`contact.personalEmail`)}
								error={Boolean(
									touched?.contact?.personalEmail &&
										errors?.contact?.personalEmail
								)}
								helperText={
									touched?.contact?.personalEmail &&
									errors?.contact?.personalEmail
								}
							/>
						</Grid> */}
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="LinkedIn"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 512 }}
                                {...getFieldProps(`contact.linkedIn`)}
                                error={Boolean(
                                    touched?.contact?.linkedIn &&
                                        errors?.contact?.linkedIn
                                )}
                                helperText={
                                    touched?.contact?.linkedIn &&
                                    errors?.contact?.linkedIn
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Skype"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 512 }}
                                {...getFieldProps(`contact.skype`)}
                                error={Boolean(
                                    touched?.contact?.skype &&
                                        errors?.contact?.skype
                                )}
                                helperText={
                                    touched?.contact?.skype &&
                                    errors?.contact?.skype
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Company Skype"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 512 }}
                                {...getFieldProps(`contact.companySkype`)}
                                error={Boolean(
                                    touched?.contact?.companySkype &&
                                        errors?.contact?.companySkype
                                )}
                                helperText={
                                    touched?.contact?.companySkype &&
                                    errors?.contact?.companySkype
                                }
                            />
                        </Grid>
                    </Grid>
                    <Stack my={3}>
                        <Divider />
                    </Stack>
                    {values &&
                        Array.isArray(values.emails) &&
                        values.emails?.length > 0 &&
                        values.emails.map((email, idx) => (
                            <>
                                <Grid className="gray-box mail-Detail-content" container spacing={3} mb={3}>
                                    <Grid item xs={4}>
                                        {/*<InputLabel id="demo-simple-select-helper-label">
                                            Email type
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Email type"
                                            size="small"
                                            {...getFieldProps(
                                                `emails[${idx}].type`
                                            )}
                                        >
                                            <MenuItem value="Personal Email">
                                                Personal Email
                                            </MenuItem>
                                            <MenuItem value="Local Email">
                                                Local Email
                                            </MenuItem>
                                            <MenuItem value="Company Email">
                                                Company Email
                                            </MenuItem>
                                            <MenuItem value="Gmail">
                                                Gmail
                                            </MenuItem>
                                            <MenuItem value="1sb Email">
                                                1sb Email
                                            </MenuItem>
                                            <MenuItem value="2ez Email">
                                                2ez Email
                                            </MenuItem>
                                        </Select> */}
                                        <Autocomplete
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="Email type"
                                            size="small"
                                            popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}

                                            {...getFieldProps(
                                            `emails[${idx}].type`
                                            )}
                                            //name="emailType"
                                            onChange={(event, newValue) => {
                                            setFieldValue(
                                                `emails[${idx}].type`,
                                                newValue || ""
                                            );
                                            }}
                        
                                            //value={values.emailType}
                                            options={emailType || []}
                                            renderInput = {(params) => 
                                                <TextField {...params} label="Email Type" 
                                                    error={Boolean(
                                                        touched?.emails &&
                                                            touched?.emails[idx] &&
                                                            touched?.emails[idx]
                                                                ?.type &&
                                                            errors?.emails &&
                                                            errors?.emails[idx] &&
                                                            errors?.emails[idx]?.type
                                                    )}   
                                                    helperText = {touched?.emails &&
                                                        touched?.emails[idx] &&
                                                        touched?.emails[idx]
                                                            ?.type &&
                                                        errors?.emails &&
                                                        errors?.emails[idx] &&
                                                        errors?.emails[idx]?.type}
                                                />
                                            }   
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="email"
                                            variant="outlined"
                                            size="small"
                                            inputProps={{ maxLength: 512 }}
                                            {...getFieldProps(
                                                `emails[${idx}].email`
                                            )}
                                            helperText={
                                                touched?.emails &&
                                                touched?.emails[idx] &&
                                                touched?.emails[idx]?.email &&
                                                errors?.emails &&
                                                errors?.emails[idx] &&
                                                errors?.emails[idx]?.email
                                            }
                                            error={Boolean(
                                                touched?.emails &&
                                                    touched?.emails[idx] &&
                                                    touched?.emails[idx]
                                                        ?.email &&
                                                    errors?.emails &&
                                                    errors?.emails[idx] &&
                                                    errors?.emails[idx]?.email
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <IconButton onClick={() => addEmails()}>
                                            <AddIcon></AddIcon>
                                        </IconButton>
                                        {idx !== 0 && (
                                            <IconButton
                                                onClick={(e) =>
                                                    removeEmails(e, idx)
                                                }
                                            >
                                                <RemoveIcon></RemoveIcon>
                                            </IconButton>
                                        )}
                                    </Grid>
                                </Grid>
                            </>
                        ))}
                </div>

                <div className="other-skill">
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        mt={4}
                        mb={2}
                        className="Employee_Detail-heading"
                    >
                        <Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
                            Emergency Contact
                        </Typography>
                    </Stack>

                    <Grid className="gray-box Emergency-Contact-content" container spacing={3}>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Name"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 64 }}
                                {...getFieldProps(
                                    `contact.emergencyContactName`
                                )}
                                error={Boolean(
                                    touched?.contact?.emergencyContactName &&
                                        errors?.contact?.emergencyContactName
                                )}
                                helperText={
                                    touched?.contact?.emergencyContactName &&
                                    errors?.contact?.emergencyContactName
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Relation With Employee"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 32 }}
                                {...getFieldProps(
                                    `contact.relationWithEmployee`
                                )}
                                error={Boolean(
                                    touched?.contact?.relationWithEmployee &&
                                        errors?.contact?.relationWithEmployee
                                )}
                                helperText={
                                    touched?.contact?.relationWithEmployee &&
                                    errors?.contact?.relationWithEmployee
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                fullWidth
                                label="Contact Number"
                                variant="outlined"
                                size="small"
                                inputProps={{ maxLength: 10 }}
                                {...getFieldProps(
                                    `contact.emergencyContactNumber`
                                )}
                                error={Boolean(
                                    touched?.contact?.emergencyContactNumber &&
                                        errors?.contact?.emergencyContactNumber
                                )}
                                helperText={
                                    touched?.contact?.emergencyContactNumber &&
                                    errors?.contact?.emergencyContactNumber
                                }
                            />
                        </Grid>
                    </Grid>

                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="flex-end"
                        mb={3}
                        mt={4}
                    >
                        <Button
                            onClick={() => handleTabChange("1")}
                            variant="contained"
                            style={{marginRight:'15px'}}
                            startIcon={<ArrowBackIcon />}
                            className="back-button"
                        >
                            Back
                        </Button>
                        <Button
                            onClick={() => handleTabChange("3")}
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                        >
                            Next
                        </Button>
                    </Stack>
                </div>
            </Container>
        </Card>
    );
}
