import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import { getEmployeeById } from "../../redux/employee/employeeThunk";
import { decryption } from "../../utils/encodeString";
import MenuPopover from "../../components/MenuPopover";

import account from "../../_mocks_/account";
import { BASE_URL } from "../../constants";

const MENU_OPTIONS = [
	{
		label: "Home",
		icon: <HomeIcon />,
		linkTo: "/",
	},
	{
		label: "Profile",
		icon: <PersonIcon />,
		linkTo: "#",
	},
	{
		label: "Settings",
		icon: <SettingsIcon />,
		linkTo: "#",
	},
];

export default function AccountPopover({ logOut }) {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const dispatch = useDispatch();
	const fullName = useSelector((state) => state.auth.fullName);
	const employeeId = useSelector((state) => state.auth.role);
	const designation = useSelector((state) => state.auth.designation);
	const employeeImage = useSelector((state) => state?.auth?.image);
	let image = localStorage?.getItem('image')
	image = image?.replace(/^"|"$/g, '');
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				// onClick={handleOpen}
				data-cy="btn-button-navbar"
				className="personal-box-profile"
				sx={{
					padding: 0,
					width: 48,
					height: 48,
					...(open && {
						"&:before": {
							zIndex: 1,
							content: "''",
							width: "100%",
							height: "100%",
							borderRadius: "50%",
							position: "absolute",
							bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
						},
					}),
				}}
			>
				<Avatar alt={fullName} src={image ? `${BASE_URL}/${image}` : `${BASE_URL}/${employeeImage}`} />
			</IconButton>
			<Box className="header-personal-box" sx={{ my: 1.5, px: 2.5 }}>
					<Typography className="header-personal-box-title" variant="subtitle1" noWrap title="Employee name">
						{fullName}
					</Typography>
					<Typography className="header-personal-box-subtitle" variant="body2" noWrap title="Employee id">
						{designation ? designation : ""}
					</Typography>
				</Box>
				<Box className="header-personal-button" sx={{ p: 2, pt: 1.5 }}>
					<Button
						onClick={logOut}
						fullWidth
						color="inherit"
						variant="outlined"
						data-cy="btn-button-logout"
						title="Logout"
					>
						<img src="/assets/images/logout.svg" />
						{/* Logout */}
					</Button>
				</Box>
			{/* <MenuPopover
				open={open}
				onClose={handleClose}
				anchorEl={anchorRef.current}
				sx={{ width: 220 }}
			>
				<Box sx={{ my: 1.5, px: 2.5 }}>
					<Typography variant="subtitle1" noWrap>
						{fullName}
					</Typography>
					<Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
						{employeeId}
					</Typography>
				</Box>

				<Divider sx={{ my: 1 }} />

				{/* {MENU_OPTIONS.map((option) => (
					<MenuItem
						key={option.label}
						to={option.linkTo}
						component={RouterLink}
						onClick={handleClose}
						sx={{ typography: 'body2', py: 1, px: 2.5 }}
					>
						<Box
							component={Icon}
							icon={option.icon}
							sx={{
								mr: 2,
								width: 24,
								height: 24,
							}}
						/>

						{option.label}
					</MenuItem>
				))} */}

				{/* <Box sx={{ p: 2, pt: 1.5 }}>
					<Button
						onClick={logOut}
						fullWidth
						color="inherit"
						variant="outlined"
						data-cy="btn-button-logout"
					>
						Logout
					</Button>
				</Box>
			</MenuPopover> */}
		</>
	);
}
