import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EditIcon from "@mui/icons-material/Edit";
import WorkIcon from "@mui/icons-material/Work";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import DownloadIcon from "@mui/icons-material/Download";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { decryption, encryption } from "../../../utils/encodeString";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  getCandidateById,
  getCandidateFromResume,
  getEmployeesForDropdown,
  getEsCandidate,
} from "../../../redux/candidate/candidateThunk";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../constants";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";

export default function Personal(props) {
  const {
    values,
    handleChange,
    touched,
    errors,
    handleTabChange,
    setSelectedFile,
    setFieldValue,
    setSelectedFileUrl,
    selectedFileUrl,
    candidateResume,
    setCandidateResume,
    setToBeDeletedCandidateResume,
    designationData,
    designationDisable,
    candidateId,
    jobId,
    setCandidateUpdateId,
  } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fileInput = useRef(null);
  const candidateResumeFileInput = useRef(null);
  const technologyData = useSelector((state) => state.technology);
  const reffrenceData = useSelector((state) => state.candidate.dropDownList);
  const loading = useSelector((state) => state.candidate.loading);
  let candidateJob = [
    { jobId: jobId, jobSource: "Linkedin" },
    { jobId: jobId, jobSource: "Naukari" },
    { jobId: jobId, jobSource: "Reference" },
  ];
  // const [source, setSource] = useState();
  const esCandidateData = useSelector((state) => state.candidate.data);
  useEffect(() => {
    if (!candidateId) {
      dispatch(
        getEsCandidate({
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          contactNumber: values.contactNumber,
          alternateContactNumber: values.alternateContactNumber,
        })
      );
    } /*  else {
			dispatch(
				getEsCandidate({
					candidateId: candidateId,
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					contactNumber: values.contactNumber,
					alternateContactNumber: values.alternateContactNumber,
				}),
			);
		} */
  }, [
    values.firstName,
    values.lastName,
    values.email,
    values.contactNumber,
    values.alternateContactNumber,
  ]);

  useEffect(() => {
    if (values.candidateJobs[0]?.jobSource == "Reference") {
      dispatch(getEmployeesForDropdown());
    }
  }, [dispatch, values]);
  const getCandidateData = async (id) => {
    if (id) {
      // setIsLoading(true);
      dispatch(getCandidateById(id))
        .unwrap()
        .then((res) => {
          // setFirstName(res.data.firstName);
          // let candidateImage = res.data.candidateImage
          //   ? `${BASE_URL}/${res.data.candidateImage}`
          //   : null;
          // console.log("2");
          // setSelectedFileUrl(candidateImage);

          setCandidateUpdateId(id);

          setCandidateResume(res.data.candidateResume);

          setFieldValue("firstName", res.data.firstName);
          setFieldValue("lastName", res.data.lastName);
          setFieldValue("contactNumber", res.data.contactNumber);
          setFieldValue("email", res.data.email);
          setFieldValue("gender", res.data.gender);
          setFieldValue(
            "alternateContactNumber",
            res.data.alternateContactNumber
          );

          setFieldValue("dateOfBirth", res.data.dateOfBirth);
          setFieldValue("linkedIn", res.data.linkedIn);
          setFieldValue("skype", res.data.skype);
          setFieldValue("technologies", res.data.technologies);
          setFieldValue("totalExperience", res.data.totalExperience);
          // setFieldValue("candidateJobs", [
          //   {
          //     jobId: jobId,
          //     jobSource:
          //       res.data.candidateJobs && res.data.candidateJobs[0].jobSource,
          //   },
          // ]);
          // setSource({
          //   jobId: jobId,
          //   jobSource:
          //     res.data.candidateJobs && res.data.candidateJobs[0].jobSource,
          // });
          // setStatus({});
          // mapTechnologyIds(res.data.experience);
          // setIsLoading(false);
        })
        .catch((err) => {
          toast.error(err.message);
          // return navigate("/candidate");
        });
    }
  };

  useEffect(() => {
    Array.isArray(technologyData.data) &&
      technologyData.data.length === 0 &&
      // dispatch(getTechnology());
      dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  }, []);
  const handleImageChange = (e) => {
    var reader = new FileReader();

    reader.onload = function (e) {
      setSelectedFileUrl(e.target.result);
    };

    reader.readAsDataURL(e.target.files[0]);
    setSelectedFile(e.target.files[0]);
  };

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }
    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };

  const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["jpeg", "jpg", "png"];
      return imageTypes.includes(file.name.split(".")[1]);
    }
    return file && file["type"] && file["type"].split("/")[0] === "image";
  };

  const handleEmployeeResumeImageChange = (e) => {
    dispatch(
      getCandidateFromResume({ candidateResume: e.target.files[0] })
    ).then((res) => {
      setFieldValue("candidateResume", e.target.files[0]);
      let finalData = res.payload.data || res.payload;

      let ids = technologyData?.data?.filter((e) =>
        finalData.skills.some(
          (item) => item.toLowerCase() === e?.title?.toLowerCase()
        )
      );
      // console.log(ids,"pd");
      setFieldValue("technologies", ids || []);
      setFieldValue(
        "firstName",
        finalData?.first_name && finalData?.first_name !== ""
          ? finalData?.first_name
          : values.firstName
      );
      setFieldValue(
        "lastName",
        finalData?.last_name && finalData?.last_name !== ""
          ? finalData?.last_name
          : values.lastName
      );
      setFieldValue(
        "contactNumber",
        finalData?.mobile && finalData?.mobile[0]
          ? finalData?.mobile[0].length > 10
            ? finalData?.mobile[0].substr(-10)
            : finalData?.mobile[0]
          : values.contactNumber
      );
      setFieldValue(
        "email",
        finalData?.email ? finalData?.email : values.email
      );
      setFieldValue(
        "skype",
        finalData?.social_links && finalData?.social_links?.skype !== ""
          ? finalData.social_links.skype
          : values.skype
      );
      setFieldValue(
        "linkedIn",
        finalData?.social_links && finalData?.social_links?.linkdin !== ""
          ? finalData.social_links.linkdin
          : values.linkdin
      );
      setFieldValue(
        "gender",
        finalData?.gender && finalData?.gender !== ""
          ? finalData?.gender
          : values.gender
      );
      setFieldValue(
        "totalExperience",
        finalData?.total_exp && finalData?.total_exp !== ""
          ? finalData?.total_exp
          : values.totalExperience
      );
    });
    setCandidateResume(e.target.files[0]);
  };

  const handleDeleteCandidateResume = (image) => {
    setCandidateResume(null);
    setFieldValue("candidateResume", "");
    setToBeDeletedCandidateResume(image?.id);
  };

  return (
    <Card className="right-side-content-part">
      <Container maxWidth="xl" sx={{ mt: 2 }}>
        {/* <Typography variant="h6" my={3}>
          Personal Details
        </Typography> */}

        <Grid container spacing={3}>
          {/* <Grid item xs={12}>
            <div className="profile-image-part">
              <div className="circle">
                <Box
                  component="img"
                  src={selectedFileUrl || "/assets/images/profile.png"}
                />
                <div className="p-image">
                  <i
                    className="upload-button"
                    onClick={() => {
                      fileInput.current.click();
                    }}
                  >
                    <PhotoCameraIcon />
                  </i>
                  <input
                    ref={fileInput}
                    className="file-upload"
                    type="file"
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </div>
              </div>
            </div>
          </Grid> */}
         
         
          <Grid container spacing={3} pb={3} pt={3} ml={1}>
            {esCandidateData && esCandidateData?.length > 0
              ? esCandidateData.map((candidate, idx) => {
                  return candidate.score && candidate.score >= 40 ? (
                    <Grid item xs={3} className="candidate-box">
                      <Item style={{ border: "1px solid #f0f0f0" }}>
                        <Stack
                          color="primary.contrastText"
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          pl={"5px"}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ gridGap: "5px" }}
                          >
                            <h4
                              style={{
                                fontSize: 17,
                                fontWeight: 600,
                              }}
                            >
                              {candidate.firstName || ""}{" "}
                              {candidate.lastName || ""}
                            </h4>
                            <VerifiedUserIcon
                              color="info"
                              style={{ fontSize: 16 }}
                            />
                          </Stack>
                          <span className="profile-complition">
                            {candidate.score}%
                          </span>
                          <IconButton
                            variant="contained"
                            // component={RouterLink}
                            // to={`/candidate/add/${encryption(candidate?.id)}`}
                            onClick={() =>
                              getCandidateData(candidate?.candidateId)
                            }
                            style={{ height: 32, width: 32 }}
                          >
                            <EditIcon
                              color="primary"
                              style={{ fontSize: 20 }}
                            />
                          </IconButton>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          my={1.5}
                          style={{ gridGap: "5px" }}
                        >
                          <PhoneIphoneIcon color="primary" />
                          <Typography>
                            <Link
                              href={`tel:${candidate.contactNumber}`}
                              underline="hover"
                              color="primary.contrastText"
                              style={{ fontSize: 14 }}
                            >
                              {candidate.contactNumber || ""}
                            </Link>
                            {candidate.contactNumber &&
                            candidate.alternateContactNumber ? (
                              <span>&nbsp;|&nbsp;</span>
                            ) : (
                              ""
                            )}
                            <Link
                              href={`tel:${candidate.alternateContactNumber}`}
                              underline="hover"
                              color="primary.contrastText"
                              style={{ fontSize: 14 }}
                            >
                              {candidate.alternateContactNumber || ""}
                            </Link>
                          </Typography>
                        </Stack>

                        <Stack
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                          my={1.5}
                          style={{ gridGap: "5px", textAlign: "left" }}
                        >
                          <EmailIcon
                            color="primary"
                            style={{ fontSize: 20, marginTop: "2px" }}
                          />
                          <Typography style={{ lineHeight: "20px" }}>
                            <Link
                              href={`mailto:${candidate.email}`}
                              underline="hover"
                              color="primary.contrastText"
                              style={{ fontSize: 14 }}
                              className="candidate-email"
                            >
                              {candidate.email || ""}
                            </Link>
                          </Typography>
                        </Stack>
                      </Item>
                    </Grid>
                  ) : (
                    ""
                  );
                })
              : ""}
          </Grid>
          <Grid className="checkList-grid-address-grid" item xs={12} mb={2}>
            {/* <Stack mb={1}>
              <Typography>Candidate Resume</Typography>
            </Stack> */}
            <Grid className="checkList-grid-address-detail" container>
              <Grid
                item
                xs={12}
                // onClick={() => {
                //   if (candidateResume) {
                //     if (checkIfImage(candidateResume)) {
                //       window.open(candidateResume.webViewLink, "_blank");
                //     } else {
                //       window.open(candidateResume.webViewLink, "_blank");
                //     }
                //   }
                // }}
              >
                <div
                  className="custome-uploadBox small profile-image-part"
                  onClick={(e) => {
                    values.candidateResume
                      ? !values?.candidateResume?.webViewLink
                        ? window.open(
                            URL.createObjectURL(values?.candidateResume),
                            "_blank"
                          )
                        : window.open(
                            values?.candidateResume?.webViewLink,
                            "_blank"
                          )
                      : candidateResumeFileInput.current.click();
                  }}
                >
                  {loading ? (
                    <Loader />
                  ) : (
                    candidateResume && (
                      <>
                        {checkIfImage(candidateResume) ? (
                          <>
                            <InsertDriveFileIcon
                            // onClick={(e) => {
                            //   window.open(
                            //     candidateResume.webViewLink,
                            //     "_blank"
                            //   );
                            // }}
                            />
                            {/* <Box
																component="img"
																src={getImageUrl(
																	values.presentAddressImage
																)}
															/> */}
                            <Typography variant="caption">
                              {getFileName(candidateResume)}
                            </Typography>
                          </>
                        ) : (
                          <>
                            <InsertDriveFileIcon
                            // onClick={(e) => {
                            //   window.open(
                            //     candidateResume.webViewLink,
                            //     "_blank"
                            //   );
                            // }}
                            />
                            <Typography variant="caption">
                              {getFileName(candidateResume)}
                            </Typography>
                          </>
                        )}
                      </>
                    )
                  )}
                  {candidateResume && (
                    <CancelRoundedIcon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteCandidateResume(candidateResume);
                      }}
                      className="closeIcon"
                      style={{
                        position: "absolute",
                        top: "-7px",
                        right: "-6px",
                      }}
                    />
                  )}
                  <div className="p-image">
                    {!candidateResume && (
                      <i className="upload-button">
                        {/* <AddCircleIcon /> */}
                        <CloudUploadIcon />
                      </i>
                    )}

                    {!candidateResume && (
                      <input
                        ref={candidateResumeFileInput}
                        className="file-upload"
                        type="file"
                        accept=".doc, .docx, .pdf"
                        onChange={handleEmployeeResumeImageChange}
                      />
                    )}
                    {!candidateResume && (
                      <>
                        <div className="file-support-text">
                          Upload Candidate Resume
                        </div>
                        <div className="file-support-text">
                          (File support: pdf, png and jpg with max 20MB size)
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </Grid>
            </Grid>
            {/* <FormHelperText
                                    error={Boolean(errors?.employeeResume)}
                                >
                                    {touched?.employeeResume &&
                                        errors?.employeeResume}
                                </FormHelperText> */}
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="First Name"
              name="firstName"
              variant="outlined"
              size="small"
              onChange={handleChange}
              inputProps={{ maxLength: 64 }}
              value={values.firstName}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Last Name"
              name="lastName"
              variant="outlined"
              size="small"
              onChange={handleChange}
              inputProps={{ maxLength: 64 }}
              value={values.lastName}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Contact Number"
              name="contactNumber"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 10 }}
              InputLabelProps={{
                shrink: values.contactNumber ?? false,
              }}
              value={values.contactNumber}
              onChange={handleChange}
              error={Boolean(touched.contactNumber && errors.contactNumber)}
              helperText={touched.contactNumber && errors.contactNumber}
              // focused={values.contactNumber ?? false}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="E-mail"
              name="email"
              variant="outlined"
              size="small"
              inputProps={{ maxLength: 512 }}
              value={values.email}
              onChange={handleChange}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Alternate Contact Number"
              name="alternateContactNumber"
              variant="outlined"
              size="small"
              value={values.alternateContactNumber}
              inputProps={{ maxLength: 10 }}
              onChange={handleChange}
              error={Boolean(
                touched.alternateContactNumber && errors.alternateContactNumber
              )}
              helperText={
                touched.alternateContactNumber && errors.alternateContactNumber
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Skype"
              name="skype"
              variant="outlined"
              size="small"
              value={values.skype}
              inputProps={{ maxLength: 512 }}
              onChange={handleChange}
              error={Boolean(touched.skype && errors.skype)}
              helperText={touched.skype && errors.skype}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="LinkedIn"
              name="linkedIn"
              variant="outlined"
              size="small"
              value={values.linkedIn}
              inputProps={{ maxLength: 1024 }}
              onChange={handleChange}
              error={Boolean(touched.linkedIn && errors.linkedIn)}
              helperText={touched.linkedIn && errors.linkedIn}
            />
          </Grid>

          {/* <Grid item xs={6}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DesktopDatePicker
								label="Date of Birth"
								value={values.dateOfBirth}
								onChange={(newValue) => {
									setFieldValue('dateOfBirth', newValue);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
										helperText={touched.dateOfBirth && errors.dateOfBirth}
										fullWidth
										size="small"
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={6} mb={3}>
						<TextField
							fullWidth
							label="Referred By"
							name="referedBy"
							variant="outlined"
							size="small"
							onChange={handleChange}
							value={values.referedBy}
							error={Boolean(touched.referedBy && errors.referedBy)}
							helperText={touched.referedBy && errors.referedBy}
						/>
					</Grid> */}
          {/* <Grid item xs="6"></Grid> */}
          <Grid item xs={6} mt={2} className="radio-select-space">
            <FormControl
              component="fieldset"
              size="small"
              error={Boolean(touched.gender && errors.gender)}
            >
              <FormLabel component="legend">Gender</FormLabel>
              <RadioGroup
                row
                aria-label="gender"
                name="gender"
                onChange={handleChange}
                value={values.gender}
              >
                <FormControlLabel
                  value="M"
                  control={<Radio size="small" />}
                  label="Male"
                  style={{ color: "#637381" }}
                />
                <FormControlLabel
                  value="F"
                  control={<Radio size="small" />}
                  label="Female"
                  style={{ color: "#637381" }}
                />
              </RadioGroup>
              <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched.gender && errors.gender}
              </FormHelperText>
            </FormControl>
          </Grid>

          {/* <Grid item xs={6} className="radio-select-space">
						<FormControl
							error={Boolean(touched.maritalStatus && errors.maritalStatus)}
							helperText={touched.maritalStatus && errors.maritalStatus}
							component="fieldset"
							size="small"
						>
							<FormLabel component="legend">Marital Status</FormLabel>
							<RadioGroup
								row
								aria-label="gender"
								name="maritalStatus"
								onChange={handleChange}
								value={values.maritalStatus}
							>
								<FormControlLabel
									value="single"
									control={<Radio size="small" />}
									label="Single"
								/>
								<FormControlLabel
									value="married"
									control={<Radio size="small" />}
									label="Married"
								/>
							</RadioGroup>
							<FormHelperText>
								{touched.maritalStatus && errors.maritalStatus}
							</FormHelperText>
						</FormControl>
					</Grid> */}
          {/* <Grid container spacing={3}></Grid> */}

          {/* <Grid item xs={6} mb={2}>
            <FormControl
              fullWidth
              error={Boolean(touched?.designation && errors?.designation)}
            >
              <Autocomplete
                size="small"
                fullWidth
                value={values.designation}
                options={designationData.data || []}
                key={designationData.data.Id || []}
                getOptionLabel={(option) => option.title || ""}
                isOptionEqualToValue={(option, value) => option === value}
                name="designationId"
                renderInput={(params) => (
                  <TextField {...params} label="Designation" />
                )}
                disabled={designationDisable}
              />

              <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched?.designation && errors?.designation}
              </FormHelperText>
            </FormControl>
          </Grid> */}

          {/* <Grid item xs={6} mt={1} mb={2} className="checkbox-part-space">
            <FormGroup row>
              <FormControlLabel
                control={<Checkbox checked={values.wishlist} />}
                label="Wishlist"
                name="wishlist"
                onChange={handleChange}
                value={values.wishlist}
              />
            </FormGroup>
          </Grid> */}
          <Grid item xs={6} mb={2}>
            <FormControl
              fullWidth
              error={Boolean(touched?.technologies && errors?.technologies)}
            >
              <Autocomplete
                multiple
                size="small"
                fullWidth
                popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                defaultValue={[]}
                options={
                  technologyData?.data?.filter((x) => {
                    return !values.technologies.map((y) => y.id).includes(x.id);
                  }) || []
                }
                key={technologyData.data || []}
                getOptionLabel={(option) => option.title || ""}
                // {...getFieldProps(`technologies`)}
                onChange={(event, newValue) => {
                  let tempArray = newValue.map((x) => {
                    return { id: x.id, title: x.title };
                  });

                  setFieldValue(`technologies`, tempArray || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    helperText={touched?.technologies && errors?.technologies}
                    error={Boolean(
                      touched?.technologies && errors?.technologies
                    )}
                    label="Technology"
                  />
                )}
                value={values.technologies.filter((x) => {
                  return x.id;
                })}
              />
              {/* <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched?.technologies && errors?.technologies}
              </FormHelperText> */}
            </FormControl>
          </Grid>
          <Grid item xs={6} mb={2}>
            <FormControl
              fullWidth
              error={Boolean(touched?.candidateJobs && errors?.candidateJobs)}
            >
              <Autocomplete
                size="small"
                fullWidth
                popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                // onBlur={handleBlur}
                options={candidateJob || []}
                getOptionLabel={(option) => option.jobSource || ""}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFieldValue(`candidateJobs`, [newValue]);
                  } else {
                    setFieldValue(`candidateJobs`, []);
                  }

                  // setSource(newValue);
                }}
                value={values.candidateJobs[0]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Candidate Source"
                    helperText={touched?.candidateJobs && errors?.candidateJobs}
                    error={Boolean(
                      touched?.candidateJobs && errors?.candidateJobs
                    )}
                    // InputLabelProps={{
                    //   shrink:
                    //     JSON.stringify(values.candidateJobs[0]) ==
                    //       JSON.stringify(candidateJob[0]) ?? false,
                    // }}
                  />
                )}
                // value={values.candidateJobs[0]}
              />
              {/* <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched?.candidateJobs && errors?.candidateJobs}
              </FormHelperText> */}
            </FormControl>
          </Grid>
          {/* {console.log(reffrenceData,"pd1")} */}
          {values.candidateJobs[0]?.jobSource == "Reference" ? (
            <Grid item xs={6} mb={2}>
              <FormControl
                fullWidth
                error={Boolean(touched?.referedBy && errors?.referedBy)}
              >
                <Autocomplete
                  // multiple
                  size="small"
                  fullWidth
                  // defaultValue={''}
                  options={
                    reffrenceData?.filter((x) => {
                      return values.referedBy != x.id;
                    }) || []
                  }
                  key={reffrenceData || []}
                  getOptionLabel={(option) =>
                    `${option.firstName} ${option.lastName}` || ""
                  }
                  // {...getFieldProps(`technologies`)}
                  onChange={(event, newValue) => {
                    setFieldValue(`referedBy`, newValue.id || null);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      helperText={touched?.referedBy && errors?.referedBy}
                      error={Boolean(touched?.referedBy && errors?.referedBy)}
                      label="Referral Name"
                    />
                  )}
                  // value={values.referedBy!=''?reffrenceData.find((x)=>x.id==values.referedBy):''}
                  value={reffrenceData.find(
                    (dept) => dept.id == values.referedBy
                  )}
                  // value={values.referedBy?reffrenceData?.filter((x) => {
                  //   return x.id==values.referedBy;
                  // })[0]:''}
                />
                {/* <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched?.referedBy && errors?.referedBy}
              </FormHelperText> */}
              </FormControl>
            </Grid>
          ) : (
            ""
          )}
          <Grid item xs={6} mb={2}>
            <TextField
              fullWidth
              type="number"
              label="Total Experience"
              name="totalExperience"
              variant="outlined"
              size="small"
              value={values.totalExperience}
              inputProps={{ maxLength: 10 }}
              onChange={handleChange}
              error={Boolean(touched.totalExperience && errors.totalExperience)}
              helperText={touched.totalExperience && errors.totalExperience}
            />
          </Grid>
        </Grid>
        {/* <Grid item xs={12}>
          <Grid container pt={3} sx={{ borderTop: "1px solid #dce0e4" }}>
           
          </Grid>
        </Grid> */}
        <Stack direction="row" justifyContent="flex-end" mt={2} mb={3}>
          {/* <Button variant="contained">Next</Button> */}
        </Stack>
      </Container>
    </Card>
  );
}
