import React, { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useConfirm } from "material-ui-confirm";
import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { debounce, uniq } from "lodash";
import Chip from "@mui/material/Chip";
import invertDirection from "../../../utils/invertDirection";
import { itemAssetMapping } from "../../../validations/mastersSchema";
import {
  getAllAssetsMapping,
  insertMapping,
  updateMapping,
  deleteMapping,
  getAssestMapCount
} from "../../../redux/itemAssetMapping/thunk";
import { getAsset } from "../../../redux/itAsset/thunk";
import { getAllItems } from "../../../redux/assestManagment/thunk";
import {
  setLimit,
  setCurrentPage,
  setSortBy,
  setOrderBy,
} from "../../../redux/itemAssetMapping/itemAssetMappingSlice";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../../utils/encodeString";
import LocalStorage from "../../../service/localStorage";
import CustomPagination from "../../../components/Pagination";
function Index() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();

  const {
    data,
    loading,
    sortBy,
    orderBy,
    currentPage,
    status,
    limit,
    totalRecords,
	totalPages,
  totalAssetMap

  } = useSelector((state) => state.ItemAssetMapping);

  const assetData = useSelector((state) => state.getAsset);
  const itemData = useSelector((state) => state.inventory);
  const [newItemData, setNewItemData] = useState();
  const inputRef = React.useRef(null);
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("assetItemMapping")) {
      dispatch(
        getAllAssetsMapping({
          page: currentPage ,
          limit: limit,
          orderBy: orderBy,
          sortBy: sortBy,
          search: "",
        })
      );
      navigate("/dashboard");
    }
  }, []);

  // console.log(assetData)
  // console.log(itemData)

  /*useEffect(() => {
    dispatch(getAsset({ sortBy, orderBy }));
  },[]);

  useEffect(() => {
    dispatch(getAllItems({ sortBy, orderBy }));
  },[]);*/
  //   const getUserPermission = async (Id) => {
  // 	try {
  // 		const res = await dispatch(
  // 			getUserPermissionByRoll({
  // 				id: Id,
  // 			}),
  // 		).unwrap();
  // 		localStorage.setItem(
  // 			"userPermissions",
  // 			encryption(JSON.stringify(res.data)),
  // 		);
  // 			console.log(res.data,"db");
  // 	} catch (error) {

  // 	}
  // };
  // useEffect(() => {
  // 	let roleId=localStorage.getItem("roleId");
  // 	getUserPermission(roleId)
  // },[dispatch])

  useEffect(() => {
    dispatch(
      getAllAssetsMapping({
        page: currentPage ,
        limit: limit,
        orderBy: orderBy,
        sortBy: sortBy,
        search: "",
      })
    );
  }, [sortBy, orderBy, limit,currentPage,dispatch]);

  useEffect(() => {
    if (
      !itemData.complete &&
      !itemData.loading &&
      !itemData.error &&
      (!itemData.data ||
        (Array.isArray(itemData.data) && itemData.data.length <= 0))
    )
      getViewVisible("items") && dispatch(getAllItems());
  }, [dispatch]);

  useEffect(() => {
    if (
      !assetData.complete &&
      !assetData.loading &&
      !assetData.error &&
      (!assetData.data ||
        (Array.isArray(assetData.data) && assetData.data.length <= 0))
    )
      getViewVisible("assetMapping") && dispatch(getAsset());
  }, [dispatch]);

  const [id, setId] = React.useState(null);
  const searchFormRef = useRef();
  const formik = useFormik({
    initialValues: {
      assetId: "",
      itemId: "",
      isRequired: false,
      hasMany: false,
    },

    validationSchema: itemAssetMapping,

    onSubmit: async (values, { resetForm }) => {
      //console.log(values)
      try {
        /*if (id) {
          await dispatch(updateMapping({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(insertMapping(values)).unwrap();
        }*/
        await dispatch(insertMapping(values)).unwrap();
        resetForm();
        dispatch(
          getAllAssetsMapping({
            page: currentPage ,
            limit: limit,
            orderBy: orderBy,
            sortBy: sortBy,
            search: "",
          })
        );
        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });

  const onDeleteChip = async () => {
    setSearchValue("");
    setIsSearching(false);
    inputRef.current.value = "";
    await dispatch(
      getAllAssetsMapping({
        page: currentPage + 1,
        limit: limit,
        orderBy,
        sortBy,
        search: "",
      })
    );
  };
  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    isSubmitting,
    getFieldProps,
    setFieldValue,
  } = formik;

  const handleUpdate = (asset) => {
    setValues({
      assetId: asset.assetId,
      itemId: asset.itemId,
      hasMany: !!asset.hasMany,
      isRequired: !!asset.isRequired,
    });
    //setId(asset.id);
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  const handleSearch = async (e) => {
    // setSearchValue(e?.target?.value);
    // setFieldValue("search", e?.target?.value || "");
    // handleSubmit();
    setSearchValue(e?.target?.value);
    if (e?.target?.value != "") {
      setIsSearching(true);
      await dispatch(
        getAllAssetsMapping({
          page: currentPage ,
          limit: limit,
          orderBy,
          sortBy,
          search: e?.target?.value,
        })
      );
    } else {
      setIsSearching(false);
      await dispatch(
        getAllAssetsMapping({
          page: currentPage ,
          limit: limit,
          orderBy,
          sortBy,
          search: "",
        })
      );
    }
    setSearchValue(e?.target?.value);
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const handleAssets = (assetId) => {
    let temp = itemData?.data
    let a = [];
    const res = dispatch(
      getAllAssetsMapping({
        orderBy: orderBy,
        sortBy: sortBy,
      })
    ).unwrap().then((res) => {
      // console.log(res)
      res?.map((d) => {
        if(assetId == d?.assetId)
        {
          a = [...a, d.itemId]
        }
      })
      temp = temp.filter(val => !a.includes(val.id));
      setNewItemData(temp)
    });
  }

  const handleReset = async () => {
    searchFormRef.current.reset();
    await dispatch(
      getAllAssetsMapping({
        page: currentPage ,
        limit: limit,
        orderBy: orderBy,
        sortBy: sortBy,
      })
    );
  };

  const handleDelete = async (id, name) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${name}?`,
      });
      await dispatch(deleteMapping(id));
      await dispatch(
        getAllAssetsMapping({
          page: currentPage ,
          limit: limit,
          orderBy: orderBy,
          sortBy: sortBy,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleChangePage = async (event, newPage) => {
    await dispatch(setCurrentPage({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = async (event) => {
    // await dispatch(setcurrentPage({ page: 1 }));
    await dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleHasManyToggle = async (asset) => {
    try {
      await dispatch(
        updateMapping({
          assetId: asset.assetId,
          itemId: asset.itemId,
          isRequired: !!asset.isRequired,
          hasMany: !asset.hasMany,
        })
      ).unwrap();
      dispatch(
        getAllAssetsMapping({
          page: currentPage ,
          limit: limit,
          orderBy: orderBy,
          sortBy: sortBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleRequiredToggle = async (asset) => {
    try {
      await dispatch(
        updateMapping({
          assetId: asset.assetId,
          itemId: asset.itemId,
          hasMany: !!asset.hasMany,
          isRequired: !asset.isRequired,
        })
      ).unwrap();
      dispatch(
        getAllAssetsMapping({
          page: currentPage ,
          limit: limit,
          orderBy: orderBy,
          sortBy: sortBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() =>{
 dispatch(getAssestMapCount())
  },[])
  return (
    <Container className="Asset-Item-Mapping-wrapper" maxWidth="xl">
    <div className="title-button-wrapper">
      <Typography variant="h4" gutterBottom mb={3}>
        Asset Item Mapping ({totalAssetMap || 0})
        {/* <Typography variant="body2">
          Total Records : {totalAssetMap || 0}
        </Typography> */}
      </Typography>
      </div>

      {getAddVisible("assetItemMapping") && (
        <Card>
          <Container maxWidth="xl">
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className="custom-space-layout"
            >
              <Typography variant="h5" mt={0} pt={2} mb={2}>
                Add Assets
              </Typography>

              <Grid container spacing={3} py={3}>
                <Grid item xs={4}>
                  <Autocomplete
                    labelid="demo-simple-select-helper-label"
                    disabled={!getViewVisible("assetMapping")}
                    id="demo-simple-select-helper"
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    name="assetId"
                    options={assetData.data || []}
                    getOptionLabel={(option) => option?.name || ""}
                    {...getFieldProps("assetId")}
                    onChange={(event, newValue) => {
                      setFieldValue(`assetId`, newValue?.id || "");
                      handleAssets(newValue?.id);
                      debounceWithSearch();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Asset"
                        error={touched?.assetId && errors?.assetId}
                        helperText={touched?.assetId && errors?.assetId}
                      />
                    )}
                    value={
                      assetData.data.find(
                        (asset) => asset.id == values.assetId
                      ) || ""
                    }
                    error={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Autocomplete
                    labelid="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    disabled={getViewVisible("items") === false}
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    name="itemId"
                    options={newItemData ? newItemData : itemData.data || []}
                    getOptionLabel={(option) => option?.name || ""}
                    {...getFieldProps("itemId")}
                    onChange={(event, newValue) => {
                      setFieldValue(`itemId`, newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Item"
                        error={touched?.itemId && errors?.itemId}
                        helperText={touched?.itemId && errors?.itemId}
                      />
                    )}
                    value={
                      itemData.data.find((item) => item.id == values.itemId) ||
                      ""
                    }
                    error={true}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Stack className="checkbox-part">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.hasMany}
                            onChange={formik.handleChange}
                            name="hasMany"
                            inputProps={{ "data-cy": "chk-hasMany-item" }}
                            size="small"
                          />
                        }
                        label="Has Many"
                      />
                    </Stack>
                    <Stack className="checkbox-part">
                      <FormControlLabel
                        control={
                          <Switch
                            checked={formik.values.isRequired}
                            onChange={formik.handleChange}
                            name="isRequired"
                            inputProps={{ "data-cy": "chk-isRequired-item" }}
                            size="small"
                          />
                        }
                        label="Required"
                      />
                    </Stack>
                    <Stack className="button-part">
                      <LoadingButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        data-cy="btn-submit-ItemAssetMapping"
                      >
                        save
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Card>
      )}


      
<Card className="custom-grid">
        <Container style={{ maxWidth: "100%", paddingTop: "15px" }}>
          <form className="employee-search-detail" onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-search-detail-grid" container xs={12} pt={2} pb={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Search by Asset Name"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
              </Grid>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearching ? (
          <Chip
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={() => onDeleteChip()}
          />
        ) : (
          ""
        )}
        </Stack>

        {isSearching ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid">
        {/* <Scrollbar> */}
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Table aria-label="simple table" className="grid-table">
              <TableHead>
                <TableRow>
                  <TableCell width="25%">
                    <TableSortLabel
                      active={sortBy === "assetName"}
                      direction={sortBy === "assetName" ? orderBy : "asc"}
                      onClick={() => handleSorting("assetName")}
                    >
                      Asset
                    </TableSortLabel>
                  </TableCell>

                  <TableCell width="25%">
                    <TableSortLabel hideSortIcon="true">Item</TableSortLabel>
                  </TableCell>

                  <TableCell width="20%">
                    <TableSortLabel hideSortIcon="true">
                      Has Many
                    </TableSortLabel>
                  </TableCell>

                  <TableCell width="20%">
                    <TableSortLabel hideSortIcon="true">
                      Required
                    </TableSortLabel>
                  </TableCell>

                  {getEditVisible("assetItemMapping") && (
                    <TableCell
                      width="10%"
                      align="right"
                      className="action-field"
                    >
                      <TableSortLabel hideSortIcon="true">
                        Actions
                      </TableSortLabel>
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                 <TableCell align="center" width="100%" colSpan={10}>
				 <Loader />
			   </TableCell>
                ) : Array.isArray(data) && data?.length === 0 ? (
                  <TableRow>
                    <TableCell className="No-Record-text" align="center" colSpan={3}>
                      No Record(s) Found
                    </TableCell>
                  </TableRow>
                ) : (
                  data?.map((asset, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": {
                          border: 0,
                        },
                      }}
                    >
                      <TableCell component="td" scope="row">
                        {asset?.assetMaster?.name}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        {asset?.itemName}
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <Switch
                          checked={!!asset?.hasMany}
                          onChange={() => handleHasManyToggle(asset)}
                          size="small"
                        />
                      </TableCell>
                      <TableCell component="td" scope="row">
                        <Switch
                          checked={!!asset?.isRequired}
                          onChange={() => handleRequiredToggle(asset)}
                          size="small"
                        />
                      </TableCell>
                      {getEditVisible("assetItemMapping") && (
                        <TableCell className="td-action-button" align="right">
                          <div className="action-button">
                            {getEditVisible("assetItemMapping") && (
                              <IconButton
                                data-cy="btn-edit-holiday"
                                onClick={() => handleUpdate(asset)}
                              >
                                {/* <EditIcon color="primary" /> */}
                                <img src="assets/images/edit.svg" title="Edit" />
                              </IconButton>
                            )}
                            {/*<IconButton
                              data-cy="btn-delete-holiday"
                              onClick={() =>
                                handleDelete(asset.id, asset.itemName)
                              }
                            >
                              <DeleteIcon color="error" />
                            </IconButton>*/}
                          </div>
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
            <CustomPagination
              totalPage={totalPages}
              totalCount={totalRecords}
              limit={limit}
              handleChangePage={handleChangePage}
              // page={currentPage}
              page={currentPage > 0 ? currentPage - 1 : 0}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        {/* </Scrollbar> */}
      </Card>
    </Container>
  );
}

export default Index;
