import {
  Autocomplete,
  Button,
  Container,
  Divider,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import DashboardLayout from "../../layouts/dashboard";
import React, { useState, useEffect } from "react";
import "./parking-allocation.css";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import {
  createparkingAllocation,
  exportAllparkingAllocation,
  getAllparkingAllocation,
  getAllparkingDetailsForFile,
  gethavecarEmployee,
  removeparkingAllocation,
} from "../../redux/parkingAllocation/parkingThunk";
import Loader from "../../components/Loader";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import {
  getAddVisible,
  getDeleteVisible,
  getEditVisible,
  getViewVisible,
} from "../../utils/userPermission";
import {
  setAddparkingData,
  setRemoveparkingData,
} from "../../redux/parkingAllocation/parkingallocationSlice";
import { BASE_URL } from "../../constants";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { Image, PDFDownloadLink, View } from "@react-pdf/renderer";
import VehicleTable from "./ParkingAllocationComponents/VehicleTable";
import Letterhead from "./ParkingAllocationComponents/LetterHead";
import Footer from "./ParkingAllocationComponents/Footer";
import { Document, Page, StyleSheet } from "@react-pdf/renderer";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import moment from "moment";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 410,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    border: "1px solid black",
  },
}));

export { LightTooltip } ;

function ParkingAllocation() {
  const dispatch = useDispatch();
  const parkingBuilding = useSelector(
    (payload) => payload?.parkingAllocation?.data
  );
  const [open, setOpen] = React.useState(false);
  const [allocated, setAllocated] = React.useState("");
  const [noteError, setNoteError] = useState({ flag: false, title: "" });
  const [exportData, setExportData] = useState([]);
  const loading = useSelector((payload) => payload?.parkingAllocation?.loading);
  const isSubmitting = useSelector(
    (payload) => payload?.parkingAllocation?.isSubmitting
  );
  const carEmployee = useSelector(
    (payload) => payload?.parkingAllocation?.carEmployee
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formik = useFormik({
    initialValues: {
      employeeId: "",
      parkingBlock: "",
      parkingNumber: "",
    },
  });
  const {
    handleSubmit,
    values,
    setFieldValue,
    getFieldProps,
    handleChange,
    setValues,
    touched,
    errors,
    handleBlur,
  } = formik;

  const VehicleTooltip = ({ employeeName, vehicleDetails }) => {
    return (
      <div className="vehicle-tooltip">
        {employeeName && (
          <>
            <p className="employee-name">{employeeName}</p>
            <Divider />
          </>
        )}

        <div>
          <p className="vehicle-details">Vehicle Details</p>

          {vehicleDetails &&
            vehicleDetails?.length > 0 &&
            vehicleDetails?.map(
              (e, index) =>
                e?.vehicleType == "CAR" && (
                  <div key={index} className="vehicle-details-div">
                    <p>
                      <b>Vehicle Name: </b>
                      {e?.vehicleName}
                    </p>
                    <p>
                      <b>Vehicle Number:</b>
                      {e?.vehicleNumber}
                    </p>
                    {e?.remarks && (
                      <p>
                        <b>Remarks:</b>
                        {e?.remarks}
                      </p>
                    )}
                    {vehicleDetails?.length - 1 !== index && <Divider />}
                  </div>
                )
            )}
        </div>
      </div>
    );
  };

  const CommonSlot = ({ parkingNumber, idx }) => (
    <Stack className="parking-number gray-box" key={idx}>
      <Typography variant="body1" className="parkingnumber-text">
        {parkingNumber?.number}
      </Typography>
      <img
        src={
          parkingNumber?.free
            ? "assets/images/parking_logo.png"
            : "assets/images/car_black.png"
        }
        alt={parkingNumber?.free ? "C" : "P"}
        className="car-img"
      />
    </Stack>
  );

  const fetchData = async () => {
    await dispatch(getAllparkingAllocation());
    await dispatch(gethavecarEmployee());
    try {
      const response = dispatch(getAllparkingDetailsForFile())
        .unwrap()
        .then((res) => {
          let data = [];
          res?.data?.map((item, index) => {
            data.push({ ...item, srNo: index + 1 });
          });
          const chunks = chunkData(data || [], 34);
          setExportData(chunks);
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const openModal = (dataEmp) => {
    setFieldValue("employeeId", dataEmp?.employeeId || "");
    setAllocated(dataEmp);
    handleOpen();
  };

  const closeModal = () => {
    setFieldValue("employeeId", "");
    setAllocated("");
    setNoteError({ flag: false, title: "" });
    handleClose();
  };

  const handleRemoveAllocate = async () => {
    let body = {
      parkingNumber: allocated?.parkingNumber,
      parkingBlock: allocated?.parkingBlock,
    };
    await dispatch(removeparkingAllocation(body)).unwrap();
    await dispatch(setRemoveparkingData(body));
    closeModal();
  };

  const handleAddAllocate = async (reallocateBoolean) => {
    setNoteError({
      flag: false,
      title: "",
    });
    if (values?.employeeId) {
      let checkIsempcar = {};
      parkingBuilding?.map((slot) => {
        slot?.parkingNumbers?.map((num) => {
          if (num?.employeeId == values?.employeeId) {
            checkIsempcar = { ...num };
          }
        });
      });

      if (reallocateBoolean || !checkIsempcar?.employeeId) {
        let body = { ...allocated, employeeId: values?.employeeId };
        let emp = carEmployee?.find((x) => x.employeeId === values?.employeeId);
        let employeeName = `${emp.firstName} ${emp.lastName}`;
        await dispatch(createparkingAllocation(body)).unwrap();
        await dispatch(
          setAddparkingData({
            ...body,
            employeeName: employeeName,
            employeeVehicles: emp?.employeeVehicles,
          })
        );
        closeModal();
      } else {
        toast.error(
          `${checkIsempcar?.employeeName} already has Parking number ${checkIsempcar?.number} !`
        );
      }
    } else {
      setNoteError({
        flag: true,
        title: "Please select employee",
      });
    }
  };

  const chunkData = (data, rowsPerPage) => {
    const chunks = [];
    for (let i = 0; i < data.length; i += rowsPerPage) {
      chunks.push(data.slice(i, i + rowsPerPage));
    }
    return chunks;
  };

  const MyDocument = () => (
    <Document>
      {exportData &&
        exportData?.length > 0 &&
        exportData?.map((chunk, index) => (
          <Page size="A4" style={{ padding: "20px 40px" }} key={index}>
            <Letterhead />
            <VehicleTable data={chunk} />
            <Footer pageNumber={index + 1} totalPages={exportData?.length} />
          </Page>
        ))}
    </Document>
  );

  const exportFile = async () => {
    try {
      const response = await dispatch(getAllparkingDetailsForFile())
        .unwrap()
        .then(async (res) => {
          let data = [];
          res?.data?.map((item, index) => {
            data.push({ ...item, srNo: index + 1 });
          });
          const chunks = chunkData(data || [], 34);
          setExportData(chunks);
          const workbook = new ExcelJS.Workbook();
          const vehicleSheet = workbook.addWorksheet(
            "Vehicles & Parking Details"
          );

          vehicleSheet.columns = [
            { header: "Sr No.", key: "srNo", width: 10 },
            { header: "Employee Id", key: "employeeId", width: 20 },
            { header: "Employee Name", key: "name", width: 20 },
            { header: "Vehicle Name", key: "vehicleName", width: 20 },
            { header: "Vehicle Number", key: "vehicleNumber", width: 20 },
            { header: "Vehicle Type", key: "vehicleType", width: 20 },
            { header: "Parking Number", key: "parkingNumber", width: 20 },
            { header: "Parking Block", key: "parkingBlock", width: 20 },
            { header: "Remarks", key: "remarks", width: 20 },
          ];

          res &&
            res?.data?.length > 0 &&
            res?.data?.forEach((el, index) => {
              vehicleSheet.addRow({
                srNo: index + 1,
                employeeId: el?.employeeId || "",
                name: el?.name || "",
                vehicleName: el?.vehicleName || "",
                vehicleNumber: el?.vehicleNumber || "",
                vehicleType: el?.vehicleType || "",
                parkingNumber: el?.parkingNumber || "",
                parkingBlock: el?.parkingBlock || "",
                remarks: el?.remarks || "",
              });
            });

          vehicleSheet.getRow(1).eachCell((cell) => {
            cell.alignment = { horizontal: "center", vertical: "center" };
            cell.font = { bold: true };
          });

          // Generate buffer and save file
          const buffer = await workbook.xlsx.writeBuffer();
          const TIME = moment().format("YYYYMMDDHHmmss");
          const FILE_NAME = `Vehicles_${TIME}.xlsx`;

          saveAs(new Blob([buffer]), FILE_NAME);
        });
    } catch (error) {
      toast.error(error?.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container
      className="Employee-wrapper Allocate-parking-container"
      maxWidth="xl"
    >
      <Stack>
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Parking Allocation
          </Typography>
          <div className="employee-header-right">
            {getViewVisible("parkingAllocation") && (
              <div className="export-buttons">
                {exportData && exportData?.length > 0 && (
                  <PDFDownloadLink
                    document={<MyDocument />}
                    fileName="cybercom_creation_B_305.pdf"
                    style={{ textDecoration: "none", color: "white" }}
                  >
                    {({ blob, url, loading, error }) => (
                      <Button
                        className="filter-icon-part"
                        variant="contained"
                        startIcon={<PictureAsPdfIcon />}
                      >
                        {" "}
                        Export as pdf
                      </Button>
                    )}
                  </PDFDownloadLink>
                )}
                <Button
                  variant="contained"
                  onClick={exportFile}
                  // startIcon={<FileUploadSharpIcon />}
                  className="filter-icon-part"
                  title="Export Employees"
                >
                  <img src="/assets/images/export.svg" />
                  Export as excel
                </Button>
              </div>
            )}
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <Stack>
            <Grid container spacing={3}>
              {parkingBuilding?.map((slot, index) => (
                <React.Fragment key={index}>
                  <Grid className="grid-item col" item xs={3} key={index + 65}>
                    <Stack>
                      <Typography variant="h2" className="block-title">
                        {slot?.block}
                      </Typography>
                      <Typography variant="h6" className="block-title-static">
                        Block
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid className="grid-item col" item xs={9} key={index + 22}>
                    <Stack flexDirection="row">
                      {slot?.parkingNumbers?.map((parkingNumber, idx) => (
                        <>
                          {!parkingNumber?.free ? (
                            <LightTooltip
                              title={
                                <VehicleTooltip
                                  employeeName={parkingNumber.employeeName}
                                  vehicleDetails={parkingNumber?.vehicleDetails}
                                />
                              }
                              placement="top"
                              key={idx}
                            >
                              <div
                                onClick={() =>
                                  openModal({
                                    employeeId: parkingNumber?.employeeId,
                                    parkingBlock: slot?.block,
                                    parkingNumber: parkingNumber?.number,
                                  })
                                }
                              >
                                <CommonSlot
                                  parkingNumber={parkingNumber}
                                  idx={idx}
                                  key={idx}
                                />
                              </div>
                            </LightTooltip>
                          ) : (
                            <div
                              onClick={() =>
                                openModal({
                                  employeeId: parkingNumber?.employeeId,
                                  parkingBlock: slot?.block,
                                  parkingNumber: parkingNumber?.number,
                                })
                              }
                            >
                              <CommonSlot
                                parkingNumber={parkingNumber}
                                idx={idx}
                                key={idx}
                              />
                            </div>
                          )}
                        </>
                      ))}
                    </Stack>
                  </Grid>

                  <Grid className="grid-item col" item xs={12}>
                    {parkingBuilding?.length - 1 != index && (
                      <Stack my={3}>
                        <Divider />
                      </Stack>
                    )}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </Stack>
        )}
      </Stack>

      <Modal
        open={
          open &&
          (getDeleteVisible("parkingAllocation") ||
            getEditVisible("parkingAllocation") ||
            getAddVisible("parkingAllocation"))
        }
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form>
          <Stack spacing={2} sx={style}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Autocomplete
                  size="small"
                  fullWidth
                  defaultValue=""
                  options={carEmployee || []}
                  getOptionLabel={(option) =>
                    option?.firstName
                      ? `${option?.firstName} ${option?.lastName}`
                      : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} value={option.id} key={option.id}>
                      {`${option.firstName} ${option.lastName}` || ""}
                    </li>
                  )}
                  onChange={(e, newValue) => {
                    setFieldValue(`employeeId`, newValue?.employeeId || "");
                    if (newValue) {
                      setNoteError({ flag: false, title: "" });
                    }
                    if (newValue == null) {
                      setNoteError({
                        flag: true,
                        title: "Please select employee",
                      });
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Employee"
                      helperText={noteError?.title && noteError?.title}
                      error={Boolean(noteError?.flag && noteError?.flag)}
                    />
                  )}
                  value={
                    carEmployee?.find(
                      (x) => x.employeeId === values?.employeeId
                    ) || ""
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                  {allocated?.employeeId &&
                    getDeleteVisible("parkingAllocation") && (
                      <Button
                        variant="contained"
                        onClick={handleRemoveAllocate}
                        style={{
                          fontSize: "13px",
                          margin: "10px 10px 0px 0px",
                        }}
                      >
                        Remove Allocate
                      </Button>
                    )}

                  {allocated?.employeeId &&
                    getEditVisible("parkingAllocation") && (
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={() => handleAddAllocate(true)}
                        style={{
                          margin: "10px 10px 0px 0px",
                          fontSize: "13px",
                          height: "35px",
                        }}
                      >
                        Reallocate
                      </LoadingButton>
                    )}

                  {!allocated?.employeeId &&
                    getAddVisible("parkingAllocation") && (
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={() => handleAddAllocate(false)}
                        style={{
                          margin: "10px 10px 0px 0px",
                          fontSize: "13px",
                          height: "35px",
                        }}
                      >
                        Allocate
                      </LoadingButton>
                    )}
                  <Button
                    variant="contained"
                    onClick={closeModal}
                    style={{
                      fontSize: "13px",
                      marginTop: "auto",
                      height: "35px",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Stack>
        </form>
      </Modal>
    </Container>
  );
}

const componentConfig = [
  {
    component: ParkingAllocation,
    path: "/parkingAllocation",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
