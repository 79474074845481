import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getAllQuestions = createAsyncThunk(
	//action type string
	"question/getQuestions",
	// callback function
	async (params, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/getALLQuestions", params, {
			headers:{
				type:'view',module:'questions'
			}
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data?.message || error.message);
		}
	},
);

export const getQuestionById = createAsyncThunk(
	//action type string
	"question/getQuestionsById",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.get(`question/`, { headers: { question_id: id,type:'view',module:'questions' } });
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const addQuestion = createAsyncThunk(
	//action type string
	"question/addQuestion",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.post("/question", body,{
				headers:{
					type:'create',module:'questions'
				}
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);
export const deleteQuestionById = createAsyncThunk(
	//action type string
	"question/deleteQuestionById",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.delete("/question", { headers: { question_id:id,type:'delete',module:'questions' } });
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const updateQuestionById = createAsyncThunk(
	//action type string
	"question/updateQuestionById",
	// callback function
	async (body, { rejectWithValue }) => {
		try {
			const res = await Axios.put("/question", body, {
				headers: { question_id: body.question_id, type:'update',module:'questions' },
			});
			toast.success(res.data.message);
			return res.data;
		} catch (error) {
			return rejectWithValue(error?.response?.data || error.message);
		}
	},
);

export const getAllSections = createAsyncThunk(
	//action type string
	"question/getAllSections",
	// callback function
	async (id, { rejectWithValue }) => {
		try {
			const res = await Axios.get(`getAllSections/`, { headers: {type:'view',module:'questions' } });
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);


export const getAllQuestionSectionWise = createAsyncThunk(
	//action type string
	"question/getAllQuestionSectionWise",
	// callback function
	async ( { rejectWithValue }) => {
		try {
			const res = await Axios.get('/getAllQuestionSectionWise',{ headers: {type:'view',module:'questions' } });
			// console.log("RES",res)
			return res.data.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	},
);

export const getTotalQuestion = createAsyncThunk(
	"question/getTotalQuestion",
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/getQuestionCount",{
        headers: { type: 'view',module:'questions' },
      });

			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.message || error.message);
		}
	},
);
