import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";

import Scrollbar from "../../../components/Scrollbar";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import { debounce } from "lodash";
import Chip from "@mui/material/Chip";
import CloseIcon from "@mui/icons-material/Close";
import { manufacturer } from "../../../validations/mastersSchema";
import {
  getManufacturer,
  updateById,
  deleteById,
  insert,
  getManufacturerCount,
} from "../../../redux/manufacturer/manufacturerThunk";

import {
  setSortBy,
  setOrderBy,
  setLimit,
  setcurrentPage
} from "../../../redux/manufacturer/manufacturerSlice";
import invertDirection from "../../../utils/invertDirection";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../../utils/encodeString";
import LocalStorage from "../../../service/localStorage";
import CustomPagination from "../../../components/Pagination";
function Index() {
  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const searchInput = useRef();
  const { data, loading, sortBy, orderBy ,currentPage,totalPages,limit,totalRecords} = useSelector(
    (state) => state.manufacturer
  );

  const [id, setId] = React.useState(null);
  const searchFormRef = useRef();
  const [isSearchApplied, setIsSearchApplied] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const totalCounts = useSelector(
    (state) => state.manufacturerCount.totalCounts
  );
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("manufacturers")) {
      dispatch(getManufacturer({ sortBy, orderBy }));
      navigate("/dashboard");
    }
  }, []);

  const handleChangePage = async (event, newPage) => {
    await dispatch(setcurrentPage({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = async (event) => {
    // await dispatch(setcurrentPage({ page: 1 }));
    await dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  const formik = useFormik({
    initialValues: {
      title: "",
      isActive: false,
    },
    validationSchema: manufacturer,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (id) {
          await dispatch(updateById({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(insert(values)).unwrap();
        }
        resetForm();

        searchFormRef.current.reset();

        dispatch(getManufacturer({ sortBy, orderBy }));
        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    isSubmitting,
  } = formik;
  React.useEffect(() => {
    dispatch(getManufacturer({ sortBy, orderBy , page : currentPage , limit : limit ,title: searchValue}));
  }, [dispatch, sortBy, orderBy,limit,currentPage]);

  const handleUpdate = (data) => {
    setValues({
      title: data.title,
      isActive: data.isActive,
    });
    setId(data.id);
  };

  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteById(id));
      // await dispatch(getManufacturer({ sortBy, orderBy }));
      handleSearch();
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleToggle = async (department) => {
    try {
      await dispatch(
        updateById({
          id: department.id,
          title: department.title,
          isActive: !department.isActive,
        })
      ).unwrap();
      dispatch(getManufacturer());
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  const handleSearch = async (e) => {
    if (searchInput.current.value) {
      setSearchValue(searchInput.current.value);
      setIsSearchApplied(true);
    } else {
      setIsSearchApplied(false);
    }
    await dispatch(
      getManufacturer({ title: searchInput.current.value, sortBy, orderBy,page : 1 , limit : 40 })
    );
  };
  const debounceWithSearch = debounce(handleSearch, 500);

  const handleReset = async () => {
    searchFormRef.current.reset();
    await dispatch(getManufacturer({ sortBy, orderBy }));
  };
  const onDeleteChip = () => {
    // console.log("delete")
    setIsSearchApplied(false);
    setSearchValue("");
    searchInput.current.value = null;
    handleReset();
  };
  useEffect(() => {
    dispatch(getManufacturerCount({}));
  }, [dispatch,data]);



  return (
    <Container className="Manufacturer-wrapper" maxWidth="xl">
     <div className="title-button-wrapper">
      <Typography variant="h4" gutterBottom mb={3}>
        Manufacturers ({totalCounts})
        {/* <Typography variant="body2">Total Records : {totalCounts}</Typography> */}
      </Typography>
      </div>

      {getAddVisible("manufacturers") && (
        <Card>
          <Container maxWidth="xl">
            <form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              className="custom-space-layout Manufacturer-custom-form"
            >
              <Typography variant="h5" mt={2} mb={-1}>
                Add Manufacturer
              </Typography>
              <Grid container spacing={3} py={3}>
                <Grid item xs={3}>
                  <TextField
                    id="outlined-basic"
                    label="Enter Manufacturer Title"
                    variant="outlined"
                    size="small"
                    autoComplete="off"
                    name="title"
                    inputProps={{
                      maxLength: 256,
                      "data-cy": "txt-title-manufacturer",
                    }}
                    FormHelperTextProps={{
                      "data-cy": "txt-title-err-manufacturer",
                    }}
                    value={values.title}
                    onChange={handleChange}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={3}>
                  <Stack direction="row" alignItems="center">
                    <Stack className="checkbox-part">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={formik.values.isActive}
                            name="isActive"
                            onChange={formik.handleChange}
                            inputProps={{
                              "data-cy": "chk-isActive-manufacturer",
                            }}
                          />
                        }
                        label="Status"
                      />
                    </Stack>
                    <Stack className="button-part">
                      <LoadingButton
                        size="small"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                        data-cy="btn-submit-manufacturer"
                      >
                        save
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </form>
          </Container>
        </Card>
      )}

      <Stack
        className="Companies-search-wrapper"
        width="400px"
        mt={5}
        mb={3}
        style={{ width: "100%", marginTop: "0"}}
      >
        <div
          style={{
            width: "100%",
            background: "#fff",
            borderRadius: "10px",
          }}
        >
          <form
            ref={searchFormRef}
            onSubmit={(e) => {
              e.preventDefault();
              debounceWithSearch();
            }}
          >
            <FormControl className="employee-search-detail" size="small" variant="outlined" fullWidth>
              <InputLabel htmlFor="outlined-adornment-search">
                Search by Title
              </InputLabel>
              <OutlinedInput
                sx={{ padding: 0 }}
                size="small"
                autoComplete="off"
                id="outlined-adornment-search"
                inputRef={searchInput}
                onChange={debounceWithSearch}
                label="Search by title"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton onClick={handleReset}>
                      <CloseIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </div>
      </Stack>
      <Stack
        className="search-filter"
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {isSearchApplied ? (
          <Chip
          className="search-filter-detail"
            label={searchValue}
            color="primary"
            size="small"
            style={{ marginRight: "5px" }}
            variant="filled"
            onDelete={onDeleteChip}
          />
        ) : (
          ""
        )}
      </Stack>
      {isSearchApplied ? (
        <Typography variant="body2" mb={1}>
          {Array.isArray(data) ? data.length : "No"} records found
        </Typography>
      ) : (
        ""
      )}

      <Card>
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
          
              <Table className="grid-table" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell width="30%">
                      <TableSortLabel
                        active={sortBy === "title"}
                        direction={sortBy === "title" ? orderBy : "asc"}
                        onClick={() => handleSorting("title")}
                      >
                        Title
                      </TableSortLabel>
                    </TableCell>

                    <TableCell width="30%" align="center">
                      <TableSortLabel hideSortIcon="true">
                        Status
                      </TableSortLabel>
                    </TableCell>

                    {(getEditVisible("manufacturers") ||
                      getDeleteVisible("manufacturers")) && (
                      <TableCell
                        width="10%"
                        align="right"
                        className="action-field"
                      >
                        <TableSortLabel hideSortIcon="true">
                          Action
                        </TableSortLabel>
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
				  
				  {loading ? (
					  <TableRow style = {{padding : '10px'}}>
                      <TableCell align="center" colSpan={3} >
					  <Loader />
                      </TableCell>
                    </TableRow>
				  ) : (
				  Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={3}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data?.map((manufacturer, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="td" scope="row">
                          {manufacturer.title}
                        </TableCell>

                        <TableCell align="center">
                          {/* <Switch
                            checked={manufacturer.isActive}
                            onChange={() => handleToggle(manufacturer)}
                            inputProps={{ "aria-label": "controlled" }}
                            size="small"
                          /> */}
                          {manufacturer.isActive ? "Active" : "In Active"}
                        </TableCell>
                        {(getEditVisible("manufacturers") ||
                          getDeleteVisible("manufacturers")) && (
                          <TableCell className="td-action-button" align="center">
                            <div className="action-button">
                              {getEditVisible("manufacturers") && (
                                <IconButton
                                  data-cy="btn-edit-holiday"
                                  onClick={() => handleUpdate(manufacturer)}
                                >
                                  {/* <EditIcon color="primary" /> */}
                                  <img src="assets/images/edit.svg" title="Edit" />
                                </IconButton>
                              )}
                              {getDeleteVisible("manufacturers") && (
                                <IconButton
                                  data-cy="btn-delete-holiday"
                                  onClick={() =>
                                    handleDelete(
                                      manufacturer.id,
                                      manufacturer.title
                                    )
                                  }
                                >
                                  <img src="assets/images/trash-gray.svg" title="trash" />
                                  {/* <DeleteIcon color="error" /> */}
                                </IconButton>
                              )}
                            </div>
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )
				)}
                </TableBody>
              </Table>
              <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                // page={currentPage}
                page={currentPage > 0 ? currentPage - 1 : 0}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
              />
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

export default Index;
