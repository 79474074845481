import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useNavigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

import Loader from "../../../components/Loader";
import DashboardLayout from "../../../layouts/dashboard";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
// import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import LoadingButton from "@mui/lab/LoadingButton";
import Autocomplete from "@mui/material/Autocomplete";
import { getCollege } from "../../../redux/college/collegeThunk";
import moment from "moment";
import { getById, updateById, insert } from "../../../redux/drives/drivesThunk";
import drivesSchema from "../../../validations/drivesSchema";
import { decryption, encryption } from "../../../utils/encodeString";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import Search from "@mui/icons-material/Search";
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import { makeStyles } from "@material-ui/core";
import { getFirstDayOfYear, getLastDayOfYear } from "../../../utils/dateFormat";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));

function AddDrives() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const navigation = useNavigate();
  const isAddMode = !params.id;
  const collegeData = useSelector((state) => state.college.data);
  const collegeStatus = useSelector((state) => state.college.status);

  const [id, setId] = useState(null);
  const [loading, setLoading] = useState(false);
  const placementType = ["On Campus", "Off Campus"];
  const placement = ["Single", "Pool"];

  // if (!getAddVisible("project") || !getEditVisible("project")) {
  // 	navigation("/dashboard");
  // }
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  useEffect(() => {
    if (collegeStatus === "idle") {
      getViewVisible("college") && dispatch(getCollege({ search: "" }));
    }
  }, [dispatch, collegeStatus]);
  const formik = useFormik({
    initialValues: {
      collegeId: "",
      placement: "",
      startYear: "",
      endYear: "",
      placementDate: "",
      placementType: "",
      participateColleges: [],
	  totalAptitudeMarks: "50",
    },
    validationSchema: drivesSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let {
          startYear,
          endYear,
          placementDate,
          participateColleges,
          ...newBody
        } = values;
        newBody.placementDate = moment(placementDate).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        newBody.participateColleges =
          participateColleges.length > 0 && newBody.placement === "Pool"
            ? participateColleges
            : null;
        newBody.year = `${startYear}-${endYear}`;
        if (!isAddMode) {
          await dispatch(updateById({ ...newBody, id })).unwrap();
        }

        if (isAddMode) {
          await dispatch(insert(newBody)).unwrap();
        }
        resetForm();
        navigation("/drives");
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const {
    handleSubmit,
	getFieldProps,
    setValues,
	handleBlur,
    values,
    setFieldValue,
    handleChange,
    errors,
	dirty,
    touched,
    isSubmitting,
  } = formik;

  useEffect(() => {
    const fetchById = async () => {
      if (!isAddMode) {
        setLoading(true);
        var decodeParam = decryption(params.id);
        if (decodeParam === -1) {
          navigation("/drives");
        }
        setId(decodeParam);
        // get user and set form fields
        try {
          let record = await dispatch(getById(decodeParam)).unwrap();
          let body = {
            collegeId: record?.college?.id,
            placement: record?.placement,
            startYear: record?.year.split("-")[0],
            endYear: record?.year.split("-")[1],
            placementDate: record?.placementDate,
            placementType: record?.placementType,
            participateColleges: record?.participateColleges
              ? record?.participateColleges
              : [],
			totalAptitudeMarks: record?.totalAptitudeMarks
          };
          setValues(body);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          navigation("/drives");
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
            navigation("/drives");
          }
        }
      }
    };
    fetchById();
  }, [isAddMode, dispatch, navigation, params.id, setValues]);



  const removeCollegeDropDown = (clgData,clgFlag = false) =>{
           
        let newClg = []
     if(clgFlag){
      
       clgData?.map((clg) =>{

        let check = values?.participateColleges?.includes(clg?.id)
            if(!check){
              newClg.push(clg)
            }
       })

     }else{
      clgData?.map((clg) =>{
        if(values?.collegeId !== clg.id){
             newClg.push(clg)
        }
      })

     }
        
   return newClg || []
  }


//  const handleInputChange = (event) => {
//     const inputValue = event.target.value;
//     const yearRegex = /^\d{0,4}$/; // Regex to match up to 4 digits

//     if (yearRegex.test(inputValue)) {
//       const year = inputValue === "" ? null : parseInt(inputValue, 10);
//       // this.handleYearChange(year);
//       console.log("YEAR_____________01",year)
//     }
//   };



  const handleYear = async (date) => {

    if (!date) {
      return;
    }
    try {
      await setFieldValue("placementDate", "");
      await setFieldValue(`startYear`, moment(new Date(date)).format("YYYY"));
      await setFieldValue(
        "endYear",
        moment(new Date(date)).add(1, "year").format("YYYY")
      );
    } catch (error) {}
  };
  if (loading) {
    return <Loader />;
  }


  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
       <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          {id ? "Edit Drive" : "Add Drive"}
        </Typography>
        <Stack direction="row" alignItems="center" justifyContent="end">
          <Button
            variant="contained"
            component={RouterLink}
            to="/drives"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back To List
          </Button>

        </Stack>
         </div>
      </Stack>
      <Card className="holiday-table-grid" mb={5}>
        <Container maxWidth="xl">
          <Paper>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={4} mt={3}>
                  <FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
                    {/*<InputLabel id="demo-simple-select-helper-label">
                      College
                    </InputLabel>
                    <Select
                      inputProps={{
                        "data-cy": "txt-collegeid-drives",
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="College"
                      size="small"
                      error={Boolean(touched.collegeId && errors.collegeId)}
                      name="collegeId"
                      onChange={handleChange}
                      value={values.collegeId}
                    >
                      {collegeData &&
                        Array.isArray(collegeData) &&
                        collegeData.map((college, idx) => (
                          <MenuItem key={idx} value={college.id}>
                            {college.collegeName}
                          </MenuItem>
                        ))}
                    </Select>*/}

                    <Autocomplete
                      disabled={getViewVisible("college") === false}
                      size="small"
                      fullWidth
                      options={removeCollegeDropDown(collegeData,true) || []}
                      getOptionLabel={(option) => option.collegeName || ""}
                      //{...getFieldProps(`collegeId`)}
                      onChange={(event, newValue) => {
                        setFieldValue(`collegeId`, newValue?.id || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          autoComplete="off"
                          label="College"
                          error={Boolean(touched.collegeId && errors.collegeId)}
                          helperText={touched.collegeId && errors.collegeId}
                        />
                      )}
                      value={
                        collegeData
                          ? collegeData.find(
                              (clg) => clg.id === values.collegeId
                            )
                          : ""
                      }

                      //error={true}
                    />
                  </FormControl>
                </Grid>
                {values.placement === "Pool" && (
                  <Grid item xs={4} mt={3} style={{ maxWidth: "330px" }}>
                    <FormControl
                      error={Boolean(
                        touched.participateColleges &&
                          errors.participateColleges
                      )}
                      size="small"
                      fullWidth
                    >
                      {/*<InputLabel id="demo-simple-select-helper-label">
                        Participate Colleges
                      </InputLabel>
                      <Select
                        inputProps={{
                          "data-cy": "txt-collegeid-drives",
                        }}
                        labelId="demo-simple-select-helper-label"
                        id="demo-simple-select-helper"
                        label="Participate Colleges"
                        multiple
                        size="small"
                        name="participateColleges"
                        onChange={handleChange}
                        value={values.participateColleges}
                      >
                        {collegeData &&
                          Array.isArray(collegeData) &&
                          collegeData.map((college, idx) => (
                            <MenuItem key={idx} value={college.id}>
                              {college.collegeName}
                            </MenuItem>
                          ))}
                          </Select>*/}

                      <Autocomplete
                        multiple
                        size="small"
                        fullWidth
                        options={removeCollegeDropDown(collegeData) || []}
                        getOptionLabel={(option) => option.collegeName || ""}
                        onChange={(event, newValue) => {
                          let tempArray = newValue.map((x) => x.id);
                          setFieldValue(`participateColleges`, tempArray || "");
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Participate Colleges"
                            autoComplete="off"
                            error={Boolean(
                              touched.participateColleges &&
                                errors.participateColleges
                            )}
                            helperText={
                              touched.participateColleges &&
                              errors.participateColleges
                            }
                          />
                        )}
                        value={collegeData.filter((x) =>
                          values.participateColleges.includes(x.id)
                        )}
                      />
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={4} mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      views={["year"]}
                      label="Start year"
                      name="startYear"
                      autoComplete="off"
                      value={values.startYear}
                      inputFormat="yyyy"
                      maxDate={new Date().getFullYear().toString()}
                      minDate={moment(
                        new Date().getFullYear().toString()
                      ).subtract(1, "years")}
                      onChange={handleYear}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          // InputProps={{
                          //   ...params.InputProps,
                          //   readOnly: true,
                          // }}

                          // type="number"
                          // maxLength = "4"
                          // inputProps={{
                          //   inputMode: "numeric",
                          //   pattern: "[0-9]*",
                          //   maxLength: 4,
                          // }}
                          // onChange={handleInputChange}
                          inputProps={{
                            ...params.inputProps,
                            disabled: true, // Add the disabled attribute to the input element
                          }}
                          classes={{
                            root: classes.root,
                          }}
                          helperText={touched?.startYear && errors?.startYear}
                          error={Boolean(
                            touched?.startYear && errors?.startYear
                          )}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      views={["year"]}
                      label="End year"
                      name="endYear"
                      autoComplete="off"
                      value={values.endYear}
                      inputFormat="yyyy"
                      // minDate={new Date('2017-01-01')}
                      disabled
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(touched.endYear && errors.endYear)}
                          helperText={touched.endYear && errors.endYear}
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} mt={3}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      disabled={values.startYear == "" ? true : false}
                      label="Placement Date"
                      autoComplete="off"
                      value={values.placementDate ? values.placementDate : null}
                      inputFormat="dd/MM/yyyy"
                      minDate={new Date(getFirstDayOfYear(values?.startYear))}
                      maxDate={
                        new Date(
                          new Date(values?.startYear).getFullYear() + 1,
                          11,
                          31
                        )
                      }
                      onChange={(newValue) => {
                        setFieldValue("placementDate", newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            disabled: true,
                          }}
                          classes={{
                            root: classes.root,
                          }}
                          fullWidth
                          size="small"
                          name="placementDate"
                          error={Boolean(
                            touched?.placementDate && errors?.placementDate
                          )}
                          helperText={
                            touched?.placementDate && errors?.placementDate
                          }
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={4} mt={3}>
                  {/*<InputLabel id="demo-simple-select-helper-label">
                      Placement Type
                    </InputLabel>
                    <Select
                      inputProps={{
                        "data-cy": "txt-collegeid-drives",
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Placement Type"
                      size="small"
                      error={Boolean(
                        touched.placementType && errors.placementType
                      )}
                      name="placementType"
                      onChange={handleChange}
                      value={values.placementType}
                    >
                      <MenuItem value="on campus">On Campus</MenuItem>
                      <MenuItem value="off campus">Off Campus</MenuItem>
                      </Select> */}

                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Marital Status"
                    size="small"
                    name="placementType"
                    onChange={(event, newValue) => {
                      setFieldValue("placementType", newValue || "");
                    }}
                    value={values.placementType}
                    options={placementType || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Placement Type"
                        error={Boolean(
                          touched.placementType && errors.placementType
                        )}
                        helperText={
                          touched.placementType && errors.placementType
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  {/*<InputLabel id="demo-simple-select-helper-label">
                      Placement
                    </InputLabel>
                    <Select
                      inputProps={{
                        "data-cy": "txt-collegeid-drives",
                      }}
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      label="Placement"
                      size="small"
                      error={Boolean(touched.placement && errors.placement)}
                      name="placement"
                      onChange={handleChange}
                      value={values.placement}
                    >
                      <MenuItem value="Single">Single</MenuItem>
                      <MenuItem value="Pool">Pool</MenuItem>
                    </Select> */}

                  <Autocomplete
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    label="Marital Status"
                    size="small"
                    name="placement"
                    onChange={(event, newValue) => {
                      setFieldValue("placement", newValue || "");
                    }}
                    value={values.placement}
                    options={placement || []}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Placement"
                        error={Boolean(touched.placement && errors.placement)}
                        helperText={touched.placement && errors.placement}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={4} mt={3}>
                  <TextField
                    fullWidth
                    autoComplete="off"
                    label="Total Aptitude Marks"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 5 }}
                    onBlur={handleBlur}
                    helperText={
                      (touched?.totalAptitudeMarks &&
                        errors?.totalAptitudeMarks) ||
                      (dirty && errors?.totalAptitudeMarks)
                    }
                    error={Boolean(
                      (touched?.totalAptitudeMarks &&
                        errors?.totalAptitudeMarks) ||
                        (dirty && errors?.totalAptitudeMarks)
                    )}
                    {...getFieldProps("totalAptitudeMarks")}
                  />
                </Grid>
              </Grid>
              <Stack direction="row" justifyContent="flex-end" my={3}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  data-cy="btn-submit-job-description"
                >
                  save
                </LoadingButton>
              </Stack>
            </form>
          </Paper>
        </Container>
      </Card>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddDrives,
    path: "/drives/add",
    public: false,
    layout: DashboardLayout,
    group: "TPA",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddDrives,
    path: "/drives/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "TPA",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
