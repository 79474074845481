import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import {
  Autocomplete,
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import modalComponentSchema from "../../../validations/modalComponentSchema";
import { addLink, updateLink } from "../../../redux/links/linksThunk";
import DeleteIcon from "@mui/icons-material/Delete";

const ModalComponent = ({
  open,
  setOpen,
  employeesData,
  callLinkApi,
  editLink,
  setEditLink,
  heading,
  categoriesData,
}) => {
  const dispatch = useDispatch();
  const roles = [
    { label: "Viewer", value: "viewer" },
    { label: "Commenter", value: "commenter" },
    { label: "Editor", value: "editor" },
  ];
  const formik = useFormik({
    initialValues: {
      title: "",
      link: "",
      assignee: [],
      owner: "",
      category: "",
    },
    validationSchema: modalComponentSchema, // Update your schema to handle assignees
    onSubmit: async (values, { resetForm }) => {
      if (editLink) {
        let assignee = values?.assignee?.map((emp) => ({
          id: emp?.id,
          role: emp?.role || "viewer",
        }));
        const body = {
          title: values?.title,
          link: values?.link,
          assignee: assignee,
          owner: values?.owner,
          id: editLink?.id,
          category: values?.category,
        };
        await dispatch(updateLink(body)).then(() => {
          setOpen(false);
          setEditLink(null);
          callLinkApi();
          resetForm();
        });
      } else {
        try {
          await dispatch(addLink(values)).then(() => {
            resetForm();
            callLinkApi();
            setOpen(false);
          });
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
  });

  const {
    handleSubmit,
    setValues,
    values,
    setFieldValue,
    handleChange,
    errors,
    touched,
    isSubmitting,
    setTouched,
    setErrors,
  } = formik;
  useEffect(() => {
    if (editLink) {
      setFieldValue("title", editLink?.title);
      setFieldValue("link", editLink?.link);
      setFieldValue("assignee", editLink?.assignee);
      setFieldValue("owner", editLink?.owner?.id);
      setFieldValue("category", editLink?.category);
    }
  }, [editLink]);
  const handleClose = () => {
    setValues({
      title: "",
      link: "",
      assignee: [],
      owner: "",
      category: "",
    });
    setTouched({});
    setErrors({});
    setOpen(false);
    setEditLink(null);
  };

  const handleRoleChange = (assigneeId, role) => {
    setFieldValue(
      "assignee",
      values.assignee.map((assignee) =>
        assignee.id === assigneeId ? { ...assignee, role: role } : assignee
      )
    );
  };

  const handleDeleteAssignee = (assigneeId) => {
    setFieldValue(
      "assignee",
      values.assignee.filter((assignee) => assignee.id !== assigneeId)
    );
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{heading || "Add Link"}</DialogTitle>
      <form onSubmit={handleSubmit} style={{ paddingBottom: "20px" }}>
        <DialogContent style={{ padding: "0 20px 20px" }}>
          <Grid container spacing={0.3}>
            <Grid item xs={12} mt={2}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                value={values.title}
                inputProps={{
                  "data-cy": "txt-title-holiday",
                }}
                FormHelperTextProps={{
                  "data-cy": "txt-title-err-holiday",
                }}
                onChange={handleChange}
                variant="outlined"
                size="small"
                error={Boolean(touched.title && errors.title)}
                helperText={touched.title && errors.title}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <Autocomplete
                freeSolo
                size="small"
                fullWidth
                options={categoriesData || []}
                getOptionLabel={(option) => option}
                renderOption={(props, option) => (
                  <li {...props} key={option}>
                    {option}
                  </li>
                )}
                openOnFocus={false} // Prevent dropdown from opening on focus
                filterOptions={(options, state) =>
                  options.filter((option) =>
                    option
                      .toLowerCase()
                      .includes(state.inputValue.toLowerCase())
                  )
                }
                onInputChange={(event, newInputValue) => {
                  setFieldValue("category", newInputValue);
                }}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setFieldValue("category", newValue);
                  } else if (newValue) {
                    setFieldValue("category", newValue);
                  } else {
                    setFieldValue("category", "");
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Category"
                    error={touched.category && Boolean(errors.category)}
                    helperText={touched.category && errors.category}
                  />
                )}
                value={values.category || ""}
              />
            </Grid>

            <Grid item xs={12} mt={3}>
              <TextField
                fullWidth
                label="Link"
                name="link"
                value={values.link}
                inputProps={{
                  maxLength: 256,
                  "data-cy": "txt-link-holiday",
                }}
                FormHelperTextProps={{
                  "data-cy": "txt-link-err-holiday",
                }}
                onChange={handleChange}
                variant="outlined"
                size="small"
                error={Boolean(touched.link && errors.link)}
                helperText={touched.link && errors.link}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <div className="employee-classification-dropdown">
                <Autocomplete
                  multiple
                  size="small"
                  fullWidth
                  popupIcon={
                    <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                  }
                  options={employeesData || []}
                  getOptionLabel={(option) =>
                    option ? `${option.firstName} ${option.lastName}` : ""
                  }
                  renderOption={(props, option) => (
                    <li {...props} value={option.id} key={option.id}>
                      {option ? `${option.firstName} ${option.lastName}` : ""}
                    </li>
                  )}
                  // renderTags={() => null}
                  name="assignee"
                  onChange={(event, newValue) => {
                    const updatedAssignees = newValue.map((newAssignee) => {
                      const existingAssignee = values.assignee.find(
                        (assignee) => assignee.id === newAssignee.id
                      );
                      return {
                        id: newAssignee.id,
                        firstName: newAssignee.firstName,
                        lastName: newAssignee.lastName,
                        role: existingAssignee?.role || "viewer" // Default to "viewer" if not already set
                      };
                    });
                    setFieldValue("assignee", updatedAssignees);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      autoComplete="off"
                      label="Assignee"
                      error={touched.assignee && Boolean(errors.assignee)}
                      helperText={touched.assignee && errors.assignee}
                    />
                  )}
                  value={
                    values.assignee.map((assignee) =>
                      employeesData.find((emp) => emp.id === assignee.id)
                    ) || []
                  }
                />
              </div>
            </Grid>

            {values.assignee.map((assignee) => (
              <Grid item xs={12} key={assignee.id} mt={1}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ flexGrow: 1 }}>
                    {`${assignee.firstName} ${assignee.lastName}`}
                  </span>
                  <Select
                    value={assignee.role}
                    onChange={(event) =>
                      handleRoleChange(assignee.id, event.target.value)
                    }
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "10px", minWidth: "120px" }}
                    label="Role"
                  >
                    {roles.map((role) => (
                      <MenuItem value={role.value} key={role.value}>
                        {role.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <IconButton onClick={() => handleDeleteAssignee(assignee.id)}>
                    <DeleteIcon />
                  </IconButton>
                </div>
              </Grid>
            ))}

            <Grid item xs={12} mt={3}>
              <Autocomplete
                size="small"
                fullWidth
                popupIcon={
                  <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                }
                options={employeesData || []}
                getOptionLabel={(option) =>
                  option ? `${option.firstName} ${option.lastName}` : ""
                }
                renderOption={(props, option) => (
                  <li {...props} value={option.id} key={option.id}>
                    {option ? `${option.firstName} ${option.lastName}` : ""}
                  </li>
                )}
                name="owner"
                onChange={(event, newValue) => {
                  setFieldValue("owner", newValue?.id || "");
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    autoComplete="off"
                    label="Owner"
                    error={touched.owner && Boolean(errors.owner)}
                    helperText={touched.owner && errors.owner}
                  />
                )}
                value={
                  employeesData?.find((t) => t.id === values.owner) || null
                }
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions style={{ paddingRight: "20px" }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            data-cy="btn-submit-holiday"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default ModalComponent;
