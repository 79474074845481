import * as Yup from "yup";
import { get, isEmpty } from "lodash";

const digitsOnly = (value) => /^\d+$/.test(value);

function instituteValidation(message) {
  return this.test("instituteValidation", message, function (value) {
    const { path, createError } = this;

    if (!this.parent.instituteId && !this.parent.instituteName) {
      return createError({
        path,
        message: message ?? "check this field please",
      });
    }
    return true;
  });
}

function courseValidation(message) {
  return this.test("courseValidation", message, function (value) {
    const { path, createError } = this;

    if (!this.parent.courseId && !this.parent.courseName) {
      return createError({
        path,
        message: message ?? "check this field please",
      });
    }
    return true;
  });
}

const uniquePropertyTest = function (value, propertyName, message) {
  if (
    this.parent
      .filter((v) => v !== value)
      .some((v) => get(v, propertyName) === get(value, propertyName))
  ) {
    throw this.createError({
      path: `${this.path}.${propertyName}`,
      message,
    });
  }

  return true;
};


let commonPF = {
    underPF: Yup.boolean(),
    underESIC: Yup.boolean(),
    name: Yup.string()
      .when("underPF", {
        is: true,
        then: Yup.string()
        .max(64, "Name of the member is too long")
        .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
        .trim("Name cannot contain only whitespace")
        .strict(true)
        .required("Name of the member is required"),
        otherwise: Yup.string().nullable(),
      }),

    presentAddress: Yup.string()
      .when("underPF", {
        is: true,
        then: Yup.string()
          .max(512, "Present address is too long")
          .trim("Present address cannot contain only whitespace")
          .strict(true)
          .required("Present address is required")
          .nullable(),
        otherwise: Yup.string()
          .trim("Present address cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),
    permanentAddress: Yup.string()
      .when("underPF", {
        is: true,
        then: Yup.string()
          .max(512, "Permanent address is too long")
          .trim("Permanent address cannot contain only whitespace")
          .strict(true)
          .required("Permanent address is required")
          .nullable(),
        otherwise: Yup.string()
          .trim("Permanent address cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),
    fatherName: Yup.string()
      .when("underPF", {
        is: true,
        then: Yup.string()
          .max(64, "Father name is too long")
          .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
          .trim("Father name cannot contain only whitespace")
          .strict(true)
          .required("Father name is required")
          .nullable(),
        otherwise: Yup.string()
          .trim("Father name cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),
    fatherDOB: Yup.mixed()
      .nullable()
      .when("underPF", {
        is: true,
        then: Yup.date()
          .typeError("Invalid date")
          .required("Father DOB is required"),
        otherwise: Yup.string().nullable(),
      }),
    motherName: Yup.string()
      .when("underPF", {
        is: true,
        then: Yup.string()
          .max(64, "Mother name is too long")
          .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
          .trim("Mother name cannot contain only whitespace")
          .strict(true)
          .required("Mother name is required")
          .nullable(),
        otherwise: Yup.string()
          .trim("Mother name cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),
    motherDOB: Yup.mixed()
      .nullable()
      .when("underPF", {
        is: true,
        then: Yup.date()
          .typeError("Invalid date")
          .required("Mother DOB is required"),
        otherwise: Yup.string().nullable(),
      }),

    dob: Yup.mixed()
      .nullable()
      .typeError("Invalid date")
      .when("underPF", {
        is: true,
        then: Yup.date()
          .typeError("Invalid date")
          .required("Date of birth is required"),
        otherwise: Yup.string().nullable(),
      }),
    maritalStatus: Yup.string().when("underPF", {
      is: true,
      then: Yup.string().required("Marital status is required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    email: Yup.string().when("underPF", {
      is: true,
      then: Yup.string()
        .email("Invalid email")
        .max(512, "Email is too long")
        .required("Email is required"),
      otherwise: Yup.string().nullable(),
    }),
    mobileNumber: Yup.string()
      .when("underPF", {
        is: true,
        then: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Mobile number is invalid")
        .min(10, "Mobile number must be 10 digits")
        .max(10, "Mobile number must be 10 digits")
        .required("Mobile number is required"),
        otherwise: Yup.string().nullable(),
      }),
    UAN: Yup.string()
      .max(128, "UAN is too long")
      .when("underPF", {
        is: true,
        then: Yup.string()
          .trim("UAN cannot contain only whitespace")
          .strict(true)
          .nullable(),
        otherwise: Yup.string()
          .trim("UAN cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),
    // esicNumber: Yup.string()
    //   .max(128, "ESIC Number is too long")
    //   .when("underESIC", {
    //     is: true,
    //     then: Yup.string()
    //       .trim("ESIC Number cannot contain only whitespace")
    //       .strict(true)
    //       .required("ESIC is required")
    //       .nullable(),
    //     otherwise: Yup.string()
    //       .trim("ESIC Number cannot contain only whitespace")
    //       .strict(true)
    //       .nullable(),
    //   }),
    memberId: Yup.string()
      .max(128, "Member Id is too long")
      .when("underPF", {
        is: true,
        then: Yup.string()
          .trim("Member Id cannot contain only whitespace")
          .strict(true)
          .nullable(),
        otherwise: Yup.string()
          .trim("Member Id cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),

    bankAccountNumber: Yup.string()
      .max(128, "Bank account number is too long")
      .when("underPF", {
        is: true,
        then: Yup.string()
          .trim("Bank account cannot contain only whitespace")
          .strict(true)
          .nullable()
          .required("Bank account number is required"),
        otherwise: Yup.string()
          .trim("Bank account cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),
    IFSC: Yup.string().when("underPF", {
      is: true,
      then: Yup.string()
        .required("IFSC Code is required")
        .min(11, "IFSC code must have 11 digits")
        .max(11, "IFSC code must have 11 digits")
        .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code."),
      otherwise: Yup.string().nullable(),
    }),
    // .min(11, "IFSC code must have 11 digits")
    // .max(11, "IFSC code must have 11 digits")
    // .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code."),
    aadharNumber: Yup.string()
      .min(12, "Aadhaar number must have 12 digits")
      .max(12, "Aadhaar number must have 12 digits")
      .when("underPF", {
        is: true,
        then: Yup.string()
          .required("Aadhaar card number is required")
          .test(
            "Digits only",
            "Aadhaar number should not contain any alphabet and special characters.",
            digitsOnly
          )
          .matches(
            /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
            "Aadhaar card number should not start with 0 or 1"
          ),
        otherwise: Yup.string().nullable(),
      }),
    panNumber: Yup.string()
      .max(10, "Pan card number must have 12 digits")
      .min(10, "Pan card number must have 12 digits")
      .matches(
        /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
        "Pan card number is not valid"
      )
      .when("underPF", {
        is: true,
        then: Yup.string().required("Pan card number is required"),
        otherwise: Yup.string().nullable(),
      }),
    bankName: Yup.string()
      .max(128, "Bank name is too long")
      .when("underPF", {
        is: true,
        then: Yup.string()
          .trim("Bank name cannot contain only whitespace")
          .strict(true)
          .nullable()
          .required("Bank name is required"),
        otherwise: Yup.string()
          .trim("Bank name cannot contain only whitespace")
          .strict(true)
          .nullable(),
      }),  
    electionCardNumber: Yup.string()
    .trim("Election card number cannot contain only whitespace")
    .strict(true)
      // .min(10, "Election card number must have 10 letters")
      // .max(10, "Election card number must have 10 letters")
      // .matches(/^([a-zA-Z]){3}([0-9]){7}?$/, "Invalid Election card number")
      .nullable(),
    licenseNumber: Yup.string()
    .trim("Licence number cannot contain only whitespace")
    .strict(true)
      // .max(8, "Licence number must be of 8 letters")
      // .min(8, "Licence number must be of 8 letters")
      // .matches(/^[0-3][0-9]{7}$/, "Invalid Licence number")
      .nullable(),
    rationCardNumber: Yup.string()
      .matches(
        /^([a-zA-Z0-9]){8,12}\s*$/,
        "Invalid Ration card number(It should contain 8-12 alpha numeric letters)"
      )
      .nullable(),
    passportNumber: Yup.string()
      .max(9, "Passport number must be of 10 or less letters")
      .matches(/[a-zA-Z][0-9]{0,10}/, "Invalid Passport number")
      .nullable(),
}



Yup.addMethod(Yup.object, "uniqueProperty", function (propertyName, message) {
  return this.test("unique", message, function (value) {
    return uniquePropertyTest.call(this, value, propertyName, message);
  });
});

Yup.addMethod(Yup.object, "uniqueProperties", function (propertyNames) {
  return this.test("unique", "", function (value) {
    const errors = propertyNames
      .map(([propertyName, message]) => {
        try {
          return uniquePropertyTest.call(this, value, propertyName, message);
        } catch (error) {
          return error;
        }
      })
      .filter((error) => error instanceof Yup.ValidationError);

    if (!isEmpty(errors)) {
      throw new Yup.ValidationError(errors);
    }

    return true;
  });
});

Yup.addMethod(Yup.mixed, "instituteValidation", instituteValidation);
Yup.addMethod(Yup.mixed, "courseValidation", courseValidation);

const employeeSchema = Yup.object().shape({
  firstName: Yup.string()
    .required("First name is required")
    .trim("First name cannot contain only whitespace")
    .strict(true)
    .max(64, "First name is too long")
    .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
    .nullable(),
  middleName: Yup.string()
    .max(64, "Middle name is too long")
    .trim("Middle name cannot contain only whitespace")
    .strict(true)
    .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
    .nullable(),
  lastName: Yup.string()
    .required("Last name is required")
    .trim("Last name cannot contain only whitespace")
    .strict(true)
    .max(64, "Last name is too long")
    .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
    .nullable(),
  dateOfBirth: Yup.date()
    .required("Date of birth is required")
    .typeError("Invalid date")
    .nullable(),
  github: Yup.string()
    .trim("Github cannot contain only whitespace")
    .strict(true)
    .max(512, "Github is too long")
    .nullable(),
  portfolioWebsite: Yup.string()
    .url("Invalid URL")
    .max(1024, "portfolioWebsite is too long")
    .nullable(),


  // roleId: Yup.string().required('Role is required'),
  gender: Yup.string().required("Gender is required"),
  maritalStatus: Yup.string().required("Marital status is required").nullable(),
  bloodGroup: Yup.string().required("Blood group is required").nullable(),
  emails: Yup.array().of(
    Yup.object().shape({
      type: Yup.string().required("Email type is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email")
        .max(512, "Email is too long")
        .nullable(),
    })
  ),
  presentAddressImage: Yup.mixed().when(["checklists.isResidentialAddress"], {
    is: true,
    then: Yup.mixed().required("Present address proof is required"),
  }),

  permanentAddressImage: Yup.mixed().when(
    ["checklists.isResidentialAddress", "address.sameAsCurrent"],
    {
      is: (isResidentialAddress, sameAsCurrent) =>
        isResidentialAddress && !sameAsCurrent,
      then: Yup.mixed().required("Permanent address proof is required"),
    }
  ),
  workLocation: Yup.string().required("Work Location is required").nullable(),
  seatNumber: Yup.string().when("workLocation", {
    is: (val) => val === "2" || val === "3",
    then: Yup.string().required("Seat Number is required").nullable(),
    otherwise: Yup.string().nullable(),
  }),

  /* bachelorCertificates: Yup.mixed().when(
		["checklists.isBachelorCertificates"],
		{
			is: true,
			then: Yup.array()
				.of(Yup.mixed())
				.min(1, "Atlesast 1 certificate is required"),
		},
	), */
  bachelorCertificates: Yup.mixed().when(
    ["checklists.isMasterCertificates", "checklists.isBachelorCertificates"],
    (isMasterCertificates, isBachelorCertificates) => {
      if (isMasterCertificates && isBachelorCertificates) {
        return Yup.array()
          .of(Yup.mixed())
          .min(2, "Atleast 2  certificate is required");
      }
      if (isMasterCertificates && !isBachelorCertificates) {
        return Yup.array()
          .of(Yup.mixed())
          .min(1, "Atleast 1  certificate is required");
      }
      if (!isMasterCertificates && isBachelorCertificates) {
        return Yup.array()
          .of(Yup.mixed())
          .min(1, "Atleast 1  certificate is required");
      }
    }
  ),

  panCardImage: Yup.mixed().required("Please provide a pan card image"),
  aadharCardImage: Yup.mixed().required("Please provide a aadhar card image"),

  contact: Yup.object().shape({
    contactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
      .min(10, "Contact number must be 10 digits")
      .max(10, "Contact number must be 10 digits")
      .required("Contact number is required")
      .notOneOf(
        [Yup.ref("alternateContactNumber"), Yup.ref("emergencyContactNumber")],
        "Contact number must be diffrent."
      ),
    alternateContactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Alternate contact number is invalid")
      .notRequired()
      .min(10, "Alternate contact number must be 10 digits")
      .max(10, "Alternate contact number must be 10 digits")
      .test({
        name: "unique",
        exclusive: false,
        params: {},
        message: "Alternate contact number must be diffrent",
        test: function (value) {
          // You can access the price field with `this.parent`.
          if (this.parent.contactNumber && value) {
            return value !== this.parent.contactNumber;
          }
          if (this.parent.emergencyContactNumber && value) {
            return value !== this.parent.emergencyContactNumber;
          }
          return true;
        },
      })
      .nullable(),
    // .notOneOf(
    //     [Yup.ref("contactNumber"), Yup.ref("emergencyContactNumber")],
    //     "Contact number must be diffrent."
    // ),
    // companyEmail: Yup.string()
    // 	.required('Company email is required')
    // 	.email('Invalid email')
    // 	.max(512, 'Company email is too long'),
    // personalEmail: Yup.string()
    // 	.required('Email is required')
    // 	.email('Invalid email')
    // 	.max(512, 'First name is too long'),
    // linkedIn: Yup.string().required("LinkedIn is required"),
    skype: Yup.string()
      .trim("Skype cannot contain only whitespace")
      .strict(true)
      .max(512, "Skype id is too long")
      .nullable(),
    linkedIn: Yup.string()
      .url("Invalid url")
      .required("LinkedIn url is required")
      .max(512, "Emergency contact name is too long")
      .nullable(),
    companySkype: Yup.string()
      .max(512, "Company Skype id is too long")
      .trim("Company Skype cannot contain only whitespace")
      .strict(true)
      .nullable(),
    emergencyContactName: Yup.string()
      .required("Emergency contact name is required")
      .trim("Emergency contact name cannot contain only whitespace")
      .strict(true)
      .max(64, "Emergency contact name is too long")
      .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
      .nullable(),
    relationWithEmployee: Yup.string()
      .required("Relation is required")
      .trim("Relation cannot contain only whitespace")
      .strict(true)
      .max(32, "Relation data is too long")
      .nullable(),
    emergencyContactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Emergency contact number is invalid")
      .min(10, "Emergency contact number must be 10 digits")
      .max(10, "Emergency contact number must be 10 digits")
      .required("Emergency contact number is required")
      .notOneOf(
        [Yup.ref("contactNumber"), Yup.ref("alternateContactNumber")],
        "Contact number must be diffrent."
      )
      .nullable(),
  }),

  documents: Yup.object().shape({
    aadharCardNumber: Yup.string()
      .required("Aadhaar card number is required")
      .min(12, "Aadhaar number must have 12 digits")
      .max(12, "Aadhaar number must have 12 digits")
      .test(
        "Digits only",
        "Aadhaar number should not contain any alphabet and special characters.",
        digitsOnly
      )
      .matches(
        /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
        "Aadhaar card number should not start with 0 or 1"
      )
      .nullable(),
    panCardNumber: Yup.string()
      .required("Pan card number is required")
      .max(10, "Pan card number must have 12 digits")
      .min(10, "Pan card number must have 12 digits")
      .matches(
        /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
        "Pan card number is not valid"
      )
      .nullable(),
    bankName: Yup.string()
      .required("Bank name is required")
      .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
      .trim("Bank name cannot contain only whitespace")
      .strict(true)
      .max(256, "Bank name too long")
      .nullable(),
    bankAccountNumber: Yup.string()
      .required("Bank account number is required")
      .matches(/^[0-9]+$/, "Please enter numeric value")
      .max(256, "Bank account number too long")
      .nullable(),
    IFSCCode: Yup.string()
      .required("IFSC Code is required")
      .min(11, "IFSC code must have 11 digits")
      .max(11, "IFSC code must have 11 digits")
      .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code.")
      .nullable(),
    bankBranch: Yup.string()
      .trim("Bank branch cannot contain only whitespace")
      .strict(true)
      .nullable()
      .max(512, "Bank branch is too long"),
  }),

  
  vehicleData: Yup.array().of(
    Yup.object().shape({
      vehicleName: Yup.string().when('vehicleType', {
        is: (val) => val && val.length > 0 && val !== 'OTHER' && val !== 'PUBLIC_TRANSPORT',
        then: Yup.string().required('Vehicle Name is required'),
        otherwise: Yup.string().nullable(),
      }),
      vehicleNumber: Yup.string().when('vehicleType', {
        is: (val) => val && val.length > 0 && val !== 'OTHER' && val !== 'PUBLIC_TRANSPORT',
        then: Yup.string().required('Vehicle Number is required'),
        otherwise: Yup.string().nullable(),
      }),
      
      // totalExperience: Yup.number()
      // 	.typeError('Eperience should be number.')
      // 	.required('Total experience is required.')
      // 	.test('Experience-validation', 'Please enter valid experience.', (value) =>
      // 		/^\d+(\.\d+)?$/.test(value)
      // 	)
      // 	.max(100, 'Please enter valid experience.')
      // 	.nullable(),
    })
  ),


  address: Yup.object()
    .shape({
      presentStreetLine1: Yup.string()
        .required("Present street line 1 is required")
        .trim("Present street line 1 cannot contain only whitespace")
        .strict(true)
        .max(256, "Present street line 1 is too long")
        .nullable(),
      presentStreetLine2: Yup.string()
        .max(256, "present street line2 is too long")
        .trim("Present street line 2 cannot contain only whitespace")
        .strict(true)
        .nullable(),
      presentCountry: Yup.string()
        .required("Present country is required")
        .nullable(),
      presentState: Yup.string()
        .required("Present state is required")
        .nullable(),
      presentCity: Yup.string().required("Present city is required").nullable(),
      presentArea: Yup.string()
        .required("Present area is required")
        .trim("Present area cannot contain only whitespace")
        .strict(true)
        .nullable(),
      presentZipCode: Yup.string()
        .required("Present zipCode is required")
        .nullable(),

      permenentStreetLine1: Yup.string()
        .required("Permanent street 1 is required")
        .trim("Permenent street line 1 cannot contain only whitespace")
        .strict(true)
        .max(256, "Permanent street 1 is too long")
        .nullable(),
      permenentStreetLine2: Yup.string()
        .max(256, "Permenent Street Line 2 is too long")
        .trim("Permenent street line 2 cannot contain only whitespace")
        .strict(true)
        .nullable(),
      permenentCountry: Yup.string()
        .required("Permanent Country is required")
        .nullable(),
      permenentState: Yup.string()
        .required("Permanent State is required")
        .nullable(),
      permenentCity: Yup.string()
        .required("Permanent City is required")
        .nullable(),
      permenentArea: Yup.string()
        .required("Permanent Area is required")
        .trim("Permanent Area cannot contain only whitespace")
        .strict(true)
        .nullable(),
      permenentZipCode: Yup.string().required("Permanent zip-code is required"),
    })
    .nullable(),

  education: Yup.array().of(
    Yup.object()
      .shape({
        educationTypeId: Yup.string().required("Education type is required"),
        courseId: Yup.mixed().when(["courseAvailable"], {
          is: (courseAvailable) => {
            return courseAvailable == 1 ? true : false;
          },
          then: Yup.mixed().courseValidation("Course is required."),
          otherwise: Yup.mixed().nullable(),
        }),
        passingYear: Yup.number()
          .required("Passing year is required")
          .typeError("Passing Year can only be number")
          .min(1900, "Passing year is Invalid")
          .max(2100, "Passing year is Invalid")
          .nullable(),
        percentage: Yup.number()
          /* .required("Percentage is required.") */
          .min(0, "Percentage is Invalid")
          .max(100, "Percentage is Invalid")
          .typeError("percentage can only be number")
          .nullable(),
        institute: Yup.mixed().instituteValidation("Institute is required"),
        courseAvailable: Yup.number().nullable(),
      })
      .uniqueProperties([["educationTypeId", "Education must be unique"]])
  ),

  checklists: Yup.object().shape({
    isResidentialAddress: Yup.boolean().nullable(),
    isBachelorCertificates: Yup.boolean().nullable(),
    // isPassportSizePhotos: false,
    // isServiceAggrement: false,
    isMasterCertificates: Yup.boolean().nullable(),
    // isLast3MonthsSalarySlip: false,
    // isExpAndRelLetter: false,
  }),
  additionalCourse: Yup.array().of(
    Yup.object().shape(
      {
        courseName: Yup.mixed().when(
          ["instituteName", "certificateUrl", "skills"],
          {
            is: (...fields) =>
              fields.some((field) => {
                if (Array.isArray(field) && field.length === 0) {
                  return false;
                }
                return Boolean(field);
              }),
            then: Yup.string()
              .trim("Course cannot contain only whitespace")
              .strict(true)
              .required("Course is required")
              .nullable(),
            otherwise: Yup.string()
              .trim("Course cannot contain only whitespace")
              .strict(true)
              .notRequired()
              .nullable(),
          }
        ),
        instituteName: Yup.mixed().when(
          ["courseName", "certificateUrl", "skills"],
          {
            is: (...fields) =>
              fields.some((field) => {
                if (Array.isArray(field) && field.length === 0) {
                  return false;
                }
                return Boolean(field);
              }),
            then: Yup.string()
              .trim("Institute name cannot contain only whitespace")
              .strict(true)
              .required("Institute name is required")
              .nullable(),
            otherwise: Yup.string()
              .trim("Institute name cannot contain only whitespace")
              .strict(true)
              .notRequired()
              .nullable(),
          }
        ),
        certificateUrl: Yup.mixed().when(
          ["instituteName", "courseName", "skills"],
          {
            is: (...fields) =>
              fields.some((field) => {
                if (Array.isArray(field) && field.length === 0) {
                  return false;
                }
                return Boolean(field);
              }),
            then: Yup.string()
              .required("Certificate url is required")
              .trim("Certificate url cannot contain only whitespace")
              .strict(true)
              .nullable(),
            otherwise: Yup.string()
              .trim("Certificate url cannot contain only whitespace")
              .strict(true)
              .notRequired()
              .nullable(),
          }
        ),
        skills: Yup.mixed().when(
          ["instituteName", "courseName", "certificateUrl"],
          {
            is: (...fields) => fields.some(Boolean),
            then: Yup.array()
              .of(Yup.mixed())
              .min(1, "Atleast one skill is required is required")
              .nullable(),
            otherwise: Yup.mixed().notRequired().nullable(),
          }
        ),
      },
      [
        ["courseName", "instituteName"],
        ["courseName", "certificateUrl"],
        ["courseName", "skills"],
        ["instituteName", "certificateUrl"],
        ["instituteName", "skills"],
        ["certificateUrl", "skills"],
      ]
    )
  ),

  experience: Yup.array().of(
    Yup.object().shape({
      companyId: Yup.string().required("Company is required").nullable(),
      designation: Yup.string()
        .required("Designation is required")
        .max(64, "Designation is Invalid")
        .nullable(),
      technologies: Yup.array()
        .of(Yup.mixed())
        .min(1, "Atleast one technology is required")
        .nullable(),
      from: Yup.date()
        .required("From date required")
        .typeError("Invalid date")
        .nullable(),
      to: Yup.date()
        .min(Yup.ref("from"), "To date can't be before from date")
        .required("To date required")
        .typeError("Invalid date")
        .nullable(),
      reasonForJobChange: Yup.string()
        .required("Reason is required")
        .nullable(),
      // totalExperience: Yup.number()
      // 	.typeError('Eperience should be number.')
      // 	.required('Total experience is required.')
      // 	.test('Experience-validation', 'Please enter valid experience.', (value) =>
      // 		/^\d+(\.\d+)?$/.test(value)
      // 	)
      // 	.max(100, 'Please enter valid experience.')
      // 	.nullable(),
    })
  ),

  workDetails: Yup.object().shape({
    joiningDate: Yup.date()
      .required("Joining date is required")
      .typeError("Invalid joining date"),
    productionDate: Yup.date()
      .required("Production date is required")
      .typeError("Invalid production date"),
    commitmentStartDate: Yup.date().when(["bondDuration"], {
      is: (bondDuration) => {
        return bondDuration !== "0";
      },
      then: Yup.date()
        .required("Commitment date is required")
        .typeError("Invalid Commitment date"),
      otherwise: Yup.date().nullable(),
    }),

    // technologyId: Yup.string().required('Technology is required'),
    // departmentId: Yup.string().required('Department is required'),
    designationId: Yup.string().required("Designation is required").nullable(),
    // teamLead: Yup.string().required('TeamLead is required'),
    // projectManager: Yup.string().required('Project Manager is required'),
    bondDuration: Yup.string().required("Bond duration is required").nullable(),
    noticePeriod: Yup.string().required("Notice period is required").nullable(),
    appraisalCycle: Yup.string()
      .required("Appraisal cycle is required")
      .nullable(),
  }),


  pf: Yup
  .object()
  .when(['gender', 'maritalStatus'], (gender, maritalStatus, schema) => {
    if (gender == 'F' && maritalStatus == 'Married') {
      return schema.shape({
        ...commonPF,
        husbandName: Yup.string()

        .when("underPF", {
          is: true,
          then: Yup.string()
          .max(64, "Husband name is too long")
           .trim("Husband name cannot contain only whitespace")
           .strict(true)
           .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
           .required("Husband name is required")
           .nullable(),
          otherwise: Yup.string().nullable(),
        }),
      husbandDOB: Yup.date()

      .when("underPF", {
        is: true,
        then: Yup.date()
        .required("Date of birth is required")
        .typeError("Invalid date")
        .nullable() ,
        otherwise: Yup.date().nullable(),
      })
       ,
     
      });
    }else{
      return schema.shape({
        husbandName: Yup.string()
              .nullable(),
        husbandDOB: Yup.date()
          .nullable(),
          ...commonPF
      });
    }

  }),

  pf: Yup
  .object()
  .when(['underPF', 'underESIC'], (underPF, underESIC, schema) => {
    if (underPF == true && underESIC == true) {
      return schema.shape({
        ...commonPF,
        esicNumber: Yup.string()
        .max(128, "ESIC Number is too long")
            .trim("ESIC Number cannot contain only whitespace")
            .strict(true)
            .required("ESIC is required")
            .nullable(),
      });
    }else{
      return schema.shape({
        esicNumber: Yup.string()
          .trim("ESIC Number cannot contain only whitespace")
          .strict(true)
          .nullable(),
          ...commonPF
      });
    }
  }),
  
  // pf: Yup.object().shape({
  //   underPF: Yup.boolean(),
  //   underESIC: Yup.boolean(),
  //   name: Yup.string()
  //     .max(64, "Name of the member is too long")
  //     .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
  //     .trim("Name cannot contain only whitespace")
  //     .strict(true)
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string().required("Name of the member is required"),
  //       otherwise: Yup.string().nullable(),
  //     }),

  //   presentAddress: Yup.string()
  //     .max(512, "Present address is too long")

  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Present address cannot contain only whitespace")
  //         .strict(true)
  //         .required("Present address is required")
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("Present address cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   permanentAddress: Yup.string()
  //     .max(512, "Permanent address is too long")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Permanent address cannot contain only whitespace")
  //         .strict(true)
  //         .required("Permanent address is required")
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("Permanent address cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   fatherName: Yup.string()
  //     .max(64, "Father name is too long")
  //     .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Father name cannot contain only whitespace")
  //         .strict(true)
  //         .required("Father name is required")
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("Father name cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   fatherDOB: Yup.mixed()
  //     .nullable()
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.date()
  //         .typeError("Invalid date")
  //         .required("Father DOB is required"),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //   motherName: Yup.string()
  //     .max(64, "Mother name is too long")
  //     .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Mother name cannot contain only whitespace")
  //         .strict(true)
  //         .required("Mother name is required")
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("Mother name cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   motherDOB: Yup.mixed()
  //     .nullable()
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.date()
  //         .typeError("Invalid date")
  //         .required("Mother DOB is required"),
  //       otherwise: Yup.string().nullable(),
  //     }),

  //   dob: Yup.mixed()
  //     .nullable()
  //     .typeError("Invalid date")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.date()
  //         .typeError("Invalid date")
  //         .required("Date of birth is required"),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //   maritalStatus: Yup.string().when("underPF", {
  //     is: true,
  //     then: Yup.string().required("Marital status is required").nullable(),
  //     otherwise: Yup.string().nullable(),
  //   }),
  //   email: Yup.string().when("underPF", {
  //     is: true,
  //     then: Yup.string()
  //       .email("Invalid email")
  //       .max(512, "Email is too long")
  //       .required("Email is required"),
  //     otherwise: Yup.string().nullable(),
  //   }),
  //   mobileNumber: Yup.string()
  //     .matches(/^[6-9]\d{9}$/, "Mobile number is invalid")
  //     .min(10, "Mobile number must be 10 digits")
  //     .max(10, "Mobile number must be 10 digits")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string().required("Mobile number is required"),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //   UAN: Yup.string()
  //     .max(128, "UAN is too long")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("UAN cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("UAN cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   esicNumber: Yup.string()
  //     .max(128, "ESIC Number is too long")
  //     .when("underESIC", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("ESIC Number cannot contain only whitespace")
  //         .strict(true)
  //         .required("ESIC is required")
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("ESIC Number cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   memberId: Yup.string()
  //     .max(128, "Member Id is too long")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Member Id cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //       otherwise: Yup.string()
  //         .trim("Member Id cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),

  //   bankAccountNumber: Yup.string()
  //     .max(128, "Bank account number is too long")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Bank account cannot contain only whitespace")
  //         .strict(true)
  //         .nullable()
  //         .required("Bank account number is required"),
  //       otherwise: Yup.string()
  //         .trim("Bank account cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),
  //   IFSC: Yup.string().when("underPF", {
  //     is: true,
  //     then: Yup.string()
  //       .required("IFSC Code is required")
  //       .min(11, "IFSC code must have 11 digits")
  //       .max(11, "IFSC code must have 11 digits")
  //       .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code."),
  //     otherwise: Yup.string().nullable(),
  //   }),
  //   // .min(11, "IFSC code must have 11 digits")
  //   // .max(11, "IFSC code must have 11 digits")
  //   // .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Invalid IFSC code."),
  //   aadharNumber: Yup.string()
  //     .min(12, "Aadhaar number must have 12 digits")
  //     .max(12, "Aadhaar number must have 12 digits")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .required("Aadhaar card number is required")
  //         .test(
  //           "Digits only",
  //           "Aadhaar number should not contain any alphabet and special characters.",
  //           digitsOnly
  //         )
  //         .matches(
  //           /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
  //           "Aadhaar card number should not start with 0 or 1"
  //         ),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //   panNumber: Yup.string()
  //     .max(10, "Pan card number must have 12 digits")
  //     .min(10, "Pan card number must have 12 digits")
  //     .matches(
  //       /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  //       "Pan card number is not valid"
  //     )
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string().required("Pan card number is required"),
  //       otherwise: Yup.string().nullable(),
  //     }),
  //   bankName: Yup.string()
  //     .max(128, "Bank name is too long")
  //     .when("underPF", {
  //       is: true,
  //       then: Yup.string()
  //         .trim("Bank name cannot contain only whitespace")
  //         .strict(true)
  //         .nullable()
  //         .required("Bank name is required"),
  //       otherwise: Yup.string()
  //         .trim("Bank name cannot contain only whitespace")
  //         .strict(true)
  //         .nullable(),
  //     }),

  //   husbandName: Yup.string()
  //     .when("maritalStatus", {
  //       is: "Married",
  //       then: Yup.string()
  //         .max(64, "Husband name is too long")
  //         .trim("Husband name cannot contain only whitespace")
  //         .strict(true)
  //         .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
  //         .nullable()
  //         .required("Husband name is required"),
  //       otherwise: Yup.string()
  //         .max(64, "Husband name is too long")
  //         .trim("Husband name cannot contain only whitespace")
  //         .strict(true)
  //         .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
  //         .nullable()
  //       }),
  //   husbandDOB: Yup.date()
  //     .when("maritalStatus", {
  //     is: "Married",
  //     then: Yup.date()
  //     .required("Date of birth is required")
  //     .typeError("Invalid date")
  //     .nullable(),
  //     otherwise: Yup.date()
  //     .typeError("Invalid date")
  //     .nullable(),
  //     }),
      
  //   electionCardNumber: Yup.string()
  //   .trim("Election card number cannot contain only whitespace")
  //   .strict(true)
  //     // .min(10, "Election card number must have 10 letters")
  //     // .max(10, "Election card number must have 10 letters")
  //     // .matches(/^([a-zA-Z]){3}([0-9]){7}?$/, "Invalid Election card number")
  //     .nullable(),
  //   licenseNumber: Yup.string()
  //   .trim("Licence number cannot contain only whitespace")
  //   .strict(true)
  //     // .max(8, "Licence number must be of 8 letters")
  //     // .min(8, "Licence number must be of 8 letters")
  //     // .matches(/^[0-3][0-9]{7}$/, "Invalid Licence number")
  //     .nullable(),
  //   rationCardNumber: Yup.string()
  //     .matches(
  //       /^([a-zA-Z0-9]){8,12}\s*$/,
  //       "Invalid Ration card number(It should contain 8-12 alpha numeric letters)"
  //     )
  //     .nullable(),
  //   passportNumber: Yup.string()
  //     .max(9, "Passport number must be of 10 or less letters")
  //     .matches(/[a-zA-Z][0-9]{0,10}/, "Invalid Passport number")
  //     .nullable(),
  // }),
  serviceAgreement: Yup.object().shape({
    isUnderServiceAgreement: Yup.boolean().nullable(),
    isCheque: Yup.boolean().nullable(),
    isRetention: Yup.boolean().nullable(),
    type: Yup.array().when("isUnderServiceAgreement", {
      is: true,
      then: Yup.array().required("Agreement type is required").nullable(),
      otherwise: Yup.array().nullable(),
    }),
    bankName: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("Bank name is required")
        .nullable()
        .max(512, "Bank name is too long"),
      otherwise: Yup.string().nullable(),
    }),
    chequeAmount: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string().required("Amount is required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    IFSC: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("IFSC is required")
        .nullable()
        .max(512, "IFSC is too long"),
      otherwise: Yup.string().nullable(),
    }),
    bankBranch: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("Bank branch is required")
        .trim("Bank branch cannot contain only whitespace")
        .strict(true)
        .nullable()
        .max(512, "Bank branch is too long"),
      otherwise: Yup.string()
        .trim("Bank branch cannot contain only whitespace")
        .strict(true)
        .nullable(),
    }),
    bankAccountNumber: Yup.string().when(
      ["isCheque", "isUnderServiceAgreement"],
      {
        is: (first, second) => {
          return first && second;
        },
        then: Yup.string()
          .required("Bank account number is required")
          .nullable()
          .max(512, "Bank account number is too long"),
        otherwise: Yup.string().nullable(),
      }
    ),
    noOfCheque: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string().required("Number of Cheque is required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    chequeNumbers: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("Cheque Number is required")
        .nullable()
        .max(1024, "Cheque Number is too long"),
      otherwise: Yup.string().nullable(),
    }),
    // retentionAmount: Yup.string().when(
    //   ["isRetention", "isUnderServiceAgreement"],
    //   {
    //     is: (first, second) => {
    //       return first && second;
    //     },
    //     then: Yup.string().required("Amount is required").nullable(),
    //     otherwise: Yup.string().nullable(),
    //   }
    // ),
  }),
  // salary: Yup.object().shape({
  //     lastIncrementDate: Yup.date()
  //         .required("Last Increment Date is required")
  //         .typeError("Invalid Date"),
  //     salaryBeforeIncrement: Yup.number("Value must be a number")
  //         .required("Salary Before Increment is required")
  //         .typeError("Value must be a number"),
  //     currentCtc: Yup.number()
  //         .required("Current CTC is required")
  //         .typeError("Value must be a number"),
  //     expectedCtc: Yup.number("Value must be a number")
  //         .required("Expected CTC is required")
  //         .typeError("Value must be a number"),
  //     status: Yup.string("Value must be a string").required("Status is required"),
  //     addNoticePeriod: Yup.number("Value must be a number")
  //         .required("Notice Period is required")
  //         .typeError("Value must be a number"),

  // }),
  departments: Yup.array()
    .of(Yup.number())
    .min(1, "Minimum one department is required"),
});

export function checkPersonalDetails(error) {
  return Boolean(
    error.firstName ||
      error.lastName ||
      error.dateOfBirth ||
      error.roleId ||
      error.gender ||
      error.maritalStatus ||
      error.documents ||
      error.panCardImage ||
      error.aadharCardImage ||
      error.workLocation ||
      error.seatNumber||
      error.middleName ||
      error.bloodGroup ||
      error.portfolioWebsite ||
      error.github  || error.vehicleData
  );
}

export function checkContactDetails(error) {
  // console.log(error,"psdsds");
  return Boolean(error.contact) || Boolean(error.emails);
}

export function checkAddressDetails(error) {
  return Boolean(error.address);
}

export function checkEducationDetails(error) {
  return Boolean(error.education);
}

export function checkAdditionalDetails(error) {
  return Boolean(error.additionalCourse);
}
export function checkExperienceDetails(error) {
  return Boolean(error.experience);
}

export function checkSalaryDetails(error) {
  return Boolean(error.salary);
}

export function checkWorDetailsError(error) {
  return Boolean(error.workDetails || error.experience || error.departments);
}

export function checkPfErrors(error) {
  return Boolean(error.pf);
}

export function checkServiceAgreementError(error) {
  return Boolean(error.serviceAgreement);
}

export function checkChecklistsError(error) {
  return Boolean(
    error.checklists ||
      error.passportImage ||
      error.presentAddressImage ||
      error.permanentAddressImage ||
      error.masterCertificates ||
      error.bachelorCertificates
  );
}

export default employeeSchema;
