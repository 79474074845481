import * as Yup from "yup";

const vendorSchema = Yup.object().shape({
  firstName: Yup.string()
    .trim("First name cannot contain only whitespace")
    .strict(true)
    .required("First name is required")
    .max(64, "Maximum 64 characters required")
    .nullable(),
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email")
    .max(512, "Email is too long"),
  lastName: Yup.string()
    .trim("Last name cannot contain only whitespace")
	.strict(true)
    .required("Last Name is required")
    .max(64, "Maximum 64 characters required")
    .nullable(),
  streetLine1: Yup.string()
  .trim("streetLine1 cannot contain only whitespace")
  .strict(true)
    .required("Field is required")
    .min(2, "Minimum 2 characters required")
    .max(512, "Maximum 512 characters required")
    .nullable(),
  streetLine2: Yup.string()
  .trim("streetLine2 cannot contain only whitespace")
  .strict(true)
    .min(2, "Minimum 2 characters required")
    .max(512, "Maximum 512 characters required")
    .nullable(),
  area: Yup.string()
  .trim("Area cannot contain only whitespace")
  .strict(true)
    .required("Area is required")
    .min(2, "Minimum 2 characters required")
    .max(64, "Maximum 64 characters required")
    .nullable()
    .nullable(),
  city: Yup.string().required("City is required").nullable(),
  state: Yup.string().required("State is required").nullable(),
  country: Yup.string().required("Country is required").nullable(),
  postalCode: Yup.string()
    .matches(/^\d+$/, "Enter valid Zipcode")
    .max(8, "Invalid Postal code")
    .required("Zipcode is required")
    .nullable(),
  contactNumber: Yup.string()
    .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
    .min(10, "Contact number must be 10 digits")
    .max(10, "Contact number must be 10 digits")
    .required("Contact number is required")
    .nullable(),
});

export default vendorSchema;
