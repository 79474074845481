import * as React from "react";
import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import invertDirection from "../../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";

import {
  getDesignation,
  updateById,
  deleteById,
  insert,
} from "../../../redux/designation/designationThunk";
import { setSortBy, setOrderBy } from "../../../redux/designation/designationSlice";
import { designation } from "../../../validations/mastersSchema";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";

const ActivityStatus = () => {
  const confirm = useConfirm();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.designation.data);
  const loading = useSelector((state) => state.designation.loading);
  const error = useSelector((state) => state.designation.error);
	const sortBy = useSelector((state) => state.designation.sortBy);
	const orderBy = useSelector((state) => state.designation.orderBy);
  const [id, setId] = React.useState(null);
  const ref = useRef(null);

  const formik = useFormik({
    initialValues: {
      title: "",
      isActive: false,
    },
    validationSchema: designation,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (id) {
          await dispatch(updateById({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(insert(values)).unwrap();
        }
        resetForm();
        dispatch(getDesignation());
        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const { errors, setErrors, touched, handleSubmit, setValues, isSubmitting } =
    formik;

  useEffect(() => {
    if (
      !loading &&
      !error &&
      (!data || (Array.isArray(data) && data.length <= 0))
    )
      dispatch(getDesignation());
  }, [loading]);

  useEffect(() => {
    dispatch(
      getDesignation({
        sortBy,
        orderBy,
      })
    );
}, [dispatch,sortBy, orderBy]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setErrors({});
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const handleUpdate = (data) => {
    setValues({
      title: data.title,
      isActive: data.isActive,
    });
    setId(data.id);
  };

  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteById(id));
      await dispatch(getDesignation());
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleToggle = async (designation) => {
    try {
      await dispatch(
        updateById({
          id: designation.id,
          title: designation.title,
          isActive: !designation.isActive,
        })
      ).unwrap();
      dispatch(getDesignation());
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

  return (
    <>
    {/*<Card className="inner-component">
  <Container className="custom-container-grid">*/}
        <div className="header" style={{ padding: "20px 20px 5px"}}>
          <form ref={ref} autoComplete="off" noValidate onSubmit={handleSubmit}>
            <h6 className="component-name">DESIGNATION MASTER</h6>
            {getAddVisible("master") && (
              <div className="heading-part">
                <div className="input-field-part">
                  <TextField
                    id="outlined-basic"
                    label="Enter Designation"
                    variant="outlined"
                    size="small"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    inputProps={{ maxLength: 64 }}
                  />
                </div>
                <div className="checkbox-part">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isActive}
                        name="isActive"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Status"
                  />
                </div>
                <div className="button-part">
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    save
                  </LoadingButton>
                </div>
              </div>
            )}
          </form>
        </div>
        <TableContainer component={Paper}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {/* <TableCell width="10%">ID</TableCell> */}
                  <TableCell width="67%">
                    <TableSortLabel
                      active={sortBy === "title"}
                      direction={sortBy === "title" ? orderBy : "asc"}
                      onClick={() => handleSorting("title")}
                    >
                      Title
                    </TableSortLabel>
                  </TableCell>
                  <TableCell width="10%" align="center">
                    Status
                  </TableCell>
                  {(getEditVisible("master") || getDeleteVisible("master")) && (
                    <TableCell
                      width="13%"
                      align="right"
                      className="action-field"
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
            {loading ? (
              <Loader />
              ) : (
              <TableBody>
                {Array.isArray(data) && data.length === 0 ? (
                  <TableRow>
                    <TableCell className="No-Record-text" align="center" colSpan={3}>
                    No Record(s) Found
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((designation, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      {/* <TableCell component="th" scope="row">
                        {designation.id}
                      </TableCell> */}
                      <TableCell component="th" scope="row">
                        {designation.title}
                      </TableCell>
                      <TableCell align="right">
                        <Switch
                          checked={designation.isActive}
                          onChange={() => handleToggle(designation)}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                      </TableCell>
                      {(getEditVisible("master") ||
                        getDeleteVisible("master")) && (
                        <TableCell align="right">
                          {getEditVisible("master") && (
                            <IconButton
                              onClick={() => handleUpdate(designation)}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          )}
                          {getDeleteVisible("master") && (
                            <IconButton
                              onClick={() =>
                                handleDelete(designation.id, designation.title)
                              }
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
              )}
            </Table>
          
        </TableContainer>
      {/*</Container>
    </Card> */}
    </>
  );
};

export default ActivityStatus;
