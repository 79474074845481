// Letterhead.js
import React from 'react';
import { View, Image, Text, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderBottom: '1 solid #000',
    marginBottom: 20,
    paddingBottom: 10,
  },
  logo: {
    height: 70,
    width: 'auto',
    marginBottom: 5,
  },
  address: {
    fontSize: 10,
    textAlign: 'center',
  },
});

const Letterhead = () => (
  <View style={styles.header} fixed>
    <Image src="/assets/images/logo.png" style={styles.logo} />
    <Text style={styles.address}>
      B-305, The First, B/h ITC Narmada, Vastrapur, Ahmedabad – 380015.
    </Text>
  </View>
);

export default Letterhead;

