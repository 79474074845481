import { createSlice } from "@reduxjs/toolkit";
import { getInterviewList } from "./InterviewListThunk";

const initialState = {
	loading: false,
	status: "idle",
	error: null,
	data: [],
	complete: false,
	page: 1,
	totalPage: 1,
	limit: 40,
	totalCount: 0,
	sortBy: "interviewDate",
	orderBy: "desc",
};

// A slice for dashboard with our 3 reducers
export const InterviewListSlice = createSlice({
	name: "getInterviewList",
	initialState,
	reducers: {
		setSortBy: (state, action) => {
			state.sortBy = action.payload.sortBy;
			state.status = "idle";
		},
		setOrderBy: (state, action) => {
			state.orderBy = action.payload.orderBy;
			state.status = "idle";
		},
		setLimit: (state, action) => {
			state.limit = action.payload.limit;
			state.complete = false;
			state.page = 1;
			state.status = "idle";
			state.error = null;
		},
		setPage: (state, action) => {
			state.page = action.payload.page;
			state.complete = false;
			state.status = "idle";
			state.error = null;
		},
		setTotalPage: (state, action) => {
			state.totalPage = action.payload.totalPage;
			state.complete = false;
			state.status = "idle";
			state.error = null;
		},
	},
	extraReducers: {
		[getInterviewList.pending]: (state) => {
			state.loading = true;
		},
		[getInterviewList.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.data = payload.data.list || payload.data;
			state.completed = true;
			state.status = "completed";
			state.totalCount = payload.data.totalRecords;
			// state.totalCount = payload.data.totalRecords;
			// state.totalPage = Math.ceil(
			// 	payload.data.list.length / state.limit,
			// ); 
			state.totalPage = payload.data.totalPages;
			state.page = payload.data.currentPage;
		},
		[getInterviewList.rejected]: (state, payload) => {
			state.loading = false;
			state.error = payload;
			state.data = [];
			state.status = "rejected";
			state.completed = true;
			state.totalCount = 0;
			state.totalPage=0;
			state.page=0;

		},
	},
});

export const { setLimit, setPage, setOrderBy, setSortBy, setTotalPage } =
	InterviewListSlice.actions;

// The reducer
export const InterviewListReducer = InterviewListSlice.reducer;
