import { createSlice } from "@reduxjs/toolkit";
import { getFolderById, getFolders } from "./notebookThunk";

const initialState = {
    data:[],
    loading:false,
    complete:false,
    error:null,
}

const notebookSlice = createSlice({
    name:"helpdeskTickets",
    initialState,
    reducers:{},
    extraReducers: {
        [getFolders.pending]:(state)=>{
            state.loading = true;
        },
        [getFolders.fulfilled]:(state, {payload})=>{
            state.loading = false;
            state.data =  payload.data
            state.complete = true;
        },
        [getFolders.rejected]:(state)=>{
            state.loading = false;
            state.data = [];
            state.complete = true;
        },
        [getFolderById.pending]:(state)=>{
            state.loading = true;
        },
        [getFolderById.fulfilled]:(state, {payload})=>{
            state.loading = false;
            state.data =  payload.data
            // state.totalPages = payload.data.totalPages;
            // state.limit = payload.data.limit;
            // state.totalRecords = payload.data.totalRecords;
            // state.currentPage = payload.data.currentPage - 1;
            // state.complete = true;
            // state.status = "fulfilled"
        },
        [getFolderById.rejected]:(state)=>{
            state.loading = false;
            state.data = [];
            // state.complete = true;
            // state.status = "rejected";
            // state.totalPages = 0;
        },
        // [getTotalTicketCount.fulfilled]:(state, {payload})=>{
        //     state.totalTicketsCount = payload.data
        // },
    }

})

export const notebookReducer = notebookSlice.reducer;