import * as Yup from "yup";

const address = Yup.object().shape({
    state: Yup.string().required("Please select state.").nullable(),
    city: Yup.string().required("Please select city.").nullable(),
    area: Yup.string().max(512, "Max 512 characters required").required("Please enter area.").nullable(),
    postalCode: Yup.string().max(512, "Max 512 characters required").required("Please enter postalCode.").nullable(),
});

export default address;
