import React from "react";
import DashboardLayout from "../../../layouts/dashboard";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink } from "react-router-dom";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { helpDeskSchema } from "../../../validations/helpDeskSchema";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";
import {
  getHelpDeskCategoryById,
  insert,
  updateById,
  validateHelpDeskSubCategory,
} from "../../../redux/helpdeskCategories/helpdeskCategoriesThunk";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Divider from "@mui/material/Divider";
import { decryption } from "../../../utils/encodeString";
import Loader from "../../../components/Loader";

function AddHelpDeskCategories() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = !params.id;
  const [id, setId] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const defaultAssigneeData = useSelector(
    (state) => state.employee.allEmployeeData
  );
  const responsiblePersonData = useSelector(
    (state) => state.employee.allEmployeeData
  );

  
  const formik = useFormik({
    initialValues: {
      title: "",
      isActive: false,
      responsiblePerson: "",
      defaultAssignee: "",
      responseTime: "",
      prefix: "",
      subCategories: [
        {
          title: "",
          isActive: false,
          suggestedText: "",
        },
      ],
      cannedMessages: [
        {
          // subject: "",
          isActive: false,
          template: "",
        },
      ],
    },
    validationSchema: helpDeskSchema,
    // on save button click
    onSubmit: async (values) => {
      try {
        // convert response time in hours
        let tempObj = { ...values, responseTime: values.responseTime * 24 };
        if((hasActiveSubcategory(values?.subCategories) &&  values?.isActive === true) || (hasActiveSubcategory(values?.subCategories) || values?.isActive === false) ){
          if (isAddMode) {
            await dispatch(insert(tempObj)).unwrap();
            setSubmitting(false);
            resetForm();
            navigate("/categories");
          }
          // if user is editing category
          if (!isAddMode) {
            await dispatch(updateById({ ...tempObj, id })).unwrap();
            setSubmitting(false);
            resetForm();
            navigate("/categories");
          }
        }else{
          toast.error("At least one subcategory should be active!")
        }
        
        // if user is adding category
      } catch (error) {
        // toast.error(error.message);
      }
    },
  });
  function hasActiveSubcategory(subcategories) {
    return subcategories.some(subcategory => subcategory.isActive);
  }

  const {
    errors,
    values,
    touched,
    handleChange,
    handleSubmit,
    setValues,
    setFieldValue,
    isSubmitting,
    getFieldProps,
    handleBlur,
    setSubmitting,
    resetForm,
  } = formik;

  // api call for dropdown values
  useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

  // if user is editing then fetching category values
  useEffect(() => {
    const fetchById = async () => {
      if (!isAddMode) {
        setLoading(true);
        const decodeParam = decryption(params.id);
        setId(decodeParam);
        if (decodeParam === -1) {
          navigate("/categories");
        }
        // get category and set form fields
        try {
          let record = await dispatch(
            getHelpDeskCategoryById(decodeParam)
          ).unwrap();
          let body = {
            title: record?.title,
            isActive: record?.isActive,
            responsiblePerson: record?.responsiblePerson,
            defaultAssignee: record?.defaultAssignee,
            prefix: record?.prefix,
            responseTime: record?.responseTime / 24,
            subCategories: record?.helpDeskSubCategories,
            cannedMessages:
              record?.helpDeskCannedMessages.length === 0
                ? [
                    {
                      subject: "",
                      isActive: false,
                      template: "",
                    },
                  ]
                : record?.helpDeskCannedMessages,
          };

          setValues(body);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          //if there is no data then it gives error msg "No records found"
          if (error.status === 404 && !error.success) {
            toast.error(error.message);
            navigate("/categories");
          }
        }
      }
    };
    fetchById();
  }, []);

  // useEffect(() => {
  //   if (values?.isActive == false) {
  //     values?.subCategories?.map((validateHelpDeskSubCategoryitem, index) => {
  //       setFieldValue(`subCategories[${index}].isActive`, false);
  //     });
  //     values?.cannedMessages?.map((item, index) => {
  //       setFieldValue(`cannedMessages[${index}].isActive`, false);
  //     });
  //   }
    // if (values?.isActive == true) {
    //   values?.subCategories?.map((item, index) => {
    //     setFieldValue(`subCategories[${index}].isActive`, true);
    //   });
    //   values?.cannedMessages?.map((item, index) => {
    //     setFieldValue(`cannedMessages[${index}].isActive`, true);
    //   });
    // }
  // }, [values?.isActive]);

  // remove dynamically added fields on click of " - " button
  // console.log("VALUES",values)

  const removeField = async(e, index, fieldName) => {
    e.preventDefault();
    if (fieldName === "subCategories") {
      try {
        if(values?.subCategories[index]?.id){
          await dispatch(validateHelpDeskSubCategory(values?.subCategories[index]?.id)).unwrap()
        }
       const temp = [...values.subCategories];
       temp.splice(index, 1);
       setFieldValue("subCategories", temp);
      } catch (error) {
        // toast.error(error)
      }
   
    }
    if (fieldName === "cannedMessages") {
      const temp = [...values.cannedMessages];
      temp.splice(index, 1);
      setFieldValue("cannedMessages", temp);
    }
  };

  // add fields dynamically on click of " + " button
  const addMore = (fieldName) => {
    if (fieldName === "subCategories") {
      setFieldValue("subCategories", [
        ...values.subCategories,
        {
          title: "",
          isActive: false,
          suggestedText: "",
        },
      ]);
    }
    if (fieldName === "cannedMessages") {
      setFieldValue("cannedMessages", [
        ...values.cannedMessages,
        {
          // subject: "",
          isActive: false,
          template: "",
        },
      ]);
    }
  };
  const handleSubCategoriesSwitch = async(e,index) =>{
    try {
        if(values?.subCategories[index]?.id){
        await dispatch(validateHelpDeskSubCategory(values?.subCategories[index].id)).unwrap()
        }
        await setFieldValue(`subCategories[${index}].isActive`,!(values?.subCategories?.[index]?.isActive))
      } catch (error) {

      }
 
}

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
      <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          {isAddMode ? "Add Helpdesk Category" : "Edit Helpdesk Category"}
        </Typography>
        <Stack direction="row">
          <LoadingButton
            loading={isSubmitting}
            variant="contained"
            type="submit"
            sx={{ marginRight: "15px" }}
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
          <Button
            variant="contained"
            component={RouterLink}
            to="/categories"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}
          >
            Back To List
          </Button>
        </Stack>
        </div>
      </Stack>
      <>
        <form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          className="custom-space-layout"
        >
          <Card>
            {loading ? (
              <Loader />
            ) : (
              <Container maxWidth="xl">
                <Typography variant="h6" my={3}>
                  Category
                </Typography>

                <Grid
                  container
                  spacing={3}
                  py={3}
                  style={{ paddingBottom: "13px", paddingTop: "17px" }}
                >
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      autoComplete="off"
                      label="Title"
                      variant="outlined"
                      size="small"
                      name="title"
                      inputProps={{
                        "data-cy": "txt-title-department",
                      }}
                      FormHelperTextProps={{
                        "data-cy": "txt-title-err-department",
                      }}
                      value={values.title}
                      onChange={handleChange}
                      error={Boolean(touched.title && errors.title)}
                      helperText={touched.title && errors.title}
                      fullWidth
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={defaultAssigneeData || []}
                      {...getFieldProps(`defaultAssignee`)}
                      value={
                        defaultAssigneeData.find(
                          (x) => x?.employeeId === values.defaultAssignee
                        ) || null
                      }
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName || ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} value={option.employeeId} key={option.id}>
                          {option.firstName + " " + option.lastName || ""}
                        </li>
                      )}
                      onChange={(event, newValue) => {
                        setFieldValue("defaultAssignee", newValue?.employeeId || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Default Assignee"
                          error={Boolean(
                            touched?.defaultAssignee && errors?.defaultAssignee
                          )}
                          helperText={
                            touched?.defaultAssignee && errors?.defaultAssignee
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Autocomplete
                      size="small"
                      fullWidth
                      options={responsiblePersonData || []}
                      value={
                        responsiblePersonData.find(
                          (x) =>
                            x?.employeeId.toString() ===
                            values.responsiblePerson.toString()
                        ) || null
                      }
                      getOptionLabel={(option) =>
                        option.firstName + " " + option.lastName || ""
                      }
                      renderOption={(props, option) => (
                        <li {...props} value={option.employeeId} key={option.id}>
                          {option.firstName + " " + option.lastName || ""}
                        </li>
                      )}
                      onChange={(event, newValue) => {
                        setFieldValue("responsiblePerson", newValue?.employeeId || "");
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Responsible Person"
                          error={Boolean(
                            touched?.responsiblePerson &&
                              errors?.responsiblePerson
                          )}
                          helperText={
                            touched?.responsiblePerson &&
                            errors?.responsiblePerson
                          }
                        />
                      )}
                      key={(responsiblePersonData || []).map(
                        (x, index) => index
                      )}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      autoComplete="off"
                      label="Response Time (In days)"
                      variant="outlined"
                      size="small"
                      name="responseTime"
                      inputProps={{
                        "data-cy": "txt-title-responseTime",
                        maxLength: 3,
                      }}
                      FormHelperTextProps={{
                        "data-cy": "txt-title-err-responseTime",
                      }}
                      value={values.responseTime}
                      onChange={handleChange}
                      error={Boolean(
                        touched.responseTime && errors.responseTime
                      )}
                      helperText={touched.responseTime && errors.responseTime}
                      fullWidth
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      id="outlined-basic"
                      autoComplete="off"
                      label="Prefix"
                      variant="outlined"
                      size="small"
                      name="prefix"
                      inputProps={{
                        "data-cy": "txt-prefix-department",
                      }}
                      FormHelperTextProps={{
                        "data-cy": "txt-prefix-err-department",
                      }}
                      value={values.prefix}
                      onChange={handleChange}
                      error={Boolean(touched.prefix && errors.prefix)}
                      helperText={touched.prefix && errors.prefix}
                      fullWidth
                      onBlur={handleBlur}
                      disabled={isAddMode ? false : true}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack className="checkbox-part">
                        <FormControlLabel
                          control={
                            <Switch
                              checked={values.isActive}
                              name="isActive"
                              onChange={handleChange}
                              inputProps={{
                                "aria-label": "controlled",
                                "data-cy": "chk-isActive-department",
                              }}
                            />
                          }
                          label="Status"
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                <Typography variant="h6" my={3}>
                  Sub-Categories
                </Typography>
                {values &&
                  values?.subCategories.map((subCategory, index) => (
                    <>
                      <Grid
                        container
                        spacing={3}
                        py={3}
                        style={{ paddingBottom: "0px", paddingTop: "0px" }}
                      >
                        <Grid item xs={6}>
                          <TextField
                            id={`subCategoryTitle-${index}`}
                            autoComplete="off"
                            label="Title"
                            variant="outlined"
                            size="small"
                            name={`subCategories[${index}].title`}
                            inputProps={{
                              "data-cy": `txt-title-subcategory-${index}`,
                            }}
                            FormHelperTextProps={{
                              "data-cy": `txt-title-err-subcategory-${index}`,
                            }}
                            value={values.subCategories[index]?.title}
                            onChange={handleChange}
                            error={Boolean(
                              touched.subCategories &&
                                touched.subCategories[index] &&
                                errors.subCategories &&
                                errors.subCategories[index]?.title
                            )}
                            helperText={
                              touched.subCategories &&
                              touched.subCategories[index] &&
                              errors.subCategories &&
                              errors.subCategories[index]?.title
                            }
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Stack className="checkbox-part">
                            <FormControlLabel
                              control={
                                <Switch
                                  checked={
                                    values.subCategories[index]?.isActive
                                  }
                                  name={`subCategories[${index}].isActive`}
                                  onChange={(e)=>handleSubCategoriesSwitch(e,index)}
                                  inputProps={{ "aria-label": "controlled" }}
                                />
                              }
                              label="Status"
                              // disabled={!values?.isActive}

                            />
                          </Stack>
                        </Grid>
                        <Grid item xs={4}>
                          {index + 1 === values.subCategories.length ? (
                            <Grid item xs={4}>
                              {values.subCategories.length !== 1 && (
                                <IconButton
                                  onClick={(e) =>
                                    removeField(e, index, "subCategories")
                                  }
                                >
                                  <RemoveIcon></RemoveIcon>
                                </IconButton>
                              )}
                              <IconButton
                                onClick={() => addMore("subCategories")}
                              >
                                <AddIcon></AddIcon>
                              </IconButton>
                            </Grid>
                          ) : (
                            <Grid item xs={2}>
                              <IconButton
                                onClick={(e) =>
                                  removeField(e, index, "subCategories")
                                }
                              >
                                <RemoveIcon></RemoveIcon>
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            fullWidth
                            multiline
                            label="Message Template"
                            minRows={4}
                            variant="outlined"
                            size="small"
                            name={`subCategories[${index}].suggestedText`}
                            inputProps={{
                              inputComponent: TextareaAutosize,
                              maxLength: 512,
                              style: {
                                resize: "auto",
                              },
                            }}
                            sx={{ display: "none" }}
                            onChange={handleChange}
                            value={values.subCategories[index]?.suggestedText}
                          />
                        </Grid>
                      </Grid>
                    </>
                  ))}
              </Container>
            )}
          </Card>
        </form>
        <Card sx={{ mt: 5 }}>
          {loading ? (
            <Loader />
          ) : (
            <Container maxWidth="xl">
              <Typography variant="h6" my={3}>
                Canned Messages
              </Typography>
              {values &&
                values?.cannedMessages.map((subCategory, index) => (
                  <>
                    <Grid
                      container
                      spacing={3}
                      py={3}
                      style={{ paddingBottom: "13px", paddingTop: "17px" }}
                    >
                      {/* <Grid item xs={6}>
                        <TextField
                          id={`subCategoryTitle-${index}`}
                          autoComplete="off"
                          label="Subject"
                          variant="outlined"
                          size="small"
                          name={`cannedMessages[${index}].subject`}
                          inputProps={{
                            "data-cy": `txt-subject-subcategory-${index}`,
                          }}
                          FormHelperTextProps={{
                            "data-cy": `txt-subject-err-subcategory-${index}`,
                          }}
                          value={values.cannedMessages[index]?.subject}
                          onChange={handleChange}
                          error={Boolean(
                            touched.cannedMessages &&
                              touched.cannedMessages[index] &&
                              errors.cannedMessages &&
                              errors.cannedMessages[index]?.subject
                          )}
                          helperText={
                            touched.cannedMessages &&
                            touched.cannedMessages[index] &&
                            errors.cannedMessages &&
                            errors.cannedMessages[index]?.subject
                          }
                          fullWidth
                        />
                      </Grid> */}
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          multiline
                          label="Message Template"
                          minRows={4}
                          variant="outlined"
                          size="small"
                          name={`cannedMessages[${index}].template`}
                          inputProps={{
                            inputComponent: TextareaAutosize,
                            maxLength: 512,
                            style: {
                              resize: "auto",
                            },
                          }}
                          onChange={handleChange}
                          value={values.cannedMessages[index]?.template}
                          error={Boolean(
                            touched.cannedMessages &&
                              touched.cannedMessages[index] &&
                              errors.cannedMessages &&
                              errors.cannedMessages[index]?.template
                          )}
                          helperText={
                            touched.cannedMessages &&
                            touched.cannedMessages[index] &&
                            errors.cannedMessages &&
                            errors.cannedMessages[index]?.template
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <Stack className="checkbox-part">
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.cannedMessages[index]?.isActive}
                                name={`cannedMessages[${index}].isActive`}
                                onChange={handleChange}
                                inputProps={{ "aria-label": "controlled" }}
                              />
                            }
                            label="Status"
                            // disabled={!values?.isActive}

                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        {index + 1 === values.cannedMessages.length ? (
                          <Grid item xs={4}>
                            {values.cannedMessages.length !== 1 && (
                              <IconButton
                                onClick={(e) =>
                                  removeField(e, index, "cannedMessages")
                                }
                              >
                                <RemoveIcon></RemoveIcon>
                              </IconButton>
                            )}
                            <IconButton
                              onClick={() => addMore("cannedMessages")}
                            >
                              <AddIcon></AddIcon>
                            </IconButton>
                          </Grid>
                        ) : (
                          <Grid item xs={2}>
                            <IconButton
                              onClick={(e) =>
                                removeField(e, index, "cannedMessages")
                              }
                            >
                              <RemoveIcon></RemoveIcon>
                            </IconButton>
                          </Grid>
                        )}
                      </Grid>
                     
                    </Grid>
                    <Divider />
                  </>
                ))}
            </Container>
          )}
        </Card>
      </>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddHelpDeskCategories,
    path: "/categories/add",
    public: false,
    layout: DashboardLayout,
    group: "Helpdesk",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddHelpDeskCategories,
    path: "/categories/edit/:id",
    public: false,
    layout: DashboardLayout,
    group: "Helpdesk",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
