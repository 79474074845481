import { createSlice } from "@reduxjs/toolkit";
import { getEmployeeStatusWithDate } from "./reportsJoiningRelievingThunk";

const initialState = {
    data: [],
    loading: false,
    complete: false,
    status: "idle",
    totalPages:0,
    limit: 40,
    totalRecords: -1,
    currentPage: 0,
    error:null,
    totalCount:-1,
    isSearchQuery:false,
}

const reportsJoiningRelievingSlice = createSlice({
    name:"reportsJoiningRelieving",
    initialState,
    extraReducers: {
        [getEmployeeStatusWithDate.pending]:(state)=>{
            state.loading = true;
        },
        [getEmployeeStatusWithDate.fulfilled]:(state, {payload})=>{
            state.loading = false;
            state.data = payload.data;
            state.complete = true;
            state.status = "fulfilled"
        },
        [getEmployeeStatusWithDate.rejected]:(state, {payload})=>{
            state.loading = false;
            state.data = [];
            state.complete = true;
            state.status = "rejected";
            state.error = payload;
        },
    }
})

export const reportsJoiningRelievingReducer = reportsJoiningRelievingSlice.reducer;
export const { setSortBy, setOrderBy, setLimit, setCurrentPage } = reportsJoiningRelievingSlice.actions