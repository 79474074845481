import React, { useEffect } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import DashboardLayout from "../../layouts/dashboard";
import Scrollbar from "../../components/Scrollbar";

import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import { useDispatch } from "react-redux";
import LocalStorage from "../../service/localStorage";
import { encryption } from "../../utils/encodeString";

function Policy() {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const getUserPermission = async (Id) => {
		
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("policy")) {
			navigate("/dashboard");
		}
	}, []);

	return (
		<Container className="Employee-wrapper Policies-main-container" maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
			<div className="title-button-wrapper Policies-main-title">
				<Typography variant="h4" gutterBottom>
					Policies 
					{/* <Typography variant="body2">
            Total Records : 1
          </Typography> */}
				</Typography>
				{getAddVisible("policy") && (
					<Button
					className="header-add-button"
						variant="contained"
						component={RouterLink}
						to="/policy/add"
						// startIcon={<AddIcon />}
					>
						<img src="/assets/images/add-circle.svg" />
						Add Policy
					</Button>
				)}
				</div>
			</Stack>

			<Card className="policy_detail_main_card">
				<Scrollbar>
					<TableContainer sx={{ minWidth: 800 }}>
						<Table className="grid-table policy_detail-table">
							<TableHead>
								<TableRow>
									<TableCell align="left">Policy Name</TableCell>
									<TableCell align="left">Department</TableCell>
									<TableCell align="left">Description</TableCell>
									<TableCell align="left">Created</TableCell>
									{/* {getEditVisible("policy") ||
										(getDeleteVisible("policy") && ( */}
											<TableCell className="th-action-buttons" align="center">Action</TableCell>
										{/* ))} */}
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell>Leave Policy</TableCell>
									<TableCell>All Departments</TableCell>
									<TableCell>Lorem ipsum dollar</TableCell>
									<TableCell>19 Feb 2019</TableCell>
									{/* {getEditVisible("policy") ||
										(getDeleteVisible("policy") && ( */}
											<TableCell className="td-action-button" align="center">
												<div className="action-button">
													{getEditVisible("policy") && (
														<IconButton>
															{/* <EditIcon color="primary" /> */}
															<img src="assets/images/edit.svg" title="Edit" />
														</IconButton>
													 )} 
													{getDeleteVisible("policy") && (
														<IconButton>
															<img src="assets/images/trash-gray.svg" title="trash" />
															{/* <DeleteIcon color="error" /> */}
														</IconButton>
													)} 
												</div>
											</TableCell>
										{/* ))} */}
								</TableRow>
							</TableBody>
						</Table>
					</TableContainer>
				</Scrollbar>
			</Card>
		</Container>
	);
}

const componentConfig = {
	component: Policy,
	path: "/policy",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
