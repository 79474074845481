import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Grid,
  Box,
  Tooltip,
  IconButton,
  Divider,
  TextField,
} from "@mui/material";
import {
  Folder as FolderIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../constants";
import { getDateWithMonthName } from "../../../utils/formatTime";
import { useDispatch } from "react-redux";
import {
  deleteFolder,
  editFolderName,
  getFolders,
} from "../../../redux/notebook/notebookThunk";
import CancelIcon from "@material-ui/icons/Cancel";
import { useConfirm } from "material-ui-confirm";
import { encryption } from "../../../utils/encodeString";
import {
  getDeleteVisible,
  getEditVisible,
} from "../../../utils/userPermission";

const FolderCard = ({ folder, getFoldersData }) => {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const [isEdit, setIsEdit] = useState(null);
  const [error, setError] = useState(false);
  const [folderName, setFolderName] = useState(folder?.folderName || "");

  const navigate = useNavigate();
  const handleFolderClick = () => {
    console.log("path", `folder/view/${folder.id}`);
    const encryptedId = encryption(folder?.id);
    navigate(`/folder/view/${encryptedId}`);
  };

  const handleEditFolderClick = () => {
    setIsEdit(folder?.id || 0);
  };
  const handleInputChange = (event) => {
    setFolderName(event.target.value);
    if (event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (folderName.trim() === "") {
        setError(true);
      } else {
        try {
          dispatch(editFolderName({ id: folder?.id, body: { folderName } }))
            .unwrap()
            .then(() => {
              setIsEdit(null);
              setFolderName("");
              getFoldersData();
            });
        } catch (error) {
          console.log(
            "%c  error:",
            "color: #0e93e0;background: #aaefe5;",
            error
          );
        }
        // (folderName.trim());
        // setAddFolder(false);
      }
    }
  };

  const handleDeleteFolder = () => {
    try {
      confirm({
        description: `Are you sure you want to delete ${folder?.folderName}?`,
      }).then(() => {
        try {
          dispatch(deleteFolder({ id: folder?.id }))
            .unwrap()
            .then(() => {
              getFoldersData();
            });
        } catch (error) {
          console.log(
            "%c  error:",
            "color: #0e93e0;background: #aaefe5;",
            error
          );
        }
      });
    } catch (error) {
      console.log("%c  error:", "color: #0e93e0;background: #aaefe5;", error);
    }
  };

  return (
    <Card
      className="folder_card"
      sx={{
        backgroundColor: "rgb(249 249 249)",
        cursor: "pointer",
      }}
    >
      <CardContent className="folder_boxcontent">
        <Box className="folder_box" onDoubleClick={handleFolderClick} sx={{ cursor: "pointer" }}>
          <Box className="folder_box-top" display="flex" gap={"8px"} style={{flexDirection: "column"}}>
            {/* <FolderIcon className="folder-icon" sx={{ fontSize: 40, marginRight: 1 }} /> */}
            <img src="/assets/images/folder.svg" style={{ width: "60px", marginTop: "-4px", marginLeft: "-6px"  }} />
            <Box className="cardbox-text" sx={{ minWidth: 0, flex: 1 }}>
              {isEdit === folder?.id ? (
                <Box className="cardbox_main" display="flex">
                  <TextField
                    id="standard-basic"
                    label="Name"
                    variant="standard"
                    value={folderName || ""}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    error={error}
                    helperText={error ? "Folder name is required" : ""}
                    autoComplete="off"
                  />
                  <IconButton onClick={() => setIsEdit(false)}>
                    <CancelIcon />
                  </IconButton>
                </Box>
              ) : (
                <Tooltip title={folder?.folderName || ""}>
                  <Typography
                    variant="h6"
                    noWrap
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {folder?.folderName || ""}
                  </Typography>
                </Tooltip>
              )}
              <div className="card-bottom">
                <Typography fontSize={"12px"} color="textSecondary">
                  {folder?.fileCount || 0} File(s)
                </Typography>
                <Typography variant="caption">
                {folder?.createdDate ?
                  getDateWithMonthName(folder?.createdDate) :''}
                </Typography>
              </div>
            </Box>
          </Box>
          <Box className="folder_box-bottom">
            <Typography variant="caption">
              Last Updated At:
              {folder?.updatedDate ?
                getDateWithMonthName(folder?.updatedDate) :''}
            </Typography>
            <Tooltip
            className="profile-tooltip"
              title={
                (folder?.folderCreatedBy?.firstName &&
                folder?.folderCreatedBy?.lastName
                  ? folder?.folderCreatedBy?.firstName +
                    " " +
                    folder?.folderCreatedBy?.lastName
                  : folder?.folderCreatedBy?.firstName) || ""
              }
            >
              <Avatar
                src={`${BASE_URL}/${folder?.folderCreatedBy?.employeeImage}`}
                alt={
                  folder?.folderCreatedBy?.firstName?.toUpperCase() ||
                  folder?.folderCreatedBy?.lastName?.toUpperCase() ||
                  ""
                }
                sx={{ width: 28, height: 28, marginLeft: 1 }}
              />
            </Tooltip>
          </Box>
          {/* <Box textAlign="end" marginTop={2}>
            <Typography variant="caption">
              {folder?.updatedDate
                ? getDateWithMonthName(folder?.updatedDate)
                : ""}
            </Typography>
          </Box> */}
        </Box>
        {(getEditVisible("folder") || getDeleteVisible("folder")) &&
          !folder?.isSystemDefault && (
            <>
              {/* <Divider sx={{ marginY: 2 }} /> */}
              <Box className="box-icons" display="flex" justifyContent="flex-end">
                {getEditVisible("folder") && (
                  <IconButton onClick={handleEditFolderClick}>
                    <EditIcon />
                  </IconButton>
                )}
                {getDeleteVisible("folder") && (
                  <IconButton onClick={handleDeleteFolder}>
                    <DeleteIcon />
                  </IconButton>
                )}
              </Box>
            </>
          )}
      </CardContent>
    </Card>
  );
};

export default FolderCard;
