import * as Yup from "yup";

const evaluationSchema = Yup.object().shape({
  title: Yup.string()
    .required("Evaluation Title is required")
    .trim("Evaluation Title cannot contain only whitespace")
    .strict(true)
    .nullable(),
  designationId: Yup.array()
    .min(1, "At least one designation must be selected")
    .required("Designation is required"),
  sections: Yup.array().of(
    Yup.object().shape({
      section: Yup.string().required("Section is required"),
      questions: Yup.array().of(
        Yup.object().shape({
          question: Yup.string().required("Question is required"),
        })
      ),
    })
  ),
});

export default evaluationSchema;