import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Container from "@mui/material/Container";
import itemAttributeValuesSchema from "../../../validations/itemAttributeValuesSchema";
import Rating from '@mui/material/Rating';
import Autocomplete from "@mui/material/Autocomplete";

import {
  addAttributeValue,
  getAllItems,
} from "../../../redux/assestManagment/thunk";

function AttributeValues({ attributeId, attributeValues , attributeType, row }) {
  const dispatch = useDispatch();
  const scoreList = ["0", "1", "2","3","4","5","6","7","8","9","10"];
  const [itemId, setItemId] = useState(0);
  const [attrId, setAttrId] = useState();
  const initialValues = {
    itemAttributeId: attributeId,
    attributeValues,
    attributeType : attributeType
  };

  const formik = useFormik({
    initialValues,
    validationSchema: itemAttributeValuesSchema,
    onSubmit: async (values) => {
      try {
        let newAttributesValues = [];
        for (const details of values.attributeValues) {
          if (details.isDeleted == false || details.id) {
            newAttributesValues.push(details);
          }
        }
        values.attributeValues = newAttributesValues;

        await dispatch(addAttributeValue(values)).unwrap();
        dispatch(getAllItems({ page: 1, limit: 10 }));
      } catch (error) {
        toast.error(error.message);
        dispatch(getAllItems({ page: 1, limit: 10 }));
      }
    },
  });

  const {
    touched,
    values,
    setErrors,
    setFieldValue,
    getFieldProps,
    handleSubmit,
    isSubmitting,
    errors,
  } = formik;

  const handleAddMoreAttributes = () => {
    setFieldValue("attributeValues", [
      ...values.attributeValues,
      { id: null, value: "",isDeleted: false },
    ]);
  };

  const removeItemOnce = (attributeValues, index) => {
    if (index > -1) {
      attributeValues.splice(index, 1);
    }
    return attributeValues;
  };

  const handleRemoveAttributes = (index) => {
    const temp = values.attributeValues.map((attr, idx) => {
      if (index === idx) {
        return { ...attr, isDeleted: true };
      }
      return attr;
    });

    if (errors?.attributeValues) {
      let tempError = errors?.attributeValues;

      if (index > -1) {
        tempError.splice(index, 1);
      }
      setErrors({ attributeValues: tempError });
    }
    setFieldValue("attributeValues", temp);
  };

  useEffect(() => {
    row?.attributes?.map((item) => {
      if(item?.name == "Processor")
      {
        setItemId(item.itemId)
      }
    })
  },[])

  const handleChange = (e,index) => {
    const regex = /^[0-9\b]+$/;
    if (e.target.value === "" || regex.test(e.target.value)) {
      setFieldValue(`attributeValues[${index}].score`,e.target.value);
    }
  };

  return (
    <Container maxWidth="xl">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {values.attributeValues.map((val, index) => {
            if (!val?.isDeleted) {
              return (
                <Grid
                  display="flex"
                  alignItems="center"
                  key={index}
                  item
                  xs={3}
                >
                  {errors?.attributeValues ? (
                    errors?.attributeValues[index] ? (
                      <>
                        <TextField
                          label="Value"
                          {...getFieldProps(`attributeValues[${index}].value`)}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                          // error={Boolean(errors?.attributeValues[index])}
                          // helperText={errors?.attributeValues[index].value}

                          error={Boolean(
                            touched?.attributeValues &&
                              touched?.attributeValues[index] &&
                              touched?.attributeValues[index]?.value &&
                              errors?.attributeValues &&
                              errors?.attributeValues[index] &&
                              errors?.attributeValues[index]?.value
                          )}
                          helperText={
                            touched?.attributeValues &&
                            touched?.attributeValues[index] &&
                            touched?.attributeValues[index]?.value &&
                            errors?.attributeValues &&
                            errors?.attributeValues[index] &&
                            errors?.attributeValues[index]?.value
                          }
                        />
                      </>
                    ) : (
                      <>
                        <TextField
                          label="Value"
                          {...getFieldProps(`attributeValues[${index}].value`)}
                          variant="outlined"
                          size="small"
                          autoComplete="off"
                        />
                      </>
                    )
                  ) : (
                    <>
                      <TextField
                        label="Value"
                        {...getFieldProps(`attributeValues[${index}].value`)}
                        variant="outlined"
                        size="small"
                        autoComplete="off"
                      />
                    </>
                  )} &nbsp;&nbsp;

                  {val.itemAttributeId == itemId || values.attributeValues[0].itemAttributeId == itemId ? 
                    <TextField
                      label="Score"
                      type="number"
                      {...getFieldProps(`attributeValues[${index}].score`)}
                      variant="outlined"
                      size="small"
                      autoComplete="off"
                      defaultValue={0}
                      InputProps={{ inputProps: { max: 999999 } }}
                      
                      // error={Boolean(
                      //   touched?.attributeValues &&
                      //     touched?.attributeValues[index] &&
                      //     touched?.attributeValues[index]?.score &&
                      //     errors?.attributeValues &&
                      //     errors?.attributeValues[index] &&
                      //     errors?.attributeValues[index]?.score
                      // )}
                      // helperText={
                      //   touched?.attributeValues &&
                      //   touched?.attributeValues[index] &&
                      //   touched?.attributeValues[index]?.score &&
                      //   errors?.attributeValues &&
                      //   errors?.attributeValues[index] &&
                      //   errors?.attributeValues[index]?.score
                      // }
                    />
                  : ""}
                  {/* <Rating name="no-value" value={null} /> */}
                  <IconButton
                    onClick={(e) => handleRemoveAttributes(index)}
                    color="error"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              );
            }
            return null;
          })}

          <Grid item xs={12}>
            <Stack justifyContent="flex-end" direction="row" spacing={1}>
              <Button
                onClick={handleAddMoreAttributes}
                variant="contained"
                color="primary"
                size="small"
              >
                Add Attribute Value
              </Button>
              <LoadingButton
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                size="small"
              >
                Save
              </LoadingButton>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}

export default AttributeValues;
