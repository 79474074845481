import React, { useState } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { FilePond, registerPlugin } from "react-filepond";
import { useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { toast } from "react-toastify";
import { uploadStudentFile } from "../../../redux/drives/drivesThunk";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import "filepond/dist/filepond.min.css";
registerPlugin(FilePondPluginFileValidateType);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ExportExcelModal = NiceModal.create(({ driveId }) => {
  const [cv, setCv] = useState([]);
  const [percentage, setPercentage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const dispatch = useDispatch();

  const modal = useModal();

  //need to export with useMemo
  const getMessageType = (percentage) => {
    if (percentage >= 80) {
      return { color: "rgb(30, 70, 32)", border: "1px solid rgb(76, 175, 80)" };
    }

    if (percentage >= 50 && percentage < 80) {
      return { color: "rgb(102, 60, 0)", border: "1px solid rgb(255, 152, 0)" };
    }

    if (percentage >= 30 && percentage < 50) {
      return { color: "orange", border: "1px solid orange" };
    }

    return { color: "rgb(95, 33, 32)", border: "1px solid rgb(239, 83, 80)" };
  };

  const handleScan = async () => {
    if (cv.length === 0) {
      return setError("Please select a csv file to upload.");
    }

    setLoading(true);
    const formData = new FormData();

    formData.append("id", driveId);
    formData.append("studentsExcel", cv[0].file);

    try {
      const response = await dispatch(uploadStudentFile(formData)).unwrap();
      if (response.status === 200) {
        toast.success("Data imported successfully.");
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
      modal.hide();
    }
  };

  return (
    <Dialog
      TransitionComponent={Transition}
      open={modal.visible}
      onClose={() => modal.hide()}
      TransitionProps={{
        onExited: () => modal.remove(),
      }}
      fullWidth
    >
      <DialogTitle id="alert-dialog-slide-title">Upload Excel</DialogTitle>
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        {error && (
          <Alert
            onClose={() => {
              setError(null);
            }}
            variant="outlined"
            severity="error"
          >
            {error}
          </Alert>
        )}

        <FilePond
          files={cv}
          onupdatefiles={setCv}
          credits={false}
          acceptedFileTypes={[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          ]}
          name="cv"
          labelIdle='Drag & Drop a File or <span class="filepond--label-action">Browse</span>'
        />
        {percentage !== null && (
          <Alert
            onClose={() => {
              setPercentage(null);
            }}
            severity="info"
            icon={false}
            variant="outlined"
            sx={{ color: "black", border: getMessageType(percentage).border }}
          >
            Skills Matched{" "}
            <strong style={{ color: getMessageType(percentage).color }}>
              {percentage} %
            </strong>{" "}
            with our requirements
          </Alert>
        )}
      </DialogContent>

      <DialogActions>
        <Button color="error" variant="contained" onClick={() => modal.hide()}>
          Cancel
        </Button>
        <LoadingButton
          loading={loading}
          color="primary"
          variant="contained"
          onClick={handleScan}
        >
          Scan
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
});
