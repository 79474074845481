import * as Yup from "yup";
import { get, isEmpty } from "lodash";
var regEx = (value) =>
  /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    value
  );

const personalSchema = Yup.object().shape({
  ispName: Yup.string().required("First name is required")
  .trim("First name cannot contain only whitespace")
  .strict(true)
  .nullable(),
  tariffPlanName: Yup.string().required("Tariff plan name is required")
  .trim("Tariff plan name cannot contain only whitespace")
  .strict(true)
  .nullable()
  ,
  billingFrom: Yup.date()
    // .max(new Date(), "From date can't be future date")
    .required("From date is required")
    .typeError("Enter valid date")
    .nullable(),
  billingTo: Yup.date()
    .required("To date is required")
    .min(Yup.ref("billingFrom"), "To date can't be before from date")
    // .max(new Date(), "To date can't be future date")
    .typeError("Enter valid date")
    .nullable()
    .isPresentValidation("To date is required"),
  rentalCharges: Yup.string().required("Rental charges is required")
  .matches(/^[0-9]+$/, 'Please enter numeric value')
  .nullable(),
  dataLimitPerBillCycle: Yup.string()
  .matches(/^[0-9]+$/, 'Please enter numeric value')
    .nullable(),
  portalAccountRelationshipNumber: Yup.number()
    .typeError("Please enter numeric value")
    .nullable(),
 staticIpCharges: Yup.string()
 .matches(/^[0-9]+$/, 'Please enter numeric value')
   .nullable(),
  routerGatewayIp: Yup.string()
    // .test("", "Invalid IP address", regEx)
    .test("", "Invalid IP address",  (value) => {
      const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
  
      if (!value) return true; 
      return ipRegex.test(value)
    }
      )
    .nullable(),
  routerUsername: Yup.string()
  .trim("Router name cannot contain only whitespace")
  .strict(true)
  .nullable()
  ,
  routerPassword: Yup.string()
  .trim("Router password cannot contain only whitespace")
  .strict(true)
  .nullable(),
  wifiPassword: Yup.string()
  .trim("Wi-fi password cannot contain only whitespace")
  .strict(true)
  .nullable(),
  wifiSsid: Yup.string()
    .matches(/^[a-zA-Z0-9]+$/, "Only characters and digits are allowed")
    .nullable(),
  // wifiPassword: Yup.string().required("Wifi password is required"),
  //portalUrl: Yup.string().required("Portal url is required")
  portalUrl: Yup.string().url("Invalid url"),
  portalUsername: Yup.string().trim("User name cannot contain only whitespace")
  .strict(true)
  .nullable(),
  portalPassword: Yup.string().trim("Password cannot contain only whitespace")
  .strict(true)
  .nullable(),
  // portalAccountRelationshipNumber: Yup.string().required("Portal account relationship number is required"),
  // notes: Yup.string().required("Notes is required"),
  staticIpDetail: Yup.array().when("staticIp", {
    is: true,
    then: Yup.array().of(
      Yup.object().shape({
        staticIpAddress: Yup.string()
         .test("", "Invalid IP address",  (value) => {
      const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
  
      if (!value) return true; 
      return ipRegex.test(value)
    }
      )
          .nullable(),
        assignedToIp: Yup.string()
         .test("", "Invalid IP address",  (value) => {
      const ipRegex = /^(\d{1,3}\.){3}\d{1,3}$/;
  
      if (!value) return true; 
      return ipRegex.test(value)
    }
      )
          .nullable(),
        assignedToDomain: Yup.string()
        .trim("Assigned To Domain cannot contain only whitespace")
        .strict(true)
        .nullable(),
       
      })
    ),
  }),
  ispFixedLine: Yup.array().when("fixedLine", {
      is: true,
      then: Yup.array().of(
          Yup.object()
              .shape({
                  phoneNumber:Yup.string()
                  .matches(/^[6-9]\d{9}$/, "Phone number is invalid")
                  .min(10, "Phone number must be 10 digits")
                  .max(10, "Phone number must be 10 digits")
                  .nullable(),
              })
      )
    }),

  notes: Yup.string()
  .trim("Custom Notes cannot contain only whitespace")
  .strict(true)
  .nullable(),
  supportPerson: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().required("Name is required.")
      .trim("Name cannot contain only whitespace")
  .strict(true)
      .nullable(),
      phoneNumber: Yup.string()
        .required("Phone number is required.")
        .matches(/^[6-9]\d{9}$/, "Phone number is invalid")
        .min(10, "Phone number must be 10 digits")
        .max(10, "Phone number must be 10 digits")
        .nullable(),
    })
  ),
});

export default personalSchema;
