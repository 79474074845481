import React, { useEffect, useState, memo, useRef,useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import {
  getSerailNumberByItemIds,
  checkSerialNumber,
} from "../../../../redux/assets/thunk";
const filter = createFilterOptions();

function HasOneComponent({
  assetItemId,
  required,
  title,
  isFocused,
  handleFocus,
  isInventoriable,
  itemAttribute,
  values,
  setFieldValue,
  getAttributeLabel,
  getAttributteValuesById,
  assetErrors,
  touchedAssets,
  setErrors,
  errors,
  touched,
  handleBlur,
  status,
  setStatus,
  handleChange,
  assetId,
}) {
  const [loading, setLoading] = useState(false);

  return (
    <Container maxWidth="xl">
      <Stack mt={3} direction="row" spacing={2}>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        {loading && <CircularProgress size={25} />}
      </Stack>

      <Grid container spacing={3} py={3}>
        {values[assetItemId]?.map((itmattr, index) => {
          return (
            <MemoizedItemAttributeValueFields
              key={index}
              isInventoriable={isInventoriable}
              itemAttributes={itmattr}
              isFocused={isFocused}
              handleFocus={handleFocus}
              getAttributeLabel={getAttributeLabel}
              index={index}
              assetItemId={assetItemId}
              getAttributteValuesById={getAttributteValuesById}
              values={values}
              setFieldValue={setFieldValue}
              itemAttribute={itemAttribute}
              loading={loading}
              setLoading={setLoading}
              assetErrors={assetErrors}
              touchedAssets={touchedAssets}
              setErrors={setErrors}
              errors={errors}
              touched={touched}
              handleBlur={handleBlur}
              status={status}
              setStatus={setStatus}
              handleChange={handleChange}
              assetId={assetId}
              required={required}
            />
          );
        })}
      </Grid>
    </Container>
  );
}

const ItemAttributeValueFields = ({
  itemAttributes,
  getAttributeLabel,
  isInventoriable,
  getFieldProps,
  isFocused,
  handleFocus,
  values,
  index,
  assetItemId,
  getAttributteValuesById,
  setFieldValue,
  itemAttribute,
  loading,
  setLoading,
  assetErrors,
  touchedAssets,
  setErrors,
  errors,
  touched,
  handleBlur,
  status,
  setStatus,
  handleChange,
  assetId,
  required,
}) => {
  // const keys = Object.keys(itemAttributes);
  const keys = useMemo(() => Object.keys(itemAttributes), [itemAttributes]);
  // const [isFocused, setIsFocused] = useState({});
  const [serialNumberOPtions, setSerialNumberOPtions] = useState([]);

  const [serialNumberError, setSerialNumberError] = useState({});

  const handleOnChange = (event, newValue, assetItemId, index, id) => {
    setFieldValue(`assets.${assetItemId}.${index}.serialNumber`, "");
    setFieldValue(
      `assets.${assetItemId}.${index}.${id}.value`,
      newValue?.id || ""
    );


  };

  const handleTextChange = (e, assetItemId, index, id) => {
    setFieldValue(
      `assets.${assetItemId}.${index}.${id}.freeText`,
      e.target.value || ""
    );
    handleFocus(index, id);
    setFieldValue(`assets.${assetItemId}.${index}.serialNumber`, "");
  };

  //console.log(errors);
  const getSerailNumber = async (itemAttributes) => {
    try {
      setLoading(true);
      const requestBody = { ...itemAttributes };

      delete requestBody["serialNumber"];

      const response = await getSerailNumberByItemIds(requestBody);
      setSerialNumberOPtions(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };
  const createObject = (key, idx) => {
    if (!errors["assets"]) errors["assets"] = {};
    if (!errors["assets"][key]) errors["assets"][key] = [];
    if (!errors["assets"][key][idx]) errors["assets"][key][idx] = {};
  };
  const checkSerialNumberF = async (serialNumberValue, assetItemId, index) => {
    try {
      //console.log(errors)
      const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?~]/;

      if (serialNumberValue.length < 256) {
        if (!specialChars.test(serialNumberValue)) {
          const response = await checkSerialNumber({
            serialNumber: serialNumberValue,
            itemId: assetItemId,
          });

          if (response.isExist) {
            let tmpAssetsItemIdSerialNumberError = {
              serialNumber: "Duplicate serial number entered",
            };

            createObject(assetItemId, index);

            errors["assets"][assetItemId][index] =
              tmpAssetsItemIdSerialNumberError;

            setErrors({ ...errors });
            //console.log(errors)
            let tmpSerialNumberObj = { [assetItemId]: { [index]: true } };

            setSerialNumberError({ ...tmpSerialNumberObj });
          } else {
            setFieldValue(
              `assets.${assetItemId}.${index}.serialNumber`,
              serialNumberValue || ""
            );
          }
        } else {
          let tmpAssetsItemIdSerialNumberError = {
            serialNumber: "Serial Number Has Special Character",
            
          };
          createObject(assetItemId, index);

          errors["assets"][assetItemId][index] =
            tmpAssetsItemIdSerialNumberError;

          setErrors({ ...errors });

          let tmpSerialNumberObj = { [assetItemId]: { [index]: true } };

          setSerialNumberError({ ...tmpSerialNumberObj });
        }
      } else {
        let tmpAssetsItemIdSerialNumberError = {
          serialNumber: "Serial Number not valid",
        };

        createObject(assetItemId, index);

        errors["assets"][assetItemId][index] = tmpAssetsItemIdSerialNumberError;
        setErrors({ ...errors });

        let tmpSerialNumberObj = { [assetItemId]: { [index]: true } };
        setSerialNumberError({ ...tmpSerialNumberObj });
      }
    } catch (error) {}
  };
  const getAllowFreeTextValueById = (id, itemAttribute) => {
    let res = itemAttribute.find((item) => item.id == id);
    if (res?.allowFreeText) {
      return true;
    } else {
      return false;
    }
  };
  const getItemValue = (assetItemId, index, id) => {
    const res = values[assetItemId][index][id].freeText || "";

    return res;
  };
  useEffect(() => {
    if (isInventoriable) {
      getSerailNumber(itemAttributes);
    }
  }, [values[assetItemId][index], isInventoriable]);

  const setInputProps = (id) => {
    id = id.toString();
  };

  return (
    <>
      {keys.map((id) => {
        if (id === "serialNumber") return null;
        return (
          <Grid key={uuidv4()} item xs={12}>
            {!getAllowFreeTextValueById(id, itemAttribute) ? (
              <Autocomplete
                size="small"
                fullWidth
                disabled={loading}
                defaultValue=""
                input={
                  <OutlinedInput label={getAttributeLabel(id, itemAttribute)} />
                }
                name={`assets.${assetItemId}.${index}.${id}.value`}
                options={getAttributteValuesById(id, itemAttribute) || []}
                getOptionLabel={(option) => option.value || ""}
                value={
                  getAttributteValuesById(id, itemAttribute).find(
                    (i) => i.id === values[assetItemId][index][id]["value"]
                  ) || ""
                }
                onChange={(event, newValue) => {
                  handleOnChange(event, newValue, assetItemId, index, id);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={getAttributeLabel(id, itemAttribute)}
                    error={
                      required
                        ? Boolean(
                            touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                              "value"
                            ] && assetErrors?.[assetItemId]?.[index]?.[id]
                          )
                        : ""
                    }
                    helperText={
                      required
                        ? touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                            "value"
                          ] && assetErrors?.[assetItemId]?.[index]?.[id]
                        : ""
                    }
                  />
                )}
              />
            ) : (
              <TextField
                size="small"
                name={`assets.${assetItemId}.${index}.${id}.freeText`}
                onChange={(e) => handleTextChange(e, assetItemId, index, id)}
                value={getItemValue(assetItemId, index, id) || ""}
                label={getAttributeLabel(id, itemAttribute)}
                fullWidth
                error={
                  required
                    ? Boolean(
                        touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                          "freeText"
                        ] && assetErrors?.[assetItemId]?.[index]?.[id]
                      )
                    : ""
                }
                helperText={
                  required
                    ? touchedAssets?.[assetItemId]?.[index]?.[id]?.[
                        "freeText"
                      ] && assetErrors?.[assetItemId]?.[index]?.[id]
                    : ""
                }
                inputProps={isFocused?.[index]?.[id] ? { autoFocus: true } : {}}
              />
            )}
          </Grid>
        );
      })}

      {isInventoriable ? (
        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            //value={values[assetItemId][index]["serialNumber"] || ""}
            value={
              /* serialNumberOPtions.find(
                (sl) => sl === */ values[assetItemId][index]["serialNumber"] ||
              "" /* ,
              ) || "" */
            }
            //onBlur={handleBlur}
            //inputValue=''
            clearOnBlur
            closeText="Close"
            onChange={(event, newValue) => {
              if (typeof newValue === "string") {
                checkSerialNumberF(newValue, assetItemId, index);
              } else if (newValue && newValue.inputValue) {
                checkSerialNumberF(newValue.inputValue, assetItemId, index);
              } else {
                checkSerialNumberF(newValue, assetItemId, index);
              }
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;

              const isExisting = options.some(
                (option) => inputValue === option
              );
              if (inputValue !== "" && !isExisting) {
                filtered.push(inputValue);
              }

              return filtered;
            }}
            size="small"
            selectOnFocus
            disabled={loading}
            fullWidth
            handleHomeEndKeys
            options={serialNumberOPtions}
            getOptionLabel={(option) => {
              // Value selected with enter, right from the input
              if (typeof option === "string") {
                return option;
              }
              // Add "xxx" option created dynamically
              if (option.inputValue) {
                return option.inputValue;
              }
              // Regular option
              return option;
            }}
            renderOption={(props, option) => <li {...props}>{option}</li>}
            error={Boolean(true)}
            renderInput={(params) => (
              <TextField
                error={
                  Boolean(
                    serialNumberError?.[assetItemId]?.[index] &&
                      assetErrors?.[assetItemId]?.[index]?.["serialNumber"]
                  ) ||
                  Boolean(
                    touchedAssets?.[assetItemId]?.[index]?.["serialNumber"] &&
                      assetErrors?.[assetItemId]?.[index]?.["serialNumber"]
                  )
                }
                helperText={
                  (serialNumberError?.[assetItemId]?.[index] &&
                    assetErrors?.[assetItemId]?.[index]?.["serialNumber"]) ||
                  (touchedAssets?.[assetItemId]?.[index]?.["serialNumber"] &&
                    assetErrors?.[assetItemId]?.[index]?.["serialNumber"])
                }
                {...params}
                label="Serial Number"
              />
            )}
          />
        </Grid>
      ) : (
        ""
      )}
    </>
  );

};

const MemoizedItemAttributeValueFields = memo(ItemAttributeValueFields);
// const MemoizedItemAttributeValueFields = memo(ItemAttributeValueFields, checkIsEqualItem);
// function checkIsEqualItem(prevState, nextState) {
//   if (!isEqual(prevState.values, nextState.values)) {
//     return false;
//   }

//   if (!isEqual(prevState.touchedAssets, nextState.touchedAssets)) {
//     return false;
//   }

//   if (
//     prevState.assetErrors &&
//     nextState.assetErrors &&
//     !isEqual(prevState.assetErrors, nextState.assetErrors)
//   ) {
//     return false;
//   }

//   return true;
// }





function checkIsEqual(prevState, nextState) {
  if (!isEqual(prevState.values, nextState.values)) {
    return false;
  }

  if (!isEqual(prevState.touchedAssets, nextState.touchedAssets)) {
    return false;
  }



  
  if (
    prevState.assetErrors &&
    nextState.assetErrors &&
    !isEqual(prevState.assetErrors, nextState.assetErrors)
  ) {
    return false;
  }


//New Add Condition  
  if (
    prevState.itemAttribute &&
    nextState.itemAttribute &&
    !isEqual(prevState.itemAttribute, nextState.itemAttribute)
  ) {
    return false;
  }
  if (
    prevState.isFocused &&
    nextState.isFocused &&
    !isEqual(prevState.isFocused, nextState.isFocused)
  ) {
    return false;
  }

  if (
    prevState.title &&
    nextState.title &&
    !isEqual(prevState.title, nextState.title)
  ) {
    return false;
  }

  if (
    prevState.isInventoriable &&
    nextState.isInventoriable &&
    !isEqual(prevState.isInventoriable, nextState.isInventoriable)
  ) {
    return false;
  }
  if (
    prevState.assetItemId &&
    nextState.assetItemId &&
    !isEqual(prevState.assetItemId, nextState.assetItemId)
  ) {
    return false;
  }

  return true;
}
const MemoizedHasOneComponent = memo(HasOneComponent, checkIsEqual);

export default MemoizedHasOneComponent;
// export default memo(HasOneComponent);
