import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import Scrollbar from "../../components/Scrollbar";
import CustomPagination from "../../components/Pagination";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import TableSortLabel from "@mui/material/TableSortLabel";
import invertDirection from "../../utils/invertDirection";
import Autocomplete from "@mui/material/Autocomplete";
import { encryption } from "../../utils/encodeString";

import {
  setLimit,
  setOrderBy,
  setSortBy,
  setPage,
} from "../../redux/onboardingCandidate/onboardedCandidateSlice";
import { getViewVisible } from "../../utils/userPermission";
import LocalStorage from "../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../redux/userPermission/userPermissionthunk";
import {
  getOnboardingCandidate,
  getTotalOnboardingCandidateCount,
} from "../../redux/onboardingCandidate/candidateThunk";
function OnboardedCandidates() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [isSearching, setIsSearching] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const data = useSelector((state) => state.onboardingCandidate.data);

  const loading = useSelector((state) => state.onboardingCandidate.loading);
  const AuthRole = useSelector((state) => state.auth.role);
  const limit = useSelector((state) => state.onboardingCandidate.limit);
  const page = useSelector((state) => state.onboardingCandidate.page);
  const totalPage = useSelector((state) => state.onboardingCandidate.totalPage);
  const onboardingProcessStatus = useSelector(
    (state) => state.onboardingCandidate.onboardingProcessStatus
  );
  const error = useSelector((state) => state.onboardingCandidate.error);
  const totalCount = useSelector(
    (state) => state.onboardingCandidate.totalCount
  );
  const sortBy = useSelector((state) => state.onboardingCandidate.sortBy);
  const orderBy = useSelector((state) => state.onboardingCandidate.orderBy);
  const totalCandidateCount = useSelector(
    (state) => state.onboardingCandidateCount.totalCounts
  );

  const [filterData, setFilterData] = useState({});
  const [filter, setFilter] = useState(false);
  const [filterChips, setFilterChips] = useState([]);
  const company = useSelector((state) => state.company);

  let params = location.state;
  const [queryFilter, setQueryFilter] = useState({onboardingProcessStatus: ["PENDING", "SUBMIT", "RESUBMIT"]});
  const inputRef = React.useRef(null);
  const onboardingProcessStatusData = ["PENDING", "SUBMIT", "RESUBMIT", "VERIFIED"]

  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("onboardedCandidates")) {
      navigate("/dashboard");
    }
  }, []);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    setFieldValue,
    getFieldProps,
  } = useFormik({
    initialValues: {
      search: "",
      onboardingProcessStatus: ["PENDING", "SUBMIT", "RESUBMIT"],
    },
    onSubmit: async (values) => {
      let temp
      if(values.onboardingProcessStatus.length > 0)
      {
        temp = {...values}
      }
      else{
        temp = {"search": values.search}
      }
      let queryParams = { ...temp };
      try {
        if ((values.search === "" && values.onboardingProcessStatus === "") || values.onboardingProcessStatus == [] ) {
          setFilter(false);
        } else {
          setFilter(true);
        }
        setFilterData({ ...temp });
        dispatch(
          getOnboardingCandidate({
            ...queryParams,
            limit,
            page: 1,
            orderBy,
            sortBy,
          })
        );
        setQueryFilter(queryParams);
        dispatch(setPage({ page: 0 }));
        // if(values?.onboardingProcessStatus.length > 0){
        //   prepareChips({...values});
        // }
        // else{
        //   prepareChips(values.search);
        //   dispatch(
        //     getOnboardingCandidate({
        //       onboardingProcessStatus:["PENDING","RESUBMIT","VERIFIED","SUBMIT"],
        //       limit,
        //       page: 1,
        //       orderBy,
        //       sortBy,
        //     })
        //   );
        // }
        prepareChips({...values});

        setState({ ...state, right: false });
      } catch (error) {
        toast.error(error?.response?.data?.message);
      }
    },
  });

  useEffect(() => {
    return () => {
      resetForm();
      dispatch(getOnboardingCandidate({ limit, orderBy, sortBy, page: page + 1 }));
      setState({ ...state, right: false });
      setFilterChips([]);
      setQueryFilter({});
    };
  }, []);

  useEffect(() => {
      if(searchValue != "" && queryFilter?.onboardingProcessStatus?.length != 0){
         dispatch(
           getOnboardingCandidate({
             limit,
             page: page + 1,
             orderBy,
             sortBy,
             search: searchValue,
             ...queryFilter,
           })
         );
      prepareChips({ ...values });

      }
      else if(searchValue == "" && queryFilter?.onboardingProcessStatus?.length != 0){
         dispatch(
           getOnboardingCandidate({
             limit,
             page: page + 1,
             orderBy,
             sortBy,
             search: searchValue,
             ...queryFilter,
           })
         );
      prepareChips({ ...values });
      }
      else if(searchValue != "" && queryFilter?.onboardingProcessStatus?.length == 0){
         dispatch(
           getOnboardingCandidate({
             limit,
             page: page + 1,
             orderBy,
             sortBy,
             search: searchValue, 
           })
         );
      prepareChips({ ...values });
      }
      else if (searchValue == "" && queryFilter?.onboardingProcessStatus?.length == 0) {
        dispatch(
          getOnboardingCandidate({
            limit,
            page: page + 1,
            orderBy,
            sortBy,
          })
        );
      prepareChips({...values});
        // }
      }
    }, [dispatch, limit, page, sortBy, orderBy, params]);


  useEffect(() => {
    if (!error) {
      dispatch(getTotalOnboardingCandidateCount({}));
    }
  }, [error]);

  // useEffect(() => {
  //   // if (!error) {
  //     dispatch(getOnboardingCandidate({ limit, orderBy, sortBy, page: page + 1, ...queryFilter,search: searchValue,}));
  //   // }
  //   prepareChips();
  // }, [dispatch, limit, page, sortBy, orderBy, params]);

  // sidebar-part functionality
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleRowsPerPageChange = (event) => {
    dispatch(setPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setPage({ page: newPage }));
  };

  const handleReset = async () => {
    resetForm();
    setQueryFilter({
      onboardingProcessStatus: ["PENDING", "SUBMIT", "RESUBMIT"],
    });
    setSearchValue("")
    inputRef.current.value = "";
    await setFieldValue('search','')
    setState({ ...state, right: false });
    setFilter(false);
    setFilterData({});
    setFilterChips([]);
    prepareChips({
    "search": "",
    "onboardingProcessStatus": [
        "PENDING",
        "SUBMIT",
        "RESUBMIT"
      ]
    });
    await dispatch(
      getOnboardingCandidate({
        limit,
        page: page + 1,
        orderBy,
        sortBy,
        search: "",
        onboardingProcessStatus: ["PENDING", "SUBMIT", "RESUBMIT"],
      })
    );
    await dispatch(setPage({ page: 0 }));
  };

  const prepareChips = (value) => {
    let filters = value;
    let tempChips = [];
    for (const key in filters) {
      if (filters[key]?.length > 0 && key === "onboardingProcessStatus") {
        let filterKey = filters[key].map((candidate) => candidate?.id);
        const res = onboardingProcessStatusData?.filter((status) =>
        filters[key].includes(status)
        );
        // if (res && res.length > 0)
        //   filters[key] = `Status : ${res.map((s) => s).join(",")}`;
        if (res[0]) filters[key] = `Status:${res.join(",")}`;
      }

      if (filters[key]?.length > 0 && filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    await setFieldValue("search", e?.target?.value || "");
    await handleSubmit();
    queryFilter.search = e?.target?.value;
    await dispatch(
      getOnboardingCandidate({
        limit,
        page: 1,
        orderBy,
        sortBy,
        search: e?.target?.value,
        ...queryFilter,
      })
    );
    await dispatch(setPage({ page: 0 }));

  };

  const onDeleteChip = async (value, length) => {
    setFilterChips([
      ...filterChips?.filter((val) => val?.filter !== value?.filter),
    ]);
    setFieldValue(value.type, "");
    let filters = { ...filterData, [value["type"]]: "" };
    setFilterData(filters);
    if (length == 1) {
      await dispatch(
        getOnboardingCandidate({ orderBy, sortBy, limit, page: page + 1 })
      );
      await dispatch(setPage({ page: 0 }));
    } else {
      await dispatch(
        getOnboardingCandidate({
          ...filters,
          orderBy,
          sortBy,
          limit,
          page: page + 1,
        })
      );
      await dispatch(setPage({ page: 0 }));
    }
    if (value.type == "search") {
      await setSearchValue("");
      inputRef.current.value = "";
      await setQueryFilter({...queryFilter,search: "" });
      await dispatch(setPage({ page: 0 }));
    }
    if (value?.type == "onboardingProcessStatus") {
     await setQueryFilter({...queryFilter,onboardingProcessStatus: [] });
     queryFilter.onboardingProcessStatus = []
      dispatch(
        getOnboardingCandidate({
          limit,
          page: page + 1,
          orderBy,
          sortBy,
          search:searchValue
        })
      );
    }
  };

  const debounceWithSearch = debounce(handleSearch, 500);

  const handleFilters = async (field,newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
    setIsSearching(true);
  }
  
  return (
    <Container className="Onboarded-Candidate-container" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="title-button-wrapper Onboarded-Candidate-title">
          <Typography variant="h4" gutterBottom>
            Onboarded Candidates ({totalCandidateCount})
          </Typography>
          </div>
      </Stack>

      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div className="employee-classification-right" style={{paddingTop:"20px"}}>
                <TextField
                  className="employee-search-detail"
                  fullWidth
                  label="Search by Name/Email/Phone Number"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    multiple
                    size="small"
                    fullWidth
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    options={onboardingProcessStatusData || []} 

                    name="onboardingProcessStatus"
                    onChange={(event, newValue) => {
                      let tempArray = newValue.map((x) => x);
                      handleFilters("onboardingProcessStatus",  tempArray)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Status" />
                    )}
                    value={onboardingProcessStatusData.filter((x) =>
                      values.onboardingProcessStatus.includes(x)
                    )}
                  />
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
      className="search-filter"
        direction="row"
        alignItems="start"
      >
        {filterChips?.map((chips, idx) => {
          return (
            <Chip
              className="search-filter-detail"
              label={chips?.filter}
              color="primary"
              size="small"
              key={chips?.filter}
              style={{ margin: "10px 5px 10px 0" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips?.length)}
            />
          );
        })}
      </Stack>

      {filterChips?.length !== 0 || isSearching ? (
        <Typography className="record-found-text" variant="body2" sx={{ mb: 1 }}>
          {totalCount} records found
        </Typography>
      ) : (
        ""
      )}
      {loading ? (
        <Loader />
      ) : (
        <Card className="onboarded-candidates-main-card">
          <Scrollbar>
            <TableContainer
              component={Paper}
              sx={{ minWidth: 800 }}
              className="radius-remove"
              style={{ borderRadius: "0" }}
            >
              <Table className="onboarded-candidate-table grid-table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortBy === "firstName"}
                        direction={sortBy === "firstName" ? orderBy : "asc"}
                        onClick={() => handleSorting("firstName")}
                      >
                        Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="left">Contact</TableCell>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">
                      Onboarding Process Manager Name
                    </TableCell>
                    <TableCell align="left">
                      <TableSortLabel
                        active={sortBy === "onboardingProcessStatus"}
                        direction={
                          sortBy === "onboardingProcessStatus" ? orderBy : "asc"
                        }
                        onClick={() => handleSorting("onboardingProcessStatus")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center" className="th-action-buttons">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length > 0 ? (
                    data.map((candidate, idx) => {
                      return (
                        <TableRow key={candidate?.id}>
                          <TableCell align="center">
                            {candidate?.firstName} {candidate?.lastName}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.contactNumber || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.email || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.onboardingProcessManagerName || "-"}
                          </TableCell>
                          <TableCell align="left">
                            {candidate?.onboardingProcessStatus || "-"}
                          </TableCell>
                          <TableCell className="table-td-action" align="center">
                            {candidate?.onboardingProcessStatus
                              .toLowerCase()
                              .replace(/\s+/g, "") !== "pending" && (
                              <Stack className="td-action-buttons" direction="row">
                                <IconButton
                                  
                                  // component={RouterLink}
                                  // to={`/onboardedCandidates/view/${encryption(
                                  //   candidate.id
                                  // )}`}
                                  onClick={(e) => window.open(`/onboardedCandidates/view/${encryption(
                                    candidate.id
                                  )}`,"_blank") }
                                >
                                  {/* <VisibilityIcon /> */}
                                  <img src="assets/images/vieweye-icon.svg" title="View" />
                                </IconButton>
                              </Stack>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell className="No-Record-text" colSpan={12} align="left">
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <CustomPagination
            totalPage={totalPage}
            totalCount={totalCount}
            limit={limit}
            handleChangePage={handleChangePage}
            page={page}
            rowsPerPageOptions={[10, 20, 40]}
            handleRowsPerPageChange={handleRowsPerPageChange}
            filter={filterChips?.length !== 0 ? true : false}
          />
        </Card>
      )}
    </Container>
  );
}

const componentConfig = {
  component: OnboardedCandidates,
  path: "/onboardedCandidates",
  public: false,
  layout: DashboardLayout,
  group: "candidate",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
