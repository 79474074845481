import React, { useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import AttributeValues from "./AttributeValues";

import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { styled } from "@mui/material/styles";

import { encryption } from "../../../utils/encodeString";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

function ItemRow(props) {
  const { row, handleDelete } = props;
  const [open, setOpen] = React.useState(false);
  const [expandedSub, setExpandedSub] = useState("panelSub1");

  const handleSubAccordionChange = (panel) => (event, newSubExpanded) => {
    setExpandedSub(newSubExpanded ? panel : false);
  };

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell scope="row">{row.name}</TableCell>
        <TableCell>{row.isActive ? "Yes" : "No"}</TableCell>
        {getEditVisible("items") && (
          <TableCell align="right">
            <div className="action-button">
              {getEditVisible("items") && (
                <span style={{ marginRight: 8 }}>
                  <IconButton
                    color="primary"
                    component={RouterLink}
                    to={`/items/add/${encryption(row.id)}`}
                  >
                    <EditIcon />
                  </IconButton>
                </span>
              )}

              {/* <span>
							<IconButton onClick={() => handleDelete(row.id, row.name)}>
								<DeleteIcon color="error" />
							</IconButton>
						</span> */}
            </div>
          </TableCell>
        )}
      </TableRow>
      <TableRow sx={{ margin: 0, padding: 0 }}>
        <TableCell
          colSpan={4}
          sx={{ paddingBottom: "0px !important", paddingTop: "0px !important" }}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
         
            <Box my={1}>
              {row.attributes?.map((d, index) => {
                return (
                  <Accordion
                    key={d.id}
                    expanded={expandedSub === d.name}
                    onChange={handleSubAccordionChange(d.name)}
                  >
                    <AccordionSummary
                      aria-controls="panelSub1d-content"
                      id="panelSub1d-header"
                    >
                      <Typography>{d.name}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <AttributeValues
                        attributeId={d.id}
                        attributeValues= {d.attributeValues}
                        attributeType = {d.dataType}
                        row = {row}
                      />
                     
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
export default ItemRow;
