import { LoadingButton } from "@mui/lab";
import { Card, Container, Grid, TextField,TextArea, Typography, Button, InputLabel, Select, MenuItem, FormControl, Autocomplete, FormHelperText, Stack } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardLayout from '../../layouts/dashboard';
import { getAllEmailTemplates } from "../../redux/emailTemplate/emailTemplateThunk";

import * as Yup from "yup";

function EmailTemplates(){

  const emailTemplateSchema = Yup.object().shape({
    template:Yup.string().required("Field is required"),
    templateName:Yup.string().required("Field is required"),
    templateSubject:Yup.string().required("Field is required"),
    templateContent:Yup.string().required("Field is required"),
    templateStyles:Yup.string().required("Field is required"),
  })

  const formik = useFormik({
		initialValues: {
			template:'',
      templateName:'',
      templateSubject:'',
      templateContent:'',
      templateStyles:''
		},
    validationSchema:emailTemplateSchema
  });
    const {status,loading ,data} = useSelector((state)=>state.emailTemplate);
    const dispatch = useDispatch();

    useEffect(()=>{
       dispatch(getAllEmailTemplates());
      //  console.log(data);
    },[])

    const {
		values,
		setFieldValue,
		getFieldProps,
		isSubmitting,
		setValues,
		errors,
		touched,
		submitCount,
		setErrors,
		handleBlur,
		handleChange,
	} = formik;
  // console.log(errors);
  // console.log(touched);
    const handleLoadTemplate= ()=>{
      // console.log(values);
    }

   const templates ={
    name:['val1','val2'],
    id:[1,2]
   }
    return (
       
      <>
      <Container maxWidth="xl">
      <form onSubmit={formik.handleSubmit}>
      <Stack direction="row" justifyContent="end"  alignItems="center">
        <LoadingButton
							loading={isSubmitting}
							type="submit"
							variant="contained"
						>
							Save Template
						</LoadingButton>
      </Stack>
       <Typography variant="h4" gutterBottom>
            Load default template
					</Typography>
       
        <Card style={{ marginBottom: "15px" }}>
        
        <Grid container pt={3} pb={3} pr={5} pl={5} spacing={3} sx={{ borderTop: "1px solid #dce0e4" }}>
                        
        <Grid item xs={12}>

            <FormControl
              fullWidth
              error={Boolean(touched?.template && errors?.template)}
            >
              <Autocomplete
                
                fullWidth
                 name="template"
                
                options={templates.name || []}
                 key={templates.id || []}
                // getOptionLabel={(option) => option.title || ""}
                 isOptionEqualToValue={(option, value) => option === value}
               
                 onChange={(event, newValue) => {
                            setFieldValue(
                                `template`,
                                newValue
                            );
                            }} 
                renderInput={(params) => (
                  <TextField {...params} label="Template" />
                )}
                //disabled={designationDisable}
              />

               <FormHelperText style={{ margin: "5px 0 0 0" }}>
                {touched?.tempalte && errors?.tempalte}
              </FormHelperText> 
            </FormControl>
             
          </Grid>
              <Grid item xs={4}>
                  <Button onClick={handleLoadTemplate} variant="contained">Load Template</Button>
              </Grid>
           </Grid>
           
           </Card>                                                    
		 
        <Typography variant="h4" gutterBottom>
            Template Information
					</Typography>
                

                <Card style={{ marginBottom: "15px" }}>
                  <Grid container pt={3} pb={3} pr={5} pl={5} spacing={3} sx={{ borderTop: "1px solid #dce0e4" }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Template Name"
                                variant="outlined"
                                 {...getFieldProps(`templateName`)}
                                error={Boolean(
                                    touched?.templateName &&
                                        errors?.templateName
                                )}
                                helperText={
                                    touched?.templateName &&
                                    errors?.templateName
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Template Subject"
                                variant="outlined"
                                
                              {...getFieldProps(`templateSubject`)}
                                error={Boolean(
                                    touched?.templateSubject &&
                                        errors?.templateSubject
                                )}
                                helperText={
                                    touched?.templateSubject &&
                                    errors?.templateSubject
                                }
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="contained">Insert Variable</Button>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Template Content"
                                variant="outlined"
                                multiline={true}
                                minRows={5}
                                {...getFieldProps(`templateContent`)}
                                error={Boolean(
                                    touched?.templateContent &&
                                        errors?.templateContent
                                )}
                                helperText={
                                    touched?.templateContent &&
                                        errors?.templateContent
                                }
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Template Styles"
                                variant="outlined"
                                multiline={true}
                                minRows={5}
                                {...getFieldProps(`templateStyles`)}
                                error={Boolean(
                                    touched?.templateStyles &&
                                        errors?.templateStyles
                                )}
                                helperText={
                                    touched?.templateStyles &&
                                    errors?.templateStyles
                                }
                            />
                        </Grid>
                        </Grid>
       </Card>
       </form>
       </Container>
       </>
     )
}
const componentConfig = {
	component: EmailTemplates,
	path: '/email-template',
	public: false,
	group: 'cms',
	layout: DashboardLayout,
	sidebar: true,
	role: ['admin'],
};

export default componentConfig;