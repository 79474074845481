import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Scrollbar from "../../components/Scrollbar";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { encryption } from "../../utils/encodeString";
import DeleteIcon from "@mui/icons-material/Delete";
import TableSortLabel from "@mui/material/TableSortLabel";
import CustomPagination from "../../components/Pagination";
import invertDirection from "../../utils/invertDirection";
import Switch from "@mui/material/Switch";
import {
  setLimit,
  setCurrentPage,
  setOrderBy,
  setSortBy,
} from "../../redux/emailTemplateDynamic/emailTemplateDynamicSlice";
import {
  getEmailTemplatesDynamic,
  deleteEmailTemplateDynamicById,
} from "../../redux/emailTemplateDynamic/emailTemplateDynamicThunk";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

function EmailTemplateDynamic() {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const navigate = useNavigate();
  const {
    data,
    currentPage,
    status,
    limit,
    totalRecords,
    sortBy,
    orderBy,
    loading,
    error,
    isSearchQuery,
    totalPages,
  } = useSelector((state) => state.emailTemplateDynamic);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("emailTemplate")) {
      dispatch(
        getEmailTemplatesDynamic({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
      navigate("/dashboard");
    }
  }, []);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  

  // useEffect(() => {
  //   dispatch(
  //     getEmailTemplatesDynamic({
  //       page: currentPage + 1,
  //       limit: limit,
  //       sortBy: sortBy,
  //       orderBy: orderBy,
  //       search: "",
  //     })
  //   );
  // }, [dispatch]);

  useEffect(() => {
    if (status === "idle") {
      dispatch(
        getEmailTemplatesDynamic({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
    }
  }, [dispatch, limit, status, currentPage, sortBy, orderBy]);

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleDelete = async (id) => {
    try {
      await confirm({
        description: `Are you sure you want to delete?`,
      });
      await dispatch(deleteEmailTemplateDynamicById(id)).unwrap();
      dispatch(
        getEmailTemplatesDynamic({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
          search: "",
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  return (
    <Container maxWidth="xl" className="item-component">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={3}
      >
        <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Email Templates
          <Typography variant="body2">
            Total Records : {totalRecords ? totalRecords : "0"}
          </Typography>
        </Typography>

        {getAddVisible("emailTemplate") && (
          <Button
            variant="contained"
            component={RouterLink}
            to="/emailTemplateDynamic/add"
            startIcon={<AddIcon />}
          >
            Add Email Template
          </Button>
        )}
         </div>
      </Stack>

      <Card className="custom-grid">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <CustomPagination
              totalPage={totalPages}
              totalCount={totalRecords}
              limit={limit}
              handleChangePage={handleChangePage}
              page={currentPage}
              rowsPerPageOptions={[10, 20, 40]}
              handleRowsPerPageChange={handleChangeRowsPerPage}
              filter={isSearchQuery ? true : false}
            />
            <Scrollbar>
              <TableContainer
                component={Paper}
                sx={{ minWidth: 800 }}
                className="radius-remove"
                style={{ borderRadius: "0" }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "title"}
                          direction={sortBy === "title" ? orderBy : "asc"}
                          onClick={() => handleSorting("title")}
                        >
                          Template Title
                        </TableSortLabel>
                      </TableCell>
                      <TableCell align="left">
                        <TableSortLabel
                          active={sortBy === "subject"}
                          direction={sortBy === "subject" ? orderBy : "asc"}
                          onClick={() => handleSorting("subject")}
                        >
                          Email Subject
                        </TableSortLabel>
                      </TableCell>
                      {/* <TableCell align="left">Module</TableCell>
                      <TableCell align="left">Status</TableCell>
                      <TableCell align="left">State</TableCell> */}
                      <TableCell align="left">Enabled</TableCell>
                      <TableCell align="center" style={{ width: "100px" }}>
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  {loading ? (
                    <TableCell align="center" width="100%" colSpan={7}>
                      <Loader />
                    </TableCell>
                  ) : (
                    <TableBody>
                      {data?.length > 0 ? (
                        data?.map((email, idx) => {
                          return (
                            <TableRow key={email.id}>
                              <TableCell align="center">
                                {email.title || "-"}
                              </TableCell>
                              <TableCell align="left">
                                {email.subject || "-"}
                              </TableCell>
                              {/* <TableCell align="left">
                                {email.module ? email.module.moduleName : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {email?.status ? email.status.name : "-"}
                              </TableCell>
                              <TableCell align="left">
                                {email?.state ? email.state.name : "-"}
                              </TableCell> */}
                              <TableCell align="center">
                                <Switch
                                  checked={email.enable}
                                  disabled
                                  // onChange={() => handleToggle(department)}
                                  inputProps={{ "aria-label": "controlled" }}
                                  size="small"
                                />
                              </TableCell>
                              <TableCell align="left">
                                <Stack direction="row">
                                  {getEditVisible("emailTemplate") && (
                                    <IconButton
                                      style={{ padding: "5px " }}
                                      component={RouterLink}
                                      to={`/emailTemplateDynamic/edit/${encryption(
                                        email.id
                                      )}`}
                                    >
                                      <EditIcon color="primary" />
                                    </IconButton>
                                  )}
                                  {/* <IconButton
									style={{ padding: "5px" }}
									component={RouterLink}
									to={`/vm/view/${encryption(email.id)}`}
								>
									<VisibilityIcon />
								</IconButton> */}
                                  {getDeleteVisible("emailTemplate") && (
                                    <span>
                                      <IconButton
                                        onClick={() => handleDelete(email.id)}
                                      >
                                        <DeleteIcon color="error" />
                                      </IconButton>
                                    </span>
                                  )}
                                </Stack>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <TableRow>
                          <TableCell className="No-Record-text" colSpan={12} align="left">
                            No Record(s) Found
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
            </Scrollbar>
          </Grid>
        </Grid>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: EmailTemplateDynamic,
  path: "/emailTemplateDynamic",
  public: false,
  layout: DashboardLayout,
  group: "users",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;

// import React, { useEffect } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { useConfirm } from "material-ui-confirm";
// import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";

// import DashboardLayout from "../../layouts/dashboard";
// import Loader from "../../components/Loader";
// import { useFormik } from "formik";

// import Card from "@mui/material/Card";
// import Grid from "@mui/material/Grid";
// import Table from "@mui/material/Table";
// import TableRow from "@mui/material/TableRow";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import Container from "@mui/material/Container";
// import Typography from "@mui/material/Typography";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import Paper from "@mui/material/Paper";
// import Stack from "@mui/material/Stack";
// import Button from "@mui/material/Button";
// import AddIcon from "@mui/icons-material/Add";
// import IconButton from "@mui/material/IconButton";
// import EditIcon from "@mui/icons-material/Edit";
// import Scrollbar from "../../components/Scrollbar";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import { encryption } from "../../utils/encodeString";
// import DeleteIcon from "@mui/icons-material/Delete";
// import TableSortLabel from "@mui/material/TableSortLabel";
// import CustomPagination from "../../components/Pagination";
// import invertDirection from "../../utils/invertDirection";
// import Switch from "@mui/material/Switch";
// import { setLimit, setCurrentPage, setOrderBy, setSortBy } from "../../redux/assetVM/assetVMSlice";
// import { getEmailTemplatesDynamic, deleteEmailTemplateDynamicById} from "../../redux/emailTemplateDynamic/emailTemplateDynamicThunk";
// import FormControl from "@mui/material/FormControl";
// import FormHelperText from "@mui/material/FormHelperText";
// import Autocomplete from "@mui/material/Autocomplete";
// import Modal from "@mui/material/Modal";
// import parse from "html-react-parser";

// import { getInterview } from "../../redux/interview/interviewThunk";
// import {
// 	getCandidateByStatus,
// 	getCandidateStatus,
// } from "../../redux/candidateState/candidateStatethunk";
// import { getStudentStatus } from "../../redux/student/studentThunk";
// import {
// 	getAllRoles,
// 	getAllModule,
// 	getUserPermissionById,
// 	updatePermission,
// } from "../../redux/userPermission/userPermissionthunk";

// import {
// 	getViewVisible,
// 	getAddVisible,
// 	getEditVisible,
// 	getDeleteVisible,
// } from "../../utils/userPermission";

// function EmailTemplateDynamic() {
// const style = {
// 	position: "absolute",
// 	top: "50%",
// 	left: "50%",
// 	transform: "translate(-50%, -50%)",
// 	width: 800,
// 	bgcolor: "background.paper",
// 	border: "none",
// 	boxShadow: 24,
// 	gap: 2,
// 	p: 4,
// };

// const EmailTemplateDynamic = () => {
// 	const { state } = useLocation();
// 	const dispatch = useDispatch();
// 	const confirm = useConfirm();

// 	const navigate = useNavigate();

// 	if (!getViewVisible("emailTemplate")) {
// 		navigate("/dashboard");
// 	}

// 	const [selectedId, setSelectedId] = useState("");
// 	const [modules, setModules] = useState("");
// 	const [open, setOpen] = React.useState(false);
// 	const handleOpen = () => setOpen(true);
// 	const handleClose = () => setOpen(false);
// 	const [candidateState, setCandidateState] = useState([]);
// 	const moduleData = ["Interview", "Employee", "Candidate", "Intern"];
// 	const candidateStatusData = useSelector(
// 		(state) => state.candidateState.statusData,
// 	);
// 	const candidateStateData = useSelector(
// 		(state) => state.candidateState.stateData,
// 	);
// 	const interviewData = useSelector((state) => state.interview);
// 	const internStatusData = useSelector((state) => state.student);
// 	const data = useSelector((state) => state.userPermission.data);
// 	useEffect(() => {
// 		if (
// 			!interviewData.complete &&
// 			!interviewData.loading &&
// 			!interviewData.error &&
// 			(!interviewData.data ||
// 				(Array.isArray(interviewData.data) && interviewData.data.length <= 0))
// 		)
// 			dispatch(getInterview());
// 	});
// 	useEffect(() => {
// 		if (Array.isArray(candidateStatusData) && candidateStatusData.length <= 0)
// 			dispatch(getCandidateStatus());
// 	}, []);

// 	const formik = useFormik({
// 		initialValues: {
// 			title: "",
// 			subject: "",
// 			body: "",
// 			moduleId: "",
// 			statusId: "",
// 			enable: "",
// 			stateId: "",
// 		},
// 		// validationSchema: vmMasterSchema,
// 		onSubmit: async (values, { resetForm }) => {
// 			// try {
// 			// 	values.users = values.users.map((user) => {
// 			// 		return {userId: user}
// 			// 	})
// 			// 	values.accessDetails = values.accessDetails.map((access) => {
// 			// 			return {...access, password: encryption(access.password)}
// 			// 		})
// 			// 		values.databaseDetails = values.databaseDetails.map((db) => {
// 			// 			return {...db, password: encryption(db.password)}
// 			// 		})
// 			// 		values.webMinPassword = encryption(values.webMinPassword)
// 			// 	if (id) {
// 			// 		await dispatch(
// 			// 			updateAssetVMById({ ...values, id: id }),
// 			// 		).unwrap();
// 			// 	}
// 			// 	if (!id) {
// 			// 		await dispatch(insertAssetVM(values)).unwrap();
// 			// 	}
// 			// 	navigate("/vm");
// 			// 	resetForm();
// 			// } catch (error) {
// 			// 	toast.error(error.message);
// 			// }
// 		},
// 	});

// 	const {
// 		data,
// 		currentPage,
// 		status,
// 		limit,
// 		totalRecords,
// 		sortBy,
// 		orderBy,
// 		loading,
// 		error,
// 		isSearchQuery,
// 		totalPages
// 	} = useSelector((state) => state.emailTemplateDynamic);
// 	const {
// 		handleSubmit,
// 		values,
// 		setFieldValue,
// 		getFieldProps,
// 		isSubmitting,
// 		handleChange,
// 		setValues,
// 		touched,
// 		errors,
// 		handleBlur,
// 	} = formik;

// 	useEffect(() => {
// 		dispatch(
// 			getEmailTemplatesDynamic({
// 				page: currentPage + 1,
// 				limit: limit,
// 				sortBy: sortBy,
// 				orderBy: orderBy,
// 				search: ""
// 			}),
// 		);
// },[dispatch])

// useEffect(() => {
// 	if (status === "idle") {
// 		dispatch(
// 			getEmailTemplatesDynamic({
// 				page: currentPage + 1,
// 				limit: limit,
// 				sortBy,
// 				orderBy,
// 				search: ""
// 			}),
// 		);
// 	}
// }, [dispatch, limit, status, currentPage, sortBy, orderBy]);

// 	const handleChangePage = (event, newPage) => {
// 		dispatch(setCurrentPage({ page: newPage }));
// 	};

// 	const handleChangeRowsPerPage = (event) => {
// 		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
// 	};
// 	useEffect(() => {
// 		// setIsLoading(true);
// 		if (values.statusId ?? false) {
// 			dispatch(getCandidateByStatus(values.statusId))
// 				.unwrap()
// 				.then((res) => {
// 					setCandidateState(res.data);
// 				})
// 				.catch((err) => {
// 					setCandidateState([]);
// 				});
// 		}
// 	}, [dispatch, values.statusId]);

// 	useEffect(() => {
// 		dispatch(getStudentStatus());
// 	}, [dispatch]);

// 	const handleDelete = async (id) => {
// 		try {
// 			await confirm({
// 				description: `Are you sure you want to delete?`,
// 			});
// 			await dispatch(deleteEmailTemplateDynamicById(id)).unwrap();
// 			dispatch(getEmailTemplatesDynamic({
// 				page: currentPage + 1,
// 				limit: limit,
// 				sortBy,
// 				orderBy,
// 				search: ""
// 			}));
// 		} catch (error) {
// 			toast.error(error?.message);
// 		}
// 	};
// 	const getAllModuleData = async () => {
// 		try {
// 			const res = await dispatch(getAllModule()).unwrap();

// 			setModules(res.data);
// 		} catch (error) {
// 			// toast.error(error.message);
// 		}
// 	};
// 	useEffect(() => {
// 		getAllModuleData();
// 	}, [dispatch]);

// 	const handleSorting = (columnName) => {
// 		dispatch(setSortBy({ sortBy: columnName }));
// 		dispatch(
// 			setOrderBy({
// 				orderBy: invertDirection(sortBy === columnName, orderBy),
// 			}),
// 		);
// 	};
// 	{
// 		console.log(modules);
// 	}

// 	return (
// 		<Container maxWidth="xl" className="item-component">
// 			<Stack
// 				direction="row"
// 				alignItems="center"
// 				justifyContent="space-between"
// 				mb={3}
// 			>
// 				<Typography variant="h4" gutterBottom>
// 					Email Template
// 					<Typography variant="body2">
// 						Total Records : {totalRecords ? totalRecords : "0"}
// 					</Typography>
// 				</Typography>

// 				{getAddVisible("emailTemplate") && (
// 					<Button
// 						variant="contained"
// 						component={RouterLink}
// 						to="/emailTemplateDynamic/add"
// 						startIcon={<AddIcon />}
// 					>
// 						Add Email Template
// 					</Button>
// 				)}
// 			</Stack>
// 			{loading ? (
// 				<Loader />
// 			) : (
// 			<Card className="custom-grid">
// 			<Grid container spacing={3}>
// 				<Grid item xs={12}>

// 					<CustomPagination
// 							totalPage={totalPages}
// 							totalCount={totalRecords}
// 							limit={limit}
// 							handleChangePage={handleChangePage}
// 							page={currentPage}
// 							rowsPerPageOptions={[10, 20, 40]}
// 							handleRowsPerPageChange={handleChangeRowsPerPage}
// 							filter={isSearchQuery ? true : false}
// 						/>
// 						<Scrollbar>
// 						<TableContainer
// 							component={Paper}
// 							sx={{ minWidth: 800 }}
// 							className="radius-remove"
// 							style={{ borderRadius: "0" }}
// 						>
// 							<Table>
// 								<TableHead>
// 									<TableRow>
// 										<TableCell align="left">
// 											<TableSortLabel
// 												active={sortBy === "title"}
// 												direction={sortBy === "title" ? orderBy : "asc"}
// 												onClick={() => handleSorting("title")}
// 											>
// 												Template Title
// 											</TableSortLabel>
// 										</TableCell>
// 										<TableCell align="left">
// 											<TableSortLabel
// 												active={sortBy === "subject"}
// 												direction={sortBy === "subject" ? orderBy : "asc"}
// 												onClick={() => handleSorting("subject")}
// 												>
// 													Email Subject
// 												</TableSortLabel>
// 											</TableCell>
// 										<TableCell align="left">Module</TableCell>
// 										<TableCell align="left">Enabled</TableCell>
// 										<TableCell align="center" style={{ width: "100px" }}>Action</TableCell>
// 									</TableRow>
// 								</TableHead>

// 								<TableBody>
// 									{data.length > 0 ? (
// 										data.map((email, idx) => {
// 											return (
// 												<TableRow key={email.id}>
// 													<TableCell align="center">
// 														{email.title || "-"}
// 													</TableCell>
// 													<TableCell align="left">
// 														{email.subject || "-"}
// 													</TableCell>
// 													<TableCell align="left">
// 														{email.module.moduleName || "-"}
// 													</TableCell>
// 													{/* <TableCell align="left">
// 														<FormGroup>
// 															<Switch
// 																value={email.enable}
// 															/>
// 														</FormGroup>
// 													</TableCell> */}
// 													<TableCell align="center">
// 														<Switch
// 															checked={email.enable}
// 															disabled
// 															// onChange={() => handleToggle(department)}
// 															inputProps={{ "aria-label": "controlled" }}
// 															size="small"
// 														/>
// 													</TableCell>
// 													<TableCell align="left">
// 														<Stack direction="row">
// 															{getEditVisible("emailTemplate") && (
// 																<IconButton
// 																	style={{ padding: "5px " }}
// 																	component={RouterLink}
// 																	to={`/emailTemplateDynamic/edit/${encryption(email.id)}`}
// 																>
// 																	<EditIcon color="primary" />
// 																</IconButton>
// 															)}
// 															{/* <IconButton
// 																style={{ padding: "5px" }}
// 																component={RouterLink}
// 																to={`/vm/view/${encryption(email.id)}`}
// 															>
// 																<VisibilityIcon />
// 															</IconButton> */}
// 															{getDeleteVisible("emailTemplate") && (
// 																<span>
// 																	<IconButton
// 																		onClick={() =>
// 																			handleDelete(
// 																				email.id,
// 																			)
// 																		}
// 																	>
// 																		<DeleteIcon color="error" />
// 																	</IconButton>
// 																</span>
// 															)}
// 														</Stack>
// 													</TableCell>
// 												</TableRow>
// 											);
// 										})
// 									) : (
// 										<TableRow>
// 											<TableCell colSpan={8} align="left">
// 												No Record(s) Found
// 											</TableCell>
// 										</TableRow>
// 									)}

// 								</TableBody>
// 							</Table>
// 						</TableContainer>
// 					</Scrollbar>
// 				</Grid>
// 			</Grid>
// 			</Card> )}
// 		</Container>
// 							<Grid
// 								container
// 								spacing={3}
// 								py={3}
// 								style={{ paddingBottom: "13px", paddingTop: "17px" }}
// 							>
// 								<Grid item xs={12}>
// 									<TextField
// 										label="Template Title"
// 										variant="outlined"
// 										size="small"
// 										// value={values.title}
// 										onChange={handleChange}
// 										autoComplete="off"
// 										inputProps={{ maxLength: 256 }}
// 										{...getFieldProps("title")}
// 										fullWidth
// 										error={Boolean(touched?.title && errors?.title)}
// 										helperText={touched?.title && errors?.title}
// 									/>
// 								</Grid>
// 								<Grid item xs={12}>
// 									<TextField
// 										label="Email Subject"
// 										variant="outlined"
// 										size="small"
// 										// value={values.subject}
// 										onChange={handleChange}
// 										autoComplete="off"
// 										inputProps={{ maxLength: 256 }}
// 										{...getFieldProps("subject")}
// 										fullWidth
// 										error={Boolean(touched?.subject && errors?.subject)}
// 										helperText={touched?.subject && errors?.subject}
// 									/>
// 								</Grid>
// 								<Grid item xs={4}>
// 									<Autocomplete
// 										labelId="demo-simple-select-helper-label"
// 										id="demo-simple-select-helper"
// 										size="small"
// 										name="module"
// 										onChange={(event, newValue) => {
// 											setFieldValue(`moduleId`, newValue.moduleName || "");
// 										}}
// 										// value={modules.find(
// 										// 	(m) =>
// 										// 		m.moduleName ==
// 										// 		values.moduleId
// 										// )}
// 										getOptionLabel={(option) => option.moduleName || ""}
// 										options={modules || []}
// 										renderInput={(params) => (
// 											<TextField
// 												{...params}
// 												label="Module"
// 												autoComplete="off"
// 												error={Boolean(touched?.moduleId && errors?.moduleId)}
// 												helperText={touched?.moduleId && errors?.moduleId}
// 											/>
// 										)}
// 									/>
// 								</Grid>

// 								{/* {modules?.map((module, idx) => {
// 									module.moduleName == "Interview" && module.id === values.moduleId && */}
// 								<Grid item xs={4}>
// 									<Autocomplete
// 										labelId="demo-simple-select-helper-label"
// 										id="demo-simple-select-helper"
// 										size="small"
// 										name="Interview Status"
// 										options={interviewData.data || []}
// 										getOptionLabel={(option) => option.title || ""}
// 										onChange={(event, newValue) => {
// 											setFieldValue(`statusId`, newValue?.id || "");
// 										}}
// 										value={interviewData.data.find(
// 											(interview) => interview.id == values.statusId,
// 										)}
// 										renderInput={(params) => (
// 											<TextField
// 												{...params}
// 												label="Status"
// 												autoComplete="off"
// 												error={Boolean(touched?.statusId && errors?.statusId)}
// 												helperText={touched?.statusId && errors?.statusId}
// 											/>
// 										)}
// 									/>
// 								</Grid>
// 								{/* //  : ""
// 								} )}  */}

// 								{/* { values.moduleId == "Interview" ?
// 									<Grid item xs={4}>
// 										<Autocomplete
// 											labelId="demo-simple-select-helper-label"
// 											id="demo-simple-select-helper"
// 											size="small"
// 											name="Interview Status"
// 											options={interviewData.data || []}
// 											getOptionLabel={(option) => option.title || ""}
//                       onChange={(event, newValue) => {
//                         setFieldValue(
//                           `statusId`,
//                           newValue?.id || ""
//                         );
// 											}}
// 											value={interviewData.data.find(
//                         (interview) =>
//                           interview.id ==
//                           values.statusId
//                       )}

// 											renderInput={(params) => (
// 												<TextField
// 													{...params}
// 													label="Status"
// 													autoComplete="off"
// 													error={Boolean(touched?.statusId && errors?.statusId)}
// 													helperText={touched?.statusId && errors?.statusId}
// 												/>
// 											)}
// 										/>
// 									</Grid>
// 								: ""
// 								} */}

// 								{values.moduleId == "Candidates" ? (
// 									<>
// 										<Grid item xs={4}>
// 											<FormControl
// 												size="small"
// 												sx={{ minWidth: 120 }}
// 												fullWidth
// 											>
// 												<Autocomplete
// 													size="small"
// 													fullWidth
// 													options={candidateStatusData || []}
// 													getOptionLabel={(option) => option.name || ""}
// 													{...getFieldProps("statusId")}
// 													onChange={(event, newValue) => {
// 														setFieldValue("statusId", newValue?.id || "");
// 														setFieldValue("state", null);
// 														setSelectedId(newValue?.id ?? "");
// 													}}
// 													renderInput={(params) => (
// 														<TextField
// 															{...params}
// 															error={Boolean(
// 																touched?.statusId && errors?.statusId,
// 															)}
// 															helperText={touched?.statusId && errors?.statusId}
// 															label="Status"
// 														/>
// 													)}
// 													value={candidateStatusData.find(
// 														(status) => status.id === values.statusId,
// 													)}
// 													error={true}
// 												/>
// 											</FormControl>
// 										</Grid>

// 										<Grid item xs={4}>
// 											<FormControl
// 												size="small"
// 												sx={{ minWidth: 120 }}
// 												fullWidth
// 											>
// 												<Autocomplete
// 													size="small"
// 													fullWidth
// 													options={candidateStateData || []}
// 													getOptionLabel={(option) => option.name || ""}
// 													{...getFieldProps("stateId")}
// 													onChange={(event, newValue) => {
// 														setFieldValue("stateId", newValue?.id || "");
// 													}}
// 													renderInput={(params) => (
// 														<TextField
// 															{...params}
// 															error={Boolean(
// 																touched?.stateId && errors?.stateId,
// 															)}
// 															helperText={touched?.stateId && errors?.stateId}
// 															label="State"
// 														/>
// 													)}
// 													// value={candidateStateData.find(
// 													// 	(status) =>
// 													// 		status.id === values.activity.candidateStateId,
// 													// )}
// 													error={true}
// 												/>
// 											</FormControl>
// 										</Grid>
// 									</>
// 								) : (
// 									""
// 								)}

// 								{values.moduleId == "Interns" ? (
// 									<>
// 										<Grid item xs={4}>
// 											<FormControl
// 												size="small"
// 												sx={{ minWidth: 120 }}
// 												fullWidth
// 											>
// 												<Autocomplete
// 													size="small"
// 													fullWidth
// 													options={internStatusData.data || []}
// 													getOptionLabel={(option) => option.status || ""}
// 													{...getFieldProps("statusId")}
// 													onChange={(event, newValue) => {
// 														setFieldValue("statusId", newValue?.id || "");
// 														setFieldValue("statusId", null);
// 														setSelectedId(newValue?.id ?? "");
// 													}}
// 													renderInput={(params) => (
// 														<TextField
// 															{...params}
// 															error={Boolean(
// 																touched?.statusId && errors?.statusId,
// 															)}
// 															helperText={touched?.statusId && errors?.statusId}
// 															label="Status"
// 														/>
// 													)}
// 													value={internStatusData.data.find(
// 														(status) => status.id === values.statusId,
// 													)}
// 													error={true}
// 												/>
// 											</FormControl>
// 										</Grid>
// 									</>
// 								) : (
// 									""
// 								)}

// 								<Grid item xs={12}>
// 									<FormHelperText sx={{ fontSize: "14px" }}>
// 										Email Body:
// 									</FormHelperText>

// 									{/* {values.body? */}
// 									<CKEditor
// 										data-cy="txt-content-job-description"
// 										id="editor1"
// 										initData={values.body ? values.body : ""}
// 										onBlur={handleBlur}
// 										onChange={(event) => {
// 											setFieldValue("body", event.editor.getData());
// 										}}
// 									/>
// 									{Boolean(touched.body && errors.body) && (
// 										<FormHelperText
// 											style={{ margin: "5px 0 0 0" }}
// 											error
// 											data-cy="txt-content-err-job-description"
// 										>
// 											{touched.body && errors.body}
// 										</FormHelperText>
// 									)}
// 								</Grid>
// 							</Grid>
// 							<Stack
// 								direction="row"
// 								alignItems="center"
// 								justifyContent="flex-end"
// 								mb={3}
// 							>
// 								<Button
// 									// type="submit"
// 									// loading={isSubmitting}
// 									onClick={handleOpen}
// 									variant="contained"
// 								>
// 									Preview
// 								</Button>
// 								<Modal
// 									open={open}
// 									onClose={handleClose}
// 									aria-labelledby="modal-modal-title"
// 									aria-describedby="modal-modal-description"
// 								>
// 									<Stack sx={style}>
// 										<b>Body Content</b>
// 										{values.body ? parse(values.body) : "No content in body"}
// 									</Stack>
// 								</Modal>
// 							</Stack>
// 						</form>
// 					</Container>
// 				</Card>
// 			)}
// 		</Container>
// 	);
// };

// const componentConfig = {
// 	component: EmailTemplateDynamic,
// 	path: '/emailTemplateDynamic',
// 	path: "/email-template-dynamic",
// 	public: false,
// 	layout: DashboardLayout,
// 	group: "users",
// 	sidebar: true,
// 	role: ["admin"],
// };

// export default componentConfig;
