import { Button, Card, Container, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Loader from "../../components/Loader";
import Scrollbar from "../../components/Scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useState } from "react";
import { createCmsVariable, deleteCmsVariableById, getCmsVariable } from "../../redux/cmsVariable/cmsVariableThunk";
import { useConfirm } from "material-ui-confirm";
import invertDirection from "../../utils/invertDirection";

import CloseIcon from "@mui/icons-material/Close";
import { setOrderBy, setSortBy } from '../../redux/cmsVariable/cmsVariableSlice';
import { toast } from "react-toastify";
import { encryption } from "../../utils/encodeString";
import { Link } from "react-router-dom";

import DashboardLayout from '../../layouts/dashboard';
import { useRef } from "react";
import { debounce } from "lodash";


function CmsVariable(){
	const dispatch = useDispatch();
	const confirm = useConfirm();
    const { data, totalRecords, currentPage, loading, sortBy, orderBy } = useSelector(
		(state) => state.cmsVariable
	); 
		const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => {
		dispatch(getCmsVariable({ page: 1, limit: rowsPerPage, sortBy, orderBy }));
	}, [sortBy, orderBy]);
	const searchFormRef = useRef();
	const searchInputRef = useRef();
	const handleSearch =  (e) => {
     dispatch(getCmsVariable({ page: 1, limit: rowsPerPage, sortBy, orderBy,search:searchInputRef.current.value }));
  };
  const debounceWithSearch = debounce(handleSearch, 500);

	const handleChangePage = (event, newPage) => {
		dispatch(getCmsVariable({ page: newPage + 1, limit: rowsPerPage }));
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) }));
	};

	const handleReset = async () => {
    searchFormRef.current.reset();
    await dispatch(getCmsVariable({ page: 1, limit: rowsPerPage, sortBy, orderBy }));
	
  };

	const handleDelete = async (id, variableName) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${variableName}?`,
			});
			await dispatch(deleteCmsVariableById(id));
			await dispatch(
				getCmsVariable({
					page: currentPage,
					limit: rowsPerPage,
					sortBy,
					orderBy,
				})
			);
		} catch (error) {
			toast.error(error?.message);
		}
	};
	return (
		<Container maxWidth="xl">
			<Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
				<Typography variant="h4" gutterBottom>
					Variable
				</Typography>
				<Stack direction="row" alignItems="center">
					<Button
						component={Link}
						to="/cms-variable/add"
						variant="contained"
						startIcon={<AddIcon />}
					>
						Add Variable
					</Button>
				</Stack>
			</Stack>
			 <Stack width="400px" mt={5} mb={3} style={{width:'100%', marginTop:'25px'}}>
        <div style={{width:'100%', padding:'20px', background:'#fff', borderRadius:'10px'}}>
        <form ref={searchFormRef} onSubmit={(e)=>{
          e.preventDefault();
          debounceWithSearch()
          }}>
          <FormControl size="small" variant="outlined" fullWidth>
            <InputLabel htmlFor="outlined-adornment-search">
              Search by Variable Name
            </InputLabel>
            <OutlinedInput
              sx={{ padding: 0 }}
              size="small"
              id="outlined-adornment-search"
              inputRef={searchInputRef}
              onChange={debounceWithSearch}
              label="Search by Variable Name"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handleReset}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>
        </div>
      </Stack>
			{loading ? (
				<Loader />
			) : (
				<Card>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === 'name'}
												direction={sortBy === 'name' ? orderBy : 'asc'}
												onClick={() => handleSorting('name')}
											>
												Variable Name
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === 'default'}
												direction={sortBy === 'default' ? orderBy : 'asc'}
												onClick={() => handleSorting('default')}
											>
												Default Value
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === 'code'}
												direction={
													sortBy === 'code' ? orderBy : 'asc'
											 	}
											 	onClick={() => handleSorting('code')}
											>
												Variable Code
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
										<TableCell align="right">Actions</TableCell>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									  {Array.isArray(data) && data.length === 0 ? (
										<TableRow>
											<TableCell className="No-Record-text" align="center" colSpan={4}>
											No Record(s) Found
											</TableCell>
										</TableRow>
									) : 
								(  
									 	data.map((cmsVariable, idx) => (
											<TableRow key={idx}>
												<TableCell align="left">
													{cmsVariable.name}
												</TableCell>
												<TableCell align="left">
													{cmsVariable.default}
												</TableCell>
												<TableCell align="left">
													{cmsVariable.code}
												</TableCell>
									
												<TableCell align="right">
									 				<div className="action-button">
									 					<span style={{ marginRight: 8 }}>
									 						<IconButton
																color="primary"
																component={Link}
																to={`/cms-variable/add/${encryption(
																	cmsVariable.id
																)}`}
															>
									 							<EditIcon />
									 						</IconButton>
									 					</span>
									 					<span>
									 						<IconButton
																onClick={() =>
																	handleDelete(
																		cmsVariable.id,
																		cmsVariable.name
																	)
																}
															>
																<DeleteIcon color="error" />
															</IconButton>
														</span>
													</div>
									 			</TableCell>
											</TableRow>
										))
									)}
							
								</TableBody>
							</Table>
						</TableContainer>
					</Scrollbar>

					 <TablePagination
						rowsPerPageOptions={[5, 10]}
						component="div"
						count={totalRecords}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/> 
				</Card>
			)}
		</Container>
	);
}
const componentConfig = {
	component: CmsVariable,
	path: '/cms-variable',
	public: false,
	group: 'cms',
	layout: DashboardLayout,
	sidebar: true,
	role: ['admin'],
};

export default componentConfig;


