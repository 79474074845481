import * as Yup from "yup";

const exitQuestionSchema = Yup.object().shape({
  question: Yup.string().required("Question is required")
  .trim("First name cannot contain only whitespace")
  .nullable(),
  
  exitinterviewoptions: Yup.array().of(
    Yup.object().shape({
      options: Yup.string().required("Option is required.")
      .trim("Name cannot contain only whitespace")
      .nullable(),
    })
  ),
});

export default exitQuestionSchema;
