import DashboardLayout from "../../layouts/dashboard";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";
import CustomPagination from "../../components/Pagination";
import EditIcon from "@mui/icons-material/Edit";
import Loader from "../../components/Loader";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { encryption } from "../../utils/encodeString";
import { Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import VisibilityIcon from "@mui/icons-material/Visibility";
import SearchIcon from "@mui/icons-material/Search";
import CachedIcon from "@mui/icons-material/Cached";
import { debounce } from "lodash";
import {
  getAssestLicence,
  deleteAssestLicence,
  getAssestLicenCount
} from "../../redux/assestLicence/thunk/index";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import React, { useEffect, useState } from "react";
import { indianDateFormat, yearMonthDateFormat } from "../../utils/dateFormat";
import {
  setLimit,
  setcurrentPage,
  setSortBy,
  setOrderBy,
} from "../../redux/assestLicence/assestLicenceSlice";
import invertDirection from "../../utils/invertDirection";
import LocalStorage from "../../service/localStorage";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import {
  Autocomplete,
  Badge,
  Box,
  Chip,
  Drawer,
  Grid,
  InputLabel,
  TextField,
} from "@mui/material";
// import { DatePicker, DesktopDatePicker, LocalizationProvider } from "@mui/lab";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker, DatePicker } from "@mui/x-date-pickers";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { getEmployeeForDropDown } from "../../redux/employee/employeeThunk";
import { getVendor } from "../../redux/vendor/vendorThunk";
import { element } from "prop-types";
import moment from "moment";
function LicenceMaster() {
  const dispatch = useDispatch();
  
  const [state, setState] = React.useState({
    right: false,
  });
  const EmployeeDropDown = useSelector(
    (state) => state.employee.allEmployeeData
  );
  const inputRef = React.useRef(null);
  const VenderDropDown = useSelector((state) => state.vendor.dataForDropdown);
  const navigate = useNavigate();
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data, "db");
    } catch (error) {}
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        let roleId = localStorage.getItem("roleId");
        getUserPermission(roleId);

        await dispatch(getEmployeeForDropDown()).unwrap();
        
        if (getViewVisible("vendors")) {
          await dispatch(getVendor()).unwrap();
        }
      } catch (error) {
        // Handle the error (uncomment the next line to display an error toast)
        // toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("licenceMaster")) {
      dispatch(
        getAssestLicence({
          page: currentPage,
          limit: limit,
          orderBy: orderBy,
          sortBy: sortBy,
          search: "",
          expityMonth: "",
          assignTo: "",
          purchasedDate: "",
          vendorId: "",
        })
      );
      navigate("/dashboard");
    }
  }, []);
  const [filterChips, setFilterChips] = useState([]);
  const [filterdata, setFilterData] = useState({
    search: "",
    expityMonth: "",
    assignTo: "",
    purchasedDate: "",
    vendorId: "",
  });

  const handleReset = async () => {
    setFilterData({
      search: "",
      expityMonth: "",
      assignTo: "",
      purchasedDate: "",
      vendorId: "",
    });
    await dispatch(
      getAssestLicence({
        page: currentPage,
        limit: limit,
        orderBy: orderBy,
        sortBy: sortBy,
        search: "",
        expityMonth: "",
        assignTo: "",
        purchasedDate: "",
        vendorId: "",
      })
    );
    setFilterChips([]);
    setState({ right: false });
  };
  const {
    loading,
    currentPage,
    data,
    limit,
    status,
    totalPages,
    totalRecords,
    orderBy,
    sortBy,
    totalLicence
  } = useSelector((x) => x.assetsLicence);
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(
          getAssestLicence({
            page: currentPage,
            limit: limit,
            orderBy: orderBy,
            sortBy: sortBy,
            ...filterdata,
            expityMonth: filterdata.expityMonth
              ? new Date(filterdata.expityMonth).getMonth() + 1
              : "",
          })
        ).unwrap();
      } catch (error) {
        // Handle the error (uncomment the next line to display an error toast)
        // toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, currentPage, limit, orderBy, sortBy, filterdata]);
  useEffect(() => {
    const fetchData = async () => {
      if (status === "idle") {
        try {
          await dispatch(
            getAssestLicence({
              page: currentPage,
              limit: limit,
              orderBy: orderBy,
              sortBy: sortBy,
              ...filterdata,
              expityMonth: filterdata.expityMonth
                ? new Date(filterdata.expityMonth).getMonth() + 1
                : "",
            })
          ).unwrap();
        } catch (error) {
          // Handle the error (uncomment the next line to display an error toast)
          // toast.error(error.message);
        }
      }
    };

    fetchData();
  }, [limit, currentPage, dispatch, status, orderBy, sortBy, filterdata]);
  const handleDelete = async (id) => {
    await dispatch(deleteAssestLicence(id));
    await dispatch(
      getAssestLicence({
        page: 1,
        limit: limit,
        orderBy: orderBy,
        sortBy: sortBy,
        ...filterdata,
        expityMonth: filterdata.expityMonth
          ? new Date(filterdata.expityMonth).getMonth() + 1
          : "",
      })
    );
  };
  const handleChangePage = async (event, newPage) => {
    await dispatch(setcurrentPage({ page: newPage + 1 }));
  };

  const handleChangeRowsPerPage = async (event) => {
    // await dispatch(setcurrentPage({ page: 1 }));
    await dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        let newFilterdata = { ...filterdata };
        
        await dispatch(
          getAssestLicence({
            page: 1,
            limit: limit,
            orderBy: orderBy,
            sortBy: sortBy,
            ...filterdata,
            expityMonth: filterdata.expityMonth
              ? new Date(filterdata.expityMonth).getMonth() + 1
              : "",
          })
        ).unwrap();

        setState({ right: false });
        setFilterChips([]);
        
        let temparr = [];
        Object.entries(newFilterdata).forEach(([key, value]) => {
          if (value !== "") {
            if (key === "expityMonth") {
              temparr.push({
                key: key,
                name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, "$1 $2"),
                value: new Date(value).toLocaleString("default", { month: "long" }),
              });
            } else if (key === "assignTo") {
              let obj = EmployeeDropDown.find((x) => value === x.id);
              temparr.push({
                key: key,
                name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, "$1 $2"),
                value: `${obj.firstName} ${obj.lastName}`,
              });
            } else if (key === "vendorId") {
              let obj = VenderDropDown.find((x) => value === x.id);
              temparr.push({
                key: key,
                name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, "$1 $2"),
                value: `${obj.firstName} ${obj.lastName}`,
              });
            } else if (key === "purchasedDate") {
              temparr.push({
                key: key,
                name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, "$1 $2"),
                value: moment(value).format("YYYY-MM-DD"),
              });
            } else {
              temparr.push({
                key: key,
                name: (key.charAt(0).toUpperCase() + key.slice(1)).replace(/([a-z])([A-Z])/g, "$1 $2"),
                value: value,
              });
            }
            setFilterChips(temparr);
          }
        });
      } catch (error) {
        // Handle the error (uncomment the next line to display an error toast)
        // toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch, filterdata, limit, orderBy, sortBy, EmployeeDropDown, VenderDropDown]);

  const handleSearch = async (e) => {
    
    setFilterData({
      ...filterdata,
      search: e.target.value,
    });
    // setFieldValue("projectName", e?.target?.value || "");
    // handleSubmit(e.target.value ,true);
  };
  const debounceWithSearch = debounce(handleSearch, 300);
  const onDeleteChip = async (value, length) => {
    setFilterChips([...filterChips.filter((val) => val.name !== value.name)]);
    setFilterData({ ...filterdata, [value.key]: "" });
    if (value?.key === "search") {
      inputRef.current.value = "";
    }
    await dispatch(
      getAssestLicence({
        page: currentPage,
        limit: limit,
        orderBy: orderBy,
        sortBy: sortBy,
        ...filterdata,
        [value.key]: "",
        expityMonth:
          value.key !== "expityMonth"
            ? filterdata.expityMonth
              ? new Date(filterdata.expityMonth).getMonth() + 1
              : ""
            : "",
      })
    );
    // let filters = { ...values, [value["type"]]: "" };

    // if (length === 1) {
    // 	// getInterviewList({ ...queryParams, orderBy, sortBy, ...params }),
    //   dispatch(getInterviewList({ orderBy, sortBy, ...params }));
    // } else {
    //   dispatch(getInterviewList({ ...filters, orderBy, sortBy, ...params }));
    // }
    // dispatch(setPage({ page: 0 }));
    // setFieldValue(value.type, "");
    // if(value.type==="search"){
    //   setSearchValue("");
    //   inputRef.current.value = "";
    // }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAssestLicenCount()).unwrap();
      } catch (error) {
        // Handle the error (uncomment the next line to display an error toast)
        // toast.error(error.message);
      }
    };

    fetchData();
  }, [dispatch]);

  return (
    <Container className="AssetsLicence-wrapper" maxWidth="xl">
      <Stack
      className="main-title-wrapper"
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={2}
      >
            <div className="title-button-wrapper">
        <Typography variant="h4" gutterBottom>
          Assets Licence ({totalLicence})
          {/* <Typography variant="body2">
            Total Records : {totalLicence}
          </Typography> */}
        </Typography>


        <Stack direction="row" alignItems="center">
          {getAddVisible("licenceMaster") && (
            <Button
              component={Link}
              to="/licence-master/add"
              variant="contained"
              startIcon={<AddIcon />}
            >
              Add Licence
            </Button>
          )}
        </Stack>
         </div>
      </Stack>

      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div className="employee-classification-right" style={{paddingTop:"20px"}}>
                <TextField
                  className="employee-search-detail"
                  label="Search By Product Name"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  // value={filterdata.search}
                  onChange={debounceWithSearch}
                />
                <div className="employee-classification-dropdown employee-classification-DatePicker">
                  <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    item
                  >
                    <DatePicker
                      label={"Expiry Month"}
                      value={filterdata.expityMonth || null}
                      openTo="month"
                      views={["month"]}
                      inputFormat="MMMM"
                      onChange={(newValue) => {
                        // console.log(newValue, "test");
                        setFilterData({
                          ...filterdata,
                          expityMonth: newValue || "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={false}
                          size="small"
                          style={{
                            width: "100%",
                            marginRight: "10px",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    disabled={!getViewVisible("employee")}
                    autoComplete="off"
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    size="small"
                    fullWidth
                    options={EmployeeDropDown || []}
                    key={EmployeeDropDown || []}
                    onChange={(event, newValue) => {
                      //   let tempArray = newValue.map((x) => x.id);
                      setFilterData({
                        ...filterdata,
                        assignTo: newValue?.id || "",
                      });
                    }}
                    getOptionLabel={(option) => {
                      return `${option?.firstName || ""} ${
                        option?.lastName || ""
                      }`.trim();
                    }}
                    value={EmployeeDropDown.find(
                      (x) => filterdata.assignTo === x.id
                    ) || ""}
                    renderInput={(params) => (
                      <TextField {...params} label="Assigned To" />
                    )}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    disabled={!getViewVisible("vendors")}
                    labelId="demo-simple-select-helper-label"
                    popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
                    id="demo-simple-select-helper"
                    label="Vendor"
                    size="small"
                    name="vendorId"
                    autoComplete="off"
                    options={VenderDropDown || []}
                    getOptionLabel={(option) =>
                      `${option?.firstName || ""} ${
                        option?.lastName || ""
                      }`.trim()
                    }
                    onChange={(event, newValue) => {
                      setFilterData({
                        ...filterdata,
                        vendorId: newValue?.id || "",
                      });
                    }}
                    value={
                      VenderDropDown?.find((vendor) => {
                        return vendor.id == filterdata.vendorId;
                      }) || ""
                    }
                    renderInput={(params) => (
                      <TextField {...params} label="Vendor" />
                    )}
                  />
                </div>
                <div className="employee-classification-dropdown employee-classification-DatePicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DesktopDatePicker
                      label="Purchased Date"
                      value={filterdata.purchasedDate || null}
                      maxDate={new Date()}
                      inputFormat="dd/MM/yyyy"
                      // minDate={new Date('2017-01-01')}
                      autoComplete="off"
                      onChange={(newValue) => {
                        //   setFieldValue("purchasedDate", newValue || "");
                        setFilterData({
                          ...filterdata,
                          purchasedDate: newValue || "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={false}
                          size="small"
                          style={{
                            width: "100%",
                            marginRight: "10px",
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        className="employee-table-Stack search-filter"
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="employee-table-Stack-label search-filter-detail"
              label={`${chips.name !== "Vendor Id" ? chips.name : "Vendor"}: ${chips.name == "Purchased Date" ? indianDateFormat(chips.value) :
                chips.value
              }`}
              color="primary"
              size="small"
              key={chips}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>
      {filterChips?.length > 0 ? (
        <Typography variant="body2" mb={1}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}
      <Card className="custom-grid" style={{ marginTop: "10px" }}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table className="grid-table">
            <TableHead>
              <TableRow>
                <TableCell style={{ maxWidth: "200px" }} align="left">
                  <TableSortLabel
                    active={sortBy === "productName"}
                    direction={sortBy === "productName" ? orderBy : "asc"}
                    onClick={() => handleSorting("productName")}
                  >
                    Product Name
                  </TableSortLabel>
                </TableCell>
                <TableCell>Vendor</TableCell>
                <TableCell style={{ maxWidth: "200px" }}>
                  <TableSortLabel
                    active={sortBy === "licencePeriod"}
                    direction={sortBy === "licencePeriod" ? orderBy : "asc"}
                    onClick={() => handleSorting("licencePeriod")}
                  >
                    License Period
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ maxWidth: "200px" }}>
                  <TableSortLabel
                    active={sortBy === "assignedEmployees"}
                    direction={sortBy === "assignedEmployees" ? orderBy : "asc"}
                    onClick={() => handleSorting("assignedEmployees")}
                  >
                    Assigned To
                  </TableSortLabel>
                </TableCell>
                <TableCell style={{ maxWidth: "200px" }}>
                  <TableSortLabel
                    active={sortBy === "expiryDate"}
                    direction={sortBy === "expiryDate" ? orderBy : "asc"}
                    onClick={() => handleSorting("expiryDate")}
                  >
                    Expiry Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortBy === "purchasedDate"}
                    direction={sortBy === "purchasedDate" ? orderBy : "asc"}
                    onClick={() => handleSorting("purchasedDate")}
                  >
                    Purchased Date
                  </TableSortLabel>
                </TableCell>
                <TableCell align="center" style={{ width: "220px" }}>
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableCell align="center" width="100%" colSpan={7}>
                  <Loader />
                </TableCell>
              ) : Array.isArray(data) && data.length === 0 ? (
                <TableRow>
                  <TableCell className="No-Record-text" align="center" colSpan={6}>
                    No Record(s) Found
                  </TableCell>
                </TableRow>
              ) : (
                data.map((res, idx) => (
                  <TableRow key={idx}>
                    <TableCell size="small" align="left">
                      {res.productName}
                    </TableCell>
                    <TableCell align="left">
                      {res.vendor
                        ? `${res.vendor.firstName} ${res.vendor.lastName}`
                        : ""}
                    </TableCell>
                    <TableCell>{res.licencePeriod}</TableCell>
                    <TableCell>
                      {res.assignedEmployees.map((e) => (
                        <tr>
                          <td>{`${e.firstName} ${e.lastName}`}</td>
                        </tr>
                      )) || ""}
                    </TableCell>
                    <TableCell>
                      {res.expiryDate ? indianDateFormat(res.expiryDate) : ""}
                    </TableCell>
                    <TableCell align="left">
                      {res ? indianDateFormat(res.purchasedDate) : ""}
                    </TableCell>
                    <TableCell className="td-action-button" size="medium" align="left">
                      <div
                        className="action-button"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        
                          {getEditVisible("licenceMaster") && (
                            <span>
                              <IconButton
                                color="primary"
                                // component={Link}
                                // to={`/licence-master/add/${encryption(res.id)}`}
                                onClick={(e) => window.open(`/licence-master/add/${encryption(res.id)}`,"_blank") }
                              >
                                {/* <EditIcon /> */}
                                <img src="assets/images/edit.svg" title="Edit" />
                              </IconButton>
                            </span>
                          )}

                          {getViewVisible("licenceMaster") && (
                            <span>
                              <IconButton
                                // component={Link}
                                // to={`/licence-master/view/${encryption(res.id)}`}
                                onClick={(e) => window.open(`/licence-master/view/${encryption(res.id)}`,"_blank") }
                              >
                                {/* <VisibilityIcon /> */}
                                <img src="assets/images/vieweye-icon.svg" title="View" />
                              </IconButton>
                            </span>
                          )}
                          {getDeleteVisible("licenceMaster") && (
                            <span>
                              <IconButton onClick={() => handleDelete(res.id)}>
                                {/* <DeleteIcon color="error" /> */}
                                <img src="assets/images/trash-gray.svg" title="trash" />
                              </IconButton>
                            </span>
                          )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
          <CustomPagination
            totalPage={totalPages}
            totalCount={totalRecords}
            limit={limit}
            handleChangePage={handleChangePage}
            // page={currentPage}
            page={currentPage > 0 ? currentPage - 1 : 0}
            rowsPerPageOptions={[10, 20, 40]}
            handleRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: LicenceMaster,
  path: "/licence-master",
  public: false,
  layout: DashboardLayout,
  group: "assMangment",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
