import { createSlice } from "@reduxjs/toolkit";
import { getAssetDetails, totalAssetDetailCount } from "./reportsAssetsThunk";

const initialState = {
    data: [],
    loading: false,
    complete: false,
    sortBy: "SystemName",
    orderBy: "asc",
    status: "idle",
    totalPages:0,
    limit: 40,
    totalRecords: -1,
    currentPage: 0,
    error:null,
    totalCount:-1,
    isSearchQuery:false,
}

const reportsAssetsSlice = createSlice({
    name:"reportsAssets",
    initialState,
    reducers:{
        setSortBy: (state, action) => {
            state.sortBy = action.payload.sortBy;
        },
        setOrderBy: (state, action) => {
            state.orderBy = action.payload.orderBy;
        },
        setLimit: (state, action) => {
            state.limit = action.payload.limit;
            state.status = "idle";
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload.page;
            state.status = "idle";
        }
    },
    extraReducers: {
        [getAssetDetails.pending]:(state)=>{
            state.loading = true;
        },
        [getAssetDetails.fulfilled]:(state, {payload})=>{
            state.loading = false;
            state.data = payload?.data?.data || payload?.data;
            state.totalPages = payload.data.totalPages;
            state.limit = payload.data.limit;
            state.totalRecords = payload.data.totalRecords;
            state.currentPage = payload.data.currentPage - 1;
            state.complete = true;
            state.status = "fulfilled"
        },
        [getAssetDetails.rejected]:(state, {payload})=>{
            state.loading = false;
            state.data = [];
            state.complete = true;
            state.status = "rejected";
            state.totalPages = 0;
            state.error = payload;
        },
        [totalAssetDetailCount.fulfilled]:(state, {payload})=>{
            state.totalCount = payload.data;
        }
    }
})

export const reportsAssetsReducer = reportsAssetsSlice.reducer;
export const { setSortBy, setOrderBy, setLimit, setCurrentPage } = reportsAssetsSlice.actions