import * as Yup from "yup";
const digitsOnly = (value) => /^\d+$/.test(value);

function notesValidation(message) {
  return this.test("notesValidation", message, function (value) {
    // console.log(value,'pppp', this.parent.statusId,this.parent.note);
    const { path, createError } = this;
    if (
      this.parent.statusId == 6 &&
      (this.parent.note == undefined ||
        this.parent.note == null ||
        this.parent.note == "")
    ) {
      return createError({
        path,
        message: message ?? "Notes is required",
      });
    }
    return true;
  });
}
function prodDateValidation() {
  return this.test("prodDateValidation", function (value) {
    // console.log(value,'pppp', this.parent.statusId,this.parent.note);
    const { path, createError } = this;
    if (
      this.parent.productionDate == undefined ||
      this.parent.productionDate == null ||
      this.parent.productionDate == ""
    ) {
      return createError({
        path,
        message: "Production Date is required",
      });
    } else if (
      new Date(this?.parent?.productionDate)?.setHours(0, 0, 0, 0) <
      new Date()?.setHours(0, 0, 0, 0)
    ) {
      return createError({
        path,
        message: "Date must be before today's date",
      });
    } else if (
      new Date(this?.parent?.productionDate)?.setHours(0, 0, 0, 0) <
      new Date(this?.parent?.joiningDate)?.setHours(0, 0, 0, 0)
    ) {
      return createError({
        path,
        message: "Date must be after Joining date",
      });
    }
    return true;
  });
}
Yup.addMethod(Yup.string, "notesValidation", notesValidation);
Yup.addMethod(Yup.string, "prodDateValidation", prodDateValidation);

const studentSchema = Yup.object().shape(
  {
    firstName: Yup.string()
      .required("First Name is required")
      .max(64, "Maximum 64 characters required")
      .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
      .trim("First Name cannot contain whitespace")
      .strict(true)
      .nullable(),
    middleName: Yup.string()
      .max(64, "Maximum 64 characters required")
      .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
      .trim("Middle Name cannot contain whitespace")
      .strict(true)
      .nullable(),
    lastName: Yup.string()
      .required("Last Name is required")
      .max(64, "Maximum 64 characters required")
      .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
      .trim("Last Name cannot contain whitespace")
      .strict(true)
      .nullable(),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required")
      .max(512, "Maximum 512 characters required")
      .trim("Email cannot contain only whitespace")
      .strict(true)
      .nullable(),
    gender: Yup.string().required("Gender is required"),
    contact: Yup.string()
      .required("Contact is required")
      .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
      .min(10, "Contact number must be 10 digits")
      .max(10, "Contact number must be 10 digits")
      .nullable(),
    note: Yup.string().notesValidation("Notes is required."),
    emergencyContactPerson: Yup.string(),
    relationWithIntern: Yup.string(),
    emergencyContactNumber: Yup.string(),
    sscPercentage: Yup.number()
      .required("SSC Percentage is required")
      .min(0, "Percentage is Invalid")
      .max(100, "Percentage is Invalid")
      .typeError("Please enter valid number"),
    // hscPercentage: Yup.number()
    //   .required("HSC Percentage is required")
    //   .min(0, "Percentage is Invalid")
    //   .max(100, "Percentage is Invalid")
    //   .typeError("Please enter valid number"),
    hscPercentage: Yup.number().when(["educationTypeId", "diplomaPercentage"], {
      is: (educationTypeId, diplomaPercentage) =>
        educationTypeId === 23 || diplomaPercentage,
      then: Yup.number().notRequired(),
      otherwise: Yup.number()
        .required("HSC Percentage is required")
        .min(0, "hscPercentage is Invalid")
        .max(100, "hscPercentage is Invalid")
        .typeError("Please enter valid number"),
    }),

    diplomaPercentage: Yup.number().when("hscPercentage", {
      is: (hscPercentage) => (hscPercentage ? false : true),
      then: Yup.number()
        .min(0, "diploma CGPA is Invalid")
        .max(10, "diploma CGPA is Invalid")
        .typeError("diploma CGPA enter valid number")
        .required("diploma CGPA is required")
        .nullable(),
      otherwise: Yup.number()
        .min(0, "diploma CGPA is Invalid")
        .max(100, "diploma CGPA is Invalid")
        .typeError("diploma CGPA enter valid number")
        .nullable(),
    }),
    pcm: Yup.number().when(["diplomaPercentage", "hscPercentage", "hscCourseId"], {
      is: (diplomaPercentage, hscPercentage, hscCourseId) => 
        diplomaPercentage || hscPercentage || hscCourseId == 2 || hscCourseId == 3,
      then: Yup.number().notRequired(),
      otherwise: Yup.number()
        .required("PCM is required")
        .typeError("Please enter valid number")
        .min(0, "PCM is Invalid")
        .max(100, "PCM is Invalid")
        .nullable(),
        
    }).when(["hscPercentage", "hscCourseId"], {
      is: (hscPercentage, hscCourseId) => 
        hscCourseId == 1 && hscPercentage && hscPercentage > 0,
      then: Yup.number()
        .required("PCM is required")
        .typeError("Please enter valid number")
        .min(0, "PCM is Invalid")
        .max(100, "PCM is Invalid")
        .nullable()
        ,
      otherwise: Yup.number().notRequired().typeError("Please enter valid number"),
    }),

    // contactNumber: Yup.string().when('email', {
    //   is: (email) => email ? false : true,
    //   then: Yup.string()
    //   .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
    //   .min(10, "Contact number must be 10 digits")
    //   .max(10, "Contact number must be 10 digits")
    //   .required('Contact number is required')
    //   .nullable(),
    //   otherwise: Yup.string()
    //   .matches(/^[6-9]\d{9}$/, "Contact number is invalid")
    //   .min(10, "Contact number must be 10 digits")
    //   .max(10, "Contact number must be 10 digits")
    //   .nullable(),
    // }),

    graduationPercentage: Yup.number()
      .min(0, "CGPA is Invalid")
      .max(10, "CGPA is Invalid")
      .typeError("Please enter valid number"),
    masterPercentage: Yup.number()
      .min(0, "CGPA is Invalid")
      .max(10, "CGPA is Invalid")
      .typeError("Please enter valid number"),
    wishlist: Yup.boolean().nullable(),
    marks: Yup.number()
      .min(0, "Marks is Invalid")
      .max(100, "Marks is Invalid")
      .typeError("Please enter valid number"),
    batch: Yup.string()
    .trim("Year cannot contain only whitespace")
    .strict(true)
    .max(32, "Maximum 32 characters required").nullable(),

    hscCourseId: Yup.string().when(["educationTypeId", "diplomaPercentage"], {
      is: (educationTypeId, diplomaPercentage) =>
        educationTypeId === 23 || diplomaPercentage,
      then: Yup.string().notRequired(),
      otherwise: Yup.string()
        .required("HSC Course is required")
    }),
    enrollmentNumber: Yup.string()
      .matches( /^[a-zA-Z0-9]+$/i, "Enrollment number is invalid")
      .max(14, "Enrollment number cannot exceed 14 digits")
      // .test(
      //   "Digits only",
      //   "Only digits allowed",
      //   digitsOnly
      // )
      .nullable(),
    collegeId: Yup.string().required("College is required").nullable(),
    educationTypeId: Yup.string()
      .required("Education Type is required")
      .nullable(),
    courseId: Yup.string().required("Course is required").nullable(),
    technologyId: Yup.string().nullable(),
    statusId: Yup.string().required("Student Status is required").nullable(),
    commitmentDuration: Yup.string()
      .required("Commitment Duration is required")
      .nullable(),
    driveId: Yup.string().nullable(),
    isUnderServiceAgreement: Yup.boolean().nullable(),
    isCheque: Yup.boolean().nullable(),
    isRetention: Yup.boolean().nullable(),
    type: Yup.array().when("isUnderServiceAgreement", {
      is: true,
      then: Yup.array().required("Agreement type is required").nullable(),
      otherwise: Yup.array().nullable(),
    }),
    bankName: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("Bank name is required")
        .nullable()
        .max(512, "Bank name is too long"),
      otherwise: Yup.string().nullable(),
    }),
    chequeAmount: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string().required("Amount is required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    IFSC: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("IFSC is required")
        .nullable()
        .max(512, "IFSC code is too long"),
      otherwise: Yup.string().nullable(),
    }),
    bankBranch: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("Bank branch is required")
        .nullable()
        .max(512, "Bank branch is too long"),
      otherwise: Yup.string().nullable(),
    }),
    comments : Yup.string()
    .trim("Comments cannot contain only whitespace")
    .strict(true)
    .nullable()
   ,
    bankAccountNumber: Yup.string().when(
      ["isCheque", "isUnderServiceAgreement"],
      {
        is: (first, second) => {
          return first && second;
        },
        then: Yup.string().nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    noOfCheque: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string().required("Number of Cheque is required").nullable(),
      otherwise: Yup.string().nullable(),
    }),
    chequeNumbers: Yup.string().when(["isCheque", "isUnderServiceAgreement"], {
      is: (first, second) => {
        return first && second;
      },
      then: Yup.string()
        .required("Cheque Number is required")
        .nullable()
        .max(1024, "Cheque number is too long"),
      otherwise: Yup.string().nullable(),
    }),
    retentionAmount: Yup.string().when(
      ["isRetention", "isUnderServiceAgreement"],
      {
        is: (first, second) => {
          return first && second;
        },
        then: Yup.string().required("Amount is required").nullable(),
        otherwise: Yup.string().nullable(),
      }
    ),
    joiningDate: Yup.string().when("statusId", {
      is: (val) => [11, 2, 3, 4].includes(Number(val)),
      then: Yup.string().nullable(),
      otherwise: Yup.string().required("Joining date is required").nullable(),
    }),

    // productionDate: Yup.string().prodDateValidation().nullable(),
    productionDate: Yup.string().when("statusId", {
      is: (val) => Number(val) === 1,
      then: Yup.string().prodDateValidation().nullable(),
      otherwise: Yup.string().nullable(),
    }),

    workLocation: Yup.string().when("statusId", {
      is: (val) => [11, 2, 3, 4].includes(Number(val)),
      then: Yup.string().nullable(),
      otherwise: Yup.string().required("Location is required"),
    }),
    seatNumber: Yup.string().when("workLocation", {
      is: (val) => val === "2" || val === "3",
      then: Yup.string().required("Seat Number is required"),
      otherwise: Yup.string().nullable(),
    }),
  },
  [["diplomaPercentage", "hscPercentage"]]
);

export default studentSchema;
