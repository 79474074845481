import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";
import NiceModal from "@ebay/nice-modal-react";
import { jsPDF } from "jspdf";
import ReactDOMServer from "react-dom/server";
import { Axios } from "../../../service/axios";
import ConfirmModal from "./AddEmployeeComponent/ConfirmationModal";
import { encryption } from "../../../utils/encodeString";
import DashboardLayout from "../../../layouts/dashboard";
import Loader from "../../../components/Loader";
import Personal from "./AddEmployeeComponent/Personal";
import Address from "./AddEmployeeComponent/Address";
import Contact from "./AddEmployeeComponent/Contact";
import Education from "./AddEmployeeComponent/Education";
import Experience from "./AddEmployeeComponent/Experience";
import PF from "./AddEmployeeComponent/PF";
import Service from "./AddEmployeeComponent/Service";
import CheckList from "./AddEmployeeComponent/CheckList";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Grid from "@mui/material/Grid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import { useConfirm } from "material-ui-confirm";

import employeeSchema, {
  checkPersonalDetails,
  checkContactDetails,
  checkAddressDetails,
  checkEducationDetails,
  checkWorDetailsError,
  checkChecklistsError,
  checkPfErrors,
  checkServiceAgreementError,
  checkAdditionalDetails,
} from "../../../validations/employeeSchema";

import {
  getEmployee,
  getEmployeeById,
  getProjectManagerData,
  getTeamLeadData,
  insertEmployee,
  updateEmployee,
} from "../../../redux/employee/employeeThunk";
import { decryption } from "../../../utils/encodeString";
import { BASE_URL } from "../../../constants/index";
import {
  getAreaData,
  getCityData,
  getStateData,
  getPostalCode,
} from "../../../utils/getLocationData";
import { getCourse } from "../../../redux/course/courseThunk";
import { getEducation } from "../../../redux/education/educationThunk";
import { getTechnology } from "../../../redux/technology/technologyThunk";
import { getCollege } from "../../../redux/college/collegeThunk";
import { getDesignation } from "../../../redux/designation/designationThunk";
import { getJobChange } from "../../../redux/jobChange/jobChangeThunk";
import { getDepartment } from "../../../redux/department/departmentThunk";
import { getCompany } from "../../../redux/company/companyThunk";
import { getSkill } from "../../../redux/skill/skillThunk";
import PFForm from "./AddEmployeeComponent/PFForm";
import { indianDateFormat } from "../../../utils/dateFormat";
import { generateObject } from "../../../utils/generateAddressObject";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
// comment for testing git v2
import LocalStorage from "../../../service/localStorage";
import {
  getUserPermissionById,
  getUserPermissionByRoll,
} from "../../../redux/userPermission/userPermissionthunk";
import localStorage from "../../../service/localStorage";
import { setResetRelievingDateValidation } from "../../../redux/employee/employeeSlice";

const concateAddress = (...addressDetails) => {
  return addressDetails.filter((singleItem) => singleItem?.trim())?.join(", ");
};

const concateName = (...name) => {
  return name.reduce((prev, current) => {
    if (current) {
      return prev + ` ${current}`;
    }

    return prev;
  }, "");
};

function AddEmployee() {
  let { id } = useParams();
  const confirm = useConfirm();

  if (id) {
    id = decryption(id);
  }
  const AuthRole = useSelector((state) => state.auth.role);
  const [esic, setESIC] = useState(false);
  const [pf, setPf] = useState(false);
  const [value, setValue] = useState("1");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [TLandPM, setTLandPM] = useState({ tl: [], pm: [] });
  const [isUnderPf, setUnderPF] = useState(true);
  const [sameAsCurrent, setSameAsCurrent] = useState(false);

  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [adharCardFileUrl, setAdharCardFileUrl] = useState(null);
  const [adharCardSelectedFile, setAdharCardSelectedFile] = useState(null);
  const [panCardFileUrl, setPanCardFileUrl] = useState(null);
  const [panCardSelectedFile, setPanCardSelectedFile] = useState(null);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [areas, setArea] = useState([]);

  const [permanentStates, setpermanentStates] = useState([]);
  const [permanentCities, setpermanentCities] = useState([]);
  const [permanentAreas, setpermanentAreas] = useState([]);
  const [backlink, setBackLink] = useState(null);
  const [pfError, setPfError] = useState({});
  const [esicError, setEsicError] = useState({});

  const educationData = useSelector((state) => state.education);
  const courseData = useSelector((state) => state.course);
  const skillData = useSelector((state) => state.skill);
  const technologyData = useSelector((state) => state.technology);
  const collegeStatus = useSelector((state) => state.college.status);
  const designationData = useSelector((state) => state.designation);
  const jobChangeData = useSelector((state) => state.jobChange);
  const departmentData = useSelector((state) => state.department);
  const companyData = useSelector((state) => state.company);
  const relievingDateValidation = useSelector((state) => state.employee.relievingDateValidation);
  const locationDropdown = [
    { title: "HOME", id: 1 },
    { title: "OFFICE", id: 2 },
    { title: "HYBRID", id: 3 },
  ];

  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (id === -1) {
      navigate("employee");
    }
  }, [dispatch, id]);

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  /* 	console.log(getAddVisible("employee")); */

  // if (!getAddVisible("employee") || !getEditVisible("employee")) {
  // 	navigate("/dashboard");
  // }
  const concateName = (firstName, middleName, lastName) => {
    if (firstName && lastName) {
      if (middleName) {
        return firstName + " " + middleName + " " + lastName;
      } else {
        return firstName + " " + lastName;
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      middleName: "",
      lastName: "",
      password: null,
      dateOfBirth: null,
      employeeImage: null,
      panCardImage: null,
      aadharCardImage: null,
      presentAddressImage: null,
      permanentAddressImage: null,
      employeeResume: null,
      bachelorCertificates: [],
      masterCertificates: [],
      // passportImage: null,
      gender: "",
      bloodGroup: "",
      github: null,
      portfolioWebsite: null,
      maritalStatus: "",
      referedBy: "",
      spouseName: "",
      emails: [{ type: "", email: "" }],
      seatNumber: "",
      vehicleData: [
        {
          vehicleType: "",
          vehicleName: "",
          vehicleNumber: "",
          remarks: "",
        },
      ],
      parkingNumber: "",
      parkingBlock: "",
      workLocation: "",
      contact: {
        contactNumber: "",
        alternateContactNumber: "",
        companyEmail: "",
        personalEmail: "",
        linkedIn: "",
        skype: "",
        companySkype: "",
        emergencyContactName: "",
        relationWithEmployee: "",
        emergencyContactNumber: "",
      },
      address: {
        presentStreetLine1: "",
        presentStreetLine2: "",
        presentCountry: "India",
        presentState: "Gujarat",
        presentCity: "Ahmedabad",
        presentArea: "",
        presentZipCode: "",
        sameAsCurrent: false,
        permenentStreetLine1: "",
        permenentStreetLine2: "",
        permenentCountry: "India",
        permenentState: "Gujarat",
        permenentCity: "Ahmedabad",
        permenentArea: "",
        permenentZipCode: "",
      },
      education: [
        {
          educationTypeId: "",
          courseId: null,
          courseName: "",
          instituteName: "",
          instituteId: "",
          percentage: null,
          passingYear: "",
          courseAvailable: "",
        },
      ],
      additionalCourse: [
        {
          courseName: "",
          instituteName: "",
          certificateUrl: "",
          skills: [],
        },
      ],
      experience: [
        {
          companyId: "",
          designation: "",
          from: null,
          to: "",
          reasonForJobChange: "",
          reasonForProfessionalGap: "",
          technologies: [],
          totalExperience: 0,
        },
      ],
      workDetails: {
        joiningDate: null,
        reJoiningDate: null,
        productionDate: null,
        commitmentStartDate: null,
        technologies: [],
        departmentId: "",
        designationId: "",
        teamLead: "",
        projectManager: "",
        bondDuration: "",
        noticePeriod: 2,
        resignationDate: null,
        resignationAcceptanceDate: null,
        relievingDate: null,
        appraisalCycle: "July",
        employeeType: "",
        currentCTC: "",
        teamId: null,
      },
      checklists: {
        isPhotoProof: 0,
        isResidentialAddress: 0,
        isBachelorCertificates: 0,
        isPassportSizePhotos: 0,
        isServiceAggrement: 0,
        isMasterCertificates: 0,
        isLast3MonthsSalarySlip: 0,
        isExpAndRelLetter: 0,
      },
      documents: {
        aadharCardNumber: "",
        panCardNumber: "",
        bankName: "Union Bank of India",
        bankAccountNumber: "",
        IFSCCode: "",
        bankBranch: "",
        // passportNumber: '',
        // passportExpiryDate: '',
        // passportFrontPageImage: '',
        // passportLastPageImage: '',
      },
      pf: {
        isFlag: false,
        underPF: false, //
        underESIC: false, //
        name: "", //
        presentAddress: "", //
        permanentAddress: "", //
        fatherName: "", //
        fatherDOB: "", //
        motherName: "", //
        motherDOB: "", //
        dob: "", //
        // gender: '',
        maritalStatus: "", //
        email: "", //
        mobileNumber: "", //
        // earlierMemberOfEPF1952: '',
        // earlierMemberOfEPension1995: '',
        UAN: "", //
        // previousPFNumber: '',
        // dateExitFromPreviosDevelopment: null,
        // schemeCertificateNumber: '',
        // pensionPaymentOrderNumber: '',
        // internationalWorker: '',
        // country: '',
        passportNumber: "", //
        passportValidityTo: null, //
        // passportValidityFrom: null,
        // passportValidityTo: null,
        bankAccountNumber: "", //
        IFSC: "", //
        aadharNumber: "", //
        panNumber: "",
        bankName: "", //
        husbandName: "", //
        husbandDOB: null, //
        electionCardNumber: "", //
        licenseNumber: "", //
        licenseValidityTo: null, //
        rationCardNumber: "", //
        esicNumber: "",
        memberId: "",
        removePFReason: "",
        removePFDate: null,
        removePFNote: "",
        removeESICDate: null,
        removeESICNote: "",
      },
      serviceAgreement: {
        isUnderServiceAgreement: false,
        type: [],
        isCheque: false,
        isRetention: false,
        bankName: "",
        bankAccountNumber: "",
        chequeAmount: "",
        IFSC: "",
        bankBranch: "",
        noOfCheque: "",
        chequeNumbers: "",
      },
      departments: [],
      toBeDeletedPresentAddress: null,
      toBeDeletedPermanentAddress: null,
      toBeDeletedBachelorCertificates: [],
      toBeDeletedMasterCertificates: [],
      toBeDeletedEmployeeResume: null,
    },
    validationSchema: employeeSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        values.firstName = values?.firstName.trim();
        values.lastName = values?.lastName.trim();
        values.vehicleData = values?.vehicleData?.map((e) => ({
          ...e,
          vehicleName: e?.vehicleName?.trim(),
          vehicleNumber: e?.vehicleNumber?.trim(),
        }));

        let newDataVehicle = [];
        values?.vehicleData?.map((e) => {
          let vehicle = {};
          let vehicleName = e?.vehicleName?.trim();
          let vehicleNumber = e?.vehicleNumber?.trim();
          let remarks = e?.remarks?.trim();
          let vehicleType = e?.vehicleType?.trim();
          let vehicleId = e?.id

          if (vehicleName) {
            vehicle = { ...vehicle, vehicleName };
          }
          if (vehicleNumber) {
            vehicle = { ...vehicle, vehicleNumber };
          }
          if (remarks) {
            vehicle = { ...vehicle, remarks };
          }
          if (vehicleType) {
            vehicle = { ...vehicle, vehicleType };
          }
          if (vehicleName && vehicleNumber && vehicleType && id) {
            vehicle = { ...vehicle, id: vehicleId };
          }

		  if(Object.keys(vehicle).length)  newDataVehicle?.push(vehicle);
         
        });
        values.vehicleData = newDataVehicle;

        if (!checkIsCar(values?.vehicleData)) {
          values.parkingBlock = "";
          values.parkingNumber = "";
        }

        values.password = values?.password ? values?.password : null;

        values.checklists.isPhotoProof =
          !values.checklists?.isPhotoProof || values?.checklists?.isPhotoProof === null ? 0 : 1;
        values.checklists.isResidentialAddress =
          !values?.checklists?.isResidentialAddress || values?.checklists?.isResidentialAddress === null ? 0 : 1;
        values.checklists.isBachelorCertificates =
          !values?.checklists?.isBachelorCertificates || values?.checklists?.isBachelorCertificates === null ? 0 : 1;
        values.checklists.isPassportSizePhotos =
          !values?.checklists?.isPassportSizePhotos || values?.checklists?.isPassportSizePhotos === null ? 0 : 1;
        values.checklists.isServiceAggrement =
          !values?.checklists?.isServiceAggrement || values?.checklists?.isServiceAggrement === null ? 0 : 1;
        values.checklists.isMasterCertificates =
          !values?.checklists?.isMasterCertificates || values?.checklists?.isMasterCertificates === null ? 0 : 1;
        values.checklists.isLast3MonthsSalarySlip =
          !values?.checklists?.isLast3MonthsSalarySlip || values?.checklists?.isLast3MonthsSalarySlip === null ? 0 : 1;
        values.checklists.isExpAndRelLetter =
          !values?.checklists?.isExpAndRelLetter || values?.checklists?.isExpAndRelLetter === null ? 0 : 1;
        if (values.workLocation === 1) {
          values.seatNumber = "";
          setFieldValue("seatNumber", "");
        }
        const formData = new FormData();
        // values.dateOfBirth = moment(values.dateOfBirth).format(
        // 	"YYYY-MM-DD ",
        // );

        if (!values?.pf?.underPF) {
          for (const key in values.pf) {
            if (
              key !== "underPF" &&
              key !== "underESIC" &&
              key !== "id" &&
              key !== "removePFReason" &&
              key !== "removePFDate" &&
              key !== "removePFNote"
            ) {
              values.pf[key] = null;
            }
          }
        }

        if (values?.workDetails?.teamLead === "") {
          values.workDetails.teamLead = null;
        }
        // if(values.github==null){
        // 	values.github = null;
        // }
        // if(values.github==null){
        // 	values.github = null;
        // }
        if (values?.workDetails?.projectManager === "") {
          values.workDetails.projectManager = null;
        }

        if (values?.workDetails?.bondDuration === "0") {
          values.workDetails.commitmentStartDate = null;
        }

        for (const key in values) {
          if (key !== "bachelorCertificates" && key !== "masterCertificates") {
            formData.append(key, values[key]);
          }
        }

        //check if additional coure is empty
        values.additionalCourse = values.additionalCourse.filter(
          (addCou) => addCou.courseName && addCou.instituteName && addCou.certificateUrl && addCou.skills
        );

        if (!values.serviceAgreement?.isCheque) {
          values.serviceAgreement.bankName = null;
          values.serviceAgreement.IFSC = null;
          values.serviceAgreement.bankBranch = null;
          values.serviceAgreement.bankAccountNumber = null;
          values.serviceAgreement.chequeAmount = null;
          values.serviceAgreement.noOfCheque = null;
          values.serviceAgreement.chequeNumbers = null;
        }

        // if (!values.serviceAgreement?.isRetention) {
        //   values.serviceAgreement.retentionHistoryDetails.retentionAmount = null;
        // }

        delete values.serviceAgreement.type;
        formData.set("contact", JSON.stringify(values.contact));
        formData.set("address", JSON.stringify(values.address));
        formData.set("employeeVehicles", JSON.stringify(values.vehicleData));
        formData.set("education", JSON.stringify(values.education));
        formData.set("additionalCourse", JSON.stringify(values.additionalCourse));
        formData.set("experience", JSON.stringify(values.experience));
        formData.set("workDetails", JSON.stringify(values.workDetails));
        formData.set("checklists", JSON.stringify(values.checklists));
        formData.set("documents", JSON.stringify(values.documents));
        formData.set("pf", JSON.stringify(values.pf));
        formData.set("serviceAgreement", JSON.stringify(values.serviceAgreement));
        formData.set("departments", JSON.stringify(values.departments));
        // formData.set("checklists", JSON.stringify(values.checklists));
        formData.set("isActive", true);
        formData.set("emails", JSON.stringify(values.emails));
        formData.set("toBeDeletedBachelorCertificates", JSON.stringify(values.toBeDeletedBachelorCertificates));
        formData.set("toBeDeletedMasterCertificates", JSON.stringify(values.toBeDeletedMasterCertificates));

        if (values.bachelorCertificates?.length > 0) {
          values.bachelorCertificates.forEach((file, idx) => {
            formData.append("bachelorCertificates", file);
          });
        } else {
          formData.append("bachelorCertificates", null);
        }

        if (values.masterCertificates?.length > 0) {
          values.masterCertificates.forEach((file, idx) => {
            formData.append("masterCertificates", file);
          });
        } else {
          formData.append("masterCertificates", null);
        }

        let res;

        if (!id && !relievingDateValidation.isWeekend && !relievingDateValidation.isHoliday) {
          res = await dispatch(insertEmployee(formData)).unwrap();
        }

        if (id && !relievingDateValidation.isWeekend && !relievingDateValidation.isHoliday){
          formData.set("id", id);
          res = await dispatch(updateEmployee({ values: formData, id })).unwrap();
        }

        if (res?.status === 200) {
          navigate(`/employee/view/${encryption(id)}`);
        }
      } catch (error) {
        if (values.serviceAgreement && values.serviceAgreement.isCheque && values.serviceAgreement.isRetention) {
          setFieldValue("serviceAgreement.type", ["CHEQUE", "RETENTION"]);
        }

        if (values.serviceAgreement && values.serviceAgreement.isCheque && !values.serviceAgreement.isRetention) {
          setFieldValue("serviceAgreement.type", ["CHEQUE"]);
        }

        if (values.serviceAgreement && !values.serviceAgreement.isCheque && values.serviceAgreement.isRetention) {
          setFieldValue("serviceAgreement.type", ["RETENTION"]);
        }
        toast.error(error.message);
        // error.status!==400&&toast.error(error.message);
      }
    },
  });

  const {
    handleSubmit,
    isSubmitting,
    getFieldProps,
  values,
    setFieldValue,
    handleChange,
    errors,
    setErrors,
    setValues,
    touched,
    handleBlur,
  } = formik;

  const getPopulatedValues = (underPF) => {
    let pf = {
      name: "",
      dob: "",
      presentAddress: ``,
      permanentAddress: ``,
      email: "",
      mobileNumber: "",
      maritalStatus: "",
      bankName: "",
      accountNumber: "",
      aadharNumber: "",
      panNumber: "",
      IFSC: "",
      bankBranch: "",
      removePFReason: "",
      removePFDate: null,
      removePFNote: "",
    };

    const getPFEmail = () => {
      let personalEmail = values.emails.filter(
        (email) => email.type === "Personal Email"
      );
      return personalEmail.length > 0
        ? personalEmail[0].email
        : values.emails
        ? [0].email
        : "";
    };
    if (underPF) {
      pf = {
        name: concateName(values.firstName, values.middleName, values.lastName),
        dob: values.dateOfBirth,
        presentAddress: concateAddress(
          values.address?.presentStreetLine1,
          values.address?.presentStreetLine2,
          values.address?.presentArea,
          values.address?.presentCity,
          values.address?.presentState,
          values.address?.presentZipCode
        ),
        permanentAddress: concateAddress(
          values.address?.presentStreetLine1,
          values.address?.permenentStreetLine1,
          values.address?.permenentArea,
          values.address?.permenentCity,
          values.address?.permenentState,
          values.address?.permenentZipCode
        ),
        email: values.pf.email || getPFEmail(),
        mobileNumber: values.contact.contactNumber,
        maritalStatus: values.maritalStatus,
        bankName: values.documents?.bankName || "",
        bankAccountNumber: values.documents?.bankAccountNumber || "",
        aadharNumber: values.documents?.aadharCardNumber || "",
        panNumber: values.documents?.panCardNumber || "",
        IFSC: values.documents?.IFSCCode || "",
        bankBranch: values.documents?.bankBranch || "",
        removePFDate: values.pf.removePFDate || null,
        removePFNote: values.pf.removePFNote || "",
        removePFReason: values.pf.removePFReason || "",
      };
    }

    if (!underPF) {
      pf.underESIC = false;
    }

    return setFieldValue("pf", { ...values.pf, ...pf, underPF });
  };

  const hanldeService = (e) => {
    if (!e.target.checked) {
      setFieldValue("serviceAgreement.type", "");
    }
    setFieldValue("serviceAgreement.isUnderServiceAgreement", e.target.checked);
  };

  const handlePFChange = async (e) => {
    // console.log(pf)

    if (!id) {
      getPopulatedValues(e.target.checked);
    } else if (id && pf == false) {
      getPopulatedValues(e.target.checked);
    } else if (id && pf == true && AuthRole !== "Super Admin") {
      return;
    } else if (AuthRole === "Super Admin" && e.target.checked == false) {
      getPopulatedValues(e.target.checked);
    } else if (AuthRole === "Super Admin" && e.target.checked == true) {
      getPopulatedValues(e.target.checked);
    }
    getPopulatedValues(e.target.checked);
  };

  // const handlePFCheckedChange = async (e) => {

  // 	if(values?.pf?.underPF == true)
  // 	{
  // 		await confirm({
  // 			description: `Are you sure you want to remove PF Details ?`,
  // 		});
  // 		await getPopulatedValues(!e.target.checked);
  // 	}
  // 	else{
  // 		getPopulatedValues(e.target.checked);
  // 	}
  // };
  const generatePfPdf = async () => {
    try {
      const { fileName = "default" } = await NiceModal.show(ConfirmModal, {
        name: values.pf.name,
      });

      const body = {
        name: values?.pf?.name,
        uan: values?.pf?.UAN ?? "",
        memberId: values?.pf?.memberId ?? "",
        esicNumber: values?.pf?.esicNumber ?? "",
        dob: indianDateFormat(values?.pf?.dob),
        contact: values?.contact?.contactNumber,
        present: values?.pf?.presentAddress,
        permanent: values?.pf?.permanentAddress,
        email: values?.pf?.email,
        maritalStatus:
          values?.pf?.maritalStatus[0].toUpperCase() +
          values?.pf?.maritalStatus.substring(1),
        fName: values?.pf?.fatherName,
        fDOB: indianDateFormat(values?.pf?.fatherDOB),
        mName: values?.pf?.motherName,
        mDOB: indianDateFormat(values?.pf?.motherDOB),
        bankName: values?.pf?.bankName,
        bankACNumber: values?.pf?.bankAccountNumber,
        aadhar: values?.pf?.aadharNumber,
        pan: values?.pf?.panNumber ?? "",
        ifsc: values?.pf?.IFSC,
        hName: values?.pf?.husbandName ?? "",
        hDOB: indianDateFormat(values?.pf?.husbandDOB) ?? "",
        eCardNumber: values?.pf?.electionCardNumber ?? "",
        licenceNumber: values?.pf?.licenseNumber ?? "",
        licenceExp: indianDateFormat(values?.pf?.licenseValidityTo) ?? "",
        rationCardNumber: values?.pf?.rationCardNumber ?? "",
        passportNumber: values?.pf?.passportNumber ?? "",
        passportExp: indianDateFormat(values?.pf?.passportValidityTo) ?? "",
      };

      await Axios.post(
        `${BASE_URL}/employee-pf-pdf`,
        body,
        {
          responseType: "blob",
          headers: {
            type: "view",
            module: "employee",
          },
        } // important
      ).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${fileName.trim().split(" ").join("_")}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
    } catch (error) {}
  };

  const mapSkills = (skills) => {
    let technologyIds = [];

    skills.map((skill, idx) => {
      technologyIds = skill.technologies.map((tech) => {
        return tech.technologyId;
      });
      return setFieldValue(`additionalCourse[${idx}].skills`, technologyIds);
    });
  };

  const mapTechnologyIds = (experienece) => {
    let technologyIds = [];

    experienece.map((exp, idx) => {
      technologyIds = exp.technologies.map((tech) => {
        return tech.technologyId;
      });
      return setFieldValue(`experience[${idx}].technologies`, technologyIds);
    });
  };

  const mapWorkTechnologies = (technologies) => {
    if (!technologies) {
      return setFieldValue("workDetails.technologies", []);
    }

    return setFieldValue(
      "workDetails.technologies",
      technologies.map((tech) => tech.technologyId)
    );
  };

  const mapDepartments = (departmentsData) => {
    const departments = departmentsData.map((deptms) => deptms.departmentId);
    return setFieldValue("departments", departments);
  };

  //for present
  const handleState = async (event, newValue) => {
    const state = newValue;

    setFieldValue("address.presentState", state);

    if (sameAsCurrent) {
      setFieldValue("address.permenentState", state);
    }

    try {
      const cityData = await getCityData(state);
      setCities(cityData);
      if (sameAsCurrent) setpermanentCities(cityData);
    } catch (error) {
      setCities([]);
    }
  };

  const handleCity = async (event, newValue) => {
    const city = newValue;
    setFieldValue("address.presentArea", "");
    setFieldValue("address.presentCity", city);

    if (sameAsCurrent) {
      setFieldValue("address.permenentCity", city);
      //setFieldValue("address.presentArea", "");
    }

    try {
      const areaData = await getAreaData(city);
      setArea(areaData);
      if (sameAsCurrent) setpermanentAreas(areaData);
    } catch (error) {
      setArea([]);
    }
  };

  const handleArea = async (event, newValue) => {
    try {
      const area = newValue;

      const postalCode = await getPostalCode({
        area,
        city: values.address.presentCity,
      });

      setFieldValue("address.presentArea", area);
      setFieldValue("address.presentZipCode", postalCode);

      if (sameAsCurrent) {
        setFieldValue("address.permenentArea", area);
        setFieldValue("address.permenentZipCode", postalCode);
      }
    } catch (error) {}
  };

  //for permanent
  const handlePermanentState = async (event, newValue) => {
    const state = newValue || event.target.value;
    setFieldValue("address.permenentCity", "");
    setFieldValue("address.permenentArea", "");
    setFieldValue("address.permenentState", state);

    try {
      const cityData = await getCityData(state);
      setpermanentCities(cityData);
    } catch (error) {
      setCities([]);
    }
  };

  const handlePermanentCity = async (event, newValue) => {
    const city = newValue || event.target.value;
    setFieldValue("address.permenentArea", "");
    setFieldValue("address.permenentCity", city);
    try {
      const areaData = await getAreaData(city);
      setpermanentAreas(areaData);
    } catch (error) {
      setArea([]);
    }
  };

  const handlePermanentArea = async (event, newValue) => {
    try {
      const area = newValue ? event.target.value : newValue;
      const postalCode = await getPostalCode({
        area,
        city: values.address.permenentCity,
      });

      setFieldValue("address.permenentArea", area);
      setFieldValue("address.permenentZipCode", postalCode);
    } catch (error) {}
  };

  const getImageUrl = (file) => {
    if (file === null || file === undefined) {
      return null;
    }

    if (
      file &&
      file["type"] &&
      file["type"].split("/")[0] === "image" &&
      !file.webViewLink
    ) {
      return window.URL.createObjectURL(file);
    }

    return file.webViewLink;
  };

  const handlePresentCityWhileEdit = (state) => {
    getCityData(state)
      .then((cities) => {
        setCities(cities);
      })
      .catch((err) => {
        setCities([]);
      });
  };

  const handlePermenetCityWhileEdit = (state) => {
    getCityData(state)
      .then((cities) => {
        setpermanentCities(cities);
      })
      .catch((err) => {
        setpermanentCities([]);
      });
  };

  const handlePresentAreaWhileEdit = async (city) => {
    try {
      const areaData = await getAreaData(city);
      setArea(areaData);
    } catch (error) {
      setArea([]);
    }
  };

  const handlePermanentAreaWhileEdit = async (city) => {
    try {
      const areaData = await getAreaData(city);
      setpermanentAreas(areaData);
    } catch (error) {
      setArea([]);
    }
  };

  useEffect(() => {
    if (
      !educationData.complete &&
      !educationData.loading &&
      !educationData.error &&
      (!educationData.data ||
        (Array.isArray(educationData.data) && educationData.data.length <= 0))
    )
      dispatch(getEducation({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !technologyData.complete &&
      !technologyData.loading &&
      !technologyData.error &&
      (!technologyData.data ||
        (Array.isArray(technologyData.data) && technologyData.data.length <= 0))
    )
      // dispatch(getTechnology());
      dispatch(getTechnology({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !courseData.complete &&
      !courseData.loading &&
      !courseData.error &&
      (!courseData.data ||
        (Array.isArray(courseData.data) && courseData.data.length <= 0))
    )
      dispatch(getCourse({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    // console.log(getViewVisible("college"),"pdppd");
    if (collegeStatus === "idle") {
      getViewVisible("college") &&
        dispatch(getCollege({ sortBy: "", orderBy: "" }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      !designationData.complete &&
      !designationData.loading &&
      !designationData.error &&
      (!designationData.data ||
        (Array.isArray(designationData.data) &&
          designationData.data.length <= 0))
    )
      dispatch(getDesignation({ sortBy: "", orderBy: "" }));
  });

  useEffect(()=>{
    return(()=>{
      dispatch(setResetRelievingDateValidation())
    })
  },[])

  useEffect(() => {
    if (companyData.statusForDropDown === "idle")
      getViewVisible("company") &&
        dispatch(getCompany({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  useEffect(() => {
    if (
      !jobChangeData.complete &&
      !jobChangeData.loading &&
      !jobChangeData.error &&
      (!jobChangeData.data ||
        (Array.isArray(jobChangeData.data) && jobChangeData.data.length <= 0))
    )
      dispatch(getJobChange({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !departmentData.complete &&
      !departmentData.loading &&
      !departmentData.error &&
      (!departmentData.data ||
        (Array.isArray(departmentData.data) && departmentData.data.length <= 0))
    )
      getViewVisible("department") &&
        dispatch(getDepartment({ sortBy: "", orderBy: "" }));
  });

  useEffect(() => {
    if (
      !skillData.complete &&
      !skillData.loading &&
      !skillData.error &&
      (!skillData.data ||
        (Array.isArray(skillData.data) && skillData.data.length <= 0))
    )
      dispatch(getSkill({ sortBy: "", orderBy: "" }));
  }, [dispatch]);

  const getFileName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }

    return file.name?.length > 10
      ? file.name?.substr(0, 10) + "..."
      : file.name;
  };

  const getCertificateName = (file) => {
    if (!file) {
      return "";
    }

    if (file && !file.name) {
      const splittedFile = file.split("/");
      return splittedFile[splittedFile.length - 1];
    }

    let CertificateName = file.name;

    if (file.name.includes("Certificate_")) {
      CertificateName = file.name
        .split("Certificate_")[1]
        .split(".")[0]
        .split("_")
        .join(" ");
    }

    return CertificateName?.length > 10
      ? CertificateName?.substr(0, 10) + "..."
      : CertificateName;
  };

  const checkIsCar = (data) => {
    let isCar = false;

    data?.map((ele) => {
      if (ele?.vehicleType === "CAR") {
        isCar = true;
      }
    });

    return isCar;
  };

  const checkIfImage = (file) => {
    if (file && file.name) {
      const imageTypes = ["jpeg", "jpg", "png"];
      return imageTypes.includes(file.name.split(".")[1]);
    }
    return file && file["type"] && file["type"].split("/")[0] === "image";
  };

  const setEmployeeData = async (employee) => {
    let workLocation = locationDropdown.find(
      (location) => location.title === employee.workLocation
    );
    let employeeData = { ...employee, workLocation: workLocation?.id }; //, password: employee?.password !== null ? decryption(employee?.password) : null};
    console.log("employeeData",employeeData)
    setValues(employeeData);
    mapSkills(employee.additionalCourse);
    mapTechnologyIds(employee.experience);
    mapWorkTechnologies(employee.workDetails?.technologies ?? null);
    mapDepartments(employee.departments);
    setSelectedFileUrl(`${BASE_URL}/${employee.employeeImage}`);
    setFieldValue("parkingNumber", employee?.parkingNumber || "");
    setFieldValue("parkingBlock", employee?.parkingBlock || "");
    if (employee?.employeeVehicles?.length > 0) {
      setFieldValue("vehicleData", employee?.employeeVehicles);
    } else {
      setFieldValue("vehicleData", [
        {
          vehicleType: "",
          vehicleName: "",
          vehicleNumber: "",
          remarks: "",
        },
      ]);
    }
    if (Array.isArray(employee.emails) && employee.emails?.length === 0) {
      setFieldValue("emails", [{ type: "", email: "" }]);
    }
    if (!employee.workDetails) {
      setFieldValue("workDetails", {
        joiningDate: null,
        reJoiningDate: null,
        productionDate: null,
        technologies: [],
        departmentId: "",
        designation: "",
        teamLead: "",
        projectManager: "",
        bondDuration: "",
        noticePeriod: 2,
        resignationDate: null,
        resignationAcceptanceDate: null,
        relievingDate: null,
        appraisalCycle: "july",
        employeeType: "",
        currentCTC: "",
      });
    }
    if (!employee.experience) {
      setFieldValue("experience", [
        {
          companyId: "",
          designationId: "",
          from: null,
          to: "",
          reasonForJobChange: "",
          reasonForProfessionalGap: "",
          technologies: [],
          totalExperience: 0,
        },
      ]);
    }
    if (!employee.checklists) {
      setFieldValue("checklists", {
        isPhotoProof: 0,
        isResidentialAddress: 0,
        isBachelorCertificates: 0,
        isPassportSizePhotos: 0,
        isServiceAggrement: 0,
        isMasterCertificates: 0,
        isLast3MonthsSalarySlip: 0,
        isExpAndRelLetter: 0,
      });
      setFieldValue("presentAddressImage", null);
      setFieldValue("permanentAddressImage", null);
      setFieldValue("bachelorCertificates", null);
      setFieldValue("masterCertificates", null);
    }
    if (!employee.address) {
      setFieldValue("address", {
        presentStreetLine1: "",
        presentStreetLine2: "",
        presentCountry: "India",
        presentState: "Gujarat",
        presentCity: "",
        presentArea: "",
        presentZipCode: "",
        permenentStreetLine1: "",
        permenentStreetLine2: "",
        permenentCountry: "India",
        permenentState: "Gujarat",
        permenentCity: "",
        permenentArea: "",
        permenentZipCode: "",
      });
    }
    if (!employee.pf) {
      setFieldValue("pf", {
        underPF: false, //
        underESIC: false, //
        name: "", //
        presentAddress: "", //
        permanentAddress: "", //
        fatherName: "", //
        fatherDOB: null, //
        motherName: "", //
        motherDOB: null, //
        dob: null, //
        // gender: '',
        maritalStatus: "", //
        email: "", //
        mobileNumber: "", //
        // earlierMemberOfEPF1952: '',
        // earlierMemberOfEPension1995: '',
        UAN: "", //
        // previousPFNumber: '',
        // dateExitFromPreviosDevelopment: null,
        // schemeCertificateNumber: '',
        // pensionPaymentOrderNumber: '',
        // internationalWorker: '',
        // country: '',
        passportNumber: "", //
        passportValidityTo: null, //
        // passportValidityFrom: null,
        // passportValidityTo: null,
        bankAccountNumber: "", //
        IFSC: "", //
        aadharNumber: "", //
        panNumber: "",
        bankName: "", //
        husbandName: "", //
        husbandDOB: null, //
        electionCardNumber: "", //
        licenseNumber: "", //
        licenseValidityTo: null, //
        rationCardNumber: "", //
        removePFReason: "",
        removePFDate: null,
        removePFNote: "",
        removeESICDate: null,
        removeESICNote: "",
      });
    }

    await handlePresentCityWhileEdit(
      employee.address?.presentState || "Gujarat"
    );
    await handlePermenetCityWhileEdit(
      employee.address?.permenentState || "Gujarat"
    );
    await handlePresentAreaWhileEdit(
      employee.address?.presentCity || "Ahmedabad"
    );
    await handlePermanentAreaWhileEdit(
      employee.address?.permenentCity || "Ahmedabad"
    );
    setFieldValue(
      "panCardImage",
      employee.documents?.panCardImage
        ? employee.documents?.panCardImage[0]
        : ""
    );
    setFieldValue(
      "aadharCardImage",
      employee.documents?.aadharCardImage
        ? employee.documents?.aadharCardImage[0]
        : ""
    );
    setFieldValue(
      "bachelorCertificates",
      employee.checklists?.bachelorCertificates || []
    );
    setFieldValue(
      "masterCertificates",
      employee.checklists?.masterCertificates || []
    );
    setFieldValue("serviceAgreement", employee?.serviceAgreement ?? {});
    setFieldValue(
      "presentAddressImage",
      employee.checklists?.residentialAddress?.present
        ? employee.checklists?.residentialAddress?.present[0]
        : ""
    );
    setFieldValue(
      "permanentAddressImage",
      employee.checklists?.residentialAddress?.permanent
        ? employee.checklists?.residentialAddress?.permanent[0]
        : ""
    );
    setFieldValue(
      "employeeResume",
      employee.documents?.employeeResume
        ? employee.documents?.employeeResume[0]
        : ""
    );
    setFieldValue("toBeDeletedBachelorCertificates", []);
    setFieldValue("toBeDeletedMasterCertificates", []);

    const tempBackLink = employee
      ? employee?.status === "employee"
        ? moment(employee.workDetails.joiningDate).isAfter(new Date())
          ? "employee/joining-employee"
          : "employee"
        : "employee/ex-employee"
      : "employee";
    setBackLink(tempBackLink);

    if (employee.id && employee.address) {
      setSameAsCurrent(generateObject(employee.address));
      setFieldValue("address.sameAsCurrent", generateObject(employee.address));
    }

    if (
      employee.serviceAgreement &&
      employee.serviceAgreement.isCheque &&
      employee.serviceAgreement.isRetention
    ) {
      setFieldValue("serviceAgreement.type", ["CHEQUE", "RETENTION"]);
    }

    if (
      employee.serviceAgreement &&
      employee.serviceAgreement.isCheque &&
      !employee.serviceAgreement.isRetention
    ) {
      setFieldValue("serviceAgreement.type", ["CHEQUE"]);
    }

    if (
      employee.serviceAgreement &&
      !employee.serviceAgreement.isCheque &&
      employee.serviceAgreement.isRetention
    ) {
      setFieldValue("serviceAgreement.type", ["RETENTION"]);
    }
  };

  const getEmployeeData = (id) => {
    dispatch(getEmployeeById(id))
      .unwrap()
      .then(async (res) => {
        console.log("RESSSS",res.data)
        await setEmployeeData(res?.data);
        setLoading(false);
        setPf(res?.data?.pf ? res?.data?.pf?.underPF : false);
        setESIC(res?.data?.pf ? res?.data?.pf?.underESIC : false);
      })
      .catch((err) => {
        toast.error(err.message);
        return navigate("/employee");
      });
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getEmployeeData(id);
    }
  }, [dispatch]);

  useEffect(() => {
    getStateData()
      .then((states) => {
        setStates(states);
        setpermanentStates(states);
      })
      .catch((err) => {
        setStates([]);
      });
  }, [dispatch]);

  useEffect(() => {
    getCityData("Gujarat")
      .then((cities) => {
        setCities(cities);
        setpermanentCities(cities);
      })
      .catch((err) => {
        setCities([]);
      });
  }, [dispatch]);

  useEffect(() => {
    getAreaData("Ahmedabad")
      .then((areas) => {
        setArea(areas);
        setpermanentAreas(areas);
      })
      .catch((err) => {
        setCities([]);
      });
  }, [dispatch]);

  const handleTabChange = async (value) => {
    /**
     * code to manually validate the form on next button
     * const errors = await validateForm();
     * setTouched(setNestedObjectValues(errors, true));
     * setErrors(errors);
     */
    setValue(value);
  };

  if (loading) {
    return <Loader></Loader>;
  }

  return (
    <Container className="editemployee-container" maxWidth="xl">
      <form
        onSubmit={(...args) => {
          setSubmitted(true);
          handleSubmit(...args);
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <div className="title-button-wrapper editemployee-title-wrapper">
            <Typography variant="h4" gutterBottom>
              {id ? ` ${values.firstName} ${values.lastName}` : "Add Employee"}
            </Typography>

            <Stack
              className="employee-header-right"
              direction="row"
              alignItems="center"
            >
              <Button
                className="back-button"
                component={RouterLink}
                to={`/${backlink || "employee"}`}
                variant="contained"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
              <LoadingButton
                loading={isSubmitting}
                variant="contained"
                type="submit"
                sx={{ marginRight: "15px" }}
              >
                Save
              </LoadingButton>
            </Stack>
          </div>
        </Stack>
        <Box className="editemployee_content-box" sx={{ width: "100%" }}>
          <TabContext value={value}>
            <Grid container>
              <Grid item xs={3}>
                <Box>
                  <Card className="left-heading-part">
                    <TabList
                      sx={{
                        borderRight: 1,
                        borderColor: "divider",
                      }}
                      // onChange={handleChange}
                      orientation="vertical"
                      className="tab-list-part sidebar-tab"
                      onChange={(e, v) => handleTabChange(v)}
                    >
                      <Tab
                        label="Personal"
                        value="1"
                        direction="row"
                        // icon={
                        // 	submitted &&
                        // 	checkPersonalDetails(errors) && (
                        // 		<ErrorOutlineIcon color="error" />
                        // 	)
                        // }
                        {...(submitted &&
                          checkPersonalDetails(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Contact"
                        value="2"
                        direction="row"
                        // icon={
                        // 	submitted &&
                        // 	checkContactDetails(errors) && (
                        // 		<ErrorOutlineIcon color="error" />
                        // 	)
                        // }
                        {...(submitted &&
                          checkContactDetails(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Address"
                        value="3"
                        direction="row"
                        // icon={
                        // 	submitted &&
                        // 	checkAddressDetails(errors) && (
                        // 		<ErrorOutlineIcon color="error" />
                        // 	)
                        // }
                        {...(submitted &&
                          checkAddressDetails(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Education"
                        value="4"
                        direction="row"
                        // icon={
                        // 	submitted &&
                        // 	checkEducationDetails(errors) && (
                        // 		<ErrorOutlineIcon color="error" />
                        // 	)
                        // }
                        {...(submitted &&
                          (checkEducationDetails(errors) ||
                            checkAdditionalDetails(errors)) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Work/Experience"
                        value="5"
                        direction="row"
                        {...(submitted &&
                          checkWorDetailsError(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="PF/ESIC"
                        value="6"
                        direction="row"
                        // icon={
                        // 	submitted &&
                        // 	checkPersonalDetails(errors) && (
                        // 		<ErrorOutlineIcon color="error" />
                        // 	)
                        // }
                        {...(submitted &&
                          checkPfErrors(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        iconPosition="end"
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      />
                      <Tab
                        label="Service Agreement"
                        value="7"
                        {...(submitted &&
                          checkServiceAgreementError(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        iconPosition="end"
                      />
                      <Tab
                        label="Check List"
                        value="8"
                        direction="row"
                        {...(submitted &&
                          checkChecklistsError(errors) && {
                            icon: <ErrorOutlineIcon color="error" />,
                          })}
                        sx={{
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        iconPosition="end"
                      />
                    </TabList>
                  </Card>
                </Box>
              </Grid>
              <Grid item xs={9}>
                {/* Personal component start here */}
                <TabPanel className="editemployee_content-tabpanel" value="1">
                  <Personal
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    getFieldProps={getFieldProps}
                    handleTabChange={handleTabChange}
                    setFieldValue={setFieldValue}
                    helperText={errors}
                    selectedFile={selectedFile}
                    setSelectedFile={setSelectedFile}
                    selectedFileUrl={selectedFileUrl}
                    setSelectedFileUrl={setSelectedFileUrl}
                    adharCardFileUrl={adharCardFileUrl}
                    setAdharCardFileUrl={setAdharCardFileUrl}
                    setAdharCardSelectedFile={setAdharCardSelectedFile}
                    adharCardSelectedFile={adharCardSelectedFile}
                    panCardFileUrl={panCardFileUrl}
                    setPanCardFileUrl={setPanCardFileUrl}
                    setPanCardSelectedFile={setPanCardSelectedFile}
                    panCardSelectedFile={panCardSelectedFile}
                    getImageUrl={getImageUrl}
                    getFileName={getFileName}
                    checkIfImage={checkIfImage}
                    id={id}
                    handleBlur={handleBlur}
                  />
                </TabPanel>
                {/* Contact details part start here */}
                <TabPanel className="editemployee_content-tabpanel" value="2">
                  <Contact
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    handleTabChange={handleTabChange}
                    helperText={errors}
                    getFieldProps={getFieldProps}
                  />
                </TabPanel>
                {/* Address details part start here  */}
                <TabPanel className="editemployee_content-tabpanel" value="3">
                  <Address
                    values={values}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    handleTabChange={handleTabChange}
                    helperText={errors}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                    getAreaData={getAreaData}
                    getPostalCode={getPostalCode}
                    sameAsCurrent={sameAsCurrent}
                    setSameAsCurrent={setSameAsCurrent}
                    states={states}
                    setStates={setStates}
                    cities={cities}
                    setCities={setCities}
                    areas={areas}
                    setArea={setArea}
                    permanentStates={permanentStates}
                    setpermanentStates={setpermanentStates}
                    permanentCities={permanentCities}
                    setpermanentCities={setpermanentCities}
                    permanentAreas={permanentAreas}
                    setpermanentAreas={setpermanentAreas}
                    handleArea={handleArea}
                    handleCity={handleCity}
                    handleState={handleState}
                    handlePermanentArea={handlePermanentArea}
                    handlePermanentCity={handlePermanentCity}
                    handlePermanentState={handlePermanentState}
                    id={id}
                  />
                </TabPanel>
                {/* Education component start here */}
                <TabPanel className="editemployee_content-tabpanel" value="4">
                  <Education
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    setErrors={setErrors}
                    handleTabChange={handleTabChange}
                    getFieldProps={getFieldProps}
                    helperText={errors}
                    id={id}
                  />
                </TabPanel>
                {/* Experience component start here */}
                <TabPanel className="editemployee_content-tabpanel" value="5">
                  <Experience
                    values={values}
                    relievingDateValidations={relievingDateValidation}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    handleTabChange={handleTabChange}
                    getFieldProps={getFieldProps}
                    helperText={errors}
                    setTLandPM={setTLandPM}
                    TLandPM={TLandPM}
                    id={id}
                  />
                </TabPanel>
                {/* PF Component start here */}
                <TabPanel className="editemployee_content-tabpanel" value="6">
                  <PF
                    id={id}
                    values={values}
                    esic={esic}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    setErrors={setErrors}
                    handleTabChange={handleTabChange}
                    helperText={errors}
                    handleSubmit={handleSubmit}
                    isUnderPf={isUnderPf}
                    setUnderPF={setUnderPF}
                    getFieldProps={getFieldProps}
                    getPopulatedValues={getPopulatedValues}
                    handlePFChange={handlePFChange}
                    generatePfPdf={generatePfPdf}
                    pfError={pfError}
                    setPfError={setPfError}
                    esicError={esicError}
                    setEsicError={setEsicError}
                  />
                </TabPanel>
                {/* Service agreement start here */}
                <TabPanel className="editemployee_content-tabpanel" value="7">
                  <Service
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    getFieldProps={getFieldProps}
                    errors={errors}
                    handleTabChange={handleTabChange}
                    helperText={errors}
                    hanldeService={hanldeService}
                  />
                </TabPanel>
                {/* Chcek component  start here */}
                <TabPanel className="editemployee_content-tabpanel" value="8">
                  <CheckList
                    getImageUrl={getImageUrl}
                    values={values}
                    setFieldValue={setFieldValue}
                    handleChange={handleChange}
                    touched={touched}
                    errors={errors}
                    handleTabChange={handleTabChange}
                    helperText={errors}
                    getFieldProps={getFieldProps}
                    getFileName={getFileName}
                    getCertificateName={getCertificateName}
                    checkIfImage={checkIfImage}
                  />
                </TabPanel>
              </Grid>
            </Grid>
          </TabContext>

          <TabContext value={value}></TabContext>
        </Box>
      </form>
    </Container>
  );
}

const componentConfig = [
  {
    component: AddEmployee,
    path: "/employee/add",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
  {
    component: AddEmployee,
    path: "/employee/add/:id",
    public: false,
    layout: DashboardLayout,
    group: "organization",
    sidebar: true,
    role: ["admin"],
  },
];

export default componentConfig;
