import * as Yup from "yup";

export const workFlowSchema = Yup.object().shape({
  emailTemplateId: Yup.string()
    .required("Email template is required")
    .nullable(),
  candidateStateId: Yup.string()
    .required("Action is required")
    .nullable(),
});
