import { createSlice } from "@reduxjs/toolkit";
import {
  createparkingAllocation,
  getAllparkingAllocation,
  gethavecarEmployee,
} from "./parkingThunk";

const initialState = {
  loading: false,
  status: "idle",
  error: null,
  complete: false,
  data: [],
  freeSlot: [],
  carEmployee: [],
};

const parkcaronSlot = (data) => {
  const parkingBuilding = [
    {
      block: "B2",
      parkingNumbers: [
        {
          number: "ML170",
          free: true,
        },
        {
          number: "ML173",
          free: true,
        },
        {
          number: "ML174",
          free: true,
        },
      ],
    },
    {
      block: "B3",
      parkingNumbers: [
        {
          number: "LL157",
          free: true,
        },
        {
          number: "LL159",
          free: true,
        },
        {
          number: "LL179",
          free: true,
        },
        {
          number: "LL180",
          free: true,
        },
        {
          number: "LL181",
          free: true,
        },
        {
          number: "LL182",
          free: true,
        },
      ],
    },
  ];

  let allocatedPakring = parkingBuilding?.map((buildingSlot) => {
    let slots = buildingSlot?.parkingNumbers?.map((slot) => {
      let block = buildingSlot?.block;
      let number = slot?.number;
      let foundBookSlot = data?.find(
        (emp) => emp?.parkingNumber == number && emp?.parkingBlock == block
      );

      if (foundBookSlot) {
        return {
          ...slot,
          free: false,
          employeeName: `${foundBookSlot?.firstName} ${foundBookSlot?.lastName}`,
          employeeId: foundBookSlot?.employeeId,
          vehicleDetails: foundBookSlot?.employeeVehicles,
        };
      }
      return slot;
    });

    return { ...buildingSlot, parkingNumbers: slots };
  });

  return allocatedPakring;
};

const foundFreeSlot = (data) => {
  let bookedSlot = data?.map((s) => ({
    parkingBlock: s.parkingBlock,
    parkingNumber: s.parkingNumber,
  }));

  const blockList = ["B2", "B3"];
  let parkigSlot = {
    B2: ["ML170", "ML173", "ML174"],
    B3: ["LL157", "LL159", "LL179", "LL180", "LL181", "LL182"],
  };

  for (let key in parkigSlot) {
    let slot = key;
    let checkIsbook = bookedSlot?.find(
      (s) => s.parkingBlock == slot && parkigSlot[key].includes(s.parkingNumber)
    );

    if (checkIsbook) {
      parkigSlot[key] = parkigSlot[key]?.filter(
        (s) => s != checkIsbook?.parkingNumber
      );
    }
  }

  return parkigSlot;
};

const addAllocation = (data, employee) => {
  data = data?.map((slot) => {
  let slots = slot?.parkingNumbers?.map((num) => {
      if (num?.employeeId === employee?.employeeId) {
        return { number: num?.number, free: true };
      }
      return num;
    });
    return { ...slot, parkingNumbers: slots };
  });

  let allocatedPakring = data?.map((slot) => {
    if (slot?.block == employee?.parkingBlock) {
      let allSlots = slot?.parkingNumbers?.map((num) => {
        if (num?.number == employee?.parkingNumber) {
          return {
            ...num,
            free: false,
            employeeName: employee?.employeeName,
            employeeId: employee?.employeeId,
            vehicleDetails: employee?.employeeVehicles,
          };
        }
        return num;
      });
      return { ...slot, parkingNumbers: allSlots };
    }
    return slot;
  });

  return allocatedPakring;
};

const removeAllocation = (data, employee) => {
  let allocatedPakring = data?.map((slot) => {
    if (slot?.block == employee?.parkingBlock) {
      let allSlots = slot?.parkingNumbers?.map((num) => {
        if (num?.number == employee?.parkingNumber) {
          return {
            number: num?.number,
            free: true,
          };
        }
        return num;
      });
      return { ...slot, parkingNumbers: allSlots };
    }
    return slot;
  });
  return allocatedPakring;
};

// A slice for CandidateCount with our 3 reducers
export const parkingallocationSlice = createSlice({
  name: "parkingAllocation",
  initialState,
  reducers: {
    setAddparkingData: (state, action) => {
      let allData = JSON.parse(JSON.stringify(state?.data)) || [];
      state.data = addAllocation(allData, action?.payload);
    },
    setRemoveparkingData: (state, action) => {
      let allData = JSON.parse(JSON.stringify(state?.data)) || [];
      state.data = removeAllocation(allData, action?.payload);
    },
  },
  extraReducers: {
    [getAllparkingAllocation.pending]: (state) => {
      state.loading = true;
    },
    [getAllparkingAllocation.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.complete = true;
      state.data = parkcaronSlot(payload.data) || [];
      state.freeSlot = foundFreeSlot(payload.data);
    },
    [getAllparkingAllocation.rejected]: (state, payload) => {
      state.loading = false;
      state.error = payload;
      state.status = "rejected";
      state.complete = true;
    },

    [gethavecarEmployee.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.status = "fulfilled";
      state.complete = true;
      state.carEmployee = payload.data || [];
    },
    [gethavecarEmployee.rejected]: (state, payload) => {
      state.loading = false;
      state.error = payload;
      state.status = "rejected";
      state.complete = true;
      state.carEmployee = [];
    },

    [createparkingAllocation.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createparkingAllocation.fulfilled]: (state, { payload }) => {
      state.isSubmitting = false;
      state.status = "fulfilled";
      state.complete = true;
    },
    [createparkingAllocation.rejected]: (state) => {
      state.isSubmitting = false;
      state.status = "rejected";
      state.complete = true;
    },
  },
});

// The reducer
export const parkingAllocationReducer = parkingallocationSlice.reducer;
export const { setAddparkingData, setRemoveparkingData } =
  parkingallocationSlice.actions;
