import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import DashboardLayout from "../../../layouts/dashboard";
import Loader from "../../../components/Loader";

import Box from "@mui/material/Box";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import Table from "@mui/material/Table";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import TabContext from "@mui/lab/TabContext";

import { getAssetVMById } from "../../../redux/assetVM/assetVMThunk";
import { getEmployeeForDropDown } from "../../../redux/employee/employeeThunk";

import { encryption,decryption } from "../../../utils/encodeString";
import { getEditVisible } from "../../../utils/userPermission";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
};

function ViewVM() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const navigation = useNavigate();
	const [loading, setLoading] = useState(true);
	let { id } = useParams();
	if (id) id = decryption(id);
	const [vm, setVM] = useState({});

	const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

	const [fildname, setFildname] = useState('');
	
	const [verifyId, setVerifyId] = useState("");
  const [verifyIds, setVerifyIds] = useState([]);
  const [verifyDBIds, setVerifyDBIds] = useState([]);
  const [verifyDBId, setVerifyDBId] = useState("");

	const [showPassword, setShowPassword] = useState(false);
  const [showDBPassword, setshowDBPassword] = useState(false);
  const [showWebMinPassword, setshowWebMinPassword] = useState(false);

	const [accessPasswordKey, setAccessPasswordKey] = useState('');
	const [webMinPasswordKey, setWebminPasswordKey] = useState('');
	const [databasePasswordKey, setDatabasePasswordKey] = useState('');

	const employeeData = useSelector((state)=> state.employee.allEmployeeData);

	useEffect(() => {
		if(id===-1){
			navigation("/vm");
		}
	}, [dispatch,id]);

	useEffect(() => {
		const fetchById = async () => {
			try {
				let record = await dispatch(getAssetVMById(id)).unwrap();
				setVM(record);
			
			} catch (error) {
				navigation("/vm");
				//if there is no data then it gives error msg "No records found"
				if (error.status === 404 && !error.success) {
					toast.error(error.message);
					navigation("/vm");
				}
			} finally {
				setLoading(false);
			}
		};

		fetchById();
	}, []);

	useEffect(() => {
    dispatch(getEmployeeForDropDown({ status: "employee" }));
  }, [dispatch]);

	const handleShowPassword = (accessId) => {
		setFildname(`accessPassword`);
		setVerifyId(accessId);
		handleOpen();
};

const handleHidePassword = (accessId) => {
// setShowPassword(!showPassword);
let access = verifyIds.filter((e) => {
	return e != accessId
})
setVerifyIds(access)
};
const handleShowDBPassword = (dbId) => {
setFildname(`dbPassword`);
setVerifyDBId(dbId);
handleOpen();
};

const handleHideDBPassword = (dbId) => {
// setShowPassword(!showPassword);
let db = verifyDBIds.filter((e) => {
return e != dbId
})
setVerifyDBIds(db)
};

	const handleShowWebMinPassword = () => {
    // setshowWebMinPassword((show) => !show);
   
      if(showWebMinPassword===false){
        setFildname(`webMinPassword`);
        handleOpen();
      }
      else{
        setshowWebMinPassword(!showWebMinPassword);
     }
	};

	let handleVerify=()=>{
    if(fildname==='accessPassword'){
      if(accessPasswordKey==='1234'){
				setVerifyIds([...verifyIds, verifyId]);
        setShowPassword(!showPassword);
        handleClose();
      }else{
        toast.error('Wrong Password');
        handleClose();
      }
    }else if(fildname==='dbPassword'){
      if(databasePasswordKey==='1234'){
				setVerifyDBIds([...verifyDBIds, verifyDBId])
        setshowDBPassword(!showDBPassword);
        handleClose();
      }else{
        toast.error('Wrong Password');
        handleClose();
      }
    }else if(fildname==='webMinPassword'){
      if(webMinPasswordKey==='1234'){
        setshowWebMinPassword(!showWebMinPassword);
        handleClose();
      }else{
        toast.error('Wrong Password');
        handleClose();
      }
    }
  }

	const mapUsers = (vm) => 
	{
		const id = vm?.users?.map((u) => {
			return u.userId;
		})
		
		const emp = employeeData.filter((e) =>
				id?.includes(e.id)
			) || ""

		const empName = emp.map((e) => {
			return e?.firstName + " " + e?.lastName;
		});
		
		return empName.join(", ");
	}

	if (loading) {
		return <Loader />;
	}

	return (
		<div className="view_employee">
			<Container maxWidth="xl" >
				<Stack direction="row" justifyContent="flex-end" mb={2}>
					{getEditVisible('vmMaster')&&
					<Button
						variant="contained"
						component={RouterLink}
						to={`/vm/edit/${encryption(vm?.id)}`}
						style={{ marginLeft: "10px" }}
					>
						Edit
					</Button>
}
					<Button
						variant="contained"
						// onClick={() => navigate("/vm")}
						onClick={() => navigate(-1)}
						startIcon={<ArrowBackIcon />}
						style={{ marginLeft: "10px" }}
					>
						Back To List
					</Button>
				</Stack>
				<Box>
          <Card style={{ padding: "25px 15px" , marginTop: "20px"}} mb={3} >
						<Typography
							variant="h6"
							className="information-card-heading"
							style={{
								fontWeight: "700",
								fontSize: "1.125rem",
								marginBottom: "20px",
							}}
							>
							Basic Details
						</Typography>
						<div className="address-blk">
							<div className="address-sub-section">
								<div className="address-div">
									{" "}
									<span>Server Name</span>
									<p>
										{vm?.serverName
											? vm?.serverName
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>Host PC Name</span>
									<p>
										{vm?.assetName
											? vm?.assetName
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>Status</span>
									<p>
										{vm?.status
											? vm?.status
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>SSH Port</span>
									<p>
										{vm?.sshPort
											? vm?.sshPort
											: ""}
									</p>
								</div>
							</div>
							<div className="address-sub-section">
								<div className="address-div">
									<span>Primary Users</span>{" "}
									<p>
										{/* {vm?.users
											? vm?.users?.userId 
											: "-"} */}
										
												{mapUsers(vm)}
											
									</p>
								</div>
								<div className="address-div">
									<span>VDI File Path</span>{" "}
									<p>
										{vm?.vdiFilePath ? vm.vdiFilePath : ""}
									</p>
								</div>
								<div className="address-div">
									<span>Purpose</span>{" "}
									<p>
										{vm?.purpose ? vm.purpose : ""}
									</p>
								</div>
							</div>
						</div>
					</Card>
				</Box>
				<Box>
          <Card style={{ padding: "25px 15px" , marginTop: "20px"}} mb={3} >
						<Typography
							variant="h6"
							className="information-card-heading"
							style={{
								fontWeight: "700",
								fontSize: "1.125rem",
								marginBottom: "20px",
							}}
							>
							Hardware Details
						</Typography>
						<div className="address-blk">
							<div className="address-sub-section">
								<div className="address-div">
									{" "}
									<span>IP Address</span>
									<p>
										{vm?.ipAddress
											? vm?.ipAddress
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>Disk Size(GB)</span>
									<p>
										{vm?.diskSize
											? vm?.diskSize
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>RAM Size(GB)</span>
									<p>
										{vm?.ramSize
											? vm?.ramSize
											: ""}
									</p>
								</div>
							</div>
							<div className="address-sub-section">
								<div className="address-div">
									<span>OS</span>
									<p>
										{vm?.os
											? vm?.os
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>Mount Point</span>{" "}
									<p>
										{vm?.mountPoint ? vm.mountPoint : ""}
									</p>
								</div>
								<div className="address-div">
									<span>Web Root</span>{" "}
									<p>
										{vm?.webRoot ? vm.webRoot : ""}
									</p>
								</div>
							</div>
						</div>
					</Card>
				</Box>
				<Box>
          <Card style={{ padding: "25px 15px" , marginTop: "20px"}} mb={3} >
						<Typography
							variant="h6"
							className="information-card-heading"
							style={{
								fontWeight: "700",
								fontSize: "1.125rem",
								marginBottom: "20px",
							}}
							>
							Web Server Details
						</Typography>
						<div className="address-blk">
							<div className="address-sub-section">
								<div className="address-div">
									{" "}
									<span>PHP Version</span>
									<p>
										{vm?.phpVersion ? vm.phpVersion : ""}
									</p>
								</div>
								<div className="address-div">
									<span>Apache Version</span>
									<p>
										{vm?.apacheVersion ? vm.apacheVersion : ""}
									</p>
								</div>
							</div>
							<div className="address-sub-section">
								<div className="address-div">
									<span>IIS Version</span>
									<p>
										{vm?.iisVersion ? vm.iisVersion : ""}
									</p>
								</div>
								<div className="address-div">
									<span>Misc Information</span>
									<p>
										{vm?.miscInformation ? vm.miscInformation : ""}
									</p>
								</div>
							</div>
						</div>
					</Card>
				</Box>
				<Box>
          <Card style={{ padding: "25px 15px" , marginTop: "20px"}} mb={3} >
						<Typography
							variant="h6"
							className="information-card-heading"
							style={{
								fontWeight: "700",
								fontSize: "1.125rem",
								marginBottom: "20px",
							}}
							>
							Other Details
						</Typography>
						<div className="address-blk">
							<div className="address-sub-section">
								<div className="address-div">
									{" "}
									<span>Deleted Path</span>
									<p>
										{vm?.deletedPath
											? vm?.deletedPath
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>Backup Directory</span>
									<p>
										{vm?.backupDir
											? vm?.backupDir
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>Backup Server Details</span>
									<p>
										{vm?.backupServerDetails
											? vm?.backupServerDetails
											: ""}
									</p>
								</div>
							</div>
							<div className="address-sub-section">
								<div className="address-div">
									<span>WebMin URL</span>
									<p>
										{vm?.webMinUrl
											? vm?.webMinUrl
											: ""}
									</p>
								</div>
								<div className="address-div">
									<span>WebMin Username</span>{" "}
									<p>
										{vm?.webMinUsername ? vm.webMinUsername : ""}
									</p>
								</div>
								<div className="address-div">
									<span>WebMin Password</span>{" "}
										{!showWebMinPassword && vm?.webMinPassword ? (
											<p> {vm?.webMinPassword ? decryption(vm.webMinPassword).replace(decryption(vm?.webMinPassword), "******") : ""}
													<VisibilityIcon onClick={handleShowWebMinPassword}/>
											</p>
										)  :
										(vm?.webMinPassword
											? 
											(<p>
													{decryption(vm.webMinPassword)}  &nbsp; <VisibilityOffIcon onClick={handleShowWebMinPassword}/>
											</p>)

											: "" ) 
										}
								</div>
							</div>
						</div>
					</Card>
				</Box>

					<Grid item xs={12} mt={2}>
						<Typography variant="h6" my={2}>
							Access Details
						</Typography>
						<Card>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left">Username</TableCell>
										<TableCell align="left">Password</TableCell>
										{/* <TableCell align="left">Action</TableCell>  */}
									</TableRow>
								</TableHead>
								<TableBody>
									{/* {console.log(vm.accessDetails)} */}
									{vm.accessDetails?.length > 0 ? (
										vm.accessDetails.map((a, index) => {
											return (
												(a.rootUsername || a.password && decryption(a.password) !=="" ? 
													<TableRow key={index}>
														<TableCell align="left">
															{a?.rootUsername ? a.rootUsername : ""}
														</TableCell>
														<TableCell align="left">
															{a?.password && decryption(a.password) !="" ?
															(verifyIds.includes(a.id) ? (
																(<p>
																	{decryption(a.password)}  &nbsp; <VisibilityOffIcon onClick={() => handleHidePassword(a.id)} />
																	</p>)) : (
																	<p> {a?.password && decryption(a.password) !="" ? decryption(a.password).replace(decryption(a?.password), "******") : ""}
																		&nbsp;<VisibilityIcon onClick={() => handleShowPassword(a.id)} />
																	</p>
															)) 
															: ""}
													</TableCell>
												</TableRow> : 
												<TableRow>
												<TableCell className="No-Record-text" colSpan={12} align="center">
													No Record(s) Found
												</TableCell>
											</TableRow>
												)
												// a.rootUsername && decryption(a.password) == "" ?
												
												// : 
												// <TableRow>
												// 	<TableCell colSpan={8} align="center">
												// 		No Record(s) Found
												// 	</TableCell>
												// </TableRow>
											);
										})
									) : (
										<TableRow>
											<TableCell className="No-Record-text" colSpan={12} align="center">
												No Record(s) Found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Card>
						<Typography variant="h6" my={2}>
							Database Details
						</Typography>
						<Card>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left">Name</TableCell>
										<TableCell align="left">Version</TableCell>
										<TableCell align="left">Username</TableCell>
										<TableCell align="left">Password</TableCell>
										{/* <TableCell align="left">Action</TableCell>  */}
									</TableRow>
								</TableHead>
								<TableBody>
									{vm.databaseDetails?.length > 0 ? (
										vm.databaseDetails.map((db, index) => {
											return (
												(db.name || db.version || db.username || db.password && decryption(db.password) !=="" ? 
												<TableRow key={index}>
													<TableCell align="left">
														{db?.name ? db.name : ""}
													</TableCell>
													<TableCell align="left">
														{db?.version ? db.version : ""}
													</TableCell>
													<TableCell align="left">
														{db?.username ? db.username : ""}
													</TableCell>
													<TableCell align="left">
														{db?.password && decryption(db.password) !="" ?
														(verifyDBIds.includes(db.id) ? (
																(<p>
																	{decryption(db.password)}  &nbsp; <VisibilityOffIcon onClick={() => handleHideDBPassword(db.id)} />
																	</p>)) : (
																<p> {db?.password ? decryption(db.password).replace(decryption(db?.password), "******") : ""}
																		&nbsp;<VisibilityIcon onClick={() => handleShowDBPassword(db.id)} />
																</p>
															)) : "" }
														</TableCell>
													</TableRow> : 
												<TableRow>
												<TableCell className="No-Record-text" colSpan={12} align="center">
													No Record(s) Found
												</TableCell>
											</TableRow> )
												);
											})
										) : (
										<TableRow>
											<TableCell className="No-Record-text" colSpan={12} align="center">
												No Record(s) Found
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</Card>
						<Modal
							open={open}
							onClose={handleClose}
							aria-labelledby="modal-modal-title"
							aria-describedby="modal-modal-description"
						>
							<Stack sx={style}>
								<TextField
									fullWidth
									autoComplete="off"
									label="Verification Code"
									onChange={(e)=>{fildname=='accessPassword'?setAccessPasswordKey(e.target.value):fildname=='dbPassword'?setDatabasePasswordKey(e.target.value):setWebminPasswordKey(e.target.value)}}
									variant="outlined"
									size="small"
								/>
								<Button variant="contained" onClick={handleVerify}>Submit</Button>
							</Stack>
						</Modal>
					</Grid>
			</Container>
		</div>
	);
}

const componentConfig = {
	component: ViewVM,
	path: "/vm/view/:id",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
