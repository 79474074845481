import DashboardLayout from "../../layouts/dashboard";
import Index from "./AssetMaster/Grid"

const AssetMaster = () => {
  return <Index></Index>;
};

const componentConfig = {
  component: AssetMaster,
  path: "/asset-master",
  public: false,
  layout: DashboardLayout,
  group: "Asset Management",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
