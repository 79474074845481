import { createSlice } from '@reduxjs/toolkit';
import {
  getEmployeeSubmittedEvalution,
} from './employeeEvaluationThunk';

const initialState = {
  loading: false,
  error: null,
  data: [],
  complete: false,
  limit: 40,
  page: 0,
  totalPages: 0,
  totalCount: 0,
  sortBy: 'id',
  orderBy: 'desc',
  isSearchQuery: false,
};

export const employeeEvaluationHistorySlice = createSlice({
  name: 'evaluation',
  initialState,
  reducers: {
    setSortBy: (state, action) => {
      state.sortBy = action.payload.sortBy;
      state.complete = false;
    },
    setOrderBy: (state, action) => {
      state.orderBy = action.payload.orderBy;
      state.complete = false;
    },
    setLimit: (state, action) => {
      state.limit = action.payload.limit;
      state.complete = false;
      state.error = null;
    },
    setPage: (state, action) => {
      state.page = action.payload.page;
      state.complete = false;
      state.error = null;
    },
  },
  extraReducers:{
    [getEmployeeSubmittedEvalution.pending]:(state)=>{
      state.loading=true;
      state.status="pending"
    },
    [getEmployeeSubmittedEvalution.fulfilled]:(state,{payload})=>{
        state.loading = false;
        console.log("payload", payload)
        state.data = payload?.data?.getSubmittedEvalutionHistory || payload;
        state.complete = true;
        state.totalRecords = payload?.data?.totalRecords || 0;
        state.totalPages = payload?.data?.totalPages || 0;
        state.limit = payload?.data?.limit;
        state.page = payload?.data?.currentPage - 1 || 0;
        state.status = "fulfilled";
        state.isSearchQuery = payload.filter;
    },
    [getEmployeeSubmittedEvalution.rejected]:(state,{payload})=>{
      state.loading = false;
      state.data = {};
      state.error = payload;
      state.complete = true;
      state.totalCount = 0;
      state.totalPages = 0;
      state.page = 0;
      state.status = "rejected"
    },
  }
});


export const { setLimit, setPage, setOrderBy, setSortBy } =
employeeEvaluationHistorySlice.actions;

export const employeeEvaluationHistoryReducer = employeeEvaluationHistorySlice.reducer;
