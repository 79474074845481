import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";
import CustomPagination from "../../components/Pagination";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";

import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";

import {
  setLimit,
  setCurrentPage,
  setOrderBy,
  setSortBy,
} from "../../redux/announcements/announcementsSlice";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../utils/userPermission";
import invertDirection from "../../utils/invertDirection";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
import { encryption } from "../../utils/encodeString";
import {
  deleteNotice,
  getAllNotice,
} from "../../redux/announcements/announcementsThunk";
import moment from "moment";
import { Chip } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Link as RouterLink } from "react-router-dom";

function Announcements() {
  const [open, setOpen] = useState(false);

  const confirm = useConfirm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data,
    totalRecords,
    currentPage,
    totalPages,
    limit,
    status,
    loading,
    sortBy,
    orderBy,
  } = useSelector((state) => state.announcements);

  const [id, setId] = useState(null);
  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
      // console.log(res.data,"db");
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = LocalStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);
  // User Permission Use Effect : If user not has access to this module then redirect to dashboard
  useEffect(() => {
    if (!getViewVisible("announcements")) {
      navigate("/dashboard");
    }
  }, []);

  // useEffect for fetching data

  useEffect(() => {
    const fetchNotices = async () => {
      try {
        await dispatch(
          getAllNotice({
            limit,
            page: currentPage + 1,
            orderBy,
            sortBy,
          })
        ).unwrap();
      } catch (error) {
        toast.error(error?.message);
      }
    };

    fetchNotices();
  }, [dispatch, limit, currentPage, sortBy, orderBy]);

  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteNotice(id));
      await dispatch(
        getAllNotice({
          page: currentPage + 1,
          limit: limit,
          sortBy,
          orderBy,
        })
      );
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
    dispatch(setCurrentPage({ page: 0 }));
  };

  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({
        orderBy: invertDirection(sortBy === columnName, orderBy),
      })
    );
  };

  const handleAddAnnouncements = () => {
    navigate("add");
  };

  //   console.log("DATA", data);
  return (
    <Container className="Employee-wrapper Add-Announcements-Container" maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <div className="title-button-wrapper Add-Announcements-title">
        <Typography variant="h4" gutterBottom>
          Announcements ({Number(totalRecords) > 0 ? totalRecords : 0})
          {/* <Typography variant="body2">
            Total Records : {Number(totalRecords) > 0 ? totalRecords : 0}
          </Typography> */}
        </Typography>
        <Button
        className="header-add-button"
          variant="contained"
          // startIcon={<AddIcon />}
          onClick={handleAddAnnouncements}
        >
          <img src="/assets/images/add-circle.svg" />
          Add Announcements
        </Button>
        </div>
      </Stack>
      <Card className="Add-Announcement-Main-Card">
        <Scrollbar sx={{ minWidth: 800 }}>
          <TableContainer sx={{ minWidth: 800 }}>
            {loading ? (
              <Loader />
            ) : (
              <Table className="grid-table Addannouncement-table">
                <TableHead>
                  <TableRow>
                    <TableCell>Title</TableCell>
                    <TableCell className="th-sorting" align="left">
                      <TableSortLabel
                        active={sortBy === "startDate"}
                        direction={sortBy === "startDate" ? orderBy : "asc"}
                        onClick={() => handleSorting("startDate")}
                      >
                        Effective From
                      </TableSortLabel>
                    </TableCell>

                    <TableCell align="left">Effective To</TableCell>
                    {(getEditVisible("announcements") ||
                      getDeleteVisible("announcements")) && (
                      <TableCell className="th-action-buttons" align="center" style={{ width: "140px" }}>
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell className="No-Record-text" align="center" colSpan={4}>
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    data.map((announcement, idx) => (
                      <TableRow key={idx}>
                        <TableCell align="left">
                          {announcement?.title || ""}
                        </TableCell>
                        <TableCell align="left">
                          {moment(announcement?.startDate).format(
                            "DD/MM/YYYY"
                          ) || ""}
                        </TableCell>

                        <TableCell align="left">
                          {moment(announcement?.endDate).format("DD/MM/YYYY") ||
                            ""}
                        </TableCell>
                        {/* {(getEditVisible("holiday") || */}
                        {/* getDeleteVisible("holiday")) && ( */}
                        <TableCell className="td-action-button" align="center">
                          <div className="action-button">
                            <>
                              {getDeleteVisible("announcements") && (
                                <IconButton
                                  data-cy="btn-delete-holiday"
                                  onClick={() =>
                                    handleDelete(
                                      announcement?.id,
                                      announcement?.title
                                    )
                                  }
                                >
                                  <img src="assets/images/trash-gray.svg" title="trash" />
                                  {/* <DeleteIcon color="error" /> */}
                                </IconButton>
                              )}
                              {getViewVisible("announcements") && (
                                <IconButton
                                  component={RouterLink}
                                  to={`/announcements/view/${encryption(
                                    announcement?.id
                                  )}`}
                                >
                                  <img src="assets/images/vieweye-icon.svg" title="View" />
                                  {/* <VisibilityIcon /> */}
                                </IconButton>
                              )}
                            </>
                          </div>
                        </TableCell>
                        {/* )} */}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </Scrollbar>
        <CustomPagination
          totalPage={totalPages || 1}
          totalCount={totalRecords || 0}
          limit={limit}
          handleChangePage={handleChangePage}
          page={currentPage}
          rowsPerPageOptions={[10, 20, 40]}
          handleRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: Announcements,
  path: "/announcements",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
