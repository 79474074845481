import React, { useRef, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EditIcon from "@mui/icons-material/Edit";
import WorkIcon from "@mui/icons-material/Work";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Autocomplete from "@mui/material/Autocomplete";
import DownloadIcon from "@mui/icons-material/Download";

import { decryption, encryption } from "../../../../utils/encodeString";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getEsCandidate } from "../../../../redux/candidate/candidateThunk";
import { useSelector } from "react-redux";

export default function Personal(props) {
	const {
		values,
		handleChange,
		touched,
		errors,
		handleTabChange,
		setSelectedFile,
		setSelectedFileUrl,
		selectedFileUrl,
		candidateResume,
		setCandidateResume,
		// setErrors,
		setToBeDeletedCandidateResume,
		designationData,
		designationDisable,
		candidateId,
	} = props;


	const dispatch = useDispatch();
	const esCandidateData = useSelector((state) => state.candidate.data);
	const fileInput = useRef(null);
	const candidateResumeFileInput = useRef(null);
	useEffect(() => {
		if (!candidateId) {
			dispatch(
				getEsCandidate({
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					contactNumber: values.contactNumber,
					alternateContactNumber: values.alternateContactNumber,
				}),
			);
		} /*  else {
			dispatch(
				getEsCandidate({
					candidateId: candidateId,
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					contactNumber: values.contactNumber,
					alternateContactNumber: values.alternateContactNumber,
				}),
			);
		} */
	}, [
		values.firstName,
		values.lastName,
		values.email,
		values.contactNumber,
		values.alternateContactNumber,
	]);

	const handleImageChange = (e) => {
		var reader = new FileReader();

		reader.onload = function (e) {
			setSelectedFileUrl(e.target.result);
		};

		reader.readAsDataURL(e.target.files[0]);
		setSelectedFile(e.target.files[0]);
	};

	const Item = styled(Paper)(({ theme }) => ({
		...theme.typography.body2,
		padding: theme.spacing(1),
		textAlign: "center",
		color: theme.palette.text.secondary,
	}));
	const getFileName = (file) => {
		if (!file) {
			return "";
		}

		if (file && !file.name) {
			const splittedFile = file.split("/");
			return splittedFile[splittedFile.length - 1];
		}
		return file.name?.length > 10
			? file.name?.substr(0, 10) + "..."
			: file.name;
	};

	const checkIfImage = (file) => {
		if (file && file.name) {
			const imageTypes = ["jpeg", "jpg", "png"];
			return imageTypes.includes(file.name.split(".")[1]);
		}
		return file && file["type"] && file["type"].split("/")[0] === "image";
	};

	const handleEmployeeResumeImageChange = (e) => {
		setCandidateResume(e.target.files[0]);
	};

	const handleDeleteCandidateResume = (image) => {
		setCandidateResume(null);
		setToBeDeletedCandidateResume(image?.id);
	};

	// useEffect(()=>{
	// 	const keys = Object.keys(errors);
	// 	if(keys.length===1&&keys[0]==='experience'&&errors.experience.length<1){
	// 	  setErrors({})
	// 	}
	//   },[errors])
 
	return (
		<Card className="right-side-content-part">
			<Container maxWidth="xl">
				<Typography className="Employee_Detail-h6" variant="h6" my={3} style={{marginTop:"0"}}>
					Personal Details
				</Typography>

				<Grid className="gray-box Personal-Detail-content" container spacing={3}>
					<Grid item xs={12}>
						<div className="profile-image-part">
							<div className="circle">
								<Box
									component="img"
									src={selectedFileUrl || "/assets/images/profile.png"}
								/>
								<div className="p-image">
									<i
										className="upload-button"
										onClick={() => {
											fileInput.current.click();
										}}
									>
										<PhotoCameraIcon />
									</i>
									<input
										ref={fileInput}
										className="file-upload"
										type="file"
										accept="image/*"
										onChange={handleImageChange}
									/>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="First Name"
							name="firstName"
							variant="outlined"
							size="small"
							onChange={handleChange}
							inputProps={{ maxLength: 64 }}
							value={values.firstName}
							error={Boolean(touched.firstName && errors.firstName)}
							helperText={touched.firstName && errors.firstName}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Last Name"
							name="lastName"
							variant="outlined"
							size="small"
							onChange={handleChange}
							inputProps={{ maxLength: 64 }}
							value={values.lastName}
							error={Boolean(touched.lastName && errors.lastName)}
							helperText={touched.lastName && errors.lastName}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Contact Number"
							name="contactNumber"
							variant="outlined"
							size="small"
							value={values.contactNumber}
							inputProps={{ maxLength: 10 }}
							onChange={handleChange}
							error={Boolean(touched.contactNumber && errors.contactNumber)}
							helperText={touched.contactNumber && errors.contactNumber}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="E-mail"
							name="email"
							variant="outlined"
							size="small"
							inputProps={{ maxLength: 512 }}
							value={values.email}
							onChange={handleChange}
							error={Boolean(touched.email && errors.email)}
							helperText={touched.email && errors.email}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Alternate Contact Number"
							name="alternateContactNumber"
							variant="outlined"
							size="small"
							value={values.alternateContactNumber}
							inputProps={{ maxLength: 10 }}
							onChange={handleChange}
							error={Boolean(
								touched.alternateContactNumber && errors.alternateContactNumber,
							)}
							helperText={
								touched.alternateContactNumber && errors.alternateContactNumber
							}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Skype"
							name="skype"
							variant="outlined"
							size="small"
							value={values.skype}
							inputProps={{ maxLength: 512 }}
							onChange={handleChange}
							error={Boolean(touched.skype && errors.skype)}
							helperText={touched.skype && errors.skype}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="LinkedIn"
							name="linkedIn"
							variant="outlined"
							size="small"
							value={values.linkedIn}
							inputProps={{ maxLength: 1024 }}
							onChange={handleChange}
							error={Boolean(touched.linkedIn && errors.linkedIn)}
							helperText={touched.linkedIn && errors.linkedIn}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="GitHub Profile"
							name="github"
							variant="outlined"
							size="small"
							value={values.github}
							inputProps={{ maxLength: 512 }}
							onChange={handleChange}
							error={Boolean(touched.github && errors.github)}
							helperText={touched.github && errors.github}
						/>
					</Grid>
					<Grid item xs={6}>
						<TextField
							fullWidth
							label="Portfolio Website"
							name="portfolioWebsite"
							variant="outlined"
							size="small"
							value={values.portfolioWebsite}
							inputProps={{ maxLength: 1024 }}
							onChange={handleChange}
							error={Boolean(touched.portfolioWebsite && errors.portfolioWebsite)}
							helperText={touched.portfolioWebsite && errors.portfolioWebsite}
						/>
					</Grid>

					{/* <Grid item xs={6}>
						<LocalizationProvider dateAdapter={AdapterDateFns}>
							<DesktopDatePicker
								label="Date of Birth"
								value={values.dateOfBirth}
								onChange={(newValue) => {
									setFieldValue('dateOfBirth', newValue);
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										error={Boolean(touched.dateOfBirth && errors.dateOfBirth)}
										helperText={touched.dateOfBirth && errors.dateOfBirth}
										fullWidth
										size="small"
									/>
								)}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs={6} mb={3}>
						<TextField
							fullWidth
							label="Referred By"
							name="referedBy"
							variant="outlined"
							size="small"
							onChange={handleChange}
							value={values.referedBy}
							error={Boolean(touched.referedBy && errors.referedBy)}
							helperText={touched.referedBy && errors.referedBy}
						/>
					</Grid> */}
					{/* <Grid item xs="6"></Grid> */}
					<Grid item xs={designationDisable?3:6} mt={2} className="radio-select-space">
						<FormControl
							component="fieldset"
							size="small"
							error={Boolean(touched.gender && errors.gender)}
						>
							<FormLabel component="legend">Gender</FormLabel>
							<RadioGroup
								row
								aria-label="gender"
								name="gender"
								onChange={handleChange}
								value={values.gender}
							>
								<FormControlLabel
									value="M"
									control={<Radio size="small" />}
									label="Male"
									style={{color:'#637381'}}
								/>
								<FormControlLabel
									value="F"
									control={<Radio size="small" />}
									label="Female"
									style={{color:'#637381'}}
								/>
							</RadioGroup>
							<FormHelperText style={{ margin: "5px 0 0 0" }}>
								{touched.gender && errors.gender}
							</FormHelperText>
						</FormControl>
					</Grid>

					{/* <Grid item xs={6} className="radio-select-space">
						<FormControl
							error={Boolean(touched.maritalStatus && errors.maritalStatus)}
							helperText={touched.maritalStatus && errors.maritalStatus}
							component="fieldset"
							size="small"
						>
							<FormLabel component="legend">Marital Status</FormLabel>
							<RadioGroup
								row
								aria-label="gender"
								name="maritalStatus"
								onChange={handleChange}
								value={values.maritalStatus}
							>
								<FormControlLabel
									value="single"
									control={<Radio size="small" />}
									label="Single"
								/>
								<FormControlLabel
									value="married"
									control={<Radio size="small" />}
									label="Married"
								/>
							</RadioGroup>
							<FormHelperText>
								{touched.maritalStatus && errors.maritalStatus}
							</FormHelperText>
						</FormControl>
					</Grid> */}
					{/* <Grid container spacing={3}></Grid> */}
{designationDisable?'':
					<Grid item xs={6} mb={2}>
						<FormControl
							fullWidth
							error={Boolean(touched?.designation && errors?.designation)}
						>
							<Autocomplete
								size="small"
								fullWidth
								value={values.designation}
								options={designationData.data || []}
								key={designationData.data.Id || []}
								getOptionLabel={(option) => option.title || ""}
								isOptionEqualToValue={(option, value) => option === value}
								name="designationId"
								/* onChange={(event, newValue) => {
                            setFieldValue(
                                `designationId`,
                                newValue
                            );
                            }} */
								renderInput={(params) => (
									<TextField {...params} label="Designation" />
								)}
								disabled={designationDisable}
							/>

							<FormHelperText style={{ margin: "5px 0 0 0" }}>
								{touched?.designation && errors?.designation}
							</FormHelperText>
						</FormControl>
					</Grid>
}
					<Grid item xs={designationDisable?3:6} mt={1} mb={2} className="checkbox-part-space">
						<FormGroup row>
							<FormControlLabel
								control={<Checkbox checked={values.wishlist} />}
								label="Wishlist"
								name="wishlist"
								onChange={handleChange}
								value={values.wishlist}
							/>
						</FormGroup>
					</Grid>
				</Grid>{candidateId&&
				<Grid item xs={12}>
					<Grid container pt={3} sx={{ borderTop: "1px solid #dce0e4" }}>
						<Grid className="checkList-grid-address-grid" item xs={6}>
							<Stack mb={1}>
								<Typography>Candidate Resume</Typography>
							</Stack>
							<Grid className="checkList-grid-address-detail" container item xs={4}>
								<Grid item xs={12}>
									<div
										className="custome-uploadBox small profile-image-part"
										onClick={(e) => {
											candidateResume ?  window.open(
												candidateResume.webViewLink,
												"_blank",
											)  : candidateResumeFileInput.current.click();
										
										}}
									>
										{candidateResume && (
											<>
												{checkIfImage(candidateResume) ? (
													<>
														<InsertDriveFileIcon
															// onClick={(e) => {
															// 	window.open(
															// 		candidateResume.webViewLink,
															// 		"_blank",
															// 	);
															// }}
														/>
														{/* <Box
																component="img"
																src={getImageUrl(
																	values.presentAddressImage
																)}
															/> */}
														<Typography variant="caption">
															{getFileName(candidateResume)}
														</Typography>
													</>
												) : (
													<>
														<InsertDriveFileIcon
															onClick={(e) => {
																// window.open(
																// 	candidateResume.webViewLink,
																// 	"_blank",
																// );
															}}
														/>
														<Typography variant="caption">
															{getFileName(candidateResume)}
														</Typography>
													</>
												)}
											</>
										)}
										{candidateResume && (
											<CancelRoundedIcon
												onClick={(e) => {
													e.stopPropagation();
													handleDeleteCandidateResume(candidateResume);
												}}
												className="closeIcon"
												style={{
													position: "absolute",
													top: "-7px",
													right: "-6px",
												}}
											/>
										)}
										<div className="p-image">
											{!candidateResume && (
												<i className="upload-button">
													<AddCircleIcon />
												</i>
											)}

											{!candidateResume && (
												<input
													ref={candidateResumeFileInput}
													className="file-upload"
													type="file"
													accept=".doc, .docx, .pdf"
													onChange={handleEmployeeResumeImageChange}
												/>
											)}
										</div>
									</div>
								</Grid>
							</Grid>
							{/* <FormHelperText
                                    error={Boolean(errors?.employeeResume)}
                                >
                                    {touched?.employeeResume &&
                                        errors?.employeeResume}
                                </FormHelperText> */}
						</Grid>
					</Grid>
				</Grid>}
				<Stack direction="row" justifyContent="flex-end" mt={2} mb={3}>
					<Button onClick={() => handleTabChange("2")} variant="contained" endIcon={<ArrowForwardIcon />}>
						Next
					</Button>
				</Stack>
				<Grid container spacing={3} pb={3}>
					{/*{esCandidateData && esCandidateData?.length > 0 ? (
				<Grid item xs={4} className="candidate-box">
						<Item style={{ border: "1px solid #f0f0f0" }}>
							<Stack
								color="primary.contrastText"
								direction="row"
								justifyContent="space-between"
								alignItems="center"
								pl={"5px"}
							>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									style={{ gridGap: "5px" }}
								>
									<h4
										style={{
											fontSize: 17,
											fontWeight: 600,
										}}
									>
										{esCandidateData[0]?.firstName || ""} {esCandidateData[0]?.lastName || ""}
									</h4>
									<VerifiedUserIcon color="info" style={{ fontSize: 16 }} />
								</Stack>
								<span className="profile-complition"> 
									{esCandidateData[0]?.score ? esCandidateData[0]?.score : 0}%
								</span>
								<IconButton 
									variant="contained"
									component={RouterLink}
									to={`/candidate/add/${encryption(esCandidateData[0]?.id)}`}
									style={{ height: 32, width: 32 }}>
									<EditIcon color="primary" style={{ fontSize: 20 }} />
								</IconButton>
							</Stack>
							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								my={1.5}
								style={{ gridGap: "5px" }}
							>
								<PhoneIphoneIcon color="primary" />
								<Typography>
									<Link
										href={`tel:${esCandidateData[0]?.contactNumber}`}
										underline="hover"
										color="primary.contrastText"
										style={{ fontSize: 14 }}
									>
										{esCandidateData[0]?.contactNumber || ""}
									</Link>
									{esCandidateData[0]?.contactNumber  &&  esCandidateData[0]?.alternateContactNumber ? <span>&nbsp;|&nbsp;</span> : ""}
									<Link
										href={`tel:${esCandidateData[0]?.alternateContactNumber}`}
										underline="hover"
										color="primary.contrastText"
										style={{ fontSize: 14 }}
									>
										{esCandidateData[0]?.alternateContactNumber || ""}
									</Link>
								</Typography>
							</Stack>

							<Stack
								direction="row"
								justifyContent="flex-start"
								alignItems="center"
								my={1.5}
								style={{ gridGap: "5px" }}
							>
								<EmailIcon color="primary" style={{ fontSize: 20 }} />
								<Typography>
									<Link
										href={`mailto:${esCandidateData[0]?.email}`}
										underline="hover"
										color="primary.contrastText"
										style={{ fontSize: 14 }}
									>
										{esCandidateData[0]?.email || ""}
									</Link>
								</Typography>
							</Stack>
						</Item>
					</Grid> ) : "" }
					{esCandidateData && esCandidateData?.length > 1 ? (
					<Grid item xs={4} className="candidate-box">
							<Item style={{ border: "1px solid #f0f0f0" }}>
								<Stack
									color="primary.contrastText"
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									pl={"5px"}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										style={{ gridGap: "5px" }}
									>
										<h4
											style={{
												fontSize: 17,
												fontWeight: 600,
											}}
										>
											{esCandidateData[1]?.firstName || ""} {esCandidateData[1]?.lastName || ""}
										</h4>
										<VerifiedUserIcon color="info" style={{ fontSize: 16 }} />
									</Stack>
									<span className="profile-complition">
										{esCandidateData[1]?.score}%
									</span>
									<IconButton 
										variant="contained"
										component={RouterLink}
										to={`/candidate/add/${encryption(esCandidateData[1]?.id)}`}
										style={{ height: 32, width: 32 }}>
										<EditIcon color="primary" style={{ fontSize: 20 }} />
								</IconButton>
								</Stack>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									my={1.5}
									style={{ gridGap: "5px" }}
								>
									<PhoneIphoneIcon color="primary" />
									<Typography>
										<Link
											href={`tel:${esCandidateData[1]?.contactNumber}`}
											underline="hover"
											color="primary.contrastText"
											style={{ fontSize: 14 }}
										>
											{esCandidateData[1]?.contactNumber || ""}
										</Link>
										{esCandidateData[1]?.contactNumber  &&  esCandidateData[1]?.alternateContactNumber ? <span>&nbsp;|&nbsp;</span> : ""}
										<Link
											href={`tel:${esCandidateData[1]?.alternateContactNumber}`}
											underline="hover"
											color="primary.contrastText"
											style={{ fontSize: 14 }}
										>
											{esCandidateData[1]?.alternateContactNumber || ""}
										</Link>
									</Typography>
								</Stack>

								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									my={1.5}
									style={{ gridGap: "5px" }}
								>
									<EmailIcon color="primary" style={{ fontSize: 20 }} />
									<Typography>
										<Link
											href={`mailto:${esCandidateData[1]?.email}`}
											underline="hover"
											color="primary.contrastText"
											style={{ fontSize: 14 }}
										>
											{esCandidateData[1]?.email || ""}
										</Link>
									</Typography>
								</Stack>
							</Item>
						</Grid> ) : "" }
						{esCandidateData && esCandidateData?.length > 2 ? (
					<Grid item xs={4} className="candidate-box">
							<Item style={{ border: "1px solid #f0f0f0" }}>
								<Stack
									color="primary.contrastText"
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									pl={"5px"}
								>
									<Stack
										direction="row"
										justifyContent="space-between"
										alignItems="center"
										style={{ gridGap: "5px" }}
									>
										<h4
											style={{
												fontSize: 17,
												fontWeight: 600,
											}}
										>
											{esCandidateData[2]?.firstName || ""} {esCandidateData[2]?.lastName || ""}
										</h4>
										<VerifiedUserIcon color="info" style={{ fontSize: 16 }} />
									</Stack>
									<span className="profile-complition">
										{esCandidateData[2]?.score}%
									</span>
									<IconButton 
										variant="contained"
										component={RouterLink}
										to={`/candidate/add/${encryption(esCandidateData[2]?.id)}`}
										style={{ height: 32, width: 32 }}>
										<EditIcon color="primary" style={{ fontSize: 20 }} />
								</IconButton>
								</Stack>
								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									my={1.5}
									style={{ gridGap: "5px" }}
								>
									<PhoneIphoneIcon color="primary" />
									<Typography>
										<Link
											href={`tel:${esCandidateData[2]?.contactNumber}`}
											underline="hover"
											color="primary.contrastText"
											style={{ fontSize: 14 }}
										>
											{esCandidateData[2]?.contactNumber || ""}
										</Link>
										{esCandidateData[2]?.contactNumber  &&  esCandidateData[2]?.alternateContactNumber ? <span>&nbsp;|&nbsp;</span> : ""}
										<Link
											href={`tel:${esCandidateData[2]?.alternateContactNumber}`}
											underline="hover"
											color="primary.contrastText"
											style={{ fontSize: 14 }}
										>
											{esCandidateData[2]?.alternateContactNumber || ""}
										</Link>
									</Typography>
								</Stack>

								<Stack
									direction="row"
									justifyContent="flex-start"
									alignItems="center"
									my={1.5}
									style={{ gridGap: "5px" }}
								>
									<EmailIcon color="primary" style={{ fontSize: 20 }} />
									<Typography>
										<Link
											href={`mailto:${esCandidateData[2]?.email}`}
											underline="hover"
											color="primary.contrastText"
											style={{ fontSize: 14 }}
										>
											{esCandidateData[2]?.email || ""}
										</Link>
									</Typography>
								</Stack>
							</Item>
						</Grid> ) : "" }*/}
{/* {console.log(esCandidateData)} */}
					{esCandidateData && esCandidateData?.length > 0
						? esCandidateData?.map((candidate, idx) => {
								return candidate?.score && candidate?.score >= 40 ? (
									<Grid item xs={4} className="candidate-box">
										<Item style={{ border: "1px solid #f0f0f0" }}>
											<Stack
												// color="primary.contrastText"
												direction="row"
												justifyContent="space-between"
												alignItems="center"
												pl={"5px"}
											>
												<Stack
													direction="row"
													justifyContent="space-between"
													alignItems="center"
													style={{ gridGap: "5px" }}
												>
													<h4
														style={{
															fontSize: 17,
															fontWeight: 600,
														}}
													>
														{candidate.firstName || ""}{" "}
														{candidate.lastName || ""}
													</h4>
													<VerifiedUserIcon
														color="info"
														style={{ fontSize: 16 }}
													/>
												</Stack>
												<span className="profile-complition">
													{candidate.score}%
												</span>
												<IconButton
													variant="contained"
													component={RouterLink}
													to={`/candidate/add/${encryption(
														candidate?.candidateId,
													)}`}
													style={{ height: 32, width: 32 }}
												>
													<EditIcon color="primary" style={{ fontSize: 20 }} />
												</IconButton>
											</Stack>
											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="center"
												my={1.5}
												style={{ gridGap: "5px" }}
											>
												<PhoneIphoneIcon color="primary" />
												<Typography>
													<Link
														href={`tel:${candidate.contactNumber}`}
														underline="hover"
														// color="primary.contrastText"
														style={{ fontSize: 14 }}
													>
														{candidate.contactNumber || ""}
													</Link>
													{candidate.contactNumber &&
													candidate.alternateContactNumber ? (
														<span>&nbsp;|&nbsp;</span>
													) : (
														""
													)}
													<Link
														href={`tel:${candidate.alternateContactNumber}`}
														underline="hover"
														// color="primary.contrastText"
														style={{ fontSize: 14 }}
													>
														{candidate.alternateContactNumber || ""}
													</Link>
												</Typography>
											</Stack>

											<Stack
												direction="row"
												justifyContent="flex-start"
												alignItems="center"
												my={1.5}
												style={{ gridGap: "5px" }}
											>
												<EmailIcon color="primary" style={{ fontSize: 20 }} />
												<Typography>
													<Link
														href={`mailto:${candidate.email}`}
														underline="hover"
														// color="primary.contrastText"
														// color="primary"
														style={{ fontSize: 14 }}
													>
														{candidate.email || ""}
													</Link>
												</Typography>
											</Stack>
										</Item>
									</Grid>
								) : (
									""
								);
						  })
						: ""}
				</Grid>
			</Container>
		</Card>
	);
}
