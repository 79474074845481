import { createSlice } from "@reduxjs/toolkit";
import { getEvalutionCount } from "./evaluationThunk";

const initialState = {
	loading: false,
	status: "idle",
	error: null,
	totalCounts: null,
	complete: false,
};

// A slice for CandidateCount with our 3 reducers
export const evaluationCountSlice = createSlice({
	name: "evaluationCount",
	initialState,
	reducers: {},
	extraReducers: {
		[getEvalutionCount.pending]: (state) => {
			state.loading = true;
		},
		[getEvalutionCount.fulfilled]: (state, { payload }) => {
			state.loading = false;
			state.status = "fulfilled";
			state.complete = true;
			state.totalCounts = payload.data;
		},
		[getEvalutionCount.rejected]: (state, payload) => {
			state.loading = false;
			state.error = payload;
			state.status = "rejected";
			state.complete = true;
			state.totalCounts = 0;
		},
	},
});

// The reducer
export const evaluationCountReducer = evaluationCountSlice.reducer;
