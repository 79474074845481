import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboard";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Scrollbar from "../../../components/Scrollbar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableSortLabel from "@mui/material/TableSortLabel";
import TableHead from "@mui/material/TableHead";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TablePagination from "@mui/material/TablePagination";
import FormHelperText from "@mui/material/FormHelperText";
import { getCityData, getStateData } from "../../../utils/getLocationData";
import addressSchema from "../../../validations/addressSchema";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Autocomplete from "@mui/material/Autocomplete";

import {
	getAddress,
	insert,
	updateById,
} from "../../../redux/adress/addressThunk";
import {
	setLimit,
	setCurrentPage,
	setSortBy,
	setOrderBy,
} from "../../../redux/adress/addressSlice";
import { getAddressCount } from "../../../redux/adress/addressThunk";
import invertDirection from "../../../utils/invertDirection";
import CustomPagination from "../../../components/Pagination";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { debounce } from "lodash";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../utils/userPermission";
import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import { encryption } from "../../../utils/encodeString";
import LocalStorage from "../../../service/localStorage";

const Department = () => {
	const searchRef = useRef(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	
	useEffect(()=>{
		if (!getViewVisible("addressMaster")) {
			dispatch(
				getAddress({
					page: currentPage + 1,
					limit: limit,
					state: values.state,
					city: values.city,
					sortBy,
					orderBy,
				}),
			);
		navigate("/dashboard");
	}
	},[])
	const {
		data,
		status,
		totalRecords,
		currentPage,
		limit,
		loading,
		sortBy,
		orderBy,
		totalPages,
		isSearchQuery,
	} = useSelector((state) => state.address);

	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);

	const [formStates, setFormStates] = useState([]);
	const [formCities, setFormCities] = useState([]);

	const [open, setOpen] = useState(false);
	const [id, setId] = useState(null);

	const totalCount = useSelector((state) => state.addressCount.totalCounts)

	useEffect(() => {
		dispatch(
			getAddress({
				page: currentPage + 1,
				limit: limit,
				state: values.state,
				city: values.city,
				sortBy,
				orderBy,
			}),
		);
	}, []);

	useEffect(() => {
		if (status === "idle") {
			dispatch(
				getAddress({
					page: currentPage + 1,
					limit: limit,
					state: values.state,
					city: values.city,
					sortBy,
					orderBy,
				}),
			);
		}
	}, [dispatch, sortBy, orderBy, limit, currentPage, status]);
	
	useEffect(() => {
		dispatch(getAddressCount({}))
	}, [dispatch]);

	useEffect(() => {
		getStateData()
			.then((states) => {
				setStates(states);
				setFormStates(states);
			})
			.catch((err) => {
				setStates([]);
			});
	}, []);
	useEffect(() => {
		getCityData("Gujarat")
			.then((cities) => {
				setCities(cities);
				setFormCities(cities);
			})
			.catch((err) => {
				setCities([]);
			});
	}, []);

	const formik = useFormik({
		initialValues: {
			city: "Ahmedabad",
			state: "Gujarat",
			country: "",
			area: "",
			postalCode: "",
		},
		onSubmit: async (values) => {
			try {
				var searchParams = {};
				searchParams.page = currentPage + 1;
				searchParams.limit = limit;
				searchParams.city = values.city;
				searchParams.state = values.state;
				dispatch(getAddress(searchParams));
			} catch (error) {
				toast.error(error.message);
			}
		},
	});
	const { handleSubmit, setValues, values, setFieldValue, isSubmitting } =
		formik;

	const formikInsertUpdate = useFormik({
		initialValues: {
			area: "",
			state: "",
			city: "",
			country: "India",
			postalCode: "",
		},
		validationSchema: addressSchema,
		onSubmit: async (values) => {
			try {
				if (id) {
					await dispatch(updateById({ ...values, id })).unwrap();
				}
				if (!id) {
					await dispatch(insert(values)).unwrap();
				}
				formikInsertUpdate.resetForm();
				dispatch(
					getAddress({
						page: currentPage + 1,
						limit: limit,
						state: "Gujarat",
						city: "Ahmedabad",
						sortBy,
						orderBy,
					}),
				);
				handleClose();
				return setId(null);
			} catch (error) {
				toast.error(error.message);
			}
		},
	});

	const handleCityChange = (event, newValue) => {
		setFieldValue("city", newValue);
		searchRef.current.value = "";
		dispatch(
			getAddress({
				page: currentPage + 1,
				limit: limit,
				state: values.state,
				city: newValue,
				sortBy,
				orderBy,
			}),
		);
	};

	const handleState = (event, newValue) => {
		setFieldValue("city", "");
		searchRef.current.value = "";
		getCityData(newValue)
			.then((cities) => {
				setCities(cities);
			})
			.catch((err) => {
				setCities([]);
			});
		setFieldValue("state", newValue);
		dispatch(
			getAddress({
				page: currentPage + 1,
				limit: limit,
				state: newValue,
				city: "",
				sortBy,
				orderBy,
			}),
		);
	};
	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage({ page: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(setCurrentPage({ page: 0 }));
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
	};
	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setValues({
			area: "",
			state: "Gujarat",
			city: "Ahmedabad",
			country: "",
			postalCode: "",
		});
		searchRef.current.value = "";
		getCityData("Gujarat")
			.then((cities) => {
				setCities(cities);
			})
			.catch((err) => {
				setCities([]);
			});
		setId(null);
		setOpen(false);
	};

	const handleStateChange = (event, newValue) => {
		formikInsertUpdate.setFieldValue("city", "");
		getCityData(newValue)
			.then((cities) => {
				setFormCities(cities);
			})
			.catch((err) => {
				setFormCities([]);
			});
		formikInsertUpdate.setFieldValue("state", newValue);
	};

	const commanCity = (state) => {
		getCityData(state)
			.then((cities) => {
				setFormCities(cities);
			})
			.catch((err) => {
				setFormCities([]);
			});
	};

	const handleUpdate = (data) => {
		formikInsertUpdate.setValues({
			area: data.area,
			state: data.state,
			city: data.city,
			country: "India",
			postalCode: data.postalCode,
		});
		commanCity(data.state);
		setId(data.id);
		handleClickOpen();
	};
	const handleInputChange = async (e) => {
		dispatch(
			getAddress({
				page: currentPage + 1,
				limit: limit,
				state: values.state,
				city: values.city,
				sortBy,
				orderBy,
				search: e.target.value,
			}),
		);
	};

	const handleInputChangeByDebounce = debounce(handleInputChange, 500);
	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};
	return (
		<>
			<Container maxWidth="xl">
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="space-between"
					mb={3}
				>
				 <div className="title-button-wrapper">
					<Typography variant="h4" gutterBottom>
						Address Master
						<Typography variant="body2">Total Records : {totalCount}</Typography>
					</Typography>
					{getAddVisible("addressMaster") && (
						<Button
							variant="contained"
							startIcon={<AddIcon />}
							onClick={handleClickOpen}
						>
							Add Address
						</Button>
					)}
					  </div>
				</Stack>
				<form onSubmit={handleSubmit}>
					<Grid container spacing={3} mb={3} className="address_form">
						<Grid item xs={4}>
							<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
								{/* <InputLabel id="demo-simple-select-helper-label">
                                    State
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="State"
                                    size="small"
                                    defaultValue=""
                                    name="state"
                                    value={values.state}
                                    onChange={handleState}
                                >
                                    {states &&
                                            states.map((state) => (
                                                    <MenuItem key={state} value={state}>
                                                            {state}
                                                    </MenuItem>
                                            ))}
                                    </Select> */}
								<Autocomplete
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									label="state"
									size="small"
									name="state"
									onChange={handleState}
									value={values.state}
									options={states || []}
									renderInput={(params) => (
										<TextField {...params} autoComplete="off" label="State" />
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<FormControl size="small" sx={{ minWidth: 120 }} fullWidth>
								{/*<InputLabel id="demo-simple-select-helper-label">
                                    City
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="city"
                                    defaultValue=""
                                    size="small"
                                    name="city"
                                    value={values.city}
                                    onChange={handleCityChange}
                                >
                                    {cities &&
                                        cities.map((city) => (
                                            <MenuItem key={city} value={city}>
                                                {city}
                                            </MenuItem>
                                        ))}
                                </Select> */}
								<Autocomplete
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									label="city"
									size="small"
									name="city"
									options={cities || []}
									renderInput={(params) => (
										<TextField {...params} autoComplete="off" label="City" />
									)}
									value={values.city}
									onChange={handleCityChange}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={4}>
							<TextField
								autoComplete="off"
								inputRef={searchRef}
								fullWidth
								label="Search by Area/Postal Code"
								name="search"
								onChange={handleInputChangeByDebounce}
								variant="outlined"
								size="small"
								inputProps={{ maxLength: 64 }}
							/>
						</Grid>
					</Grid>
				</form>
				{/* {isSearchQuery ? ( */}
				{isSearchQuery || values.city || values.state || values.postalCode ? 
					<Typography variant="body2">{totalRecords} records found</Typography> : "" }
				{/* ) : (
					""
				)} */}
				<Card mt={3}>
					<CustomPagination
						totalPage={totalPages}
						totalCount={totalRecords}
						limit={limit}
						handleChangePage={handleChangePage}
						page={currentPage}
						rowsPerPageOptions={[10,20,40]}
						handleRowsPerPageChange={handleChangeRowsPerPage}
						//filter={isSearchQuery ? true : false}
					/>

					<Scrollbar>
						<TableContainer component={Paper} sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left">
											{/* <TableSortLabel> */}
											Country
											{/* </TableSortLabel> */}
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "state"}
												direction={sortBy === "state" ? orderBy : "asc"}
												onClick={() => handleSorting("state")}
											>
												State
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "city"}
												direction={sortBy === "city" ? orderBy : "asc"}
												onClick={() => handleSorting("city")}
											>
												City
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "area"}
												direction={sortBy === "area" ? orderBy : "asc"}
												onClick={() => handleSorting("area")}
											>
												Area
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "postalCode"}
												direction={sortBy === "postalCode" ? orderBy : "asc"}
												onClick={() => handleSorting("postalCode")}
											>
												Pincode
											</TableSortLabel>
										</TableCell>
										{getEditVisible("addressMaster") && (
											<TableCell align="left" style={{ width: "100px" }}>
												Action
											</TableCell>
										)}
									</TableRow>
								</TableHead>

								<TableBody>
									{ loading ? (
											<TableCell align="center" colSpan={6}  sx = {{pading : '2px'}}>
												<Loader />
											</TableCell>
									) : Array.isArray(data) && data.length === 0 ? (
										<TableRow>
											<TableCell align="center" colSpan={6}>
											No Record(s) Found
											</TableCell>
										</TableRow>
									) : (
										data.map((address, idx) => (
											<TableRow key={idx}>
												<TableCell align="left">
													{address?.country ?? "India"}
												</TableCell>
												<TableCell align="left">{address?.state}</TableCell>
												<TableCell align="left">{address?.city}</TableCell>
												<TableCell align="left">{address?.area}</TableCell>
												<TableCell align="left">
													{address?.postalCode}
												</TableCell>
												{getEditVisible("addressMaster") && (
													<TableCell align="left">
														{getEditVisible("addressMaster") && (
															<IconButton onClick={() => handleUpdate(address)}>
																<EditIcon color="primary" />
															</IconButton>
														)}
													</TableCell>
												)}
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Scrollbar>
				</Card>
				<Dialog open={open} onClose={handleClose}>
					<DialogTitle>Add Address</DialogTitle>
					<form
						onSubmit={formikInsertUpdate.handleSubmit}
						style={{ paddingBottom: "20px" }}
					>
						<DialogContent>
							<Grid container spacing={3}>
								<Grid item xs={12}>
									{/*<InputLabel id="demo-simple-select-helper-label">
                                            State
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="State"
                                            size="small"
                                            error={Boolean(
                                                formikInsertUpdate.touched
                                                    .state &&
                                                    formikInsertUpdate.errors
                                                        .state
                                            )}
                                            defaultValue=""
                                            name="state"
                                            value={
                                                formikInsertUpdate.values.state
                                            }
                                            onChange={handleStateChange}
                                        >
                                            {formStates &&
                                                formStates.map((state) => (
                                                    <MenuItem
                                                        key={state}
                                                        value={state}
                                                    >
                                                        {state}
                                                    </MenuItem>
                                                ))}
                                                </Select> */}

									<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="state"
										size="small"
										name="state"
										onChange={handleStateChange}
										value={formikInsertUpdate.values.state}
										options={formStates || []}
										renderInput={(params) => (
											<TextField
												{...params}
												label="State"
												autoComplete="off"
												error={Boolean(
													formikInsertUpdate.touched.state &&
														formikInsertUpdate.errors.state,
												)}
												helperText={
													formikInsertUpdate.touched.state &&
													formikInsertUpdate.errors.state
												}
											/>
										)}
									/>
								</Grid>
								<Grid item xs={12}>
									{/*<InputLabel id="demo-simple-select-helper-label">
                                            City
                                        </InputLabel>
                                        <Select
                                            labelId="demo-simple-select-helper-label"
                                            id="demo-simple-select-helper"
                                            label="city"
                                            defaultValue=""
                                            size="small"
                                            name="city"
                                            error={Boolean(
                                                formikInsertUpdate.touched
                                                    .city &&
                                                    formikInsertUpdate.errors
                                                        .city
                                            )}
                                            value={
                                                formikInsertUpdate.values.city
                                            }
                                            onChange={
                                                formikInsertUpdate.handleChange
                                            }
                                        >
                                            {formCities &&
                                                formCities.map((city) => (
                                                    <MenuItem
                                                        key={city}
                                                        value={city}
                                                    >
                                                        {city}
                                                    </MenuItem>
                                                ))}
                                                </Select> */}
									<Autocomplete
										labelId="demo-simple-select-helper-label"
										id="demo-simple-select-helper"
										label="city"
										size="small"
										name="city"
										options={formCities || []}
										renderInput={(params) => (
											<TextField
												{...params}
												label="City"
												autoComplete="off"
												error={Boolean(
													formikInsertUpdate.touched.city &&
														formikInsertUpdate.errors.city,
												)}
												helperText={
													formikInsertUpdate.touched.city &&
													formikInsertUpdate.errors.city
												}
											/>
										)}
										value={formikInsertUpdate.values.city}
										onChange={(event, newValue) => {
											formikInsertUpdate.setFieldValue("city", newValue);
										}}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										label="Area"
										autoComplete="off"
										name="area"
										value={formikInsertUpdate.values.area}
										inputProps={{
											maxLength: 512,
											"data-cy": "txt-title-holiday",
										}}
										FormHelperTextProps={{
											"data-cy": "txt-title-err-holiday",
										}}
										onChange={formikInsertUpdate.handleChange}
										variant="outlined"
										size="small"
										error={Boolean(
											formikInsertUpdate.touched.area &&
												formikInsertUpdate.errors.area,
										)}
										helperText={
											formikInsertUpdate.touched.area &&
											formikInsertUpdate.errors.area
										}
									/>
								</Grid>
								<Grid item xs={12}>
									<TextField
										fullWidth
										autoComplete="off"
										label="Postal Code"
										name="postalCode"
										value={formikInsertUpdate.values.postalCode}
										inputProps={{
											maxLength: 8,
											"data-cy": "txt-title-holiday",
										}}
										FormHelperTextProps={{
											"data-cy": "txt-title-err-holiday",
										}}
										onChange={formikInsertUpdate.handleChange}
										variant="outlined"
										size="small"
										error={Boolean(
											formikInsertUpdate.touched.postalCode &&
												formikInsertUpdate.errors.postalCode,
										)}
										helperText={
											formikInsertUpdate.touched.postalCode &&
											formikInsertUpdate.errors.postalCode
										}
									/>
								</Grid>
							</Grid>
						</DialogContent>
						<DialogActions>
							<Button onClick={handleClose}>Cancel</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								loading={isSubmitting}
								data-cy="btn-submit-holiday"
								style={{ marginRight: "17px" }}
							>
								save
							</LoadingButton>
						</DialogActions>
					</form>
				</Dialog>
			</Container>
		</>
	);
};

const componentConfig = {
	component: Department,
	path: "/address-master",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
