import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getAssetDetails = createAsyncThunk(
  "reports/getAssetDetails",
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getAssetDetails", {
        params,
        headers: {
          type: "view",
          module: "reports",
        },
      });
      return res.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);
export const totalAssetDetailCount = createAsyncThunk(
	"reports/totalAssetDetailCount",
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/totalAssetDetailCount", {
				headers: { type:'view',module:'reports' },
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.message || error.message);
		}
	},
);
export const getAssetDetailsForExcel = createAsyncThunk(
  "reports/getAssetDetailsForExcel",
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getAssetDetails", {
        params,
        headers: {
          type: "view",
          module: "reports",
        },
      });
      return res.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);