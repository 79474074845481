import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import NiceModal from "@ebay/nice-modal-react";

import DashboardLayout from "../../layouts/dashboard";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import TabContext from "@mui/lab/TabContext";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Modal from '@mui/material/Modal';
import { useFormik } from "formik";
import { decryption, encryption } from "../../utils/encodeString";
import {
  convertToEmployee,
} from "../../redux/candidate/candidateThunk";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../constants";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// import "./custom-candidate.css";
import { toast } from "react-toastify";
import { EmployeeConfirmationModal } from "../Candidate/CandidateComponent/ViewCandidateComponents/EmployeeConfirmationModal";
// import { DesktopDatePicker, LocalizationProvider } from "@mui/lab";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { TextField } from "@mui/material";
import moment from "moment";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import localStorage from "../../service/localStorage";
import LoadingButton from "@mui/lab/LoadingButton";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import { monthYearFormat, indianDateFormat } from "../../utils/dateFormat";

import { verifyOnboardingProcess, 
        resubmitOnboardingProcess, 
        getOnboardingCandidateDetails 
  } from "../../redux/onboardingCandidate/candidateThunk";
  import { getCollege } from "../../redux/college/collegeThunk";
  import { getCourse } from "../../redux/course/courseThunk";
  import { getOnboardingCandidate } from "../../redux/onboardingCandidate/candidateThunk";
import { addMonths, getDay } from "date-fns";
import { getCurrentMonthHolidayList } from "../../redux/dashboard/dashboardThunk";
import {
  getBloodGroup,
} from "../../redux/BloodGroup/bloddGroupThunk";
import { makeStyles } from "@material-ui/core";
import { useConfirm } from "material-ui-confirm";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: 'none',
  boxShadow: 24,
  gap: 2,
  p: 4,
};


const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
      },
      "&:hover fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
      "&.Mui-focused fieldset": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
      },
    },
    "& .MuiInputLabel-root": {
        opacity: '0.4',
        cursor: 'default',
        '-webkit-text-fill-color': '#919EAB',
        
    },
  },
}));

function ViewOnboardedCandidate() {
  const classes = useStyles();
  const confirm = useConfirm()
  let { id } = useParams();
  id = decryption(id);
  const [tabValue, setTabValue] = React.useState("1");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);
  const [noteError, setNoteError] = useState({ flag: false, title: "" });
  const [joiningDateError, setJoiningDateError] = useState({ flag: false, title: "" });
  const [resubmitNote, setResubmitNote] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("1");
  const [isLoading, setIsLoading] = useState(true);
  const [joiningDate, setJoiningDate] = useState();
  const [productionDate, setProductionDate] = useState();
  const [onboardingData, setOnboardingData] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [educationData, setEducationData]= useState(null);
  const [addressData, setAddressData] = useState(null);
  const [bankData, setBankData] = useState(null);
  const AuthRole = useSelector((state) => state.auth.role);
  const [disableResubmit, setDisableResubmit] = useState(false);
  const [disableVerify, setDisableVerify] = useState(false);
  const [verified, setVerified] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [bloodgroup, setBloodgroup] = useState("");
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [convertEmployeeLoading, setConvertEmployeeLoading] = useState(false)
  const [resubmitLoading, setResubmitLoading] = useState(false);
  const [yesLoading, setYesLoading] = useState(false);

  const data = useSelector(
    (state) => state.onboardingCandidate.data
  );
  const bloodGroupData = useSelector((state) => state.bloodGroup.data);
  let totalYears = 0;
  let totalMonths = 0;

  const { values, setFieldValue} =
	useFormik({
		initialValues: {
			note: ""
		},
	});

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      localStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

useEffect(() => {
  dispatch(
    getBloodGroup({ sortBy: "", orderBy: "" }),
  );
}, []);

useEffect(() => {
  bloodGroupData.map((b) => {
    if(b.id === personalData?.requestData?.bloodGroup)
    {
      setBloodgroup(b?.title)
    }
  })
},[personalData])

  const handleJoinAndProductionDate = (date) => {
    if( new Date(date)?.setHours(0,0,0,0) > new Date()?.setHours(0,0,0,0))
    {
      setJoiningDateError({flag: false, title:""})
    }
    setJoiningDate(date);
    if (getDay(date)) {
      let flag = false;
      let months = 15 > getDay(date) ? 3 : 4;
      let getDate = addMonths(date, months);
      dispatch(
        getCurrentMonthHolidayList({
          date: moment(getDate).format("YYYY-MM") || "",
        })
      )
        .unwrap()
        .then((data) => {
          if (data?.holidayData.length === 0) {
            return setProductionDate(getDate);
          }
          data?.holidayData.sort(function (a, b) {
            return new Date(a.start) - new Date(b.start);
          });

          for (let index = 0; index < data?.holidayData.length; index++) {
            const element = data?.holidayData[index];
            if (
              moment(getDate).format("DD-MM-YYYY") !=
              moment(element.start).format("DD-MM-YYYY")
            ) {
              flag = true;
              setProductionDate(getDate);
              break;
            } else {
              getDate = moment(getDate).add(1, "d");
            }
          }
          if (!flag) {
            setProductionDate(getDate);
          }
        })
        .catch((err) => {
          
        });
    }
  };

  const handleBackLog = (e) => {
    if (e.view.navigation.canGoBack) {
      navigate(-1);
    } else {
      navigate("/employee/joining-employee");
    }
  };

  const handleTabChange = (v) => {
    setTabValue(v);
  };

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getOnboardingCandidateDetails(id))
      .unwrap()
      .then((res) => {
        setOnboardingData(res.data);
        res?.data?.candidateData?.map((e) => {
          if(e.requestType == "personalDetails")
          {
            setPersonalData(e)
          }
          if(e.requestType == "EducationExperience")
          {
            setEducationData(e)
          }
          if(e.requestType == "address")
          {
            setAddressData(e)
          }
          if(e.requestType == "Documents")
          {
            setBankData(e)
          }
        })
        setIsLoading(false);
      })
      .catch((err) => {
        
        navigate("/onboardedCandidates");
      });
  }, [dispatch, id, navigate,open,open2]);

  const getTechnolgyNames = (experience) => {
    if (experience.technologies && experience.technologies.length <= 0) {
      return "-";
    }

    const technologyNames = experience.technologies.map((techs) => {
      return techs.technology?.title;
    });

    return technologyNames.join(", ");
  };
  let convertToEmployeefun = async (joiningDate) => {
    
    if(joiningDate == "")
		{
			setJoiningDateError({ flag: true, title: "This field is required" });
		}
    else if(!joiningDate)
    {
      setJoiningDateError({ flag: true, title: "This field is required" });
    }
		else if(joiningDate != "")
		{
			setJoiningDateError({ flag: false, title: "" });
      
      var defaultDate = new Date();
			defaultDate.setFullYear(1970,0,1)
      if(joiningDate?.setHours(0,0,0,0) == defaultDate.setHours(0,0,0,0))
      {
        setJoiningDateError({ flag: true, title: "This field is required" });
      }
      else if( new Date(joiningDate)?.setHours(0,0,0,0) < new Date()?.setHours(0,0,0,0))
      {
        setJoiningDateError({ flag: true, title: "Date must be greater than today's date" });
      }
      else
      {
        setJoiningDateError({ flag: false, title: "" });
        try {
          const response = await NiceModal.show(EmployeeConfirmationModal);
          setConvertEmployeeLoading(true)
          if (response?.success) {
            const res = await dispatch(
              convertToEmployee({ id, joiningDate, productionDate })
            ).unwrap();
            if (res.status === 200) {
              setConvertEmployeeLoading(false)
              toast.success("Candidate successfully converted to employee");
              return navigate(`/employee/joining-employee`);
            }
          }
          handleClose();
        } catch (error) {
          toast.error(error.message);
          handleClose();
        }
      }
    }
  };

  const handleTotalExperience = (from, to) => {
    let start = moment(from, "YYYY-MM-01");
    let end = moment(to ? to : new Date(), "YYYY-MM-01");
    let monthsCount = moment(end).diff(moment(start), "months");
    monthsCount += 1;
    let floored = Math.floor(monthsCount / 12);
    let finalValue = monthsCount - floored * 12;
    totalYears = totalYears + floored;
    totalMonths = totalMonths + finalValue;
    if (totalMonths >= 12) {
      totalMonths = totalMonths - 12;
      totalYears = totalYears + 1;
    }
    if (floored === 0 && finalValue === 0) {
      return "No experience";
    }
    if (floored > 0 && finalValue === 0) {
      return `${floored} Yrs`;
    }
    if (floored === 0 && finalValue > 0) {
      return `0.${finalValue} Yrs`;
    }
    return `${floored}.${finalValue} Yrs`;
  };

  // const openEmployeeConfirmationModal = useCallback(async () => {
  // 	try {
  // 		const response = await NiceModal.show(EmployeeConfirmationModal);

  // 		if (response?.success) {
  // 			const res = await dispatch(convertToEmployee({ id })).unwrap();

  // 			if (res.status === 200) {
  // 				toast.success("Candidate successfully converted to employee");
  // 				return navigate(`/employee/add/${encryption(res.data.id)}`);
  // 			}
  // 		}
  // 	} catch (error) {
  // 		toast.error(error.message);
  // 	}
  // }, [id, dispatch, navigate]);

  const getDocumentImage = (type) => {
    if (type) {
      if (type.includes("image")) {
        return "/assets/images/image-icon-rounded.png";
      } else if (type.includes("pdf")) {
        return "/assets/images/pdf-icon.png";
      } else if (type.includes("msword")) {
        return "/assets/images/word-doc-icon.png";
      } else {
        return "/assets/images/pdf-icon.png";
      }
    } else {
      return "/assets/images/pdf-icon.png";
    }
  };

  const renderInstituteName = (education) => {
    if (education?.instituteName) {
      return education?.instituteName;
    }
    if (education?.institute && education?.instituteId) {
      return education?.institute?.collegeName;
    }
    return "-";
  };

  const formatDecimal = (num) => {
    let strArray = num.toString().split(".");
    let secondArry = strArray[1]?.split("");
    return secondArry
      ? `${strArray[0]}.${
          secondArry[0] == "0"
            ? `${secondArry[1]}`
            : `${secondArry[0]}${secondArry[1] || 0}`
        }`
      : `${strArray[0]}.00`;
  };

  const handleCancel = () => {
		handleClose2();
		setFieldValue("note", "");
		setNoteError({ flag: false, title: "" });
    setResubmitLoading(false)
	}

  const handleResubmit = (noteValue) => {
		if(noteValue == "")
		{
			setNoteError({ flag: true, title: "Please enter notes" });
		}
		if(noteValue != "")
		{
			setNoteError({ flag: false, title: "" });
		}
		if(noteValue != "")
		{
      dispatch(
					resubmitOnboardingProcess({
						id: id,
						note: noteValue,
					}),
				).then(() => {
					toast.success("Resubmitted Successfully")
          setDisableResubmit(true)
          setYesLoading(false)
          setResubmitLoading(false)
				});
      setShowVerify(true);
			setFieldValue("note", "");
			handleClose2();
		}
	}

  const handleVerify = async () => {
    try{
      await confirm({
          description: "Are you sure you want to Verify ?",
        }); 
        setVerifyLoading(true);
      await dispatch(
        verifyOnboardingProcess(id),
      ).then(() => {
        toast.success("Candidate Verified Successfully")
        setDisableResubmit(true);
        setDisableVerify(true)
        setVerified(true)
        setVerifyLoading(false)
      });
       dispatch(getOnboardingCandidateDetails(id))
         .unwrap()
         .then((res) => {
           setOnboardingData(res.data);
           res?.data?.candidateData?.map((e) => {
             if (e.requestType == "personalDetails") {
               setPersonalData(e);
             }
             if (e.requestType == "EducationExperience") {
               setEducationData(e);
             }
             if (e.requestType == "address") {
               setAddressData(e);
             }
             if (e.requestType == "Documents") {
               setBankData(e);
             }
           });
           setIsLoading(false);
         })
         .catch((err) => {
           navigate("/onboardedCandidates");
         });
      } 
    catch(error){

    }
  }

  const getPresentAddress = (address) => {
    if (
      (address.presentStreetLine1 || address.presentStreetLine2) &&
      // address.presentStreetLine2 &&
      address.presentArea &&
      address.presentCity &&
      address.presentState &&
      address.presentZipCode
    ) {
      return `${address.presentStreetLine1 || ""} ${
        address.presentStreetLine2 || ""
      } , ${address.presentArea} ${address.presentCity} , ${
        address.presentState
      } , ${address.presentZipCode}`;
    } else {
      return "-";
    }
  };
  const getPermanetAddress = (address) => {
    if (
      (address.presentStreetLine1 || address.presentStreetLine2) &&
      // address.permenentStreetLine2 &&
      address.permenentArea &&
      address.permenentCity &&
      address.permenentState &&
      address.permenentZipCode
    ) {
      return `${address.permenentStreetLine1 || ""} ${
        address.permenentStreetLine2 || ""
      } , ${address.permenentArea} ${address.permenentCity} , ${
        address.permenentState
      } , ${address.permenentZipCode}`;
    } else {
      return "-";
    }
  };

  const ClicktoCopy = (value) => {
    var textArea = document.createElement("textarea");

    //
    // *** This styling is an extra step which is likely not required. ***
    //
    // Why is it here? To ensure:
    // 1. the element is able to have focus and selection.
    // 2. if the element was to flash render it has minimal visual impact.
    // 3. less flakyness with selection and copying which **might** occur if
    //    the textarea element is not visible.
    //
    // The likelihood is the element won't even render, not even a
    // flash, so some of these are just precautions. However in
    // Internet Explorer the element is visible whilst the popup
    // box asking the user for permission for the web page to
    // copy to the clipboard.
    //

    // Place in the top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of the white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = value;

    document.body.appendChild(textArea);

    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      var msg = successful ? "successful" : "unsuccessful";
      toast.success("Text Copied!");
    } catch (err) {
      toast.error("Text Not Copied!");
    }

    document.body.removeChild(textArea);
  };

  useEffect(() => {
    data.map((onboarding) => {
      if(onboarding.id == id
        && 
        onboarding?.onboardingProcessStatus?.toLowerCase().replace(/\s+/g, "") === "resubmit")
      {
        setResubmitNote(onboarding?.onboardingProccessNote)
        // setDisableResubmit(true)
      } 
      if(onboarding.id == id
        && 
        onboarding?.onboardingProcessStatus?.toLowerCase().replace(/\s+/g, "") === "verified")
      {
        setDisableVerify(true)
        setDisableResubmit(true)
        setVerified(true);
      } 
    })
  },[])


  if (isLoading) {
    return <Loader></Loader>;
  }

  return (
    <div className="view_employee candidate-details view-onboardedCandidate-main">
      <Container maxWidth="xl">
        <Stack className="view-onboardedCandidate-header" direction="row" justifyContent="flex-end" mb={2}>
          <div className="onboardedCandidate-right">
            {
              // AuthRole === "Super Admin" &&
              !personalData?.requestData?.employeeId &&
                (verified ||
                  onboardingData?.onboardingData?.onboardingProcessStatus
                    ?.toLowerCase()
                    .replace(/\s+/g, "") === "verified") && (
                  <Button
                    variant="contained"
                    // onClick={openEmployeeConfirmationModal}
                    onClick={handleOpen}
                    startIcon={<SwapHorizIcon />}
                  >
                    Convert to Employee
                  </Button>
                )
            }
            <Button
              className="Resubmit-button"
              variant="contained"
              onClick={() => {
                setResubmitLoading(true);
                handleOpen2();
              }}
              loading={resubmitLoading}
              disabled={
                (onboardingData?.onboardingData?.onboardingProcessStatus ==
                "VERIFIED"
                  ? true
                  : false) || disableResubmit
              }
              // disabled={
              //   disableResubmit ||
              //   onboardingData?.onboardingData?.onboardingProcessStatus ==
              //     "VERIFIED"
              //     ? true
              //     : false
              // }
            >
              Resubmit
            </Button>

            <LoadingButton
              className="Verify-button"
              variant="contained"
              onClick={() => {
                handleVerify();
              }}
              loading={verifyLoading}
              disabled={showVerify ||   onboardingData?.onboardingData?.onboardingProcessStatus !=
                "SUBMIT"
                  ? true
                  : false
              }
              // disabled={
              //   disableVerify ||
              //   onboardingData?.onboardingData?.onboardingProcessStatus !=
              //     "SUBMIT"
              //     ? true
              //     : false
              // }
            >
              Verify
            </LoadingButton>
            <Button
              className="back-button"
              variant="contained"
              /*component={RouterLink}
            to="/candidate" */
              // onClick={handleBackLog}
              onClick={() => navigate("/onboardedCandidates")}
              startIcon={<ArrowBackIcon />}
            >
              Back
            </Button>
          </div>
        </Stack>
        {resubmitNote ? (
          <Card style={{ height: "100%" }}>
            <Typography gutterBottom style={{ color: "red" }} ml={2}>
              Note: {resubmitNote}
            </Typography>
          </Card>
        ) : (
          ""
        )}
        <Box className="onboardedCandidate-detail-box" sx={{ flexGrow: 1 }}>
          <Grid className="onboardedCandidate-container" container spacing={2}>
            {/* /right personal details */}
            <Grid item xs={4} mt={2}>
              <Card className="gray-box Employee_Detail_Content onboardedCandidate-card" style={{ height: "100%" }}>
                <Container maxWidth="xl">
                  <div className="profile-info-part">
                    <div className="profile-image-part">
                      <Avatar
                        // src={
                        //   `${BASE_URL}/${personalData?.requestData?.employeeImage?.url}` || ""
                        // }
                        src={
                          personalData?.requestData?.profile?.length > 0
                            ? `${BASE_URL}/${personalData?.requestData?.profile}`
                            : personalData?.requestData?.employeeImage?.url ||
                              ""
                        }
                        alt={personalData?.requestData?.firstName}
                        sx={{
                          width: "110px",
                          height: "110px",
                          fontSize: "2.5rem",
                          marginTop: "10px",
                        }}
                      ></Avatar>
                    </div>
                    <Typography variant="h4" align="center" mt={1}>
                      {personalData?.requestData?.firstName}{" "}
                      {personalData?.requestData?.lastName}
                    </Typography>
                    <div className="inner-info-part" style={{ marginTop: "0" }}>
                      {personalData?.requestData?.contactNumber && (
                        <Stack
                          style={{ marginTop: "5px" }}
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          my={3}
                        >
                          <p>
                            <PhoneIphoneIcon color="primary" />
                            <>
                              <Link
                                href={`tel:(+91) ${personalData?.requestData?.contactNumber}`}
                                className="profile-specific-info"
                              >
                                +91 {personalData?.requestData?.contactNumber}
                              </Link>
                              <img
                                src="/assets/images/vfile.svg"
                                onClick={() => {
                                  ClicktoCopy(
                                    "+91 " +
                                      personalData?.requestData?.contactNumber
                                  );
                                  /* navigator.clipboard.writeText(
																		"+91" + employee?.contact?.contactNumber,
																	); */
                                }}
                                style={{ cursor: "pointer" }}
                              ></img>

                              {personalData?.requestData
                                ?.alternateContactNumber ? (
                                <>
                                  <p>, </p>
                                  <Link
                                    href={`tel:(+91) ${personalData?.requestData?.alternateContactNumber}`}
                                    className="profile-specific-info"
                                  >
                                    +91{" "}
                                    {
                                      personalData?.requestData
                                        ?.alternateContactNumber
                                    }
                                  </Link>
                                  <img
                                    src="/assets/images/vfile.svg"
                                    onClick={() => {
                                      ClicktoCopy(
                                        "+91 " +
                                          personalData?.requestData
                                            ?.alternateContactNumber
                                      );
                                      /* navigator.clipboard.writeText(
                                          "+91" + employee?.contact?.contactNumber,
                                        ); */
                                    }}
                                    style={{ cursor: "pointer" }}
                                  ></img>
                                </>
                              ) : (
                                ""
                              )}
                            </>
                          </p>
                        </Stack>
                      )}
                      {/* <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Chip
                          // label={getInterviewStatus(onboardingData)}
                          color="primary"
                        />
                      </Stack> */}
                    </div>
                    <Stack
                      className="social-image-icon"
                      direction="row"
                      justifyContent={"center"}
                      style={{ marginBottom: "20px" }}
                    >
                      {personalData?.requestData?.email && (
                        <a href={`mailto:${personalData?.requestData?.email}`}>
                          <Box
                            component="img"
                            src="/assets/images/mail.svg"
                            className="mail-img"
                            style={{
                              width: "16px !important",
                            }}
                          />
                        </a>
                      )}
                    </Stack>
                  </div>
                </Container>
              </Card>
            </Grid>
            {/* /right personal details */}
            {/* left personal details */}
            <Grid className="onboardedCandidate-right" item xs={8} mt={2}>
              <Card className="gray-box Employee_Detail_Content onboardedCandidate-card" style={{ minHeight: "100%" }}>
                <Container>
                  <TabContext value={value}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        style={{ maxWidth: "100%", flexBasis: "100%" }}
                      >
                        <Typography
                          variant="h6"
                          className="information-card-heading"
                          style={{
                            marginTop: "10px",
                            fontWeight: "700",
                            fontSize: "1.125rem",
                            marginBottom: "20px",
                          }}
                        >
                          Personal Information
                        </Typography>
                        <div className="address-blk">
                          <div className="address-sub-section">
                            <div className="address-div">
                              <span>First Name</span>
                              <p>
                                {personalData?.requestData?.firstName
                                  ? personalData?.requestData?.firstName
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Middle Name</span>
                              <p>
                                {personalData?.requestData?.middleName
                                  ? personalData?.requestData?.middleName
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Last Name</span>
                              <p>
                                {personalData?.requestData?.lastName
                                  ? personalData?.requestData?.lastName
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Gender</span>
                              <p>
                                {personalData?.requestData?.gender
                                  ? personalData?.requestData?.gender == "M"
                                    ? "Male"
                                    : "Female"
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Date Of Birth</span>
                              <p>
                                {personalData?.requestData?.dateOfBirth
                                  ? indianDateFormat(
                                      personalData?.requestData?.dateOfBirth
                                    )
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Blood Group</span>
                              <p>
                                {/* {personalData?.requestData?.bloodGroup
                                  ? personalData?.requestData?.bloodGroup
                                  : "-"} */}
                                {bloodgroup ? bloodgroup : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Marital Status</span>
                              <p>
                                {personalData?.requestData?.maritalStatus
                                  ? personalData?.requestData?.maritalStatus
                                  : "-"}
                              </p>
                            </div>
                          </div>

                          <div className="address-sub-section">
                            <div className="address-div">
                              <span>Spouse Name</span>
                              <p>
                                {personalData?.requestData?.spouseName
                                  ? personalData?.requestData?.spouseName
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>LinkedIn</span>
                              <p>
                                {personalData?.requestData?.linkedIn ? (
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    href={
                                      personalData?.requestData?.linkedIn
                                        ? personalData?.requestData?.linkedIn
                                        : "-"
                                    }
                                  >
                                    {personalData?.requestData?.firstName}{" "}
                                    {personalData?.requestData?.lastName}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>E-mail</span>
                              <p>
                                {personalData?.requestData?.email
                                  ? personalData?.requestData?.email
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Skype</span>
                              <p>
                                {personalData?.requestData?.skype
                                  ? personalData?.requestData?.skype
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>GitHub Profile</span>
                              <p>
                                {personalData?.requestData?.github
                                  ? personalData?.requestData?.github
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Portfolio Website</span>
                              <p>
                                {personalData?.requestData?.portfolioWebsite
                                  ? personalData?.requestData?.portfolioWebsite
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabContext>
                </Container>
              </Card>
            </Grid>
            {/* left personal details */}
          </Grid>
        </Box>
        <Box className="onboardedCandidate-detail-box box-2" sx={{ flexGrow: 1 }}>
          <Grid className="onboardedCandidate-container" container spacing={2}>
            {/* /right personal details */}
            <Grid item xs={4} mt={2} mb={2}>
              <Card className="gray-box Employee_Detail_Content onboardedCandidate-card" style={{ height: "100%" }}>
                <Container>
                  <TabContext value={value}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        style={{ maxWidth: "100%", flexBasis: "100%" }}
                      >
                        <Typography
                          variant="h6"
                          className="information-card-heading"
                          style={{
                            marginTop: "10px",
                            fontWeight: "700",
                            fontSize: "1.125rem",
                            marginBottom: "20px",
                          }}
                        >
                          Address Details
                        </Typography>
                        <div className="address-blk ">
                          <div className="address-sub-section general_sec">
                            <div className="address-div">
                              <span>Present Address</span>
                              <p>
                                {addressData?.requestData
                                  ? getPresentAddress(addressData?.requestData)
                                  : "-"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>Permanent Address</span>{" "}
                              <p>
                                {addressData?.requestData
                                  ? getPermanetAddress(addressData?.requestData)
                                  : "-"}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabContext>
                </Container>
              </Card>
            </Grid>
            {/* /right personal details */}
            {/* left personal details */}
            <Grid item xs={8} mt={2} mb={2}>
              <Card className="gray-box Employee_Detail_Content onboardedCandidate-card" style={{ minHeight: "100%" }}>
                <Container>
                  <TabContext value={value}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={10}
                        style={{ maxWidth: "100%", flexBasis: "100%" }}
                      >
                        <Typography
                          variant="h6"
                          className="information-card-heading"
                          style={{
                            marginTop: "10px",
                            fontWeight: "700",
                            fontSize: "1.125rem",
                            marginBottom: "20px",
                          }}
                        >
                          Bank Details
                        </Typography>
                        <div className="address-blk">
                          <div className="address-sub-section">
                            <div className="address-div">
                              {" "}
                              <span>Bank Name</span>
                              <p>
                                {bankData?.requestData?.bankName ||
                                  "UNION BANK OF INDIA"}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>AC No.</span>
                              <p>
                                {bankData?.requestData?.bankAccountNumber ? (
                                  <>
                                    {bankData?.requestData?.bankAccountNumber}
                                  </>
                                ) : (
                                  "-"
                                )}
                              </p>
                            </div>
                          </div>
                          <div className="address-sub-section">
                            <div className="address-div">
                              <span>Branch</span>
                              <p>
                                {bankData?.requestData?.bankBranch ? (
                                  <>{bankData?.requestData?.bankBranch}</>
                                ) : (
                                  "-"
                                )}
                              </p>
                            </div>
                            <div className="address-div">
                              <span>IFSC Code</span>
                              <p>
                                {bankData?.requestData?.IFSCCode ? (
                                  <>
                                    {" "}
                                    {bankData?.requestData?.IFSCCode}
                                    <img
                                      src="/assets/images/vfile.svg"
                                      onClick={() => {
                                        ClicktoCopy(
                                          bankData?.requestData?.IFSCCode
                                        );
                                        // navigator.clipboard.writeText(
                                        //   bankData?.requestData?.bankIFSCCode,
                                        // );
                                      }}
                                      style={{ cursor: "pointer" }}
                                    ></img>
                                  </>
                                ) : (
                                  "-"
                                )}{" "}
                              </p>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </TabContext>
                </Container>
              </Card>
            </Grid>
            {/* left personal details */}
          </Grid>
        </Box>

        <Box className="onboardedCandidate-detail-box box-3" sx={{ flexGrow: 1 }}>
          <Grid className="onboardedCandidate-container grid_container" container spacing={2}>
            <Grid className="onboardedCandidate-col" item xs={12}>
              <Box>
                <TabContext value={tabValue}>
                  <Card className="left-heading-part">
                    <div className="tab_list">
                      <TabList
                        sx={{
                          borderColor: "divider",
                        }}
                        // onChange={handleChange}
                        orientation="horizontal"
                        className="tab-list-part"
                        onChange={(e, v) => handleTabChange(v)}
                      >
                        <Tab
                          label="Experience"
                          // label={`Experience (${educationData?.requestData?.totalExperience} Yrs)`}
                          value="1"
                          direction="row"
                        />
                        <Tab label="Education" value="2" direction="row" />
                        <Tab label="Documents" value="3" direction="row" />
                      </TabList>
                    </div>

                    <TabPanel className="tab_list_detail" value="1">
                      <div>
                        {Array.isArray(
                          educationData?.requestData?.experience
                        ) &&
                        educationData?.requestData?.experience?.length === 0
                          ? "No Experience Found"
                          : educationData?.requestData?.experience?.map(
                              (exp, idx) => (
                                <div className="tab_inner">
                                  <div className="tab_item">
                                    <span>{exp?.designationName ?? "-"}</span>
                                    <p>
                                      {exp?.companyName
                                        ? exp?.companyName
                                        : "-"}
                                    </p>
                                  </div>
                                  <div className="tab_item tab_flex">
                                    <Typography variant="h6">
                                      {monthYearFormat(exp?.from)} -{" "}
                                      {exp?.to == null && exp?.isPresent == true
                                        ? "Present"
                                        : monthYearFormat(exp?.to)}
                                    </Typography>
                                    <span>
                                      {handleTotalExperience(
                                        exp?.from,
                                        exp?.to
                                      )}
                                    </span>
                                  </div>
                                </div>
                              )
                            )}
                      </div>
                    </TabPanel>

                    <TabPanel className="tab_list_detail" value="2">
                      <div>
                        {Array.isArray(educationData?.requestData?.education) &&
                        educationData?.requestData?.education?.length === 0
                          ? "No Education Found"
                          : educationData?.requestData?.education?.map(
                              (education, idx) => (
                                <div className="tab_inner">
                                  <div className="tab_item">
                                    <span>{education?.courseName}</span>
                                    <p>{education.educationTypeName}</p>
                                    <p>{renderInstituteName(education)}</p>
                                  </div>
                                  <div className="tab_item tab_flex">
                                    <Typography variant="h6">
                                      {education?.percentage} %
                                    </Typography>
                                    <span>{education?.passingYear}</span>
                                  </div>
                                </div>
                              )
                            )}
                      </div>
                    </TabPanel>
                    <TabPanel className="tab_list_detail" value="3">
                      <div>
                        <div>
                          <b>Personal Documents</b>
                        </div>
                        {
                          onboardingData?.candidateDocument
                            ?.adharCardNumberImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Aadhar Card</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.adharCardNumberImage[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.adharCardNumberImage[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }

                        {
                          onboardingData?.candidateDocument
                            ?.panCardNumberImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>PAN Card</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.panCardNumberImage[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.panCardNumberImage[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }
                        {
                          onboardingData?.candidateDocument?.passportImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Passport</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.passportImage[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.passportImage[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }

                        {
                          onboardingData?.candidateDocument
                            ?.presentAddressImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Present Address</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.presentAddressImage[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.presentAddressImage[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }

                        {
                          onboardingData?.candidateDocument
                            ?.permentAddressImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Permanent Address</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.permentAddressImage[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.permentAddressImage[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }
                        {/* {onboardingData?.candidateDocument?.educationCertificatesImage &&
                          // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Education Certificates</span>
                              </div>
                              <div className="tab_item tab_flex">
                              {onboardingData?.candidateDocument?.educationCertificatesImage.length > 0 && 
                               onboardingData?.candidateDocument?.educationCertificatesImage?.map((edu) => { return (
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    edu?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      edu?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a> )
                              })}
                                
                              </div>
                            </div>
                          // )
                          } */}
                        <div></div>
                        <div style={{ marginTop: "20px" }}>
                          <b>Education Documents</b>
                        </div>
                        {onboardingData?.candidateDocument
                          ?.educationCertificatesImage &&
                          Array.isArray(
                            onboardingData?.candidateDocument
                              ?.educationCertificatesImage
                          ) &&
                          onboardingData?.candidateDocument
                            ?.educationCertificatesImage.length > 0 &&
                          onboardingData?.candidateDocument?.educationCertificatesImage.map(
                            (certificate, index) => (
                              <div className="tab_inner">
                                <div className="tab_item">
                                  <span>
                                    {" "}
                                    Certificate{" "}
                                    {
                                      certificate?.name
                                        ?.split("Certificate_")[1]
                                        ?.split(".")[0]
                                        ?.split("_")[0]
                                    }
                                  </span>
                                </div>
                                <div className="tab_item tab_flex">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={certificate?.webViewLink}
                                    className="document-img-anchor"
                                  >
                                    <Box
                                      component="img"
                                      src={getDocumentImage(
                                        certificate.mimeType
                                      )}
                                      className="documnet-img-pdf"
                                      width={17}
                                    />
                                  </a>
                                </div>
                              </div>
                            )
                          )}

                        <div style={{ marginTop: "20px" }}>
                          <b>Previous Employer Documents</b>
                        </div>
                        {
                          onboardingData?.candidateDocument
                            ?.form16OfPreviousEmployer && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Form 160</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.form16OfPreviousEmployer[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.form16OfPreviousEmployer[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }
                        {
                          onboardingData?.candidateDocument?.salarySlip && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Salary Slip</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.salarySlip[0]?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.salarySlip[0]?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }
                        {
                          onboardingData?.candidateDocument
                            ?.latestExperienceLetterImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Latest Experience Letter</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.latestExperienceLetterImage[0]
                                      ?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.latestExperienceLetterImage[0]
                                        ?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }
                        {
                          onboardingData?.candidateDocument
                            ?.latestRelievingLetterImage && (
                            // employee?.documents?.aadharCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Latest Relieving Letter</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    onboardingData?.candidateDocument
                                      ?.latestRelievingLetterImage[0]
                                      ?.webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      onboardingData?.candidateDocument
                                        ?.latestRelievingLetterImage[0]
                                        ?.mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )
                          // )
                        }

                        {/* {employee?.documents?.panCardImage &&
                          employee?.documents?.panCardImage.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Pan Card</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.documents?.panCardImage[0]
                                      .webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.documents?.panCardImage[0]
                                        .mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )}

                        {employee?.checklists?.residentialAddress &&
                          employee?.checklists?.residentialAddress?.present
                            ?.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Present Address</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.checklists?.residentialAddress
                                      ?.present[0].webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.checklists?.residentialAddress
                                        ?.present[0].mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )} 

                        {/* {employee?.checklists?.residentialAddress &&
                          employee?.checklists?.residentialAddress?.permanent
                            ?.length > 0 && (
                            <div className="tab_inner">
                              <div className="tab_item">
                                <span>Permanent Address</span>
                              </div>
                              <div className="tab_item tab_flex">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  href={
                                    employee?.checklists?.residentialAddress
                                      ?.permanent[0].webViewLink
                                  }
                                  className="document-img-anchor"
                                >
                                  <Box
                                    component="img"
                                    src={getDocumentImage(
                                      employee?.checklists?.residentialAddress
                                        ?.permanent[0].mimeType
                                    )}
                                    className="documnet-img-pdf"
                                    width={17}
                                  />
                                </a>
                              </div>
                            </div>
                          )}
                        {employee?.checklists?.bachelorCertificates &&
                          Array.isArray(
                            employee?.checklists?.bachelorCertificates
                          ) &&
                          employee?.checklists?.bachelorCertificates.length >
                            0 &&
                          employee?.checklists?.bachelorCertificates.map(
                            (certificate, index) => (
                              <div className="tab_inner">
                                <div className="tab_item">
                                  <span>
                                    {" "}
                                    Certificate{" "}
                                    {
                                      certificate.name
                                        .split("Certificate_")[1]
                                        .split(".")[0]
                                        .split("_")[0]
                                    }
                                  </span>
                                </div>
                                <div className="tab_item tab_flex">
                                  <a
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    href={certificate.webViewLink}
                                    className="document-img-anchor"
                                  >
                                    <Box
                                      component="img"
                                      src={getDocumentImage(
                                        certificate.mimeType
                                      )}
                                      className="documnet-img-pdf"
                                      width={17}
                                    />
                                  </a>
                                </div>
                              </div>
                            )
                          )}

                        {employee?.documents?.aadharCardImage === null &&
                          employee?.documents?.panCardImage === null &&
                          employee?.checklists?.residentialAddress?.present ===
                            null &&
                          employee?.checklists?.residentialAddress
                            ?.permanent === null &&
                          (employee?.checklists?.bachelorCertificates ===
                            null ||
                            (Array.isArray(
                              employee?.checklists?.bachelorCertificates
                            ) &&
                              employee?.checklists?.bachelorCertificates
                                .length === 0)) && (
                            <div>No Documents uploaded</div>
                                )} */}
                      </div>
                    </TabPanel>
                  </Card>
                </TabContext>
                <TabContext value={tabValue}></TabContext>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        shouldCloseOnOverlayClick={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack spacing={2} sx={style}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              label="Joining/Probation Date"
              minDate={new Date()}
              maxDate={
                new Date(
                  moment(new Date()).add(3, "months").format("YYYY,MM,DD")
                )
              }
              value={joiningDate ? joiningDate : ""}
              inputFormat="dd/MM/yyyy"
              defaultDate={new Date()}
              onChange={(newValue) => handleJoinAndProductionDate(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  classes={{
                    root: classes.root,
                  }}
                  inputProps={{
                    ...params.inputProps,
                    disabled: true, // Add the disabled attribute to the input element
                  }}
                  fullWidth
                  size="small"
                  helperText={
                    joiningDateError?.title && joiningDateError?.title
                  }
                  error={Boolean(
                    joiningDateError?.flag && joiningDateError?.flag
                  )}
                />
              )}
            />
          </LocalizationProvider>

          <Stack
            sx={{ display: "flex", flexDirection: "row", gap: 2 }}
            justifyContent="flex-end"
            alignItems="center"
          >
            <LoadingButton
              variant="contained"
              loading={convertEmployeeLoading}
              onClick={() => convertToEmployeefun(joiningDate)}
            >
              Save
            </LoadingButton>
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        keyboard={false}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Stack sx={style2}>
          Please enter note...
          <TextField
            fullWidth
            multiline
            label="Note"
            aria-label="Note..."
            minRows={3}
            // placeholder="Minimum 3 rows"
            /* className="custom-textarea" */
            // {...getFieldProps("note")}
            inputProps={{
              inputComponent: TextareaAutosize,
              maxLength: 512,
              style: {
                resize: "auto",
              },
            }}
            onChange={(e) => {
              setFieldValue("note", e.target.value || "");
              if (e.target.value) {
                setNoteError({ flag: false, title: "" });
              }
              if (e.target.value == "") {
                setNoteError({ flag: true, title: "Please enter notes" });
              }
            }}
            helperText={noteError?.title && noteError?.title}
            error={Boolean(noteError?.flag && noteError?.flag)}
          />
          <Stack direction="row" alignItems="right" justifyContent="flex-end">
            <Button
              variant="contained"
              // type="submit"
              onClick={handleCancel}
              sx={{ marginRight: "15px" }}
            >
              Cancel
            </Button>
            <Button
              // component={RouterLink}
              // onClick={() => handleResubmit(values.note)}
              onClick={() => {
                handleResubmit(values.note);
              }}
              variant="contained"
            >
              Yes
            </Button>
          </Stack>
        </Stack>
      </Modal>
    </div>
  );
}

const componentConfig = {
  component: ViewOnboardedCandidate,
  path: "/onboardedCandidates/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "candidate",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
