import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useDispatch, useSelector } from "react-redux";

import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import Chip from "@mui/material/Chip";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";
import LanguageIcon from "@mui/icons-material/Language";
import CompanyDetailsModal from "./CompanyComponent/CompanyDetailsModal";
import NiceModal from "@ebay/nice-modal-react";
import {
	getCompany,
	deleteById,
	getTotalCompanyCount,
} from "../../redux/company/companyThunk";
import { debounce } from "lodash";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import {
	setOrderBy,
	setSortBy,
	setCurrentPage,
	setLimit,
	setStatus,
} from "../../redux/company/companySlice";
import { encryption } from "../../utils/encodeString";
import invertDirection from "../../utils/invertDirection";
import CustomPagination from "../../components/Pagination";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
function Company() {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchValue, setSearchValue] = useState("");
	const inputRef = React.useRef(null);
	const getUserPermission = async (Id) => {
		try {
			const res = await dispatch(
				getUserPermissionByRoll({
					id: Id,
				}),
			).unwrap();
			LocalStorage.setItem(
				"userPermissions",
				encryption(JSON.stringify(res.data)),
			);
				// console.log(res.data,"db");
		} catch (error) {
			
		}
	};
	useEffect(() => {
		let roleId=LocalStorage.getItem("roleId");
		getUserPermission(roleId)
	},[dispatch])
	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	useEffect(() => {
		if (!getViewVisible("company")) {
			dispatch(getCompany({ page: currentPage + 1, limit, sortBy, orderBy, search: searchValue, }));
			navigate("/dashboard");
		}
	}, []);

	const {
		data,
		totalRecords,
		totalPages,
		currentPage,
		loading,
		sortBy,
		orderBy,
		status,
		limit,
		error,
	} = useSelector((state) => state.company);
	// const [searchValue, setSearchValue] = useState("");
	const totalCounts = useSelector((state) => state.companyCount.totalCounts);
	const [isSearching, setIsSearching] = useState(false);
	useEffect(() => {
		// if (status === "idle") {
			if(!error){
			dispatch(getCompany({ page: currentPage + 1, limit, sortBy, orderBy, search: searchValue, }));
		}
		// }
	}, [dispatch, limit, currentPage, status, error, sortBy, orderBy]);

	// useEffect(() => {
	// 	return () => {
	// 		dispatch(getCompany({ page: currentPage + 1, limit, sortBy, orderBy }));
	// 	};
	// }, []);

	useEffect(() => {
		dispatch(getTotalCompanyCount({}));
	}, []);

	const handleDelete = async (id, name) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${name}?`,
			});
			await dispatch(deleteById(id)).unwrap();
			dispatch(setStatus({ status: "idle" }));
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleChangePage = (event, newPage) => {
		dispatch(setCurrentPage({ page: newPage }));
	};

	const handleChangeRowsPerPage = (event) => {
		dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
		dispatch(setCurrentPage({ page: 0 }));
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};
	const showDetailsModal = async (id) => {
		try {
			await NiceModal.show(CompanyDetailsModal, { id: id });
		} catch (error) {}
	};



    const onDeleteChip = () => {
		// console.log( "value",inputRef.current.value)
	    	setSearchValue("")
	 	  setIsSearching(false);
		  inputRef.current.value = "";
		  dispatch(getCompany({ page: currentPage + 1, limit, sortBy, orderBy, search: ""}));
	  };
	const handleInputChange = async (e) => {
		setIsSearching(true);
		dispatch(setCurrentPage({page:0}))
		setSearchValue(e?.target?.value);
		if (e.target.value != "") {
			dispatch(
				getCompany({
					page: currentPage + 1,
					limit: limit,
					sortBy,
					orderBy,
					search: e.target.value,
				}),
			);
		} else {
			setIsSearching(false);
			dispatch(getCompany({ page: currentPage + 1, limit, sortBy, orderBy }));
		}
		setSearchValue(e?.target?.value);
		// setFieldValue("search", e?.target?.value || "");
		// handleSubmit()
	};

	const handleInputChangeByDebounce = debounce(handleInputChange, 300);
	
	return (
		<Container className="Companies-wrapper-Container" maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
			<div className="title-button-wrapper Companies-detail-title">
				<Typography variant="h4" gutterBottom>
					Companies ({totalCounts})
					{/* <Typography variant="body2">Total Records : {totalCounts}</Typography> */}
				</Typography>
				{getAddVisible("company") && (
					<Stack direction="row" alignItems="center">
						<Button
						className="header-add-button"
							component={Link}
							to="/company/add"
							variant="contained"
							// startIcon={<AddIcon />}
						>
							<img src="/assets/images/add-circle.svg" />
							Add Company
						</Button>
					</Stack>
				)}
			</div>
			</Stack>
			<Card className="Companies-search-wrapper">
				<Container className="Companies-search-wrapper-container" maxWidth="xl">
					<Grid
						className="employee-search-detail"
						container
						spacing={3}
					>
						<Grid className="employee-search-detail-grid" item xs={12}>
							<TextField
								size="small"
								fullWidth
								label="Search by Name/City"
								onChange={handleInputChangeByDebounce}
								inputRef = {inputRef} 
							/>
						</Grid>
					</Grid>
				</Container>
			</Card>

			<Stack
			className="search-filter"
			direction="row"
			alignItems="start">

		  {
			isSearching ? (<Chip
			className="search-filter-detail"
				label={searchValue}
				color="primary"
				size="small"
				style={{ marginRight: "5px" }}
				variant="filled"
				onDelete={() => onDeleteChip()}
			  />) : ("")
		  }
      </Stack>

			{isSearching ? (
				<Typography className="record-found-text" variant="body2" sx={{ mb: 1 }}>
					{totalRecords} records found
				</Typography>
			) : (
				""
			)}


			{loading ? (
				<Loader />
			) : (
				<Card>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table className="Company-detail-table grid-table">
								<TableHead>
									<TableRow>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "name"}
												direction={sortBy === "name" ? orderBy : "asc"}
												onClick={() => handleSorting("name")}
											>
												Name
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "contactEmail"}
												direction={sortBy === "contactEmail" ? orderBy : "asc"}
												onClick={() => handleSorting("contactEmail")}
											>
												Contact Email
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">Contact Number</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "city"}
												direction={sortBy === "city" ? orderBy : "asc"}
												onClick={() => handleSorting("city")}
											>
												City
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">Website</TableCell>
										{(getEditVisible("company") ||
											getDeleteVisible("company")) && (
											<TableCell className="th-action-buttons" align="center">
												Actions
											</TableCell>
										)}
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(data) && data.length === 0 ? (
										<TableRow>
											<TableCell className="No-Record-text" align="center" colSpan={4}>
											No Record(s) Found
											</TableCell>
										</TableRow>
									) : (
										data.map((company, idx) => (
											<TableRow key={idx}>
												<TableCell
													align="left"
													onClick={() => showDetailsModal(company.id)}
													style={{
														cursor: "pointer",
													}}
												>
													{company.name}
												</TableCell>
												<TableCell align="left">
													{company.contactEmail}
												</TableCell>
												<TableCell align="left">
													{company.contactNumber}
												</TableCell>
												<TableCell align="left">{company.city}</TableCell>
												<TableCell align="left">
													<a
														href={company.website}
														target="_blank"
														style={{
															color: "inherit",
														}}
													>
														<LanguageIcon color="primary" />
													</a>
												</TableCell>

												{(getEditVisible("company") ||
													getDeleteVisible("company")) && (
													<TableCell className="td-action-button" align="right">
														<div className="action-button">
															{getEditVisible("company") && (
																<span>
																	<IconButton
																		color="primary"
																		component={Link}
																		to={`/company/add/${encryption(
																			company.id,
																		)}`}
																	>
																		{/* <EditIcon /> */}
																		<img src="assets/images/edit.svg" title="Edit" />
																	</IconButton>
																</span>
															)}
															{getDeleteVisible("company") && (
																<span>
																	<IconButton
																		onClick={() =>
																			handleDelete(company.id, company.name)
																		}
																	>
																		{/* <DeleteIcon color="error" /> */}
																		<img src="assets/images/trash-gray.svg" title="trash" />
																	</IconButton>
																</span>
															)}
														</div>
													</TableCell>
												)}
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Scrollbar>
					<CustomPagination
						totalPage={totalPages}
						totalCount={totalRecords}
						limit={limit}
						handleChangePage={handleChangePage}
						page={currentPage}
						rowsPerPageOptions={[10,20, 40]}
						handleRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			)}
		</Container>
	);
}

const componentConfig = {
	component: Company,
	path: "/company",
	public: false,
	layout: DashboardLayout,
	group: "candidate",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
