import { useState } from "react";
import { IconButton, TextField, Card, CardContent, Grid } from "@mui/material";
import FolderIcon from "@mui/icons-material/Folder";
import CancelIcon from "@material-ui/icons/Cancel";

function AddFolder({ setAddFolder, createNewFolder }) {
  const [folderName, setFolderName] = useState("");
  const [error, setError] = useState(false);

  const handleInputChange = (event) => {
    setFolderName(event.target.value);
    if (event.target.value.trim() !== "") {
      setError(false);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (folderName.trim() === "") {
        setError(true);
      } else {
        createNewFolder(folderName.trim());
        setAddFolder(false);
      }
    }
  };

  return (
    <Card
      className="folder_card"
      sx={{
        backgroundColor: "rgb(249 249 249)",
        cursor: "pointer",
      }}
    >
      <CardContent className="addfolder-gridcontent">
        <Grid
        className="addfolder-contentdetail"
          container
          spacing={1}
          alignItems="center"
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Grid style={{ padding: "0"}} item>
            {/* <FolderIcon sx={{ fontSize: 40 }} /> */}
            <img src="/assets/images/folder.svg" style={{ width: "60px", marginTop: "-4px", marginLeft: "-6px"  }} />
          </Grid>
          <IconButton className="close-icon" onClick={() => setAddFolder(false)}>
            <CancelIcon />
          </IconButton>
          <TextField
          className="addfolder-text"
            id="standard-basic"
            label="Name"
            variant="standard"
            value={folderName}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            error={error}
            helperText={error ? "Folder name is required" : ""}
            autoComplete="off"
          />
        </Grid>
      </CardContent>
    </Card>
  );
}

export default AddFolder;
