import * as Yup from "yup";

const itemSchema = Yup.object().shape({
  name: Yup.string()
  .trim("Item name cannot contain only whitespace")
  .strict(true)
    .required("Item name is required")
    .matches(/^[a-zA-Z.\s]+$/, "Only alphabets are allowed for this field ")
    .max(256, "Item name is too long")
    .nullable(),
  attributes: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when("isDeleted", {
        is: false,
        then: Yup.string()
         .trim("Attribute name cannot contain only whitespace")
         .strict(true)
          .required("Attribute name is required")
          .max(256, "Attrbute name is too long")
          .nullable(),
        otherwise: Yup.string()
        .trim("Attribute name cannot contain only whitespace")
        .strict(true)
        .nullable()
        .notRequired(),
      }),
      dataType: Yup.string()
      .when("isDeleted", {
        is: false,
        then: Yup.string()
        .trim("Data type cannot contain only whitespace")
        .strict(true)
          .required("Data type is required")
          .max(64, "Data type is too long")
          .nullable(),
        otherwise: Yup
        .string()
        .trim("Data type cannot contain only whitespace")
        .strict(true)
        .nullable()
        .notRequired(),
      }),

      unit: Yup.string()
      .when("isDeleted", {
        is: false,
        then: Yup.string()
          .required("Unit is required")
          .max(64, "Unit is too long")
          .nullable(),
        otherwise: Yup.string().notRequired(),
      }),
    })
  ),
});

export default itemSchema;
