import React, { useState, useEffect } from "react";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { useDispatch } from "react-redux";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { getById } from "../../../redux/company/companyThunk";
import Loader from "../../../components/Loader";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const CompanyDetailsModal = NiceModal.create(({ id }) => {
    const [companyData, setCompanyData] = useState({});
    const [loading, setLoading] = useState(true);

    const modal = useModal();
    const dispatch = useDispatch();

    useEffect(() => {
        const getCompanydata = async () => {
            try {
                const response = await dispatch(getById(id)).unwrap();
                setCompanyData(response);
            } catch (error) {
                setCompanyData({});
            } finally {
                setLoading(false);
            }
        };

        getCompanydata();
    }, [dispatch, id]);

    return (
        <Dialog
            TransitionComponent={Transition}
            open={modal.visible}
            onClose={() => modal.hide()}
            TransitionProps={{
                onExited: () => modal.remove(),
            }}
            fullWidth
        >
            {loading ? (
                <div style={{ marginTop: "20px" }}>
                    <Loader />
                </div>
            ) : Object.keys(companyData).length === 0 ? (
                <DialogTitle id="alert-dialog-slide-title">
                    No college found
                </DialogTitle>
            ) : (
                <>
                    <DialogTitle id="alert-dialog-slide-title">
                        {companyData?.name}
                    </DialogTitle>
                    <DialogContent
                        sx={{ display: "flex", flexDirection: "column" }}
                    >
                        <div>
                            <b>Address</b>:
                            <p>{`${companyData?.streetLine1 ?? "-"},${
                                companyData?.streetLine2 ?? "-"
                            },${companyData?.area},${companyData?.city},${
                                companyData?.state
                            },${companyData?.zipcode}`}</p>
                        </div>
                        <br />
                        <div>
                            <b>Contact</b>

                            <p>{`${
                                companyData?.contactEmail
                                    ? companyData?.contactEmail
                                    : "-"
                            } - ${
                                companyData?.contactNumber
                                    ? companyData?.contactNumber
                                    : "-"
                            }`}</p>
                        </div>
                        <br />
                        <div>
                            <b>Website</b>
                            <p>
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href={companyData?.website}
                                >
                                    {companyData?.website}
                                </a>
                            </p>
                        </div>
                        <br />
                        <div>
                            <b>Technology</b>
                            <br />
                            {companyData.technologies?.length > 0
                                ? companyData.technologies.map((item, idx) => (
                                      <p key={idx}>{`${item}`}</p>
                                  ))
                                : "-"}
                        </div>
                        <br />
                        <div>
                            <b>No Of Employees</b>
                            <p>{`${
                                companyData?.noOfEmployees
                                    ? companyData?.noOfEmployees
                                    : "-"
                            }`}</p>
                        </div>
                        <br />
                        <div>
                            <b>Notice Period</b>
                            <p>{`${
                                companyData?.noticePeriod
                                    ? companyData?.noticePeriod
                                    : "-"
                            }`}</p>
                        </div>

                        <br />
                        <div>
                            <b>Bond Duration For Experienced</b>
                            <p>{`${
                                companyData?.bondDurationForExperienced
                                    ? companyData?.bondDurationForExperienced
                                    : "-"
                            }`}</p>
                        </div>
                        <br />
                        <div>
                            <b>Bond Duration For Freshers</b>
                            <p>{`${
                                companyData?.bondDurationForFreshers
                                    ? companyData?.bondDurationForFreshers
                                    : "-"
                            }`}</p>
                        </div>
                        <br />
                        <div>
                            <b>Appraisal Cycle</b>
                            <br />
                            {companyData.appraisalCycle?.length > 0
                                ? companyData.appraisalCycle.map(
                                      (item, idx) => (
                                          <p key={idx}>{`${item}`}</p>
                                      )
                                  )
                                : "-"}
                        </div>
                    </DialogContent>
                </>
            )}

            <DialogActions>
                <Button
                    color="error"
                    variant="contained"
                    onClick={() => modal.hide()}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
});

export default CompanyDetailsModal;
