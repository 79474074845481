import * as Yup from "yup";

const modalComponentSchema = Yup.object().shape({
  title: Yup.string()
    .trim("Title cannot contain only whitespace")
    .required("Title is required")
    .strict(true)
    .min(5, "Minimum 5 characters required")
    .max(100, "Maximum 100 characters allowed")
    .nullable(),
  link: Yup.string()
    .trim("Link cannot contain only whitespace")
    .url("Enter a valid URL")
    .required("Link is required")
    .strict(true)
    .nullable(),
  owner: Yup.number().required("Owner is required").strict(true).nullable(),
  category: Yup.string()
    .trim("Category cannot contain only whitespace")
    .required("Category is required")
    .strict(true)
    .nullable(),
});

export default modalComponentSchema;
