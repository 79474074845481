import * as Yup from "yup";
const yesterday = new Date(Date.now() -86400000);
const scheduleInterviewSchema = Yup.object().shape({
  offeredDateDisable: Yup.boolean(),
  offerDate: Yup.date()
    .when("offeredDateDisable", (offeredDateDisable) => {
      if (offeredDateDisable === false) {
        return Yup.date()
          .required("Offered Date Required")
          .typeError("Offered Date Required");
      } else {
        return Yup.date().nullable();
      }
    })
    .typeError("Invalid Date"),
  selectDateDisable: Yup.boolean(),
  joiningDate: Yup.date().when("selectDateDisable", (selectDateDisable) => {
    if (selectDateDisable === false) {
      return Yup.date()
        .required("Joining Date Required")
        .typeError("Joining Date Required");
    } else {
      return Yup.date().nullable();
    }
  }),
  /*  
  designationId : Yup.string().when("selectDateDisable",(selectDateDisable) => {
    if(selectDateDisable == false){
      return Yup.string().required("Designation Required").typeError("Designation Required")
    }else{
      return Yup.string().nullable()
    }
      
  }), */

  interviewDetails: Yup.array().of(
    Yup.object().shape({
      interviewrefrences: Yup.array().min(
        1,
        "Minimum one interviewer is required"
      ),
      id: Yup.string().nullable(),
      interviewType: Yup.string()
        .required("Interview Type is required")
        .nullable(),
      interviewMode: Yup.string()
        .required("Interview Mode is required")
        .nullable(),
      // interviewDate: Yup.date()
      //   .required("Interview Date is required")
      //   .min(new Date(), "Must be greater than today's date")
      //   .typeError("Invalid Date"),

      interviewDate: Yup.date().when(["id"], {
        is: (id) => {
          return id != null;
        },
        then: Yup.date()
          .required("Interview Date is required")
          .typeError("Invalid Date")
          .nullable(),
        otherwise: Yup.date()
          .required("Interview Date is required")
          // .min(new Date(), "Should not be past date")
          .min(yesterday, "Should not be past date")
          .typeError("Invalid Date")
          .nullable(),
      }),
      interviewTime: Yup.string()
        .required("Interview Time is required")
        .test("", "Invalid Interview Time",  (value) => {
          if(value == "Invalid Date"){
            return false;
          }
          else{
            return true
          }
        }
      )
      .nullable(),
      duration: Yup.number()
        .required("Interview duration is required")
        .nullable(),
      technologies: Yup.string().required("Technology is required").nullable(),
      comments: Yup.string().nullable(),
    })
  ),
});

export default scheduleInterviewSchema;
