import * as Yup from "yup";

const faqsSchema = Yup.object({
  title: Yup.string()
    .required("FAQs Title is required")
    .trim("FAQs Title cannot contain only whitespace")
    .strict(true)
    .nullable(),
  description: Yup.string()
    .required("Description is required")
    .trim("FAQs Description cannot contain only whitespace")
    .strict(true),
  faqs: Yup.array().of(
    Yup.object().shape({
      question: Yup.string()
        .required("Question is required")
        .trim("Question cannot contain only whitespace")
        .strict(true),
      answer: Yup.string()
        .required("Answer is required")
    })
  ),
});

export default faqsSchema;
