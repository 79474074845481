import * as Yup from "yup";

export const activity = Yup.object().shape({
  name: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const bloodgroup = Yup.object().shape({
  title: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const course = Yup.object().shape({
  title: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const candidateStatusValidation = Yup.object().shape({
  name: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  candidateStatusId: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const manufacturer = Yup.object().shape({
  title: Yup.string()
    .required("Field is required")
    .trim("Manufacturer Title cannot contain only whitespace")
    .strict(true)
    .nullable()
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const department = Yup.object().shape({
  title: Yup.string()
    .required("Department Title is Required")
    .matches(/^[a-zA-Z.\s]+$/, "Please enter a valid title")
    .max(64, "Length of character must be less than 64"),
  teamLead: Yup.array()
    .of(Yup.mixed())
    .min(1, "Team lead is required")
    .nullable(),
  projectManager: Yup.array()
    .of(Yup.mixed())
    .min(1, "Project manager is required")
    .nullable(),
  isActive: Yup.boolean(),
});

export const designation = Yup.object().shape({
  title: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const grade = Yup.object().shape({
  grade: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
});

export const externalDesignation = Yup.object().shape({
  title: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  isActive: Yup.boolean(),
});

export const checkList = Yup.object().shape({
  title: Yup.string()
    .required("Field is required")
    .max(128, "Length of character must be less than 128"),
  isActive: Yup.boolean(),
});

export const assetMaster = Yup.object().shape({
  name: Yup.string()
    .required("Field is required")
    .trim("Asset Name cannot contain only whitespace")
    .strict(true)
    .nullable()
    .max(64, "Length of character must be less than 64"),
  iconPath: Yup.string()
    .required("Field is required")
    .max(64, "Length of character must be less than 64"),
  assetPrefix: Yup.string()
    .required("Field is required")
    .trim("Asset Prefix cannot contain only whitespace")
    .strict(true)
    .nullable()
    .max(64, "Length of character must be less than 64"),
});

export const itemAssetMapping = Yup.object().shape({
  assetId: Yup.string().required("Field is required"),
  itemId: Yup.string().required("Field is required"),
});
