import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Axios } from "../../../service/axios";

export const getExperienceDetails = createAsyncThunk(
  "reports/getExperienceDetails",
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getExperienceDetails", {
        params,
        headers: {
          type: "view",
          module: "reports",
        },
      });
      return res.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);
export const totalCountExperienceDetails = createAsyncThunk(
	"reports/totalCountExperienceDetails",
	async ({ rejectWithValue }) => {
		try {
			const res = await Axios.get("/totalCountExperienceDetails", {
				headers: { type:'view',module:'reports' },
			});
			return res.data;
		} catch (error) {
			return rejectWithValue(error.response.data.message || error.message);
		}
	},
);
export const getExperienceDetailsForExcel = createAsyncThunk(
  "reports/getExperienceDetailsForExcel",
  async (params, { rejectWithValue }) => {
    try {
      const res = await Axios.get("/getExperienceDetails", {
        params,
        headers: {
          type: "view",
          module: "reports",
        },
      });
      return res.data;
    } catch (error) {
        return rejectWithValue(error?.response?.data?.message || error.message);
    }
  }
);