import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { indianDateTimeFormat } from "../../../utils/formatTime";
import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  LabelList,
} from "recharts";
import {BASE_URL} from "../../../constants"
import "./Singlepoll.css"
const SinglePoll = ({ ele }) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length){
      return payload[0].payload.optUrl  && payload[0]?.payload?.optText  ? (
        <div className="tooltip-text">
          <img height="40px" width="40px" src={ `${BASE_URL}/${payload[0]?.payload?.optUrl}`} />{" "}
          <p>{payload[0].payload.optText}</p>
          <span>{`Attempt :${payload[0].payload.count}`}</span>
        </div>
      ) : payload[0].payload.optUrl ? (
        <div className="tooltip-text">
          <img height="40px" width="40px" src={`${BASE_URL}/${payload[0]?.payload?.optUrl}`} />
          <span>{`Attempt:${payload[0].payload.count}`}</span>
        </div>
      ) : (
        <div className="tooltip-text">
          <p>{payload[0].payload.optText}</p>{" "}
          <span>{`Attempt:${payload[0].payload.count}`}</span>
        </div>
      );
    }
  
   return null;
  };

  const customizedGroupTick = (props) => {
    const { index, x, y, payload } = props;
    const string = payload.value.includes("both")
      ? payload.value.replace("both", "")
      : payload.value;
    console.log(props, string, "label payload");
    return (
      <g>
        <g>
          {payload.value === "Image Option" ? (
            <g transform={`translate(${props.x - 10},${props.y})`}>
              <image
                xlinkHref="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png"
                x={0}
                y={0}
                height="20px"
                width="20px"
                cursor="pointer"
                textAnchor="middle"
                fill="#666"
              />
            </g>
          ) : payload.value.includes("both") ? (
            <>
              <g transform={`translate(${props.x - 30},${props.y})`}>
                <image
                  xlinkHref="https://cdn4.iconfinder.com/data/icons/ionicons/512/icon-image-512.png"
                  x={0}
                  y={0}
                  height="20px"
                  width="20px"
                  cursor="pointer"
                  textAnchor="middle"
                  fill="#666"
                />
              </g>
              <g transform={`translate(${x + 5},${y})`}>
                {" "}
                <text x={0} y={0} dy={16} fill="#666">
                  {payload.value.replace("both", "")}
                </text>
              </g>{" "}
            </>
          ) : (
            <g left='50%' transform={`translate(${x},${y})`}>
              <text x={'50%'} y={0} dy={16} dx={'-50%'} fill="#666" style={{ textAnchor: 'middle'}}>
                {payload.value}
              </text>
            </g>
          )}
        </g>
      </g>
    );
  };

  const handleCharData = (ele) => {
    let optionName = ["A", "B", "C", "D", "E", "F"];
    let optionData = ele?.pollQuestionOptions?.map((opt, index) => {
      return {
        optUrl : opt.optionsUrl ? opt.optionsUrl : "",
        count: opt?.answerCounts,
        name: optionName[index],
        label: opt.optionText,
        optText: opt.optionText,
      };
    });
    return optionData;
  };

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={handleChange("panel1")}
      key={ele}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Typography sx={{ width: "80%", flexShrink: 0 }}>
          {ele?.questionText}
        </Typography>
        <Typography sx={{ color: "text.secondary", fontSize: "13px" }}>
          {" "}
          {indianDateTimeFormat(ele?.startDate)} to{" "}
          {indianDateTimeFormat(ele?.endDate)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {/* <Typography>
        chart
      </Typography> */}

        <div>
          <BarChart
            width={700}
            height={400}
            data={handleCharData(ele)}
            margin={{
              top: 20,
              right: 30,
              left: 30,
              bottom: 30,
            }}
          >
            <XAxis
              dataKey="label"
              cursor="pointer"
              label={{
                value: "Options",
                offset: 15,
                position: "bottom",
                textAnchor: "middle",
              }}
              //   onClick={(chartData, index) =>
              //     handleLabelClick(chartData)
              //   }
              //   tick={customizedGroupTick}
            ></XAxis>
            <YAxis
              dataKey="count"
              label={{
                value: "Results",
                angle: -90,
                position: "insideLeft",
                textAnchor: "middle",
              }}
              tickFormatter={(tick) => {
                return `${tick.toFixed(2)}`;
              }}
            ></YAxis>
            <Tooltip content={<CustomTooltip />} />

            <Bar dataKey="count" fill="#ff9934"></Bar>
          </BarChart>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default SinglePoll;
