import * as Yup from "yup";


const systemConfigSchema =  (moduleName) => {
  return(
  Yup.object().shape({
    ...( moduleName !== "employee" && moduleName != "mattermost" ? {
  clientEmail: Yup.string()
    .required("clientEmail is required")
    .email("Invalid clientEmail")
    .max(512, "clientEmail is too long"),
  clientId: Yup.string().required("Client Id is required"),
  clientSecretId: Yup.string().required("Client SecretId is required"),
  refreshToken: Yup.string().required("RefreshToken is required"),
  redirectUrl: Yup.string()
    .required("Redirect Url is required")
    .url("Invalid URL")
    .max(1024, "RedirectUrl is too long")
    } : {}),
    ...( moduleName === "drives" ? {
        adharFolderKey: Yup.string().required("Adhar FolderKey is required"),
        checklistFolderKey: Yup.string().required("Checklist FolderKey is required"),
        panFolderKey: Yup.string().required("PAN FolderKey is required"),
        employeeResumesFolderKey: Yup.string().required("EmployeeResumes FolderKey is required"),
        candidateResumesFolderKey: Yup.string().required("CandidateResumes FolderKey is required"),
        internResumesFolderKey:Yup.string().required("InternResumes FolderKey is required"),
        projectDefinationFolderKey: Yup.string().required("ProjectDefination FolderKey is required")
    } : {}),
     ...( moduleName === "mail" ?
    {
     defaultSenderEmail : Yup.string()
     .required("SenderEmail is required")
     .email("Invalid SenderEmail")
     .max(512, "SenderEmail is too long")
  } : {}),
  ...( moduleName === "employee" ?
  {
    exitCheckListResponsibleHr : Yup.string()
    .required("Responsible HR is required")
    .nullable(),
  exitCheckListResponsibleSystemAdmin : Yup.string()
    .required("Responsible Admin is required")
    .nullable(),
} : {}),
...( moduleName === "mattermost" ?
  {
    mattermostToken : Yup.string()
    .required("Token is required")
    .nullable(),
    mattermostUrl : Yup.string()
    .required("URL is required")
    .nullable(),
    adminEmailForMatterMost : Yup.string()
    .required("Email is required")
    .nullable(),
} : {})
})
)
};


export default systemConfigSchema;