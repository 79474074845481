import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboard";

import Card from "@mui/material/Card";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../utils/userPermission";

function AddPolicyForm() {
	const navigate = useNavigate();

	// if (!getAddVisible("policy") || !getEditVisible("policy")) {
	// 	navigate("/dashboard");
	// }

	return (
		<Container className="Employee-wrapper Add-Policy-main-container" maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
			>
			  <div className="title-button-wrapper Add-Policy-title">
				<Typography variant="h4" gutterBottom>
					Add Policy
				</Typography>
				<Button
				className="back-button"
					component={RouterLink}
					to="/policy"
					variant="contained"
					startIcon={<ArrowBackIcon />}
				>
					Back
				</Button>
				</div>
			</Stack>
			<Card className="Addpolicy-card" pb={3}>
				<Container maxWidth="xl">
					<form className="gray-box Employee_Detail_Content Addpolicy-form">
						<Grid container spacing={3} mb={3}>
							<Grid item xs={6} mt={3}>
								<TextField
									fullWidth
									label="Policy Name"
									name="title"
									inputProps={{ maxLength: 64 }}
									variant="outlined"
									size="small"
								/>
							</Grid>
							<Grid item xs={6} mt={3}>
								<TextField
									fullWidth
									label="Department"
									name="title"
									inputProps={{ maxLength: 64 }}
									variant="outlined"
									size="small"
								/>
							</Grid>
						</Grid>
						<Grid container spacing={3}>
							<Grid item xs={6}>
								<FormControl fullWidth >
									<TextareaAutosize
										aria-label="Note..."
										minRows={4}
										placeholder="Description"
										fullWidth
										className="custom-textarea"
										style={{ width:'100%'}}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={6}>
							<div className="form-group custom-upload-part">
								<div className="input-group">
									<input
										type="text"
										className="form-control"
										disabled
										placeholder="Upload File"
										aria-label="Upload File"
										aria-describedby="basic-addon1"
									/>
									<div className="input-group-append">
										<input type="file" className="file-part" />
										<Button
											variant="contained"
											className="upload-button"
											startIcon={<FileUploadIcon />}
										>
											{/* Upload */}
										</Button>
									</div>
								</div>
							</div>
							</Grid>
							</Grid>
							
						
					</form>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						mb={3}
					>
						<Button variant="contained">Save</Button>
					</Stack>
				</Container>
			</Card>
		</Container>
	);
}

const componentConfig = [
	{
		component: AddPolicyForm,
		path: "/policy/add",
		public: false,
		layout: DashboardLayout,
		group: "organization",
		sidebar: true,
		role: ["admin"],
	},
	{
		component: AddPolicyForm,
		path: "/policy/add/:id",
		public: false,
		layout: DashboardLayout,
		group: "organization",
		sidebar: true,
		role: ["admin"],
	},
];

export default componentConfig;
