import * as React from "react";
import { useRef, useEffect } from "react";
import { useConfirm } from "material-ui-confirm";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Loader from "../../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";
import LoadingButton from "@mui/lab/LoadingButton";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import invertDirection from "../../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";
import {
  getExternalDesignation,
  updateById,
  deleteById,
  insert,
} from "../../../redux/externalDesignation/externalDesignationThunk";
import { externalDesignation } from "../../../validations/mastersSchema";
import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import { setSortBy, setOrderBy } from "../../../redux/externalDesignation/externalDesignation";

const ActivityStatus = () => {
  const confirm = useConfirm();

  const dispatch = useDispatch();
  const data = useSelector((state) => state.externalDesignation.data);
  const loading = useSelector((state) => state.externalDesignation.loading);
  const error = useSelector((state) => state.externalDesignation.error);
	const sortBy = useSelector((state) => state.externalDesignation.sortBy);
	const orderBy = useSelector((state) => state.externalDesignation.orderBy);
  const [id, setId] = React.useState(null);
  const ref = useRef(null);

  const formik = useFormik({
    initialValues: {
      title: "",
      isActive: false,
      parentId: "",
    },
    validationSchema: externalDesignation,
    onSubmit: async (values, { resetForm }) => {
      if (!values.parentId) {
        values.parentId = null;
      }

      try {
        if (id) {
          await dispatch(updateById({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(insert(values)).unwrap();
        }
        resetForm();
        dispatch(getExternalDesignation());

        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const { errors, setErrors, touched, handleSubmit, setValues, isSubmitting } =
    formik;

  useEffect(() => {
    if (
      !loading &&
      !error &&
      (!data || (Array.isArray(data) && data.length <= 0))
    )
      dispatch(getExternalDesignation());
  }, [loading]);

  useEffect(() => {
    dispatch(
      getExternalDesignation({
        sortBy,
        orderBy,
      }),
    );
}, [dispatch,sortBy, orderBy]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setErrors({});
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const handleUpdate = (data) => {
    setValues({
      title: data.title,
      isActive: data.isActive,
      parentId: data.parentId ?? "",
    });
    setId(data.id);
  };

  const handleDelete = async (id, title) => {
    try {
      await confirm({
        description: `Are you sure you want to delete ${title}?`,
      });
      await dispatch(deleteById(id));
      await dispatch(getExternalDesignation());
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const handleToggle = async (skill) => {
    try {
      await dispatch(
        updateById({
          id: skill.id,
          title: skill.title,
          isActive: !skill.isActive,
        })
      ).unwrap();
      dispatch(getExternalDesignation());
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

  return (
    <>
    {/*<Card className="inner-component">
      <Container className="custom-container-grid"> */}
       <Container
          className="custom-container-grid"
          style={{
            boxShadow: "initial !important",
            paddingLeft: "0",
            paddingRight: "0",
            minWidth: "100%",
            minHeight: "70%",
          }}
        >
        <div className="header" >
          <form ref={ref} autoComplete="off" noValidate onSubmit={handleSubmit}>
            <h6 className="component-name">EXTERNAL DESIGNATION MASTER</h6>
            {getAddVisible("master") && (
              <div className="heading-part">
                <div className="input-field-part">
                  <TextField
                    id="outlined-basic"
                    label="Enter External Designation"
                    variant="outlined"
                    size="small"
                    name="title"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                    inputProps={{ maxLength: 64 }}
                  />
                </div>
                <div className="checkbox-part">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isActive}
                        name="isActive"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Status"
                  />
                </div>
                <div className="button-part">
                  <LoadingButton
                    size="small"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    save
                  </LoadingButton>
                </div>
              </div>
            )}
          </form>
        </div>
        <TableContainer component={Paper}>
          {loading ? (
            <Loader />
          ) : (
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell width="77%">
                    <TableSortLabel
                      active={sortBy === "title"}
                      direction={sortBy === "title" ? orderBy : "asc"}
                      onClick={() => handleSorting("title")}
                    >
                      Title
										</TableSortLabel>
                  </TableCell>
                  <TableCell width="10%" align="center">
                    Status
                  </TableCell>
                  {(getEditVisible("master") || getDeleteVisible("master")) && (
                    <TableCell
                      width="13%"
                      align="right"
                      className="action-field"
                    >
                      Actions
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {Array.isArray(data) && data.length === 0 ? (
                  <TableRow>
                    <TableCell align="center" colSpan={3}>
                    No Record(s) Found
                    </TableCell>
                  </TableRow>
                ) : (
                  data.map((externalDesignation, idx) => (
                    <TableRow
                      key={idx}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {externalDesignation.title}
                      </TableCell>
                      <TableCell align="right">
                        <Switch
                          checked={externalDesignation.isActive}
                          onChange={() => handleToggle(externalDesignation)}
                          inputProps={{ "aria-label": "controlled" }}
                          size="small"
                        />
                      </TableCell>
                      {(getEditVisible("master") ||
                        getDeleteVisible("master")) && (
                        <TableCell align="right">
                          {getEditVisible("master") && (
                            <IconButton
                              onClick={() => handleUpdate(externalDesignation)}
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          )}
                          {getDeleteVisible("master") && (
                            <IconButton
                              onClick={() =>
                                handleDelete(
                                  externalDesignation.id,
                                  externalDesignation.title
                                )
                              }
                            >
                              <DeleteIcon color="error" />
                            </IconButton>
                          )}
                        </TableCell>
                      )}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          )}
        </TableContainer>
     </Container>
    {/*</Card> */}
    </>
  );
};

export default ActivityStatus;
