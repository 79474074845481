import React from "react";

import { Link as RouterLink, useNavigate } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboard";
import AddHolidayForm from "./components/HolidayAddForm";

import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../../utils/userPermission";

function AddHoliday() {
	const navigate = useNavigate();

	// if (!getAddVisible("holiday") || !getEditVisible("holiday")) {
	// 	navigate("/dashboard");
	// }

	return (
		<>
			<AddHolidayForm />
		</>
	);
}

const componentConfig = {
	component: AddHoliday,
	path: "/holiday/add",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
