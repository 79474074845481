import React, { useEffect } from "react";
import DashboardLayout from "../../layouts/dashboard";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { useNavigate } from "react-router-dom";
import Scrollbar from "../../components/Scrollbar";
import Loader from "../../components/Loader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import TableSortLabel from "@mui/material/TableSortLabel";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import { Badge, Modal } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Drawer from "@mui/material/Drawer";
import CachedIcon from "@mui/icons-material/Cached";
import SearchIcon from "@mui/icons-material/Search";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { yearMonthDateFormat } from "../../utils/dateFormat";
import { debounce } from "lodash";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { decryption, encryption } from "../../utils/encodeString";

import {
  setSortBy,
  setOrderBy,
  setCurrentPage,
  setLimit,
} from "../../redux/helpdeskTickets/helpdeskTicketsSlice";
import Chip from "@mui/material/Chip";
import invertDirection from "../../utils/invertDirection";
import CustomPagination from "../../components/Pagination";
import { Link as RouterLink } from "react-router-dom";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
// import {
// 	getViewVisible,
// 	getAddVisible,
// 	getEditVisible,
// 	getDeleteVisible,
// } from "../../../utils/userPermission";
// import { getUserPermissionByRoll } from "../../../redux/userPermission/userPermissionthunk";
import { useDispatch, useSelector } from "react-redux";
import {
  getHelpDeskTickets,
  getTotalTicketCount,
  updatePriority,
} from "../../redux/helpdeskTickets/helpdeskTicketsThunk";
import { getHelpdeskCategoriesDropDown } from "../../redux/helpdeskCategories/helpdeskCategoriesThunk";
import { getEmployeeForDropDown } from "../../redux/employee/employeeThunk";
import moment from "moment";
import CustomSelect from "./CustomSelect";
import { getViewVisible, getEditVisible } from "../../utils/userPermission";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";

function HelpDeskTickets() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = React.useState("");
  const [filterChips, setFilterChips] = React.useState([]);
  const inputRef = React.useRef(null);
  const [state, setState] = React.useState({
    right: false,
  });
  // const userId = JSON.parse(decryption((localStorage.getItem("userID"))));
  // const userId =decryption(JSON.parse(localStorage.getItem("userID")));
  const userId = JSON.parse(localStorage.getItem("employeeId"));

  const assignedByData = useSelector((state) => state.employee.allEmployeeData);
  const {
    data,
    loading,
    sortBy,
    orderBy,
    totalRecords,
    limit,
    currentPage,
    totalPages,
    status,
    error,
  } = useSelector((state) => state.helpdeskTickets);
  const totalTicketsCount = useSelector(
    (state) => state.helpdeskTickets.totalTicketsCount
  );
  const isSearchQuery = useSelector(
    (state) => state.helpdeskTickets.isSearchQuery
  );
  const priorityData = ["LOW", "MEDIUM", "HIGH"];
  const statusData = ["OPEN", "CLOSED", "REOPEN"];
  const stateData = [
    "NEW",
    "IN PROGRESS",
    "PENDING",
    "RESOLVED",
    "CLOSED",
    "ESCALATED",
  ];
  const roleId = localStorage?.getItem("roleId");
  const role = JSON.parse(localStorage?.getItem("role"));
  const categoriesData = useSelector(
    (state) => state.helpdeskCategories.categoryData
  );
  const assignedToData = useSelector((state) => state.employee.allEmployeeData);

  const ticketsData = Array.isArray(data)
    ? data?.map((item) => {
        return {
          ...item,
          category: categoriesData.find((x) => x.id === item.categoryId)?.title,
          assignedToName:
            assignedToData.find((x) => x.employeeId === item.assignedTo)
              ?.firstName +
            " " +
            assignedToData.find((x) => x.employeeId === item.assignedTo)
              ?.lastName,
          createdByName:
            assignedToData.find((x) => x.employeeId === item.createdBy)
              ?.firstName +
            " " +
            assignedToData.find((x) => x.employeeId === item.createdBy)
              ?.lastName,
          createdAt: moment(item.createdDate).format("DD-MM-YYYY HH:mm:ss"),
          updatedAt: moment(
            item?.helpDeskTicketResponses[
              item?.helpDeskTicketResponses?.length - 1
            ]?.updatedDate
          ).format("DD-MM-YYYY hh:mm:ss A"),
        };
      })
    : [];

  const getUserPermission = async (Id) => {
    try {
      const res = await dispatch(
        getUserPermissionByRoll({
          id: Id,
        })
      ).unwrap();
      LocalStorage.setItem(
        "userPermissions",
        encryption(JSON.stringify(res.data))
      );
    } catch (error) {}
  };
  useEffect(() => {
    let roleId = localStorage.getItem("roleId");
    getUserPermission(roleId);
  }, [dispatch]);

  // function for toggle filter drawer
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // useEffect for fetching data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Dispatch actions to fetch data
        dispatch(getHelpdeskCategoriesDropDown());
        dispatch(getTotalTicketCount({}));
        dispatch(getEmployeeForDropDown());

        // Fetch help desk tickets with async/await
        const response = await dispatch(
          getHelpDeskTickets({
            limit,
            ...values,
            page: currentPage + 1,
            orderBy,
            sortBy,
            search: searchValue,
          })
        ).unwrap();

        // Optionally handle response if needed
        // e.g., console.log(response);

      } catch (error) {
        toast.error(error?.message);
      }
    };

    fetchData();
  }, [ limit, currentPage, sortBy, orderBy]);

  // function for update ticket
  const handleUpdate = (data) => {
    navigate("/tickets/edit/" + encryption(data.id));
  };

  // function for sorting
  const handleSorting = (columnName) => {
    dispatch(setSortBy({ sortBy: columnName }));
    dispatch(
      setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) })
    );
  };

  // functions for pagination
  const handleChangePage = (event, newPage) => {
    dispatch(setCurrentPage({ page: newPage }));
  };
  const handleChangeRowsPerPage = (event) => {
    dispatch(setCurrentPage({ page: 0 }));
    dispatch(setLimit({ limit: parseInt(event.target.value, 10) }));
  };
  const {
    handleSubmit,
    values,
    handleChange,
    resetForm,
    setFieldValue,
    errors,
    touched,
    setErrors,
  } = useFormik({
    initialValues: {
      search: "",
      assignedBy: "",
      assignedTo: "",
      categoryId: "",
      subCategoryId: "",
      priority: "",
      status: "",
      state: "",
      dateFrom: "",
      dateTo: "",
    },

    // on submit of filter
    onSubmit: async (values) => {
      let queryParams = { ...values };
      try {
        await dispatch(getHelpDeskTickets({ ...queryParams, limit, page: 1 }));
        dispatch(setCurrentPage({ page: 0 }));
        prepareChips();
      } catch (error) {
        toast.error(error.response.data.message);
      }
    },
  });

  // function for reset filter drawer
  const handleReset = async () => {
    resetForm();
    await dispatch(getHelpDeskTickets({ limit, page: 1, sortBy, orderBy }));
    dispatch(setCurrentPage({ page: 0 }));
    setState({ ...state, right: false });
    setFilterChips([]);
  };

  // function for search
  const handleSearch = async (e) => {
    setSearchValue(e?.target?.value);
    setFieldValue("search", e?.target?.value || "");
    handleSubmit();
  };
  // function for debounce search
  const debounceWithSearch = debounce(handleSearch, 500);

  // functino for preparing filter and search chips
  const prepareChips = () => {
    let filters = { ...values };
    let tempChips = [];
    for (const key in filters) {
      if (filters[key] && key === "assignedBy") {
        const res = assignedToData.find(
          (assignedBy) => assignedBy.employeeId === filters[key]
        );
        if (res)
          filters[key] =
            "Assigned By : " + res?.firstName + " " + res?.lastName;
      }
      if (filters[key] && key === "assignedTo") {
        const res = assignedToData.find(
          (assignedTo) => assignedTo.employeeId === filters[key]
        );
        if (res)
          filters[key] =
            "Assigned To : " + res?.firstName + " " + res?.lastName;
      }
      if (filters[key] && key === "dateFrom") {
        filters[key] = "From :" + moment(filters[key]).format("DD/MM/yyyy");
      }
      if (filters[key] && key === "dateTo") {
        filters[key] = "To : " + moment(filters[key]).format("DD/MM/yyyy");
      }
      if (filters[key] && key === "categoryId") {
        filters.category = filters[key];
        const res = categoriesData.find(
          (category) => category.id === filters[key]
        );
        if (res) filters[key] = "Category : " + res?.title;
      }
      if (filters[key] && key === "subCategoryId") {
        const res = categoriesData
          .find((category) => category.id === filters["category"])
          .helpDeskSubCategories.find(
            (subCategory) => subCategory.id === filters[key]
          );
        if (res) filters[key] = "Sub category : " + res?.title;
      }
      if (filters[key] && key === "priority") {
        filters[key] = "Priority : " + filters[key];
      }
      if (filters[key] && key === "status") {
        filters[key] = "Status : " + filters[key];
      }
      if (filters[key] && key === "state") {
        filters[key] = "State : " + filters[key];
      }

      if (filters[key]) {
        tempChips = [...tempChips, { type: key, filter: filters[key] }];
      }
    }
    setFilterChips(tempChips);
  };

  // function for delete chip
  const onDeleteChip = (value, length) => {
    setFilterChips([
      ...filterChips.filter((val) => val.filter !== value.filter),
    ]);
    let filters = { ...values, [value["type"]]: "" };
    if (length === 1) {
      dispatch(getHelpDeskTickets({ limit, page: 1, sortBy, orderBy }));
      dispatch(setCurrentPage({ page: 0 }));
    } else {
      dispatch(
        getHelpDeskTickets({ ...filters, limit, page: 1, sortBy, orderBy })
      );
      dispatch(setCurrentPage({ page: 0 }));
    }
    setFieldValue(value.type, "");
    if (value.type === "search") {
      setSearchValue("");
      inputRef.current.value = "";
    }
  };

  // function for priority change using select dropdown
  const handlePriorityChange = async (ticket, e) => {
    // if(ticket?.status !== "CLOSED" && (ticket?.assignedBy === Number(userId) || ticket?.reporterPerson === Number(userId))){
    const body = {
      assignedBy: ticket?.assignedBy,
      assignedTo: ticket?.assignedTo,
      categoryId: ticket?.categoryId,
      subCategoryId: ticket?.subCategoryId,
      priority: e.target.value,
      status: ticket?.status,
      state: ticket?.state,
      description: ticket?.description,
      subject: ticket?.subject,
      attachments: ticket?.attachments,
      additionalNotes: ticket?.additionalNotes,
    };
    await dispatch(updatePriority({ ...body, id: ticket.id })).unwrap();
    await dispatch(getHelpDeskTickets({ limit, page: 1, sortBy, orderBy }));

    // }
    // else if(ticket?.status === "CLOSED" && (ticket?.assignedBy === Number(userId) || ticket?.reporterPerson === Number(userId))){
    //   toast.error("You are not allowed to change priority of closed ticket");
    // }
    // else{
    //   toast.error("You are not allowed to change priority");
    // }
  };

  // function for status chip color
  const getStatusColor = (status) => {
    switch (status) {
      case "OPEN":
        return "primary";
      case "CLOSED":
        return "success";
      case "REOPEN":
        return "info";
      default:
        return "default";
    }
  };

  const handleFilters = async (field, newValue) => {
    setFieldValue(field, newValue || "");
    handleSubmit();
  };

  return (
    <Container maxWidth="xl">
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <div className="title-button-wrapper">
          <Typography variant="h4" gutterBottom>
            Tickets
            <Typography variant="body2">
              Total Records : {totalTicketsCount?.totalTickets || 0}
            </Typography>
          </Typography>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            {/* {["right"].map((anchor) => (
            <React.Fragment key={anchor}>
              <span
                className="filter-icon-part"
                onClick={toggleDrawer(anchor, true)}
              >
                <IconButton>
                  <Badge
                    color="error"
                    variant="dot"
                    invisible={filterChips.length !== 0 ? false : true}
                  >
                    <FilterAltIcon />
                  </Badge>
                </IconButton>
              </span>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                style={{ zIndex: "1300" }}
              >
                <Box
                  pt={4}
                  px={3}
                  sx={{
                    width:
                      anchor === "top" || anchor === "bottom" ? "auto" : 400,
                  }}
                  role="presentation"
                >
                  <div className="content-filter-part">
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      mb={3}
                    >
                      <Typography variant="h6">Tickets Filter</Typography>
                      <span>
                        <IconButton onClick={toggleDrawer(anchor, false)}>
                          <CloseIcon />
                        </IconButton>
                      </span>
                    </Stack>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3} mb={2}>
                        {(roleId === "3" && role === "HR") ||
                        (roleId === "4" && role === "Employee") ||
                        (roleId === "43" && role === "Cybercom App User") ? (
                          ""
                        ) : (
                          <Grid item xs={12}>
                            <Autocomplete
                              size="small"
                              fullWidth
                              options={assignedByData || []}
                              getOptionLabel={(option) =>
                                option.firstName + " " + option.lastName || ""
                              }
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  value={option.id}
                                  key={option.id}
                                >
                                  {option.firstName + " " + option.lastName ||
                                    ""}
                                </li>
                              )}
                              name="assignedBy"
                              onChange={(event, newValue) => {
                                setFieldValue("assignedBy", newValue?.id || "");
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  autoComplete="off"
                                  label="Assigned By"
                                />
                              )}
                              value={assignedByData?.find(
                                (t) => t.id === values.assignedBy
                              )}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={assignedToData || []}
                            getOptionLabel={(option) =>
                              option.firstName + " " + option.lastName || ""
                            }
                            renderOption={(props, option) => (
                              <li {...props} value={option.id} key={option.id}>
                                {option.firstName + " " + option.lastName || ""}
                              </li>
                            )}
                            name="assignedTo"
                            onChange={(event, newValue) => {
                              setFieldValue("assignedTo", newValue?.id || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="Assigned To"
                              />
                            )}
                            value={assignedToData?.find(
                              (t) => t.id === values.assignedTo
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={categoriesData || []}
                            getOptionLabel={(option) => option.title || ""}
                            name="categoryId"
                            onChange={(event, newValue) => {
                              setFieldValue("categoryId", newValue?.id || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="Category"
                              />
                            )}
                            value={categoriesData?.find(
                              (t) => t.id === values.categoryId
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={
                              categoriesData?.find(
                                (x) => x.id === values.categoryId
                              )?.helpDeskSubCategories || []
                            }
                            getOptionLabel={(option) => option.title || ""}
                            name="subCategoryId"
                            onChange={(event, newValue) => {
                              setFieldValue(
                                "subCategoryId",
                                newValue?.id || ""
                              );
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="Sub Category"
                              />
                            )}
                            value={
                              categoriesData
                                ?.find((x) => x.id === values.categoryId)
                                ?.helpDeskSubCategories?.find(
                                  (t) => t.id === values.subCategoryId
                                ) || null
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={priorityData || []}
                            name="priority"
                            onChange={(event, newValue) => {
                              setFieldValue("priority", newValue || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="Priority"
                              />
                            )}
                            value={values.priority}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={statusData || []}
                            name="status"
                            onChange={(event, newValue) => {
                              setFieldValue("status", newValue || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="Status"
                              />
                            )}
                            value={values.status}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            size="small"
                            fullWidth
                            options={stateData || []}
                            name="state"
                            onChange={(event, newValue) => {
                              setFieldValue("state", newValue || "");
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoComplete="off"
                                label="State"
                              />
                            )}
                            value={values.state}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            item
                          >
                            <Grid item>
                              <DesktopDatePicker
                                xs={6}
                                label="From"
                                style={{ width: "100px" }}
                                value={values.dateFrom}
                                inputFormat="dd/MM/yyyy"
                                // minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "dateFrom",
                                    yearMonthDateFormat(newValue)
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={false}
                                    size="small"
                                    style={{
                                      width: "45%",
                                      marginRight: "10px",
                                    }}
                                  />
                                )}
                              />
                              <DesktopDatePicker
                                xs={6}
                                label="To"
                                value={values.dateTo}
                                inputFormat="dd/MM/yyyy"
                                // minDate={new Date('2017-01-01')}
                                onChange={(newValue) => {
                                  setFieldValue(
                                    "dateTo",
                                    yearMonthDateFormat(newValue)
                                  );
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    error={false}
                                    size="small"
                                    style={{ width: "50%" }}
                                  />
                                )}
                              />
                            </Grid>
                          </LocalizationProvider>
                        </Grid>
                      </Grid>

                      <Stack direction="row" justifyContent="flex-end" mb={3}>
                        <Button
                          variant="contained"
                          startIcon={<SearchIcon />}
                          type="submit"
                          onClick={toggleDrawer(anchor, false)}
                        >
                          Search
                        </Button>
                        <Button
                          variant="contained"
                          type="reset"
                          onClick={handleReset}
                          startIcon={<CachedIcon />}
                          sx={{ marginLeft: "10px" }}
                        >
                          Reset
                        </Button>
                      </Stack>
                    </form>
                  </div>
                </Box>
              </Drawer>
            </React.Fragment>
          ))} */}
          </Stack>
          <Button
            component={RouterLink}
            to="/tickets/add"
            variant="contained"
            startIcon={<AddIcon />}
            className="filter-icon-part"
          >
            Add Helpdesk Ticket
          </Button>
        </div>
      </Stack>

      <Card className="employee-table-grid" pb={3}>
        <Container className="employee-table-grid_container" maxWidth="xl">
          <form onSubmit={(e) => e.preventDefault()}>
            <Grid className="employee-classification" container spacing={2}>
              <div
                className="employee-classification-right"
                style={{ paddingTop: "20px" }}
              >
                <TextField
                  className="employee-search-detail"
                  fullWidth
                  autoComplete="off"
                  label="Search by Description"
                  name="search"
                  inputRef={inputRef}
                  variant="outlined"
                  size="small"
                  onChange={debounceWithSearch}
                />
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={assignedByData || []}
                    getOptionLabel={(option) =>
                      option ? `${option.firstName} ${option.lastName}` : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.employeeId} key={option.id}>
                        {option ? `${option.firstName} ${option.lastName}` : ""}
                      </li>
                    )}
                    name="assignedBy"
                    onChange={(event, newValue) => {
                      // setFieldValue("assignedBy", newValue?.id || "");
                      handleFilters("assignedBy", newValue?.employeeId || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Assigned By"
                      />
                    )}
                    value={
                      assignedByData?.find(
                        (t) => t.employeeId === values.assignedBy
                      ) || ""
                    }
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={assignedToData || []}
                    getOptionLabel={(option) =>
                      option ? `${option.firstName} ${option.lastName}` : ""
                    }
                    renderOption={(props, option) => (
                      <li {...props} value={option.employeeId} key={option.id}>
                        {option ? `${option.firstName} ${option.lastName}` : ""}
                      </li>
                    )}
                    name="assignedTo"
                    onChange={(event, newValue) => {
                      handleFilters("assignedTo", newValue?.employeeId || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Assigned To"
                      />
                    )}
                    value={
                      assignedToData?.find(
                        (t) => t.employeeId === values.assignedTo
                      ) || ""
                    }
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={categoriesData || []}
                    getOptionLabel={(option) => option.title || ""}
                    name="categoryId"
                    onChange={(event, newValue) => {
                      handleFilters("categoryId", newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Category"
                      />
                    )}
                    value={
                      categoriesData?.find((t) => t.id === values.categoryId) ||
                      ""
                    }
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={
                      categoriesData?.find((x) => x.id === values.categoryId)
                        ?.helpDeskSubCategories || []
                    }
                    getOptionLabel={(option) => option.title || ""}
                    name="subCategoryId"
                    onChange={(event, newValue) => {
                      handleFilters("subCategoryId", newValue?.id || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Sub Category"
                      />
                    )}
                    value={
                      categoriesData
                        ?.find((x) => x.id === values.categoryId)
                        ?.helpDeskSubCategories?.find(
                          (t) => t.id === values.subCategoryId
                        ) || null
                    }
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={priorityData || []}
                    name="priority"
                    onChange={(event, newValue) => {
                      handleFilters("priority", newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Priority"
                      />
                    )}
                    value={values.priority || ""}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={statusData || []}
                    name="status"
                    onChange={(event, newValue) => {
                      handleFilters("status", newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        autoComplete="off"
                        label="Status"
                      />
                    )}
                    value={values.status || ""}
                  />
                </div>
                <div className="employee-classification-dropdown">
                  <Autocomplete
                    size="small"
                    fullWidth
                    popupIcon={
                      <img src="/assets/images/arrow-down.svg" alt="dropdown" />
                    }
                    options={stateData || []}
                    name="state"
                    onChange={(event, newValue) => {
                      handleFilters("state", newValue || "");
                    }}
                    renderInput={(params) => (
                      <TextField {...params} autoComplete="off" label="State" />
                    )}
                    value={values.state || ""}
                  />
                </div>
                <div className="employee-classification-dropdown employee-classification-DatePicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} item>
                    <DesktopDatePicker
                      xs={6}
                      label="From"
                      value={values.dateFrom || null}
                      inputFormat="dd/MM/yyyy"
                      // minDate={new Date('2017-01-01')}
                      onChange={(newValue) => {
                        handleFilters(
                          "dateFrom",
                          yearMonthDateFormat(newValue)
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={false}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div className="employee-classification-dropdown employee-classification-DatePicker">
                  <LocalizationProvider dateAdapter={AdapterDateFns} item>
                    <DesktopDatePicker
                      xs={6}
                      label="To"
                      value={values.dateTo || null}
                      inputFormat="dd/MM/yyyy"
                      // minDate={new Date('2017-01-01')}
                      onChange={(newValue) => {
                        handleFilters("dateTo", yearMonthDateFormat(newValue));
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          fullWidth
                          error={false}
                          size="small"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </Grid>
          </form>
        </Container>
      </Card>
      <Stack
        style={{ marginBottom: "5px", marginTop: "10px" }}
        direction="row"
        alignItems="start"
        className="employee-table-Stack search-filter"
      >
        {filterChips.map((chips, idx) => {
          return (
            <Chip
              className="employee-table-Stack-label search-filter-detail"
              label={chips.filter}
              color="primary"
              size="small"
              key={chips.filter}
              style={{ marginRight: "5px" }}
              variant="filled"
              onDelete={() => onDeleteChip(chips, filterChips.length)}
            />
          );
        })}
      </Stack>

      {filterChips.length !== 0 || isSearchQuery ? (
        <Typography variant="body2" sx={{ mb: 1 }}>
          {totalRecords} {"records found"}
        </Typography>
      ) : (
        ""
      )}

      <Card className="custom-grid">
        <Scrollbar>
          <TableContainer component={Paper} sx={{ minWidth: 800 }}>
            <Card>
              <CustomPagination
                totalPage={totalPages}
                totalCount={totalRecords}
                limit={limit}
                handleChangePage={handleChangePage}
                page={currentPage}
                rowsPerPageOptions={[10, 20, 40]}
                handleRowsPerPageChange={handleChangeRowsPerPage}
                // filter={isSearchQuery ? true : false}
              />
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "ticket"}
                        direction={sortBy === "ticket" ? orderBy : "asc"}
                        onClick={() => handleSorting("ticket")}
                      >
                        Ticket Id
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "categoryId"}
                        direction={sortBy === "categoryId" ? orderBy : "asc"}
                        onClick={() => handleSorting("categoryId")}
                      >
                        Category
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "createdBy"}
                        direction={sortBy === "createdBy" ? orderBy : "asc"}
                        onClick={() => handleSorting("createdBy")}
                      >
                        Created By
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "assignedTo"}
                        direction={sortBy === "assignedTo" ? orderBy : "asc"}
                        onClick={() => handleSorting("assignedTo")}
                      >
                        Assigned To
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "status"}
                        direction={sortBy === "status" ? orderBy : "asc"}
                        onClick={() => handleSorting("status")}
                      >
                        Status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "state"}
                        direction={sortBy === "state" ? orderBy : "asc"}
                        onClick={() => handleSorting("state")}
                      >
                        State
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "priority"}
                        direction={sortBy === "priority" ? orderBy : "asc"}
                        onClick={() => handleSorting("priority")}
                      >
                        Priority
                      </TableSortLabel>
                    </TableCell>
                    <TableCell>
                      <TableSortLabel
                        active={sortBy === "updatedDate"}
                        direction={sortBy === "updatedDate" ? orderBy : "asc"}
                        onClick={() => handleSorting("updatedDate")}
                      >
                        Last Action
                      </TableSortLabel>
                    </TableCell>
                    <TableCell align="center">Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell align="center" colSpan={8}>
                        <Loader />
                      </TableCell>
                    </TableRow>
                  ) : Array.isArray(data) && data.length === 0 ? (
                    <TableRow>
                      <TableCell
                        className="No-Record-text"
                        align="center"
                        colSpan={3}
                      >
                        No Record(s) Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    Array.isArray(ticketsData) &&
                    ticketsData.length > 0 &&
                    ticketsData?.map((ticket, idx) => (
                      <TableRow
                        key={idx}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {ticket?.ticket || ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket?.category || ""}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket?.createdByName || "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket?.assignedToName || "-"}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Chip
                            color={getStatusColor(ticket.status)}
                            label={ticket?.status || ""}
                            variant="outlined"
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket?.state === "NEW" ? (
                            <strong>{ticket?.state || ""}</strong>
                          ) : (
                            ticket?.state || ""
                          )}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <CustomSelect
                            priority={ticket.priority}
                            ticket={ticket}
                            priorityData={priorityData}
                            handlePriorityChange={handlePriorityChange}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {ticket?.updatedAt || ""}
                        </TableCell>

                        <TableCell align="right">
                          {(userId === ticket?.createdBy ||
                            userId === ticket?.reporterPerson) &&
                          ticket?.status !== "CLOSED" ? (
                            <IconButton
                              data-cy="btn-edit-holiday"
                              // onClick={() => handleUpdate(ticket)}
                              onClick={(e) =>
                                window.open(
                                  `/tickets/edit/${encryption(ticket.id)}`,
                                  "_blank"
                                )
                              }
                            >
                              <EditIcon color="primary" />
                            </IconButton>
                          ) : null}
                          <IconButton
                            style={{ padding: "5px" }}
                            // component={RouterLink}
                            // to={`/tickets/view/${encryption(ticket.id)}`}
                            onClick={(e) =>
                              window.open(
                                `/tickets/view/${encryption(ticket.id)}`,
                                "_blank"
                              )
                            }
                          >
                            <VisibilityIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </Card>
          </TableContainer>
        </Scrollbar>
      </Card>
    </Container>
  );
}

const componentConfig = {
  component: HelpDeskTickets,
  path: "/tickets",
  public: false,
  layout: DashboardLayout,
  group: "Helpdesk",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
