import React from "react";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useConfirm } from "material-ui-confirm";

import Scrollbar from "../../components/Scrollbar";
import DashboardLayout from "../../layouts/dashboard";
import Loader from "../../components/Loader";

import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import IconButton from "@mui/material/IconButton";
import TableSortLabel from "@mui/material/TableSortLabel";

import {
	getPageMaster,
	deletePageMasterById,
} from "../../redux/pageMaster/pageMasterThunk";
import { encryption } from "../../utils/encodeString";
import invertDirection from "../../utils/invertDirection";
import { setOrderBy, setSortBy } from "../../redux/pageMaster/pageMasterSlice";
import {
	getViewVisible,
	getAddVisible,
	getEditVisible,
	getDeleteVisible,
} from "../../utils/userPermission";

function PageMaster() {
	const confirm = useConfirm();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	// User Permission Use Effect : If user not has access to this module then redirect to dashboard
	// useEffect(() => {
	// 	if (!getViewVisible("page-master")) {
	// 		navigate("/dashboard");
	// 	}
	// }, []);

	const { data, totalRecords, currentPage, loading, sortBy, orderBy } =
		useSelector((state) => state.pageMaster);

	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(5);

	useEffect(() => {
		dispatch(getPageMaster({ page: 1, limit: rowsPerPage, sortBy, orderBy }));
	}, [sortBy, orderBy]);

	const handleDelete = async (id, title) => {
		try {
			await confirm({
				description: `Are you sure you want to delete ${title}?`,
			});
			await dispatch(deletePageMasterById(id));
			await dispatch(
				getPageMaster({
					page: currentPage,
					limit: rowsPerPage,
					sortBy,
					orderBy,
				}),
			);
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleChangePage = (event, newPage) => {
		dispatch(getPageMaster({ page: newPage + 1, limit: rowsPerPage }));
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({ orderBy: invertDirection(sortBy === columnName, orderBy) }),
		);
	};

	return (
		<Container maxWidth="xl">
			<Stack
				direction="row"
				alignItems="center"
				justifyContent="space-between"
				mb={2}
			>
				<Typography variant="h4" gutterBottom>
					Pages
				</Typography>
				<Stack direction="row" alignItems="center">
					<Button
						component={Link}
						to="/page-master/add"
						variant="contained"
						startIcon={<AddIcon />}
					>
						Add Pages
					</Button>
				</Stack>
			</Stack>

			{loading ? (
				<Loader />
			) : (
				<Card>
					<Scrollbar>
						<TableContainer sx={{ minWidth: 800 }}>
							<Table>
								<TableHead>
									<TableRow>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "title"}
												direction={sortBy === "title" ? orderBy : "asc"}
												onClick={() => handleSorting("title")}
											>
												Title
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "accessKey"}
												direction={sortBy === "accessKey" ? orderBy : "asc"}
												onClick={() => handleSorting("accessKey")}
											>
												Access Key
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "metaKeyword"}
												direction={sortBy === "metaKeyword" ? orderBy : "asc"}
												onClick={() => handleSorting("metaKeyword")}
											>
												Meta Keyword
											</TableSortLabel>
										</TableCell>
										<TableCell align="left">
											<TableSortLabel
												active={sortBy === "status"}
												direction={sortBy === "status" ? orderBy : "asc"}
												onClick={() => handleSorting("status")}
											>
												Status
											</TableSortLabel>
										</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{Array.isArray(data) && data.length === 0 ? (
										<TableRow>
											<TableCell className="No-Record-text" align="center" colSpan={4}>
											No Record(s) Found
											</TableCell>
										</TableRow>
									) : (
										data.map((pageMaster, idx) => (
											<TableRow key={idx}>
												<TableCell align="left">{pageMaster.title}</TableCell>
												<TableCell align="left">
													{pageMaster.accessKey}
												</TableCell>
												<TableCell align="left">
													{pageMaster.metaKeyword}
												</TableCell>
												<TableCell align="left">{pageMaster.status}</TableCell>
												<TableCell align="right">
													<div className="action-button">
														<span style={{ marginRight: 8 }}>
															<IconButton
																color="primary"
																component={Link}
																to={`/page-master/add/${encryption(
																	pageMaster.id,
																)}`}
															>
																<EditIcon />
															</IconButton>
														</span>
														<span>
															<IconButton
																onClick={() =>
																	handleDelete(pageMaster.id, pageMaster.title)
																}
															>
																<DeleteIcon color="error" />
															</IconButton>
														</span>
													</div>
												</TableCell>
											</TableRow>
										))
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Scrollbar>

					<TablePagination
						rowsPerPageOptions={[5, 10]}
						component="div"
						count={totalRecords}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Card>
			)}
		</Container>
	);
}

const componentConfig = {
	component: PageMaster,
	path: "/page-master",
	public: false,
	group: "cms",
	layout: DashboardLayout,
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;
