import { Button, Card, Container, IconButton, Stack, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import DashboardLayout from "../../layouts/dashboard";
import { getDeleteVisible, getEditVisible } from "../../utils/userPermission";
import Scrollbar from "../../components/Scrollbar";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouterLink } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteFAQ, getFAQs, updateFAQ } from "../../redux/faqs/faqsThunk";
import { encryption } from "../../utils/encodeString";
import { toast } from "react-toastify";
import { useConfirm } from "material-ui-confirm";
import { getUserPermissionByRoll } from "../../redux/userPermission/userPermissionthunk";
import LocalStorage from "../../service/localStorage";
function FAQs() {
    const faqsData=useSelector(state=>state.faqs.faqsData)
    // console.log("FAQSDATA______",faqsData)
    const dispatch = useDispatch()
    const confirm = useConfirm();
    const getUserPermission = async (Id) => {
      try {
        const res = await dispatch(
          getUserPermissionByRoll({
            id: Id,
          })
        ).unwrap();
        LocalStorage.setItem(
          "userPermissions",
          encryption(JSON.stringify(res.data))
        );
        // console.log(res.data,"db");
      } catch (error) {}
    };
    useEffect(() => {
      let roleId = LocalStorage.getItem("roleId");
      getUserPermission(roleId);
    }, [dispatch]);
    useEffect(()=>{
        dispatch(getFAQs({})).unwrap()
    },[])

    const handleToggle = async (faq) => {
        try {
          // console.log("EVALUATION______",evaluation)
          if(faq?.isActive == true){
              let faqsArr= faq.faqs.map((item)=>{
                  return {...item,isActive : false}
              })
            let editObj={
                title: faq.title,
                isActive: false,
                description:faq?.description,
                faqs:faqsArr
            }
            await dispatch(
                updateFAQ({editFAQsData:editObj,faqCategoryId:faq?.id})
            ).unwrap();
          }
          if(faq?.isActive == false){
            let faqsArr= faq.faqs.map((item)=>{
                  return {...item,isActive : true}
              })
            let editObj={
              title: faq.title,
              isActive: true,
              description:faq?.description,
              faqs:faqsArr
            }
            await dispatch(
                updateFAQ({editFAQsData:editObj,faqCategoryId:faq?.id})
            ).unwrap();
          }
    
          await dispatch(
            getFAQs({})
          );
        } catch (error) {
          toast.error(error.message);
        }
      };
      const handleDelete = async (id) => {
        try {
          await confirm({
            description: `Are you sure you want to delete?`,
          });
          await dispatch(deleteFAQ(id)).unwrap();
          await dispatch(
            getFAQs({})
          );
        } catch (error) {
          toast.error(error?.message);
        }
      };
    

    return ( 
        <Container className="Employee-wrapper faq-main-container" maxWidth="xl">
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
        >
        <div className="title-button-wrapper faq-main-title">
            <Typography variant="h4" gutterBottom>
                FAQs ({faqsData?.length || 0})
                {/* <Typography variant="body2">
        Total Records : {faqsData?.length || 0}
      </Typography> */}
            </Typography>
            {/* {getAddVisible("faqs") && ( */}
                <Button
                    className="header-add-button"
                    variant="contained"
                    component={RouterLink}
                    to="/faqs/add"
                    // startIcon={<AddIcon />}
                >
                  <img src="/assets/images/add-circle.svg" />
                    Add FAQs
                </Button>
            {/* )} */}
    </div>
        </Stack>

        <Card className="faq-main-card" mb={5}>
            <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                    <Table className="faq-table grid-table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Title</TableCell>
                                <TableCell align="left">Description</TableCell>
                                <TableCell align="left">Status</TableCell>
                                {(getEditVisible("faqs") || getDeleteVisible("faqs")) && (
                                        <TableCell className="th-action-buttons" align="center">Action</TableCell>
                                 )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {(faqsData && faqsData?.length > 0) ?
faqsData?.map((faq,index)=>(

                            <TableRow key={index}>
                                <TableCell>{faq?.title}</TableCell>
                                <TableCell>{faq?.description}</TableCell>
                                <TableCell>
                                <Switch
                              checked={faq?.isActive}
                              onChange={() => handleToggle(faq)}
                              inputProps={{ "aria-label": "controlled" }}
                              size="small"
                              disabled={!getEditVisible("faqs")}
                            />
                                </TableCell>
                                {(getEditVisible("faqs") || getDeleteVisible("faqs")) && (
                                        <TableCell className="td-action-button" align="center">
                                            <div className="action-button">
                                                {getEditVisible("faqs") && (
                                    <IconButton
                                      component={RouterLink}
                                      to={`/faqs/edit/${encryption(
                                        faq?.id
                                      )}`}
                                    >
                                      <img src="assets/images/edit.svg" title="Edit" />
                                      {/* <EditIcon color="primary" /> */}
                                    </IconButton>
                                )}
                                  {/* <IconButton
									style={{ padding: "5px" }}
									component={RouterLink}
									to={`/vm/view/${encryption(email.id)}`}
								>
									<VisibilityIcon />
								</IconButton> */}
                                 {getDeleteVisible("faqs") && ( 
                                    <span>
                                      <IconButton
                                        onClick={() => handleDelete(faq?.id)}
                                      >
                                        <img src="assets/images/trash-gray.svg" title="trash" />
                                        {/* <DeleteIcon color="error" /> */}
                                      </IconButton>
                                    </span>
                                 )}
                                                                            </div>
                                        </TableCell>
                                 )}
                            </TableRow>
)): 
<TableRow>
										<TableCell className="No-Record-text" colspan="12">No Record(s) Found</TableCell>
									</TableRow>
}

                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
        </Card>
    </Container> 
     );
}

const componentConfig = {
	component: FAQs,
	path: "/faqs",
	public: false,
	layout: DashboardLayout,
	group: "organization",
	sidebar: true,
	role: ["admin"],
};

export default componentConfig;