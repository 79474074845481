import React, { useEffect, useState } from "react";

import NiceModal from "@ebay/nice-modal-react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import FormHelperText from "@mui/material/FormHelperText";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddAreaModal from "./AddAreaModal";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { generateObject } from "../../../../utils/generateAddressObject";
const filter = createFilterOptions();

export default function Address(props) {
	const {
		values,
		setFieldValue,
		touched,
		errors,
		handleTabChange,
		getFieldProps,
		setArea,
		getAreaData,
		getPostalCode,
		states,
		cities,
		areas,
		permanentStates,
		permanentCities,
		permanentAreas,
		setpermanentAreas,
		handleArea,
		handleCity,
		handleState,
		handlePermanentArea,
		handlePermanentCity,
		handlePermanentState,
		sameAsCurrent,
		setSameAsCurrent,
		id,
	} = props;

	const countryData = ["India"];

	useEffect(() => {
		if (id && values.address) {
			setSameAsCurrent(generateObject(values.address));
			setFieldValue("address.sameAsCurrent", generateObject(values.address));
		}
	}, []);

	const setSameAsPresentAddress = async () => {
		try {
			if (values.address.presentState !== "") {
				await handlePermanentState({
					target: { value: values.address.presentState },
				});
			}

			if (values.address.presentCity !== "") {
				await handlePermanentCity({
					target: { value: values.address.presentCity },
				});
			}

			setFieldValue(
				"address.permenentStreetLine1",
				values.address.presentStreetLine1,
			);
			setFieldValue(
				"address.permenentStreetLine2",
				values.address.presentStreetLine2,
			);
			setFieldValue("address.permenentCountry", values.address.presentCountry);
			setFieldValue("address.permenentState", values.address.presentState);
			setFieldValue("address.permenentCity", values.address.presentCity);

			setFieldValue("address.permenentArea", values.address.presentArea);
			setFieldValue("address.permenentZipCode", values.address.presentZipCode);
		} catch (error) {
			
		}
	};

	const handleSameAsCurrent = async (event) => {
		try {
			setSameAsCurrent(event.target.checked);
			setFieldValue("address.sameAsCurrent", event.target.checked);
			if (event.target.checked) {
				await setSameAsPresentAddress();
			}
		} catch (error) {
			
		}
	};

	const handlePresentOnChange = (e, label) => {
		if (values.address.sameAsCurrent) {
			setFieldValue(`address.permenent${label}`, e.target.value);
		}

		setFieldValue(`address.present${label}`, e.target.value);
	};

	const handleAreaAutoComplete = async (res) => {
		setFieldValue(`address.presentArea`, res.area);
		setFieldValue(`address.presentZipCode`, res.postalCode);
		setFieldValue(`address.presentState`, res.state);
		setFieldValue(`address.presentCity`, res.city);
		const areaData = await getAreaData(res.city);
		setArea(areaData);
		if (values.address.sameAsCurrent) {
			setFieldValue(`address.permenentArea`, res.area);
			setFieldValue(`address.permenentZipCode`, res.postalCode);
			setFieldValue(`address.presentState`, res.state);
			setFieldValue(`address.permenentCity`, res.city);
			setpermanentAreas(areaData);
		}
	};

	const handlePermenentAreaAutoComplete = async (res, values) => {
		setFieldValue(`address.permenentArea`, res.area);
		setFieldValue(`address.permenentZipCode`, res.postalCode);
		setFieldValue(`address.permenentState`, res.state);
		setFieldValue(`address.permenentCity`, res.city);
		const areaData = await getAreaData(res.city);
		setpermanentAreas(areaData);

		// 		sameAsCurrent: false,

		// console.log(values);
	};

	const fillPostalCode = async (area) => {
		setFieldValue(`address.presentArea`, area);
		const postalCode = await getPostalCode({
			area,
			city: values.address.presentCity,
		});
		setFieldValue("address.presentZipCode", postalCode);
		if (values.address.sameAsCurrent) {
			setFieldValue(`address.permenentArea`, area);
			setFieldValue("address.permenentZipCode", postalCode);
		}
	};

	const fillPermenentPostalCode = async (area) => {
		setFieldValue(`address.permenentArea`, area);
		const postalCode = await getPostalCode({
			area,
			city: values.address.permenentCity,
		});
		setFieldValue("address.permenentZipCode", postalCode);
	};

	return (
		<Card className="right-side-content-part">
			<Container maxWidth="xl">
				<div>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mt={3}
						mb={2}
						className="Employee_Detail-heading"
					>
						<Typography className="Employee_Detail-h6" variant="h6" gutterBottom style={{marginTop:"0"}}>
							Address
						</Typography>
					</Stack>
					<Grid className="gray-box Employee_Detail_Content" container spacing={3}>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Street Line 1"
								variant="outlined"
								size="small"
								autoComplete="disabled"
								name="address.presentStreetLine1"
								inputProps={{ maxLength: 256 }}
								value={values.address?.presentStreetLine1 || ""}
								onChange={(e) => handlePresentOnChange(e, "StreetLine1")}
								// {...getFieldProps(`address.presentStreetLine1`)}
								error={Boolean(
									touched?.address?.presentStreetLine1 &&
										errors?.address?.presentStreetLine1,
								)}
								helperText={
									touched?.address?.presentStreetLine1 &&
									errors?.address?.presentStreetLine1
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Street Line 2"
								variant="outlined"
								size="small"
								autoComplete="disabled"
								name="address.presentStreetLine2"
								value={values.address?.presentStreetLine2 || ""}
								inputProps={{ maxLength: 256 }}
								onChange={(e) => handlePresentOnChange(e, "StreetLine2")}
								// {...getFieldProps(`address.presentStreetLine2`)}
								error={Boolean(
									touched?.address?.presentStreetLine2 &&
										errors?.address?.presentStreetLine2,
								)}
								helperText={
									touched?.address?.presentStreetLine2 &&
									errors?.address?.presentStreetLine2
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControl
								error={Boolean(
									touched?.address?.presentCountry &&
										errors?.address?.presentCountry,
								)}
								size="small"
								sx={{ minWidth: 120 }}
								fullWidth
							>
								{/*<InputLabel id="demo-simple-select-helper-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Country"
                  size="small"
                  defaultValue="India"
                  // {...getFieldProps(`address.presentCountry`)}
                  name="address.presentCountry"
                  value={values.address?.presentCountry || ""}
                  onChange={(e) => handlePresentOnChange(e, "Country")}
                >
                  <MenuItem value={"India"}>India</MenuItem>
                </Select>*/}
								<Autocomplete
									labelId="demo-simple-select-helper-label"
									id="demo-simple-select-helper"
									label="country"
									size="small"
									popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
									onChange={(event, newValue) => {
										if (values.address.sameAsCurrent) {
											setFieldValue(`address.permenentCountry`, newValue);
										}

										setFieldValue(`address.presentCountry`, newValue);
									}}
									value={values.address?.presentCountry || ""}
									options={countryData || []}
									renderInput={(params) => (
										<TextField {...params} label="Country" />
									)}
								/>
								<FormHelperText style={{ margin: "5px 0 0 0" }}>
									{touched?.address?.presentCountry &&
										errors?.address?.presentCountry}
								</FormHelperText>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							{/*<InputLabel id="demo-simple-select-helper-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="State"
                  size="small"
                  defaultValue=""
                  {...getFieldProps(`address.presentState`)}
                  onChange={handleState}
                >
                  {states &&
                    states.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                    </Select> */}
							<Autocomplete
								labelId="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								label="state"
								size="small"
								name="state"
								popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
								defaultValue=""
								onChange={handleState}
								value={values.address.presentState}
								disabled={!values.address.presentCountry}
								options={states || []}
								renderInput={(params) => (
									<TextField
										{...params}
										label="State"
										error={Boolean(
											touched?.address?.presentState &&
												errors?.address?.presentState,
										)}
										helperText={
											touched?.address?.presentState &&
											errors?.address?.presentState
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							{/*<InputLabel id="demo-simple-select-helper-label">
                  City
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="city"
                  defaultValue=""
                  size="small"
                  {...getFieldProps("address.presentCity")}
                  onChange={handleCity}
                >
                  {cities &&
                    cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                    </Select> */}

							<Autocomplete
								labelId="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								label="state"
								size="small"
								name="state"
								popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
								onChange={handleCity}
								value={values.address.presentCity}
								disabled={!values.address.presentState}
								options={cities || []}
								renderInput={(params) => (
									<TextField
										{...params}
										label="City"
										error={Boolean(
											touched?.address?.presentCity &&
												errors?.address?.presentCity,
										)}
										helperText={
											touched?.address?.presentCity &&
											errors?.address?.presentCity
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControl size="small" fullWidth>
								<Autocomplete
									// disabled={!values.address.presentCity}
									value={
										areas.find((area) => area === values.address.presentArea) ??
										""
									}
									onChange={async (event, newValue) => {
										if (newValue && newValue.inputValue) {
											const res = await NiceModal.show(AddAreaModal, {
												country: values?.address?.presentCountry,
												state: values?.address?.presentState,
												city: values?.address?.presentCity,
												areaName: newValue.inputValue,
											});
											handleAreaAutoComplete(res);
										} else {
											fillPostalCode(newValue);
										}
									}}
									filterOptions={(options, params) => {
										const filtered = filter(options, params);

										if (params.inputValue !== "" && !filtered.length) {
											filtered.push({
												inputValue: params.inputValue,
												name: `Add "${params.inputValue}"`,
											});
										}

										// console.log(filtered);
										return filtered;
									}}
									options={areas}
									getOptionLabel={(option) => {
										// e.g value selected with enter, right from the input
										if (typeof option === "string") {
											return option;
										}

										if (option.inputValue) {
											return option.name;
										}
										return (
											<li
												{...props}
												value={option.inputValue}
												key={option.inputValue}
											>
												{option.name}
											</li>
										);
									}}
									fullWidth
									size="small"
									freeSolo
									renderInput={(params) => (
										<TextField
											{...params}
											label="Area"
											helperText={
												touched?.address?.presentArea &&
												errors?.address?.presentArea
											}
											error={Boolean(
												touched?.address?.presentArea &&
													errors?.address?.presentArea,
											)}
										/>
									)}
								/>
							</FormControl>
						</Grid>

						{/*<Grid item xs={6}>
                            <FormControl
                                error={Boolean(
                                    touched?.address?.presentArea &&
                                        errors?.address?.presentArea
                                )}
                                size="small"
                                sx={{ minWidth: 120 }}
                                fullWidth
                            >
                                <InputLabel id="demo-simple-select-helper-label">
                                    Area
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    label="area"
                                    size="small"
                                    defaultValue=""
                                    {...getFieldProps(`address.presentArea`)}
                                    onChange={handleArea}
                                >
                                    {areas &&
                                        areas.map((area) => (
                                            <MenuItem key={area} value={area}>
                                                {area}
                                            </MenuItem>
                                        ))}
                                </Select>
                                <FormHelperText>
                                    {touched?.address?.presentArea &&
                                        errors?.address?.presentArea}
                                </FormHelperText>
                            </FormControl>
                        </Grid>*/}
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Postal Code"
								variant="outlined"
								size="small"
								inputProps={{ maxLength: 8, readOnly: true }}
								{...getFieldProps(`address.presentZipCode`)}
								autoComplete="disabled"
								error={Boolean(
									touched?.address?.presentZipCode &&
										errors?.address?.presentZipCode,
								)}
								helperText={
									touched?.address?.presentZipCode &&
									errors?.address?.presentZipCode
								}
							/>
						</Grid>
					</Grid>
				</div>

				<div className="p-address">
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="space-between"
						mb={2}
						mt={2}
						className="Employee_Detail-heading"
					>
						<Typography className="Employee_Detail-h6" variant="h6" gutterBottom>
							Permanent Address
						</Typography>
						<div>
							<FormGroup>
								<FormControlLabel
									control={
										<Checkbox
											checked={values.address.sameAsCurrent || sameAsCurrent}
											onChange={handleSameAsCurrent}
										/>
									}
									label="Same as current"
								/>
							</FormGroup>
						</div>
					</Stack>
					<Grid className="gray-box Employee_Detail_Content" container spacing={3}>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Street Line 1"
								variant="outlined"
								size="small"
								disabled={sameAsCurrent}
								autoComplete="disabled"
								inputProps={{ maxLength: 256 }}
								{...getFieldProps(`address.permenentStreetLine1`)}
								error={Boolean(
									touched?.address?.permenentStreetLine1 &&
										errors?.address?.permenentStreetLine1,
								)}
								helperText={
									touched?.address?.permenentStreetLine1 &&
									errors?.address?.permenentStreetLine1
								}
							/>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Street Line 2"
								variant="outlined"
								size="small"
								disabled={sameAsCurrent}
								inputProps={{ maxLength: 256 }}
								autoComplete="disabled"
								{...getFieldProps(`address.permenentStreetLine2`)}
								error={Boolean(
									touched?.address?.permenentStreetLine2 &&
										errors?.address?.permenentStreetLine2,
								)}
								helperText={
									touched?.address?.permenentStreetLine2 &&
									errors?.address?.permenentStreetLine2
								}
							/>
						</Grid>
						<Grid item xs={6}>
							{/*<InputLabel id="demo-simple-select-helper-label">
                  Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="Country"
                  size="small"
                  disabled={values.address.sameAsCurrent}
                  defaultValue=""
                  {...getFieldProps("address.permenentCountry")}
                >
                  <MenuItem value={"India"}>India</MenuItem>
                </Select>*/}
							<Autocomplete
								labelId="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								label="country"
								size="small"
								popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
								disabled={sameAsCurrent}
								{...getFieldProps("address.permenentCountry")}
								onChange={(event, newValue) => {
									setFieldValue(`address.permenentCountry`, newValue);
								}}
								value={values.address?.permenentCountry || ""}
								options={countryData || []}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Country"
										error={Boolean(
											touched?.address?.permenentCountry &&
												errors?.address?.permenentCountry,
										)}
										helperText={
											touched?.address?.permenentCountry &&
											errors?.address?.permenentCountry
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							{/*<InputLabel id="demo-simple-select-helper-label">
                  State
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="State"
                  defaultValue=""
                  size="small"
                  disabled={values.address.sameAsCurrent}
                  {...getFieldProps(`address.permenentState`)}
                  onChange={handlePermanentState}
                >
                  {permanentStates &&
                    permanentStates.map((state) => (
                      <MenuItem key={state} value={state}>
                        {state}
                      </MenuItem>
                    ))}
                    </Select> */}
							<Autocomplete
								labelId="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								label="state"
								size="small"
								name="permenentState"
								popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
								defaultValue=""
								disabled={sameAsCurrent}
								onChange={handlePermanentState}
								value={values.address.permenentState}
								options={permanentStates || []}
								renderInput={(params) => (
									<TextField
										{...params}
										label="State"
										error={Boolean(
											touched?.address?.permenentState &&
												errors?.address?.permenentState,
										)}
										helperText={
											touched?.address?.permenentState &&
											errors?.address?.permenentState
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							{/*<InputLabel id="demo-simple-select-helper-label">
                  City
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  label="city"
                  size="small"
                  disabled={values.address.sameAsCurrent}
                  defaultValue=""
                  {...getFieldProps(`address.permenentCity`)}
                  onChange={handlePermanentCity}
                >
                  {permanentCities &&
                    permanentCities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                    </Select> */}
							<Autocomplete
								labelId="demo-simple-select-helper-label"
								id="demo-simple-select-helper"
								label="state"
								size="small"
								popupIcon={<img src="/assets/images/arrow-down.svg" alt="dropdown" />}
								disabled={sameAsCurrent}
								name="permenentCity"
								defaultValue=""
								options={permanentCities || []}
								value={values.address.permenentCity}
								onChange={handlePermanentCity}
								renderInput={(params) => (
									<TextField
										{...params}
										label="City"
										error={Boolean(
											touched?.address?.permenentCity &&
												errors?.address?.permenentCity,
										)}
										helperText={
											touched?.address?.permenentCity &&
											errors?.address?.permenentCity
										}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6}>
							<FormControl size="small" fullWidth>
								<Autocomplete
									disabled={sameAsCurrent}
									value={
										permanentAreas.find(
											(area) => area === values.address.permenentArea,
										) ?? ""
									}
									onChange={async (event, newValue) => {
										if (newValue && newValue.inputValue) {
											const res = await NiceModal.show(AddAreaModal, {
												country: values?.address?.permenentCountry,
												state: values?.address?.permenentState,
												city: values?.address?.permenentCity,
												areaName: newValue.inputValue,
											});
											handlePermenentAreaAutoComplete(res);
										} else {
											fillPermenentPostalCode(newValue);
										}
									}}
									filterOptions={(options, params) => {
										const filtered = filter(options, params);

										if (params.inputValue !== "" && !filtered.length) {
											filtered.push({
												inputValue: params.inputValue,
												name: `Add "${params.inputValue}"`,
											});
										}
										return filtered;
									}}
									options={permanentAreas}
									getOptionLabel={(option) => {
										// e.g value selected with enter, right from the input
										if (typeof option === "string") {
											return option;
										}

										if (option.inputValue) {
											return option.inputValue;
										}
										return option;
									}}
									selectOnFocus
									handleHomeEndKeys
									renderOption={(props, option) => {
										if (typeof option === "string") {
											option = {
												name: option,
												inputValue: option,
											};
										}
										return (
											<li
												{...props}
												value={option.inputValue}
												key={option.inputValue}
											>
												{option.name}
											</li>
										);
									}}
									fullWidth
									size="small"
									freeSolo
									renderInput={(params) => (
										<TextField
											{...params}
											label="Area"
											error={Boolean(
												touched?.address?.permenentArea &&
													errors?.address?.permenentArea,
											)}
											helperText={
												touched?.address?.permenentArea &&
												errors?.address?.permenentArea
											}
										/>
									)}
								/>
							</FormControl>
						</Grid>
						<Grid item xs={6}>
							<TextField
								fullWidth
								label="Postal Code"
								variant="outlined"
								size="small"
								disabled={sameAsCurrent}
								inputProps={{ maxLength: 6, readOnly: true }}
								{...getFieldProps(`address.permenentZipCode`)}
								autoComplete="disabled"
								error={Boolean(
									touched?.address?.permenentZipCode &&
										errors?.address?.permenentZipCode,
								)}
								helperText={
									touched?.address?.permenentZipCode &&
									errors?.address?.permenentZipCode
								}
							/>
						</Grid>
					</Grid>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="flex-end"
						mb={3}
						mt={4}
					>
						<Button
							className="back-button"
							onClick={() => handleTabChange("2")}
							variant="contained"
							style={{ marginRight: "15px" }}
							startIcon={<ArrowBackIcon />}
						>
							Back
						</Button>
						<Button onClick={() => handleTabChange("4")} variant="contained" endIcon={<ArrowForwardIcon />}>
							Next
						</Button>
					</Stack>
				</div>
			</Container>
		</Card>
	);
}
