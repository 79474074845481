import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Loader from "../../../components/Loader";
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import invertDirection from "../../../utils/invertDirection";
import TableSortLabel from "@mui/material/TableSortLabel";

import { course } from "../../../validations/mastersSchema";

import {
  getViewVisible,
  getAddVisible,
  getEditVisible,
  getDeleteVisible,
} from "../../../utils/userPermission";
import {
  addStudentStatus,
  deleteStudentStatusById,
  getStudentStatus,
  updateStudentStatusById,
} from "../../../redux/student/studentThunk";
import { setSortBy, setOrderBy } from "../../../redux/student/studentSlice";

const StudentStatus = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.student.data);
  const loading = useSelector((state) => state.student.loading);
  const error = useSelector((state) => state.student.error);
	const sortBy = useSelector((state) => state.student.sortBy);
	const orderBy = useSelector((state) => state.student.orderBy);

  const [id, setId] = React.useState(null);

  const formik = useFormik({
    initialValues: {
      title: "",
      isActive: false,
    },
    validationSchema: course,
    onSubmit: async (values, { resetForm }) => {
      try {
        if (id) {
          await dispatch(updateStudentStatusById({ ...values, id })).unwrap();
        }
        if (!id) {
          await dispatch(addStudentStatus(values)).unwrap();
        }
        resetForm();
        dispatch(getStudentStatus());
        return setId(null);
      } catch (error) {
        toast.error(error.message);
      }
    },
  });
  const { errors, touched, handleSubmit, setValues } = formik;

  React.useEffect(() => {
    if (
      !loading &&
      !error &&
      (!data || (Array.isArray(data) && data.length <= 0))
    )
      dispatch(getStudentStatus());
  }, [loading]);

  React.useEffect(() => {
    dispatch(
      getStudentStatus({
        sortBy,
        orderBy,
      }),
    );
}, [dispatch,sortBy, orderBy]);

  const handleUpdate = (data) => {
    setValues({
      title: data.status,
      isActive: data.isActive,
    });
    setId(data.id);
  };

  const handleDelete = async (id) => {
    try {
      if (id) {
        await dispatch(deleteStudentStatusById(id)).unwrap();
      }
      dispatch(getStudentStatus());
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleToggle = async (student) => {
    try {
      await dispatch(
        updateStudentStatusById({
          id: student.id,
          title: student.status,
          isActive: !student.isActive,
        })
      ).unwrap();
      dispatch(getStudentStatus());
    } catch (error) {
      toast.error(error.message);
    }
  };
  const handleSorting = (columnName) => {
		dispatch(setSortBy({ sortBy: columnName }));
		dispatch(
			setOrderBy({
				orderBy: invertDirection(sortBy === columnName, orderBy),
			}),
		);
	};

  return (
    <>
    {/*<Card className="inner-component">
      <Container className="custom-container-grid"> */}
       <Container
          className="custom-container-grid"
          style={{
            boxShadow: "initial !important",
            paddingLeft: "0",
            paddingRight: "0",
            minWidth: "100%",
            minHeight: "70%",
          }}
        >
        <div className="header">
          <form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <h6 className="component-name">STUDENT STATUS MASTER</h6>
            {getAddVisible("master") && (
              <div className="heading-part">
                <div className="input-field-part">
                  <TextField
                    id="outlined-basic"
                    label="Enter Student Status"
                    variant="outlined"
                    size="small"
                    name="title"
                    inputProps={{ maxLength: 64 }}
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={Boolean(touched.title && errors.title)}
                    helperText={touched.title && errors.title}
                  />
                </div>
                <div className="checkbox-part">
                  {" "}
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formik.values.isActive}
                        name="isActive"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Status"
                  />
                </div>
                <div className="button-part">
                  <Button type="submit" variant="contained" size="small">
                    Save
                  </Button>
                </div>
              </div>
            )}
          </form>
        </div>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {/* <TableCell width="10%">ID</TableCell> */}
                <TableCell width="67%">
                  <TableSortLabel
                    active={sortBy === "status"}
                    direction={sortBy === "status" ? orderBy : "asc"}
                    onClick={() => handleSorting("status")}
                  >
                    Title
                  </TableSortLabel>
                </TableCell>
                <TableCell width="10%" align="center">
                  Status
                </TableCell>
                {(getEditVisible("master") || getDeleteVisible("master")) && (
                  <TableCell width="13%" align="right" className="action-field">
                    Actions
                  </TableCell>
                )}
              </TableRow>
            </TableHead>
            {loading ? (
									<Loader />
									) : (
            <TableBody>
              {data &&
                data.map((student, idx) => (
                  <TableRow
                    key={idx}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    {/* <TableCell component="th" scope="row">
                      {student.id}
                    </TableCell> */}
                    <TableCell component="th" scope="row">
                      {student.status}
                    </TableCell>
                    <TableCell align="right">
                      <Switch
                        checked={student.isActive}
                        onChange={() => handleToggle(student)}
                        inputProps={{ "aria-label": "controlled" }}
                        size="small"
                      />
                    </TableCell>
                    {(getEditVisible("master") ||
                      getDeleteVisible("master")) && (
                      <TableCell align="right">
                        {getEditVisible("master") && (
                          <IconButton onClick={() => handleUpdate(student)}>
                            <EditIcon color="primary" />
                          </IconButton>
                        )}
                        {getDeleteVisible("master") && (
                          <IconButton onClick={() => handleDelete(student.id)}>
                            <DeleteIcon color="error" />
                          </IconButton>
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
                  )}
          </Table>
        </TableContainer>
      </Container>
    {/*</Card> */}
    </>
  );
};

export default StudentStatus;
