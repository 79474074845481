import {
  Autocomplete,
  Button,
  Card,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import DashboardLayout from "../../../layouts/dashboard";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import {
  DesktopDatePicker,
  LoadingButton,
  LocalizationProvider,
} from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import ispSchema from "../../../validations/ispSchema";
import { decryption } from "../../../utils/encodeString";
import {
  addIsp,
  getAllIspById,
  updateIsp,
} from "../../../redux/assestManagment/thunk";
import InputAdornment from "@mui/material/InputAdornment";
import Box from "@mui/material/Box";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { setISPInventoryStatus } from "../../../redux/assestManagment/assestManagmentSlice";
import Modal from "@mui/material/Modal";
import { fDateCustom } from "../../../utils/formatTime";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 24,
  gap: 2,
  p: 4,
};
const ViewIsp = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();
  if (id) id = decryption(id);
  // console.log("id", id);
  const [showRouterPassword, setShowRouterPassword] = useState(false);
  const [showWifiPassword, setShowWifiPassword] = useState(false);
  const [showPortalPassword, setShowPortalPassword] = useState(false);

  const [routerPasswordKey, setRouterPasswordKey] = useState("");
  const [wifiPasswordKey, setWifiPasswordKey] = useState("");
  const [portalPasswordKey, setPortalPasswordKey] = useState("");
  const [fildname, setFildname] = useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [values, setValues] = useState({});
  useEffect(() => {
    if (id!==-1) {
      // setIsLoading(true);
      dispatch(getAllIspById(id))
        .unwrap()
        .then((res) => {
          let temp = { ...res.data };
          if (res.data.staticIp === false) {
            temp.staticIpDetail = [
              {
                staticIpAddress: "",
                assignedToIp: "",
                assignedToDomain: "",
                ipType: "",
              },
            ];
          }
          if (res.data.fixedLine === false) {
            temp.ispFixedLine = [
              {
                phoneNumber: "",
              },
            ];
          }
          setValues(temp);
        })
        .catch((err) => {
          // toast.error(err.message);
          return navigate("/candidate");
        });
    }else{
      navigate("/isp");
    }
  }, [id, dispatch, setValues]);

  let handleShowRouterPassword = () => {
    if (!id) {
      setShowRouterPassword(!showRouterPassword);
    } else {
      if (showRouterPassword === false) {
        setFildname("routerPassword");
        handleOpen();
      } else {
        setShowRouterPassword(!showRouterPassword);
      }
    }
  };

  let handleShowWifiPassword = () => {
    if (!id) {
      setShowWifiPassword(!showWifiPassword);
    } else {
      if (showWifiPassword === false) {
        setFildname("wifiPassword");
        handleOpen();
      } else {
        setShowWifiPassword(!showWifiPassword);
      }
    }
  };

  let handleShowPortalPassword = () => {
    if (!id) {
      setShowPortalPassword(!showPortalPassword);
    } else {
      if (showPortalPassword === false) {
        setFildname("portalPassword");
        handleOpen();
      } else {
        setShowPortalPassword(!showPortalPassword);
      }
    }
  };
  let handleVerify = () => {
    if (fildname === "routerPassword") {
      if (routerPasswordKey === "1234") {
        setShowRouterPassword(!showRouterPassword);
        handleClose();
      } else {
        toast.error("Wrong Password");
        handleClose();
      }
    } else if (fildname === "wifiPassword") {
      if (wifiPasswordKey === "1234") {
        setShowWifiPassword(!showWifiPassword);
        handleClose();
      } else {
        toast.error("Wrong Password");
        handleClose();
      }
    } else if (fildname === "portalPassword") {
      if (portalPasswordKey === "1234") {
        setShowPortalPassword(!showPortalPassword);
        handleClose();
      } else {
        toast.error("Wrong Password");
        handleClose();
      }
    }
  };
  return (
    <Container maxWidth="xl">
      <Stack direction="row" alignItems="center" justifyContent="end" mb={2}>
        {/* <Typography variant="h4" gutterBottom>
        {id ? "Edit Job" : "Add Job"}
      </Typography> */}
        <Stack direction="row" alignItems="center" justifyContent="end">
        <div className="title-button-wrapper">
          <Button
            variant="contained"
            component={RouterLink}
            to="/isp"
            startIcon={<ArrowBackIcon />}
            style={{ marginLeft: "10px" }}>
            Back To List
          </Button>
          </div>
        </Stack>
      </Stack>
      {/* <Card className="holiday-table-grid" mb={5} > */}
        {/* <Container maxWidth="xl"> */}
          {/* <Paper> */}
            {/* <form onSubmit={(...args) => {
					// setSubmitted(true);
					handleSubmit(...args);
				}}> */}
        <Stack spacing={4}>
<Card sx={{pl:4,pr:4}} >
<Grid container spacing={1} mb={5}>
              <Grid item xs={12} mt={3} >
                <Typography variant="h5"  gutterBottom>
                  Basic Details
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    ISP Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.ispName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Tariff Plan Name
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.tariffPlanName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Billing Cycle Start Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.billingFrom?fDateCustom(values.billingFrom):''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Billing Cycle End Date
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.billingTo?fDateCustom(values.billingTo):''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                  Data Limit Per Bill Cycle
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.dataLimitPerBillCycle}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Rental Charges
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.rentalCharges}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Static IP Charges
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.staticIpCharges}
                  </Typography>
                </Grid>
              </Grid>
              </Grid>
</Card>

{values.staticIp&&<Stack>
  <Typography variant="h5" gutterBottom>
                Static IP Details
                </Typography>
                <Card >
                <Table>
							<TableHead>
								<TableRow>
									<TableCell align="left">IP Address</TableCell>
									<TableCell align="left">Assigned To IP</TableCell>
									<TableCell align="left">Assigned To Domain</TableCell>
									<TableCell align="left">IP Type</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{values.staticIp &&
                values &&
                values.staticIpDetail &&
                values.staticIpDetail.map((edu, index) => (
											<TableRow key={index}>
												<TableCell align="left">
                        {values.staticIpDetail[index]?.staticIpAddress||"-"}
												</TableCell>
                        <TableCell align="left">
                        {values.staticIpDetail[index]?.assignedToIp||"-"}
												</TableCell>
                        <TableCell align="left">
                        {values.staticIpDetail[index]?.assignedToDomain||"-"}
												</TableCell>
                        <TableCell align="left">
                        {values.staticIpDetail[index].ipType||"-"}
												</TableCell>
											</TableRow>
										))
									}
							</TableBody>
						</Table>





  {/* <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
    {values.staticIp&&<Stack sx={{width:"50%"}}><Grid container spacing={1} mb={5} >
    {values.staticIp &&
                values &&
                values.staticIpDetail &&
                values.staticIpDetail.map((edu, index) => (
                  <>
                  {index === 0 && (
                    <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom>
                Static IP Details
                </Typography>
              </Grid>
                )}
                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          IPAddress{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index]?.staticIpAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Assigned To Ip{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index]?.assignedToIp}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Assigned To Domain{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index]?.assignedToDomain}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Ip Type{index + 1}
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index].ipType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}
      </Grid></Stack>}
  </Stack> */}

</Card>
  </Stack>}

  {values.fixedLine&&<Stack>
  <Typography variant="h5" gutterBottom>
  Fixed Line Details
                </Typography>
                <Card >
                <Table>
							<TableHead>
								<TableRow>
									<TableCell align="center">Phone Number</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
              {values.fixedLine &&
                values &&
                values.ispFixedLine &&
                values.ispFixedLine.map((edu, index) => (
											<TableRow key={index}>
                        <TableCell align="center">
                        {values.ispFixedLine[index]?.phoneNumber||"-"}
												</TableCell>
											</TableRow>
										))
									}
							</TableBody>
						</Table>
</Card>
  </Stack>}

{/* {(values.staticIp ||values.fixedLine )&&
<Card sx={{pl:4,pr:4}}>
  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}>
    {values.staticIp&&<Stack sx={{width:"50%"}}><Grid container spacing={1} mb={5} >
    {values.staticIp &&
                values &&
                values.staticIpDetail &&
                values.staticIpDetail.map((edu, index) => (
                  <>
                  {index === 0 && (
                    <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom>
                Static IP Details
                </Typography>
              </Grid>
                )}
                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          IPAddress{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index]?.staticIpAddress}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Assigned To Ip{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index]?.assignedToIp}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Assigned To Domain{index + 1}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index]?.assignedToDomain}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Ip Type{index + 1}
                        </Typography>
                        </Grid>
                        <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.staticIpDetail[index].ipType}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}
      </Grid></Stack>}
      {values.fixedLine&&<Stack sx={{width:"50%"}}>
    <Grid container spacing={1} mb={5}>
    {values.fixedLine &&
                values &&
                values.ispFixedLine &&
                values.ispFixedLine.map((edu, index) => (
                  <>
                  {index === 0 && (
                    <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom >
                Fixed Line Detail{index + 1}
                </Typography>
              </Grid>
                )}
                    <Grid
                      item
                      xs={12}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Phone Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.ispFixedLine[index]?.phoneNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}
    </Grid>
    </Stack>}
  </Stack>

</Card>
} */}
{(values.routerGatewayIp||values.routerUsername||values.routerPassword)?
<Card sx={{pl:4,pr:4}}>
  {/* <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={2}> */}
    <Stack sx={{width:"100%"}}>
      <Grid container spacing={1} mb={5} >
      <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom>
                  Router Information
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Gateway IP
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.routerGatewayIp}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                  Username
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.routerUsername}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.routerPassword?(showRouterPassword ? values.routerPassword : "******"):''}
                    <IconButton onClick={handleShowRouterPassword} edge="end">
                      {values.routerPassword?showRouterPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      ):''}
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
      </Grid>
      </Stack>
    
  {/* </Stack> */}

</Card>
:''}

{(values.wifiSsid||values.wifiPassword)?<Card sx={{pl:4,pr:4}}>
<Stack sx={{width:"100%"}}>
    <Grid container spacing={1} mb={5}>
    <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom>
                  Wi-fi Details
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                  SSID
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.wifiSsid}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.wifiPassword?(showWifiPassword ? values.wifiPassword : "******"):''}
                    <IconButton onClick={handleShowWifiPassword} edge="end">
                      {values.wifiPassword?showWifiPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      ):''}
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
    </Grid>
    </Stack>
</Card>:''}

{(values.portalAccountRelationshipNumber||values.portalUrl||values.portalUsername||values.portalPassword)?
  <Card sx={{pl:4,pr:4}}>
    <Stack sx={{width:"100%"}}>
      <Grid container spacing={1} mb={5} >
      <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom>
                  Portal Information
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                  Account Relationship Number
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.portalAccountRelationshipNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center",fontSize:'13px' }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    URL
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.portalUrl}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Username
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.portalUsername}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={6}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.portalPassword?(showPortalPassword ? values.portalPassword : "******"):''}
                    <IconButton onClick={handleShowPortalPassword} edge="end">
                      {values.portalPassword?showPortalPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      ):''}
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
      </Grid>
      </Stack>
  

</Card>
:''}

{values.supportPerson&&<Stack>
  <Typography variant="h5" gutterBottom>
  Support Person Details
                </Typography>
                <Card >
                <Table>
							<TableHead>
								<TableRow>
                <TableCell align="center">Name</TableCell>
									<TableCell align="center">Phone Number</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
              {values &&
                values.supportPerson &&
                values.supportPerson.map((edu, index) => (
											<TableRow key={index}>
                        <TableCell align="center">
                        {values.supportPerson[index]?.name||""}
												</TableCell>
                        <TableCell align="center">
                        {values.supportPerson[index]?.phoneNumber||""}
												</TableCell>
											</TableRow>
										))
									}
							</TableBody>
						</Table>
</Card>
  </Stack>}

{/* <Card sx={{pl:4,pr:4}}>
  <Stack direction="row"  divider={<Divider orientation="vertical" flexItem />} spacing={2}>
    <Stack sx={{width:"50%"}}>
      <Grid container spacing={1} mb={5} >
      <Grid item xs={12} mt={2}>
                <Typography variant="h5" gutterBottom>
                  Portal Information
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                  Account Relationship Number
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.portalAccountRelationshipNumber}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                
                sx={{ display: "flex",  alignItems: "center",fontSize:'13px' }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    URL
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.portalUrl}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Username
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.portalUsername}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={6}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                    Password
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152" }}>
                    {values.portalPassword?(showPortalPassword ? values.portalPassword : "******"):''}
                    <IconButton onClick={handleShowPortalPassword} edge="end">
                      {values.portalPassword?showPortalPassword ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      ):''}
                    </IconButton>
                  </Typography>
                </Grid>
              </Grid>
      </Grid>
      </Stack>
    <Stack sx={{width:"50%"}}>
    <Grid container spacing={1} mb={5}>
    <Grid item xs={12} mt={2}>
<Grid
                item
                xs={12}
                sx={{ display: "flex",  alignItems: "center" }}>
                <Typography variant="h5" gutterBottom>
                  Support Person Details
                </Typography>
              </Grid>
              {values &&
                values.supportPerson &&
                values.supportPerson.map((edu, index) => (
                  <>
                    <Grid
                      item
                      xs={6}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Name
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.supportPerson[index]?.name}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      
                      sx={{ display: "flex",  alignItems: "center" }}>
                      <Grid item xs={6}>
                        <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                          Phone Number
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                          {values.supportPerson[index]?.phoneNumber}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                ))}
                </Grid>
    
    </Grid>
    </Stack>
  </Stack>

</Card> */}
{values.notes&&
<Card sx={{pl:4,pr:4}}>
<Stack direction="row">
      <Grid container spacing={1} mb={5} >
      <Grid
                item
                xs={12}
                sx={{ display: "flex",  alignItems: "center" }} mt={2}>
                <Typography variant="h5" gutterBottom >
                Custom Note
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                
                sx={{ display: "flex",  alignItems: "center" }}>
                <Grid item xs={3}>
                  <Typography variant="" sx={{ fontWeight: "regular",fontSize:'13px' }}>
                  Custom Note
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Typography sx={{ fontWeight: "regular", color: "#505152",fontSize:'13px' }}>
                    {values.notes}
                  </Typography>
                </Grid>
              </Grid>
                </Grid>
      </Stack>

</Card>
}
</Stack>





            {/* </form> */}
          {/* </Paper> */}
        {/* </Container> */}
      {/* </Card> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Stack sx={style}>
          <TextField
            fullWidth
            autoComplete="off"
            label="Varification Code"
            name="ispName"
            // value={values.ispName}
            onChange={(e) => {
              fildname == "routerPassword"
                ? setRouterPasswordKey(e.target.value)
                : fildname == "wifiPassword"
                ? setWifiPasswordKey(e.target.value)
                : setPortalPasswordKey(e.target.value);
            }}
            variant="outlined"
            size="small"
          />
          <Button variant="contained" onClick={handleVerify}>
            Submit
          </Button>
        </Stack>
      </Modal>
    </Container>
  );
};

const componentConfig = {
  component: ViewIsp,
  path: "/isp/view/:id",
  public: false,
  layout: DashboardLayout,
  group: "organization",
  sidebar: true,
  role: ["admin"],
};

export default componentConfig;
